import React from "react";
import { useState } from "react";
import "../regularTheme2Mobile/RegularTheme2Mobile.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import timeline from "../../../../../../images/how-it-work/timeline-mobile.png"
import blogpostmobile_1 from "../../../../../../../src/images/desktopblogpost/blogpostmobile1.png"
import { BsCheckCircleFill } from "react-icons/bs";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { IoIosArrowForward } from "react-icons/io";
import { FcCheckmark } from "react-icons/fc"
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai"
import "slick-carousel/slick/slick-theme.css";
export default function RegularTheme2Mobile() {
    const [isVisible, setIsVisible] = useState(false);

    const handleClick = () => {
        setIsVisible(!isVisible);
    };

    const handleClose = () => {
        setIsVisible(false);
    };
    const [hamburger, setHamburger] = useState(false);

    const handleHamburgerClick = () => {
        setHamburger(!hamburger);
    };

    const handleHamburgerClose = () => {
        setHamburger(false);
    };
    const [bookme, setBookme] = useState(false);

    const handleBookmeClick = () => {
        setBookme(!bookme);
    };

    const handleBookmeClose = () => {
        setBookme(false);
    };

    const [Competency1, setCompetency1] = useState(10);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div>
            <div className="mobile-maincontent p-5">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-whoweare d-flex justify-content-center flex-column py-5 h-100">
                            <div className="whoweare-content-1">
                                <h5 className="whoweare">Who are we?</h5>
                            </div>
                            <div className="whoweare-paragraph-1">
                                <p className="paragraph-1 mb-0 mt-2">
                                    Lorem ipsum dolor sit amet consectetur. Elit nunc faucibus sit pellentesque aliquet condimentum risus cursus vivamus. Id in quis amet urna enim sed nunc ut. Nunc scelerisque arcu dictum sem rhoncus duis. Lacus vulputate tempus cursus elit quam gravida lectus at. Nec mauris sed ac arcu ullamcorper sem nulla accumsan. Mauris iaculis etiam at molestie lobortis a pulvinar eget. Fames venenatis euismod tincidunt sapien. Senectus sit morbi arcu egestas ac id pellentesque. Et consectetur orci hac volutpat. Volutpat viverra fames imperdiet quis rhoncus nunc aliquam. Consectet.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="blogpostmobile_1 d-flex h-100">
                            <img src={blogpostmobile_1} alt="blogpostmobile_1" className="img-fluid w-100"></img>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="bg-whoweare2 d-flex justify-content-center flex-column h-100 py-5">
                            <div className="whoweare-content-2">
                                <h5 className="whoweare2">Who are we?</h5>
                            </div>
                            <div className="whoweare-paragraph-2">
                                <p className="paragraph-2 mb-0 mt-2 pb-3">
                                    Lorem ipsum dolor sit amet consectetur. Elit nunc faucibus sit pellentesque aliquet condimentum risus cursus vivamus. Id in quis amet urna enim sed nunc ut. Nunc scelerisque arcu dictum sem rhoncus duis.
                                </p>
                            </div>
                            <ul className="whoweare-paragraph-list">
                                <li className="list-items mt-3"><span className="me-3"><BsCheckCircleFill className="checkicon" /></span>Lacus vulputate tempus</li>
                                <li className="list-items mt-3"><span className="me-3"><BsCheckCircleFill className="checkicon" /></span>Cursus elit quam</li>
                                <li className="list-items mt-3"><span className="me-3"><BsCheckCircleFill className="checkicon" /></span>Gravida lectus at</li>
                                <li className="list-items mt-3"><span className="me-3"><BsCheckCircleFill className="checkicon" /></span>Nec mauris sed</li>
                                <li className="list-items mt-3"><span className="me-3"><BsCheckCircleFill className="checkicon" /></span>Ac arcu ullamcorper</li>
                            </ul>
                        </div> 
                    </div>
                    <div className="col-12">
                        <div className="blogpostmobile_1 d-flex h-100">
                            <img src={blogpostmobile_1} alt="blogpostmobile_1" className="img-fluid w-100"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
