import React from "react";
// import Education from "./ProfileSec/Education";
import img1 from '../../../../../images/profileview/img27.png';
import img2 from '../../../../../images/profileview/img28.png';
import img3 from '../../../../../images/profileview/img29.png';
import img4 from '../../../../../images/profileview/img30.png';
import img5 from '../../../../../images/profileview/img31.png';
import img6 from '../../../../../images/profileview/img32.png';
import img7 from '../../../../../images/profileview/img33.png';
import img8 from '../../../../../images/profileview/img34.png';
import Experiance from "./ProfileSec/Experiance";
import FavouritePodcast from "./ProfileSec/FavouritePodcast";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function ProfileViewThree() {
  const { t, i18n } = useTranslation();

  return (
    <div className="customRow">
      {/* <Education /> */}
      <Experiance />
      <div className="text-center mb-4">
        <h4 className="shadow-5in bg px-2 py-2 h8 rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.favourite_Book')} </h4>
        <div className="shadow-4 br-30 p-30 text-center">
          <img src={img1} alt="" className="w-100 mb-4" />
          <h4 className="shadow-5in bg px-2 py-2 h8 rounded-1 fw-bold d-inline-block">{t('profile_section.favourite_writter_AUTHOR')}</h4>
          <h5 className="h7 mb-4">{t('profile_section.timothy_ferris')}</h5>
          
          <h4 className="shadow-5in bg px-2 py-2 h8 text-uppercase rounded-1 fw-bold d-inline-block ">
            {t('profile_section.favourite_inspirational_speaker')}</h4>
          <h5 className="h7 mb-4">{t('profile_section.eric_thomas')}</h5>
        </div>
      </div>
      <div className="text-center mb-4">
        <h4 className="shadow-5in bg px-2 py-2 h8 rounded-1 fw-bold d-inline-block headingMinus">
          {t('profile_section.core_value')}</h4>
        <div className="shadow-4 br-30 p-30">
          <ul className="UlLine">
            <NavItem as="li" className="d-flex mb-4 justify-content-between align-items-center">
              <span className="h7 shadow-4 br-5 w-85 py-0 h-26 bg"> {t('profile_section.value_1')}</span>
              <h3 className="h7 text-start shadow-4 mb-0 br-5 ms-3 py-0 px-3 w-100 h-36">
              Loyalty | Integrity | Honesty | Authenticity
              </h3>
            </NavItem>
            <NavItem as="li" className="d-flex mb-4 justify-content-between align-items-center">
              <span className="h7 shadow-4 br-5 w-85 py-0 h-26 bg"> {t('profile_section.value_2')}</span>
              <h3 className="h7 text-start shadow-4 mb-0 br-5 ms-3 py-0 px-3 w-100 h-36"> {t('profile_section.family_privacy')}</h3>
            </NavItem>
            <NavItem as="li" className="d-flex mb-4 justify-content-between align-items-center">
              <span className="h7 shadow-4 br-5 w-85 py-0 h-26 bg">{t('profile_section.value_3')}</span>
              <h3 className="h7 shadow-4 text-start mb-0 br-5 ms-3 py-0 px-3 w-100 h-36">{t('profile_section.spirituality')}</h3>
            </NavItem>
            <NavItem as="li" className="d-flex justify-content-between align-items-center">
              <span className="h7 shadow-4 br-5 w-85 py-0 h-26 bg">{t('profile_section.value_4')}</span>
              <h3 className="h7 shadow-4 text-start mb-0 br-5 ms-3 py-0 px-3 w-100 h-36">
                {t('profile_section.substainability_social_responsibilities')}</h3>
            </NavItem>
          </ul>
        </div>
      </div>
      <div className="text-center case mb-4">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.personal_value')}</h4>
        <div className="shadow-4 br-20 py-4 px-4 d-flex justify-content-center align-items-center flex-column">
          <img src={img2} alt="" />
          <ul className="mt-4">
          <NavItem as="li" className="h8">
              <span className="circle"></span>Self-Respect
            </NavItem>
            <NavItem as="li" className="h8">
              <span className="circle"></span>{t('profile_section.self_respect_self_Love')}
            </NavItem>
            <NavItem as="li" className="h8">
              <span className="circle"></span>{t('profile_section.spirituality')}
            </NavItem>
            <NavItem as="li" className="h8">
              <span className="circle"></span>{t('profile_section.mental_health')}
            </NavItem>
            <NavItem as="li" className="h8">
              <span className="circle"></span>{t('profile_section.healthy_lifestyle')}
            </NavItem>
            <NavItem as="li" className="h8">
              <span className="circle"></span>{t('profile_section.work_life_balance')}
            </NavItem>
          </ul>
        </div>
      </div>
      <FavouritePodcast />
      <div className="text-center case mb-4">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">
        {t('profile_section.anime_character')}
        </h4>
        <div className="shadow-4 br-20 pt-4 px-4 d-flex justify-content-center align-items-center flex-column">
          <img src={img3} alt="" className="mb-3" />
          <ul className="d-flex w-100 py-0  m-0 justify-content-between align-items-center">
            <NavItem as="li" className="d-flex justify-content-center align-items-center flex-column">
              <img src={img4} alt="" width="112" height="112" className="" />
              <h6 className="shadow-5in h7 py-1 px-2 rounded">{t('profile_section.gon')}</h6>
            </NavItem>
            <NavItem as="li" className="d-flex justify-content-center align-items-center flex-column">
              <img src={img5} alt="" width="112" height="112" className="" />
              <h6 className="shadow-5in h7 py-1 px-2 rounded">{t('profile_section.narato')}</h6>
            </NavItem>
            <NavItem as="li" className="d-flex justify-content-center align-items-center flex-column">
              <img src={img6} alt="" width="112" height="112" className="" />
              <h6 className="shadow-5in h7 py-1 px-2 rounded">{t('profile_section.luffy')}</h6>
            </NavItem>
          </ul>
        </div>
      </div>
      <div className="text-center case mb-4">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.favourite_game')}</h4>
        <div className="shadow-4 br-20 h-250 pt-4 px-4 d-flex justify-content-center align-items-center flex-column">
          <img src={img7} alt="" className="mb-3" />
          <ul className="d-flex flex-wrap w-100 py-0  m-0 justify-content-center align-items-center">
            <NavItem as="li" className="d-flex justify-content-center align-items-center flex-column">
              <img src={img8} alt="" width="100" height="100" className="" />
              <h6 className="shadow-5in h7 py-1 px-2 rounded">{t('profile_section.valorant')}</h6>
            </NavItem>
          </ul>
        </div>
      </div>
    </div>
  );
}
