import React from "react";
import ButtonBox from "../../../global/ButtonBox";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { NavItem } from 'react-bootstrap';
import DropdownBox from '../../../global/DropdownBox';

export default function ThreeHoursBusinessForm2() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });
    const options = [
        {
            value: "FASHION",
            label: "FASHION",
        },
        {
            value: "BEAUTY",
            label: "BEAUTY",
        },
        {
            value: "NUTRITION",
            label: "NUTRITION",
        },
        {
            value: "FITNESS",
            label: "FITNESS",
        },
        {
            value: "TRAVEL",
            label: "TRAVEL",
        },
        {
            value: "HOME DECOR",
            label: "HOME DECOR",
        },
        {
            value: "SPIRITUALITY",
            label: "SPIRITUALITY",
        },
        {
            value: "COACHING",
            label: "COACHING",
        },
        {
            value: "EVENT PLANNING",
            label: "EVENT PLANNING",
        },
        {
            value: "ANIMAL",
            label: "ANIMAL",
        },
        {
            value: "FINANCE",
            label: "FINANCE",
        },
        {
            value: "PARENTING",
            label: "PARENTING",
        },
    ];

    return (
        <div>
            <ul className="arrow-select">
                <NavItem as="li" className="mb-4">
                    <div className=" position-relative">
                        <label className="mb-2">{t('timeline_form_component.whats_your_industry')}</label>
                        {/* <Select isSearchable={false}
                            options={options}
                            className="country mb-4 w-100 mx-auto text-center bgArrow"
                            placeholder={t('timeline_form_component.other_placeholder')}
                            theme={theme}
                            styles={{
                                option: (base) => ({
                                    ...base,
                                    borderRadius: "30px",
                                    width: "94%",
                                    maxheight: "50px",
                                    lineHeight: "32px",
                                    margin: "10px",
                                    boxShadow: "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                    cursor: 'pointer',
                                }),
                            }}
                        /> */}
                    <DropdownBox name="Fashion" boxid="country" boxwidth="w-100 mx-auto mt-3"/>

                    </div>

                </NavItem>
                <NavItem as="li" className="mb-4">
                    <div className=" position-relative">
                        <label className="mb-2">{t('timeline_form_component.name_your_industry')}</label>
                        {/* <Select isSearchable={false}
                            options={options}
                            className="country mb-4 w-100 mx-auto text-center bgArrow"
                            placeholder={t('timeline_form_component.fashion_placeholder')}
                            theme={theme}
                            styles={{
                                option: (base) => ({
                                    ...base,
                                    borderRadius: "30px",
                                    width: "94%",
                                    maxheight: "50px",
                                    lineHeight: "32px",
                                    margin: "10px",
                                    cursor: 'pointer',
                                    boxShadow:
                                        "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                }),
                            }}
                        /> */}
                    <DropdownBox name="Fashion" boxid="country" boxwidth="w-100 mx-auto mt-3"/>

                    </div>

                </NavItem>
                <NavItem as="li" className="mb-4">
                    <div className=" position-relative">
                        <label className="mb-2">{t('timeline_form_component.whats_your_business_niche')}</label>
                        {/* <Select isSearchable={false}
                            options={options}
                            className="country mb-4 w-100 mx-auto text-center bgArrow"
                            placeholder= {t('timeline_form_component.fashion_placeholder')}
                            theme={theme}
                            styles={{
                                option: (base) => ({
                                    ...base,
                                    borderRadius: "30px",
                                    width: "94%",
                                    maxheight: "50px",
                                    lineHeight: "32px",
                                    margin: "10px",
                                    cursor: 'pointer',
                                    boxShadow:
                                        "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                }),
                            }}
                        /> */}
                    <DropdownBox name="Fashion" boxid="country" boxwidth="w-100 mx-auto mt-3"/>

                    </div>

                </NavItem>
                <NavItem as="li" className="mb-5">
                    <div className="position-relative">
                        <label className="mb-2">{t('timeline_form_component.describe_your_business')}</label>
                        <textarea
                            className="country mb-4 w-100 mx-auto text-center"
                            placeholder={t('timeline_form_component.describe_placeholder')}
                        >

                        </textarea>
                    </div>

                </NavItem>
                <NavItem as="li" className=" text-center time-btn mb-4">
                <ButtonBox boxwidth="w-300 lg  mb-5" clicked="Slogan/Tagline Generated" name="Generate Slogan/Tagline Now" />

 
                </NavItem>
            </ul>
        </div>
    );
}
