import React, { useState, useEffect } from 'react';

import {  FaFacebookF, FaTwitter, FaLinkedinIn, FaDribbble, FaPinterest, FaYoutube,  } from "react-icons/fa";
import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';

import dribble_dark from '../../../../../images/dribble-dark.png';

import fb from '../../../../../images/fb.png';

import insta from '../../../../../images/insta.png';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import {  RiInstagramFill,  } from "react-icons/ri";

import printer_est from '../../../../../images/pinter-est.png';
import linked_in from '../../../../../images/linked-in.png';
import you_tube from '../../../../../images/you-tube.png';
import Social_2 from '../../../../../images/Social-2.png'

import { useTranslation } from "react-i18next";



export default function SocialMediaIconsComponent() {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setIsOpen(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [Competency1, setCompetency1] = useState(10);

  return (
    <div>



      <div className="">
        <div>




          <ul className="mt-5 top-padding reg-social ">
            <li className="pb-3">
              <img src={fb} alt="fb" ></img>

            </li>
            <li className="pb-3">
              <img src={insta} alt="insta" ></img>

            </li>
            <li className="pb-3">
              <img src={Social_2} alt="twitter" ></img>

            </li>
            <li className="pb-3">
              <img src={linked_in} alt="linked-in" ></img>

            </li>
            <li className="pb-3">
              <img src={dribble_dark} alt="dribble" ></img>

            </li>
            <li className="pb-3">
              <img src={printer_est} alt="printerest" ></img>

            </li>
            <li className="pb-3">
              <img src={you_tube} alt="youtube" ></img>
            </li>
          </ul>
          <div className="meu-social">
            <ul className="mt-5 top-padding">
              <li >
                <FaFacebookF />
              </li>
              <li >
                <RiInstagramFill />
              </li>
              <li >
                <FaTwitter />
              </li>
              <li >
                <FaLinkedinIn />
              </li>
              <li >
                <FaDribbble />
              </li>
              <li >
                <FaPinterest />
              </li>
              <li >
                <FaYoutube />
              </li>
            </ul>
          </div>



          <div>


          </div>
        </div>


      </div>




    </div>



  );
}
