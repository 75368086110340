import React, { useState, useEffect } from "react";

import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaPinterest,
  FaYoutube,
  FaTiktok,
  FaSnapchatGhost,
  FaInstagram,
} from "react-icons/fa";

import "../../ProfileHeroSectionDarkTheme.scss";
import "../../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss";

import img1 from "../../../../../../images/img1.png";

import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

import { BiMessageDots } from "react-icons/bi";
import { RiVipFill, RiVipCrown2Fill } from "react-icons/ri";

import canda_img from "../../../../../../images/canada.png";

import { useTranslation } from "react-i18next";
import { BsPatchCheckFill } from "react-icons/bs";

export default function ProfileComponent() {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setIsOpen(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [Competency1, setCompetency1] = useState(10);

  return (
 
      <div className="mr-3 zoom-section ">
        <div className="icons-circle">
          <div id="parent-circle">
            <div class="circle printerest">
              <FaTwitter />
            </div>
            <div class="circle facebookk">
              <FaLinkedinIn />
            </div>
            <div class="circle instaa">
              <FaTiktok />
            </div>
            <div class="circle twitter">
              <FaYoutube />
            </div>
            <div class="circle innn">
              <FaSnapchatGhost />
            </div>
            <div class="circle tiktok">
              <BiMessageDots />
            </div>
            <div class="circle videoo">
              <BsPatchCheckFill />
            </div>
            <div class="circle videooo">
              <img src={canda_img} alt="canada" />
            </div>
            <div class="circle snapchat">
              <RiVipCrown2Fill />
            </div>
            <div class="circle logo-f">
              <RiVipFill />
            </div>
            <div class="circle logo2">
              <FaPinterest />
            </div>
            <div class="circle logo-3">
              <FaFacebookF />
            </div>
            <div class="circle logo-4">
              <FaInstagram />
            </div>
          </div>
        </div>
        <img src={img1} alt="img1" className="profile-img"></img>
      </div>
  
  );
}
