import React, { useState } from "react";
import ButtonBox from "../../../global/ButtonBox";
import { Link } from 'react-router-dom';
import Barsleft from "../../../../images/bars-left.png";
import Barscenter from "../../../../images/bars-center.png";
import Barsright from "../../../../images/bars-right.png";
import btnBorder from "../../../../images/btn-border.png";
import btnIcon from "../../../../images/btn-icon.png";
import { IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io';
import { RxCrossCircled } from 'react-icons/rx';
import { AiFillFile } from 'react-icons/ai'
import { BsCode, BsBoxSeam } from 'react-icons/bs'
import EditorDrop from "../../../global/EditorDrop";
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './DigitalBusinessCardStep.scss';
import { NavItem } from 'react-bootstrap';
import ColorPick from "../../../global/ColorPick";

export default function DigitalBusinessSettings() {

    const { t, i18n } = useTranslation();


    const openSelect = () => { inputRef1.current.click(); }
    const inputRef1 = React.useRef();


    const [previewImage1, setPreviewImage1] = useState('');


    const [image1, setImage1] = useState('');

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const [addSection, setAddSection] = useState(false);
    const [customizeTheFooter, setCustomizeTheFooter] = useState(false);
    const [simplifyYourWebsite, setSimplifyYourWebsite] = useState(false);
    const [searchEngine, setSearchEngine] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const changeAddSection = (val)=>{
        setAddSection(val);
    }
    const changeCustomizeTheFooter = (val)=>{
        setCustomizeTheFooter(val);
    }
    const changeSimplifyYourWebsite = (val)=>{
        setSimplifyYourWebsite(val);
    }
    const changeSearchEngine = (val)=>{
        setSearchEngine(val);
    }

    return (
        <div className="Tab_settings">
            <form action="" method="" className="m-5">
                <div className="section_wrapper mb-5">
                    <div className="check-button mb-4">
                        <div className='check-button mb-2 d-flex justify-content-start align-items-center'>
                            <input type="checkbox" className="btn-check" name="communication-day" id="signature"  onClick={(e) => changeAddSection(e.target.checked)}/>
                            <label className="btn rounded-circle btnRadio large me-3" htmlFor="signature"><div className="radioInner"><span></span></div></label>
                            {t('timeline_form_digital_business_card_component.add_section')}
                        </div> 
                    </div>
                    <div className="col-md-12 mb-4">
                        <label className="mb-2 invisible"></label>
                        <input
                            placeholder={t('timeline_form_digital_business_card_component.input_placeholder')}
                            className="shadow-5in br-10 w-100 mb-4"
                            type="text"
                        />
                    </div>

                    {addSection && (
                        <div>
                            {/* <div className="check-button mb-4">
                                <input type="checkbox" className="btn-check" name="communication-day" id="add-section" checked />
                                <label className="btn btn-outline-primary" htmlFor="add-section">
                                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                                    </svg>
                                </label>
                                {t('timeline_form_digital_business_card_component.add_section')}
                            </div> */}
                            <div className="section_inner mb-4">
                                <div className="row mb-4">
                                    <div className="col-md-1 arrow_icon">
                                        <Link to="">
                                            <IoMdArrowDropup className="icon" />
                                        </Link>
                                        <Link to="">
                                            <IoMdArrowDropdown className="icon" />
                                        </Link>
                                    </div>
                                    <div className="col-md-10 mb-4">
                                        <input
                                            placeholder={t('timeline_form_digital_business_card_component.review_zip')}
                                            className=" w-100"
                                            type="text"
                                        />
                                        <div className="border_b">
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <Link to="" className="cross_icon">
                                            <RxCrossCircled className="icon" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-1 arrow_icon">
                                        <Link to="">
                                            <IoMdArrowDropup className="icon" />
                                        </Link>
                                        <Link to="">
                                            <IoMdArrowDropdown className="icon" />
                                        </Link>
                                    </div>
                                    <div className="col-md-10 mb-4">
                                        <input
                                            placeholder={t('timeline_form_digital_business_card_component.input1_placeholder')}
                                            className="shadow-5in br-10 w-100 mb-4"
                                            type="text"
                                        />
                                    </div>
                                    <div className="col-md-1">
                                        <Link to="" className="cross_icon">
                                            <RxCrossCircled className="icon" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="row mb-4 align-items-center">
                                    <div className="col-md-1 arrow_icon">
                                        <Link to="">
                                            <IoMdArrowDropup className="icon" />
                                        </Link>
                                        <Link to="">
                                            <IoMdArrowDropdown className="icon" />
                                        </Link>
                                    </div>
                                    <div className="col-md-10 mb-4">
                                        <div className="section_wrapper">
                                            <input
                                                placeholder={t('timeline_form_digital_business_card_component.review_link_generator')}
                                                className="shadow-5in br-10 w-100 mb-4"
                                                type="text"
                                            />
                                            <textarea
                                                placeholder="be hosted on your domain or ours (Optional upgrade)." rows="5"
                                                className="shadow-5in br-bottom-15 mb-4 w-100  p-5 pt-4"
                                                type="text">
                                            </textarea>
                                            <input
                                                placeholder={t('timeline_form_digital_business_card_component.its_free')}
                                                className="shadow-5in br-10 w-100 mb-4"
                                                type="text"
                                            />
                                            <input
                                                placeholder={t('timeline_form_digital_business_card_component.web_link')}
                                                className="shadow-5in br-10 w-100 mb-4"
                                                type="text"
                                            />
                                            <div className="mdl row">
                                                <div className="shadow-5in br-10 w-100 mb-4 d-flex justify-content-center align-items-center">

                                                    <Button variant="primary">
                                                        <span>ADD TO CONTACTS</span>
                                                        <div className="inner_btn">
                                                            <div className="btn_1"></div>
                                                        </div>
                                                        <div className="btn_arrow">

                                                        </div>
                                                    </Button>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                            <div className="mdl">
                                                <div className="shadow-5in br-10 w-100 mb-4 d-flex justify-content-center align-items-center">

                                                    <Button variant="primary" onClick={handleShow}>
                                                        <span>BOOK ME NOW</span>
                                                        <div className="inner_btn">
                                                            <div className="btn_1"></div>
                                                        </div>
                                                        <div className="btn_arrow">

                                                        </div>
                                                    </Button>
                                                </div>

                                                <Modal show={show} onHide={handleClose}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>
                                                            <div className="w-100 d-flex justify-content-between align-items-center">
                                                                <h6>Content alignment</h6>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="btn bg shadow-4 br-40 mr-2">
                                                                        <img src={Barsleft} alt="" />
                                                                    </div>
                                                                    <div className="btn bg shadow-4 br-40 mr-2">
                                                                        <img src={Barscenter} alt="" />
                                                                    </div>
                                                                    <div className="btn bg shadow-4 br-40">
                                                                        <img src={Barsright} alt="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        {/* <form > */}
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <label className="mb-2">{t('timeline_form_digital_business_card_component.button_text')}</label>
                                                                <input
                                                                    placeholder={t('timeline_form_digital_business_card_component.button_text_placeholder')}
                                                                    className="shadow-5in br-10 w-100 mb-4"
                                                                    type="text"
                                                                />
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div class="btn bg shadow-4 br-40 mb-1">
                                                                    <u>A</u>
                                                                </div>
                                                                <div className="d-flex color_dropdown">
                                                                    <div className="dropdown">
                                                                        <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                                                            <div className="btn bg shadow-4 br-40 p-1">
                                                                                <div className="text_icon">
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                        <ul className="dropdown-menu w_320 dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                                                                            <div className="graphSec mt-2 mb-30">
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <ColorPick />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-md-12">
                                                                <label className="mb-2">{t('timeline_form_digital_business_card_component.button_text')}</label>
                                                                <input
                                                                    placeholder={t('timeline_form_digital_business_card_component.button_text_placeholder')}
                                                                    className="shadow-5in br-10 w-100 mb-4"
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mt-4 text-center pt-3">
                                                            <div className="">

                                                                <ButtonBox boxwidth="w-220 mt-0 mb-50 mx-auto"
                                                                    name="Choose button style"
                                                                    value="Choose button style"
                                                                    btnclick={() => handleShow1()}
                                                                />

                                                                <Modal className="button_modal " show={show1} onHide={handleClose1}>
                                                                    <Modal.Header closeButton>
                                                                    </Modal.Header>
                                                                    <Modal.Body className="relative">
                                                                        <div className="col-md-12 d-flex justify-content-end">
                                                                            <div className="d-flex color_dropdown">
                                                                                <div className="dropdown">
                                                                                    <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                                                                        <div className="btn bg shadow-4 br-40 p-1">
                                                                                            <div className="text_icon">
                                                                                            </div>
                                                                                        </div>
                                                                                    </span>
                                                                                    <ul className="dropdown-menu w_320 dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                                                                                        <div className="graphSec mt-2 mb-30">
                                                                                            <div className="row">
                                                                                                <div className="col-md-12">
                                                                                                    <ColorPick />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="button_wrapper w-100">
                                                                            <div className="row mb-4 px-5">
                                                                                <div className="button_1">
                                                                                    <span>button 1</span>
                                                                                    <div className="inner_btn">
                                                                                        <div className="btn_1"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mb-4 px-5">
                                                                                <div className="button_2">
                                                                                    <span>button 2</span>
                                                                                    <div className="inner_btn">
                                                                                        <div className="btn_1"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mb-4 px-5">
                                                                                <div className="button_3_outer">
                                                                                    <div className="button_3">
                                                                                        <span>button 3</span>
                                                                                        <div className="inner_btn">
                                                                                            <div className="btn_1"></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mb-4 px-5">
                                                                                <div className="button_4_outer">
                                                                                    <div className="button_4">
                                                                                        <span>button 4</span>
                                                                                        <div className="inner_btn">
                                                                                            <div className="btn_1"></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mb-4 px-5">
                                                                                <div className="button_5">
                                                                                    <span>button 5</span>
                                                                                    <div className="inner_btn">
                                                                                        <div className="btn_1"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mb-4 px-5">
                                                                                <div className="button_6">
                                                                                    <span>button 6</span>
                                                                                    <div className="inner_btn">
                                                                                        <div className="btn_1"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mb-4 px-5 py-2">
                                                                                <div className="button_7">
                                                                                    <img src={btnBorder} alt="" className="btn_border" />
                                                                                    <span>button 7</span>
                                                                                    <div className="inner_btn">
                                                                                        <div className="btn_1"></div>
                                                                                    </div>
                                                                                    <img src={btnBorder} alt="" className="btn_border_reverse" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mb-4 px-5">
                                                                                <div className="relative d-flex p-0">
                                                                                    <div className="button_8">
                                                                                        <span>button 8</span>
                                                                                        <div className="inner_btn">
                                                                                            <div className="btn_1"></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="btn_8_after"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mb-4 px-5">
                                                                                <div className="button_9">
                                                                                    <span>button 9</span>
                                                                                    <div className="inner_btn">
                                                                                        <div className="btn_1"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mb-4 px-5 py-2">
                                                                                <div className="button_7 border-0">
                                                                                    <img src={btnIcon} alt="" className="btn_border ml_2" />
                                                                                    <span>button 7</span> 
                                                                                    <div className="inner_btn">
                                                                                        <div className="btn_1"></div>
                                                                                    </div>
                                                                                    <img src={btnIcon} alt="" className="btn_border_reverse ml_2" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Modal.Body>
                                                                </Modal>
                                                            </div>
                                                            {/* <ButtonBox boxwidth="w-220 mt-0 mb-50 mx-auto"
                                                            className="next action-button"
                                                            name="Choose button style"
                                                            value="Choose button style"
                                                        /> */}
                                                        </div>
                                                        {/* </form> */}
                                                    </Modal.Body>
                                                </Modal>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <Link to="" className="cross_icon">
                                            <RxCrossCircled className="icon" />
                                        </Link>
                                    </div>
                                </div>

                                <div className="row setting_box">
                                    <div className="col-md-10 mx-auto">
                                        <div className="uploadSec widsec mb-4">
                                            <ul className="d-flex flex-wrap row">
                                                <NavItem as="li" className="col-md-6 mb-4 li w-50">
                                                    <div className="imgView br-20 bg mb-2 h_16">
                                                        <div className='mx-auto mb-4 fileTab h-100'>
                                                            <label onClick={openSelect} className="w-100 h-100">
                                                                <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                                                                    {previewImage1 ? <img src={previewImage1} alt="Profile Picture" className="w-100 h-100" /> :
                                                                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                                                            <AiFillFile className="icon" />
                                                                            <p className="mb-0 mt-1 p">Attach media</p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                                                            let url = URL.createObjectURL(e.target.files[0])
                                                            setPreviewImage1(url);
                                                            setImage1(e.target.files[0])
                                                        }} ref={inputRef1} />
                                                    </div>
                                                </NavItem>
                                                <NavItem as="li" className="col-md-6 mb-4 li">
                                                    <div className="imgView br-20 bg mb-2 h_16">
                                                        <div className='mx-auto mb-4 fileTab h-100'>
                                                            <label className="w-100 h-100">
                                                                <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center h-100">
                                                                    <div className="d-flex flex-column bioSec justify-content-center align-items-center h-100 relative">
                                                                        <div className="dropFormat mb-2">
                                                                            <div className="d-flex">
                                                                                <div className="dropdown btnArrow btnArrow2 text_editor">
                                                                                    <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-display="static">
                                                                                        <Link to="" id="notab" className="d-flex justify-content-center fs-5 fw-bold ps-3 pe-5 align-items-center w-100x text-underline shadow-4 h-40 h8 br-30">
                                                                                            <u className="h5">A</u>
                                                                                        </Link>
                                                                                    </span>
                                                                                    <EditorDrop />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <textarea className="mb-0 mt-1 p h-100 px-3 w-100 br-20 pt_6" placeholder="Start typing here ..."></textarea>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </NavItem>
                                                <NavItem as="li" className="col-md-6 mb-4 li">
                                                    <div className="imgView br-20 bg mb-2 h_16">
                                                        <div className='mx-auto mb-4 fileTab h-100'>
                                                            <label className="w-100 h-100">
                                                                <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                                                                    <div className="d-flex flex-column bioSec justify-content-center align-items-center h-100 relative">
                                                                        <div className="d-flex flex-column justify-content-center align-items-center pt_6">
                                                                            <BsCode className="icon" />
                                                                        </div>
                                                                        <textarea className="mb-0 mt-1 p px-3" placeholder="Embed code"></textarea>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </NavItem>
                                                <NavItem as="li" className="col-md-6 mb-4 li">
                                                    <div className="imgView br-20 bg mb-2 h_16">
                                                        <div className='mx-auto mb-4 fileTab h-100'>
                                                            <label className="w-100 h-100">
                                                                <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                                                                    <div className="d-flex flex-column bioSec justify-content-center align-items-center h-100 relative">
                                                                        <div className="d-flex flex-column justify-content-center align-items-center pt_6">
                                                                            <BsBoxSeam className="icon" />
                                                                        </div>
                                                                        <textarea className="mb-0 mt-1 p px-3" placeholder="Add product link "></textarea>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </NavItem>
                                                {/* <NavItem as="li" className="col-md-6 mb-4 li">
                                                    <div className="imgView br-20 bg mb-2 h_16">
                                                        <div className='mx-auto mb-4 fileTab h-100'>
                                                            <label className="w-100 h-100">
                                                                <div className=" w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                                                                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                                                        <BsBoxSeam className="icon" />
                                                                        <p className="mb-0 mt-1 p">Add product link </p>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <input hidden type="text" name="profile" id="profile" />
                                                    </div>
                                                </NavItem> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border_b my-4"></div>
                        </div>
                    )}


                    <div className="pe-2 mb-4">
                        <div className="toggle_btn_wrapper bg h-50s">
                            <div className="form-check form-switch px-2 h-100">
                                <input className="form-check-input d-block float-end" type="checkbox" role="switch" onChange={(e) => changeCustomizeTheFooter(e.target.checked)} />
                                <label className="form-check-label ms-3 w-50" htmlFor="">
                                    <h4>{t('timeline_form_digital_business_card_component.customize_footer_credit')}</h4>
                                    <p>{t('timeline_form_digital_business_card_component.no_thanks')}</p>
                                </label>
                            </div>
                        </div>
                    </div>
                    {customizeTheFooter && (
                        <div>
                            {/* <div className="flex justify-content-between align-items-center mb-4">
                                <div className="pe-2">
                                    <div className="toggle_btn_wrapper bg h-50s">
                                        <div className="form-check form-switch px-2 h-100">
                                            <input className="form-check-input d-block float-end" type="checkbox" role="switch" id="" />
                                            <label className="form-check-label ms-3 w-100" htmlFor="">
                                                <h4>{t('timeline_form_digital_business_card_component.customize_footer_credit')}</h4>
                                                <p className="mb-0">{t('timeline_form_digital_business_card_component.no_thanks')}</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Link to="">
                                        <IoMdArrowDropdown className="yellow_icon" />
                                    </Link>
                                </div>
                            </div> */}
                            <div className="section_inner">
                                <h4>{t('timeline_form_digital_business_card_component.customize_the_footer')}</h4>
                                <div className="my-4">
                                    <div className="toggle_btn_wrapper bg h-50s">
                                        <div className="form-check form-switch px-2 h-100">
                                            <input className="form-check-input d-block float-end" type="checkbox" role="switch" checked />
                                            <label className="form-check-label ms-3 w-100" htmlFor="">
                                                <p className="mb-0">{t('timeline_form_digital_business_card_component.you_can_feature_your_privacy_policy_below')}</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <label className="mb-2">{t('timeline_form_digital_business_card_component.footer_text')}</label>
                                    <input
                                        placeholder={t('timeline_form_digital_business_card_component.footer_text_placeholder')}
                                        className="shadow-5in br-10 w-100 mb-4"
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-12 mb-4">
                                    <label className="mb-2">{t('timeline_form_digital_business_card_component.link_text')}</label>
                                    <input
                                        placeholder={t('timeline_form_digital_business_card_component.your_brand')}
                                        className="shadow-5in br-10 w-100 mb-4"
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="mb-2">{t('timeline_form_digital_business_card_component.url')}</label>
                                    <input
                                        placeholder={t('timeline_form_digital_business_card_component.url_placeholder')}
                                        className="shadow-5in br-10 w-100 mb-4"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="border_b my-4"></div>
                        </div>
                    )}

                    <div className="pe-2 mb-4">
                        <div className="toggle_btn_wrapper bg h-50s">
                            <div className="form-check form-switch px-2 h-100">
                                <input className="form-check-input d-block float-end" type="checkbox" role="switch" onChange={(e) => changeSimplifyYourWebsite(e.target.checked)} />
                                <label className="form-check-label ms-3 w-50" htmlFor="">
                                    <h4>{t('timeline_form_digital_business_card_component.simplify_your_card')}</h4>
                                    <p>{t('timeline_form_digital_business_card_component.display_all_actions')}</p>
                                </label>
                            </div>
                        </div>
                    </div>
                    {simplifyYourWebsite && (
                        <div>
                            <div className="flex justify-content-between align-items-center mb-4">
                                <div className="pe-2">
                                    <div className="toggle_btn_wrapper bg h-50s">
                                        <div className="form-check form-switch px-2 h-100">
                                            <input className="form-check-input d-block float-end" type="checkbox" role="switch" checked />
                                            <label className="form-check-label ms-3 w-100" htmlFor="">
                                                <h4>{t('timeline_form_digital_business_card_component.showcase_your_digital_business_card')}</h4>
                                                <p className="mb-0">{t('timeline_form_digital_business_card_component.display_all_actions')}</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Link to="">
                                        <IoMdArrowDropdown className="yellow_icon" />
                                    </Link>
                                </div>
                            </div>
                            <div className="section_inner">
                                <h4>{t('timeline_form_digital_business_card_component.simplify_your_digital_business_card_view')}</h4>
                                <div className="mt-4">
                                    <div className="toggle_btn_wrapper bg h-50s">
                                        <div className="form-check form-switch px-2 h-100">
                                            <input className="form-check-input d-block float-end" type="checkbox" role="switch" checked />
                                            <label className="form-check-label ms-3 w-100" htmlFor="">
                                                <p className="mb-0">{t('timeline_form_digital_business_card_component.always_show_add_content_button')}</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border_b my-4"></div>
                        </div>
                    )}

                    <div className="pe-2 mb-4">
                        <div className="toggle_btn_wrapper bg h-50s">
                            <div className="form-check form-switch px-2 h-100">
                                <input className="form-check-input d-block float-end" type="checkbox" role="switch" onChange={(e) => changeSearchEngine(e.target.checked)} />
                                <label className="form-check-label ms-3 w-50" htmlFor="">
                                    <h4>{t('timeline_form_digital_business_card_component.search_engine_visibilty')}</h4>
                                    <p>{t('timeline_form_digital_business_card_component.encourage_search_engine')}</p>
                                </label>
                            </div>
                        </div>
                    </div>
                    {searchEngine && (
                        <div>
                            <div className="flex justify-content-between align-items-center mb-4">
                                <div className="pe-2">
                                    <div className="toggle_btn_wrapper bg h-50s">
                                        <div className="form-check form-switch px-2 h-100">
                                            <input className="form-check-input d-block float-end" type="checkbox" role="switch" checked />
                                            <label className="form-check-label ms-3 w-100" htmlFor="">
                                                <h4>{t('timeline_form_digital_business_card_component.lets_search_engine_find_your_digital_business_card')}</h4>
                                                <p className="mb-0">{t('timeline_form_digital_business_card_component.enable_search_engine_visibility')}</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Link to="">
                                        <IoMdArrowDropdown className="yellow_icon" />
                                    </Link>
                                </div>
                            </div>
                            <div className="section_inner">
                                <h4>{t('timeline_form_digital_business_card_component.search_engine_visibilty')}</h4>
                                <div className="my-4">
                                    <div className="toggle_btn_wrapper bg h-50s">
                                        <div className="form-check form-switch px-2 h-100">
                                            <input className="form-check-input d-block float-end" type="checkbox" role="switch" checked />
                                            <label className="form-check-label ms-3 w-100" htmlFor="">
                                                <p className="mb-0">{t('timeline_form_digital_business_card_component.encourage_search_engine')}</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <label className="mb-2">{t('timeline_form_digital_business_card_component.seo_title')}</label>
                                    <input
                                        placeholder={t('timeline_form_digital_business_card_component.seo_title_placeholder')}
                                        className="shadow-5in br-10 w-100 mb-4"
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="mb-2">{t('timeline_form_digital_business_card_component.meta_description')}</label>
                                    <input
                                        placeholder={t('timeline_form_digital_business_card_component.your_brand')}
                                        className="shadow-5in br-10 w-100 mb-4"
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>)}
                </div>





                <div className="mt-5 text-center pt-3">
                    <ButtonBox boxwidth="w-350 mt-0 mb-50 mx-auto" name="Generate Digital Biz Card Now" className="next action-button" value="Generate Digital Biz Card Now" clicked="Saved" />
                </div>
            </form>
        </div >
    );
}
