import React from 'react'


// import $ from 'jquery'
// 	$(document).ready(function(){
    
// 	var current_fs, next_fs, previous_fs; //fieldsets
// 	var opacity;
	
// 	$(".next").click(function(){
		
// 		current_fs = $(this).parent();
// 		next_fs = $(this).parent().next();
		
// 		//Add Class Active
// 		$("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
		
// 		//show the next fieldset
// 		next_fs.show(); 
// 		//hide the current fieldset with style
// 		current_fs.animate({opacity: 0}, {
// 			step: function(now) {
// 				// for making fielset appear animation
// 				opacity = 1 - now;
	
// 				current_fs.css({
// 					'display': 'none',
// 					'position': 'relative'
// 				});
// 				next_fs.css({'opacity': opacity});
// 			}, 
// 			duration: 600
// 		});
// 	});
	
// 	$(".previous").click(function(){
		
// 		current_fs = $(this).parent();
// 		previous_fs = $(this).parent().prev();
		
// 		//Remove class active
// 		$("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");
		
// 		//show the previous fieldset
// 		previous_fs.show();
	
// 		//hide the current fieldset with style
// 		current_fs.animate({opacity: 0}, {
// 			step: function(now) {
// 				// for making fielset appear animation
// 				opacity = 1 - now;
	
// 				current_fs.css({
// 					'display': 'none',
// 					'position': 'relative'
// 				});
// 				previous_fs.css({'opacity': opacity});
// 			}, 
// 			duration: 600
// 		});
// 	});
	
// 	$('.radio-group .radio').click(function(){
// 		$(this).parent().find('.radio').removeClass('selected');
// 		$(this).addClass('selected');
// 	});
	
// 	$(".submit").click(function(){
// 		return false;
// 	})
		
// 	});

export default function BusinessTab() {
    
  // const theme = theme => ({
  //   ...theme,
  //   colors: {
  //     ...theme.colors,
  //     primary25: "#FACB30",
  //     primary: "#FACB30",
  //   }
  // });
  // const options = [
  //       { value: 'chocolate', label: 'Chocolate' },
  //       { value: 'strawberry', label: 'Strawberry' },
  //       { value: 'vanilla', label: 'Vanilla' }
  //     ]

  return (
    <div>
    {/* <div className="w-780 mb-4 align-items-center mx-auto d-flex justify-content-between">
      <span className="d-flex align-items-center justify-content-center">
        <button className="buttonArrow me-3 border-0 bg br-10">
          <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
        </button>
        <button className="buttonArrow bg border-0 br-10">
          <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
        </button>
      </span>
      <ButtonBox boxwidth="w-110 mt-0 mb-4" clicked="Saved" name="Save" />
    </div>
    <div className="factorTab w-780 text-start d-block br-20 position-relative mx-auto mb-5">
      <NavItem as="li"nk to="" className="VideoIcon">
          <img src={Video} className="lightImg" alt="" />
          <img src={Video1} className="darkImg" alt="" />
      </Link>
      <h3 className="mb-4">Enter Your Profile Details</h3>
      <div>
      <div class="container-fluid">
    <div class="row justify-content-center mt-0">
        <div class="col-12 px-0">
            <div class="card bg-transparent border-0 px-0 pt-4 pb-0 mb-3">
                <div class="row">
                    <div class="col-md-12 mx-0 px-0">
                        <form>
                        <div className="ProgressBar">
                          <ul>
                              <NavItem as="li" class="active" id="profile"></NavItem>
                              <NavItem as="li" class="active" id="chat"></NavItem>
                              <NavItem as="li" id="location"></NavItem>
                              <NavItem as="li" id="user"></NavItem>
                              <NavItem as="li" id="briefcase"></NavItem>
                              <NavItem as="li" id="graduation-cap"></NavItem>
                              <NavItem as="li" id="favorite"></NavItem>
                              <NavItem as="li" id="award"></NavItem>
                              <NavItem as="li" id="connectivity"></NavItem>
                              <NavItem as="li" id="lock"></NavItem>
                              <NavItem as="li" id="eye"></NavItem>
                          </ul>
                        </div>
                            <fieldset>
                              <BasicInfoTab />
                              <div className="mt-4 text-center">
                                <ButtonBox boxwidth="w-140 mt-0 mb-50 mx-auto" name="next" class="next action-button" value="Save/Next" clicked="Saved" />
                              </div>
                                {/* <input type="button" name="next" class="next action-button" value="Next Step"/> */}
                            {/* </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
   </div>
    </div> */}
  </div>
   
  )
}
