import React from "react";
import ButtonBox from "../../../global/ButtonBox";
import { useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faMusic, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import './EmailSignatureStep.scss';
import { NavItem } from 'react-bootstrap';
import DropdownBox from '../../../global/DropdownBox';

export default function EmailSignatureForm() {
    const { t } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });

    const openSelect1 = () => { inputRef1.current.click(); }
    const inputRef1 = React.useRef();
    const openSelect2 = () => { inputRef2.current.click(); }
    const inputRef2 = React.useRef();
    const openSelect3 = () => { inputRef3.current.click(); }
    const inputRef3 = React.useRef();
    const openSelect4 = () => { inputRef4.current.click(); }
    const inputRef4 = React.useRef();
    const openSelect5 = () => { inputRef5.current.click(); }
    const inputRef5 = React.useRef();
    const openSelect6 = () => { inputRef6.current.click(); }
    const inputRef6 = React.useRef();
    const openSelect7 = () => { inputRef7.current.click(); }
    const inputRef7 = React.useRef();
    const openSelect8 = () => { inputRef8.current.click(); }
    const inputRef8 = React.useRef();
    const openSelect9 = () => { inputRef9.current.click(); }
    const inputRef9 = React.useRef();
    const openSelect10 = () => { inputRef10.current.click(); }
    const inputRef10 = React.useRef();
    const openSelect11 = () => { inputRef11.current.click(); }
    const inputRef11 = React.useRef();
    const openSelect12 = () => { inputRef12.current.click(); }
    const inputRef12 = React.useRef();
    const openSelect13 = () => { inputRef13.current.click(); }
    const inputRef13 = React.useRef();

    const [previewImage1, setPreviewImage1] = useState('');
  const [previewImage2, setPreviewImage2] = useState('');
  const [previewImage3, setPreviewImage3] = useState('');
  const [previewImage4, setPreviewImage4] = useState('');
  const [previewImage5, setPreviewImage5] = useState('');
  const [previewImage6, setPreviewImage6] = useState('');
  const [previewImage7, setPreviewImage7] = useState('');
  const [previewImage8, setPreviewImage8] = useState('');
  const [previewImage9, setPreviewImage9] = useState('');
  const [previewImage10, setPreviewImage10] = useState('');
  const [previewImage11, setPreviewImage11] = useState('');
  const [previewImage12, setPreviewImage12] = useState('');
  const [previewImage13, setPreviewImage13] = useState('');


    const [ setImage1] = useState('');
    const [ setImage2] = useState('');
    const [ setImage3] = useState('');
    const [ setImage4] = useState('');
    const [ setImage5] = useState('');
    const [ setImage6] = useState('');
    const [ setImage7] = useState('');
    const [ setImage8] = useState('');
    const [ setImage9] = useState('');
    const [ setImage10] = useState('');
    const [ setImage11] = useState('');
    const [ setImage12] = useState('');
    const [ setImage13] = useState('');

    // const Address_options = [
    //     {
    //         value: "BILLING ADDRESS",
    //         label: "BILLING ADDRESS",
    //     },
    //     {
    //         value: "SHIPPING ADDRESS",
    //         label: "SHIPPING ADDRESS",
    //     },
    //     {
    //         value: "SHIPPING ADDRESS",
    //         label: "SHIPPING ADDRESS",
    //     },
    //     {
    //         value: "HOME ADDRESS",
    //         label: "HOME ADDRESS",
    //     },
    // ];

    const addressOptions =
    [{
        "name": "BILLING ADDRESS",
      }, {
        "name": "SHIPPING ADDRESS",
      }, {
        "name": "SHIPPING ADDRESS",
      }, {
        "name": "HOME ADDRESS",
      }
    ] 

    // const quote_options = [
    //     {
    //         value: "Favorite Quote  #1",
    //         label: "Favorite Quote  #1",
    //     },
    //     {
    //         value: "Favorite Quote  #2",
    //         label: "Favorite Quote  #2",
    //     },
    //     {
    //         value: "Favorite Quote  #3",
    //         label: "Favorite Quote  #3",
    //     },
    //     {
    //         value: "Favorite Quote  #4",
    //         label: "Favorite Quote  #4",
    //     },
    // ];
    const cityOptions =
      [{
          "name": "Favorite Quote  #1",
        }, {
          "name": "Favorite Quote  #2",
        }, {
          "name": "Favorite Quote  #3",
        }, {
          "name": "Favorite Quote  #4",
        }
      ] 
    return (
        <div>
            <ul className="TAB-7-form">
                <div className="row">
                    <div className="col-lg-6">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('first_name')}</label>
                            <input placeholder={t('timeline_form_seven_component.dyna')} className='shadow-5in border-5 br-10  mb-0 me-3' type="text"></input>

                        </NavItem>
                    </div>
                    <div className="col-lg-6">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('last_name')}</label>
                            <input placeholder={t('timeline_form_seven_component.appolon')} className=' border-5 shadow-5in br-10  mb-0 me-3' type="text"></input>

                        </NavItem>
                    </div>
                    <div className="col-lg-6">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_seven_component.company_name_label')}</label>
                            <input placeholder={t('timeline_form_seven_component.company_name_placeholder')} className='shadow-5in border-5 br-10  mb-0 me-3' type="text"></input>

                        </NavItem>
                    </div>

                    <div className="col-lg-6">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_seven_component.industry_name_label')}</label>
                            <input placeholder={t('timeline_form_seven_component.industry_name_placeholder')} className='shadow-5in border-5 br-10  mb-0 me-3' type="text"></input>

                        </NavItem>
                    </div>
                    <div className="col-lg-6">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_seven_component.job_title_label')}</label>
                            <input placeholder={t('timeline_form_seven_component.job_title_placeholder')} className=' border-5 shadow-5in br-10  mb-0 me-3' type="text"></input>

                        </NavItem>
                    </div>
                    <div className="col-lg-6">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_seven_component.department_label')}</label>
                            <input placeholder={t('timeline_form_seven_component.department_placeholder')} className='shadow-5in border-5 br-10  mb-0 me-3' type="text"></input>

                        </NavItem>
                    </div>
                    <div className="col-lg-12">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_seven_component.mission_statement_label')}</label>
                            <input placeholder={t('timeline_form_seven_component.mission_statement_placeholder')} className=' border-5 shadow-5in br-10  mb-0 me-3' type="text"></input>
                        </NavItem>
                    </div>
                    <div className="col-lg-12">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_seven_component.slogan_label')}</label>
                            <input placeholder={t('timeline_form_seven_component.slogan_placeholder')} className=' border-5 shadow-5in br-10  mb-0 me-3' type="text"></input>
                        </NavItem>
                    </div>
                    <div className="col-lg-12 first-field">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_seven_component.your_favorite_quote_label')}</label>
                            {/* <input placeholder={t('timeline_form_seven_component.your_favorite_quote_placeholder')} className=' border-5 shadow-5in br-40  mb-0 me-3' type="text"></input> */}
                            <div className=" position-relative">
                                        {/* <Select isSearchable={false}
                                            options={quote_options}
                                            className="country mb-4 w-100 mx-auto text-center"
                                            placeholder={t('timeline_form_seven_component.your_favorite_quote_placeholder')}
                                            theme={theme}
                                            styles={{
                                                option: (base) => ({
                                                    ...base,
                                                    borderRadius: "30px",
                                                    width: "94%",
                                                    maxheight: "50px",
                                                    lineHeight: "32px",
                                                    margin: "10px",
                                                    boxShadow: "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                                    cursor: 'pointer',
                                                }),
                                            }}
                                        /> */}
                                        <DropdownBox name="Favorite Quote" boxid="favoritequote" options={cityOptions} boxwidth="w-100 my-5 mx-auto"/>
                                    </div>
                        </NavItem>
                    </div>
                    <NavItem as="li" className="mb-4">
                        <div className="row">
                        <div className="custom-form-group col-md-4">
                            <div className="custom-file-drop-area ">
                                <div className="imgView">
                                    <div className="mx-auto mb-4 fileTab h-100">
                                        <label onClick={openSelect1} className="w-100 h-100">
                                            <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                                                {previewImage1 ? (
                                                    <img
                                                        src={previewImage1}
                                                        alt=""
                                                        className="w-100 h-100"
                                                    />
                                                ) : (
                                                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                                        <FontAwesomeIcon icon={faUser} className=" icon" />
                                                        <p className="mb-0 mt-1">Drag and Drop
                                                            <br />
                                                            OR
                                                            <br />
                                                            <span className="font-weight-bold text-decoration-underline">Upload Picture here</span></p>
                                                    </div>
                                                )}
                                            </div>
                                        </label>
                                    </div>
                                    <input
                                        hidden
                                        type="file"
                                        name="profile"
                                        id="profile"
                                        accept="image/*"
                                        onChange={(e) => {
                                            let url = URL.createObjectURL(e.target.files[0]);
                                            setPreviewImage1(url);
                                            setImage1(e.target.files[0]);
                                        }}
                                        ref={inputRef1}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="custom-form-group col-md-4">
                            <div className="custom-file-drop-area ">
                                <div className="imgView">
                                    <div className="mx-auto mb-4 fileTab h-100">
                                        <label onClick={openSelect2} className="w-100 h-100">
                                            <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                                                {previewImage2 ? (
                                                    <img
                                                        src={previewImage2}
                                                        alt=""
                                                        className="w-100 h-100"
                                                    />
                                                ) : (
                                                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                                        <FontAwesomeIcon icon={faMusic} className=" icon" />
                                                        <p className="mb-0 mt-1">Drag and Drop
                                                            <br />
                                                            OR
                                                            <br />
                                                            <span className="font-weight-bold text-decoration-underline">Upload Logo here</span></p>
                                                    </div>
                                                )}
                                            </div>
                                        </label>
                                    </div>
                                    <input
                                        hidden
                                        type="file"
                                        name="profile"
                                        id="profile"
                                        accept="image/*"
                                        onChange={(e) => {
                                            let url = URL.createObjectURL(e.target.files[0]);
                                            setPreviewImage2(url);
                                            setImage2(e.target.files[0]);
                                        }}
                                        ref={inputRef2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="custom-form-group col-md-4">
                            <div className="custom-file-drop-area ">
                                <div className="imgView">
                                    <div className="mx-auto mb-4 fileTab h-100">
                                        <label onClick={openSelect3} className="w-100 h-100">
                                            <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                                                {previewImage3 ? (
                                                    <img
                                                        src={previewImage3}
                                                        alt=""
                                                        className="w-100 h-100"
                                                    />
                                                ) : (
                                                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                                        <FontAwesomeIcon icon={faCloudUploadAlt} className=" icon" />
                                                        <p className="mb-0 mt-1">Drag and Drop
                                                            <br />
                                                            OR
                                                            <br />
                                                            <span className="font-weight-bold text-decoration-underline">Upload Icon here</span></p>
                                                    </div>
                                                )}
                                            </div>
                                        </label>
                                    </div>
                                    <input
                                        hidden
                                        type="file"
                                        name="profile"
                                        id="profile"
                                        accept="image/*"
                                        onChange={(e) => {
                                            let url = URL.createObjectURL(e.target.files[0]);
                                            setPreviewImage3(url);
                                            setImage3(e.target.files[0]);
                                        }}
                                        ref={inputRef3}
                                    />
                                </div>
                            </div>
                        </div>
                        </div>
                    </NavItem>
                    <div className="uploadSocial my-5">
        <label className="mb-3 fw-medium">{t('timeline_form_seven_component.upload_custom_icon_label')}</label>
        <ul className="d-flex flex-wrap justify-content-between mx-3">
          <NavItem as="li" className="me-1">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect4} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage4 ? <img src={previewImage4} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage4(url);
                setImage4(e.target.files[0])
              }} ref={inputRef4} />
            </div>
            Facebook
          </NavItem>
          <NavItem as="li" className="mx-1">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect5} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage5 ? <img src={previewImage5} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage5(url);
                setImage5(e.target.files[0])
              }} ref={inputRef5} />
            </div>
            Twitter
          </NavItem>
          <NavItem as="li" className="mx-1">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect6} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage6 ? <img src={previewImage6} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage6(url);
                setImage6(e.target.files[0])
              }} ref={inputRef6} />
            </div>
            Instagram
          </NavItem>
          <NavItem as="li" className="mx-1">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect7} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage7 ? <img src={previewImage7} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage7(url);
                setImage7(e.target.files[0])
              }} ref={inputRef7} />
            </div>
            Linked In
          </NavItem>
          <NavItem as="li" className="mx-1">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect8} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage8 ? <img src={previewImage8} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage8(url);
                setImage8(e.target.files[0])
              }} ref={inputRef8} />
            </div>
            Twitch
          </NavItem>
          <NavItem as="li" className="mx-1">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect9} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage9 ? <img src={previewImage9} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage9(url);
                setImage9(e.target.files[0])
              }} ref={inputRef9} />
            </div>
            Tiktok
          </NavItem>
          <NavItem as="li" className="mx-1">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect10} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage10 ? <img src={previewImage10} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage10(url);
                setImage10(e.target.files[0])
              }} ref={inputRef10} />
            </div>
            Youtube
          </NavItem>
          <NavItem as="li" className="mx-1">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect11} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage11 ? <img src={previewImage11} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage11(url);
                setImage11(e.target.files[0])
              }} ref={inputRef11} />
            </div>
            Pinterest
          </NavItem>
          <NavItem as="li" className="mx-1">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect12} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage12 ? <img src={previewImage12} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage12(url);
                setImage12(e.target.files[0])
              }} ref={inputRef12} />
            </div>
            Snapchat
          </NavItem>
          <NavItem as="li" className="ms-1">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect13} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage13 ? <img src={previewImage13} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage13(url);
                setImage13(e.target.files[0])
              }} ref={inputRef13} />
            </div>
            Discord
          </NavItem>
        </ul>
      </div>
                    <div className="col-lg-6">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_seven_component.email_address')}</label>
                            <input placeholder={t('timeline_form_seven_component.email_address_placeholder')} className=' border-5 shadow-5in br-10  mb-0 me-3' type="email"></input>
                        </NavItem>
                    </div>
                    <div className="col-lg-6">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_seven_component.website')}</label>
                            <input placeholder={t('timeline_form_seven_component.website_placeholder')} className=' border-5 shadow-5in br-10  mb-0 me-3' type="text"></input>
                        </NavItem>
                    </div>
                    <div className="col-lg-6">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_seven_component.phone')}</label>
                            <input placeholder={t('timeline_form_seven_component.phone_placeholder')} className=' border-5 shadow-5in br-10 mt-4  mb-0 me-3' type="text"></input>
                        </NavItem>
                    </div>
                    <div className="col-lg-6 first-field">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_seven_component.business_address')}</label>
                            {/* <input placeholder={t('timeline_form_seven_component.business_address_placeholder')} className=' border-5 shadow-5in br-40  mb-0 me-3' type="text"></input> */}
                            <div className="position-relative">
                                        {/* <Select isSearchable={false}
                                            options={Address_options}
                                            className="country mb-4 w-100 mx-auto text-center"
                                            placeholder={t('timeline_form_seven_component.business_address_placeholder')}
                                            theme={theme}
                                            styles={{
                                                option: (base) => ({
                                                    ...base,
                                                    borderRadius: "30px",
                                                    width: "94%",
                                                    maxheight: "50px",
                                                    lineHeight: "32px",
                                                    margin: "10px",
                                                    boxShadow: "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                                    cursor: 'pointer',
                                                }),
                                            }}
                                        /> */}
                                       <DropdownBox name="Business Address" boxid="businessaddress" options={addressOptions} boxwidth="w-100 mt-4 mx-auto"/>
                                    </div>
                        </NavItem>
                    </div>
                </div>

                {/*            
                <NavItem as="li" className="mb-5 border-box">
                  
                </NavItem> */}
                <NavItem as="li" className=" text-center time-btn mt-4">

                    <ButtonBox
                        boxwidth="w-150 mt-0 mb-50 mx-auto"
                        clicked="Save/Next"
                        name="Save/Next"
                    />
                </NavItem>
            </ul>
        </div >
    );
}
