import React, { useState } from "react";
import img1 from '../../../../images/StepZero/img2s1.png';
import img2 from '../../../../images/StepZero/img2s2.png';
import img3 from '../../../../images/StepZero/img2s3.png';
import img4 from '../../../../images/StepZero/img2s4.png';
import img5 from '../../../../images/StepZero/img68.png';
import imgarrow from '../../../../images/triangle1.png';

import NicheSix from "../../profileManagement/tabs/StepZero/Nicheform/NicheSix";
import NicheSeven from "../../profileManagement/tabs/StepZero/Nicheform/NicheSeven";
import NicheSixSec from "../../profileManagement/tabs/StepZero/Nicheform/NicheSixSec";
export default function MarketResearchSizeValidationForm() {
  const handleClick1 = () => {
    setToggle1(!toggle1);
  };
  const [toggle1, setToggle1] = useState("true");

  const handleClick = () => {
    setToggle(!toggle);
  };
  const [toggle, setToggle] = useState("true");

  const handleClick2 = () => {
    setToggle2(!toggle2);
  };
  const [toggle2, setToggle2] = useState("true");

  const handleClick3 = () => {
    setToggle3(!toggle3);
  };
  const [toggle3, setToggle3] = useState("true");

  const [valueFromComponentA, setValueFromComponentA] = useState('');

  const handleValueChange = (newValue) => {
    // Receive the value from Component A and set it in the parent component
    setValueFromComponentA(newValue);
  };
  return (
    <div className="nicheClass1 mx-auto">
      <ul className='w-100 nichul'>
        <li className='d-flex flex-column pe-4 nichli'>
          <div className='d-flex align-items-start'>
            <div className="d-flex pt-6 pb-4">
              <div className='d-flex mb-5 pt-5'>
                <button onClick={handleClick} className='px-3 border-0 bg-transparent active' id="housekeep" data-bs-toggle="tab" data-bs-target="#housekeep" type="button" role="tab" aria-controls="housekeep" aria-selected="true">
                  <img src={img1} alt="" height="110" width='119' className='firstimg' />
                </button>
              </div>
            </div>
          </div>
          {toggle ? (
            <div className="borderTop1 borderTop4">
              <div className="borderTop">
                <NicheSix onValueChange={handleValueChange} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </li>
        <li className="nichli topsix1">
          <div className='d-flex align-items-end justify-content-end'>
            <div className="d-flex pt-6 pb-5">
              <div className='d-flex mb-5 py-5'>
                <button onClick={handleClick1} className='px-3 border-0 bg-transparent active' id="housekeep" data-bs-toggle="tab" data-bs-target="#housekeep" type="button" role="tab" aria-controls="housekeep" aria-selected="true">
                  <img src={img2} alt="" height="110" width='119' className='firstimg' />
                </button>
              </div>
            </div>
          </div>
          {toggle1 ? (
            <div className="borderTop5">
              <div className="borderTop">
                <NicheSixSec activeColor={valueFromComponentA} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </li>
        <li className="nichli">
          <div className='d-flex align-items-start'>
            <div className="d-flex pt-6 pb-4">
              <div className='d-flex mb-5 py-5'>
                <button onClick={handleClick2} className='px-3 border-0 bg-transparent active' id="housekeep" data-bs-toggle="tab" data-bs-target="#housekeep" type="button" role="tab" aria-controls="housekeep" aria-selected="true">
                  <img src={img3} alt="" height="110" width='119' className='firstimg' />
                </button>
              </div>
            </div>
          </div>
          {toggle2 ? (
            <div className="borderTop1">
              <div className="borderTop">
                <NicheSeven />
                <span className="cursor-pointer sixarrow" onClick={handleClick1}><img src={imgarrow} alt="" width="40px" height="20px" /></span>
                {toggle1 ? (
                  <></>
                ) : (
                  <div className="w-100 d-flex flex-column-reverse px-5">
                    <div className='container privacyTab1 privacyTab3s px-5 mb-3 pb-3 mt-4'>
                      <div id="myTab" className='slidetab' role="tablist">
                        <div className="d-flex justify-content-between pb-3">
                          <div className='px-2 btntb1'>
                            <button className="br-40 w-100 h-100 border-0 active" id="market3" data-bs-toggle="tab" data-bs-target="#market3" type="button" role="tab" aria-controls="market3" aria-selected="true">(TAM) TOTAL AVAILABLE MARKET</button>
                          </div>
                          <div className='px-2 btntb1'>
                            <button className="br-40 w-100 h-100 border-0" id="market4" data-bs-toggle="tab" data-bs-target="#market4" type="button" role="tab" aria-controls="market4" aria-selected="true">(SAM) SERVICEABLE AVAILABLE MARKET</button>
                          </div>
                          <div className='px-2 btntb1'>
                            <button className="br-40 w-100 h-100 border-0" id="market5" data-bs-toggle="tab" data-bs-target="#market5" type="button" role="tab" aria-controls="market5" aria-selected="false">(SOM) SERVICEABLE OBTAINABLE MARKET</button>
                          </div>
                          <div className='px-2 btntb1'>
                            <button className="br-40 w-100 h-100 border-0" id="market6" data-bs-toggle="tab" data-bs-target="#market6" type="button" role="tab" aria-controls="market6" aria-selected="false">(EVG) EARLYVANGELISTS</button>
                          </div>
                          <div className='px-2 btntb1'>
                            <button className="br-40 w-100 h-100 border-0" id="otherg7" data-bs-toggle="tab" data-bs-target="#otherg7" type="button" role="tab" aria-controls="otherg7" aria-selected="false">other</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </li>
        <li className="nichli">
          <div className='d-flex align-items-end justify-content-end'>
            <div className="d-flex pt-6 pb-5">
              <div className='d-flex mb-5 py-5'>
                <button onClick={handleClick3} className='px-3 border-0 bg-transparent active' id="housekeep" data-bs-toggle="tab" data-bs-target="#housekeep" type="button" role="tab" aria-controls="housekeep" aria-selected="true">
                  <img src={img4} alt="" height="110" width='119' className='firstimg' />
                </button>
              </div>
            </div>
          </div>
          {toggle3 ? (
            <div className="borderTop5">
              <div className="borderTop">
                <img src={img5} alt="" className="w-100" />
              </div>
            </div>
          ) : (
            <></>
          )}
        </li>
        <li className="nichli">
        </li>
      </ul>
    </div>
  )
}
