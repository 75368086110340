import React from 'react'
import timeline from "../../../../../../../../images/how-it-work/timeline.png"
import timeline_2 from "../../../../../../../../images/how-it-work/timeline-2.png"
import timeline_3 from "../../../../../../../../images/how-it-work/timeline-3.png"
export default function StepsTimeline() {
  return (
    <div className="steps-tab-timeline-wrapper">
    <div className="steps-timeline-img-wrapper">
      <img className="regular-timeline" src={timeline} alt="timeline-1"></img>
      <img className="neumorphic-timeline" src={timeline_2} alt="timeline-2"></img>
      <img className="glassmorphic-timeline" src={timeline_3} alt="timeline-3"></img>
      <div className="steps-timeline-content">
        <div className="steps-timeline-content-inner">
          <div className="step-counter">
            1
          </div>
          <span className="step-heading">
          Make a decision
          </span>
          <p className="steps-timeline">Lorem ipsum dolor sit amet, mea ei viderer probatus conse quuntu.</p>
        </div>
        <div className="steps-timeline-content-inner">
          <div className="step-counter">
            2
          </div>
          <span className="step-heading">
          Schedule a meeting
          </span>
          <p className="steps-timeline">Lorem ipsum dolor sit amet, mea ei viderer probatus conse quuntu.</p>
        </div>
        <div className="steps-timeline-content-inner">
          <div className="step-counter">
            3
          </div>
          <span className="step-heading">
          Show Commitment
          </span>
          <p className="steps-timeline">Lorem ipsum dolor sit amet, mea ei viderer probatus conse quuntu.</p>
        </div>
        <div className="steps-timeline-content-inner">
          <div className="step-counter">
            4
          </div>
          <span className="step-heading">
          Transformation
          </span>
          <p className="steps-timeline">Lorem ipsum dolor sit amet, mea ei viderer probatus conse quuntu.</p>
        </div>
        <div className="steps-timeline-content-inner">
          <div className="step-counter">
            5
          </div>
          <span className="step-heading">
          Make a decision
          </span>
          <p className="steps-timeline">Lorem ipsum dolor sit amet, mea ei viderer probatus conse quuntu.</p>
        </div>
        <div className="steps-timeline-content-inner">
          <div className="step-counter">
            5
          </div>
          <span className="step-heading">
          Schedule a meeting
          </span>
          <p className="steps-timeline">Lorem ipsum dolor sit amet, mea ei viderer probatus conse quuntu.</p>
        </div>
      </div>
  </div>
  </div>
  )
}
