import React, { useState } from "react";
import img2 from '../../../../../../images/StepZero/img14.png';
import img3 from '../../../../../../images/StepZero/img15.png';
import img4 from '../../../../../../images/StepZero/img16.png';
import img5 from '../../../../../../images/StepZero/img19.png';
import img6 from '../../../../../../images/StepZero/img20.png';
import img7 from '../../../../../../images/StepZero/call.png';
import img8 from '../../../../../../images/StepZero/close.png';
import img9 from '../../../../../../images/StepZero/img21.png';
import img10 from '../../../../../../images/StepZero/img22.png';
import img11 from '../../../../../../images/StepZero/img23.png';
import img12 from '../../../../../../images/StepZero/img24.png';
import img13 from '../../../../../../images/StepZero/img25.png';
import img14 from '../../../../../../images/StepZero/img26.png';
import img15 from '../../../../../../images/StepZero/img27.png';
import img16 from '../../../../../../images/StepZero/img51.png';
import img1s from '../../../../../../images/triangle1.png';
import Video from '../../../../../../images/iconVid.png';
import Video1 from '../../../../../../images/iconVid1.png';
import img2s from '../../../../../../images/close1.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../../../redux/actions/videoAction';
export default function NicheTwo() {

  const [value, setValue] = useState('')
  const [countryCode, setCountryCode] = useState(1)
  const [areaCode, setAreaCode] = useState(902)
  const [exchange, setExchange] = useState(516)
  const [lineNumber, setLineNumber] = useState(6643)
  const getValue = (val) => {
    let updatedNumber = value + val;
    if (updatedNumber.length === 1 || updatedNumber.length === 5 || updatedNumber.length === 9) {
      updatedNumber += '-';
    }

    setValue(updatedNumber);
    // setValue((prevNumber) => prevNumber + val);
  }

  const handleClearClick = () => {
    // Clear the entered number
    // setValue('');
    setValue((prevNumber) => prevNumber.slice(0, -1));
  };

  const handleClick1 = () => {
    setToggle1(!toggle1);
  };
  const [toggle1, setToggle1] = useState("true");

  let { isVideoVisible } = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };

  return (
    <div className="d-flex justify-content-center flex-column align-items-center mt-4 mb-120">
      <div className="formleft d-flex flex-wrap ps-3 formheight">
        <div className="row mx-0 pe-5">
          <div className="col-lg-8 px-0 w-63">
            <div className="d-flex">
              <img src={img16} alt="" height="140px" />
              <h3 className="mb-4 me-auto ms-5">1-902-516-6643</h3>
            </div>
            <span className="w-100 shadow-5in p-4 mt-4">
              <div className="d-flex justify-content-between align-items-start w-100">
                <div className="onetag tagleft">
                  <h6>Country Code</h6>
                  <img src={img3} alt="" />
                  <h4>{countryCode}</h4>
                </div>
                <div className="twotag tagleft pt-60">
                  <h4>{areaCode}</h4>
                  <img src={img2} alt="" />
                  <h6>Area Code</h6>
                </div>
                <div className="threetag tagleft">
                  <h6>Exchange</h6>
                  <img src={img4} alt="" />
                  <h4>{exchange}</h4>
                </div>
                <div className="fourtag tagleft pt-60">
                  <h4>{lineNumber}</h4>
                  <img src={img4} alt="" />
                  <h6>Line Number</h6>
                </div>
              </div>
            </span>
          </div>
          <div className="col-lg-4 px-0">
            <div className="calculter gradient-box2 position-relative">
              <div className="position-absolute top-0 end-0 caltop pt-3 pe-1 d-flex flex-column align-items-end">
                <Link to="" className="linkbtn">
                  <img src={img13} alt="" width="18px" height="18px" />
                </Link>
                <Link to="" className="linkbtn">
                  <img src={img14} alt="" width="18px" height="18px" />
                </Link>
                <Link to="" className="linkbtn">
                  <img src={img15} alt="" />
                </Link>
                <div onClick={handleClearClick}>
                  <img src={img8} alt="" width="40px" height="40px" />
                </div>
              </div>
              <span className="d-flex flex-column justify-content-start align-items-center py-1">
                <img src={img5} alt="" width="66px" height="66px" className="mb-1" />
                <img src={img6} alt="" width="114px" height="26px" />
              </span>
              {/* <h4 className="mt-2 mb-4">1-602-566-6600</h4> */}
              <h4 className="mt-2 mb-4">{value}</h4>
              <div className="caliner w-100">
                <div className="calbtn d-flex justify-content-between flex-wrap">
                  <Link to="" className="gradient-box1" onClick={() => getValue("1")}>
                    <b >1</b>
                  </Link>
                  <Link to="" className="gradient-box1" onClick={() => getValue("2")}>
                    <b >2</b>
                    <strong>ABC</strong>
                  </Link>
                  <Link to="" className="gradient-box1" onClick={() => getValue("3")}>
                    <b >3</b>
                    <strong>DEF</strong>
                  </Link>
                  <Link to="" className="gradient-box1" onClick={() => getValue("4")}>
                    <b >4</b>
                    <strong>GHI</strong>
                  </Link>
                  <Link to="" className="gradient-box1" onClick={() => getValue("5")}>
                    <b>5</b>
                    <strong>JKL</strong>
                  </Link>
                  <Link to="" className="gradient-box1" onClick={() => getValue("6")}>
                    <b >6</b>
                    <strong>MNO</strong>
                  </Link>
                  <Link to="" className="gradient-box1" onClick={() => getValue("7")}>
                    <b >  7</b>
                    <strong>PQRS</strong>
                  </Link>
                  <Link to="" className="gradient-box1" onClick={() => getValue("8")}>
                    <b >8</b>
                    <strong>TUV</strong>
                  </Link>
                  <Link to="" className="gradient-box1" onClick={() => getValue("9")}>
                    <b >9</b>
                    <strong>WXYZ</strong>
                  </Link>
                  <Link to="" className="gradient-box1" onClick={() => getValue("*")}>
                    <b >*</b>
                  </Link>
                  <Link to="" className="gradient-box1">
                    <b onClick={() => getValue("0")}>0</b>
                    <strong onClick={() => getValue("+")}>+</strong>
                  </Link>
                  <Link to="" className="gradient-box1" onClick={() => getValue("#")}>
                    <b >#</b>
                  </Link>
                </div>
                <div className="d-flex justify-content-center">
                  <Link to="" className=" mt-2">
                    <img src={img7} alt="" width="53px" height="45px" />
                  </Link>
                </div>
              </div>
              <div className="calculaterbtm d-flex justify-content-center">
                <img src={img9} alt="" width="60px" height="60px" />
                <img src={img10} alt="" width="60px" height="60px" />
                <img src={img11} alt="" width="60px" height="60px" />
                <img src={img12} alt="" width="60px" height="60px" />
              </div>
              <div className="linbtm"></div>
            </div>
          </div>
        </div>
      </div>
      <span className="cursor-pointer" onClick={handleClick1} ><img src={img1s} alt="" width="40px" height="20px" /></span>
      {toggle1 ? (
        <></>
      ) : (
        <div className="w-100 d-flex flex-column-reverse my-5 pt-5">
          <div className='w-100'>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="manage1" role="tabpanel" aria-labelledby="manage1">
                <div className="shadow-4 d-block pt-150 br-30 position-relative factorTab1s">
                  <div className="text-end d-flex">
                    <div className="VideoIcon ms-auto" onClick={handleClick6}>
                      <img src={Video} className="lightImg" width="130px" height="80px" alt="" />
                      <img src={Video1} className="darkImg" width="130px" height="80px" alt="" />
                    </div>
                    <img src={img2s} alt="" width="40px" height="52px" className="mt-87 cursor-pointer me-3 position-absolute end-0 top-0" onClick={handleClick1} />
                  </div>
                  <div className="formleft d-flex flex-wrap ps-3 formheight">
                    <div className="row mx-0 pe-5">
                      <div className="col-lg-8 px-0 w-63">
                        <div className="d-flex">
                          <img src={img16} alt="" height="140px" />
                          <h3 className="mb-4 me-auto ms-5">1-902-516-6643</h3>
                        </div>
                        <span className="w-100 shadow-5in p-4 mt-4">
                          <div className="d-flex justify-content-between align-items-start w-100">
                            <div className="onetag tagleft">
                              <h6>Country Code</h6>
                              <img src={img3} alt="" />
                              <input type="text" placeholder="1" value={countryCode} onChange={(e) => setCountryCode(e.target.value)} className="newinput w-90e" />
                            </div>
                            <div className="twotag tagleft pt-60">
                              <input type="text" placeholder="902" value={areaCode} onChange={(e) => setAreaCode(e.target.value)} className="newinput w-150e" />
                              <img src={img2} alt="" />
                              <h6>Area Code</h6>
                            </div>
                            <div className="threetag tagleft">
                              <h6>Exchange</h6>
                              <img src={img4} alt="" />
                              <input type="text" placeholder="516" value={exchange} onChange={(e) => setExchange(e.target.value)} className="newinput w-150e" />
                            </div>
                            <div className="fourtag tagleft pt-60">
                              <input type="text" placeholder="6643" value={lineNumber} onChange={(e) => setLineNumber(e.target.value)} className="newinput w-150e" />
                              <img src={img4} alt="" />
                              <h6>Line Number</h6>
                            </div>
                          </div>
                        </span>
                      </div>
                      <div className="col-lg-4 px-0">
                        <div className="calculter gradient-box2 position-relative">
                          <div className="position-absolute top-0 end-0 caltop pt-3 pe-1 d-flex flex-column align-items-end">
                            <Link to="" className="linkbtn">
                              <img src={img13} alt="" width="18px" height="18px" />
                            </Link>
                            <Link to="" className="linkbtn">
                              <img src={img14} alt="" width="18px" height="18px" />
                            </Link>
                            <Link to="" className="linkbtn">
                              <img src={img15} alt="" />
                            </Link>
                            <div onClick={handleClearClick}>
                              <img src={img8} alt="" width="40px" height="40px" />
                            </div>
                          </div>
                          <span className="d-flex flex-column justify-content-start align-items-center py-1">
                            <img src={img5} alt="" width="66px" height="66px" className="mb-1" />
                            <img src={img6} alt="" width="114px" height="26px" />
                          </span>
                          {/* <h4 className="mt-2 mb-4">1-602-566-6600</h4> */}
                          <h4 className="mt-2 mb-4">{value}</h4>
                          <div className="caliner w-100">
                            <div className="calbtn d-flex justify-content-between flex-wrap">
                              <Link to="" className="gradient-box1" onClick={() => getValue("1")}>
                                <b >1</b>
                              </Link>
                              <Link to="" className="gradient-box1" onClick={() => getValue("2")}>
                                <b >2</b>
                                <strong>ABC</strong>
                              </Link>
                              <Link to="" className="gradient-box1" onClick={() => getValue("3")}>
                                <b >3</b>
                                <strong>DEF</strong>
                              </Link>
                              <Link to="" className="gradient-box1" onClick={() => getValue("4")}>
                                <b >4</b>
                                <strong>GHI</strong>
                              </Link>
                              <Link to="" className="gradient-box1" onClick={() => getValue("5")}>
                                <b>5</b>
                                <strong>JKL</strong>
                              </Link>
                              <Link to="" className="gradient-box1" onClick={() => getValue("6")}>
                                <b >6</b>
                                <strong>MNO</strong>
                              </Link>
                              <Link to="" className="gradient-box1" onClick={() => getValue("7")}>
                                <b >  7</b>
                                <strong>PQRS</strong>
                              </Link>
                              <Link to="" className="gradient-box1" onClick={() => getValue("8")}>
                                <b >8</b>
                                <strong>TUV</strong>
                              </Link>
                              <Link to="" className="gradient-box1" onClick={() => getValue("9")}>
                                <b >9</b>
                                <strong>WXYZ</strong>
                              </Link>
                              <Link to="" className="gradient-box1" onClick={() => getValue("*")}>
                                <b >*</b>
                              </Link>
                              <Link to="" className="gradient-box1">
                                <b onClick={() => getValue("0")}>0</b>
                                <strong onClick={() => getValue("+")}>+</strong>
                              </Link>
                              <Link to="" className="gradient-box1" onClick={() => getValue("#")}>
                                <b >#</b>
                              </Link>
                            </div>
                            <div className="d-flex justify-content-center">
                              <Link to="" className=" mt-2">
                                <img src={img7} alt="" width="53px" height="45px" />
                              </Link>
                            </div>
                          </div>
                          <div className="calculaterbtm d-flex justify-content-center">
                            <img src={img9} alt="" width="60px" height="60px" />
                            <img src={img10} alt="" width="60px" height="60px" />
                            <img src={img11} alt="" width="60px" height="60px" />
                            <img src={img12} alt="" width="60px" height="60px" />
                          </div>
                          <div className="linbtm"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="list1" role="tabpanel" aria-labelledby="list1">

              </div>
              <div className="tab-pane text-center detailTab fade" id="card1" role="tabpanel" aria-labelledby="card1">

              </div>
              <div className="tab-pane text-center detailTab fade" id="sms1" role="tabpanel" aria-labelledby="sms1">

              </div>
              <div className="tab-pane text-center detailTab fade" id="whatsapp1" role="tabpanel" aria-labelledby="whatsapp1">

              </div>
              <div className="tab-pane text-center detailTab fade" id="setting1" role="tabpanel" aria-labelledby="setting1">

              </div>
            </div>
          </div>
          <div className='container privacyTab1 privacyTab3s mb-3 pb-3 mt-4'>
            <div id="myTab" className='slidetab' role="tablist">
              <div className="d-flex justify-content-between pb-3">
                <div className='px-2 btntb1'>
                  <button className="br-40 w-100 h-100 border-0 active" id="manage1" data-bs-toggle="tab" data-bs-target="#manage1" type="button" role="tab" aria-controls="manage1" aria-selected="true">MANAGE BUSINESS PHONE NUMBER</button>
                </div>
                <div className='px-2 btntb1'>
                  <button className="br-40 w-100 h-100 border-0" id="list1" data-bs-toggle="tab" data-bs-target="#list1" type="button" role="tab" aria-controls="list1" aria-selected="true">CONTACT LIST</button>
                </div>
                <div className='px-2 btntb1'>
                  <button className="br-40 w-100 h-100 border-0" id="card1" data-bs-toggle="tab" data-bs-target="#card1" type="button" role="tab" aria-controls="card1" aria-selected="false">CONNECT TO DIGITAL BUSINESS CARD</button>
                </div>
                <div className='px-2 btntb1'>
                  <button className="br-40 w-100 h-100 border-0" id="sms1" data-bs-toggle="tab" data-bs-target="#sms1" type="button" role="tab" aria-controls="sms1" aria-selected="false">SMS campaigns</button>
                </div>
                <div className='px-2 btntb1'>
                  <button className="br-40 w-100 h-100 border-0" id="whatsapp1" data-bs-toggle="tab" data-bs-target="#whatsapp1" type="button" role="tab" aria-controls="whatsapp1" aria-selected="false">WHATSAPP</button>
                </div>
                <div className='px-2 btntb1'>
                  <button className="br-40 w-100 h-100 border-0" id="setting1" data-bs-toggle="tab" data-bs-target="#setting1" type="button" role="tab" aria-controls="setting1" aria-selected="false">SETTINGS</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
