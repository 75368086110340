import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function WeekendModeSectionSlider() {
  const responsive = {
    desktop: {
    breakpoint: { max: 3000, min: 1366 },
    items: 6,
    slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
    breakpoint: { max: 1366, min: 768 },
    items: 5,
    slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
    }
};
  return (
    <Carousel responsive={responsive} autoPlay={false}  autoPlaySpeed={2000} transitionDuration={500}>
       <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 active" id="WeekendMode1" data-bs-toggle="tab" data-bs-target="#WeekendMode1" type="button" role="tab" aria-controls="WeekendMode1" aria-selected="true">Social Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="WeekendMode2" data-bs-toggle="tab" data-bs-target="#WeekendMode2" type="button" role="tab" aria-controls="WeekendMode2" aria-selected="true">Vacation Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="WeekendMode3" data-bs-toggle="tab" data-bs-target="#WeekendMode3" type="button" role="tab" aria-controls="WeekendMode3" aria-selected="false">QR Code Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="WeekendMode4" data-bs-toggle="tab" data-bs-target="#WeekendMode4" type="button" role="tab" aria-controls="WeekendMode4" aria-selected="false">Bio Link Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="WeekendMode5" data-bs-toggle="tab" data-bs-target="#WeekendMode5" type="button" role="tab" aria-controls="WeekendMode5" aria-selected="false">Under Construction Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 react-toastify" id="WeekendMode6" data-bs-toggle="tab" data-bs-target="#WeekendMode6" type="button" role="tab" aria-controls="WeekendMode6" aria-selected="true">Under Maintenance Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 react-toastify" id="WeekendMode7" data-bs-toggle="tab" data-bs-target="#WeekendMode7" type="button" role="tab" aria-controls="WeekendMode7" aria-selected="true">Coming Soon Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 react-toastify" id="WeekendMode8" data-bs-toggle="tab" data-bs-target="#WeekendMode8" type="button" role="tab" aria-controls="WeekendMode8" aria-selected="true">400 Page Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 react-toastify" id="WeekendMode9" data-bs-toggle="tab" data-bs-target="#WeekendMode9" type="button" role="tab" aria-controls="WeekendMode9" aria-selected="true">401 Page Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 react-toastify" id="WeekendMode10" data-bs-toggle="tab" data-bs-target="#WeekendMode10" type="button" role="tab" aria-controls="WeekendMode10" aria-selected="true">402 Page Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 react-toastify" id="WeekendMode11" data-bs-toggle="tab" data-bs-target="#WeekendMode11" type="button" role="tab" aria-controls="WeekendMode11" aria-selected="true">403 Page Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 react-toastify" id="WeekendMode12" data-bs-toggle="tab" data-bs-target="#WeekendMode12" type="button" role="tab" aria-controls="WeekendMode12" aria-selected="true">404 Page Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 react-toastify" id="WeekendMode13" data-bs-toggle="tab" data-bs-target="#WeekendMode13" type="button" role="tab" aria-controls="WeekendMode13" aria-selected="true">500 Page Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 react-toastify" id="WeekendMode14" data-bs-toggle="tab" data-bs-target="#WeekendMode14" type="button" role="tab" aria-controls="WeekendMode14" aria-selected="true">501 Page Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 react-toastify" id="WeekendMode15" data-bs-toggle="tab" data-bs-target="#WeekendMode15" type="button" role="tab" aria-controls="WeekendMode15" aria-selected="true">503 Page Mode</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 react-toastify" id="WeekendMode16" data-bs-toggle="tab" data-bs-target="#WeekendMode16" type="button" role="tab" aria-controls="WeekendMode16" aria-selected="true">504 Page Mode</button>
        </div>
</Carousel>
  )
}