import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import circle_img from '../../../../../../images/circle-shape.png'
import './RegularTheme1Tab.scss';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { IoIosArrowForward } from "react-icons/io";
import { FcCheckmark } from "react-icons/fc"
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai"
import { useTranslation } from "react-i18next";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
// import RegularTheme1Desktop from './regularTheme1\RegularTheme1Desktop/regularTheme1Desktop'

export default function RegularTheme1Tab() {

  const { t, i18n } = useTranslation();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1366 },
      items: 6,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1366, min: 768 },
      items: 5,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  const [Competency1, setCompetency1] = useState(10);

  return (
    <div>
      <div className="">
        <div>
          <div className="main-content p-4">
            <div className="d-flex align-items-center justify-content-center mb-5">
              <div className="toggle_button">
                <button type="" className="btn active_btn">Monthly</button>
                <button type="" className="btn1 ">Yearly</button>
              </div>
            </div>
            <div className="d-flex align-items-center relative">
              <div className="main_row p-4"></div>
              <div className=" px-4 plan_content">
                <div className="flex align-items-center justify-content-center pt-4">
                  <h2 className="mb-3">HOSTING FEATURES</h2>
                  <ul className="px-4">
                    <li>Lorem ipsum dolor</li>
                    <li> Sit amet consect</li>
                    <li>Amet consectetur</li>
                    <li>Neque sed suspend</li>
                  </ul>
                  <ul className="">
                    <li>Se sed diam mattis</li>
                    <li>Ac sagittis ac</li>
                    <li>Sitt isporta vestibulum</li>
                    <li>eu velit viverra quis vel</li>
                  </ul>
                </div>
                <div className="row ">

                  <div className="col-md-4">
                    <div className="plan_outer">
                      <div className="pt-3 px-3 plan_div">
                        <h1 className="text_basic">BASIC</h1>
                      </div>
                      <div>
                        <div className="ribbon">
                          <span className="ribbon_1"></span>
                          <div className="ribbon1">
                            <div className="price">
                              <h2>$29</h2>
                              <p>/Month</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 px-3 py-5 services_list">
                        <ul>
                          <li>
                            <FcCheckmark className="icon" />
                            Lorem ipsum dolor</li>
                          <li>
                            <FcCheckmark className="icon" />
                            Sit amet consect</li>
                          <li>
                            <FcCheckmark className="icon" />
                            Amet consectetur</li>
                          <li>
                            <FcCheckmark className="icon" />
                            Neque sed suspend</li>
                          <li>
                            <RxCross2 className="icon text-danger" />
                            Se sed diam mattis</li>
                          <li>
                            <RxCross2 className="icon text-danger" />
                            Ac sagittis ac
                          </li>
                          <li>
                            <RxCross2 className="icon text-danger" />
                            Sitt isporta vestibulum
                          </li>
                          <li>
                            <RxCross2 className="icon text-danger" />
                            eu velit viverra quis vel
                          </li>
                        </ul>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <button type="" className="signin_btn">
                          <div className="btn_inner basic_btn_inner">
                            <div>
                              <span>
                                Sign up
                              </span>
                              <IoIosArrowForward className="arrow_icon" />
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="plan_outer">
                      <div className="pt-3 px-3 plan_div">
                        <h1 className="text_professional">PROFESSIONAL</h1>
                      </div>
                      <div>
                        <div className="ribbon">
                          <span className="ribbon_2"></span>
                          <div className="ribbon2">
                            <div className="price">
                              <h2>$99</h2>
                              <p>/Month</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 px-3 py-5 services_list">
                        <ul>
                          <li>
                            <FcCheckmark className="icon" />
                            Lorem ipsum dolor</li>
                          <li>
                            <FcCheckmark className="icon" />
                            Sit amet consect</li>
                          <li>
                            <FcCheckmark className="icon" />
                            Amet consectetur</li>
                          <li>
                            <FcCheckmark className="icon" />
                            Neque sed suspend</li>
                          <li>
                            <FcCheckmark className="icon" />
                            Se sed diam mattis</li>
                          <li>
                            <FcCheckmark className="icon" />
                            Ac sagittis ac
                          </li>
                          <li>
                            <RxCross2 className="icon text-danger" />
                            Sitt isporta vestibulum
                          </li>
                          <li>
                            <RxCross2 className="icon text-danger" />
                            eu velit viverra quis vel
                          </li>
                        </ul>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <button type="" className="signin_btn">
                          <div className="btn_inner professional_btn_inner">
                            <div>
                              <span>
                                Sign up
                              </span>
                              <IoIosArrowForward className="arrow_icon" />
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="plan_outer">
                      <div className="pt-3 px-3 plan_div">
                        <h1 className="text_luxury">LUXURY</h1>
                      </div>
                      <div>
                        <div className="ribbon">
                          <span className="ribbon_3"></span>
                          <div className="ribbon3">
                            <div className="price">
                              <h2>$199</h2>
                              <p>/Month</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 px-3 py-5 services_list">
                        <ul>
                          <li>
                            <FcCheckmark className="icon" />
                            Lorem ipsum dolor</li>
                          <li>
                            <FcCheckmark className="icon" />
                            Sit amet consect</li>
                          <li>
                            <FcCheckmark className="icon" />
                            Amet consectetur</li>
                          <li>
                            <FcCheckmark className="icon" />
                            Neque sed suspend</li>
                          <li>
                            <FcCheckmark className="icon" />
                            Se sed diam mattis</li>
                          <li>
                            <FcCheckmark className="icon" />
                            Ac sagittis ac
                          </li>
                          <li>
                            <AiFillStar className="icon text_yellow" />
                            Sitt isporta vestibulum
                          </li>
                          <li>
                            <AiFillStar className="icon text_yellow" />
                            eu velit viverra quis vel
                          </li>
                        </ul>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <button type="" className="signin_btn">
                          <div className="btn_inner luxury_btn_inner">
                            <div>
                              <span>
                                Sign up
                              </span>
                              <IoIosArrowForward className="arrow_icon" />
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





    </div >
  );
}
