import React from 'react'
import IntegAddFrom from '../IntegAddFrom';
import icon18 from "../../../../../../images/formicon/icon18.png";
import icon19 from "../../../../../../images/formicon/icon19.png";
import icon20 from "../../../../../../images/formicon/icon20.png";
import ConnectComponent from './ConnectComponent';
import ConnectComponentInput from './ConnectComponentInput';
export default function GoogleSec() {

  return (

    <div>
      <div className='row'>
        <div className='col-lg-12'>
          <ul className="customDrop6 customDrop1s max-h-auto customDrop2s w-100 shadow-none position-relative">
            <div className="dropheight h-auto">
              <ConnectComponent Name="Google Analytics" icon={icon18}  />
              <ConnectComponent Name="Google Tag Manager" icon={icon19} />
              <ConnectComponent Name="Google Adwords" icon={icon20} />
              <ConnectComponentInput Name="Other ?  Type here...."/>
              <IntegAddFrom />
            </div>
          </ul>
        </div>
      </div>
    </div>
  )
}
