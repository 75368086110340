import React from "react";
import { useState } from "react";
import "../regularTheme4Mobile/RegularTheme4Mobile.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import timeline from "../../../../../../images/how-it-work/timeline-mobile.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { IoIosArrowForward } from "react-icons/io";
import { FcCheckmark } from "react-icons/fc"
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai"
import "slick-carousel/slick/slick-theme.css";
export default function RegularTheme4Mobile() {
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div>
      <div className="">
        <div>
          <div className="main-content plan_content">

            <div>
              <div className="mobile-help-section-4 text-center relative" >
                <div className="d-flex align-items-center justify-content-center mb-5">
                  <div className="toggle_button">
                    <button type="" className="btn active_btn">Monthly</button>
                    <button type="" className="btn1 ">Yearly</button>
                  </div>
                </div>
                <div className="relative">
                  <div className="content_wrapper">
                    <div className="how-it-work-programs-wrapper">
                      {/* <div className="programs-flex d-flex justify-content-center"> */}
                      <Slider {...settings} className="">
                        <div className="p-4 pb-5">
                          <div className="plan_outer">
                            <div className="shape_wrapper">
                              <div className="shape_outer bottom">
                                <div className="ribbon_wrapper">
                                  <div className="ribbon">
                                    <span className="ribbon1"><span>
                                      <div className="price">
                                        <p>Monthly</p>
                                        <h2>$29</h2>
                                      </div>
                                    </span></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="plan_listing">
                              <div className="mt-3">
                                <h3>Lorem Ipsum</h3>
                                <p>Sit amet consectetur</p>
                              </div>
                              <ul>
                                <li>
                          <div className="icon_outer">
                                  <FcCheckmark className="icon" />
                                  </div>
                                  Lorem ipsum dolor</li>
                                <li>
                          <div className="icon_outer">
                                  <FcCheckmark className="icon" />
                                  </div>
                                  Sit amet consect</li>
                                <li>
                          <div className="icon_outer">
                                  <RxCross2 className="icon text-danger" />
                                  </div>
                                  Sitt isporta vestibulum
                                </li>
                                <li>
                                  <div className="icon_outer">
                                  <RxCross2 className="icon text-danger" />
                                  </div>
                                  eu velit viverra quis vel
                                </li>
                              </ul>
                            </div>
                            <div className="d-flex justify-content-center fixed_btn">
                              <button className="btn mt-4" type="button">Order Now
                                <IoIosArrowForward className="ms-2" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="p-4 pb-5">
                          <div className="plan_outer">
                            <div className="shape_wrapper">
                              <div className="shape_outer bottom">
                                <div className="ribbon_wrapper">
                                  <div className="ribbon">
                                    <span className="ribbon1"><span>
                                      <div className="price">
                                        <p>Monthly</p>
                                        <h2>$59</h2>
                                      </div>
                                    </span></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center relative">
                              <button type="" className="green_btn1">Most Popular</button>
                            </div>
                            <div className="plan_listing">
                              <div>
                                <h3>Lorem Ipsum</h3>
                                <p>Sit amet consectetur</p>
                              </div>
                              <ul>
                                <li>
                          <div className="icon_outer">
                                  <FcCheckmark className="icon" />
                                  </div>
                                  Lorem ipsum dolor</li>
                                <li>
                          <div className="icon_outer">
                                  <FcCheckmark className="icon" />
                                  </div>
                                  Sit amet consect</li>
                                <li>
                          <div className="icon_outer">
                                  <FcCheckmark className="icon" />
                                  </div>
                                  Sitt isporta vestibulum
                                </li>
                                <li>
                          <div className="icon_outer">
                                  <FcCheckmark className="icon" />
                                  </div>
                                  eu velit viverra quis vel
                                </li>
                              </ul>
                            </div>
                            <div className="d-flex justify-content-center fixed_btn">
                              <button className="btn mt-4" type="button">Order Now
                                <IoIosArrowForward className="ms-2" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="p-4 pb-5">
                          <div className="plan_outer">
                            <div className="shape_wrapper">
                              <div className="shape_outer bottom">
                                <div className="ribbon_wrapper">
                                  <div className="ribbon">
                                    <span className="ribbon1"><span>
                                      <div className="price">
                                        <p>Monthly</p>
                                        <h2>$99</h2>
                                      </div>
                                    </span></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" plan_listing">
                              <div className="mt-3">
                                <h3>Lorem Ipsum</h3>
                                <p>Sit amet consectetur</p>
                              </div>
                              <ul>
                                <li>
                          <div className="icon_outer">
                                  <FcCheckmark className="icon" />
                                  </div>
                                  Lorem ipsum dolor</li>
                                <li>
                          <div className="icon_outer">
                                  <FcCheckmark className="icon" />
                                  </div>
                                  Sit amet consect</li>
                                <li>
                          <div className="icon_outer">
                                  <RxCross2 className="icon text-danger" />
                                  </div>
                                  Sitt isporta vestibulum
                                </li>
                                <li>
                          <div className="icon_outer">
                                  <RxCross2 className="icon text-danger" />
                                  </div>
                                  eu velit viverra quis vel
                                </li>
                                <li>
                          <div className="icon_outer">
                                  <AiFillStar className="icon text_yellow" />
                                  </div>
                                  Sitt isporta vestibulum
                                </li>
                                <li>
                          <div className="icon_outer">
                                  <AiFillStar className="icon text_yellow" />
                                  </div>
                                  eu velit viverra quis vel
                                </li>
                              </ul>
                            </div>
                            <div className="d-flex justify-content-center fixed_btn">
                              <button className="btn mt-4" type="button">Order Now
                                <IoIosArrowForward className="ms-2" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </Slider>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
