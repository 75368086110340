import { faCalendar, faExclamationCircle, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import Amazon from '../../../../images/amazon.png';
import ButtonBox from "../../../global/ButtonBox";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
// import { Link } from 'react-router-dom';
import "./AffiliatePrograms.scss"
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';
export default function AffiliateProgramsForm() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });
    let {isVideoVisible} = useSelector(state => state.videoReducer);
    const dispatch = useDispatch();
    const handleClick6 = () => {
      dispatch(toggleVideo());
    };
    return (
        <div className="pb-5 pt-2">
            <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
            <div className="row mb-4 px-3">
                <div className="col-md-4 px-0">
                    <label className="mb-1">{t('timeline_form_amazon_affiliate_program_component.amazon_affiliate_registration')}</label>
                    <span className="shadow-4 h-50s br-3  d-flex justify-content-start px-3 align-items-center text_blue text-center h6">
                        <img src={Amazon} alt="" className="w-35 me-3" />
                        Registration Link</span>
                </div>
                <div className="col-md-8">
                    <label className="mb-1">{t('timeline_form_amazon_affiliate_program_component.your_amazon_affiliate_account_link')}</label>
                    <input placeholder={t('timeline_form_amazon_affiliate_program_component.your_amazon_affiliate_account_link_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
            </div>
            <div className="row mb-4 px-3">
                <div className="col-md-12">
                    <label className="mb-1">{t('timeline_form_amazon_affiliate_program_component.your_amazon_affiliate_short_link')}</label>
                </div>
                <div className="row">
                    <div className="col-md-1 px-0">
                        <span className="shadow-4 h-50s br-3  d-flex justify-content-start px-3 align-items-center text-secondary text-center h6">
                            <FontAwesomeIcon icon={faLink} className=" icon" />
                        </span>
                    </div>
                    <div className="col-md-11">
                        <input placeholder={t('timeline_form_amazon_affiliate_program_component.your_amazon_affiliate_short_link_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                    </div>
                </div>
            </div>
            <div className="row mb-4 px-3">
                <div className="col-md-12">
                    <label className="mb-1">{t('timeline_form_amazon_affiliate_program_component.your_amazon_affiliate_long_link')}</label>
                </div>
                <div className="row">
                    <div className="col-md-1 px-0">
                        <span className="shadow-4 h-50s br-3  d-flex justify-content-start px-3 align-items-center text-secondary text-center h6">
                            <FontAwesomeIcon icon={faLink} className=" icon" />
                        </span>
                    </div>
                    <div className="col-md-11">
                        <input placeholder={t('timeline_form_amazon_affiliate_program_component.your_amazon_affiliate_long_link_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                    </div>
                </div>
            </div>
            <div className="row mb-4 px-3 justify-content-between  align-items-center">
                <div className="date_area">
                    Joined Since: <span className="ms-1">Nov. 10, 2022</span>
                    <div className="icon ms-2">
                        <FontAwesomeIcon icon={faCalendar} className="" />
                    </div>
                </div>
                <div className="date_area">
                    Grace Period until <span className="ms-1">March 09, 2023</span>
                    <div className="icon ms-2">
                        <FontAwesomeIcon icon={faCalendar} className="" />
                    </div>
                </div>
            </div>
            <div className="w-100 d-flex flex-wrap justify-content-start align-items-center mb-30">
                <div className="w-100 mb-30 shadow-4 p-4 mb-5">
                <h6 className="mb-3 text-danger font-weight-semibold d-flex align-items-center">
                <FontAwesomeIcon icon={faExclamationCircle} className="me-2 f_20" />
                    {t('timeline_form_amazon_affiliate_program_component.earning_disclaimer_label')}
                    </h6>
                    <p className=" fw-normal lh-normal">
                        This post | content | website may contain affiliate links. This means that if any body clicks on a text or an image or a link, I may earn a commission from purchases, but at no additional cost to you. Many or all of the products or services featured on any of my website pages are from my affiliate partners including Amazon. This may influence which products I write about and where and how the product appears on a page. However, this does not influence my true evaluations. My opinions are my own.
                    </p>
                    <p className="text-center">
                        “As an Amazon Associate I earn from qualifying purchases.”
                    </p>
                </div>
            </div>
            <div className="row text-center text-secondary mb-5">
                <h6>OR</h6>
            </div>
            <div className="w-100 d-flex flex-wrap justify-content-start align-items-center mb-30">
                <label className="mb-1">{t('timeline_form_amazon_affiliate_program_component.your_own_earning_disclaimer')}</label>
                <div className="d-flex w-100">
                    <textarea rows={7} placeholder={t('timeline_form_amazon_affiliate_program_component.your_own_earning_disclaimer_placeholder')} className='shadow-5in h-300 br-5 h6' type="text"></textarea>
                </div>
            </div>
            <div className=" text-center time-btn rotate_btn">

                <ButtonBox
                    boxwidth="w-320 text_light mt-4 mb-50 mx-auto"
                    clicked="Confirm Amazon Affiliate Account"
                    name="Confirm Amazon Affiliate Account"
                />
            </div>
        </div>
    );
}
