import React, { useState } from 'react';

function TabComponentMonth() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className='login-tab2'>
      <div className="tabsul nav">
        <div
          className={`tabli ${activeTab === 0 ? 'active' : ''}`}
          onClick={() => handleTabClick(0)}
        >
          <div className='btn-layer br-30'><span className='br-20 w-100 h-90 border-0'>Jan</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 1 ? 'active' : ''}`}
          onClick={() => handleTabClick(1)}
        >
          <div className='btn-layer br-30'><span className='br-20 w-100 h-90 border-0'>Feb</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 2 ? 'active' : ''}`}
          onClick={() => handleTabClick(2)}
        >
          <div className='btn-layer br-30'><span className='br-20 w-100 h-90 border-0'>Mar</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 4 ? 'active' : ''}`}
          onClick={() => handleTabClick(4)}
        >
          <div className='btn-layer br-30'><span className='br-20 w-100 h-90 border-0'>Apr</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 5 ? 'active' : ''}`}
          onClick={() => handleTabClick(5)}
        >
          <div className='btn-layer br-30'><span className='br-20 w-100 h-90 border-0'>May</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 6 ? 'active' : ''}`}
          onClick={() => handleTabClick(6)}
        >
          <div className='btn-layer br-30'><span className='br-20 w-100 h-90 border-0'>Jun</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 7 ? 'active' : ''}`}
          onClick={() => handleTabClick(7)}
        >
          <div className='btn-layer br-30'><span className='br-20 w-100 h-90 border-0'>Jul</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 8 ? 'active' : ''}`}
          onClick={() => handleTabClick(8)}
        >
          <div className='btn-layer br-30'><span className='br-20 w-100 h-90 border-0'>Aug</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 9 ? 'active' : ''}`}
          onClick={() => handleTabClick(9)}
        >
          <div className='btn-layer br-30'><span className='br-20 w-100 h-90 border-0'>Sep</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 10 ? 'active' : ''}`}
          onClick={() => handleTabClick(10)}
        >
          <div className='btn-layer br-30'><span className='br-20 w-100 h-90 border-0'>Oct</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 11 ? 'active' : ''}`}
          onClick={() => handleTabClick(11)}
        >
          <div className='btn-layer br-30'><span className='br-20 w-100 h-90 border-0'>Nov</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 12 ? 'active' : ''}`}
          onClick={() => handleTabClick(12)}
        >
          <div className='btn-layer br-30'><span className='br-20 w-100 h-90 border-0'>Dec</span></div>
        </div>
      </div>

      {/* Content for each tab */}
      {/* <div className="tab-content">
        {activeTab === 0 && <p>Content for Tab 1</p>}
        {activeTab === 1 && <p>Content for Tab 2</p>}
        {activeTab === 2 && <p>Content for Tab 3</p>}
      </div> */}
    </div>
  );
}

export default TabComponentMonth;
