import React from "react";
import { Link } from "react-router-dom";
import { NavItem } from 'react-bootstrap';

export default function FormatIcon(props) {
  return (
    <NavItem as="li">
      <Link to={props.url} className={props.size}>
        <img src={props.src} alt="" width="auto" height="20" />
      </Link>
    </NavItem>
  );
}
