import React, { useState } from "react";
import desktop_3_1  from "../../../../images/profile/Desktop3-1.png"
import tab_3_1  from "../../../../images/profile/Tab3-1.png"
import mobile_3_1  from "../../../../images/profile/Mobile3-1.png"
import desktop_3_2  from "../../../../images/profile/Desktop3-2.png"
import tab_3_2  from "../../../../images/profile/Tab3-2.png"
import mobile_3_2  from "../../../../images/profile/Mobile3-2.png"
import desktop_3_3  from "../../../../images/profile/Desktop3-3.png"
import tab_3_3  from "../../../../images/profile/Tab3-3.png"
import Modal from 'react-bootstrap/Modal';
import mobile_3_3  from "../../../../images/profile/Mobile3-3.png"
import ProfileHeroSectionGlassmorphicThemeDesktopView1 from "./profileHeroSectionGlassmorphicThemes/ProfileHeroSectionGlassmorphicThemeDesktopView1";
import ProfileHeroSectionGlassmorphicThemeTabView1 from "./profileHeroSectionGlassmorphicThemes/ProfileHeroSectionGlassmorphicThemeTabView1";
import ProfileHeroSectionGlassmorphicThemeDesktopView2 from "./profileHeroSectionGlassmorphicThemes/ProfileHeroSectionGlassmorphicThemeDesktopView2";
import ProfileHeroSectionGlassmorphicThemeTabView2 from "./profileHeroSectionGlassmorphicThemes/ProfileHeroSectionGlassmorphicThemeTabView2";
import ProfileHeroSectionGlassmorphicThemeMobileView1 from "./profileHeroSectionGlassmorphicThemes/ProfileHeroSectionGlassmorphicThemeMobileView1";
import ProfileHeroSectionGlassmorphicThemeMobileView2 from "./profileHeroSectionGlassmorphicThemes/ProfileHeroSectionGlassmorphicThemeMobileView2";
import ProfileHeroSectionGlassmorphicThemeMobileView3 from "./profileHeroSectionGlassmorphicThemes/ProfileHeroSectionGlassmorphicThemeMobileView3";
import ProfileHeroSectionGlassmorphicThemeTabView3 from "./profileHeroSectionGlassmorphicThemes/ProfileHeroSectionGlassmorphicThemeTabView3";
import ProfileHeroSectionGlassmorphicThemeDesktopView3 from "./profileHeroSectionGlassmorphicThemes/ProfileHeroSectionGlassmorphicThemeDesktopView3";
import ButtonBoxNew from "../../../global/ButtonBoxNew"

import './ProfileHeroSectionThemes.scss';
import { useTranslation } from "react-i18next";
export default function ProfileHeroSectionGlassmorphicTheme() {
  const { t, i18n } = useTranslation();

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [themedesktopShow, setThemedesktopShow] = useState(false);
  const [themetabShow, setThemetabShow] = useState(false);
  const [themedesktop3Show, setThemedesktop3Show] = useState(false);
  const [themetab3Show, setThemetab3Show] = useState(false);
  const [thememobileShow, setThememobileShow] = useState(false);
  const [thememobile3Show, setThememobile3Show] = useState(false);
  const [desktopgeoShow, setDesktopgeoShow] = useState(false);



  

  return (
    <div className="">
   
<h4 className="text-center">
Glassmorphic  Design Version 1
</h4>
<div className="d-flex card-outo">
<div className="">
<div className="screen-bg">
  <img src={desktop_3_1} alt='desktop-3-1' >
  </img>

</div>
{/* <button className="screen-btn" onClick={() => setDesktopgeoShow(true)}>
See Desktop view
</button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-5" onClick={() => setDesktopgeoShow(true)}/>
<Modal
    
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
                show={desktopgeoShow}
        onHide={() => setDesktopgeoShow(false)}
        
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        <ProfileHeroSectionGlassmorphicThemeDesktopView1 />

        </Modal.Body>
      </Modal>

</div>
<div className="">
<div className="screen-bg">
<img src={tab_3_1} alt='desktop-3-1' ></img>
</div>
{/* <div className="screen-btn"   onClick={() => setSmShow(true)}>
See Tablet view
</div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-5" onClick={() => setSmShow(true)}/>
<Modal
    
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
                show={smShow}
        onHide={() => setSmShow(false)}
        
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        <ProfileHeroSectionGlassmorphicThemeTabView1 />

        </Modal.Body>
      </Modal>
</div>

<div className="">
<div className="screen-bg">
<img src={mobile_3_1} alt='mobile-3-1' ></img>
</div>
{/* <div className="screen-btn" onClick={() => setLgShow(true)}>
See Mobile view
</div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220  mx-auto mt-4 mb-5" onClick={() => setLgShow(true)}/>
<Modal
        show={lgShow}
        onHide={() => setLgShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-titlee"
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        <ProfileHeroSectionGlassmorphicThemeMobileView1 />

        </Modal.Body>
      </Modal>
</div>
</div>
<h4 className="text-center">
Glassmorphic  Design Version 2
</h4>
<div className="d-flex card-outo">
<div className="">
<div className="screen-bg">
  <img src={desktop_3_2} alt='desktop-3-2' >
  </img>

</div>
{/* <button className="screen-btn"  onClick={() => setThemedesktopShow(true)}>
See Desktop view
</button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-5" onClick={() => setThemedesktopShow(true)}/>
<Modal
        show={themedesktopShow}
        onHide={() => setThemedesktopShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-titlee"
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        <ProfileHeroSectionGlassmorphicThemeDesktopView2 />

        </Modal.Body>
      </Modal>
</div>
<div className="">
<div className="screen-bg">
<img src={tab_3_2} alt='tab-3-2' ></img>
</div>
{/* <div className="screen-btn" onClick={() => setThemetabShow(true)}>
See Tablet view
</div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-5" onClick={() => setThemetabShow(true)} />
<Modal
        show={themetabShow}
        onHide={() => setThemetabShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-titlee"
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        <ProfileHeroSectionGlassmorphicThemeTabView2 />

        </Modal.Body>
      </Modal>
</div>

<div className="">
<div className="screen-bg">
<img src={mobile_3_2} alt='mobile-3-2' ></img>
</div>
{/* <div className="screen-btn" onClick={() => setThememobileShow(true)}>
See Mobile view
</div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-5" onClick={() => setThememobileShow(true)} />
<Modal
        show={thememobileShow}
        onHide={() => setThememobileShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-titlee"
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        <ProfileHeroSectionGlassmorphicThemeMobileView2 />

        </Modal.Body>
      </Modal>
</div>
</div>
<h4 className="text-center">
Glassmorphic  Design Version 3
</h4>
<div className="d-flex card-outo">
<div className="">
<div className="screen-bg">
  <img src={desktop_3_3} alt='desktop-3-3' >
  </img>

</div>
{/* <button className="screen-btn"  onClick={() => setThemedesktop3Show(true)}>
See Desktop view
</button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-5" onClick={() => setThemedesktop3Show(true)}/>
<Modal
        show={themedesktop3Show}
        onHide={() => setThemedesktop3Show(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-titlee"
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        <ProfileHeroSectionGlassmorphicThemeDesktopView3 />

        </Modal.Body>
      </Modal>
</div>
<div className="">
<div className="screen-bg">
<img src={tab_3_3} alt='tab-3-3' ></img>
</div>
{/* <div className="screen-btn" onClick={() => setThemetab3Show(true)}>
See Tablet view
</div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-5" onClick={() => setThemetab3Show(true)}/>
<Modal
        show={themetab3Show}
        onHide={() => setThemetab3Show(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-titlee"
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        <ProfileHeroSectionGlassmorphicThemeTabView3 />

        </Modal.Body>
      </Modal>
</div>

<div className="">
<div className="screen-bg">
<img src={mobile_3_3} alt='mobile-3-3' ></img>
</div>
{/* <div className="screen-btn" onClick={() => setThememobile3Show(true)}>
See Mobile view
</div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-5" onClick={() => setThememobile3Show(true)}/>
<Modal
        show={thememobile3Show}
        onHide={() => setThememobile3Show(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-titlee"
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        <ProfileHeroSectionGlassmorphicThemeMobileView3 />

        </Modal.Body>
      </Modal>
</div>
</div>
    </div>
  );
}
