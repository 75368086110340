import React from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import left_girl_image from "../../../../../../../../images/how-it-work/left-girl-image.png";
import right_girl_image from "../../../../../../../../images/how-it-work/right-girl-image.png";

export default function Transformation() {
  return (
    <div className="img-tranformation">
      <h4>IMAGE YOUR BODY TRANSFORMATION</h4>
      <div className="compare-slider-tab-parent">
        <div className="overlay-image-left">
          <img src={left_girl_image} alt="" srcset="" />
        </div>
        <div className="compare-slider-tab">
          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage
                src={left_girl_image}
                alt="Image one"
                className="slider-image"
                style={{
                  height: "460px",
                  objectFit: "contain",
                }}
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={right_girl_image}
                alt="Image two"
                className="slider-image"
                style={{
                  height: "460px",
                  objectFit: "contain",
                }}
              />
            }
          />
        </div>
        <div className="overlay-image-right">
          <img src={right_girl_image} alt="" srcset="" />
        </div>
      </div>
    </div>
  );
}
