import React from "react";
import ToggleButton from "../../../../../global/ToggleButton";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function ProfileGroupEight() {
  const { t, i18n } = useTranslation();

  return (
    <div className="shadow-5in w-100 p-40 br-30 mb-4">
      <h3 className="mb-5 text-uppercase">{t('profile_section.your_education_status')}</h3>
      <ul className="row">
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="YOUR HIGHEST LEVEL OF EDUCATIONa" id="YOUREDUCATION" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="LAST INSTITUTION/UNIVERSITY NAME" id="INSTITUTION/UNIVERSITY" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="MAJOR/SPECIALIZATION" id="SPECIALIZATION" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="YEAR OF GRADUATION" id="GRADUATION" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="I AM AN ALUMNI" id="ALUMNI" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="FULL-TIME STUDENT" id="STUDENT" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="PART-TIME STUDENT" id="PSTUDENT" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="RECONSIDERING MY OPTIONS" id="RECONSIDERING" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="DROPPED-OUT" id="DROPPED-OUT" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="FIRST LANGUAGE" id="FIRSTLANGUAGE" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="SECOND LANGUAGE" id="SECONDLANGUAGE" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12"> 
          <ToggleButton name="THIRD LANGUAGE" id="THIRDLANGUAGE" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Language 1 Proficiency Level" id="LanguageLevel" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Language 2 Proficiency Level" id="LanguageLevel2" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Language 3 Proficiency Level" id="LanguageLevel3" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="fOURTH lANGAUGE" id="fOURTHlANGAUGE" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Language 4 Proficiency Level" id="LanguageLevel4" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Hobby 1" id="Hobby" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Hobby 2" id="Hobby2" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Hobby 3" id="Hobby3" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Hobby 4" id="Hobby4" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Hobby 5" id="Hobby5" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="SKill 1" id="SKill1" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="SKill 2" id="SKill2" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="SKill 3" id="SKill3" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="SKill 4" id="SKill4" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="SKill 5" id="SKill5" check="checked" />
        </NavItem>
      </ul>
    </div>
  );
}
