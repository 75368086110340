import React, { useState } from "react";
import desktop_1 from "../../../../images/faq/Desktop1.png"
import tab_1 from "../../../../images/faq/Tab1.png"
import mobile_1 from "../../../../images/faq/Mobile1.png"
import desktop_2 from "../../../../images/faq/Desktop2.png"
import tab_2 from "../../../../images/faq/Tab2.png"
import mobile_2 from "../../../../images/faq/Mobile2.png"
import desktop_3 from "../../../../images/faq/Desktop3.png"
import tab_3 from "../../../../images/faq/Tab3.png"
import mobile_3 from "../../../../images/faq/Mobile3.png"
// import desktop_4 from "../../../../images/featured-on/Desktop4.png"
// import tab_4 from "../../../../images/featured-on/Tab4.png"
// import mobile_4 from "../../../../images/featured-on/Mobile4.png"

// import HowItWorkSectionRegularThemeDesktopView1 from './HowItWorkSectionRegularThemeDesktopView1'
// import HowItWorkSectionRegularThemeDesktopView2 from './HowItWorkSectionRegularThemeDesktopView2'


// import HowItWorkSectionRegularThemeTabView1 from './HowItWorkSectionRegularThemeTabView1'
// import HowItWorkSectionRegularThemeMobileView1 from './HowItWorkSectionRegularThemeMobileView1'
// import HowItWorkSectionRegularThemeTabView2 from './HowItWorkSectionRegularThemeTabView2'
// import HowItWorkSectionRegularThemeMobileView2 from './HowItWorkSectionRegularThemeMobileView2'

import Modal from 'react-bootstrap/Modal';

import { useTranslation } from "react-i18next";


import './FaqSectionThemes.scss';
import ButtonBoxNew from "../../../global/ButtonBoxNew";
export default function FaqSectionRegularTheme() {

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [TabTwoShow, setTabTwoShow] = useState(false);
  const [MobileTwoShow, setMobileTwoShow] = useState(false);
  const [deskShow, setDeskShow] = useState(false);
  const [desktwoShow, setDesktwoShow] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);
  const [TabFourShow, setTabFourShow] = useState(false);
  const [MobileFourShow, setMobileFourShow] = useState(false);
  const [deskFourShow, setdeskFourShow] = useState(false);
  const [TabFiveShow, setTabFiveShow] = useState(false);
  const [MobileFiveShow, setMobileFiveShow] = useState(false);
  const [deskFiveShow, setdeskFiveShow] = useState(false);
  const [TabSixShow, setTabSixShow] = useState(false);
  const [MobileSixShow, setMobileSixShow] = useState(false);
  const [deskSixShow, setdeskSixShow] = useState(false);

  const { t, i18n } = useTranslation();
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });




  return (
    <div className="">

      <h4 className="text-center">
        Regular  Design Version 1
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_1} alt="deshtop-1"  >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setDeskShow(true)}/>
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HireMeSectionRegularThemeDesktopView1 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_1} alt="tab-1"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setSmShow(true)}/>
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeTabView1 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_1} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setLgShow(true)}/>
          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView1 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 2
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2} alt="desktop-2" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDesktwoShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setDesktwoShow(true)}/>
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktwoShow}
            onHide={() => setDesktwoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeDesktopView2 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2} alt="tab-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabTwoShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setTabTwoShow(true)}/>
          
          <Modal
            show={TabTwoShow}
            onHide={() => setTabTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-tit"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeTabView2 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2} alt="mobile-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileTwoShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setMobileTwoShow(true)}/>

          <Modal
            show={MobileTwoShow}
            onHide={() => setMobileTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-ti"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView2 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 3
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3} alt="desktop-3" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskthreeShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setDeskthreeShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskthreeShow}
            onHide={() => setDeskthreeShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3} alt="tab-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabThreeShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setTabThreeShow(true)}/>

          <Modal
            show={TabThreeShow}
            onHide={() => setTabThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3} alt="mobile-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileThreeShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setMobileThreeShow(true)}/>

          <Modal
            show={MobileThreeShow}
            onHide={() => setMobileThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      {/* <h4 className="text-center">
        Regular  Design Version 4
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_4} alt="desktop-4" >
            </img>

          </div>
          <div className="screen-btn" onClick={() => setdeskFourShow(true)}>
            See Desktop view
          </div>
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFourShow}
            onHide={() => setdeskFourShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
             

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_4} alt="tab-4" ></img>
          </div>
          <div className="screen-btn" onClick={() => setTabFourShow(true)}>
            See Tablet view
          </div>
          <Modal
            show={TabFourShow}
            onHide={() => setTabFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
            

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_4} alt="mobile-4" ></img>
          </div>
          <div className="screen-btn" onClick={() => setMobileFourShow(true)}>
            See Mobile view
          </div>
          <Modal
            show={MobileFourShow}
            onHide={() => setMobileFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
             

            </Modal.Body>
          </Modal>
        </div>
      </div> */}
    </div>
  );
}
