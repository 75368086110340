import * as React from 'react';
import '../../profileManagement.scss';
import '../../profileManagementSec.scss';

import GlobleSlider from './GlobleSlider';
import TrackingTrackingGoogle from './InteTabs/TrackingGoogle';
import IntegSliderTrack from './IntegSliderTrack';
import TrackingSocial from './InteTabs/TrackingSocial';
import TrackingMaster from './InteTabs/TrackingMaster';
import TrackingUserMedia from './InteTabs/TrackingUserMedia';
import TrackingPayment from './InteTabs/TrackingPayment';
import TrackingOther from './InteTabs/TrackingOther';
import TrackingAffiliate from './InteTabs/TrackingAffiliate';


export default function GlobleTab() {

    return (
        <div className="w-100 d-flex flex-column-reverse">
            <div className='w-100'>
                
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="googleseotrack" role="tabpanel" aria-labelledby="googleseotrack">
                        <TrackingTrackingGoogle />
                    </div>
                    <div className="tab-pane fade" id="social08track" role="tabpanel" aria-labelledby="social08track">
                        <TrackingSocial />
                    </div>
                    <div className="tab-pane fade" id="usermedia08track" role="tabpanel" aria-labelledby="usermedia08track">
                        <TrackingUserMedia />
                    </div>
                    <div className="tab-pane detailTab fade" id="master08track" role="tabpanel" aria-labelledby="master08track">
                        <TrackingMaster />
                    </div>
                    <div className="tab-pane detailTab fade" id="payment08track" role="tabpanel" aria-labelledby="payment08track">
                        <TrackingPayment />
                    </div>
                    <div className="tab-pane detailTab fade" id="affiliate08track" role="tabpanel" aria-labelledby="affiliate08track">
                        <TrackingAffiliate />
                    </div>
                    <div className="tab-pane fade" id="other08track" role="tabpanel" aria-labelledby="other08track">
                        <TrackingOther />
                    </div>                 
                </div>
            </div>
            <div className='container privacyTab mb-0 pb-2 mt-5 mt-lg-0'>
                <div id="myTab78" className='slidetab' role="tablist">
                    <IntegSliderTrack />
                    <GlobleSlider />
                </div>
            </div>
        </div>
    );
  }