import React, { useState } from 'react';
import EditorDrop from "../global/EditorDrop";
import img3 from "../../images/download3.png";
import { Link } from 'react-router-dom';
import { ReactComponent as IconBlue4s } from "../../images/icons/icon16.svg";
// import { ReactComponent as Plus } from "../../images/icons/plus.svg";
import { ReactComponent as Delete } from "../../images/icons/delete.svg";
import { ReactComponent as IconBlue19 } from "../../images/audio/coolicon.svg";
import ReactStars from "react-rating-stars-component";
import RangeSlider from "react-bootstrap-range-slider";
import { useTranslation } from "react-i18next";

function Announcment() {

  const ratingChanged = (newRating) => {
  };

  const { t, i18n } = useTranslation();

  const [ yearStarted, setYearStarted ] = useState(2018); 
  const [ yearEnded, setYearEnded ] = useState(1); 

  const [divs, setDivs] = useState([]);

  const addDiv = () => {
    const newDivs = [...divs, { id: divs.length, content: 'New Div' }];
    setDivs(newDivs);
  };

  const deleteDiv = (idToDelete) => {
    const newDivs = divs.filter((div) => div.id !== idToDelete);
    setDivs(newDivs);
  };

  return (
    <div>
      {/* <button onClick={addDiv} className='position-absolute bottom-0 end-0 border-0 btn bs-primary z-1'>
        <Plus />
      </button> */}
        {divs.map((div, index) => (
          <div className='w-100 mb-4 position-relative'>
            <div className="col-lg-3 px-2 text-center mb-4 mx-auto">
              <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
                <IconBlue19 />
                <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="newcircle shadow-5in py-1 px-2 w-auto br-10 my-2" />
                <div className="upload-btn-wrapper">
                  <button className="btns1 bg shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
                  <input type="file" name="myfile" />
                </div>
                <p className="h7 fw-semibold mt-2">200 X 200 Pixels</p>
              </div>
              <input type="text" placeholder="Paste Your Photo Url Here..." className="shadow-5in inputnew3 my-4" />
            </div>
            <div className="position-relative">
              <div className="newform">
                <div className="row w-100 mb-4">
                  <div className="col-lg-6 d-flex mb-28 nav-item2">
                    <label>FULL NAME</label>
                    <div className="w-75 z1">
                      <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder="Maya Taylor" />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex mb-28 nav-item2">
                    <label>DESIGNATION</label>
                    <div className="w-75 z1">
                      <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder="9 TO 5 ESCAPEE" />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h3>100%</h3>
                    <h4>I Would recommend to a friend</h4>
                    <div className="w-250 mx-auto pt-5">
                      <RangeSlider value={yearEnded} onChange={changeEvent => setYearEnded(changeEvent.target.value)} min={0} max={100}/> 
                    </div>
                  </div>
                  <div className="col-lg-6 starrateing">
                    <h5>Your Rating</h5>
                    <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={43}
                        activeColor="#facb30"
                        color="#eff4f8"
                      />
              
                    <h5>Your  Overall Experience</h5>
                  </div>
                  <div className="col-lg-12 mt-3 mb-28 nav-item3">
                    <label className="w-360">TESTIMONIAL MESSAGE</label>
                    <div className=" w-100">
                      <span className="inputspan1 position-relative">
                        <div className="dropdown btnArrow btnArrow2 position-absolute spacedrop z-index-2">
                          <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-display="static">
                            <Link to="#" id="notab" className="d-flex justify-content-center fs-5 fw-bold ps-3 pe-5 align-items-center w-100x text-underline shadow-5in h-40s h8 br-30">
                              <u className="h5">A</u>
                            </Link>
                          </span>
                          <EditorDrop />
                        </div>
                        <textarea className="announcment shadow-5in w-100 ps-5 pt-4" placeholder="Type your  Testimonial  here...."></textarea>
                        <span className="position-absolute top-20 start-16 ">
                          <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4s /></span>
                          <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4s /></span>
                          <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4s /></span>

                        </span>
                        <div className="dropdownload">
                          <Link to="#">
                            <img src={img3} alt="" />
                          </Link>
                          <label for="test">
                            <input type="file" id="test" />
                            <span>Choose a local File</span>
                          </label>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-9 d-flex nav-item4 justify-content-start align-items-center">
                    <label>TESTIMONIAL RECIPIENT</label>
                    <div className=" w-75 z1">
                      <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder="Dyna Appolon" />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="newannous d-flex justify-content-end align-itmes-center">
                      <h6 className="ml-auto">Type or Select The Person Whom You are Writing The Testimonial for</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className='position-absolute bottom-0 custom-end2 end-0 border-0 btn bs-primary' onClick={() => deleteDiv(div.id)}><Delete /></button>
          </div>
        ))}
        <div className='w-100 mt-5 custmToggle'>
              <div className='btmBtn9 d-flex w-100 justify-content-center '>
                <button className='shadow-4 border-0'  onClick={addDiv}>Add MORE</button>
                <span>
                  <div className="switch">
                    <div className="slider1 round" onClick={addDiv}></div>
                  </div>
                </span>
              </div>
            </div>
    </div>
  );
}

export default Announcment;
