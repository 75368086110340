import React from 'react';
import './header.scss';
// import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import logo from '../../images/logo.png';
import logo from '../../images/profileicon/dyna-logo.png';
import logo1 from '../../images/social/logo2.gif';
import logo2 from '../../images/social/logo3.png';
import { auto } from '@popperjs/core';
import { Link } from 'react-router-dom';
import { faFacebookF, faYoutube, faInstagram, faTwitter, faLinkedin, faDribbble, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { faLock, faMoon, faSun, faGlobe, faEnvelope, faPaperPlane, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import SocialLink from '../global/SocialLink';
import CircleMenu from '../global/circleMenu/CircleMenu'
import { useTranslation } from "react-i18next";
import user from "../../images/audio/img45.png";
import { ReactComponent as IconBlue1s } from "../../images/svgIcon/icon8.svg";
import { ReactComponent as IconBlue2s } from "../../images/svgIcon/icon9.svg";
import { ReactComponent as IconBlue3s } from "../../images/svgIcon/icon10.svg";
import { library } from "@fortawesome/fontawesome-svg-core";
// import { NavItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
// import Select from 'react-select';
import Dropdown from 'react-bootstrap/Dropdown';

import userimg from '../../images/profileview/userimg.png';
import closebtn from "../../images/closebtn.png";
import { NavItem } from 'react-bootstrap';
import img10 from '../../images/profileview/img16s.png';
import state2 from '../../images/profileview/stateblack.png';
// import mainimg from '../../images/dropdown/mainimg.png';
// import mainimg1 from '../../images/dropdown/img1.png';
// import mainimg2 from '../../images/dropdown/img2.png';
// import mainimg3 from '../../images/dropdown/img3.png';
// import mainimg4 from '../../images/dropdown/img4.png';
// import mainimg5 from '../../images/dropdown/img5.png';
// import mainimg6 from '../../images/dropdown/img6.png';
// import mainimg7 from '../../images/profileview/img1.png';
// import mainimg8 from '../../images/profileview/imgs2.png';
// import mainimg9 from '../../images/profileview/imgs3.png';
// import mainimg10 from '../../images/profileview/img15.png';
// import mainimg11 from '../../images/profileview/img17s.png';
// import leftbtn from '../../images/dropdown/leftbtn.png';
// import rightbtn from '../../images/dropdown/rightbtn.png';
// import tag from '../../images/dropdown/tag.png';
// import ImageBox from "../global/ImageBox";

import crown from "../../images/profileicon/img1.svg";
import fb from "../../images/profileicon/img2.svg";
import ins from "../../images/profileicon/img3.svg";
import twi from "../../images/profileicon/img4.svg";
import linked from "../../images/profileicon/img5.svg";
import pine from "../../images/profileicon/img6.svg";
import tube from "../../images/profileicon/img7.svg";
import tok from "../../images/profileicon/img8.svg";
import check from "../../images/profileicon/img10.svg";
import canda from "../../images/profileicon/img11.svg";
import vip from "../../images/profileicon/img12.svg";
import whts from "../../images/profileicon/img13.svg";
// import DropdownStyle from '../global/DropdownStyle';
import SubDropdown from '../global/SubDropdown';

import { ReactComponent as IconBlue1 } from "../../images/listicon/icon1.svg";
import { ReactComponent as IconBlue2 } from "../../images/listicon/icon2.svg";
import { ReactComponent as IconBlue3 } from "../../images/social/whatsa.svg";
import { ReactComponent as IconBlue4 } from "../../images/social/call.svg";
import { ReactComponent as IconBlue5 } from "../../images/listicon/icon5.svg";
import { ReactComponent as IconBlue6 } from "../../images/listicon/icon6.svg";
import { ReactComponent as IconBlue7 } from "../../images/listicon/icon7.svg";
import { ReactComponent as IconBlue8 } from "../../images/listicon/icon8.svg";
import { ReactComponent as IconBlue9 } from "../../images/listicon/icon9.svg";
import { ReactComponent as IconBlue10 } from "../../images/listicon/icon10.svg";
import { ReactComponent as IconBlue11 } from "../../images/listicon/icon11.svg";
// import { ReactComponent as IconBlue12 } from "../../images/listicon/icon12.svg";
import { ReactComponent as IconBlue13 } from "../../images/listicon/icon13.svg";
import { ReactComponent as IconBlue14 } from "../../images/listicon/icon14.svg";
import { ReactComponent as IconBlue20 } from "../../images/audio/icon34.svg";

import SimpleView1 from "../global/SimpleView1";
import VideoView from "../global/VideoView1";
import AudioView from "../global/AudioView1";

library.add(faLock, faMoon, faSun, faGlobe, faEnvelope, faPaperPlane, faLightbulb, faFacebookF, faYoutube, faInstagram, faTwitter, faLinkedin, faDribbble, faPinterest);

export default function Header() {

  // const theme1 = theme => ({
  //   ...theme,
  //   colors: {
  //     ...theme.colors,
  //     primary25: "#EFF4F8",
  //     primary: "#EFF4F8",
  //   }
  // });

  // const options2 = [
  //   { value: 'English', label: <span className="">English</span> },
  //   { value: 'Germany', label: <span className="">Germany</span> },
  // ]

  const [handleView, setHandleView ] = useState(""); 

  const handleClick5 = () => {
    setToggle5(!toggle5);
  };
  const [toggle5, setToggle5] = useState("true");

  const handleClick6 = () => {
    setToggle6(!toggle6);
  };
  const [toggle6, setToggle6] = useState("true");

  const handleClick7 = () => {
    setToggle7(!toggle7);
  };
  const [toggle7, setToggle7] = useState("true");

  const meetOptions = [
    {
      icon: <span className=""><IconBlue1 className="me-2"/></span>,
      name: "Live | Available"
    },
    {
      icon: <span className=""><IconBlue2 className="me-2"/> </span>,
      name: "Busy"
    },
    {
      icon: <span className=""><IconBlue3 className="me-2"/></span>,
      name: "WhatsApp Text Only"
    },
    {
      icon: <span className=""><IconBlue4 className="me-2"/></span>,
      name: "Urgent Calls Only"
    },
    {
      icon: <span className=""><IconBlue5 className="me-2"/> </span>,
      name: "In A Meeting"
    },
    {
      icon: <span className=""><IconBlue6 className="me-2"/> </span>,
      name: "Coaching Now"
    },
    {
      icon: <span className=""><IconBlue7 className="me-2"/> </span>,
      name: "On Break"
    },
    {
      icon: <span className=""><IconBlue8 className="me-2"/> </span>,
      name: "Self-Care"
    },
    {
      icon: <span className=""><IconBlue9 className="me-2"/> </span>,
      name: "Meditation"
    },
    {
      icon: <span className=""><IconBlue10 className="me-2"/> </span>,
      name: "On Vacation"
    },
    {
      icon: <span className=""><IconBlue11 className="me-2"/> </span>,
      name: "Out Sick"
    },
    {
      icon: <span className=""><IconBlue13 className="me-2"/> </span>,
      name: "Working In Bed"
    },
    {
      icon: <span className=""><IconBlue14 className="me-2"/> </span>,
      name: "Do Not Disturb"
    },
  ] 

  const { t, i18n } = useTranslation();
  //Creating a method to change the language onChnage from select box
  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value
    i18n.changeLanguage(languageValue);
  }
  const [theme, setTheme] = useState(false);
  const handleClick = () => {
    setTheme(!theme)
  }
  useEffect(() => {
    if (theme == true) {
      document.body.classList.add("dark");
    }
    else {
      document.body.classList.remove("dark");
    }

  })
  return (
    <div className='header w-100 bg' >
      <div className='container-xl h-100 px-0'>
        <div className='h-100 w-100 d-flex justify-content-start align-items-center'>
          <div className='col-lg-1'>
            <div className='left-sidebar d-flex justify-content-center align-items-center'>
              <button className='collapsed leftbar' type="button" data-bs-toggle="collapse" data-bs-target="#leftBar" aria-controls="leftBar" aria-expanded="false" aria-label="Toggle navigation">
                <span className='"navbar-toggler-icon mb-2'></span>
                <span className='"navbar-toggler-icon mb-2'></span>
                <span className='"navbar-toggler-icon'></span>
              </button>
            </div>
          </div>
          <div className='divider'></div>
          <Link to="/" className='position-relative logosite'>
            <img src={logo} alt="Logo" width={auto} height={100} className='mw-auto' />
            <img src={logo1} alt="Logo" width={34} height={34} className='gifimg' />
            <div className='loghover shadow'>
              <div className='position-relative h-100 d-flex justify-content-center align-items-center'>
                <img src={logo2} alt="Logo" width={auto}  className='w-auto logtop' />
                <img src={logo1} alt="Logo" width={84} height={84} className='gifimg' />
              </div>
            </div>
          </Link>
          <div className='social-search w-100 ps-5'>
            <div className='position-relative ms-5 start-6'>
              <div className='hdrIcon d-flex justify-content-between align-items-center'>
                <SocialLink icon={faFacebookF} url="#" />
                <SocialLink icon={faInstagram} url="#" />
                <SocialLink icon={faTwitter} url="#" />
                <SocialLink icon={faLinkedin} url="#" />
                <SocialLink icon={faDribbble} url="#" />
                <SocialLink icon={faPinterest} url="#" />
                <SocialLink icon={faYoutube} url="#" />
              </div>
              <CircleMenu />
            </div>
            <div className='search-area ms-auto'>
              <form autoComplete="off">
                <div className='search-field d-flex justify-content-between align-items-center pe-4 ms-auto'>
                  <input placeholder={t('search_placeholder')} className="border-0" type="text" />
                  <IconBlue1s />
                </div>
              </form>
            </div>
            <div className='d-flex align-items-center'>
              {/* <SocialLink icon={faGlobe} url="#" /> */}
              {/* <div className="dropdown m-0">
                <span role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                  <Link to="#">
                    <FontAwesomeIcon icon={faGlobe} />
                  </Link>
                </span>
                <ul className="dropdown-menu bg shadow-4 border-0 px-2 py-0 br-10" aria-labelledby="dropdownMenuLink">
                  <NavItem as="li" className='my-2'><Link className="shadow-4 br-10 " to="#" onClick={changeLanguageHandler} >English</Link></li>
                  <NavItem as="li" className='my-2'><Link className="shadow-4 br-10" to="#" onClick={changeLanguageHandler} >German</Link></li>
                </ul>
              </div> */}
              <div className='languagesec mx-2'>
                {/* <Select options={options2} className="countryList countryList1 iconbg1 rounded-circle w-40 customWidth1" placeholder="" theme={theme1}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                  
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-blue"><IconBlue1s/></span>,
                }}  
              /> */}
                <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <FontAwesomeIcon icon={faGlobe} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item to="#">English</Dropdown.Item>
                    <Dropdown.Item to="#">Germany</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <button className='border-0 bg-transparent px-0' onClick={handleClick} >
                <span className='light-mode'>
                  {theme ? (
                    <Link className='lightColor'>
                      <i className="fa-sharp fa-solid fa-moon"></i>
                    </Link>
                  ) : (
                    <Link className='darkColor' >
                      <i className="fa-sharp fa-solid fa-sun"></i>
                    </Link>
                  )}
                </span>
              </button>
              <span>
                <Link to="" data-bs-toggle="modal" data-bs-target="#locked">
                  <FontAwesomeIcon icon={faLock} />
                </Link>
              </span>
              <span>
                <Link to="">
                  <IconBlue2s />
                </Link>
              </span>
              <span>
                <Link to="">
                  <IconBlue3s />
                </Link>
              </span>
              <SocialLink icon={faPaperPlane} url="/subcription" />
              <SocialLink icon={faEnvelope} url="/contact" />
              <div className="position-relative droptop px-1" id="dropbtn">
                  <button className="border-0 p-0 bg-transparent rounded-circle" type="button" onClick={handleClick5}>
                    <img src={user} alt='' width="50" height="55"  />
                  </button>
                  {toggle5 ?
                  <></>
                  :
                  <div className="end-0 menu top-80 position-absolute d-block border-0 bg-transparent">
                    <div className='position-relative'>
                      <Link
                      className='position-absolute top-0 pe-2 end-0 z-1 me-1'
                        
                        onClick={handleClick5}
                      >
                        <img src={closebtn} width="35" alt="" />
                      </Link>
                      <div className="newdrop8">
                        <div className='droplayout'>
                          <ul class="d-flex justify-content-center mb-4 px-4">
                            <li className='me-2'>
                              <div className='outerdiv'>
                                <div className="bs-primary circlediv flex-column rounded-circle d-flex justify-content-center align-items-center">
                                  <h4 className="m-0">547</h4>
                                  <p className="m-0">POSTS</p>
                                </div>
                              </div>
                            </li>
                            <li className='me-2'>
                              <div className='outerdiv position-relative'>
                                <div className="bs-primary circlediv flex-column rounded-circle d-flex justify-content-center align-items-center">
                                  <h4 className="m-0">1243</h4>
                                  <p className="m-0">FOLLOWERS</p>
                                </div>
                                <div className='statustop shadow-4'>
                                  <img src={state2} alt='' />
                                </div>
                                <div className='topfolow'>
                                  <div className='inerlayer'>
                                    <img src={img10} alt="" />
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className=''>
                              <div className='outerdiv'>
                                <div className="bs-primary circlediv flex-column rounded-circle d-flex justify-content-center align-items-center">
                                  <h4 className="m-0">698</h4>
                                  <p className="m-0">FOLLOWERS</p>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div className='usertop position-relative rounded-circle'>
                            <div className="imgin5">
                              <div className="position-relative mb-3">
                                <img src={userimg} alt="" />
                              </div>
                              <Link to="" className="icon1"><img src={fb} alt=""/></Link>
                              <Link to="" className="icon2"><img src={ins} alt=""/></Link>
                              <Link to="" className="icon3"><img src={whts} alt=""/></Link>
                              <Link to="" className="icon4"><img src={twi} alt=""/></Link>
                              <Link to="" className="icon5"><img src={canda} alt=""/></Link>
                              <Link to="" className="icon6"><img src={crown} alt=""/></Link>
                              <Link to="" className="icon7"><img src={tok} alt=""/></Link>
                              <Link to="" className="icon8"><img src={check} alt=""/></Link>
                              <Link to="" className="icon9"><img src={tube} alt=""/></Link>
                              <Link to="" className="icon10"><img src={linked} alt=""/></Link>
                              <Link to="" className="icon11"><img src={vip} alt=""/></Link>
                              <Link to="" className="icon12"><img src={pine} alt=""/></Link>
                            </div>
                          </div>
                          <div>
                            {handleView == ""  && 
                            <SimpleView1 setHandleView={setHandleView} />
                            }
                            
                            {handleView == 'video' && 
                            <VideoView setHandleView={setHandleView} />
                            }
                            {handleView == 'audio' &&
                            <AudioView setHandleView={setHandleView} />
                            }
                          </div>
                        </div>
                        {/* <div className='position-relative'>
                          <SubDropdown name="" boxid="livemeet" options={meetOptions} boxwidth="country customWidth text-end z-3 btnposition" />
                        </div> */}
                        {/* <DropdownStyle options={dropoption} boxwidth="country customWidth mb-4 w-100 mx-auto" /> */}
                        <div className=''>
                          <ul className="customDrop customDrop1s">
                              <div className="dropheight">
                                  <li>
                                    <div className="spansec">
                                      <div className="dropdiv d-flex align-items-center" >
                                        <span className="">
                                          <IconBlue20 className="me-2"/>
                                        </span><h6 className='m-0'>My Profile</h6> 
                                        <div className="toggle_btn_wrapper bg w-auto ms-2">
                                          <div className="form-check form-switch px-0">
                                              <input className="form-check-input d-block float-end" id='auto4' type="checkbox" role="switch"/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className='custafter'>
                                    <div className="newsecdrop">
                                      <div className="dropdiv w-100 px-0" >
                                        <SubDropdown name="My Availability Status" boxid="livemeet2" options={meetOptions} boxwidth="country customWidth " />
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="spansec">
                                      <div className="dropdiv" >
                                        <span className="">
                                        </span>My Followers
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="spansec">
                                      <div className="dropdiv" >
                                        <span className="">
                                        </span>My Likes
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="spansec">
                                      <div className="dropdiv" >
                                        <span className="">
                                        </span>My Notifications
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="spansec">
                                      <div className="dropdiv" >
                                        <span className="">
                                        </span>My Messages
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="spansec">
                                      <div className="dropdiv" >
                                        <span className="">
                                        </span>My Rewards
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="spansec">
                                      <div className="dropdiv" >
                                        <span className="">
                                        </span>My Appointments
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="spansec">
                                      <div className="dropdiv" >
                                        <span className="">
                                        </span>My To Do List
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="spansec">
                                      <div className="dropdiv" >
                                        <span className="">
                                        </span>My Sales
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="spansec">
                                      <div className="dropdiv" >
                                        <span className="">
                                        </span>My Account Settings
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="spansec">
                                      <div className="dropdiv d-flex align-items-center" >
                                        <span className="">
                                        </span><h6 className='m-0'>SignOut</h6>
                                        <div className="toggle_btn_wrapper bg w-auto ms-2">
                                          <div className="form-check form-switch px-0">
                                              <input className="form-check-input d-block float-end" id='auto4' type="checkbox" role="switch"/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                              </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                </div>
            </div>
          </div>
          <div className='divider me-0'></div>
          <div className='col-lg-1'>
            <div className='left-sidebar d-flex justify-content-center align-items-center'>
              <button className='collapsed leftbar' type="button" data-bs-toggle="collapse" data-bs-target="#rightBar" aria-controls="rightBar" aria-expanded="false" aria-label="Toggle navigation">
                <span className='"navbar-toggler-icon mb-2'></span>
                <span className='"navbar-toggler-icon mb-2'></span>
                <span className='"navbar-toggler-icon'></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
