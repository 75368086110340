import React from "react";
import '../../ServicePackagePlanThemes.scss'
import './RegularTheme6Desktop.scss';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useTranslation } from "react-i18next";

export default function RegularTheme6Desktop() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="">
        <div>
          <div className="main-content p-5">
            <div className="d-flex align-items-center justify-content-center mb-5">
              <div className="toggle_button">
                <button type="" className="btn active_btn">Monthly</button>
                <button type="" className="btn1 ">Yearly</button>
              </div>
            </div>
            <div className="main_wrapper mx-auto">
              <div className="plan_outer_wrapper mx-auto">
                <div className="content_wrapper_outer">
                <div className="content_wrapper">
                  <div className="d-flex align-items-center relative ">
                    <div class="plan_wrapper mx-auto">
                      <Tab.Container className="plan_tabs" id="" defaultActiveKey="Regular">

                        <div className="d-flex flex-column-reverse">
                          <Tab.Container id="left-tabs-example" defaultActiveKey="one">




                            <Tab.Content>
                              <Tab.Pane eventKey="one">
                                <div className="tab_content_wrapper">                                  
                              <div className="tab_content">
                                  <div className="">
                                    <h1>$99</h1>
                                    <h3>2X 30 Minutes Coaching Sessions</h3>
                                    <small>(cost per session)</small>
                                    <div className="mt-5">
                                      <h5>30 Minute Free Assessment Consultation (value $50)</h5>
                                      <ul>
                                        <li>SELF-IMAGE EVALUATION</li>
                                        <li>SELF-IMAGE SCORE</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                </div>
                                <div className="w-100 d-flex justify-content-center align-items-center tab_btn">
                                  <button type="" className="btn_left me-5 mt-5 mb-4">Questionnaire</button>
                                  <button type="" className="btn_right mt-5 mb-4 ms-5">BOOK NOW</button>

                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="self-talk">
                                <div className="tab_content_wrapper">
                              <div className="tab_content">
                                  <div className="">
                                    <h1>$99</h1>
                                    <h3>2X 30 Minutes Coaching Sessions</h3>
                                    <small>(cost per session)</small>
                                    <div className="mt-5">
                                      <h5>30 Minute Free Assessment Consultation (value $50)</h5>
                                      <ul>
                                        <li>SELF-IMAGE EVALUATION</li>
                                        <li>SELF-IMAGE SCORE</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                </div>
                                <div className="w-100 d-flex justify-content-center align-items-center tab_btn">
                                  <button type="" className="btn_left me-5 mt-5 mb-4">Questionnaire</button>
                                  <button type="" className="btn_right mt-5 mb-4 ms-5">BOOK NOW</button>

                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="world-perception">
                                <div className="tab_content_wrapper">
                              <div className="tab_content">
                                  <div className="">
                                    <h1>$99</h1>
                                    <h3>2X 30 Minutes Coaching Sessions</h3>
                                    <small>(cost per session)</small>
                                    <div className="mt-5">
                                      <h5>30 Minute Free Assessment Consultation (value $50)</h5>
                                      <ul>
                                        <li>SELF-IMAGE EVALUATION</li>
                                        <li>SELF-IMAGE SCORE</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                </div>
                                <div className="w-100 d-flex justify-content-center align-items-center tab_btn">
                                  <button type="" className="btn_left me-5 mt-5 mb-4">Questionnaire</button>
                                  <button type="" className="btn_right mt-5 mb-4 ms-5">BOOK NOW</button>

                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="Ideal-Self">
                                <div className="tab_content_wrapper">
                              <div className="tab_content">
                                  <div className="">
                                    <h1>$99</h1>
                                    <h3>2X 30 Minutes Coaching Sessions</h3>
                                    <small>(cost per session)</small>
                                    <div className="mt-5">
                                      <h5>30 Minute Free Assessment Consultation (value $50)</h5>
                                      <ul>
                                        <li>SELF-IMAGE EVALUATION</li>
                                        <li>SELF-IMAGE SCORE</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                </div>
                                <div className="w-100 d-flex justify-content-center align-items-center tab_btn">
                                  <button type="" className="btn_left me-5 mt-5 mb-4">Questionnaire</button>
                                  <button type="" className="btn_right mt-5 mb-4 ms-5">BOOK NOW</button>

                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                            <div className="d-flex align-items-center justify-center  w-100 mb-0 ">
                              <Nav variant="pills" className="w-100 d-flex  align-items-center justify-content-center">
                                <ul className="d-flex mt-5 align-items-center modal-tabs1 justify-content-center">
                                  <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
                                    <Nav.Link className="m-0 p-0 border-0" eventKey="one">
                                      <span className="d-flex align-items-center ">  
                                      Perception of Self
                                      </span>
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
                                    <Nav.Link className="m-0 p-0 border-0" eventKey="self-talk">
                                      <span className="d-flex align-items-center ">  
                                      Self-Talk
                                      </span>
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
                                    <Nav.Link className="m-0 p-0 border-0" eventKey="world-perception">
                                      <span className="d-flex align-items-center ">
                                      World Perception of You
                                        </span>
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
                                    <Nav.Link className="m-0 p-0 border-0" eventKey="Ideal-Self">
                                      <span className="d-flex align-items-center ">
                                      Ideal-Self
                                        </span>
                                    </Nav.Link>
                                  </Nav.Item>
                                </ul>
                              </Nav>
                            </div>
                          </Tab.Container>
                        </div>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>




    </div>



  );
}
