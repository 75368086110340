import React from "react";
import "./HireMeNavMob.scss";

import tile1 from "../../../../../../../images/hire-me/desktopview/tile1.png";
import tile2 from "../../../../../../../images/hire-me/desktopview/tile2.png";
import tile3 from "../../../../../../../images/hire-me/desktopview/tile3.png";
import tile4 from "../../../../../../../images/hire-me/desktopview/tile4.png";

const HireMeNavMob = ({ Regular, Neumorphic }) => {
  return (
    <>
      <div
        className={`position-nav-mob d-flex p-3 ${
          Regular ? "Regular-style" : Neumorphic ? "Neumorphic-style" : ""
        }`}
      >
        <div className="p-nav-mob-child">
          <div className="p-n">
            <img src={tile1} alt="" />
          </div>
          <div className="p-n">
            <img src={tile2} alt="" />
          </div>
        </div>
        <div className="p-nav-mob-child">
          <div className="p-n">
            <img src={tile3} alt="" />
          </div>
          <div className="p-n">
            <img src={tile4} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HireMeNavMob;
