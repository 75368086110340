import React from "react";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
// import { Link } from 'react-router-dom';
import ButtonBox from '../../../global/ButtonBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import ProfileEditOne from "./ProfileEditSingle/ProfileEditOne";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';

export default function ProfileEditTab() {
  const { t, i18n } = useTranslation();
  let {isVideoVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };
  return (
    <div>
      <div className="w-1200 mb-4 align-items-center mx-auto d-flex flex-column-reverse justify-content-between">     
        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto mb-5">
        <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
          <h3 className="mb-3 d-flex justify-content-between w-780 pe-0">{t('profile_section.enter_your_profile_details')} <small>{t('step')} <b>11</b> of 11</small></h3>
          <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="proOne" role="tabpanel" aria-labelledby="proOne">
                <ProfileEditOne />
              </div>
              <div className="tab-pane fade" id="proOne" role="tabpanel" aria-labelledby="proOne">

              </div>
          </div>
        </div>
        <span className="d-flex align-items-center justify-content-between w-100 mb-4">
          <div id="myTab1" className='' role="tablist">
            <button className="buttonArrow me-3 text-body border-0 bg br-10 active" id="proOne" data-bs-toggle="tab" data-bs-target="#proOne" type="button" role="tab" aria-controls="proOne" aria-selected="true">
              <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
            </button>
            <button className="buttonArrow me-3 text-body passwordTwo border-0 bg br-10" id="proOne" data-bs-toggle="tab" data-bs-target="#proOne" type="button" role="tab" aria-controls="proOne" aria-selected="false">
              <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
            </button>
          </div>
          <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="Saved" icon={faFloppyDisk} name="Save" />
        </span>
      </div>
    </div>   
  )
}
