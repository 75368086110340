import React from "react";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function SocialPage1() {
  const { t, i18n } = useTranslation();


  return (
    <div className="shadow-4 br-20 p-20 mt-3 gradient-box h-860">
      <h3 className="mb-4"> {t('profile_section.describe_yourself_in_240_characters')}</h3>
      <ul>
        <NavItem as="li" className="d-flex align-items-center mb-20">
          <label> {t('profile_section.hi_there_i_am')}</label>
          <input
            placeholder={t('profile_section.current_job_title_company')}
            className="shadow-5in br-10 w-400 mx-4"
            type="text"
          />
          <label className="bg shadow-none">{t('profile_section.and')}</label>
        </NavItem>
        <NavItem as="li" className="mb-20">
          <textarea
            placeholder={t('profile_section.one_liner_with_whats_most_likely_to_interest_my_profile_viewers')}
            className="shadow-5in br-20 h-100s w-100"
            type="text"
          ></textarea>
        </NavItem>
        <NavItem as="li" className="d-flex align-items-center mb-20">
          <label>{t('profile_section.in_my')}</label>
          <input
            placeholder="#"
            className="shadow-5in br-5 w-50s mx-4"
            type="text"
          />
          <label className="bg shadow-none">{t('profile_section.of_years_working_in')}</label>
          <input
            placeholder={t('profile_section.industry_role')}
            className="shadow-5in br-10 w-200 mx-4"
            type="text"
          />
          <label className="bg shadow-none">{t('profile_section.i_have_become_an_expert_in')}</label>
        </NavItem>
        <NavItem as="li" className="d-flex align-items-center justify-content-between mb-20">
          <input
            placeholder={t('profile_section.area_1')}
            className="shadow-5in br-10 w-200"
            type="text"
          />
          <input
            placeholder={t('profile_section.area_2')}
            className="shadow-5in br-10 w-200"
            type="text"
          />
          <input
            placeholder={t('profile_section.area_3')}
            className="shadow-5in br-10 w-200"
            type="text"
          />
        </NavItem>
        <NavItem as="li" className="d-flex flex-wrap align-items-center justify-content-between mb-20">
          <label className="w-100 mb-10">{t('profile_section.my_proudest_accomplishments_are')}</label>
          <input
            placeholder={t('profile_section.example_1')}
            className="shadow-5in br-10 w-200"
            type="text"
          />
          <input
            placeholder={t('profile_section.example_2')}
            className="shadow-5in br-10 w-200"
            type="text"
          />
          <input
            placeholder={t('profile_section.example_3')}
            className="shadow-5in br-10 w-200"
            type="text"
          />
        </NavItem>
        <NavItem as="li" className="mb-20">
          <label className="w-100 mb-10">{t('profile_section.skills_qualifications')}</label>
          <input
            placeholder={t('profile_section.skills_1')}
            className="shadow-5in br-10 w-100 mb-20"
            type="text"
          />
          <input
            placeholder={t('profile_section.skills_2')}
            className="shadow-5in br-10 w-100 mb-20"
            type="text"
          />
          <input
            placeholder={t('profile_section.skills_3')}
            className="shadow-5in br-10 w-100"
            type="text"
          />
        </NavItem>
        <NavItem as="li" className="mb-20">
          <label className="w-100 mb-10">{t('profile_section.dont_take_my_word_for_it')}</label>
          <input
            placeholder={t('profile_section.social_proof_link_or_testimonial_page_link')}
            className="shadow-5in br-10 w-100"
            type="text"
          />
        </NavItem>
      </ul>
    </div>
  );
}
