import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import React from 'react';

export default function SocialLink(props) {
  return (
    <div>
      <span>
        <Link to={props.url}>
          <FontAwesomeIcon icon={props.icon}  />
        </Link>
      </span>
    </div>
  )
}
