import React from "react";
import ButtonBox from "../../../global/ButtonBox";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import './LogoStep.scss';
import DropdownBox from '../../../global/DropdownBox';
export default function LogoForm1() {

  const { t, i18n } = useTranslation();
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });
  const fashionOptions = [
    {
      "name": "FASHION",
    },
    {
      "name": "BEAUTY",
    },
    {
      "name": "NUTRITION",
    },
    {
      "name": "FITNESS",
    },
    {
      "name": "TRAVEL",
    },
    {
      "name": "HOME DECOR",
    },
    {
      "name": "SPIRITUALITY",
    },
    {
      "name": "COACHING",
    },
    {
      "name": "EVENT PLANNING",
    },
    {
      "name": "ANIMAL",
    },
    {
      "name": "FINANCE",
    },
    {
      "name": "PARENTING",
    },
  ]
  
  return (
    <div className="">
   
   <div className="row">
                  <div className="col-lg-6">
                    <li className="mb-4">
                      <label className="mb-1">  {t('first_name')}</label>
                      <input placeholder={t('choose_your_logo_component.diva_boss')} className='shadow-5in border-5 br-10  mb-0 me-3' type="text"></input>

                    </li>
                  </div>
                  <div className="col-lg-6">
                    <li className="mb-4">
                      <label className="mb-1">  {t('last_name')}</label>
                      <input placeholder={t('choose_your_logo_component.boss')} className=' border-5 shadow-5in br-10  mb-0 me-3' type="text"></input>

                    </li>
                  </div>
                  <div className="col-lg-12">
                    <li className="mb-4">
                      <label className="mb-1">  {t('choose_your_logo_component.your_slogan_tagline')}</label>
                      <input placeholder={t('choose_your_logo_component.boss_up_your_leadership')} className='shadow-5in border-5 br-10  mb-0 me-3' type="text"></input>

                    </li>
                  </div>

                  <div className="col-lg-6">
                    <li className="mb-4">
                      <label className="mb-1">  {t('choose_your_logo_component.your_job_title_position')}</label>
                      <input placeholder={t('choose_your_logo_component.dyna')} className='shadow-5in border-5 br-10  mb-0 me-3' type="text"></input>

                    </li>
                  </div>
                  <div className="col-lg-6">
                    <li className="mb-4">
                      <label className="mb-1">  {t('choose_your_logo_component.your_company_name')}</label>
                      <input placeholder={t('choose_your_logo_component.your_company_name')} className=' border-5 shadow-5in br-10  mb-0 me-3' type="text"></input>

                    </li>
                  </div>
                  <div className="col-lg-6">
                    <li className="mb-4">
                      <label className="mb-1">  {t('choose_your_logo_component.enter_your_business_niche')}</label>
                      <input placeholder={t('choose_your_logo_component.women_fashion')} className='shadow-5in border-5 br-10 mt-4 mb-0 me-3' type="text"></input>

                    </li>
                  </div>
                  <div className="col-lg-6">
                    <li className="mb-4">
                    
                      <div className=" position-relative shadow-fix">
                      <label className="mb-2">  {t('choose_your_logo_component.industry')}</label>   
                                           {/* <Select
                            options={options} isSearchable={false}
                            className="country mb-4 w-100 mx-auto text-center"
                            placeholder={t('timeline_form_component.fashion_placeholder')}
                            theme={theme}
                            styles={{
                                option: (base) => ({
                                    ...base,
                                    borderRadius: "30px",
                                    width: "94%",
                                    maxheight: "50px",
                                    lineHeight: "32px",
                                    margin: "10px",
                                    cursor: 'pointer',
                                    
                                    boxShadow:
                                        "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                }),
                            }}
                        /> */}
                    <DropdownBox name="Fashion" boxid="fashion"  options={fashionOptions} boxwidth="w-100 mx-auto mt-4"/>

                    </div>


                    </li>
                  </div>
                  <label className="mb-1">  {t('choose_your_logo_component.keywords_adjectives')}</label>
                  <div className="col-lg-4">
                    <li className="mb-4">

                      <input placeholder={t('choose_your_logo_component.fashion')} className='shadow-5in border-5 br-10  mb-0 me-3' type="text"></input>

                    </li>
                  </div>
                  <div className="col-lg-4">
                    <li className="mb-4">

                      <input placeholder={t('choose_your_logo_component.keywords_adjectives')} className=' border-5 shadow-5in br-10  mb-0 me-3' type="text"></input>

                    </li>
                  </div>
                  <div className="col-lg-4">
                    <li className="mb-4">

                      <input placeholder={t('choose_your_logo_component.fashion_women_style')} className=' border-5 shadow-5in br-10  mb-0 me-3' type="text"></input>

                    </li>
                  </div>
                </div>
                <ul>
                  <li className=" text-center time-btn color-whitee">

                    <ButtonBox

                      boxwidth="w-200 mt-0 mb-50 mx-auto "
                      clicked="Generating logo"
                      name=" Susrprise me"
                    />
                  </li>
                </ul>

    </div>
  );
}
