import React from "react";
import '../EmailSignatureStep.scss';
import ButtonBox from "../../../../global/ButtonBox";
import Contest3 from '../../../../../images/img29sss.png';
import label1 from '../../../../../images/img31s.png';
import user4 from '../../../../../images/img43s.png';
import { Link } from 'react-router-dom';

// import Select from 'react-select';
import { ReactComponent as IconBlue14 } from "../../../../../images/icons/icon41.svg";
import { ReactComponent as IconBlue15 } from "../../../../../images/icons/icon42.svg";
import { ReactComponent as IconBlue16 } from "../../../../../images/icons/icon43.svg";
import { ReactComponent as IconBlue17 } from "../../../../../images/icons/icon44.svg";
import { ReactComponent as IconBlue32 } from "../../../../../images/slider1/fb.svg";
import { ReactComponent as IconBlue33 } from "../../../../../images/slider1/insta.svg";
import { ReactComponent as IconBlue34 } from "../../../../../images/slider1/twitter.svg";
import { ReactComponent as IconBlue35 } from "../../../../../images/slider1/linkedin.svg";
import { ReactComponent as IconBlue36 } from "../../../../../images/slider1/social.svg";
import { ReactComponent as IconBlue37 } from "../../../../../images/slider1/dribble.svg";
import { ReactComponent as IconBlue38 } from "../../../../../images/slider1/tiktok.svg";
import { ReactComponent as IconBlue39 } from "../../../../../images/slider1/pinterest.svg";
import { ReactComponent as IconBlue40 } from "../../../../../images/slider1/youtube.svg";
import EmailTemp from "../../../../global/EmailTemp";
import EmailTempBm from "../../../../global/EmailTempBm";

export default function EmailDesign4() {

  return (
    <div className="w-98 pt-2">
      {/* 4th section */}
      <div className="shadow-4 br-20 pb-30 mb-5 emailfourth">
        <div className="row pt-4 pb-5 justify-content-center align-items-center">
          <div className="bg shadow-4 p-1 h-70 w-100x br-8">
            <img src={label1} alt=""/>
          </div>
          <div className="ms-4 py-2 px-3 h10s br-30 shadow-4 tophead h-30">
            The Best Alternative Lifestyle to the 9 to 5 Grind.
          </div>
        </div>
        <div className="px-30">
          <div className="shadow-4 br-10 mb-40 p-4 pb-40 h-360">
            <div className="row mx-0">
              <div className="col-md-6 pe-2 ps-0">
                <div className="p-3 shadow-4 br-15 text-center">
                  <h3 className="mb-2 pb-1">Dyna Appolon</h3>
                  <h6 className="h7 text-uppercase pb-2">CEO | Social Network Strategist</h6>
                  <div className="shadow-4 br-10 p-10">
                    <ul className="d-flex flex-wrap text-start emaillist">
                      <li className="w-50 pe-3">
                        <h4>Industry:</h4>
                        <p>Social Network</p>
                      </li>
                      <li className="w-50 ps-3">
                        <h4>Department:</h4>
                        <p>Startup</p>
                      </li>
                    </ul>
                    <div className="px-3 pt-4 text-start">
                      <h5 className="py-1 h7 d-flex align-items-center m-0 mb-2"><IconBlue15 className="me-2" /> 1-800-000-00-00</h5>
                      <h5 className="py-1 h7 d-flex align-items-center m-0 mb-2"><IconBlue17 className="me-2"/> dyna@7figurebosses.com</h5>
                      <h5 className="py-1 h7 d-flex align-items-center m-0 mb-2"><IconBlue16 className="me-2"/> 9to5escapee.com </h5>
                      <h5 className="py-1 h7 d-flex align-items-center m-0"><IconBlue14 className="me-2"/><span className="w-90e">123 Success Rd, Take Action City, Freedom Country M6Y 10T </span></h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 p-0 position-relative">
                <div className="shadow-5in p-10 br-10 customimg">
                  <img src={user4} alt="" className="br-10 h-420 w-100 object-cover" />
                </div>
              </div>
              <div className="col-md-1 ps-2 pe-0">
                <div className="w-38 shadow-4 h-100 br-30 d-flex flex-column justify-content-center align-items-center">
                  <Link to="#" className="mb-2"><IconBlue32 /></Link>
                  <Link to="#" className="mb-2"><IconBlue33 /></Link>
                  <Link to="#" className="mb-2"><IconBlue34 /></Link>
                  <Link to="#" className="mb-2"><IconBlue35 /></Link>
                  <Link to="#" className="mb-2"><IconBlue36 /></Link>
                  <Link to="#" className="mb-2"><IconBlue37 /></Link>
                  <Link to="#" className="mb-2"><IconBlue38 /></Link>
                  <Link to="#" className="mb-2"><IconBlue39 /></Link>
                  <Link to="#" ><IconBlue40 /></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow-4 br-10 d-inline-block w-100 text-center">
            <div className="shadow-4 br-10 h8 p-3 my-3 d-inline-block w-520 mx-auto">
              Mission Statement: I help people quit their 9 to 5 jobs and launch, manage, and scale up their online business.
            </div>
            <div className="br-5 p-4 h-85 w-100 position-relative bg shadow-4 d-flex justify-centent-center align-items-center ">
              <img src={Contest3} alt="" className="mx-auto position-absolute top-15 start-0 end-0" />
              <p className="h6 px-4 text-start lh-normal mb-0 w-100 text-center">“I never dreamed about success. I worked for it.” Estee Lauder</p>
            </div>
          </div>
        </div>
        <div className="silverClr">
          <EmailTemp />
        </div>
      </div>
      <div className="shadow-4 mb-5 silverClr">
        <EmailTempBm />
      </div>
    </div>
  );
} 
