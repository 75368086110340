import { faCalendar, faLock, faCloudDownload, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import Merch from '../../../../images/merch.png';
import ButtonBox from "../../../global/ButtonBox";
import "./TrustPilotBusinessAccount.scss"
import { ReactComponent as Download } from "../../../../images/icons/icon29.svg";

export default function TrustPilotBusinessAccountForm_1() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });

    return (
        <div className="pb-5 pt-2">
            <div className="row mb-4 px-3">
                <div className="col-md-4 px-0">
                    <label className="mb-1">{t('timeline_trustpilot_business_account_component.capterra_reviews_registration')}</label>
                    <span className="shadow-4 h-50s br-3  d-flex justify-content-start px-3 align-items-center text_blue text-center h6">
                        <img src={Merch} alt="" className="w-35 me-3" />
                        Registration Link</span>
                </div>
                <div className="col-md-8">
                    <label className="mb-1">{t('timeline_trustpilot_business_account_component.your_capterra_accont_email')}</label>
                    <input placeholder={t('timeline_trustpilot_business_account_component.your_capterra_accont_email_placeholder')} className='shadow-5in br-5 h6 mb-0' type="email" />
                </div>
            </div>
            <div className="row mb-4 px-3">
                <div className="col-md-12 d-flex justify-content-between px-0">
                    <label className="mb-3">{t('timeline_trustpilot_business_account_component.your_capterra_business_account_review_account_link')}</label>
                    <div className="flex items-center">
                        <span className="QR_label">Save QR code</span>
                        <div className="download_icon">
                            {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                            <Download />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 px-0">
                    <div className="row px-0">
                        <div className="col-md-1 px-0">
                            <span className="shadow-4 h-50s br-3  d-flex justify-content-start px-3 align-items-center text-secondary text-center h6">
                                <FontAwesomeIcon icon={faLink} className=" icon" />
                            </span>
                        </div>
                        <div className="col-md-11">
                            <div class="d-flex justify-content-center">
                                <div class="input-group">
                                    <input
                                        type="text"
                                        class="form-control shadow-5in br-5 h6 mb-0"
                                        placeholder={t('timeline_trustpilot_business_account_component.your_capterra_business_account_review_account_link_placeholder')}
                                        aria-label="Example input"
                                        aria-describedby="button-addon1"
                                    />
                                    <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                                        Generate QR code
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-4 px-3 justify-content-end  align-items-center">
                <div className="QR_outer_div ms-auto">
                    <div className="QR_box">
                        <FontAwesomeIcon icon={faLock} className=" icon" />
                    </div>
                </div>
            </div>
            <div className=" text-center time-btn rotate_btn">

                <ButtonBox
                    boxwidth="w-360 text_light mt-4 mb-50 mx-auto"
                    clicked="Confirm Trustpilot Business Review Account"
                    name="Confirm Trustpilot Business Review Account"
                />
            </div>
        </div>
    );
}
