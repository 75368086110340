import React from "react";
import { Link } from 'react-router-dom';

export default function PersonalityZodicCircle(props) {
  return (
    <div className="w-100 h-125 rounded-circle  me-3">
      <Link to="" className="bgGold shadow-4 rounded-circle bg w-100 h-100 text-center d-flex justify-content-center align-items-center flex-column">
          <img src={props.img} alt="" width="auto" height="30px" />
          <h5 className="h6 my-1">{props.name}</h5>
          <h6 className="h10">{props.date}</h6>
      </Link>
    </div>
  );
}
