import React from 'react';
import './TimelineTab.scss';
import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

library.add(faCaretRight);


export default function TimelineTabDesktop() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });


    return (
        <div>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                <div className='desktop' >
                    <div className='flex'>

                        <div className=''>

                            <div className='scoll-class class-width-head' >
                                <div className='flex'>
                                    <div className="ProgressBar-timeline">

                                        <Nav variant="pills" className="flex-column">
                                            <ul className=''>

                                                <Link className="card-1" to="/niche-clarity-discovery">
                                                    <NavItem as="li" ><h6>
                                                        1
                                                    </h6></NavItem>
                                                </Link>
                                                <Link className="card-1" to="/market-research-size-validation">
                                                    <NavItem as="li" ><h6>
                                                        2
                                                    </h6></NavItem>
                                                </Link>
                                                <Link className="card-1" to="">
                                                    <NavItem as="li" ><h6>
                                                        3
                                                    </h6></NavItem>
                                                </Link>
                                                <Link className="card-1" to="">
                                                    <NavItem as="li" ><h6>
                                                        4
                                                    </h6></NavItem>
                                                </Link>
                                                <Link className="card-1" to="">
                                                    <NavItem as="li" ><h6>
                                                        5
                                                    </h6></NavItem>
                                                </Link>
                                                <Link className="card-1" to="">
                                                    <NavItem as="li" ><h6>
                                                        6
                                                    </h6></NavItem>
                                                </Link>
                                                <Link className="card-1" to="/pick-business-address">
                                                    <NavItem as="li" ><h6>
                                                        7
                                                    </h6></NavItem>
                                                </Link>
                                                <Link className="card-1" to="/secure-business-phone-number">
                                                    <NavItem as="li" ><h6>
                                                        8
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/business-email-address">
                                                    <NavItem as="li"   ><h6>   9
                                                    </h6></NavItem>
                                                </Link>
                                                <Link className="card-1" to="/logo">

                                                    <NavItem as="li"   ><h6>     10
                                                    </h6></NavItem>

                                                </Link>

                                                <Link className="card-1" to="/profile">
                                                    <NavItem as="li" ><h6>
                                                        11
                                                    </h6></NavItem>
                                                </Link>
                                                <Link className="card-1" to="">
                                                    <NavItem as="li" ><h6>
                                                        12
                                                    </h6></NavItem>
                                                </Link>


                                                <Link to="/electronic-signature">

                                                    <NavItem as="li"   ><h6>     13
                                                    </h6></NavItem>

                                                </Link>
                                               
                                                <Link to="/email-signature">
                                                    <NavItem as="li"   ><h6>   14
                                                    </h6></NavItem>
                                                </Link>

                                                <Link to="/digital-business-card">
                                                    <NavItem as="li"   ><h6>     15
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="">
                                                    <NavItem as="li"   ><h6>     16
                                                    </h6></NavItem>
                                                </Link>
                                                <Link className='card-1 ' to="/custom-domain">
                                                    <NavItem as="li"   ><h6>   17
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/website-design-style">
                                                    <NavItem as="li"   ><h6>     18
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/profile-hero-banner">

                                                    <NavItem as="li"   ><h6>     19
                                                    </h6></NavItem>

                                                </Link>
                                                <Link to="/how-it-works">
                                                    <NavItem as="li"   ><h6>     20
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/service-package-plans">
                                                    <NavItem as="li"   ><h6>     21
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/hire-me">
                                                    <Nav.Item>
                                                        <NavItem as="li"   ><h6>     22
                                                        </h6></NavItem>
                                                    </Nav.Item>
                                                </Link>
                                                <Link to="/testimonial-case-study">
                                                    <NavItem as="li"   ><h6>     23
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/subscriptions">
                                                    <NavItem as="li"   ><h6>     24
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/blog-posts">
                                                    <NavItem as="li"   ><h6>     25
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/podcast">
                                                    <NavItem as="li"   ><h6>    26
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/video-playlist">
                                                    <NavItem as="li"   ><h6>     27
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/events">
                                                    <NavItem as="li"   ><h6>     28
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/freebies">
                                                    <NavItem as="li"   ><h6>     29
                                                    </h6></NavItem>
                                                </Link>

                                                <Link to="/featured-on">
                                                    <NavItem as="li"   ><h6>     30
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/e-magazine">
                                                    <NavItem as="li"   ><h6>     31
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/press-release">
                                                    <NavItem as="li"   ><h6>     32
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/contests">
                                                    <NavItem as="li"   ><h6>     33
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/portfolio">
                                                    <NavItem as="li"   ><h6>     34
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/meet-our-team">
                                                    <NavItem as="li"   ><h6>     35
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/faqs">
                                                    <NavItem as="li"   ><h6>     36
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/sponsorships">
                                                    <NavItem as="li"   ><h6>     37
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/online-course">
                                                    <NavItem as="li"   ><h6>     38
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/online-store">
                                                    <NavItem as="li"   ><h6>     39
                                                    </h6></NavItem>
                                                </Link>

                                                <Link to="/contact-us">
                                                    <NavItem as="li"   ><h6>     40
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/resume">
                                                    <NavItem as="li"   ><h6>     41
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="/weekend-mode">
                                                    <NavItem as="li"   ><h6>     42
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="">
                                                    <NavItem as="li"   ><h6>     43
                                                    </h6></NavItem>
                                                </Link>
                                                <Link to="">

                                                    <NavItem as="li"   ><h6>     44
                                                    </h6></NavItem>

                                                </Link>
                                                <Link to="/affilate-programs">
                                                    <NavItem as="li"   ><h6>     45
                                                    </h6></NavItem>
                                                </Link>
                                                <Nav.Item>
                                                    <NavItem as="li"   ><h6>     46
                                                    </h6></NavItem>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <NavItem as="li"   ><h6>     47
                                                    </h6></NavItem>
                                                </Nav.Item>
                                                {/* <Link to="/social-media">
                                                    <NavItem as="li"   ><h6>   41
                                                    </h6></NavItem>
                                                </Link> */}
                                                <Link to="">
                                                    <NavItem as="li"   ><h6>     48
                                                    </h6></NavItem>
                                                </Link>

                                                <Nav.Item>
                                                    <NavItem as="li"   ><h6>     49
                                                    </h6></NavItem>
                                                </Nav.Item>
                                                <Link to="/elevator-pitch">

                                                    <NavItem as="li"   ><h6>     50
                                                    </h6></NavItem>

                                                </Link>
                                                <Link to="">

                                                    <NavItem as="li"   ><h6>     51
                                                    </h6></NavItem>

                                                </Link>
                                                <Link to="">

                                                    <NavItem as="li"   ><h6>     52
                                                    </h6></NavItem>

                                                </Link>
                                                <Link to="">

                                                    <NavItem as="li"   ><h6>     53
                                                    </h6></NavItem>

                                                </Link>
                                                <Link to="/trust-pilot-business-account">

                                                    <NavItem as="li"   ><h6>     54
                                                    </h6></NavItem>

                                                </Link>
                                                <Nav.Item>
                                                    <NavItem as="li"   ><h6>     55
                                                    </h6></NavItem>
                                                </Nav.Item>

                                            </ul>
                                        </Nav>

                                    </div>
                                    <div className='card-width newbarTab'>
                                        <Link to="/niche-clarity-discovery">
                                            <div className='card-tab active-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        "Niche Clarity" Discovery
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/market-research-size-validation">
                                            <div className='card-tab active-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        "Market Research" & Size Validation
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="">
                                            <div className='card-tab active-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        Buyer Persona & Customer Segmentation
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="">
                                            <div className='card-tab active-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        "Buyer's Journey" Mapping
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="">
                                            <div className='card-tab active-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        Branding Framework & Design Packages
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="">
                                            <div className='card-tab active-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        Business "Strategic Plan"
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/pick-business-address">
                                            <div className='card-tab active-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        Pick Your "Business Address"
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/secure-business-phone-number">
                                            <div className='card-tab active-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        Secure Your "Business Phone Number"
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/business-email-address">
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>
                                                            {t('timeline_tab_component.generate_your_business_email_address')}
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/logo">
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>
                                                        <h5>
                                                            Generate Your “Business Logo” | Favicon Icon</h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                       
                                        <Link to="/profile">
                                            <div className='card-tab active-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                           Complete Your "Business Profile"
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>

                                        <Link to="">
                                            <div className='card-tab active-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        Secure Business VPN | Virtual Private Network
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                       
                                        <Link to="/electronic-signature">
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            {t('timeline_tab_component.generate_your_electronic_signature')}
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>

                                        
                                        <Link to="/email-signature">
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            {t('timeline_tab_component.generate_your_email_signature')}
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>

                                        <Link to="/digital-business-card">
                                            <div className='card-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            Generate your
                                                            “Digital Business Card”
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="">
                                            <div className='card-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        Step-up Your Appointment Booking & Live Chat
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>


                                        <Link to="/custom-domain">
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>
                                                        Secure Your Business Domain & Hosting & Site General Settings

                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/website-design-style">
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        Choose Your Website: Design Theme & Layout
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/profile-hero-banner" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            {t('timeline_tab_component.choose_your_website_hero_banner_and_aboutme_section')}
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/how-it-works" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            {t('timeline_tab_component.choose_your_how_it_works_section')}
                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/service-package-plans" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            {t('timeline_tab_component.choose_your_service_package_plans_section')}

                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/hire-me" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            Choose Your “Hire Me”
                                                            “Appointment Booking” section

                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/testimonial-case-study" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>
                                                            Choose your “Testimonials |
                                                            Case Studies | wall of love” section


                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/subscriptions" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            {t('timeline_tab_component.choose_your_subscription_section')}


                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/blog-posts" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>
                                                            {t('timeline_tab_component.choose_your_blog_post_style_section')}

                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/podcast" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            {t('timeline_tab_component.choose_your_podcast_series_style_section')}

                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/video-playlist" >
                                            <div className='card-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            {t('timeline_tab_component.choose_your_video_playlist_style_section')}

                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/events" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>
                                                            {t('timeline_tab_component.choose_your_events_section')}

                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/freebies" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            {t('timeline_tab_component.choose_your_freebies_section')}

                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/featured-on" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>
                                                            {t('timeline_tab_component.Choose_your_as_seen_featured_on_section')}

                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/e-magazine" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>
                                                            {t('timeline_tab_component.choose_your_emagazines_section')}


                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/press-release" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            {t('timeline_tab_component.choose_your_press_release_section')}

                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/contests" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>
                                                            {t('timeline_tab_component.choose_your_contests_section')}

                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/portfolio" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            {t('timeline_tab_component.choose_your_portfolio_section')}


                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/meet-our-team" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            {t('timeline_tab_component.choose_your_team_section')}


                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/faqs" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>
                                                            Generate your Business “FAQ’s” section


                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/sponorships" >
                                            <div className='card-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            Choose your “Sponsorship | collab” section


                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/online-course" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            Choose your “Online Course” section



                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/online-store" >
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            {t('timeline_tab_component.choose_your_online_store_section')}


                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/contact-us">
                                        <div className='card-tab '>
                                            <div className='flex justifY-space'>
                                                <div className='card-text'>

                                                    <h5>

                                                        Choose Your “Contact Me” |
                                                        “Live Chat” section



                                                    </h5>
                                                </div>
                                                <div className='card-progress'>
                                                    <div className='progress-line'></div>
                                                    <div className='circles-small'>
                                                        <div className='circle-small'>
                                                            <div className='text'><span className='d-block'>100%</span></div>
                                                            <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        </Link>
                                        <Link to="/resume">
                                        <div className='card-tab '>
                                            <div className='flex justifY-space'>
                                                <div className='card-text'>

                                                    <h5>

                                                        Create Your ”Jobs & Career” |
                                                        “Resume | CV” Section


                                                    </h5>
                                                </div>
                                                <div className='card-progress'>
                                                    <div className='progress-line'></div>
                                                    <div className='circles-small'>
                                                        <div className='circle-small'>
                                                            <div className='text'><span className='d-block'>100%</span></div>
                                                            <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        </Link>
                                        <Link to="/weekend-mode">
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            Choose your
                                                            “Weekend | Social Mode” section



                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <div className='card-tab '>
                                            <div className='flex justifY-space'>
                                                <div className='card-text'>

                                                    <h5>

                                                    Choose Your "Website Legal Terms" & "Cookie Banner & Settings"



                                                    </h5>
                                                </div>
                                                <div className='card-progress'>
                                                    <div className='progress-line'></div>
                                                    <div className='circles-small'>
                                                        <div className='circle-small'>
                                                            <div className='text'><span className='d-block'>100%</span></div>
                                                            <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <Link to=''>
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            Generate  "Bio Links”
                                                            For All Of Your Business



                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="/affiliate-programs">
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            Set Up Your “affiliate programs”


                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                       
                                        <div className='card-tab '>
                                            <div className='flex justifY-space'>
                                                <div className='card-text'>

                                                    <h5>

                                                        All SEO: Google | yahoo | Bing | Baidu
                                                    </h5>
                                                </div>
                                                <div className='card-progress'>
                                                    <div className='progress-line'></div>
                                                    <div className='circles-small'>
                                                        <div className='circle-small'>
                                                            <div className='text'><span className='d-block'>100%</span></div>
                                                            <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='card-tab '>
                                            <div className='flex justifY-space'>
                                                <div className='card-text'>

                                                    <h5>

                                                        Featured Your Site on
                                                        “Beta-List StartUps”


                                                    </h5>
                                                </div>
                                                <div className='card-progress'>
                                                    <div className='progress-line'></div>
                                                    <div className='circles-small'>
                                                        <div className='circle-small'>
                                                            <div className='text'><span className='d-block'>100%</span></div>
                                                            <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='card-tab '>
                                            <div className='flex justifY-space'>
                                                <div className='card-text'>

                                                    <h5>

                                                        Introduce Your Business to
                                                        “100 Pocket-List” Prospects


                                                    </h5>
                                                </div>
                                                <div className='card-progress'>
                                                    <div className='progress-line'></div>
                                                    <div className='circles-small'>
                                                        <div className='circle-small'>
                                                            <div className='text'><span className='d-block'>100%</span></div>
                                                            <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='card-tab '>
                                            <div className='flex justifY-space'>
                                                <div className='card-text'>

                                                    <h5>

                                                        Claim Your “Virtual Office Booth” | Generate Your “Avatar”


                                                    </h5>
                                                </div>
                                                <div className='card-progress'>
                                                    <div className='progress-line'></div>
                                                    <div className='circles-small'>
                                                        <div className='circle-small'>
                                                            <div className='text'><span className='d-block'>100%</span></div>
                                                            <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        {/* <Link to="/social-media">
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>
                                                            {t('timeline_tab_component.choose_all_your_social_media_business_account_profile')}


                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link> */}
                                        <Link to="/elevator-pitch">
                                            <div className='card-tab '>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>
                                                            Generate Your 15 Second
                                                            “Elevator Pitch | Slide Deck”



                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <div className='card-tab '>
                                            <div className='flex justifY-space'>
                                                <div className='card-text'>

                                                    <h5>

                                                        Generate "qr codes”
                                                        for all Of Your business


                                                    </h5>
                                                </div>
                                                <div className='card-progress'>
                                                    <div className='progress-line'></div>
                                                    <div className='circles-small'>
                                                        <div className='circle-small'>
                                                            <div className='text'><span className='d-block'>100%</span></div>
                                                            <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <Link to="">
                                            <div className='card-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                            Generate An “App” for your Business


                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="">
                                            <div className='card-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        Preview Your Website & Front-End Customization Settings


                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="">
                                            <div className='card-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        3-hours Biz Launch Challenge
                                  "Certificate Award"

                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                        <Link to="">
                                            <div className='card-tab'>
                                                <div className='flex justifY-space'>
                                                    <div className='card-text'>

                                                        <h5>

                                                        Step-Up Your Client Portal

                                                        </h5>
                                                    </div>
                                                    <div className='card-progress'>
                                                        <div className='progress-line'></div>
                                                        <div className='circles-small'>
                                                            <div className='circle-small'>
                                                                <div className='text'><span className='d-block'>100%</span></div>
                                                                <svg><circle className="bg" cx="30" cy="30" r="20"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>





            </Tab.Container>

        </div>
    )
}