import * as React from 'react';
import ButtonBox from '../global/ButtonBox';
import User from '../../images/img1.png';
import '../login/login.scss';
import { NavItem } from 'react-bootstrap';

export default function LockScreen() {
    return (
        <div className='text-center'>
            <div className='uploadImage mx-auto mb-4'>
                <label htmlFor="profile_pic" className='p-0'>
                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center" >
                        <img src={User} alt="" className='ImageCover' />
                    </div>
                </label>
                <input className="hidden" type="file" name="" id="profile_pic" accept="image/*" />
            </div>
            <h3 className='mb-4'>LOCK SCREEN</h3>
            <ul className='pb-5'>
                <NavItem as="li" className='mb-4'>
                    <input placeholder="Enter your password" className="shadow-5in br-10 w-500" type="password" />
                </NavItem>
                <NavItem as="li"  className='modal-btn'>
                    <ButtonBox boxwidth="w-110 mt-0 mb-50" modal="modal" modalId="#lockpopup" clicked="UNLOCKED" name="UNLOCK" />
                </NavItem>
            </ul>
            {/* <!-- Modal --> */}
            

        </div>

        
    );
}