import React from 'react'
import IntegAddFrom from '../IntegAddFrom';
import fb from "../../../../../../images/formicon/icon21.png";
import linkedin from "../../../../../../images/formicon/icon26.png";
import Twitter from "../../../../../../images/formicon/icon22.png";
import Pinterest from "../../../../../../images/formicon/icon24.png";
import TikTok from "../../../../../../images/formicon/icon27.png";
import Instagram from "../../../../../../images/formicon/icon23.png";
import YouTube from "../../../../../../images/formicon/icon25.png";
import Snapchat from "../../../../../../images/formicon/icon29.png";
import Twitch from "../../../../../../images/formicon/icon28.png";
import Dribble from "../../../../../../images/formicon/icon37.png";
import Quorum from "../../../../../../images/formicon/icon38.png";
import reddit from "../../../../../../images/formicon/icon30.png";
import behance from "../../../../../../images/formicon/icon31.png";
import discord from "../../../../../../images/formicon/icon32.png";
import telegram from "../../../../../../images/formicon/icon33.png";
import Medium from "../../../../../../images/formicon/icon35.png";
import Patreon from "../../../../../../images/formicon/icon28.png";
import ConnectComponent from './ConnectComponent';
import ConnectComponentInput from './ConnectComponentInput';
export default function Social() {

  return (

    <div>
      <div className='row'>
        <div className='col-lg-12'>
          <ul className="customDrop6 customDrop1s max-h-auto customDrop2s w-100 shadow-none position-relative">
            <div className="dropheight h-auto position-relative">
              <ConnectComponent Name="Facebook" icon={fb} />
              <ConnectComponent Name="Facebook Group" icon={fb} />
              <ConnectComponent Name="LinkedIn My" icon={linkedin} />
              <ConnectComponent Name="LinkedIn Org" icon={linkedin} />
              <ConnectComponent Name="Twitter" icon={Twitter} />
              <ConnectComponent Name="Pinterest" icon={Pinterest} />
              <ConnectComponent Name="TikTok" icon={TikTok} />
              <ConnectComponent Name="Instagram" icon={Instagram} />
              <ConnectComponent Name="YouTube" icon={YouTube} />
              <ConnectComponent Name="Snapchat" icon={Snapchat} />
              <ConnectComponent Name="Twitch" icon={Twitch} />
              <ConnectComponent Name="Dribble" icon={Dribble} />
              <ConnectComponent Name="Quorum" icon={Quorum} />
              <ConnectComponent Name="reddit" icon={reddit} />
              <ConnectComponent Name="behance" icon={behance} />
              <ConnectComponent Name="discord" icon={discord} />
              <ConnectComponent Name="telegram" icon={telegram} />
              <ConnectComponent Name="Medium" icon={Medium} />
              <ConnectComponent Name="Patreon" icon={Patreon} />
              <ConnectComponent Name="WhatsApp"/>
              <ConnectComponent Name="Social Media Planner | calendar" />
              <ConnectComponentInput Name="Other ?  Type here...."/>
              <IntegAddFrom />
            </div>
          </ul>
        </div>
      </div>
    </div>
  )
}