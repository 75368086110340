import React from "react";
import './RegularTheme3Tab.scss';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai"
import { BsCheckLg } from 'react-icons/bs'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
// import RegularTheme1Desktop from './regularTheme1\RegularTheme1Desktop/regularTheme1Desktop'

export default function RegularTheme3Tab() {

  return (
    <div>
      <div className="">
        <div>
          <div className="main-content p-4">
            <div className="d-flex align-items-center justify-content-center mb-5">
              <div className="toggle_button">
                <button type="" className="btn active_btn">Monthly</button>
                <button type="" className="btn1 ">Yearly</button>
              </div>
            </div>
            <div className="d-flex align-items-center relative">
              <div className="row plan_content mx-auto">
                <div className="col-md-4 pt-5">
                  <div class="plan_outer mt-4">
                    <div class="ribbon_wrapper">
                      <div class="ribbon">
                        <span class="ribbon_1"><span></span></span>
                        <span class="ribbon1"><span>FREE</span></span>
                      </div>
                    </div>
                    <div className="w-100">
                      <div class="price">
                        <h2>$ 00. <sup>00</sup> </h2>
                        <p>Per user <sup>Per Month</sup></p>
                      </div>
                      <div className="mt-4 plan_listing">
                        <ul>
                          <li>
                            <div className="icon_outer">
                              <BsCheckLg className="icon" />
                            </div>
                            Lorem ipsum dolor</li>
                          <li>
                            <div className="icon_outer">
                              <BsCheckLg className="icon" />
                            </div>
                            Sit amet consect</li>
                          <li>
                            <div className="icon_outer">
                              <BsCheckLg className="icon" />
                            </div>
                            Amet consectetur</li>
                          <li>
                            <div className="icon_outer">
                              <RxCross2 className="icon" />
                            </div>
                            Se sed diam mattis</li>
                          <li>
                            <div className="icon_outer">
                              <RxCross2 className="icon" />
                            </div>
                            Ac sagittis ac
                          </li>
                          <li>
                            <div className="icon_outer">
                              <RxCross2 className="icon" />
                            </div>
                            Sitt isporta vestibulum
                          </li>
                        </ul>
                      </div>
                      <div class="d-flex justify-content-center">
                        <button class="btn mt-4" type="button">Order Now</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div class="plan_outer">
                    <div class="ribbon_wrapper">
                      <div class="ribbon">
                        <span class="ribbon_1"><span></span></span>
                        <span class="ribbon1"><span>Medium</span></span>
                      </div>
                    </div>
                    <div className="w-100">
                      <div class="price">
                        <h2>$ 19. <sup>00</sup> </h2>
                        <p>3 users <sup>Per Month</sup></p>
                      </div>
                      <div className="mt-4 plan_listing">
                        <ul>
                          <li>
                            <div className="icon_outer">
                              <BsCheckLg className="icon" />
                            </div>
                            Lorem ipsum dolor</li>
                          <li>
                            <div className="icon_outer">
                              <BsCheckLg className="icon" />
                            </div>
                            Sit amet consect</li>
                          <li>
                            <div className="icon_outer">
                              <BsCheckLg className="icon" />
                            </div>
                            Amet consectetur</li>
                          <li>
                            <div className="icon_outer">
                              <BsCheckLg className="icon" />
                            </div>
                            Se sed diam mattis</li>
                          <li>
                            <div className="icon_outer">
                              <RxCross2 className="icon" />
                            </div>
                            Ac sagittis ac
                          </li>
                          <li>
                            <div className="icon_outer">
                              <RxCross2 className="icon" />
                            </div>
                            Sitt isporta vestibulum
                          </li>
                        </ul>
                      </div>
                      <div class="d-flex justify-content-center">
                        <button class="btn mt-4" type="button">Order Now</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pt-5">
                  <div class="plan_outer mt-4">
                    <div class="ribbon_wrapper">
                      <div class="ribbon">
                        <span class="ribbon_1"><span></span></span>
                        <span class="ribbon1"><span>Premium</span></span>
                      </div>
                    </div>
                    <div className="w-100">
                      <div class="price">
                        <h2>$ 35. <sup>00</sup> </h2>
                        <p>5 user <sup>Per Month</sup></p>
                      </div>
                      <div className="mt-4 plan_listing">
                        <ul>
                          <li>
                            <div className="icon_outer">
                              <BsCheckLg className="icon" />
                            </div>
                            Lorem ipsum dolor</li>
                          <li>
                            <div className="icon_outer">
                              <BsCheckLg className="icon" />
                            </div>
                            Sit amet consect</li>
                          <li>
                            <div className="icon_outer">
                              <BsCheckLg className="icon" />
                            </div>
                            Amet consectetur</li>
                          <li>
                            <div className="icon_outer">
                              <BsCheckLg className="icon" />
                            </div>
                            Se sed diam mattis</li>
                          <li>
                            <div className="icon_outer">
                              <AiFillStar className="icon star_icon" />
                            </div>
                            Ac sagittis ac
                          </li>
                          <li>
                            <div className="icon_outer">
                              <AiFillStar className="icon star_icon" />
                            </div>
                            Sitt isporta vestibulum
                          </li>
                        </ul>
                      </div>
                      <div class="d-flex justify-content-center">
                        <button class="btn mt-4" type="button">Order Now</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>





    </div >
  );
}
