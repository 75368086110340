import React, { useState } from "react";
// import { Link } from 'react-router-dom';

export default function DropdownBoxFashion(props) {
  // const data = props;

  return (
   
    
    <div className={"dropdown " + props.boxwidth} id={props.boxid}>
      {/* <div className="bgborder"></div> */}
      <button className="btn bgArrow7 position-relative shadow-5in bg h-42 br-30 border-0 outline-none py-2 px-3 w-100" type="button" id={props.boxid} data-bs-toggle="dropdown" aria-expanded="false">
        {/* <img src={frame} className='me-2'/> */}
          {props.name}
      </button>
      <ul className="dropdown-menu customDrop customDrop2 relative" aria-labelledby={props.boxid}>
        <div className="topheadGlob1 px-4 mx-auto">
            <div className="inrhead text-uppercase">
            {props.namebutton}
          </div>
        </div>
       {props.options &&
       <div className="dropheight"> 
        {props.options.map((item) => (
          <li><div className="spansec"><div className="dropdiv"  onClick={() => props.onChange(item.name)}>{item.icon ? item.icon : ""}{item.name}</div></div>
          <b>{item.subname}</b>
          </li>
        ))}
</div>
}
          
          
      </ul>
    </div>
        
  );
}
