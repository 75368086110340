import React from "react";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
import { Link } from 'react-router-dom';
import ButtonBox from '../../../global/ButtonBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import EmailOne from "./EmailMulti/EmailOne";
import EmailTwo from "./EmailMulti/EmailTwo";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';
import { useTranslation } from "react-i18next";


library.add( faCaretRight, faCaretLeft, faFloppyDisk  );

export default function EmailTab() {
  const { t, i18n } = useTranslation();
  let {isVideoVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };
  return (
    <div>
      <div className="w-780 mb-4 align-items-center mx-auto d-flex flex-column-reverse justify-content-between">     
        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto mb-5">
        <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
          <h3 className="mb-5">{t('privacy_component.email_notifications_component.email_notification_settings')}</h3>
          <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="emailOne" role="tabpanel" aria-labelledby="emailOne">
                <EmailOne />
              </div>
              <div className="tab-pane fade" id="emailTwo" role="tabpanel" aria-labelledby="emailTwo">
                <EmailTwo />
              </div>
          </div>
        </div>
        <span className="d-flex align-items-center justify-content-between w-100 mb-4">
          <div id="myTab1" className='' role="tablist">
            <button className="buttonArrow me-3 text-body border-0 bg br-10 active" id="emailOne" data-bs-toggle="tab" data-bs-target="#emailOne" type="button" role="tab" aria-controls="emailOne" aria-selected="true">
              <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
            </button>
            <button className="buttonArrow me-3 text-body border-0 bg br-10" id="emailTwo" data-bs-toggle="tab" data-bs-target="#emailTwo" type="button" role="tab" aria-controls="emailTwo" aria-selected="false">
              <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
            </button>
          </div>
          <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="Saved" icon={faFloppyDisk} name="Save" />
        </span>
      </div>
    </div>
  );
}
