import React from 'react';
import '../../../global/timelineTab/TimelineTab.scss';
import Tab from 'react-bootstrap/Tab';
import './LogoStep.scss';
import img5 from '../../../../images/step3-1.png';
import img2 from '../../../../images/mag.png';
import img3 from '../../../../images/clipboard-1.png';
import img4 from '../../../../images/phone.png';
import img1 from '../../../../images/apron.png';
import shirt from '../../../../images/shirt.png';
import img8 from '../../../../images/clipboard1-2.png';
import img9 from '../../../../images/box-img.png';


export default function ThreeHourBusinessStep3images() {
    return (
        <div>
            <Tab.Container  defaultActiveKey="">
                <div className='' >
                    <div className='flex'>
                        <div className='width-fll step-three'>
                                    <div className="  align-items-center mx-auto d-flex flex-column-reverse justify-content-between three-bsiness">
                                      <div className='d-flex'>
                                        <div className='factorTab img-box-set d-col p-3 '>
                                            <div className='img-set'>
                                            <img src={img5} alt='first-logo' ></img>
                                            </div>
                                            <div className='img-set'>
                                            <img src={img3} alt='second-logo' ></img>
                                            </div>
                                            <div className='img-set'>
                                            <img src={shirt} alt='third-logo' ></img>
                                            </div>
                                            <div className='img-set'>
                                            <img src={img4} alt='for-logo' ></img>
                                            </div>
                                             
                                            <div className='img-set'>
                                            <img src={img9} alt='five-logo' ></img>
                                            </div>
                                          
                                            <div className='img-set'>
                                            <img src={img1} alt='six-logo' ></img>
                                            </div>
                                           
                                            <div className='img-set'>
                                            <img src={img8} alt='seven-logo' ></img>
                                            </div>
                                            <div className='img-set'>
                                            <img src={img2} alt='eight-logo' ></img>
                                            </div>
                                           

                                        
                                          
                                    
                                        </div>
                                      
                                       
                                        </div>
                                    </div>
                               
                          


                        </div>
                    </div>
                </div>





            </Tab.Container>
          
        </div>
    )
}