import React, { useState } from "react";
import '../DigitalBusinessCardStep.scss';
import MobileTime from '../../../../../images/Items.png';
import user from '../../../../../images/img1.png';
import user1 from '../../../../../images/user4.png';
import label from '../../../../../images/img31s.png';
import button from '../../../../../images/btnbg.png';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { ReactComponent as IconBlue1 } from "../../../../../images/mblicon/shareheart.svg";
import { ReactComponent as IconBlue2 } from "../../../../../images/mblicon/share.svg";
import { ReactComponent as IconBlue3 } from "../../../../../images/mblicon/qrcode.svg";
import { ReactComponent as IconBlue5 } from "../../../../../images/mblicon/upload.svg";

import { ReactComponent as IconBlue1s } from "../../../../../images/mblicon/sharehearts.svg";
import { ReactComponent as IconBlue2s } from "../../../../../images/mblicon/shares.svg";
import { ReactComponent as IconBlue3s } from "../../../../../images/mblicon/qrcodes.svg";
import { ReactComponent as IconBlue5s } from "../../../../../images/mblicon/uploads.svg";

import { ReactComponent as IconBlue6 } from "../../../../../images/mblicon/phone.svg";
import { ReactComponent as IconBlue7 } from "../../../../../images/mblicon/email.svg";
import { ReactComponent as IconBlue8 } from "../../../../../images/mblicon/globe.svg";
import { ReactComponent as IconBlue9 } from "../../../../../images/mblicon/whatsapp.svg";
import { ReactComponent as IconBlue10 } from "../../../../../images/mblicon/location.svg";
import { ReactComponent as IconBlue11 } from "../../../../../images/mblicon/lock.svg";
import { ReactComponent as IconBlue12 } from "../../../../../images/mblicon/paypal.svg";

import { ReactComponent as IconBlue13 } from "../../../../../images/mblicon/fb.svg";
import { ReactComponent as IconBlue14 } from "../../../../../images/mblicon/insta.svg";
import { ReactComponent as IconBlue15 } from "../../../../../images/mblicon/twitter.svg";
import { ReactComponent as IconBlue16 } from "../../../../../images/mblicon/gmail.svg";
import { ReactComponent as IconBlue17 } from "../../../../../images/mblicon/linkedin.svg";
import { ReactComponent as IconBlue18 } from "../../../../../images/mblicon/youtube.svg";

import { ReactComponent as IconBlue13s } from "../../../../../images/mblicon/fbs.svg";
import { ReactComponent as IconBlue14s } from "../../../../../images/mblicon/instas.svg";
import { ReactComponent as IconBlue15s } from "../../../../../images/mblicon/twitters.svg";
import { ReactComponent as IconBlue16s } from "../../../../../images/mblicon/gmails.svg";
import { ReactComponent as IconBlue17s } from "../../../../../images/mblicon/linkedins.svg";
import { ReactComponent as IconBlue18s } from "../../../../../images/mblicon/youtubes.svg";

import { ReactComponent as IconBlue19 } from "../../../../../images/mblicon/home.svg";
import { ReactComponent as IconBlue20 } from "../../../../../images/mblicon/user.svg";
import { ReactComponent as IconBlue21 } from "../../../../../images/mblicon/headphone.svg";
import { ReactComponent as IconBlue22 } from "../../../../../images/mblicon/cart.svg";

import { ReactComponent as IconBlue23 } from "../../../../../images/mblicon/back.svg";
import { ReactComponent as IconBlue24 } from "../../../../../images/mblicon/center.svg";
import { ReactComponent as IconBlue25 } from "../../../../../images/mblicon/recent.svg";

import { ReactComponent as IconBlue26 } from "../../../../../images/mblicon/plus.svg";
import { ReactComponent as IconBlue26s } from "../../../../../images/mblicon/plus1s.svg";

export default function DigitalBusinessCard1() {
    const { t, i18n } = useTranslation();
    return (
        <div className="d-flex justify-content-around pt-4">
                <div className="w-396 px-10">
                    <div className="mbltop">
                        <div className="mblbtm">
                            <div className="topsec w-100 d-flex justify-content-end">
                                <img src={MobileTime} alt="" />
                            </div>
                            <div className="bg golden w-100">
                                <div className="mdlsec">
                                    <img src={label} alt="" className="mb-2"/>
                                    <div className="bmsec shadow-5in bg mt-5">
                                        <span className="imgsec bg shadow-5in br-50 position-relative">
                                            <img src={user} alt="" className="w-100 h-100 shadow-4 border-4 border-primary br-50" />
                                        </span>
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <button className="sm bt-primary h7 shadow-4 border-0">DYNA APPOLON</button>
                                            <h6 className="shadow-5in ">CEO | 9 TO 5 ESCAPEE</h6>
                                            <button className="bt-primary mt-3 br-30 h10 ps-3 pe-2 mb-3 mx-auto lg border-0 d-flex justify-content-between align-items-center">Add to contacts <IconBlue26 /></button>
                                            <div className="col-md-12 bioSec biosetp10 ps-3s">
                                                <nav className="d-flex justify-content-between align-items-center mb-2">
                                                    <div className="bioNav d-flex justify-content-center align-items-center" id="nav-tab" role="tablist">
                                                        <button className="shadow-5in navbtn active" id="nav-whoin" data-bs-toggle="tab" data-bs-target="#nav-who-i" type="button" role="tab" aria-controls="nav-who-i" aria-selected="true">
                                                            <span className="shadow-4">{t('profile_section.who_am_i')}</span>
                                                        </button>
                                                        <button className="shadow-5in navbtn" id="nav-whati" data-bs-toggle="tab" data-bs-target="#nav-what-i" type="button" role="tab" aria-controls="nav-what-i" aria-selected="false"><span className="shadow-4">{t('profile_section.what_i_do')}</span></button>
                                                        <button className="shadow-5in navbtn" id="nav-service-for" data-bs-toggle="tab" data-bs-target="#nav-service-f" type="button" role="tab" aria-controls="nav-service-f" aria-selected="false"><span className="shadow-4">{t('profile_section.who_is_my_service_for')}</span></button>
                                                        <button className="shadow-5in navbtn" id="nav-work-with" data-bs-toggle="tab" data-bs-target="#nav-work-w" type="button" role="tab" aria-controls="nav-work-w" aria-selected="false"><span className="shadow-4">{t('profile_section.why_work_with_me')}</span></button>
                                                    </div>
                                                </nav>
                                                <div className="tab-content" id="nav-tabContent">
                                                    <div className="tab-pane fade show active" id="nav-who-i" role="tabpanel" aria-labelledby="nav-whoin">
                                                        <div className="textsec">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-what-i" role="tabpanel" aria-labelledby="nav-whati">
                                                        <div className="textsec">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-service-f" role="tabpanel" aria-labelledby="nav-service-for">
                                                        <div className="textsec">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-work-w" role="tabpanel" aria-labelledby="nav-work-with">
                                                        <div className="textsec">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btmarea mt-4">
                                                <button className="bg bt-primary border-0 d-flex justify-content-between align-items-center">BOOK  ME NOW <img src={button} alt="" className="position-relative z-10" /></button>
                                            </div> 
                                            <div className="d-flex socialmbl justify-content-center align-items-center mt-4 pt-3 mb-4">
                                                <Link to="#">
                                                    <IconBlue1 />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue2 />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue3 /> 
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue5 />
                                                </Link>
                                            </div>
                                            <div className="w-100 px-3 d-flex flex-column mb-4">
                                                <span className="h6 mb-2 w-100"><IconBlue6 className="me-3 w-46 br-50 bt-primary" /> 1-877-455-3482</span>
                                                <span className="h6 mb-2 w-100"><IconBlue7 className="me-3 w-46 br-50 bt-primary" /> dyna@9to5escapee.com</span>
                                                <span className="h6 mb-2 w-100"><IconBlue8 className="me-3 w-46 br-50 bt-primary" /> www.9to5escapee.com</span>
                                                <span className="h6 mb-2 w-100"><IconBlue9 className="me-3 w-46 br-50 bt-primary" /> Connect on WhatsApp</span>
                                                <span className="h6 mb-2 w-100"><IconBlue10 className="me-3 w-46 br-50 bt-primary" /> Toronto, Canada</span>
                                            </div>
                                            <div className="d-flex socialmbl justify-content-center align-items-center mb-4">
                                                <Link to="#">
                                                    <IconBlue13 />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue14 />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue15 /> 
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue16 />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue17 />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue18 />
                                                </Link>
                                            </div>
                                            <div className="px-2">
                                                <div className="goldbr px-3">
                                                    <div className="goldiner">
                                                        <h4>Special Notes:</h4>
                                                        <p>Thank you for contacting me today. I would like to share my “Digital Business Card” as a quick way of contacting me in the future. Don’t forget to connect with me on WHATSAPP </p>
                                                    </div>
                                                </div>
                                                <div className="shadow-5in mb-4 br-30 goldInput d-flex justify-content-between align-items-center">
                                                    <input type="text" className="h-100 w-100 px-2" placeholder="Enter your Whatsapp Number" />
                                                    <button className="w-110 h-100 bg-transparent d-flex justify-content-between align-items-center"><IconBlue9 className="br-50 bt-primary" /> Share</button>
                                                </div>
                                                <div className="goldbtn">
                                                    <button className="w-100 border-0 br-30 fst-italic"><IconBlue11 /> See Our Privacy Policy</button>
                                                    <button className="w-100 border-0 br-30 fw-bold mb-1"><IconBlue12 /> Paypal</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bt-primary w-100 mblBtmSec d-flex justify-content-between align-items-center">
                                    <Link to="">
                                        <IconBlue19 />
                                        HOME
                                    </Link>
                                    <Link to="">
                                        <IconBlue20 />
                                        ABOUT US
                                    </Link>
                                    <Link to="">
                                        <IconBlue21 />
                                        SERVICE
                                    </Link>
                                    <Link to="">
                                        <IconBlue22 />
                                        PAYMENT
                                    </Link>
                                </div>
                                <div className="w-100 d-block mt-4 py-3 bg-dark px-5 d-flex justify-content-around align-items-center">
                                    <IconBlue23 />
                                    <IconBlue24 />
                                    <IconBlue25 />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-396 px-10">
                    <div className="mbltop">
                        <div className="mblbtm">
                            <div className="topsec w-100 d-flex justify-content-end">
                                <img src={MobileTime} alt="" />
                            </div>
                            <div className="silver w-100 bg">
                                <div className="mdlsec1">
                                    <img src={label} alt="" className="mb-2"/>
                                    <div className="bmsec shadow-5in bg mt-5">
                                        <span className="imgsec bg shadow-5in br-50 position-relative">
                                            <img src={user1} alt="" className="w-100 h-100 shadow-4 border-4 border-secondary1 br-50" />
                                        </span>
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <button className="sm bt-primary h7 shadow-4 border-0">DEX RICHARDS</button>
                                            <h6 className="shadow-5in text-center">9 TO 5 ESCAPEE</h6>
                                            <button className="bt-primary mt-3 br-30 h10 ps-3 pe-2 mb-3 mx-auto lg border-0 d-flex justify-content-between align-items-center">Add to contacts <IconBlue26s /></button>
                                            <div className="col-md-12 bioSec biosetp10 ps-3s">
                                                <nav className="d-flex justify-content-between align-items-center mb-2">
                                                    <div className="bioNav d-flex justify-content-center align-items-center" id="nav-tab" role="tablist">
                                                        <button className="shadow-5in navbtn active" id="nav-whoin1" data-bs-toggle="tab" data-bs-target="#nav-who-i1" type="button" role="tab" aria-controls="nav-who-i1" aria-selected="true">
                                                            <span className="shadow-4">{t('profile_section.who_am_i')}</span>
                                                        </button>
                                                        <button className="shadow-5in navbtn" id="nav-whati1" data-bs-toggle="tab" data-bs-target="#nav-what-i1" type="button" role="tab" aria-controls="nav-what-i1" aria-selected="false"><span className="shadow-4">{t('profile_section.what_i_do')}</span></button>
                                                        <button className="shadow-5in navbtn" id="nav-service-for1" data-bs-toggle="tab" data-bs-target="#nav-service-f1" type="button" role="tab" aria-controls="nav-service-f1" aria-selected="false"><span className="shadow-4">{t('profile_section.who_is_my_service_for')}</span></button>
                                                        <button className="shadow-5in navbtn" id="nav-work-with1" data-bs-toggle="tab" data-bs-target="#nav-work-w1" type="button" role="tab" aria-controls="nav-work-w1" aria-selected="false"><span className="shadow-4">{t('profile_section.why_work_with_me')}</span></button>
                                                    </div>
                                                </nav>
                                                <div className="tab-content" id="nav-tabContent1">
                                                    <div className="tab-pane fade show active" id="nav-who-i1" role="tabpanel" aria-labelledby="nav-whoin">
                                                        <div className="textsec border border-secondary1">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-what-i1" role="tabpanel" aria-labelledby="nav-whati">
                                                        <div className="textsec border border-secondary1">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-service-f1" role="tabpanel" aria-labelledby="nav-service-for">
                                                        <div className="textsec border border-secondary1">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-work-w1" role="tabpanel" aria-labelledby="nav-work-with">
                                                        <div className="textsec border border-secondary1">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btmarea mt-4">
                                                <button className="bg bt-primary border-0 d-flex justify-content-between align-items-center">BOOK  ME NOW <img src={button} alt="" className="position-relative z-10" /></button>
                                            </div> 
                                            <div className="d-flex socialmbl justify-content-center align-items-center mt-4 pt-3 mb-3">
                                                <Link to="#">
                                                    <IconBlue1s />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue2s />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue3s /> 
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue5s />
                                                </Link>
                                            </div>
                                            <div className="w-100 px-0 d-flex flex-wrap mb-4 socailtab2 silvericon">
                                                <span className="shadow-5in br-5"><IconBlue6 className="me-3 br-50 bt-primary" /> 1-877-455-3482</span>
                                                <span className="shadow-5in br-5"><IconBlue7 className="me-3 br-50 bt-primary" /> dyna@9to5escapee.com</span>
                                                <span className="shadow-5in br-5"><IconBlue8 className="me-3 br-50 bt-primary" /> www.9to5escapee.com</span>
                                                <span className="shadow-5in br-5"><IconBlue10 className="me-3 br-50 bt-primary" /> Toronto, Canada</span>
                                            </div>
                                            <div className="d-flex socialmbl justify-content-center align-items-center mb-4">
                                                <Link to="#">
                                                    <IconBlue13s />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue14s />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue15s /> 
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue16s />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue17s />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue18s />
                                                </Link>
                                            </div>
                                            <div className="px-2">
                                                <div className="goldbr px-3">
                                                    <div className="goldiner">
                                                        <h4>Special Notes:</h4>
                                                        <p>Thank you for contacting me today. I would like to share my “Digital Business Card” as a quick way of contacting me in the future. Don’t forget to connect with me on WHATSAPP </p>
                                                    </div>
                                                </div>
                                                <div className="shadow-5in mb-4 br-30 goldInput d-flex justify-content-between align-items-center">
                                                    <input type="text" className="h-100 w-100 px-2" placeholder="Enter your Whatsapp Number" />
                                                    <button className="w-110 h-100 bg-transparent d-flex justify-content-between align-items-center"><IconBlue9 className="w-46 br-50 bt-primary" /> Share</button>
                                                </div>
                                                <div className="goldbtn">
                                                    <button className="w-100 border-0 br-30 fst-italic"><IconBlue11 /> See Our Privacy Policy</button>
                                                    <button className="w-100 border-0 br-30 fw-bold mb-1"><IconBlue12 /> Paypal</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bt-primary w-100 mblBtmSec d-flex justify-content-between align-items-center">
                                    <Link to="">
                                        <IconBlue19 />
                                        HOME
                                    </Link>
                                    <Link to="">
                                        <IconBlue20 />
                                        ABOUT US
                                    </Link>
                                    <Link to="">
                                        <IconBlue21 />
                                        SERVICE
                                    </Link>
                                    <Link to="">
                                        <IconBlue22 />
                                        PAYMENT
                                    </Link>
                                </div>
                                <div className="pt-4 bg"></div>
                                <div className="w-100 d-block py-3 bg-dark px-5 d-flex justify-content-around align-items-center">
                                    <IconBlue23 />
                                    <IconBlue24 />
                                    <IconBlue25 />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}
