import React, { useState } from "react";
import '../DigitalBusinessCardStep.scss';
import MobileTime from '../../../../../images/Items.png';
import user2 from '../../../../../images/img56.png';
import user4 from '../../../../../images/img61.png';
import label from '../../../../../images/img31s.png';
import button from '../../../../../images/btnbg.png';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { ReactComponent as IconBlue1 } from "../../../../../images/mblicon/shareheart.svg";
import { ReactComponent as IconBlue2 } from "../../../../../images/mblicon/share.svg";
import { ReactComponent as IconBlue3 } from "../../../../../images/mblicon/qrcode.svg";
import { ReactComponent as IconBlue5 } from "../../../../../images/mblicon/upload.svg";

import { ReactComponent as IconBlue1s } from "../../../../../images/mblicon/sharehearts.svg";
import { ReactComponent as IconBlue2s } from "../../../../../images/mblicon/shares.svg";
import { ReactComponent as IconBlue3s } from "../../../../../images/mblicon/qrcodes.svg";
import { ReactComponent as IconBlue5s } from "../../../../../images/mblicon/uploads.svg";

import { ReactComponent as IconBlue6 } from "../../../../../images/mblicon/phone.svg";
import { ReactComponent as IconBlue7 } from "../../../../../images/mblicon/email.svg";
import { ReactComponent as IconBlue8 } from "../../../../../images/mblicon/globe.svg";
import { ReactComponent as IconBlue9 } from "../../../../../images/mblicon/whatsapp.svg";
import { ReactComponent as IconBlue10 } from "../../../../../images/mblicon/location.svg";
import { ReactComponent as IconBlue11 } from "../../../../../images/mblicon/lock.svg";
import { ReactComponent as IconBlue12 } from "../../../../../images/mblicon/paypal.svg";

import { ReactComponent as IconBlue13 } from "../../../../../images/mblicon/fb.svg";
import { ReactComponent as IconBlue14 } from "../../../../../images/mblicon/insta.svg";
import { ReactComponent as IconBlue15 } from "../../../../../images/mblicon/twitter.svg";
import { ReactComponent as IconBlue16 } from "../../../../../images/mblicon/gmail.svg";
import { ReactComponent as IconBlue17 } from "../../../../../images/mblicon/linkedin.svg";
import { ReactComponent as IconBlue18 } from "../../../../../images/mblicon/youtube.svg";

import { ReactComponent as IconBlue13s } from "../../../../../images/mblicon/fbs.svg";
import { ReactComponent as IconBlue14s } from "../../../../../images/mblicon/instas.svg";
import { ReactComponent as IconBlue15s } from "../../../../../images/mblicon/twitters.svg";
import { ReactComponent as IconBlue16s } from "../../../../../images/mblicon/gmails.svg";
import { ReactComponent as IconBlue17s } from "../../../../../images/mblicon/linkedins.svg";
import { ReactComponent as IconBlue18s } from "../../../../../images/mblicon/youtubes.svg";

import { ReactComponent as IconBlue19 } from "../../../../../images/mblicon/home.svg";
import { ReactComponent as IconBlue20 } from "../../../../../images/mblicon/user.svg";
import { ReactComponent as IconBlue21 } from "../../../../../images/mblicon/headphone.svg";
import { ReactComponent as IconBlue22 } from "../../../../../images/mblicon/cart.svg";

import { ReactComponent as IconBlue23 } from "../../../../../images/mblicon/back.svg";
import { ReactComponent as IconBlue24 } from "../../../../../images/mblicon/center.svg";
import { ReactComponent as IconBlue25 } from "../../../../../images/mblicon/recent.svg";

import { ReactComponent as IconBlue26 } from "../../../../../images/mblicon/plus.svg";
import { ReactComponent as IconBlue26s } from "../../../../../images/mblicon/plus1s.svg";

export default function DigitalBusinessCard14() {
    const { t, i18n } = useTranslation();
    return (
        <div className="d-flex justify-content-around pt-4">
                <div className="w-396 px-10">
                    <div className="mbltop">
                        <div className="mblbtm">
                            <div className="topsec w-100 d-flex justify-content-end">
                                <img src={MobileTime} alt="" />
                            </div>
                            <div className="bg golden w-100">
                                <div className="mdlsec pt-0 secstyle2 bgm-transparent px-3">
                                    <div className="bmsec mt-4 pt-5 shadow-5in bg rounded-0 px-2 text-center">
                                        <div className="bt-primary shadow-7in mt-5 pt-0 rounded-5 text-center flex-column px-4 h-200 w-100 d-flex justify-content-center align-items-center">
                                            <span className="imgsec3 mb-4 bg shadow-5in br-50 position-relative mt-115">
                                                <img src={user4} alt="" className="w-100 h-100 shadow-4 border-4 border-primary br-50" />
                                                <div className="sm-social shadow-3in bg-white d-block">
                                                    <Link to="#">
                                                        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.413 13.1532C9.83446 13.1532 9.34274 12.9614 8.93779 12.5778C8.53284 12.1941 8.33037 11.7283 8.33037 11.1802C8.33037 11.1035 8.33615 11.0239 8.34772 10.9415C8.35929 10.8595 8.37665 10.7856 8.39979 10.7199L3.5057 8.02346C3.30901 8.18787 3.08918 8.31656 2.84621 8.40951C2.60324 8.50289 2.3487 8.54959 2.08259 8.54959C1.50409 8.54959 1.01237 8.35777 0.607423 7.97413C0.202474 7.5905 0 7.12466 0 6.57661C0 6.02856 0.202474 5.56271 0.607423 5.17908C1.01237 4.79544 1.50409 4.60362 2.08259 4.60362C2.3487 4.60362 2.60324 4.6501 2.84621 4.74305C3.08918 4.83644 3.30901 4.96534 3.5057 5.12975L8.39979 2.43334C8.37665 2.36758 8.35929 2.2937 8.34772 2.21171C8.33615 2.12929 8.33037 2.04971 8.33037 1.97298C8.33037 1.42493 8.53284 0.959088 8.93779 0.575453C9.34274 0.191818 9.83446 0 10.413 0C10.9915 0 11.4832 0.191818 11.8881 0.575453C12.2931 0.959088 12.4956 1.42493 12.4956 1.97298C12.4956 2.52103 12.2931 2.98688 11.8881 3.37051C11.4832 3.75415 10.9915 3.94596 10.413 3.94596C10.1469 3.94596 9.89231 3.89927 9.64934 3.80588C9.40637 3.71293 9.18654 3.58425 8.98986 3.41984L4.09576 6.11624C4.1189 6.18201 4.13626 6.25589 4.14783 6.33788C4.1594 6.4203 4.16518 6.49988 4.16518 6.57661C4.16518 6.65333 4.1594 6.73269 4.14783 6.81468C4.13626 6.89711 4.1189 6.9712 4.09576 7.03697L8.98986 9.73338C9.18654 9.56896 9.40637 9.44006 9.64934 9.34667C9.89231 9.25372 10.1469 9.20725 10.413 9.20725C10.9915 9.20725 11.4832 9.39907 11.8881 9.7827C12.2931 10.1663 12.4956 10.6322 12.4956 11.1802C12.4956 11.7283 12.2931 12.1941 11.8881 12.5778C11.4832 12.9614 10.9915 13.1532 10.413 13.1532Z" fill="url(#paint0_linear_659_133489)"/>
                                                            <defs>
                                                            <linearGradient id="paint0_linear_659_133489" x1="6.24778" y1="-2.36758" x2="6.24778" y2="15.2577" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#FACB30"/>
                                                            <stop offset="0.302269" stop-color="#FEF9C8"/>
                                                            <stop offset="0.640625" stop-color="#C49530"/>
                                                            <stop offset="1" stop-color="#FFF8C4"/>
                                                            </linearGradient>
                                                            </defs>
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </span>
                                            <div className="style6 mx-auto text-center d-flex flex-column justify-content-center align-items-center">
                                                <h4 className="bg shadow-5in m-0">ALAN JONES</h4>
                                                <h6 className="bg shadow-3">9 TO 5 ESCAPEE</h6>
                                            </div>
                                        </div>   
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <button className="bt-primary mt-3 br-30 h10 ps-3 pe-2 mb-3 mx-auto lg border-0 d-flex justify-content-between align-items-center">Add to contacts <IconBlue26 /></button>
                                            <img src={label} alt="" className="mb-4 br-10"/>
                                            <div className="col-md-12 bioSec biosetp10 ps-3s">
                                                <nav className="d-flex justify-content-between align-items-center mb-2">
                                                        <div className="bioNav d-flex justify-content-center align-items-center" id="nav-tab" role="tablist">
                                                            <button className="shadow-5in navbtn active" id="nav-whoin14a" data-bs-toggle="tab" data-bs-target="#nav-who-i14a" type="button" role="tab" aria-controls="nav-who-i14a" aria-selected="true">
                                                                <span className="shadow-4">{t('profile_section.who_am_i')}</span>
                                                            </button>
                                                            <button className="shadow-5in navbtn" id="nav-whati14a" data-bs-toggle="tab" data-bs-target="#nav-what-i14a" type="button" role="tab" aria-controls="nav-what-i14a" aria-selected="false"><span className="shadow-4">{t('profile_section.what_i_do')}</span></button>
                                                            <button className="shadow-5in navbtn" id="nav-service-for14a" data-bs-toggle="tab" data-bs-target="#nav-service-f14a" type="button" role="tab" aria-controls="nav-service-f14a" aria-selected="false"><span className="shadow-4">{t('profile_section.who_is_my_service_for')}</span></button>
                                                            <button className="shadow-5in navbtn" id="nav-work-with14a" data-bs-toggle="tab" data-bs-target="#nav-work-w14a" type="button" role="tab" aria-controls="nav-work-w14a" aria-selected="false"><span className="shadow-4">{t('profile_section.why_work_with_me')}</span></button>
                                                        </div>
                                                </nav>
                                                <div className="tab-content" id="nav-tabContent14a">
                                                    <div className="tab-pane fade show active" id="nav-who-i14a" role="tabpanel" aria-labelledby="nav-whoin">
                                                        <div className="textsec bg border border-primary">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-what-i14a" role="tabpanel" aria-labelledby="nav-whati">
                                                        <div className="textsec bg border border-primary">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-service-f14a" role="tabpanel" aria-labelledby="nav-service-for">
                                                        <div className="textsec bg border border-primary">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-work-w14a" role="tabpanel" aria-labelledby="nav-work-with">
                                                        <div className="textsec bg border border-primary">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btmarea mt-4">
                                                <button className="bg bt-primary border-0 d-flex justify-content-between align-items-center">BOOK  ME NOW <img src={button} alt="" className="position-relative z-10" /></button>
                                            </div> 
                                            <div className="d-flex socialmbl justify-content-center align-items-center mt-4 pt-3 mb-4">
                                                <Link to="#">
                                                    <IconBlue1 />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue2 />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue3 /> 
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue5 />
                                                </Link>
                                            </div>
                                            <div className="w-100 px-3 d-flex text-start flex-column mb-4">
                                                <span className="h6 mb-2 w-100"><IconBlue6 className="me-3 w-46 br-50 bt-primary" /> 1-877-455-3482</span>
                                                <span className="h6 mb-2 w-100"><IconBlue7 className="me-3 w-46 br-50 bt-primary" /> dyna@9to5escapee.com</span>
                                                <span className="h6 mb-2 w-100"><IconBlue8 className="me-3 w-46 br-50 bt-primary" /> www.9to5escapee.com</span>
                                                <span className="h6 mb-2 w-100"><IconBlue9 className="me-3 w-46 br-50 bt-primary" /> Connect on WhatsApp</span>
                                                <span className="h6 mb-2 w-100"><IconBlue10 className="me-3 w-46 br-50 bt-primary" /> Toronto, Canada</span>
                                            </div>
                                            <div className="d-flex socialmbl justify-content-center align-items-center mb-4">
                                                <Link to="#">
                                                    <IconBlue13 />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue14 />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue15 /> 
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue16 />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue17 />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue18 />
                                                </Link>
                                            </div>
                                            <div className="px-2">
                                                <div className="goldbr px-3">
                                                    <div className="goldiner">
                                                        <h4>Special Notes:</h4>
                                                        <p>Thank you for contacting me today. I would like to share my “Digital Business Card” as a quick way of contacting me in the future. Don’t forget to connect with me on WHATSAPP </p>
                                                    </div>
                                                </div>
                                                <div className="shadow-5in mb-4 br-30 goldInput d-flex justify-content-between align-items-center">
                                                    <input type="text" className="h-100 w-100 px-2" placeholder="Enter your Whatsapp Number" />
                                                    <button className="w-110 h-100 bg-transparent d-flex justify-content-between align-items-center"><IconBlue9 className="br-50 bt-primary" /> Share</button>
                                                </div>
                                                <div className="goldbtn">
                                                    <button className="w-100 border-0 br-30 fst-italic"><IconBlue11 /> See Our Privacy Policy</button>
                                                    <button className="w-100 border-0 br-30 fw-bold mb-1"><IconBlue12 /> Paypal</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bt-primary w-100 mblBtmSec d-flex justify-content-between align-items-center">
                                    <Link to="">
                                        <IconBlue19 />
                                        HOME
                                    </Link>
                                    <Link to="">
                                        <IconBlue20 />
                                        ABOUT US
                                    </Link>
                                    <Link to="">
                                        <IconBlue21 />
                                        SERVICE
                                    </Link>
                                    <Link to="">
                                        <IconBlue22 />
                                        PAYMENT
                                    </Link>
                                </div>
                                <div className="w-100 d-block mt-4 py-3 bg-dark px-5 d-flex justify-content-around align-items-center">
                                    <IconBlue23 />
                                    <IconBlue24 />
                                    <IconBlue25 />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-396 px-10">
                    <div className="mbltop">
                        <div className="mblbtm">
                            <div className="topsec w-100 d-flex justify-content-end">
                                <img src={MobileTime} alt="" />
                            </div>
                            <div className="bg silver w-100">
                                <div className="mdlsec pt-0 secstyle2 bgm-transparent px-3">
                                <   div className="bmsec mt-4 pt-5 shadow-5in bg rounded-0 px-2 text-center">
                                        <div className="bt-primary shadow-7in mt-5 pt-0 rounded-5 text-center flex-column px-4 h-200 w-100 d-flex justify-content-center align-items-center">
                                            <span className="imgsec3 mb-4 bg shadow-5in br-50 position-relative mt-115">
                                                <img src={user2} alt="" className="w-100 h-100 shadow-4 border-4 border-secondary br-50" />
                                                <div className="sm-social shadow-3in bg-white d-block">
                                                    <Link to="#">
                                                        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.413 13.1532C9.83446 13.1532 9.34274 12.9614 8.93779 12.5778C8.53284 12.1941 8.33037 11.7283 8.33037 11.1802C8.33037 11.1035 8.33615 11.0239 8.34772 10.9415C8.35929 10.8595 8.37665 10.7856 8.39979 10.7199L3.5057 8.02346C3.30901 8.18787 3.08918 8.31656 2.84621 8.40951C2.60324 8.50289 2.3487 8.54959 2.08259 8.54959C1.50409 8.54959 1.01237 8.35777 0.607423 7.97413C0.202474 7.5905 0 7.12466 0 6.57661C0 6.02856 0.202474 5.56271 0.607423 5.17908C1.01237 4.79544 1.50409 4.60362 2.08259 4.60362C2.3487 4.60362 2.60324 4.6501 2.84621 4.74305C3.08918 4.83644 3.30901 4.96534 3.5057 5.12975L8.39979 2.43334C8.37665 2.36758 8.35929 2.2937 8.34772 2.21171C8.33615 2.12929 8.33037 2.04971 8.33037 1.97298C8.33037 1.42493 8.53284 0.959088 8.93779 0.575453C9.34274 0.191818 9.83446 0 10.413 0C10.9915 0 11.4832 0.191818 11.8881 0.575453C12.2931 0.959088 12.4956 1.42493 12.4956 1.97298C12.4956 2.52103 12.2931 2.98688 11.8881 3.37051C11.4832 3.75415 10.9915 3.94596 10.413 3.94596C10.1469 3.94596 9.89231 3.89927 9.64934 3.80588C9.40637 3.71293 9.18654 3.58425 8.98986 3.41984L4.09576 6.11624C4.1189 6.18201 4.13626 6.25589 4.14783 6.33788C4.1594 6.4203 4.16518 6.49988 4.16518 6.57661C4.16518 6.65333 4.1594 6.73269 4.14783 6.81468C4.13626 6.89711 4.1189 6.9712 4.09576 7.03697L8.98986 9.73338C9.18654 9.56896 9.40637 9.44006 9.64934 9.34667C9.89231 9.25372 10.1469 9.20725 10.413 9.20725C10.9915 9.20725 11.4832 9.39907 11.8881 9.7827C12.2931 10.1663 12.4956 10.6322 12.4956 11.1802C12.4956 11.7283 12.2931 12.1941 11.8881 12.5778C11.4832 12.9614 10.9915 13.1532 10.413 13.1532Z" fill="url(#paint0_linear_2_85876)"/>
                                                            <defs>
                                                            <linearGradient id="paint0_linear_2_85876" x1="6.24778" y1="-2.36758" x2="6.24778" y2="15.2577" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#808080"/>
                                                            <stop offset="0.302269" stop-color="#EBEBEB"/>
                                                            <stop offset="0.640625" stop-color="#A7A7A7"/>
                                                            <stop offset="1" stop-color="#E8E8E8"/>
                                                            </linearGradient>
                                                            </defs>
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </span>
                                            <div className="style6 mx-auto text-center d-flex flex-column justify-content-center align-items-center">
                                                <h4 className="bg shadow-5in m-0">DYNA APPOLON</h4>
                                                <h6 className="bg shadow-3">CEO | 9 TO 5 ESCAPEE</h6>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <button className="bt-primary mt-3 br-30 h10 ps-3 pe-2 mb-3 mx-auto lg border-0 d-flex justify-content-between align-items-center">Add to contacts <IconBlue26 /></button>
                                            <img src={label} alt="" className="mb-4 br-10"/>
                                            <div className="col-md-12 bioSec biosetp10 ps-3s">
                                                <nav className="d-flex justify-content-between align-items-center mb-2">
                                                        <div className="bioNav d-flex justify-content-center align-items-center" id="nav-tab" role="tablist">
                                                            <button className="shadow-5in navbtn active" id="nav-whoin15d" data-bs-toggle="tab" data-bs-target="#nav-who-i15d" type="button" role="tab" aria-controls="nav-who-i15d" aria-selected="true">
                                                                <span className="shadow-4">{t('profile_section.who_am_i')}</span>
                                                            </button>
                                                            <button className="shadow-5in navbtn" id="nav-whati15d" data-bs-toggle="tab" data-bs-target="#nav-what-i15d" type="button" role="tab" aria-controls="nav-what-i15d" aria-selected="false"><span className="shadow-4">{t('profile_section.what_i_do')}</span></button>
                                                            <button className="shadow-5in navbtn" id="nav-service-for15d" data-bs-toggle="tab" data-bs-target="#nav-service-f15d" type="button" role="tab" aria-controls="nav-service-f15d" aria-selected="false"><span className="shadow-4">{t('profile_section.who_is_my_service_for')}</span></button>
                                                            <button className="shadow-5in navbtn" id="nav-work-with15d" data-bs-toggle="tab" data-bs-target="#nav-work-w15d" type="button" role="tab" aria-controls="nav-work-w15d" aria-selected="false"><span className="shadow-4">{t('profile_section.why_work_with_me')}</span></button>
                                                        </div>
                                                </nav>
                                                <div className="tab-content" id="nav-tabContent15d">
                                                    <div className="tab-pane fade show active" id="nav-who-i15d" role="tabpanel" aria-labelledby="nav-whoin">
                                                        <div className="textsec bg border border-secondary1">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-what-i15d" role="tabpanel" aria-labelledby="nav-whati">
                                                        <div className="textsec bg border border-secondary1">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-service-f15d" role="tabpanel" aria-labelledby="nav-service-for">
                                                        <div className="textsec bg border border-secondary1">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-work-w15d" role="tabpanel" aria-labelledby="nav-work-with">
                                                        <div className="textsec bg border border-secondary1">
                                                            <div className="h-100 overflow-overlay p-10">
                                                                <h5>HEY! I'M DYNA</h5>
                                                                <p>I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btmarea mt-4">
                                                <button className="bg bt-primary border-0 d-flex justify-content-between align-items-center">BOOK  ME NOW <img src={button} alt="" className="position-relative z-10" /></button>
                                            </div> 
                                            <div className="d-flex socialmbl justify-content-center align-items-center mt-4 pt-3 mb-4">
                                                <Link to="#">
                                                    <IconBlue1s />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue2s />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue3s /> 
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue5s />
                                                </Link>
                                            </div>
                                            <div className="w-100 px-0 d-flex flex-wrap mb-4 socailtab2 silvericon">
                                                    <span className="shadow-5in bg br-5"><IconBlue6 className="me-3 br-50 bt-primary" /> 1-877-455-3482</span>
                                                    <span className="shadow-5in bg br-5"><IconBlue7 className="me-3 br-50 bt-primary" /> dyna@9to5escapee.com</span>
                                                    <span className="shadow-5in bg br-5"><IconBlue8 className="me-3 br-50 bt-primary" /> www.9to5escapee.com</span>
                                                    <span className="shadow-5in bg br-5"><IconBlue10 className="me-3 br-50 bt-primary" /> Toronto, Canada</span>
                                                </div>
                                            <div className="d-flex socialmbl justify-content-center align-items-center mb-4">
                                                <Link to="#">
                                                    <IconBlue13s />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue14s />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue15s /> 
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue16s />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue17s />
                                                </Link>
                                                <Link to="#">
                                                    <IconBlue18s />
                                                </Link>
                                            </div>
                                            <div className="px-2">
                                                <div className="goldbr px-3">
                                                    <div className="goldiner">
                                                        <h4>Special Notes:</h4>
                                                        <p>Thank you for contacting me today. I would like to share my “Digital Business Card” as a quick way of contacting me in the future. Don’t forget to connect with me on WHATSAPP </p>
                                                    </div>
                                                </div>
                                                <div className="shadow-5in mb-4 br-30 goldInput d-flex justify-content-between align-items-center">
                                                    <input type="text" className="h-100 w-100 px-2" placeholder="Enter your Whatsapp Number" />
                                                    <button className="w-110 h-100 bg-transparent d-flex justify-content-between align-items-center"><IconBlue9 className="br-50 bt-primary" /> Share</button>
                                                </div>
                                                <div className="goldbtn">
                                                    <button className="w-100 border-0 br-30 fst-italic"><IconBlue11 /> See Our Privacy Policy</button>
                                                    <button className="w-100 border-0 br-30 fw-bold mb-1"><IconBlue12 /> Paypal</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bt-primary w-100 mblBtmSec d-flex justify-content-between align-items-center">
                                    <Link to="">
                                        <IconBlue19 />
                                        HOME
                                    </Link>
                                    <Link to="">
                                        <IconBlue20 />
                                        ABOUT US
                                    </Link>
                                    <Link to="">
                                        <IconBlue21 />
                                        SERVICE
                                    </Link>
                                    <Link to="">
                                        <IconBlue22 />
                                        PAYMENT
                                    </Link>
                                </div>
                                <div className="w-100 d-block mt-4 py-3 bg-dark px-5 d-flex justify-content-around align-items-center">
                                    <IconBlue23 />
                                    <IconBlue24 />
                                    <IconBlue25 />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}
