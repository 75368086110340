import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cap_gold from '../../../../../../images/cap-gold.png'
import './RegularTheme2Tab.scss';
import  canda_img  from "../../../../../../images/canada.png"
import testimonial_glass from '../../../../../../images/testimonial-glass.png';
import mue_shape from '../../../../../../images/mue-shape.png'
import shape_m from '../../../../../../images/shape-m.png'
import three_btn_m from '../../../../../../images/three-btn-m.png'
import { faStar } from "@fortawesome/free-solid-svg-icons";
import glass_btn_3 from '../../../../../../images/glass-btn-3.png';
import two_btn from '../../../../../../images/two-btn.png'
import gold_star from '../../../../../../images/gold-star.png';
import message_gold from '../../../../../../images/msg-gold.png'
import rank_gold from '../../../../../../images/rank-gold.png'
import timeline_gold from '../../../../../../images/timeline-gold.png';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaDribbble, FaPinterest, FaYoutube ,FaTiktok ,FaSnapchatGhost ,FaInstagram  } from "react-icons/fa";
import plalist_gold from '../../../../../../images/plalist-gold.png'
import {  faCloudArrowDown,  faInfo,  } from "@fortawesome/free-solid-svg-icons";
import img1 from '../../../../../../images/img1.png'
import { BiMessageDots } from "react-icons/bi";
import second_3 from '../../../../../../images/second-3.png'
import dribble_dark from '../../../../../../images/dribble-dark.png';
import message from '../../../../../../images/message.png';
import answer from '../../../../../../images/answers.png';
import rank from '../../../../../../images/rank.png';
import load from '../../../../../../images/load.png';
import vedio from '../../../../../../images/video.png'
import fb from '../../../../../../images/fb.png';
import mic_img from '../../../../../../images/mic-img.png'
import insta from '../../../../../../images/insta.png';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import sond_1 from '../../../../../../images/sond-1.png';
import sond_2 from '../../../../../../images/sond-2.png';
import RangeSlider from "react-bootstrap-range-slider";
import { BsPatchCheckFill } from "react-icons/bs"
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { MdSkipNext, MdSkipPrevious, } from "react-icons/md";
import { RiVipFill ,RiVipCrown2Fill , RiDownloadLine, RiPlayCircleFill,RiCloseLine,RiPlayMiniLine, RiShareForwardLine, RiFullscreenLine,RiInstagramFill } from "react-icons/ri";
import sond_right from '../../../../../../images/sond-right.png';
import sond_left from '../../../../../../images/sond-left.png';
import Form from 'react-bootstrap/Form';
import { RxGear } from "react-icons/rx";
import printer_est from '../../../../../../images/pinter-est.png';
import linked_in from '../../../../../../images/linked-in.png';
import you_tube from '../../../../../../images/you-tube.png';
import Social_2 from '../../../../../../images/Social-2.png'
import { RiDownloadCloud2Line } from "react-icons/ri";
import { RxMagnifyingGlass } from "react-icons/rx";
import reg_tab_bg from '../../../../../../images/reg-tab-bg.png';
import tab_two_m from '../../../../../../images/tab-two-m.png';
import { FaBars } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import testimonial from '../../../../../../images/testimonial.png';
import down_icon from '../../../../../../images/down-icon.png';
import down_gold from '../../../../../../images/down-gold.png'
import { useTranslation } from "react-i18next";

export default function RegularTheme2Tab() {
  const { t, i18n } = useTranslation();
  const [seeall, setSeeall] = useState(false);

  const handleSeeallClick = () => {
    setSeeall(!seeall);
  };

  const handleSeeallClose = () => {
    setSeeall(false);
  };

  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };

  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };
  const [bookmenow, setBookmenow] = useState(false);

  const handleBookmenowClick = () => {
    setBookmenow(!bookmenow);
  };

  const handleBookmenowClose = () => {
    setBookmenow(false);
  };
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };


  const [Competency1, setCompetency1] = useState(10);

  return (
    <div>

    

 

          <div className="design-screen-tab">
          <div>
          <div className="d-flex w-100">
       
          </div>
          <div className="main-content d-flex">
            <div className="hero-sidebar text-center">
            <div className="mr-3 zoom-section">
                  <div className="icons-circle">
                  <div id="parent-circle">
        <div class="circle printerest"><FaTwitter /></div>
        <div class="circle facebookk"><FaLinkedinIn /></div>
        <div class="circle instaa"><FaTiktok /></div>
        <div class="circle twitter"><FaYoutube /></div>
        <div class="circle innn"><FaSnapchatGhost /></div>
        <div class="circle tiktok"><BiMessageDots /></div>
        <div class="circle videoo"><BsPatchCheckFill /></div>
        <div class="circle videooo"><img src={canda_img} alt="canada" /></div>
        <div class="circle snapchat"><RiVipCrown2Fill /></div>
        <div class="circle logo-f"><RiVipFill /></div>
        <div class="circle logo2"><FaPinterest /></div>
        <div class="circle logo-3"><FaFacebookF /></div>
        <div class="circle logo-4"><FaInstagram /></div>
      
   
      </div>
                  </div>
                  <img src={img1} alt="img1" className="profile-img" ></img>
                </div>
              <div className="name-btn">
                DYNA APPOLON
              </div>
              <p>
                CEO | 9 to 5 Escapee
              </p>
              <div>
                <ul className="pt-2 pb-2">
                <div className="search-position">
                    <li onClick={handleClick}>
                    <RxMagnifyingGlass className="fa-magnify" />

                    </li>
                    {isVisible && (
                      <div className="searchbox">
                        <div className='search-area w-100'>
                          <button className="close-btn" onClick={handleClose}>
                            <RiCloseLine />
                          </button>
                          <form autoComplete="off">
                            <div className='search-field d-flex justify-content-between align-items-center pe-4 ms-auto'>
                              <input placeholder="Search" className="border-0" type="text" />
                              <RxMagnifyingGlass className="icon-mgni" />

                              <div>

                              </div>
                            </div>
                          </form>
                          <button className="search-me-btn w-50">
                            Search
                          </button>
                          <div className="book-me-mue mt-2 m-auto"  >
  <img src={two_btn} alt="two-btn" ></img>
  </div>
                          <div className="glass-show mt-2 m-auto" >
                          <img src={glass_btn_3} alt="bookbtn" ></img>

                  </div>
                        </div>

                      </div>
                    )}
                  </div>
                  <li className="info-oter">
                    <FontAwesomeIcon className="info-icon" icon={faInfo} >

                    </FontAwesomeIcon>
                  </li>
                </ul>
                <div className="mode-set" >
                  {t('profile_hero_section.mode')}
                </div>
                <div className="switch-outer">
                  <input type="checkbox" hidden="hidden" id="tab2" />
                  <label class="switch" for="tab2"></label>
                </div>
                <div className="social-set" >
                  {t('profile_hero_section.social')}
                </div>
          
                <div className="bars-hambrger">
              <div className="navbar-bars mb-3 bars-green" onClick={handleHamburgerClick}>
              <FaBars />
              </div>
              {hamburger && (
                <div className="hamburger-outer">
                  <div className=' mb-5 w-100'>
                    <button className="close-btn" onClick={handleHamburgerClose}>
                    <RiCloseLine />
                    </button>
                    <div className="d-flex align-items-center">
                              
                    <div className="switch-border">
        <input type="checkbox" />
        </div>   
                    <div className="social-set" >
                   {t('profile_hero_section.social_mode')}
                    </div>
                      </div>
              
                  </div>

                  <div>
                  <ul className="mb-3">
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')}1
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')}2
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')} 3
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')} 4
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')} 5
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')}6
                      </li>
                    </ul>
                    <p className="testimonial-p">
                    {t('profile_hero_section.testimonials')}
                    </p>
                    <div>
         
                      <div className="d-flex w-100">

                        <div className="testimonial">
                          <div className="timeline-top d-flex">
                            <div className="d-flex">
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                            </div>
                            <div>
                              <p>
                                dummy text of the
                                printing  printing
                                <p><b>Client Name Here</b>
                                  Designation Here</p>
                              </p>


                            </div>

                          </div>
                          <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                        </div>
                        <div className="testimonial">
                          <div className="timeline-top d-flex">
                            <div className="d-flex">
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                            </div>
                            <div>
                              <p>
                                dummy text of the
                                printing  printing
                                <p><b>Client Name Here</b>
                                  Designation Here</p>
                              </p>


                            </div>

                          </div>
                          <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                        </div>
                        <div className="testimonial">
                          <div className="timeline-top d-flex">
                            <div className="d-flex">
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                            </div>
                            <div>
                              <p>
                                dummy text of the
                                printing  printing
                                <p><b>Client Name Here</b>
                                  Designation Here</p>
                              </p>


                            </div>

                          </div>
                          <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                        </div>
                      </div>
                      <ul className="mt-5 top-padding reg-social d-flex">
                  
                        <div className="d-flex">
                        <li className="pb-3">
                          <img src={fb} alt="fb" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={insta} alt="insta" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={Social_2} alt="twitter" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={linked_in} alt="linked-in" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={dribble_dark} alt="dribble" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={printer_est} alt="printerest" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={you_tube} alt="youtube" ></img>
                        </li>
                        </div>
                       
                  
                      </ul>
                      <div className="meu-social mt-5 pt-5">
                      <ul className="mt-5 top-padding pt-5">
                    <li >
                      <FaFacebookF />
                    </li>
                    <li >
                      <RiInstagramFill />
                    </li>
                    <li >
                      <FaTwitter />
                    </li>
                    <li >
                      <FaLinkedinIn />
                    </li>
                    <li >
                      <FaDribbble />
                    </li>
                    <li >
                      <FaPinterest />
                    </li>
                    <li >
                      <FaYoutube />
                    </li>
                    </ul>
                    </div>
                      <div className="bottom-tabs" >
                        <RiDownloadCloud2Line />
                        <h6>
                           {t('profile_hero_section.download_cv')}
                        </h6>
                      </div>


                    </div>

                  </div>

                </div>
              )}
            
            </div>
                <div className="intro-left-section">
                  <div className="logo-intro">
                    <div className="img-top">
                      <img src={second_3} className="img-logo" alt="logo" >
                      </img>
                    </div>
                 
                  </div>
                  <div className="bookme-oter">
                <button className="book-me-btn book-me-reg"   onClick={handleBookmeClick}>
                  Book me
                </button>
                <div className="book-me-mue" onClick={handleBookmeClick} >
  <img src={two_btn} alt="two-btn" ></img>
  </div>
  <div className=" glass-show"  onClick={handleBookmeClick}>
                    <img src={glass_btn_3} alt="bookbtn" ></img>

                  </div>
                  {bookme && (
                        <div className="bookme-inner">
                <div className="hamburger-outer">
                  <div className=' mb-5 w-100'>
                    <button className="close-btn" onClick={handleBookmeClose}>
                    <RiCloseLine />
                    </button>
                    <div className="w-100 reg-timeline mt-5">
                          <ul className="timelinee">
                            <li className="active">
                              <div className="timeline-topp">
                                9
                              </div>
                              <div className="timeline-icon">
                                <img className="message" src={message} alt="message" ></img>
                                <p>
        {t('profile_hero_section.answers')}
                                </p>
                              </div>
                            </li>
                            <li> <div className="timeline-topp">
                              300/
                              1000
                            </div>
                              <div className="timeline-icon">
                                <img src={load} className="load" alt="load" ></img>
                                <p>
                                  Social
                                  score
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="timeline-topp">
                                #1
                              </div>
                              <div className="timeline-icon">
                                <img src={rank} alt="rank" ></img>
                                <p>
                                  Rank
                                </p>
                              </div>
                            </li>
                            <li className="mr-0">
                              <div className="timeline-topp">
                                5
                              </div>
                              <div className="timeline-icon">

                                <img src={answer} className="answer" alt="answer" >
                                </img>

                                <p>
                                  Best
        {t('profile_hero_section.answers')}</p>
                              </div>
                            </li>

                          </ul>
                          <div className=" forms-inpt">
                                <div>
                                  <Form.Group className="w-100" controlId="reg-Form.Controlname">

                                    <Form.Control type="input" placeholder="Type Your Name here" />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group className="mt-2 w-100" controlId="reg-forn.Controlemail">

                                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                                  </Form.Group>
                                </div>
                                <div className="book-me-btnn book-me-reg mt-2" >
                                  Book me Now
                                </div>
                                <div className="book-me-mue"  >
  <img src={two_btn} alt="two-btn" ></img>
  </div>
  <div className=" glass-show" >
                    <img src={glass_btn_3} alt="bookbtn" ></img>

                  </div>
                              </div>
                        </div>
                        <div className="w-100 meu-timeline">
                        <div className="w-100 meu-timeline">
                        <div className=" forms-inpt">
                                <div>
                                  <Form.Group className="w-100" controlId="reg-Form.Controlname">

                                    <Form.Control type="input" placeholder="Type Your Name here" />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group className="mt-2 w-100" controlId="reg-forn.Controlemail">

                                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                                  </Form.Group>
                                </div>
                                <div className=" mt-2 text-center reg-book"  >
                                <img src={three_btn_m} alt="bookbtn" ></img>
                                </div>
                                <div className="book-me-mue mt-2 m-auto" >
  <img src={two_btn} alt="two-btn" ></img>
  </div>
                                <div className="mt-2 glass-show m-auto" >
                    <img src={glass_btn_3} alt="bookbtn" ></img>

                  </div>
                              </div>

              </div>

              </div>
                  </div>


                </div>
                
                </div>
              )}
    
        </div>
        <div className="intro-oter">
                  <div className="intro-banner">
                    <h5>
                      Hello, I’m
                    </h5>
                    <h2>
                      Dyna Appolon
                    </h2>
                    <div className="intro-btn">
                    {t('timeline_form_seven_component.job_title_placeholder')}
                    </div>
                  </div>
                  </div>
                </div>




                <ul className="top-padding reg-social">
                  <li className="social-icone-img">
                    <img src={fb} alt="fb" ></img>

                  </li>
                  <li className="social-icone-img">
                    <img src={insta} alt="insta" ></img>

                  </li>
                  <li className="social-icone-img">
                    <img src={Social_2} alt="twitter" ></img>

                  </li>
                  <li className="social-icone-img">
                    <img src={linked_in} alt="linked-in" ></img>

                  </li>
                  <li className="social-icone-img">
                    <img src={dribble_dark} alt="dribble" ></img>

                  </li>
                  <li className="social-icone-img">
                    <img src={printer_est} alt="printerest" ></img>

                  </li>
                  <li className="social-icone-img">
                    <img src={you_tube} alt="youtube" ></img>
                  </li>
                </ul>
                <div className="meu-social">
                      <ul className="mt-5 top-padding">
                    <li >
                      <FaFacebookF />
                    </li>
                    <li >
                      <RiInstagramFill />
                    </li>
                    <li >
                      <FaTwitter />
                    </li>
                    <li >
                      <FaLinkedinIn />
                    </li>
                    <li >
                      <FaDribbble />
                    </li>
                    <li >
                      <FaPinterest />
                    </li>
                    <li >
                      <FaYoutube />
                    </li>
                    </ul>
                    </div>
                <div className="bottom-tabs" >
                  <FontAwesomeIcon icon={faCloudArrowDown} ></FontAwesomeIcon>
                  <h6>
                     {t('profile_hero_section.download_cv')}
                  </h6>
                </div>
              </div>
            </div>
            <div className="content-hero">
            <img className="meo-shape" src={shape_m}></img>
            <div className="navbar-top p-2 w-100 align-items-center justify-content-between">
              <div className="d-flex">
              <div className="d-flex forms-inpt">
                <div>
                  <Form.Group className="" controlId="reg-Form.Controlname">

                    <Form.Control type="input" placeholder="Type Your Name here" />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group className="" controlId="reg-forn.Controlemail">

                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                  </Form.Group>
                </div>
              </div>
              <div>
              <div className="bookme-oterr">
                  <button className="book-me-btn book-me-reg"  onClick={handleBookmenowClick} >Book me Now</button>
                  <div className="book-me-mue" onClick={handleBookmenowClick} >
  <img src={two_btn} alt="two-btn" ></img>
  </div>  <div className=" glass-show" onClick={handleBookmenowClick}  >
                    <img src={glass_btn_3} alt="bookbtn" ></img>

                  </div>
                  {bookmenow && (
                        <div className="bookme-inner">
                <div className="hamburger-outer">
                  <div className=' mb-5 w-100'>
                    <button className="close-btn" onClick={handleBookmenowClose}>
                    <RiCloseLine />
                    </button>
                    <div className="w-100 reg-timeline mt-5">
                          <ul className="timelinee">
                            <li className="active">
                              <div className="timeline-topp">
                                9
                              </div>
                              <div className="timeline-icon">
                                <img className="message" src={message} alt="message" ></img>
                                <p>
        {t('profile_hero_section.answers')}
                                </p>
                              </div>
                            </li>
                            <li> <div className="timeline-topp">
                              300/
                              1000
                            </div>
                              <div className="timeline-icon">
                                <img src={load} className="load" alt="load" ></img>
                                <p>
                                  Social
                                  score
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="timeline-topp">
                                #1
                              </div>
                              <div className="timeline-icon">
                                <img src={rank} alt="rank" ></img>
                                <p>
                                  Rank
                                </p>
                              </div>
                            </li>
                            <li className="mr-0">
                              <div className="timeline-topp">
                                5
                              </div>
                              <div className="timeline-icon">

                                <img src={answer} className="answer" alt="answer" >
                                </img>

                                <p>
                                  Best
        {t('profile_hero_section.answers')}</p>
                              </div>
                            </li>

                          </ul>
                          <div className=" forms-inpt">
                                <div>
                                  <Form.Group className="w-100" controlId="reg-Form.Controlname">

                                    <Form.Control type="input" placeholder="Type Your Name here" />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group className="mt-2 w-100" controlId="reg-forn.Controlemail">

                                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                                  </Form.Group>
                                </div>
                                <div className="book-me-btnn book-me-reg mt-2" >
                                  Book me Now
                                </div>
                                <div className="book-me-mue" >
  <img src={two_btn} alt="two-btn" ></img>
  </div>
  <div className=" glass-show" onClick={handleBookmenowClick}  >
                    <img src={glass_btn_3} alt="bookbtn" ></img>

                  </div>
                              </div>
                        </div>
            

                        <div className="w-100 meu-timeline">
                        <div className="w-100 meu-timeline">
                        <div className=" forms-inpt">
                                <div>
                                  <Form.Group className="w-100" controlId="reg-Form.Controlname">

                                    <Form.Control type="input" placeholder="Type Your Name here" />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group className="mt-2 w-100" controlId="reg-forn.Controlemail">

                                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                                  </Form.Group>
                                </div>
                                <div className=" mt-2 text-center meo-show"  >
                                <img src={three_btn_m} alt="bookbtn" ></img>
                                </div>
                                <div className="book-me-mue mt-2 m-auto" >
  <img src={two_btn} alt="two-btn" ></img>
  </div>
                                <div className=" mt-2 glass-show m-auto" >
                    <img src={glass_btn_3} alt="bookbtn" ></img>

                  </div>
                              </div>

              </div>

              </div>
                  </div>


                </div>
                
                </div>
              )}

    
        </div>
              </div>
              </div>
              <div className="d-flex align-items-center tab-seeall w-50">
                <div className="testimonial">
                  <div className="timeline-top d-flex">
                    <div className="d-flex">
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                    </div>
                    <div>
                      <p>
                        dummy text of the
                        printing  printing
                        <p><b>Client Name Here</b>
                          Designation Here</p>
                      </p>


                    </div>

                  </div>
                  <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                </div>

                <div className="bars-seeall">
                  <div className="testimonial-seeall" onClick={handleSeeallClick}>
                    <h5>See all </h5> <img src={down_icon} className="down-icon" alt="down_icon" ></img>
                    <img src={down_gold} alt="down_gold" className="down-gold" ></img>
                  </div>

                  {seeall && (
                    <div className="hamburger-outer">
                      <div className=' mb-5 w-100'>
                        <button className="close-btn" onClick={handleSeeallClose}>
                        <RiCloseLine />
                        </button>
                        <div className="d-flex align-items-center">

                          <div className="switch-outer">
                            <input type="checkbox" hidden="hidden" id="mobile-1-1" />
                            <label class="switch" for="mobile-1-1"></label>
                          </div>
                          <div className="social-set" >
                         {t('profile_hero_section.social_mode')}
                          </div>
                        </div>

                      </div>

                      <div>
              
                        <p className="testimonial-p text-center m-auto">
                          Testimonials:
                        </p>
                        <div>

                          <div className=" w-100">

                            <div className="testimonial">
                              <div className="timeline-top d-flex">
                                <div className="d-flex">
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                </div>
                                <div>
                                  <p>
                                 {t('profile_hero_section.dummy_text_of_the_printing_printing')}
                                <p><b> {t('profile_hero_section.client_name_here')}</b>
                                  {t('profile_hero_section.designation_here')}</p>
                                  </p>


                                </div>

                              </div>
                            <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                            </div>
                            <div className="testimonial">
                              <div className="timeline-top d-flex">
                                <div className="d-flex">
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                </div>
                                <div>
                                  <p>
                                 {t('profile_hero_section.dummy_text_of_the_printing_printing')}
                                <p><b> {t('profile_hero_section.client_name_here')}</b>
                                  {t('profile_hero_section.designation_here')}</p>
                                  </p>


                                </div>

                              </div>
                            <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                            </div>
                            <div className="testimonial">
                              <div className="timeline-top d-flex">
                                <div className="d-flex">
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                </div>
                                <div>
                                  <p>
                                 {t('profile_hero_section.dummy_text_of_the_printing_printing')}
                                <p><b> {t('profile_hero_section.client_name_here')}</b>
                                  {t('profile_hero_section.designation_here')}</p>
                                  </p>


                                </div>

                              </div>
                            <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                            </div>
                          </div>
                      


                        </div>

                      </div>

                    </div>
                  )}

                </div>
              </div>
              <div className="w-100 timeline-tab reg-timeline">
                <ul className="timeline">
                  <li className="active">
                    <div className="timeline-top">
                      9
                    </div>
                    <div className="timeline-icon">
                      <img className="message" src={message} alt="message" ></img>
                      <p>
                        Answers
                      </p>
                    </div>
                  </li>
                  <li> <div className="timeline-top">
                    300/
                    1000
                  </div>
                    <div className="timeline-icon">
                      <img src={load} className="load" alt="load" ></img>
                      <p>
                        Social
                        score
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-top">
                      #1
                    </div>
                    <div className="timeline-icon">
                      <img src={rank} alt="rank" ></img>
                      <p>
                        Rank
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-top">
                      5
                    </div>
                    <div className="timeline-icon">

                      <img src={answer} className="answer" alt="answer" >
                      </img>

                      <p>
                        Best
                        Answers</p>
                    </div>
                  </li>

                </ul>

              </div>
      
              <div className="w-100 meu-timeline">
                <ul className="timeline">
                  <div className="active-oter">
                    <li className="active">
                      <div className="timeline-top">
                        9
                      </div>
                      <div className="timeline-icon">
                        <img className="message" src={message_gold} alt="message" ></img>
                        <p>
{t('profile_hero_section.answers')}
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li> <div className="timeline-top">
                      300/
                      1000
                    </div>
                      <div className="timeline-icon">
                        <img src={rank_gold} className="load" alt="load" ></img>
                        <p>
                          {t('profile_hero_section.social_score')}
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li>
                      <div className="timeline-top">
                        #1
                      </div>
                      <div className="timeline-icon">
                        <img src={gold_star} alt="rank" ></img>
                        <p>
                        {t('profile_hero_section.rank')}
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li>
                      <div className="timeline-top">
                        5
                      </div>
                      <div className="timeline-icon">

                        <img src={cap_gold} className="answer" alt="answer" >
                        </img>

                        <p>
                          {t('profile_hero_section.best_answers')}</p>
                      </div>
                    </li>
                  </div>


                </ul>

              </div>
            </div>

              <div className="hero-conent">
                <div className="hero-section">
                  <img src={reg_tab_bg} alt="backgrnd-2" className="content-img hero-section-reg" >
                  </img>
                  <div className="hero-section-mue">
                  <img src={tab_two_m} alt="backgrnd-2" className="content-img" >
                  </img>
     
                </div>
                </div>
                
                <div className="himself-section">

                <div className="tab-section">
                  
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
<div className="d-flex flex-column-reverse ">

   
       
          <div className="d-flex align-items-center justify-content-center  w-100 mb-0">

<Nav variant="pills" className="w-100 d-flex align-items-center justify-content-center justify-center ">
<ul className="d-flex   my-4 pb-2 align-items-center modal-tabs justify-content-center">

  <Nav.Item as="li" className="me-0">
    <Nav.Link className=" m-0 p-0 design-about  border-0" eventKey="first">                  <span className="d-flex align-items-center "> {t('profile_section.who_am_i')}</span>
</Nav.Link>
  </Nav.Item>
  <Nav.Item as="li" className="margin-left-scnd margin-left-btn" >
    <Nav.Link className=" m-0 p-0 design-about  border-0" eventKey="second">    <span className="d-flex align-items-center ">  
                    {t('profile_section.what_i_do')}</span> </Nav.Link>
    
  </Nav.Item >
  <Nav.Item as="li" className="margin-left-scnd margin-left-btn" >
    <Nav.Link className=" m-0 p-0 design-about  border-0" eventKey="three">                  <span className="d-flex align-items-center "> {t('profile_section.who_is_my_service_for')}</span></Nav.Link>
    
  </Nav.Item>
  <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
    <Nav.Link className=" m-0 p-0 design-about  border-0" eventKey="forr">
    <span className="d-flex align-items-center ">  
                  {t('profile_section.why_work_with_me')}</span>
    </Nav.Link>
    
  </Nav.Item>
  </ul>
</Nav>
</div>

<Tab.Content>
            <Tab.Pane eventKey="first">
            <div className=" tab-himself-text ">
              
                  <div className="d-flex">
                  <div className="himself-left">
                             <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                          <div className="himself-right">
                          <div className="playlist-section">
                  
   
                    <p>
                    And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.
                    </p>
                  </div>
                          </div>
                  </div>
                         
                   
                        </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
            <div className=" tab-himself-text  ">
              
                  <div className="d-flex">
                  <div className="himself-left">
                             <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                          <div className="himself-right">
                          <div className="playlist-section">
                  

                    <p>
                    And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.
                    </p>
                  </div>
                          </div>
                  </div>
                         
                   
                        </div>
            </Tab.Pane>
            <Tab.Pane eventKey="three">
            <div className=" tab-himself-text ">
              
                  <div className="d-flex">
                  <div className="himself-left">
                             <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                          <div className="himself-right">
                          <div className="playlist-section">
                  

                    <p>
                    And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.
                    </p>
                  </div>
                          </div>
                  </div>
                         
                   
                        </div>
            </Tab.Pane>
            <Tab.Pane eventKey="forr">
            <div className=" tab-himself-text ">
              
                  <div className="d-flex">
                  <div className="himself-left">
                             <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                          <div className="himself-right">
                          <div className="playlist-section">
                  

                    <p>
                    And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.
                    </p>
                  </div>
                          </div>
                  </div>
                         
                   
                        </div>
            </Tab.Pane>
            <div className="video-btn-oter">
                          <div className="video-section">
                    <div className="video-img">
                      <img src={vedio} alt="vedio" ></img>
                    </div>
                  </div>
                            </div>
                            <div className="playlist-section">
                  
                  <div className="playlist-section-text">
                  <div className="meu-playlist-section">
<div className="d-flex align-items-center justify-content-center">
<div className="next-icon">
<MdSkipNext />
</div>
<div className="mic-img reg-img">
<img src={mic_img} alt="mic" ></img>
</div>
<div className="mic-img meu-img">
                    <img src={plalist_gold} alt="mic" ></img>

                  </div>
<div className="next-icon">
<MdSkipPrevious />
</div>
</div>
<div className="d-flex align-items-center justify-content-center mt-2" >
<div className="slidecontainer mb- text-start">
            <RangeSlider value={Competency1} onChange={changeEvent => setCompetency1(changeEvent.target.value)} min={0} max={15} />
        </div>
        <div className="next-icon-center">
          <RiDownloadLine className="iconss" />
        </div>
        <div className="video-icon reg-icon">
          <RiPlayCircleFill />
        </div>
        <div className=" meu-icon ">
                    <div className="video-icon">
                      <RiPlayMiniLine />
                    </div>

                  </div>
        <div className="next-icon-center">
          <RiShareForwardLine className="iconss" />
          </div>
          <div className="setting-icons ">
            <RxGear className="icons-set" />
            <RiFullscreenLine className="icons-set" />
          </div>
</div>
</div>
<div className="d-flex align-items-center justify-content-center ">
                <div className="reg-sond">
                  <img src={sond_right}  alt="" ></img>
                </div>
                <div className="glass-sond">
                  <img src={sond_2} alt="" ></img>
                </div>
                <div>
                  <div className="text-gree">
                    {t('profile_section.episode_5_season_1')}

                  </div>
                  <div className="side-text">
                    {t('profile_hero_section.seven_figure_bosses_series')}

                  </div>
                </div>


                <div className="reg-sond">
                  <img src={sond_left} alt="" ></img>
                </div>
                <div className="glass-sond">
                  <img src={sond_1} alt="" ></img>
                </div>
              </div>
</div>
       
          </div>

          </Tab.Content>
</div>
</Tab.Container>
                 
                  </div>
                  <img src={mue_shape} className="mue-shape" alt="mue-shape"></img>
                </div>

            
              </div>

            </div>

            <div>

            </div>
          </div>
        </div>
            </div>
        
        

     

    </div >
  );
}
