import React from "react";
import { useState } from "react";
import subscribeUserIcon from "../../../../../../images/subscription/subscribe-user-icon.png";
import subscribeAroundTextImg from "../../../../../../images/subscription/subscribr-text-img.svg";
import subscriptionChoosePoisonSlideImg1 from "../../../../../../images/subscription/subscription-choose-poison-slide-img-1.png";
import subscriptionChoosePoisonSlideImg2 from "../../../../../../images/subscription/subscription-choose-poison-slide-img-2.png";
import subscriptionChoosePoisonSlideImg3 from "../../../../../../images/subscription/subscription-choose-poison-slide-img-3.png";
import subscriptionChoosePoisonSlideImg4 from "../../../../../../images/subscription/subscription-choose-poison-slide-img-4.png";
import "../regularTheme1Desktop/RegularTheme1Desktop.scss";
import "../regularTheme1Mobile/RegularTheme1Mobile.scss";
import "./RegularTheme1Tab.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
export default function RegularTheme1Desktop({
  Regular,
  Neumorphic,
  Glassmorphic,
}) {
  const [isSwitchToggled, toggle] = useState(false);
  const callbackTab = () => {
    toggle(!isSwitchToggled);
  };
  return (
    <>
      <div
        className={`subscription-tab-theme1 ${
          Regular
            ? "regular-style"
            : Neumorphic
            ? "neumorphic-style"
            : Glassmorphic
            ? "glassmorphic-style"
            : ""
        }`}
      >
        <div
          className={`subscription-tab-theme1-main-wrapper ${
            Regular
              ? "regular-style"
              : Neumorphic
              ? "neumorphic-style"
              : Glassmorphic
              ? "glassmorphic-style"
              : ""
          }`}
        >
          <div
            className={`subscribe-img-wrapper ${
              Regular
                ? "regular-style"
                : Neumorphic
                ? "neumorphic-style"
                : Glassmorphic
                ? "glassmorphic-style"
                : ""
            }`}
          >
            <img
              src={subscribeUserIcon}
              alt="subscribeUserIcon"
              className="subscribeUserIcon"
            ></img>
            <img
              src={subscribeAroundTextImg}
              alt="subscribeAroundTextImg"
              className="subscribeAroundTextImg"
            ></img>
            <p
              className={`choose-position ${
                Regular ? "regular-style" : Neumorphic ? "neumorphic-style" : ""
              }`}
            >
              CHOOSE YOUR POSITION
            </p>
          </div>

          <div className="regular-main-wrapper">
            <div className="subscription-images-wrapper">
              <div className="images-main-wrapper">
                <div className="images-wrapper d-flex">
                  <img
                    src={subscriptionChoosePoisonSlideImg1}
                    alt="subscriptionChoosePoisonSlideImg"
                    className="subscriptionChoosePoisonSlideImg1"
                  ></img>
                  <img
                    src={subscriptionChoosePoisonSlideImg2}
                    alt="subscriptionChoosePoisonSlideImg"
                    className="subscriptionChoosePoisonSlideImg2"
                  ></img>
                  <img
                    src={subscriptionChoosePoisonSlideImg3}
                    alt="subscriptionChoosePoisonSlideImg"
                    className="subscriptionChoosePoisonSlideImg3"
                  ></img>
                  <img
                    src={subscriptionChoosePoisonSlideImg4}
                    alt="subscriptionChoosePoisonSlideImg"
                    className="subscriptionChoosePoisonSlideImg4"
                  ></img>
                </div>
              </div>
              <div
                className={`d-flex justify-center sign-up-form ${
                  Regular
                    ? "regular-style"
                    : Neumorphic
                    ? "neumorphic-style"
                    : Glassmorphic
                    ? "glassmorphic-style"
                    : ""
                }`}
              >
                <Form>
                  <Form.Group>
                    <div
                      className={`form-group ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <Form.Control type="text" placeholder="Your@gmail.com" />
                      <Button variant="primary" type="submit">
                        SIGNUP
                      </Button>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div
              className={`on-off-buttons-wrapper ${
                Regular
                  ? "regular-style"
                  : Neumorphic
                  ? "neumorphic-style"
                  : Glassmorphic
                  ? "glassmorphic-style"
                  : ""
              }`}
            >
              <ul className="btns-list">
                <li>
                  <label
                    className={`labelSwitch ${
                      Regular
                        ? "regular-style"
                        : Neumorphic
                        ? "neumorphic-style"
                        : Glassmorphic
                        ? "glassmorphic-style"
                        : ""
                    }`}
                    htmlFor="events"
                  >
                    <input
                      type="checkbox"
                      defaultChecked={isSwitchToggled}
                      onClick={callbackTab}
                      id="events"
                    />
                    <span className="span"></span>
                    <strong>EVENTS</strong>
                  </label>
                </li>
                <li>
                  <label
                    className={`labelSwitch ${
                      Regular
                        ? "regular-style"
                        : Neumorphic
                        ? "neumorphic-style"
                        : Glassmorphic
                        ? "glassmorphic-style"
                        : ""
                    }`}
                    htmlFor="newsletter"
                  >
                    <input
                      type="checkbox"
                      defaultChecked={true}
                      onClick={callbackTab}
                      id="newsletter"
                    />
                    <span className="span"></span>
                    <strong>NEWSLETTER</strong>
                  </label>
                </li>
                <li>
                  <label
                    className={`labelSwitch ${
                      Regular
                        ? "regular-style"
                        : Neumorphic
                        ? "neumorphic-style"
                        : Glassmorphic
                        ? "glassmorphic-style"
                        : ""
                    }`}
                    htmlFor="blogPost"
                  >
                    <input
                      type="checkbox"
                      defaultChecked={isSwitchToggled}
                      onClick={callbackTab}
                      id="blogPost"
                    />
                    <span className="span"></span>
                    <strong>BLOG POSTS</strong>
                  </label>
                </li>
                <li>
                  <label
                    className={`labelSwitch ${
                      Regular
                        ? "regular-style"
                        : Neumorphic
                        ? "neumorphic-style"
                        : Glassmorphic
                        ? "glassmorphic-style"
                        : ""
                    }`}
                    htmlFor="channel"
                  >
                    <input
                      type="checkbox"
                      defaultChecked={isSwitchToggled}
                      onClick={callbackTab}
                      id="channel"
                    />
                    <span className="span"></span>
                    <strong>VIDEO CHANNEL</strong>
                  </label>
                </li>
                <li>
                  <label
                    className={`labelSwitch ${
                      Regular
                        ? "regular-style"
                        : Neumorphic
                        ? "neumorphic-style"
                        : Glassmorphic
                        ? "glassmorphic-style"
                        : ""
                    }`}
                    htmlFor="podcast"
                  >
                    <input
                      type="checkbox"
                      defaultChecked={isSwitchToggled}
                      onClick={callbackTab}
                      id="podcast"
                    />
                    <span className="span"></span>
                    <strong>PODCAST</strong>
                  </label>
                </li>
                <li>
                  <label
                    className={`labelSwitch ${
                      Regular
                        ? "regular-style"
                        : Neumorphic
                        ? "neumorphic-style"
                        : Glassmorphic
                        ? "glassmorphic-style"
                        : ""
                    }`}
                    htmlFor="subscribe"
                  >
                    <input
                      type="checkbox"
                      defaultChecked={isSwitchToggled}
                      onClick={callbackTab}
                      id="subscribe"
                    />
                    <span className="span"></span>
                    <strong>SUBSCRIBE TO ALL</strong>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
