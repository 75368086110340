import React from "react";
import { useState } from "react";
import 'react-calendar/dist/Calendar.css'
import EventsSlider from "../components/EventsSlider/EventsSlider"
import Calendar from 'react-calendar'
import EventsTimeline from '../components/EventsTimeline/EventsTimeline'
import "./RegularTheme1Desktop.scss";
import "../regularTheme1Mobile/RegularTheme1Mobile.scss";
import "../regularTheme1Tab/RegularTheme1Tab.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

export default function RegularTheme1Desktop({ Regular, Neumorphic }) {
  const [isVisible, setIsVisible] = useState(false);
  const [value, onChange] = useState(new Date());


  return (
    <>
      <div
        className="main-content RegularTheme1Desktop h_1550"
        style={{
          background: Regular ? "#CBD5E0" : Neumorphic ? "#EFF4F8" : "",
        }}
      >
        {/* <div
          className="pattern"
          style={{ display: Regular ? "block" : "none" }}
        >
          <img src={Regular ? pattern : undefined} alt="" />
        </div>
        <div className="pattern-two">
          <img src={Dots} alt="" />
        </div> */}

        <div className="text-center-desk">
          <p className="top_heading">MY EVENTS</p>
        </div>

        <div className='container eventTab mb-4 pb-3 mt-5 mt-lg-0'>
          <div id="myTab" className='slidetab' role="tablist">
            <EventsSlider Regular={Regular} Neumorphic={Neumorphic} />
          </div>
        </div>

        <div className="">
        <Calendar className="mx-auto" onChange={onChange} value={value} />
        </div>
        <div>
          <EventsTimeline />
        </div>

        {/* <div className="main-container-desk">
          <HireMeMainContent Regular={Regular} Neumorphic={Neumorphic} />
        </div>
        <div className="position-box-desk">
          <HireMePositionBox Regular={Regular} Neumorphic={Neumorphic} />
        </div> */}
        {/* Form - nav  */}
        {/* <div className="form-position-desk"> */}
        {/* <HireMeForm Regular={Regular} Neumorphic={Neumorphic} /> */}
        {/* </div> */}
        {/* side-position-box */}
        {/* <div className="side-position-box-desk">
          <HireMeSideBox Regular={Regular} Neumorphic={Neumorphic} />
        </div> */}
      </div>
    </>
  );
}
