import React, { useState } from "react";
import RangeSelect from "../../../../../global/rangeSelect/RangeSelect";

import { EducationRange } from "../../../../../global/rangeSelect/RangeValues";
import MultiRangeSlider from "../../../../../global/CustomRange";
import RangeSlider from "react-bootstrap-range-slider";
import { useTranslation } from "react-i18next";

export default function Education() {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(2018);
  const [selectedRange, setSelectedRange] = useState(
    EducationRange[Math.floor(EducationRange.length / 2)] 
  );
  return (
    <div className="text-center mb-4">
      <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.education')}</h4>
      <div className="shadow-4 h-355 br-20 pt-4 pb-5 px-4 d-flex justify-content-center align-items-center flex-column">
        <div className="row w-100 mt-5">
          <div className="col-md-5 text-start secDivide">
            <h4 className="h10">{t('profile_section.institution_university')}</h4>
            <h5 className="h7 mb-3">{t('profile_section.smith_school_of_business')}</h5>
            <h4 className="h10">{t('profile_section.major_specialization')}</h4>
            <h5 className="h7">{t('profile_section.mba_mrketing_strategy')}</h5>
          </div>
          <div className="col-md-7">
            <div className="row w-100 mx-0">
              <div className="col-md-12 px-0 d-flex justify-content-center align-items-center flex-column custom-sr">
                <RangeSlider
                  value={value}
                  onChange={(changeEvent) =>
                    setValue(changeEvent.target.value)
                  }
                  min={2000}
                  max={2030}
                />
                <span className="d-flex justify-content-between w-100 flex-column">
                <h5 className="shadow-4 mt-2 h7 d-flex w-125 mx-auto justify-content-center align-items-center fw-bold text-uppercase py-2 br-5 px-2">{t('profile_section.graduated_in')}</h5>
                <h4 className="shadow-5in bg px-2 h7 w-125 mx-auto py-2 fw-bold text-uppercase rounded-1 fw-bold d-inline-block">{t('profile_section.im_an_alumni')}</h4>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 dropLink large pointer">
          <h4 className="h7 mb-3">{t('profile_section.education_level')}</h4>
          {/* <RangeSelect
              data={EducationRange}
              type="ages"
              selectedRange={selectedRange}
              setSelectedRange={setSelectedRange}
              value={selectedRange}
            /> */}
            <MultiRangeSlider />
        </div>
      </div>
    </div>
  );
}
