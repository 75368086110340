import React from "react";
import BusinessSlider from "../../../global/BusinessSlider";


export default function DigitalBusinessGenerate() {
    return (
        <div className="Tab_settings">
            <BusinessSlider />
        </div >
    );
}
