import React, { useState } from "react";
import "./HireMeForm.scss";
import "../../../../../../../images/hire-me/desktopview/form2.png";

const HireMeForm = ({ Regular, Neumorphic }) => {
  const [active, setActive] = useState(1);
  return (
    <>
      <div
        className={`form-position ${
          Regular ? "Regular-style" : Neumorphic ? "Neumorphic-style" : ""
        }`}
      >
        <div className="d-flex">
          <h2 className="form-heading">Hire</h2>
          <h2 className="form-heading-one">Me</h2>
        </div>
        <div className="line">.</div>
        <div className="circles d-flex justify-content-between">
          <div
            className={`c-s 
          ${active === 1 ? "c-si" : ""}`}
            onClick={() => setActive(1)}
          >
            1
          </div>
          <div
            className={`c-s 
          ${active === 2 ? "c-si" : ""}`}
            onClick={() => setActive(2)}
          >
            2
          </div>
          <div
            className={`c-s 
          ${active === 3 ? "c-si" : ""}`}
            onClick={() => setActive(3)}
          >
            3
          </div>
        </div>
        <div className="circle-text d-flex justify-content-between w-100">
          <span>You</span>
          <span>Service</span>
          <span>Booking</span>
        </div>
        {active === 1 ? (
          <>
            {" "}
            <form action="#" className="inputs-all">
              <div>
                <label htmlFor="">Name</label>
                <input
                  className="inp-s"
                  type="text"
                  placeholder="Name"
                  name=""
                  id=""
                />
              </div>
              <div>
                <label htmlFor="">Email</label>
                <input
                  className="inp-s"
                  type="text"
                  placeholder="Email"
                  name=""
                  id=""
                />
              </div>
              <div>
                <label htmlFor="">Phone</label>
                <input
                  className="inp-s"
                  type="text"
                  placeholder="Phone Number"
                  name=""
                  id=""
                />
              </div>
              <div>
                <input className="submit-btn-m" type="submit" value="SUBMIT" />
              </div>
            </form>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default HireMeForm;
