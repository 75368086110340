import React from "react";
import ReactStars from "react-rating-stars-component";
import avatar from "../../../../../images/testimonial-case-study/avatar.png";
import avatar_mobile from "../../../../../images/testimonial-case-study/avatar_mobile.png";
import neumorphic_mobile_avatar from "../../../../../images/testimonial-case-study/neumorphic_mobile_avatar.png";

import "./RegularTheme1.scss";

export default function RegularTheme1(props) {
  const ratingChanged = (newRating) => {
  };
  let renderedAvatar;
  let renderedRatingColor;

  if (props.theme === "Regular") {
    renderedRatingColor = "#7CBAA7";
  } else {
    renderedRatingColor = "#FACB30";
  }

  ///
  if (props.screenMode === "Mobile") {
    if (props.theme === "Regular" || props.theme === "Glassmorphic") {
      renderedAvatar = avatar_mobile;
    } else {
      renderedAvatar = neumorphic_mobile_avatar;
    }
  } else {
    renderedAvatar = avatar;
  }

  return (
    <div className="desktop-dot-container">
      <div className="desktop-profile-div">
        <div className="desktop-profile-detail-div">
          <div className="desktop-profile-detail-heading-div">
            <p>What our client say</p>
            <span> About Us</span>
          </div>

          <div className="desktop-profile-detail-avatar-div">
            <img src={renderedAvatar} alt="" srcset="" />
          </div>

          <div className="profile-desc-outer-div">
            <div className="desktop-profile-detail-desc-div">
              <div className="rating-stars-div">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={20}
                  activeColor={renderedRatingColor}
                />
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur. Duis elementum varius
                duis eu donec. Tellus ut metus sed id mi consequat odio nunc
                velit.
              </p>
              <h3>DYNA APPOLON</h3>
              <h4>CEO | SOCIAL NETWORK STRATEGIST</h4>
            </div>
          </div>
        </div>

        <div className="see-my-wall-div">
          <p>SEE MY</p>
          <a href="#">WALL OF LOVE</a>
        </div>
      </div>
    </div>
  );
}
