import React, { useState } from "react";
import { Link } from 'react-router-dom';
import leftbtn from '../../images/dropdown/leftbtn.png';
import rightbtn from '../../images/dropdown/rightbtn.png';
import mainimg from '../../images/dropdown/mainimg.png';
import mainimg1 from '../../images/dropdown/img1.png';
import mainimg2 from '../../images/dropdown/img2.png';
import mainimg3 from '../../images/dropdown/img3.png';
import mainimg4 from '../../images/dropdown/img4.png';
import mainimg5 from '../../images/dropdown/img5.png';
import mainimg6 from '../../images/dropdown/img6.png';
import mainimg7 from '../../images/profileview/img1.png';
import mainimg8 from '../../images/profileview/imgs2.png';
import mainimg9 from '../../images/profileview/imgs3.png';
import tag from '../../images/dropdown/tag.png';
import ImageBox from "../global/ImageBox";

export default function SimpleView1({ setHandleView }) {
  return (
    <div className="bottomlayer">
      <div className='buttontop position-relative bottom-10s'>
        <button>DYNA APPOLON</button>
      </div>
      <div className='mainimg'>
        <img src={mainimg} alt='' />
        <div className='centerbar'>
          <Link to=""><img src={mainimg1} alt='' />43</Link>
          <Link to=""><img src={mainimg2} alt='' />24.5k</Link>
          <Link to=""><img src={mainimg3} alt='' />587</Link>
          <Link to=""><img src={mainimg4} alt='' /></Link>
          <Link to=""><img src={mainimg5} alt='' /></Link>
        </div>
        <div className='left-drop'>
          <img src={leftbtn} alt='' onClick={() => setHandleView('video')} />
        </div>
        <div className='right-drop'>
          <img src={rightbtn} alt='' onClick={() => setHandleView('audio')} />
        </div>
      </div>
      <div className='buttontop position-relative top-10s'>
        <button>CEO | 9 TO 5 ESCAPEE</button>
      </div>
      <img src={mainimg6} alt='' className='imgsbtm' />
      <ul class="d-flex justify-content-center mb-4 px-4">
        <li className='me-2'>
          <div className='outerdiv'>
            <img src={mainimg7} alt="" className="circle" />
          </div>
        </li>
        <li className='me-2'>
          <div className='outerdiv'>
            <img src={mainimg8} alt="" className="circle" />
          </div>
        </li>
        <li className=''>
          <div className='outerdiv position-relative'>
            <img src={mainimg9} alt="" className="circle" />
            <div className="tag position-absolute">
              <ImageBox boxwidth="w-16" src={tag} />
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
