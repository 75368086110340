import React, { useEffect, useRef, useState } from 'react';
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
import ButtonBox from '../../../global/ButtonBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import ProfileInfoOne from "./ProfileSingle/ProfileInfoOne";
import ProfileInfoTwo from "./ProfileSingle/ProfileInfoTwo";
import ProfileInfoThree from "./ProfileSingle/ProfileInfoThree";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
// import VideoViewSec from '../../../global/VideoViewSec';
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';
export default function ProfileInfoTab() {

  const { t, i18n } = useTranslation();
  let {isVideoVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };
   

  return (
    <div className='' >
      <div className="w-1070 mb-4 align-items-center mx-auto d-flex flex-column-reverse justify-content-between" >
        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto mb-5">
          <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
          <h3 className="mb-3 d-flex justify-content-between w-780">{t('profile_section.enter_your_profile_details')} <small>{t('step')} <b>1</b> of 11</small></h3>
          <div className="ProgressBar">
            <ul>
                <NavItem as="li" className="active" id="profile"></NavItem>
                <NavItem as="li" id="chat"></NavItem>
                <NavItem as="li" id="location"></NavItem>
                <NavItem as="li" id="user"></NavItem>
                <NavItem as="li" id="briefcase"></NavItem>
                <NavItem as="li" id="graduation-cap"></NavItem>
                <NavItem as="li" id="favorite"></NavItem>
                <NavItem as="li" id="award"></NavItem>
                <NavItem as="li" id="connectivity"></NavItem>
                <NavItem as="li" id="lock"></NavItem>
                <NavItem as="li" id="eye"></NavItem>
            </ul>
          </div>
          {/* {isVideoVisible ?
          <div className="mb-5 w-100">
            <VideoViewSec handleClick6={handleClick6}/>
          </div>
          :
          <></>
        } */}
          <div className="d-flex flex-column-reverse">
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane listOne fade show active" id="profileOne" role="tabpanel" aria-labelledby="profileOne">
                  <ProfileInfoOne />
                </div>
                <div className="tab-pane listTwo fade" id="profileTwo" role="tabpanel" aria-labelledby="profileTwo">
                  <ProfileInfoTwo />
                </div>
                <div className="tab-pane listTwo fade" id="profileThree" role="tabpanel" aria-labelledby="profileThree">
                  <ProfileInfoThree />
                </div>
            </div>
            <span className="d-flex align-items-center justify-content-between w-100 mb-0 TabBtn">
                <div id="myTab1" className='w-100' role="tablist">
                  <ul className="d-flex justify-content-center my-4 pb-2">
                    <NavItem as="li" className="me-0">
                      <button className="listOne m-0 p-0 firsttab border-0 active" id="profileOne" data-bs-toggle="tab" data-bs-target="#profileOne" type="button" role="tab" aria-controls="profileOne" aria-selected="true">
                        <span>1</span>
                      </button>
                    </NavItem>
                    <NavItem as="li" className="ms-4">
                      <button className="listTwo m-0 p-0 border-0 sectab " id="profileTwo" data-bs-toggle="tab" data-bs-target="#profileTwo" type="button" role="tab" aria-controls="profileTwo" aria-selected="false">
                        <span>2</span>
                      </button>
                    </NavItem>
                    <NavItem as="li" className="ms-4">
                      <button className="listTwo m-0 p-0 border-0 thrdtab" id="profileThree" data-bs-toggle="tab" data-bs-target="#profileThree" type="button" role="tab" aria-controls="profileThree" aria-selected="false">
                        <span>3</span>
                      </button>
                    </NavItem>
                  </ul>
                </div>
            </span>
          </div>
        </div>
        <span className="d-flex align-items-center justify-content-between w-100 mb-4">
          <div id="myTab1" className='' role="tablist">
            <button className="buttonArrow me-3 firsttab text-body border-0 bg br-10 active" id="profileOne" data-bs-toggle="tab" data-bs-target="#profileOne" type="button" role="tab" aria-controls="profileOne" aria-selected="true">
              <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
            </button>
            <button className="buttonArrow me-3 sectab text-body border-0 bg br-10" id="profileTwo" data-bs-toggle="tab" data-bs-target="#profileTwo" type="button" role="tab" aria-controls="profileTwo" aria-selected="false">
              <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
            </button>
            <button className="buttonArrow me-3 thrdtab text-body border-0 bg br-10" id="profileThree" data-bs-toggle="tab" data-bs-target="#profileThree" type="button" role="tab" aria-controls="profileThree" aria-selected="false">
              <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
            </button>
          </div>
          <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="Saved" icon={faFloppyDisk} name="Save" />
        </span>
      </div>
    </div>   
  )
}
