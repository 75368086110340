import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import subscriptionCenterRegularRating3Stars from "../../../../../../images/subscription/subscription-center-title-regular-rating-3stars.png";
import subscriptionCenterNeumorphicRating3Stars from "../../../../../../images/subscription/subscription-center-neumorphic-rating3-stars.png";
import subscriptionCenterGlassmorphicRating3Stars from "../../../../../../images/subscription/subscription-center-title-glassmorphic-rating-3stars.png";
import subscribeUserIcon from "../../../../../../images/subscription/subscribe-user-icon.png";
import subscribeAroundTextImg from "../../../../../../images/subscription/subscribr-text-img.svg";
import subscribeAroundTextTheme2Img from "../../../../../../images/subscription/subscribr-text-theme2-img.png";
import theme2TopLeftDotsImg from "../../../../../../images/subscription/theme2-top-left-dots-img.png";
import theme2TopRightDotsImg from "../../../../../../images/subscription/theme2-top-right-dots-img.png";
import theme2BottomRightDotsImg from "../../../../../../images/subscription/theme2-bottom-right-dots-bg-img.png";
import theme2GlassmorphicTopLeftDotsImg from "../../../../../../images/subscription/theme2-glassmorphic-top-left-dots-img.png";
import theme2GlassorpicTopRightDotsImg from "../../../../../../images/subscription/theme2-glassmorphic-top-right-dots-img.png";
import theme2GlassmorphicBottomRightDotsImg from "../../../../../../images/subscription/theme2-glassmorphic-bottom-right-dots-img.png";
import theme2NeumorphicTopLeftDotsImg from "../../../../../../images/subscription/theme2-neumorphic-top-left-dots-img.png";
import theme2NeumorphicTopRightDotsImg from "../../../../../../images/subscription/theme2-neumorphic-top-right-dots-img.png";
import theme2NeumorphicBottomRightDotsImg from "../../../../../../images/subscription/theme2-neumorphic-bottom-right-dots-img.png";
import "./RegularTheme2Desktop.scss";
import "../regularTheme2Mobile/RegularTheme2Mobile.scss";
import "../regularTheme2Tab/RegularTheme2Tab.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Form from "react-bootstrap/Form";
export default function RegularTheme2Desktop({
  Regular,
  Neumorphic,
  Glassmorphic,
}) {
  const [isSwitchBtn, setSwitchBtn] = useState(false);

  const switchToggleBtn = () => {
    setSwitchBtn(!isSwitchBtn);
  };
  return (
    <>
      <div
        className="subscription-desktop-theme2"
        style={{
          background: Regular
            ? "#CBD5E0"
            : Neumorphic
            ? "#F0F4F8"
            : Glassmorphic
            ? "linear-gradient(326deg, #9595B8 0%, #ECEDF5 46.35%, #C8C9D2 70.83%, #97999D 100%)"
            : "",
        }}
      >
        <div
          className={`theme-2-main-wrapper  ${
            Regular
              ? "regular-style"
              : Neumorphic
              ? "neumorphic-style"
              : Glassmorphic
              ? "glassmorphic-style"
              : ""
          }`}
        >
          <div
            className={`subscription-desktop-theme2-main-wrapper ${
              Regular
                ? "regular-style"
                : Neumorphic
                ? "neumorphic-style"
                : Glassmorphic
                ? "glassmorphic-style"
                : ""
            }`}
          >
            <div
              className={`subscription-center-title-wrapper ${
                Regular
                  ? "regular-style"
                  : Neumorphic
                  ? "neumorphic-style"
                  : Glassmorphic
                  ? "glassmorphic-style"
                  : ""
              }`}
            >
              <div
                className={`subscription-center-title-content ${
                  Regular
                    ? "regular-style"
                    : Neumorphic
                    ? "neumorphic-style"
                    : Glassmorphic
                    ? "glassmorphic-style"
                    : ""
                }`}
              >
                <img
                  src={subscriptionCenterRegularRating3Stars}
                  alt="subscriptionCenterRegularRating3Stars"
                  className="subscriptionCenterRegularRatingStarsIcon"
                ></img>
                <img
                  src={subscriptionCenterNeumorphicRating3Stars}
                  alt="subscriptionCenterRegularRating3Stars"
                  className="subscriptionCenterNeumorphicRatingStarsIcon"
                ></img>
                <img
                  src={subscriptionCenterGlassmorphicRating3Stars}
                  alt="subscriptionCenterGlassmorphicRating3Stars"
                  className="subscriptionCenterGlassmorphicRatingStarsIcon"
                ></img>
                <h2>
                  Subscription <br></br> Center
                </h2>
                <img
                  src={subscriptionCenterRegularRating3Stars}
                  alt="subscriptionCenterRegularRating3Stars"
                  className="subscriptionCenterRegularRatingStarsIcon"
                ></img>
                <img
                  src={subscriptionCenterNeumorphicRating3Stars}
                  alt="subscriptionCenterRegularRating3Stars"
                  className="subscriptionCenterNeumorphicRatingStarsIcon"
                ></img>
                <img
                  src={subscriptionCenterGlassmorphicRating3Stars}
                  alt="subscriptionCenterGlassmorphicRating3Stars"
                  className="subscriptionCenterGlassmorphicRatingStarsIcon"
                ></img>
              </div>
            </div>
            <div
              className={`subscribe-weekly-drops ${
                Regular
                  ? "regular-style"
                  : Neumorphic
                  ? "neumorphic-style"
                  : Glassmorphic
                  ? "glassmorphic-style"
                  : ""
              }`}
            >
              <div className="subscribe-weekly-drops-icon-wrapper">
                <div
                  className={`img-wrapper  ${
                    Regular
                      ? "regular-style"
                      : Neumorphic
                      ? "neumorphic-style"
                      : Glassmorphic
                      ? "glassmorphic-style"
                      : ""
                  }`}
                >
                  <img
                    src={subscribeAroundTextTheme2Img}
                    alt="subscribeAroundTextImg"
                    className="subscribeAroundRegularTextImg"
                  />
                  <img
                    src={subscribeAroundTextImg}
                    alt="subscribeAroundTextImg"
                    className="subscribeAroundNeumorphicTextImg"
                  />
                  <div className="icon-wrapper">
                    <img
                      src={subscribeUserIcon}
                      alt="subscribeUserIcon"
                      className="subscribeUserIcon"
                    />
                  </div>
                  <p className="pick-poison-text">PICK YOUR POISON</p>
                </div>
              </div>
              <div
                className={`on-off-buttons-wrapper ${
                  Regular
                    ? "regular-style"
                    : Neumorphic
                    ? "neumorphic-style"
                    : Glassmorphic
                    ? "glassmorphic-style"
                    : ""
                }`}
              >
                <ul className="on-off-btn-items">
                  <li>
                    <div
                      className={`toggle-switch ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="events"
                        id="events"
                        defaultChecked={isSwitchBtn}
                        onClick={switchToggleBtn}
                      />
                      <label className="label" htmlFor="events">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <strong>EVENTS</strong>
                  </li>
                  <li>
                    <div
                      className={`toggle-switch ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="newsletter"
                        id="newsletter"
                        defaultChecked={true}
                        onClick={switchToggleBtn}
                      />
                      <label className="label" htmlFor="newsletter">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <strong>NEWSLETTER</strong>
                  </li>
                  <li>
                    <div
                      className={`toggle-switch ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="videoChannel"
                        id="videoChannel"
                        defaultChecked={isSwitchBtn}
                        onClick={switchToggleBtn}
                      />
                      <label className="label" htmlFor="videoChannel">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <strong>VIDEO CHANNEL</strong>
                  </li>
                  <li>
                    <div
                      className={`toggle-switch ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="blogPosts"
                        id="blogPosts"
                        defaultChecked={isSwitchBtn}
                        onClick={switchToggleBtn}
                      />
                      <label className="label" htmlFor="blogPosts">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <strong>BLOG POSTS</strong>
                  </li>
                  <li>
                    <div
                      className={`toggle-switch ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="podcast"
                        id="podcast"
                        defaultChecked={isSwitchBtn}
                        onClick={switchToggleBtn}
                      />
                      <label className="label" htmlFor="podcast">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <strong>PODCAST</strong>
                  </li>
                  <li>
                    <div
                      className={`toggle-switch ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="subscribe"
                        id="subscribe"
                        defaultChecked={isSwitchBtn}
                        onClick={switchToggleBtn}
                      />
                      <label className="label" htmlFor="subscribe">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <strong>SUBSCRIBE TO ALL</strong>
                  </li>
                </ul>
              </div>
              <div
                className={`subscribe-form ${
                  Regular
                    ? "regular-style"
                    : Neumorphic
                    ? "neumorphic-style"
                    : Glassmorphic
                    ? "glassmorphic-style"
                    : ""
                }`}
              >
                <Form>
                  <Form.Group>
                    <div
                      className={`form-group ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <Form.Control type="text" placeholder="EMAIL" />
                      <Button variant="primary" type="submit">
                        SUBSCRIBE
                      </Button>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
            <img
              src={theme2TopLeftDotsImg}
              alt="theme2TopLeftDotsImg"
              className="theme2TopLeftDotsImg"
            />
            <img
              src={theme2TopRightDotsImg}
              alt="theme2TopRightDotsImg"
              className="theme2TopRightDotsImg"
            />
            <img
              src={theme2BottomRightDotsImg}
              alt="theme2BottomRightDotsImg"
              className="theme2BottomRightDotsImg"
            />

            <img
              src={theme2GlassmorphicTopLeftDotsImg}
              alt="theme2TopLeftDotsImg"
              className="theme2GlassmorphicTopLeftDotsImg"
            />
            <img
              src={theme2GlassorpicTopRightDotsImg}
              alt="theme2TopRightDotsImg"
              className="theme2GlassmorphicTopRightDotsImg"
            />
            <img
              src={theme2GlassmorphicBottomRightDotsImg}
              alt="theme2BottomRightDotsImg"
              className="theme2GlassmorphicBottomRightDotsImg"
            />

            <img
              src={theme2NeumorphicTopLeftDotsImg}
              alt="theme2TopLeftDotsImg"
              className="theme2NeumorphicTopLeftDotsImg"
            />
            <img
              src={theme2NeumorphicTopRightDotsImg}
              alt="theme2TopRightDotsImg"
              className="theme2NeumorphicTopRightDotsImg"
            />
            <img
              src={theme2NeumorphicBottomRightDotsImg}
              alt="theme2BottomRightDotsImg"
              className="theme2NeumorphicBottomRightDotsImg"
            />
          </div>
        </div>
      </div>
    </>
  );
}
