import React, { useState } from "react";
import img14 from '../../../../../../images/StepZero/img53.png';
import img24 from '../../../../../../images/StepZero/img54.png';
import img34 from '../../../../../../images/StepZero/img55.png';
import MarketResearchGraphTab from "../../../../../global/marketResearchGraph/MarketResearchGraphTab";
export default function NicheSixSec({ activeColor }) {
  // const [activeColor, setActiveColor] = useState(receivedValue);

  // const [marketResearch, setMarketResearch] = useState(false);

  // const MarketResearchOpen = (color) => {
  //   // setActiveColor(color);
  //   setMarketResearch(true);
  // };

  // const MarketResearchClose = () => {
  //   setMarketResearch(false);
  // };

  const [activeColor1, setActiveColor1] = useState(false);

  const [marketResearch1, setMarketResearch1] = useState(false);

  const MarketResearchGraphOpen = (color) => {
    setActiveColor1(color);
    setMarketResearch1(true);
  };

  const MarketResearchGraphClose = () => {
    setMarketResearch1(false);
    setActiveColor1("");
  };

  return (
    <div className="row justify-content-center sixtwo mt-0 mx-0 formleft1 px-3">
      <div className="col-lg-2 text-start p-0 ">
        <img src={img14} alt="" width="160px" height="205px" />
      </div>
      <div className="col-lg-10 d-flex flex-column sevenBox pe-5">
        <img src={img24} alt="" width="300px" height="auto" className="ms-auto me-160" />
        <img src={img34} alt="" width="650px" height="80px" className="ms-4" />
        <div className="d-flex text-start">
          <div className="w-20">
            <h4>Less Competition</h4>
            <p>No or little competition while serving limited or specific audience</p>
          </div>
          <div className="w-20">
            <h4>Less Spends on Marketing</h4>
            <p>High target marketing makes it cost effectient</p>
          </div>
          <div className="w-20">
            <h4>Expertise</h4>
            <p>Market Research analysis to develop business position Specialized products manufactured with skill and expertise</p>
          </div>
          <div className="w-20">
            <h4>Brand Loyalty </h4>
            <p>Engagement with a limited audience help focus on quality of customer service and nurturing relationship</p>
          </div>
          <div className="w-20">
            <h4>Higher Profit</h4>
            <p>High profit margins due to meeting specialized needs of customers Brand monopoly thus high product costs</p>
          </div>
        </div>
      </div>
      <div className="sixtab sixtab2 col-lg-12 px-0 pt-80 mt-4">
        <div className={` ${activeColor == 'FASHION' ? 'active' : ''} hide`}>
          <div className="lastcircle d-flex justify-content-center align-items-center pe-3">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('WOMENS')}>
              <span className={` ${activeColor1 == 'WOMENS' ? 'activeSpan' : ''}`}>WOMEN’S FASHION</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('CORPORATE')}>
              <span className={` ${activeColor1 == 'CORPORATE' ? 'activeSpan' : ''}`}>CORPORATE FASHION</span>
            </div>
            <div className="circleinner1"  onClick={() => MarketResearchGraphOpen('MENS')}>
              <span className={` ${activeColor1 == 'MENS' ? 'activeSpan' : ''}`}>MEN’S<br/> FASHION</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center mb-3 py-4">
            <div className="circleinner"  onClick={() => MarketResearchGraphOpen('CONSULTANT')}>
              <span className={` ${activeColor1 == 'CONSULTANT' ? 'activeSpan' : ''}`}>FASHION CONSULTANT</span>
            </div>
            <div className="circleinner"  onClick={() => MarketResearchGraphOpen('STYLIST')}>
              <span className={` ${activeColor1 == 'STYLIST' ? 'activeSpan' : ''}`}>FASHION<br/> STYLIST</span>
            </div>
            <div className="circleinner2" >
              <span className="bg-primary"> FASHION INDUSTRY TOP NICHES</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('CHILDRENS')}>
              <span className={` ${activeColor1 == 'CHILDRENS' ? 'activeSpan' : ''}`}>CHILDREN’S FASHION</span>
            </div>
            <div className="circleinner1"  onClick={() => MarketResearchGraphOpen('COACH')}>
              <span className={` ${activeColor1 == 'COACH' ? 'activeSpan' : ''}`}>FASHION<br/> COACH</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('BRIDAL')}>
              <span className={` ${activeColor1 == 'BRIDAL' ? 'activeSpan' : ''}`}>BRIDAL<br/> FASHION</span>
            </div>
            <div className="circleinner1"  onClick={() => MarketResearchGraphOpen('TEENS')}>
              <span className={` ${activeColor1 == 'TEENS' ? 'activeSpan' : ''}`}>TEEN’S<br/> FASHION</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-0"  onClick={() => MarketResearchGraphOpen('PROM')}>
              <span className={` ${activeColor1 == 'PROM' ? 'activeSpan' : ''}`}>PROM<br /> FASHION </span>
            </div>
          </div>
        </div>
        <div className={` ${activeColor == 'BEAUTY' ? 'active' : ''} hide`}>
          <div className="lastcircle d-flex justify-content-center align-items-center pe-3">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('SKINCARE')}>
              <span className={` ${activeColor1 == 'SKINCARE' ? 'activeSpan' : ''}`}>SKIN CARE SERVICES PRODUCTS</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('MAKEUP')}>
              <span className={` ${activeColor1 == 'MAKEUP' ? 'activeSpan' : ''}`}>MAKEUP SERVICES  PRODUCTS</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('HAIRCARE')}>
              <span className={` ${activeColor1 == 'HAIRCARE' ? 'activeSpan' : ''}`}>HAIR CARE SERVICES  PRODUCTS</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center mb-3 py-4">
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('ORGANIC')}>
              <span className={` ${activeColor1 == 'ORGANIC' ? 'activeSpan' : ''}`}>NATURAL ORGANIC PRODUCTS</span>
            </div>
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('DENTAL')}>
              <span className={` ${activeColor1 == 'DENTAL' ? 'activeSpan' : ''}`}>DENTAL CARE SERVICES PRODUCTS</span>
            </div>
            <div className="circleinner2">
              <span className="bg-primary">BEAUTY INDUSTRY TOP NICHES</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('NAILCARE')}>
              <span className={` ${activeColor1 == 'NAILCARE' ? 'activeSpan' : ''}`}>NAIL CARE SERVICES PRODUCTS</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('ANTIAGING')}>
              <span className={` ${activeColor1 == 'ANTIAGING' ? 'activeSpan' : ''}`}>ANTI-AGING SERVICES PRODUCTS</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('WELLENESS')}>
              <span className={` ${activeColor1 == 'WELLENESS' ? 'activeSpan' : ''}`}>BEAUTY WELLENESS RETREAT SPA</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('PERMANENT')}>
              <span className={` ${activeColor1 == 'PERMANENT' ? 'activeSpan' : ''}`}>PERMANENT MAKEUP MICRO-BLADING</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('PERFUMES')}>
              <span className={` ${activeColor1 == 'PERFUMES' ? 'activeSpan' : ''}`}>PERFUMES & FRAGRANCES</span>
            </div>
          </div>
        </div>
        <div className={` ${activeColor == 'COACHING' ? 'active' : ''} hide`}>
          <div className="lastcircle d-flex justify-content-center align-items-center pe-3">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('LIFECOACHING')}>
              <span className={` ${activeColor1 == 'LIFECOACHING' ? 'activeSpan' : ''}`}>LIFE COACHING</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('BUSINESSCOACHING')}>
              <span className={` ${activeColor1 == 'BUSINESSCOACHING' ? 'activeSpan' : ''}`}>BUSINESS COACHING</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('CAREERCOACHING')}>
              <span className={` ${activeColor1 == 'CAREERCOACHING' ? 'activeSpan' : ''}`}>CAREER COACHING</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center mb-3 py-4">
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('EMOTIONAL')}>
              <span className={` ${activeColor1 == 'EMOTIONAL' ? 'activeSpan' : ''}`}>EMOTIONAL INTELLIGENCE COACHING</span>
            </div>
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('EXECUTIVE')}>
              <span className={` ${activeColor1 == 'EXECUTIVE' ? 'activeSpan' : ''}`}>EXECUTIVE COACHING</span>
            </div>
            <div className="circleinner2">
              <span className="bg-primary">COACHING INDUSTRY TOP NICHES</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('MOTIVATIONAL')}>
              <span className={` ${activeColor1 == 'MOTIVATIONAL' ? 'activeSpan' : ''}`}>MOTIVATIONAL COACHING</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('PERFORMANCE')}>
              <span className={` ${activeColor1 == 'PERFORMANCE' ? 'activeSpan' : ''}`}>PERFORMANCE COACHING</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('PARENTING')}>
              <span className={` ${activeColor1 == 'PARENTING' ? 'activeSpan' : ''}`}>PARENTING COACHING</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('MINDFULNESS')}>
              <span className={` ${activeColor1 == 'MINDFULNESS' ? 'activeSpan' : ''}`}>MINDFULNESS COACHING</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('COMMUNICATION')}>
              <span className={` ${activeColor1 == 'COMMUNICATION' ? 'activeSpan' : ''}`}>COMMUNICATION COACHING</span>
            </div>
          </div>
        </div>
        <div className={` ${activeColor == 'TRAVEL' ? 'active' : ''} hide`}>
          <div className="lastcircle d-flex justify-content-center align-items-center pe-3">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('TOURISM')}>
              <span className={` ${activeColor1 == 'TOURISM' ? 'activeSpan' : ''}`}>ADVENTURE TOURISM FAMILY VACATIONS</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('Wildlife')}>
              <span className={` ${activeColor1 == 'Wildlife' ? 'activeSpan' : ''}`}>BUSINESS TRAVEL Wildlife |religious| humanitarian |medical</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('IMMERSIVE')}>
              <span className={` ${activeColor1 == 'IMMERSIVE' ? 'activeSpan' : ''}`}>LUXURY IMMERSIVE TRAVEL EXPERIENCE</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center mb-3 py-4">
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('CAMPERVAN')}>
              <span className={` ${activeColor1 == 'CAMPERVAN' ? 'activeSpan' : ''}`}>RV CAMPERVAN OUTDOORS TRAVEL</span>
            </div>
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('CRUISE')}>
              <span className={` ${activeColor1 == 'CRUISE' ? 'activeSpan' : ''}`}>CRUISE SHIP /YACHT /SAILING TOURISM</span>
            </div>
            <div className="circleinner2">
              <span className="bg-primary">TRAVEL INDUSTRY TOP NICHES</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('HONNEYMOON')}>
              <span className={` ${activeColor1 == 'HONNEYMOON' ? 'activeSpan' : ''}`}>HONNEYMOON ROMANTIC GETAWAYS</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('SKIING')}>
              <span className={` ${activeColor1 == 'SKIING' ? 'activeSpan' : ''}`}>SPORTS TRAVEL SKIING, DIVING,GOLF</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('LIMOUSINE')}>
              <span className={` ${activeColor1 == 'LIMOUSINE' ? 'activeSpan' : ''}`}>EVENTS LIMOUSINE TRAVEL</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('ACCESSIBLE')}>
              <span className={` ${activeColor1 == 'ACCESSIBLE' ? 'activeSpan' : ''}`}>ACCESSIBLE INCLUSIVE TRAVEL</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('HISTORICAL')}>
              <span className={` ${activeColor1 == 'HISTORICAL' ? 'activeSpan' : ''}`}>DARK TOURISM HISTORICAL TRAGEDY SITES</span>
            </div>
          </div>
        </div>
        <div className={` ${activeColor == 'FITNESS' ? 'active' : ''} hide`}>
          <div className="lastcircle d-flex justify-content-center align-items-center pe-3">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('WELLENESS')}>
              <span className={` ${activeColor1 == 'WELLENESS' ? 'activeSpan' : ''}`}>PERSONAL TRAINING CORPORATE WELLENESS</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('SENIORFITNESS')}>
              <span className={` ${activeColor1 == 'SENIORFITNESS' ? 'activeSpan' : ''}`}>SENIOR FITNESS & WELLNESS</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('CONDITIONING')}>
              <span className={` ${activeColor1 == 'CONDITIONING' ? 'activeSpan' : ''}`}>SPORT BODY-CONDITIONING TRAINING</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center mb-3 py-4">
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('PROGRAMS')}>
              <span className={` ${activeColor1 == 'PROGRAMS' ? 'activeSpan' : ''}`}>VIRTUAL WORKOUT | GROUP PROGRAMS</span>
            </div>
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('POSTNATAL')}>
              <span className={` ${activeColor1 == 'POSTNATAL' ? 'activeSpan' : ''}`}>PRE & POST-NATAL FITNESS</span>
            </div>
            <div className="circleinner2" >
              <span className="bg-primary">FITNESS INDUSTRY TOP NICHES</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('CROSSFIT')}>
              <span className={` ${activeColor1 == 'CROSSFIT' ? 'activeSpan' : ''}`}>CROSSFIT & FUNCTIONAL TRAINING | BOOKCAMP</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('SOCCER')}>
              <span className={` ${activeColor1 == 'SOCCER' ? 'activeSpan' : ''}`}>SOCCER | HOCKEY | TENNIS | GOLF COACH</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('REHABILITATION')}>
              <span className={` ${activeColor1 == 'REHABILITATION' ? 'activeSpan' : ''}flex-column`}>PHYSICAL <small>REHABILITATION</small> THERAPHY</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('BODYBUILDING')}>
              <span className={` ${activeColor1 == 'BODYBUILDING' ? 'activeSpan' : ''}flex-column`}><small>BODYBUILDING|</small> PHYSICAL TRAINING</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('AQUATIC')}>
              <span className={` ${activeColor1 == 'AQUATIC' ? 'activeSpan' : ''}`}>AQUATIC | DANCE YOGA, PILATES, BARRE, MEDITATION</span>
            </div>
          </div>
        </div>
        <div className={` ${activeColor == 'NUTRITION' ? 'active' : ''} hide`}>
          <div className="lastcircle d-flex justify-content-center align-items-center pe-3">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('PERSONALIZED')}>
              <span className={` ${activeColor1 == 'PERSONALIZED' ? 'activeSpan' : ''}flex-column`}><small>PERSONALIZED</small> MEAL PLANNING SERVICES</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('ALLERGEN')}>
              <span className={` ${activeColor1 == 'ALLERGEN' ? 'activeSpan' : ''}`}>GLUTEN ALLERGEN-FREE SPECIALTY DIET</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('PERFORMANCE')}>
              <span className={` ${activeColor1 == 'PERFORMANCE' ? 'activeSpan' : ''}flex-column`}>SPORT <small>PERFORMANCE</small> NUTRITION</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center mb-3 py-4">
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('KETOGENIC')}>
              <span className={` ${activeColor1 == 'KETOGENIC' ? 'activeSpan' : ''}`}>PLANT-BASED VEGAN KETOGENIC NUTRITION</span>
            </div>
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('DIGESTIVE')}>
              <span className={` ${activeColor1 == 'DIGESTIVE' ? 'activeSpan' : ''}flex-column`}>DIGESTIVE GUT <small>FUNCTIONAL</small> DIET</span>
            </div>
            <div className="circleinner2" onClick={() => MarketResearchGraphOpen('WOMENS')}>
              <span className="bg-primary">NUTRITION INDUSTRY TOP NICHES</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('OBESITY')}>
              <span className={` ${activeColor1 == 'OBESITY' ? 'activeSpan' : ''}flex-column`}>OBESITY WEIGHT MANAGEMENT</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('MINDFUL')}>
              <span className={` ${activeColor1 == 'MINDFUL' ? 'activeSpan' : ''}`}>MINDFUL EATING INTUITIVE NUTRITION</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('GERIATRIC')}>
              <span className={` ${activeColor1 == 'GERIATRIC' ? 'activeSpan' : ''}`}>GERIATRIC SENIOR NUTRITION</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('DIABETIC')}>
              <span className={` ${activeColor1 == 'DIABETIC' ? 'activeSpan' : ''}`}>DIABETIC RENAL| KIDNEY HEALTH NUTRITION</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('PEDIATRIC')}>
              <span className={` ${activeColor1 == 'PEDIATRIC' ? 'activeSpan' : ''}`}>PEDIATRIC ADOLESCENT NUTRITION</span>
            </div>
          </div>
        </div>
        <div className={` ${activeColor == 'FINANCE' ? 'active' : ''} hide`}>
          <div className="lastcircle d-flex justify-content-center align-items-center pe-3">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('MGMT')}>
              <span className={` ${activeColor1 == 'MGMT' ? 'activeSpan' : ''}`}>PERSONAL FINANCE WEALTH MGMT</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('PLANNING')}>
              <span className={` ${activeColor1 == 'PLANNING' ? 'activeSpan' : ''}flex-column`}>TAX PLANNING <small>PREPARATION BOOKEEPING</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('RETIREMENT')}>
              <span className={` ${activeColor1 == 'RETIREMENT' ? 'activeSpan' : ''}`}>RETIREMENT PENSION PLANNING</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center mb-3 py-4">
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('ESTATE')}>
              <span className={` ${activeColor1 == 'ESTATE' ? 'activeSpan' : ''}`}>REAL ESTATE MORTGAGE HOME EQUITY LOAN</span>
            </div>
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('MGMT')}>
              <span className={` ${activeColor1 == 'MGMT' ? 'activeSpan' : ''}`}>ESTATE PLANNING TRUST MGMT</span>
            </div>
            <div className="circleinner2">
              <span className="bg-primary">FINANCE INDUSTRY TOP NICHES</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('DEBT')}>
              <span className={` ${activeColor1 == 'DEBT' ? 'activeSpan' : ''}flex-column`}>DEBT MANAGEMENT <small>CONSOLIDATIONS</small> CREDIT REPAIR</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('FORENSIC')}>
              <span className={` ${activeColor1 == 'FORENSIC' ? 'activeSpan' : ''}`}>FORENSIC ACCOUNTING FRAUD DETECTION</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('STOCKS')}>
              <span className={` ${activeColor1 == 'STOCKS' ? 'activeSpan' : ''}`}>STOCKS OPTIONS FOREX CRYPTO . BLOCKCHAIN</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('INSURANCE')}>
              <span className={` ${activeColor1 == 'INSURANCE' ? 'activeSpan' : ''}`}>INSURANCE RISK MANAGEMENT</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('VENTURE')}>
              <span className={` ${activeColor1 == 'VENTURE' ? 'activeSpan' : ''}`}>VENTURE ANGEL INVESTING STARTUP MENTORING</span>
            </div>
          </div>
        </div>
        <div className={` ${activeColor == 'INTERIOR' ? 'active' : ''} hide`}>
          <div className="lastcircle d-flex justify-content-center align-items-center pe-3">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('RESIDENTIAL')}>
              <span className={` ${activeColor1 == 'RESIDENTIAL' ? 'activeSpan' : ''}`}><small>RESIDENTIAL COMMERCIAL RESTAURANT HOSPITALITY INTERIOR DECOR</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('LUXURY')}>
              <span className={` ${activeColor1 == 'LUXURY' ? 'activeSpan' : ''}`}><small>LUXURY HIGH-END ART GALLERY CURATION INTERIOR DECOR</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('SUSTAINABLE')}>
              <span className={` ${activeColor1 == 'SUSTAINABLE' ? 'activeSpan' : ''}flex-column`}><small>SUSTAINABLE</small> UPCYCLED ECO-FRIENDLY DECOR</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center mb-3 py-4">
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('LANDSCAPING')}>
              <span className={` ${activeColor1 == 'LANDSCAPING' ? 'activeSpan' : ''}`}>LANDSCAPING GARDEN GREEN SPACE DESIGN</span>
            </div>
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('STAGING')}>
              <span className={` ${activeColor1 == 'STAGING' ? 'activeSpan' : ''}`}>HOME STAGING /STYLING FOR REAL ESTATE</span>
            </div>
            <div className="circleinner2">
              <span className="bg-primary">INT. DESIGNS INDUSTRY TOP NICHES</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('HOLISTIC')}>
              <span className={` ${activeColor1 == 'HOLISTIC' ? 'activeSpan' : ''}`}>FENG SHUI HOLISTIC DESIGN PRINCIPLES</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('AUTOMATION')}>
              <span className={` ${activeColor1 == 'AUTOMATION' ? 'activeSpan' : ''}`}>SMART HOME AUTOMATION INTEGRATION</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('BOAT')}>
              <span className={` ${activeColor1 == 'BOAT' ? 'activeSpan' : ''}`}>YACHT LUXURY BOAT RV DESIGNS</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('ACCESSIBLE')}>
              <span className={` ${activeColor1 == 'ACCESSIBLE' ? 'activeSpan' : ''}`}>UNIVERSAL ACCESSIBLE DESIGN SOLUTIONS</span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('NURSERY')}>
              <span className={` ${activeColor1 == 'NURSERY' ? 'activeSpan' : ''}`}>NURSERY CHILD-FRIENDLY INTERIOR DECOR</span>
            </div>
          </div>
        </div>
        <div className={` ${activeColor == 'LEARNING' ? 'active' : ''} hide`}>
          <div className="lastcircle d-flex justify-content-center align-items-center pe-3">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('DEVELOPMENT')}>
              <span className={` ${activeColor1 == 'DEVELOPMENT' ? 'activeSpan' : ''}`}><small>ONLINE COURSE PLANNING | DEVELOPMENT</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('TUTORING')}>
              <span className={` ${activeColor1 == 'TUTORING' ? 'activeSpan' : ''}`}><small>K-12 VIRTUAL TUTORING SERVICES Language, Math, Stem, Coding, Art, Instruments</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('PROFESSIONAL')}>
              <span className={` ${activeColor1 == 'PROFESSIONAL' ? 'activeSpan' : ''}`}><small>CORPORATE TRAINING PROFESSIONAL DEVELOPMENT</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center mb-3 py-4">
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('NATAL')}>
              <span className={` ${activeColor1 == 'NATAL' ? 'activeSpan' : ''}`}>PARENTING PRE | POST-NATAL FAMILY-RELATED CLASSES</span>
            </div>
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('PET')}>
              <span className={` ${activeColor1 == 'PET' ? 'activeSpan' : ''}`}>PET CARE ANIMAL TRAINNING PROGRAMS</span>
            </div>
            <div className="circleinner2">
              <span className="bg-primary">E-LEARNING INDUSTRY TOP NICHES</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('LSAT')}>
              <span className={` ${activeColor1 == 'LSAT' ? 'activeSpan' : ''}`}><small>PREPARATION AUDITION LSAT GRE GMAT GAMING</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('FILMING')}>
              <span className={` ${activeColor1 == 'FILMING' ? 'activeSpan' : ''}`}><small>FILMING VIDEO EDITING PHOTOGRAPH INSTRUMENTS COURSES</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('FINANCECOURSE')}>
              <span className={` ${activeColor1 == 'FINANCECOURSE' ? 'activeSpan' : ''}`}><small>FASHION BEAUTY FITNESS NUTRITION FINANCE COURSE</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('INSPIRATIONAL')}>
              <span className={` ${activeColor1 == 'INSPIRATIONAL' ? 'activeSpan' : ''}`}><small>INSPIRATIONAL PUBLIC | VIRTUAL PRESENTATION MEETING HOST PUBLIC RELATIONS</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('SPIRITUALITY')}>
              <span className={` ${activeColor1 == 'SPIRITUALITY' ? 'activeSpan' : ''}`}>SPIRITUALITY INTERIOR DECOR COACHING TRAVEL COURSE</span>
            </div>
          </div>
        </div>
        <div className={` ${activeColor == 'SPIRITUALITY' ? 'active' : ''} hide`}>
          <div className="lastcircle d-flex justify-content-center align-items-center pe-3">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('MEDITATION')}>
              <span className={` ${activeColor1 == 'MEDITATION' ? 'activeSpan' : ''}`}><small>MEDITATION MINDFULNESS COACHING VIBRATIONAL THERAPY </small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('REIKI')}>
              <span className={` ${activeColor1 == 'REIKI' ? 'activeSpan' : ''}`}><small>REIKI ENERGY HEALING CHAKRA-BALANCING CRYSTAL GEMSTONES THERAPHY</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('HOLISTIC')}>
              <span className={` ${activeColor1 == 'HOLISTIC' ? 'activeSpan' : ''}`}><small>HOLISTIC HEALING ALTERNATIVE SOUND VIBRATIONAL AROMA | OILS THERAPY</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center mb-3 py-4">
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('TAROT')}>
              <span className={` ${activeColor1 == 'TAROT' ? 'activeSpan' : ''}`}><small>TAROT ORACLE PSYCHIC READINGS | CONSULTATIONS</small></span>
            </div>
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('ASTROLOGY')}>
              <span className={` ${activeColor1 == 'ASTROLOGY' ? 'activeSpan' : ''}`}><small>ASTROLOGY HOROSCOPE READING SERVICES</small></span>
            </div>
            <div className="circleinner2">
              <span className="bg-primary">SPIRITUALITY INDUSTRY TOP NICHES</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('SACRED')}>
              <span className={` ${activeColor1 == 'SACRED' ? 'activeSpan' : ''}`}><small>SACRED SPACE ALTAR DESIGN FENG SHUI SPACE CLEARING SERVICES</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('SPIRITUAL')}>
              <span className={` ${activeColor1 == 'SPIRITUAL' ? 'activeSpan' : ''}`}><small>SPIRITUAL STAGE JOURNEY GUIDANCE SHAMANIC PRACTICES</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('METAPHYSICAL')}>
              <span className={` ${activeColor1 == 'METAPHYSICAL' ? 'activeSpan' : ''}`}><small>METAPHYSICAL OCCULT QUANTUM AWARENESS</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('PAST')}>
              <span className={` ${activeColor1 == 'PAST' ? 'activeSpan' : ''}`}><small>PAST LIFE REGRESSION THERAPY SACRED TRAVEL PILGRIMAGE TOURS</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('YOGA')}>
              <span className={` ${activeColor1 == 'YOGA' ? 'activeSpan' : ''}`}><small>YOGA HOLISTIC WELLNESS RETREATS</small></span>
            </div>
          </div>
        </div>
        <div className={` ${activeColor == 'ENTERTAINMENT' ? 'active' : ''} hide`}>
          <div className="lastcircle d-flex justify-content-center align-items-center pe-3">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('BACHELOR')}>
              <span className={` ${activeColor1 == 'BACHELOR' ? 'activeSpan' : ''}`}><small>EVENTS PLANNING BACHELOR /ETTE WEDDING BIRTHDAY BABY SHOWER</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('CELEBRITY')}>
              <span className={` ${activeColor1 == 'CELEBRITY' ? 'activeSpan' : ''}`}><small>CELEBRITY ENTERTAINMENT NEWS REPORTING ENDORSEMENTS PARTNERSHIPS</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('ENTERTAINMENT')}>
              <span className={` ${activeColor1 == 'ENTERTAINMENT' ? 'activeSpan' : ''}`}><small>LIVE ENTERTAINMENT COORDINATION FILM | MUSIC FESTIVALS MANAGEMENT</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center mb-3 py-4">
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('LEGAL')}>
              <span className={` ${activeColor1 == 'LEGAL' ? 'activeSpan' : ''}`}><small>ENTERTAINMENT LAW LEGAL SERVICES</small></span>
            </div>
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('IMMERSIVE')}>
              <span className={` ${activeColor1 == 'IMMERSIVE' ? 'activeSpan' : ''}`}><small>LUXURY IMMERSIVE EXPERIENCE VR | AR DEEP FAKE</small></span>
            </div>
            <div className="circleinner2">
              <span className="bg-primary">ENTERTAINMENT INDUSTRY TOP NICHES</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('VIDEOGRAPHY')}>
              <span className={` ${activeColor1 == 'VIDEOGRAPHY' ? 'activeSpan' : ''}`}><small>EVENT PHOTOGRAPHY VIDEOGRAPHY ANIMATION VISUAL EFFECTS PYROTECHNICS SERVICES</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('SCOUTING')}>
              <span className={` ${activeColor1 == 'SCOUTING' ? 'activeSpan' : ''}`}><small>TALENT SCOUTING AUDITION MANAGEMENT ARTISTS REPRESENTATION</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('WOMENS')}>
              <span className={` ${activeColor1 == 'WOMENS' ? 'activeSpan' : ''}`}><small>MUSIC RECORD LABEL MANAGEMENT PODCASTING FILM PRODUCTION</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('COMEDY')}>
              <span className={` ${activeColor1 == 'COMEDY' ? 'activeSpan' : ''}`}><small>STAND-UP COMEDY GENERAL CLUB MANAGEMENT</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('STREAMING')}>
              <span className={` ${activeColor1 == 'STREAMING' ? 'activeSpan' : ''}`}><small>LIVE CONCERT STREAMING EVENT TICKETING MARKETING PROMOS</small></span>
            </div>
          </div>
        </div>
        <div className={` ${activeColor == 'ANIMAL' ? 'active' : ''} hide`}>
          <div className="lastcircle d-flex justify-content-center align-items-center pe-3">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('HEALTHCARE')}>
              <span className={` ${activeColor1 == 'HEALTHCARE' ? 'activeSpan' : ''}`}><small>VETERINARY ANIMAL HEALTHCARE CLINICS</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('GROOMING')}>
              <span className={` ${activeColor1 == 'GROOMING' ? 'activeSpan' : ''}`}><small>FULL PET SERVICES SPA,GROOMING, BOARDING, DAYCARE, WALKING, SITTING IN-HOME PET CARE</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('COVERAGE')}>
              <span className={` ${activeColor1 == 'COVERAGE' ? 'activeSpan' : ''}`}><small>PET INSURANCE HEALTH COVERAGE PLANS</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center mb-3 py-4">
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('SAFARI')}>
              <span className={` ${activeColor1 == 'SAFARI' ? 'activeSpan' : ''}`}><small>WILDFIRE TOURISM SAFARI EXPERIENCES</small></span>
            </div>
            <div className="circleinner" onClick={() => MarketResearchGraphOpen('PHOTOGRAPHY')}>
              <span className={` ${activeColor1 == 'PHOTOGRAPHY' ? 'activeSpan' : ''}`}><small>PET ENTERTAINMENT PHOTOGRAPHY VIDEOPGRAPHY SERVICES</small></span>
            </div>
            <div className="circleinner2">
              <span className="bg-primary">ANIMAL INDUSTRY TOP NICHES</span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('COMMERCE')}>
              <span className={` ${activeColor1 == 'COMMERCE' ? 'activeSpan' : ''}`}><small>E-COMMERCE PET’S STORE ORGANIC NATURAL PET CARE ACCESSORIES FOOD NUTRITION PRODUCTS</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('EXOTIC')}>
              <span className={` ${activeColor1 == 'EXOTIC' ? 'activeSpan' : ''}`}><small>EXOTIC PET TRADE MANAGEMENT TALENT SCOUTING AUDITION TRAINING</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-150" onClick={() => MarketResearchGraphOpen('MANAGEMENT')}>
              <span className={` ${activeColor1 == 'MANAGEMENT' ? 'activeSpan' : ''}`}><small>PET WASTE MANAGEMENT CLEANING SERVICES</small></span>
            </div>
            <div className="circleinner1" onClick={() => MarketResearchGraphOpen('BEHAVIOR')}>
              <span className={` ${activeColor1 == 'BEHAVIOR' ? 'activeSpan' : ''}`}><small>PET TRAINING BEHAVIOR MODIFICATION REHABILITATION OBEDIENCE EMOTIONAL THERAPY</small></span>
            </div>
          </div>
          <div className="lastcircle d-flex justify-content-center align-items-center">
            <div className="circleinner me-0" onClick={() => MarketResearchGraphOpen('RESCUE')}>
              <span className={` ${activeColor1 == 'RESCUE' ? 'activeSpan' : ''}`}><small>PET MGMT RESCUE ADOPTIONS GENETICS BREEDING</small></span>
            </div>
          </div>
        </div>
        <div className="sixtab w-100">
            {marketResearch1 &&
            <div>
              <MarketResearchGraphTab handleClick={MarketResearchGraphClose} />
            </div>
            }
          </div>
      </div>
    </div>
  )
}
