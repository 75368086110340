import React from "react";
import ReactStars from "react-rating-stars-component";

import avatar from "../../../../../images/testimonial-case-study/regular4_desktop_avatar.png";

import regular5_main_grid_ang from "../../../../../images/testimonial-case-study/regular5_main_grid_ang.png";

import "./RegularTheme5.scss";

export default function RegularTheme5(props) {
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  return (
    <div className="dot-container">
      <div className="heading-client-div">
        <span className="span1">What</span>
        <span className="span2">our Clients</span>
        <span className="span3">Say about us?</span>
      </div>

      <hr className="hr-break" />
      <div className="after-image">
        <img src={regular5_main_grid_ang} alt="" srcset="" />
      </div>
      <div className="main-grid-container ">
        <div className="grid-container-left">
          <div className="left-grid1">
            <img src={avatar} alt=" Version 5 Avatar" srcset="" />
          </div>

          <div className="left-grid2">
            <div className="profile-div">
              <h1>DYNA APPOLON</h1>
              <p>CEO | SOCIAL NETWORK STRATEGIST</p>
            </div>
            <div className="rating-stars-div">
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={20}
                activeColor="#7CBAA7"
              />
            </div>
          </div>
        </div>

        <div className="grid-container-right">
          <div className="right-grid1">
            <div id="border-left"></div>
            <p>
              Lorem ipsum dolor sit amet consectetur. Ante fringilla turpis
              egestas arcu risus sapien congue. Morbi ipsum posuere in quam sed.
              Nam id senectus sapien aliquet mauris risus nulla ultrices.
            </p>
          </div>

          <div className="right-grid2">
            <p>
              Lorem ipsum dolor sit amet consectetur. Ante fringilla turpis
              egestas arcu risus sapien congue. Morbi ipsum posuere in quam sed.
              Nam id senectus sapien aliquet mauris risus nulla ultrices.
            </p>
          </div>
        </div>
      </div>

      <div className="see-my-wall-div">
        <p>SEE MY</p>
        <a href="#">WALL OF LOVE</a>
      </div>
    </div>
  );
}