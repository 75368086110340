import React from "react";
import { useState } from "react";
import "./RegularTheme4Tab.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { useTranslation } from "react-i18next";

import abc from "../../../../../../images/featured-on/circle/abc.png";
import ben from "../../../../../../images/featured-on/circle/ben.png";
import cbs from "../../../../../../images/featured-on/circle/cbs.png";
import digi from "../../../../../../images/featured-on/circle/digi.png";
import fn from "../../../../../../images/featured-on/circle/fn.png";
import nbc from "../../../../../../images/featured-on/circle/nbc.png";
import usa from "../../../../../../images/featured-on/circle/usa.png";
import above from "../../../../../../images/featured-on/circle/above.png";
import below from "../../../../../../images/featured-on/circle/below.png";
import line from "../../../../../../images/featured-on/circle/line.png";

export default function RegularTheme4Tab() {
  const { t, i18n } = useTranslation();
  const [seeall, setSeeall] = useState(false);

  const handleSeeallClick = () => {
    setSeeall(!seeall);
  };

  const handleSeeallClose = () => {
    setSeeall(false);
  };

  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };
  const [bookmenow, setBookmenow] = useState(false);

  const handleBookmenowClick = () => {
    setBookmenow(!bookmenow);
  };

  const handleBookmenowClose = () => {
    setBookmenow(false);
  };

  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const [Competency1, setCompetency1] = useState(10);

  return (
    <div>
      <div className="grey-box-tab-4 d-flex flex-column justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center text-center">
          <p
            style={{
              color: "Black",
              paddingTop: "20px",
              paddingBottom: "0px",
              fontSize: "3cd f5px",
              fontWeight: "bolder",
            }}
          >
            AS SEEN ON
          </p>
        </div>
        <div className="box-container-tab-4 d-flex justify-content-center">
          <div className="image-circle-tab-4">
            <img src={ben} alt="Image 1" width="80" height="80" />
          </div>
          <div className="image-circle-tab-4">
            <img src={digi} alt="Image 2" width="80" height="80" />
          </div>
          <div className="image-circle-tab-4">
            <img src={cbs} alt="Image 3" width="80" height="80" />
          </div>
          <div className="image-circle-tab-4">
            <img src={nbc} alt="Image 4" width="80" height="80" />
          </div>
          <div className="image-circle-tab-4 image-usa">
            <img src={usa} alt="Image 5" width="80" height="80" />
          </div>
          <div className="image-circle-tab-4">
            <div className="image-line"></div>
            <img src={fn} alt="Image 6" width="80" height="80" />
          </div>
          <div className="image-circle-tab-4">
            <img src={abc} alt="Image 7" width="80" height="80" />
          </div>
        </div>
      </div>
    </div>
  );
}
