import React from "react";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
import { Link } from 'react-router-dom';
import ButtonBox from '../../../global/ButtonBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import ChangeOne from "./ChangeMulti/ChangeOne";
import ChangeTwo from "./ChangeMulti/ChangeTwo";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';


library.add( faCaretRight, faCaretLeft, faFloppyDisk  );


export default function ChangeTab() {
  let {isVideoVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };
  return (
    <div>
      <div className="w-780 mb-4 align-items-center mx-auto d-flex flex-column-reverse justify-content-between">     
        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto mb-5">
          <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
          <h3 className="mb-5 pb-4">CHANGE PASSWORD</h3>
          <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="changeone" role="tabpanel" aria-labelledby="changeone">
                <ChangeOne />
              </div>
              <div className="tab-pane fade" id="changetwo" role="tabpanel" aria-labelledby="changetwo">
                <ChangeTwo />
              </div>
          </div>
        </div>
        <span className="d-flex align-items-center justify-content-between w-100 mb-4">
          <div id="myTab1" className='' role="tablist">
            <button className="buttonArrow me-3 text-body border-0 bg br-10 active" id="changeone" data-bs-toggle="tab" data-bs-target="#changeone" type="button" role="tab" aria-controls="changeone" aria-selected="true">
              <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
            </button>
            <button className="buttonArrow me-3 text-body border-0 bg br-10" id="changetwo" data-bs-toggle="tab" data-bs-target="#changetwo" type="button" role="tab" aria-controls="changetwo" aria-selected="false">
              <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
            </button>
          </div>
          <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="Saved" icon={faFloppyDisk} name="Save" />
        </span>
      </div>
      
    </div>
  );
}
