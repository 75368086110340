import React, { useState } from "react";
import ButtonBox from "../../../global/ButtonBox";
import { Link } from 'react-router-dom';
import { ReactComponent as IconBlue4 } from "../../../../images/icons/icon16.svg";
import qrcode from "../../../../images/QR2.png";
import { ReactComponent as IconBlue15 } from "../../../../images/listicon/icon15.svg";
import { ReactComponent as IconBlue16 } from "../../../../images/listicon/icon17.svg";
import { ReactComponent as IconBlue17 } from "../../../../images/listicon/icon16.svg";
import { ReactComponent as IconBlue6q } from "../../../../images/svgIcon/icon23.svg";
import { ReactComponent as IconBlue7q } from "../../../../images/svgIcon/icon24.svg";
import { ReactComponent as IconBlue1q } from "../../../../images/social-icons/contact.svg";
import { ReactComponent as IconBlue2q } from "../../../../images/social-icons/mail.svg";
import { ReactComponent as IconBlue3q } from "../../../../images/social-icons/globe.svg";
import { ReactComponent as IconBlue4q } from "../../../../images/social-icons/headquater.svg";
import { ReactComponent as IconBlue5q } from "../../../../images/social-icons/location.svg";
import EditorDrop from "../../../global/EditorDrop";
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import './DigitalBusinessCardStep.scss';
import { NavItem } from 'react-bootstrap';
import Slide7 from '../../../../images/profileview/icon48.png';
import ButtonBoxNew from "../../../global/ButtonBoxNew";
import DropdownBox from '../../../global/DropdownBox';
export default function DigitalBusinessPrimary() {

    const { t, i18n } = useTranslation();
    const theme = theme => ({

        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        }
    });
    const options2 = [
        {
          icon: <span className=""></span>,
          name: "None"
        },
        {
          icon: <span className=""><IconBlue15 className="text-blue" /> </span>,
          name: "She/Her"
        },
        {
          icon: <span className=""><IconBlue17 className="text-blue" /></span>,
          name: " He/Him"
        },
        {
          icon: <span className=""><IconBlue16 className="text-blue" /> </span>,
          name: "They/Them"
        },
      ]

    const openSelect = () => { inputRef1.current.click(); }
    const inputRef1 = React.useRef();
    const openSelect2 = () => { inputRef2.current.click(); }
    const inputRef2 = React.useRef();
    const openSelect3 = () => { inputRef3.current.click(); }
    const inputRef3 = React.useRef();

    const [previewImage1, setPreviewImage1] = useState('');
    const [previewImage2, setPreviewImage2] = useState('');
    const [previewImage3, setPreviewImage3] = useState('');

    const [image1, setImage1] = useState('');
    const [image2, setImage2] = useState('');
    const [image3, setImage3] = useState('');

    return (
        <div>
            <form action="" method="">
                <div className="uploadSec widsec mb-4">
                    <ul className="d-flex flex-wrap">
                        <NavItem as="li">
                            <div className="imgView br-20 bg mb-2">
                                <div className='mx-auto mb-4 fileTab h-100'>
                                    <label onClick={openSelect} className="w-100 h-100">
                                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                                            {previewImage1 ? <img src={previewImage1} alt="Profile Picture" className="w-100 h-100" /> :
                                                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                                    <svg width="20" height="24" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.158 6.84211C4.158 3.06332 7.22131 0 11.0001 0C14.7789 0 17.8422 3.06332 17.8422 6.84211C17.8422 10.6209 14.7789 13.6842 11.0001 13.6842C7.22131 13.6842 4.158 10.6209 4.158 6.84211ZM11.0001 10.9474C13.2674 10.9474 15.1054 9.10938 15.1054 6.84211C15.1054 4.57483 13.2674 2.73684 11.0001 2.73684C8.73283 2.73684 6.89484 4.57483 6.89484 6.84211C6.89484 9.10938 8.73283 10.9474 11.0001 10.9474Z" fill="#4E4E57" />
                                                        <path d="M3.25914 18.259C1.20611 20.3121 0.0527344 23.0966 0.0527344 26H2.78958C2.78958 23.8224 3.65461 21.7341 5.19438 20.1943C6.73416 18.6545 8.82254 17.7895 11.0001 17.7895C13.1777 17.7895 15.2661 18.6545 16.8058 20.1943C18.3456 21.7341 19.2106 23.8224 19.2106 26H21.9475C21.9475 23.0966 20.7941 20.3121 18.7411 18.259C16.688 16.206 13.9035 15.0526 11.0001 15.0526C8.09668 15.0526 5.31217 16.206 3.25914 18.259Z" fill="#4E4E57" />
                                                    </svg>
                                                    <p className="mb-0 mt-1">Change Avatar</p>
                                                </div>
                                            }
                                        </div>
                                    </label>
                                </div>
                                <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                                    let url = URL.createObjectURL(e.target.files[0])
                                    setPreviewImage1(url);
                                    setImage1(e.target.files[0])
                                }} ref={inputRef1} />
                            </div>
                        </NavItem>
                        <NavItem as="li">
                            <div className="imgView br-20 bg mb-2">
                                <div className='mx-auto mb-4 fileTab h-100'>
                                    <label onClick={openSelect2} className="w-100 h-100">
                                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                                            {previewImage2 ? <img src={previewImage2} alt="Profile Picture" className="w-100 h-100" /> :
                                                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                                    <IconBlue7q />
                                                    <p className="mb-0 mt-1">Change background cover</p>
                                                    <p className="fw-bold mb-2">320 X 300 Pixels</p>
                                                </div>
                                            }
                                        </div>
                                    </label>
                                </div>
                                <input hidden type="file" name="profile" id="profile" accept="image/*" onChange={(e) => {
                                    let url = URL.createObjectURL(e.target.files[0])
                                    setPreviewImage2(url);
                                    setImage2(e.target.files[0])
                                }} ref={inputRef2} />
                            </div>
                        </NavItem>
                        <NavItem as="li">
                            <div className="imgView br-20 bg mb-2">
                                <div className='mx-auto mb-4 fileTab h-100'>
                                    <label onClick={openSelect3} className="w-100 h-100">
                                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                                            {previewImage3 ? <img src={previewImage3} alt="Profile Picture" className="w-100 h-100" /> :
                                                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                                    <IconBlue6q />
                                                    <p className="mb-0 mt-1">Change Business Logo</p>
                                                    <p className="fw-bold mb-2">500 X 500 Pixels</p>
                                                </div>
                                            }
                                        </div>
                                    </label>
                                </div>
                                <input hidden type="file" name="profile" id="profile" accept="image/*" onChange={(e) => {
                                    let url = URL.createObjectURL(e.target.files[0])
                                    setPreviewImage3(url);
                                    setImage3(e.target.files[0])
                                }} ref={inputRef3} />
                            </div>
                        </NavItem>
                    </ul>
                </div>
                <div className="row customCol">
                    <div className="col-md-5">
                        <label className="mb-2">{t('profile_section.first_name')}</label>
                        <input
                            placeholder={t('profile_section.your_tagline_here')}
                            className="shadow-5in br-10 w-100 mb-4"
                            type="text"
                        />
                    </div>
                    <div className="col-md-5">
                        <label className="mb-2">{t('profile_section.last_name')}</label>
                        <input
                            placeholder={t('profile_section.your_tagline_here')}
                            className="shadow-5in br-10 w-100 mb-4"
                            type="text"
                        />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2"></label>
                        {/* <Select isSearchable={false} options={options2} className="countryList br-30 w-100 customWidth1 mb-4" placeholder="" theme={theme}
                            styles={{
                                option: (base) => ({
                                    ...base,
                                    borderRadius: '30px',
                                    width: '88%',
                                    height: 'auto',
                                    lineHeight: '24px',
                                    margin: '10px',
                                    cursor: 'pointer',
                                    color: '#000000',
                                    boxShadow: '2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',

                                }),
                            }}
                            defaultValue={{
                                value: 'She/Her',
                                label: <span className=""><IconBlue15 className="text-blue" />{t('profile_section.she_her')}</span>,
                            }}
                        /> */}
                        <DropdownBox name="She/Her" boxid="gender1" options={options2} boxwidth="w-100 mx-auto"/>
                    </div>
                    <div className="col-md-6">
                        <label className="mb-2">{t('profile_section.business_title')}</label>
                        <input
                            placeholder={t('profile_section.your_business_title')}
                            className="shadow-5in br-10 w-100 mb-4"
                            type="text"
                        />
                    </div>
                    <div className="col-md-6">
                        <label className="mb-2">{t('profile_section.preferred_profile')}</label>
                        <input
                            placeholder={t('profile_section.dyna')}
                            className="shadow-5in br-10 w-100 mb-4"
                            type="text"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label className="mb-2">{t('profile_section.business_company_name')}</label>
                        <input
                            placeholder={t('profile_section.business_name_here')}
                            className="shadow-5in br-10 w-100 mb-4"
                            type="text"
                        />
                    </div>
                    <div className="col-md-6">
                        <label className="mb-2">{t('profile_section.business_niche')}</label>
                        <input
                            placeholder={t('profile_section.fashion')}
                            className="shadow-5in br-10 w-100 mb-4"
                            type="text"
                        />
                    </div>
                </div>
                <div className="w-100 text-center mt-2 mb-40">
                    {/* <button className="primarybtn btn br-5 shadow-4 mx-auto bg-primary">primary contact</button> */}
                    <ButtonBoxNew  name="primary contact" boxwidth="w-220 mt-0 mb-4" />
                </div>
                <div className="row px-5 mx-0 mb-40">
                    <div className="col-lg-7 inputwid">
                        <div className="mb-3 d-flex flex-wrap w-100">
                            <label className="mb-2 h8 w-100 ps-5 ms-2">Contact/WhatsApp Number</label>
                            <div className="d-flex align-items-center w-100">
                                <span className="w-50c me-2 shadow-4 bg-primary br-50 d-flex justify-content-center align-items-center"><IconBlue1q /></span>
                                <input
                                    placeholder="554-980-5678"
                                    className="shadow-5in br-10"
                                    type="phone"
                                />
                            </div>
                        </div>
                        <div className="mb-3 d-flex flex-wrap w-100">
                            <label className="mb-2 h8 w-100 ps-5 ms-2">Business Email</label>
                            <div className="d-flex align-items-center w-100">
                                <span className="w-50c me-2 shadow-4 bg-primary br-50 d-flex justify-content-center align-items-center"><IconBlue2q /></span>
                                <input
                                    placeholder="dyna@9t05escapee.com"
                                    className="shadow-5in br-10"
                                    type="email"
                                />
                            </div>
                        </div>
                        <div className="mb-3 d-flex flex-wrap w-100">
                            <label className="mb-2 h8 w-100 ps-5 ms-2">Website</label>
                            <div className="d-flex align-items-center w-100">
                                <span className="w-50c me-2 shadow-4 bg-primary br-50 d-flex justify-content-center align-items-center"><IconBlue3q /></span>
                                <input
                                    placeholder="www.website.com"
                                    className="shadow-5in br-10"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="mb-3 d-flex flex-wrap w-100">
                            <label className="mb-2 h8 w-100 ps-5 ms-2">Business Headquarter Location</label>
                            <div className="d-flex align-items-center w-100">
                                <span className="w-50c me-2 shadow-4 bg-primary br-50 d-flex justify-content-center align-items-center"><IconBlue4q /></span>
                                <input
                                    placeholder="Location"
                                    className="shadow-5in br-10"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="mb-3 d-flex flex-wrap w-100">
                            <label className="mb-2 h8 w-100 ps-5 ms-2">Business Address</label>
                            <div className="d-flex align-items-center w-100">
                                <span className="w-50c me-2 shadow-4 bg-primary br-50 d-flex justify-content-center align-items-center"><IconBlue5q /></span>
                                <input
                                    placeholder="123 Success Rd, Smart City, Ontario M1E 3R0"
                                    className="shadow-5in br-10"
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 pt-5 d-flex flex-column justify-content-center align-items-center">
                        {/* <button className="genbtn btn br-5 mt-5 shadow-4 mx-auto bg-primary">Generate QR code</button> */}
                        <ButtonBoxNew  name="Generate QR code" boxwidth="w-220 mt-0 mb-4" />
                        <div className="shadow-4 bg p-2 w-80c mb-3 br-5 d-inline-block mx-auto">
                            <img src={qrcode} alt="" />
                        </div>
                        <div>
                            <h5 className="h7 d-flex justify-content-center align-items-center">Save QR code
                                <Link to="" className="text-primary downloadLink shadow-4 d-flex bg justify-content-center align-items-center">
                                    <img src={Slide7} alt="" />
                                </Link>
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 bioSec">
                        <nav className="d-flex justify-content-between align-items-center mb-3">
                            <div className="bioNav" id="nav-tab" role="tablist">
                                <button className="br-top-15 px-3 bg h-50 border-0 active" id="nav-who-tab" data-bs-toggle="tab" data-bs-target="#nav-who" type="button" role="tab" aria-controls="nav-who" aria-selected="true">
                                    {t('profile_section.who_am_i')}</button>
                                <button className="br-top-15 px-3 bg h-50 border-0" id="nav-what-tab" data-bs-toggle="tab" data-bs-target="#nav-what" type="button" role="tab" aria-controls="nav-what" aria-selected="false">{t('profile_section.what_i_do')}</button>
                                <button className="br-top-15 px-3 bg h-50 border-0" id="nav-service-tab" data-bs-toggle="tab" data-bs-target="#nav-service" type="button" role="tab" aria-controls="nav-service" aria-selected="false">{t('profile_section.who_is_my_service_for')}</button>
                                <button className="br-top-15 px-3 bg h-50 border-0" id="nav-work-tab" data-bs-toggle="tab" data-bs-target="#nav-work" type="button" role="tab" aria-controls="nav-work" aria-selected="false">{t('profile_section.why_work_with_me')}</button>
                            </div>
                            <div className="dropFormat">
                                <div className="d-flex">
                                    <div className="dropdown btnArrow btnArrow2">
                                        <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-display="static">
                                            <Link to="#" id="notab" className="d-flex justify-content-center fs-5 fw-bold ps-3 pe-5 align-items-center w-100x text-underline shadow-4 h-40 h8 br-30">
                                                <u className="h5">A</u>
                                            </Link>
                                        </span>
                                        <EditorDrop />
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-who" role="tabpanel" aria-labelledby="nav-who-tab">
                                <div className="position-relative">
                                    <textarea
                                        placeholder={t('profile_section.your_bio_here')} rows="10"
                                        className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                                        type="text">
                                    </textarea>
                                    <span className="position-absolute top-20 start-16 ">
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                    </span>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-what" role="tabpanel" aria-labelledby="nav-what-tab">
                                <div className="position-relative">
                                    <textarea
                                        placeholder={t('profile_section.your_bio_here')} rows="10"
                                        className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                                        type="text">
                                    </textarea>
                                    <span className="position-absolute top-20 start-16 ">
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                    </span>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-service" role="tabpanel" aria-labelledby="nav-service-tab">
                                <div className="position-relative">
                                    <textarea
                                        placeholder={t('profile_section.your_bio_here')} rows="10"
                                        className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                                        type="text">
                                    </textarea>
                                    <span className="position-absolute top-20 start-16 ">
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                    </span>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-work" role="tabpanel" aria-labelledby="nav-work-tab">
                                <div className="position-relative">
                                    <textarea
                                        placeholder={t('profile_section.your_bio_here')} rows="10"
                                        className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                                        type="text">
                                    </textarea>
                                    <span className="position-absolute top-20 start-16 ">
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 bioSec">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <div className="bioNav">
                                <h5 className="h8">SPECIAL NOTES</h5>
                            </div>
                            <div className="dropFormat mb-2">
                                <div className="d-flex">
                                    <div className="dropdown btnArrow btnArrow2">
                                        <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-display="static">
                                            <Link to="#" id="notab" className="d-flex justify-content-center fs-5 fw-bold ps-3 pe-5 align-items-center w-100x text-underline shadow-4 h-40 h8 br-30">
                                                <u className="h5">A</u>
                                            </Link>
                                        </span>
                                        <EditorDrop />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="position-relative">
                                <textarea
                                    placeholder="Type notes here" rows="10"
                                    className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                                    type="text">
                                </textarea>
                                <span className="position-absolute top-20 start-16 ">
                                    <span className="d-block mb-2">.</span>
                                    <span className="d-block mb-2">.</span>
                                    <span className="d-block mb-2">.</span>
                                    <span className="d-block mb-2">.</span>
                                    <span className="d-block mb-2">.</span>
                                    <span className="d-block">.</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 text-center">
                    <ButtonBox boxwidth="w-140 mt-0 mb-50 mx-auto" name="Save/Next" className="next action-button" value="Save/Next" clicked="Saved" />
                </div>
            </form>
        </div >
    );
}
