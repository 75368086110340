import React, { useState, useEffect, useRef } from "react";
import img1 from "../../images/profileview/img1.png";
import img2 from "../../images/profileview/imgs2.png";
import img3 from "../../images/profileview/imgs3.png";
// import bg from '../../images/profileview/bgimg.png';
// import flag from "../../images/flag.png";
import ImageBox from "../global/ImageBox";
import CustomRangeColor from "../global/CustomRangeColor";
// import user from '../../images/img1.png';
import img10 from "../../images/profileview/img16s.png";
import bgimg from "../../images/profileview/bg-img2.png";
import tag from "../../images/profileview/img2.png";
import state2 from "../../images/status.png";
// import state3 from '../../images/profileview/stateblack.png';
import { NavItem } from "react-bootstrap";
// import { ReactComponent as IconBlue1v } from "../../images/icons/icon36.svg";
// import { ReactComponent as IconBlue2v } from "../../images/icons/icon37.svg";
// import { ReactComponent as IconBlue3v } from "../../images/icons/icon38.svg";
// import { RiCloseLine} from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
// import video from './Videos.video1.mp4';

import imguser from "../../images//img1.png";
import userimg from "../../images/profileview/userimg.png";
// import img4 from '../../images/objectaudio.png';
import icon13 from "../../images/audio/icon10.png";

import img5 from "../../images/audio/img8.png";
import img6 from "../../images/audio/img1.png";
import img7 from "../../images/audio/img2.png";
import img8 from "../../images/audio/img3.png";
import img20 from "../../images/audio/img9.png";
import img21 from "../../images/audio/img10.png";
import img11s from "../../images/audio/imge4.png";
import wcircle from "../../images/audio/wcircle.png";
import img12s from "../../images/audio/img7.png";
import imgbg3 from "../../images/audio/bgimg.png";
import imgbg2 from "../../images/audio/user.png";
import color from "../../images/audio/img11.png";
import range from "../../images/audio/img12.png";
import imgvid from "../../images/imgvid.png";
import auto from "../../images/audio/auto.png";
import setting from "../../images/audio/setting.png";
import short from "../../images/audio/short.png";
import frame1 from "../../images/audio/frame1.png";
import full from "../../images/audio/full.png";
import play from "../../images/audio/play.png";
import forwad from "../../images/audio/forwad.png";
import sound from "../../images/audio/sound.png";
import vpause from "../../images/audio/vpause.png";
import icon2 from "../../images/audio/icon2.png";
import icon3 from "../../images/audio/icon3.png";
import icon4 from "../../images/audio/icon4.png";
import icon5 from "../../images/audio/icon5.png";
import icon6 from "../../images/audio/icon6.png";
import icon9 from "../../images/audio/icon8.png";

import close from "../../images/close1.png";
import Slider from "@mui/material/Slider";
import crown from "../../images/profileicon/img1.svg";
import fb from "../../images/profileicon/img2.svg";
import ins from "../../images/profileicon/img3.svg";
import twi from "../../images/profileicon/img4.svg";
import linked from "../../images/profileicon/img5.svg";
import pine from "../../images/profileicon/img6.svg";
import tube from "../../images/profileicon/img7.svg";
import tok from "../../images/profileicon/img8.svg";
import check from "../../images/profileicon/img10.svg";
import canda from "../../images/profileicon/img11.svg";
import vip from "../../images/profileicon/img12.svg";
import whts from "../../images/profileicon/img13.svg";
import embed from "../../images/audio/embed.png";
import whats from "../../images/audio/whats.png";
import fb1 from "../../images/audio/fb.png";
import twitter from "../../images/audio/twitter.png";
import insta from "../../images/audio/insta.png";
import other from "../../images/audio/other.png";
import closebtn from "../../images/closebtn.png";
import frame from "../../images/audio/frame.png";
import arrow2 from "../../images/audio/up.png";
import colorimg from "../../images/audio/img19.png";
import playbar from "../../images/audio/img21.png";
import soundeimg from "../../images/audio/soundeimg.png";
import testvideo from "../../images/audio/testvideo.mp4";
import testvideo2 from "../../images/audio/testvideo2.mp4";

import testvideo3 from "../../images/audio/testvideo3.mp4";
import mute from "../../images/audio/mute.png";
import high from "../../images/audio/high.png";
import volumeunions from "../../images/volumeunions.png";
import { GiPauseButton } from "react-icons/gi";
import RangeSlider from "react-bootstrap-range-slider";
import { ReactComponent as Arrow1 } from "../../images/audio/arrow2.svg";
import { ReactComponent as Save } from "../../images/audio/save.svg";
import { ReactComponent as Links } from "../../images/audio/link.svg";
import { ReactComponent as Lock } from "../../images/audio/lock.svg";
import { ReactComponent as Mark } from "../../images/audio/mark.svg";
import { ReactComponent as Doted } from "../../images/audio/dotsmenu.svg";
import { ReactComponent as Emoji } from "../../images/audio/emoji.svg";
import { ReactComponent as Icon4 } from "../../images/audio/icon.svg";
import { ReactComponent as Checkicon } from "../../images/audio/check.svg";
import Tabvid from "./PaymentTab";
import EditableTextField from "./TextEdit";

export default function VideoView({
  setHandleView,
  isMiniScreenOpen,
  toggleMiniScreen,
  videoSource,
  setVideoSource,
  setIsChildVideoPlaying,
}) {
  const videoData = [testvideo, testvideo2, testvideo3];
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const videoRef = useRef(null);
  const [volume, setVolume] = useState(50);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [showVolumeControls, setShowVolumeControls] = useState(false);
  const [isMiniScreen, setIsMiniScreen] = useState(false);
  const [autoplay, setAutoplay] = useState(false);
  const thumbImageRef = useRef(null);
  const sliderRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [backgroundColor, setBackgroundColor] = useState("linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) ");
  const [sliderText, setSliderText] = useState("50"); // Initial text

  const handleSliderTextChange = (newText) => {
    setSliderText(newText);
  };
  const handleBackgroundColorChange = (color) => {
    setBackgroundColor(color);
  }
  const handlePlaylistClick = (index) => {
    setCurrentVideoIndex(index);
    setAutoplay(true);
    setIsPlaying(true);
    const progressBar = document.querySelector(".progress-inner");
    progressBar.style.width = "0%";
    progressBar.style.animation = "none";

    // Load and play the new video
    videoRef.current.load();
    videoRef.current.play();

    // Set up a new event listener for the progress bar on the new video
    videoRef.current.addEventListener("timeupdate", updateProgressBar);
  };
  const updateProgressBar = () => {
    const video = videoRef.current;
    const progressBar = document.querySelector(".progress-inner");
    const currentTime = video.currentTime;
    const duration = video.duration;

    const progress = (currentTime / duration) * 100;
    progressBar.style.width = progress + "%";
  };

  useEffect(() => {
    if (autoplay) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [autoplay]);
  const onVideoEnded = () => {
    if (autoplay) {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoData.length);

      if (currentVideoIndex < videoData.length) {
        videoRef.current.src = videoData[currentVideoIndex];
        videoRef.current.play();
      }
    }
  };

  useEffect(() => {
    if (showVolumeControls) {
      setTimeout(() => {
        setShowVolumeControls(false);
      }, 5000);
    }
  }, [showVolumeControls]);

  const toggleVolumeControls = () => {
    setShowVolumeControls(!showVolumeControls);
  };
  const handleForwardClick = () => {
    const nextVideoIndex = (currentVideoIndex + 1) % videoData.length;
    if (nextVideoIndex >= 0) {
      const progressBar = document.querySelector(".progress-inner");
      progressBar.style.width = "0%";
      progressBar.style.animation = "none";
      setAutoplay(true);
      videoRef.current.load();
      videoRef.current.play();
      setIsPlaying(true);
      setCurrentVideoIndex(nextVideoIndex);
      videoRef.current.addEventListener("timeupdate", updateProgressBar);
    }
  };

  useEffect(() => {
    videoRef.current.src = videoData[currentVideoIndex];
  }, [currentVideoIndex]);

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value) / 100;
    setVolume(newVolume);
    videoRef.current.volume = newVolume;

    // Calculate the thumb image position
    updateThumbPosition(newVolume);
  };

  const updateThumbPosition = (newVolume) => {
    const slider = sliderRef.current;
    const thumbImage = thumbImageRef.current;
    const min = parseFloat(slider.min);
    const max = parseFloat(slider.max);
    const percent = ((newVolume - min) / (max - min)) * 100;

    // Calculate the position of the thumb image vertically
    const thumbPosition =
      ((slider.clientHeight - thumbImage.clientHeight) * percent) / 100;

    // Set the position of the thumb image
    thumbImage.style.top = `${thumbPosition}px`;
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
    setIsChildVideoPlaying(!isPlaying);
  };
  const onTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const handleTheaterButtonClick = () => {
    const videoContainer = document.querySelector(".middleimg");
    if (videoContainer.classList.contains("theater-mode")) {
      videoContainer.classList.remove("theater-mode");
      setIsMiniScreen(false);
      setIsFullScreen(false); // Exit theater mode
    } else {
      videoContainer.classList.add("theater-mode");
      setIsMiniScreen(false);
      setIsFullScreen(false); // Exit mini-screen mode if currently in it
    }
  };
  const navigate = useNavigate();
  const handleMiniPlayerButtonClick = () => {
    const newVideoSource = videoData[currentVideoIndex];

    // Pass the new video source to the parent component
    toggleMiniScreen(newVideoSource);
  };

  const handleFullScreenButtonClick = () => {
    const videoContainer = document.querySelector(".middleimg");

    if (videoContainer) {
      if (!document.fullscreenElement) {
        if (videoContainer.requestFullscreen) {
          videoContainer.requestFullscreen();
        } else if (videoContainer.mozRequestFullScreen) {
          videoContainer.mozRequestFullScreen();
        } else if (videoContainer.webkitRequestFullscreen) {
          videoContainer.webkitRequestFullscreen();
        } else if (videoContainer.msRequestFullscreen) {
          videoContainer.msRequestFullscreen();
        }

        videoContainer.classList.add("full-screen");

        setIsFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }

        // Remove the full-screen CSS class
        videoContainer.classList.remove("full-screen");
        setIsFullScreen(false);
      }
    }
  };

  useEffect(() => {
    const videoContainer = document.querySelector(".middleimg");

    if (videoContainer) {
      videoContainer.addEventListener("fullscreenchange", () => {
        setIsFullScreen(!!document.fullscreenElement);
      });
    }
  }, []);
  const handleSliderChange = (e) => {
    videoRef.current.currentTime = e.target.value;
    setCurrentTime(e.target.value);
  };

  const handleClick1 = () => {
    setToggle1(!toggle1);
  };
  const [toggle1, setToggle1] = useState("true");

  const handleClick2 = () => {
    setToggle2(!toggle2);
  };
  const [toggle2, setToggle2] = useState("true");
  const handleVideoClick = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    // <div className="bannerSection1">
    //   <ul className="w-950 d-flex justify-content-center align-items-center mx-auto">
    //     <NavItem as="li">
    //       <img src={img1} alt="" className="circle" />
    //     </NavItem>
    //     <NavItem as="li">
    //       <img src={img2} alt="" className="circle" />
    //     </NavItem>
    //     <NavItem as="li">
    //       <img src={img3} alt="" className="circle" />
    //     </NavItem>
    //     <NavItem as="li">
    //       <div className="ImgArea m-0 w-100 h-100">
    //         <div className="user position-relative mx-auto">
    //           <ImageBox boxwidth="w-100 userImg" src={user} />
    //           <div className="tag top-0 position-absolute">
    //             <ImageBox boxwidth="w-16" src={tag} />
    //           </div>
    //           <div className="flag position-absolute">
    //             <ImageBox boxwidth="w-24" src={flag} />
    //           </div>
    //           <div className="status bottom-0 end-0 position-absolute">
    //             <ImageBox boxwidth="w-19" src={state2} />
    //           </div>
    //         </div>
    //       </div>
    //     </NavItem>
    //     <NavItem as="li">
    //       <div className="bs-primary w-100 h-100 flex-column rounded-circle d-flex justify-content-center align-items-center">
    //         <h4 className="m-0 h5">547</h4>
    //         <p className="m-0 h7">POSTS</p>
    //       </div>
    //     </NavItem>
    //     <NavItem as="li" className="position-relative">
    //       <div className="topTag1 bg shadow-4 position-absolute br-100">
    //         <div class="shadow-5in position-relative bg z-index br-100 d-flex align-items-center justify-content-center p-9 w-100 h-100">
    //           <img src={img10} alt="" />
    //         </div>
    //       </div>
    //       <div className="bs-primary w-100 h-100 flex-column rounded-circle d-flex justify-content-center align-items-center">
    //         <h4 className="m-0 h5">1243</h4>
    //         <p className="m-0 h7">Followers</p>
    //       </div>
    //       <div className="status position-absolute">
    //         <div className="shadow-4 position-relative bg z-index br-100 d-flex align-items-center justify-content-center mx-auto w-24">
    //           <ImageBox boxwidth="w-19 shadow-0" src={state3} />
    //         </div>
    //       </div>
    //     </NavItem>
    //     <NavItem as="li">
    //       <div className="bs-primary w-100 h-100 flex-column rounded-circle d-flex justify-content-center align-items-center">
    //         <h4 className="m-0 h5">689</h4>
    //         <p className="m-0 h7">Following</p>
    //       </div>
    //     </NavItem>
    //   </ul>
    //   <div className="w-800 d-flex position-relative mt-52 justify-content-between mx-auto ps-4">
    //       <div className="w-185 br-10 h8 shadow-5in h-44 lh-44 ms-5">
    //         DYNA APPOLON
    //       </div>
    //       <div className="d-flex">
    //         <div className="w-110 br-10 h8 shadow-5in h-44 lh-44 fw-normal">
    //         <Link href="" className="d-block">Share<IconBlue1v className="ms-2" /></Link>
    //         </div>
    //         <div className="w-140 br-10 h8 shadow-5in h-44 lh-44 ms-2">
    //           <Link href="" className="d-block">Download<IconBlue2v className="ms-2" /></Link>
    //         </div>
    //       </div>
    //   </div>
    //   <div className="w-950 imgMain shadow-5in br-40 p-20 mx-auto position-relative mb-4">
    //     <video width="100%" height="380" controls  className="br-40">
    //       <source src="https://www.youtube.com/embed/1bwft4RXa30" type="video/mp4"/>
    //     </video>
    //     <div className="position-absolute bottom-50s start-0 end-0 mx-auto">
    //       <img src={bgimg} alt="" className="bg-contain w-96" />
    //     </div>
    //     <div className="position-absolute videocrose">
    //       <Link href="" onClick={() => setHandleView('')}  className="position-relative">
    //         <span className="close-btn1"><RiCloseLine /></span>
    //       </Link>
    //     </div>
    //   </div>
    //   <input type="text" placeholder="VIDEO TITLE" className="shadow-5in bg mx-auto mt-5 inputaudio" />
    // </div>
    <div>
      <div className="bannerSection mx-auto text-center w-1340">
        <div className="audioply">
          <div className="w-100">
            <ul className="userlist w-950 d-flex justify-content-center align-items-center mx-auto">
              <NavItem as="li" className="mt-auto mb-3">
                <img src={img1} alt="" className="circle" />
              </NavItem>
              <NavItem as="li" className="mt-auto mb-3">
                <img src={img2} alt="" className="circle" />
              </NavItem>
              <NavItem
                as="li"
                className="ImgArea ImgArea3 mt-auto mb-3 position-relative"
              >
                <img src={img3} alt="" className="circle" />
                <div className="tag top-0 start-0 end-0 mx-auto position-absolute">
                  <ImageBox boxwidth="w-16" src={tag} />
                </div>
              </NavItem>
              <NavItem as="li">
                <div className="imgin5">
                  <div className="position-relative mb-3">
                    <img src={userimg} alt="" />
                  </div>
                  <Link to="" className="icon1">
                    <img src={fb} alt="" />
                  </Link>
                  <Link to="" className="icon2">
                    <img src={ins} alt="" />
                  </Link>
                  <Link to="" className="icon3">
                    <img src={whts} alt="" />
                  </Link>
                  <Link to="" className="icon4">
                    <img src={twi} alt="" />
                  </Link>
                  <Link to="" className="icon5">
                    <img src={canda} alt="" />
                  </Link>
                  <Link to="" className="icon6">
                    <img src={crown} alt="" />
                  </Link>
                  <Link to="" className="icon7">
                    <img src={tok} alt="" />
                  </Link>
                  <Link to="" className="icon8">
                    <img src={check} alt="" />
                  </Link>
                  <Link to="" className="icon9">
                    <img src={tube} alt="" />
                  </Link>
                  <Link to="" className="icon10">
                    <img src={linked} alt="" />
                  </Link>
                  <Link to="" className="icon11">
                    <img src={vip} alt="" />
                  </Link>
                  <Link to="" className="icon12">
                    <img src={pine} alt="" />
                  </Link>
                </div>
              </NavItem>
              <NavItem as="li" className="mt-auto mb-3">
                <div className="bs-primary w-100 h-100 flex-column rounded-circle d-flex justify-content-center align-items-center">
                  <h4 className="m-0">547</h4>
                  <p className="m-0 h7">POSTS</p>
                </div>
              </NavItem>
              <NavItem as="li" className="position-relative mt-auto mb-3">
                <div className="status bottom-0 start-0 position-absolute">
                  <ImageBox boxwidth="w-26" src={state2} />
                </div>
                <div className="topTag bg shadow-4 position-absolute br-100">
                  <div class="shadow-5in position-relative bg z-index br-100 d-flex align-items-center justify-content-center p-9 w-100 h-100">
                    <img src={img10} alt="" />
                  </div>
                </div>
                <div className="bs-primary w-100 h-100 flex-column rounded-circle d-flex justify-content-center align-items-center">
                  <h4 className="m-0">1243</h4>
                  <p className="m-0 h7">Followers</p>
                </div>
                {/* <div className="status position-absolute">
                  <div className="shadow-4 position-relative bg z-index br-100 d-flex align-items-center justify-content-center mx-auto w-24">
                    <ImageBox boxwidth="w-19 shadow-0" src={state3} />
                  </div>
                </div> */}
              </NavItem>
              <NavItem as="li" className="mt-auto mb-3">
                <div className="bs-primary w-100 h-100 flex-column rounded-circle d-flex justify-content-center align-items-center">
                  <h4 className="m-0">689</h4>
                  <p className="m-0 h7">Following</p>
                </div>
              </NavItem>
            </ul>
            <div className="btmaudio">
              <h3>
                <span>DYNA APPOLON</span>
              </h3>
              <h4>
                <span>CEO | Founder | 9 to 5 Escapee</span>
              </h4>
            </div>
          </div>
          <div className="videoplayer">
            <button className="topbtn border-0 bg-primary">
              VIDEO CENTER{" "}
            </button>
            <div className="videobox d-flex justify-content-between">
              <div className="playersec">
                <div className="playerinternal position-relative">
                  <div className="vidplay position-relative">
                    <div className="audiotool position-relative">
                      <div className="wcircle">
                        <img src={wcircle} />
                      </div>

                      {/* <div className="middleimg position-relative">
                        <div className='soundediv'>
                          <img src={soundeimg} alt='' />
                        </div>
                        <img src={img5} alt="" />
                        <Link to="" className="position-absolute d-inline-table top-0 bottom-0 start-0 end-0 m-auto">
                          <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130" fill="none">
                              <circle cx="65" cy="65" r="64" stroke="#F0F4F8" stroke-width="2"/>
                              <circle cx="63.375" cy="63.375" r="34.125" fill="url(#paint0_linear_255_76455)"/>
                              <path d="M56.875 55.4821C56.875 53.9385 58.5494 52.9768 59.8827 53.7545L73.4135 61.6474C74.7365 62.4192 74.7365 64.3308 73.4135 65.1026L59.8827 72.9955C58.5494 73.7732 56.875 72.8115 56.875 71.2679V55.4821Z" fill="#202020"/>
                              <defs>
                                <linearGradient id="paint0_linear_255_76455" x1="63.375" y1="16.965" x2="63.375" y2="108.42" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#FACB30"/>
                                  <stop offset="0.302269" stop-color="#FEF9C8"/>
                                  <stop offset="0.640625" stop-color="#C49530"/>
                                  <stop offset="1" stop-color="#FFF8C4"/>
                                </linearGradient>
                              </defs>
                            </svg>
                        </Link>
                        <div className='position-absolute pb-3 d-inline-table bottom-0 start-0 end-0 mx-auto'>
                          <img src={playbar} alt="" />
                          <div className='d-flex playable justify-content-between px-4'>
                            <div className='d-flex'>
                              <Link to="">
                                <img src={play} alt="" />
                              </Link>
                              <Link to="">
                                <img src={forwad} alt="" />
                              </Link>
                              <Link to="">
                                <img src={sound} alt="" />
                              </Link>
                              <Link className='btnplaytime'>
                                <svg xmlns="http://www.w3.org/2000/svg" className='me-2' width="18" height="19" viewBox="0 0 18 19" fill="none">
                                  <path d="M9 18.1699C4.0293 18.1699 0 14.1406 0 9.16992C0 4.19922 4.0293 0.169922 9 0.169922C13.9707 0.169922 18 4.19922 18 9.16992C18 14.1406 13.9707 18.1699 9 18.1699ZM9.9 9.16992V4.66992H8.1V10.9699H13.5V9.16992H9.9Z" fill="#202020"/>
                                </svg>
                                16:39  /  1:52:26
                              </Link>

                            </div>
                            <div className='d-flex justify-content-between'>
                              <div className="dropdown mx-3 dropnew dropnew4" id="dropbtn8">
                                <button className="p-0 m-0 bg-transparent border-0" type="button" id="dropbtn8" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={auto} alt="" />
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end border-0 bg-transparent" aria-labelledby="dropbtn8">
                                  <div className="gradient-box newdrop6">
                                    <li>
                                      <h6 className='m-0 d-flex align-items-center justify-content-center'>
                                        Auto Play On
                                        <div className="toggle_btn_wrapper bg w-auto ms-2">
                                          <div className="form-check form-switch px-2">
                                              <input className="form-check-input d-block float-end" id='auto4' type="checkbox" role="switch"/>
                                          </div>
                                        </div>
                                      </h6>
                                    </li>
                                  </div>
                                </ul>
                              </div>
                              <div className="dropdown mx-3 dropnew dropnew5" id="dropbtn8">
                                <button className="p-0 m-0 bg-transparent border-0" type="button" id="dropbtn8" data-bs-toggle="dropdown" aria-expanded="false">
                                  <img src={setting} alt="" />
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end border-0 bg-transparent" aria-labelledby="dropbtn8">
                                  <div className="gradient-box newdrop6">
                                    <div className='position-relative'>
                                      <button className="top">
                                        Settings
                                      </button>
                                      <div className='lidiv mt-4'>
                                        <img src={icon2} alt="" />
                                        Ambient Mode
                                        <div className="toggle_btn_wrapper bg w-auto ms-auto">
                                          <div className="form-check form-switch px-0">
                                              <input className="form-check-input d-block float-end" id='auto4' type="checkbox" role="switch"/>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='lidiv'>
                                        <img src={icon3} alt="" />
                                        Annotations
                                        <div className="toggle_btn_wrapper bg w-auto ms-auto">
                                          <div className="form-check form-switch px-0">
                                              <input className="form-check-input d-block float-end" id='auto4' type="checkbox" role="switch"/>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='lidiv'>
                                        <img src={icon4} alt="" />
                                        Playback speed
                                        <h6>Normal <img src={icon9} alt="" className='me-0' /></h6>
                                      </div>
                                      <div className='lidiv'>
                                        <img src={icon5} alt="" />
                                        Substitles/cc (1)
                                        <div className="toggle_btn_wrapper bg w-auto ms-auto">
                                          <div className="form-check form-switch px-0">
                                              <input className="form-check-input d-block float-end" id='auto4' type="checkbox" role="switch"/>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='lidiv lastdiv'>
                                        <img src={icon6} alt="" />
                                        Quality
                                        <h6>Auto <img src={icon9} alt="" className='me-0' /></h6>
                                      </div>
                                    </div>
                                  </div>
                                </ul>
                              </div>
                              <Link to="">
                                <img src={short} alt="" />
                              </Link>
                              <Link to="">
                                <img src={frame1} alt="" />
                              </Link>
                              <Link to="">
                                <img src={full} alt="" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="videop">
                        <div
                          className={`middleimg  position-relative  ${
                            isMiniScreen ? "mini-player" : ""
                          } ${isFullScreen ? "full-screen" : ""}`}
                        >
                          {/* <img src={img5} alt="" /> */}
                          {isFullScreen ? (
                            <div>
                              <button className="topbtn border-0 mt-5 bg-primary">
                                VIDEO CENTER{" "}
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="topvid4">
                            <video
                              className="theater-mode "
                              ref={videoRef}
                              onCanPlayThrough={() => {
                                setDuration(videoRef.current.duration);
                                setIsLoading(false);
                              }}
                              onTimeUpdate={onTimeUpdate}
                              onEnded={onVideoEnded}
                              autoPlay={autoplay}
                              onClick={handleVideoClick}
                            >
                              <source
                                src={videoData[currentVideoIndex]}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                          <div className="sound-control">
                            <div>
                              {showVolumeControls && (
                                <div className=" justify-content-center top-25 ">
                                  <div>
                                    <img src={high} />
                                  </div>
                                  <div className="volume-control">
                                    {/* <div className="slider-container"> */}
                                    <input
                                      className="soundediv win10-thumb"
                                      type="range"
                                      defaultValue={volume}
                                      min={0}
                                      max={100}
                                      step={10}
                                      sliderRef={sliderRef}
                                      onChange={handleVolumeChange}
                                    />
                                    {/* <div
                                      className="thumb-image "
                                      ref={thumbImageRef}
                                    >
                                      <img src={volumeunions} />
                                    </div> */}
                                    {/*</div>  */}
                                  </div>
                                  <div>
                                    <img src={mute} />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {isMiniScreen ? (
                            ""
                          ) : (
                            <div className="position-absolute d-inline-table top-0 bottom-0 start-0 end-0 m-auto">
                              <div onClick={handlePlayPause}>
                                <div>
                                  {isPlaying ? (
                                    ""
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="130"
                                      height="130"
                                      viewBox="0 0 130 130"
                                      fill="none"
                                    >
                                      <circle
                                        cx="65"
                                        cy="65"
                                        r="64"
                                        stroke="#F0F4F8"
                                        stroke-width="2"
                                      />
                                      <circle
                                        cx="63.375"
                                        cy="63.375"
                                        r="34.125"
                                        fill="url(#paint0_linear_255_76455)"
                                      />
                                      <path
                                        d="M56.875 55.4821C56.875 53.9385 58.5494 52.9768 59.8827 53.7545L73.4135 61.6474C74.7365 62.4192 74.7365 64.3308 73.4135 65.1026L59.8827 72.9955C58.5494 73.7732 56.875 72.8115 56.875 71.2679V55.4821Z"
                                        fill="#202020"
                                      />
                                      <defs>
                                        <linearGradient
                                          id="paint0_linear_255_76455"
                                          x1="63.375"
                                          y1="16.965"
                                          x2="63.375"
                                          y2="108.42"
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop stop-color="#FACB30" />
                                          <stop
                                            offset="0.302269"
                                            stop-color="#FEF9C8"
                                          />
                                          <stop
                                            offset="0.640625"
                                            stop-color="#C49530"
                                          />
                                          <stop
                                            offset="1"
                                            stop-color="#FFF8C4"
                                          />
                                        </linearGradient>
                                      </defs>
                                    </svg>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          {isMiniScreen ? (
                            ""
                          ) : (
                            <div>
                              {isFullScreen ? (
                                <div className="position-absolute pb-3 d-inline-table  videoControlls bottom-0 start-0 end-0 mx-auto">
                                  {/* <img src={playbar} alt="" /> */}

                                  <div className="  mx-auto mb-3">
                                    <div className=" mx-auto mb-3 progressbar">
                                      <div
                                        className={`${"progressinner"} ${
                                          currentTime !== duration
                                            ? "progress-inner"
                                            : ""
                                        } `}
                                        style={{
                                          animationPlayState: isPlaying
                                            ? "running"
                                            : "paused",
                                          animationDuration: isLoading
                                            ? "0s"
                                            : `${videoRef.current.duration}s`,
                                        }}
                                      />
                                    </div>{" "}
                                  </div>

                                  {/* <Slider
                                  className="slider1"
                                  type="range"
                                  min="0"
                                  max={duration}
                                  step="1"
                                  value={currentTime}
                                  onChange={handleSliderChange}
                                  style={{
                                    animationPlayState: isPlaying
                                      ? "running"
                                      : "paused",
                                    animationDuration: isLoading
                                      ? "0s"
                                      : `${videoRef.current.duration}s`,
                                  }}
                                /> */}

                                  <div className="d-flex playable justify-content-between px-4">
                                    <div className="d-flex">
                                      <Link to="">
                                        <div onClick={handlePlayPause}>
                                          {isPlaying ? (
                                            <img src={vpause} alt="" />
                                          ) : (
                                            <img src={play} alt="" />
                                          )}
                                        </div>
                                      </Link>
                                      <Link to="">
                                        <img
                                          src={forwad}
                                          alt=""
                                          onClick={handleForwardClick}
                                        />
                                      </Link>
                                      <Link to="">
                                        <img
                                          src={sound}
                                          alt=""
                                          onClick={toggleVolumeControls}
                                        />
                                      </Link>

                                      <div className="btnplaytime">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="me-2"
                                          width="18"
                                          height="19"
                                          viewBox="0 0 18 19"
                                          fill="none"
                                        >
                                          <path
                                            d="M9 18.1699C4.0293 18.1699 0 14.1406 0 9.16992C0 4.19922 4.0293 0.169922 9 0.169922C13.9707 0.169922 18 4.19922 18 9.16992C18 14.1406 13.9707 18.1699 9 18.1699ZM9.9 9.16992V4.66992H8.1V10.9699H13.5V9.16992H9.9Z"
                                            fill="#202020"
                                          />
                                        </svg>
                                        {formatTime(currentTime)} /{" "}
                                        {formatTime(duration)}
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <div
                                        className="dropdown mx-3 dropnew dropnew4"
                                        id="dropbtn8"
                                      >
                                        <button
                                          className="p-0 m-0 bg-transparent border-0"
                                          type="button"
                                          id="dropbtn8"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <img src={auto} alt="" />
                                        </button>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end border-0 bg-transparent"
                                          aria-labelledby="dropbtn8"
                                        >
                                          <div className="gradient-box newdrop6">
                                            <li>
                                              <h6 className="m-0 d-flex align-items-center justify-content-center">
                                                Auto Play On
                                                <div className="toggle_btn_wrapper bg w-auto ms-2">
                                                  <div className="form-check form-switch px-2">
                                                    <input
                                                      className="form-check-input d-block float-end"
                                                      id="auto4"
                                                      type="checkbox"
                                                      role="switch"
                                                      onChange={() =>
                                                        setAutoplay(!autoplay)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </h6>
                                            </li>
                                          </div>
                                        </ul>
                                      </div>

                                      <div
                                        className="dropdown mx-3 dropnew dropnew5"
                                        id="dropbtn8"
                                      >
                                        <button
                                          className="p-0 m-0 bg-transparent border-0"
                                          type="button"
                                          id="dropbtn8"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <img src={setting} alt="" />
                                        </button>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end border-0 bg-transparent"
                                          aria-labelledby="dropbtn8"
                                        >
                                          <div className="gradient-box newdrop6">
                                            <div className="position-relative">
                                              <button className="top">
                                                Settings
                                              </button>
                                              <div className="lidiv mt-4">
                                                <img src={icon2} alt="" />
                                                Ambient Mode
                                                <div className="toggle_btn_wrapper bg w-auto ms-auto">
                                                  <div className="form-check form-switch px-0">
                                                    <input
                                                      className="form-check-input d-block float-end"
                                                      id="auto4"
                                                      type="checkbox"
                                                      role="switch"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lidiv">
                                                <img src={icon3} alt="" />
                                                Annotations
                                                <div className="toggle_btn_wrapper bg w-auto ms-auto">
                                                  <div className="form-check form-switch px-0">
                                                    <input
                                                      className="form-check-input d-block float-end"
                                                      id="auto4"
                                                      type="checkbox"
                                                      role="switch"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lidiv">
                                                <img src={icon4} alt="" />
                                                Playback speed
                                                <h6>
                                                  Normal{" "}
                                                  <img
                                                    src={icon9}
                                                    alt=""
                                                    className="me-0"
                                                  />
                                                </h6>
                                              </div>
                                              <div className="lidiv">
                                                <img src={icon5} alt="" />
                                                Substitles/cc (1)
                                                <div className="toggle_btn_wrapper bg w-auto ms-auto">
                                                  <div className="form-check form-switch px-0">
                                                    <input
                                                      className="form-check-input d-block float-end"
                                                      id="auto4"
                                                      type="checkbox"
                                                      role="switch"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lidiv lastdiv">
                                                <img src={icon6} alt="" />
                                                Quality
                                                <h6>
                                                  Auto{" "}
                                                  <img
                                                    src={icon9}
                                                    alt=""
                                                    className="me-0"
                                                  />
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        </ul>
                                      </div>

                                      <Link to="">
                                        <img
                                          src={short}
                                          alt=""
                                          onClick={handleMiniPlayerButtonClick}
                                        />
                                      </Link>

                                      <div className="d-flex">
                                        <Link to="">
                                          <img
                                            src={frame1}
                                            alt=""
                                            onClick={
                                              handleFullScreenButtonClick
                                            }
                                          />
                                        </Link>
                                        <Link to="">
                                          <img
                                            src={full}
                                            alt=""
                                            onClick={
                                              handleFullScreenButtonClick
                                            }
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="position-absolute pb-3 d-inline-table  w-75 bottom-0 start-0 end-0 mx-auto">
                                  {/* <img src={playbar} alt="" /> */}

                                  <div className="  mx-auto mb-3">
                                    <div className=" mx-auto mb-3 progressbar">
                                      <div
                                        className={`${"progressinner"} ${
                                          currentTime !== duration
                                            ? "progress-inner"
                                            : ""
                                        } `}
                                        style={{
                                          animationPlayState: isPlaying
                                            ? "running"
                                            : "paused",
                                          animationDuration: isLoading
                                            ? "0s"
                                            : `${videoRef.current.duration}s`,
                                        }}
                                      />
                                    </div>{" "}
                                  </div>

                                  {/* <Slider
                                  className="slider1"
                                  type="range"
                                  min="0"
                                  max={duration}
                                  step="1"
                                  value={currentTime}
                                  onChange={handleSliderChange}
                                  style={{
                                    animationPlayState: isPlaying
                                      ? "running"
                                      : "paused",
                                    animationDuration: isLoading
                                      ? "0s"
                                      : `${videoRef.current.duration}s`,
                                  }}
                                /> */}

                                  <div className="d-flex playable justify-content-between px-4">
                                    <div className="d-flex">
                                      <Link to="">
                                        <div onClick={handlePlayPause}>
                                          {isPlaying ? (
                                            <img src={vpause} alt="" />
                                          ) : (
                                            <img src={play} alt="" />
                                          )}
                                        </div>
                                      </Link>
                                      <Link to="">
                                        <img
                                          src={forwad}
                                          alt=""
                                          onClick={handleForwardClick}
                                        />
                                      </Link>
                                      <Link to="">
                                        <img
                                          src={sound}
                                          alt=""
                                          onClick={toggleVolumeControls}
                                        />
                                      </Link>

                                      <div className="btnplaytime">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="me-2"
                                          width="18"
                                          height="19"
                                          viewBox="0 0 18 19"
                                          fill="none"
                                        >
                                          <path
                                            d="M9 18.1699C4.0293 18.1699 0 14.1406 0 9.16992C0 4.19922 4.0293 0.169922 9 0.169922C13.9707 0.169922 18 4.19922 18 9.16992C18 14.1406 13.9707 18.1699 9 18.1699ZM9.9 9.16992V4.66992H8.1V10.9699H13.5V9.16992H9.9Z"
                                            fill="#202020"
                                          />
                                        </svg>
                                        {formatTime(currentTime)} /{" "}
                                        {formatTime(duration)}
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <div
                                        className="dropdown mx-3 dropnew dropnew4"
                                        id="dropbtn8"
                                      >
                                        <button
                                          className="p-0 m-0 bg-transparent border-0"
                                          type="button"
                                          id="dropbtn8"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <img src={auto} alt="" />
                                        </button>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end border-0 bg-transparent"
                                          aria-labelledby="dropbtn8"
                                        >
                                          <div className="gradient-box newdrop6">
                                            <li>
                                              <h6 className="m-0 d-flex align-items-center justify-content-center">
                                                Auto Play On
                                                <div className="toggle_btn_wrapper bg w-auto ms-2">
                                                  <div className="form-check form-switch px-2">
                                                    <input
                                                      className="form-check-input d-block float-end"
                                                      id="auto4"
                                                      type="checkbox"
                                                      role="switch"
                                                      onChange={() =>
                                                        setAutoplay(!autoplay)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </h6>
                                            </li>
                                          </div>
                                        </ul>
                                      </div>

                                      <div
                                        className="dropdown mx-3 dropnew dropnew5"
                                        id="dropbtn8"
                                      >
                                        <button
                                          className="p-0 m-0 bg-transparent border-0"
                                          type="button"
                                          id="dropbtn8"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <img src={setting} alt="" />
                                        </button>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end border-0 bg-transparent"
                                          aria-labelledby="dropbtn8"
                                        >
                                          <div className="gradient-box newdrop6">
                                            <div className="position-relative">
                                              <button className="top">
                                                Settings
                                              </button>
                                              <div className="lidiv mt-4">
                                                <img src={icon2} alt="" />
                                                Ambient Mode
                                                <div className="toggle_btn_wrapper bg w-auto ms-auto">
                                                  <div className="form-check form-switch px-0">
                                                    <input
                                                      className="form-check-input d-block float-end"
                                                      id="auto4"
                                                      type="checkbox"
                                                      role="switch"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lidiv">
                                                <img src={icon3} alt="" />
                                                Annotations
                                                <div className="toggle_btn_wrapper bg w-auto ms-auto">
                                                  <div className="form-check form-switch px-0">
                                                    <input
                                                      className="form-check-input d-block float-end"
                                                      id="auto4"
                                                      type="checkbox"
                                                      role="switch"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lidiv">
                                                <img src={icon4} alt="" />
                                                Playback speed
                                                <h6>
                                                  Normal{" "}
                                                  <img
                                                    src={icon9}
                                                    alt=""
                                                    className="me-0"
                                                  />
                                                </h6>
                                              </div>
                                              <div className="lidiv">
                                                <img src={icon5} alt="" />
                                                Substitles/cc (1)
                                                <div className="toggle_btn_wrapper bg w-auto ms-auto">
                                                  <div className="form-check form-switch px-0">
                                                    <input
                                                      className="form-check-input d-block float-end"
                                                      id="auto4"
                                                      type="checkbox"
                                                      role="switch"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lidiv lastdiv">
                                                <img src={icon6} alt="" />
                                                Quality
                                                <h6>
                                                  Auto{" "}
                                                  <img
                                                    src={icon9}
                                                    alt=""
                                                    className="me-0"
                                                  />
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        </ul>
                                      </div>

                                      <Link to="">
                                        <img
                                          src={short}
                                          alt=""
                                          onClick={handleMiniPlayerButtonClick}
                                        />
                                      </Link>

                                      <div>
                                        <Link to="">
                                          <img
                                            src={frame1}
                                            alt=""
                                            onClick={
                                              handleFullScreenButtonClick
                                            }
                                          />
                                        </Link>
                                        <Link to="">
                                          <img
                                            src={full}
                                            alt=""
                                            onClick={
                                              handleFullScreenButtonClick
                                            }
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {isMiniScreen ? (
                          " "
                        ) : (
                          <div className="righttool end-0 top-0 bottom-0 my-auto d-flex flex-column justify-content-center">
                            <div className="iconaudio">
                              <img src={img11s} alt="" />
                              <div className="livebtn d-flex shadow-5in bg">
                                <div className="active">Now</div>
                                <div>Now</div>
                              </div>
                            </div>
                            <div className="mb-auto">
                              {isFullScreen ? (
                                <Link to="" onClick={handleTheaterButtonClick}>
                                  <img src={closebtn} alt="" />
                                </Link>
                              ) : (
                                <Link to="" onClick={() => setHandleView("")}>
                                  <img src={closebtn} alt="" />
                                </Link>
                              )}
                            </div>
                            <div className="mb-2">
                              <Link to="">
                                <img src={img6} alt="" /> 43
                              </Link>
                            </div>
                            <div className="mb-2">
                              <Link to="">
                                <img src={img7} alt="" /> 42.5k
                              </Link>
                            </div>
                            <div className="mb-3">
                              <Link to="">
                                <img src={img8} alt="" /> 587
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="audioBtm bg ">
                      <div className="d-flex align-items-center">
                        <div className="listenLive1 pe-3">
                          <div className="icontb shadow-5in bg">
                            <img src={img12s} alt="" />
                          </div>
                          <span>
                            <h3>LISTEN LIVE</h3>
                            <h4>ALL TIPS & TRICKS</h4>
                          </span>
                        </div>
                        <div className="liststyle text-center">
                          HOW TO FINALLY QUIT YOUR 9 TO 5 JOB IN 4-HOURS
                        </div>
                      </div>
                    </div>

                    <div className="customSec bg">
                      <div className="d-flex align-items-center">
                        <div className="col-lg-5">
                          <div className="listbtn d-flex">
                            <div className="listfriest1 d-flex">
                              <img src={imguser} alt="" />
                              <div>
                                <h3>Dyna appolon</h3>
                                <h4>100k Subscribers</h4>
                              </div>
                            </div>
                            <button
                              className="border-0 bg-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#joinsec"
                            >
                              Join
                            </button>
                            <button className="border-0 bg-dark text-white">
                              Subscribe
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="w-100 d-flex btnarea justify-content-center">
                            <button className="d-flex justify-content-between align-items-center">
                              <div
                                className="dropdown dropnew dropnew3"
                                id="dropbtn1"
                              >
                                <button
                                  className="p-0 m-0 "
                                  type="button"
                                  id="dropbtn1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="me-2"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M1.14844 7.49976H4.89779V16.4982H1.14844V7.49976Z"
                                      stroke="#202020"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      d="M17.2038 8.25005C17.2038 7.85229 17.0458 7.47083 16.7645 7.18957C16.4833 6.90832 16.1018 6.75031 15.7041 6.75031H10.9649L11.6848 3.3234C11.6998 3.24842 11.7073 3.16593 11.7073 3.08345C11.7073 2.776 11.5798 2.49105 11.3773 2.28858L10.5825 1.50122L4.89844 7.50018V16.4986H13.4545C14.0768 16.4986 14.6093 16.1237 14.8342 15.5838L17.0988 10.2972C17.1663 10.1247 17.2038 9.94476 17.2038 9.74979V8.25005Z"
                                      stroke="#202020"
                                      stroke-width="1.5"
                                    />
                                  </svg>
                                </button>
                                <ul
                                  className="dropdown-menu dropdown-menu-end border-0 bg-transparent"
                                  aria-labelledby="dropbtn1"
                                >
                                  <div className="gradient-box newdrop6">
                                    <li>
                                      <Link to="">I Like This</Link>
                                    </li>
                                  </div>
                                </ul>
                              </div>
                              Like |
                              <div
                                className="dropdown dropnew dropnew3"
                                id="dropbtn8"
                              >
                                <button
                                  className="p-0 m-0 "
                                  type="button"
                                  id="dropbtn8"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ms-2"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M17.2031 10.4985L13.4538 10.4985L13.4538 1.5001L17.2031 1.5001L17.2031 10.4985Z"
                                      stroke="#202020"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      d="M1.14776 9.75117C1.14776 10.1489 1.30577 10.5304 1.58702 10.8116C1.86828 11.0929 2.24974 11.2509 2.6475 11.2509L7.38668 11.2509L6.6668 14.6778C6.6518 14.7528 6.64431 14.8353 6.64431 14.9178C6.64431 15.2252 6.77178 15.5102 6.97425 15.7126L7.76911 16.5L13.4531 10.501L13.4531 1.5026L4.89711 1.5026C4.27472 1.5026 3.74231 1.87754 3.51735 2.41745L1.25274 7.70403C1.18525 7.8765 1.14776 8.05647 1.14776 8.25143L1.14776 9.75117Z"
                                      stroke="#202020"
                                      stroke-width="1.5"
                                    />
                                  </svg>
                                </button>
                                <ul
                                  className="dropdown-menu dropdown-menu-end border-0 bg-transparent"
                                  aria-labelledby="dropbtn8"
                                >
                                  <div className="gradient-box newdrop6">
                                    <li>
                                      <Link to="">I Dislike This</Link>
                                    </li>
                                  </div>
                                </ul>
                              </div>
                            </button>
                            <button
                              data-bs-toggle="modal"
                              data-bs-target="#sharesec"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="me-2"
                                width="18"
                                height="20"
                                viewBox="0 0 18 20"
                                fill="none"
                              >
                                <path
                                  d="M14.5255 19.9998C13.5236 20.0122 12.5739 19.5193 11.959 18.6676C11.3441 17.8159 11.1346 16.7035 11.393 15.6612L5.59663 12.0942C4.59502 13.0822 3.12684 13.2913 1.92108 12.6177C0.715326 11.9441 0.0288155 10.5313 0.202424 9.08073C0.376032 7.63019 1.37278 6.4509 2.69776 6.1284C4.02273 5.80591 5.39371 6.40891 6.12997 7.63799L11.3921 4.399C11.324 4.12205 11.2879 3.8371 11.2847 3.55063C11.2714 1.8825 12.3475 0.433407 13.8618 0.0801794C15.3761 -0.273049 16.9127 0.566597 17.5419 2.09106C18.1711 3.61552 17.72 5.40612 16.4616 6.37939C15.2032 7.35266 13.4831 7.24131 12.3421 6.11271L6.64665 9.61687C6.64101 9.87539 6.60745 10.1323 6.54664 10.3825L12.3421 13.9485C13.409 12.8943 14.9966 12.7316 16.2291 13.55C17.4617 14.3685 18.0391 15.969 17.643 17.4686C17.2469 18.9683 15.9736 20.0021 14.5255 19.9998ZM14.5255 15.0152C13.7584 15.0152 13.1366 15.6847 13.1366 16.5106C13.1366 17.3364 13.7584 18.0059 14.5255 18.0059C15.2926 18.0059 15.9144 17.3364 15.9144 16.5106C15.9144 15.6847 15.2926 15.0152 14.5255 15.0152ZM3.41418 8.03676C2.6471 8.03676 2.02526 8.70626 2.02526 9.53213C2.02526 10.358 2.6471 11.0275 3.41418 11.0275C4.18126 11.0275 4.80309 10.358 4.80309 9.53213C4.80309 8.70626 4.18126 8.03676 3.41418 8.03676ZM14.5255 2.05525C13.7584 2.05525 13.1366 2.72475 13.1366 3.55063C13.1366 4.3765 13.7584 5.046 14.5255 5.046C15.2926 5.046 15.9144 4.3765 15.9144 3.55063C15.9144 2.72475 15.2926 2.05525 14.5255 2.05525Z"
                                  fill="#101010"
                                />
                              </svg>
                              Share
                            </button>
                            <button
                              data-bs-toggle="modal"
                              data-bs-target="#downloadsec"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="me-2"
                                width="16"
                                height="18"
                                viewBox="0 0 16 18"
                                fill="none"
                              >
                                <path
                                  d="M1.92969 17H13.7192M13.7192 7.73684H10.3507V1H5.29811V7.73684H1.92969L7.82442 13.6316L13.7192 7.73684Z"
                                  stroke="#202020"
                                  stroke-width="1.5"
                                />
                              </svg>
                              Download
                            </button>
                            <button
                              data-bs-toggle="modal"
                              data-bs-target="#thanksec"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="me-2"
                                width="18"
                                height="16"
                                viewBox="0 0 18 16"
                                fill="none"
                              >
                                <path
                                  d="M8.82482 15.2144C8.89057 15.1666 8.98006 15.0996 9.09062 15.0141C9.32421 14.8334 9.64457 14.5757 10.0221 14.2536C10.7779 13.6085 11.7542 12.7122 12.718 11.6644C13.684 10.6143 14.6228 9.42793 15.3166 8.20356C16.0137 6.97355 16.4346 5.75589 16.4348 4.62869L8.82482 15.2144ZM8.82482 15.2144C8.75907 15.1666 8.66958 15.0996 8.55902 15.0141C8.32543 14.8334 8.00507 14.5757 7.62757 14.2536C6.87178 13.6085 5.89548 12.7122 4.93164 11.6644C3.96567 10.6143 3.02684 9.42793 2.333 8.20356C1.63587 6.97339 1.21495 5.75558 1.21484 4.62827C1.21611 3.60008 1.62512 2.61437 2.35217 1.88732C3.07921 1.16028 4.06493 0.75127 5.09311 0.75C6.41512 0.750121 7.53533 1.31492 8.22506 2.23353L8.82482 3.03233L9.42458 2.23353C10.1143 1.31492 11.2345 0.750121 12.5565 0.75C13.5847 0.75127 14.5704 1.16028 15.2975 1.88732C16.0246 2.61445 16.4336 3.60031 16.4348 4.62863L8.82482 15.2144Z"
                                  stroke="#202020"
                                  stroke-width="1.5"
                                />
                                <path
                                  d="M10.6835 6.10739H11.6124C11.6124 4.658 10.3328 3.99691 9.29016 3.84467V2.78662H8.36128V3.84467C7.3186 3.99691 6.03906 4.658 6.03906 6.10739C6.03906 7.48985 7.27727 8.20867 8.36128 8.37062V10.9097C7.68876 10.7815 6.96795 10.3866 6.96795 9.6836H6.03906C6.03906 11.0063 7.16534 11.7879 8.36128 11.9499V13.0044H9.29016V11.9468C10.3328 11.7946 11.6124 11.133 11.6124 9.6836C11.6124 8.23421 10.3328 7.57312 9.29016 7.42088V4.88126C9.90787 5.00336 10.6835 5.362 10.6835 6.10739ZM6.96795 6.10739C6.96795 5.362 7.74357 5.00336 8.36128 4.88126V7.33301C7.72453 7.20375 6.96795 6.8211 6.96795 6.10739ZM10.6835 9.6836C10.6835 10.429 9.90787 10.7876 9.29016 10.9097V8.45747C9.90787 8.57957 10.6835 8.93821 10.6835 9.6836Z"
                                  fill="#202020"
                                />
                              </svg>
                              Thanks
                            </button>
                            <div className="dropdown dropnew2" id="dropbtn1">
                              <button
                                className=""
                                type="button"
                                id="dropbtn1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="me-2"
                                  width="17"
                                  height="18"
                                  viewBox="0 0 17 18"
                                  fill="none"
                                >
                                  <path
                                    d="M8.93359 7.125L7.77637 5.96777C7.91992 5.59863 7.99609 5.2002 7.99609 4.78125C7.99609 2.96777 6.52832 1.5 4.71484 1.5C2.90137 1.5 1.43359 2.96777 1.43359 4.78125C1.43359 6.59473 2.90137 8.0625 4.71484 8.0625C5.13379 8.0625 5.53223 7.9834 5.90137 7.84277L7.05859 9L5.90137 10.1572C5.53223 10.0137 5.13379 9.9375 4.71484 9.9375C2.90137 9.9375 1.43359 11.4053 1.43359 13.2187C1.43359 15.0322 2.90137 16.5 4.71484 16.5C6.52832 16.5 7.99609 15.0322 7.99609 13.2187C7.99609 12.7998 7.91699 12.4014 7.77637 12.0322L16.0586 3.75C16.2666 3.54199 16.2666 3.20801 16.0586 3C15.2295 2.1709 13.8877 2.1709 13.0586 3L8.93359 7.125ZM9.5957 11.5371L13.0586 15C13.8877 15.8291 15.2295 15.8291 16.0586 15C16.2666 14.792 16.2666 14.458 16.0586 14.25L11.4707 9.66211L9.5957 11.5371ZM3.30859 4.78125C3.30859 4.01654 3.95758 3.375 4.71484 3.375C5.47956 3.375 6.12109 4.02399 6.12109 4.78125C6.12109 5.54596 5.47211 6.1875 4.71484 6.1875C3.95013 6.1875 3.30859 5.53851 3.30859 4.78125ZM4.71484 11.8125C4.89952 11.8125 5.08238 11.8489 5.25299 11.9195C5.42361 11.9902 5.57863 12.0938 5.70921 12.2244C5.8398 12.355 5.94338 12.51 6.01405 12.6806C6.08472 12.8512 6.12109 13.0341 6.12109 13.2187C6.12109 13.4034 6.08472 13.5863 6.01405 13.7569C5.94338 13.9275 5.8398 14.0825 5.70921 14.2131C5.57863 14.3437 5.42361 14.4473 5.25299 14.518C5.08238 14.5886 4.89952 14.625 4.71484 14.625C4.53017 14.625 4.34731 14.5886 4.1767 14.518C4.00608 14.4473 3.85106 14.3437 3.72048 14.2131C3.58989 14.0825 3.48631 13.9275 3.41564 13.7569C3.34497 13.5863 3.30859 13.4034 3.30859 13.2187C3.30859 13.0341 3.34497 12.8512 3.41564 12.6806C3.48631 12.51 3.58989 12.355 3.72048 12.2244C3.85106 12.0938 4.00608 11.9902 4.1767 11.9195C4.34731 11.8489 4.53017 11.8125 4.71484 11.8125Z"
                                    stroke="#202020"
                                    stroke-width="1.5"
                                  />
                                </svg>
                                Clips
                              </button>
                              <ul
                                className="dropdown-menu dropdown-menu-end border-0 bg-transparent"
                                aria-labelledby="dropbtn1"
                              >
                                <div className="gradient-box newdrop7">
                                  <div className="d-flex align-items-center justify-content-center">
                                    <h6 className="labeldiv">0:12.6</h6>
                                    <h6 className="mx-4">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="2"
                                        viewBox="0 0 10 2"
                                        fill="none"
                                      >
                                        <path
                                          d="M9.62092 0.0981445V1.90212H0.380859V0.0981445H9.62092Z"
                                          fill="#202020"
                                        />
                                      </svg>
                                    </h6>
                                    <h6 className="labeldiv">0:27.6</h6>
                                  </div>
                                  <div className="clipdiv">
                                    <div className="d-flex">
                                      <h5>0:00</h5>
                                      <h5>0:30</h5>
                                      <h5>1:00</h5>
                                      <h5>1:30</h5>
                                    </div>
                                    <div className="clipinternal d-flex shadow-5in">
                                      <img src={imgvid} alt="" />
                                      <img src={imgvid} alt="" />
                                      <img src={imgvid} alt="" />
                                      <img src={imgvid} alt="" />
                                    </div>
                                  </div>
                                  <h4>15.0 seconds</h4>
                                  <div className="d-flex justify-content-end">
                                    <button
                                      className="me-3 bg shadow-4 border-0"
                                      data-bs-toggle="modal"
                                      data-bs-target="#discardsec"
                                    >
                                      Cancel
                                    </button>
                                    <button className="bg-primary shadow-4 border-0">
                                      Share clip
                                    </button>
                                  </div>
                                </div>
                              </ul>
                            </div>
                            <div className="dropdown dropnew" id="dropbtn">
                              <button
                                className=""
                                type="button"
                                id="dropbtn"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                ...
                              </button>
                              <ul
                                className="dropdown-menu dropdown-menu-end border-0 bg-transparent"
                                aria-labelledby="dropbtn"
                              >
                                <div className="gradient-box newdrop6">
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#savesec"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="me-2"
                                        width="24"
                                        height="12"
                                        viewBox="0 0 24 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M0.25 1H16.25"
                                          stroke="#202020"
                                          stroke-width="1.5"
                                        />
                                        <path
                                          d="M0.25 6H11.25"
                                          stroke="#202020"
                                          stroke-width="1.5"
                                        />
                                        <path
                                          d="M0.25 11H11.25"
                                          stroke="#202020"
                                          stroke-width="1.5"
                                        />
                                        <path
                                          d="M13.75 6H23.75"
                                          stroke="#202020"
                                          stroke-width="1.5"
                                        />
                                        <path
                                          d="M18.75 11L18.75 1"
                                          stroke="#202020"
                                          stroke-width="1.5"
                                        />
                                      </svg>
                                      Save
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#reportsec"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="me-2"
                                        width="16"
                                        height="21"
                                        viewBox="0 0 16 21"
                                        fill="none"
                                      >
                                        <path
                                          d="M1.5 11.5H7.1L7.34 12.7C7.43 13.17 7.84 13.5 8.32 13.5H13.5C14.05 13.5 14.5 13.05 14.5 12.5V4.5C14.5 3.95 14.05 3.5 13.5 3.5H8.9L8.66 2.3C8.57 1.84 8.16 1.5 7.68 1.5H1.5V20.5"
                                          stroke="#202020"
                                          stroke-width="1.5"
                                        />
                                      </svg>
                                      Report
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#transcriptsec"
                                    >
                                      <svg
                                        width="22"
                                        height="16"
                                        className="me-2"
                                        viewBox="0 0 22 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.5 5.75H3.5"
                                          stroke="#202020"
                                          stroke-width="1.5"
                                        />
                                        <path
                                          d="M18.5 5.75H10.5"
                                          stroke="#202020"
                                          stroke-width="1.5"
                                        />
                                        <path
                                          d="M11.5 10.25H3.5"
                                          stroke="#202020"
                                          stroke-width="1.5"
                                        />
                                        <path
                                          d="M18.5 10.25H14.5"
                                          stroke="#202020"
                                          stroke-width="1.5"
                                        />
                                        <rect
                                          x="0.75"
                                          y="0.75"
                                          width="20.5"
                                          height="14.5"
                                          stroke="#202020"
                                          stroke-width="1.5"
                                        />
                                      </svg>
                                      Show transcript
                                    </Link>
                                  </li>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <img src={bgimg} alt="" className="bg-contain imgtop" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="vidsidebr">
                <div className="videolay">
                  {videoData.map((video, index) => (
                    <div
                      key={index}
                      className={`vidlayer ${
                        currentVideoIndex === index ? "active" : ""
                      }`}
                      onClick={() => handlePlaylistClick(index)}
                    >
                      <Link>
                        <img src={img20} alt="" />
                      </Link>
                      {`Video ${index + 1}`}
                    </div>
                  ))}
                  <div className="vidlayer">
                    <Link>
                      <img src={img21} alt="" />
                    </Link>
                  </div>
                  <div className="vidlayer">
                    <Link>
                      <img src={img21} alt="" />
                    </Link>
                  </div>
                  <div className="vidlayer">
                    <Link>
                      <img src={img21} alt="" />
                    </Link>
                  </div>
                  <div className="vidlayer">
                    <Link>
                      <img src={img21} alt="" />
                    </Link>
                  </div>
                  <div className="vidlayer">
                    <Link>
                      <img src={img21} alt="" />
                    </Link>
                  </div>
                  <div className="vidlayer">
                    <Link>
                      <img src={img21} alt="" />
                    </Link>
                  </div>
                  <div className="vidlayer">
                    <Link>
                      <img src={img21} alt="" />
                    </Link>
                  </div>
                  <div className="vidlayer">
                    <Link>
                      <img src={img21} alt="" />
                    </Link>
                  </div>
                  <div className="vidlayer">
                    <Link>
                      <img src={img21} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal"
        id="savesec"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog my-0 custompopup h-100 d-flex text-start">
          <div className="modal-content gradient-box bg position-relative">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>
            <div className="modal-body border-0 pb-0">
              <h6>
                <Save /> Save to
              </h6>
              <ul>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="watchk"
                      id="watchk"
                      autoComplete="off"
                    />
                    <label className="btn btnRadio large me-1" htmlFor="watchk">
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Watch Later
                  <Lock />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="boss"
                      id="boss"
                      autoComplete="off"
                    />
                    <label className="btn btnRadio large me-1" htmlFor="boss">
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  How does dive boss work
                  <Links />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="expensive"
                      id="expensive"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="expensive"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  How to look expensive and stylish tips & hacks runaway fashion
                  <Links />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="language"
                      id="language"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="language"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Creole language videos: Life style
                  <Links />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="development"
                      id="development"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="development"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Self development
                  <Links />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="Creole"
                      id="Creole"
                      autoComplete="off"
                    />
                    <label className="btn btnRadio large me-1" htmlFor="Creole">
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Creole language videos: Life style
                  <Links />
                </li>
              </ul>
              <button>Create new playlist</button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal"
        id="reportsec"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mb-0 mt-5 custompopup0 custompopup1 h-100 d-flex text-start">
          <div className="modal-content gradient-box bg position-relative">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>
            <div className="modal-body border-0 pb-0">
              <button className="top">Report Video</button>
              <ul>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="content"
                      id="content"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="content"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Sexual content
                  <Mark />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="repulsive"
                      id="repulsive"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="repulsive"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Violent or repulsive content
                  <Mark />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="abusive"
                      id="abusive"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="abusive"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Hateful or abusive content
                  <Mark />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="bullying"
                      id="bullying"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="bullying"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Harassment or bullying
                  <Mark />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="dangerous"
                      id="dangerous"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="dangerous"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Harmful or dangerous acts
                  <Mark />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="misinformation"
                      id="misinformation"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="misinformation"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Misinformation
                  <Mark />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="abuse"
                      id="abuse"
                      autoComplete="off"
                    />
                    <label className="btn btnRadio large me-1" htmlFor="abuse">
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Child abuse
                  <Mark />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="terrorism"
                      id="terrorism"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="terrorism"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Promotes terrorism
                  <Mark />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="misleading"
                      id="misleading"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="misleading"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Spam or misleading
                  <Mark />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="rights"
                      id="rights"
                      autoComplete="off"
                    />
                    <label className="btn btnRadio large me-1" htmlFor="rights">
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Infringes my rights
                  <Mark />
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="issue"
                      id="issue"
                      autoComplete="off"
                    />
                    <label className="btn btnRadio large me-1" htmlFor="issue">
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Captions issue
                  <Mark />
                </li>
              </ul>
              <p>
                Flagged videos and users are reviewed by YouTube staff 24 hours
                a day, 7 days a week to determine whether they violate Community
                Guidelines. Accounts are penalized for Community Guidelines
                violations, and serious or repeated violations can lead to
                account termination. <br />
                <Link to="">Report channel</Link>
              </p>
              <button className="bottom border-0">Report Video</button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal"
        id="transcriptsec"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mb-0 mt-5 custompopup0 custompopup2 h-100 d-flex text-start">
          <div className="modal-content gradient-box bg position-relative">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>
            <Link className="dotbtn">
              <Doted />
            </Link>
            <div className="modal-body border-0 pb-0">
              <button className="top">Transcript</button>
              <ul>
                <li>
                  <span>0:00</span>
                  <Link to="">
                    Lorem ipsum dolor sit amet consectetur. Suspendisse aliquam
                    gravida faucibus a enim lorem urna.
                  </Link>
                </li>
                <li>
                  <span>0:05</span>
                  <Link to="">
                    Lorem ipsum dolor sit amet consectetur. Suspendisse aliquam
                    gravida faucibus a enim lorem urna.
                  </Link>
                </li>
                <li>
                  <span>0:12</span>
                  <Link to="">
                    Lorem ipsum dolor sit amet consectetur. Suspendisse aliquam
                    gravida faucibus a enim lorem urna.
                  </Link>
                </li>
                <li>
                  <span>0:19</span>
                  <Link to="">
                    Lorem ipsum dolor sit amet consectetur. Suspendisse aliquam
                    gravida faucibus a enim lorem urna.
                  </Link>
                </li>
                <li>
                  <span>0:24</span>
                  <Link to="">
                    Lorem ipsum dolor sit amet consectetur. Suspendisse aliquam
                    gravida faucibus a enim lorem urna.
                  </Link>
                </li>
                <li>
                  <span>0:29</span>
                  <Link to="">
                    Lorem ipsum dolor sit amet consectetur. Suspendisse aliquam
                    gravida faucibus a enim lorem urna.
                  </Link>
                </li>
                <li>
                  <span>0:35</span>
                  <Link to="">
                    Lorem ipsum dolor sit amet consectetur. Suspendisse aliquam
                    gravida faucibus a enim lorem urna.
                  </Link>
                </li>
                <li>
                  <span>0:43</span>
                  <Link to="">
                    Lorem ipsum dolor sit amet consectetur. Suspendisse aliquam
                    gravida faucibus a enim lorem urna.
                  </Link>
                </li>
                <li>
                  <span>0:50</span>
                  <Link to="">
                    Lorem ipsum dolor sit amet consectetur. Suspendisse aliquam
                    gravida faucibus a enim lorem urna.
                  </Link>
                </li>
                <li>
                  <span>1:02</span>
                  <Link to="">
                    Lorem ipsum dolor sit amet consectetur. Suspendisse aliquam
                    gravida faucibus a enim lorem urna.
                  </Link>
                </li>
              </ul>
              <p>English (auto generated)</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal"
        id="downloadsec"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mb-0 mt-5 custompopup0 custompopup3 h-100 d-flex text-start">
          <div className="modal-content gradient-box bg position-relative">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>
            <div className="modal-body border-0 pb-0">
              <button className="top">Download</button>
              <h3>Download this video with premium</h3>
              <h4>Plus, enjoy ad-free videos, offline and in the background</h4>
              <ul className="d-flex my-4">
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="content"
                      id="content6"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="content6"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Full HD (1080)
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="content"
                      id="content7"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="content7"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  High (720)
                </li>
                <li>
                  <span className="me-0 d-flex">
                    <input
                      type="radio"
                      className="btn-check"
                      name="content"
                      id="content8"
                      autoComplete="off"
                    />
                    <label
                      className="btn btnRadio large me-1"
                      htmlFor="content8"
                    >
                      <div className="radioInner">
                        <span></span>
                      </div>
                    </label>
                  </span>
                  Standard (480)
                </li>
              </ul>
              <h5>
                By tapping 'Get YouTube Premium', you are agreeing to these
                terms. See the privacy statement and restrictions.
              </h5>
              <div className="d-flex justify-content-center">
                <button className="btn-bottom bg me-2">Not now</button>
                <button className="btn-bottom bg-primary">Get Premium</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal"
        id="sharesec"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mb-0 mt-5 custompopup0 custompopup4 h-100 d-flex text-start">
          <div className="modal-content gradient-box bg position-relative">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>
            <div className="modal-body border-0 pb-0">
              <button className="top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                >
                  <path
                    d="M14.3497 19.9998C13.3478 20.0122 12.3982 19.5193 11.7832 18.6676C11.1683 17.8159 10.9589 16.7035 11.2172 15.6612L5.42085 12.0942C4.41924 13.0822 2.95106 13.2913 1.7453 12.6177C0.539545 11.9441 -0.146966 10.5313 0.0266424 9.08073C0.200251 7.63019 1.197 6.4509 2.52197 6.1284C3.84695 5.80591 5.21793 6.40891 5.95419 7.63799L11.2163 4.399C11.1482 4.12205 11.1122 3.8371 11.1089 3.55063C11.0956 1.8825 12.1717 0.433407 13.686 0.0801794C15.2003 -0.273049 16.7369 0.566597 17.3661 2.09106C17.9953 3.61552 17.5442 5.40612 16.2858 6.37939C15.0274 7.35266 13.3073 7.24131 12.1663 6.11271L6.47086 9.61687C6.46522 9.87539 6.43166 10.1323 6.37086 10.3825L12.1663 13.9485C13.2333 12.8943 14.8208 12.7316 16.0533 13.55C17.2859 14.3685 17.8633 15.969 17.4672 17.4686C17.0711 18.9683 15.7978 20.0021 14.3497 19.9998ZM14.3497 15.0152C13.5826 15.0152 12.9608 15.6847 12.9608 16.5106C12.9608 17.3364 13.5826 18.0059 14.3497 18.0059C15.1168 18.0059 15.7386 17.3364 15.7386 16.5106C15.7386 15.6847 15.1168 15.0152 14.3497 15.0152ZM3.2384 8.03676C2.47132 8.03676 1.84948 8.70626 1.84948 9.53213C1.84948 10.358 2.47132 11.0275 3.2384 11.0275C4.00547 11.0275 4.62731 10.358 4.62731 9.53213C4.62731 8.70626 4.00547 8.03676 3.2384 8.03676ZM14.3497 2.05525C13.5826 2.05525 12.9608 2.72475 12.9608 3.55063C12.9608 4.3765 13.5826 5.046 14.3497 5.046C15.1168 5.046 15.7386 4.3765 15.7386 3.55063C15.7386 2.72475 15.1168 2.05525 14.3497 2.05525Z"
                    fill="#101010"
                  />
                </svg>
                Share
              </button>
              <div className="d-flex align-items-center">
                <h3>Share in a post:</h3>
                <button
                  className="btn-dark mx-3"
                  data-bs-toggle="modal"
                  data-bs-target="#postsec"
                >
                  Create post
                </button>
                <h4>86 subscribers</h4>
              </div>
              <h5 className="text-start mt-3">Share</h5>
              <ul className="d-flex my-3">
                <li>
                  <img src={embed} alt="" />
                  <Link to="">Embed</Link>
                </li>
                <li>
                  <img src={whats} alt="" />
                  <Link to="">WhatsApp</Link>
                </li>
                <li>
                  <img src={fb1} alt="" />
                  <Link to="">Facebook</Link>
                </li>
                <li>
                  <img src={twitter} alt="" />
                  <Link to="">Twitter</Link>
                </li>
                <li>
                  <img src={insta} alt="" />
                  <Link to="">Instagram</Link>
                </li>
                <li>
                  <img src={other} alt="" />
                  <Link to="">Email</Link>
                </li>
              </ul>
              <div className="mt-4 pt-2 d-flex">
                <input
                  type="text"
                  placeholder="https://youtu.be/zRqnMi6gWO4"
                  className="br-30 shadow-5in"
                />
                <button className="br-30 border-0 bg-primary btn-bottom">
                  Copy
                </button>
              </div>
              <hr />
              <div className="d-flex align-items-center">
                <input type="checkbox" />
                <h6 className="mx-2 my-0">Start at</h6>
                <small>0:12</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal"
        id="postsec"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mb-0 mt-5 custompopup0 custompopup5 h-100 d-flex text-start">
          <div className="modal-content gradient-box bg position-relative">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>
            <div className="modal-body border-0 pb-0">
              <button className="top">Create post</button>
              <div className="mt-4 pt-4 d-flex">
                <input
                  type="text"
                  placeholder="Post something publicly (type @ to mention a channel)"
                  className="br-30 shadow-5in"
                />
              </div>
              <div className="row my-4">
                <div className="col-lg-4">
                  <img src={frame} alt="" />
                </div>
                <div className="col-lg-8">
                  <h3>Lorem ipsum dolor sit amet consectetur. Placerat.</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Suspendisse habitant
                    viverra sagittis consectetur bibendum. Ultricies pretium
                    nullam mi ut platea. Imperdiet mattis fringilla at arcu
                    sapien arcu tempus.
                  </p>
                </div>
              </div>
              <hr className="mb-0" />
              <div className="d-flex justify-content-end align-items-end">
                <button className="bg br-30 btn-bottom me-5">Post</button>
                <span className="text-center ms-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="36"
                    viewBox="0 0 50 36"
                    fill="none"
                  >
                    <g filter="url(#filter0_dd_114_113183)">
                      <path
                        d="M25 26L10 10L40 10L25 26Z"
                        fill="url(#paint0_linear_114_113183)"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_dd_114_113183"
                        x="0"
                        y="0"
                        width="50"
                        height="36"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="2" dy="2" />
                        <feGaussianBlur stdDeviation="4" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.698039 0 0 0 0 0.741176 0 0 0 0 0.796078 0 0 0 1 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_114_113183"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="-2" dy="-2" />
                        <feGaussianBlur stdDeviation="4" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="effect1_dropShadow_114_113183"
                          result="effect2_dropShadow_114_113183"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect2_dropShadow_114_113183"
                          result="shape"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_114_113183"
                        x1="4.6"
                        y1="18"
                        x2="44.8"
                        y2="18"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#FACB30" />
                        <stop offset="0.302269" stop-color="#FEF9C8" />
                        <stop offset="0.640625" stop-color="#C49530" />
                        <stop offset="1" stop-color="#FFF8C4" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <button className="bg-primary btn-bottom">
                    New! Create a Quiz
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal"
        id="thanksec"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mb-0 my-5 custompopup0 custompopup6 h-100 d-flex text-start">
          <div className="modal-content gradient-box bg position-relative">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>
            <div className="modal-body border-0 pb-0">
              <button className="top">Thanks 9 TO 5 ESCAPEE</button>
              <div className="position-relative text-center">
                <Link
                  to=""
                  className="position-absolute top-0 end-0"
                  data-bs-toggle="modal"
                  data-bs-target="#supersec"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M8.39427 12.5174V12.4086C8.40191 11.6983 8.47255 11.1332 8.60621 10.7131C8.74368 10.2931 8.93842 9.95322 9.19045 9.69356C9.44248 9.43389 9.74606 9.19714 10.1012 8.98329C10.3303 8.83819 10.5365 8.6759 10.7198 8.49642C10.9031 8.31695 11.0482 8.11074 11.1551 7.8778C11.2621 7.64487 11.3155 7.38711 11.3155 7.10454C11.3155 6.76468 11.2353 6.47064 11.0749 6.22243C10.9146 5.97422 10.7007 5.78329 10.4334 5.64964C10.1699 5.51217 9.8759 5.44344 9.55131 5.44344C9.25728 5.44344 8.97661 5.50453 8.70931 5.62673C8.442 5.74893 8.22053 5.93986 8.04487 6.19952C7.86921 6.45537 7.76802 6.78568 7.74129 7.19045H6C6.02673 6.5031 6.20048 5.92267 6.52124 5.44916C6.842 4.97184 7.26587 4.61098 7.79284 4.36659C8.32363 4.1222 8.90979 4 9.55131 4C10.2539 4 10.8687 4.13174 11.3957 4.39523C11.9227 4.65489 12.3313 5.01957 12.6215 5.48926C12.9155 5.95513 13.0625 6.49928 13.0625 7.12172C13.0625 7.5494 12.9957 7.93508 12.8621 8.27876C12.7284 8.61862 12.5375 8.9222 12.2893 9.1895C12.0449 9.4568 11.7508 9.69356 11.4072 9.89976C11.0826 10.1021 10.8191 10.3122 10.6167 10.5298C10.4181 10.7475 10.273 11.0052 10.1814 11.3031C10.0897 11.601 10.0401 11.9695 10.0325 12.4086V12.5174H8.39427ZM9.25919 16C8.94606 16 8.67685 15.8893 8.45155 15.6678C8.22625 15.4425 8.1136 15.1714 8.1136 14.8544C8.1136 14.5413 8.22625 14.274 8.45155 14.0525C8.67685 13.8272 8.94606 13.7146 9.25919 13.7146C9.5685 13.7146 9.8358 13.8272 10.0611 14.0525C10.2902 14.274 10.4048 14.5413 10.4048 14.8544C10.4048 15.0644 10.3513 15.2573 10.2444 15.4329C10.1413 15.6048 10.0038 15.7422 9.83198 15.8453C9.66014 15.9484 9.46921 16 9.25919 16Z"
                      fill="#202020"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10 18.5C14.6944 18.5 18.5 14.6944 18.5 10C18.5 5.30558 14.6944 1.5 10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                      fill="#202020"
                    />
                  </svg>
                </Link>
                <div className="picarea shadow-5in bg mx-auto my-5">
                  <img src={imgbg3} alt="" />
                  <div className="inerpic shadow-5in br-50 bg">
                    <img src={imgbg2} alt="" />
                  </div>
                </div>
                <p>
                  Buy a super Thanks, Which directly supports 9 TO 5 ESCAPEE
                </p>
                <div className="boxmodal gradient-box bg rounded-0 position-relative">
                  <span className="topbtn">
                    <button className="bg-primary border-0">Bonus</button>
                  </span>
                  <div className="spandiv d-flex align-items-center w-100">
                    <img src={imgbg2} alt="" />
                    <span>
                      <h3>Dyna Appolon</h3>
                      <h4 className=" br-20"  style={{ background: backgroundColor }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="me-1"
                          width="14"
                          height="15"
                          viewBox="0 0 14 15"
                          fill="none"
                        >
                          <path
                            d="M7 0.695312C3.13396 0.695312 0 3.82927 0 7.69531C0 11.5614 3.13396 14.6953 7 14.6953C10.866 14.6953 14 11.5614 14 7.69531C14 3.82927 10.866 0.695312 7 0.695312ZM7.60444 11.4906V12.4764H6.66141V11.5016C5.11394 11.2897 4.43629 10.0182 4.43629 10.0182L5.39955 9.21244C5.39955 9.21244 6.01459 10.2831 7.12715 10.2831C7.74171 10.2831 8.20793 9.95415 8.20793 9.39209C8.20793 8.07821 4.66795 8.23763 4.66795 5.8001C4.66795 4.74052 5.50599 3.97713 6.66093 3.79652V2.81159H7.60396V3.79652C8.40925 3.90248 9.36287 4.32632 9.36287 5.23804V5.93737H8.11256V5.5983C8.11256 5.24864 7.66706 5.01553 7.16857 5.01553C6.53282 5.01553 6.06708 5.3334 6.06708 5.77843C6.06708 7.1241 9.60706 6.79563 9.60706 9.34971C9.60706 10.3997 8.82297 11.3099 7.60444 11.4906Z"
                            fill="#202020"
                          />
                        </svg>
                        CA${sliderText}.00
                      </h4>
                    </span>
                    <h5 className="ms-4">Thanks!</h5>
                    <Link to="" className="ms-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          d="M14.27 6.11302L10.5493 2.43607L11.775 1.21042C12.1106 0.874828 12.5229 0.707031 13.012 0.707031C13.5011 0.707031 13.9132 0.874828 14.2482 1.21042L15.4738 2.43607C15.8094 2.77167 15.9845 3.17671 15.9991 3.65121C16.0137 4.12571 15.8532 4.53047 15.5176 4.86548L14.27 6.11302ZM13.0006 7.40432L3.72072 16.6842H0V12.9635L9.27991 3.68361L13.0006 7.40432Z"
                          fill="#202020"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="w-470 mx-auto">
                  <div className="shadow-5in p-2 br-10">
                    <CustomRangeColor  onBackgroundColorChange={handleBackgroundColorChange} onTextChange={handleSliderTextChange}/>
                  </div>
                 
                </div>
                <h5 className="text-start px-5">
                  As an added bonus, the above public comment will be published
                  on your behalf (subject to Community Guidelines).{" "}
                  <Link>Learn More.</Link>
                </h5>
                <div
                  className="shadow-4 bottmdrop d-flex justify-content-between"
                  onClick={handleClick1}
                >
                  <button className="border-0 bg-primary br-30">
                    Buy and send
                  </button>
                  <Arrow1 />
                </div>

                {toggle1 ? (
                  <></>
                ) : (
                  <div className="bottmsec">
                    <div className="hidesection">
                      <div className="firstbtn">
                        <button className="left bg">JOIN NOW</button>
                        <button className="right bg-primary border-0">
                          CA$2.26
                        </button>
                      </div>
                      <h6 className="text-center">
                        <Emoji /> MONTHLY CHARGE -- BILLING STARTS : JULY
                        18,2023
                      </h6>
                      <ul className="w-100 mt-3 mb-4">
                        <li>
                          <div className="shadow-4 overflow-h">
                            <span className="spantop bg-primary">PRICE</span>
                            <span className="spanbtm bg">$400</span>
                          </div>
                        </li>
                        <li>
                          <div className="shadow-4 overflow-h">
                            <span className="spantop bg-primary">TAX</span>
                            <span className="spanbtm bg">$55</span>
                          </div>
                        </li>
                        <li>
                          <div className="shadow-4 overflow-h">
                            <span className="spantop bg-primary">
                              TOTAL TODAY
                            </span>
                            <span className="spanbtm bg">$455</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <h5 className="w-100 text-start my-4">
                      Add credit or debit card
                    </h5>
                    <Tabvid />
                    <div className="editsec">
                      <EditableTextField />
                    </div>
                    <div className=" mt-4">
                      <h6>
                        Billing will renew automatically every month. Payments
                        won't be refunded for partial billing periods. Cancel
                        anytime in Settings. <Link to="">Learn more</Link>
                      </h6>
                      <h6>
                        By continuing, you verify that you are at least 18 years
                        old and agree to these terms.{" "}
                        <Link to="">Learn more</Link>
                      </h6>
                      <button className="border-0 py-2 px-4 bg-primary br-30 mt-3">
                        Buy
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal"
        id="supersec"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mb-0 mt-5 custompopup0 custompopup7 h-100 d-flex text-start">
          <div className="modal-content gradient-box bg position-relative">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>
            <div className="modal-body border-0 pb-0">
              <button className="top">
                <Emoji /> Super Thanks
              </button>
              <div className="w-100">
                <div className="mb-7">
                  <h4>
                    <Icon4 /> About
                  </h4>
                  <hr />
                  <p>
                    Super Thanks is a way for fans to express their gratitude
                    and show support.
                  </p>
                  <p>
                    When you purchase a Super Thanks, you will see a special
                    animated GIF and, as an added bonus, get a distinct,
                    colorful comment to highlight your purchase which creators
                    can respond to!{" "}
                  </p>
                  <Link to="">Learn more</Link>
                </div>
                <div className="mb-7">
                  <h4>
                    <Icon4 /> Terms and Conditions
                  </h4>
                  <hr />
                  <p>
                    By continuing, you verify that you are at least 18 years old
                    and agree to these terms. Learn more.
                  </p>
                </div>
              </div>
              <button className="btn-bottom bg-primary br-30">GOT IT</button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal"
        id="joinsec"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mb-0 my-5 custompopup0 custompopup6 h-100 d-flex text-start">
          <div className="modal-content gradient-box bg position-relative">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>
            <div className="modal-body border-0 pb-0">
              <button className="top">JOIN OUR MEMBERSHIP</button>
              <div className="position-relative text-center">
                <div className="picarea shadow-5in bg mx-auto my-5">
                  <img src={imgbg3} alt="" />
                  <div className="inerpic shadow-5in br-50 bg">
                    <img src={imgbg2} alt="" />
                  </div>
                </div>
                <div className="newpopup">
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <div className="topnewbtn">
                      <h4>CHANNEL SUPPORTER!</h4>
                    </div>
                    <h6 className="ms-4 me-4">CA$2.99/month</h6>
                    <button className="join br-30 bg-primary border-0">
                      join
                    </button>
                  </div>
                  <p className="mb-22">
                    <Emoji /> MONTHLY CHARGE -- BILLING STARTS : JULY 18,2023
                  </p>
                  <div className="text-center">
                    <button className="member bg-primary border-0">
                      MEMBERSHIP
                    </button>
                  </div>
                  <h6 className="my-2">9 TO 5 ESCAPEE</h6>
                  <h3 className="mb-1">Join this channel</h3>
                  <h6>Get access to membership perks</h6>
                  <h6 className="my-4">
                    Recurring payment. Cancel anytime. Creator may update perks.
                  </h6>
                  <h4 className="mb-4">
                    Loyalty badges next to your name in comments
                    <br /> and live chat
                  </h4>

                  <div className="w-100 mb-2">
                    <img src={colorimg} alt="" />
                  </div>
                  <div className="checksec">
                    <span>
                      <Checkicon />
                    </span>{" "}
                    Photos and status updates
                  </div>
                  <div className="checksec">
                    <span>
                      <Checkicon />
                    </span>{" "}
                    Priority reply to comments
                  </div>
                  <div className="checksec">
                    <span>
                      <Checkicon />
                    </span>{" "}
                    Support the Channel! <br /> Help Support Ula Tea Leaf
                    Readings
                  </div>
                  <h5 className="my-4 text-start px-4">
                    Your channel name (Dyna Appolon) and member status may be
                    publicly visible and shared by the channel with 3rd parties
                    (to provide perks). Learn more
                  </h5>
                </div>

                <div
                  className="shadow-4 bottmdrop d-flex justify-content-between"
                  onClick={handleClick2}
                >
                  <button className="border-0 bg-primary br-30">
                    Buy and send
                  </button>
                  <img src={arrow2} alt="" />
                </div>

                {toggle2 ? (
                  <></>
                ) : (
                  <div className="bottmsec">
                    <div className="hidesection">
                      <div className="firstbtn">
                        <button className="left bg">JOIN NOW</button>
                        <button className="right bg-primary border-0">
                          CA$2.26
                        </button>
                      </div>
                      <h6 className="text-center">
                        <Emoji /> MONTHLY CHARGE -- BILLING STARTS : JULY
                        18,2023
                      </h6>
                      <ul className="w-100 mt-3 mb-4">
                        <li>
                          <div className="shadow-4 overflow-h">
                            <span className="spantop bg-primary">PRICE</span>
                            <span className="spanbtm bg">$400</span>
                          </div>
                        </li>
                        <li>
                          <div className="shadow-4 overflow-h">
                            <span className="spantop bg-primary">TAX</span>
                            <span className="spanbtm bg">$55</span>
                          </div>
                        </li>
                        <li>
                          <div className="shadow-4 overflow-h">
                            <span className="spantop bg-primary">
                              TOTAL TODAY
                            </span>
                            <span className="spanbtm bg">$455</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <h5 className="w-100 text-start my-4">
                      Add credit or debit card
                    </h5>
                    <Tabvid />
                    <div className="editsec">
                      <EditableTextField />
                    </div>
                    <div className=" mt-4">
                      <h6>
                        Billing will renew automatically every month. Payments
                        won't be refunded for partial billing periods. Cancel
                        anytime in Settings. <Link to="">Learn more</Link>
                      </h6>
                      <h6>
                        By continuing, you verify that you are at least 18 years
                        old and agree to these terms.{" "}
                        <Link to="">Learn more</Link>
                      </h6>
                      <button className="border-0 py-2 px-4 bg-primary br-30 mt-3">
                        Buy
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal"
        id="discardsec"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mb-0 mt-5 custompopup0 custompopup8 h-100 d-flex text-start">
          <div className="modal-content pt-0 gradient-box bg position-relative">
            <div className="modal-body border-0 pb-0">
              <h4>Discard this clip</h4>
              <h5>if you do, you’ll loose all your changes</h5>
              <div className="d-flex mt-4">
                <button
                  className="btn-bottom bg-dark br-30 ms-auto text-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Discard clip
                </button>
                <button className="btn-bottom bg br-30 text-dark">Keep</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
