import * as React from 'react';
import ButtonBox from '../../global/ButtonBox';
import SMS from '../../../images/sms.png';
import SMSDark from '../../../images/sms1.png';
import '../../login/login.scss';
import { useTranslation } from "react-i18next";
import { NavItem } from 'react-bootstrap';

import { ReactComponent as IconBlue1 } from "../../../images/svgIcon/icon1.svg";
import { ReactComponent as IconBlue2 } from "../../../images/svgIcon/icon2.svg";
import ButtonBoxNew from '../../global/ButtonBoxNew';

export default function SmsVerify() {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <h6 className='w-100 text-center mb-3'>{t('reset_component.a_code_will_be_sent_to_your_phone')}</h6>
            <ul className='pb-5'>
                <NavItem as="li" className='mb-4'>
                    <input placeholder={t('enter_your_phone_number')} className="shadow-5in br-10 w-500" type="text" />
                </NavItem>
                <NavItem as="li">
                    <ButtonBox boxwidth="w-185 mt-0 mb-50" clicked="Requested" name="Request Code" />
                </NavItem>
                <NavItem as="li" className='mb-3'>
                    <div className='w-500 mx-auto d-flex justify-content-between'>
                        <h6 className='h6'>{t('reset_component.a_code_has_been_sent_to_your_phone')}</h6>
                        <h6 className='h6 fw-bold text-uppercase'>{t('reset_component.resend_code')}</h6>
                    </div>
                </NavItem>
                <NavItem as="li">
                    <div className='verificationCard bg br-30 shadow-4 mx-auto my-4'>
                        <div className=' mx-auto border-0'>
                            <img src={SMS} alt="" className='w-auto h-auto lightImg' />
                            <img src={SMSDark} alt="" className='w-auto h-auto darkImg' />
                        </div>
                        <h6>{t('reset_component.enter_6_digit_security_code')}</h6>
                        <input placeholder={t('pass_code')} classbox="w-100 text-center" length="6" type="password" />
                        <div className='success-section'>
                            <div className='verifyIcon rounded-circle icon-sucess d-flex justify-content-center align-items-center'>
                                <IconBlue2 />
                            </div>
                            <h6>{t('reset_component.phone_number_verified')}</h6>
                        </div>
                        <div className='success-section d-flex flex-column justify-content-center align-items-center'>
                            <div className='verifyIcon rounded-circle bg-danger d-flex justify-content-center align-items-center'>
                                <IconBlue1 />
                            </div>
                            <h6 className="w-100 mb-4">{t('reset_component.invalid_code')}</h6>
                            {/* <button type="button" className="btn border-0 bg shadow-4 w-110 rounded-pill h6 text-uppercase textBody fw-bold">Try again</button> */}
                            <ButtonBoxNew name="Try again" boxwidth="w-100" />
                        </div>

                    </div>
                </NavItem>

                <NavItem as="li" className='mb-4'>
                    <input placeholder={t('enter_new_password')} className="shadow-5in br-10 w-500" type="password" />
                </NavItem>
                <NavItem as="li">
                    <ButtonBox boxwidth="w-185 mt-0 mb-50" clicked="Password changed" name="Change Password" />
                </NavItem>
            </ul>
        </div>
    );
}