import React from "react";
import '../EmailSignatureStep.scss';
import Contest from '../../../../../images/img29sss1.png';
import Contest1 from '../../../../../images/img32s.png';
import label1 from '../../../../../images/img31s.png';
import { Link } from 'react-router-dom';
// import Select from 'react-select';

import { ReactComponent as IconBlue14 } from "../../../../../images/icons/icon41.svg";
import { ReactComponent as IconBlue15 } from "../../../../../images/icons/icon42.svg";
import { ReactComponent as IconBlue16 } from "../../../../../images/icons/icon43.svg";
import { ReactComponent as IconBlue17 } from "../../../../../images/icons/icon44.svg";
import { ReactComponent as IconBlue1 } from "../../../../../images/icons/icon46.svg";
import { ReactComponent as IconBlue2 } from "../../../../../images/icons/icon54.svg";
import { ReactComponent as IconBlue3 } from "../../../../../images/icons/icon53.svg";
import { ReactComponent as IconBlue4 } from "../../../../../images/icons/icon50.svg";
import { ReactComponent as IconBlue5 } from "../../../../../images/icons/icon49.svg";
import { ReactComponent as IconBlue6 } from "../../../../../images/icons/icon47.svg";
import { ReactComponent as IconBlue7 } from "../../../../../images/icons/icon52.svg";
import { ReactComponent as IconBlue8 } from "../../../../../images/icons/icon51.svg";
import { ReactComponent as IconBlue9 } from "../../../../../images/icons/icon48.svg";
import EmailTemp from "../../../../global/EmailTemp";
import EmailTempBm from "../../../../global/EmailTempBm";

export default function EmailDesign7() {
  return (
    <div className="emailcustom">
      {/* 3rd section */}
      <div className="shadow-4 mb-4 silverClr">
        <div className="row mx-0 pt-4">
          <div className="col-md-7 px-30">
            <div className="d-flex align-items-center mb-3">
              <span>
                <img src={label1} width="90" height="60" alt="" />
              </span>
              <h6 className="h13 ps-2 lh-20">The Best Alternative Lifestyle to<br /> the 9 to 5 Grind.</h6>
            </div>
            <div className="p-4 shadow-4 w-348">
              <h3 className="mb-3 h30 fw-semibold">Dyna Appolon</h3>
              <h5 className="h8 mb-3">CEO | Social Network Strategist</h5>
              <div className="shadow-4 d-flex justify-content-between align-items-center p-10 emailsix">
                <h6><b>Industry:</b>Social Network</h6>
                <h6><b>Department:</b>Startup</h6>
              </div>
            </div>
          </div>
          <div className="col-md-5 px-3 pt-2 text-end">
            <img src={Contest1} alt="" height="370"/>
            <div className="shadow-4 position-relative h-42 w-335 bg float-end mt-9 d-flex justify-content-center align-items-center">
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue1 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue2 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue3 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue4 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue5 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue6 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue7 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue8 /></Link>
              <Link to="#" className="shadow-4 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue9 /></Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="w-450 mt-10 h-210 shadow-4 d-flex justify-content-start align-items-center">
              <div className="px-30 text-start">
                <h5 className="py-1 h7 d-flex align-items-center m-0 mb-2"><span className="w-28 br-50 shadow-4 me-2 d-flex justify-content-center align-items-center"><IconBlue15 /></span>1-800-000-00-00</h5>
                <h5 className="py-1 h7 d-flex align-items-center m-0 mb-2"><span className="w-28 br-50 shadow-4 me-2 d-flex justify-content-center align-items-center"><IconBlue17 /></span>dyna@7figurebosses.com</h5>
                <h5 className="py-1 h7 d-flex align-items-center m-0 mb-2"><span className="w-28 br-50 shadow-4 me-2 d-flex justify-content-center align-items-center"><IconBlue16 /></span>9to5escapee.com </h5>
                <h5 className="py-1 h7 d-flex align-items-center m-0"><span className="w-28 me-2 br-50 shadow-4 d-flex justify-content-center align-items-center"><IconBlue14 /></span><span className="w-90e">123 Success Rd, Take Action City,<br /> Freedom Country M6Y 10T </span></h5>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 my-4 text-center">
          <div className="w-600s shadow-4 text-start p-3 h8 mx-auto">
            <b>Mission Statement:</b> I help people quit their 9 to 5 jobs and launch, manage, and scale up their online business.
          </div>
        </div>
        <div className="br-5 p-2 w-100 position-relative h-93 px-5 d-flex justify-content-center align-items-center">
            <img src={Contest} alt="" width="650" height="100%" className="bottom-0 position-absolute top-0 start-0 end-0 m-auto" />
            <p className="h6 lh-30 mb-0 px-2">Self-Image Brand identity Consultant & Coach Fashion Stylist Social Enterprise Network Strategist</p>
        </div>
        <EmailTemp />
      </div>
      <div className="shadow-4 mb-4 silverClr">
        <EmailTempBm />
      </div>
    </div>
  );
} 
