import React, { useState } from "react";
import desktop_2_1 from "../../../../images/profile/Desktop2-1.png";
import desktop_version_2 from "../../../../images/profile/Desktop2-1-new.png";
import tab_2_1 from "../../../../images/profile/Tab2-1.png";
import mobile_2_1 from "../../../../images/profile/Mobile2-1.png";
import desktop_2_2 from "../../../../images/profile/Desktop2-2.png";
import tab_2_2 from "../../../../images/profile/Tab2-2.png";
import mobile_2_2 from "../../../../images/profile/Mobile2-2.png";
import desktop_2_3 from "../../../../images/profile/Desktop2-3.png";
import tab_2_3 from "../../../../images/profile/Tab2-3.png";
import mobile_2_3 from "../../../../images/profile/Mobile2-3.png";

import "./ProfileHeroSectionThemes.scss";
import Modal from "react-bootstrap/Modal";
import ProfileHeroSectionNeumorphicThemeDesktopView1 from "./profileHeroSectionNeumorphicThemes/ProfileHeroSectionNeumorphicThemeDesktopView1";
import ProfileHeroSectionNeumorphicThemeTabView1 from "./profileHeroSectionNeumorphicThemes/ProfileHeroSectionNeumorphicThemeTabView1";
import ProfileHeroSectionNeumorphicThemeDesktopView2 from "./profileHeroSectionNeumorphicThemes/ProfileHeroSectionNeumorphicThemeDesktopView2";
import ProfileHeroSectionNeumorphicThemeTabView2 from "./profileHeroSectionNeumorphicThemes/ProfileHeroSectionNeumorphicThemeTabView2";
import ProfileHeroSectionNeumorphicThemeMobileView1 from "./profileHeroSectionNeumorphicThemes/ProfileHeroSectionNeumorphicThemeMobileView1";
import ProfileHeroSectionNeumorphicThemeMobileView2 from "./profileHeroSectionNeumorphicThemes/ProfileHeroSectionNeumorphicThemeMobileView2";
import ProfileHeroSectionNeumorphicThemeMobileView3 from "./profileHeroSectionNeumorphicThemes/ProfileHeroSectionNeumorphicThemeMobileView3";
import ProfileHeroSectionNeumorphicThemeTabView3 from "./profileHeroSectionNeumorphicThemes/ProfileHeroSectionNeumorphicThemeTabView3";
import ProfileHeroSectionNeumorphicThemeDesktopView3 from "./profileHeroSectionNeumorphicThemes/ProfileHeroSectionNeumorphicThemeDesktopView3";
import { useTranslation } from "react-i18next";
import ButtonBoxNew from "../../../global/ButtonBoxNew"

export default function ProfileHeroSectionNeumorphicTheme() {
  const [desktwoShow, setDeskTwoShow] = useState(false);
  const [deskOneShow, setDeskOneShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [themedesktopShow, setThemedesktopShow] = useState(false);
  const [themetabShow, setThemetabShow] = useState(false);
  const [themedesktop3Show, setThemedesktop3Show] = useState(false);
  const [themetab3Show, setThemetab3Show] = useState(false);
  const [thememobileShow, setThememobileShow] = useState(false);
  const [thememobile3Show, setThememobile3Show] = useState(false);

  return (
    <div className="Neumorphic-design">
      <h4 className="text-center">Neumorphic Design Version 1</h4>
      <div className="d-flex card-outo">
        <div className="w-25 px-3">
          <div className="screen-bg w-100 newimg1s">
            <img src={desktop_version_2} alt="deshtop-1"></img>
          </div>
          {/* <div
            className="screen-btn desktop-top2"
            onClick={() => setDeskTwoShow(true)}
          >
            See Desktop Version 2 view
          </div> */}
          <ButtonBoxNew  name="See Desktop view 2" boxwidth="w-220 mx-auto mt-4" onClick={() => setDeskTwoShow(true)}/>
          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktwoShow}
            onHide={() => setDeskTwoShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="desktop-version-2">
                <ProfileHeroSectionNeumorphicThemeDesktopView1 />
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <div className="w-25 px-3">
          <div className="screen-bg w-100 newimg1s">
            <img src={desktop_2_1} alt="desktop-2-1"></img>
          </div>

          {/* <button
            className="screen-btn"
            typeof="button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropNem"
          >
            See Desktop view
          </button> */}
          <ButtonBoxNew  name=" See Desktop view" boxwidth="w-220 mx-auto mt-4" onClick={() => setDeskTwoShow(true)} />
          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskOneShow}
            onHide={() => setDeskTwoShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="desktop-version-2">
                <ProfileHeroSectionNeumorphicThemeDesktopView1 />
              </div>
            </Modal.Body>
          </Modal>
          {/* <div
            className="modal fade"
            id="staticBackdropNem"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropNemLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content">
                <div className="modal-header ">
                  <button
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div>
                    <ProfileHeroSectionNeumorphicThemeDesktopView1 />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="w-25 px-3">
          <div className="screen-bg w-100">
            <img src={tab_2_1} alt="tab_2_1"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="  See Tablet view" boxwidth="w-220  mx-auto mt-4" onClick={() => setSmShow(true)} />
          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionNeumorphicThemeTabView1 />
            </Modal.Body>
          </Modal>
        </div>

        <div className="w-25 px-3 mb-5">
          <div className="screen-bg w-100">
            <img src={mobile_2_1} alt="mobile_2_1"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name=" See Mobile view" boxwidth="w-220 mx-auto mt-4" onClick={() => setLgShow(true)}/>
          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionNeumorphicThemeMobileView1 />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Neumorphic Design Version 2</h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2_2} alt="desktop-2-2"></img>
          </div>
          {/* <button
            className="screen-btn"
            onClick={() => setThemedesktopShow(true)}
          >
            See Desktop view
          </button> */}
          <ButtonBoxNew  name=" See Desktop view" boxwidth="w-220 mx-auto mt-4" onClick={() => setThemedesktopShow(true)} />
          <Modal
            show={themedesktopShow}
            onHide={() => setThemedesktopShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionNeumorphicThemeDesktopView2 />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2_2} alt="tab-2-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetabShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name=" See Tablet view" boxwidth="w-220 mx-auto mt-4" onClick={() => setThemetabShow(true)}/>
          <Modal
            show={themetabShow}
            onHide={() => setThemetabShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionNeumorphicThemeTabView2 />
            </Modal.Body>
          </Modal>
        </div>

        <div className="mb-5">
          <div className="screen-bg">
            <img src={mobile_2_2} alt="mobile-2-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobileShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name=" See Mobile view" boxwidth="w-220 mx-auto mt-4" onClick={() => setThememobileShow(true)}/>
          <Modal
            show={thememobileShow}
            onHide={() => setThememobileShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionNeumorphicThemeMobileView2 />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Neumorphic Design Version 3</h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2_3} alt="desktop-2-3"></img>
          </div>
          {/* <button
            className="screen-btn"
            onClick={() => setThemedesktop3Show(true)}
          >
            See Desktop view
          </button> */}
          <ButtonBoxNew  name=" See Desktop view" boxwidth="w-220 mx-auto mt-4" onClick={() => setThemedesktop3Show(true)}/>
          <Modal
            show={themedesktop3Show}
            onHide={() => setThemedesktop3Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionNeumorphicThemeDesktopView3 />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2_3} alt="tab-2-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetab3Show(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto  mt-4" onClick={() => setThemetab3Show(true)}/>
          <Modal
            show={themetab3Show}
            onHide={() => setThemetab3Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionNeumorphicThemeTabView3 />
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2_3} alt="mobile-2-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobile3Show(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name=" See Mobile view" boxwidth="w-220 mx-auto  mt-4" onClick={() => setThememobile3Show(true)}/>
          <Modal
            show={thememobile3Show}
            onHide={() => setThememobile3Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionNeumorphicThemeMobileView3 />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
