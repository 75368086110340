import React from "react";
import ButtonBox from '../../../../global/ButtonBox';
import Select from 'react-select';
import { useState } from 'react';
import RangeSelect from "../../../../global/rangeSelect/RangeSelect";

import {CommunicationTime} from '../../../../global/rangeSelect/RangeValues';
import { ReactComponent as IconBlue1 } from "../../../../../images/listicon/icon1.svg";
import { ReactComponent as IconBlue2 } from "../../../../../images/listicon/icon2.svg";
import { ReactComponent as IconBlue3 } from "../../../../../images/listicon/icon3.svg";
import { ReactComponent as IconBlue4 } from "../../../../../images/listicon/icon4.svg";
import { ReactComponent as IconBlue5 } from "../../../../../images/listicon/icon5.svg";
import { ReactComponent as IconBlue6 } from "../../../../../images/listicon/icon6.svg";
import { ReactComponent as IconBlue7 } from "../../../../../images/listicon/icon7.svg";
import { ReactComponent as IconBlue8 } from "../../../../../images/listicon/icon8.svg";
import { ReactComponent as IconBlue9 } from "../../../../../images/listicon/icon9.svg";
import { ReactComponent as IconBlue10 } from "../../../../../images/listicon/icon10.svg";
import { ReactComponent as IconBlue11 } from "../../../../../images/listicon/icon11.svg";
import { ReactComponent as IconBlue12 } from "../../../../../images/listicon/icon12.svg";
import { ReactComponent as IconBlue13 } from "../../../../../images/listicon/icon13.svg";
import { ReactComponent as IconBlue14 } from "../../../../../images/listicon/icon14.svg";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import DropdownBox from "../../../../global/DropdownBox";

 

export default function BasicInfoTab() { 
  const { t, i18n } = useTranslation();

  const theme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    }
  });
  // const options = [
  //       { value: 'LIVE', label: <span><IconBlue1 className="me-2"/> {t('profile_section.live')}</span> },
  //       { value: 'IN MEETING NOW', label: <span><IconBlue2 className="me-2"/> {t('profile_section.in_meeting_now')} </span> },
  //       { value: 'COACHING NOW', label: <span><IconBlue3 className="me-2"/> {t('profile_section.coaching_now')}</span> },
  //       { value: 'ON BREAK', label: <span><IconBlue4 className="me-2"/> {t('profile_section.on_break')}</span> },
  //       { value: 'OUT SICK', label: <span><IconBlue5 className="me-2"/> {t('profile_section.out_sick')}</span> },
  //       { value: 'FAMILY TIME', label: <span><IconBlue6 className="me-2"/> {t('profile_section.family_time')}</span> },
  //       { value: 'SELF-CARE', label: <span><IconBlue7 className="me-2"/> {t('profile_section.self_care')}</span>  },
  //       { value: 'MEDITATING', label: <span><IconBlue8 className="me-2"/> {t('profile_section.meditating')}</span>  },
  //       { value: 'WORKING FROM HOME', label: <span><IconBlue9 className="me-2"/> {t('profile_section.working_from_home')}</span> },
  //       { value: 'OUT OF THE OFFICE', label: <span><IconBlue10 className="me-2"/> {t('profile_section.out_of_the_office')}</span> },
  //       { value: 'WORKING OUTSIDE', label: <span><IconBlue11 className="me-2"/> {t('profile_section.working_outside')}</span> },
  //       { value: 'IN THE OFFICE', label: <span><IconBlue12 className="me-2"/> {t('profile_section.in_the_office')}</span> },
  //       { value: 'WORKING IN BED', label: <span><IconBlue13 className="me-2"/> {t('profile_section.working_in_bed')}</span> },
  //       { value: 'DO NOT DISTURB', label: <span><IconBlue14 className="me-2"/>{t('profile_section.do_not_disturb')}</span> },
  //     ]

  const meetOptions = [
    {
      icon: <span className=""><IconBlue1 className="me-2"/></span>,
      name: "LIVE"
    },
    {
      icon: <span className=""><IconBlue2 className="me-2"/> </span>,
      name: "IN MEETING NOW"
    },
    {
      icon: <span className=""><IconBlue3 className="me-2"/></span>,
      name: "COACHING NOW"
    },
    {
      icon: <span className=""><IconBlue4 className="me-2"/> </span>,
      name: "ON BREAK"
    },
    {
      icon: <span className=""><IconBlue5 className="me-2"/> </span>,
      name: "OUT SICK"
    },
    {
      icon: <span className=""><IconBlue6 className="me-2"/> </span>,
      name: "FAMILY TIME"
    },
    {
      icon: <span className=""><IconBlue7 className="me-2"/> </span>,
      name: "SELF-CARE"
    },
    {
      icon: <span className=""><IconBlue8 className="me-2"/> </span>,
      name: "MEDITATING"
    },
    {
      icon: <span className=""><IconBlue9 className="me-2"/> </span>,
      name: "WORKING FROM HOME"
    },
    {
      icon: <span className=""><IconBlue10 className="me-2"/> </span>,
      name: "OUT OF THE OFFICE"
    },
    {
      icon: <span className=""><IconBlue11 className="me-2"/> </span>,
      name: "WORKING OUTSIDE"
    },
    {
      icon: <span className=""><IconBlue12 className="me-2"/> </span>,
      name: "IN THE OFFICE"
    },
    {
      icon: <span className=""><IconBlue13 className="me-2"/> </span>,
      name: "WORKING IN BED"
    },
    {
      icon: <span className=""><IconBlue14 className="me-2"/> </span>,
      name: "DO NOT DISTURB"
    },
  ] 

      const [phone, setPhone] = useState(false);
      const [text, setText] = useState(false);
      const [email, setEmail] = useState(false);
      const [meet, setMeet] = useState(false);
      const [socialMedia, setSocialMedia] = useState(false);
      const [inPerson, setInPerson] = useState(false);

      const [monday, setMonday] = useState(false);
      const [tuesday, setTuesday] = useState(false);
      const [wednesday, setWednesday] = useState(false);
      const [thursday, setThursday] = useState(false);
      const [friday, setFriday] = useState(false);
      const [saturday, setSaturday] = useState(false);
      const [sunday, setSunday] = useState(false);

    const [time, setTime] = useState(CommunicationTime[Math.floor(CommunicationTime.length/2)])
    let communicationTime = time.start + " to " + time.end;

  return (
    <div>
      <div className="ProgressBar">
        <ul>
            <NavItem as="li" className="active" id="profile"></NavItem>
            <NavItem as="li" className="active" id="chat"></NavItem>
            <NavItem as="li" id="location"></NavItem>
            <NavItem as="li" id="user"></NavItem>
            <NavItem as="li" id="briefcase"></NavItem>
            <NavItem as="li" id="graduation-cap"></NavItem>
            <NavItem as="li" id="favorite"></NavItem>
            <NavItem as="li" id="award"></NavItem>
            <NavItem as="li" id="connectivity"></NavItem>
            <NavItem as="li" id="lock"></NavItem>
            <NavItem as="li" id="eye"></NavItem>
        </ul>
      </div>
        <form action="" method="">
          <div className="text-center mb-5">
            <h5>{t('profile_section.your_basic_business_info')} </h5>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className=" position-relative">
                <label className="mb-2">{t('profile_section.availability_status')}</label>
                {/* <Select isSearchable={false} options={options} className="country customWidth mb-4 w-100 mx-auto"
                 placeholder={t('profile_section.linkedin_email_live_meet')} theme={theme}
                    styles={{
                        option: (base) => ({
                          ...base,
                          borderRadius: "30px",
                          width: "93%",
                          maxheight: "39px",
                          lineHeight: "28px",
                          fontSize:"14px",
                          margin: "10px",
                          paddingLeft:"22px",
                          cursor: 'pointer',
                          color:'#000000',
                          boxShadow:
                            "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                        }),
                      }} /> */}
                      <DropdownBox name="Linkedin Email Live Meet" boxid="livemeets" options={meetOptions} boxwidth="country customWidth mb-4 w-100 mx-auto" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="w-100 d-flex justify-content-between align-items-center">
                <label className="fw-normal fs-5">{t('profile_section.preferred_ways_of_communication')} </label>
                  <div className='shadow-4 br-30 w-125 h-50s px-4 text-center check-button mb-2 d-flex justify-content-start align-items-center'>
                    <input type="checkbox" className="btn-check" name="communication-way" id="all1"  value={phone} onChange={(e)=> {setPhone(!phone);}} />
                    <label className="btn rounded-circle btnRadio large me-3" htmlFor="all1"><div className="radioInner"><span></span></div></label>
                    All
                  </div>
              </div>
              <div className="my-4 communicationWay">
                  <div className='col-md-6 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="communication-way" id="phone"  value={phone} onChange={(e)=> {setPhone(!phone);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="phone"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.phone')} 
                    </div>
                    <div className='col-md-6 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="communication-way" id="text"  value={text} onChange={(e)=> {setText(!text);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="text"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.text_messaging')}
                    </div>
                    <div className='col-md-6 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="communication-way" id="email"  value={email} onChange={(e)=> {setEmail(!email);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="email"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.email')}
                    </div>
                    <div className='col-md-6 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="communication-way" id="meet"  value={meet} onChange={(e)=> {setMeet(!meet);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="meet"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.live_meet')}
                    </div>
                    <div className='col-md-6 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="communication-way" id="media"  value={socialMedia} onChange={(e)=> {setSocialMedia(!socialMedia);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="media"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.social_media')}
                    </div>
                    <div className='col-md-6 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="communication-way" id="person"  value={inPerson} onChange={(e)=> {setInPerson(!inPerson);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="person"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.in_person')}
                       
                    </div>
                  </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="mb-2 d-flex align-items-center"> {t('profile_section.other')}<small className="ms-1 mt-1">{t('profile_section.seperate_using_comma')}</small></label>
              <input
                placeholder={t('profile_section.linkedin_email_live_meet')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="w-100 d-flex justify-content-between align-items-center">
                <label className="fw-normal fs-5">{t('profile_section.preferred_days_of_communication')} </label>
                  <div className='shadow-4 br-30 w-125 h-50s px-4 text-center check-button mb-2 d-flex justify-content-start align-items-center'>
                    <input type="checkbox" className="btn-check" name="communication-way" id="all2"  value={phone} onChange={(e)=> {setPhone(!phone);}} />
                    <label className="btn rounded-circle btnRadio large me-3" htmlFor="remember"><div className="radioInner"><span></span></div></label>
                    {t('profile_section.all')}

                  </div>
              </div>
              <div className="my-4 communicationWay">
                  <div className='col-md-6 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="communication-day" id="monday"  value={monday} onChange={(e)=> {setMonday(!monday);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="monday"><div className="radioInner"><span></span></div></label>
                    {t('profile_section.mondays')}                   
                    </div>
                    <div className='col-md-6 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="communication-day" id="tuesday"  value={tuesday} onChange={(e)=> {setTuesday(!tuesday);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="tuesday"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.tuesdays')}                   

                    </div>
                    <div className='col-md-6 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="communication-day" id="wednesday"  value={wednesday} onChange={(e)=> {setWednesday(!wednesday);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="wednesday"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.wednesdays')}                   

                    </div>
                    <div className='col-md-6 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="communication-day" id="thursday"  value={thursday} onChange={(e)=> {setThursday(!thursday);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="thursday"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.thursdays')}                   

                    </div>
                    <div className='col-md-6 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="communication-day" id="friday"  value={friday} onChange={(e)=> {setFriday(!friday);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="friday"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.fridays')}                   

                    </div>
                    <div className='col-md-6 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="communication-day" id="saturday"  value={saturday} onChange={(e)=> {setSaturday(!saturday);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="saturday"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.saturdays')}                   

                    </div>
                    <div className='col-md-6 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="communication-day" id="sunday"  value={sunday} onChange={(e)=> {setSunday(!sunday);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="sunday"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.sundays')}                   

                    </div>
                  </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="mb-2 d-flex align-items-center">{t('profile_section.other')} <small className="ms-1 mt-1">
                {t('profile_section.seperate_using_comma')}</small></label>
              <input
                placeholder={t('profile_section.monday_thursday')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 dropLink">
            <label className="fw-normal fs-5 mb-3">{t('profile_section.preferred_time_of_communication')} </label>
            <RangeSelect data={CommunicationTime} type='time' selectedRange={time} setSelectedRange={setTime} value={time} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="mb-2 d-flex align-items-center">{t('profile_section.other')} <small className="ms-1 mt-1">{t('profile_section.seperate_using_comma')}</small></label>
              <input
                placeholder={t('profile_section._10_00_11_00_am_4_00_8_00_pm')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
            </div>
          </div>
          <div className="mt-4 text-center">
            <ButtonBox boxwidth="w-140 mt-0 mb-50 mx-auto" name="save/next" className="next action-button" value="Save/Next" clicked="Saved" />
          </div>
        </form>
    </div>
  );
}
