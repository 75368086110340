import React from "react";
import {useState} from "react";
import "./RegularTheme1Mobile.scss";
import "../regularTheme1Desktop/RegularTheme1Desktop.scss";
import "../regularTheme1Tab/RegularTheme1Tab.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import subscribeUserIcon from "../../../../../../images/subscription/subscribe-user-icon.png";
import subscribeAroundTextImg from "../../../../../../images/subscription/subscribr-text-img.svg";
import subscriptionTheme1SliderImg
    from "../../../../../../images/subscription/subscription-theme1-mobile-slider-group-img.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-multi-carousel/lib/styles.css";

export default function RegularTheme1Mobile({
                                                Regular,
                                                Neumorphic,
                                                Glassmorphic,
                                            }) {
    const [isSwitchToggled, toggle] = useState(false);
    const callbackMob = () => {
        toggle(!isSwitchToggled);
    };
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
    };
    return (
        <>
            <div
                className="regular-theme-mobile-main-wrapper"
                style={{
                    background: Regular
                        ? "#CBD5E0"
                        : Neumorphic
                            ? "#F0F4F8"
                            : Glassmorphic
                                ? "linear-gradient(326deg, #9595B8 0%, #ECEDF5 46.35%, #C8C9D2 70.83%, #97999D 100%)"
                                : "",
                }}
            >
                <div
                    className={`subscribe-img-wrapper ${
                        Regular
                            ? "regular-style"
                            : Neumorphic
                                ? "neumorphic-style"
                                : Glassmorphic
                                    ? "glassmorphic-style"
                                    : ""
                    }`}
                >
                    <img
                        src={subscribeUserIcon}
                        alt="subscribeUserIcon"
                        className="subscribeUserIcon"
                    ></img>
                    <img
                        src={subscribeAroundTextImg}
                        alt="subscribeAroundTextImg"
                        className="subscribeAroundTextImg"
                    ></img>
                    <p
                        className={`choose-position ${
                            Regular
                                ? "regular-style"
                                : Neumorphic
                                    ? "neumorphic-style"
                                    : Glassmorphic
                                        ? "glassmorphic-style"
                                        : ""
                        }`}
                    >
                        CHOOSE YOUR POSITION
                    </p>
                </div>
                <div
                    className={`regular-theme1-mobile-slider  ${
                        Regular
                            ? "regular-style"
                            : Neumorphic
                                ? "neumorphic-style"
                                : Glassmorphic
                                    ? "glassmorphic-style"
                                    : Glassmorphic
                                        ? "glassmorphic-style"
                                        : ""
                    }`}
                >
                    <Slider {...settings} className="">
                        <div className="subscription-regular-theme1-mobile-sliderimg">
                            <img
                                src={subscriptionTheme1SliderImg}
                                alt="subscriptionTheme1SliderImg"
                            ></img>
                        </div>
                        <div className="subscription-regular-theme1-mobile-sliderimg">
                            <img
                                src={subscriptionTheme1SliderImg}
                                alt="subscriptionTheme1SliderImg"
                            ></img>
                        </div>
                        <div className="subscription-regular-theme1-mobile-sliderimg">
                            <img
                                src={subscriptionTheme1SliderImg}
                                alt="subscriptionTheme1SliderImg"
                            ></img>
                        </div>
                        <div className="subscription-regular-theme1-mobile-sliderimg">
                            <img
                                src={subscriptionTheme1SliderImg}
                                alt="subscriptionSliderMobImg1"
                            ></img>
                        </div>
                    </Slider>
                </div>
                <div className="sign-up-form-main-wrapper">
                    <div
                        className={`d-flex justify-center sign-up-form ${
                            Regular
                                ? "regular-style"
                                : Neumorphic
                                    ? "neumorphic-style"
                                    : Glassmorphic
                                        ? "glassmorphic-style"
                                        : ""
                        }`}
                    >
                        <Form>
                            <Form.Group>
                                <div
                                    className={`form-group ${
                                        Regular
                                            ? "regular-style"
                                            : Neumorphic
                                                ? "neumorphic-style"
                                                : Glassmorphic
                                                    ? "glassmorphic-style"
                                                    : ""
                                    }`}
                                >
                                    <Form.Control type="text" placeholder="Your@gmail.com"/>
                                    <Button variant="primary" type="submit">
                                        SIGNUP
                                    </Button>
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </div>

                <div
                    className={`on-off-buttons-wrapper ${
                        Regular
                            ? "regular-style"
                            : Neumorphic
                                ? "neumorphic-style"
                                : Glassmorphic
                                    ? "glassmorphic-style"
                                    : ""
                    }`}
                >
                    <ul className="btns-list">
                        <li>
                            <label
                                className={`labelSwitch ${
                                    Regular
                                        ? "regular-style"
                                        : Neumorphic
                                            ? "neumorphic-style"
                                            : Glassmorphic
                                                ? "glassmorphic-style"
                                                : ""
                                }`}
                                htmlFor="events"
                            >
                                <input
                                    type="checkbox"
                                    defaultChecked={isSwitchToggled}
                                    onClick={callbackMob}
                                    id="events"
                                />
                                <span className="span"></span>
                            </label>
                            <strong>EVENTS</strong>
                        </li>
                        <li>
                            <label
                                className={`labelSwitch ${
                                    Regular
                                        ? "regular-style"
                                        : Neumorphic
                                            ? "neumorphic-style"
                                            : Glassmorphic
                                                ? "glassmorphic-style"
                                                : ""
                                }`}
                                htmlFor="newsletter"
                            >
                                <input
                                    type="checkbox"
                                    defaultChecked={true}
                                    onClick={callbackMob}
                                    id="newsletter"
                                />
                                <span className="span"></span>
                            </label>
                            <strong>NEWSLETTER</strong>
                        </li>
                        <li>
                            <label
                                className={`labelSwitch ${
                                    Regular
                                        ? "regular-style"
                                        : Neumorphic
                                            ? "neumorphic-style"
                                            : Glassmorphic
                                                ? "glassmorphic-style"
                                                : ""
                                }`}
                                htmlFor="blogPost"
                            >
                                <input
                                    type="checkbox"
                                    defaultChecked={isSwitchToggled}
                                    onClick={callbackMob}
                                    id="blogPost"
                                />
                                <span className="span"></span>
                            </label>
                            <strong>BLOG POSTS</strong>
                        </li>
                        <li>
                            <label
                                className={`labelSwitch ${
                                    Regular
                                        ? "regular-style"
                                        : Neumorphic
                                            ? "neumorphic-style"
                                            : Glassmorphic
                                                ? "glassmorphic-style"
                                                : ""
                                }`}
                                htmlFor="channel"
                            >
                                <input
                                    type="checkbox"
                                    defaultChecked={isSwitchToggled}
                                    onClick={callbackMob}
                                    id="channel"
                                />
                                <span className="span"></span>
                            </label>
                            <strong>VIDEO CHANNEL</strong>
                        </li>
                        <li>
                            <label
                                className={`labelSwitch ${
                                    Regular
                                        ? "regular-style"
                                        : Neumorphic
                                            ? "neumorphic-style"
                                            : Glassmorphic
                                                ? "glassmorphic-style"
                                                : ""
                                }`}
                                htmlFor="podcast"
                            >
                                <input
                                    type="checkbox"
                                    defaultChecked={isSwitchToggled}
                                    onClick={callbackMob}
                                    id="podcast"
                                />
                                <span className="span"></span>
                            </label>
                            <strong>PODCAST</strong>
                        </li>
                        <li>
                            <label
                                className={`labelSwitch ${
                                    Regular
                                        ? "regular-style"
                                        : Neumorphic
                                            ? "neumorphic-style"
                                            : Glassmorphic
                                                ? "glassmorphic-style"
                                                : ""
                                }`}
                                htmlFor="subscribe"
                            >
                                <input
                                    type="checkbox"
                                    defaultChecked={isSwitchToggled}
                                    onClick={callbackMob}
                                    id="subscribe"
                                />
                                <span className="span"></span>
                            </label>
                            <strong>SUBSCRIBE TO ALL</strong>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
