import React from 'react';
import '../../../global/timelineTab/TimelineTab.scss';
import Video from '../../../../images/iconVid.png';
import VideoPopup from '../../../global/VideoPopup';
import Video1 from '../../../../images/iconVid1.png';
import ButtonBox from "../../../global/ButtonBox";
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import EMagazineSectionThemes from './EMagazineSectionThemes';

export default function EMagazineSectionSteps() {

    return (
        <div>
            <Tab.Container  defaultActiveKey="">
                <div className='' >
                    <div className='flex'>

                       
                        <div className='width-fll'>
                            <VideoPopup />
                            <Tab.Content className='class-scroll'>
                                    <div className="  align-items-center mx-auto d-flex flex-column-reverse justify-content-between three-bsiness">
                                        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto ">
                                            <Link className="VideoIcon" data-bs-toggle="modal" data-bs-target="#videoScreen">
                                                <img src={Video} className="lightImg"  alt="light-img" />
                                                <img src={Video1} className="darkImg" alt="dark-img" />
                                            </Link>
                                            <div className="tab-content" id="myTabContentsss">
                                                <div className='tab-pane active' id="custom_email">
                                                    <div className=' mt-3 d-flex topic-css'>
                                                        <div className='flex flex-diction  none' >
                                                            <h5>Enter Your Profile Details</h5>
                                                        </div>
                                                        <div className='page-no'>
                                                        Step <span>
                                                        1
                                                            </span> of 11
                                                        </div>
                                                    </div>
                                                    <EMagazineSectionThemes />
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <span className="d-flex align-items-center justify-content-between w-100 mb-4">
                                            <div id="myTab1" className='flex' role="tablist">
                                                <button className="buttonArrow me-3 nav-link text-body border-0 bg br-10 active" id="custom_email" data-bs-toggle="tab" data-bs-target="#custom_email" type="button" role="tab" aria-controls="custom_email" aria-selected="true">
                                                    <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
                                                </button>
                                                <button className="buttonArrow me-3 text-body nav-link border-0 bg br-10" id="generate_password" data-bs-toggle="tab" data-bs-target="#generate_password" type="button" role="tab" aria-controls="generate_password" aria-selected="false">
                                                    <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
                                                </button>
                                            </div>
                                            <div className='btn-save-hide'>
                                                <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="" icon={faFloppyDisk} name="Save" />
                                            </div>

                                        </span>
                                    </div>
                             
                           
                               
                           
                            </Tab.Content>


                        </div>
                    </div>
                </div>





            </Tab.Container>
          
        </div>
    )
}