import React from "react";
import { useState } from "react";

import pattern from "../../../../../../images/hire-me/desktopview/Dots.png";
import Dots from "../../../../../../images/hire-me/desktopview/Dotsblack.png";

import HireMeNav from "../components/HireMeNav/HireMeNav";
import HireMeMainContent from "../components/HireMeMainContent/HireMeMainContent";
import HireMePositionBox from "../components/HireMePositionBox/HireMePositionBox";
import HireMeForm from "../components/HireMeForm/HireMeForm";
import HireMeSideBox from "../components/HireMeSideBox/HireMeSideBox";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaBars,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaDribbble,
  FaPinterest,
  FaYoutube,
} from "react-icons/fa";

import book_btn from "../../../../../../images/met-book.png";
import glass_btn from "../../../../../../images/glass-btn.png";
import "./RegularTheme1Desktop.scss";
import "../regularTheme1Mobile/RegularTheme1Mobile.scss";
import "../regularTheme1Tab/RegularTheme1Tab.scss";
import {
  faCloudArrowDown,
  faInfo,
  faMagnifyingGlass,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import img1 from "../../../../../../images/img1.png";
import social_icon from "../../../../../../images/social-icons.png";
import second_3 from "../../../../../../images/second-3.png";
import dribble_dark from "../../../../../../images/dribble-dark.png";
import message from "../../../../../../images/message.png";
import answer from "../../../../../../images/answers.png";
import rank from "../../../../../../images/rank.png";
import gold_star from "../../../../../../images/gold-star.png";
import load from "../../../../../../images/load.png";
import vedio from "../../../../../../images/video.png";
import fb from "../../../../../../images/fb.png";
import mic_img from "../../../../../../images/mic-img.png";
import insta from "../../../../../../images/insta.png";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import {
  RiDownloadLine,
  RiPlayCircleFill,
  RiCloseLine,
  RiPlayMiniLine,
  RiShareForwardLine,
  RiFullscreenLine,
} from "react-icons/ri";
import sond_right from "../../../../../../images/sond-right.png";
import sond_left from "../../../../../../images/sond-left.png";
import sond_1 from "../../../../../../images/sond-1.png";
import sond_2 from "../../../../../../images/sond-2.png";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import { FaStar } from "react-icons/fa";
import { RiDownloadCloud2Line, RiInstagramFill } from "react-icons/ri";
import message_gold from "../../../../../../images/msg-gold.png";
import rank_gold from "../../../../../../images/rank-gold.png";

import cap_gold from "../../../../../../images/cap-gold.png";
import desktop_m from "../../../../../../images/desktop-m.png";
import Nav from "react-bootstrap/Nav";
import { RxGear, RxMagnifyingGlass } from "react-icons/rx";
import printer_est from "../../../../../../images/pinter-est.png";
import linked_in from "../../../../../../images/linked-in.png";
import you_tube from "../../../../../../images/you-tube.png";
import Social_2 from "../../../../../../images/Social-2.png";
import testimonial from "../../../../../../images/testimonial.png";
import testimonial_glass from "../../../../../../images/testimonial-glass.png";
import timeline_gold from "../../../../../../images/timeline-gold.png";
import navbar_shape from "../../../../../../images/navbar-shape.png";
import Vector_shape from "../../../../../../images/Vector-shape.png";
import circle_img from "../../../../../../images/circle-shape.png";
import dots from "../../../../../../images/Dots.png";
import meo_shape_right from "../../../../../../images/meo-shape-right.png";
import mue_shape from "../../../../../../images/mue-shape.png";
import desktop_img from "../../../../../../images/desktop.png";
import plalist_gold from "../../../../../../images/plalist-gold.png";
import timeline from "../../../../../../images/how-it-work/timeline.png";
import timeline_2 from "../../../../../../images/how-it-work/timeline-2.png";
import timeline_3 from "../../../../../../images/how-it-work/timeline-3.png";

export default function RegularTheme1Desktop({ Regular, Neumorphic }) {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [Competency1, setCompetency1] = useState(10);

  return (
    <>
      <div
        className="main-content RegularTheme1Desktop"
        style={{
          background: Regular ? "#CBD5E0" : Neumorphic ? "#EFF4F8" : "",
        }}
      >
        <div
          className="pattern"
          style={{ display: Regular ? "block" : "none" }}
        >
          <img src={Regular ? pattern : undefined} alt="" />
        </div>
        <div className="pattern-two">
          <img src={Dots} alt="" />
        </div>

        <div className="text-center-desk">
          <p>AWARDS</p>
        </div>
        <div className="position-nav-desk">
          <HireMeNav Regular={Regular} Neumorphic={Neumorphic} />
        </div>
        <div className="main-container-desk">
          <HireMeMainContent Regular={Regular} Neumorphic={Neumorphic} />
        </div>
        <div className="position-box-desk">
          <HireMePositionBox Regular={Regular} Neumorphic={Neumorphic} />
        </div>
        {/* Form - nav  */}
        <div className="form-position-desk">
          <HireMeForm Regular={Regular} Neumorphic={Neumorphic} />
        </div>
        {/* side-position-box */}
        <div className="side-position-box-desk">
          <HireMeSideBox Regular={Regular} Neumorphic={Neumorphic} />
        </div>
      </div>
    </>
  );
}
