import React from "react";
import ButtonBox from "../../../global/ButtonBox";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUsers, faUserGroup, faImage, faCloudUploadAlt, faCheckCircle, faCloudDownload, faLock } from '@fortawesome/free-solid-svg-icons'
import './ElectronicSignatureStep.scss';
import Select from 'react-select';
import { NavItem } from 'react-bootstrap';
import RangeSlider from "react-bootstrap-range-slider";
import ButtonBoxNew from "../../../global/ButtonBoxNew";
import DropdownBox from '../../../global/DropdownBox';
import { ReactComponent as Download } from "../../../../images/icons/icon29.svg";

export default function ElectronicSignatureForm() {

    // const options = [
    //     { value: 'Qwigley', label: 'Qwigley' },
    //     { value: 'Great Vibes', label: 'Great Vibes' },
    //     { value: 'Norican', label: 'Norican' }
    // ]
    const fontOptions =
    [{
        "name": "Qwigley",      
      }, {
        "name": "Great Vibes",
      }, {
        "name": "Norican",      
      }
    ] 
  
    const [Competency, setCompetency] = useState(10);
    const [Angle, setAngle] = useState(10);
    const openSelect = () => { inputRef.current.click(); }
    const inputRef = React.useRef();
    const [previewImage, setPreviewImage] = useState('');
    const [setImage] = useState('');

    const { t } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });

    return (
        <div>
            <ul className="TAB-8-form">
                <NavItem as="li" className="my-5 pb-4">
                    <h2 className="">{t('timeline_form_eight_component.set_your_electronic_signature')}</h2>
                    <div className="d-flex flex-column-reverse">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane listOne fade show active" id="profileOne" role="tabpanel" aria-labelledby="profileOne">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="user_type shadow-5in">
                                            <div>
                                                <div className="user_icon">
                                                    <FontAwesomeIcon icon={faUser} className=" icon" />
                                                </div>
                                                <h6>{t('timeline_form_eight_component.only_me')}</h6>
                                                <p>{t('timeline_form_eight_component.sign_a_document_yourself')}</p>
                                                {/* <button type="button" className="user_type_button">{t('timeline_form_eight_component.only_me')}</button>
                                                 */}
                                                <ButtonBoxNew  name="Only Me" boxwidth="w-90 mt-0 mb-4 mx-auto" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="user_type shadow-5in">
                                            <div>
                                                <div className="user_icon">
                                                    <FontAwesomeIcon icon={faUsers} className=" icon" />
                                                </div>
                                                <h6>{t('timeline_form_eight_component.me_and_others')}</h6>
                                                <p>{t('timeline_form_eight_component.send_a_document')}</p>
                                                {/* <button type="button" className="user_type_button">{t('timeline_form_eight_component.me_and_others')}</button> */}
                                                <ButtonBoxNew  name="Me & Others" boxwidth="w-100 mt-0 mb-4 mx-auto" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="user_type shadow-5in">
                                            <div>
                                                <div className="user_icon">
                                                    <FontAwesomeIcon icon={faUserGroup} className=" icon" />
                                                </div>
                                                <h6>{t('timeline_form_eight_component.only_other')}</h6>
                                                <p>{t('timeline_form_eight_component.send_a_document_for_others')}</p>
                                                {/* <button type="button" className="user_type_button">{t('timeline_form_eight_component.only_other')}</button> */}
                                                <ButtonBoxNew  name="Only Others" boxwidth="w-90 mt-0 mb-4 mx-auto" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" text-center time-btn mt-5">

                                        <ButtonBox
                                            boxwidth="w-150 mt-0 mb-50 mx-auto"
                                            clicked="Save/Next"
                                            name="Save/Next"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane listTwo fade" id="profileTwo" role="tabpanel" aria-labelledby="profileTwo">
                                <div>
                                    <div className="col-md-10 mx-auto mb-5">
                                        <label className="mb-1">{t('timeline_form_eight_component.enter_your_name')}</label>
                                        <input placeholder="Dyna Appolon" className='shadow-5in border-5 br-10  mb-0 me-3' type="text"></input>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div>
                                                        <h5>Signature</h5>
                                                        <div className="signature-button mb-2 d-flex justify-content-center align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                className="btn-check"
                                                                name="gender"
                                                                id="signature_1"
                                                                value="signature_1"
                                                                checked
                                                            />
                                                            <label className="btn btn-outline-primary dancing_font" htmlFor="signature_1">
                                                                Dyna Appolon
                                                                <FontAwesomeIcon icon={faCheckCircle} className=" icon" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div>
                                                        <h5>Initial</h5>
                                                        <div className="signature-button mb-2 d-flex justify-content-center align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                className="btn-check"
                                                                name="gender"
                                                                id="signature_1_init"
                                                                value="signature_1_init"
                                                                checked
                                                            />
                                                            <label className="btn btn-outline-primary dancing_font" htmlFor="signature_1_init">
                                                                DA
                                                                <FontAwesomeIcon icon={faCheckCircle} className=" icon" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div>
                                                        <h5>Signature</h5>
                                                        <div className="signature-button mb-2 d-flex justify-content-center align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                className="btn-check"
                                                                name="gender"
                                                                id="signature_2"
                                                                value="signature_2"
                                                            />
                                                            <label className="btn btn-outline-primary great_vibes_font" htmlFor="signature_2">
                                                                Dyna Appolon
                                                                <FontAwesomeIcon icon={faCheckCircle} className=" icon" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div>
                                                        <h5>Initial</h5>
                                                        <div className="signature-button mb-2 d-flex justify-content-center align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                className="btn-check"
                                                                name="gender"
                                                                id="signature_2_init"
                                                                value="signature_2_init"
                                                            />
                                                            <label className="btn btn-outline-primary great_vibes_font" htmlFor="signature_2_init">
                                                                DA
                                                                <FontAwesomeIcon icon={faCheckCircle} className=" icon" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div>
                                                        <h5>Signature</h5>
                                                        <div className="signature-button mb-2 d-flex justify-content-center align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                className="btn-check"
                                                                name="gender"
                                                                id="signature_3"
                                                                value="signature_3"
                                                            />
                                                            <label className="btn btn-outline-primary Sacramento_font" htmlFor="signature_3">
                                                                Dyna Appolon
                                                                <FontAwesomeIcon icon={faCheckCircle} className=" icon" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div>
                                                        <h5>Initial</h5>
                                                        <div className="signature-button mb-2 d-flex justify-content-center align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                className="btn-check"
                                                                name="gender"
                                                                id="signature_3_init"
                                                                value="signature_3_init"
                                                            />
                                                            <label className="btn btn-outline-primary Sacramento_font" htmlFor="signature_3_init">
                                                                DA
                                                                <FontAwesomeIcon icon={faCheckCircle} className=" icon" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div>
                                                        <h5>Signature</h5>
                                                        <div className="signature-button mb-2 d-flex justify-content-center align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                className="btn-check"
                                                                name="gender"
                                                                id="signature_4"
                                                                value="signature_4"
                                                            />
                                                            <label className="btn btn-outline-primary Madi_font" htmlFor="signature_4">
                                                                Dyna appolon
                                                                <FontAwesomeIcon icon={faCheckCircle} className=" icon" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div>
                                                        <h5>Initial</h5>
                                                        <div className="signature-button mb-2 d-flex justify-content-center align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                className="btn-check"
                                                                name="gender"
                                                                id="signature_4_init"
                                                                value="signature_4_init"
                                                            />
                                                            <label className="btn btn-outline-primary Madi_font" htmlFor="signature_4_init">
                                                                Da
                                                                <FontAwesomeIcon icon={faCheckCircle} className=" icon" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center text-center my-4">
                                        <p>OR</p>
                                    </div>
                                    <div className="mb-4 row justify-content-center text-center">
                                        <h6>PICK YOUR PREFERRED FONT STYLE</h6>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <div className=" position-relative">
                                                <label className="mb-2">Font</label>
                                                {/* <Select isSearchable={false} options={options} className="country mb-4 w-100 mx-auto" placeholder="Qwigley" theme={theme}
                                                    styles={{
                                                        option: (base) => ({
                                                            ...base,
                                                            borderRadius: '30px',
                                                            width: '94%',
                                                            height: '50px',
                                                            lineHeight: '32px',
                                                            margin: '10px',
                                                            cursor: 'pointer',
                                                            color: '#000000',
                                                            boxShadow: '2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',

                                                        }),
                                                    }} /> */}
                                                    <DropdownBox name="Font" boxid="font" options={fontOptions} boxwidth="w-100 mt-4 mx-auto"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="mb-1"> {t('timeline_form_eight_component.enter_your_name')}</label>
                                            <input placeholder="Dyna Appolon" className=' border-5 shadow-5in br-10 mt-4 mb-0 me-3' type="text"></input>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-8">
                                            <label className="signature_label mb-3" for="">Signature</label>
                                            <div className="signature_box shadow-5in">
                                                <span className="signature_font">
                                                    Dyna Appolon
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="signature_label mb-3" for="">Initial</label>
                                            <div className="signature_box">
                                                <span className="signature_font">
                                                    DA
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row justify-content-center items-center my-5">
                                        <div className="white_btn">
                                        <ButtonBoxNew  name=" Draw your own signature" boxwidth="w-220 mt-0 mb-4 bg2 br-40" />
                                           
                                        </div>

                                        <div className="green_btn ms-3">
                                        <ButtonBoxNew  name=" Accept signature" boxwidth="w-220 mt-0 mb-4 bg2 br-40" />

                                        </div>
                                    </div> */}
                                     <div className="flex justify-content-center items-center gap-4 mt-5">
                                            {/* <div className="white_btn">

                                                
                                            </div> */}
                                            <ButtonBoxNew  name=" Clear and draw again" boxwidth="w-220 mt-0 mb-4 bg2 br-40" />

                                            {/* <div className="green_btn ms-3">

                                            </div> */}
                                            <ButtonBoxNew  name=" Accept  signature" boxwidth="w-220 mt-0 mb-4 bg3 text-white br-40" />
                                            </div>
                                </div>
                            </div>
                            <div className="tab-pane listTwo fade" id="draw" role="tabpanel" aria-labelledby="draw">
                                <div>
                                    <div className="col-md-11 mx-auto">
                                        <h3 className="my-4 text-center"> {t('timeline_form_eight_component.draw_your_signature')}</h3>
                                        <div className="draw_box mb-4">
                                            <div className="draw_area">
                                                <span>{t('timeline_form_eight_component.dyna_appolon')}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div class="form-group row mt-4">
                                                    <label for="color" class="col-sm-2 col-form-label">Color</label>
                                                    <div class="col-sm-10">
                                                        <label for="" className="color_input">
                                                            <input type="color" class="form-control" id="color" placeholder="" />
                                                            #4770DA
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="slidecontainer mb- text-start">
                                                    <label className="mb-3">Width</label>
                                                    <RangeSlider value={Competency} onChange={changeEvent => setCompetency(changeEvent.target.value)} min={0} max={15} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="slidecontainer mb- text-start">
                                                    <label className="mb-3">Angle</label>
                                                    <RangeSlider value={Angle} onChange={changeEvent => setAngle(changeEvent.target.value)} min={0} max={15} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-content-center items-center gap-4 mt-5">
                                            {/* <div className="white_btn">

                                                
                                            </div> */}
                                            <ButtonBoxNew  name=" Clear and draw again" boxwidth="w-220 mt-0 mb-4 bg2 br-40" />

                                            {/* <div className="green_btn ms-3">

                                            </div> */}
                                            <ButtonBoxNew  name=" Accept this signature" boxwidth="w-300 mt-0 mb-4 bg3 text-white br-40" />
                                            </div>
                                            <div className="flex items-center justify-content-center gap-2">
                                                                <ButtonBoxNew  name="  Upload" boxwidth="w-220 mt-0 mb-4 bg2 br-40" />

                                                                    
                                                                    {/* <FontAwesomeIcon icon={faCloudUploadAlt} className="ms-1 icon" /> */}
                                             </div>
                                        
                                        <NavItem as="li" className=" text-center time-btn mt-4">

                                            <ButtonBox
                                                boxwidth="w-150 mt-0 mb-50 mx-auto"
                                                clicked="Save/Next"
                                                name="Save/Next"
                                            />
                                        </NavItem>
                                    </div>

                                </div>
                            </div>
                            <div className="tab-pane listTwo fade" id="upload" role="tabpanel" aria-labelledby="upload">
                                <div className="col-md-5 mx-auto text-center my-4">
                                    <label className="mb-4">UPload your signature</label>
                                    <div className="custom-file-drop-area ">
                                        <div className="imgView">
                                            <div className="mx-auto mb-4 fileTab h-100">
                                                <label onClick={openSelect} className="w-100 h-100">
                                                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                                                        {previewImage ? (
                                                            <img
                                                                src={previewImage}
                                                                alt=""
                                                                className="w-100 h-100"
                                                            />
                                                        ) : (
                                                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                                                <FontAwesomeIcon icon={faImage} className=" icon" />
                                                                <p className="mb-3 mt-1">Drag and Drop
                                                                    <br />
                                                                    OR
                                                                    <br />
                                                                    <span className="">Upload your signature here</span></p>
                                                                <div className="flex items-center gap-2">
                                                                <ButtonBoxNew  name="  Upload" boxwidth="w-220 mt-0 mb-4 bg2 br-40" />

                                                                    
                                                                    {/* <FontAwesomeIcon icon={faCloudUploadAlt} className="ms-1 icon" /> */}
                                                                </div>
                                                                

                                                            </div>
                                                        )}
                                                    </div>
                                                </label>
                                            </div>
                                            <input
                                                hidden
                                                type="file"
                                                name="profile"
                                                id="profile"
                                                accept="image/*"
                                                onChange={(e) => {
                                                    let url = URL.createObjectURL(e.target.files[0]);
                                                    setPreviewImage(url);
                                                    setImage(e.target.files[0]);
                                                }}
                                                ref={inputRef}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className=" text-center time-btn mt-5">

                                    <ButtonBox
                                        boxwidth="w-150 mt-0 mb-50 mx-auto"
                                        clicked="Save/Next"
                                        name="Save/Next"
                                    />
                                </div>
                            </div>
                            <div className="tab-pane listTwo fade" id="confirm" role="tabpanel" aria-labelledby="confirm">
                                <div>
                                    <h1 className="text-center my-4">signature and confirmation of identity</h1>
                                    <div className="col-md-10 mx-auto mb-5">
                                        <label className="mb-1">  {t('first_name')}</label>
                                        <input placeholder={t('timeline_form_seven_component.dyna')} className='shadow-5in border-5 br-10  mb-0 me-3' type="text"></input>
                                    </div>
                                    <div className="col-md-10 mx-auto mb-5">
                                        <label className="mb-1">  {t('last_name')}</label>
                                        <input placeholder={t('timeline_form_seven_component.appolon')} className=' border-5 shadow-5in br-10  mb-0 me-3' type="text"></input>
                                    </div>
                                    <div className="col-md-10 mx-auto mb-5">
                                        <label className="mb-1">  {t('timeline_form_eight_component.email')}</label>
                                        <input placeholder="dyna@9to5escape.com" className=' border-5 shadow-5in br-10  mb-0 me-3' type="text"></input>
                                    </div>
                                    <div className="col-md-10 mx-auto mb-5">
                                        <label className="mb-1 flex justify-content-between">
                                            <div className="w_50">
                                                {t('timeline_form_eight_component.ip_address')}
                                            </div>
                                            <div className="flex justify-content-between w_50 pe-5">
                                                <div>
                                                    Date
                                                </div>
                                                <div className="pe-3">
                                                    Time
                                                </div>
                                            </div>
                                        </label>
                                        <input placeholder="192.168.1.1" className=' border-5 ip_input br-10  mb-0 me-3' type="text"></input>
                                    </div>
                                    <div className="col-md-11 final_signature">
                                        <div className="row px-3 items-center my-4">
                                            <div className="col-md-7">
                                                <label className="mb-2">YOUR FINAL ELECTRONIC SIGNATURE</label>
                                                <div className="signature_box relative">
                                                    <span>Dyna Appolon</span>
                                                    <div className="signature_icon">
                                                        {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                                                    <Download />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1 px-3">AND</div>
                                            <div className="col-md-4">
                                                <label className="mb-2">INITIAL</label>
                                                <div className=" signature_box relative">
                                                    <span>DA</span>
                                                    <div className="signature_icon">
                                                        {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                                                    <Download />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0 h8 check-button mx-auto mb-2 d-flex justify-content-start mb-3'>
                                            <input type="checkbox" className="btn-check" name="communication-day" id="accept" />
                                            <label className="btn btn-outline-primary" htmlFor="accept">
                                                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                                                </svg>
                                            </label>
                                            By clicking "Set E-Signature", I agree that the signature and initial appearing herein will be my legal signature for purposes of using this platform, and any Document I execute using this electronic signature will have the same effect as if I had signed my name or initials in person.
                                        </div>
                                        <div className='col-md-12 p-0 h8 check-button mx-auto mb-2 d-flex justify-content-between align-items-center'>
                                            <div>
                                                <input type="checkbox" className="btn-check" name="communication-day" id="signature" />
                                                <label className="btn btn-outline-primary" htmlFor="signature">
                                                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                                                    </svg>
                                                </label>
                                                {t('timeline_form_eight_component.remember_me_in_singature')}
                                            </div>
                                            <div>
                                                <div className="relative">
                                                <ButtonBoxNew  name="  Download Both" boxwidth="w-220 mt-0 mb-4 bg2 br-40" />

                                                  
                                                    {/* <div className="signature_icon">
                                                        <FontAwesomeIcon icon={faCloudDownload} className=" icon" />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0 h8 check-button mx-auto d-flex justify-content-center  gap-3 my-5 align-items-center'>
                                            {/* <div className="yellow_download_btn me-5">
                                                Generate QR Code
                                            </div> */}
                                                                <ButtonBoxNew  name="Generate QR Code" boxwidth="w-220 mt-0 mb-4 bg2 br-40" />

                                            <div className="flex items-center gap-10">
                                                <div className="QR_outer_div">
                                                    <div className="QR_box">
                                                        <FontAwesomeIcon icon={faLock} className=" icon" />
                                                    </div>
                                                </div>
                                                <span>Save QR code</span>
                                                <div className="download_icon">
                                                    {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                                                    <Download />
                                                </div>
                                            </div>
                                        </div>


                                        <NavItem as="li" className=" text-center time-btn mt-4 rotate_btn">

                                            <ButtonBox
                                                boxwidth="w-350 text_light mt-0 mb-50 mx-auto"
                                                clicked="Set E-Signature"
                                                name="Set E-Signature"
                                            />
                                        </NavItem>
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                        <span className="d-flex align-items-center justify-content-between w-100 mb-0 TabBtns">
                            <div id="myTab1" className='w-100' role="tablist">
                                <ul className="d-flex justify-content-center my-4 pb-2">
                                    <NavItem as="li" className="me-0">
                                        <button className="listOne m-0 firsttab border-0 active" id="profileOne" data-bs-toggle="tab" data-bs-target="#profileOne" type="button" role="tab" aria-controls="profileOne" aria-selected="true">
                                            Who Needs to Sign?
                                        </button>
                                    </NavItem>
                                    <NavItem as="li">
                                        <button className="listTwo m-0 border-0 sectab " id="profileTwo" data-bs-toggle="tab" data-bs-target="#profileTwo" type="button" role="tab" aria-controls="profileTwo" aria-selected="false">
                                            Type
                                        </button>
                                    </NavItem>
                                    <NavItem as="li">
                                        <button className="listTwo m-0 border-0 thrdtab" id="draw" data-bs-toggle="tab" data-bs-target="#draw" type="button" role="tab" aria-controls="draw" aria-selected="false">
                                            Draw
                                        </button>
                                    </NavItem>
                                    <NavItem as="li">
                                        <button className="listTwo m-0 border-0 thrdtab" id="upload" data-bs-toggle="tab" data-bs-target="#upload" type="button" role="tab" aria-controls="upload" aria-selected="false">
                                            Upload
                                        </button>
                                    </NavItem>
                                    <NavItem as="li">
                                        <button className="listTwo m-0 border-0 thrdtab" id="confirm" data-bs-toggle="tab" data-bs-target="#confirm" type="button" role="tab" aria-controls="confirm" aria-selected="false">
                                            Confirm
                                        </button>
                                    </NavItem>
                                </ul>
                            </div>
                        </span>
                    </div>
                </NavItem>

                {/*            
                <NavItem as="li" className="mb-5 border-box">
                  
                </NavItem> */}
                {/* <NavItem as="li" className=" text-center time-btn mt-4">

                    <ButtonBox
                        boxwidth="w-150 mt-0 mb-50 mx-auto"
                        clicked="Save/Next"
                        name="Save/Next"
                    />
                </NavItem> */}
            </ul>
        </div >
    );
}
