import React from "react";
import './EmailSignatureStep.scss';
import ButtonBox from "../../../global/ButtonBox";
import Contest from '../../../../images/img29.png';
import Contest1 from '../../../../images/img29s.png';
import Contest2 from '../../../../images/img29ss.png';
import Contest3 from '../../../../images/img29sss.png';
import brcode from '../../../../images/img30.png';
import user from '../../../../images/img32.png';
import user3 from '../../../../images/img1.png';
import label from '../../../../images/img31.png';
import label1 from '../../../../images/img31s.png';
import user2 from '../../../../images/img43.png';
import lines from '../../../../images/img40.png';
import user4 from '../../../../images/img43s.png';
import { Link } from 'react-router-dom';

// import Select from 'react-select';
import { ReactComponent as IconBlue1 } from "../../../../images/social-icons/fb.svg";
import { ReactComponent as IconBlue2 } from "../../../../images/social-icons/instagram.svg";
import { ReactComponent as IconBlue3 } from "../../../../images/social-icons/twitter.svg";
import { ReactComponent as IconBlue4 } from "../../../../images/social-icons/linkedin.svg";
import { ReactComponent as IconBlue5 } from "../../../../images/social-icons/Group.svg";
import { ReactComponent as IconBlue6 } from "../../../../images/social-icons/dribble.svg";
import { ReactComponent as IconBlue7 } from "../../../../images/social-icons/tiktok.svg";
import { ReactComponent as IconBlue8 } from "../../../../images/social-icons/pinterest.svg";
import { ReactComponent as IconBlue9 } from "../../../../images/social-icons/youtube.svg";
import { ReactComponent as IconBlue10 } from "../../../../images/social-icons/phonegold.svg";
import { ReactComponent as IconBlue11 } from "../../../../images/social-icons/mailgold.svg";
import { ReactComponent as IconBlue12 } from "../../../../images/social-icons/linkgold.svg";
import { ReactComponent as IconBlue13 } from "../../../../images/social-icons/locationgold.svg";
import { ReactComponent as IconBlue14 } from "../../../../images/icons/icon41.svg";
import { ReactComponent as IconBlue15 } from "../../../../images/icons/icon42.svg";
import { ReactComponent as IconBlue16 } from "../../../../images/icons/icon43.svg";
import { ReactComponent as IconBlue17 } from "../../../../images/icons/icon44.svg";
import { ReactComponent as IconBlue18 } from "../../../../images/icons/icon54.svg";
import { ReactComponent as IconBlue19 } from "../../../../images/icons/icon46.svg";
import { ReactComponent as IconBlue20 } from "../../../../images/icons/icon47.svg";
import { ReactComponent as IconBlue21 } from "../../../../images/icons/icon48.svg";
import { ReactComponent as IconBlue22 } from "../../../../images/icons/icon49.svg";
import { ReactComponent as IconBlue23 } from "../../../../images/icons/icon50.svg";
import { ReactComponent as IconBlue24 } from "../../../../images/icons/icon51.svg";
import { ReactComponent as IconBlue25 } from "../../../../images/icons/icon52.svg";
import { ReactComponent as IconBlue26 } from "../../../../images/icons/icon53.svg";
import { ReactComponent as IconBlue27 } from "../../../../images/email_icons/icon1.svg";
import { ReactComponent as IconBlue28 } from "../../../../images/email_icons/icon2.svg";
import { ReactComponent as IconBlue29 } from "../../../../images/email_icons/icon3.svg";
import { ReactComponent as IconBlue30 } from "../../../../images/email_icons/icon4.svg";
import { ReactComponent as IconBlue31 } from "../../../../images/email_icons/icon5.svg";
import { ReactComponent as IconBlue32 } from "../../../../images/slider1/fb.svg";
import { ReactComponent as IconBlue33 } from "../../../../images/slider1/insta.svg";
import { ReactComponent as IconBlue34 } from "../../../../images/slider1/twitter.svg";
import { ReactComponent as IconBlue35 } from "../../../../images/slider1/linkedin.svg";
import { ReactComponent as IconBlue36 } from "../../../../images/slider1/social.svg";
import { ReactComponent as IconBlue37 } from "../../../../images/slider1/dribble.svg";
import { ReactComponent as IconBlue38 } from "../../../../images/slider1/tiktok.svg";
import { ReactComponent as IconBlue39 } from "../../../../images/slider1/pinterest.svg";
import { ReactComponent as IconBlue40 } from "../../../../images/slider1/youtube.svg";
import EmailTemp from "../../../global/EmailTemp";
import EmailTempBm from "../../../global/EmailTempBm";
import EmailDesign1 from "./EmailComponent/EmailDesign1";
import EmailDesign2 from "./EmailComponent/EmailDesign2";
import EmailDesign3 from "./EmailComponent/EmailDesign3";
import EmailDesign4 from "./EmailComponent/EmailDesign4";

export default function EmailSignatureForm3() {
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]

  return (
    <div>
      {/* 1st section */}
      <EmailDesign1 />
      {/* 2nd section */}
      <EmailDesign2 />
      {/* 3rd section */}
      <EmailDesign3 />
      {/* 4th section */}
      <EmailDesign4 />
      <div className="text-center w-100">
          <ButtonBox
            boxwidth="w-300 mt-0 mb-50 mx-auto"
            clicked="Generated Email Signature"
            name="Generate More Email Signature"
          />
      </div>
    </div>
  );
} 
