import React from "react";
import ToggleButton from "../../../../../global/ToggleButton";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function ProfileGroupOne() {
  const { t, i18n } = useTranslation();

  return (
    <div className="shadow-5in w-100 p-40 br-30 mb-4">
      <h3 className="mb-5">{t('profile_section.your_basic_business_info')}</h3>
      <ul className="row">
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="FIRST NAME" id="FIRSTNAME" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="LAST NAME" id="LASTNAME" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="BUSINESS TITLE" id="BUSINESSTITLE" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="PREFERRED PROFILE NAME | BUSINESS NAME" id="PREFERREDNAME" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="BUSINESS INDUSTRY" id="BUSINESSINDUSTRY" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="BUSINESS NICHE" id="BUSINESSNICHE" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="website" id="website" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="CONTACT | WHATSAPP NUMBER" id="CONTACTNUMBER" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="PUBLIC EMAIL" id="PUBLICEMAIL" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="BUSINESS EMAIL" id="BUSINESSEMAIL" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="BUSINESS/company name" id="BUSINESSNAME" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="BUSINESS HEADQUARTER LOCATION" id="BUSINESSLOCATION" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="JOINED US SINCE" id="JOINEDSINCE" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="BUSINESS MISSION STATEMENT" id="BUSINESSSTATEMENT" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="TAGLINE | SLOGAN" id="TAGLINESLOGAN" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="BRAND POSITION STATEMENT" id="BRANDPOSITIONSTATEMENT"  check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="BUSINESS BIO" id="BUSINESSBIO" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="USERNAME" id="USERNAME" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Preferred Time of Communication" id="PreferrerCommunication" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="QR Code" id="QRCode" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Electronic Signature" id="ElectronicSignature" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="IDENTIFIES AS" id="IDENTIFIESAS" check="checked" />
        </NavItem>
      </ul>
    </div>
  );
}
