import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './WeekendModeSectionThemes.scss';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { faLock } from "@fortawesome/free-solid-svg-icons";
import active_vector from '../../../../images/active-vector.png'
import ComingSoonSectionRegularTheme from "./comingSoon/ComingSoonSectionRegularTheme";
import ComingSoonSectionNeumorphicTheme from "./comingSoon/ComingSoonSectionNeumorphicTheme";
import ComingSoonSectionGlassmorphicTheme from "./comingSoon/ComingSoonSectionGlassmorphicTheme";

export default function ComingSoonSectionThemes() {
  return (
    <div className="pt-4">
      <h4 className="text-center">
        Switch the Style
      </h4>
          
      <Tab.Container id="left-tabs-example" defaultActiveKey="Regular">
      <div className="d-flex flex-column-reverse">

      <Tab.Content>
            <Tab.Pane eventKey="Regular">
            <ComingSoonSectionRegularTheme />
 
            </Tab.Pane>
            <Tab.Pane eventKey="Neumorphic">
            <ComingSoonSectionNeumorphicTheme />

            </Tab.Pane>
            <Tab.Pane eventKey="Glassmorphic">
            <ComingSoonSectionGlassmorphicTheme />

            </Tab.Pane>
          </Tab.Content>
<div className="d-flex align-items-center justify-content-between services-work  w-100 mb-0 ">
<Nav variant="pills" className="w-100 d-flex  align-items-center justify-content-center">
<ul className="d-flex justify-content-center my-4 pb-2 items-center">

  <Nav.Item as="li" className="me-0">
    <Nav.Link className="m-0 p-0 border-0 design-one " eventKey="Regular">
    <div className="active-sign">
          <div className="active-icon-color " >
            <img src={active_vector} className="active-vector" alt="active-icon" ></img>
          </div>
        </div>
        <span>Regular</span>
    </Nav.Link>
  </Nav.Item>
  <Nav.Item as="li" className="margin-left-scnd pl-3">
    <Nav.Link className="m-0 p-0 border-0 design-two ml-3" eventKey="Neumorphic">
    <div className="active-sign">
          <div className="active-icon-color-second " >
            <img src={active_vector} className="active-vector" alt="active-icon" ></img>
          </div>
        </div>  <span>Neumorphic <FontAwesomeIcon className="lock" icon={faLock} ></FontAwesomeIcon> </span>
    </Nav.Link>
    
  </Nav.Item>
  <Nav.Item as="li" className="margin-left-scnd pl-3">
    <Nav.Link className="m-0 p-0 border-0 design-three ml-3" eventKey="Glassmorphic">
    <div className="active-sign">
          <div className="active-icon-color-third " >
            <img src={active_vector} className="active-vector" alt="active-icon" ></img>
          </div>
        </div>      <span>Glassmorphic <FontAwesomeIcon className="lock" icon={faLock} ></FontAwesomeIcon> </span>

    </Nav.Link>
    
  </Nav.Item>
  </ul>
</Nav>
</div>

</div>
</Tab.Container>

    </div >
  );
}
