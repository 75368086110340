import React, { useState, useEffect } from 'react';

import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';



import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import desktop_img from '../../../../../images/desktop.png';

import { useTranslation } from "react-i18next";



export default function HeroSectionComponent() {
  const { t, i18n } = useTranslation();


  return (
  <div>
       <img src={desktop_img} alt="desktop" className="content-img hero-section-reg" >
                  </img>
  </div>


  );
}
