import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide0 from '../../images/slider2/01.png';
import Slide1 from '../../images/slider2/02.png';
import Slide2 from '../../images/slider2/03.png';
import Slide3 from '../../images/slider2/04.png';
import Slide4 from '../../images/slider2/05.png';
import Slide5 from '../../images/slider2/06.png';
import Slide6 from '../../images/slider2/07.png';
import Slide7 from '../../images/slider2/08.png';
import Slide8 from '../../images/slider2/09.png';
import Slide9 from '../../images/slider2/10.png';
import Slide10 from '../../images/slider2/11.png';
import Slide11 from '../../images/slider2/12.png';
import Slide12 from '../../images/slider2/13.png';
import Slide13 from '../../images/slider2/14.png';
import Slide14 from '../../images/slider2/15.png';

import DigitalBusinessCard1 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard1";
import DigitalBusinessCard2 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard2";
import DigitalBusinessCard3 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard3";
import DigitalBusinessCard4 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard4";
import DigitalBusinessCard5 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard5";
import DigitalBusinessCard6 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard6";
import DigitalBusinessCard7 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard7";
import DigitalBusinessCard8 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard8";
import DigitalBusinessCard9 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard9";
import DigitalBusinessCard10 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard10";
import DigitalBusinessCard11 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard11";
import DigitalBusinessCard12 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard12";
import DigitalBusinessCard13 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard13";
import DigitalBusinessCard14 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard14";
import DigitalBusinessCard15 from "../pages/threeHoursBusinessLaunch/digitalBusinessCard/DigitalBusinessCard/DigitalBusinessCard15";


export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
      <div className="slideremail">
        <div className="px-0 mb-30 emailslider">
          <div className="py-3 px-2">
            <Slider className="ProfileTrack"
              asNavFor={this.state.nav1}
              ref={slider => (this.slider2 = slider)}
              slidesToShow={2}
              swipeToSlide={true}
              focusOnSelect={true}
              arrows={false}
              variableWidth={true}
            >
              <div>
                <div className="shadow-4 p-10 h-160 br-10 mx-1 slidimg">
                  <img src={Slide0} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-10 h-160 br-10 mx-1 slidimg">
                  <img src={Slide1} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-10 h-160 br-10 mx-1 slidimg">
                  <img src={Slide2} alt="" className="" />              
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 slidimg">
                  <img src={Slide3} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 slidimg">
                  <img src={Slide4} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                  <img src={Slide5} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                  <img src={Slide6} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                  <img src={Slide7} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                  <img src={Slide8} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                  <img src={Slide9} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                  <img src={Slide10} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                  <img src={Slide11} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                  <img src={Slide12} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                  <img src={Slide13} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                  <img src={Slide14} alt="" className="" />
                </div>
              </div>
            </Slider>

          </div>
        </div>
        <Slider className=""
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
        >
          <div>
            <DigitalBusinessCard1 />
          </div>
          <div>
            <DigitalBusinessCard2 />
          </div>
          <div>
            <DigitalBusinessCard3 />
          </div>
          <div>
            <DigitalBusinessCard4 />
          </div>
          <div>
            <DigitalBusinessCard5 />
          </div>
          <div>
            <DigitalBusinessCard6 />
          </div>
          <div>
            <DigitalBusinessCard7 />
          </div>
          <div>
            <DigitalBusinessCard8 />
          </div>
          <div>
            <DigitalBusinessCard9 />
          </div>
          <div>
            <DigitalBusinessCard10 />
          </div>
          <div>
            <DigitalBusinessCard11 />
          </div>
          <div>
            <DigitalBusinessCard12 />
          </div>
          <div>
            <DigitalBusinessCard13 />
          </div>
          <div>
            <DigitalBusinessCard14 />
          </div>
          <div>
            <DigitalBusinessCard15 />
          </div>
         
        </Slider>
        
      </div>
    );
  }
}