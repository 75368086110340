import {combineReducers} from 'redux';
import authReducer from './authReducer';
import subscriptionReducer from './subscriptionReducer';
import videoReducer from './videoReducer';


export default combineReducers({
  authReducer,
  subscriptionReducer,
  videoReducer
  
});