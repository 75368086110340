import React from "react";
import ReactStars from "react-rating-stars-component";

import avatar from "../../../../../images/testimonial-case-study/regular4_desktop_avatar.png";

import regular4_desktop_right_comma from "../../../../../images/testimonial-case-study/regular4_desktop_right_comma.png";
import regular4_desktop_left_comma from "../../../../../images/testimonial-case-study/regular4_desktop_left_comma.png";

import regular4_mobile_right_comma from "../../../../../images/testimonial-case-study/regular4_mobile_right_comma.png";
import regular4_mobile_left_comma from "../../../../../images/testimonial-case-study/regular4_mobile_left_comma.png";

import regular4_desktop_mask from "../../../../../images/testimonial-case-study/regular4_desktop_mask.png";
import regular4_mobile_mask from "../../../../../images/testimonial-case-study/regular4_mobile_mask.png";

import neumorphic4_desktop_right_comma from "../../../../../images/testimonial-case-study/neumorphic4_desktop_right_comma.png";
import neumorphic4_desktop_left_comma from "../../../../../images/testimonial-case-study/neumorphic4_desktop_left_comma.png";

import neumorphic4_mobile_right_comma from "../../../../../images/testimonial-case-study/neumorphic4_mobile_right_comma.png";
import neumorphic4_mobile_left_comma from "../../../../../images/testimonial-case-study/neumorphic4_mobile_left_comma.png";

import neumorphic4_desktop_mask from "../../../../../images/testimonial-case-study/neumorphic4_desktop_mask.png";
import neumorphic4_mobile_mask from "../../../../../images/testimonial-case-study/neumorphic4_mobile_mask.png";

import glassmorphic4_desktop_mask from "../../../../../images/testimonial-case-study/glassmorphic4_desktop_mask.png";
import glassmorphic4_mobile_mask from "../../../../../images/testimonial-case-study/glassmorphic4_mobile_mask.png";

import "./RegularTheme4.scss";

export default function RegularTheme4(props) {
  const ratingChanged = (newRating) => {
  };

  let renderedMask;
  let rederedLeftComma;
  let renderedRightComma;

  if (props.themeType === "Regular") {
    if (props.screenMode === "three") {
      renderedMask = regular4_desktop_mask;
    } else {
      renderedMask = regular4_mobile_mask;
    }
  } else if (props.themeType === "Neumorphic") {
    if (props.screenMode === "three") {
      renderedMask = neumorphic4_mobile_mask;
    } else {
      renderedMask = neumorphic4_desktop_mask;
    }
  } else {
    if (props.screenMode === "three") {
      renderedMask = glassmorphic4_mobile_mask;
    } else {
      renderedMask = glassmorphic4_desktop_mask;
    }
  }

  if (props.themeType === "Regular") {
    if (props.screenMode === "three") {
      rederedLeftComma = regular4_mobile_left_comma;
      renderedRightComma = regular4_mobile_right_comma;
    } else {
      rederedLeftComma = regular4_desktop_left_comma;
      renderedRightComma = regular4_desktop_right_comma;
    }
  } else {
    if (props.screenMode === "three") {
      rederedLeftComma = neumorphic4_mobile_left_comma;
      renderedRightComma = neumorphic4_mobile_right_comma;
    } else {
      rederedLeftComma = neumorphic4_desktop_left_comma;
      renderedRightComma = neumorphic4_desktop_right_comma;
    }
  }

  return (
    <div className="dot-container">
      <div className="profile-div">
        <div className="mask-img-div">
          {" "}
          <img src={renderedMask} alt="Regular Version 4 Mask" srcset="" />
        </div>
        <div className="inner-circle-div">
          <div className="inverted-comma">
            <div className="left-comma-div">
              <img src={rederedLeftComma} alt="" />
            </div>
            {" . "}
            <div className="right-comma-div">
              <img src={renderedRightComma} alt="" />
            </div>
          </div>
        </div>

        <div className="avatar-div">
          <img src={avatar} alt="Regular Theme 2 Avatar" srcset="" />
        </div>

        <div className="profile-detail-div">
          <h1>DYNA APPOLON</h1>
          <p>CEO | SOCIAL NETWORK STRATEGIST</p>

          <h3>
            “Lorem ipsum dolor sit amet consectetur. Duis elementum varius duis
            eu donec. Tellus ut metus sed id mi consequat odio nunc velit.”
          </h3>
        </div>
        <div className="rating-stars-div">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={20}
            activeColor="#E28D0E"
          />
        </div>
      </div>
      <div className="see-my-wall-div">
        <p>SEE MY</p>
        <a href="#">WALL OF LOVE</a>
      </div>
    </div>
  );
}
