import React from "react";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
// import { Link } from 'react-router-dom';
import ButtonBox from '../../../global/ButtonBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import BillingAddressTab from "./BillingAddressTab";
import ShippingTab from "./ShippingTab";
import { NavItem } from 'react-bootstrap';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';


export default function AddressInfoTab() {
  const { t, i18n } = useTranslation();
  let {isVideoVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };
  return (
    <div>
      {/* <div className="w-780 mb-4 align-items-center mx-auto d-flex justify-content-between"> */}
        <div className="w-780 mb-4 align-items-center mx-auto d-flex flex-column-reverse justify-content-between">
          <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto mb-5">
          <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
            <h3 className="mb-3 d-flex justify-content-between w-600 pe-5">{t('profile_section.enter_your_profile_details')} <small>
              {t('step')} <b>3</b> of 11</small></h3>
            <div className="ProgressBar">
              <ul>
                  <NavItem as="li" className="active" id="profile"></NavItem>
                  <NavItem as="li" className="active" id="chat"></NavItem>
                  <NavItem as="li" className="active" id="location"></NavItem>
                  <NavItem as="li" id="user"></NavItem>
                  <NavItem as="li"  id="briefcase"></NavItem>
                  <NavItem as="li"  id="graduation-cap"></NavItem>
                  <NavItem as="li"  id="favorite"></NavItem>
                  <NavItem as="li" id="award"></NavItem>
                  <NavItem as="li" id="connectivity"></NavItem>
                  <NavItem as="li" id="lock"></NavItem>
                  <NavItem as="li" id="eye"></NavItem>
              </ul>
            </div>

            <div className="d-flex flex-column-reverse">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="billingAddress" role="tabpanel" aria-labelledby="billingAddress">
                  {/* <BillingAddressTab /> */}
                </div>
                <div className="tab-pane fade" id="shippingAddress" role="tabpanel" aria-labelledby="shippingAddress">
                  {/* <ShippingTab /> */}
                </div>
                <div className="mt-4 text-center">
                  <ButtonBox boxwidth="w-140 mt-0 mb-50 mx-auto" clicked="Saved" name="Save/Next" />
                </div>
              </div>
              <div className="text-center mb-3">
              <div className='w-100'>
                    <div className='d-none d-lg-block'>
                        <div className='scroll-bar w-100 shadow-4in br-20 my-30'></div>
                    </div>
                    <Tabs
                      defaultActiveKey="billing"
                      transition={false}
                      id="noanim-tab-example"
                      className="login-tab mb-4 bgLine"
                    >
                      <Tab eventKey="billing" title="BILLING ADDRESS">
                        <h5 className="mb-4">{t('profile_section.billing_address')}</h5>
                        <div className="mb-5">
                          <label className="w-100 text-start mb-3">{t('profile_section.same_as')}</label>
                          <div className="bgLine radiobtn">
                            <span className='me-0 d-flex shadow-4 bg h-50s w-145 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="billsame" id="billsame1" autoComplete="off" checked />
                                <label className="btn btnRadio large" htmlFor="billsame1">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.billing_address')}</h4>
                            </span>
                            <span className='me-0 d-flex shadow-4 bg h-50s w-145 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="billsame" id="billsame2" autoComplete="off" />
                                <label className="btn btnRadio large" htmlFor="billsame2">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.shipping_address')}</h4>
                            </span>
                            <span className='me-0 d-flex shadow-4 bg h-50s w-240 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="billsame" id="billsame3" autoComplete="off" />
                                <label className="btn btnRadio large" htmlFor="billsame3">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.business_office_headquarter_address')}</h4>
                            </span>
                            <span className='me-0 d-flex shadow-4 bg h-50s w-168 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="billsame" id="billsame4" autoComplete="off" />
                                <label className="btn btnRadio large" htmlFor="billsame4">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.home_address')}</h4>
                            </span>
                          </div>
                        </div>
                        <BillingAddressTab />
                      </Tab>
                      <Tab eventKey="shipping" title="SHIPPING ADDRESS">
                        <h5 className="mb-4">{t('profile_section.shipping_address')}</h5>
                        <div className="mb-4">
                          <label className="w-100 text-start mb-3">{t('profile_section.same_as')}</label>
                          <div className="bgLine radiobtn">
                            <span className='me-0 d-flex shadow-4 bg h-50s w-145 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="shipsame" id="shipsame1" autoComplete="off" />
                                <label className="btn btnRadio large" htmlFor="shipsame1">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.billing_address')}</h4>
                            </span>
                            <span className='me-0 d-flex shadow-4 bg h-50s w-145 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="shipsame" id="shipsame2" autoComplete="off" checked />
                                <label className="btn btnRadio large" htmlFor="shipsame2">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.shipping_address')}</h4>
                            </span>
                            <span className='me-0 d-flex shadow-4 bg h-50s w-240 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="shipsame" id="shipsame3" autoComplete="off" />
                                <label className="btn btnRadio large" htmlFor="shipsame3">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.business_office_headquarter_address')}</h4>
                            </span>
                            <span className='me-0 d-flex shadow-4 bg h-50s w-168 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="shipsame" id="shipsame4" autoComplete="off" />
                                <label className="btn btnRadio large" htmlFor="shipsame4">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.home_address')}</h4>
                            </span>
                          </div>
                        </div>
                        <ShippingTab />
                      </Tab>
                      <Tab className="" eventKey="business" title="BUSINESS OFFICE | HEADQUARTER ADDRESS">
                        <h5 className="mb-4 w-100">{t('profile_section.business_office_headquarter_address')}</h5>
                        <div className="mb-4 w-100">
                          <label className="w-100 text-start mb-3">{t('profile_section.same_as')}</label>
                          <div className="bgLine radiobtn">
                            <span className='me-0 d-flex shadow-4 bg h-50s w-145 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="bussame" id="bussame1" autoComplete="off" />
                                <label className="btn btnRadio large" htmlFor="bussame1">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.billing_address')}</h4>
                            </span>
                            <span className='me-0 d-flex shadow-4 bg h-50s w-145 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="bussame" id="bussame2" autoComplete="off" />
                                <label className="btn btnRadio large" htmlFor="bussame2">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.shipping_address')}</h4>
                            </span>
                            <span className='me-0 d-flex shadow-4 bg h-50s w-240 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="bussame" id="bussame3" autoComplete="off"  checked/>
                                <label className="btn btnRadio large" htmlFor="bussame3">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.business_office_headquarter_address')}</h4>
                            </span>
                            <span className='me-0 d-flex shadow-4 bg h-50s w-168 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="bussame" id="bussame4" autoComplete="off" />
                                <label className="btn btnRadio large" htmlFor="bussame4">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.home_address')}</h4>
                            </span>
                          </div>
                        </div>
                        <BillingAddressTab />
                      </Tab>
                      <Tab eventKey="haddress" title="HOME ADDRESS">
                        <h5 className="mb-4">{t('profile_section.home_address')}</h5>
                        <div className="mb-4">
                          <label className="w-100 text-start mb-3">{t('profile_section.same_as')}</label>
                          <div className="bgLine radiobtn">
                            <span className='me-0 d-flex shadow-4 bg h-50s w-145 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="hmsame" id="hmsame1" autoComplete="off" />
                                <label className="btn btnRadio large" htmlFor="hmsame1">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.billing_address')}</h4>
                            </span>
                            <span className='me-0 d-flex shadow-4 bg h-50s w-145 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="hmsame" id="hmsame2" autoComplete="off" />
                                <label className="btn btnRadio large" htmlFor="hmsame2">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.shipping_address')}</h4>
                            </span>
                            <span className='me-0 d-flex shadow-4 bg h-50s w-240 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="hmsame" id="hmsame3" autoComplete="off" />
                                <label className="btn btnRadio large" htmlFor="hmsame3">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.business_office_headquarter_address')}</h4>
                            </span>
                            <span className='me-0 d-flex shadow-4 bg h-50s w-168 align-items-center justify-content-center'>
                                <input type="radio" className="btn-check" name="hmsame" id="hmsame4" autoComplete="off" checked />
                                <label className="btn btnRadio large" htmlFor="hmsame4">
                                  <div className='radioInner'>
                                    <span></span>
                                  </div>
                                </label>
                                <h4>{t('profile_section.home_address')}</h4>
                            </span>
                          </div>
                        </div>
                        <BillingAddressTab />
                      </Tab>
                    </Tabs>
                </div>
                {/* <div className="radioButton mt-4 d-flex justify-content-center">
                  <div id="myTab" className='' role="tablist">
                    <ul className="d-flex justify-content-start mt-4 pb-2">
                      <NavItem as="li">
                        <button className="btn br-30 bg m-0 p-0 billYes border-0 active activetab" id="billingAddress" data-bs-toggle="tab" data-bs-target="#billingAddress" type="button" role="tab" aria-controls="billingAddress" aria-selected="true">
                          <span>Yes</span>
                        </button>
                      </NavItem>
                      <NavItem as="li" className="me-3">
                        <div className="radioHeading">
                          Same as Shipping Address?
                        </div>
                      </NavItem>
                      <NavItem as="li" className="droparrow">
                        <button className="btn br-30 bg m-0 p-0 billNo border-0 sectab " id="shippingAddress" data-bs-toggle="tab" data-bs-target="#shippingAddress" type="button" role="tab" aria-controls="shippingAddress" aria-selected="false">
                          <span>No</span>
                        </button>
                      </NavItem>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <span className="d-flex align-items-center justify-content-between w-100 mb-4">
            <div id="myTab1" className='' role="tablist">
              <button className="buttonArrow me-3 text-body billarrow border-0 bg br-10 active" id="billingAddress" data-bs-toggle="tab" data-bs-target="#billingAddress" type="button" role="tab" aria-controls="billingAddress" aria-selected="true">
                <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
              </button>
              <button className="buttonArrow me-3 text-body billarrow2 border-0 bg br-10 notab" id="shippingAddress" data-bs-toggle="tab" data-bs-target="#shippingAddress" type="button" role="tab" aria-controls="shippingAddress" aria-selected="false">
                <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
              </button>
            </div>
            <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="Saved" icon={faFloppyDisk} name="Save" />
          </span>
        </div>
      {/* </div> */}
    </div>
  )
}
