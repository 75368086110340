import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function StepZeroTab() {
  const responsive = {
    desktop: {
    breakpoint: { max: 3000, min: 1366 },
    items: 6,
    slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
    breakpoint: { max: 1366, min: 768 },
    items: 5,
    slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
    }
};
  return (
    <Carousel responsive={responsive} autoPlay={false}  autoPlaySpeed={2000} transitionDuration={500}>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 active" id="clarity" data-bs-toggle="tab" data-bs-target="#clarity" type="button" role="tab" aria-controls="clarity" aria-selected="true">NICHE CLARITY</button>
        </div> 
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="marketr" data-bs-toggle="tab" data-bs-target="#marketr" type="button" role="tab" aria-controls="marketr" aria-selected="true">MARKET RESEARCH</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="strategy" data-bs-toggle="tab" data-bs-target="#strategy" type="button" role="tab" aria-controls="strategy" aria-selected="false">MARKET ANALYSIS & STRATEGY</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="brandingr" data-bs-toggle="tab" data-bs-target="#brandingr" type="button" role="tab" aria-controls="shipping" aria-selected="false">PERSONAL BRANDING</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="canvasplaning" data-bs-toggle="tab" data-bs-target="#canvasplaning" type="button" role="tab" aria-controls="canvasplaning" aria-selected="false">BUSINESS PLANNING & CANVAS</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 react-toastify" id="summarytb" data-bs-toggle="tab" data-bs-target="#summarytb" type="button" role="tab" aria-controls="summarytb" aria-selected="true">EXECUTIVE SUMMARY & BUSINESS PLAN</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="decktb" data-bs-toggle="tab" data-bs-target="#decktb" type="button" role="tab" aria-controls="decktb" aria-selected="false">PITCH DECK</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="registertb" data-bs-toggle="tab" data-bs-target="#registertb" type="button" role="tab" aria-controls="registertb" aria-selected="false">BUSINESS<br/> REGISTRATION</button>
        </div>
    </Carousel>
  )
}