import React from "react";

export default function DietConsultation() {
  return (
    <div className="diet-consultaion">
      <h3>DIET PROGRAMS</h3>
      <p>Fresh Start Consultation</p>
      <div className="diet-consultaion-btn">
        <div class="consultaion-btn diet-buy-btn">BUY IT NOW</div>
        <div class="consultaion-btn program-btn">Programs</div>
      </div>
    </div>
  );
}
