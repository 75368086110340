import React, { useState } from "react";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
// import { Link } from 'react-router-dom';
import ButtonBox from '../../../global/ButtonBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
// import SocialMediaOne from "./SocialSingle/SocialMediaOne";
import { useTranslation } from "react-i18next";
import { NavItem } from "react-bootstrap";
import Integration from "./IntegrationAnalysis/Integration";
import Analysis from "./IntegrationAnalysis/Analysis";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';


export default function IntegrationAnalytics() {
  const { t, i18n } = useTranslation();
  let {isVideoVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };
const [widthFull , setWidthFull] = useState(1)
  return (
    <div>
      <div className={`w-950 mb-4 align-items-center mx-auto d-flex flex-column-reverse justify-content-between widthSocial ${widthFull == 1  ? "" : ""  }`}>     
        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto mb-5">
        <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
          <h3 className="mb-3 d-flex justify-content-between w-600 pe-5">{t('profile_section.enter_your_profile_details')} <small>{t('step')} <b>8</b> of 11</small></h3>
          
          <div className="ProgressBar">
          <ul>
              <NavItem as="li" className="active" id="profile"></NavItem>
              <NavItem as="li" className="active" id="chat"></NavItem>
              <NavItem as="li" className="active" id="location"></NavItem>
              <NavItem as="li" className="active" id="user"></NavItem>
              <NavItem as="li" className="active" id="briefcase"></NavItem>
              <NavItem as="li" className="active" id="graduation-cap"></NavItem>
              <NavItem as="li" className="active" id="favorite"></NavItem>
              <NavItem as="li" className="active" id="award"></NavItem>
              <NavItem as="li" id="connectivity"></NavItem>
              <NavItem as="li" id="lock"></NavItem>
              <NavItem as="li" id="eye"></NavItem>
          </ul>
        </div>
        <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="integraionone" role="tabpanel" aria-labelledby="integrationone">
                <Integration />
                {/* <Analysis /> */}
              </div>
              <div className="tab-pane fade" id="analysisone" role="tabpanel" aria-labelledby="analysisone">
                <Analysis />
              </div>
          </div>
        </div>
        <span className="d-flex align-items-center justify-content-between w-100 mb-4">
          <div id="myTab1" className='' role="tablist">
            <button className="buttonArrow me-3 text-body border-0 bg br-10 active" id="integraionone" data-bs-toggle="tab" data-bs-target="#integraionone" type="button" role="tab" aria-controls="integraionone" aria-selected="true">
              <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
            </button>
            <button className="buttonArrow me-3 text-body border-0 bg br-10" id="analysisone" data-bs-toggle="tab" data-bs-target="#analysisone" type="button" role="tab" aria-controls="analysisone" aria-selected="false">
              <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
            </button>
          </div>
          <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="Saved" icon={faFloppyDisk} name="Save" />
        </span>
      </div>
    </div>   
  )
}
