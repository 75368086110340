import React, { useState, useEffect } from 'react';
import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';

import second_3 from '../../../../../images/second-3.png'

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import { useTranslation } from "react-i18next";



export default function LogoImageComponent() {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  
    useEffect(() => {
      if (isOpen) {
        const timer = setTimeout(() => {
          setIsOpen(false);
        }, 5000);
  
        return () => clearTimeout(timer);
      }
    }, [isOpen]);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  const [Competency1, setCompetency1] = useState(10);

  return (
    <div>



      <div className="">
      
 

      
   

    
            
             
                <div className="img-logo-oter">
                  <img src={second_3} className="img-logo" alt="logo" >
                  </img>
                </div>

              
            

           
       
       

    
      

       
      
    


      </div>




    </div>



  );
}
