import React from "react";
import ToggleButton from "../../../../../global/ToggleButton";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function ProfileGroupSix() {
  const { t, i18n } = useTranslation();

  return (
    <div className="shadow-5in w-100 p-40 br-30 mb-4">
      <h3 className="mb-5 text-uppercase">{t('profile_section.your_personal_information')}</h3>
      <ul className="row">
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="AGE RANGE" id="AGERANGE" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="GENDER" id="GENDER" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="BIRTHDAY (MONTH & DAY ONLY)" id="BIRTHDAY" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="DATE OF BIRTH" id="DATEOFBIRTH" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="BIRTH PLACE" id="BIRTHPLACE" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="MARITAL STATUS" id="MARITALSTATUS" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="NATIONALITY 1" id="NATIONALITY"  check="checked"/>
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="NATIONALITY 2" id="NATIONALITY2" />
        </NavItem>

        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="DO YOU HAVE ANY PETS?" id="DOPETS" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="NUMBER OF PETS" id="NUMBEROFPETS" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="DO YOU HAVE ANY CHILDREN?" id="DOCHILDREN?" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="NUMBER OF CHILDREN" id="NUMBERCHILDREN" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="NUMBER OF PEOPLE IN HOUSEHOLD" id="NUMBERHOUSEHOLD" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Your Zodiac Sign" id="YourZodiacSign" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="PERSONALITY" id="PERSONALITY" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="PERSONAL VALUES" id="PERSONALVALUES" check="checked" />
        </NavItem>
      </ul>
    </div>
  );
}
