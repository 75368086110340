import * as React from 'react';
import '../../profileManagement.scss';
import '../../profileManagementSec.scss';
import { useState } from 'react';
import Social from './InteTabs/social';
import UserMedia from './InteTabs/userMedia';
import Master from './InteTabs/Master';
import Payment from './InteTabs/Payment';
import Affiliate from './InteTabs/Affiliate';
import Other from './InteTabs/Other';
import IntegSlider from './IntegSlider';
import GoogleSec from './InteTabs/GoogleSec';


export default function IntegTab() {
    const handleClick5 = () => {
        setToggle5(!toggle5);
    };
    const [toggle5, setToggle5] = useState("true");
    return (
        <div className="w-100 d-flex flex-column-reverse">
            <div className='w-100 px-4'>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="googleseo" role="tabpanel" aria-labelledby="googleseo">
                        <GoogleSec />
                    </div>
                    <div className="tab-pane fade " id="social08" role="tabpanel" aria-labelledby="social08">
                        <Social/>
                    </div>
                    <div className="tab-pane fade" id="usermedia08" role="tabpanel" aria-labelledby="usermedia08">
                        <UserMedia/>
                    </div>
                    <div className="tab-pane detailTab fade" id="master08" role="tabpanel" aria-labelledby="master08">
                        <Master />
                    </div>
                    <div className="tab-pane detailTab fade" id="payment08" role="tabpanel" aria-labelledby="payment08">
                        <Payment />
                    </div>
                    <div className="tab-pane detailTab fade" id="affiliate08" role="tabpanel" aria-labelledby="affiliate08">
                        <Affiliate/>
                    </div>
                    <div className="tab-pane fade" id="other08" role="tabpanel" aria-labelledby="other08">
                        <Other />
                    </div>               
                </div>
            </div>
            <div className='container privacyTab mb-0 pb-2 mt-5 mt-lg-0'>
                <div id="myTab78" className='slidetab' role="tablist">
                    <IntegSlider />
                </div>
            </div>
        </div>
    );
  }