import React from "react";
import ButtonBox from "../../../../global/ButtonBox";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import { NavItem } from 'react-bootstrap';
import faCaretDown from '../../../../../images/icons/faCaretDown.svg';
import ButtonBoxNew from "../../../../global/ButtonBoxNew";
export default function RestrictedOne() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <ul>
        <NavItem as="li">
          <h3 className="mb-0 fw-bold">{t('privacy_component.restrictions_component.restricted_list')}</h3>
        </NavItem>
        <NavItem as="li" className="py-3">
          <div className="listText w-400">
            <p>{t('privacy_component.restrictions_component.restricted_list_text')}</p>
          </div>
          <div className="d-flex">
            <div className="dropdown">
              <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
              <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
              {t('choose_your_audience')} 
              {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
              <img src={faCaretDown} alt="" />
              </Link>
              </span>
              <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
              <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
                <NavItem as="li" className='px-2'>
                  <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.restrictions_component.placeholder_email')} />
                </NavItem>
                <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                  {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
                </NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('.only_this_person')}:</Link></NavItem>
                <NavItem as="li" className='px-2'>
                  <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.restrictions_component.placeholder_email')} />
                </NavItem>
                <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                  {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
                </NavItem>
              </ul> 
            </div>
          </div>
        </NavItem>
        <NavItem as="li" className="py-4">
          <div className="listText d-flex align-items-center mx-auto">
            <h5>{t('privacy_component.restrictions_component.restrict_user')}</h5>
            <input className="shadow-5in br-10 mb-0 mx-3 w-320" type="text" placeholder={t('privacy_component.restrictions_component.placeholder_email_restrict')} />
            {/* <button className="h-50 bg-danger text-white br-30 w-110 h8 text-center border-0 shadow-4">
            {t('privacy_component.restrictions_component.restrict')}
            </button> */}
            <ButtonBoxNew name="Restrict" boxwidth="w-125 text-white bg-danger" />
          </div>
        </NavItem>
        <NavItem as="li" className="pb-3 d-flex justify-content-center align-items-center">
          {/* <button className="h-50 bg br-30 w-185 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
            <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
            {t('add_more_emails')}
          </button> */}
          <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
        </NavItem>
        <NavItem as="li" className="pb-4 flex-wrap">
          <h3 className="my-3 text-center w-100 fw-bold w-100">{t('privacy_component.restrictions_component.your_restrict_list')}</h3>
          <ol className="w-400 mx-auto">
            <NavItem as="li" className="listText tabBdr mt0">
              <h5>{t('privacy_component.restrictions_component.restricteduser_one')}</h5>
              {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                {t('privacy_component.restrictions_component.remove_restriction')}
              </button> */}
              <ButtonBoxNew name="Remove Restriction" boxwidth="w-240" />
            </NavItem>
            <NavItem as="li" className="listText tabBdr mt0">
              <h5>{t('privacy_component.restrictions_component.restricteduser_two')}</h5>
              {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
              {t('privacy_component.restrictions_component.remove_restriction')}
              </button> */}
              <ButtonBoxNew name="Remove Restriction" boxwidth="w-240" />
            </NavItem>
            <NavItem as="li" className="listText tabBdr mt0">
              <h5>{t('privacy_component.restrictions_component.restricteduser_three')}</h5>
              {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
              {t('privacy_component.restrictions_component.remove_restriction')}
              </button> */}
              <ButtonBoxNew name="Remove Restriction" boxwidth="w-240" />
            </NavItem>
          </ol>
        </NavItem>

        <NavItem as="li">
          <h3 className="mb-0 fw-bold">{t('privacy_component.restrictions_component.block_users')}</h3>
        </NavItem>
        <NavItem as="li" className="py-3">
          <div className="listText">
            <p>{t('privacy_component.restrictions_component.restricted_list_text')}</p>
          </div>
        </NavItem>
        <NavItem as="li" className="py-4">
          <div className="listText d-flex align-items-center mx-auto">
            <h5 className="mt-2">{t('privacy_component.restrictions_component.block_users')}</h5>
            <input className="shadow-5in br-10 mb-0 mt-2 mx-3 w-320" type="text" placeholder={t('privacy_component.restrictions_component.placeholder_email_block')} />
            {/* <button className="h-50 bg-danger text-white br-30 w-110 h8 text-center border-0 shadow-4">
              {t('privacy_component.restrictions_component.block')}
            </button> */}
            <ButtonBoxNew name="Block" boxwidth="w-125 text-white bg-danger mt-0" />
          </div>
        </NavItem>
        <NavItem as="li" className="pb-3 d-flex justify-content-center align-items-center">
          {/* <button className="h-50 bg br-30 w-185 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
            <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
            {t('add_more_emails')}
          </button> */}
          <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
        </NavItem>
        <NavItem as="li" className="tabBdr pb-4 flex-wrap">
          <h3 className="my-3 text-center w-100 fw-bold w-100">{t('privacy_component.restrictions_component.your_block_list')}</h3>
          <ol className="w-400 mx-auto">
            <NavItem as="li" className="listText tabBdr mt0">
              <h5>{t('privacy_component.restrictions_component.blockeduser_one')}</h5>
              {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                {t('privacy_component.restrictions_component.unblock')}
              </button> */}
              <ButtonBoxNew name="unblock" boxwidth="w-180" />
            </NavItem>
            <NavItem as="li" className="listText tabBdr mt0">
              <h5>{t('privacy_component.restrictions_component.blockeduser_two')}</h5>
              {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                {t('privacy_component.restrictions_component.unblock')}
              </button> */}
              <ButtonBoxNew name="unblock" boxwidth="w-180" />
            </NavItem>
            <NavItem as="li" className="listText tabBdr mt0">
              <h5>{t('privacy_component.restrictions_component.blockeduser_three')}</h5>
              {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
              {t('privacy_component.restrictions_component.unblock')}
              </button> */}
              <ButtonBoxNew name="unblock" boxwidth="w-180" />
            </NavItem>
          </ol>
        </NavItem>

        <NavItem as="li" className=" text-center mt-5 justify-content-center">
          <ButtonBox
            boxwidth="w-140 mt-0 mb-50 mx-auto"
            clicked="Saved"
            name="Save/Next"
          />
        </NavItem>
      </ul>
    </div>
  );
}
