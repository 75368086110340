import React, { useState } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStar } from '@fortawesome/free-solid-svg-icons';
// import img3 from '../../../../../images/login.png';
import img4 from '../../../../../images/case.png';
import img5 from '../../../../../images/imgFinger.png';
import img1 from '../../../../../images/img1.png';
import img11 from '../../../../../images/img11.png';
import img12 from '../../../../../images/img12.png';
import img12s from '../../../../../images/fastar.png';
import img13 from '../../../../../images/profileview/img13.png';
import img14 from '../../../../../images/profileview/img9.png';
import img15 from '../../../../../images/profileview/img10.png';
import img16 from '../../../../../images/profileview/img11.png';
import { Link } from "react-router-dom";

import user from "../../../../../images/img1.png";
import crown from "../../../../../images/profileicon/img1.svg";
import fb from "../../../../../images/profileicon/img2.svg";
import ins from "../../../../../images/profileicon/img3.svg";
import twi from "../../../../../images/profileicon/img4.svg";
import linked from "../../../../../images/profileicon/img5.svg";
import pine from "../../../../../images/profileicon/img6.svg";
import tube from "../../../../../images/profileicon/img7.svg";
import tok from "../../../../../images/profileicon/img8.svg";
import check from "../../../../../images/profileicon/img10.svg";
import canda from "../../../../../images/profileicon/img11.svg";
import vip from "../../../../../images/profileicon/img12.svg";
import whts from "../../../../../images/profileicon/img13.svg";

import ImageBox from "../../../../global/ImageBox";
import PreferredSec from "./ProfileSec/PreferredSec";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function ProfileViewTwo() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      {/* // */}
      <div className="text-center mb-5 innerHeight">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus mt-0">
          {t('profile_section.clients_feedback')}</h4>
          <div className="shadow-4 br-20 p-5s ">
            <div id="carouselExampleIndicatornew" class="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="p-2s d-flex justify-content-center align-items-center flex-column">
                    <div className="textArea position-relative me-auto shadow-5in d-flex flex-column justify-content-center align-items-center">
                      <span className="w-100 mb-2">
                        <img src={img12s} alt="" />
                      </span>
                      <p>{t('profile_section.client_text')}</p>
                      <div className="imgin1 shadow-5in rounded-circle position-absolute w-125 h-110 bottom-50e end-0">
                          <div className="clinetimg shadow-5in">
                            <ImageBox boxwidth="w-65 userImg" src={user} />
                          </div>
                          <Link to="" className="icon1"><img src={ins} alt=""/></Link>
                          <Link to="" className="icon2"><img src={twi} alt=""/></Link>
                          <Link to="" className="icon3"><img src={fb} alt=""/></Link>
                          <Link to="" className="icon4"><img src={linked} alt=""/></Link>
                          <Link to="" className="icon5"><img src={whts} alt=""/></Link>
                          <Link to="" className="icon6"><img src={crown} alt=""/></Link>
                          <Link to="" className="icon7"><img src={tok} alt=""/></Link>
                          <Link to="" className="icon8"><img src={check} alt=""/></Link>
                          <Link to="" className="icon9"><img src={tube} alt=""/></Link>
                          <Link to="" className="icon10"><img src={canda} alt=""/></Link>
                          <Link to="" className="icon11"><img src={vip} alt=""/></Link>
                          <Link to="" className="icon12"><img src={pine} alt=""/></Link>
                        </div>
                    </div>
                    <div className="textThumb ms-auto d-flex flex-column">
                      <div className="headingnew mt-auto ms-auto">
                        <h5>DYNA APPOLON</h5>
                      </div>
                      <div className="headingnew1 ms-auto">
                        <h5>CEO | Founder | 9 to 5 Escapee</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item ">
                  <div className="p-2s d-flex justify-content-center align-items-center flex-column">
                    <div className="textArea position-relative me-auto shadow-5in d-flex flex-column justify-content-center align-items-center">
                      <span className="w-100 mb-2">
                        <img src={img12s} alt="" />
                      </span>
                      <p>{t('profile_section.client_text')}</p>
                      <div className="imgin1 shadow-5in rounded-circle position-absolute w-125 h-110 bottom-50e end-0">
                          <div className="clinetimg shadow-5in">
                            <ImageBox boxwidth="w-65 userImg" src={user} />
                          </div>
                          <Link to="" className="icon1"><img src={ins} alt=""/></Link>
                          <Link to="" className="icon2"><img src={twi} alt=""/></Link>
                          <Link to="" className="icon3"><img src={fb} alt=""/></Link>
                          <Link to="" className="icon4"><img src={linked} alt=""/></Link>
                          <Link to="" className="icon5"><img src={whts} alt=""/></Link>
                          <Link to="" className="icon6"><img src={crown} alt=""/></Link>
                          <Link to="" className="icon7"><img src={tok} alt=""/></Link>
                          <Link to="" className="icon8"><img src={check} alt=""/></Link>
                          <Link to="" className="icon9"><img src={tube} alt=""/></Link>
                          <Link to="" className="icon10"><img src={canda} alt=""/></Link>
                          <Link to="" className="icon11"><img src={vip} alt=""/></Link>
                          <Link to="" className="icon12"><img src={pine} alt=""/></Link>
                        </div>
                    </div>
                    <div className="textThumb ms-auto d-flex flex-column">
                      <div className="headingnew mt-auto ms-auto">
                        <h5>DYNA APPOLON</h5>
                      </div>
                      <div className="headingnew1 ms-auto">
                        <h5>CEO | Founder | 9 to 5 Escapee</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="p-2s d-flex justify-content-center align-items-center flex-column">
                    <div className="textArea position-relative me-auto shadow-5in d-flex flex-column justify-content-center align-items-center">
                      <span className="w-100 mb-2">
                        <img src={img12s} alt="" />
                      </span>
                      <p>{t('profile_section.client_text')}</p>
                      <div className="imgin1 shadow-5in rounded-circle position-absolute w-125 h-110 bottom-50e end-0">
                          <div className="clinetimg shadow-5in">
                            <ImageBox boxwidth="w-65 userImg" src={user} />
                          </div>
                          <Link to="" className="icon1"><img src={ins} alt=""/></Link>
                          <Link to="" className="icon2"><img src={twi} alt=""/></Link>
                          <Link to="" className="icon3"><img src={fb} alt=""/></Link>
                          <Link to="" className="icon4"><img src={linked} alt=""/></Link>
                          <Link to="" className="icon5"><img src={whts} alt=""/></Link>
                          <Link to="" className="icon6"><img src={crown} alt=""/></Link>
                          <Link to="" className="icon7"><img src={tok} alt=""/></Link>
                          <Link to="" className="icon8"><img src={check} alt=""/></Link>
                          <Link to="" className="icon9"><img src={tube} alt=""/></Link>
                          <Link to="" className="icon10"><img src={canda} alt=""/></Link>
                          <Link to="" className="icon11"><img src={vip} alt=""/></Link>
                          <Link to="" className="icon12"><img src={pine} alt=""/></Link>
                        </div>
                    </div>
                    <div className="textThumb ms-auto d-flex flex-column">
                      <div className="headingnew mt-auto ms-auto">
                        <h5>DYNA APPOLON</h5>
                      </div>
                      <div className="headingnew1 ms-auto">
                        <h5>CEO | Founder | 9 to 5 Escapee</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicatornew" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicatornew" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicatornew" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
            </div>
          </div>

      </div>
      <PreferredSec />
      <div className="text-center case mb-5">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.business_info')}</h4>
        <div className="shadow-4 br-20 h-245 py-5 px-4 d-flex justify-content-center align-items-center flex-column">
          <img src={img4} alt="" />
          <ul className="d-flex flex-wrap mt-4 w-100 py-0 px-2 m-0 justify-content-between align-items-center">
            <NavItem as="li" className="w-50 p-0">
              <span className="circle"></span>{t('profile_section.business_email')}
            </NavItem>
            <NavItem as="li" className="w-50 p-0">
              <span className="line"></span>{t('profile_section.dyna_9_to5escapee_com')}
            </NavItem>
            <NavItem as="li" className="w-50 p-0">
              <span className="circle"></span>{t('profile_section.contact_whatsApp_number')}
            </NavItem>
            <NavItem as="li" className="w-50 p-0">
              <span className="line"></span>{t('profile_section._554_980_5678')}
            </NavItem>
          </ul>
        </div>
      </div>
      <div className="text-center case mb-5">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.headquater_1')}</h4>
        <div className="shadow-4 br-20  py-3 px-4 d-flex justify-content-center align-items-center flex-column">
          <img src={img14} alt="" />
          <ul className="d-flex flex-wrap m-0 w-100 py-0 px-2 m-0 justify-content-center align-items-center">
            <NavItem as="li" className="w-100 justify-content-center">
              <span className="circle"></span>{t('profile_section.monaco_france')}
            </NavItem>
          </ul>
          <img src={img16} height="200" alt="" />
        </div>
      </div>
      <div className="text-center case mb-5">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.headquater_2')}</h4>
        <div className="shadow-4 br-20 py-3 px-4 d-flex justify-content-center align-items-center flex-column">
          <img src={img14} alt="" />
          <ul className="d-flex flex-wrap m-0 w-100 py-0 px-2 m-0 justify-content-center align-items-center">
            <NavItem as="li" className="w-100 justify-content-center">
              <span className="circle"></span>{t('profile_section.george_town_cayman_islands')}
            </NavItem>
          </ul>
          <img src={img15} height="200" alt="" />
        </div>
      </div>
      <div className="text-center case mb-5">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.interest')}</h4>
        <div className="shadow-4 br-20 h-245 py-5 px-4 d-flex justify-content-center align-items-center flex-column">
          <img src={img5} alt="" />
          <ul className="d-flex flex-wrap mt-4 w-100 py-0 px-2 m-0 justify-content-center align-items-center">
            <NavItem as="li">
              <span className="circle"></span>{t('profile_section.philosophy')}
            </NavItem>
            <NavItem as="li">
              <span className="circle"></span>{t('profile_section.substainability')}
            </NavItem>
            <NavItem as="li">
              <span className="circle"></span>{t('profile_section.spaceship')}
            </NavItem>
          </ul>
        </div>
      </div>
      <div className="text-center case mb-6">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.brands_affinity')}</h4>
        <div className="shadow-4 h-245 br-20 py-5 px-4 d-flex justify-content-center align-items-center flex-column">
          <ul className="d-flex flex-wrap w-100 py-0  m-0 justify-content-between align-items-center">
            <NavItem as="li" className="d-flex justify-content-center align-items-center flex-column">
              <img src={img1} alt="" width="63" height="63" className="mb-3" />
              <h6 className="shadow-5in h7 py-2 px-2 rounded">{t('profile_section.dyna_appolon')}</h6>
            </NavItem>
            <NavItem as="li" className="d-flex justify-content-center align-items-center flex-column">
              <img src={img11} alt="" width="auto" height="63" className="mb-3" />
              <h6 className="shadow-5in h7 py-2 px-2 rounded">{t('profile_section.chanel')}</h6>
            </NavItem>
            <NavItem as="li" className="d-flex justify-content-center align-items-center flex-column">
              <img src={img12} alt="" width="auto" height="63" className="mb-3" />
              <h6 className="shadow-5in h7 py-2 px-2 rounded">{t('profile_section.tesla')}</h6>
            </NavItem>
          </ul>
        </div>
      </div>
      <div className="text-center case mb-5">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">
          {t('profile_section.next_travel_destination')}</h4>
        <div className="shadow-4 br-20 py-4 px-4 d-flex justify-content-center align-items-center flex-column">
          <img src={img13} alt="" />
          <ul className="d-flex flex-wrap mt-4 w-100 py-0 px-2 m-0 justify-content-between align-items-center">
            <NavItem as="li" className="w-50 p-0">
              <span className="circle"></span> {t('profile_section.jemeriah_beach')}
            </NavItem>
            <NavItem as="li" className="w-50 p-0">
              <span className="line"></span> {t('profile_section.dubai_uae')}
            </NavItem>
            <NavItem as="li" className="w-50 p-0 ">
              <span className="circle"></span> {t('profile_section.garden_of_gethsemane')}
            </NavItem>
            <NavItem as="li" className="w-50 p-0">
              <span className="line"></span> {t('profile_section.jerusalem')}
            </NavItem>
            <NavItem as="li" className="w-50 p-0">
              <span className="circle"></span> {t('profile_section.lencois_maranhenses')}
            </NavItem>
            <NavItem as="li" className="w-50 p-0">
              <span className="line"></span> {t('profile_section.brazil')}
            </NavItem>
          </ul>
        </div>
      </div>
    </div>
  );
}
