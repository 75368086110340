import React from "react";
import ReactStars from "react-rating-stars-component";
import avatar from "../../../../../images/testimonial-case-study/avatar.png";

import regular3_desktop_commas from "../../../../../images/testimonial-case-study/regular3_desktop_commas.png";
import neumorphic3_desktop_commas from "../../../../../images/testimonial-case-study/neumorphic3_desktop_commas.png";

import "./RegularTheme3.scss";

export default function RegularTheme3(props) {
  const ratingChanged = (newRating) => {
  };

  let rederedCommaImg;
  let rederedColor;

  if (props.themeType === "Regular") {
    rederedCommaImg = regular3_desktop_commas;
  } else {
    rederedCommaImg = neumorphic3_desktop_commas;
  }

  if (props.themeType === "Regular") {
    rederedColor = "#7CBAA7";
  } else {
    rederedColor = "#FACB30";
  }

  return (
    <div className="dot-container">
      <div className="profile-div">
        <div className="comma-div">
          <div className="comma-img-div"></div>
        </div>

        <div className="profile-detail-div">
          <div className="avatar-div">
            <img src={avatar} alt="Regular Theme 2 Avatar" srcset="" />
          </div>
          <h1>DYNA APPOLON</h1>
          <p>CEO | SOCIAL NETWORK STRATEGIST</p>

          <h3>
            “Lorem ipsum dolor sit amet consectetur. Duis elementum varius duis
            eu donec. Tellus ut metus sed id mi consequat odio nunc velit.”
          </h3>
        </div>
        <div className="rating-stars-div">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={20}
            activeColor={rederedColor}
          />
        </div>

        <div className="see-my-wall-div">
          <p>SEE MY</p>
          <a href="#">WALL OF LOVE</a>
        </div>
      </div>
    </div>
  );
}
