import React from "react";
import { useState } from "react";
import "./RegularTheme1Mobile.scss";
import "../regularTheme1Desktop/RegularTheme1Desktop.scss";
import "../regularTheme1Tab/RegularTheme1Tab.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
// import timeline from "../../../../../../images/how-it-work/timeline-mobile.png";
// import timeline_2 from "../../../../../images/how-it-work/timeline-2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import HireMeMainMob from "../components/HireMeMainMob/HireMeMainMob";
import HireMeNavMob from "../components/HireMeNavMob/HireMeNavMob";
// import HireMeFormMob from "../components/HireMeFormMob/HireMeFormMob";
import HireMeSideBox from "../components/HireMeSideBox/HireMeSideBox";
// import HireMePositionBoxMob from "../components/HireMePositionBoxMob/HireMePositionBoxMob";
import HireMePositionBox from "../components/HireMePositionBox/HireMePositionBox";
import HireMeForm from "../components/HireMeForm/HireMeForm";

export default function RegularTheme1Mobile({ Regular, Neumorphic }) {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [Competency1, setCompetency1] = useState(10);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div
        className="main-content-mob RegularTheme1Mob"
        style={{ backgroundColor: Regular ? "#cbd5e0" : "" }}
      >
        {/*  */}
        <div className="top-content">
          <p className="text-center-mob pt-5">AWARDS</p>
          <HireMeNavMob Regular={Regular} Neumorphic={Neumorphic} />
        </div>
        {/*  */}
        <div className="main-container-mob">
          <HireMeMainMob Regular={Regular} Neumorphic={Neumorphic} />
        </div>
        <div className="box-one-mob">
          <HireMePositionBox Regular={Regular} Neumorphic={Neumorphic} />
        </div>

        <div className="form-and-profile-mob d-flex ">
          {/* side-position-box */}
          <div className="side-position-box-mob mt-5">
            <HireMeSideBox Regular={Regular} Neumorphic={Neumorphic} />
          </div>

          {/* form */}
          <div className="form-position-mob mt-4">
            <HireMeForm Regular={Regular} Neumorphic={Neumorphic} />
          </div>
        </div>
      </div>
    </>
  );
}
