import React from "react";
import { useTranslation } from "react-i18next";
import Stripe from '../../../../images/stripe.png';
import ButtonBox from "../../../global/ButtonBox";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
// import { Link } from 'react-router-dom';
import "./StripeAccount.scss"
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';
export default function StripeAccountForm() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });
    let {isVideoVisible} = useSelector(state => state.videoReducer);
    const dispatch = useDispatch();
    const handleClick6 = () => {
      dispatch(toggleVideo());
    };
    return (
        <div className="pb-5 pt-2">
           <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div> 
            <div className="row mb-4 px-3">
                <div className="col-md-6">
                    <label className="mb-1">{t('timeline_form_stripe_account_component.stripe_account_registration')}</label>
                    <span className="shadow-4 h-50s br-3  d-flex justify-content-start px-3 align-items-center text_blue text-center h6">
                        <img src={Stripe} alt="" className="w-35 me-3" />
                        Registration Link</span>
                </div>
                <div className="col-md-6">
                    <label className="mb-1">{t('timeline_form_stripe_account_component.your_stripe_account_email')}</label>
                    <input placeholder={t('timeline_form_stripe_account_component.your_stripe_account_email_placeholder')} className='shadow-5in br-5 h6 mb-0' type="email" />
                </div>
            </div>
            <div className="row mb-4 px-3">
                <div className="col-md-6">
                    <label className="mb-1">{t('timeline_form_stripe_account_component.your_stripe_backup_code')}</label>
                    <input placeholder={t('timeline_form_stripe_account_component.your_stripe_backup_code_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
                <div className="col-md-6">
                    <label className="mb-1">{t('timeline_form_stripe_account_component.publishable_key')}</label>
                    <input placeholder={t('timeline_form_stripe_account_component.publishable_key_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
            </div>
            <div className="row mb-4 px-3">
                <div className="col-md-6">
                    <label className="mb-1">{t('timeline_form_stripe_account_component.stripe_client_id')}</label>
                    <input placeholder={t('timeline_form_stripe_account_component.stripe_client_id_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
                <div className="col-md-6">
                    <label className="mb-1">{t('timeline_form_stripe_account_component.stripe_secret_key')}</label>
                    <input placeholder={t('timeline_form_stripe_account_component.stripe_secret_key_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
            </div>
            <div className=" text-center time-btn rotate_btn">

                <ButtonBox
                    boxwidth="w-320 text_light mt-4 mb-50 mx-auto"
                    clicked="Save| Confirm Stripe Account"
                    name="Save| Confirm Stripe Account"
                />
            </div>
        </div>
    );
}
