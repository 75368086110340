import React from "react";
import { useState } from "react";
import '../../BlogPostSectionThemes.scss'
import { BsCheckLg } from 'react-icons/bs'
import '../regularTheme3Desktop/RegularTheme3Desktop.scss';
import blogpostdesktop_3 from "../../../../../../../src/images/desktopblogpost/blogpost3desktop3.png"
import blogpostdesktop_3_1 from "../../../../../../../src/images/desktopblogpost/blogpostdesktop3-1.png"
import blogpostdesktop_3_2 from "../../../../../../../src/images/desktopblogpost/blogpostdesktop3-2.png"
import blogpostdesktop_3_3 from "../../../../../../../src/images/desktopblogpost/blogpostdesktop3-3.png"
import { BsBookmark } from "react-icons/bs";


import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
// import { BsCheckLg } from "react-icons/fc"
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai"
import { useTranslation } from "react-i18next";
export default function RegularTheme3Desktop() {

  return (
    <div>
      <div className="blogpostdesktop-maincontent p-5 pb-0">
        <div className="row">
          <div className="col-md-6 px-0">
            <div className="blogpostdesktop-image w-100">
              <img src={blogpostdesktop_3} className="img-fluid w-100"></img>
            </div>
          </div>
          <div className="col-md-6 px-0 ">


            <div className="d-flex flex-column justify-content-between h-100 position-relative white-box-position">

            <div className="d-flex">
            <div className="white-box p-4 mt-5">
                <div className="white-box-content">
                  <h6 className="fashion">Fashion- May 16, 2023</h6>
                  <h2 className="fashion-heading">Lorem ipsum dolor sit amet consectetur.</h2>
                  <p className="fashion-paragraph pb-5">
                    Lorem ipsum dolor sit amet consectetur. Tristique tortor dictum fames vitae nunc purus ac commodo enim. Egestas enim adipiscing gravida platea accumsan pellentesque imperdiet. Eget lacinia tincidunt purus egestas laoreet ullamcorper. Sed risus pretium proin gravida bibendum fermentum sagittis condimentum. Massa aliquam dui turpis libero odio. Tortor magna sed in quam viverra neque vitae.
                  </p>
                  <a href="" className="read-more">Read more</a>
                </div>
              </div>
              <div className="bookmark-icon">
              <span><BsBookmark /></span>
            </div>
            </div>
              <div className="comment-share d-flex justify-content-end">
                <p className="mb-0 me-4 text-capitalize">0 comments</p>
                <p className="mb-0 me-4 text-capitalize">0 shares</p>
              </div>

            </div>
          

          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-4">
            <div className="card p-3 bg-white rounded-0  pb-0">
              <div className="card-image-1 w-100">
                <img src={blogpostdesktop_3_1} alt="blogpostdesktop_3_1" className="img-fluid"></img>
              </div>
              <div className="card-image-content p-4 mx-3 position-relative">
                <h4 className="fashion">Fashion- May 16, 2023</h4>
                <p className="fashion-paragraph">
                  Lorem ipsum dolor sit amet consectetur. Tristique tortor dictum fames vitae nunc purus ac commodo enim. Egestas enim adipiscing gravida platea accumsan pellentesque imperdiet. Eget lacinia tincidunt purus egestas laoreet ullamcorper.

                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card p-3 bg-white rounded-0 pb-0">
              <div className="card-image-1 w-100">
                <img src={blogpostdesktop_3_2} alt="blogpostdesktop_3_2" className="img-fluid"></img>
              </div>
              <div className="card-image-content p-4 mx-3 position-relative">
                <h4 className="fashion">Fashion- May 16, 2023</h4>
                <p className="fashion-paragraph">
                  Lorem ipsum dolor sit amet consectetur. Tristique tortor dictum fames vitae nunc purus ac commodo enim. Egestas enim adipiscing gravida platea accumsan pellentesque imperdiet. Eget lacinia tincidunt purus egestas laoreet ullamcorper.
                </p>
              </div>
            </div>
          </div>


          <div className="col-md-4">
            <div className="card p-3 bg-white rounded-0 pb-0">
              <div className="card-image-1 w-100">
                <img src={blogpostdesktop_3_3} alt="blogpostdesktop_3_2" className="img-fluid"></img>
              </div>
              <div className="card-image-content p-4 mx-3 position-relative">
                <h4 className="fashion">Fashion- May 16, 2023</h4>
                <p className="fashion-paragraph">
                  Lorem ipsum dolor sit amet consectetur. Tristique tortor dictum fames vitae nunc purus ac commodo enim. Egestas enim adipiscing gravida platea accumsan pellentesque imperdiet. Eget lacinia tincidunt purus egestas laoreet ullamcorper.

                </p>
              </div>
            </div>
          </div>
        </div>

      </div>




    </div>



  );
}
