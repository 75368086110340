import React, { useState } from 'react'
import "./MarketResearchValidation.scss"
import Image from '../../../images/screen-frame.png';
import Image1 from '../../../images/image1.png';
import Image2 from '../../../images/2.png';
import Image3 from '../../../images/3.png';
import Image4 from '../../../images/4.png';
import Image5 from '../../../images/5.png';
import Image6 from '../../../images/6.png';
import Image7 from '../../../images/7.png';
import Image8 from '../../../images/8.png';
import Image9 from '../../../images/9.png';
import Image10 from '../../../images/10.png';
import Image11 from '../../../images/11.png';
import Image12 from '../../../images/12.png';
import MarketResearchGraph from '../../global/marketResearchGraph/MarketResearchGraph';


export default function MarketResearchValidation() {

  const [headingName, setHeadingName] = useState('Beauty Niche');
  const [bgColor, setBgColor] = useState('frame-badge-pink');

  const handleNameAndColor = (name,color) => {
    setHeadingName(name);
    setBgColor(color);
  }


  return (
    <div>
      <div className="market-researc-validation">
        <div className="market-research-screen-container shadow-5in">
          <div className="row px-3 py-4 research_div">
            <div className='col-8'>
              <MarketResearchGraph headingName={headingName} bgColor={bgColor} />
            </div>
            <div className='col-4 marketscroll'>
              <div onClick={() =>handleNameAndColor("Beauty Niche", "frame-badge-pink")}>
                <img src={Image1} alt="" className="mx-auto w-100"  />
              </div>
              <div onClick={() =>handleNameAndColor("FASHION NICHE", "frame-badge-golden")}>
                <img src={Image2} alt="" className="mx-auto w-100" />
              </div>
              <div onClick={() =>handleNameAndColor("FITNESS NICHE", "frame-badge-red")}>
                <img src={Image3} alt="" className="mx-auto w-100" />
              </div>
              <div onClick={() =>handleNameAndColor("SPIRITUALITY NICHE", "frame-badge-purple")}>
                <img src={Image4} alt="" className="mx-auto w-100" />
              </div>
              <div onClick={() =>handleNameAndColor("NUTRITION NICHE", "frame-badge-green")}>
                <img src={Image5} alt="" className="mx-auto w-100" />
              </div>
              <div onClick={() =>handleNameAndColor("HOME DECOR NICHE", "frame-badge-orange")}>
                <img src={Image6} alt="" className="mx-auto w-100" />
              </div>
              <div onClick={() =>handleNameAndColor("TRAVEL NICHE", "frame-badge-blue")}>
                <img src={Image7} alt="" className="mx-auto w-100" />
              </div>
              <div onClick={() =>handleNameAndColor("EVENT PLANNING NICHE", "frame-badge-green")}>
                <img src={Image8} alt="" className="mx-auto w-100" />
              </div>
              <div onClick={() =>handleNameAndColor("COACHING NICHE", "frame-badge-amber")}>
                <img src={Image9} alt="" className="mx-auto w-100" />
              </div>
              <div onClick={() =>handleNameAndColor("ANIMAL NICHE", "frame-badge-light")}>
                <img src={Image10} alt="" className="mx-auto w-100" />
              </div>
              <div onClick={() =>handleNameAndColor("PARENTING NICHE", "frame-badge-lightpink")}>
                <img src={Image11} alt="" className="mx-auto w-100" />
              </div>
              <div onClick={() =>handleNameAndColor("FINANCE NICHE", "frame-badge-parrot")}>
                <img src={Image12} alt="" className="mx-auto w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
