import React from "react";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { ReactComponent as Download } from "../../images/icons/icon29.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons'

import FormatIcon from "../global/FormatIcon";
import Icon1 from "../../images/formaticon/01.png";
import Icon2 from "../../images/formaticon/02.png";
import Icon3 from "../../images/formaticon/03.png";
import Icon4 from "../../images/formaticon/04.png";
import Icon5 from "../../images/formaticon/05.png";
import Icon6 from "../../images/formaticon/06.png";
import Icon7 from "../../images/formaticon/07.png";
import Icon8 from "../../images/formaticon/08.png";
import Icon9 from "../../images/formaticon/09.png";
import Icon10 from "../../images/formaticon/10.png";
import Icon11 from "../../images/formaticon/11.png";
import Icon12 from "../../images/formaticon/12.png";
import Icon13 from "../../images/formaticon/13.png";
import Icon14 from "../../images/formaticon/14.png";
import Icon15 from "../../images/formaticon/15.png";
import Icon16 from "../../images/formaticon/16.png";
import Icon17 from "../../images/formaticon/17.png";
import Icon18 from "../../images/formaticon/18.png";
import Icon19 from "../../images/formaticon/19.png";
import Icon20 from "../../images/formaticon/20.png";
import Icon21 from "../../images/formaticon/21.png";
import Icon22 from "../../images/formaticon/22.png";
import Icon23 from "../../images/formaticon/23.png";
import Icon24 from "../../images/formaticon/24.png";
import Icon25 from "../../images/formaticon/25.png";
import Icon26 from "../../images/formaticon/26.png";
import Icon27 from "../../images/formaticon/27.png";
import Icon28 from "../../images/formaticon/28.png";
import Icon29 from "../../images/formaticon/29.png";
import Icon30 from "../../images/formaticon/30.png";
import Icon31 from "../../images/formaticon/31.png";
import Icon32 from "../../images/formaticon/32.png";
import Icon33 from "../../images/formaticon/33.png";
import Icon34 from "../../images/formaticon/34.png";
import Icon35 from "../../images/formaticon/35.png";
import Icon36 from "../../images/formaticon/36.png";
import Icon37 from "../../images/formaticon/37.png";
import Icon38 from "../../images/formaticon/38.png";
import Icon39 from "../../images/formaticon/39.png";
import Icon40 from "../../images/formaticon/40.png";
import { AiFillCheckCircle } from 'react-icons/ai'

import { NavItem } from 'react-bootstrap';
import ButtonBoxNew from "./ButtonBoxNew";

export default function EditorDrop1() {



  const theme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    }

  });
  const options = [
    { value: 'USD', label: 'USD' },
    { value: 'CAD', label: 'CAD' },
    { value: 'EURO', label: 'EURO' },
    { value: 'POUNDS', label: 'POUNDS' },
    { value: 'OTHER', label: 'OTHER' },

  ]

  return (
    <div className="dropdown-menu z-index dropdown-menu-lg-end bg shadow-4 border-0 br-20 p-20" >
      <ul className="d-flex align-itmes-center justify-content-center mb-20 flex-wrap pe-5 me-3">
        <NavItem as="li" className="me-3">
          <Link to="#" className="large">
            <span className="pe-2">Source</span>
            <img src={Icon1} alt="" width="16" height="20" />
          </Link>
        </NavItem>
        <FormatIcon src={Icon2} url="#" />
        <FormatIcon src={Icon3} url="#" />
        <FormatIcon src={Icon4} url="#" />
        <FormatIcon src={Icon5} url="#" />
        <FormatIcon src={Icon6} url="#" />
        <FormatIcon src={Icon7} url="#" />
        <FormatIcon src={Icon8} url="#" />
        <FormatIcon src={Icon9} url="#" />
        <FormatIcon src={Icon10} url="#" />
        <FormatIcon src={Icon11} url="#" />
        <FormatIcon src={Icon12} url="#" />
        <FormatIcon src={Icon13} url="#" />
        <FormatIcon src={Icon14} url="#" />
        <FormatIcon src={Icon15} url="#" />
        <FormatIcon src={Icon16} url="#" />
        <FormatIcon src={Icon17} url="#" />
        <FormatIcon src={Icon18} url="#" />
        <FormatIcon src={Icon19} url="#" />
        <FormatIcon src={Icon20} url="#" />
        <FormatIcon src={Icon21} url="#" />
        <FormatIcon src={Icon22} url="#" />
        <FormatIcon src={Icon23} url="#" />
        <FormatIcon src={Icon24} url="#" />
        <FormatIcon src={Icon25} url="#" />
        <FormatIcon src={Icon26} url="#" />
        <FormatIcon src={Icon27} url="#" />
        <FormatIcon src={Icon28} url="#" />
        <FormatIcon src={Icon29} url="#" />
        <FormatIcon src={Icon30} url="#" />
        <FormatIcon src={Icon31} url="#" />
        <FormatIcon src={Icon32} url="#" />
        <FormatIcon src={Icon33} url="#" />
        <FormatIcon src={Icon34} url="#" />
        <FormatIcon src={Icon35} url="#" />
        <FormatIcon src={Icon36} url="#" />
        <FormatIcon src={Icon37} url="#" />
        <FormatIcon src={Icon38} url="#" />
        <FormatIcon src={Icon39} url="#" />
        <FormatIcon src={Icon40} url="#" />
      </ul>
      <div className="row p-20">
        <div className="w-160">
          <Select isSearchable={false} options={options} className="countryList br-30 w-100 customWidth mb-4 css-b62m3t-container" placeholder="Styles" theme={theme}
            styles={{
              option: (base) => ({
                ...base,
                borderRadius: "30px",
                width: "90%",
                height: "auto",
                lineHeight: "32px",
                fontSize: "14px",
                margin: "10px",
                cursor: 'pointer',
                color: '#000000',
                boxShadow:
                  "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
              }),
            }}
          />
        </div>
        <div className="w-168">
          <Select isSearchable={false} options={options} className="countryList br-30 w-100 customWidth mb-4 css-b62m3t-container" placeholder="Format" theme={theme}
            styles={{
              option: (base) => ({
                ...base,
                borderRadius: "30px",
                width: "90%",
                height: "auto",
                lineHeight: "32px",
                fontSize: "14px",
                margin: "10px",
                cursor: 'pointer',
                color: '#000000',
                boxShadow:
                  "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
              }),
            }}
          />
        </div>
        <div className="w-168">
          <Select isSearchable={false} options={options} className="countryList br-30 w-100 customWidth mb-4 css-b62m3t-container" placeholder="Font" theme={theme}
            styles={{
              option: (base) => ({
                ...base,
                borderRadius: "30px",
                width: "90%",
                height: "auto",
                lineHeight: "32px",
                fontSize: "14px",
                margin: "10px",
                cursor: 'pointer',
                color: '#000000',
                boxShadow:
                  "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
              }),
            }}
          />
        </div>
        <div className="w-168">
          <Select isSearchable={false} options={options} className="countryList br-30 w-100 customWidth mb-4 css-b62m3t-container" placeholder="Size" theme={theme}
            styles={{
              option: (base) => ({
                ...base,
                borderRadius: "30px",
                width: "90%",
                height: "auto",
                lineHeight: "32px",
                fontSize: "14px",
                margin: "10px",
                cursor: 'pointer',
                color: '#000000',
                boxShadow:
                  "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
              }),
            }}
          />
        </div>
      </div>
      <div className="row p-20">
        <div className="text_box mb-4">
          <div>
            <ol>
              <h6>Redeeming in-person tickets:</h6>
              <li>Check your ticket(s) attached to this email. The following instructions apply to in-person tickets.</li>
              <li>Print out your attached ticket(s) or simply download them to your mobile device and bring them with you to the event.</li>
              <li>All barcodes must be visible and clear.</li>
              <li>Please note that exact instructions may differ between event organizers. Please reach out to your event's organizer directly if you have any questions.</li>
            </ol>

            <ol>
              <h6>Redeeming streaming tickets:</h6>
              <li>Check your ticket(s) attached to this email. The following instructions apply to streaming tickets.</li>
              <li>Streaming checkin will begin 10 minutes prior to the event. </li>
              <li>If you’ve purchased streaming tickets, you will see links below next to each of your tickets to join the live stream. Treat these links as passwords, and do not share them with anyone to avoid being kicked out of the stream.</li>
              <li>Once it’s time, click the link to join the live stream.</li>
              <li>Enjoy the event!</li>
            </ol>

            <ol>
              <h6>Redeeming add-ons:</h6>
              <li>Check your add-on(s) attached to this email. The following instructions apply to add-ons.</li>
              <li>Print out your attached add-on(s) or simply download them to your mobile device and bring them with you to the event.</li>
              <li>All barcodes must be visible and clear.</li>
              <li>Please note that exact instructions may differ between event organizers. Please reach out to your event’s organizer directly if you have any questions.</li>
            </ol>
          </div>
        </div>

        <div className="row mb-4">
          <div className="check_box">
            <span>Add this Privacy Policy to my  Digital Businesss Card</span>
            <div className="check_icon">
              <AiFillCheckCircle class="icon" />
            </div>
          </div>
        </div>


        <div className="row mb-5">
          <div className="col-md-10 mx-auto ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Privacy Policy</h6>
              <div className="flex items-center">
                <span className="QR_label">Copy & Paste New Link Above</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate New Link
                </button> */}
                <ButtonBoxNew boxwidth="w-240 br-30 bg2" name="Generate New Link" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-4 text-center">
            {/* <button class="btn btn-primary yellow_btn px-2" type="button">
              Generate New Policy
            </button> */}
            <ButtonBoxNew boxwidth="w-240 br-30 bg2 mt-0" name="Generate New Policy" />
          </div>
          <div className="col-md-8 select">
            <div className="bgArrow position-relative shadow-fix">
              <label className="mb-1 sr-only"></label>                       
              <Select isSearchable={false}
                options={options}
                className="country mb-4 w-100 mx-auto text-center"
                placeholder="Select Other Terms and Conditions"
                theme={theme}
                styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: "50px",
                    width: "94%",
                    height: "50px",
                    lineHeight: "32px",
                    margin: "10px",
                    cursor: 'pointer',

                    boxShadow:
                      "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                  }),
                }}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
