import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackwardStep, faForwardStep, faExpand, faGear } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img1 from '../../../../../../images/profileview/img36.png';
import img2 from '../../../../../../images/profileview/img37.png';
import img3 from '../../../../../../images/profileview/img38.png';
import img4 from '../../../../../../images/profileview/img35.png';
import faShare from '../../../../../../images/icons/faShare.svg';
import faDownload from '../../../../../../images/icons/faDownload.svg';
import faPlay from '../../../../../../images/icons/faPlay.svg';
import { NavItem } from 'react-bootstrap';
import RangeSlider from "react-bootstrap-range-slider";
import { useTranslation } from "react-i18next";



export default function FavouritePodcast() {
  const { t, i18n } = useTranslation();

  const [value, setValue] = useState(0);
  return (
    <div className="text-center mb-5">
      <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">Favourite Podcast</h4>
      <div className="shadow-4 br-20 py-4 px-4 d-flex justify-content-center align-items-center flex-column">
        <div className="br-10 shadow-4 p-2 w-100">
          <div className="d-flex justify-content-center align-items-end">
            <span className="shadow-4 w-34 mb-5 d-flex justify-content-center align-items-center rounded-circle">
              <Link to="#"><FontAwesomeIcon icon={faBackwardStep} className="h6 m-0" /></Link>
            </span>
            <span className="mx-3">
              <div className="bg-headphone d-flex justify-content-center align-items-end">
              <img src={img4} alt="" width="60" height="60" className="rounded-circle" />
              </div>
              <h4 className="shadow-5in bg px-2 py-2 h7 fw-bold text-uppercase rounded-1 fw-bold d-inline-block">
                {t('profile_section.le_precepteur')} </h4>
            </span>
            <span className="shadow-4 w-34 mb-5 d-flex justify-content-center align-items-center rounded-circle">
              <Link to="#"><FontAwesomeIcon icon={faForwardStep} className="h6 m-0" /></Link>
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <ul className="w-100 d-flex justify-content-between align-items-center">
              <NavItem as="li" className="w-100x">
                <RangeSlider
                  value={value}
                  onChange={(changeEvent) =>
                    setValue(changeEvent.target.value)
                  }
                  min={0}
                  max={10}
                />
              </NavItem>
              <NavItem as="li" className="d-flex">
                <Link to="#" className="w-34 p-1 rounded-circle d-flex justify-content-center align-items-center">
                  <span className="bg shadow-4 w-100 h-100 rounded-circle d-flex justify-content-center align-items-center">
                    {/* <FontAwesomeIcon icon={faDownload} className="text-primary h6 m-0" /> */}
                    <img src={faDownload} alt="" />
                  </span>
                </Link>
                <Link to="#" className="w-34 p-1 mx-2 shadow-5in rounded-circle d-flex justify-content-center align-items-center">
                  <span className="bg shadow-4 w-100 h-100 rounded-circle d-flex justify-content-center align-items-center">
                    {/* <FontAwesomeIcon icon={faPlay} className="text-primary h6 m-0" /> */}
                    <img src={faPlay} alt="" />
                  </span>
                </Link>
                <Link to="#" className="w-34 p-1 rounded-circle d-flex justify-content-center align-items-center">
                  <span className="bg shadow-4 w-100 h-100 rounded-circle d-flex justify-content-center align-items-center">
                    {/* <FontAwesomeIcon icon={faShare} className="text-primary h6 m-0" /> */}
                    <img src={faShare} alt="" />
                  </span>
                </Link>
              </NavItem>
              <NavItem as="li" className="">
                <div className="w-60 h-30 shadow-4 br-30 d-flex justify-content-between align-items-center px-2">
                  <Link to="#" className="">
                    <span className="">
                      <FontAwesomeIcon icon={faGear} className="h6 m-0" />
                    </span>
                  </Link>
                  <Link to="#" className="">
                    <span className="">
                      <FontAwesomeIcon icon={faExpand} className="h6 m-0" />
                    </span>
                  </Link>
                </div>
              </NavItem>
            </ul>
          </div>
        </div>
        <div className="d-flex justify-content-center w-100 mt-3 align-items-center">
          <img src={img2} width="88" height="98" alt="" />
          <div className=" mx-2">
            <h4 className="shadow-5in bg px-2 py-2 h10 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.episode_5_season_1')}</h4>
            <div className="border-linear br-5">
              <div className="bg pt-2 pb-2 px-3">
                <h4 className="h7 m-0 p-1">{t('profile_section.french_philosophy_series')}</h4>
              </div>
            </div>
          </div>
          <img src={img3} width="88" height="98" alt="" />
        </div>
        <div className="bySec">
          <h4 className="h7 w-34 bg rounded-circle shadow-5in d-flex justify-content-center align-items-center">{t('by')}</h4>
        </div>

        <ul className="listDivide1 w-100 d-flex justify-content-center align-items-center">
          <NavItem as="li" className="w-auto">
            <span className="mx-auto bg-headphone large d-flex justify-content-center align-items-end">
              <img src={img1} alt="" width="78" height="78" className="rounded-circle" />
            </span>
            <h4 className="shadow-5in w-100 bg px-0 py-1 mt-1 h7 fw-bold rounded-1 fw-bold d-inline-block">{t('profile_section.charles_robin')}</h4>
          </NavItem>
        </ul>
      </div>
    </div>
  );
}
