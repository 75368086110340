import React from 'react'
import { NavItem } from 'react-bootstrap';

export default function SubHeading(props) {
  return (
    <div className='moveSection'>
      <NavItem as="li">
        <img src={props.src} alt={props.alt} />
        <h6>{props.text}</h6>
      </NavItem>
    </div>
  )
}