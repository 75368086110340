import React from "react";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
import { Link } from 'react-router-dom';
import ButtonBox from '../../../global/ButtonBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import RangeSelect from "../../../global/rangeSelect/RangeSelect";
import {YearExperiance, incomeRange} from '../../../global/rangeSelect/RangeValues';
import { useState } from 'react';
import BusinessTab from '../tabs/BusinessTab';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import EditorDrop from "../../../global/EditorDrop";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import DropdownBox from "../../../global/DropdownBox";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';
export default function EmployementInfoTab() {
  const { t, i18n } = useTranslation();

  const [selectedExperiance, setSelectedExperiance] = useState(YearExperiance[Math.floor(YearExperiance.length/2)])
  const [selectedIncome, setSelectedIncome] = useState(incomeRange[Math.floor(incomeRange.length/2)])
  const [gender, setGender] = useState('');
  const inputRef = React.useRef();
  const [previewImage, setPreviewImage] = useState('');
  const [employed, setEmployed] = useState(false);
  const [notEmployed, setNotEmployed] = useState(false);
  const [stillWork, setStillWork] = useState(false);


  const [ yearStarted, setYearStarted ] = useState(2000); 
  const [ yearEnded, setYearEnded ] = useState(2000); 


  const getGenderName = (e) =>{
    setGender(e.target.value);
 }
 const openSelect = () =>{
  inputRef.current.click();
}
  
  const theme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    }
    
  });
  // const options = [
  //       { value: 'USD', label: 'USD' },
  //       { value: 'CAD', label: 'CAD' },
  //       { value: 'EURO', label: 'EURO' },
  //       { value: 'POUNDS', label: 'POUNDS' },
  //       { value: 'OTHER', label: 'OTHER' },
        
  //     ]
      
  const currencyOptions =
  [{
      "name": "USD",      
    }, {
      "name": "CAD",
    }, {
      "name": "EURO",      
    }, {
      "name": "POUNDS",      
    }
  ] 
  let {isVideoVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };
  return (
    <div>
      <div className="w-780 mb-4 align-items-center mx-auto d-flex justify-content-between">
        <span className="d-flex align-items-center justify-content-center">
          <button className="buttonArrow me-3 border-0 bg br-10">
            <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
          </button>
          <button className="buttonArrow bg border-0 br-10">
            <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
          </button>
        </span>
        <ButtonBox boxwidth="w-110 mt-0 mb-4" clicked="Saved" name="Save" />
      </div>
      <div className="factorTab w-780 text-start d-block br-20 position-relative mx-auto mb-5">
      <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
        <h3 className="mb-3 d-flex justify-content-between w-600 pe-5">{t('profile_section.enter_your_profile_details')} <small>
          {t('step')} <b>5</b> of 11</small></h3>
        <div className="ProgressBar">
          <ul>
              <NavItem as="li" className="active" id="profile"></NavItem>
              <NavItem as="li" className="active" id="chat"></NavItem>
              <NavItem as="li" className="active" id="location"></NavItem>
              <NavItem as="li" className="active" id="user"></NavItem>
              <NavItem as="li" className="active" id="briefcase"></NavItem>
              <NavItem as="li" id="graduation-cap"></NavItem>
              <NavItem as="li" id="favorite"></NavItem>
              <NavItem as="li" id="award"></NavItem>
              <NavItem as="li" id="connectivity"></NavItem>
              <NavItem as="li" id="lock"></NavItem>
              <NavItem as="li" id="eye"></NavItem>
          </ul>
        </div>
        <BusinessTab /> 
        <form>
          <div className="text-center mb-5">
            <h5>{t('profile_section.your_employment_information')}</h5>
            <div className="row my-5">
              <div className="col-md-5 border-right">
                <div className="text-start my-auto">
                  <label>{t('profile_section.are_you_currently_employed')}</label>
                  <div className="my-3 d-flex justify-content-center">
                  <div className='check-button w-500 mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="radio" className="btn-check" name="employement-status" id="yes"  value={employed} onChange={(e)=> {setEmployed(!employed);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="yes"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.yes_i_am')}
                    </div>
                    <div className='check-button w-500 mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="radio" className="btn-check" name="employement-status" id="no"  value={notEmployed} onChange={(e)=> {setNotEmployed(!notEmployed);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="no"><div className="radioInner"><span></span></div></label>
                        {t('no')}

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
              <div className="slidecontainer mb-3">
              <p>{t('profile_section.year_started')}</p>
              <RangeSlider value={yearStarted} onChange={changeEvent => setYearStarted(changeEvent.target.value)} min={2000} max={2099}/>
            </div>
              <div className="slidecontainer mb-3">
              <p>{t('profile_section.year_ended')}</p>
              <RangeSlider value={yearEnded} onChange={changeEvent => setYearEnded(changeEvent.target.value)} min={2000} max={2099}/>
            </div>
              <div className='check-button w-500 mx-auto my-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="still-work" id="still-work"  value={stillWork} onChange={(e)=> {setStillWork(!stillWork);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="still-work"><div className="radioInner"><span></span></div></label>
                        {t('profile_section.i_still_work_there')}
                    </div>
              </div>
            </div>
            <div className="btn-group-container d-inline-flex">
              <div className="radioTab" data-toggle="buttons">
              <div>
                <input type="radio" className="btn-check" id="part-time"  name="working-time" />
                <label className="btn bg" htmlFor="part-time"> {t('profile_section.part_time')}</label>
              </div>
              <div>
                <input type="radio" className="btn-check" id="full-time"  name="working-time" />
                <label className="btn bg" htmlFor="full-time">{t('profile_section.full_time')}</label>
              </div>
              <div>
                <input type="radio" className="btn-check" id="seasonal"  name="working-time" />
                <label className="btn bg" htmlFor="seasonal">{t('profile_section.seasonal')}</label>
              </div>
              <div>
                <input type="radio" className="btn-check" id="freelance"  name="working-time" />
                <label className="btn bg" htmlFor="freelance">{t('profile_section.freelance')}</label>
              </div>
              <div>
                <input type="radio" className="btn-check" id="terminated"  name="working-time" />
                <label className="btn bg" htmlFor="terminated">{t('profile_section.terminated')}</label>
              </div>
              <div>
                <input type="radio" className="btn-check" id="escapee"  name="working-time" />
                <label className="btn bg" htmlFor="escapee">{t('profile_section.i_am_a_9_to_5_escapee')}</label>
              </div>
              <div>
                <input type="radio" className="btn-check" id="7-boss"  name="working-time" />
                <label className="btn bg" htmlFor="7-boss">{t('profile_section.i_am_a_7_figure_boss')}</label>
              </div>
              <div>
                <input type="radio" className="btn-check" id="6-figure-boss"  name="working-time" />
                <label className="btn bg" htmlFor="6-figure-boss">{t('profile_section.i_am_a_6_figure_boss')}</label>
              </div>
              <div>
                <input type="radio" className="btn-check" id="socialite-boss"  name="working-time" />
                <label className="btn bg" htmlFor="socialite-boss">{t('profile_section.i_am_a_socialite_boss')}</label>
              </div>
              <div>
                <input type="radio" className="btn-check" id="diva-boss"  name="working-time" />
                <label className="btn bg" htmlFor="diva-boss">{t('profile_section.i_am_a_diva_boss')}</label>
              </div>
            </div>
          </div>
     
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="mb-2">{t('profile_section.name_of_employer_company_name')}</label>
              <input
                placeholder={t('profile_section.current_or_last_place_of_Work')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
            </div>
            <div className="col-md-12">
              <label className="mb-2">{t('profile_section.current_job_title')}</label>
              <input
                placeholder={t('profile_section.ceo_social_network_strategist')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
            </div>
              <div className="col-md-12">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <label className="">{t('profile_section.describe_your_typical_day_at_work')}</label>
                  <label className="ms-auto me-3">{t('profile_section.text_formating')}</label>
                  <div className="dropFormat">
                    <div className="d-flex">
                      <div className="dropdown btnArrow">
                        <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside"   data-bs-display="static">
                          <Link to="#" id="notab" className="d-flex justify-content-center fs-5 fw-bold ps-3 pe-5 align-items-center w-100x text-underline shadow-4 h-45 h8 br-30">
                            <u>A</u>
                          </Link>
                        </span>
                        <EditorDrop />
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  placeholder={t('profile_section.my_day_starts_with')} rows="9"
                  className="shadow-5in br-20 h-250 w-100 mb-4"
                  type="text"
                ></textarea>
            </div>
              </div>
              
          <div className="row">
            <div className="col-md-6">
              <div className="position-relative">
                <label className="mb-2">{t('profile_section.select_your_currency')}</label>
                {/* <Select isSearchable={false} options={options} className="country currencySec mb-4 w-100 mx-auto" placeholder={t('profile_section.ontario')} theme={theme}
                    styles={{
                        option: (base) => ({
                          ...base,
                          borderRadius: '30px',
                          width:'93%',
                          height:'39px',
                          lineHeight: '24px',
                          margin: '10px',
                          paddingLeft:'20px',
                          cursor: 'pointer',
                          color:'#000000',
                          boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                          
                        }),
                      }} /> */}
                      <DropdownBox name="Select Your Currency" boxid="yourcurrency" options={currencyOptions} boxwidth="country mb-4 w-100 mx-auto" />
              </div>
            </div>
            <div className="col-md-6">
             <p className="mt-4 lh-base">{t('profile_section.please_convert_your_income_in_USD_to_choose_the_equivalent_income')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
            <div className="dropLink">
                <p className="text-center neu_range_select__title mt-0 mb-3">{t('profile_section.years_of_experience')}</p>
                <RangeSelect data={YearExperiance} type='' selectedRange={selectedExperiance} setSelectedRange={setSelectedExperiance} value={selectedExperiance} />
              </div>
            </div>
            <div className="col-md-6">
            <div className="dropLink">
                <p className="text-center neu_range_select__title mt-0 mb-3">{t('profile_section.yearly_income_range')}</p>
                <RangeSelect data={incomeRange} type='' selectedRange={selectedIncome} setSelectedRange={setSelectedIncome} value={selectedIncome} />
              </div>
            </div>
          </div>
          <div className="mt-4 text-center">
            <ButtonBox boxwidth="w-140 mt-0 mb-50 mx-auto" clicked="Saved" name="Save/Next" />
          </div>
        </form>
      </div>
    </div>
  );
}
