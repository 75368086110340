import React from "react";
import ButtonBox from "../../../global/ButtonBox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import './LogoStep.scss';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import second_1 from "../../../../images/second-1.png";
import second_2 from "../../../../images/second-2.png";
import second_3 from "../../../../images/second-3.png";
import second_4 from "../../../../images/second-4.png";
import ButtonBoxNew from "../../../global/ButtonBoxNew";

export default function LogoForm2({ setStep3Images }) {

  const { t, i18n } = useTranslation();
 
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // handleFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };
  return (
    <div className="three-tab-stle">

      <div className="row">
        <div className="col-lg-12">
          <li className="mb-4">
            <label className="mb-1">{t('choose_your_logo_component.your_name')}</label>
            <input placeholder={t('choose_your_logo_component.your_name')} className='shadow-5in border-5 br-10  mb-0 me-3' type="text"></input>

          </li>
        </div>
        <div className="col-lg-12">
          <li className="mb-4">
            <label className="mb-1">  {t('choose_your_logo_component.your_slogan')}</label>
            <input placeholder={t('choose_your_logo_component.boss_up_your_leadership')} className=' border-5 shadow-5in br-10  mb-0 me-3' type="text"></input>

          </li>
        </div>




        <div className="row">

          <div className="col-lg-12">
            <li className="mb-4">
              <label className="mb-1">  {t('timeline_form_three_component.generated_specially_for_you')}</label>
              <div className="row">
                <div className="col-lg-3">
                  <div className="card-timeline">
                    <div className='check-button w-500 mx-auto mb-3 fw-bold d-flex justify-content-start align-items-center'>

                      <input type="checkbox" className="btn-check" name="remember" id="img4" onChange={e => setStep3Images(e.target.checked)} />
                      <label className="btn btn-outline-primary" htmlFor="img4">
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                        </svg>
                      </label>

                    </div>
                    <img src={second_1} alt='eight-logo' ></img>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card-timeline">
                    <div className='check-button w-500 mx-auto mb-3 fw-bold d-flex justify-content-start align-items-center'>
                      <input type="checkbox" className="btn-check" name="remember" id="img3" onChange={e => setStep3Images(e.target.checked)} />
                      <label className="btn btn-outline-primary" htmlFor="img3">
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                        </svg>
                      </label>

                    </div>
                    <img src={second_2} alt='eight-logo' ></img>
                  </div>
                </div>  <div className="col-lg-3">
                  <div className="card-timeline">
                    <div className='check-button w-500 mx-auto mb-3 fw-bold d-flex justify-content-start align-items-center'>
                      <input type="checkbox" className="btn-check" name="remember" id="img2" onChange={e => setStep3Images(e.target.checked)} />
                      <label className="btn btn-outline-primary" htmlFor="img2">
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                        </svg>
                      </label>

                    </div>
                    <img src={second_3} alt='third-logo'></img>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card-timeline">
                    <div className='check-button w-500 mx-auto mb-3 fw-bold d-flex justify-content-start align-items-center'>
                      <input type="checkbox" className="btn-check" name="img1" id="img1" onChange={e => setStep3Images(e.target.checked)} />
                      <label className="btn btn-outline-primary" htmlFor="img1">
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                        </svg>
                      </label>

                    </div>
                    <img src={second_4} alt='thelve-logo' ></img>
                  </div>
                </div>
              </div>


            </li>

          </div>


        </div>

        <div id="separator" className="mt-3 mb-3"><hr className="hr_s" /><p className="ml-3 mr-3">OR</p><hr className="hr_s" /></div>
        <p>
          Upload your own logo
        </p>
        <div className="file-loader">

          <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
            <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
              <div>
                <FontAwesomeIcon icon={faImage} className="h4 mb-0 " />

                <p>Drag & Drop Your Logo Here</p>

              </div>
            </label>
            <button className="upload-button" onClick={onButtonClick}>Upload From Computer</button>
            {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
          </form>
        </div>
      </div>

      <ul className="d-flex step-three-btn">
        <li className=" text-center time-btn">

          <ButtonBox

            boxwidth="w-150 mt-5 mb-50 mx-auto"
            clicked="Confirm Logo"
            name="Confirm Logo"
          />
        </li>
        {/* <Link to="/threeHoursBusinessLaunchStepLogoEditor" > */}
          <li className=" text-center time-btn time-btn-two pt-2">

            {/* <ButtonBox

              boxwidth="w-150 mt-5 mb-50  mx-auto"
              clicked="Confirm Logo"
              name="Customize Logo"
            /> */}
            <ButtonBoxNew boxwidth="w-200 mt-5" name="Customize Logo" />
          </li>
        {/* </Link> */}
      </ul>
    </div>
  );
}
