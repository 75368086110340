import React from "react";
// import { Link } from 'react-router-dom';

export default function DropdownBox(props) {
  // const data = props;
  return (
   
    
    <div className={"dropdown " + props.boxwidth} id={props.boxid}>
      {/* <div className="bgborder"></div> */}
      <button className="btn bgArrow5 position-relative shadow-4 br-30 border-0 outline-none py-2 px-3 w-100" type="button" id={props.boxid} data-bs-toggle="dropdown" aria-expanded="false">
        {/* <img src={frame} className='me-2'/> */}
          {props.name}
      </button>
      <ul className="dropdown-menu customDrop relative" aria-labelledby={props.boxid}>
       
       {props.options &&
       <div className="dropheight"> 
        {props.options.map((item) => (
          <li><div className="spansec"><div className="dropdiv" >{item.icon ? item.icon : ""}{item.name}</div></div></li>
        ))}
</div>
}
          
          
      </ul>
    </div>
        
  );
}
