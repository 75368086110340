import React, { useState } from "react";
import desktop_3_6 from "../../../../../images/website-mode/Desktop3-6.png";
import tab_3_6 from "../../../../../images/website-mode/Tab3-6.png"
import mobile_3_6 from "../../../../../images/website-mode/Mobile3-6.png"
import desktop_3_7 from "../../../../../images/website-mode/Desktop3-7.png";
import tab_3_7 from "../../../../../images/website-mode/Tab3-7.png"
import mobile_3_7 from "../../../../../images/website-mode/Mobile3-7.png"
import desktop_3_8 from "../../../../../images/website-mode/Desktop3-8.png";
import tab_3_8 from "../../../../../images/website-mode/Tab3-8.png"
import mobile_3_8 from "../../../../../images/website-mode/Mobile3-8.png"
import desktop_3_9 from "../../../../../images/website-mode/Desktop3-9.png";
import tab_3_9 from "../../../../../images/website-mode/Tab3-9.png"
import mobile_3_9 from "../../../../../images/website-mode/Mobile3-9.png"
import desktop_3_10 from "../../../../../images/website-mode/Desktop3-10.png";
import tab_3_10 from "../../../../../images/website-mode/Tab3-10.png"
import mobile_3_10 from "../../../../../images/website-mode/Mobile3-10.png"
import desktop_3_11 from "../../../../../images/website-mode/Desktop3-11.png";
import tab_3_11 from "../../../../../images/website-mode/Tab3-11.png"
import mobile_3_11 from "../../../../../images/website-mode/Mobile3-11.png"
import Modal from 'react-bootstrap/Modal';
import '../WeekendModeSectionThemes.scss';
import { useTranslation } from "react-i18next";
import ButtonBoxNew from "../../../../global/ButtonBoxNew";


export default function ComingSoonSectionGlassmorphicTheme() {
  const { t, i18n } = useTranslation();

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [themedesktopShow, setThemedesktopShow] = useState(false);
  const [themetabShow, setThemetabShow] = useState(false);
  const [themedesktop3Show, setThemedesktop3Show] = useState(false);
  const [themetab3Show, setThemetab3Show] = useState(false);
  const [thememobileShow, setThememobileShow] = useState(false);
  const [thememobile3Show, setThememobile3Show] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);
  const [desktopgeoShow, setDesktopgeoShow] = useState(false);



  return (
    <div className="">

      <h4 className="text-center">
        Glassmorphic  Design Version 1
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_6} alt='desktop-3-1' >
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setDesktopgeoShow(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setDesktopgeoShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktopgeoShow}
            onHide={() => setDesktopgeoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeDesktopView1 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_6} alt='desktop-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setSmShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeTabView1 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_6} alt='mobile-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeMobileView1 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Glassmorphic  Design Version 2
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_7} alt='desktop-3-1' >
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setDesktopgeoShow(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setDesktopgeoShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktopgeoShow}
            onHide={() => setDesktopgeoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeDesktopView1 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_7} alt='desktop-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setSmShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeTabView1 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_7} alt='mobile-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeMobileView1 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Glassmorphic  Design Version 3
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_8} alt='desktop-3-1' >
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setDesktopgeoShow(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setDesktopgeoShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktopgeoShow}
            onHide={() => setDesktopgeoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeDesktopView1 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_8} alt='desktop-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setSmShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeTabView1 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_8} alt='mobile-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeMobileView1 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Glassmorphic  Design Version 4
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_9} alt='desktop-3-1' >
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setDesktopgeoShow(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setDesktopgeoShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktopgeoShow}
            onHide={() => setDesktopgeoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeDesktopView1 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_9} alt='desktop-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setSmShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeTabView1 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_9} alt='mobile-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeMobileView1 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Glassmorphic  Design Version 5
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_10} alt='desktop-3-1' >
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setDesktopgeoShow(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setDesktopgeoShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktopgeoShow}
            onHide={() => setDesktopgeoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeDesktopView1 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_10} alt='desktop-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setSmShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeTabView1 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_10} alt='mobile-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeMobileView1 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Glassmorphic  Design Version 6
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_11} alt='desktop-3-1' >
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setDesktopgeoShow(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setDesktopgeoShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktopgeoShow}
            onHide={() => setDesktopgeoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeDesktopView1 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_11} alt='desktop-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setSmShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeTabView1 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_11} alt='mobile-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeMobileView1 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
    
    </div>
  );
}
