import React from "react";
import Slide1 from "../../images/banner/banner4s.png"
import Slide2 from '../../images/banner/banner4ss.png';
import { useState } from 'react';

export default function BannerThree(props) {

  const openSelect1 = () =>{inputRef1.current.click();}
const inputRef1 = React.useRef();
const [previewImage1, setPreviewImage1] = useState('');
const [setImage1] = useState('');

const openSelect2 = () =>{inputRef2.current.click();}
const inputRef2 = React.useRef();
const [previewImage2, setPreviewImage2] = useState('');
const [setImage2] = useState('');

  return (
    <div className={"banner4 " + props.boxwidth}>
      <div className="row borderdiv mx-0">
        <div className="col-lg-3s">
          <div className="d-block boxiner">
            <label onClick={openSelect1} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage1 ? (
                  <img
                    src={previewImage1}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={Slide1} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage1(url);
                setImage1(e.target.files[0]);
              }}
              ref={inputRef1}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="boxiner">
            <div className="banner4-iner d-flex flex-column justify-content-center align-items-center">
              <h5>DYNA APPOLON</h5>
              <h6>SOCIAL NETWORK STRATEGIST</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-3s">
          <div className="d-block boxiner">
            <label onClick={openSelect2} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage2 ? (
                  <img
                    src={previewImage2}
                    alt=""
                    className="w-100 h-100 m-0"
                  />
                ) : (
                  <img src={Slide2} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage2(url);
                setImage2(e.target.files[0]);
              }}
              ref={inputRef2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
