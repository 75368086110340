import axios from 'axios';
import { baseUrl } from '../../utils/basePath';
import { toast } from 'react-toastify';

// Subscription
export const SubscriptionAction = (data,setEmail) => {
  console.log("data is subscription",data);
  return async dispatch => {
  try {
    await axios.post(baseUrl + '/subscription/subscribe', data).then(response => {
        dispatch({
          type: 'Subscription_Success',
          payload: response.data,
        });
        toast(response.data.message);
        setEmail('');
    });
  } catch (err) {
    console.log('err', err);
    dispatch({type: 'Subscription_Error', msg: 'Server Error'});
  }
}
};
