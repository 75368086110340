import React, { useState } from "react";
import rocket from '../../../../../images/StepZero/img01.png';
import img1 from '../../../../../images/StepZero/img2.png';
import img2 from '../../../../../images/StepZero/img3.png';
import img3 from '../../../../../images/StepZero/img5.png';
import img4 from '../../../../../images/StepZero/img6.png';
import img5 from '../../../../../images/StepZero/img7.png';
import img6 from '../../../../../images/StepZero/img8.png';
import img7 from '../../../../../images/StepZero/img9.png';
import img8 from '../../../../../images/StepZero/img10.png';
import img9 from '../../../../../images/StepZero/img11.png';
import img10 from '../../../../../images/StepZero/left.png';
import img11 from '../../../../../images/StepZero/right.png';
import { Link } from 'react-router-dom';
// import { button } from 'react-router-dom';
import NicheFormOne from "./NicheFormOne";
import NicheOne from "./Nicheform/NicheOne";
import NicheTwo from "./Nicheform/NicheTwo";
import NicheThree from "./Nicheform/NicheThree";
import NicheFour from "./Nicheform/NicheFour";
import NicheFive from "./Nicheform/NicheFive";
import NicheSix from "./Nicheform/NicheSix";
import NicheOneSec from "./Nicheform/NicheOneSec";
import NicheSeven from "./Nicheform/NicheSeven";
import NicheOneEdit from "./Nicheform/NicheOneEdit";
import img1s from '../../../../../images/triangle1.png';
import NicheSixSec from "./Nicheform/NicheSixSec";
export default function NicheClarity() {
  const handleClick1 = () => {
    setToggle1(!toggle1);
  };
  const [toggle1, setToggle1] = useState("true");

  const handleClick = () => {
    setToggle(!toggle);
  };
  const [toggle, setToggle] = useState("true");

  const handleClick2 = () => {
    setToggle2(!toggle2);
  };
  const [toggle2, setToggle2] = useState("true");

  const [valueFromComponentA, setValueFromComponentA] = useState('');

  const handleValueChange = (newValue) => {
    // Receive the value from Component A and set it in the parent component
    setValueFromComponentA(newValue);
  };
  return (
    <div className="nicheClass mx-auto">
      <ul className='w-100 nichul'>
        <li className='d-flex flex-column-reverse pe-4 nichli'>
            {toggle ? (
              <></>
            ) : (
              <div className="borderTop1">
                <div className="borderTop">
                <Link className='leftlink'>
                  <img src={img10} alt="" width="38px" />
                </Link>
                <Link className='rightlink'>
                  <img src={img11} alt="" width="38px" />
                </Link>
                  <div className="tab-content formdiv shadow-5in p-3" id="myTabCont">
                    <div className="tab-pane fade show active" id="housekeep" role="tabpanel" aria-labelledby="housekeep">
                      <NicheFormOne />
                    </div>
                    <div className="tab-pane fade" id="marketrwe" role="tabpanel" aria-labelledby="marketrwe">
                      <div className="col-lg-6 mx-auto">
                        <NicheOne />
                        <span  onClick={handleClick1} className="cursor-pointer"><img src={img1s} alt="" width="40px" height="20px" /></span>
                      </div>
                      {toggle1 ? (
                        <></>
                        ) : (
                        <div className="col-lg-12 mx-auto centerdiv">
                          <NicheOneEdit handleClick1={handleClick1} />
                        </div>
                      )}
                    </div>
                    <div className="tab-pane fade" id="strategywe" role="tabpanel" aria-labelledby="strategywe">
                      <div className="col-lg-6 mx-auto">
                        <NicheOneSec  />
                      </div>
                    </div>
                    <div className="tab-pane fade" id="brandingrwe" role="tabpanel" aria-labelledby="brandingrwe">
                      <NicheTwo />
                    </div>
                    <div className="tab-pane fade" id="canvasplaningwe" role="tabpanel" aria-labelledby="canvasplaningwe">
                      <NicheThree />
                    </div>
                    <div className="tab-pane fade" id="summarytbwe" role="tabpanel" aria-labelledby="summarytbwe">
                        <NicheFour />
                    </div>
                    <div className="tab-pane fade" id="decktbwe" role="tabpanel" aria-labelledby="decktbwe">
                        <NicheFive />
                    </div>
                    <div className="tab-pane fade" id="registertbwe" role="tabpanel" aria-labelledby="registertbwe">
                      <div className="px-3 pt-3">
                      <NicheSix onValueChange={handleValueChange} />
                      <span className="cursor-pointer"><img src={img1s} alt="" width="40px" height="20px" /></span>
                      <NicheSixSec activeColor={valueFromComponentA} />
                      </div>
                    </div>
                    <div className="tab-pane fade" id="registwe" role="tabpanel" aria-labelledby="registwe">
                      <NicheSeven />
                    </div>
                  </div>
              </div>
              </div>
            )}
          <div className='d-flex align-items-start'>
            <img src={rocket} alt='' className='position-relative top-0 start-0' height="300" />
            <div className="d-flex pt-6">
              <div id="myTab" className='d-flex' role="tablist">
                <button onClick={handleClick} className='px-3 border-0 bg-transparent active' id="housekeep" data-bs-toggle="tab" data-bs-target="#housekeep" type="button" role="tab" aria-controls="housekeep" aria-selected="true">
                  <img src={img1} alt="" height="110" width='119' className='firstimg' />
                </button>
                <div className='toptb d-flex'>
                  <span className=''>
                    <button id="marketrwe" data-bs-toggle="tab" data-bs-target="#marketrwe" type="button" role="tab" aria-controls="marketrwe" aria-selected="true" className='d-flex flex-column justify-content-center align-items-center border-0 bg-transparent w-100'>
                      <img src={img2} alt="" height="67" width='58' />
                      <h6>BUSINESS<br/> NAME</h6>
                    </button>
                  </span>
                  <span className=''>
                    <button id="strategywe" data-bs-toggle="tab" data-bs-target="#strategywe" type="button" role="tab" aria-controls="strategywe" aria-selected="false" className='d-flex flex-column justify-content-center align-items-center border-0 bg-transparent w-100'>
                      <img src={img3} alt="" height="67" width='58' />
                      <h6>FOUNDED <br />YEAR</h6>
                    </button>
                  </span>
                  <span className=''>
                    <button id="brandingrwe" data-bs-toggle="tab" data-bs-target="#brandingrwe" type="button" role="tab" aria-controls="brandingrwe" aria-selected="false" className='d-flex flex-column justify-content-center align-items-center border-0 bg-transparent w-100'>
                      <img src={img4} alt="" height="67" width='58' />
                      <h6>PHONE <br/> NUMBER</h6>
                    </button>
                  </span>
                  <span className=''>
                    <button id="canvasplaningwe" data-bs-toggle="tab" data-bs-target="#canvasplaningwe" type="button" role="tab" aria-controls="canvasplaningwe" aria-selected="false" className='d-flex flex-column justify-content-center align-items-center w-100 border-0 bg-transparent'>
                      <img src={img5} alt="" height="67" width='58' />
                      <h6>HEADQUARTER LOCATION</h6>
                    </button>
                  </span>
                  <span className=''>
                    <button id="summarytbwe" data-bs-toggle="tab" data-bs-target="#summarytbwe" type="button" role="tab" aria-controls="summarytbwe" aria-selected="true" className='d-flex flex-column justify-content-center align-items-center w-100 border-0 bg-transparent'>
                      <img src={img6} alt="" height="67" width='58' />
                      <h6>DOMAIN <br/> NAME</h6>
                    </button>
                  </span>
                  <span className=''>
                    <button id="decktbwe" data-bs-toggle="tab" data-bs-target="#decktbwe" type="button" role="tab" aria-controls="decktbwe" aria-selected="false" className='d-flex flex-column justify-content-center align-items-center border-0 bg-transparent w-100'>
                      <img src={img7} alt="" height="67" width='58' />
                      <h6>CONTACT <br/> EMAIL</h6>
                    </button>
                  </span>
                  <span className=''>
                    <button id="registertbwe" data-bs-toggle="tab" data-bs-target="#registertbwe" type="button" role="tab" aria-controls="registertbwe" aria-selected="false" className='d-flex flex-column justify-content-center w-100 align-items-center border-0 bg-transparent'>
                      <img src={img8} alt="" height="67" width='58' />
                      <h6>INDUSTRY</h6>
                    </button>
                  </span>
                  <span className=''>
                    <button id="registwe" data-bs-toggle="tab" data-bs-target="#registwe" type="button" role="tab" aria-controls="registwe" aria-selected="false" className='d-flex flex-column justify-content-center align-items-center border-0 w-100 bg-transparent'>
                      <img src={img9} alt="" height="67" width='58' />
                      <h6>NICHE</h6>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="nichli pb-200">

        </li>
      </ul>
    </div>   
  )
}
