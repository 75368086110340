import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

import Slide7 from '../../../images/social/icon45.svg';
import BannerFirst from "../BannerFirst"
import BannerTwo from "../BannerTwo";
import BannerThree from "../BannerThree";
import BannerFour from "../BannerFour";
import BannerFive from "../BannerFive";
import BannerSix from "../BannerSix";


export default class SimpleSlider extends Component {
  
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };


    // const openSelect1 = () => { inputRef1.current.click(); }
    // const inputRef1 = React.useRef();
    // const [image1, setImage1] = useState('');
    // const [previewImage1, setPreviewImage1] = useState('');

    return (
      <div className="px-4 position-relative">
        <Slider {...settings} className="socialSlider ">
          <div>
            <BannerFirst />
          </div>
          <div>
            <BannerTwo />
          </div>
          <div>
            <BannerThree />
          </div>
          <div>
            <BannerFour />
          </div>
          <div>
            <BannerFive />
          </div>
          <div>
            <BannerSix />
          </div>
        </Slider>
        <div className="position-absolute bottom-0 end-0 py-3 px-5 z-10">
          <Link to="" className="text-primary downloadLink shadow-4 d-flex bg justify-content-center align-items-center">
          <img src={Slide7} alt="" />
          </Link>
        </div>
        {/* <div className="position-absolute top-0 start-0 w-100 px-4">
          <div className="sliderupload">
            <img src={Slide4} alt="" />
            <div className="imgView br-20 bg mb-2">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect1} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage1 ? <img src={previewImage1} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="20" height="24" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.158 6.84211C4.158 3.06332 7.22131 0 11.0001 0C14.7789 0 17.8422 3.06332 17.8422 6.84211C17.8422 10.6209 14.7789 13.6842 11.0001 13.6842C7.22131 13.6842 4.158 10.6209 4.158 6.84211ZM11.0001 10.9474C13.2674 10.9474 15.1054 9.10938 15.1054 6.84211C15.1054 4.57483 13.2674 2.73684 11.0001 2.73684C8.73283 2.73684 6.89484 4.57483 6.89484 6.84211C6.89484 9.10938 8.73283 10.9474 11.0001 10.9474Z" fill="#4E4E57" />
                          <path d="M3.25914 18.259C1.20611 20.3121 0.0527344 23.0966 0.0527344 26H2.78958C2.78958 23.8224 3.65461 21.7341 5.19438 20.1943C6.73416 18.6545 8.82254 17.7895 11.0001 17.7895C13.1777 17.7895 15.2661 18.6545 16.8058 20.1943C18.3456 21.7341 19.2106 23.8224 19.2106 26H21.9475C21.9475 23.0966 20.7941 20.3121 18.7411 18.259C16.688 16.206 13.9035 15.0526 11.0001 15.0526C8.09668 15.0526 5.31217 16.206 3.25914 18.259Z" fill="#4E4E57" />
                        </svg>
                        <p className="mb-0 mt-1">Change Profile image</p>
                        <p className="fw-bold mb-2">200 X 200 Pixels</p>
                         type="text" placeholder="Paste link here" className="shadow-5in h-44 br-30" />
                      </div>
                    }
                  </div>
                </label>
              </div>
               hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage1(url);
                setImage1(e.target.files[0])
              }} ref={inputRef1} />
            </div>
          </div>
        </div> */}
      </div>
    );
    
  }
  
}