import React from "react";
import ToggleButton from "../../../../../global/ToggleButton";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function ProfileGroupFour() {
  const { t, i18n } = useTranslation();

  return (
    <div className="shadow-5in w-100 p-40 br-30 mb-4">
      <h3 className="mb-5 text-uppercase">{t('profile_section.preferred_time_of_communication')}</h3>
      <ul className="row">
      <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="9-11 am" id="9-11am" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="11 am- 1:00 pm" id="InPerson1"  check="checked"/>
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="1-2pm" id="LiveMeet1"  check="checked"/>
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="2-4pm" id="Email1"  />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="4-6pm" id="4-6pm" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="6-8pm" id="6-8pm" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="8-9pm" id="8-9pm"  check="checked"/>
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Other (Ex: 10-11am & 4-8pm)" id="Other1" check="checked" />
        </NavItem>
      </ul>
    </div>
  );
}
