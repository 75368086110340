import React, {useState} from 'react'
import CounterTimer from '../../global/counterTimer/CounterTimer';
import './ThreeHoursBusinessLaunch.scss';
import { useTranslation } from "react-i18next";
import TimelineTabMobile from '../../global/timelineTab/TimelineTabMobile';
import SocialMediaOne from '../profileManagement/tabs/SocialSingle/SocialMediaOne';

export default function SocialMediaSection() {
  const { t, i18n } = useTranslation();
  const [widthFull , setWidthFull] = useState(1)
  return (
    <div className=''>

      <div className='flex header-text' ><h2>{t('business_step_2.three_hours_business_launch_challenge')} </h2>
        <div className='circles-small'>
        <div className='circle-small'>
            <div className='text'><span className="d-block">45%</span></div>
            <svg>
              <circle className='bg' cx="30" cy="30" r="20"></circle>
              <circle className='progress one' cx="30" cy="30" r="20"></circle>
            </svg>
          </div>

        </div>
      </div>
      <div className='three-hour-launch d-flex'>
        <div className=''>
          <CounterTimer />
        </div>

        <div className='width-tab-set'>
          <TimelineTabMobile />
        </div>
        <div className='width-form-set'>
          <div className='flex'>
            <div className='width-fll socialCstm px-3'>
                <SocialMediaOne widthFull={widthFull} setWidthFull={setWidthFull}  />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
