const initState = {
  user: {},
  isAuthenticated: false
};
const subscriptionReducer = (state = initState, action) => {
  switch (action.type) {
 
    case 'Subscription_Error':
      return {
        ...state,
       
        isAuthenticated: false,
        user: {},
      };
    case 'Subscription_Success':
      return {
        ...state,
       
        user: action.payload,
      
      };
   
    default:
      return state;
  }
};
export default subscriptionReducer;
