import React from "react";
import Social1 from "./SocialTab/Social1";
import Social2 from "./SocialTab/Social2";
import Social3 from "./SocialTab/Social3";
import Social4 from "./SocialTab/Social4";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function SocialMediaOne(props) {
  const { t, i18n } = useTranslation(); 


  return (
    <div>
      <div className="ProgressBar">
        <ul>
            <NavItem as="li" className="active" id="profile"></NavItem>
            <NavItem as="li" className="active" id="chat"></NavItem>
            <NavItem as="li" className="active" id="location"></NavItem>
            <NavItem as="li" className="active" id="user"></NavItem>
            <NavItem as="li" className="active" id="briefcase"></NavItem>
            <NavItem as="li" className="active" id="graduation-cap"></NavItem>
            <NavItem as="li" className="active" id="favorite"></NavItem>
            <NavItem as="li" className="active" id="award"></NavItem>
            <NavItem as="li" className="active" id="connectivity"></NavItem>
            <NavItem as="li" id="lock"></NavItem>
            <NavItem as="li" id="eye"></NavItem>
        </ul>
      </div>
      <h3 className="mb-2 custtb"> {t('profile_section.social_media_business_account_profile')}</h3>
      <div className="w-100 mb-4 align-items-center mx-auto d-flex flex-column-reverse justify-content-between">     
        <div className="tab-content w-100" id="myTabContent">
         { props.widthFull == 1 &&  
        // <div className="tab-pane fade show active" id="social1" role="tabpanel" aria-labelledby="social1">
              <Social1 />
            // </div>
            }
            { props.widthFull == 2 &&  
            
          //  <div className="tab-pane fade" id="social2" role="tabpanel" aria-labelledby="social2">
              <Social2 />
            //</div>
            
            }
            { props.widthFull == 3 &&     
            
           // <div className="tab-pane fade" id="social3" role="tabpanel" aria-labelledby="social3">
             <Social3 />
           // </div>
            
            }
            { props.widthFull == 4 &&      
            
         //   <div className="tab-pane fade" id="social4" role="tabpanel" aria-labelledby="social4">
             <Social4 />
           //  </div>
            
            }
        </div>
        <span className="d-flex align-items-center justify-content-between w-100 mb-0 SocialTabBtn">
          <div id="myTab1" className='' role="tablist">
            <ul className="d-flex justify-content-start mt-4 pb-2">
              <NavItem as="li" className="me-3">
                <button className={`m-0 p-0 border-0 firsttab active ${props.widthFull == 1 ||  props.widthFull == 2  || props.widthFull == 3 || props.widthFull == 4  ? " activetab" : ""} `}  onClick={()=> props.setWidthFull(1)}>
                  <span>1</span>
                </button>
              </NavItem>
              <NavItem as="li" className="me-3">
                <button className={`m-0 p-0 border-0 sectab ${props.widthFull == 2 || props.widthFull == 3 || props.widthFull == 4 ? " activetab" : ""}`}  onClick={()=> props.setWidthFull(2)}>
                  <span>2</span>
                </button>
              </NavItem>
              <NavItem as="li" className="me-3">
                <button className={`m-0 p-0 border-0 thrdtab ${props.widthFull == 3  || props.widthFull == 4 ? " activetab" : ""}`} id="social3" data-bs-toggle="tab" data-bs-target="#social3" type="button" role="tab" aria-controls="social3" aria-selected="false" onClick={()=> props.setWidthFull(3)}>
                  <span>3</span>
                </button>
              </NavItem>
              <NavItem as="li">
                <button className={`m-0 p-0 border-0 frthtab ${props.widthFull == 4 ? " activetab" : ""}`} id="social4" data-bs-toggle="tab" data-bs-target="#social4" type="button" role="tab" aria-controls="social4" aria-selected="false" onClick={()=> props.setWidthFull(4)}>
                  <span>4</span>
                </button>
              </NavItem>
            </ul>
          </div>
        </span>
      </div>
    </div>
  );
}
