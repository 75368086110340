import React, { useState } from 'react';
import Edit from "../../images/audio/edit.svg";
import Save from "../../images/audio/save2.svg";

function EditableTextField() {
  const [text, setText] = useState('Address, Toronto ontario canada');
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  return (
    <div className='d-flex justify-content-between align-items-center w-100'>
      {isEditing ? (
        <input
          type="text"
          value={text}
          onChange={handleInputChange}
          className='px-4 h-auto'
        />
      ) : (
        <span className='px-3'>{text}</span>
      )}
      <button onClick={handleEditClick} className='border-0 bg-transparent'>
        {isEditing ? <img src={Save} alt='' /> : <img src={Edit} alt='' />}
      </button>
    </div>
  );
}

export default EditableTextField;