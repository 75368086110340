import React from "react";
import { useState } from "react";
import 'react-calendar/dist/Calendar.css'
import EventsSlider2 from "../components/EventsSlider2/EventsSlider2"
import Calendar from 'react-calendar'
import EventsTimeline2 from '../components/EventsTimeline2/EventsTimeline2'
import "./RegularTheme2Desktop.scss";
import "../regularTheme2Mobile/RegularTheme2Mobile.scss";
import "../regularTheme2Tab/RegularTheme2Tab.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

export default function RegularTheme2Desktop({ Regular, Neumorphic }) {
  const [isVisible, setIsVisible] = useState(false);
  const [value, onChange] = useState(new Date());


  return (
    <>
      <div
        className="main-content EventRegularTheme2Desktop h_1550"
        style={{
          background: Regular ? "#CBD5E0" : Neumorphic ? "#EFF4F8" : "",
        }}
      >
        <div className="text-center-desk">
          <p className="top_heading">MY EVENTS</p>
        </div>

        <div className='container  mb-4 pb-3 mt-5 mt-lg-0'>
        <div className="row">
            <div className="col-2 eventTab2 px-0">
              <div id="myTab2" className='slidetab2' role="tablist">
                <EventsSlider2 Regular={Regular} Neumorphic={Neumorphic} />
              </div>
            </div>
            <div className="col-2 px-1">
              <div className="">
                <Calendar className="mx-auto" onChange={onChange} value={value} />
              </div>
            </div>
            <div className="col-8">
              <div>
                <EventsTimeline2 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
