export default {
  done: "Done",
  save: "Save",
  completed: "Completed",
  login: "LOGIN",
  signup: "SIGNUP",
  reset: "RESET",
  lock_screen: "LOCK SCREEN",
  first_name: "First Name",
  last_name: "Last Name",
  username: "Username",
  email: "Email",
  password: "Password",
  country: "Country",
  remember_me: "Remember me",
  add_more: "Add More",
  delete: "Delete",
  allow: "Allow",
  enter_your_email: "Enter your Email",
  enter_your_phone_number: "Enter your phone number",
  enter_new_password: 'Enter new password',
  pass_code: "Pass Code",
  agree_statement: "I still agree to the terms of use of this website",
  or: "OR",
  signed: "Signed",
  sign_in: "Sign in",
  search_placeholder: "Search...",
  step: "Step",
  no: "N0",
  by: "By",
  and: "and",
  left_bar_menu: {
    text_here: "Text here",
    all_privacy_settings: "ALL PRIVACY SETTINGS",
    professional_social_profile_mgmt: "PROFESSIONAL | SOCIAL PROFILE MGMT",
    rewards_badges_quests_points: "REWARDS: BADGES, QUESTS, POINTS",
    business_management: "BUSINESS MANAGEMENT",
    membership_plans: "MEMBERSHIP PLANS",
    membership_add_ons: "MEMBERSHIP ADD-ONS",
    business_website_mgmt: "BUSINESS WEBSITE MGMT",
    biz_performance: "BIZ PERFORMANCE",
    business_incubator: "BUSINESS INCUBATOR",
    three_hour_business_launch: "3-HOUR BUSINESS LAUNCH",
    market_research_validation: "MARKET RESEARCH - VALIDATION",
  },
  choose_your_audience: " Choose your audience",
  family_only: "Family Only",
  member_only: "Member Only",
  everyone_public: "Everyone ( Public)",
  friends_only: "Friends Only",
  friends_of_friends: "Friends of Friends",
  only_me: "Only Me",
  friends_except_this_user: "Friends Except This User",
  add_more_emails: "Add More Emails",
  only_this_person: "Only This Person",
  paceolder_word: "Word1, Word2, Word3...",
  unacceptable_words: "Unacceptable Words",
  enter_current_password: "Enter current password",
  change: "Change",
  signin_component: {
    email_username_placeholder: "Email / Username",
    password_placeholder: "Password",
    dont_have_an_account: "Dont have an account?",
    or: "OR",

  },
  signup_component: {
    select_your_age_range: "Select Your Age Range",
    what_is_your_gender: "What is Your Gender?",
    male: "Male",
    female: "Female",
    non_binary: "Non-Binary",
    non_conforming: "Non-Conforming",
    yearly_income_range_in_US_currency: "Yearly Income Range in US Currency",
    already_have_an_account: "Already have an account?",
  },
  reset_component: {
    reset_password: "RESET PASSWORD",
    verification_via: "Verification Via",
    email: "Email",
    sms: "SMS",
    fingerprint: "Fingerprint",
    face_id: "Face ID",
    a_code_will_be_emailed_to_you_to_reset_your_password: "A code will be emailed to you to reset your password",
    a_code_will_be_sent_to_your_phone: "A code will be sent to your phone",
    a_code_has_been_sent_to_your_email: "A code has been sent to your email",
    a_code_has_been_sent_to_your_phone: "A code has been sent to your phone",
    resend_code: "Resend Code",
    enter_6_digit_security_code: "Enter 6 Digit Security code",
    email_verified: "Email Verified",
    invalid_code: "Invalid code",
    try_again: "Try again",
    phone_number_verified: "Phone number verified",
    scan_your_fingerprint_to_complete_the_verification: "Scan your fingerprint to complete the verification",
    fingerprint_verified: "Fingerprint Verified",
    verfication_failed: "Verfication failed",
    scan_your_face_to_turn_on_face_recognition: "Scan your face to turn on face recognition",
    face_id_verified: "Face ID Verified",
  },
  business_step_2: {
    three_hours_business_launch_challenge: "3 HOURS BUSINESS LAUNCH CHALLENGE",
  },
  counter_timer_component: {
    step_1: "Step 1",
    complete_your_profile: "Complete Your Profile",
  },
  timeline_tab_component: {
    complete_your_profile: "Complete Your “Profile” ",
    choose_your_slogan: "Choose Your “Slogan” ",
    choose_your_logo: "choose your “logo” ",
    generate_your_mission_statement: "Generate your “mission statement” ",
    generate_your_brand_position_statement: "Generate Your “Brand Position statement”",
    Choose_Custom_domain_name: "create your custom “Domain Name” ",
    generate_your_business_email_address: "Generate Your “Business email Addresses”",
    generate_your_email_signature: "Generate Your “Email Signature” ",
    generate_your_electronic_signature: " Generate your “Electronic Signature” ",
    Choose_your_digital_business_card: "choose your “Digital Business Card”  ",
    choose_your_website_design_style: " choose your “Website Design Style” ",
    choose_your_website_hero_banner_and_aboutme_section: " choose your Website “Hero Banner & About Me” section ",
    choose_your_how_it_works_section: "choose your “How it Works” section",
    choose_your_service_package_plans_section: "Choose your “Service | Package plans” section",
    choose_your_hire_me_section: " Choose Your “Hire Me” section",
    choose_your_testimonials_case_studies_section: " Choose your “Testimonials | Case Studies” section",
    choose_your_subscription_section: " Choose your “Subscription” section",
    choose_your_blog_post_style_section: " Choose your “Blog Post Style” section",
    choose_your_podcast_series_style_section: " Choose your  “podcast Series Style” section",
    choose_your_video_playlist_style_section: " Choose your  “Video Playlist Style” section ",
    choose_your_events_section: " Choose Your “Events” section",
    choose_your_freebies_section: " Choose your “Freebies” section",
    Choose_your_as_seen_featured_on_section: "Choose Your “As Seen | Featured On” Section ",
    choose_your_emagazines_section: "Choose your “E-Magazines” section ",
    choose_your_press_release_section: "Choose your “Press Release” section",
    choose_your_contests_section: " Choose your “Contests” section ",
    choose_your_portfolio_section: " Choose Your “Portfolio” section",
    choose_your_team_section: " Choose your “Team” section  ",
    choose_your_faqs_section: " Choose your “FAQ’s” section ",
    choose_your_sponsorship_section: " Choose your “Sponsorship” section ",
    choose_your_collab_request_section: " Choose your “Collab Request” section ",
    choose_your_online_store_section: " Choose your “Online Store” section ",
    choose_your_contact_me_section: " Choose Your “Contact Me” section ",
    create_your_resume_cv_section: " Create Your ”Resume | CV” Section  ",
    choose_your_weekend_social_mode_section: " Choose your “Weekend | Social Mode” section ",
    choose_your_legal_terms: " Choose Your “Legal Terms” ",
    create_your_15_second_elevator_pitch: " Create Your 15 Second “Elevator Pitch” ",
    choose_your_thankyou_card_event_tickets_design: " Choose Your “Thank You Card & Event Tickets” design ",
    choose_your_gift_certificate_coupons_designs: " Choose Your “Gift Certificate & Coupon” Designs ",
    generate_your_qr_codes_for_all: " Generate your “QR Codes” For All",
    choose_all_your_social_media_business_account_profile: " Create All  your “Social Media Business Account Profile”",
    set_up_your_video_testimonial_wall_of_love_page: " Set Up your “Video Testimonial”  Wall of Love Page   ",
    create_your_biolink_microsite: "Create your “Bio Link” Micro-Site ",
    create_your_paypal_personal_business_accounts: " Create your “Paypal: Personal & Business Accounts” ",
    create_your_stripe_account: " Create your “Stripe Account” ",
    apply_for_your_amazon_affiliate_program: "Apply for your “Amazon Affiliate Program” ",
    apply_for_your_printful_merch_account: " Apply for your “Printful Merch Account” ",
    create_your_trustpilot_capterra_business_account: " Create your “TrustPilot & Capterra Business Account” ",
    set_up_all_analytics_on_your_subdomain: " Set-Up All “Analytics” on your SubDomain” ",
    set_up_your_sub_domain_to_search_engine_google_yahoo_bing: "Set-Up your “SubDomain to Search Engine” Google, Yahoo, Bing  ",
    list_your_new_website_on_all_betalist_startup_sites_for_exposure: " List Your New Website on All Beta-List | Startup Sites For Exposure  ",
    sign_up_for_your_own_business_office_virtual_address: "Sign-Up for your own “Business Office Virtual Address” ",
    sign_up_for_your_own_call_forwarding_business_number: " Sign-Up for your own“Call-Forwarding Business Number” ",
    sign_up_for_vip_ip_account_to_kepp_your_computer_ip_private: " Sign-Up for a “VPN IP Account” to keep your computer IP private ",
    create_a_list_of_50_people_to_introduce_your_new_biz_launch_to: " Create a List of 50 People to Introduce your New biz launch to ",
  },
  timeline_form_component: {
    create_your_slogan_tagline: "Create Your Slogan/Tagline",
    whats_your_industry: "What’s your industry? ",
    name_your_industry: "Name your industry? ",
    whats_your_business_niche: "What’s your business niche?",
    describe_your_business: "Describe your business",
    describe_placeholder: "I help Women Entrepreneurs Boss-Up Their Leadership Style",
    fashion_placeholder: "Fashion",
    other_placeholder: "Other",
    step_two_heading: "We have generated slogans for you! Select the one you like",
    tagline_checkbox_one: "Dress Like A 7-Figure Boss",
    tagline_checkbox_two: "Boss Up Your Leadership Style",
    tagline_checkbox_three: "Intentionally Dressed",
    tagline_checkbox_four: "Lead with Style",
    tagline_checkbox_five: "Turn Your Workplace into a Runway",
    tagline_custom_slogan: "Create your custom Slogan",
    tagline_boss_up_placholder: "Boss Up Your Leadership Style",




  },
  timeline_form_five_component: {
    your_custom_domain_name: "Your Custom Domain Name",
    already_have_your_own_domain: "Already Have Your Own Domain",
    website_name_label: "Type the name you want to appear as website URL",
    web_name: "Dyna",
    webtype_placeholder: ".Fashion",
    domain_name: "Domain name",
    domain_name_placeholder: ".9to5escapee.com",
    your_domain_label: "Your domain",
  },

  timeline_form_six_component: {
    your_custom_email_address: "Your new custom Business Email Address",
    client_name_label: "Client Name",
    client_name_placeholder: "Dyna",
    association_label: "Niche Association",
    association_option_placeholder: ".Fashion",
    client_number_label: "Auto-Generate Client Number",
    domain_name_label: "Domain Name",
    domain_name_placeholder: ".9to5escapee.com",
    new_email_address_label: "Your New Business Email Address is:",
    generate_a_secured_password: "GENERATE A SECURED PASSWORD",
    email_password_label: "YOUR NEW SECURED BUSINESS EMAIL PASSWORD' IS:",
    lowercase_label: "Lowercase",
    numbers_label: "Numbers",
    uppercase_label: "Uppercase",
    symbols_label: "Symbols",
  },

  timeline_form_seven_component: {
    create_your_email_signature: "Create Your “Email Signature",
    dyna: "Dyna",
    appolon: "Appolon",
    company_name_label: "Company Name",
    company_name_placeholder: "7 Figure Bosses",
    industry_name_label: "Industry Name",
    industry_name_placeholder: "Social Network",
    job_title_label: "Job Title",
    job_title_placeholder: "CEO | Social Network Strategist",
    department_label: "Department",
    department_placeholder: "Startup",
    mission_statement_label: "Mission Statement",
    mission_statement_placeholder: "I help women boss up their leadership style for maximum impact",
    slogan_label: "Slogan",
    slogan_placeholder: "Boss Up Your Leadership style",
    your_favorite_quote_label: "Your Favourite Quote",
    your_favorite_quote_placeholder: "“I never dreamed about success. I worked for it.” Estée Lauder",
    email_address: "Email Address",
    email_address_placeholder: "dyna@fashion1.9to5escapee.com",
    website: "Website",
    website_placeholder: "dyna.fashion.7figurebosses.com",
    phone: "Phone",
    phone_placeholder: "1-905-000-0000",
    business_address: "Business Address",
    business_address_placeholder: "123 Success Rd, Smart City, Ontario M1E 3R0",
    featureed_content: "Featured Content",
    remember_my_details: "Remember my details",
  },
  timeline_form_thirtyseven_component: {
    create_your_elevator_pitch: "Create Your “Elevator Pitch”",
    already_have_an_elevator_pitch: "Already Have An Elevator Pitch?",
    generate_your_elevator_pitch_preference: "Generate your “Elevator Pitch” preference",
    define_offering: "What?  a “defined offering”",
    define_offering_placeholder: "All-in-One-Done-For-You Business-in-A-Box Software System",
    target_audience_label: "Who? Target Audience",
    target_audience_placeholder: "Men and women who are 18 & 45",
    whats_a_problem: "What’s a Problem?",
    whats_a_problem_placeholder: "Who are tired of their 9 to 5  jobs and are struggling financially",
    do_what_solution: "Do What? Solution",
    do_what_solution_placeholder: "to launch their Own Online Business in less than 3-Hours and generate up to $15k in income within 90 days ",
    secret_sauce: "How? The Secret Sauce: UPS (Unique Selling Proposition)",
    secret_sauce_placeholder: "What I love about this is that we are the only company that helps you get 1- a business “niche clarity”,  2- provides you with a comprehensive “market research” 3- gives you a tailored “business plan”, so that you can launch a proven to be Successful, Profitable, Scalable, Sustainable, Passive, and Evergreen online business from the start.",
    call_to_action: "CTA (Call to Action)",
    call_to_action_placeholder: " If you’re really serious about financial freedom, this software can fast forward your dreams of quitting your 9 to 5 job, launching your online business in merely 3 hours, and start making up to $15K of passive income within weeks.",
    generated_elevator_pitch_label: "Generated “Elevator Pitch”",
    generated_elevator_pitch: "My company 9 to 5 Escapee is developing an “All-In-One-Done-For-You Business-In-A-Box Software System to help men and women who are 18 and 45 who are tired of their 9 to 5 jobs and who are struggling financially; to launch their own online business within 3-hours and generate up to $15K in income within 90 days. What I love about this is that we are the only company that helps you get 1- a business “niche clarity”,  2- provides you with a comprehensive “market research” 3- gives you a tailored “business plan”, so that you can launch a proven to be Successful, Profitable, Scalable, Sustainable, Passive, and Evergreen online business from the start.  If you’re really serious about financial freedom, this software can fast forward your dreams of quitting your 9 to 5 job, launching your online business in merely 3 hours, and start making up to $15K of passive income within weeks.",
    type_your_own_elevator_pitch: "Type Your Own Elevator Pitch Here",
    type_your_own_elevator_pitch_placeholder: "My company 9 to 5 Escapee is developing an “All-In-One-Done-For-You Business-In-A-Box Software System to help men and women who are 18 and 45 who are tired of their 9 to 5 jobs and who are struggling financially; to launch their own online business within 3-hours and generate up to $15K in income within 90 days. What I love about this is that we are the only company that helps you get 1- a business “niche clarity”,  2- provides you with a comprehensive “market research” 3- gives you a tailored “business plan”, so that you can launch a proven to be Successful, Profitable, Scalable, Sustainable, Passive, and Evergreen online business from the start.  If you’re really serious about financial freedom, this software can fast forward your dreams of quitting your 9 to 5 job, launching your online business in merely 3 hours, and start making up to $15K of passive income within weeks.",
  },
  timeline_form_paypal_account_component: {
    personal_paypal_account: "Personal Paypal Account",
    paypal_personal_registration: "PayPal personal registration",
    paypal_account_user_name: "Your PayPal personal account user profile name",
    paypal_account_user_name_placeholder: "MyPersonalPayPal",
    personal_paypal_email: "Your personal PayPal Email",
    personal_paypal_email_placeholder: "mypersonalpaypal@email.com",
    personal_paypal_page_url: "Your personal PayPal page URL",
    personal_paypal_page_url_placeholder: "https://paypal.com/Mypersonalpaypal",
    paypal_client_id: "Paypal Client ID",
    paypal_client_id_placeholder: "9876552",
    paypal_secret_code: "Paypal Secret Code",
    paypal_secret_code_placeholder: "1234",
    business_paypal_account: "Business PayPal Account",
    business_paypal_registration: "PayPal business registration",
    paypal_business_account_username: "Your PayPal business account user profile name",
    paypal_business_account_username_placeholder: "MyBusinessPayPal",
    business_paypal_email: "Your business PayPal Email",
    business_paypal_email_placeholder: "mybusinesspaypal@email.com",
    business_paypal_page_url: "Your business PayPal page URL",
    business_paypal_page_url_placeholder: "https://paypal.com/Mybusinesspaypal",
  },
  timeline_form_stripe_account_component: {
    your_stripe_account: "Your Stripe Account",
    stripe_account_registration: "Stripe account registration",
    your_stripe_account_email: "Your Stripe account email",
    your_stripe_account_email_placeholder: "mystripe@mail.com",
    your_stripe_backup_code: "Your Stripe Backup code",
    your_stripe_backup_code_placeholder: "ihuj-kolp-redt-bnmv-salq",
    publishable_key: "Publishable Key",
    publishable_key_placeholder: "ihuj-kolp-redt-bnmv-salq",
    stripe_client_id: "Stripe client ID",
    stripe_client_id_placeholder: "9876552",
    stripe_secret_key: "Stripe Secret Key",
    stripe_secret_key_placeholder: "1234"
  },
  timeline_form_amazon_affiliate_program_component: {
    create_your_amazon_affiliate_account: "Create Your Amazon Affiliate Marketing Account",
    amazon_affiliate_registration: "Amazon Affiliate Registration",
    your_amazon_affiliate_account_link: "Your Amazon affiliate account link",
    your_amazon_affiliate_account_link_placeholder: "https://amazon.com/myaffiliate",
    your_amazon_affiliate_short_link: "Your Amazon affiliate short link",
    your_amazon_affiliate_short_link_placeholder: "https://amazon.com/45c+hjska",
    your_amazon_affiliate_long_link: "Your Amazon affiliate long link",
    your_amazon_affiliate_long_link_placeholder: "https://amazon.com/45c+hjska/longAmazonAffiliateLink/65789/affiliate/id=768",
    your_own_earning_disclaimer: "Your Own Earning Disclaimer",
    your_own_earning_disclaimer_placeholder: "Type Your Own Earning Disclaimer here",
    earning_disclaimer_label: "Earning Disclaimer",
  },
  timeline_form_printful_merch_account_component: {
    create_your_printful_merch_account: "Create Your Printful Merch Account",
    printful_registration: "Printful Registration",
    your_printful_merch_account_link: "Your Printful Merch Account link",
    your_printful_merch_account_link_placeholder: "Your Printful Merch Account link",
    ecommercestore_link: "Here is your E-commerce Store Link to use to connect to your Printful Account",
    ecommercestore_link_placeholder: "https://www.dyna.9to5escappee.com/myshop",
  },
  timeline_trustpilot_business_account_component: {
    create_trustpilot_reviews_account: "Create Trustpilot Reviews Business Account",
    trustpilot_reviews_registration: "Truspilot Reviews Registration",
    your_trustpilot_business_account_email: "Your Trustpilot Business Account Email Address",
    your_trustpilot_business_account_email_placeholder: "mytrustpilot@mail.com",
    your_trustpilot_business_account_review_account_link: "Your Trustpilot Busines Review Account link",
    your_trustpilot_business_account_review_account_link_placeholder: "https://trustpilot.com/mybusiness_account",
    create_capterra_reviews_account: "Create Capterra Reviews Business Account",
    capterra_reviews_registration: "Capterra Reviews Registration",
    your_capterra_accont_email: "Your Capterra Business Account Email Address",
    your_capterra_accont_email_placeholder: "myCapterra@mail.com",
    your_capterra_business_account_review_account_link: "Your Capterra Busines Review Account link",
    your_capterra_business_account_review_account_link_placeholder: "https://Capterra.com/mybusiness_account",
  },
  timeline_form_website_design_component: {
    choose_your_peofessional_website_style: "Choose Your Professional Website Style",
    your_website_says_a_lot_about_you: "Your Website says a lot about you, your business, & your brand. So, choose wisely.",
    my_personal_website: "My Professional Website",
    present_your_business: "Present Your Business How You Want People to Feel When Interacting With Your Brand.",
  },
  timeline_form_digital_business_card_component: {
    add_section: "Add Section",
    input_placeholder: "Supported media file formats: jpeg, png, mp3, mp4, webm and pdf",
    customize_footer_credit: "Customize the footer credit?",
    no_thanks: "No Thanks",
    simplify_your_card: "Simplify your visible vcard?",
    display_all_actions: "display all actions and content",
    search_engine_visibilty: "Search engine visibility?",
    encourage_search_engine: "Encourage search engine visibility",
    review_zip: "review zip",
    input1_placeholder: "< div style=”padding:56.25% 0 0 0; position:re....",
    review_link_generator: "3-in-1 Review Link Generator",
    its_free: "It’s Free!",
    web_link: "http://www.review.com",
    customize_the_footer: "Customize the Footer?",
    you_can_feature_your_privacy_policy_below: "You can feature your Privacy Policy Below.",
    footer_text: "Footer Text",
    footer_text_placeholder: "Powered By 9 to 5 Escapee | Privacy Policy",
    link_text: "Link Text",
    your_brand: "Your brand",
    url: "URL",
    url_placeholder: "https://example.com",
    showcase_your_digital_business_card: "Showcase Your Digital Business Card?",
    simplify_your_digital_business_card_view: "Simplify your Digital Business Card View?",
    always_show_add_content_button: "Always show the “Add to Contact” button.",
    lets_search_engine_find_your_digital_business_card: "Let’s Search Engine Find Your Digital Business Card?",
    enable_search_engine_visibility: "Enable search engine visibility",
    seo_title: "SEO Title",
    seo_title_placeholder: "Enter site tittle (50-60 characters)",
    meta_description: "Meta Description",
    meta_description_placeholder: "Enter site description (150-160 characters)",
    button_text: "Button Text",
    button_text_placeholder: "Add button text...",
  },

  timeline_form_eight_component: {
    create_your_electrnic_signature: "Create Your “Electronic Signature",
    set_your_electronic_signature: "SET YOUR ELECTRONIC SIGNATURE",
    only_me: "Only me",
    sign_a_document_yourself: "Sign a document yourself",
    me_and_others: "Me & others",
    send_a_document: "Send a document for others and yourself for sign",
    only_other: "Only others",
    send_a_document_for_others: "Send a document for others for sign",
    enter_your_name: "Enter Your Name",
    email: "Email",
    ip_address: "IP address",
    remember_me_in_singature: "Remember my signature for future signings",
    draw_your_signature: "Draw your email signature here",
    dyna_appolon: "Dyna Appolon",
  },
  timeline_form_four_component: {
    create_your_mission_statement: " Create Your Mission Statement",
    already_have_a_mission_statement: "Already Have a Mission Statement?",
    type_your_current_mission_statement_here: "Type your current mission statement here",
    generated_mission_text: "I help new moms lose their baby weight in 8 weeks so that they can have their body back and enjoy their newborns with confidence",
    help_placeholder: "Help",
    generated_mission_statement: "Generated Mission Statement",
    i_label: "I",
    in_label: "In",
    new_moms: "New moms",
    do_what: "Do what?",
    lose_their_baby_weight: "lose their baby weight",
    who_label: "Who?",
    in_placeholder: "In",
    eight_week: "8 weeks",
    so_that_they_can: "So that they can",
    what_timeframe: "What timeframe?",
    have_their_body_back: "have their body back",
    enjoy_their_newborns_with_confidence: "enjoy their newborns with confidence",
    have_or_do_what: "Have or do what?",
    how_would_that_benefit_them_or_change_their_life: "How would that benefit them or change their life?",

  },
  choose_your_logo_component: {
    diva_boss: "Diva Boss",
    boss: " Boss",
    your_slogan_tagline: "Your Slogan | Tagline",
    boss_up_your_leadership: "Boss up your leadership",
    your_job_title_position: "Your Job Title | Position",
    dyna: "Dyna",
    your_company_name: "Your Company Name",
    enter_your_business_niche: "Enter your business niche",
    women_fashion: "Women Fashion",
    industry: "Industry",
    fashion: "Fashion",
    keywords_adjectives: "3 Keywords | Adjectives that best describe your Service | Expertise or Yourself",
    fashion_women_style: "e.g. Fashion, Women, Style",
    your_name: "Your Name",
    your_slogan: "Your Slogan",
    generated_specially_for_you: "Select one of the generated specially for you",
    upload_your_own_logo: "Upload your own logo",
    create_your_logo: "Create Your Logo",
    your_logos: "Your Logos",
    pick_one: "PICK ONE AND ADJUST TO YOUR NEEDS",
    enter_info: "Enter Info",
    review_logos: "Review logos",
    customize_it: "Customize it",
    animate: "Animate",
    effects: "Effects",
    letter_spacing: "Letter Spacing",
    export_files: "Export files",
    file_type: "File type",
    file_quality: "File Quality",
    download: "DOWNLOAD",
    transparency: "Transparency",
    on: "On",
    off: "Off",
    inter: "Inter",
    choose_font: "Choose Font",
    bebas: "Bebas",
    italian_typewriter: "Italian Typewriter",
    julee: "Julee",
    script_mt_bold: "Script MT Bold",
    inder: "Inder",
    gradient: "Gradient",
    color: "Color",
    custom_color: "custom color",
    seven_figure_bosses: "7 Figure bosses",
    edit_image: "Edit Image",
    gallery: "Gallery",
    object: "Object",
    squares: "Squares",
    show_more: "Show more",
    circles: "Circles ",
    polygon: "Polygon ",
    star: "Star",
    line: "Line",
    text: "Text",
    add_text: "ADD TEXT",
    styles: "STYlES",
    my_fonts: "MY FONTS",
    uploaded_fonts_will_appear_here: " Uploaded fonts will appear here",
    files_should_be_in_ttf_or_otf_format: "Files should be in TTF or OTF Format",
    upload_fonts: "upload fonts",
    upload: "Upload",
    upload_image: "upload image",
    no_uploaded_image: "No uploaded image",
    no_uploaded_svgs: "No uploaded SVGs",
    photos: "Photos",
    layers: "Layers",
    company_name: "Company Name",
    nine_t0_five_escape: "9 to 5 Escape",
    layer_one: "Layer 1",
    rectangle: "Rectangle",
    background: "Background",
    apply_changes: "apply changes",
    brand: "Brand",
    applied_brand: "Applied brand",
    change_which_brand_gets_applied_to_this_design: "Change which brand gets applied to this design",
    my_first_palette: "My first palette",
    heading: "Heading",
    acme: "85, Acme",
    select_a_heading_font: "Select a heading font",
    google_fonts: "Google fonts",
    custom_fonts: "custom fonts",
    select_a_font_from_google_fonts: "Select a font from google fonts",
    subheading: "Subheading",
    fifty_open_sans: "50, Open Sans",
    select_a_sub_heading: "Select a sub heading",
    upload_font: "Upload font",
    body_text: "Body text",
    twentyfive_open_sans: "25, Open Sans",
    select_a_body_font: "Select a body font",
    resize: "Resize",
    custom_size: "Custom Size",
    recently_used: "Recently used",
    fonts: "Fonts",
    accepted_font_file: "Accepted font file: OTF, TTF, Woff, Woff2 (1mb Limit)",
    type_scale_text: "Great font choice! Now let’s make their usage consistent! Select a preferred typescale using the options below. This will help make sure your typography scales well and always look consistent.",
    type_scale: "Typescale",
    base_size: "base_size",
    scale_ratio: "Scale ratio",
    major_third: "1.250 - Major Third",
    preview_text: "Preview text",
    lorem_ipsum: "Lorem ipsum dolor sit amet,",
    Lorem_ipsum_small: "Lorem ipsum ",
    Lorem_ipsum_dolor: "Lorem ipsum dolor",

  },

  privacy_component: {
    general_settings: "General Settings",
    two_factor_authentication_settings: "2-Factor Authentication Settings",
    email_notifications: "Email Notifications",
    privacy_settings: "Privacy Settings",
    timeline_tagging_reviews_settings: "Timeline | Tagging |Reviews Settings",
    restrictions_blocks_settings: "Restrictions & Blocks Settings",
    sessions_logs_location_settings: "Sessions Logs & | Location Settings",
    account_settings: "Account Settings",
    change_passwords: "Change Passwords",
    privacy_general_component: {
      account_web_address: "Account URL (Web Address)",
      privacy_password_mail: "9to5escapee.com",
      privacy_change_account: "When you change your account's URL, we'll redirect from the old one for 30 days.",
      first_day: "First Day Of The Week",
      change_the_first_day: "Change the first day of the week in the all date related places in the system (date picker, timeline, etc..)",
      sunday: "Sunday",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      language: "Language",
      language_eng: "English (United State)",
      date_format: "Date Format",
      bangladesh: "Bangladesh (GMT +6)",
      change_currency: "Change Currency",
      timeline_weekends: "Timeline Weekends",
      weekend_text: "Decide whether or not to present weekends on the timeline. This will set the default presentation mode, and users may change it from each timeline view or widget.",
      show_weekends: "Show Weekends",
      hide_weekends: " Hide Weekends",
      data_residency: "Data residency",
      enable_two_factor_authentication: "Enable Two-Factor Authentication",
      two_factor_authentication_text: "Ensure top-level security for your account with Two-Factor Authentication.",
      two_factor_authentication: "Two-Factor Authentication",
      two_factor_authentication_text_two: "The authentication code will be required only from users that will connect with email and password.",
      support_access: "Support Access",
      authorize_account_access: "Authorize users in this account to grant access to Technical Support Agents to log into this account in order to solve a technical issue or bug they are experiencing.",
      grant_support_access: "Grant Support Access",
      timezone: "Timezone",
      algeria: "Algeria",
      albania: "Albania",
      afghanistan: "Afghanistan",
      page_visibility: "Page Visibility",
      page_published: "Page published",
      posting_ability: "Posting Ability",
      anyone_can_post_to_my_page_timeline: "Anyone can post to my Page timeline",
      anyone_can_add_photos_and_videos_to_my_page_timeline: "Anyone can add photos and videos to my Page timeline",
      post_visibility: "Post Visibility",
      posts_to_page_appear_on_my_page_timeline: "Posts to Page appear on my Page timeline",
      post_targeting_and_privacy: "Post Targeting and Privacy",
      privacy_control_for_posts_is_turned_off: "Privacy control for posts is turned off",
      messages: "Messages",
      turned_off: "Turned off",
      people_can_contact_my_page_privately: "People can contact my Page privately.",
      tagging_ability: "Tagging Ability",
      allow_others_to_tag_photos_posted_by_dyna: "Allow others to tag photos posted by Dyna",
      country_restrictions: "Country Restrictions",
      page_is_visible_to_everyone: "Page is visible to everyone",
      age_restrictions: "Age Restrictions",
      page_is_shown_to_everyone: "Page is shown to everyone.",
      select_your_age_range: "Select Your Age Range",
      profanity_filter: "Profanity Filter",
      filter_hide_words: "Filter | Hide Words",
      similar_page_suggestions: "Similar Page Suggestions",
      choose_whether_your_page_is_recommended_to_others: "Choose whether your Page is recommended to others",
      replies: "Replies",
      comment_replies_are_turned_on_for_your_Page: "Comment replies are turned on for your Page",
      cancel: "Cancel",
      save_change: "Save Change",
      change_language: "Change Language",
      country: "country",

    },
    factor_authentication_component: {
      your_authentication_heading: "SET UP YOUR 2-FACTOR AUTHENTICATIONS",
      confirm_current_password: "Confirm current password",
      privacy_security: "We take your security and privacy very seriously. Please set up your 3 security questions now with answers to verify you next time you need to make a change in your account.",
      security_question_one: "Security Question 1",
      security_question_two: "Security Question 2",
      security_question_three: "Security Question 3",
      country: "country",
      toronto: "Toronto",
      recovery_email: "Recovery Email",
      recovery_phone_no: "Recovery Phone No.",
      confirm_your_2_factor_authentications: "Confirm your 2-factor authentications",
      verification_via: "Verification Via",
      email: "Email",
      sms: "SMS",
      fingerprint: "Fingerprint",
      face_id: "Face ID",
    },
    email_notifications_component: {
      email_notification_settings: "Email Notification Settings",
      comments: "Comments",
      comments_post: "You'll be notified when someone comments on your posts and/or",
      comments_replies: "replies to your comments.",
      tags: "Tags",
      tag_post: "These are notifications for when someone tags you in a post of image.",
      friends_requests: "Friends requests",
      friends_requests_post: "You'll be notified when someone send you a friend request.",
      groups: "Groups",
      groups_post: "These are notifications for activity on groups you created or joined.",
      forums: "Forums",
      forums_post: "These are notifications for activity on forums you created or joined.",
      events: "Events",
      events_post: " You'll be notified about events you created or added to your calendar.",
      marketplace: "Marketplace",
      marketplace_post: "These are notifications for marketplace items you posted.",
      notifications_sound: "Notifications Sound",
      notifications_sound_post: "A sound will be played each time you receive a new activity notification.",
      notifications_email: "Notifications Email",
      notifications_email_post: " We'll send you an email to your account each time you receive",
      notifications_email_replies: " a new activity notification.",
      friends_birthdays: "Friend’s Birthdays",
      friends_birthdays_post: "Choose whether or not receive notifications about your friend's ",
      friends_birthdays_replies: "birthdays on your newsfeed.",
      chat_message_sound: "Chat Message Sound",
      chat_message_sound_post: " A sound will be played each time you receive a new message on",
      chat_message_sound_replies: "  an inactive chat window.",
      email_me_when: "Email me when",
      email_me_text: " Someone asks me to be a collaborator on one of their Activities | Events",
      someone_mention_me: " Someone mentions me",
      i_receive_invitations_to_invite_new_friends: "I receive invitations to invite new Friends | Players",
      my_invitations_are_about_to_expire: "My invitations are about to expire",
      someone_accepts_my_invitation: "Someone accepts my invitation",
      anyone_follows_me: "Anyone follows me",
      a_player_follows_me: "A player follows me",
      subscribe_us_to: "Subscribe us to:",
      education: "Education",
      monday: "Monday: Design workshops and education resources for your career (formerly Overtime)",
      opportunities: "Opportunities",
      tuesday: "Tuesday: New career and freelance project opportunities (formerly Job Digest)",
      figure_bosses_insider: "Figure Bosses Insider",
      wednesday: "Wednesday: Latest design news & community highlights (formerly Courtside)",
      inspiration_for_you: "Inspiration For You",
      friday: "Friday: Can't miss shots and inspiration from the community (formerly New For You)",
    },
    restrictions_component: {
      restrictions_blocks_settings: "Restrictions & Blocks Settings",
      restricted_list: "Restricted list",
      restricted_list_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",

      restrict_user: "Restrict user",
      restrict: "Restrict",
      your_restrict_list: "Your restrict list",
      restricteduser_one: "restricteduser1",
      remove_restriction: "Remove Restriction",
      restricteduser_two: "restricteduser2",
      restricteduser_three: "restricteduser3",
      block_users: "Block users",
      block: "Block",
      your_block_list: "Your block list",
      blockeduser_one: "blockeduser1",
      unblock: "Unblock",
      blockeduser_two: "blockeduser2",
      blockeduser_three: "blockeduser3",
      placeholder_email: "Enter Person Email Address Here",
      placeholder_email_block: "Enter Email/Username you want to block",
      placeholder_email_restrict: "Enter Email/Username you want to restrict"
    },
    timeline_component: {
      timeline_and_tagging_settings: "Timeline and Tagging Settings",
      timeline: "Timeline",
      who_can_post_on_my_timeline: "Who can post on my timeline?",
      placeholder_email: "Enter Person Email Address Here",
      timeline_post: "Who can see what others post on my timeline?",
      stories_post: "Allow others to share my posts to their stories?",
      timeline_commwent: "Hide comments containing certain words from my timeline.",
      tagging: "Tagging",
      timeline_tagging: "Who can see my posts I'm tagged in on my timeline?",
      timeline_add_post: "When I'm tagged in a post, who do i want to add to the audience of the post if they can’t already see it?",
      timeline_tagged_appear: "Review posts you’re tagged in before the post appears on your timeline?",
      timeline_post_review: "Review posts you are tagged in",
      timeline_email_tag: "@userexmaple Tagged you in a post",
      timeline_text: "Lorem ipsum dolor sit amet cons ectetur adipiscing elit.",
      review: "Review",
      filter_hide_words: "Filter | Hide Words",

    },
    privacy_tab_component: {
      privacy_settings: "Privacy Settings",
      who_can_see_my_profile: "Who can see my profile?",
      who_can_send_me_a_friend_request: "Who can send me a friend request",
      type_here: "type here",
      who_can_see_my_chat_activity: "Who can see my chat activity?",
      who_can_see_my_friends_list: "Who can see my friends list?",
      who_can_see_my_photos: "Who can see my photos?",
      who_can_see_my_videos: "Who can see my videos?",
      who_can_see_my_future_posts: "Who can see my future posts?",
      who_can_look_me_up_using_my_email: "Who can look me up using my email?",
      who_can_look_me_up_using_my_phone_number: "Who can look me up using my phone number?",
      suggest_more_privacy_options_to_us: "Suggest more privacy options to us",
      who_can_see_my: "Who can see my",


      placeholder_email: "Enter Person Email Address Here",
    }
  },


  profile_section: {
    enter_your_profile_details: "Enter Your Profile Details",
    live: "LIVE",
    in_meeting_now: "IN MEETING NOW",
    coaching_now: "COACHING NOW",
    on_break: "ON BREAK",
    out_sick: "OUT SICK",
    family_time: "FAMILY TIME",
    self_care: "SELF-CARE",
    meditating: "MEDITATING",
    working_from_home: "WORKING FROM HOME",
    out_of_the_office: "OUT OF THE OFFICE",
    working_outside: "WORKING OUTSIDE",
    in_the_office: "IN THE OFFICE",
    working_in_bed: "WORKING IN BED",
    do_not_disturb: "DO NOT DISTURB",
    choose_your_profile_picture_banner: "Choose Your Profile Picture & banner",
    download: "Download",
    lets_desgin_your_profile_banner: "LET’S DESIGN YOUR PROFILE BANNER",
    enter_season_or_series: "Enter Season or Series",
    enter_episode: "ENTER EPISODE",
    upload_video: "Upload Video",
    live_preview: "Live Preview",
    podcast_audio: "Podcast|Audio",
    hire_me: "Hire Me",
    business_title: "Business Title",
    preferred_profile_name_business_name: "Preferred Profile name/Business name",
    availability_status: "Availability status",
    change_profile_image: "Change Profile image",
    Pixels_200_x_200_Pixels: "200 X 200 Pixels",
    change_banner_image: "Change banner image",
    pixels_1000_X_400_pixels: "1000 X 400 Pixels",
    change_business_logo: "Change Business Logo",
    pixels_600_X_600_pixels: "600 X 600 Pixels",
    change_favicon: "Change Favicon",
    url_Icon_logo: "Url Icon | logo",
    pixels_16_X_16_pixels: "16 X 16 Pixels",
    upload_photos: "upload photos",
    pixels_1024_X_1024_pixels: "1024 X 1024 Pixels",
    title: "Title",
    and: "and",
    name: "Name",
    upload_country_flag: "Upload country flag",
    upload_rank: "Upload Rank",
    profile_picture_icon: "profile picture icon",
    booking_page_url_here: "Booking page URL here",
    message_me: "Message me",
    email_address: "Email address",
    emai_me: "Email me",
    upload_custom_social_media_icons: "Upload custom social media icons",
    facebook: "Facebook",
    twitter: "Twitter",
    instagram: "Instagram",
    linked_in: "Linked In",
    twitch: "Twitch",
    tiktok: "Tiktok",
    youtube: "Youtube",
    pinterest: "Pinterest",
    snapchat: "Snapchat",
    discord: "Discord",
    earn_your_verified_member_sticker_now: "Earn your verified member sticker now",
    picture_id_here_to_verify_your_account: "Upload Your Picture ID here to verify Your Account & Have a Beautiful Verify Check Next to Your Profile Picture",
    drag_drop_or_upload: "Drag & drop or upload",
    image_here: "image here",
    upload_or_Scan_Your_id_now: "Upload or Scan Your ID Now",
    bio: "Bio",
    press_kit: "Press Kit",
    stats: "Stats",
    reviews: "reviews",
    portfolio: "Portfolio",
    blogs: "Blogs",
    shop: "Shop",
    website: "Website",
    Q_A: "Q&A",
    profile_slide_show_upper_menu: "PROFILE SLIDE SHOW UPPER MENU",
    upload: "Upload",
    menu_icon: "menu icon",
    profile_static_lower_menu: "PROFILE STATIC LOWER  MENU",
    your_basic_business_info: "Your Basic | Business Info",
    first_name: "First Name",
    last_name: "Last Name",
    she_her: "She/Her",
    preferred_profile: "Preferred Profile Name/Business Name",
    business_industry: "Business Industry",
    business_niche: "Business Niche",
    contact_whatsApp_number: "Contact/WhatsApp Number",
    public_email: "Public Email",
    business_email: "Business Email",
    business_company_name: "Business | Company Name",
    business_headquarter_location: "Business Headquarter Location",
    joined_us_since: "Joined Us Since",
    business_mission_statement: "Business Mission Statement",
    tagline_slogan: "Tagline/Slogan",
    brand_position_statement: "Brand Position Statement",
    who_am_i: "Who am I",
    what_i_do: "What I do?",
    who_is_my_service_for: "Who is my service for?",
    why_work_with_me: "Why work with me?",
    user_name: "Username",
    enter_your_password: "Enter Your Password",
    your_password_must_meet_these_requirements: "Your password must meet these requirements:",
    one_number: " One number",
    one_special: "One special",
    character: "character",
    one_special_character: "One special character",
    _8_characters: "8 characters",
    minimum: "minimum",
    preferred_ways_of_communication: "Preferred ways of Communication:",
    phone: "Phone",
    text_messaging: "Text Messaging",
    email: "Email",
    live_meet: "Live Meet",
    social_media: "Social Media",
    in_person: " In Person",
    other: "Other",
    preferred_days_of_communication: "Preferred Days of Communication:",
    all: "All",
    mondays: "Mondays",
    tuesdays: "Tuesdays",
    wednesdays: " Wednesdays",
    thursdays: "Thursdays",
    fridays: "Fridays",
    saturdays: "Saturdays",
    sundays: "Sundays",
    preferred_time_of_communication: "Preferred Time of Communication:",
    your_education_level_status_skills_hobbies_languages: "Your Education Level & Status | Skills | Hobbies | Languages",
    your_highest_level_of_education: "Your Highest Level of Education",
    last_institution_university_name: "Last Institution/University Name",
    major_specialization: "Major/Specialization",
    year_started: "Year Started",
    i_am_an_alumni: "I am an Alumni",
    i_am_currently: "I am currently",
    a_full_time_student: "a Full-Time Student",
    a_part_time_student: " a Part-Time Student",
    reconsidering_my_options: "Reconsidering My Options",
    dropped_out: " Dropped-Out",
    list_5_special_skills: "List 5 Special Skills",
    competency_level_1_10: "Competency Level 1-10",
    list_5_hobbies: "List 5 Hobbies",
    first_language: "First Language",
    proficiency: "Proficiency:",
    second_language: "Second Language",
    third_language: "Third Language",
    fourth_language: "Fourth Language",
    your_personal_information: "Your Personal Information",
    select_your_age_range: "Select Your Age Range",
    what_is_your_gender: "What is Your Gender?",
    male: "Male",
    female: "Female",
    non_binary: "Non-Binary",
    non_conforming: "Non-Conforming",
    birthday: "Birthday ( Month & Day Only)",
    date_of_birth: "Date of Birth",
    birth_place: "Birth Place",
    marital_status: "Marital Status",
    nationality_1: "Nationality 1",
    nationality_2: "Nationality 2",
    do_you_have_any_pets: "Do You Have Any Pets?",
    pets_type: "Pet’s Type",
    no_of_pets: "# of Pets",
    pets_name: "Pet’s Name",
    dog: "Dog",
    lucifer: "Lucifer",
    add_image: "Add image",
    done: "done",
    add_more_pets: "Add More Pets",
    number_of_pets: "Number of Pets",
    change_cover: "Change cover",
    number_of_people_in_household: "Number of People in Household",
    do_you_have_any_children: "Do You Have Any Children?",
    yes: "Yes",
    no: "NO",
    number_of_children: "Number of Children",
    children: "Children",
    children_name: "Children Naame",
    age: "Age",
    boy: "Boy",
    james: "James",
    identify_your_zodiac_sign: "IDENTIFY YOUR ZODIAC SIGN",
    identify_your_persinality: "IDENTIFY YOUR PERSONALITY",
    What_your_zodiac_sign: "What’s Your Zodiac sign?",
    personality: "PERSONALITY",
    core_values: "Core Values:",
    value_1: "Value 1",
    value_2: "Value 2",
    value_3: "Value 3",
    value_4: "Value 4",
    personal_values: "Personal Values",
    your_employment_information: "Your Employment Information",
    are_you_currently_employed: "Are You Currently Employed?",
    yes_i_am: "Yes I am",
    year_ended: "Year Ended",
    i_still_work_there: "I still work there",
    part_time: "Part-Time",
    seasonal: "Seasonal",
    freelance: "Freelance",
    terminated: "Terminated",
    i_am_a_9_to_5_escapee: "I am a 9 to 5 Escapee",
    i_am_a_7_figure_boss: "I am a 7-Figure Boss",
    i_am_a_6_figure_boss: "I am a 6-Figure Boss",
    i_am_a_socialite_boss: "I am a Socialite Boss",
    i_am_a_diva_boss: "I am a Diva-Boss",
    name_of_employer_company_name: "Name of Employer | Company Name",
    current_job_title: "Current Job Title",
    describe_your_typical_day_at_work: "Describe your typical day at work",
    text_formating: "Text formating:",
    select_your_currency: "Select Your Currency",
    please_convert_your_income_in_USD_to_choose_the_equivalent_income: "Please convert your income in USD to choose the equivalent income.",
    years_of_experience: "Years of Experience",
    yearly_income_range: "Yearly Income Range",
    lets_play_favourites: "Lets play favourites",
    favorite_color: "Favorite Color (s) (Seperate using comma)",
    baby_pink: "Baby Pink",
    silver: "Silver",
    gold: "Gold",
    black: "Black",
    nude: "Nude",
    favourite_song: "Favourite Song (s)",
    seperate_using_comma: "(Seperate using comma)",
    upload_image_here: "Upload Image here",
    music_music_genre: "Music Music Genre (s)",
    favourite_singer: "Favourite Singer (s)",
    favourite_music_band: "Favourite Music Band (s)",
    favorite_tv_show: "Favorite TV Show (s)",
    favorite_movie: "Favorite Movie (s)",
    favourite_actor: "Favourite Actor (s)",
    favorite_game: "Favorite Game (s)",
    favourite_anime_character: "Favourite Anime Character (s)",
    favourite_book: "Favourite Book",
    sport: "Sport",
    favourite_writer_author: "Favourite Writer/Author (s)",
    favourite_podcast: "Favourite Podcast (s)",
    favourite_quote_1: "Favourite Quote (1)",
    favourite_quote_2: "Favourite Quote (2)",
    favourite_quote_3: "Favourite Quote (3)",
    favourite_animal: "Favourite Animal (s)",
    favourite_brand_1: "Favourite Brand 1",
    favourite_brand_2: "Favourite Brand 2",
    favourite_brand_3: "Favourite Brand 3",
    brand_affinity_loyalty: "( Brand Affinity/Loyalty)",
    upload_logo_here: "Upload Logo here",
    confirm_current_password: "Confirm current password",
    we_take_your_security: "We take your security and privacy very seriously. Please set up your3 security questions now with answers to verify you next time you need to make a change in your account.",
    security_question_1: "Security Question 1",
    security_question_2: "Security Question 2",
    security_question_3: "Security Question 3",
    select_a_method_to_change_your_password: "Select a method to change your Password",
    sms: "SMS",
    fingerprint: "Fingerprint",
    face_ID: "face_ID",
    verification_via: "Verification Via",
    password_generator: "Password generator",
    your_new_secured_business_email_password: "YOUR NEW SECURED BUSINESS EMAIL PASSWORD' IS:",
    your_password_can_be_cracked_in_31_years: "Your password can be cracked in 31 Years",
    password_length: "Password length:",
    uppercase_character: "Uppercase character",
    special_character: "Special character",
    lowercase_character: "Lowercase character",
    _8_characters_minimum: "8 characters minimum",
    number: "Number",
    accept_password: "Accept Password",
    generate_a_new_one: "Generate a new one",
    enter_your_own_password_here: "ENTER YOUR OWN PASSWORD HERE",
    one_uppercase_character: "One uppercase character",
    one_lowercase_character: "One lowercase character",
    your_education_status: "YOUR EDUCATION STATUS",
    social_media_business_account_profile: "SOCIAL MEDIA BUSINESS ACCOUNT PROFILE",
    billing: "BILLING",
    shipping: "SHIPPING",
    business_office: "BUSINESS OFFICE |",
    headquater: "HEADQUARTER",
    home: "HOME",
    address: "ADDRESS",
    your_accomplishments: "YOUR ACCOMPLISHMENTS",
    edit_profile_intro: "Edit Profile Intro",
    here_you_have_100_Control: 'Here you have 100% Control of what you want to "SHOW" OR to "HIDE" on your',
    profile: "Profile.",
    education: "EDUCATION",
    institution_university: "Institution/University",
    mba_mrketing_strategy: "MBA | Marketing Strategy",
    im_an_alumni: "I'm an Alumni",
    graduated_in: "Graduated in",
    education_level: "Education level",
    experience: "EXPERIENCE",
    ceo_social_network_strategist: "CEO | Social Network Strategist",
    _7_figure_bosses: "7 FIGURE BOSSES",
    full_time: " Full-Time",
    areas_of_expertise_passion: "AREAS OF EXPERTISE & PASSION",
    social_network_strategist: "Social Network Strategist",
    fashion_stylist_coach: "Fashion Stylist/Coach",
    venture_start_up_guru: "Venture Start-Up Guru",
    _7_figure_lifestyle_brand_consultant: "7 Figure Lifestyle Brand Consultant",
    _7_figure_self_made_coach: "7 Figure Self-Made Coach",
    favourite_music: "FAVOURITE MUSIC",
    jazz: "Jazz",
    blues: "Blues",
    soft_rock: "Soft-Rock",
    blue_eyed_soul: "Blue-Eyed Soul",
    celine_dion: "Celine Dion",
    michael_bolton: "Michael Bolton",
    garou: "Garou",
    episode_5_season_1: "EPISODE 5 | SEASON 1",
    french_philosophy_series: "French Philosophy Series",
    charles_robin: "Charles Robin",
    social: "Social",
    chat: "Chat",
    _1st_rank: "1st Rank",
    dyna_appolon: "DYNA APPOLON",
    social_score: "SOCIAL SCORE",
    language_skills: "Language skills",
    french: "French",
    english: "English",
    spanish: "Spanish",
    madarin: "Madarin",
    hobby_ies: "HOBBY (IES)",
    fashion_venture: "Fashion & Venture",
    stock_trading: " Stock Trading",
    boat_ride: "Boat Ride",
    motorcycle_ride: "Motorcycle Ride",
    equestrian: "Equestrian",
    helipcopter_ride: "Helipcopter Ride",
    guitar_violin: "Guitar & Violin",
    latin_dance: "Latin Dance",
    chess: "Chess",
    home_decor: "Home Decor",
    favourite_movie: "FAVOURITE MOVIE (S)",
    my_cousin_vinny: "My cousin Vinny",
    two_can_play_that_game: "Two can play that game",
    the_bodyguard: "The Bodyguard",
    gabrielle_union: "Gabrielle Union",
    kevin_costner: "Kevin Costner",
    favourite_color: "FAVOURITE COLOR (S)",
    favourite_Book: "FAVOURITE BOOK (S)",
    favourite_writter_AUTHOR: "FAVOURITE WRITER (S) | AUTHOR (S)",
    timothy_ferris: "Timothy Ferris",
    favourite_inspirational_speaker: "Favourite Inspirational Speaker (S)",
    eric_thomas: "Eric Thomas",
    core_value: "CORE VALUES (S)",
    authenticity_integrity_honesty_loyalty: "Authenticity | Integrity | Honesty | Loyalty",
    family_privacy: "Family | Privacy",
    spirituality: "Spirituality",
    substainability_social_responsibilities: "Substainability | Social Responsibilities",
    personal_value: "PERSONAL VALUE (S)",
    self_respect_self_Love: "Self-Love",
    mental_health: "Mental Health",
    healthy_lifestyle: "Healthy Lifestyle",
    work_life_balance: "Work | Life Balance",
    anime_character: "ANIME CHARACTER (S)",
    gon: "GON",
    narato: "Narato",
    luffy: "Luffy",
    favourite_game: "FAVOURITE GAME (S)",
    valorant: "VALORANT",
    clients_feedback: "Client’s Feedback",
    client_text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    business_info: "BUSINESS INFO",
    dyna_9_to5escapee_com: "dyna@9to5escapee.com",
    _554_980_5678: "554-980-5678",
    headquater_1: "HEADQUARTER (1)",
    monaco_france: "Monaco | France",
    headquater_2: "HEADQUARTER (2)",
    george_town_cayman_islands: "George Town, Cayman Islands.",
    interest: "INTEREST (S)",
    philosophy: "Philosophy",
    substainability: "Substainability",
    spaceship: "Spaceship",
    brands_affinity: "Brands Affinity",
    chanel: "Chanel",
    tesla: "Tesla",
    next_travel_destination: "NEXT TRAVEL DESTINATION (S)",
    jemeriah_beach: "Jemeriah Beach",
    dubai_uae: "Dubai, UAE",
    garden_of_gethsemane: "Garden of Gethsemane",
    jerusalem: "Jerusalem",
    lencois_maranhenses: "Lencois Maranhenses",
    brazil: "Brazil",
    describe_yourself_in_240_characters: "Describe Yourself in 240 characters",
    hi_there_i_am: "Hi there, I'm",
    in_my: "In my",
    of_years_working_in: "of years working in",
    i_have_become_an_expert_in: "I've become an expert in",
    my_proudest_accomplishments_are: "My proudest accomplishments are:",
    skills_qualifications: "Skills & qualifications:",
    dont_take_my_word_for_it: "Don't take my word for it:",
    preview_of_the_generated_bio: "Preview of the generated bio",
    Name: "name",
    job_title_company: "Job Title Company",
    brand_mission_tagline: "Brand Mission | Tagline",
    describe_notable_accomplishments: "Describe Notable Accomplishments",
    target_keyword: "Target Keyword",
    branded_hashtags: "Branded Hashtags (Separate using commas)",
    contact_info: "Contact info",
    website_url: "Website URL",
    link: "Link",
    shop_link_or_product_link: "Shop Link or Product Link",
    partnership_sponsorship: "Partnership | Sponsorship",
    special_offer_link: "Special Offer Link",
    Location: "location",
    help_people_quit_their: "Help people quit their 9 to 5 job & launch their business in 3-hours",
    helped_30000_quit_their_jobs_last_year: "Helped 3,000 quit their Jobs Last Year!",
    quit_The_rat_race_how_to_quit_your_9_to_5_job_escape_the_9_5: "Quit The Rat Race | How to Quit Your 9 to 5 Job | Escape the 9-5",
    _9to5job_quitthe9to5job_quittheratrace_escapethe9_5: "#9to5job | #quitthe9to5job | #quittheratrace | #escapethe9-5",
    whatsApp_number: "WhatsApp Number",
    www_9to5escapee_com: "www.9to5escapee.com",
    help_9to5escapee_com: "help@9to5escapee.com",
    _30_minutes_free_consultation_to_learn_more: "30-Minutes Free Consultation to Learn more",
    check_Out_my_service_packages: "Check Out My Service Packages",
    lets_partner_up: "Let’s Partner Up!",
    copy_bio: "Copy bio",
    create_your_unique_social_media_username_handle: "CREATE YOUR UNIQUE SOCIAL MEDIA USERNAME | HANDLE",
    choose_your_facebook_business_profile_banner_design: "Choose Your Facebook Business Profile | Banner Design",
    _1980_X_1005_pixels: "1980 X 1005 PIXELS",
    facebook_business_account: "Facebook Business Account",
    registration_link: "Registration Link",
    facebook_business_id: "Facebook Business ID",
    facebook_pixel_id: "Facebook Pixel ID",
    pixel_access_link: "Pixel Access Link",
    bio_link: "Bio Link",
    qr_code: "QR Code",
    generate_qr_code_now: "Generate QR Code Now",
    choose_your_twitter_business_profile_banner_design: "Choose Your Twitter  Business Profile | Banner Design ",
    _1500_X_500_pixels: "1500 X 500 PIXELS",
    twitter_business_account: "Twitter Business Account",
    twitter_business_account_username: "Twitter Business Account Username",
    choose_your_youtube_business_channel_banner_design: "Choose Your Youtube Business Channel | Banner Design",
    _2048_X_1152_pIixels: "2048 X 1152 PIXELS",
    youtube_business_account: "Youtube Business Account",
    youtube_business_account_url: "Youtube Business Account URL",
    instagram_business_account: "Instagram Business Account",
    instagram_business_account_username: "Instagram Business Account Username",
    choose_your_linkedin_business_profile_banner_design: "Choose Your Linkedin Business Profile | Banner Design",
    _2256_X_382_pixels: "2256 X 382 PIXELS",
    linkedIn_business_account: "LinkedIn Business Account",
    linkedIn_business_username: "LinkedIn Business Username",
    choose_your_pinterest_business_profile_banner_design: "Choose Your Pinterest Business Profile | Banner Design",
    _800_X_450_pixels: "800 X 450 PIXELS",
    pinterest_business_account: "Pinterest Business Account",
    pinterest_business_username: "Pinterest Business Username",
    choose_your_twitch_business_profile_banner_design: "Choose Your Twitch Business Profile | Banner Design ",
    twitch_business_account: "Twitch Business Account",
    twitch_business_account_username: "Twitch Business Account Username",
    tikTok_business_account: "TikTok Business Account",
    tikTok_business_account_username: "TikTok Business Account Username",
    choose_your_telegram_business_profile_banner_design: "Choose Your Telegram Business Profile | Banner Design",
    _512_X_512_pixels: "512 X 512 PIXELS",
    telegram_business_account: "Telegram Business Account",
    telegram_business_username: "Telegram Business Username",
    choose_your_reddit_business_profile_banner_design: "Choose Your Reddit Business Profile | Banner Design",
    _1920_X_384_pixels: "1920 X 384 PIXELS",
    reddit_business_account: "Reddit Business Account",
    reddit_business_username: "Reddit Business Username",
    choose_your_medium_business_profile_banner_design: "Choose Your Medium Business Profile | Banner Design",
    _1500_X_750_pixels: "1500 X 750 PIXELS",
    medium_business_account: "Medium Business Account",
    medium_business_username: "Medium Business Username",
    dribble_business_account: "Dribble Business Account",
    dribble_business_username: "Dribble Business Username",
    choose_your_patreon_business_profile_banner_design: "Choose Your Patreon  Business Profile | Banner Design ",
    _1600_X_400_pixels: "1600 X 400 PIXELS",
    patreon_business_account: "Patreon Business Account",
    patreon_business_account_username: "Patreon Business Account Username",
    choose_your_discord_business_profile_banner_design: "Choose Your Discord Business Profile | Banner Design ",
    _960_X_540_pixels: "960 X 540 PIXELS",
    discord_business_account: "Discord Business Account",
    discord_business_account_username: "Discord Business Account Username",
    choose_your_behance_business_profile_banner_design: "Choose Your Behance  Business Profile | Banner Design",
    _3200_X_410_pixels: "3200 X 410 PIXELS",
    behance_business_account: "Behance Business Account",
    behance_business_account_username: "Behance Business Account Username",
    awards_contests: "Awards/Contests",
    points: "Points",
    badges: "Badges",
    quests: "Quests",
    influencer_score: "Influencer Score",
    csat: "CSAT (Customer Satisfaction Score)",
    nps: "NPS (Net Promoter Score)",
    rank: "Rank",
    leaderboard_rank_status: "Leaderboard Rank Status",
    month: "Month",
    billing_address: "BILLING ADDRESS",
    same_as: "Same As",
    shipping_address: "SHIPPING ADDRESS",
    business_office_headquarter_address: "BUSINESS OFFICE | HEADQUARTER ADDRESS",
    home_address: "HOME ADDRESS",
    suite_appt_unit_number: "Suite/Appt Unit Number",
    street_house_number: "Street/House Number",
    street_name: "Street/Name",
    city_name: "City Name",
    country: "Country",
    states: "States",
    zip_code: "Zip Code",
    province: "Province",
    postal_code: "Postal Code",
    change_password: "CHANGE PASSWORD",
    generate_a_secured_Password: "GENERATE A SECURED PASSWORD",
    tour_memories: "TOUR MEMORIES",
    _10TH_birthday: "10TH BIRTHDAY",
    hey_i_am_dyna: "HEY! I'M DYNA",
    dyna_text: "I am an EXPRESSIVE FASHION ADDICT & SELF-IMAGE CONSULTANT who thrives on creating SENSORY experiences in turning your SELF-IMAGE into an INSTANT BRAND. My WARM and SOCIAL interaction will be a confidence builder for you to explore an INDIVIDUALISTIC VOGUE STYLE THEME APPROACH for MAXIMUM IMPACT. My unfailingly Passionate slant on Individuality is the hallmark of the Diva Boss brand. And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.",
    lip_liner: "LIP LINER",
    fashion_week: "FASHION WEEK",
    Nationality_1: "Nationality (1)",
    Nationality_2: "Nationality (2)",
    joined: "JOINED",
    aug_2022: "AUG, 2022",
    niche: "Niche",
    social_status: "SOCIAL STATUS",
    family: "family",
    girl: "girl",
    cat: "cat",
    just_like_coco_chanel_i_am_my_own_brand: "JUST LIKE COCO CHANEL, I AM MY OWN BRAND.",
    your_tagline_here: "Your tagline here",
    your_business_title: "Your business title",
    dyna: "Dyna",
    fashion: "Fashion",
    www_website_com: "www.website.com",
    your_bio_here: "Your bio here",
    dyna_9t05escapee_com: "dyna@9t05escapee.com",
    business_name_here: "Business name here",
    mission_statement: "Mission statement",
    position_statement: "Position Statement",
    dynaappolon: "dynaappolon",
    genertae_a_new_PAssword: "genertae a new PAssword",
    _123456: "123456",
    linkedin_email_live_meet: "Linkedin, Email, Live Meet",
    monday_thursday: "Monday, Thursday",
    _10_00_11_00_am_4_00_8_00_pm: "10:00-11:00 am, 4:00-8:00 pm",
    smith_school_of_business: "Smith School of Business",
    mba_marketing_strategist: "MBA | Marketing Strategist",
    playing_the_guitar_violin: "Playing the Guitar & Violin",
    horse_backriding: "Horse Backriding",
    playing_golf_tennis: "Playing Golf & Tennis",
    home_decor_organizing: "Home Decor & Organizing",
    reading_philosophy_books: "Reading Philosophy Books",
    add_more: "Add More",
    baby_pink_silver_gold_black_nude: "Baby Pink, Silver, Gold, Black, Nude",
    seduces_me_celine_dion: "Seduces Me | Celine Dion",
    enter_music_link_here: "Enter Music link Here",
    jazz_blues_soft_rock_blue_eyed_soul: "Jazz, Blues, Soft-Rock, Blue-Eyed Soul",
    taylor_swift: "Taylor Swift",
    one_direction: "One direction",
    my_cousin_vinny_two_can_play_that_games: "My cousin Vinny | Two can play that games",
    leonardo_diCaprio: "Leonardo DiCaprio",
    call_of_duty: "Call of duty",
    light_yagami: "Light Yagami",
    The_4_hour_work_week_timothy_ferris: "The 4-Hour Work Week | Timothy Ferris",
    enter_book_link_here: "Enter book link here",
    tennis_golf: "Tennis & Golf",
    le_precepteur: "Le Precepteur",
    by_charles_robin: "By | Charles Robin",
    enter_podcast_link_here: "Enter Podcast Link Here",
    you_are_your_own_brand_coco_chanel: '"You are your own Brand" Coco Chanel',
    writer_name: '"Writer name',
    dog_lover: "Dog Lover",
    my_self: "Myself",
    enter_current_password: "Enter current password",
    toronto: "Toronto",
    z_zwGqfcxO: "z?zwGqfcxO",
    enter_Password: "Enter Password",
    dd_mm: "DD/MM",
    dd_mm_yy: "DD/MM/YY",
    canada: "Canada",
    single: "Single",
    american: "American",
    canadian: "Canadian",
    cat: "Cat",
    _1: "1",
    _meow: "Meow",
    jesica: "Jesica",
    _08_26_2014: "08/26/2014",
    authenticity: "Authenticity",
    sustainability: "Sustainability",
    responsibility: "Responsibility",
    integrity: "Integrity",
    enter_podcast_title: "Enter Podcast title",
    enter_video_title_here: "Enter video title here",
    paste_link_here: "Paste link here",
    enter_a_url_or_upload: "Enter a URL or upload",
    paste_contact_page_url_here: "Paste Contact Page URL Here",
    type_preferred_email_address_here: "Type Preferred Email Address Here",
    change_menu_title_here: "Change menu title here",
    paste_page_url_here: "Paste Page URL here...",
    current_job_title_company: "Current job title Company",
    one_liner_with_whats_most_likely_to_interest_my_profile_viewers: "one-liner with what's most likely to interest my profile viewers",
    industry_role: "Industry | role",
    area_1: "Area 1",
    area_2: "Area 2",
    area_3: "area_3",
    example_1: "Example 1",
    example_2: "Example 2",
    example_3: "Example 3",
    skills_1: "Skills 1",
    skills_2: "Skills 2",
    skills_3: "Skills 3",
    social_proof_link_or_testimonial_page_link: "Social proof link or Testimonial page link",
    placeholder: "Placeholder",
    appolon: "Appolon",
    my_day_starts_with: "My day starts with...",
    enter_username: "Enter username",
    enter_facebook_pixel_id_here: "Enter Facebook Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_facebook_profile_here: "Enter Bio link you want to add on your Facebook profile here",
    enter_qr_code_title_label_here: "Enter QR Code Title | Label here",
    twitter_pixel_id: "Twitter Pixel ID",
    enter_twitter_pixel_id_here: "Enter Twitter Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_twitter_profile_here: "Enter Bio link you want to add on your Twitter profile here",
    youtube_pixel_id: "Youtube Pixel ID",
    enter_youtube_pixel_id_here: "Enter Youtube Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_youtube_profile_here: "Enter Bio link you want to add on your Youtube profile here",
    instagram_pixel_id: "Instagram Pixel ID",
    enter_instagram_pixel_id_here: "Enter Instagram Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_instagram_profile_here: "Enter Bio link you want to add on your Instagram profile here",
    linkedIn_pixel_id: "LinkedIn Pixel ID",
    enter_linkedIn_pixel_id_here: "Enter LinkedIn Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_linkedin_profile_here: "Enter Bio link you want to add on your Linkedin profile here",
    pinterest_pixel_id: "Pinterest Pixel ID",
    enter_pinterest_pixel_id_here: "Enter Pinterest Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_pinterest_profile_here: "Enter Bio link you want to add on your Pinterest profile here",
    twitch_pixel_id: "Twitch Pixel ID",
    enter_twitch_pixel_id_here: "twitch_pixel_id",
    enter_bio_link_you_want_to_add_on_your_twitch_profile_here: "Enter Bio link you want to add on your Twitch profile here",
    tikTok_pixel_id: "TikTok Pixel ID",
    enter_tiktok_pixel_id_here: "Enter TikTok Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_tiktok_profile_here: "Enter Bio link you want to add on your TikTok profile here",
    telegram_pixel_id: "Telegram Pixel ID",
    enter_telegram_pixel_id_here: "Enter Telegram Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_telegram_profile_here: "Enter Bio link you want to add on your Telegram profile her",
    reddit_pixel_id: "Reddit Pixel ID",
    enter_reddit_pixel_id_here: "Enter Reddit Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_reddit_profile_here: "Enter Bio link you want to add on your Reddit profile here",
    medium_pixel_id: "Medium Pixel ID",
    enter_medium_pixel_id_here: "Enter Medium Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_medium_profile_here: "Enter Bio link you want to add on your Medium profile here",
    dribble_pixel_id: "Dribble Pixel ID",
    enter_dribble_pixel_id_here: "Enter Dribble Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_dribble_profile_here: "Enter Bio link you want to add on your Dribble profile here",
    patreon_pixel_id: "Patreon Pixel ID",
    enter_patreon_pixel_id_here: "Enter Patreon Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_patreon_profile_here: "Enter Bio link you want to add on your Patreon profile here",
    discord_pixel_id: "Discord Pixel ID",
    enter_discord_pixel_id_here: "Enter Discord Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_discord_profile_here: "Enter Bio link you want to add on your Discord profile here",
    behance_pixel_id: "Behance Pixel ID",
    enter_behance_pixel_id_here: "Enter Behance Pixel ID here",
    enter_bio_link_you_want_to_add_on_your_behance_profile_here: "Enter Bio link you want to add on your Behance profile here",
    fashion_stylist_expression_award: "Fashion Stylist Expression Award",
    total_points: "Total points",
    super_brand_badge: "Super Brand Badge",
    quest_1: "Quest 1",
    your_influencer_score: "Your influencer score",
    your_social_score: "Your Social Score",
    _100: "100%",
    your_rank_statustotal_points: "Your rank statusTotal points",
    ontario: "Ontario",
    k1P_2Y9: "K1P 2Y9",
    current_or_last_place_of_Work: "Current or Last Place of Work",
    _4B: "4B",
    carling_avenue: "Carling Avenue",
  },
  profile_hero_section: {
    switch_the_style: " Switch the Style",
    regular: "Regular",
    neumorphic: "Neumorphic ",
    glassmorphic: "Glassmorphic",
    enter_your_profile_details: "Enter Your Profile Details",
    step: "Step",
    of_11: "of 11",
    mobile: "Mobile",
    tab: "Tab",
    desktop: "Desktop",
    download_cv: "Download CV",
    mode: "MODE",
    social: "SOCIAL",
    hi: "Hi,",
    i_am: "I am",
    andy_rubin: "Andy rubin",
    ceo: "CEO",
    social_network_strategistt: "Social Network Strategist",
    seven_figure_bosses_series: "7-FIGURE BOSSES SQUAD SERIES",
    life_of_dina: "Life of DYNA",
    tab_tranlate_text: "And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology ",
    book_me_now: "Book me Now",
    answers: "Answers",
    social_score: " Social score",
    rank: "Rank",
    best_answers: " Best Answers",
    menu_item_number: "Menu item number",
    testimonials: "Testimonials:",
    dummy_text_of_the_printing_printing: "dummy text of the printing  printing",
    client_name_here: "Client Name Here",
    designation_here: "Designation Here",
    book_me: "Book me",
    social_mode: " SOCIAL MODE",
    a_litter_abot_me: "A LITTLE ABOUT ME",
    ceo_escapee: "CEO | 9 to 5 Escapee"

  }

}
