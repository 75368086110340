import React from "react";
import { useState } from "react";
import '../../BlogPostSectionThemes.scss'
import { BsCheckLg } from 'react-icons/bs'
import '../regularTheme3Mobile/RegularTheme3Mobile.scss';
import '../regularTheme3Tab/RegularTheme3Tab.scss';
import blogpostmobile_1 from "../../../../../../../src/images/desktopblogpost/blogpost3mobile1.png"
import blogpostmobile_2 from "../../../../../../../src/images/desktopblogpost/blogpost3mobile2.png"
import blogpostmobile_3 from "../../../../../../../src/images/desktopblogpost/blogpost3mobile3.png"
import blogpostmobile_4 from "../../../../../../../src/images/desktopblogpost/blogpost3mobile4.png"
import c from "../../../../../../../src/images/desktopblogpost/blogpost3mobile2.png"
import blogposttab_1 from "../../../../../../../src/images/desktopblogpost/blogpost3tab1.png"
import blogpost3tab_2 from "../../../../../../../src/images/desktopblogpost/blogpost3tab2.png"
import blogpost3tab_3 from "../../../../../../../src/images/desktopblogpost/blogpost3tab3.png"
import blogpost3tab_4 from "../../../../../../../src/images/desktopblogpost/blogpost3tab4.png"
import blogpostdesktop_3_1 from "../../../../../../../src/images/desktopblogpost/blogpostdesktop3-1.png"
import blogpostdesktop_3_2 from "../../../../../../../src/images/desktopblogpost/blogpostdesktop3-2.png"
import blogpostdesktop_3_3 from "../../../../../../../src/images/desktopblogpost/blogpostdesktop3-3.png"
import { BsBookmark } from "react-icons/bs";
import { BsFillHeartFill } from "react-icons/bs";
import { FaRegThumbsUp } from "react-icons/fa";
import { BiShocked } from "react-icons/bi";


import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
// import { BsCheckLg } from "react-icons/fc"
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai"
import { useTranslation } from "react-i18next";
export default function RegularTheme3Desktop() {

  return (
    <div>
      <div className="blogpostdesktop-maincontent p-5 pb-0">
        <div className="row">
          <div className="col-12 px-0 position-relative">
            <div className="blogpostdesktop-image w-100 position-relative">
              <img src={blogpostmobile_1} className="img-fluid w-100"></img>
              <div className="bookmark-icon position-absolute">
                <span><BsBookmark /></span>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-between position-relative mx-3  white-box-position">
              <div className="white-box p-4">
                <div className="white-box-content">
                  <h6 className="fashion">Fashion- May 16, 2023</h6>
                  <h2 className="fashion-heading">Lorem ipsum dolor sit amet consectetur.</h2>
                  <p className="fashion-paragraph pb-4">
                    Lorem ipsum dolor sit amet consectetur. Tristique tortor dictum fames vitae nunc purus ac commodo enim. Egestas enim adipiscing gravida platea accumsan pellentesque imperdiet. Eget lacinia tincidunt purus egestas laoreet ullamcorper. Sed risus pretium proin gravida bibendum fermentum sagittis condimentum. Massa aliquam dui turpis libero odio. Tortor magna sed in quam viverra neque vitae.
                  </p>
                  <a href="" className="read-more">Read more</a>
                </div>
              </div>

              <div className="comment-share d-flex justify-content-end my-3">
                <p className="mb-0 me-4 text-capitalize">0 comments</p>
                <p className="mb-0 text-capitalize">0 shares</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-12 px-0">
            <div className="card rounded-0 bg-white p-3 mb-3">
              <div className="blogpostmobile-image position-relaive">
                <img src={blogpostmobile_2} className="img-fluid w-100"></img>
              </div>
                  <div className="card-content-bgcolor p-3">
                      <p className="fashion">Fashion- May 16, 2023</p>
                      <h5 className="main-heading">Lorem ipsum dolor sit amet consectetur.</h5>
                      <p className="fashion-paragraph pb-3">Lorem ipsum dolor sit amet consectetur. Tristique tortor dictum fames vitae nunc purus ac commodo enim. Egestas enim adipiscing gravida platea accumsan pellentesque imperdiet. Eget lacinia tincidunt purus egestas laoreet ullamcorper.</p>
                      <a href="" className="read-more">Read more</a>
                    </div>
                    <div className="icons d-flex justify-content-between my-0">
                      <div className="thumb">
                    <span>
                    {/* <BiShocked className="shock-icon"/> */}
                    {/* <BsFillHeartFill className="heart-icon"/> */}
                    <FaRegThumbsUp className="thumb-icon"/>
                    </span>
                    <span className="ms-2"> 3</span>
                      </div>
                      <div className="comment-share">
                        <span className="me-3">0 Comments</span>
                        <span>0 Shares</span>
                      </div>
                    </div>
            </div>
          </div>

        </div>

        <div className="row ">
          <div className="col-12 px-0">
            <div className="card rounded-0 bg-white p-3 mb-3">
              <div className="blogpostmobile-image position-relaive">
                <img src={blogpostmobile_3} className="img-fluid w-100"></img>
              </div>
                  <div className="card-content-bgcolor p-3">
                      <p className="fashion">Fashion- May 16, 2023</p>
                      <h5 className="main-heading">Lorem ipsum dolor sit amet consectetur.</h5>
                      <p className="fashion-paragraph pb-3">Lorem ipsum dolor sit amet consectetur. Tristique tortor dictum fames vitae nunc purus ac commodo enim. Egestas enim adipiscing gravida platea accumsan pellentesque imperdiet. Eget lacinia tincidunt purus egestas laoreet ullamcorper.</p>
                      <a href="" className="read-more">Read more</a>
                    </div>
                    <div className="icons d-flex justify-content-between my-0">
                      <div className="thumb">
                    <span>
                    <BiShocked className="shock-icon"/>
                    <BsFillHeartFill className="heart-icon"/>
                    <FaRegThumbsUp className="thumb-icon"/>
                    </span>
                    <span className="ms-2"> 3</span>
                      </div>
                      <div className="comment-share">
                        <span className="me-3">0 Comments</span>
                        <span>0 Shares</span>
                      </div>
                    </div>
            </div>
          </div>

        </div>

        <div className="row ">
          <div className="col-12 px-0">
            <div className="card rounded-0 bg-white p-3 mb-3">
              <div className="blogpostmobile-image position-relaive">
                <img src={blogpostmobile_4} className="img-fluid w-100"></img>
              </div>
                  <div className="card-content-bgcolor p-3">
                      <p className="fashion">Fashion- May 16, 2023</p>
                      <h5 className="main-heading">Lorem ipsum dolor sit amet consectetur.</h5>
                      <p className="fashion-paragraph pb-3">Lorem ipsum dolor sit amet consectetur. Tristique tortor dictum fames vitae nunc purus ac commodo enim. Egestas enim adipiscing gravida platea accumsan pellentesque imperdiet. Eget lacinia tincidunt purus egestas laoreet ullamcorper.</p>
                      <a href="" className="read-more">Read more</a>
                    </div>
                    <div className="icons d-flex justify-content-between my-0">
                      <div className="thumb">
                    <span>
                    <BiShocked className="shock-icon"/>
                    <BsFillHeartFill className="heart-icon"/>
                    <FaRegThumbsUp className="thumb-icon"/>
                    </span>
                    <span className="ms-2"> 3</span>
                      </div>
                      <div className="comment-share">
                        <span className="me-3">0 Comments</span>
                        <span>0 Shares</span>
                      </div>
                    </div>
            </div>
          </div>

        </div>
      </div>
    </div>



  );
}
