import React, { useState } from "react";

import desktop_1 from "../../../../images/events/Desktop1.png"
import tab_1 from "../../../../images/events/Tab1.png"
import mobile_1 from "../../../../images/events/Mobile1.png"
import desktop_2 from "../../../../images/events/Desktop2.png"
import tab_2 from "../../../../images/events/Tab2.png"
import mobile_2 from "../../../../images/events/Mobile2.png"
import desktop_3 from "../../../../images/events/Desktop3.png"
import tab_3 from "../../../../images/events/Tab3.png"
import mobile_3 from "../../../../images/events/Mobile3.png"
import desktop_4 from "../../../../images/events/Desktop4.png"
import tab_4 from "../../../../images/events/Tab4.png"
import mobile_4 from "../../../../images/events/Mobile4.png"
import desktop_5 from "../../../../images/events/Desktop5.png"
import tab_5 from "../../../../images/events/Tab5.png"
import mobile_5 from "../../../../images/events/Mobile5.png"
import desktop_6 from "../../../../images/events/Desktop6.png"
import tab_6 from "../../../../images/events/Tab6.png"
import mobile_6 from "../../../../images/events/Mobile6.png"

import EventSectionRegularThemeDesktopView1 from './EventSectionRegularThemeDesktopView1'
import EventSectionRegularThemeTabView1 from './EventSectionRegularThemeTabView1'
import EventSectionRegularThemeMobileView1 from './EventSectionRegularThemeMobileView1'
import EventSectionRegularThemeDesktopView2 from './EventSectionRegularThemeDesktopView2'
import EventSectionRegularThemeTabView2 from './EventSectionRegularThemeTabView2'
import EventSectionRegularThemeMobileView2 from './EventSectionRegularThemeMobileView2'
import EventSectionRegularThemeDesktopView3 from './EventSectionRegularThemeDesktopView3'

import ButtonBoxNew from "../../../global/ButtonBoxNew";

// import HowItWorkSectionRegularThemeTabView1 from './HowItWorkSectionRegularThemeTabView1'
// import HowItWorkSectionRegularThemeMobileView1 from './HowItWorkSectionRegularThemeMobileView1'
// import HowItWorkSectionRegularThemeTabView2 from './HowItWorkSectionRegularThemeTabView2'
// import HowItWorkSectionRegularThemeMobileView2 from './HowItWorkSectionRegularThemeMobileView2'

import Modal from 'react-bootstrap/Modal';

import { useTranslation } from "react-i18next";


import './EventSectionThemes.scss';
export default function EventSectionRegularTheme() {

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [TabTwoShow, setTabTwoShow] = useState(false);
  const [MobileTwoShow, setMobileTwoShow] = useState(false);
  const [deskShow, setDeskShow] = useState(false);
  const [desktwoShow, setDesktwoShow] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);
  const [TabFourShow, setTabFourShow] = useState(false);
  const [MobileFourShow, setMobileFourShow] = useState(false);
  const [deskFourShow, setdeskFourShow] = useState(false);
  const [TabFiveShow, setTabFiveShow] = useState(false);
  const [MobileFiveShow, setMobileFiveShow] = useState(false);
  const [deskFiveShow, setdeskFiveShow] = useState(false);
  const [TabSixShow, setTabSixShow] = useState(false);
  const [MobileSixShow, setMobileSixShow] = useState(false);
  const [deskSixShow, setdeskSixShow] = useState(false);
  const [TabSevenShow, setTabSevenShow] = useState(false);
  const [MobileSevenShow, setMobileSevenShow] = useState(false);
  const [deskSevenShow, setdeskSevenShow] = useState(false);
  const [TabEightShow, setTabEightShow] = useState(false);
  const [MobileEightShow, setMobileEightShow] = useState(false);
  const [deskEightShow, setdeskEightShow] = useState(false);
  const { t, i18n } = useTranslation();
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });




  return (
    <div className="">

      <h4 className="text-center">
      Regular  Layout  Version 1
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_1} alt="deshtop-1"  >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name=" See Desktop view" boxwidth="w-220 mt-4 mx-auto mb-4"  onClick={() => setDeskShow(true)}/>
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <EventSectionRegularThemeDesktopView1 />

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_1} alt="tab-1"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setSmShow(true)}/>
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <EventSectionRegularThemeTabView1 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_1} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <EventSectionRegularThemeMobileView1 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
      Regular  Layout  Version 2
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2} alt="desktop-2" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDesktwoShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setDesktwoShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktwoShow}
            onHide={() => setDesktwoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <EventSectionRegularThemeDesktopView2 />

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2} alt="tab-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabTwoShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setTabTwoShow(true)}/>

          <Modal
            show={TabTwoShow}
            onHide={() => setTabTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-tit"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <EventSectionRegularThemeTabView2 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2} alt="mobile-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileTwoShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setMobileTwoShow(true)}/>

          <Modal
            show={MobileTwoShow}
            onHide={() => setMobileTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-ti"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <EventSectionRegularThemeMobileView2 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
      Regular  Layout  Version 3
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3} alt="desktop-3" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskthreeShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setDeskthreeShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskthreeShow}
            onHide={() => setDeskthreeShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <EventSectionRegularThemeDesktopView3 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3} alt="tab-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabThreeShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setTabThreeShow(true)}/>

          <Modal
            show={TabThreeShow}
            onHide={() => setTabThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3} alt="mobile-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileThreeShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setMobileThreeShow(true)} />

          <Modal
            show={MobileThreeShow}
            onHide={() => setMobileThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
      Regular Design - Agenda Pop-Up Version 1
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_4} alt="desktop-4" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setdeskFourShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setdeskFourShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFourShow}
            onHide={() => setdeskFourShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_4} alt="tab-4" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFourShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setTabFourShow(true)}/>

          <Modal
            show={TabFourShow}
            onHide={() => setTabFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_4} alt="mobile-4" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFourShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setMobileFourShow(true)}/>

          <Modal
            show={MobileFourShow}
            onHide={() => setMobileFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
      Regular Design - Agenda Pop-Up Version 2
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_5} alt="desktop-5" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setdeskFiveShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setdeskFiveShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFiveShow}
            onHide={() => setdeskFiveShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_5} alt="tab-5" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFiveShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setTabFiveShow(true)} />

          <Modal
            show={TabFiveShow}
            onHide={() => setTabFiveShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_5} alt="mobile-5" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFiveShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setMobileFiveShow(true)}/>

          <Modal
            show={MobileFiveShow}
            onHide={() => setMobileFiveShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
      Regular Design - Agenda Pop-Up Version 3
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_6} alt="desktop-6" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setdeskSixShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setdeskSixShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskSixShow}
            onHide={() => setdeskSixShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_6} alt="tab-6" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabSixShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-4 mx-auto mb-4"onClick={() => setTabSixShow(true)} />

          <Modal
            show={TabSixShow}
            onHide={() => setTabSixShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_6} alt="mobile-6" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileSixShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-4 mx-auto mb-4" onClick={() => setMobileSixShow(true)}/>

          <Modal
            show={MobileSixShow}
            onHide={() => setMobileSixShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
