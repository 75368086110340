import React, { useState } from "react";
import img1 from '../../../../../../images/StepZero/img13.png';
import img2 from '../../../../../../images/StepZero/img14.png';
import img3 from '../../../../../../images/StepZero/img15.png';
import img4 from '../../../../../../images/StepZero/img16.png';
// import img1s from '../../../../../../images/triangle1.png';
import img2s from '../../../../../../images/close1.png';
import DropdownBoxFashion from "../../../../../global/DropdownBoxFashion";
import ButtonBox from "../../../../../global/ButtonBox";
import Video from '../../../../../../images/iconVid.png';
import img67 from '../../../../../../images/StepZero/img67.png';
import Video1 from '../../../../../../images/iconVid1.png';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../../../redux/actions/videoAction';
export default function NicheOneEdit({ handleClick1 }) {
  const { t } = useTranslation();

  let { isVideoVisible } = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };

  const [distinctive, setDistinctive] = useState("dyna");
  const [descriptive, setDescriptive] = useState("fashion");
  const handleDescriptiveChange = (newDescriptive) => {
    setDescriptive(newDescriptive);
  };
  const [legal, setLegal] = useState("INC");
  const handleLegalChange = (newLegal) => {
    setLegal(newLegal);
  };

  const associationOptions = [
    {
      "name": "FASHION",
    },
    {
      "name": "BEAUTY",
    },
    {
      "name": "NUTRITION",
    },
    {
      "name": "FITNESS",
    },
    {
      "name": "TRAVEL",
    },
    {
      "name": "DECOR",
    },
    {
      "name": "COACHING",
    },
    {
      "name": "SPIRITUALITY",
    },
    {
      "name": "ENTERTAINMENT",
    },
    {
      "name": "FINANCE",
    },
    {
      "name": "E-LEARNING",
    },
    {
      "name": "ANIMAL",
    },
    {
      "name": "OTHER",
    },
  ]
  const endingsOptions = [
    {
      "name": "INC.",
      "subname": "Incorporated"
    },
    {
      "name": "SP  | SOLE PROP",
      "subname": "Sole Proprietorship"
    },
    {
      "name": "LLC",
      "subname": "Limited Liability Company"
    },
    {
      "name": "CORP.",
      "subname": " Corporation"
    },
    {
      "name": "S CORP",
      "subname": "S. Corporation"
    },
    {
      "name": "C CORP",
      "subname": "C. Corporation"
    },
    {
      "name": "LP.",
      "subname": "Limited  Partnership"
    },
    {
      "name": "LLPs.",
      "subname": "Limited Liability Partnerships"
    },
    {
      "name": "CO-OP.",
      "subname": "Cooperative"
    },
    {
      "name": "NPO",
      "subname": "Non-Profit Organization"
    },
    {
      "name": "DBA",
      "subname": "Doing Business As"
    },
    {
      "name": "T/A",
      "subname": "Trade As"
    },
  ]

  return (
    <div className="w-100 d-flex flex-column-reverse">
      <div className='w-100'>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="manage" role="tabpanel" aria-labelledby="manage">
            <div className="col-lg-6 mauto">
              <div className="formleft flex-column factorTab1s position-relative">
                <div className="text-end d-flex">
                  <div className="VideoIcon ms-auto" onClick={handleClick6}>
                    <img src={Video} className="lightImg" width="130px" height="80px" alt="" />
                    <img src={Video1} className="darkImg" width="130px" height="80px" alt="" />
                  </div>
                  <img src={img2s} alt="" width="40px" height="52px" className="mt-87 cursor-pointer me-3 position-absolute end-0 top-0" onClick={handleClick1} />
                </div>
                <div className="w-100 d-flex">
                  <img src={img1} alt="" height="140px" className="mw-129 position-absolute top-0 start-0" />
                  <span className="w-75 mx-auto pt-100">
                    <h3>Dyna.Fashion Inc.</h3>
                    <div className="d-flex justify-content-between align-items-start w-100">
                      <span className="h4">
                        <input type="text" placeholder="Dyna" value={distinctive} onChange={(e) => setDistinctive(e.target.value)} />
                        <img src={img2} alt="" />
                        <p>Distinctive</p>
                      </span>
                      <span className="h5">
                        <p>Descriptive</p>
                        <img src={img3} alt="" />
                        <DropdownBoxFashion name={descriptive} namebutton="INDUSTRY" boxid="fashion" onChange={handleDescriptiveChange} options={associationOptions} boxwidth="text-start country w-100" />
                      </span>
                      <span className="h6">
                        <DropdownBoxFashion name={legal} boxid="inc" namebutton="LEGAL ENDINGS" onChange={handleLegalChange} options={endingsOptions} boxwidth="text-start country w-100" />
                        <img src={img4} alt="" />
                        <p>Legal</p>
                      </span>
                    </div>
                    <div className='btnsec d-flex w-100 mt-5 justify-content-center'>
                      <ButtonBox
                        boxwidth="w-110 bg-theme mt-0 mb-0 me-5"
                        clicked="Cancel"
                        name="Cancel"
                      />
                      <ButtonBox
                        boxwidth="w-110  mt-0 mb-0"
                        clicked="Saved"
                        name="Save"
                      />
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="nuans" role="tabpanel" aria-labelledby="nuans">

          </div>
          <div className="tab-pane text-center detailTab fade" id="otherg" role="tabpanel" aria-labelledby="otherg">
            <div className="formleft flex-column p-2">
              <img src={img67} alt="" />
              <div className='btnsec d-flex w-100 my-5 justify-content-center'>
                <ButtonBox
                  boxwidth="w-110 bg-theme mt-0 mb-0 me-5"
                  clicked="Cancel"
                  name="Cancel"
                />
                <ButtonBox
                  boxwidth="w-110  mt-0 mb-0"
                  clicked="Saved"
                  name="Save"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-6 mauto px-0 privacyTab1 privacyTab3s mb-3 pb-3 mt-4'>
        <div id="myTab" className='slidetab' role="tablist">
          <div className="d-flex justify-content-between pb-3">
            <div className='px-2 btntb1'>
              <button className="br-40 w-100 h-100 border-0 active" id="manage" data-bs-toggle="tab" data-bs-target="#manage" type="button" role="tab" aria-controls="manage" aria-selected="true">MANAGE BUSINESS NAME</button>
            </div>
            <div className='px-2 btntb1'>
              <button className="br-40 w-100 h-100 border-0" id="nuans" data-bs-toggle="tab" data-bs-target="#nuans" type="button" role="tab" aria-controls="nuans" aria-selected="true">NUANS BUSINESS NAME SEARCH</button>
            </div>
            <div className='px-2 btntb1'>
              <button className="br-40 w-100 h-100 border-0" id="otherg" data-bs-toggle="tab" data-bs-target="#otherg" type="button" role="tab" aria-controls="otherg" aria-selected="false">Legal Structure</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
