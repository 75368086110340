import React from 'react';
import '../../../global/timelineTab/TimelineTab.scss';
import Video from '../../../../images/iconVid.png';
import VideoPopup from '../../../global/VideoPopup';
import Video1 from '../../../../images/iconVid1.png';
import ButtonBox from "../../../global/ButtonBox";
// import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
// import { NavItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import TrustPilotBusinessAccountForm from '../trustPilotBusinessAccount/TrustPilotBusinessAccountForm';
import TrustPilotBusinessAccountForm_1 from '../trustPilotBusinessAccount/TrustPilotBusinessAccountForm_1'
import './TrustPilotBusinessAccount.scss'
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';
// import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
export default function TrustPilotBusinessAccountStep() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });
    let {isVideoVisible} = useSelector(state => state.videoReducer);
    const dispatch = useDispatch();
    const handleClick6 = () => {
      dispatch(toggleVideo());
    };

    return (
        <div>
            <Tab.Container defaultActiveKey="">
                <div className='trust_pilot_business_account' >
                    <div className='flex'>
                        <div className='width-fll'>
                            <VideoPopup />
                            <Tab.Content className='class-scroll  ' >
                                <div className="w-780  align-items-center mx-auto d-flex flex-column-reverse justify-content-between three-bsiness">
                                    <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto ">
                                    <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
                                        <div className="tab-content" id="myTabContentsss">
                                            <div className='tab-pane active' id="trustpilot_review_account">
                                                <div className='h3-border mt-3'>
                                                    <div className='flex flex-diction  none' >
                                                        <h5>
                                                            {t('timeline_trustpilot_business_account_component.create_trustpilot_reviews_account')}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <TrustPilotBusinessAccountForm />
                                            </div>
                                            <div className='tab-pane' id="capterra_reviews_account">
                                            <div className='h3-border mt-3'>
                                                    <div className='flex flex-diction  none' >
                                                        <h5>
                                                            {t('timeline_trustpilot_business_account_component.create_capterra_reviews_account')}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <TrustPilotBusinessAccountForm_1 />
                                            </div>
                                        </div>
                                    </div>
                                    <span className="d-flex align-items-center justify-content-between w-100 mb-4">
                                        <div id="myTab1" className='flex' role="tablist">
                                            <button className="buttonArrow me-3 nav-link text-body border-0 bg br-10 active" id="trustpilot_review_account" data-bs-toggle="tab" data-bs-target="#trustpilot_review_account" type="button" role="tab" aria-controls="trustpilot_review_account" aria-selected="true">
                                                <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
                                            </button>
                                            <button className="buttonArrow me-3 text-body nav-link border-0 bg br-10" id="capterra_reviews_account" data-bs-toggle="tab" data-bs-target="#capterra_reviews_account" type="button" role="tab" aria-controls="capterra_reviews_account" aria-selected="false">
                                                <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
                                            </button>
                                        </div>
                                        <div className='btn-save-hide'>
                                            <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="" icon={faFloppyDisk} name="Save" />
                                        </div>

                                    </span>
                                </div>
                            </Tab.Content>
                        </div>
                    </div>
                </div>
            </Tab.Container>
        </div>
        // <div className='sectionfifth trust_pilot_business_account arrow-select'>
        //     <Tab.Container id="left-tabs-example" defaultActiveKey="two">
        //         <div className='' >
        //             <div className='flex'>


        //                 <div className='width-fll'>
        //                     <VideoPopup />
        //                     <Tab.Content className='class-scroll'>
        //                         <Tab.Pane eventKey="two">
        //                             <div className="w-720 align-items-center mx-auto d-flex flex-column-reverse justify-content-between three-bsiness">
        //                                 <div className='d-flex'>

        //                                     <div>
        //                                         <span className="d-flex align-items-center justify-content-between w-100 mb-4">
        //                                             <div id="myTab1" className='flex' role="tablist">
        //                                                 <button className="buttonArrow me-3 nav-link text-body border-0 bg br-10 active" id="trustpilot_review_account" data-bs-toggle="tab" data-bs-target="#trustpilot_review_account" type="button" role="tab" aria-controls="trustpilot_review_account" aria-selected="true">
        //                                                     <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
        //                                                 </button>

        //                                                 <button className="buttonArrow me-3 text-body nav-link border-0 bg br-10" id="capterra_reviews_account" data-bs-toggle="tab" data-bs-target="#capterra_reviews_account" type="button" role="tab" aria-controls="capterra_reviews_account" aria-selected="false">
        //                                                     <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
        //                                                 </button>
        //                                             </div>
        //                                             <div className='btn-save-hide'>
        //                                                 <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="" icon={faFloppyDisk} name="Save" />
        //                                             </div>

        //                                         </span>
        //                                         <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto ">
        //                                             <Link to="" className="VideoIcon" data-bs-toggle="modal" data-bs-target="#videoScreenn">
        //                                                 <img src={Video} className="lightImg" alt="" />
        //                                                 <img src={Video1} className="darkImg" alt="" />
        //                                             </Link>

        //                                             <div className="tab-content" id="myTabContent">
        //                                                 <div className='tab-pane active' id="trustpilot_review_account">
        //                                                     <div className='h3-border1 d-flex w-75'>
        //                                                         <h3 className='mb-0 text-body border-0 text-left bg'>
        //                                                             {t('timeline_trustpilot_business_account_component.create_trustpilot_reviews_account')}
        //                                                         </h3>
        //                                                     </div>
        //                                                     <TrustPilotBusinessAccountForm />
        //                                                 </div>
        //                                                 <div className='tab-pane' id="capterra_reviews_account">
        //                                                     <h1>second tab</h1>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </Tab.Pane>

        //                     </Tab.Content>


        //                 </div>
        //             </div>
        //         </div>





        //     </Tab.Container>

        // </div>
    )
}