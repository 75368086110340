import React from "react";
import RangeSelect from "../../../../global/rangeSelect/RangeSelect";
import { EducationRange } from "../../../../global/rangeSelect/RangeValues"; 
import { useState } from "react";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import ButtonBox from '../../../../global/ButtonBox';
import { useTranslation } from "react-i18next";
import ButtonBoxNew from "../../../../global/ButtonBoxNew";
import { ReactComponent as Icon5 } from "../../../../../images/icons/icon56.svg";
import CustomRange from "../../../../global/CustomRange";

export default function EducationOne() {
  const { t, i18n } = useTranslation();

  const [selectedRange, setSelectedRange] = useState(
    EducationRange[Math.floor(EducationRange.length / 2)] 
  );
  const [ yearStarted, setYearStarted ] = useState(2018);
  const [ Competency1, setCompetency1 ] = useState(3);
  const [ Competency2, setCompetency2 ] = useState(3);
  const [ Competency3, setCompetency3 ] = useState(3);
  const [ Competency4, setCompetency4 ] = useState(3);
  const [ Competency5, setCompetency5 ] = useState(3);
  const [ Competency6, setCompetency6 ] = useState(3);
  const [ Competency7, setCompetency7 ] = useState(3);
  const [ Competency8, setCompetency8 ] = useState(3);
  const [ Competency9, setCompetency9 ] = useState(3); 

  return (
    <div>
      <div className="text-center mb-5">
        <h3 className="mb-2"> {t('profile_section.your_education_level_status_skills_hobbies_languages')}</h3>
      </div>
      <div className="mb-4 pb-0 rangeCenter">
        <div>
          <p className="text-center neu_range_select__title mt-5 mb-3">
          {t('profile_section.your_highest_level_of_education')}
          </p>
          {/* <RangeSelect
            data={EducationRange}
            type="ages"
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
            value={selectedRange}
          /> */}
          <div className="w-75 mx-auto">
          <CustomRange />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-6">
          <label className="mb-2"> {t('profile_section.last_institution_university_name')}</label>
          <input
              placeholder={t('profile_section.smith_school_of_business')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
        </div>
        <div className="col-md-6">
          <label className="mb-2"> {t('profile_section.major_specialization')}</label>
          <input
              placeholder={t('profile_section.mba_marketing_strategist')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
        </div>
      </div>
      <div className="row mx-0">
        <label className="w-100 text-center mb-5">{t('profile_section.year_started')}</label>
        <div className="col-md-4">
          <div className='check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
              <input type="checkbox" className="btn-check" name="alumni" id="alumni" />
              <label className="btn rounded-circle btnRadio large me-3" htmlFor="alumni"><div className="radioInner"><span></span></div></label>
              {t('profile_section.i_am_an_alumni')}
          </div>
        </div>
        <div className="col-md-8 accomplishSec">
          <div className="slidecontainer mb-3">
            <RangeSlider value={yearStarted} onChange={changeEvent => setYearStarted(changeEvent.target.value)} min={2000} max={2099}/>
          </div>
        </div>
      </div>
      <div className="row mb-4 pb-2">
        <label className="w-100 text-center mb-4 h9 fw-bold">{t('profile_section.i_am_currently')}</label>
        <div className='col-md-3 p-0 h8 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
            <input type="checkbox" className="btn-check" name="communication-day" id="fulltime" />
            <label className="btn rounded-circle btnRadio large me-3" htmlFor="fulltime"><div className="radioInner"><span></span></div></label>
            {t('profile_section.a_full_time_student')}
        </div>
        <div className='col-md-3 p-0 h8 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
            <input type="checkbox" className="btn-check" name="communication-day" id="parttime"/>
            <label className="btn rounded-circle btnRadio large me-3" htmlFor="parttime"><div className="radioInner"><span></span></div></label>
            {t('profile_section.a_part_time_student')}
        </div>
        <div className='col-md-4 h8 p-0 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
            <input type="checkbox" className="btn-check" name="communication-day" id="reconsidering" />
            <label className="btn rounded-circle btnRadio large me-3" htmlFor="reconsidering"><div className="radioInner"><span></span></div></label>
            {t('profile_section.reconsidering_my_options')}
        </div>
        <div className='col-md-2 h8 p-0 check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
            <input type="checkbox" className="btn-check" name="communication-day" id="dropped"   />
            <label className="btn rounded-circle btnRadio large me-3" htmlFor="dropped"><div className="radioInner"><span></span></div></label>
            {t('profile_section.dropped_out')}
        </div>
      </div>
      <div className="w-100 mb-4 pb-2">
          <label className="mb-3">{t('profile_section.list_5_special_skills')}</label>
          <input
              placeholder={t('profile_section.social_network_strategist')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
      </div>
      <div className="row mb-4">
        <div className="col-md-4">
          <label className="w-100 mb-0">{t('profile_section.competency_level_1_10')}
</label>
        </div>
        <div className="col-md-8 accomplishSec">
          <div className="slidecontainer mb-3">
            <RangeSlider value={Competency1} onChange={changeEvent => setCompetency1(changeEvent.target.value)} min={0} max={10}/>
          </div>
        </div>
        <div className="col-md-12">
          <input
            placeholder={t('profile_section.fashion_stylist_coach')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4">
          <label className="w-100 mb-0">{t('profile_section.competency_level_1_10')}</label>
        </div>
        <div className="col-md-8 accomplishSec">
          <div className="slidecontainer mb-3">
            <RangeSlider value={Competency2} onChange={changeEvent => setCompetency2(changeEvent.target.value)} min={0} max={10}/>
          </div>
        </div>
        <div className="col-md-12">
          <input
            placeholder={t('profile_section.venture_start_up_guru')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4">
          <label className="w-100 mb-0">{t('profile_section.competency_level_1_10')}</label>
        </div>
        <div className="col-md-8 accomplishSec">
          <div className="slidecontainer mb-3">
            <RangeSlider value={Competency3} onChange={changeEvent => setCompetency3(changeEvent.target.value)} min={0} max={10}/>
          </div>
        </div>
        <div className="col-md-12">
          <input
            placeholder={t('profile_section._7_figure_lifestyle_brand_consultant')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4">
          <label className="w-100 mb-0">{t('profile_section.competency_level_1_10')}</label>
        </div>
        <div className="col-md-8 accomplishSec">
          <div className="slidecontainer mb-3">
            <RangeSlider value={Competency4} onChange={changeEvent => setCompetency4(changeEvent.target.value)} min={0} max={10}/>
          </div>
        </div>
        <div className="col-md-12">
          <input
            placeholder={t('profile_section._7_figure_self_made_coach')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4">
          <label className="w-100 mb-0">{t('profile_section.competency_level_1_10')}</label>
        </div>
        <div className="col-md-8 accomplishSec">
          <div className="slidecontainer mb-3">
            <RangeSlider value={Competency5} onChange={changeEvent => setCompetency5(changeEvent.target.value)} min={0} max={10}/>
          </div>
        </div>
        <div className="col-md-12">
            {/* <button className="addMore h-50s bg br-30 w-150 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-plus" className="svg-inline--fa fa-circle-plus h5 my-auto me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"></path></svg>
              {t('profile_section.add_more')}
            </button> */}
            <ButtonBoxNew name="Add More" iconbox="d-block" boxwidth="w-200" />
        </div>
      </div>

      <div className="w-100 mb-4 pb-2">
          <label className="mb-3">{t('profile_section.list_5_hobbies')}</label>
          <input
              placeholder={t('profile_section.playing_the_guitar_violin')}
              className="shadow-5in br-10 w-100 mb-2"
              type="text"
            />
            <input
              placeholder={t('profile_section.horse_backriding')}
              className="shadow-5in br-10 w-100 mb-2"
              type="text"
            />
            <input
              placeholder={t('profile_section.playing_golf_tennis')}
              className="shadow-5in br-10 w-100 mb-2"
              type="text"
            />
            <input
              placeholder={t('profile_section.home_decor_organizing')}
              className="shadow-5in br-10 w-100 mb-2"
              type="text"
            />
            <input
              placeholder={t('profile_section.reading_philosophy_books')}
              className="shadow-5in br-10 w-100 mb-3"
              type="text"
            />
            {/* <button className="addMore h-50s bg br-30 w-150 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-plus" className="svg-inline--fa fa-circle-plus h5 my-auto me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"></path></svg>
              {t('profile_section.add_more')}
            </button> */}
            <ButtonBoxNew name="Add More" iconbox="d-block" boxwidth="w-200" />
      </div>

      <div className="row mb-3">
        <div className="col-md-4">
          <label className="w-100 mb-3">{t('profile_section.first_language')}
</label>
          <input
            placeholder={t('profile_section.french')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
        <div className="col-md-8 accomplishSec">
          <label className="w-100 mb-4">{t('profile_section.proficiency')}
</label>
          <div className="slidecontainer mb-3">
            <RangeSlider value={Competency6} onChange={changeEvent => setCompetency6(changeEvent.target.value)} min={0} max={10}/>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-4">
          <label className="w-100 mb-3">{t('profile_section.second_language')}</label>
          <input
            placeholder={t('profile_section.english')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
        <div className="col-md-8 accomplishSec">
          <label className="w-100 mb-4">{t('profile_section.proficiency')}</label>
          <div className="slidecontainer mb-3">
            <RangeSlider value={Competency7} onChange={changeEvent => setCompetency7(changeEvent.target.value)} min={0} max={10}/>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-4">
          <label className="w-100 mb-3">{t('profile_section.third_language')}</label>
          <input
            placeholder={t('profile_section.spanish')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
        <div className="col-md-8 accomplishSec">
          <label className="w-100 mb-4">{t('profile_section.proficiency')}</label>
          <div className="slidecontainer mb-3">
            <RangeSlider value={Competency8} onChange={changeEvent => setCompetency8(changeEvent.target.value)} min={0} max={10}/>
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-md-4">
          <label className="w-100 mb-3">{t('profile_section.fourth_language')}</label>
          <input
            placeholder={t('profile_section.madarin')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
        <div className="col-md-8 accomplishSec">
          <label className="w-100 mb-4">{t('profile_section.proficiency')}:</label>
          <div className="slidecontainer mb-3">
            <RangeSlider value={Competency9} onChange={changeEvent => setCompetency9(changeEvent.target.value)} min={0} max={10}/>
          </div>
        </div>
      </div>
      <div className="mt-4 text-center">
        <ButtonBox boxwidth="w-140 mt-0 mb-50 mx-auto" clicked="Saved" name="Save/Next" />
      </div>
    </div>
  );
}
