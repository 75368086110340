import React from "react";
import { useState } from "react";

import Modal from "react-bootstrap/Modal";
// import "bootstrap/dist/css/bootstrap.css";
// import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

// import pattern from "../../../../../../images/hire-me/desktopview/Dots.png";
// import Dots from "../../../../../../images/hire-me/desktopview/Dotsblack.png";

// import HireMeNav from "../components/HireMeNav/HireMeNav";
// import HireMeMainContent from "../components/HireMeMainContent/HireMeMainContent";
// import HireMePositionBox from "../components/HireMePositionBox/HireMePositionBox";
// import HireMeForm from "../components/HireMeForm/HireMeForm";
// import HireMeSideBox from "../components/HireMeSideBox/HireMeSideBox";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaBars,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaDribbble,
  FaPinterest,
  FaYoutube,
} from "react-icons/fa";

import subscriptionCenterRegularRating3Stars from "../../../../../../images/subscription/subscription-center-title-regular-rating-3stars.png";
import subscriptionCenterNeumorphicRating3Stars from "../../../../../../images/subscription/subscription-center-neumorphic-rating3-stars.png";
import subscriptionCenterGlassmorphicRating3Stars from "../../../../../../images/subscription/subscription-center-title-glassmorphic-rating-3stars.png";
import subscribeUserIcon from "../../../../../../images/subscription/subscribe-user-icon.png";
import subscribeAroundTextImg from "../../../../../../images/subscription/subscribr-text-img.svg";
import subscribeAroundTextWhiteImg from "../../../../../../images/subscription/subscribr-text-theme2-img.png";
import greenEnvelopDropImg from "../../../../../../images/subscription/green-envelop-drop-box-img.png";
import whiteEnvelopDropImg from "../../../../../../images/subscription/white-envelop-drop-box-img.svg";
import goldenEnvelopDropImg from "../../../../../../images/subscription/golden-envelop-drop-box-img.png";
import whiteEnvelopImg from "../../../../../../images/subscription/white-envelop-img.png";

import theme3TopRightDotsImg from "../../../../../../images/subscription/theme3-top-right-dots-img.png";
import theme3BottomLeftDotsImg from "../../../../../../images/subscription/theme3-bottom-left-dots-img.png";
import theme3BottomRightDotsImg from "../../../../../../images/subscription/theme3-bottom-right-dots-bg-img.png";

import theme2GlassmorphicTopLeftDotsImg from "../../../../../../images/subscription/theme2-glassmorphic-top-left-dots-img.png";
import theme2GlassorpicTopRightDotsImg from "../../../../../../images/subscription/theme2-glassmorphic-top-right-dots-img.png";
import theme2GlassmorphicBottomRightDotsImg from "../../../../../../images/subscription/theme2-glassmorphic-bottom-right-dots-img.png";

import theme3NeumorphicTopRightDotsImg from "../../../../../../images/subscription/theme3-neumorphic-top-right-dots-img.png";
import theme3NeumorphicBottomRightDotsImg from "../../../../../../images/subscription/theme3-neumorphic-bottom-right-dots-img.png";
import theme3NeumorphicBottomLeftDotsImg from "../../../../../../images/subscription/theme3-neumorphic-bottom-left-dots-img.png";

import book_btn from "../../../../../../images/met-book.png";
import glass_btn from "../../../../../../images/glass-btn.png";
import "./RegularTheme4Desktop.scss";
import "../regularTheme4Mobile/RegularTheme4Mobile.scss";
import "../regularTheme4Tab/RegularTheme4Tab.scss";
import {
  faCloudArrowDown,
  faInfo,
  faMagnifyingGlass,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import img1 from "../../../../../../images/img1.png";
import social_icon from "../../../../../../images/social-icons.png";
import second_3 from "../../../../../../images/second-3.png";
import dribble_dark from "../../../../../../images/dribble-dark.png";
import message from "../../../../../../images/message.png";
import answer from "../../../../../../images/answers.png";
import rank from "../../../../../../images/rank.png";
import gold_star from "../../../../../../images/gold-star.png";
import load from "../../../../../../images/load.png";
import vedio from "../../../../../../images/video.png";
import fb from "../../../../../../images/fb.png";
import mic_img from "../../../../../../images/mic-img.png";
import insta from "../../../../../../images/insta.png";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import {
  RiDownloadLine,
  RiPlayCircleFill,
  RiCloseLine,
  RiPlayMiniLine,
  RiShareForwardLine,
  RiFullscreenLine,
} from "react-icons/ri";
import sond_right from "../../../../../../images/sond-right.png";
import sond_left from "../../../../../../images/sond-left.png";
import sond_1 from "../../../../../../images/sond-1.png";
import sond_2 from "../../../../../../images/sond-2.png";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import { FaStar } from "react-icons/fa";
import { RiDownloadCloud2Line, RiInstagramFill } from "react-icons/ri";
import message_gold from "../../../../../../images/msg-gold.png";
import rank_gold from "../../../../../../images/rank-gold.png";

import cap_gold from "../../../../../../images/cap-gold.png";
import desktop_m from "../../../../../../images/desktop-m.png";
import Nav from "react-bootstrap/Nav";
import { RxGear, RxMagnifyingGlass } from "react-icons/rx";
import printer_est from "../../../../../../images/pinter-est.png";
import linked_in from "../../../../../../images/linked-in.png";
import you_tube from "../../../../../../images/you-tube.png";
import Social_2 from "../../../../../../images/Social-2.png";
import testimonial from "../../../../../../images/testimonial.png";
import testimonial_glass from "../../../../../../images/testimonial-glass.png";
import timeline_gold from "../../../../../../images/timeline-gold.png";
import navbar_shape from "../../../../../../images/navbar-shape.png";
import Vector_shape from "../../../../../../images/Vector-shape.png";
import circle_img from "../../../../../../images/circle-shape.png";
import dots from "../../../../../../images/Dots.png";
import meo_shape_right from "../../../../../../images/meo-shape-right.png";
import mue_shape from "../../../../../../images/mue-shape.png";
import desktop_img from "../../../../../../images/desktop.png";
import plalist_gold from "../../../../../../images/plalist-gold.png";
import timeline from "../../../../../../images/how-it-work/timeline.png";
import timeline_2 from "../../../../../../images/how-it-work/timeline-2.png";
import timeline_3 from "../../../../../../images/how-it-work/timeline-3.png";

export default function RegularTheme4Desktop({
  Regular,
  Neumorphic,
  Glassmorphic,
}) {
  const [isSwitchBtn, setSwitchBtn] = useState(false);

  const switchToggleBtn = () => {
    setSwitchBtn(!isSwitchBtn);
  };

  const [isSwitchToggled, toggle] = useState(false);

  const callback = () => {
    toggle(!isSwitchToggled);
  };

  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [Competency1, setCompetency1] = useState(10);

  return (
    <>
      <div
        className="subscription-desktop-theme4"
        style={{
          background: Regular ? "#CBD5E0" : Neumorphic ? "#F0F4F8" : Glassmorphic ? "linear-gradient(326deg, #9595B8 0%, #ECEDF5 46.35%, #C8C9D2 70.83%, #97999D 100%)" : "",
        }}
      >
        <div
          className={`theme-4-main-wrapper  ${
            Regular
              ? "regular-style"
              : Neumorphic
              ? "neumorphic-style"
              : Glassmorphic
              ? "glassmorphic-style"
              : ""
          }`}
        >
            <div
                className={`subscription-desktop-theme4-main-wrapper ${
                    Regular
                        ? "regular-style"
                        : Neumorphic
                            ? "neumorphic-style"
                            : Glassmorphic
                                ? "glassmorphic-style"
                                : ""
                }`}
            >
              <div className={`shadow-wrapper ${
                  Regular
                      ? "regular-style"
                      : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                              ? "glassmorphic-style"
                              : ""
              }`}>
              <div
                  className={`subscribe-weekly-drops ${
                      Regular
                          ? "regular-style"
                          : Neumorphic
                              ? "neumorphic-style"
                              : Glassmorphic
                                  ? "glassmorphic-style"
                                  : ""
                  }`}
              >
                <div className="subscribe-weekly-drops-icon-wrapper">
                  <div
                      className={`img-wrapper  ${
                          Regular
                              ? "regular-style"
                              : Neumorphic
                                  ? "neumorphic-style"
                                  : Glassmorphic
                                      ? "glassmorphic-style"
                                      : ""
                      }`}
                  >
                    <img
                        src={subscribeAroundTextWhiteImg}
                        alt="subscribeAroundTextImg"
                        className="subscribeAroundTextBlackImg"
                    />
                    <img
                        src={subscribeAroundTextImg}
                        alt="subscribeAroundTextImg"
                        className="subscribeAroundNeumorphicTextImg"
                    />
                    <div className="icon-wrapper">
                      <img
                          src={subscribeUserIcon}
                          alt="subscribeUserIcon"
                          className="subscribeUserIcon"
                      />
                    </div>
                    <p className="pick-poison-text">GOSSIP HUB</p>
                  </div>
                </div>

                <div className="envelop-drop-wrapper">
                  <div className={`img-div  ${
                      Regular
                          ? "regular-style"
                          : Neumorphic
                              ? "neumorphic-style"
                              : Glassmorphic
                                  ? "glassmorphic-style"
                                  : ""
                  }`}>
                    <img src={whiteEnvelopImg} alt="whiteEnvelopImg" className="whiteEnvelopImg"/>
                    <img src={greenEnvelopDropImg} alt="greenEnvelopDropImg" className="greenEnvelopDropImg"/>
                    <img src={whiteEnvelopDropImg} alt="whiteEnvelopDropImg" className="whiteEnvelopDropImg"/>
                    <img src={goldenEnvelopDropImg} alt="goldenEnvelopDropImg" className="goldenEnvelopDropImg"/>
                  </div>
                </div>

                <div
                    className={`on-off-buttons-wrapper ${
                        Regular
                            ? "regular-style"
                            : Neumorphic
                                ? "neumorphic-style"
                                : Glassmorphic
                                    ? "glassmorphic-style"
                                    : ""
                    }`}
                >
                  <ul className="on-off-btn-items">
                    <li>
                      <div
                          className={`toggle-switch ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style"
                                          : ""
                          }`}
                      >
                        <input
                            type="checkbox"
                            className="checkbox"
                            name="events"
                            id="events"
                            defaultChecked={isSwitchBtn}
                            onClick={switchToggleBtn}
                        />
                        <label className="label" htmlFor="events">
                          <span className="inner"/>
                          <span className="switch"/>
                        </label>
                      </div>
                      <strong>EVENTS</strong>
                    </li>
                    <li>
                      <div
                          className={`toggle-switch ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style"
                                          : ""
                          }`}
                      >
                        <input
                            type="checkbox"
                            className="checkbox"
                            name="newsletter"
                            id="newsletter"
                            defaultChecked={true}
                            onClick={switchToggleBtn}
                        />
                        <label className="label" htmlFor="newsletter">
                          <span className="inner"/>
                          <span className="switch"/>
                        </label>
                      </div>
                      <strong>NEWSLETTER</strong>
                    </li>
                    <li>
                      <div
                          className={`toggle-switch ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style"
                                          : ""
                          }`}
                      >
                        <input
                            type="checkbox"
                            className="checkbox"
                            name="videoChannel"
                            id="videoChannel"
                            defaultChecked={isSwitchBtn}
                            onClick={switchToggleBtn}
                        />
                        <label className="label" htmlFor="videoChannel">
                          <span className="inner"/>
                          <span className="switch"/>
                        </label>
                      </div>
                      <strong>VIDEO CHANNEL</strong>
                    </li>
                    <li>
                      <div
                          className={`toggle-switch ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style"
                                          : ""
                          }`}
                      >
                        <input
                            type="checkbox"
                            className="checkbox"
                            name="blogPosts"
                            id="blogPosts"
                            defaultChecked={isSwitchBtn}
                            onClick={switchToggleBtn}
                        />
                        <label className="label" htmlFor="blogPosts">
                          <span className="inner"/>
                          <span className="switch"/>
                        </label>
                      </div>
                      <strong>BLOG POSTS</strong>
                    </li>
                    <li>
                      <div
                          className={`toggle-switch ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style"
                                          : ""
                          }`}
                      >
                        <input
                            type="checkbox"
                            className="checkbox"
                            name="podcast"
                            id="podcast"
                            defaultChecked={isSwitchBtn}
                            onClick={switchToggleBtn}
                        />
                        <label className="label" htmlFor="podcast">
                          <span className="inner"/>
                          <span className="switch"/>
                        </label>
                      </div>
                      <strong>PODCAST</strong>
                    </li>
                    <li>
                      <div
                          className={`toggle-switch ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style"
                                          : ""
                          }`}
                      >
                        <input
                            type="checkbox"
                            className="checkbox"
                            name="subscribe"
                            id="subscribe"
                            defaultChecked={isSwitchBtn}
                            onClick={switchToggleBtn}
                        />
                        <label className="label" htmlFor="subscribe">
                          <span className="inner"/>
                          <span className="switch"/>
                        </label>
                      </div>
                      <strong>SUBSCRIBE TO ALL</strong>
                    </li>
                  </ul>
                </div>
                <div
                    className={`signup-form ${
                        Regular
                            ? "regular-style"
                            : Neumorphic
                                ? "neumorphic-style"
                                : Glassmorphic
                                    ? "glassmorphic-style"
                                    : ""
                    }`}
                >
                  <Form>
                    <Form.Group>
                      <div
                          className={`form-group ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style" :
                                          ""
                          }`}
                      >
                        <Form.Control type="text" placeholder=""/>
                        <Button variant="primary" type="submit">
                          SUBSCRIBE
                        </Button>
                      </div>
                    </Form.Group>
                  </Form>
                </div>
              </div>
              <img
                  src={theme3TopRightDotsImg}
                  alt="theme3TopRighttDotsImg"
                  className="theme3TopRighttDotsImg"
              />
              <img
                  src={theme3BottomLeftDotsImg}
                  alt="theme3BottomLeftDotsImg"
                  className="theme3BottomLeftDotsImg"
              />
              <img
                  src={theme3BottomRightDotsImg}
                  alt="theme3BottomRightDotsImg"
                  className="theme3BottomRightDotsImg"
              />

              <img
                  src={theme3NeumorphicTopRightDotsImg}
                  alt="theme3NeumorphicTopRightDotsImg"
                  className="theme3NeumorphicTopRightDotsImg"
              />
              <img
                  src={theme3NeumorphicBottomRightDotsImg}
                  alt="theme3NeumorphicBottomRightDotsImg"
                  className="theme3NeumorphicBottomRightDotsImg"
              />
              <img
                  src={theme3NeumorphicBottomLeftDotsImg}
                  alt="theme3NeumorphicBottomLeftDotsImg"
                  className="theme3NeumorphicBottomLeftDotsImg"
              />

              <img
                  src={theme3NeumorphicTopRightDotsImg}
                  alt="theme3NeumorphicTopRightDotsImg"
                  className="theme3NeumorphicTopRightDotsImg"
              />
              <img
                  src={theme3NeumorphicBottomRightDotsImg}
                  alt="theme3NeumorphicBottomRightDotsImg"
                  className="theme3NeumorphicBottomRightDotsImg"
              />
              <img
                  src={theme3NeumorphicBottomLeftDotsImg}
                  alt="theme3NeumorphicBottomLeftDotsImg"
                  className="theme3NeumorphicBottomLeftDotsImg"
              />


            </div>
          </div>
        </div>
      </div>
    </>
  );
}
