import React from 'react'
import './ThreeHoursBusinessLaunch.scss';
import '../threeHoursBusinessLaunch/customDomain/CustomDomainStep.scss';
import { useTranslation } from "react-i18next";
import img365 from "../../../images/StepZero/img70.png";
import img10 from '../../../images/StepZero/left.png';
import img11 from '../../../images/StepZero/right.png';
import { Link } from 'react-router-dom';
export default function DayMarketingPlanner() {
  const { t, i18n } = useTranslation();
  return (
    <div className=''>
      <div className='three-hour-launch d-flex position-relative scrollpage'>
        <div className='width-form-set w-80s'>
          <div className='flex justify-content-center'>
            <div className='width-fll w-1120 mb-5 text-center'>
              <div className="borderTop1 borderTop1s">
                <div className="borderTop px-4">
                  <Link className='leftlink'>
                    <img src={img10} alt="" width="38px" />
                  </Link>
                  <Link className='rightlink'>
                    <img src={img11} alt="" width="38px" />
                  </Link>
                  <img src={img365} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
