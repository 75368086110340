import React, { useState } from "react";
import desktop_6 from "../../../../../images/website-mode/Desktop6.png"
import tab_6 from "../../../../../images/website-mode/Tab6.png"
import mobile_6 from "../../../../../images/website-mode/Mobile6.png"
import desktop_7 from "../../../../../images/website-mode/Desktop7.png"
import tab_7 from "../../../../../images/website-mode/Tab7.png"
import mobile_7 from "../../../../../images/website-mode/Mobile7.png"
import desktop_8 from "../../../../../images/website-mode/Desktop8.png"
import tab_8 from "../../../../../images/website-mode/Tab8.png"
import mobile_8 from "../../../../../images/website-mode/Mobile8.png"
import desktop_9 from "../../../../../images/website-mode/Desktop9.png"
import tab_9 from "../../../../../images/website-mode/Tab9.png"
import mobile_9 from "../../../../../images/website-mode/Mobile9.png"
import desktop_10 from "../../../../../images/website-mode/Desktop10.png"
import tab_10 from "../../../../../images/website-mode/Tab10.png"
import mobile_10 from "../../../../../images/website-mode/Mobile10.png"
import desktop_11 from "../../../../../images/website-mode/Desktop11.png"
import tab_11 from "../../../../../images/website-mode/Tab11.png"
import mobile_11 from "../../../../../images/website-mode/Mobile11.png"
import Modal from 'react-bootstrap/Modal';

import { useTranslation } from "react-i18next";


import '../WeekendModeSectionThemes.scss';
import ButtonBoxNew from "../../../../global/ButtonBoxNew";
export default function ComingSoonSectionRegularTheme() {

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [TabTwoShow, setTabTwoShow] = useState(false);
  const [MobileTwoShow, setMobileTwoShow] = useState(false);
  const [deskShow, setDeskShow] = useState(false);
  const [desktwoShow, setDesktwoShow] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);
  const [TabFourShow, setTabFourShow] = useState(false);
  const [MobileFourShow, setMobileFourShow] = useState(false);
  const [deskFourShow, setdeskFourShow] = useState(false);
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });




  return (
    <div className="">

      <h4 className="text-center">
        Regular  Design Version 1
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_6} alt="deshtop-2"  >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HireMeSectionRegularThemeDesktopView2 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_6} alt="tab-2"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeTabView2 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_6} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView2 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 2
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_7} alt="deshtop-2"  >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HireMeSectionRegularThemeDesktopView2 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_7} alt="tab-2"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeTabView2 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_7} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView2 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>

      <h4 className="text-center">
        Regular  Design Version 3
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_8} alt="deshtop-2"  >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HireMeSectionRegularThemeDesktopView2 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_8} alt="tab-2"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeTabView2 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_8} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView2 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 4
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_9} alt="deshtop-2"  >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HireMeSectionRegularThemeDesktopView2 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_9} alt="tab-2"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeTabView2 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_9} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView2 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 5
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_10} alt="deshtop-2"  >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HireMeSectionRegularThemeDesktopView2 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_10} alt="tab-2"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeTabView2 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_10} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView2 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 6
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_11} alt="deshtop-2"  >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HireMeSectionRegularThemeDesktopView2 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_11} alt="tab-2"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeTabView2 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_11} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView2 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
