import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaBars,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaDribbble,
  FaPinterest,
  FaYoutube,
} from "react-icons/fa";

import book_btn from "../../../../../../images/met-book.png";
import glass_btn from "../../../../../../images/glass-btn.png";
import "../regularTheme1Desktop/RegularTheme1Desktop.scss";
// import "../regularTheme1Desktop/components/howItWorkCards/HowItWorkCards.scss"
// import "../regularTheme1Desktop/components/readyToStartWork/ReadyToStartWork.scss"
// import "../regularTheme1Desktop/components/stepsTimeline/StepsTimeline.scss"
import {
  faCloudArrowDown,
  faInfo,
  faMagnifyingGlass,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import img1 from "../../../../../../images/img1.png";
import social_icon from "../../../../../../images/social-icons.png";
import second_3 from "../../../../../../images/second-3.png";
import dribble_dark from "../../../../../../images/dribble-dark.png";
import message from "../../../../../../images/message.png";
import answer from "../../../../../../images/answers.png";
import rank from "../../../../../../images/rank.png";
import gold_star from "../../../../../../images/gold-star.png";
import load from "../../../../../../images/load.png";
import vedio from "../../../../../../images/video.png";
import fb from "../../../../../../images/fb.png";
import mic_img from "../../../../../../images/mic-img.png";
import insta from "../../../../../../images/insta.png";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import {
  RiDownloadLine,
  RiPlayCircleFill,
  RiCloseLine,
  RiPlayMiniLine,
  RiShareForwardLine,
  RiFullscreenLine,
} from "react-icons/ri";
import sond_right from "../../../../../../images/sond-right.png";
import sond_left from "../../../../../../images/sond-left.png";
import sond_1 from "../../../../../../images/sond-1.png";
import sond_2 from "../../../../../../images/sond-2.png";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import { FaStar } from "react-icons/fa";
import { RiDownloadCloud2Line, RiInstagramFill } from "react-icons/ri";
import message_gold from "../../../../../../images/msg-gold.png";
import rank_gold from "../../../../../../images/rank-gold.png";

import cap_gold from "../../../../../../images/cap-gold.png";
import desktop_m from "../../../../../../images/desktop-m.png";
import Nav from "react-bootstrap/Nav";
import { RxGear, RxMagnifyingGlass } from "react-icons/rx";
import printer_est from "../../../../../../images/pinter-est.png";
import linked_in from "../../../../../../images/linked-in.png";
import you_tube from "../../../../../../images/you-tube.png";
import Social_2 from "../../../../../../images/Social-2.png";
import testimonial from "../../../../../../images/testimonial.png";
import testimonial_glass from "../../../../../../images/testimonial-glass.png";
import timeline_gold from "../../../../../../images/timeline-gold.png";
import navbar_shape from "../../../../../../images/navbar-shape.png";
import Vector_shape from "../../../../../../images/Vector-shape.png";
import circle_img from "../../../../../../images/circle-shape.png";
import dots from "../../../../../../images/Dots.png";
import meo_shape_right from "../../../../../../images/meo-shape-right.png";
import mue_shape from "../../../../../../images/mue-shape.png";
import desktop_img from "../../../../../../images/desktop.png";
import plalist_gold from "../../../../../../images/plalist-gold.png";
import timeline from "../../../../../../images/how-it-work/timeline.png";
import timeline_2 from "../../../../../../images/how-it-work/timeline-2.png";
import timeline_3 from "../../../../../../images/how-it-work/timeline-3.png";
// import HowItWorkCards from "./components/howItWorkCards/HowItWorkCards";
// import ReadyToStartWork from "./components/readyToStartWork/ReadyToStartWork";
// import StepsTimeline from "./components/stepsTimeline/StepsTimeline";

import abc from "../../../../../../images/featured-on/circle/abc.png";
import ben from "../../../../../../images/featured-on/circle/ben.png";
import cbs from "../../../../../../images/featured-on/circle/cbs.png";
import digi from "../../../../../../images/featured-on/circle/digi.png";
import fn from "../../../../../../images/featured-on/circle/fn.png";
import nbc from "../../../../../../images/featured-on/circle/nbc.png";
import usa from "../../../../../../images/featured-on/circle/usa.png";
import above from "../../../../../../images/featured-on/circle/above.png";
import below from "../../../../../../images/featured-on/circle/below.png";
import line from "../../../../../../images/featured-on/circle/line.png";

export default function RegularTheme1Desktop() {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [Competency1, setCompetency1] = useState(10);

  return (
    <>
      <div className="container-2 grey-box container">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-center outer-circle-desktop-1">
            
            <div className="curve-image p-b-20 ">
             <img src={above} alt="" />
            </div>
            {/* <div className="line">
              <img src={line} alt="" />
            </div> */}
            <div className="line-update"></div>

            <div className="ma-20 mb-20 p-20 d-flex justify-content-center">
              <div className="image-circle">
              <span>

   <img src={ben} alt="Image 1" width="80" height="80" />
              </span>
             
              </div>
              <div className="image-circle">
                <span> <img src={digi} alt="Image 2" width="80" height="80" /></span>
              </div>
              <div className="image-circle">
               <span>  <img src={cbs} alt="Image 3" width="80" height="80" /></span>
              </div>
              <div className="image-circle">
              <span>   <img src={nbc} alt="Image 4" width="80" height="80" /></span>
              </div>
              <div className="image-circle image-usa">
               <span>  <img src={usa} alt="Image 5" width="80" height="80" /></span>
              </div>
              <div className="image-circle">
                <div className="image-line"></div>
               <span>  <img src={fn} alt="Image 6" width="80" height="80" /></span>
              </div>
              <div className="image-circle">
             <span>    <img src={abc} alt="Image 7" width="80" height="80" /></span>
              </div>
            </div>
            <div className="curve-image p-b-20 ">
              <img src={below} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
