import React, { memo } from 'react'
import Scale from '../../../images/scale_part.png';

function RangeSelect({data,type,selectedRange,setSelectedRange}) {
  const handleSelect = (range) => {
    setSelectedRange(range)
  }

  return (
    <div className={`d-flex justify-content-between DropdownRange position-relative ${type == 'ages' ? "w-500" : "w-300"}  mx-auto`}>
      <div className="neu_range_select__bg position-absolute w-100"/>
      
      {data.map((item, index) =>(
        <div onClick={() => handleSelect(item)} className="d-flex flex-column items-center z-10 cursor-pointer">
          <div className={`neu_range_select__bar_container d-flex justify-content-between p-[4px] w-[111px] ${selectedRange?.start != item?.start && 'opacity-0'} transition-all duration-100`}>
            <div className="circle"/>
            <div className="circle"/>
          </div>
          <div className="mx-2">
            <img src={Scale} alt="" />
            <div className="d-flex justify-content-between centertab">
              <p className='neu_range_select__number'>{item.start}</p>
              <p className='neu_range_select__number '>{item.end}</p>
            </div>
          </div>
        </div>
      ))}
     

    </div>
    )
}

export default memo(RangeSelect);
