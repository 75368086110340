import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import ButtonBox from '../../global/ButtonBox';
export default function DummyLoginPage() {
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [close, setClose] = useState(false);
    const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated") || false));
    const users = [{ username: "maya", password: "Maya&Usman" }];
    const handleSubmit = (e) => {
        e.preventDefault();
        const account = users.find((user) => user.username === username);
        if (account && account.password === password) {
            setauthenticated(true)
            localStorage.setItem("authenticated", true);
            toast('Successfully Logged in');
            window.location.reload();
        }else{
            toast('You are not allowed to visit this web');
        }
    };

        return (
            <div className="w-100 d-inline-block">
                <form onSubmit={handleSubmit}>
                    <div className="container">
                        <h3 className="mt-lg-4 text-center pb-40">COMING SOON</h3>
                        <div className='d-flex justify-content-center align-items-center'>
                            <input placeholder="username" className="shadow-5in br-10 w-500" type="text" value={username} onChange={(e) => { setUsername(e.target.value); }} />
                        </div>
                        <div className='d-flex justify-content-center align-items-center'>
                            <input placeholder="password" className="shadow-5in br-10 w-500" type="password" value={password} onChange={(e) => { setPassword(e.target.value); }} />
                        </div>


                    </div>


                    <div className='container text-center my-5'>
                        <ButtonBox boxwidth="w-185 mt-0 mb-50" close={close} clicked="Login" name="Login" />
                    </div>
                </form>
            </div>
        );
    }