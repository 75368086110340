import React, { useState } from 'react';
import { ReactComponent as Delete } from "../../../../../images/icons/delete.svg";
import ButtonBox from "../../../../global/ButtonBox";
import { Link } from 'react-router-dom';
import closebtn from "../../../../../images/closebtn.png";
import IntegrationForm from './IntegrationForm';

export default function IntegAddFrom({ addDivProp }) {
  const [divs, setDivs] = useState([]);

  const addNewDiv = () => {
    const newDivs = [...divs, { id: divs.length, content: 'New Div' }];
    setDivs(newDivs);
  };

  const deleteDiv = (idToDelete) => {
    const newDivs = divs.filter((div) => div.id !== idToDelete);
    setDivs(newDivs);
  };

  const handleClick1 = () => {
    setToggle1(!toggle1);
  };
  const [toggle1, setToggle1] = useState("true");

  return (
    <div>
      {divs.map((div, index) => (
        <li key={div.id} className='m-0'>
          <div className="spansec spansec2">
            <div className="dropdiv d-flex align-items-center pb-0">
              <span className="dropicn">
                <span></span>
              </span>
              <input type='text' placeholder='Other? Type here....' className='m-0 h-auto' />
            </div>
          </div>
          <div className='d-flex gap-2 sideBtn ms-4'>
            <div className="droptop px-0" id="dropbtn">
              <button className="br-30 bg shadow-4" type="button" onClick={handleClick1}>
                Connected
              </button>
              {toggle1 ?
                <></>
                :
                <div className="top-65 start-0 end-0 menu position-absolute d-block border-0 bg">
                  <div className='position-relative z-index-2'>
                    <Link
                      className='position-absolute top-0 pe-0 end-0 z-1 me-0'
                      to=""
                      onClick={handleClick1}
                    >
                      <img src={closebtn} width="35" alt="" />
                    </Link>
                    <div className="newdrop10 announcment1s w-100">
                      <IntegrationForm />
                    </div>
                  </div>
                </div>
              }
            </div>
            <button className='br-30 bg-primary shadow-4'>
              Not Connected
            </button>
            <button className='br-30 bg shadow-4'>
              Reconnect
            </button>
            <button className='br-30 bg shadow-4'>
              Disconnect
            </button>
          </div>
          <button className='position-absolute bottom-0 top-0 my-auto custom-end border-0 btn bs-primary' onClick={() => deleteDiv(div.id)}><Delete /></button>
        </li>
      ))}
      <div className='row mt-5 custmToggle'>
              <div className='btmBtn9 d-flex col-lg-4'>
                <button className='shadow-4 border-0'>Add MORE</button>
                <span>
                  <div className="switch">
                    <div className="slider1 round" onClick={addNewDiv}></div>
                  </div>
                </span>
              </div>
              <div className='btnsec d-flex col-lg-8 pt-5 ps-4'>
              <ButtonBox
                boxwidth="w-110 bg-theme mt-0 mb-50 me-5"
                clicked="Cancel"
                name="Cancel"
              />
              <ButtonBox
                boxwidth="w-110  mt-0 mb-50"
                clicked="Saved"
                name="Save"
              />
              </div>
            </div>
    </div> 
  );
}
