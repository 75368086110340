import React from "react";
// import { Link } from 'react-router-dom';
// import IconVid from "../../images/iconVid2.png";

export default function VideoPopup() {

  return (
    <div className="w-100 mb-5 text-center VidIcon d-none">
      <div className='d-flex shadow-4 mx-auto w-970 bg br-30 flex-column justify-content-center h-700 align-items-center position-relative'>
          {/* <img src={IconVid} alt="" className=' w-320 h-auto lightImg'/>
          <img src={IconVid} alt="" className=' w-320 h-auto darkImg'/> */}
          <iframe width="560" height="315" src="https://www.youtube.com/embed/d63PX4cLJZQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          <button className="btn-close VideoIcon mt-4"></button>
      </div>
    </div>
  );
}
