import facebook from '../../images/facebook.png';
import instagram from '../../images/instagram.png';
import twitter from '../../images/twitter.png';
import linked from '../../images/linkedin.png';
import dribble from '../../images/dribble.png';
import pinterest from '../../images/pinterest.png';
import youtube from '../../images/youtube.png';
import { Link } from 'react-router-dom';
import React from 'react';

import { NavItem } from 'react-bootstrap';

export default function SocialBox() {
  return (
    <div className='social-icon'>
      <ul className='d-flex justify-content-center mt-5 flex-wrap'>
        <NavItem as="li" className='shadow-4 rounded-circle p-2 d-flex justify-content-center align-items-center mx-1 w-40'>
          <Link to='#'>
            <img src={facebook} alt="" />
          </Link>
        </NavItem>
        <NavItem as="li" className='shadow-4 rounded-circle p-2 d-flex justify-content-center align-items-center mx-1 w-40'>
          <Link to='#'>
            <img src={instagram} alt="" />
          </Link>
        </NavItem>
        <NavItem as="li" className='shadow-4 rounded-circle p-2 d-flex justify-content-center align-items-center mx-1 w-40'>
          <Link to='#'>
            <img src={twitter} alt="" />
          </Link>
        </NavItem>
        <NavItem as="li" className='shadow-4 rounded-circle p-2 d-flex justify-content-center align-items-center mx-1 w-40'>
          <Link to='#'>
            <img src={linked} alt="" />
          </Link>
        </NavItem>
        <NavItem as="li" className='shadow-4 rounded-circle p-2 d-flex justify-content-center align-items-center mx-1 w-40'>
          <Link to='#'>
            <img src={dribble} alt="" />
          </Link>
        </NavItem>
        <NavItem as="li" className='shadow-4 rounded-circle p-2 d-flex justify-content-center align-items-center mx-1 w-40'>
          <Link to='#'>
            <img src={pinterest} alt="" />
          </Link>
        </NavItem>
        <NavItem as="li" className='shadow-4 rounded-circle p-2 d-flex justify-content-center align-items-center mx-1 w-40'>
          <Link to='#'>
            <img src={youtube} alt="" />
          </Link>
        </NavItem>
      </ul>
    </div>
  )
}
