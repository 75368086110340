import React from "react";
import ButtonBox from "../../../../global/ButtonBox";
import RadioOff from "../../../../global/RadioOff";
import RadioOn from "../../../../global/RadioOn";

import { NavItem } from 'react-bootstrap';
import ButtonBoxNew from "../../../../global/ButtonBoxNew";

export default function SessionsOne() {

  return (
    <div>
      <ul>
        <NavItem as="li" className="py-3 tabBdr">
          <div className="listText">
            <p>This is the list of devices that have logged into your account. Revoke<br/> the sessions that you do not recognize.</p>
            <h5 className="pt-4 pb-3">Device</h5>
          </div>
        </NavItem>
        <NavItem as="li" className="py-3">
          <div className="w-300 listText d-flex flex-column">
          <div className='check-button w-500 mx-auto mb-3 fw-bold d-flex justify-content-start align-items-center'>
                <input type="checkbox" className="btn-check" name="remember" id="remember" />
                <label className="btn btn-outline-primary" htmlFor="remember">
                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                    </svg>
                </label>
                107.189.209.74
            </div>
            <h5 className="mb-3"><b>Chorme:</b> On Macintosh</h5>
            <h5 className="mb-3"><b>Last accessed: </b> Oct 13, 2022</h5>
            <h5 className="mb-3"><b>Signed In: </b> Oct 13, 2022</h5>
          </div>
          <div className="listText d-flex align-items-center justify-content-start">
            {/* <ButtonBox
              boxwidth="w-110 mt-0 mb-4 me-4"
              clicked="Revoked"
              name="Revoke"
            /> */}
            <ButtonBoxNew name="Revoke" boxwidth="w-125" />
            <p>Current Session</p>
          </div>
        </NavItem>
        <NavItem as="li" className="py-4">
          <div className="listText">
            <h5 className="mb-2 fw-bold">Location settings</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora.</p>
          </div>
        </NavItem>
        <NavItem as="li" className="py-3 flex-wrap justify-content-center">
          <div className="listText text-center w-100">
            <h5 className="fw-bold mb-2">
              Location history
            </h5>
            <p>Turn on location history for your mobile devices?</p>
          </div>
          <div className="radioButton d-flex pt-4">
            
            <RadioOn id="stories1" name="stories" />
            <RadioOff id="stories2" name="stories" text="Off" />
          </div>
        </NavItem>



        <NavItem as="li" className=" text-center mt-5 justify-content-center">
          <ButtonBox
            boxwidth="w-140 mt-0 mb-50 mx-auto"
            clicked="Saved"
            name="Save/Next"
          />
        </NavItem>
      </ul>
    </div>
  );
}
