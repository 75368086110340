import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slide1 from "../../../images/banner/twitter1.png"
// import Slide2 from '../../../images/banner/twitter2.png';
// import Slide3 from '../../../images/banner/twitter3.png';
// import Slide4 from '../../../images/banner/twitter4.png';
// import Slide5 from '../../../images/banner/twitter5.png';
// import Slide6 from '../../../images/banner/twitter6.png';
import Slide7 from '../../../images/social/icon45.svg';
import { Link } from 'react-router-dom';
import BannerFirst from "../BannerFirst"
import BannerTwo from "../BannerTwo";
import BannerThree from "../BannerThree";
import BannerFour from "../BannerFour";
import BannerFive from "../BannerFive";
import BannerSix from "../BannerSix";


export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div className="px-4 position-relative">
        <Slider {...settings} className="socialSlider">
          <div>
            <BannerFirst />
          </div>
          <div>
            <BannerTwo />
          </div>
          <div>
            <BannerThree />
          </div>
          <div>
            <BannerFour />
          </div>
          <div>
            <BannerFive />
          </div>
          <div>
            <BannerSix />
          </div>
        </Slider>
        <div className="position-absolute bottom-0 end-0 py-3 px-5">
            <Link to="" className="text-primary downloadLink shadow-4 d-flex bg justify-content-center align-items-center">
            <img src={Slide7} alt="" />
            </Link>
          </div>
      </div>
    );
  }
}