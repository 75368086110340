import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Login from '../components/login/Login'
import Contact from '../components/pages/contact/Contact'
import MarketResearchValidation from '../components/pages/marketResearchValidation/MarketResearchValidation'
import Subscription from '../components/pages/subscription/Subscription'
import Privacy from '../components/pages/privacy/Privacy'
import Profile from '../components/pages/threeHoursBusinessLaunch/Profile'
import Slogan from '../components/pages/threeHoursBusinessLaunch/Slogan'
import DigitalBusinessCard from '../components/pages/threeHoursBusinessLaunch/DigitalBusinessCard'
import Logo from '../components/pages/threeHoursBusinessLaunch/Logo'
import MissionStatement from '../components/pages/threeHoursBusinessLaunch/MissionStatement'
import BrandPositionStatement from '../components/pages/threeHoursBusinessLaunch/BrandPositionStatement'
import CustomDomain from '../components/pages/threeHoursBusinessLaunch/CustomDomain'
import BusinessEmailAddress from '../components/pages/threeHoursBusinessLaunch/BusinessEmailAddress'
import EmailSignature from '../components/pages/threeHoursBusinessLaunch/EmailSignature'
import ElectronicSignature from '../components/pages/threeHoursBusinessLaunch/ElectronicSignature'
import ElevatorPitch from '../components/pages/threeHoursBusinessLaunch/ElevatorPitch'
import TrustPilotBusinessAccount from '../components/pages/threeHoursBusinessLaunch/TrustPilotBusinessAccount'
import WebsiteDesignStyle from '../components/pages/threeHoursBusinessLaunch/WebsiteDesignStyle'
import ProfileManagement from '../components/pages/profileManagement/ProfileManagement'
import ProfileHeroSection from '../components/pages/threeHoursBusinessLaunch/ProfileHeroSection'
import SocialMediaSection from '../components/pages/threeHoursBusinessLaunch/SocialMediaSection'
import HowItWorkSection from '../components/pages/threeHoursBusinessLaunch/HowItWorkSection'
import HireMeSection from '../components/pages/threeHoursBusinessLaunch/HireMeSection'
import SubscriptionSection from '../components/pages/threeHoursBusinessLaunch/SubscriptionSection'
import BlogPostSection from '../components/pages/threeHoursBusinessLaunch/BlogPostSection'
import Podcast from '../components/pages/threeHoursBusinessLaunch/Podcast'
import VideoPlaylist from '../components/pages/threeHoursBusinessLaunch/VideoPlaylist'
import EventSection from '../components/pages/threeHoursBusinessLaunch/EventSection'
import FreebieSection from '../components/pages/threeHoursBusinessLaunch/FreebieSection'
import FeaturedOnSection from '../components/pages/threeHoursBusinessLaunch/FeaturedOnSection'
import TestimonialCaseStudySection from '../components/pages/threeHoursBusinessLaunch/TestimonialCaseStudySection'
import ServicePackagePlan from '../components/pages/threeHoursBusinessLaunch/ServicePackagePlan'
import EMagazineSection from '../components/pages/threeHoursBusinessLaunch/EMagazineSection'
import PressRelease from '../components/pages/threeHoursBusinessLaunch/PressRelease'
import Contest from '../components/pages/threeHoursBusinessLaunch/Contest'
import Portfolio from '../components/pages/threeHoursBusinessLaunch/Portfolio'
import OnlineCourse from '../components/pages/threeHoursBusinessLaunch/OnlineCourse'
import FaqSection from '../components/pages/threeHoursBusinessLaunch/FaqSection'
import SponorshipsSection from '../components/pages/threeHoursBusinessLaunch/SponorshipsSection'
import TeamSection from '../components/pages/threeHoursBusinessLaunch/TeamSection'
import ContactUs from '../components/pages/threeHoursBusinessLaunch/ContactUs'
import ResumeSection from '../components/pages/threeHoursBusinessLaunch/ResumeSection'
import OnlineStoreSection from '../components/pages/threeHoursBusinessLaunch/OnlineStoreSection'
import IntegrationConnection from '../components/pages/threeHoursBusinessLaunch/IntegrationConnection'
import AffiliatePrograms from '../components/pages/threeHoursBusinessLaunch/AffiliatePrograms'
import ExecutiveSummary from '../components/pages/threeHoursBusinessLaunch/ExecutiveSummary'
import WeekendMode from '../components/pages/threeHoursBusinessLaunch/WeekendModeSection'
import BusinessProfileAssets from '../components/pages/threeHoursBusinessLaunch/BusinessProfileAssets'
import MarketResearchSizeValidation from '../components/pages/threeHoursBusinessLaunch/MarketResearchSizeValidation'
import SecureBusinessPhoneNumber from '../components/pages/threeHoursBusinessLaunch/SecureBusinessPhoneNumber'
import PickBusinessAddress from '../components/pages/threeHoursBusinessLaunch/PickBusinessAddress'
import NicheClarityDiscovery from '../components/pages/threeHoursBusinessLaunch/NicheClarityDiscovery'
import DayMarketingPlanner from '../components/pages/threeHoursBusinessLaunch/DayMarketingPlanner'

export default function Pages() {
  return (
    <Routes>
      <Route path="/" element={
        <>
          <Login />
        </>
      }>
      </Route>

      <Route path="/contact" element={
        <>
          <Contact />
        </>
      }>
      </Route>
      <Route path="/market-research-validation" element={
        <>
          <MarketResearchValidation />
        </>
      }>
      </Route>
      <Route path="/subcription" element={
        <>
          <Subscription />
        </>
      }></Route>
      <Route path="/privacy" element={
        <>
          <Privacy />
        </>
      }></Route>

      <Route path="/profileManagement" element={
        <>
          <ProfileManagement />
        </>
      }></Route>
      <Route path="/profile" element={
        <>
          <Profile />
        </>
      }></Route>
      <Route path="/slogan" element={
        <>
          <Slogan />
        </>
      }></Route>
      <Route path="/logo" element={
        <>
          <Logo />
        </>
      }></Route>
      <Route path="/logo" element={
        <>
          <Logo />
        </>
      }></Route>
      <Route path="/mission-statement" element={
        <>
          <MissionStatement />
        </>
      }></Route>
      <Route path="/brand-position-statement" element={
        <>
          <BrandPositionStatement />
        </>
      }></Route>
      <Route path="/niche-clarity-discovery" element={
        <>
          <NicheClarityDiscovery />
        </>
      }></Route>
      <Route path="/day-marketing-planner" element={
        <>
          <DayMarketingPlanner />
        </>
      }></Route>
      <Route path="/market-research-size-validation" element={
        <>
          <MarketResearchSizeValidation />
        </>
      }></Route>
      <Route path="/pick-business-address" element={
        <>
          <PickBusinessAddress />
        </>
      }></Route>
      <Route path="/secure-business-phone-number" element={
        <>
          <SecureBusinessPhoneNumber />
        </>
      }></Route>
      <Route path="/custom-domain" element={
        <>
          <CustomDomain />
        </>
      }></Route>
      <Route path="/business-email-address" element={
        <>
          <BusinessEmailAddress />
        </>
      }></Route>
      <Route path="/email-signature" element={
        <>
          <EmailSignature />
        </>
      }></Route>
      <Route path="/electronic-signature" element={
        <>
          <ElectronicSignature />
        </>
      }></Route>
      <Route path="/elevator-pitch" element={
        <>
          <ElevatorPitch />
        </>
      }></Route>
   
      <Route path="/trust-pilot-business-account" element={
        <>
          <TrustPilotBusinessAccount />
        </>
      }></Route>


      <Route path="/website-design-style" element={
        <>
          <WebsiteDesignStyle />
        </>
      }></Route>
      <Route path="/profile-hero-banner" element={
        <>
          <ProfileHeroSection />
        </>
      }></Route>

      <Route path="/digital-business-card" element={
        <>
          <DigitalBusinessCard />
        </>
      }></Route>
      <Route path="/social-media" element={
        <>
          <SocialMediaSection />
        </>
      }></Route>

      <Route path="/how-it-works" element={
        <>
          <HowItWorkSection />
        </>
      }></Route>
      <Route path="/hire-me" element={
        <>
          <HireMeSection />
        </>
      }></Route>
      <Route path="/testimonial-case-study" element={
        <>
          <TestimonialCaseStudySection />
        </>
      }></Route>
      <Route path="/subscriptions" element={
        <>
          <SubscriptionSection />
        </>
      }></Route>
      <Route path="/blog-posts" element={
        <>
          <BlogPostSection />
        </>
      }></Route>
      <Route path="/podcast" element={
        <>
          <Podcast />
        </>
      }></Route>
      <Route path="/video-playlist" element={
        <>
          <VideoPlaylist />
        </>
      }></Route>
      <Route path="/events" element={
        <>
          <EventSection />
        </>
      }></Route>
      <Route path="/freebies" element={
        <>
          <FreebieSection />
        </>
      }></Route>
      <Route path="/featured-On" element={
        <>
          <FeaturedOnSection />
        </>
      }></Route>

      <Route path="/e-magazine" element={
        <>
          <EMagazineSection />
        </>
      }></Route>


      <Route path="/press-release" element={
        <>
          <PressRelease />
        </>
      }></Route>

      <Route path="/contests" element={
        <>
          <Contest />
        </>
      }></Route>
      <Route path="/portfolio" element={
        <>
          <Portfolio />
        </>
      }></Route>
      <Route path="/online-course" element={
        <>
          <OnlineCourse />
        </>
      }></Route>


      <Route path="/faqs" element={
        <>
          <FaqSection />
        </>
      }></Route>
      <Route path="/sponorships" element={
        <>
          <SponorshipsSection />
        </>
      }></Route>
      <Route path="/meet-our-team" element={
        <>
          <TeamSection />
        </>
      }></Route>
      <Route path="/contact-us" element={
        <>
          <ContactUs />
        </>
      }></Route>

      <Route path="/resume" element={
        <>
          <ResumeSection />
        </>
      }></Route>

<Route path="/online-store" element={
        <>
          <OnlineStoreSection />
        </>
      }></Route>

      <Route path="/service-package-plans" element={
        <>
          <ServicePackagePlan />
        </>
      }></Route>

<Route path="/integrations-connections" element={
        <>
          <IntegrationConnection />
        </>
      }></Route>
      <Route path="/affiliate-programs" element={
        <>
          <AffiliatePrograms />
        </>
      }></Route>
      {/* <Route path="/executive-summary" element={
        <>
           <ExecutiveSummary /> 
        </>
      }></Route> */}
      <Route path="/business-profile-assets" element={
        <>
          <BusinessProfileAssets />
        </>
      }></Route>
      <Route path="/weekend-mode" element={
        <>
          <WeekendMode />
        </>
      }></Route>
    </Routes>

  )
}
