import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaBars, FaFacebookF, FaTwitter, FaLinkedinIn, FaDribbble, FaPinterest, FaYoutube } from "react-icons/fa";
import '../../ServicePackagePlanThemes.scss'
import '../regularTheme2Desktop/RegularTheme2Desktop.scss';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { IoIosArrowForward } from "react-icons/io";
import { FcCheckmark } from "react-icons/fc"
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai"
import { useTranslation } from "react-i18next";
export default function RegularTheme1Desktop() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="">
        <div>
          <div className="main-content p-5">
            <div className="d-flex align-items-center justify-content-center mb-5">
              <div className="toggle_button mb-5">
                <button type="" className="btn1 active_btn">Monthly</button>
                <button type="" className="btn1 ">Yearly</button>
              </div>
            </div>
            <div className="d-flex align-items-center relative">
              <div className="main_row p-4"></div>

              <div className="row px-4 plan_content mx-auto">
                <div className="col-md-4">
                  <div class="plan_outer">
                    <div class="shape_outer">
                      <div class="shape44_1"></div>
                      <div class="shape44"></div>
                      <div id="base">
                        <div class="price">
                          <h2>$29</h2>
                          <p>/Month</p>
                        </div>
                        <div class="pt-3 px-3 plan_heading d-flex align-items-center justify-content-center">
                          <h1>REGULAR</h1>
                          <p>Ipsum dolor sit amet consect</p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 plan_listing">
                      <ul>
                        <li>
                          <FcCheckmark className="icon" />
                          Lorem ipsum dolor</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Sit amet consect</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Amet consectetur</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Neque sed suspend</li>
                        <li>
                          <RxCross2 className="icon text-danger" />
                          Se sed diam mattis</li>
                        <li>
                          <RxCross2 className="icon text-danger" />
                          Ac sagittis ac
                        </li>
                        <li>
                          <RxCross2 className="icon text-danger" />
                          Sitt isporta vestibulum
                        </li>
                        <li>
                          <RxCross2 className="icon text-danger" />
                          eu velit viverra quis vel
                        </li>
                      </ul>
                    </div>
                    <div class="d-flex justify-content-center">
                      <button class="btn" type="button">Order Now</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div class="plan_outer">
                    <div class="shape_outer">
                      <div class="shape44_1"></div>
                      <div class="shape44"></div>
                      <div id="base">
                        <div class="price">
                          <h2>$99</h2>
                          <p>/Mo</p>
                        </div>
                        <div class="pt-3 mt_20 px-3 plan_heading d-flex align-items-center justify-content-center">
                          <h1>ADVANCED</h1>
                          <p>Ipsum dolor sit amet consect</p>
                          <button type="" className="green_btn1 mt-3">Most Popular</button>

                        </div>
                      </div>
                    </div>
                    <div className="mt-5 plan_listing">
                      <ul>
                        <li>
                          <FcCheckmark className="icon" />
                          Lorem ipsum dolor</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Sit amet consect</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Amet consectetur</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Neque sed suspend</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Se sed diam mattis</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Ac sagittis ac
                        </li>
                        <li>
                          <RxCross2 className="icon text-danger" />
                          Sitt isporta vestibulum
                        </li>
                        <li>
                          <RxCross2 className="icon text-danger" />
                          eu velit viverra quis vel
                        </li>
                      </ul>
                    </div>
                    <div class="d-flex justify-content-center">
                      <button class="btn" type="button">Order Now</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div class="plan_outer">
                    <div class="shape_outer">
                      <div class="shape44_1"></div>
                      <div class="shape44"></div>
                      <div id="base">
                        <div class="price">
                          <h2>$199</h2>
                          <p>/Mo</p>
                        </div>
                        <div class="pt-3 px-3 plan_heading d-flex align-items-center justify-content-center">
                          <h1>BUSINESS</h1>
                          <p>Ipsum dolor sit amet consect</p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 plan_listing">
                      <ul>
                        <li>
                          <FcCheckmark className="icon" />
                          Lorem ipsum dolor</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Sit amet consect</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Amet consectetur</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Neque sed suspend</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Se sed diam mattis</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Ac sagittis ac
                        </li>
                        <li>
                          <AiFillStar className="icon text_yellow" />
                          Sitt isporta vestibulum
                        </li>
                        <li>
                          <AiFillStar className="icon text_yellow" />
                          eu velit viverra quis vel
                        </li>
                      </ul>
                    </div>
                    <div class="d-flex justify-content-center">
                      <button class="btn" type="button">Order Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
