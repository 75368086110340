import React from "react";
import '../EmailSignatureStep.scss';
import label from '../../../../../images/img294s.png';
import label1 from '../../../../../images/img31s.png';
import user4 from '../../../../../images/img43s.png';
import { Link } from 'react-router-dom';
// import Select from 'react-select';

import { ReactComponent as IconBlue14 } from "../../../../../images/icons/icon41.svg";
import { ReactComponent as IconBlue15 } from "../../../../../images/icons/icon42.svg";
import { ReactComponent as IconBlue16 } from "../../../../../images/icons/icon43.svg";
import { ReactComponent as IconBlue17 } from "../../../../../images/icons/icon44.svg";
import { ReactComponent as IconBlue1 } from "../../../../../images/icons/icon46.svg";
import { ReactComponent as IconBlue2 } from "../../../../../images/icons/icon54.svg";
import { ReactComponent as IconBlue3 } from "../../../../../images/icons/icon53.svg";
import { ReactComponent as IconBlue4 } from "../../../../../images/icons/icon50.svg";
import { ReactComponent as IconBlue5 } from "../../../../../images/icons/icon49.svg";
import { ReactComponent as IconBlue6 } from "../../../../../images/icons/icon47.svg";
import { ReactComponent as IconBlue7 } from "../../../../../images/icons/icon52.svg";
import { ReactComponent as IconBlue8 } from "../../../../../images/icons/icon51.svg";
import { ReactComponent as IconBlue9 } from "../../../../../images/icons/icon48.svg";
import EmailTemp from "../../../../global/EmailTemp";
import EmailTempBm from "../../../../global/EmailTempBm";

export default function EmailDesign6() {
  return (
    <div className="emailcustom">
      {/* 2nd section */}
      <div className="w-100 shadow-4 d-flex flex-wrap mb-4 px-3 py-2">
        <div className="d-flex w-100 mb-30 flex-wrap">
          <div className="w-100 ps-4 pb-2 pt-30">
            <span className="w-40 bg-dark br-50 d-inline-block"></span>
          </div>
          <div className="col-lg-6">
            <div className="position-relative w-100 shadow-4 h-210 d-inline-block br-100-r bottom-4">
              <img src={user4} alt="" className="h-250 bottom-0 end-50s position-absolute br-100-l"/>
            </div>
          </div>
          <div className="col-lg-6 text-center d-flex flex-column">
            <div className="w-100 mt-7 px-25">
              <div className="w-100x h-70 shadow-4 p-1 mb-3 mx-auto">
                <img src={label1} alt="" />
              </div>
              <div className="shadow-4 px-4 p-10 h8">
                The Best Alternative Lifestyle to the 9 to 5 Grind.
              </div>
            </div>
            <div className="shadow-4 mt-auto h-42 d-flex justify-content-center align-items-center">
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue1 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue2 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue3 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue4 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue5 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue6 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue7 /></Link>
              <Link to="#" className="shadow-4 me-2 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue8 /></Link>
              <Link to="#" className="shadow-4 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue9 /></Link>
            </div>
          </div>
        </div>
        <div className="row w-600s mx-auto">
          <div className="col-md-5 emailfive">
            <h2>Dyna Appolon</h2>
            <h4 className="h8">CEO | Social Network Strategist</h4>
            <ul className="d-flex flex-wrap text-start emaillist p-10 shadow-4 h-70">
              <li className="w-50 pe-3 h-100 d-flex flex-column justify-content-center align-items-start">
                <h4>Industry:</h4>
                <p>Social Network</p>
              </li>
              <li className="w-50 ps-3 h-100 d-flex flex-column justify-content-center align-items-start">
                <h4>Department:</h4>
                <p>Startup</p>
              </li>
            </ul>
          </div>
          <div className="col-md-7 ps-30">
            <div className="px-3 pt-3 text-start">
              <h5 className="py-1 h7 d-flex align-items-center m-0 mb-2"><span className="w-28 br-50 shadow-4 me-2 d-flex justify-content-center align-items-center"><IconBlue15 /></span>1-800-000-00-00</h5>
              <h5 className="py-1 h7 d-flex align-items-center m-0 mb-2"><span className="w-28 br-50 shadow-4 me-2 d-flex justify-content-center align-items-center"><IconBlue17 /></span>dyna@7figurebosses.com</h5>
              <h5 className="py-1 h7 d-flex align-items-center m-0 mb-2"><span className="w-28 br-50 shadow-4 me-2 d-flex justify-content-center align-items-center"><IconBlue16 /></span>9to5escapee.com </h5>
              <h5 className="py-1 h7 d-flex align-items-center m-0"><span className="w-28 me-2 br-50 shadow-4 d-flex justify-content-center align-items-center"><IconBlue14 /></span><span className="w-90e">123 Success Rd, Take Action City, Freedom Country M6Y 10T </span></h5>
            </div>
          </div>
        </div>
        <div className="w-100 my-4 text-center">
          <div className="w-600s shadow-4 text-start p-3 h8 mx-auto">
            <b>Mission Statement:</b> I help people quit their 9 to 5 jobs and launch, manage, and scale up their online business.
          </div>
        </div>
        <div className="br-5 p-2 w-100 position-relative">
            <img src={label} alt="" className="w-100 position-absolute top-0 start-0" />
            <p className="h6 text-center lh-85 mb-0">“I never dreamed about success. I worked for it.” Estee Lauder</p>
        </div>
        <div className="silverClr w-100">
          <EmailTemp />
        </div>
      </div>
      <div className="shadow-4 mb-4 silverClr">
        <EmailTempBm />
      </div>
    </div>
  );
} 
