import React from "react";
import "../regularTheme3Mobile/RegularTheme3Mobile.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai"
import { BsCheckLg } from 'react-icons/bs'
import "slick-carousel/slick/slick-theme.css";
export default function RegularTheme3Mobile() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div>
      <div className="">
        <div>
          <div className="main-content plan_content">

            <div>
              <div className="mobile-help-section text-center relative" >
                <div className="d-flex align-items-center justify-content-center mb-5">
                  <div className="toggle_button">
                    <button type="" className="btn active_btn">Monthly</button>
                    <button type="" className="btn1 ">Yearly</button>
                  </div>
                </div>
                <div className="relative">
                  <div className="content_wrapper">
                    <div className="how-it-work-programs-wrapper ">
                      {/* <div className="programs-flex d-flex justify-content-center"> */}
                      <Slider {...settings} className="">
                        <div className="px-4 pt-4 mt-4">
                          <div class="plan_outer mx-auto mt-5">
                            <div class="top_ribbon_wrapper">
                              <div class="top_ribbon">
                                <span class="top_ribbon_1"><span></span></span>
                                <span class="top_ribbon1"><span>FREE</span></span>
                              </div>
                            </div>
                            <div className="w-100">
                              <div class="price">
                                <h2>$ 00. <sup>00</sup> </h2>
                                <p>Per user <sup>Per Month</sup></p>
                              </div>
                              <div className="mt-4 plan_listing">
                                <ul>
                                  <li>
                                    <div className="icon_outer">
                                      <BsCheckLg className="icon" />
                                    </div>
                                    Lorem ipsum dolor</li>
                                  <li>
                                    <div className="icon_outer">
                                      <BsCheckLg className="icon" />
                                    </div>
                                    Sit amet consect</li>
                                  <li>
                                    <div className="icon_outer">
                                      <BsCheckLg className="icon" />
                                    </div>
                                    Amet consectetur</li>
                                  <li>
                                    <div className="icon_outer">
                                      <RxCross2 className="icon" />
                                    </div>
                                    Se sed diam mattis</li>
                                  <li>
                                    <div className="icon_outer">
                                      <RxCross2 className="icon" />
                                    </div>
                                    Ac sagittis ac
                                  </li>
                                  <li>
                                    <div className="icon_outer">
                                      <RxCross2 className="icon" />
                                    </div>
                                    Sitt isporta vestibulum
                                  </li>
                                </ul>
                              </div>
                              <div class="d-flex justify-content-center">
                                <button class="btn mt-4" type="button">Order Now</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 pt-4">
                          <div class="plan_outer mx-auto">
                            <div class="top_ribbon_wrapper">
                              <div class="top_ribbon">
                                <span class="top_ribbon_1"><span></span></span>
                                <span class="top_ribbon1"><span>Medium</span></span>
                              </div>
                            </div>
                            <div className="w-100">
                              <div class="price">
                                <h2>$ 19. <sup>00</sup> </h2>
                                <p>3 users <sup>Per Month</sup></p>
                              </div>
                              <div className="mt-4 plan_listing">
                                <ul>
                                  <li>
                                    <div className="icon_outer">
                                      <BsCheckLg className="icon" />
                                    </div>
                                    Lorem ipsum dolor</li>
                                  <li>
                                    <div className="icon_outer">
                                      <BsCheckLg className="icon" />
                                    </div>
                                    Sit amet consect</li>
                                  <li>
                                    <div className="icon_outer">
                                      <BsCheckLg className="icon" />
                                    </div>
                                    Amet consectetur</li>
                                  <li>
                                    <div className="icon_outer">
                                      <BsCheckLg className="icon" />
                                    </div>
                                    Se sed diam mattis</li>
                                  <li>
                                    <div className="icon_outer">
                                      <RxCross2 className="icon" />
                                    </div>
                                    Ac sagittis ac
                                  </li>
                                  <li>
                                    <div className="icon_outer">
                                      <RxCross2 className="icon" />
                                    </div>
                                    Sitt isporta vestibulum
                                  </li>
                                </ul>
                              </div>
                              <div class="d-flex justify-content-center">
                                <button class="btn mt-4" type="button">Order Now</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 pt-4 mt-4">
                          <div class="plan_outer mx-auto mt-5">
                            <div class="top_ribbon_wrapper">
                              <div class="top_ribbon">
                                <span class="top_ribbon_1"><span></span></span>
                                <span class="top_ribbon1"><span>Premium</span></span>
                              </div>
                            </div>
                            <div className="w-100">
                              <div class="price">
                                <h2>$ 35. <sup>00</sup> </h2>
                                <p>5 user <sup>Per Month</sup></p>
                              </div>
                              <div className="mt-4 plan_listing">
                                <ul>
                                  <li>
                                    <div className="icon_outer">
                                      <BsCheckLg className="icon" />
                                    </div>
                                    Lorem ipsum dolor</li>
                                  <li>
                                    <div className="icon_outer">
                                      <BsCheckLg className="icon" />
                                    </div>
                                    Sit amet consect</li>
                                  <li>
                                    <div className="icon_outer">
                                      <BsCheckLg className="icon" />
                                    </div>
                                    Amet consectetur</li>
                                  <li>
                                    <div className="icon_outer">
                                      <BsCheckLg className="icon" />
                                    </div>
                                    Se sed diam mattis</li>
                                  <li>
                                    <div className="icon_outer">
                                      <AiFillStar className="icon star_icon" />
                                    </div>
                                    Ac sagittis ac
                                  </li>
                                  <li>
                                    <div className="icon_outer">
                                      <AiFillStar className="icon star_icon" />
                                    </div>
                                    Sitt isporta vestibulum
                                  </li>
                                </ul>
                              </div>
                              <div class="d-flex justify-content-center">
                                <button class="btn mt-4" type="button">Order Now</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
