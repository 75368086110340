import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerFirst from "../BannerFirst"
import BannerTwo from "../BannerTwo";
import BannerThree from "../BannerThree";
import BannerFour from "../BannerFour";
import BannerFive from "../BannerFive";
import BannerSix from "../BannerSix";
import Slide7 from '../../../images/social/icon45.svg';
import { Link } from 'react-router-dom';


export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div className="px-4 position-relative">
        <Slider {...settings} className="socialSlider">
          <div>
            <BannerFirst boxwidth="h-233 banner-small" />
          </div>
          <div>
            <BannerTwo boxwidth="h-233" boxul="zoom-banner2" />
          </div>
          <div>
            <BannerThree boxwidth="h-233" />
          </div>
          <div>
            <BannerFour boxwidth="h-233" />
          </div>
          <div>
            <BannerFive boxwidth="h-233" />
          </div>
          <div>
            <BannerSix boxwidth="h-233 zoomebanner5" />
          </div>
        </Slider>
        <div className="position-absolute bottom-0 end-0 py-3 px-5">
            <Link to="" className="text-primary downloadLink shadow-4 d-flex bg justify-content-center align-items-center">
            <img src={Slide7} alt="" />
            </Link>
          </div>
      </div>
    );
  }
}