import React from "react";
import { useState } from "react";
import "../regularTheme5Mobile/RegularTheme5Mobile.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import timeline from "../../../../../../images/how-it-work/timeline-mobile.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { RxCross2 } from "react-icons/rx";
import { BsCheckLg } from 'react-icons/bs'
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
export default function RegularTheme5Mobile() {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [Competency1, setCompetency1] = useState(10);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div>
      <div className="">
        <div>
          <div className="main-content plan_content">

            <div>
              <div className="mobile-help-section-4 text-center relative" >
                <div className="main_wrapper mx-auto">
                  <div className="plan_outer_wrapper mx-auto">
                    <div className="content_wrapper_outer">
                      <div className="d-flex align-items-center justify-content-center mb-5">
                        <div className="toggle_button">
                          <button type="" className="btn active_btn">Monthly</button>
                          <button type="" className="btn1 ">Yearly</button>
                        </div>
                      </div>
                      <div className="relative">
                        <div className="content_wrapper">
                          <div className="how-it-work-programs-wrapper">
                            {/* <div className="programs-flex d-flex justify-content-center"> */}
                            <Slider {...settings} className="">
                              <div className="p-2 pt_80">
                                <div class="plan_outer">
                                  <div class="plan_content">
                                    <div class="d-flex justify-content-center">
                                      <div class="badge_wrapper bg_green mx-auto">
                                        <div>
                                          <h2>BASIC</h2>
                                          <p>Lorem Ipsum</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="price">
                                      <h1 class="text_green">$10.99</h1>
                                      <p>Per User/Month</p>
                                    </div>
                                    <div>
                                      <ul>
                                        <li>
                                    <div className="icon_outer">
                                          <BsCheckLg className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                          <span className="list_divider"></span>
                                        <li>
                                    <div className="icon_outer">
                                          <BsCheckLg className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                          <span className="list_divider"></span>
                                        <li>
                                    <div className="icon_outer">
                                          <BsCheckLg className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                          <span className="list_divider"></span>
                                        <li>
                                    <div className="icon_outer">
                                          <RxCross2 className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                          <span className="list_divider"></span>
                                        <li>
                                    <div className="icon_outer">
                                          <RxCross2 className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                      </ul>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                      <Link to="#" className="read_more_btn p-2 fs_10">READ MORE</Link>
                                    </div>
                                  </div>
                                  <div class="footer_wrapper">
                                    <div class="btn_wrapper">
                                      <button class="btn1 basic_sign_up_btn">SIGN UP</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-2 pt_80">
                                <div class="plan_outer">
                                  <div class="plan_content">
                                    <div class="d-flex justify-content-center">
                                      <div class="badge_wrapper bg_amber mx-auto">
                                        <div>
                                          <h2>STANDARD</h2>
                                          <p>Lorem Ipsum</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="price">
                                      <h1 class="text_amber">$10.99</h1>
                                      <p>Per User/Month</p>
                                    </div>
                                    <div>
                                      <ul>
                                        <li>
                                    <div className="icon_outer">
                                          <BsCheckLg className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                          <span className="list_divider"></span>
                                        <li>
                                    <div className="icon_outer">
                                          <BsCheckLg className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                          <span className="list_divider"></span>
                                        <li>
                                    <div className="icon_outer">
                                          <BsCheckLg className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                          <span className="list_divider"></span>
                                        <li>
                                    <div className="icon_outer">
                                          <BsCheckLg className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                          <span className="list_divider"></span>
                                        <li>
                                    <div className="icon_outer">
                                          <RxCross2 className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                      </ul>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                      <Link to="#" className="read_more_btn p-2 fs_10">READ MORE</Link>
                                    </div>
                                  </div>
                                  <div class="footer_wrapper">
                                    <div class="btn_wrapper">
                                      <button class="btn1 standard_sign_up_btn">SIGN UP</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-2 pt_80">
                                <div class="plan_outer">
                                  <div class="plan_content">
                                    <div class="d-flex justify-content-center">
                                      <div class="badge_wrapper bg_gray mx-auto">
                                        <div>
                                          <h2>PREMIUM</h2>
                                          <p>Lorem Ipsum</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="price">
                                      <h1 class="text_gray">$10.99</h1>
                                      <p>Per User/Month</p>
                                    </div>
                                    <div>
                                      <ul>
                                        <li>
                                    <div className="icon_outer">
                                          <BsCheckLg className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                          <span className="list_divider"></span>
                                        <li>
                                    <div className="icon_outer">
                                          <BsCheckLg className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                          <span className="list_divider"></span>
                                        <li>
                                    <div className="icon_outer">
                                          <BsCheckLg className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                          <span className="list_divider"></span>
                                        <li>
                                    <div className="icon_outer">
                                          <BsCheckLg className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                          <span className="list_divider"></span>
                                        <li>
                                    <div className="icon_outer">
                                          <RxCross2 className="icon" />
                                          </div>
                                          Lorem Ipsum Dolor Sit</li>
                                      </ul>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                      <Link to="#" className="read_more_btn p-2 fs_10">READ MORE</Link>
                                    </div>
                                  </div>
                                  <div class="footer_wrapper">
                                    <div class="btn_wrapper">
                                      <button class="btn1 premium_sign_up_btn">SIGN UP</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Slider>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
