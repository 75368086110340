import React from "react";
import ButtonBox from "../../../../global/ButtonBox";
import { useState } from "react";
import { NavItem } from 'react-bootstrap';
import { ReactComponent as IconBluew1s } from "../../../../../images/svgIcon/icon23.svg";
import { ReactComponent as IconBluew2s } from "../../../../../images/svgIcon/icon24.svg";
import { useTranslation } from "react-i18next";


export default function ListFavouritesTwo() {
  const { t, i18n } = useTranslation();


const openSelect20 = () =>{inputRef20.current.click();}
const inputRef20 = React.useRef();
const openSelect21 = () =>{inputRef21.current.click();}
const inputRef21 = React.useRef();
const openSelect22 = () =>{inputRef22.current.click();}
const inputRef22 = React.useRef();
const openSelect23 = () =>{inputRef23.current.click();}
const inputRef23 = React.useRef();
const openSelect24 = () =>{inputRef24.current.click();}
const inputRef24 = React.useRef();
const openSelect25 = () =>{inputRef25.current.click();}
const inputRef25 = React.useRef();
const openSelect26 = () =>{inputRef26.current.click();}
const inputRef26 = React.useRef();
const openSelect27 = () =>{inputRef27.current.click();}
const inputRef27 = React.useRef();
const openSelect28 = () =>{inputRef28.current.click();}
const inputRef28 = React.useRef();
const openSelect29 = () =>{inputRef29.current.click();}
const inputRef29 = React.useRef();



const [previewImage20, setPreviewImage20] = useState('');
const [previewImage21, setPreviewImage21] = useState('');
const [previewImage22, setPreviewImage22] = useState('');
const [previewImage23, setPreviewImage23] = useState('');
const [previewImage24, setPreviewImage24] = useState('');
const [previewImage25, setPreviewImage25] = useState('');
const [previewImage26, setPreviewImage26] = useState('');
const [previewImage27, setPreviewImage27] = useState('');
const [previewImage28, setPreviewImage28] = useState('');
const [previewImage29, setPreviewImage29] = useState('');


const [setImage20] = useState('');
const [setImage21] = useState('');
const [setImage22] = useState('');
const [setImage23] = useState('');
const [setImage24] = useState('');
const [setImage25] = useState('');
const [setImage26] = useState('');
const [setImage27] = useState('');
const [setImage28] = useState('');
const [setImage29] = useState('');

  return (
    <div>
      <div className="text-center">
        <h3 className="mb-0 pb-0 fw-bold">{t('profile_section.lets_play_favourites')}</h3>
      </div>
      <div className="row pt-0">
        <label className="mb-2 mt-4 pt-2 h8 w-100">{t('profile_section.favourite_book')} <span className="small d-inline-block">{t('profile_section.seperate_using_comma')}</span></label>
          <div className="col-md-10">
            <input
            placeholder={t('profile_section.The_4_hour_work_week_timothy_ferris')}
            className="shadow-5in br-10 w-100 mb-3"
            type="text"
            />
            <input
              placeholder={t('profile_section.enter_book_link_here')}
              className="shadow-5in br-10 w-100 mb-0"
              type="text"
            />
          </div>
          <div className="custom-form-group col-md-2">
              <div className="custom-file-drop-sec">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect20} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage20 ? (
                          <img
                            src={previewImage20}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew2s />
                            <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage20(url);
                      setImage20(e.target.files[0]);
                    }}
                    ref={inputRef20}
                  />
                </div>
              </div>
          </div>
          <div className="col-md-10">
            <input
            placeholder={t('profile_section.The_4_hour_work_week_timothy_ferris')}
            className="shadow-5in br-10 w-100 mb-3"
            type="text"
            />
            <input
              placeholder={t('profile_section.enter_book_link_here')}
              className="shadow-5in br-10 w-100 mb-0"
              type="text"
            />
          </div>
          <div className="custom-form-group col-md-2">
              <div className="custom-file-drop-sec">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect21} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage21 ? (
                          <img
                            src={previewImage21}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew2s />
                            <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage21(url);
                      setImage21(e.target.files[0]);
                    }}
                    ref={inputRef21}
                  />
                </div>
              </div>
          </div>
          <div className="col-md-10">
            <input
            placeholder={t('profile_section.The_4_hour_work_week_timothy_ferris')}
            className="shadow-5in br-10 w-100 mb-3"
            type="text"
            />
            <input
              placeholder={t('profile_section.enter_book_link_here')}
              className="shadow-5in br-10 w-100 mb-0"
              type="text"
            />
          </div>
          <div className="custom-form-group col-md-2">
              <div className="custom-file-drop-sec">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect22} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage22 ? (
                          <img
                            src={previewImage22}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew2s />
                            <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage22(url);
                      setImage22(e.target.files[0]);
                    }}
                    ref={inputRef22}
                  />
                </div>
              </div>
          </div>
          <div className="col-md-10">
            <input
            placeholder={t('profile_section.The_4_hour_work_week_timothy_ferris')}
            className="shadow-5in br-10 w-100 mb-3"
            type="text"
            />
            <input
              placeholder={t('profile_section.enter_book_link_here')}
              className="shadow-5in br-10 w-100 mb-0"
              type="text"
            />
          </div>
          <div className="custom-form-group col-md-2">
              <div className="custom-file-drop-sec">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect23} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage23 ? (
                          <img
                            src={previewImage23}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew2s />
                            <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage23(url);
                      setImage23(e.target.files[0]);
                    }}
                    ref={inputRef23}
                  />
                </div>
              </div>
          </div>
      </div>
      <div className="pe-4 me-2 w-100">
          <label className="mb-2 mt-4 pt-2 h8">{t('profile_section.sport')} <span className="small d-inline-block">{t('profile_section.seperate_using_comma')}</span></label>
          <input
          placeholder={t('profile_section.tennis_golf')}
          className="shadow-5in br-10 w-100 mb-4"
          type="text"
        />
      </div>
      <div className="row pt-3">
          <div className="col-md-8 d-flex flex-column justify-content-center align-items-center">
            <label className="mb-2 mt-0 pt-0 h8 w-100">{t('profile_section.favourite_writer_author')} <span className="small d-inline-block">{t('profile_section.seperate_using_comma')}</span></label>
            <input
            placeholder=""
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
          </div>
          <div className="custom-form-group col-md-4">
              <div className="custom-file-drop-area">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect24} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage24 ? (
                          <img
                            src={previewImage24}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew2s />
                            <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage24(url);
                      setImage24(e.target.files[0]);
                    }}
                    ref={inputRef24}
                  />
                </div>
              </div>
          </div>
      </div>
      <div className="row pt-3">
          <div className="col-md-8 d-flex flex-column justify-content-center align-items-center">
            <label className="mb-2 mt-0 pt-0 h8 w-100">{t('profile_section.favourite_inspirational_speaker')} <span className="small d-inline-block">{t('profile_section.seperate_using_comma')}</span></label>
            <input
            placeholder=""
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
          </div>
          <div className="custom-form-group col-md-4">
              <div className="custom-file-drop-area">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect25} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage25 ? (
                          <img
                            src={previewImage25}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew2s />
                            <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage25(url);
                      setImage25(e.target.files[0]);
                    }}
                    ref={inputRef25}
                  />
                </div>
              </div>
          </div>
      </div>
      <div className="row pt-3">
          <div className="col-md-8 d-flex flex-column justify-content-center align-items-center">
            <label className="mb-2 mt-0 pt-0 h8 w-100">{t('profile_section.favourite_podcast')} <span className="small d-inline-block">{t('profile_section.seperate_using_comma')}</span></label>
            <div className="row mx-0 w-100">
              <div className="col-md-6 pe-2 ps-0">
                <input
                placeholder={t('profile_section.le_precepteur')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
                />
              </div>
              <div className="col-md-6 pe-0 ps-2">
                <input
                placeholder={t('profile_section.by_charles_robin')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
                />
              </div>
            </div>
            <input
              placeholder={t('profile_section.enter_podcast_link_here')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
          <div className="custom-form-group col-md-4">
            <div className="custom-file-drop-area">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect26} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage26 ? (
                          <img
                            src={previewImage26}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew2s />
                            <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage26(url);
                      setImage26(e.target.files[0]);
                    }}
                    ref={inputRef26}
                  />
                </div>
              </div>
          </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <div className=" me-2 w-100">
              <label className="mb-2 mt-4 pt-2 h8">{t('profile_section.favourite_quote_1')}</label>
              <input
              placeholder={t('profile_section.you_are_your_own_brand_coco_chanel')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className=" me-2 w-100">
              <label className="mb-2 mt-4 pt-2 h8">{t('by')}</label>
              <input
              placeholder={t('profile_section.writer_name')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <div className=" me-2 w-100">
              <label className="mb-2 mt-4 pt-2 h8">{t('profile_section.favourite_quote_2')}</label>
              <input
              placeholder={t('profile_section.you_are_your_own_brand_coco_chanel')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className=" me-2 w-100">
              <label className="mb-2 mt-4 pt-2 h8">{t('by')}y</label>
              <input
              placeholder={t('profile_section.writer_name')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <div className=" me-2 w-100">
              <label className="mb-2 mt-4 pt-2 h8">{t('profile_section.favourite_quote_3')}</label>
              <input
              placeholder={t('profile_section.you_are_your_own_brand_coco_chanel')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className=" me-2 w-100">
              <label className="mb-2 mt-4 pt-2 h8">{t('by')}</label>
              <input
              placeholder={t('profile_section.writer_name')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="pe-0 me-2 w-100">
          <label className="mb-2 mt-4 pt-2 h8">{t('profile_section.favourite_animal')} <span className="small d-inline-block">{t('profile_section.seperate_using_comma')}</span></label>
          <input
          placeholder={t('profile_section.dog_lover')}
          className="shadow-5in br-10 w-100 mb-4"
          type="text"
        />
      </div>
      <hr/>
      <div className="row pt-3">
          <div className="col-md-8 d-flex flex-column justify-content-center align-items-center">
            <label className="mb-2 mt-0 pt-0 h8 w-100">{t('profile_section.favourite_brand_1')} <span className="small d-inline-block">{t('profile_section.brand_affinity_loyalty')}</span></label>
            <input
            placeholder={t('profile_section.my_self')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
          </div>
          <div className="custom-form-group col-md-4">
            <div className="custom-file-drop-area">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect27} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage27 ? (
                          <img
                            src={previewImage27}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew1s />
                            <p className="mb-0 mt-2 lh-sm">{t('profile_section.upload_logo_here')}</p>
                            <p className="mb-0 mt-2 lh-sm">720 X 720 Pixels</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage27(url);
                      setImage27(e.target.files[0]);
                    }}
                    ref={inputRef27}
                  />
                </div>
              </div>
          </div>
      </div>
      <div className="row pt-3">
          <div className="col-md-8 d-flex flex-column justify-content-center align-items-center">
            <label className="mb-2 mt-0 pt-0 h8 w-100">{t('profile_section.favourite_brand_2')} <span className="small d-inline-block">{t('profile_section.brand_affinity_loyalty')}</span></label>
            <input
            placeholder={t('profile_section.chanel')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
          </div>
          <div className="custom-form-group col-md-4">
              <div className="custom-file-drop-area">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect28} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage28 ? (
                          <img
                            src={previewImage28}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew1s />
                            <p className="mb-0 mt-2 lh-sm">{t('profile_section.upload_logo_here')}</p>
                            <p className="mb-0 mt-2 lh-sm">720 X 720 Pixels</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage28(url);
                      setImage28(e.target.files[0]);
                    }}
                    ref={inputRef28}
                  />
                </div>
              </div>
          </div>
      </div>
      <div className="row pt-3">
          <div className="col-md-8 d-flex flex-column justify-content-center align-items-center">
            <label className="mb-2 mt-0 pt-0 h8 w-100">{t('profile_section.favourite_brand_3')} <span className="small d-inline-block">{t('profile_section.brand_affinity_loyalty')}</span></label>
            <input
            placeholder={t('profile_section.tesla')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
          </div>
          <div className="custom-form-group col-md-4">
              <div className="custom-file-drop-area">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect29} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage29 ? (
                          <img
                            src={previewImage29}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew1s />
                            <p className="mb-0 mt-2 lh-sm">{t('profile_section.upload_logo_here')}</p>
                            <p className="mb-0 mt-2 lh-sm">720 X 720 Pixels</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage29(url);
                      setImage29(e.target.files[0]);
                    }}
                    ref={inputRef29}
                  />
                </div>
              </div>
          </div>
      </div>
      <ul>
      <NavItem as="li" className=" text-center mt-5 justify-content-center">
          <ButtonBox
            boxwidth="w-140 mt-0 mb-50 mx-auto"
            clicked="Saved"
            name="Save/Next"
          />
        </NavItem>
      </ul>
    </div>
  );
}
