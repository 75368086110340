import React from 'react';
import './circle-menu.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF, faYoutube, faInstagram, faTwitter, faLinkedin, faDribbble, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

library.add(faBars, faXmark, faFacebookF, faYoutube, faInstagram, faTwitter, faLinkedin, faDribbble, faPinterest);

export default function TextBox(props) {
  return (
    <div className='circleMenu'>
      <div className="menu">
        <input type="checkbox" className='menubar' id="toggle" />
        <label id="show-menu" htmlFor="toggle">
          <div className="btn bs-primary shadow-4">
            <FontAwesomeIcon icon={faBars} className="md-36 toggleBtn menuBtn" />
            <FontAwesomeIcon icon={faXmark} className="md-36 toggleBtn closeBtn" />
          </div>
          <div className="btn bs-primary shadow-4">
            <FontAwesomeIcon icon={faFacebookF} />
          </div>
          <div className="btn bs-primary shadow-4">
          <FontAwesomeIcon icon={faYoutube} />
          </div>
          <div className="btn bs-primary shadow-4">
          <FontAwesomeIcon icon={faInstagram} />
          </div>
          <div className="btn bs-primary shadow-4">
          <FontAwesomeIcon icon={faTwitter} />
          </div>
          <div className="btn bs-primary shadow-4">
          <FontAwesomeIcon icon={faLinkedin} />
          </div>
          <div className="btn bs-primary shadow-4">
          <FontAwesomeIcon icon={faDribbble} />
          </div>
          <div className="btn bs-primary shadow-4">
          <FontAwesomeIcon icon={faPinterest} />
          </div>
        </label>
      </div>
    </div>
  )
}