import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackwardStep, faForwardStep, faExpand, faGear } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img1 from '../../../../../../images/profileview/img24.png';
import img2 from '../../../../../../images/profileview/img25.png';
import img3 from '../../../../../../images/profileview/img26.png';
import img4 from '../../../../../../images/img1.png';
import faShare from '../../../../../../images/icons/faShare.svg';
import faDownload from '../../../../../../images/icons/faDownload.svg';
import faPlay from '../../../../../../images/icons/faPlay.svg';

import RangeSlider from "react-bootstrap-range-slider";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";



export default function FavouriteMusic() {
  const { t, i18n } = useTranslation();

  const [value, setValue] = useState(0);
  return (
    <div className="text-center mb-4">
      <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.favourite_music')}</h4>
      <div className="shadow-4 br-20 py-5 px-4 d-flex justify-content-center align-items-center flex-column">
        <div className="br-10 shadow-4 p-2 w-100">
          <div className="d-flex justify-content-center align-items-end">
            <span className="shadow-4 w-34 mb-2 d-flex justify-content-center align-items-center rounded-circle">
              <Link to="#"><FontAwesomeIcon icon={faBackwardStep} className="h6 m-0" /></Link>
            </span>
            <span className="mx-3 bg-headphone xl-large d-flex justify-content-center align-items-end">
              <img src={img4} alt="" width="80" height="80" className="rounded-circle" />
            </span>
            <span className="shadow-4 w-34 mb-2 d-flex justify-content-center align-items-center rounded-circle">
              <Link to="#"><FontAwesomeIcon icon={faForwardStep} className="h6 m-0" /></Link>
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <ul className="w-100 d-flex justify-content-between align-items-center">
              <NavItem as="li" className="w-100x">
                <RangeSlider
                  value={value}
                  onChange={(changeEvent) =>
                    setValue(changeEvent.target.value)
                  }
                  min={0}
                  max={10}
                />
              </NavItem>
              <NavItem as="li" className="d-flex">
                <Link to="#" className="w-34 p-1 rounded-circle d-flex justify-content-center align-items-center">
                  <span className="bg shadow-4 w-100 h-100 rounded-circle d-flex justify-content-center align-items-center">
                    {/* <FontAwesomeIcon icon={faDownload} className="text-primary h6 m-0" /> */}
                    <img src={faDownload} alt="" />
                  </span>
                </Link>
                <Link to="#" className="w-34 p-1 mx-2 shadow-5in rounded-circle d-flex justify-content-center align-items-center">
                  <span className="bg shadow-4 w-100 h-100 rounded-circle d-flex justify-content-center align-items-center">
                    {/* <FontAwesomeIcon icon={faPlay} className="text-primary h6 m-0" /> */}
                    <img src={faPlay} alt="" />
                  </span>
                </Link>
                <Link to="#" className="w-34 p-1 rounded-circle d-flex justify-content-center align-items-center">
                  <span className="bg shadow-4 w-100 h-100 rounded-circle d-flex justify-content-center align-items-center">
                    {/* <FontAwesomeIcon icon={faShare} className="text-primary h6 m-0" /> */}
                    <img src={faShare} alt="" />
                  </span>
                </Link>
              </NavItem>
              <NavItem as="li" className="">
                <div className="w-60 h-30 shadow-4 br-30 d-flex justify-content-between align-items-center px-2">
                  <Link to="#" className="">
                    <span className="">
                      <FontAwesomeIcon icon={faGear} className="h6 m-0" />
                    </span>
                  </Link>
                  <Link to="#" className="">
                    <span className="">
                      <FontAwesomeIcon icon={faExpand} className="h6 m-0" />
                    </span>
                  </Link>
                </div>
              </NavItem>
            </ul>
          </div>
        </div>
        <ul className="listDivide">
          <NavItem as="li" className="pb-2 pt-3 h8">{t('profile_section.jazz')}</NavItem>
          <NavItem as="li" className="py-2 h8">{t('profile_section.blues')}</NavItem>
          <NavItem as="li" className="py-2 h8">{t('profile_section.soft_rock')}</NavItem>
          <NavItem as="li" className="pt-2 h8 pb-3">{t('profile_section.blue_eyed_soul')}</NavItem>
        </ul>
        <ul className="listDivide1 w-100 d-flex mt-4 justify-content-between align-items-center">
          <NavItem as="li">
            <span className="mx-auto bg-headphone small d-flex justify-content-center align-items-end">
              <img src={img1} alt="" width="52" height="52" className="rounded-circle" />
            </span>
            <h4 className="shadow-5in w-100 bg px-0 py-1 mt-1 h7 fw-bold rounded-1 fw-bold d-inline-block">{t('profile_section.celine_dion')}</h4>
          </NavItem>
          <NavItem as="li">
            <span className="mx-auto bg-headphone small d-flex justify-content-center align-items-end">
              <img src={img2} alt="" width="52" height="52" className="rounded-circle" />
            </span>
            <h4 className="shadow-5in w-100 bg px-0 py-1 mt-1 h7 fw-bold rounded-1 fw-bold d-inline-block">{t('profile_section.michael_bolton')}</h4>
          </NavItem>
          <NavItem as="li">
            <span className="mx-auto bg-headphone small d-flex justify-content-center align-items-end">
              <img src={img3} alt="" width="52" height="52" className="rounded-circle" />
            </span>
            <h4 className="shadow-5in w-100 bg px-0 py-1 mt-1 h7 fw-bold rounded-1 fw-bold d-inline-block">{t('profile_section.garou')}</h4>
          </NavItem>
        </ul>
      </div>
    </div>
  );
}
