import React from "react";
import '../EmailSignatureStep.scss';
import Contest2 from '../../../../../images/img29ss.png';
import user3 from '../../../../../images/img1.png';
import label1 from '../../../../../images/img31s.png';
import lines from '../../../../../images/img40.png';

// import Select from 'react-select';

import { ReactComponent as IconBlue18 } from "../../../../../images/icons/icon54.svg";
import { ReactComponent as IconBlue19 } from "../../../../../images/icons/icon46.svg";
import { ReactComponent as IconBlue20 } from "../../../../../images/icons/icon47.svg";
import { ReactComponent as IconBlue21 } from "../../../../../images/icons/icon48.svg";
import { ReactComponent as IconBlue22 } from "../../../../../images/icons/icon49.svg";
import { ReactComponent as IconBlue23 } from "../../../../../images/icons/icon50.svg";
import { ReactComponent as IconBlue24 } from "../../../../../images/icons/icon51.svg";
import { ReactComponent as IconBlue25 } from "../../../../../images/icons/icon52.svg";
import { ReactComponent as IconBlue26 } from "../../../../../images/icons/icon53.svg";
import { ReactComponent as IconBlue27 } from "../../../../../images/email_icons/icon1.svg";
import { ReactComponent as IconBlue28 } from "../../../../../images/email_icons/icon2.svg";
import { ReactComponent as IconBlue29 } from "../../../../../images/email_icons/icon3.svg";
import { ReactComponent as IconBlue30 } from "../../../../../images/email_icons/icon4.svg";
import { ReactComponent as IconBlue31 } from "../../../../../images/email_icons/icon5.svg";

import EmailTemp from "../../../../global/EmailTemp";
import EmailTempBm from "../../../../global/EmailTempBm";

export default function EmailDesign3() {

  return (
    <div className="w-98 pt-2">
      {/* 3rd section */}
      <div className="w-100 shadow-4 d-flex flex-wrap mb-4 px-3 py-2 thirdsec">
        <div className="d-flex justify-content-between align-items-center w-100">
          <img src={lines} alt="" width="160" height="80" />
          <div className="bg br-5 shadow-4 p-10 w-170 h-120">
            <img src={label1} alt="" className="w-100" />
          </div>
          <img src={lines} alt="" width="160" height="80" />
        </div>
        <div className="w-100 text-center">
          <h4 className="h8 p-10 shadow-4 d-inline-block mx-auto my-3">The Best Alternative Lifestyle to the 9 to 5 Grind.</h4>
        </div>
        <div className="row w-100">
          <div className="col-lg-4 text-center">
            <span><img src={user3} alt="" className="shadow-4 br-50"/></span>
            <h5 className="w-200 h-40s my-3 text-center px-2 pb-2 pt-1 h22 m-0 shadow-4">Dyna Appolon</h5>
            <h6 className="h8">CEO | Social Network Strategist</h6>
            <ul className="emailul2">
              <li><b className="h7">Industry:</b> Social Network</li>
              <li><b className="h7">Department:</b> Startup</li>
            </ul>
          </div>
          <div className="col-lg-8">
            <div className="shadow-5in py-25 w-100">
              <ul className="emailul d-flex flex-wrap px-2 mb-3">
                <li className="w-100 pb-25"><IconBlue27 /><h4 className="ms-3 m-0">1-800-000-00-00</h4></li>
                <li className="w-50"><IconBlue28 className="me-1" /> <h6>9to5escape.com</h6></li>
                <li className="w-50"><IconBlue29 className="me-1" /> <h6>dyna@7figurebosses.com</h6></li>
                <li className="w-50"><IconBlue30 className="me-1" /> <h6>+(148) 537 6040</h6></li>
                <li className="w-50"><IconBlue31 className="me-2" /> <h6>123 Success Rd, Take Action City, Freedom Country M6Y 10T</h6></li>
              </ul>
              <div className="px-4 w-100">
                <div className="shadow-4 bg w-100 p-10">
                  <p className="h8">Mission Statement: I help people quit their 9 to 5 jobs and launch, manage, and scale up their online business.</p>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex emailSocial text-white">
              <a href="" className="w-24 d-flex justify-content-center align-items-center"><IconBlue19 /></a>
              <a href="" className="w-24 d-flex justify-content-center align-items-center"><IconBlue18 /></a>
              <a href="" className="w-24 d-flex justify-content-center align-items-center"><IconBlue26 /></a>
              <a href="" className="w-24 d-flex justify-content-center align-items-center"><IconBlue23 /></a>
              <a href="" className="w-24 d-flex justify-content-center align-items-center"><IconBlue22 /></a>
              <a href="" className="w-24 d-flex justify-content-center align-items-center"><IconBlue20 /></a>
              <a href="" className="w-24 d-flex justify-content-center align-items-center"><IconBlue25 /></a>
              <a href="" className="w-24 d-flex justify-content-center align-items-center"><IconBlue24 /></a>
              <a href="" className="w-24 d-flex justify-content-center align-items-center"><IconBlue21 /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center thirdsec">
        <div className="br-5 p-4 h-85 w-100 position-relative bg shadow-4 d-flex justify-centent-center align-items-center ">
          <img src={Contest2} alt="" className="w-96 position-absolute top-15 start-15" />
          <p className="h9 px-4 text-start lh-normal mb-0">“I never dreamed about success. I worked for it.” Estee Lauder</p>
        </div>
      </div>
      <div className="shadow-4 mb-4 silverClr">
        <EmailTemp />
      </div>
      <div className="shadow-4 mb-4 silverClr">
        <EmailTempBm />
      </div>
    </div>
  );
} 
