import React from "react";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
import { Link } from 'react-router-dom';
import ButtonBox from '../../../global/ButtonBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import FactorOne from "./FactorMulti/FactorOne";
import FactorTwo from "./FactorMulti/FactorTwo";
import { useTranslation } from "react-i18next";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';

library.add( faCaretRight, faCaretLeft, faFloppyDisk );

export default function FactorTab() {
  const { t, i18n } = useTranslation();
  let {isVideoVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };
  return (
    <div>
      <div className="w-780 mb-4 align-items-center mx-auto d-flex flex-column-reverse justify-content-between">     
        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto mb-5">
        <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
          <h3>{t('privacy_component.factor_authentication_component.your_authentication_heading')}</h3>
          <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="factorone" role="tabpanel" aria-labelledby="factorone">
                <FactorOne />
              </div>
              <div className="tab-pane fade" id="factortwo" role="tabpanel" aria-labelledby="factortwo">
                <FactorTwo />
              </div>
          </div>
        </div>
        <span className="d-flex align-items-center justify-content-between w-100 mb-4">
          <div id="myTab1" className='' role="tablist">
            <button className="buttonArrow me-3 text-body border-0 bg br-10 active" id="factorone" data-bs-toggle="tab" data-bs-target="#factorone" type="button" role="tab" aria-controls="factorone" aria-selected="true">
              <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
            </button>
            <button className="buttonArrow me-3 text-body border-0 bg br-10" id="factortwo" data-bs-toggle="tab" data-bs-target="#factortwo" type="button" role="tab" aria-controls="factortwo" aria-selected="false">
              <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
            </button>
          </div>
          <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="Saved" icon={faFloppyDisk} name="Save" />
        </span>
      </div>
      
    </div>
  );
}
