import React from 'react'
export default function HowItWorkCards() {
  return (
    <div>
            <div className="how-it-work-programs-wrapper">
                  <div className="programs-flex d-flex justify-content-center">
                    <div className="how-it-work-program">
                      <h5>COACHING</h5>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Ullamcorper
                        dictum aliquam pulvinar imperdiet. Massa congue eget
                        enim ultricies nec nibh.
                      </p>
                      <div className="how-it-work-program-numbering">
                        <span>1</span>
                      </div>
                    </div>
                    <div className="how-it-work-program">
                      <h5>SELF DEVELOPMENT</h5>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Ullamcorper
                        dictum aliquam pulvinar imperdiet. Massa congue eget
                        enim ultricies nec nibh.
                      </p>
                      <div className="how-it-work-program-numbering">
                        <span>2</span>
                      </div>
                    </div>

                    <div className="how-it-work-program">
                      <h5>LIFE PROGRAMS</h5>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Ullamcorper
                        dictum aliquam pulvinar imperdiet. Massa congue eget
                        enim ultricies nec nibh.
                      </p>
                      <div className="how-it-work-program-numbering">
                        <span>3</span>
                      </div>
                    </div>
                    <div className="how-it-work-program">
                      <h5>SUPPORT</h5>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Ullamcorper
                        dictum aliquam pulvinar imperdiet. Massa congue eget
                        enim ultricies nec nibh.
                      </p>
                      <div className="how-it-work-program-numbering">
                        <span>4</span>
                      </div>
                    </div>
                  </div>
                </div>
    </div>
  )
}
