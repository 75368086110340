import React from "react";
import ButtonBox from "../../../global/ButtonBox";
import Select from "react-select";
import { useTranslation } from "react-i18next";

export default function BrandPositionStatementForm() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });

    const options = [
        {
            value: "is a",
            label: "is a",
        },
        {
            value: "is an",
            label: "is an",
        },
        {
            value: "is the",
            label: "is the",
        },
    ];

    return (
        <div className="pb-5 pt-5">
            <div className="w-100 d-flex flex-wrap justify-content-evenly align-items-center mb-30">
                <label className="mb-1">Who? Target Audience</label>
                <label className="mb-1">Who want or need what?</label>
                <div className="d-flex w-100">
                    <span className="shadow-4 w-70 h-50s br-40  d-flex justify-content-center align-items-center text-center h6">For</span>
                    <input placeholder="people who are tired of working 9 to 5 and seriously ready for real Financial Freedom" className='shadow-5in br-10 h6' type="text"/>
                    <span className="shadow-4 w-70 h-50s br-40  d-flex justify-content-center align-items-center text-center h6">,</span>
                </div>
            </div>
            <div className="w-100 d-flex justify-content-evenly align-items-center mb-30">
                <div className="w-240 d-flex flex-column">
                    <label className="mb-1">Your Brand  | Company</label>
                    <input placeholder="9 to 5 Escapee" className='shadow-5in br-10 h6 mb-0' type="text"/>
                </div>
                <div className="d-flex justify-content-end align-items-end w-100x">
                   
                    <div className=" bg-box position-relative mt-4">
                        <Select isSearchable={false}
                            options={options}
                            className="country customWidth2 w-100x text-center bgArrow"
                            placeholder="Is the"
                            theme={theme}
                            styles={{
                                option: (base) => ({
                                    ...base,
                                    borderRadius: "5px",
                                   width:"80%",
                                   zIndex:"5",
                                    height: "35px",
                                    lineHeight: "20px",
                                    margin: "10px",
                                    color:"#000",
                                    boxShadow: "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                    cursor: 'pointer',
                                }),
                            }}
                        />
                    </div>
                </div>
                <div className=" w-100">
                    <label className="mb-1">Business Niche or Category</label>
                    <input placeholder="leader of the Turnkey Business In-A-Box" className='shadow-5in br-10 h6 mb-0' type="text"/>
                </div>
            </div>
            <div className="w-100 d-flex flex-wrap justify-content-center align-items-center mb-30">
                <label className="mb-1">Point of Difference</label>
                <div className="d-flex w-100">
                    <input placeholder="Automated System that delivers 55 Proven Steps to launch any online businesses within 3 hours" className='shadow-5in br-10 h6' type="text"/>
                </div>
            </div>
            <div className="w-100 d-flex flex-wrap justify-content-center align-items-center mb-30">
                <label className="mb-1">End Benefit</label>
                <div className="d-flex w-100">
                    <span className="shadow-4 w-85 h-50s br-40 d-flex justify-content-center align-items-center text-center h6">, so</span>
                    <input placeholder="people can finally break-free from the rat race and experience real financial freedom" className='shadow-5in br-10 h6' type="text"/>
                </div>
            </div>
            <div className="w-100 d-flex flex-wrap justify-content-center align-items-center mb-30">
                <label className="mb-1">Reason to Believe</label>
                <div className="d-flex w-100">
                    <span className="shadow-4 w-105 h-50s br-40 d-flex justify-content-center align-items-center text-center h6">, because</span>
                    <input placeholder="9 to 5 ESCAPEE takes innovative and efficient approach to business best practices" className='shadow-5in br-10 h6' type="text"/>
                </div>
            </div>
            <div className="w-100 d-flex flex-wrap justify-content-center align-items-center mb-30">
                <label className="mb-1">Problem  your product or service solves to your customers</label>
                <div className="d-flex w-100">
                    <textarea placeholder="fully-loaded with business niche clarity, market research, business plan in order to launch a proven to be Successful, Profitable, Scalable, Sustainable, Passive, and Evergreen online business from the start." className='shadow-5in h-90 br-5 h6' type="text"></textarea>
                </div>
            </div>
            <div className="w-100 mb-30 shadow-4 border-primary border p-4 mb-5 gradient-box">
                <h4 className="h6 fw-bold pt-3 custombdr">Generated Brand Position Statement</h4>
                <p className="h6 fw-normal lh-normal">For people who are tired of working 9 to 5 and seriously ready for real Financial Freedom, 9 to 5 ESCAPEE is the leader of the Turnkey  Business In-A-Box Automated System that delivers 55 Proven Steps to launch any online businesses within 3 hours, so people can finally break-free from the rat race and experience real financial freedom, because 9 to 5 ESCAPEE takes innovative and efficient approach to business best practices fully-loaded with business niche clarity, market research, business plan in order to launch a proven to be Successful, Profitable, Scalable, Sustainable, Passive, and Evergreen online business from the start.</p>
            </div>
            <div className="w-100 d-flex justify-content-center align-items-center">
                <ButtonBox boxwidth="w-400 mt-5 lg text-primary mb-4" clicked="Brand Position Statement Saved" name="Save Generated Brand Position Statement" />
            </div>
        </div>
    );
}
