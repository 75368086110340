import React, { useState } from "react";
import {CommunicationTime} from '../../../../../global/rangeSelect/RangeValues';
import RangeSelect from "../../../../../global/rangeSelect/RangeSelect";
import img1 from '../../../../../../images/profileview/img23.png';
import img2 from '../../../../../../images/profileview/img22.png';
import img3 from '../../../../../../images/profileview/img21.png';
import img4 from '../../../../../../images/profileview/img20.png';
import img5 from '../../../../../../images/profileview/img19.png';
import img6 from '../../../../../../images/profileview/img18.png';
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";



export default function PreferredSec() {
  const { t, i18n } = useTranslation();

  const [time, setTime] = useState(CommunicationTime[Math.floor(CommunicationTime.length/2)])
  let communicationTime = time.start + " to " + time.end;
  return (
    <div className="text-center mb-5">
      <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.preferred_ways_of_communication')}</h4>
      <div className="shadow-4 br-20 pt-4 pb-5 px-4 d-flex justify-content-center align-items-center flex-column">
        <ul className="mb-4 check-button w-100 d-flex justify-content-between align-items-center">
          <NavItem as="li">
            <img src={img1} alt="" className="shadow-4 rounded-circle p-1" />
            <p className="h10 mt-3">{t('profile_section.social')}</p>
            <input type="checkbox" className="btn-check" name="communication" id="Socialp" />
            <label className="btn rounded-circle btnRadio large mx-auto" htmlFor="Socialp"><div className="radioInner"><span></span></div></label>
          </NavItem>
          <NavItem as="li">
            <img src={img2} alt="" className="shadow-4 rounded-circle p-1" />
            <p className="h10 mt-3">{t('profile_section.email')}</p>
            <input type="checkbox" className="btn-check" name="communication" id="Emailp" />
            <label className="btn rounded-circle btnRadio large mx-auto" htmlFor="Emailp"><div className="radioInner"><span></span></div></label>
          </NavItem>
          <NavItem as="li">
            <img src={img3} alt="" className="shadow-4 rounded-circle p-1" />
            <p className="h10 mt-3">{t('profile_section.in_person')}</p>
            <input type="checkbox" className="btn-check" name="communication" id="personp" />
            <label className="btn rounded-circle btnRadio large mx-auto" htmlFor="personp"><div className="radioInner"><span></span></div></label>
            
          </NavItem>
          <NavItem as="li">
            <img src={img4} alt="" className="shadow-4 rounded-circle p-1" />
            <p className="h10 mt-3">{t('profile_section.live_meet')}</p>
            <input type="checkbox" className="btn-check" name="communication" id="meetp" />
            <label className="btn rounded-circle btnRadio large mx-auto" htmlFor="meetp"><div className="radioInner"><span></span></div></label>
          </NavItem>
          <NavItem as="li">
            <img src={img5} alt="" className="shadow-4 rounded-circle p-1" />
            <p className="h10 mt-3">{t('profile_section.phone')}</p>
            <input type="checkbox" className="btn-check" name="communication" id="phonep" />
            <label className="btn rounded-circle btnRadio large mx-auto" htmlFor="phonep"><div className="radioInner"><span></span></div></label>
          </NavItem>
          <NavItem as="li">
            <img src={img6} alt="" className="shadow-4 rounded-circle p-1" />
            <p className="h10 mt-3">{t('profile_section.chat')}</p>
            <input type="checkbox" className="btn-check" name="communication" id="chatp" />
            <label className="btn rounded-circle btnRadio large mx-auto" htmlFor="chatp"><div className="radioInner"><span></span></div></label>
          </NavItem>
        </ul>
        <h4 className="shadow-5in bg mb-3 px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block">{t('profile_section.preferred_days_of_communication')}</h4>
        <ul className="mblbtn mb-4 w-100 d-flex justify-content-between align-items-center">
          <NavItem as="li">
            <div className="shadow-4 h7 h-40 w-125 check-button">
              <input type="checkbox" className="btn-check" name="Preferred" id="Saturdaysp" />
              <label className="btn rounded-circle btnRadio large me-3" htmlFor="Saturdaysp"><div className="radioInner"><span></span></div></label>
              {t('profile_section.saturdays')}
            </div>
          </NavItem>
          <NavItem as="li">
            <div className="shadow-4 h7 h-40 w-125 check-button">
              <input type="checkbox" className="btn-check" name="Preferred" id="Mondaysp" />
              <label className="btn rounded-circle btnRadio large me-3" htmlFor="Mondaysp"><div className="radioInner"><span></span></div></label>
              {t('profile_section.mondays')}
            </div>
          </NavItem>
          <NavItem as="li">
            <div className="shadow-4 h7 h-40 w-125 check-button">
              <input type="checkbox" className="btn-check" name="Preferred" id="Wednesdayp" />
              <label className="btn rounded-circle btnRadio large me-3" htmlFor="Wednesdayp"><div className="radioInner"><span></span></div></label>
              {t('profile_section.wednesdays')}
            </div>
          </NavItem>
        </ul>
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block">
          {t('profile_section.preferred_time_of_communication')}</h4>
        <div className="col-md-12 dropLink small px-0">
            <RangeSelect data={CommunicationTime} type='time' selectedRange={time} setSelectedRange={setTime} value={time} />
        </div>
      </div>
    </div>
  );
}
