import React from 'react'
import TrackingComponentSec from './TrackingComponentSec'
import IntegAddTrackFrom from '../IntegAddTrackFrom';
import iconBluew1 from "../../../../../../images/formicon/icon13.png";
import iconBluew2 from "../../../../../../images/formicon/icon14.png";
import iconBluew3 from "../../../../../../images/formicon/icon15.png";
import icon2 from "../../../../../../images/formicon/user/icon2.png";
import icon3 from "../../../../../../images/formicon/icon40.png";
import icon4 from "../../../../../../images/formicon/icon41.png";
import icon5 from "../../../../../../images/formicon/icon42.png";
import icon6 from "../../../../../../images/formicon/icondot.png";
import TrackingComponent from './TrackingComponent';
export default function TrackingAffiliate() {


  return (
    <div className='px-4'>
      <ul className="customDrop6 customDrop1s max-h-auto customDrop2s w-100 shadow-none position-relative">
            <div className="dropheight h-auto">
              <TrackingComponentSec Name="9 TO 5 ESCAPEE" icon={iconBluew1}  Dropname="9 TO 5 ESCAPEE"/>
              <TrackingComponentSec Name="AMAZON" icon={iconBluew2}  Dropname="AMAZON"/>
              <TrackingComponentSec Name="PRINTFUL" icon={iconBluew3}  Dropname="PRINTFUL"/>
              <TrackingComponentSec Name="WALMART" icon={icon2}  Dropname="WALMART"/>
              <TrackingComponentSec Name="CLICK BANK" icon={icon3}  Dropname="CLICK BANK"/>
              <TrackingComponentSec Name="EBAY" icon={icon4}  Dropname="EBAY"/>
              <TrackingComponentSec Name="ENVATO MARKET" icon={icon5}  Dropname="ENVATO MARKET"/>
              <TrackingComponentSec Name="share-a-sale" icon={icon6}  Dropname="share-a-sale"/>
              <TrackingComponentSec Name="other" icon={icon6}  Dropname="other"/>
              <TrackingComponentSec Name="other" icon={icon6}  Dropname="other"/>
              <TrackingComponentSec Name="other" icon={icon6}  Dropname="other"/>
              <TrackingComponentSec Name="other" icon={icon6}  Dropname="other"/>
              <TrackingComponentSec Name="other" icon={icon6}  Dropname="other"/>
              <TrackingComponent Dropname="Other ?  Type here...." icon={icon6}/>
              <IntegAddTrackFrom />
            </div>
          </ul>
    </div>
  )
}
