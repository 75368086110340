import React from "react";
import ButtonBox from "../../../global/ButtonBox";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownload, faLock, faMicrophone, faPlay } from '@fortawesome/free-solid-svg-icons'
import './DigitalBusinessCardStep.scss';
import { NavItem } from 'react-bootstrap';
import Messenger from '../../../../images/social-icons/messenger.png'
import Blog from '../../../../images/social-icons/blog.png'
import Event from '../../../../images/social-icons/event.png'
import Product from '../../../../images/social-icons/product.png'
import Webinar from '../../../../images/social-icons/webinar.png'
import Course from '../../../../images/social-icons/course.png'
import Surveys from '../../../../images/social-icons/surveys.png'
import Polls from '../../../../images/social-icons/polls.png'
import Contest from '../../../../images/social-icons/contest.png'
import Service from '../../../../images/social-icons/Service.png'
import Paypal from '../../../../images/social-icons/Paypal.png'
import Privacy from '../../../../images/social-icons/Privacy.png'
import donwloadimg from '../../../../images/profileview/icon48.png'
import { Link } from 'react-router-dom';
import EditorDrop1 from "../../../global/EditorDrop1";
import ButtonBoxNew from "../../../global/ButtonBoxNew";
import { ReactComponent as Download } from "../../../../images/icons/icon29.svg";


export default function EmailSignatureForm2() {
  const { t } = useTranslation();
  return (
    <div>
      <ul className="TAB-8_1-form">
        <div className="text-center my-5">
          <h2>{t('timeline_form_seven_component.featureed_content')}</h2>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Messenger} alt="" />
              </div>
            </div>
            <label for="">Wall of fame</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Wall of fame Tetimonials</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  {/* <img src={donwloadimg} alt="" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <FontAwesomeIcon icon={faMicrophone} className=" icon" />
              </div>
            </div>
            <label for="">Podcast Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Podcast Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <FontAwesomeIcon icon={faPlay} className=" icon" />
              </div>
            </div>
            <label for="">Video Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Video Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Blog} alt="" />
              </div>
            </div>
            <label for="">Blog Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Blog Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Event} alt="" />
              </div>
            </div>
            <label for="">Event Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Event Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Contest} alt="" />
              </div>
            </div>
            <label for="">Contest Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Contest Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Product} alt="" />
              </div>
            </div>
            <label for="">Product Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Product Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Webinar} alt="" />
              </div>
            </div>
            <label for="">Webinar Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Webinar Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Course} alt="" />
              </div>
            </div>
            <label for="">Course Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Course Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Surveys} alt="" />
              </div>
            </div>
            <label for="">Surveys</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Surveys Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Polls} alt="" />
              </div>
            </div>
            <label for="">Polls</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Polls Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Service} alt="" />
              </div>
            </div>
            <label for="">Service</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Service</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Paypal} alt="" />
              </div>
            </div>
            <label for="">Paypal</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Paypal Checkout</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Privacy} alt="" />
              </div>
            </div>
            <label for="">Privacy</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Privacy Policy</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4 justify-content-end">
          {/* <button class="btn btn-primary px-2 me-3 all_QR_btn" type="button" id="button-addon1" data-mdb-ripple-color="dark">
            Generate all QR code
          </button> */}
                <ButtonBoxNew  name=" Generate all QR code" boxwidth="w-300 mt-0 mb-4 bg2 br-40" />

        </div>

        <div className="row policy_dropdown">
          <div className="dropFormat mb-2">
            <div className="d-flex justify-content-center">
              <div className="dropdown btnArrow btnArrow2">
                <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-display="static">
                  {/* <Link to="#" id="notab" className="dropdown_btn">
                    <span className="h5">Write | Review Your Privacy  Policy  Clauses</span>
                  </Link> */}
                  <ButtonBoxNew name="Write | Review Your Privacy  Policy  Clauses" id="notab" boxwidth="w-400 br-30 bg h-50s" />
                </span>
                <EditorDrop1 />
              </div>
            </div>
          </div>
        </div>
        <NavItem as="li" className=" text-center time-btn mt-5">

          <ButtonBox
            boxwidth="w-300 mt-0 mb-50 mx-auto"
            clicked="Save/Next"
            name="Save/Next"
          />
        </NavItem>
      </ul>
    </div >
  );
} 
