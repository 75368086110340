import React from 'react';
import './subHeader.scss';
import SubHeading from '../global/SubHeading';
import clipboard from '../../images/clipboard.png';
import Calendar from '../../images/calendar.png';
import Reminder from '../../images/reminder.png';
import Email from '../../images/email.png';
import Waiting from '../../images/waiting.png';
import Meeting from '../../images/meeting.png';
import Event from '../../images/event.png';
import Webinar from '../../images/webinar.png';
import Crm from '../../images/crm.png';
import { NavItem } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
  
export default function SubHeader() {
  let {isSubHeaderVisible} = useSelector(state => state.videoReducer);

 
  return (
<div>
      {isSubHeaderVisible &&
  <div  className="subHeader position-relative">
      <div className='container-xl position-relative px-0 pl-240'>
        <ul className='focusbar'>
          <NavItem as="li"><SubHeading text="TODAY’s AGENDA" src={clipboard} alt="" /></NavItem>
        </ul>
        <div width="100%" direction="left" className="cssmarquee">
          <ul className='d-flex justify-content-center'>
              <SubHeading text="CALENDAR" src={Calendar} alt=""/>
              <SubHeading text="REMINDERS" src={Reminder} alt=""/>
              <SubHeading text="Email" src={Email} alt=""/>
              <SubHeading text="APPOIN-TMENTS" src={Waiting} alt=""/>
              <SubHeading text="MEETINGS" src={Meeting} alt=""/>
              <SubHeading text="events" src={Event} alt=""/>
              <SubHeading text="WEBINAR" src={Webinar} alt=""/>
              <SubHeading text="CONTACTS | CRM" src={Crm} alt=""/>
          </ul>
        </div>
      </div>
    
    </div>
      }



</div>


  )
}
