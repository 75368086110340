import React from "react";
import { useState } from "react";
import "./RegularTheme1Mobile.scss";
import "../regularTheme1Desktop/RegularTheme1Desktop.scss";
import "../regularTheme1Tab/RegularTheme1Tab.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import EventsSlider from "../components/EventsSlider/EventsSlider"
import Calendar from 'react-calendar'
import EventsTimeline from '../components/EventsTimeline/EventsTimeline'


export default function RegularTheme1Mobile({ Regular, Neumorphic }) {
  const [value, onChange] = useState(new Date());

 
  return (
    <>
      <div
        className="main-content-mob eventsMobile EventRegularTheme1Mob"
        style={{ backgroundColor: Regular ? "#cbd5e0" : "" }}
      >
        <div className="text-center-desk">
          <p className="top_heading">MY EVENTS</p>
        </div>

        <div className='container eventTab mb-4 pb-3 mt-5 mt-lg-0'>
          <div id="myTab" className='slidetab' role="tablist">
            <EventsSlider Regular={Regular} Neumorphic={Neumorphic} />
          </div>
        </div>

        <div className="">
          <Calendar className="mx-auto" onChange={onChange} value={value} />
        </div>
        <div>
          <EventsTimeline />
        </div>
      </div>
    </>
  );
}
