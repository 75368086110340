import React, { useState, useEffect } from 'react';

import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';


import { useTranslation } from "react-i18next";



export default function UserIntroductionComponent() {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);











  const [isOpen, setIsOpen] = useState(false);
  
    useEffect(() => {
      if (isOpen) {
        const timer = setTimeout(() => {
          setIsOpen(false);
        }, 5000);
  
        return () => clearTimeout(timer);
      }
    }, [isOpen]);
  


  return (
    <div>



<div className="intro-anner-oter">

<div className="intro-banner">
  
  <h5>
    Hello, I’m
  </h5>
  <h2>
    Dyna Appolon
  </h2>
  <div className="intro-btn">
  {t('timeline_form_seven_component.job_title_placeholder')}
  </div>
</div>
</div>




    </div>



  );
}
