import React from "react";
import { ReactComponent as IconBluew1 } from "../../../../../../images/svgIcon/icon13.svg";
import { ReactComponent as IconBluew2 } from "../../../../../../images/svgIcon/icon14.svg";
import { ReactComponent as IconBluew3 } from "../../../../../../images/svgIcon/icon15.svg";
import { ReactComponent as IconBluew4 } from "../../../../../../images/svgIcon/icon16.svg";
import { ReactComponent as IconBluew5 } from "../../../../../../images/svgIcon/icon17.svg";
import { ReactComponent as IconBluew6 } from "../../../../../../images/svgIcon/icon18.svg";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import ButtonBox from '../../../../../global/ButtonBox';
import ButtonBoxNew from "../../../../../global/ButtonBoxNew";

export default function SocialPage5() {

  const { t, i18n } = useTranslation();

  return (
    <div className="mt-5 pt-5">
      <h3 className="h8 mb-2">{t('profile_section.preview_of_the_generated_bio')}</h3>
      <div className="shadow-4 br-20 p-20 gradient-box">
        <ul className="socialList d-flex justify-content-between mb-40">
          <NavItem as="li">
            <button className="shadow-4 w-100 h7 h-57 br-10 border-0 bg text-body">
              <IconBluew1 />
              {t('profile_section.facebook')}
            </button>
          </NavItem>
          <NavItem as="li">
            <button className="shadow-4 w-100 h7 h-57 br-10 border-0 bg text-body">
            <IconBluew2 />
            {t('profile_section.instagram')}
            </button>
          </NavItem>
          <NavItem as="li">
            <button className="shadow-4 w-100 h7 h-57 br-10 border-0 bg text-body">
            <IconBluew3 />
              {t('profile_section.twitter')}
            </button>
          </NavItem>
          <NavItem as="li">
            <button className="shadow-4 w-100 h7 h-57 br-10 border-0 bg text-body">
            <IconBluew4 />
            {t('profile_section.linked_in')} 
            </button>
          </NavItem>
          <NavItem as="li">
            <button className="shadow-4 w-100 h7 h-57 br-10 border-0 bg text-body">
            <IconBluew5 />
            {t('profile_section.pinterest')}  
            </button>
          </NavItem>
          <NavItem as="li">
            <button className="shadow-4 w-100 h7 h-57 br-10 border-0 bg text-body">
            <IconBluew6 />
            {t('profile_section.youtube')}  
              
            </button>
          </NavItem>
        </ul>
        <div className="op-0">
          <p className="mb-0">Amet maecenas pretium sit eget sed et habitant. Orci ullamcorper ridiculus vehicula enim nam id faucibus.</p>
          <p className="mb-0">😍 Pellentesque sollicitudin cursus viverra platea mauris sit nibh vestibulum enim.</p>
          <p className="mb-0">💅 Pharetra donec nulla arcu tortor.</p>
          <p className="mb-0">🥰 ullamcorper quis bibendum at proin. Phasellus habitasse est lorem eget. Ultrices mauris in tortor suspendisse lobortis maecenas.</p>
          {/* <p className="mb-0">💪 Feugiat at dictum nibh pharetra varius tortor vitae augue.</p> */}
        </div>
        <div className="text-center mt-40">
        {/* <ButtonBox boxwidth="w-140 mt-0 mb-50 mx-auto" clicked="Copied" name={t('profile_section.copy_bio')} /> */}
        <ButtonBoxNew boxwidth="w-140 mx-auto" name={t('profile_section.copy_bio')} />
          {/* <button className="h-50s bs-primary br-5 mx-auto w-200 fw-bold h7 text-uppercase text-center border-0 shadow-4 d-flex align-items-center justify-content-center">{t('profile_section.copy_bio')}</button> */}
        </div>
      </div>
    </div>
  );
}
