import React from 'react';
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function GlobleSlider() {
//   const responsive = {
//     desktop: {
//     breakpoint: { max: 3000, min: 1366 },
//     items: 6,
//     slidesToSlide: 1 // optional, default to 1.
//     },
//     tablet: {
//     breakpoint: { max: 1366, min: 768 },
//     items: 5,
//     slidesToSlide: 1 // optional, default to 1.
//     },
//     mobile: {
//     breakpoint: { max: 700, min: 0 },
//     items: 1,
//     slidesToSlide: 1 // optional, default to 1.
//     }
// };
  return (
    <div className='d-flex justify-content-center integTab5'>
       <div className='py-2 px-4'>
          <button className="br-30 w-150 h-50s border-0 active" id="" data-bs-toggle="tab" data-bs-target="#social08" type="button" role="tab" aria-controls="social08" aria-selected="true">TRACKING</button>
        </div>
        <div className='py-2 px-4'>
          <button className="br-30 w-150 h-50s border-0" id="" data-bs-toggle="tab" data-bs-target="#usermedia08" type="button" role="tab" aria-controls="usermedia08" aria-selected="true">CUSTOM SCRIPTS</button>
        </div>
        <div className='py-2 px-4'>
          <button className="br-30 w-150 h-50s border-0" id="" data-bs-toggle="tab" data-bs-target="#master08" type="button" role="tab" aria-controls="master08" aria-selected="false">LINK REGISTRATION</button>
        </div>
    </div>
  )
}