import React from "react";
import ButtonBox from "../../../global/ButtonBox";
import Select from "react-select";
// import { right } from "@popperjs/core";
import { useTranslation } from "react-i18next";
import "./ElevatorPitch.scss"
import DropdownBox from "../../../global/DropdownBox";
// import Form from 'react-bootstrap/Form';
// import { NavItem } from 'react-bootstrap';
export default function ElevatorPitchForm() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });

    // const options = [
    //     {
    //         value: "a",
    //         label: "a",
    //     },
    //     {
    //         value: "an",
    //         label: "an",
    //     },
    // ];

    const stylesOptions =
    [{
        "name": "a",      
      }, {
        "name": "an",
      }
    ] 

    return (
        <div className="pb-5 pt-2 elevator">
            <h4 className="elevator_heading">{t('timeline_form_thirtyseven_component.generate_your_elevator_pitch_preference')}</h4>
            <div className="row mb-4">
                <div className="col-md-2 px-0">
                    <label className="mb-1 invisible">Company</label>
                    <span className="shadow-4 h-50s br-3  d-flex justify-content-center align-items-center text-center h6">My company</span>
                </div>
                <div className="col-md-6">
                    <label className="mb-1">Company Name</label>
                    <input placeholder="9 to 5 Escapee" className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
                <div className="col-md-2 px-0">
                    <label className="mb-1 invisible">status</label>
                    <span className="shadow-4 h-50s br-3  d-flex justify-content-center align-items-center text-center h6">Is Developing</span>
                </div>
                <div className="col-md-2">
                    <div className=" bg-box position-relative mt-4">
                        {/* <Select isSearchable={false}
                            options={options}
                            className="country customWidth2 w-100x text-center bgArrow"
                            placeholder="an"
                            theme={theme}
                            styles={{
                                option: (base) => ({
                                    ...base,
                                    borderRadius: "5px",
                                    width: "80%",
                                    zIndex: "5",
                                    height: "35px",
                                    lineHeight: "20px",
                                    margin: "10px",
                                    color: "#000",
                                    boxShadow: "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                    cursor: 'pointer',
                                }),
                            }}
                        /> */}
                        <DropdownBox name="an" options={stylesOptions} boxwidth="w-100x newdrop1" />
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-12">
                    <label className="mb-1">
                        {t('timeline_form_thirtyseven_component.define_offering')}
                    </label>
                    <input placeholder={t('timeline_form_thirtyseven_component.define_offering_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-2 px-0">
                    <label className="mb-1 invisible">To help</label>
                    <span className="shadow-4 h-50s br-3  d-flex justify-content-center align-items-center text-center h6">To help</span>
                </div>
                <div className="col-md-10">
                    <label className="mb-1">
                        {t('timeline_form_thirtyseven_component.target_audience_label')}
                    </label>
                    <input placeholder={t('timeline_form_thirtyseven_component.target_audience_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
            </div>
            <div className="w-100 d-flex flex-wrap justify-content-start align-items-center mb-30">
                <label className="mb-1">
                    {t('timeline_form_thirtyseven_component.whats_a_problem')}
                </label>
                <div className="d-flex w-100">
                    <input placeholder={t('timeline_form_thirtyseven_component.whats_a_problem_placeholder')} className='shadow-5in br-5 h6' type="text" />
                </div>
            </div>
            <div className="w-100 d-flex flex-wrap justify-content-start align-items-center mb-30">
                <label className="mb-1">{t('timeline_form_thirtyseven_component.do_what_solution')}</label>
                <div className="d-flex w-100">
                    <textarea placeholder={t('timeline_form_thirtyseven_component.do_what_solution_placeholder')} className='shadow-5in h-90 br-5 h6' type="text"></textarea>
                </div>
            </div>
            <div className="w-100 d-flex flex-wrap justify-content-start align-items-center mb-30">
                <label className="mb-1">{t('timeline_form_thirtyseven_component.secret_sauce')}</label>
                <div className="d-flex w-100">
                    <textarea rows={4} placeholder={t('timeline_form_thirtyseven_component.secret_sauce_placeholder')} className='shadow-5in br-5 h6' type="text"></textarea>
                </div>
            </div>
            <div className="w-100 d-flex flex-wrap justify-content-start align-items-center mb-30">
                <label className="mb-1">{t('timeline_form_thirtyseven_component.call_to_action')}</label>
                <div className="d-flex w-100">
                    <textarea placeholder={t('timeline_form_thirtyseven_component.call_to_action_placeholder')} className='shadow-5in h-90 br-5 h6' type="text"></textarea>
                </div>
            </div>
            <div className="w-100 d-flex flex-wrap justify-content-start align-items-center mb-30">
                <label className="mb-1">{t('timeline_form_thirtyseven_component.generated_elevator_pitch_label')}</label>
                <div className="w-100 mb-30 shadow-4 border-primary border p-4 mb-5">
                    <p className=" fw-normal lh-normal">{t('timeline_form_thirtyseven_component.generated_elevator_pitch')}</p>
                </div>
            </div>
            <div className="w-100 d-flex justify-content-center align-items-center">
                <ButtonBox boxwidth="w-150 lg text-primary mb-4" clicked="Save/Next" name="Save/Next" />
            </div>
        </div>
    );
}
