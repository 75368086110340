import React from "react";
import RadioOff from "../../../../global/RadioOff";
import RadioOn from "../../../../global/RadioOn";
import RangeSelect from '../../../../global/rangeSelect/RangeSelect';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCirclePlus} from '@fortawesome/free-solid-svg-icons';
import {AgeRangePrivacy} from '../../../../global/rangeSelect/RangeValues';
import { useState } from 'react';
import ButtonBox from "../../../../global/ButtonBox";
import { useTranslation } from "react-i18next";

import { NavItem } from 'react-bootstrap';
import ButtonBoxNew from "../../../../global/ButtonBoxNew";

export default function GeneralOne() {
  const { t, i18n } = useTranslation();
  const [selectedRange, setSelectedRange] = useState(AgeRangePrivacy[Math.floor(AgeRangePrivacy.length/2)])
  return (
    <div>
      <ul className="mb-5">
                <NavItem as="li" className="tabBdr">
                  <div className="listText">
                    <h5>{t('privacy_component.privacy_general_component.page_visibility')}</h5>
                    <p>{t('privacy_component.privacy_general_component.page_published')}</p>
                  </div>
                  <div className="radioButton d-flex position-relative customDropDown">
                    {/* <div className="dropdown me-0">
                      <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                      <Link to="#">
                        <FontAwesomeIcon icon={faCaretDown} className="h4 m-0" />
                      </Link>
                      </span>
                      <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                      <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
                        <NavItem as="li" className='px-2'>
                          <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('placeholder_email')} />
                        </NavItem>
                        <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                          <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                            Done
                          </button>
                          <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                             {t('add_more_emails')}
                          </button>
                        </NavItem>
                        <NavItem as="li" className=''><Link className="h8" to="#">                             {t('only_this_person')}
:</Link></NavItem>
                        <NavItem as="li" className='px-2'>
                          <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('placeholder_email')} />
                        </NavItem>
                        <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                          <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                            Done
                          </button>
                          <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                            {t('add_more_emails')}
                          </button>
                        </NavItem>
                      </ul>
                      
                    </div> */}
                    <RadioOn id="page1" name="page" />
                    <RadioOff id="page2" name="page" />
                  </div>
                </NavItem>
                <NavItem as="li" className="tabBdr">
                  <div className="listText">
                    <h5>{t('privacy_component.privacy_general_component.posting_ability')}</h5>
                    <p>{t('privacy_component.privacy_general_component.anyone_can_post_to_my_page_timeline')}</p>
                    <p>{t('privacy_component.privacy_general_component.anyone_can_add_photos_and_videos_to_my_page_timeline')}</p>
                  </div>
                  <div className="radioButton d-flex">
                    <RadioOn id="posting1" name="posting" />
                    <RadioOff id="posting2" name="posting" text="Off" />
                  </div>
                </NavItem>
                <NavItem as="li" className="tabBdr">
                  <div className="listText">
                    <h5>{t('privacy_component.privacy_general_component.post_visibility')}</h5>
                    <p>{t('privacy_component.privacy_general_component.posts_to_page_appear_on_my_page_timeline')}</p>
                  </div>
                  <div className="radioButton d-flex">
                    <RadioOn id="post1" name="post" />
                    <RadioOff id="post2" name="post" />
                  </div>
                </NavItem>
                <NavItem as="li" className="tabBdr">
                  <div className="listText">
                    <h5>{t('privacy_component.privacy_general_component.post_targeting_and_privacy')}</h5>
                    <p>{t('privacy_control_for_posts_is_turned_off')}</p>
                  </div>
                  <div className="radioButton d-flex">
                    <RadioOn id="targeting1" name="targeting" />
                    <RadioOff
                      id="targeting2"
                      name="1argeting"
                    />
                  </div>
                </NavItem>
                <NavItem as="li" className="tabBdr">
                  <div className="listText">
                    <h5>{t('privacy_component.privacy_general_component.messages')}</h5>
                    <p>{t('privacy_component.privacy_general_component.people_can_contact_my_page_privately')}</p>
                  </div>
                  <div className="radioButton d-flex">
                    <RadioOn id="messages1" name="messages" />
                    <RadioOff
                      id="messages2"
                      name="messages"
                    />
                  </div>
                </NavItem>
                <NavItem as="li" className="change tabBdr">
                  <div className="listText">
                    <h5>{t('privacy_component.privacy_general_component.tagging_ability')}</h5>
                      <div className='check-button w-500 mx-auto mt-2 d-flex justify-content-start align-items-center'>
                          <input type="checkbox" className="btn-check" name="remember" id="remember7"  />
                          <label className="btn btn-outline-primary" htmlFor="remember7">
                              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                              </svg>
                          </label>
                        {t('privacy_component.privacy_general_component.allow_others_to_tag_photos_posted_by_dyna')}
                      </div>
                  </div>
                  <div className="d-flex">
                    {/* <button className="h-50 bg-btn br-30 text-body w-168 bg text-center border-0 shadow-4 me-3">{t('privacy_component.privacy_general_component.save_change')}</button> */}
                    {/* <button className="h-50 bg-btn br-30 text-body w-105 text-center bg border-0 shadow-4">{t('privacy_component.privacy_general_component.cancel')}</button> */}
                    <ButtonBoxNew name="Save Change" boxwidth="w-168 me-4" />
                    <ButtonBoxNew name="Cancel" boxwidth="w-125" />
                  </div>
                </NavItem>
                <NavItem as="li" className="tabBdr">
                  <div className="listText">
                    <h5>{t('privacy_component.privacy_general_component.country_restrictions')}</h5>
                    <p>{t('privacy_component.privacy_general_component.page_is_visible_to_everyone')}</p>
                  </div>
                  <div className="radioButton d-flex">
                    {/* <div className="dropdown me-0">
                        <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                        <Link to="#">
                          <FontAwesomeIcon icon={faCaretDown} className="h4 m-0" />
                        </Link>
                        </span>
                        <ul className="dropdown-menu dropdown-menu-end dropList bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">

                          <NavItem as="li" className=''><Link className="h8" to="#">{t('privacy_component.privacy_general_component.afghanistan')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('privacy_component.privacy_general_component.albania')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('privacy_component.privacy_general_component.algeria')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
                        </ul>
                      </div> */}
                    <RadioOn id="country3" name="country1" />
                    <RadioOff id="country4" name="country1" />
                  </div>
                </NavItem>
                <NavItem as="li" className="tabBdr">
                  <div className="listText">
                    <h5>{t('privacy_component.privacy_general_component.age_restrictions')}</h5>
                    <p>{t('privacy_component.privacy_general_component.page_is_shown_to_everyone')}</p>
                  </div>
                  <div className="radioButton d-flex">
                  {/* <div className="dropdown me-0">
                        <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                        <Link to="#">
                          <FontAwesomeIcon icon={faCaretDown} className="h4 m-0" />
                        </Link>
                        </span>
                        <ul className="dropdown-menu dropdown-menu-end dropLink w-340 bg shadow-4 border-0 br-20" aria-labelledby="dropdownMenuLink">
                          <NavItem as="li" className=''>
                            <div>
                                  <p className="text-center neu_range_select__title">{t('privacy_component.privacy_general_component.select_your_age_range')}</p>
                                  <RangeSelect data={AgeRangePrivacy} type='ages' selectedRange={selectedRange} setSelectedRange={setSelectedRange} value={selectedRange} />
                              </div>
                          </NavItem>
                        </ul>
                      </div> */}
                    <RadioOn id="age1" name="age" />
                    <RadioOff id="age2" name="age" />
                  </div>
                </NavItem>
                <NavItem as="li" className="tabBdr">
                  <div className="listText">
                    <h5>{t('privacy_component.privacy_general_component.profanity_filter')}</h5>
                    <p>{t('privacy_component.privacy_general_component.turned_off')}</p>
                  </div>
                  <div className="radioButton d-flex">
                  {/* <div className="dropdown me-0">
                      <span role="button" className="dropArrow" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                      <Link to="#">
                        <FontAwesomeIcon icon={faCaretDown} className="h4 m-0" />
                      </Link>
                      </span>
                      <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" aria-labelledby="dropdownMenuLink">
                      <NavItem as="li" className='h8 mb-3'>{t('tunacceptable_words')}<small className="d-inline-block">{t('seperate_using_comma')}</small></NavItem>
                        <NavItem as="li" className='px-2'>
                          <textarea className="shadow-5in br-20 mb-3 me-2 w-300" placeholder={t('paceolder_word')}> </textarea>
                        </NavItem>
                        <NavItem as="li" className="px-4 d-flex align-items-center justify-content-center">
                          <button className="h-50 bs-success br-30 w-auto px-4 h8 text-center border-0 shadow-4 me-2">
                          {t('timeline_one_component.filter_hide_words')}
                          </button>
                        </NavItem>
                      </ul>
                    </div> */}
                    <RadioOn id="profanity1" name="profanity" />
                    <RadioOff id="profanity2" name="profanity" />
                  </div>
                </NavItem>
                <NavItem as="li" className="tabBdr">
                  <div className="listText">
                    <h5>{t('privacy_component.privacy_general_component.similar_page_suggestions')}</h5>
                    <p>{t('privacy_component.privacy_general_component.choose_whether_your_page_is_recommended_to_others')}</p>
                  </div>
                  <div className="radioButton d-flex">
                    <RadioOn id="similar1" name="similar" />
                    <RadioOff id="similar2" name="similar" />
                  </div>
                </NavItem>
                <NavItem as="li" className="py-3">
                  <div className="listText">
                    <h5>{t('privacy_component.privacy_general_component.replies')}</h5>
                    <p>{t('privacy_component.privacy_general_component.comment_replies_are_turned_on_for_your_Page')}</p>
                  </div>
                  <div className="radioButton d-flex">
                    <RadioOn id="replies1" name="replies" />
                    <RadioOff id="replies2" name="replies" />
                  </div>
                </NavItem>
                <NavItem as="li" className=" text-center justify-content-center">
                  <ButtonBox
                    boxwidth="w-140 mt-5 mb-50 mx-auto"
                    clicked="Saved"
                    name="Save/Next"
                  />
                </NavItem>
              </ul>
    </div>
  );
}
