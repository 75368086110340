import React from "react";
import timeline from "../../../../../../../../images/how-it-work/timeline.png";
import timeline_2 from "../../../../../../../../images/how-it-work/timeline-2.png";
import timeline_3 from "../../../../../../../../images/how-it-work/timeline-3.png";

import bar_one from "../../../../../../../../images/how-it-work/bar_one.png";

export default function StepsTimeline() {
  const stepsArray = [
    {
      step_no: 1,
      title: "HOW To Get Shapes Of Your Dream",
      content: "I teach you how to become slim and get the body of your dreams",
    },
    {
      step_no: 2,
      title: "Step-By-Step Training GuideStep 3",
      content: "I teach you how to become slim and get the body of your dreams",
    },
    {
      step_no: 3,
      title: "Explosive Tabata For Maximum Results",
      content: "I teach you how to become slim and get the body of your dreams",
    },
    {
      step_no: 4,
      title: "Free Advising",
      content: "I teach you how to become slim and get the body of your dreams",
    },
  ];
  return (
    <div className="diet-programs-right-section">
      <div className="diet-programs-right-section-inner">
      {stepsArray.map((step) => (
          <div className="main-row-tab">
            <img src={bar_one} alt="" srcset="" style={{ zIndex: "1" }} />
            <div className="counter-div-tab">
              <span>{step.step_no}</span>
            </div>
            <div className="description-div-tab">
              <span>{step.title}</span>
              <p>
                {step.content}
              </p>
            </div>
          </div>
        ))}


      </div>
    </div>
  );
}
