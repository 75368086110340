import React from "react";
import '../EmailSignatureStep.scss';
import Contest from '../../../../../images/img29.png';
import brcode from '../../../../../images/img30.png';
import user from '../../../../../images/img32.png';
import label from '../../../../../images/img31.png';

// import Select from 'react-select';
import { ReactComponent as IconBlue1 } from "../../../../../images/social-icons/fb.svg";
import { ReactComponent as IconBlue2 } from "../../../../../images/social-icons/instagram.svg";
import { ReactComponent as IconBlue3 } from "../../../../../images/social-icons/twitter.svg";
import { ReactComponent as IconBlue4 } from "../../../../../images/social-icons/linkedin.svg";
import { ReactComponent as IconBlue5 } from "../../../../../images/social-icons/Group.svg";
import { ReactComponent as IconBlue6 } from "../../../../../images/social-icons/dribble.svg";
import { ReactComponent as IconBlue7 } from "../../../../../images/social-icons/tiktok.svg";
import { ReactComponent as IconBlue8 } from "../../../../../images/social-icons/pinterest.svg";
import { ReactComponent as IconBlue9 } from "../../../../../images/social-icons/youtube.svg";
import { ReactComponent as IconBlue10 } from "../../../../../images/social-icons/phonegold.svg";
import { ReactComponent as IconBlue11 } from "../../../../../images/social-icons/mailgold.svg";
import { ReactComponent as IconBlue12 } from "../../../../../images/social-icons/linkgold.svg";
import { ReactComponent as IconBlue13 } from "../../../../../images/social-icons/locationgold.svg";

import EmailTemp from "../../../../global/EmailTemp";
import EmailTempBm from "../../../../global/EmailTempBm";

export default function EmailDesign1() {

  return (
    <div className="emailcustom">
      <div className="w-100 shadow-4 d-flex h-450 flex-wrap pb-5">
        <div className="w-50s bg-dark h-100 d-flex justify-content-center align-items-center flex-column">
          <span className="text-darks mb-12 bg-dark w-24 d-flex justify-content-center align-items-center shadow-5in-dark rounded-circle bg-dark"><IconBlue1 /></span>
          <span className="text-darks mb-12 bg-dark w-24 d-flex justify-content-center align-items-center shadow-5in-dark rounded-circle bg-dark"><IconBlue2 /></span>
          <span className="text-darks mb-12 bg-dark w-24 d-flex justify-content-center align-items-center shadow-5in-dark rounded-circle bg-dark"><IconBlue3 /></span>
          <span className="text-darks mb-12 bg-dark w-24 d-flex justify-content-center align-items-center shadow-5in-dark rounded-circle bg-dark"><IconBlue4 /></span>
          <span className="text-darks mb-12 bg-dark w-24 d-flex justify-content-center align-items-center shadow-5in-dark rounded-circle bg-dark"><IconBlue5 /></span>
          <span className="text-darks mb-12 bg-dark w-24 d-flex justify-content-center align-items-center shadow-5in-dark rounded-circle bg-dark"><IconBlue6 /></span>
          <span className="text-darks mb-12 bg-dark w-24 d-flex justify-content-center align-items-center shadow-5in-dark rounded-circle bg-dark"><IconBlue7 /></span>
          <span className="text-darks mb-12 bg-dark w-24 d-flex justify-content-center align-items-center shadow-5in-dark rounded-circle bg-dark"><IconBlue8 /></span>
          <span className="text-darks mb-12 bg-dark w-24 d-flex justify-content-center align-items-center shadow-5in-dark rounded-circle bg-dark"><IconBlue9 /></span>

        </div>
        <div className="w-300 h-100 d-flex flex-wrap">
          <div className="w-150 h-230 d-block p-5s bt-primary">
            <img src={user} alt="" />
          </div>
          <div className="w-150 h-230 d-flex justify-content-center align-items-center">
            <img src={brcode} alt="" width="110px" height="110px" className="br-10 shadow-4" />
          </div>
          <div className="socialText shadow-4 bg br-10 ms-auto me-2">
            <h3 className="mb-2 pb-1">Dyna Appolon</h3>
            <button className="bt-primary h11s mb-2 p-2 border-0 br-3 shadow-4 text-uppercase">CEO | Social Network Strategist</button>
            <h4 className="py-2 d-block m-0">The Best Alternative Lifestyle to the 9 to 5 Grind.</h4>
            <div className="linesap bg"></div>
            <h4 className="py-2 d-block m-0"><b>Industry:</b>Social Network</h4>
            <div className="linesap bg"></div>
            <h4 className="py-2 d-block m-0"><b>Department:</b>Startup</h4>
          </div>
        </div>
        <div className="ps-3 h-100 bt-primary shadow-4"></div>
        <div className="socailnet px-4 py-3 bg h-100 text-center">
          <img src={label} alt="" className="mx-auto" />
          <div className="px-3 pt-3 text-end">
            <h5 className="py-1 d-block m-0">1-800-000-00-00 <IconBlue10 className="ms-2 shadow-4 br-50" /></h5>
            <div className="linesap bg mb-1"></div>
            <h5 className="py-1 d-block m-0">dyna@7figurebosses.com <IconBlue11 className="ms-2 shadow-4 br-50" /></h5>
            <div className="linesap bg mb-1"></div>
            <h5 className="py-1 d-block m-0">9to5escapee.com <IconBlue12 className="ms-2 shadow-4 br-50" /></h5>
            <div className="linesap bg mb-1"></div>
            <h5 className="py-1 d-flex justify-content-end m-0">123 Success Rd, Take Action<br /> City, Freedom Country M6Y 10T <IconBlue13 className="ms-2 shadow-4 br-50" /></h5>
          </div>
        </div>
      </div>
      <div className="btmtxt bt-primary mx-auto mb-3 shadow-4 z-10 position-relative">
        <p><b>Mission Statement:</b> I help people quit their 9 to 5 jobs and launch, manage, and scale up their online business.</p>
      </div>
      <div className="w-100 mb-4">
        <div className="d-flex justify-centent-end align-items-center py-3 px-4 bt-primary">
          <div className="br-5 p-2 w-100 position-relative">
            <img src={Contest} alt="" className="w-100 position-absolute top-0 start-0" />
            <p className="h9 text-center lh-85 mb-0">“I never dreamed about success. I worked for it.” <b>Estee Lauder</b></p>
          </div>
        </div>
        <div className="shadow-4 mb-4 yellowClr">
          <EmailTemp />
        </div>
        <div className="shadow-4 mb-4 yellowClr">
          <EmailTempBm />
        </div>
      </div>
    </div>
  );
} 
