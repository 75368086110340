import React from 'react'
import IntegAddFrom from '../IntegAddFrom';
import icon from "../../../../../../images/formicon/user/icon.png";
import icon1 from "../../../../../../images/formicon/user/icon1.png";
import icon2 from "../../../../../../images/formicon/user/icon2.png";
import icon3 from "../../../../../../images/formicon/user/icon3.png";
import icon4 from "../../../../../../images/formicon/user/icon4.png";
import icon5 from "../../../../../../images/formicon/user/icon5.png";
import icon6 from "../../../../../../images/formicon/user/icon6.png";
import icon6s from "../../../../../../images/formicon/user/icon6s.png";
import icon7 from "../../../../../../images/formicon/user/icon7.png";
import icon8 from "../../../../../../images/formicon/user/icon8.png";
import icon8s from "../../../../../../images/formicon/user/icon8s.png";
import icon9 from "../../../../../../images/formicon/user/icon9.png";
import icon10 from "../../../../../../images/formicon/user/icon10.png";
import icon11 from "../../../../../../images/formicon/user/icon11.png";
import icon38 from "../../../../../../images/formicon/icon38.png";
import ConnectComponent from './ConnectComponent';
import ConnectComponentInput from './ConnectComponentInput';

export default function UserMedia() {
  return (
    <div>
      <div className='row'>
        <div className='col-lg-12'>
          <ul className="customDrop6 customDrop1s max-h-auto customDrop2s w-100 shadow-none position-relative">
            <div className="dropheight h-auto">
              <ConnectComponent Name="PROFESSIONAL WEBSITE" icon={icon} />
              <ConnectComponent Name="1000 SOCIALITE | WEBSITE" icon={icon1} />
              <ConnectComponent Name="NICHE BLOG NETWORK | SITE" icon={icon2} />
              <ConnectComponent Name="9 TO 5 ESCAPEE | WEBSITE" icon={icon3} />
              <ConnectComponent Name="7 FIGURE BOSSES | WEBSITE" icon={icon38} />
              <ConnectComponent Name="VIDEO CHANNEL | WEBSITE" icon={icon4} />
              <ConnectComponent Name="PODCAST | WEBSITE" icon={icon5} />
              <ConnectComponent Name="EVENTS | WEBSITE" icon={icon6} />
              <ConnectComponent Name="CONTESTS | WEBSITE" icon={icon6s} />
              <ConnectComponent Name="MARKETPLACE | WEBSITE" icon={icon7} />
              <ConnectComponent Name="E-MAGAZINES | WEBSITE" icon={icon8} />
              <ConnectComponent Name="PRESS RELEASE | WEBSITE" icon={icon8s} />
              <ConnectComponent Name="GUEST POST | WEBSITE" icon={icon9} />
              <ConnectComponent Name="LMS COURSE | WEBSITE" icon={icon10} />
              <ConnectComponent Name="AFFILIATE  | WEBSITE" icon={icon11} />
              <ConnectComponent Name="OTHER  | WEBSITE" />
              <ConnectComponent Name="OTHER  | WEBSITE" />
              <ConnectComponentInput Name="Other ?  Type here...."/>
              <IntegAddFrom />
            </div>
          </ul>
        </div>
      </div>
    </div>
  )
}

