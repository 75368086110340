import React from "react";

import { useTranslation } from "react-i18next";
import './LogoStep.scss';

import LogoForm1 from './LogoForm1'
import LogoForm2 from './LogoForm2'
import { NavItem } from 'react-bootstrap';
import LogoStepEditor from "./LogoStepEditor";



export default function LogoForm({setStep3Images}) {

  const { t, i18n } = useTranslation();

  

  


  return (
    <div className="three-tab-stle three-tab-logo-stle">
  
      <h2 className="text-center h2-color">
        {t('choose_your_logo_component.your_logos')}
      </h2>
      <p className="text-center h2-color">     {t('choose_your_logo_component.pick_one')}</p>
      <div className="d-flex flex-column-reverse">
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane listOne fade show active" id="three-tab-1" role="tabpanel" aria-labelledby="three-tab-1">
         <LogoForm1 />

                </div>
                <div className="tab-pane listTwo fade" id="three-tab-2" role="tabpanel" aria-labelledby="three-tab-2">
        <LogoForm2 setStep3Images={setStep3Images} />
                </div>
                <div className="tab-pane listTwo fade logo-fix-width w-100" id="three-tab-3" role="tabpanel" aria-labelledby="three-tab-3">
                <LogoStepEditor />
                </div>
            </div>
            <span className="d-flex align-items-center justify-content-between w-100 mb-0 TabBtn">
                <div id="myTab1" className='w-100' role="tablist">
                  <ul className="d-flex justify-content-center my-4 pb-2">
                    <NavItem as="li" className="me-0">
                      <button className="listOne m-0 p-0 firsttab border-0 active" id="three-tab-1" data-bs-toggle="tab" data-bs-target="#three-tab-1" type="button" role="tab" aria-controls="three-tab-1" aria-selected="true">
                        <span>1</span>
                      </button>
                    </NavItem>
                    <NavItem as="li" className="margin-left-scnd">
                      <button className="listTwo m-0 p-0 border-0 sectab " id="three-tab-2" data-bs-toggle="tab" data-bs-target="#three-tab-2" type="button" role="tab" aria-controls="three-tab-2" aria-selected="false">
                        <span>2</span>
                      </button>
                    </NavItem>
                    <NavItem as="li" className="margin-left-scnd">
                    {/* <Link to="/threeHoursBusinessLaunchStepLogoEditor" > */}

                      <button className="listTwo m-0 p-0 border-0 thrdtab" id="three-tab-3" data-bs-toggle="tab" data-bs-target="#three-tab-3" type="button" role="tab" aria-controls="three-tab-3" aria-selected="false">
                        <span>3</span>
                      </button>
                      {/* </Link> */}
                    </NavItem>
                  </ul>
                  <div className="d-flex justify-content-center mb-5">

<div className="ms-3 mr-min">
  <p>
  {t('choose_your_logo_component.enter_info')}

  </p>
</div>



<div className="ms-5">
  <p>   {t('choose_your_logo_component.review_logos')}</p>
</div>
<div className="ms-5">
  <p>        Customize it</p>
</div>


</div>
                </div>
                
            </span>
            
          </div>
      
    </div>
  );
}
