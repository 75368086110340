import React, { useState } from 'react';
import { ReactComponent as Delete } from "../../../../../images/icons/delete.svg";
import ButtonBox from "../../../../global/ButtonBox";
import arrowicon from "../../../../../images/triangle1.png";
import dot from "../../../../../images/dropdown/img13.png";

export default function IntegAddTrackFrom({ addDivProp }) {
  const [divs, setDivs] = useState([]);

  const addNewDiv = () => {
    const newDivs = [...divs, { id: divs.length, content: 'New Div' }];
    setDivs(newDivs);
  };

  const deleteDiv = (idToDelete) => {
    const newDivs = divs.filter((div) => div.id !== idToDelete);
    setDivs(newDivs);
  };

  const handleClick1 = () => {
    setToggle1(!toggle1);
  };
  const [toggle1, setToggle1] = useState("true");

  return (
    <div>
      {divs.map((div, index) => (
        <div className='dropNew21' key={div.id}>
          <li onClick={handleClick1}>
            <div className="spansec spansec1">
              <div className="dropdiv d-flex align-items-center pb-0" >
                <input type='text' placeholder="Other ?  Type here...." className='m-0 h-auto' />
              </div>
            </div>
            <div className='position-relative z-index-1 w-125 d-flex justify-content-center'>
              <span className="dropicn">
                <span>
                </span>
              </span>
            </div>
            <div className='bgLine7 w-100'>
              <h6>Other ?  Type here.... <img src={arrowicon} alt="" className='ms-auto' /></h6>
            </div>
            <button className='position-absolute bottom-0 top-0 my-auto custom-end1 border-0 btn bs-primary' onClick={() => deleteDiv(div.id)}><Delete /></button>
          </li>
          {toggle1 ? (
            <></>
          ) : (
            <div className='d-flex justify-content-between align-items-center w-100 bgLine8 ps-1 bglinearrow1'>
              <img src={dot} className='dot' alt="" />
              <input type="text" placeholder='G-XXXXXXXXXX or UA-XXXXXXXXXXX-X' />
            </div>
          )}
        </div>
      ))}
      <div className='row mt-5 custmToggle'>
              <div className='btmBtn9 d-flex col-lg-4'>
                <button className='shadow-4 border-0'>Add MORE</button>
                <span>
                  <div className="switch">
                    <div className="slider1 round" onClick={addNewDiv}></div>
                  </div>
                </span>
              </div>
              <div className='btnsec d-flex col-lg-8 pt-5 ps-4'>
              <ButtonBox
                boxwidth="w-110 bg-theme mt-0 mb-50 me-5"
                clicked="Cancel"
                name="Cancel"
              />
              <ButtonBox
                boxwidth="w-110  mt-0 mb-50"
                clicked="Saved"
                name="Save"
              />
              </div>
            </div>
    </div> 
  );
}
