import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import img5 from '../../images/social/img11.png';
import img11s from '../../images/social/img12.png';
import topbg from '../../images/social/img10.png';
// import auto from '../../images/audio/auto.png';
// import setting from '../../images/audio/setting.png';
// import short from '../../images/audio/short.png';
// import frame1 from '../../images/audio/frame1.png';
// import full from '../../images/audio/full.png';
// import play from '../../images/audio/play.png';
// import forwad from '../../images/audio/forwad.png';
// import sound from '../../images/audio/sound.png';
// import playbar from "../../images/audio/img21.png";
// import soundeimg from "../../images/audio/soundeimg.png";

import setting from "../../images/audio/setting.png";
import short from "../../images/audio/short.png";
import frame1 from "../../images/audio/frame1.png";
import full from "../../images/audio/full.png";
import play from "../../images/audio/play.png";
import pause from "../../images/audio/vpause.png";
import forwad from "../../images/audio/forwad.png";
import sound from "../../images/audio/sound.png";
import auto from "../../images/audio/auto.png";
// import icon2 from "../../images/audio/icon2.png";
// import icon3 from "../../images/audio/icon3.png";
// import icon4 from "../../images/audio/icon4.png";
// import icon5 from "../../images/audio/icon5.png";
// import icon6 from "../../images/audio/icon6.png";
// import icon9 from "../../images/audio/icon8.png";

import testvideo from "../../images/audio/testvideo.mp4";
import testvideo2 from "../../images/audio/testvideo2.mp4";
import close from "../../images/close1.png";
import testvideo3 from "../../images/audio/testvideo3.mp4";
import mute from "../../images/audio/mute.png";
import high from "../../images/audio/high.png";
import { GiPauseButton } from "react-icons/gi";


export default function VideoViewSocial({ handleClick9 }) {

  const videoData = [testvideo, testvideo2, testvideo3];
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const videoRef = useRef(null);
  const [volume, setVolume] = useState(50);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [showVolumeControls, setShowVolumeControls] = useState(false);
  const [isMiniScreen, setIsMiniScreen] = useState(false);
  const [autoplay, setAutoplay] = useState(false);

  const handlePlaylistClick = (index) => {
    setCurrentVideoIndex(index);
    
    setIsPlaying(true);
    const progressBar = document.querySelector('.progress-inner');
    progressBar.style.width = '0%';
    progressBar.style.animation = 'none';
  
    // Load and play the new video
    videoRef.current.load();
    videoRef.current.play();
  
    // Set up a new event listener for the progress bar on the new video
    videoRef.current.addEventListener('timeupdate', updateProgressBar);
  
  };
  const updateProgressBar = () => {
    const video = videoRef.current;
    const progressBar = document.querySelector('.progress-inner');
    const currentTime = video.currentTime;
    const duration = video.duration;
  
    const progress = (currentTime / duration) * 100;
    progressBar.style.width = progress + '%';
  };
  useEffect(() => {
    if (autoplay) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [autoplay]);
  const onVideoEnded = () => {
    if (autoplay) {
      currentVideoIndex.current++;
      if (currentVideoIndex.current < videoData.length) {
        videoRef.current.src = videoData[currentVideoIndex.current];
        videoRef.current.play();
      }
    }
    setIsPlaying(false);
  };
  useEffect(() => {
    // Load the initial video when the component mounts
    loadVideo(currentVideoIndex);
  }, [currentVideoIndex]);

  const loadVideo = (index) => {
    setIsLoading(true);
    setCurrentTime(0);
    setIsPlaying(false);

    videoRef.current.src = videoData[index];
    videoRef.current.load();
  };

  useEffect(() => {
    if (showVolumeControls) {
      setTimeout(() => {
        setShowVolumeControls(false);
      }, 5000);
    }
  }, [showVolumeControls]);

  const toggleVolumeControls = () => {
    setShowVolumeControls(!showVolumeControls);
  };
  const handleForwardClick = () => {
    const nextVideoIndex = (currentVideoIndex + 1) % videoData.length;
    if (nextVideoIndex >= 0) {
      const progressBar = document.querySelector('.progress-inner');
      progressBar.style.width = '0%';
      progressBar.style.animation = 'none';
  
      setTimeout(() => {
        // Re-enable the animation
        progressBar.style.animation = 'animate-bar 1s linear forwards';
  
        // Load and play the new video
        videoRef.current.src = videoData[nextVideoIndex];
        videoRef.current.load();
        videoRef.current.play();
  
        setCurrentVideoIndex(nextVideoIndex);
        setIsPlaying(true);
      }, 10); // Add a slight delay (e.g., 10 milliseconds) before re-enabling the animation
    }
  };
  
  useEffect(() => {
    videoRef.current.src = videoData[currentVideoIndex];
  }, [currentVideoIndex]);

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value) / 100;
    setVolume(newVolume);
    videoRef.current.volume = newVolume;
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  const onTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const handleTheaterButtonClick = () => {
    const videoContainer = document.querySelector(".middleimg");
    if (videoContainer.classList.contains("theater-mode")) {
      videoContainer.classList.remove("theater-mode");
      setIsMiniScreen(false); // Exit theater mode
    } else {
      videoContainer.classList.add("theater-mode");
      setIsMiniScreen(false); // Exit mini-screen mode if currently in it
    }
  };

  const handleMiniPlayerButtonClick = () => {
    const videoContainer = document.querySelector(".middleimg");
    if (videoContainer.classList.contains("mini-player")) {
      videoContainer.classList.remove("mini-player");
      setIsMiniScreen(false); // Exit mini-screen mode if currently in it
    } else {
      videoContainer.classList.add("mini-player");
      setIsMiniScreen(true); // Enter mini-screen mode
    }
  };

  const handleFullScreenButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.requestFullscreen();
    }
  };
 

  return (
    <div>
      <div className="text-center w-100">
        <div className="audioply">
          <div className="videoplayer2 pt-0 videoplayer3 pt-2">
            <button class="topbtn border-0 bg-primary">VIDEO CENTER </button>
            <div className="d-flex justify-content-end h-100 position-relative">
              <div className="closebtn2" onClick={handleClick9}>
                <img src={close} alt="" height="40"  className="w-auto" />
              </div>
              <div className="playersec">
                <div className="playerinternal position-relative h-100">
                  <div className="vidplay position-relative h-100 w-100">
                    <div className="audiotool position-relative shadow-5in br-50s p-2">
                    <div className="gradient-box-dark br-50s h-183">
                        <div
                          className={`middleimg  position-relative ${
                            isMiniScreen ? "mini-player" : ""
                          }`}
                        >
                          {/* <img src={img5} alt="" /> */}
                          <video
                            className="theater-mode br-45 h-177"
                            ref={videoRef}
                            onCanPlayThrough={() => {
                              setDuration(videoRef.current.duration);
                              setIsLoading(false);
                            }}
                            onTimeUpdate={onTimeUpdate}
                            onEnded={onVideoEnded}
                          >
                            <source
                              src={videoData[currentVideoIndex]}
                              type="video/mp4"
                            />
                          </video>
                          <div className="sound-control4 top-25">
                            <div>
                              {showVolumeControls && (
                                <div className=" justify-content-center top-0 ">
                                  <div>
                                    <img src={high} width="24" />
                                  </div>
                                  <div className="volume-control4">
                                    <input
                                      className="soundediv"
                                      type="range"
                                      defaultValue={volume}
                                      min={0}
                                      max={100}
                                      step={10}
                                      onChange={handleVolumeChange}
                                    />
                                  </div>
                                  <div>
                                    <img src={mute} width="24" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="position-absolute d-inline-table top-0 bottom-0 start-0 end-0 m-auto">
                            <div onClick={handlePlayPause}>
                              {isMiniScreen ? (
                                ""
                              ) : (
                                <div>
                                  { isPlaying ? (
                                    <svg width="20" height="26" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Group 427330918">
                                    <path id="Vector" d="M1.49861 0.500002C1.10115 0.500002 0.719976 0.635415 0.438933 0.87645C0.15789 1.11748 0 1.44438 0 1.78526V14.2147C0 14.5556 0.15789 14.8825 0.438933 15.1236C0.719976 15.3646 1.10115 15.5 1.49861 15.5C1.89606 15.5 2.27723 15.3646 2.55828 15.1236C2.83932 14.8825 2.99721 14.5556 2.99721 14.2147V1.86363C3.00969 1.68837 2.98019 1.51278 2.91048 1.34768C2.84078 1.18258 2.73237 1.03149 2.59195 0.903715C2.45153 0.775939 2.28207 0.674192 2.09402 0.604757C1.90598 0.535322 1.70334 0.499675 1.49861 0.500002Z" fill="url(#paint0_linear_539_82955)"/>
                                    <path id="Vector_2" d="M8.50139 0.500002C8.69819 0.500002 8.89307 0.53323 9.07489 0.597821C9.25671 0.662412 9.42191 0.757102 9.56107 0.87645C9.70023 0.995798 9.81062 1.13748 9.88593 1.29341C9.96124 1.44935 10 1.61647 10 1.78526V14.2147C10 14.5556 9.84211 14.8825 9.56107 15.1236C9.28003 15.3646 8.89885 15.5 8.50139 15.5C8.10394 15.5 7.72276 15.3646 7.44172 15.1236C7.16068 14.8825 7.00279 14.5556 7.00279 14.2147V1.86363C6.9903 1.68837 7.01982 1.51278 7.08953 1.34768C7.15923 1.18258 7.26763 1.03149 7.40806 0.903715C7.54848 0.775939 7.71794 0.674192 7.90598 0.604757C8.09403 0.535322 8.29666 0.499675 8.50139 0.500002Z" fill="url(#paint1_linear_539_82955)"/>
                                    </g>
                                    <defs>
                                    <linearGradient id="paint0_linear_539_82955" x1="1.5" y1="-2.2" x2="1.5" y2="17.9" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FACB30"/>
                                    <stop offset="0.302269" stop-color="#FEF9C8"/>
                                    <stop offset="0.640625" stop-color="#C49530"/>
                                    <stop offset="1" stop-color="#FFF8C4"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_539_82955" x1="8.5" y1="-2.2" x2="8.5" y2="17.9" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FACB30"/>
                                    <stop offset="0.302269" stop-color="#FEF9C8"/>
                                    <stop offset="0.640625" stop-color="#C49530"/>
                                    <stop offset="1" stop-color="#FFF8C4"/>
                                    </linearGradient>
                                    </defs>
                                    </svg>
                                    
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="50"
                                      height="50"
                                      viewBox="0 0 130 130"
                                      fill="none"
                                    >
                                      <circle
                                        cx="65"
                                        cy="65"
                                        r="64"
                                        stroke="#F0F4F8"
                                        stroke-width="2"
                                      />
                                      <circle
                                        cx="63.375"
                                        cy="63.375"
                                        r="34.125"
                                        fill="url(#paint0_linear_255_76455)"
                                      />
                                      <path
                                        d="M56.875 55.4821C56.875 53.9385 58.5494 52.9768 59.8827 53.7545L73.4135 61.6474C74.7365 62.4192 74.7365 64.3308 73.4135 65.1026L59.8827 72.9955C58.5494 73.7732 56.875 72.8115 56.875 71.2679V55.4821Z"
                                        fill="#202020"
                                      />
                                      <defs>
                                        <linearGradient
                                          id="paint0_linear_255_76455"
                                          x1="63.375"
                                          y1="16.965"
                                          x2="63.375"
                                          y2="108.42"
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop stop-color="#FACB30" />
                                          <stop
                                            offset="0.302269"
                                            stop-color="#FEF9C8"
                                          />
                                          <stop
                                            offset="0.640625"
                                            stop-color="#C49530"
                                          />
                                          <stop
                                            offset="1"
                                            stop-color="#FFF8C4"
                                          />
                                        </linearGradient>
                                      </defs>
                                    </svg>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="position-absolute pb-3 d-inline-table w-100 px-4  bottom-0 start-0 end-0 mx-auto">
                            {/* <img src={playbar} alt="" /> */}
                            {isMiniScreen ? (
                              ""
                            ) : (
                              <div className="  mx-auto mb-2">
                                <div className=" mx-auto mb-0 progressbar">
                                  <div
                                    className={`${"progressinner"} ${
                                      currentTime !== duration
                                        ? "progress-inner inner2"
                                        : ""
                                    } `}
                                    style={{
                                      animationPlayState: isPlaying
                                        ? "running"
                                        : "paused",
                                      animationDuration: isLoading
                                        ? "0s"
                                        : `${videoRef.current.duration}s`,
                                    }}
                                  />
                                </div>{" "}
                              </div>
                            )}
                            <div className="d-flex playable justify-content-between px-4">
                              <div className="d-flex">
                                <Link to="">
                                  <div onClick={handlePlayPause}>
                                    {isPlaying ? (
                                      <img src={pause} alt="" />
                                    ) : (
                                      <img src={play} alt="" />
                                    )}
                                  </div>
                                </Link>
                                <Link to="">
                                  <img
                                    src={forwad}
                                    alt=""
                                    onClick={handleForwardClick}
                                  />
                                </Link>
                                <Link to="">
                                  <img
                                    src={sound}
                                    alt=""
                                    onClick={toggleVolumeControls}
                                  />
                                </Link>
                              </div>
                              <div className="d-flex justify-content-between">
                                {isMiniScreen ? (
                                  <div className="d-flex">
                                    <Link to="">
                                      <img
                                        src={frame1}
                                        alt=""
                                        onClick={handleTheaterButtonClick}
                                      />
                                    </Link>
                                    <Link to="">
                                      <img
                                        src={full}
                                        alt=""
                                        onClick={handleFullScreenButtonClick}
                                      />
                                    </Link>
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    <Link to="">
                                      <img
                                        src={frame1}
                                        alt=""
                                        onClick={handleTheaterButtonClick}
                                      />
                                    </Link>
                                    <Link to="">
                                      <img
                                        src={full}
                                        alt=""
                                        onClick={handleFullScreenButtonClick}
                                      />
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
