import React from "react";
import GlobleTab from "./GlobleTab";
// import analysis from "../../../../../images/analysisi.png";
const Analysis = () => {
  return (
    <div className='pt-4'>
      <div className="topheadGlob px-4 mx-auto">
          <div className="inrhead text-uppercase">
            Globle Tracking Pixels Hub
          </div>
      </div>
      <div className='glob-track py-4'>
        <div className='pt-5'>
            <GlobleTab />
        </div>
      </div>
    </div>
  );
};

export default Analysis;
