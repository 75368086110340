import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';
import { faInfo} from "@fortawesome/free-solid-svg-icons";

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import { useTranslation } from "react-i18next";



export default function InfoIconComponent() {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  
    useEffect(() => {
      if (isOpen) {
        const timer = setTimeout(() => {
          setIsOpen(false);
        }, 5000);
  
        return () => clearTimeout(timer);
      }
    }, [isOpen]);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  const [Competency1, setCompetency1] = useState(10);

  return (
    <div>



      <div className="">
  


                  <li>
                    <div className="info-oter">
                      <FontAwesomeIcon className="info-icon" icon={faInfo} >

                      </FontAwesomeIcon>
                    </div>
                  </li>
             

      </div>




    </div>



  );
}
