import React, { useState, useEffect, useRef } from "react";
// import img1 from '../../images/profileview/img1.png';
// import img2 from '../../images/profileview/imgs2.png';
// import img3 from '../../images/profileview/imgs3.png';
// import bg from '../../images/profileview/bgimg2.png';
// import flag from "../../images/flag.png";
// import ImageBox from "../global/ImageBox";
// import user from '../../images/img1.png';
// import img10 from '../../images/profileview/img16s.png';
// import img11 from '../../images/slider1/img2.png';
// import img12 from '../../images/slider1/img3.png';
import bgimg from "../../images/profileview/bg-img2.png";
import imguser from "../../images//img1.png";
// import state2 from '../../images/status.png';
// import state3 from '../../images/profileview/stateblack.png';
// import { NavItem } from 'react-bootstrap';
// import { ReactComponent as IconBlue1v } from "../../images/icons/icon36.svg";
// import { ReactComponent as IconBlue2v } from "../../images/icons/icon37.svg";
// import { ReactComponent as IconBlue3v } from "../../images/icons/icon38.svg";
// import { RiCloseLine} from "react-icons/ri";
import { Link } from "react-router-dom";
import img4 from "../../images/dropdown/object.png";
import img5 from "../../images/audiobg.png";
import img6 from "../../images/audio/img1.png";
import img7 from "../../images/audio/img2.png";
import img8 from "../../images/audio/img3.png";
import img9s from "../../images/audio/img6.png";
import img10s from "../../images/audio/img5.png";
import img11s from "../../images/audio/img4.png";
import img12s from "../../images/audio/img7.png";
import closebtn from "../../images/closebtn.png";
import img22 from "../../images/audio/img22.png";
import auto from "../../images/audio/auto.png";
import setting from "../../images/audio/setting.png";
import short from "../../images/audio/short.png";
import frame1 from "../../images/audio/frame1.png";
import full from "../../images/audio/full.png";
import play from "../../images/audio/play.png";
import icon2 from "../../images/audio/icon2.png";
import icon3 from "../../images/audio/icon3.png";
import icon4 from "../../images/audio/icon4.png";
import icon5 from "../../images/audio/icon5.png";
import icon6 from "../../images/audio/icon6.png";
import icon9 from "../../images/audio/icon8.png";

import icon10 from "../../images/audio/icon1.png";
import icon11 from "../../images/audio/icon7.png";
import icon12 from "../../images/audio/icon9.png";
import icon13 from "../../images/audio/icon10.png";
import icon14 from "../../images/audio/icon11.png";
import icon15 from "../../images/audio/icon12.png";
import icon16 from "../../images/audio/icon13.png";

import mainimg7 from '../../images/profileview/img1.png';
import mainimg8 from '../../images/profileview/imgs2.png';
import mainimg9 from '../../images/profileview/imgs3.png';
import tag from '../../images/dropdown/tag.png';
import ImageBox from "../global/ImageBox";

import mute from "../../images/audio/mute.png";
import high from "../../images/audio/high.png";
import testaudio2 from "../../images/audio/testaudio2.mp3";
import testaudio3 from "../../images/audio/testaudio3.mp3";
import testaudio from "../../images/audio/testaudio.mp3";
import ColorPicker, { useColorPicker } from "react-best-gradient-color-picker";

// import { Slider } from "@mui/material";
export default function AudioView({ setHandleView }) {
  const audioData = [testaudio, testaudio2, testaudio3];
  const audioRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentaudioIndex, setCurrentaudioIndex] = useState(0);
  const [autoplay, setAutoplay] = useState(false);
  const [isMiniScreen, setIsMiniScreen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const currentaudiosIndex = useRef(0);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [showVolumeControls, setShowVolumeControls] = useState(false);
  const thumbImageRef = useRef(null);
  const sliderRef = useRef(null);
  const [color, setColor] = useState(
    "linear-gradient(180deg, #C49530  67.84%, #FEF9C8 22.5%,  #FACB30 -18%, #FFF8C4 116%)"
  );
  const { setSolid, setGradient } = useColorPicker(color, setColor);
  const setElementBackgroundColor = (element) => {
    element.style.backgroundColor = color;
  };
  useEffect(() => {
    if (showVolumeControls) {
      setTimeout(() => {
        setShowVolumeControls(false);
      }, 9000);
    }
  }, [showVolumeControls]);

  const toggleVolumeControls = () => {
    setShowVolumeControls(!showVolumeControls);
  };

  const handleSpeedUp = () => {
    const newSpeed = playbackSpeed * 2;
    audioRef.current.playbackRate = newSpeed;
    setPlaybackSpeed(newSpeed);
  };

  const handleSlowDown = () => {
    const newSpeed = playbackSpeed / 2;
    audioRef.current.playbackRate = newSpeed;
    setPlaybackSpeed(newSpeed);
  };

  useEffect(() => {
    if (autoplay) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [autoplay]);
  const onVideoEnded = () => {
    if (autoplay) {
      currentaudiosIndex.current++;
      if (currentaudiosIndex.current < audioData.length) {
        audioRef.current.src = audioData[currentaudiosIndex.current];
        audioRef.current.play();
      }
    }
  };
  const handleForwardClick = () => {
    const nextAudioIndex = (currentaudioIndex + 1) % audioData.length;
    if (nextAudioIndex >= 0) {
      const progressBar = document.querySelector(".progress-inner");
      progressBar.style.width = "0%";
      progressBar.style.animation = "none";

      setTimeout(() => {
        // Re-enable the animation
        progressBar.style.animation = "animate-bar 1s linear forwards";

        // Load and play the new audio
        audioRef.current.src = audioData[nextAudioIndex];
        audioRef.current.load();
        audioRef.current.play();

        setCurrentaudioIndex(nextAudioIndex);
        setIsPlaying(true);
      }, 10); // Add a slight delay (e.g., 10 milliseconds) before re-enabling the animation
    }
  };

  const handleBackwardClick = () => {
    const nextAudioIndex = (currentaudioIndex - 1) % audioData.length;
    if (nextAudioIndex >= 0) {
      const progressBar = document.querySelector(".progress-inner");
      progressBar.style.width = "0%";
      progressBar.style.animation = "none";

      setTimeout(() => {
        // Re-enable the animation
        progressBar.style.animation = "animate-bar 1s linear forwards";

        // Load and play the new audio
        audioRef.current.src = audioData[nextAudioIndex];
        audioRef.current.load();
        audioRef.current.play();

        setCurrentaudioIndex(nextAudioIndex);
        setIsPlaying(true);
      }, 10); // Add a slight delay (e.g., 10 milliseconds) before re-enabling the animation
    }
  };

  const playCurrentaudio = () => {
    if (!audioRef.current.paused) {
      audioRef.current.pause();
    }

    audioRef.current.src = audioData[currentaudioIndex];

    audioRef.current.onLoadmetadata = () => {
      audioRef.current.play();
      audioRef.current.onLoadmetadata = null;
    };
  };

  const handlePlayPauseClick = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  const onTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };
  const [volume, setVolume] = useState(50);

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value) / 100;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;

    // Calculate the thumb image position
    updateThumbPosition(newVolume);
  };

  const updateThumbPosition = (newVolume) => {
    const slider = sliderRef.current;
    const thumbImage = thumbImageRef.current;
    const min = parseFloat(slider.min);
    const max = parseFloat(slider.max);
    const percent = ((newVolume - min) / (max - min)) * 100;

    // Calculate the position of the thumb image vertically
    const thumbPosition =
      ((slider.clientHeight - thumbImage.clientHeight) * percent) / 100;

    // Set the position of the thumb image
    thumbImage.style.top = `${thumbPosition}px`;
  };

  const handleSliderChange = (e) => {
    audioRef.current.currentTime = e.target.value;
    setCurrentTime(e.target.value);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };
  const handleTheaterButtonClick = () => {
    const audioContainer = document.querySelector(".playable1");
    if (audioContainer.classList.contains("theater-mode")) {
      audioContainer.classList.remove("theater-mode");
      setIsMiniScreen(false);
      setIsFullScreen(false);
    } else {
      audioContainer.classList.add("theater-mode");
      setIsMiniScreen(false);
      setIsFullScreen(false);
    }
  };

  const handleMiniPlayerButtonClick = () => {
    const audioContainer = document.querySelector(".playable1");
    if (audioContainer.classList.contains("mini-player2")) {
      audioContainer.classList.remove("mini-player2");
      setIsMiniScreen(false);
      setIsFullScreen(false);
    } else {
      audioContainer.classList.add("mini-player2");
      setIsMiniScreen(true);
      setIsFullScreen(false);
    }
  };

  const handleFullScreenButtonClick = () => {
    const videoContainer = document.querySelector(".middleimg");

    if (videoContainer) {
      if (!document.fullscreenElement) {
        if (videoContainer.requestFullscreen) {
          videoContainer.requestFullscreen();
        } else if (videoContainer.mozRequestFullScreen) {
          videoContainer.mozRequestFullScreen();
        } else if (videoContainer.webkitRequestFullscreen) {
          videoContainer.webkitRequestFullscreen();
        } else if (videoContainer.msRequestFullscreen) {
          videoContainer.msRequestFullscreen();
        }
        setIsFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        setIsFullScreen(false);
      }
    }
  };

  useEffect(() => {
    const videoContainer = document.querySelector(".middleimg");

    if (videoContainer) {
      videoContainer.addEventListener("fullscreenchange", () => {
        setIsFullScreen(!!document.fullscreenElement);
      });
    }
  }, []);

  // const handleClick1 = () => {
  //   setToggle1(!toggle1);
  // };
  // const [toggle1, setToggle1] = useState("true");

  // const handleClick2 = () => {
  //   setToggle2(!toggle2);
  // };
  const [toggle2, setToggle2] = useState("true");
  const handleVideoClick = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };
  return (
    <div className="text-center bottomlayer1">
      <div className='buttontop position-relative bottom-10s'>
        <button>DYNA APPOLON</button>
      </div>
      <div className='buttontop position-relative bottom-10s'>
        <button>CEO | 9 TO 5 ESCAPEE</button>
      </div>
      <div className="audioply shadow-5in px-3 py-3 br-30 mt-4">
        <div className="playerinternal playerinterna2  position-relative">
          <img src={img4} alt="" />
          <div className="audioplay position-absolute w-100 top-0 pt-5 mt-5">
            <div className="btnaudio mx-auto">
              <h6>PODCAST CENTER</h6>
            </div>
            <div className="audiotool position-relative">
              
              <div
                className={`middleimg  position-relative mt-4 ${
                  isMiniScreen ? "mini-player" : ""
                } ${isFullScreen ? "full-screen" : ""}`}
              >
                <div className="sound-control4 d-none">
                  <div className=" justify-content-center top-25 ">
                    <div className="volume-control volume-control4 d-none">
                      <input
                        className="soundediv2 win10-thumb"
                        type="range"
                        defaultValue={volume}
                        min={0}
                        max={100}
                        step={10}
                        sliderRef={sliderRef}
                        onChange={handleVolumeChange}
                      />

                    </div>
                    <div>
                      <img src={mute} />
                    </div>
                  </div>
                </div>
                <div className="audibg mx-auto position-relative">
                  <div className="soundediv1">
                    <audio
                      ref={audioRef}
                      onEnded={onVideoEnded}
                      onCanPlayThrough={() => {
                        setDuration(audioRef.current.duration);
                        setIsLoading(false);
                      }}
                      onTimeUpdate={onTimeUpdate}
                      onClick={handleVideoClick}
                    >
                      <source
                        src={audioData[currentaudioIndex]}
                        type="audio/mp3"
                      />
                    </audio>
                  </div>
                  <img src={img5} alt="" />
                  <div className="position-absolute z-1w end-0 top-0 d-flex flex-column justify-content-center">
                    <div className="mb-auto">
                      <Link to="" onClick={() => setHandleView("")}>
                        <img src={closebtn} alt="" width="20" />
                      </Link>
                    </div>
                  </div>
                </div>
                <Link
                  to=""
                  className="position-absolute d-inline-table pt-4 mt-2 top-0 start-0 end-0 mx-auto"
                >
                  {isMiniScreen ? (
                    ""
                  ) : (
                    <div onClick={handlePlayPauseClick}>
                      {isPlaying ? (
                        " "
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 130 130"
                          fill="none"
                        >
                          <circle
                            cx="65"
                            cy="65"
                            r="64"
                            stroke="#F0F4F8"
                            stroke-width="2"
                          />
                          <circle
                            cx="63.375"
                            cy="63.375"
                            r="34.125"
                            fill="url(#paint0_linear_255_76455)"
                          />
                          <path
                            d="M56.875 55.4821C56.875 53.9385 58.5494 52.9768 59.8827 53.7545L73.4135 61.6474C74.7365 62.4192 74.7365 64.3308 73.4135 65.1026L59.8827 72.9955C58.5494 73.7732 56.875 72.8115 56.875 71.2679V55.4821Z"
                            fill="#202020"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_255_76455"
                              x1="63.375"
                              y1="16.965"
                              x2="63.375"
                              y2="108.42"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#FACB30" />
                              <stop offset="0.302269" stop-color="#FEF9C8" />
                              <stop offset="0.640625" stop-color="#C49530" />
                              <stop offset="1" stop-color="#FFF8C4" />
                            </linearGradient>
                          </defs>
                        </svg>
                      )}
                    </div>
                  )}
                </Link>
                <div className="darkbg">
                  <div className="position-relative pb-3 d-inline-table bottom-0 start-0 end-0 mx-auto w-100">
                    <div className=" controls">
                      {isMiniScreen ? (
                        ""
                      ) : (
                        <div className="  mx-auto mb-3">
                          <div className=" mx-auto mb-3 progressbar">
                            <div
                              className={`${"progressinner"} ${
                                currentTime !== duration ? "progress-inner inner2" : ""
                              } `}
                              style={{
                                animationPlayState: isPlaying
                                  ? "running"
                                  : "paused",
                                animationDuration: isLoading
                                  ? "0s"
                                  : `${audioRef.current.duration}s`,
                              }}
                            />
                          </div>{" "}
                        </div>
                      )}
                    </div>
                    <div className="d-flex playable1 justify-content-center px-0">
                      <div className="d-flex inerplayable align-items-center flex-column">
                        {isMiniScreen ? (
                          ""
                        ) : (
                          <div>
                            <Link className="btnplaytime">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="me-2"
                                width="18"
                                height="19"
                                viewBox="0 0 18 19"
                                fill="none"
                              >
                                <path
                                  d="M9 18.1699C4.0293 18.1699 0 14.1406 0 9.16992C0 4.19922 4.0293 0.169922 9 0.169922C13.9707 0.169922 18 4.19922 18 9.16992C18 14.1406 13.9707 18.1699 9 18.1699ZM9.9 9.16992V4.66992H8.1V10.9699H13.5V9.16992H9.9Z"
                                  fill="#202020"
                                />
                              </svg>
                              {formatTime(currentTime)} / {formatTime(duration)}
                            </Link>
                          </div>
                        )}
                        <div className="d-flex gap-1 align-items-center pt-3">
                          {isMiniScreen ? (
                            ""
                          ) : (
                            <div>
                              {" "}
                              <Link to="">
                                <img
                                  src={icon10}
                                  alt=""
                                  onClick={toggleVolumeControls}
                                  width="24"
                                />
                              </Link>
                            </div>
                          )}
                          {isMiniScreen ? (
                            " "
                          ) : (
                            <div>
                              <Link to="">
                                <img src={icon11} alt="" width="24" />
                              </Link>
                            </div>
                          )}
                          <Link to="">
                            <img
                              src={icon12}
                              alt=""
                              onClick={handleBackwardClick}
                              width="14"
                            />
                          </Link>
                          <div>
                            <div onClick={handlePlayPauseClick}>
                              {isPlaying ? (
                              <img src={icon13} alt="" width="24" />

                              ) : (
                                
                                <img src={play} alt="" width="24" />
                              )
                            }
                            </div>
                          </div>
                          <Link to="">
                            <img src={icon14} alt="" onClick={handleForwardClick} width="14" />
                          </Link>
                          <Link to="">
                            <img src={icon15} alt="" onClick={handleSlowDown} width="20" />
                          </Link>
                          <Link to="">
                            <img src={icon16} alt="" onClick={handleSpeedUp} width="20" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="audioBtm5 bg w-100 px-0 d-flex flex-wrap z-3 pt-4">
              <div className="w-100">
                <h5 className="bg shadow-5in">EPISODE 5 | SEASON 1</h5>
                <h6 className="bg shadow-4">7-FIGURE BOSSES SQUAD SERIES</h6>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <img src={img10s} alt="" />
                </div>
                <div className="col-lg-2 px-0">
                <img src={img22} alt="" />
                </div>
                <div className="col-lg-5">
                  <img src={img9s} alt="" />
                </div>
              </div>
            </div>
            <div className="">
              <div className="d-flex align-items-center flex-column">
                <div className="listenLive2 d-flex justify-content-center align-items-center pe-3 my-3">
                  <div className="icontb shadow-5in bg">
                    <img src={img12s} alt="" />
                  </div>
                  <span>
                    <h3>LISTEN LIVE</h3>
                    <h4>ALL TIPS & TRICKS</h4>
                  </span>
                </div>
                <div className="listbtn d-flex d-none">
                  <div className="listfriest d-flex">
                    <img src={imguser} alt="" />
                    <div>
                      <h3>Dyna appolon</h3>
                      <h4>100k Subscribers</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="d-flex justify-content-center mb-4 px-4 mt-5">
            <li className='me-2'>
              <div className='outerdiv'>
                <img src={mainimg7} alt="" className="circle" />
              </div>
            </li>
            <li className='me-2'>
              <div className='outerdiv'>
                <img src={mainimg8} alt="" className="circle" />
              </div>
            </li>
            <li className=''>
              <div className='outerdiv position-relative'>
                <img src={mainimg9} alt="" className="circle" />
                <div className="tag position-absolute">
                  <ImageBox boxwidth="w-16" src={tag} />
                </div>
              </div>
            </li>
          </ul>
    </div>
  );
}
