import React from "react";
import ButtonBox from "../../../../global/ButtonBox";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import RadioOff from "../../../../global/RadioOff";
import RadioOn from "../../../../global/RadioOn";
import user from '../../../../../images/imguser.png';
import faCaretDown from '../../../../../images/icons/faCaretDown.svg';
import { useTranslation } from "react-i18next";
import { NavItem } from 'react-bootstrap';
import ButtonBoxNew from "../../../../global/ButtonBoxNew";
export default function TimeLineOne() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <ul>
      <NavItem as="li">
          <h3 className="mb-0">{t('privacy_component.timeline_component.timeline')}</h3>
        </NavItem>
        <NavItem as="li" className="py-3">
          <div className="listText">
            <h5>{t('privacy_component.timeline_component.who_can_post_on_my_timeline')}</h5>
          </div>
          <div className="d-flex">
          <div className="dropdown">
              <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
              <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
                   {t('choose_your_audience')} 
                   {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
                   <img src={faCaretDown} alt="" />
              </Link>
              </span>
              <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
              <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
                <NavItem as="li" className='px-2'>
                  <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.timeline_component.placeholder_email')} />
                </NavItem>
                <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                  {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
                </NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
                <NavItem as="li" className='px-2'>
                  <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.timeline_component.placeholder_email')} />
                </NavItem>
                <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                  {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
                </NavItem>
              </ul> 
            </div>
          </div>
        </NavItem>
        <NavItem as="li" className="py-3">
          <div className="listText">
            <h5>{t('privacy_component.timeline_component.timeline_post')}</h5>
          </div>
          <div className="d-flex">
          <div className="dropdown">
              <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
              <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
               {t('choose_your_audience')} 
               {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
               <img src={faCaretDown} alt="" />
              </Link>
              </span>
              <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
           <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
                <NavItem as="li" className='px-2'>
                  <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.timeline_component.placeholder_email')} />
                </NavItem>
                <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                  {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
                </NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
                <NavItem as="li" className='px-2'>
                  <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.timeline_component.placeholder_email')} />
                </NavItem>
                <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                  {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
                </NavItem>
              </ul> 
            </div>
          </div>
        </NavItem>
        <NavItem as="li" className="py-3">
          <div className="listText">
            <h5>
            {t('privacy_component.timeline_component.stories_post')}
            </h5>
          </div>
          <div className="radioButton d-flex">
            <div className="dropdown me-3">
              <span role="button" className="dropArrow" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
              <Link to="#">
                <FontAwesomeIcon icon={faCaretDown} className="h4 m-0" />
              </Link>
              </span>
              <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" aria-labelledby="dropdownMenuLink">
                <NavItem as="li" className='h8 mb-3'>{t('unacceptable_words')}<small className="d-inline-block">{t('seperate_using_comma')}</small></NavItem>
                <NavItem as="li" className='px-2'>
                  <textarea className="shadow-5in br-20 mb-3 me-2 w-300" placeholder={t('paceolder_word')}> </textarea>
                </NavItem>
                <NavItem as="li" className="px-4 d-flex align-items-center justify-content-center">
                  <button className="h-50 bs-success br-30 w-auto px-4 h8 text-center border-0 shadow-4 me-2">
                  {t('privacy_component.timeline_component.filter_hide_words')}
                  </button>
                </NavItem>
              </ul>
            </div>
            <RadioOn id="stories1" name="stories" />
            <RadioOff id="stories2" name="stories" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr pb-4">
          <div className="listText">
            <h5>
            {t('privacy_component.timeline_component.timeline_commwent')}
            </h5>
          </div>
          <div className="radioButton d-flex">
            <div className="dropdown me-3">
              <span role="button" className="dropArrow" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
              <Link to="#">
                <FontAwesomeIcon icon={faCaretDown} className="h4 m-0" />
              </Link>
              </span>
              <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" aria-labelledby="dropdownMenuLink">
                <NavItem as="li" className='h8 mb-3'> {t('unacceptable_words')}<small className="d-inline-block">{t('seperate_using_comma')}</small></NavItem>
                <NavItem as="li" className='px-2'>
                  <textarea className="shadow-5in br-20 mb-3 me-2 w-300" placeholder={t('paceolder_word')}> </textarea>
                </NavItem>
                <NavItem as="li" className="px-4 d-flex align-items-center justify-content-center">
                  <button className="h-50 bs-success br-30 w-auto px-4 h8 text-center border-0 shadow-4 me-2">
                {t('privacy_component.timeline_component.filter_hide_words')}
                  </button>
                </NavItem>
              </ul>
            </div>
            <RadioOn id="certain1" name="certain" />
            <RadioOff id="certain2" name="certain" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li">
          <h3 className="m-0 mt-4 pt-3">{t('privacy_component.timeline_component.tagging')}</h3>
        </NavItem>
        <NavItem as="li" className="py-3">
          <div className="listText">
            <h5>{t('privacy_component.timeline_component.timeline_tagging')}</h5>
          </div>
          <div className="d-flex">
          <div className="dropdown">
              <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
              <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
              {t('only_me')}
               {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
               <img src={faCaretDown} alt="" />
              </Link>
              </span>
              <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
           <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
                <NavItem as="li" className='px-2'>
                  <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.timeline_component.placeholder_email')} />
                </NavItem>
                <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                  {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
                </NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
                <NavItem as="li" className='px-2'>
                  <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.timeline_component.placeholder_email')} />
                </NavItem>
                <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                  {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
                </NavItem>
              </ul> 
            </div>
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr pb-4">
          <div className="listText">
            <h5>{t('privacy_component.timeline_component.timeline_add_post')}</h5>
          </div>
          <div className="d-flex">
          <div className="dropdown">
              <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
              <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
                   {t('choose_your_audience')} 
                   {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
                   <img src={faCaretDown} alt="" />
              </Link>
              </span>
              <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
           <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
                <NavItem as="li" className='px-2'>
                  <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.timeline_component.placeholder_email')} />
                </NavItem>
                <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                  {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
                </NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
                <NavItem as="li" className='px-2'>
                  <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.timeline_component.placeholder_email')} />
                </NavItem>
                <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                  {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
                </NavItem>
              </ul> 
            </div>
          </div>
        </NavItem>
        <NavItem as="li">
          <h3 className="m-0 mt-4 pt-3">{t('privacy_component.timeline_component.review')}</h3>
        </NavItem>
        <NavItem as="li" className="py-3">
          <div className="listText">
            <h5>
            {t('privacy_component.timeline_component.timeline_tagged_appear')}
            </h5>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="appears1" name="appears" />
            <RadioOff id="appears2" name="appears" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="py-3">
          <div className="d-flex mx-auto">
            <div className="dropdown review">
              <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
              {/* <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-100 shadow-4 h-50s h8 br-30">
              {t('privacy_component.timeline_component.timeline_post_review')}
              </Link> */}
              <ButtonBoxNew name="Review posts you are tagged in" boxwidth="w-300" />
              </span>
              <ul className="dropdown-menu w-560 px-30 dropdown-menu-end dropSec bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                <NavItem as="li" className='py-30'>
                  <div className="w-200">
                    <img src={user} alt="" className="w-100" />
                  </div>
                  <div className="w-270">
                    <p>{t('privacy_component.timeline_component.timeline_email_tag')}:</p>
                    <p>{t('privacy_component.timeline_component.timeline_text')}</p>
                    <div className="d-flex">
                      <button className="h-40 bs-success br-30 w-110 h8 text-center border-0 shadow-4 me-2 px-3">
                           {t('allow')} <FontAwesomeIcon icon={faCheck} className="h6 m-0 ms-2" />
                      </button>
                      <button className="h-40 bg-danger br-30 w-110 text-white h8 text-center border-0 shadow-4 me-2 px-3">
                        {t('Delete')} <FontAwesomeIcon icon={faXmark} className="h6 m-0 ms-2 text-white" />
                      </button>
                    </div>
                  </div>
                </NavItem>
                <NavItem as="li" className='py-30'>
                  <div className="w-200">
                    <img src={user} alt="" className="w-100" />
                  </div>
                  <div className="w-270">
               <p>{t('privacy_component.timeline_component.timeline_email_tag')}:</p>
                    <p>{t('privacy_component.timeline_component.timeline_text')}</p>
                    <div className="d-flex">
                      <button className="h-40 bs-success br-30 w-110 h8 text-center border-0 shadow-4 me-2 px-3">
                           {t('allow')} <FontAwesomeIcon icon={faCheck} className="h6 m-0 ms-2" />
                      </button>
                      <button className="h-40 bg-danger br-30 w-110 text-white h8 text-center border-0 shadow-4 me-2 px-3">
                        {t('Delete')} <FontAwesomeIcon icon={faXmark} className="h6 m-0 ms-2 text-white" />
                      </button>
                    </div>
                  </div>
                </NavItem>
                <NavItem as="li" className='py-30'>
                  <div className="w-200">
                    <img src={user} alt="" className="w-100" />
                  </div>
                  <div className="w-270">
               <p>{t('privacy_component.timeline_component.timeline_email_tag')}:</p>
                    <p>{t('privacy_component.timeline_component.timeline_text')}</p>
                    <div className="d-flex">
                      <button className="h-40 bs-success br-30 w-110 h8 text-center border-0 shadow-4 me-2 px-3">
                           {t('allow')} <FontAwesomeIcon icon={faCheck} className="h6 m-0 ms-2" />
                      </button>
                      <button className="h-40 bg-danger br-30 w-110 text-white h8 text-center border-0 shadow-4 me-2 px-3">
                        {t('Delete')} <FontAwesomeIcon icon={faXmark} className="h6 m-0 ms-2 text-white" />
                      </button>
                    </div>
                  </div>
                </NavItem>
                <NavItem as="li" className='py-30'>
                  <div className="w-200">
                    <img src={user} alt="" className="w-100" />
                  </div>
                  <div className="w-270">
               <p>{t('privacy_component.timeline_component.timeline_email_tag')}:</p>
                    <p>{t('privacy_component.timeline_component.timeline_text')}</p>
                    <div className="d-flex">
                      <button className="h-40 bs-success br-30 w-110 h8 text-center border-0 shadow-4 me-2 px-3">
                           {t('allow')} <FontAwesomeIcon icon={faCheck} className="h6 m-0 ms-2" />
                      </button>
                      <button className="h-40 bg-danger br-30 w-110 text-white h8 text-center border-0 shadow-4 me-2 px-3">
                        {t('Delete')} <FontAwesomeIcon icon={faXmark} className="h6 m-0 ms-2 text-white" />
                      </button>
                    </div>
                  </div>
                </NavItem>
              </ul> 
            </div>
          </div>
        </NavItem>

        <NavItem as="li" className=" text-center mt-5 justify-content-center">
          <ButtonBox
            boxwidth="w-140 mt-0 mb-50 mx-auto"
            clicked="Saved"
            name="Save/Next"
          />
        </NavItem>
      </ul>
    </div>
  );
}
