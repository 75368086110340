import Carousel from 'react-bootstrap/Carousel';
import Slider from '../../images/login.png';

function CarouselFade() {
  return (
    <Carousel>
      <Carousel.Item>
        <img src={Slider} alt="" className='d-block w-100' />
      </Carousel.Item>
      <Carousel.Item>
        <img src={Slider} alt="" className='d-block w-100' />
      </Carousel.Item>
      <Carousel.Item>
        <img src={Slider} alt="" className='d-block w-100' />
      </Carousel.Item>
    </Carousel>
  );
}
export default CarouselFade;