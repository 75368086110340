import React from "react";
import { useState } from "react";
import './RegularTheme2Tab.scss';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { IoIosArrowForward } from "react-icons/io";
import { FcCheckmark } from "react-icons/fc"
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai"
import { useTranslation } from "react-i18next";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
// import RegularTheme1Desktop from './regularTheme1\RegularTheme1Desktop/regularTheme1Desktop'

export default function RegularTheme2Tab() {

  return (
    <div>
      <div className="h_950">
        <div>
          <div className="main-content p-3">
            <div className="d-flex align-items-center justify-content-center pt-4 mb-5">
              <div className="toggle_button mb-5">
                <button type="" className="btn1 active_btn">Monthly</button>
                <button type="" className="btn1 ">Yearly</button>
              </div>
            </div>
            <div className="d-flex align-items-center relative">

              <div className="row plan_content mx-auto">
                <div className="col-md-4">
                  <div class="plan_outer">
                    <div class="shape_outer">
                      <div class="shape44_1"></div>
                      <div class="shape44"></div>
                      <div id="base">
                        <div class="price">
                          <h2>$29</h2>
                          <p>/Month</p>
                        </div>
                        <div class="pt-3 px-3 plan_heading d-flex align-items-center justify-content-center">
                          <h1>REGULAR</h1>
                          <p>Ipsum dolor sit amet consect</p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 plan_listing">
                      <ul>
                        <li>
                          <FcCheckmark className="icon" />
                          Lorem ipsum dolor</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Sit amet consect</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Amet consectetur</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Neque sed suspend</li>
                        <li>
                          <RxCross2 className="icon text-danger" />
                          Se sed diam mattis</li>
                        <li>
                          <RxCross2 className="icon text-danger" />
                          Ac sagittis ac
                        </li>
                        <li>
                          <RxCross2 className="icon text-danger" />
                          Sitt isporta vestibulum
                        </li>
                        <li>
                          <RxCross2 className="icon text-danger" />
                          eu velit viverra quis vel
                        </li>
                      </ul>
                    </div>
                    <div class="d-flex justify-content-center">
                      <button class="btn" type="button">Order Now</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div class="plan_outer">
                    <div class="shape_outer">
                      <div class="shape44_1"></div>
                      <div class="shape44"></div>
                      <div id="base">
                        <div class="price">
                          <h2>$99</h2>
                          <p>/Mo</p>
                        </div>
                        <div class="pt-3 px-3 plan_heading d-flex align-items-center justify-content-center">
                          <h1>ADVANCED</h1>
                          <p>Ipsum dolor sit amet consect</p>
                          <button type="" className="green_btn1 -mb-12 mt-2">Most Popular</button>

                        </div>
                      </div>
                    </div>
                    <div className="mt-5 plan_listing">
                      <ul>
                        <li>
                          <FcCheckmark className="icon" />
                          Lorem ipsum dolor</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Sit amet consect</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Amet consectetur</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Neque sed suspend</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Se sed diam mattis</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Ac sagittis ac
                        </li>
                        <li>
                          <RxCross2 className="icon text-danger" />
                          Sitt isporta vestibulum
                        </li>
                        <li>
                          <RxCross2 className="icon text-danger" />
                          eu velit viverra quis vel
                        </li>
                      </ul>
                    </div>
                    <div class="d-flex justify-content-center">
                      <button class="btn" type="button">Order Now</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div class="plan_outer">
                    <div class="shape_outer">
                      <div class="shape44_1"></div>
                      <div class="shape44"></div>
                      <div id="base">
                        <div class="price">
                          <h2>$199</h2>
                          <p>/Mo</p>
                        </div>
                        <div class="pt-3 px-3 plan_heading d-flex align-items-center justify-content-center">
                          <h1>BUSINESS</h1>
                          <p>Ipsum dolor sit amet consect</p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 plan_listing">
                      <ul>
                        <li>
                          <FcCheckmark className="icon" />
                          Lorem ipsum dolor</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Sit amet consect</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Amet consectetur</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Neque sed suspend</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Se sed diam mattis</li>
                        <li>
                          <FcCheckmark className="icon" />
                          Ac sagittis ac
                        </li>
                        <li>
                          <AiFillStar className="icon text_yellow" />
                          Sitt isporta vestibulum
                        </li>
                        <li>
                          <AiFillStar className="icon text_yellow" />
                          eu velit viverra quis vel
                        </li>
                      </ul>
                    </div>
                    <div class="d-flex justify-content-center">
                      <button class="btn" type="button">Order Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





    </div >
  );
}
