import React from 'react'
import iconBluew2 from "../../../../../../images/formicon/icon16.png";
import iconBluew3 from "../../../../../../images/formicon/icon44.png";
import iconBluew4 from "../../../../../../images/formicon/user/icon1.png";
import iconBluew5 from "../../../../../../images/formicon/user/icon9.png";
import iconBluew6 from "../../../../../../images/formicon/user/icon10.png";
import iconBluew7 from "../../../../../../images/formicon/user/icon8.png";
import iconBluew8 from "../../../../../../images/formicon/user/icon8s.png";
import iconBluew9 from "../../../../../../images/formicon/user/icon7.png";
import iconBluew10 from "../../../../../../images/formicon/user/icon4.png";
import iconBluew11 from "../../../../../../images/formicon/user/icon5.png";
import iconBluew12 from "../../../../../../images/formicon/user/icon11.png";
import IntegAddFrom from '../IntegAddFrom';
import icon from "../../../../../../images/formicon/user/icon.png";
import icons from "../../../../../../images/formicon/icon43.png";
import icon1 from "../../../../../../images/formicon/master/icon.png";
import icon2 from "../../../../../../images/formicon/master/icon1.png";
import icon3 from "../../../../../../images/formicon/master/icon2.png";
import icon4 from "../../../../../../images/formicon/master/icon3.png";
import icon5 from "../../../../../../images/formicon/master/icon4.png";
import icon7 from "../../../../../../images/formicon/master/icon5.png";
import icon6 from "../../../../../../images/formicon/icondot.png";
import icon8 from "../../../../../../images/formicon/user/icon6s.png";
import icon9 from "../../../../../../images/formicon/user/icon6.png";
import icon10 from "../../../../../../images/formicon/master/icon6.png";
import icon11 from "../../../../../../images/formicon/master/icon7.png";
import icon12 from "../../../../../../images/formicon/master/icon8.png";
import icon13 from "../../../../../../images/formicon/master/icon9.png";
import ConnectComponent from './ConnectComponent';
import ConnectComponentInput from './ConnectComponentInput';
export default function Master() {
  return (
    <div>
      <div className='row'>
        <div className='col-lg-12'>
          <ul className="customDrop6 customDrop1s max-h-auto customDrop2s w-100 shadow-none position-relative">
            <div className="dropheight h-auto">
              <ConnectComponent Name="Personal Website" icon={icon} />
              <ConnectComponent Name="Profile Widgets" icon={icons} />
              <ConnectComponent Name="1000 Socialite  Site" icon={iconBluew4} />
              <ConnectComponent Name="Coming Soon Beta-List" icon={iconBluew3} />
              <ConnectComponent Name="Landing Page | Queue Launch" icon={iconBluew5} />
              <ConnectComponent Name="Webinar sales page" icon={iconBluew2} />
              <ConnectComponent Name="LMS online Academy" icon={iconBluew6} />
              <ConnectComponent Name="E-magazine Website" icon={iconBluew7} />
              <ConnectComponent Name="Press Release" icon={iconBluew8}/>
              <ConnectComponent Name="E-commerce store" icon={iconBluew9}/>
              <ConnectComponent Name="Video Channel" icon={iconBluew10}/>
              <ConnectComponent Name="Podcast website" icon={iconBluew11}/>
              <ConnectComponent Name="Digital content site" icon={iconBluew12}/>
              <ConnectComponent Name="Fashion niche Landing Page website" icon={icon1}/>
              <ConnectComponent Name="beauty niche Landing Page website" icon={icon2}/>
              <ConnectComponent Name="Fitness niche lead generation site" icon={icon3}/>
              <ConnectComponent Name="nutrition niche Landing Page website" icon={icon4}/>
              <ConnectComponent Name="travel niche Landing Page website" icon={icon5}/>
              <ConnectComponent Name="decor niche Landing Page website" icon={icon7}/>
              <ConnectComponent Name="coaching niche Landing Page website" icon={icon8}/>
              <ConnectComponent Name="entertainment niche Landing Page website" icon={icon9}/>
              <ConnectComponent Name="Finance niche Landing Page website" icon={icon10}/>
              <ConnectComponent Name="spirituality niche Landing Page website" icon={icon11}/>
              <ConnectComponent Name="relationships niche Landing Page website" icon={icon12}/>
              <ConnectComponent Name="animal niche Landing Page website" icon={icon13}/>
              <ConnectComponent Name="Fashion niche lead generation site" icon={icon1}/>
              <ConnectComponent Name="beauty niche lead generation site" icon={icon2}/>
              <ConnectComponent Name="Fitness niche lead generation site" icon={icon3}/>
              <ConnectComponent Name="nutrition niche lead generation site" icon={icon4}/>
              <ConnectComponent Name="travel niche lead generation site" icon={icon5}/>
              <ConnectComponent Name="decor niche lead generation site" icon={icon7}/>
              <ConnectComponent Name="coaching niche lead generation site" icon={icon8}/>
              <ConnectComponent Name="entertainment niche lead generation site" icon={icon9}/>
              <ConnectComponent Name="Finance niche lead generation site" icon={icon10}/>
              <ConnectComponent Name="spirituality niche lead generation site" icon={icon11}/>
              <ConnectComponent Name="relationships niche lead generation site" icon={icon12}/>
              <ConnectComponent Name="animal niche lead generation site" icon={icon13}/>
              <ConnectComponent Name="Other" icon={icon6}/>
              <ConnectComponent Name="Other" icon={icon6}/>
              <ConnectComponentInput Name="Other ?  Type here...." icon={icon6}/>
              <IntegAddFrom />
            </div>
          </ul>
        </div>
      </div>
    </div>
  )
}

