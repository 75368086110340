import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import subscribeUserIcon from "../../../../../../images/subscription/subscribe-user-icon.png";
import subscribeAroundTextImg from "../../../../../../images/subscription/subscribr-text-img.svg";
import subscribeAroundTextWhiteImg from "../../../../../../images/subscription/subscribr-text-theme2-img.png";
import theme5RegularTopLeftDotsImg from "../../../../../../images/subscription/theme5-regular-top-left-dots-img.png";
import theme5RegularTopRightDotsImg from "../../../../../../images/subscription/theme5-regular-top-right-dots-img.png";
import theme5RegularBottomRightDotsImg from "../../../../../../images/subscription/theme5-regular-bottom-right-dots-img.png";
import theme5NeumorphicTopLeftDotsImg from "../../../../../../images/subscription/theme5-neumorphic-top-left-dots-img.png";
import theme5NeumorphicTopRightDotsImg from "../../../../../../images/subscription/theme5-neumorphic-top-right-dots-img.png";
import theme5NeumorphicBottomRightDotsImg from "../../../../../../images/subscription/theme5-neumorphic-bottom-right-dots-img.png";
import theme5GlassmorphicTopLeftDotsImg from "../../../../../../images/subscription/theme5-glassmorphic-top-left-dots-img.png";
import theme5GlassmorphicTopRightDotsImg from "../../../../../../images/subscription/theme5-glassmorphic-top-right-dots-img.png";
import theme5GlassmorphicBottomRightDotsImg from "../../../../../../images/subscription/theme5-glassmorphic-bottom-right-dots-img.png";
import "./RegularTheme6Desktop.scss";
import "../regularTheme6Mobile/RegularTheme6Mobile.scss";
import "../regularTheme6Tab/RegularTheme6Tab.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Form from "react-bootstrap/Form";
export default function RegularTheme6Desktop({
  Regular,
  Neumorphic,
  Glassmorphic,
}) {
  const [isSwitchBtn, setSwitchBtn] = useState(false);
  const switchToggleBtn = () => {
    setSwitchBtn(!isSwitchBtn);
  };
  return (
    <>
      <div
        className="subscription-desktop-theme6"
        style={{
          background: Regular ? "#CBD5E0" : Neumorphic ? "#F0F4F8" : Glassmorphic ? "linear-gradient(326deg, #9595B8 0%, #ECEDF5 46.35%, #C8C9D2 70.83%, #97999D 100%)" : "",
        }}
      >
        <div
          className={`theme-6-main-wrapper  ${
            Regular
              ? "regular-style"
              : Neumorphic
              ? "neumorphic-style"
              : Glassmorphic
              ? "glassmorphic-style"
              : ""
          }`}
        >
            <div
                className={`subscription-desktop-theme6-main-wrapper ${
                    Regular
                        ? "regular-style"
                        : Neumorphic
                            ? "neumorphic-style"
                            : Glassmorphic
                                ? "glassmorphic-style"
                                : ""
                }`}
            >
              <div className={`shadow-wrapper ${
                  Regular
                      ? "regular-style"
                      : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                              ? "glassmorphic-style"
                              : ""
              }`}>
              <div
                  className={`subscribe-weekly-drops ${
                      Regular
                          ? "regular-style"
                          : Neumorphic
                              ? "neumorphic-style"
                              : Glassmorphic
                                  ? "glassmorphic-style"
                                  : ""
                  }`}
              >
                <div className="subscribe-weekly-drops-icon-wrapper">
                  <div
                      className={`img-wrapper  ${
                          Regular
                              ? "regular-style"
                              : Neumorphic
                                  ? "neumorphic-style"
                                  : Glassmorphic
                                      ? "glassmorphic-style"
                                      : ""
                      }`}
                  >
                    <img
                        src={subscribeAroundTextWhiteImg}
                        alt="subscribeAroundTextImg"
                        className="subscribeAroundTextWhiteImg"
                    />
                    <img
                        src={subscribeAroundTextImg}
                        alt="subscribeAroundTextImg"
                        className="subscribeAroundTextBlackImg"
                    />
                    <div className="icon-wrapper">
                      <img
                          src={subscribeUserIcon}
                          alt="subscribeUserIcon"
                          className="subscribeUserIcon"
                      />
                    </div>
                    <p className="pick-poison-text">YOUR CHOICE</p>
                  </div>
                </div>

                <div
                    className={`on-off-buttons-wrapper ${
                        Regular
                            ? "regular-style"
                            : Neumorphic
                                ? "neumorphic-style"
                                : Glassmorphic
                                    ? "glassmorphic-style"
                                    : ""
                    }`}
                >
                  <ul className="on-off-btn-items">
                    <li>
                      <div
                          className={`toggle-switch ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style"
                                          : ""
                          }`}
                      >
                        <input
                            type="checkbox"
                            className="checkbox"
                            name="events"
                            id="events"
                            defaultChecked={isSwitchBtn}
                            onClick={switchToggleBtn}
                        />
                        <label className="label" htmlFor="events">
                          <span className="inner"/>
                          <span className="switch"/>
                        </label>
                      </div>
                      <strong>EVENTS</strong>
                    </li>
                    <li>
                      <div
                          className={`toggle-switch ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style"
                                          : ""
                          }`}
                      >
                        <input
                            type="checkbox"
                            className="checkbox"
                            name="newsletter"
                            id="newsletter"
                            defaultChecked={true}
                            onClick={switchToggleBtn}
                        />
                        <label className="label" htmlFor="newsletter">
                          <span className="inner"/>
                          <span className="switch"/>
                        </label>
                      </div>
                      <strong>NEWSLETTER</strong>
                    </li>
                    <li>
                      <div
                          className={`toggle-switch ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style"
                                          : ""
                          }`}
                      >
                        <input
                            type="checkbox"
                            className="checkbox"
                            name="videoChannel"
                            id="videoChannel"
                            defaultChecked={isSwitchBtn}
                            onClick={switchToggleBtn}
                        />
                        <label className="label" htmlFor="videoChannel">
                          <span className="inner"/>
                          <span className="switch"/>
                        </label>
                      </div>
                      <strong>VIDEO CHANNEL</strong>
                    </li>
                    <li>
                      <div
                          className={`toggle-switch ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style"
                                          : ""
                          }`}
                      >
                        <input
                            type="checkbox"
                            className="checkbox"
                            name="blogPosts"
                            id="blogPosts"
                            defaultChecked={isSwitchBtn}
                            onClick={switchToggleBtn}
                        />
                        <label className="label" htmlFor="blogPosts">
                          <span className="inner"/>
                          <span className="switch"/>
                        </label>
                      </div>
                      <strong>BLOG POSTS</strong>
                    </li>
                    <li>
                      <div
                          className={`toggle-switch ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style"
                                          : ""
                          }`}
                      >
                        <input
                            type="checkbox"
                            className="checkbox"
                            name="podcast"
                            id="podcast"
                            defaultChecked={isSwitchBtn}
                            onClick={switchToggleBtn}
                        />
                        <label className="label" htmlFor="podcast">
                          <span className="inner"/>
                          <span className="switch"/>
                        </label>
                      </div>
                      <strong>PODCAST</strong>
                    </li>
                    <li>
                      <div
                          className={`toggle-switch ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style"
                                          : ""
                          }`}
                      >
                        <input
                            type="checkbox"
                            className="checkbox"
                            name="subscribe"
                            id="subscribe"
                            defaultChecked={isSwitchBtn}
                            onClick={switchToggleBtn}
                        />
                        <label className="label" htmlFor="subscribe">
                          <span className="inner"/>
                          <span className="switch"/>
                        </label>
                      </div>
                      <strong>SUBSCRIBE TO ALL</strong>
                    </li>
                  </ul>
                </div>
                <div
                    className={`signup-form ${
                        Regular
                            ? "regular-style"
                            : Neumorphic
                                ? "neumorphic-style"
                                : Glassmorphic
                                    ? "glassmorphic-style"
                                    : ""
                    }`}
                >
                  <Form>
                    <Form.Group>
                      <div
                          className={`form-group ${
                              Regular
                                  ? "regular-style"
                                  : Neumorphic
                                      ? "neumorphic-style"
                                      : Glassmorphic
                                          ? "glassmorphic-style" :
                                          ""
                          }`}
                      >
                        <Form.Control type="text" placeholder="Type your email address"/>
                        <Button variant="primary" type="submit">
                          SUBMIT
                        </Button>
                      </div>
                    </Form.Group>
                  </Form>
                </div>
              </div>

            </div>
                <img
                    src={theme5RegularTopLeftDotsImg}
                    alt="theme5RegularTopLeftDotsImg"
                    className="theme5RegularTopLeftDotsImg"
                />
                <img
                    src={theme5RegularTopRightDotsImg}
                    alt="theme5RegularTopRightDotsImg"
                    className="theme5RegularTopRightDotsImg"
                />
                <img
                    src={theme5RegularBottomRightDotsImg}
                    alt="theme5RegularBottomRightDotsImg"
                    className="theme5RegularBottomRightDotsImg"
                />

                <img
                    src={theme5NeumorphicTopLeftDotsImg}
                    alt="theme5NeumorphicTopLeftDotsImg"
                    className="theme5NeumorphicTopLeftDotsImg"
                />
                <img
                    src={theme5NeumorphicTopRightDotsImg}
                    alt="theme5NeumorphicTopRightDotsImg"
                    className="theme5NeumorphicTopRightDotsImg"
                />
                <img
                    src={theme5NeumorphicBottomRightDotsImg}
                    alt="theme5NeumorphicBottomRightDotsImg"
                    className="theme5NeumorphicBottomRightDotsImg"
                />

                <img
                    src={theme5GlassmorphicTopLeftDotsImg}
                    alt="theme5GlassmorphicTopLeftDotsImg"
                    className="theme5GlassmorphicTopLeftDotsImg"
                />
                <img
                    src={theme5GlassmorphicTopRightDotsImg}
                    alt="theme5GlassmorphicTopRightDotsImg"
                    className="theme5GlassmorphicTopRightDotsImg"
                />
                <img
                    src={theme5GlassmorphicBottomRightDotsImg}
                    alt="theme5GlassmorphicBottomRightDotsImg"
                    className="theme5GlassmorphicBottomRightDotsImg"
                />
            </div>
        </div>
      </div>
    </>
  );
}
