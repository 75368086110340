import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import closebtn from "../../../../../../images/closebtn.png";
import IntegrationForm from '../IntegrationForm';

export default function ConnectComponentInput(props) {

  const handleClick1 = () => {
    setToggle1(!toggle1);
  };
  const [toggle1, setToggle1] = useState("true");

  return (
    <li className='position-relative m-0'>
      <div className="spansec spansec2">
        <div className="dropdiv d-flex align-items-center" >
          <span className="dropicn">
            <span>
            <img src={props.icon} alt='' className='w-auto' height="14px" />
            </span>
          </span>
          <input type='text' placeholder={props.Name} className='m-0 h-auto' />
        </div>
      </div>
      <div className='d-flex gap-2 sideBtn ms-4'>
        <div className="droptop px-0" id="dropbtn">
          <button className="br-30 bg shadow-4" type="button" onClick={handleClick1}>
            Connected
          </button>
          {toggle1 ?
            <></>
            :
            <div className="top-65 start-0 end-0 menu position-absolute d-block border-0 bg">
              <div className='position-relative z-index-2'>
                <Link
                  className='position-absolute top-0 pe-0 end-0 z-1 me-0'
                  to=""
                  onClick={handleClick1}
                >
                  <img src={closebtn} width="35" alt="" />
                </Link>
                <div className="newdrop10 announcment1s w-100">
                  <IntegrationForm />
                </div>
              </div>
            </div>
          }
        </div>
        <button className='br-30 bg-primary shadow-4'>
          Not Connected
        </button>
        <button className='br-30 bg shadow-4'>
          Reconnect
        </button>
        <button className='br-30 bg shadow-4'>
          Disconnect
        </button>
      </div>
    </li>
  )
}
