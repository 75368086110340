import React from "react";
import '../pages/threeHoursBusinessLaunch/emailSignature/EmailSignatureStep.scss';



export default function EmailTempBm() {

  return (
    <div>
      <div className="w-100 shadow-4 py-30 pe-30 d-flex flex-wrap">
        <div className="d-flex justify-content-between">
          <button className="br-primary h8 py-2 border-0 lh-normal px-4 fw-bold h-40">DISCLAIMER</button>
          <div className="w-75 text-center">
            <p className="h8">Thank you for subscribing to us and opting in. You received this email to keep you up-to-date on important news, promos, and changes to your account and services with us.</p>
          </div>
        </div>
        <div className="w-90 ms-auto text-center">
          <div className='container privacyTab privacyTab2 mb-4 mt-3'>
            <div id="myTab" className='slidetab d-flex' role="tablist">
              <div className='p-2'>
                <button className="br-40 w-125 h10s h-33 border-0"> Update Email<br /> Preferences</button>
              </div>
              <div className='p-2'>
                <button className="br-40 w-125 h10s h-33 border-0" >Update your<br /> profile</button>
              </div>
              <div className='p-2'>
                <button className="br-40 w-125 h10s h-33 border-0">Privacy<br /> Policy</button>
              </div>
              <div className='p-2'>
                <button className="br-40 w-125 h10s h-33 border-0" >Unsubscribe</button>
              </div>
            </div>
          </div>
          <p className="h8 mb-0">© 2023| 9 to 5 Escapee<br /> 2967 Dundas STREET WEST  SUITE # 1220 Toronto, ONTARIO  M6P 1Z2 ,<br /> CANADA<br /> Powered by 9 to 5 Escapee</p>
        </div>
      </div>
    </div>
  );
} 
