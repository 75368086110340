import React from "react";
import '../../../../login/login.scss';
import Email from '../../../../auth/verification/EmailVerify';
import SMS from '../../../../auth/verification/SmsVerify';
import FingerPrint from '../../../../auth/verification/FingerpPrintVerify';
import FaceID from '../../../../auth/verification/FaceIDVerify';
import { useTranslation } from "react-i18next";
import { NavItem } from 'react-bootstrap';
export default function FactorTwo() {
    const { t, i18n } = useTranslation();

  return (
    <div className="text-center">
      <ul className="w-500 mx-auto text-start mb-4 pb-2">
        <NavItem as="li">
          <label className="mb-2">{t('privacy_component.factor_authentication_component.recovery_email')}</label>
          <input
            placeholder="dyna@fashion1.9to5escapee.com"
            className="shadow-5in br-10 w-100 mb-4"
            type="password"
          />
        </NavItem>
        <NavItem as="li">
          <label className="mb-2">{t('privacy_component.factor_authentication_component.recovery_phone_no')}.</label>
          <input
            placeholder="+634 232 435 22"
            className="shadow-5in br-10 w-100 mb-4"
            type="phone"
          />
        </NavItem>
      </ul>
      <h4 className="mb-4">{t('privacy_component.factor_authentication_component.confirm_your_2_factor_authentications')}</h4>
      <h6>{t('privacy_component.factor_authentication_component.verification_via')}</h6>
      <div className='d-flex flex-column-reverse'>
              <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="email" role="tabpanel" aria-labelledby="email">
                      <Email />
                  </div>
                  <div className="tab-pane fade" id="sms" role="tabpanel" aria-labelledby="sms">
                      <SMS />
                  </div>
                  <div className="tab-pane fade" id="fingerprint" role="tabpanel" aria-labelledby="fingerprint">
                      <FingerPrint />
                  </div>
                  <div className="tab-pane fade" id="faceid" role="tabpanel" aria-labelledby="faceid">
                      <FaceID />
                  </div>
              </div>
              <div className='w-540 mx-auto'>
                  <div className='d-none d-lg-block'>
                      <div className='scroll-bar w-100 shadow-4in br-20 my-30'></div>
                  </div>
                  <div className='login-tab  mb-4 pb-3 mt-5 mt-lg-0'>
                      <ul className="nav" id="myTab" role="tablist">
                          <NavItem as="li" className='pe-3'>
                              <div className='btn-layer br-30 bg'>
                                  <button className="br-20 w-140 active h-90 border-0 d-flex justify-content-center align-items-center" id="email" data-bs-toggle="tab" data-bs-target="#email" type="button" role="tab" aria-controls="email" aria-selected="true">
                                       {t('privacy_component.factor_authentication_component.email')}
                                  </button>
                              </div>
                          </NavItem>
                          <NavItem as="li" className="pe-3">
                              <div className='btn-layer br-30 bg'>
                                  <button className="br-20 w-140 h-90 border-0" id="sms" data-bs-toggle="tab" data-bs-target="#sms" type="button" role="tab" aria-controls="sms" aria-selected="false">
                                       {t('privacy_component.factor_authentication_component.sms')}
                                  </button>
                              </div>
                          </NavItem>
                          <NavItem as="li" className="pe-3">
                              <div className='btn-layer br-30 bg'>
                                  <button className="br-20 w-140 h-90 border-0" id="fingerprint" data-bs-toggle="tab" data-bs-target="#fingerprint" type="button" role="tab" aria-controls="fingerprint" aria-selected="false">
                                    {t('privacy_component.factor_authentication_component.fingerprint')}
                                  </button>
                              </div>
                          </NavItem>
                          <NavItem as="li" className="pe-0">
                              <div className='btn-layer br-30 bg'>
                                  <button className="br-20 w-140 h-90 border-0" id="faceid" data-bs-toggle="tab" data-bs-target="#faceid" type="button" role="tab" aria-controls="faceid" aria-selected="false">
                                       {t('privacy_component.factor_authentication_component.face_id')}
                                  </button>
                              </div>
                          </NavItem>
                      </ul>
                  </div>
              </div>
      </div>
    </div>
  );
}
