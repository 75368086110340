import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function TabSlider() {
  const responsive = {
    desktop: {
    breakpoint: { max: 3000, min: 1366 },
    items: 9,
    slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
    breakpoint: { max: 1366, min: 768 },
    items: 5,
    slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
    }
};
  return (
    <Carousel responsive={responsive} autoPlay={false}  autoPlaySpeed={2000} transitionDuration={500}>
        <div className='p-2'>
          <button className="br-20 w-100 h-90 border-0 active" id="tab1" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true">Fashion</button>
        </div>
        <div className='p-2'>
          <button className="br-20 w-100 h-90 border-0" id="tab2" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false">Beauty</button>
        </div>
        <div className='p-2'>
          <button className="br-20 w-100 h-90 border-0" id="tab3" data-bs-toggle="tab" data-bs-target="#tab3" type="button" role="tab" aria-controls="tab3" aria-selected="false">NUTRITION</button>
        </div>
        <div className='p-2'>
          <button className="br-20 w-100 h-90 border-0" id="tab4" data-bs-toggle="tab" data-bs-target="#tab4" type="button" role="tab" aria-controls="tab4" aria-selected="false">FITNESS</button>
        </div>
        <div className='p-2'>
          <button className="br-20 w-100 h-90 border-0 react-toastify" id="tab5" data-bs-toggle="tab" data-bs-target="#tab5" type="button" role="tab" aria-controls="tab5" aria-selected="true">COACHING</button>
        </div>
        <div className='p-2'>
          <button className="br-20 w-100 h-90 border-0" id="tab6" data-bs-toggle="tab" data-bs-target="#tab6" type="button" role="tab" aria-controls="tab6" aria-selected="false">HOME DECOR</button>
        </div>
        <div className='p-2'>
          <button className="br-20 w-100 h-90 border-0" id="tab7" data-bs-toggle="tab" data-bs-target="#tab7" type="button" role="tab" aria-controls="tab7" aria-selected="false">TRAVEL</button>
        </div>
        <div className='p-2'>
          <button className="br-20 w-100 h-90 border-0" id="tab8" data-bs-toggle="tab" data-bs-target="#tab8" type="button" role="tab" aria-controls="tab8" aria-selected="false">SPIRITUALITY</button>
        </div>
        <div className='p-2'>
          <button className="br-20 w-100 h-90 border-0" id="tab9" data-bs-toggle="tab" data-bs-target="#tab9" type="button" role="tab" aria-controls="tab9" aria-selected="false">EVENT PLANNING</button>
        </div>
        <div className='p-2'>
          <button className="br-20 w-100 h-90 border-0" id="tab10" data-bs-toggle="tab" data-bs-target="#tab10" type="button" role="tab" aria-controls="tab10" aria-selected="false">FINANCE</button>
        </div>
        <div className='p-2'>
          <button className="br-20 w-100 h-90 border-0" id="tab11" data-bs-toggle="tab" data-bs-target="#tab11" type="button" role="tab" aria-controls="tab11" aria-selected="false">Parenting</button>
        </div>
        <div className='p-2'>
          <button className="br-20 w-100 h-90 border-0" id="tab12" data-bs-toggle="tab" data-bs-target="#tab12" type="button" role="tab" aria-controls="tab12" aria-selected="false">Animal</button>
        </div>
    </Carousel>
  )
}