import React, { useState, useEffect } from 'react';

import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import Tab from 'react-bootstrap/Tab';

import Nav from 'react-bootstrap/Nav';

import { useTranslation } from "react-i18next";



export default function TabsComponent() {
  const { t, i18n } = useTranslation();
 

  return (
    <div className='tab-section'>




        

                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="d-flex flex-column-reverse ">


                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className=" tab-himself-text">
                            <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}
                            </p>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div className=" tab-himself-text">
                            <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="three">
                          <div className=" tab-himself-text">
                            <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="forr">
                          <div className=" tab-himself-text">
                            <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                      <div className="d-flex align-items-center justify-center  w-100 mb-0">

                        <Nav variant="pills" className="w-100 d-flex align-items-center justify-content-center ">
                          <ul className="d-flex   my-4 pb-2 align-items-center modal-tabs justify-content-center">

                            <Nav.Item as="li" className="me-0">
                              <Nav.Link className=" m-0 p-0 design-about  border-0" eventKey="first">                  <span className="d-flex align-items-center "> {t('profile_section.who_am_i')}</span>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="margin-left-scnd margin-left-btn" >
                              <Nav.Link className=" m-0 p-0 design-about  border-0" eventKey="second">    <span className="d-flex align-items-center ">
                                {t('profile_section.what_i_do')}</span> </Nav.Link>

                            </Nav.Item >
                            <Nav.Item as="li" className="margin-left-scnd margin-left-btn" >
                              <Nav.Link className=" m-0 p-0 design-about  border-0" eventKey="three">                  <span className="d-flex align-items-center "> {t('profile_section.who_is_my_service_for')} </span></Nav.Link>

                            </Nav.Item>
                            <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
                              <Nav.Link className=" m-0 p-0 design-about  border-0" eventKey="forr">
                                <span className="d-flex align-items-center ">
                                  {t('profile_section.why_work_with_me')}</span>
                              </Nav.Link>

                            </Nav.Item>
                          </ul>
                        </Nav>
                      </div>


                    </div>
                  </Tab.Container>
              
    </div>



  );
}
