import React from "react";
import { Link } from 'react-router-dom';
import { ReactComponent as VideoIcon } from "../../../../../../images/icons/iconvid.svg";
import ButtonBox from "../../../../../global/ButtonBox";
import SocialSliderPint from "../../../../../global/socialSlider/SocialSliderPint";
import SocialSliderTch from "../../../../../global/socialSlider/SocialSliderTch";
import SocialSliderTele from "../../../../../global/socialSlider/SocialSliderTele";
import SocialSliderRed from "../../../../../global/socialSlider/SocialSliderRed";
// import SocialSliderTik from "../../../../../global/socialSlider/SocialSliderTik";
import qrcode from '../../../../../../images/qrcode.png';
import { ReactComponent as Download } from "../../../../../../images/icons/icon29.svg";
import { ReactComponent as IconBluew1 } from "../../../../../../images/svgIcon/icon13.svg";
import { ReactComponent as IconBluew7 } from "../../../../../../images/svgIcon/icon19.svg";
import { ReactComponent as IconBluew8 } from "../../../../../../images/svgIcon/icon20.svg";
import { ReactComponent as IconBluew9 } from "../../../../../../images/svgIcon/icon21.svg";
import { NavItem } from 'react-bootstrap';

import { useTranslation } from "react-i18next";
import PinterestBanner from "../../../../../global/socialBanner/PinterestBanner";
import TwitchBanner from "../../../../../global/socialBanner/TwitchBanner";
import RedditBanner from "../../../../../global/socialBanner/RedditBanner";
import TiktokBanner from "../../../../../global/socialBanner/TiktokBanner";
import TelegramBanner from "../../../../../global/socialBanner/TelegramBanner";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import './ContactUsSectionThemes.scss';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { faLock } from "@fortawesome/free-solid-svg-icons";
import active_vector from '../../../../../../images/active-vector.png'
export default function Social3() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="SocialMedia d-none">
        <ul>
          <NavItem as="li" className="shadow-4 br-20 my-4 px-3">
            <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-5">{t('profile_section.choose_your_pinterest_business_profile_banner_design')}  <b className="ms-2">{t('profile_section._800_X_450_pixels')}</b></h6>
              <Link to="" className="text-primary">
                <VideoIcon className="w-100x h-auto" />
              </Link>
            </div>
            <div className="px-5 mb-4 mt-3">
              <SocialSliderPint />
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.pinterest_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 12.9999C0.5 18.1183 3.57785 22.5154 7.98212 24.4487C7.94696 23.5758 7.97587 22.528 8.19969 21.5783C8.44002 20.5636 9.80805 14.7672 9.80805 14.7672C9.80805 14.7672 9.40874 13.9691 9.40874 12.7895C9.40874 10.9371 10.4823 9.55366 11.8194 9.55366C12.9564 9.55366 13.5057 10.4076 13.5057 11.4303C13.5057 12.5732 12.7767 14.2828 12.4018 15.8663C12.0886 17.1922 13.0666 18.2737 14.3747 18.2737C16.743 18.2737 18.3381 15.2319 18.3381 11.6279C18.3381 8.88835 16.4929 6.83782 13.1369 6.83782C9.34517 6.83782 6.98303 9.66547 6.98303 12.824C6.98303 13.913 7.30411 14.681 7.80703 15.2757C8.03827 15.5488 8.0704 15.6587 7.98671 15.9723C7.92675 16.2023 7.78906 16.756 7.73203 16.9754C7.64883 17.292 7.39229 17.4052 7.10617 17.2883C5.35973 16.5753 4.54637 14.6628 4.54637 12.5129C4.54637 8.96207 7.54102 4.70438 13.4799 4.70438C18.2522 4.70438 21.3933 8.1578 21.3933 11.8648C21.3933 16.7683 18.6672 20.4316 14.6487 20.4316C13.2993 20.4316 12.0299 19.7021 11.595 18.8735C11.595 18.8735 10.8693 21.7535 10.7156 22.3097C10.4506 23.2734 9.93187 24.2367 9.45757 24.9874C10.6077 25.3275 11.8008 25.5001 13.0001 25.5C19.9027 25.5 25.4994 19.9036 25.4994 12.9999C25.4994 6.09643 19.9027 0.5 13.0001 0.5C6.09692 0.5 0.5 6.09643 0.5 12.9999Z" fill="#CB1F27" />
                  </svg>
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.pinterest_business_username')}</h6>
                <input placeholder={t('profile_section.enter_username')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.pinterest_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.pinterest_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_pinterest_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_pinterest_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className="shadow-4 br-20 my-4 px-3">
            <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-5">{t('profile_section.choose_your_twitch_business_profile_banner_design')}<b className="ms-2">{t('profile_section._800_X_450_pixels')}</b></h6>
              <Link to="" className="text-primary">
                <VideoIcon className="w-100x h-auto" />
              </Link>
            </div>
            <div className="px-5 mb-4 mt-3">
              <SocialSliderTch />
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.twitch_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew7 />
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.twitch_business_account_username')}</h6>
                <input placeholder={t('profile_section.enter_username')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.twitch_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.twitch_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_twitch_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_twitch_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className="bdrnone shadow-4 br-20 my-4 px-3">
            {/* <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-4">Choose Your TikTok Business Channel | Banner Design2048 X 1152 PIXELS
              <b className="ms-2">{t('profile_section._2048_X_1152_pIixels')}</b></h6>
              <Link to="" className="text-primary">
              <VideoIcon className="w-100x h-auto" />
              </Link>
              </div>
              <div className="px-5 mb-4 mt-3">
                <SocialSliderTik />
              </div> */}
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.tikTok_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew8 />
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.tikTok_business_account_username')}</h6>
                <input placeholder={t('profile_section.enter_userman')} className="w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.tikTok_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.tikTok_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_tiktok_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_tiktok_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className="shadow-4 br-20 my-4 px-3">
            <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-5">{t('profile_section.choose_your_telegram_business_profile_banner_design')} <b className="ms-2">{t('profile_section._512_X_512_pixels')}</b></h6>
              <Link to="" className="text-primary">
                <VideoIcon className="w-100x h-auto" />
              </Link>
            </div>
            <div className="px-5 mb-4 mt-3">
              <SocialSliderTele />
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.telegram_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 0.5C6.09436 0.5 0.5 6.09516 0.5 13C0.5 19.9048 6.09516 25.5 13 25.5C19.9056 25.5 25.5 19.9048 25.5 13C25.5 6.09516 19.9048 0.5 13 0.5ZM19.1395 9.06371L17.0879 18.7315C16.9363 19.4169 16.5282 19.5831 15.9589 19.2605L12.8339 16.9573L11.3266 18.4089C11.1605 18.575 11.0194 18.7161 10.6968 18.7161L10.9185 15.5355L16.7097 10.3032C16.9621 10.0815 16.654 9.95565 16.321 10.1774L9.16371 14.6831L6.07903 13.7202C5.40887 13.5089 5.39355 13.05 6.22016 12.7274L18.2718 8.07984C18.8315 7.87823 19.3202 8.21613 19.1387 9.0629L19.1395 9.06371Z" fill="#3797DD" />
                  </svg>
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.telegram_business_username')}</h6>
                <input placeholder={t('profile_section.enter_username')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.telegram_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.telegram_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_telegram_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_telegram_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className="shadow-4 br-20 my-4 px-3">
            <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-5">{t('profile_section.choose_your_reddit_business_profile_banner_design')}<b className="ms-2">{t('profile_section._1920_X_384_pixels')}</b></h6>
              <Link to="" className="text-primary">
                <VideoIcon className="w-100x h-auto" />
              </Link>
            </div>
            <div className="px-5 mb-4 mt-3">
              <SocialSliderRed />
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.reddit_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew9 />
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.reddit_business_username')}</h6>
                <input placeholder={t('profile_section.enter_username')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.reddit_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.reddit_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_reddit_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_reddit_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className=" text-center mt-5 justify-content-center bdrnone">
            <ButtonBox
              boxwidth="w-140 mt-0 mb-50 mx-auto"
              clicked="Saved"
              name="Save/Next"
            />
          </NavItem>
        </ul>
      </div>
      <div>
        <h4 className="text-center">
          Switch the Style
        </h4>
        <Tab.Container id="left-tabs-example2" defaultActiveKey="Regular">
          <div className="d-flex flex-column-reverse">
            <Tab.Content>
              <Tab.Pane eventKey="Regular">
                <div className="social-reguler">
                  <PinterestBanner />
                  <TwitchBanner />
                  <TiktokBanner />
                  <TelegramBanner />
                  <RedditBanner />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Neumorphic">
                <div className="social-neumorphic">
                  <PinterestBanner />
                  <TwitchBanner />
                  <TiktokBanner />
                  <TelegramBanner />
                  <RedditBanner />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Glassmorphic">
                <div className="social-glassmorphic">
                  <PinterestBanner />
                  <TwitchBanner />
                  <TiktokBanner />
                  <TelegramBanner />
                  <RedditBanner />
                </div>
              </Tab.Pane>
            </Tab.Content>
            <div className="d-flex align-items-center justify-content-between services-work  w-100 mb-0 ">
              <Nav className="w-100 d-flex  align-items-center justify-content-center">
                <ul className="d-flex justify-content-center my-4 pb-2 items-center">
                  <Nav.Item as="li" className="me-0">
                    <Nav.Link className="m-0 p-0 border-0 design-one design-one-reg" eventKey="Regular">
                      <div className="active-sign">
                        <div className="active-icon-color " >
                          <img src={active_vector} className="active-vector" alt="active-icon" ></img>
                        </div>
                      </div>
                      <span>Regular</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="margin-left-scnd pl-3">
                    <Nav.Link className="m-0 p-0 border-0 design-two ml-3" eventKey="Neumorphic">
                      <div className="active-sign">
                        <div className="active-icon-color-second " >
                          <img src={active_vector} className="active-vector" alt="active-icon" ></img>
                        </div>
                      </div>  <span>Neumorphic <FontAwesomeIcon className="lock" icon={faLock} ></FontAwesomeIcon> </span>
                    </Nav.Link>

                  </Nav.Item>
                  <Nav.Item as="li" className="margin-left-scnd pl-3">
                    <Nav.Link className="m-0 p-0 border-0 design-three ml-3" eventKey="Glassmorphic">
                      <div className="active-sign">
                        <div className="active-icon-color-third " >
                          <img src={active_vector} className="active-vector" alt="active-icon" ></img>
                        </div>
                      </div>      <span>Glassmorphic <FontAwesomeIcon className="lock" icon={faLock} ></FontAwesomeIcon> </span>

                    </Nav.Link>

                  </Nav.Item>
                </ul>
              </Nav>
            </div>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
}
