import React from "react";
import RadioOff from "../../../../global/RadioOff";
import RadioOn from "../../../../global/RadioOn";
import ButtonBox from "../../../../global/ButtonBox";
import { useTranslation } from "react-i18next";
import { NavItem } from 'react-bootstrap';

export default function EmailOne() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <ul className="">
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.comments')}</h5>
            <p>
            {t('privacy_component.email_notifications_component.comments_post')} <br/> {t('privacy_component.email_notifications_component.comments_replies')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="comments1" name="comments" />
            <RadioOff id="comments2" name="comments" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.tags')}</h5>
            <p>
            {t('privacy_component.email_notifications_component.tag_post')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="tags1" name="tags" />
            <RadioOff id="tags2" name="tags" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.friends_requests')}</h5>
            <p>
            {t('privacy_component.email_notifications_component.friends_requests_post')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="requests1" name="requests" />
            <RadioOff id="requests2" name="requests" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.groups')}</h5>
            <p>
            {t('privacy_component.email_notifications_component.groups_post')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="groups1" name="groups" />
            <RadioOff id="groups2" name="groups" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.forums')}</h5>
            <p>
            {t('privacy_component.email_notifications_component.forums_post')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="forums1" name="forums" />
            <RadioOff id="forums2" name="forums" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.events')}</h5>
            <p>
            {t('privacy_component.email_notifications_component.events_post')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="events1" name="events" />
            <RadioOff id="events2" name="events" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.marketplace')}</h5>
            <p>
            {t('privacy_component.email_notifications_component.marketplace_post')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="marketplace1" name="marketplace" />
            <RadioOff id="marketplace2" name="marketplace" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.notifications_sound')}</h5>
            <p>
            {t('privacy_component.email_notifications_component.notifications_sound_post')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="sound1" name="sound" />
            <RadioOff id="sound2" name="sound" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.notifications_email')}</h5>
            <p>
            {t('privacy_component.email_notifications_component.notifications_email_post')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="emailtb1" name="emailtb" />
            <RadioOff id="emailtb2" name="emailtb" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.friends_birthdays')}</h5>
            <p>
            {t('privacy_component.email_notifications_component.friends_birthdays_post')} <br />{t('privacy_component.email_notifications_component.friends_birthdays_replies')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="birthdays1" name="birthdays" />
            <RadioOff id="birthdays2" name="birthdays" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.chat_message_sound')}</h5>
            <p>
            {t('privacy_component.email_notifications_component.chat_message_sound_post')} <br/> {t('privacy_component.email_notifications_component.chat_message_sound_replies')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="chatab1" name="chatab" />
            <RadioOff id="chatab2" name="chatab" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className=" text-center mt-5 justify-content-center">
          <ButtonBox
            boxwidth="w-140 mt-0 mb-50 mx-auto"
            clicked="Saved"
            name="Save/Next"
          />
        </NavItem>
      </ul>
    </div>
  );
}
