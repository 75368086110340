import React, { useState } from 'react';
import SocialSliderLK from "../../global/socialSlider/SocialSliderLinkd";
// import { ReactComponent as IconBluew7 } from "../../../images/social/linkedin.svg";
// import { ReactComponent as IconBluew8 } from "../../../images/social/icon45.svg";
import img23 from "../../../images/iconVid2.png";
import img232 from "../../../images/social/img8.png";
import VideoViewSocial from '../../global/VideoViewSocial';
import {useDispatch} from "react-redux";
import { socialBannerVideoOpen } from '../../../redux/actions/videoAction';
import iconBluew7 from "../../../images/social/icon/linkedin.png";
import iconBluew8 from "../../../images/social/icon/icon45.png";
export default function LinkedinBanner() {
  const dispatch = useDispatch();
  const handleClick9 = () => {
    dispatch(socialBannerVideoOpen());
    setToggle9(!toggle9);
  };
  const [toggle9, setToggle9] = useState("true");
  return (
        <div className="newsocial pe-4 mb-4">
          <div className="d-flex justify-content-start align-items-end pt-5">
            <div className="tophead me-4">
                <div className="inrhead">
                  Choose your Linkedin  profile banner  design    |2256 X 382 |
                </div>
            </div>
            {/* <img src={img23} alt="" width={138} /> */}
          </div>
          <div className="SocialMedia downarrow py-4 px-5 shadow-4 bg z1">
          <SocialSliderLK />
          </div>
          <div className="socialdown mt-5 z1 position-relative">
            <div className="row mx-0 w-100">
              <div className="col-lg-8 announcment1 d-flex flex-wrap pt-100">
                <div className="dropup ps-5">
                  <button className="border-0 bg-transparent" type="button" id="dropbtn" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="icon67">
                      <img src={iconBluew7} alt='' />
                    </div>
                  </button>
                  <ul className="dropdown-menu pb-4 pr-5 border-0 bg-transparent dropopen" aria-labelledby="dropbtn">
                    <div className="newdrop9">
                      <div className="dropbtn9">
                        <button>Linkedin Business Account</button>
                      </div>
                    </div>
                  </ul>
                </div>
                <div className="w-100 d-flex mb-28 justify-content-end announment6">
                  <div className="col-lg-4 pe-3 z1">
                    <div className="announce3">
                    Linkedin Registration : LINK
                    </div>
                  </div>
                  <div className="col-lg-1 px-0 d-flex justify-content-center align-items-center announmentLin">
                    <div className="dropup">
                      <button className="border-0 bg-transparent ps-0" type="button" id="dropbtn" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="icon67">
                          <img src={iconBluew7} alt='' />
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 d-flex nav-item2">
                      <label>Linkedin Business ID</label>
                      <div className="w-75 z1">
                        <span className="inputspan">
                          <input className="announcment shadow-5in w-100" type="text" placeholder="enter Username here..." />
                        </span>
                      </div>
                  </div>
                </div>
                <div className="w-100 d-flex mb-28 justify-content-end announment6">
                  <div className="col-lg-4 pe-3 z1">
                    <div className="announce3">
                    Linkedin Pixel  Access : LINK
                    </div>
                  </div>
                  <div className="col-lg-1 px-0 d-flex justify-content-center align-items-center announmentLin">
                    <div className="dropup">
                      <button className="border-0 bg-transparent ps-0" type="button" id="dropbtn" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="icon67">
                          <img src={iconBluew7} alt='' />
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 d-flex nav-item2">
                    <label>Linkedin Pixel ID</label>
                    <div className="w-75 z1">
                      <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder="enter Pixel ID here..." />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-100 d-flex mb-28 justify-content-end announment6">
                  <div className="col-lg-4 pe-3 z1">
                    <div className="announce3 ">
                      Bio Access : LINK
                    </div>
                  </div>
                  <div className="col-lg-1 px-0 d-flex justify-content-center align-items-center announmentLin">
                    <div className="dropup">
                      <button className="border-0 bg-transparent ps-0" type="button" id="dropbtn" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="icon67">
                          <img src={iconBluew7} alt='' />
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 d-flex nav-item2">
                    <label>Bio Link</label>
                    <div className="w-75 z1">
                      <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder="enter The Bio Link you want to Add on your Profile" />
                      </span>
                    </div>
                </div>
                </div>
                <div className="w-100 d-flex mb-0 justify-content-end announment6">
                  <div className="col-lg-4 pe-3 z1">
                    <div className="announce3 ">
                      “QR Code”  Generator
                    </div>
                  </div>
                  <div className="col-lg-1 px-0 d-flex justify-content-center align-items-center announmentLin">
                    <div className="dropup">
                      <button className="border-0 bg-transparent ps-0" type="button" id="dropbtn" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="icon67">
                          <img src={iconBluew7} alt='' />
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 d-flex nav-item2">
                    <label>Linkedin QR Code</label>
                    <div className="w-75 z1">
                      <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder="enter  QR Code Title | Label Here" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex flex-column position-relative">
                {toggle9 ?
                  <img src={img23} alt="" width={138} className="position-absolute top-0 end-0 VideoIcon" onClick={handleClick9} />
                  :
                  <div className="mb-3 w-100">
                    <VideoViewSocial handleClick9={handleClick9}/> 
                  </div>
                }
                <div className='d-flex mt-auto'>
                  <div className="dropup downbnt mt-auto ms-4">
                    <button className="border-0 bg-transparent" type="button" id="dropbtn" data-bs-toggle="dropdown" aria-expanded="false">
                      <div className="icon67">
                      <img src={iconBluew8} alt='' />
                      </div>
                    </button>
                    <ul className="dropdown-menu pr-5 border-0 bg-transparent dropopen1" aria-labelledby="dropbtn">
                      <div className="newdrop10">
                        <img src={img232} alt="" />
                      </div>
                    </ul>
                  </div>
                  <div className="newannous1 d-flex justify-content-end align-itmes-center mt-auto ms-auto">
                    <h6 className="ml-auto">the QR – <br/> stands for <br/>“Quick Response” A type of Barcode that stores information which can be read by a digital device.</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
  );
}
