import React from 'react';
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function IntegSlider() {
//   const responsive = {
//     desktop: {
//     breakpoint: { max: 3000, min: 1366 },
//     items: 6,
//     slidesToSlide: 1 // optional, default to 1.
//     },
//     tablet: {
//     breakpoint: { max: 1366, min: 768 },
//     items: 5,
//     slidesToSlide: 1 // optional, default to 1.
//     },
//     mobile: {
//     breakpoint: { max: 700, min: 0 },
//     items: 1,
//     slidesToSlide: 1 // optional, default to 1.
//     }
// };
  return (
    <div className='d-flex justify-content-center integTab4'>
        <div className='py-2 px-1'>
          <button className="br-30 w-110 h-50s border-0 active" id="googleseo" data-bs-toggle="tab" data-bs-target="#googleseo" type="button" role="tab" aria-controls="googleseo" aria-selected="true">Google Seo</button>
        </div>
        <div className='py-2 px-1'>
          <button className="br-30 w-110 h-50s border-0" id="social08" data-bs-toggle="tab" data-bs-target="#social08" type="button" role="tab" aria-controls="social08" aria-selected="true">Social Media</button>
        </div>
        <div className='py-2 px-1'>
          <button className="br-30 w-110 h-50s border-0" id="usermedia08" data-bs-toggle="tab" data-bs-target="#usermedia08" type="button" role="tab" aria-controls="usermedia08" aria-selected="true">User | Website</button>
        </div>
        <div className='py-2 px-1'>
          <button className="br-30 w-110 h-50s border-0" id="master08" data-bs-toggle="tab" data-bs-target="#master08" type="button" role="tab" aria-controls="master08" aria-selected="false">Master | Sites</button>
        </div>
        <div className='py-2 px-1'>
          <button className="br-30 w-110 h-50s border-0" id="payment08" data-bs-toggle="tab" data-bs-target="#payment08" type="button" role="tab" aria-controls="payment08" aria-selected="false">Payments</button>
        </div>
        <div className='py-2 px-1'>
          <button className="br-30 w-110 h-50s border-0" id="affiliate08" data-bs-toggle="tab" data-bs-target="#affiliate08" type="button" role="tab" aria-controls="affiliate08" aria-selected="false">All Affiliates</button>
        </div>
        <div className='py-2 px-1'>
          <button className="br-30 w-110 h-50s border-0 react-toastify" id="other08" data-bs-toggle="tab" data-bs-target="#other08" type="button" role="tab" aria-controls="other08" aria-selected="true">Others</button>
        </div>
    </div>
  )
}