import React from "react";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
// import { Link } from 'react-router-dom';
import ButtonBox from '../../../global/ButtonBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import ListFavouritesOne from "./ListFavouritesSingle/ListFavouritesOne";
import ListFavouritesTwo from "./ListFavouritesSingle/ListFavouritesTwo";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';

export default function ListFavouritesTab() {
  const { t, i18n } = useTranslation();
  let {isVideoVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };
  return (
    <div>
      <div className="w-780 mb-4 align-items-center mx-auto d-flex flex-column-reverse justify-content-between">     
        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto mb-5">
        <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
          <h3 className="mb-3 d-flex justify-content-between w-600 pe-5">{t('profile_section.enter_your_profile_details')}  <small>
            {t('step')}  <b>7</b> of 11</small></h3>
          <div className="ProgressBar">
            <ul>
                <NavItem as="li" className="active" id="profile"></NavItem>
                <NavItem as="li" className="active" id="chat"></NavItem>
                <NavItem as="li" className="active" id="location"></NavItem>
                <NavItem as="li" className="active" id="user"></NavItem>
                <NavItem as="li" className="active" id="briefcase"></NavItem>
                <NavItem as="li" className="active" id="graduation-cap"></NavItem>
                <NavItem as="li" className="active" id="favorite"></NavItem>
                <NavItem as="li" id="award"></NavItem>
                <NavItem as="li" id="connectivity"></NavItem>
                <NavItem as="li" id="lock"></NavItem>
                <NavItem as="li" id="eye"></NavItem>
            </ul>
          </div>
          <div className="d-flex flex-column-reverse">
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade listOne show active" id="listOne" role="tabpanel" aria-labelledby="listOne">
                  <ListFavouritesOne />
                </div>
                <div className="tab-pane fade listTwo" id="listTwo" role="tabpanel" aria-labelledby="listTwo">
                  <ListFavouritesTwo />
                </div>
            </div>
            <span className="d-flex align-items-center justify-content-between w-100 mb-0 TabBtn">
              <div id="myTab1" className='w-100' role="tablist">
                <ul className="d-flex justify-content-center my-4 pb-2">
                  <NavItem as="li" className="me-5">
                    <button className="listOne m-0 p-0 firsttab border-0 active" id="listOne" data-bs-toggle="tab" data-bs-target="#listOne" type="button" role="tab" aria-controls="listOne" aria-selected="true">
                      <span>1</span>
                    </button>
                  </NavItem>
                  <NavItem as="li" className="ms-4">
                    <button className="listTwo m-0 p-0 border-0 sectab " id="listTwo" data-bs-toggle="tab" data-bs-target="#listTwo" type="button" role="tab" aria-controls="listTwo" aria-selected="false">
                      <span>2</span>
                    </button>
                  </NavItem>
                </ul>
              </div>
            </span>
          </div>
          
        </div>
        <span className="d-flex align-items-center justify-content-between w-100 mb-4">
          <div id="myTab1" className='' role="tablist">
            <button className="listOne buttonArrow me-3 text-body border-0 bg br-10 firsttab  active" id="listOne" data-bs-toggle="tab" data-bs-target="#listOne" type="button" role="tab" aria-controls="listOne" aria-selected="true">
              <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
            </button>
            <button className="listTwo buttonArrow me-3 text-body border-0 bg br-10 sectab " id="listTwo" data-bs-toggle="tab" data-bs-target="#listTwo" type="button" role="tab" aria-controls="listTwo" aria-selected="false">
              <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
            </button>
          </div>
          <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="Saved" icon={faFloppyDisk} name="Save" />
        </span>
      </div>
    </div>   
  )
}
