export const AgeRange =  [
    {start: '18', end: '24'},
    {start: '25', end: '34'},
    {start: '35', end: '44'},
    {start: '45', end: '54'},
    {start: '55+', end: ''},
  ]

  export const Salaries =  [
    {start: '$50k', end: '$80k'},
    {start: '$100k', end: '$150k'},
    {start: 'Over $150k', end: ''},
  ]
  export const AgeRangePrivacy =  [
    {start: '10', end: '13'},
    {start: '14', end: '17'},
    {start: '18+', end: ''},
  ]
  export const YearExperiance =  [
    {start: '1', end: '5'},
    {start: '6', end: '10'},
    {start: '10+', end: ''},
  ]
  export const incomeRange =  [
    {start: '50k', end: '80k'},
    {start: '100k', end: '150k'},
    {start: 'Over 150k', end: ''},
  ]
  export const CommunicationTime =  [
    {start: '9', end: '11:00 am'},
    {start: '11', end: '1:00 pm'},
    {start: '1', end: '2:00 pm'},
    {start: '2', end: '4:00 pm'},
    {start: '4', end: '6:00 pm'},
    {start: '6', end: '8:00 pm'},
    {start: '8', end: '9:00 pm'},
  ]

  export const EducationRange =  [
    {start: 'High School'},
    {start: 'College'},
    {start: 'BAs'},
    {start: 'MAs'},
    {start: 'PhD'},
  ]