import React, { useEffect,useState } from 'react'
import Header from '../components/header/Header'
import SubHeader from '../components/subHeader/SubHeader'
import MainContainer from '../components/mainContainer/MainContainer'
import DummyLoginPage from '../components/pages/dummyLoginPage/DummyLoginPage'
import arrow from '../images/arrow2.png';
import { useDispatch, useSelector } from 'react-redux';
import { closeSubHeaderContainer } from '../redux/actions/videoAction';
  import VideoViewSec from '../components/global/VideoViewSec';
// import { useSelector } from 'react-redux';
export default function AppRoutes() {
  let {isSubHeaderVisible} = useSelector(state => state.videoReducer);
  const dispatch =  useDispatch(); 
  const [authenticated, setauthenticated] = useState(null);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);
  const closeSubHeader = () => {
    dispatch(closeSubHeaderContainer());
  };
  let {isVideoVisible} = useSelector(state => state.videoReducer);

  return (
    <>
    {!authenticated &&
       <DummyLoginPage />
    }
    {authenticated && 
   <div>  <Header />
      
      <div className='h-100 overflow-overlay'>
      <button 
            onClick={closeSubHeader}
            className="togglebtn togglebtn3 text-primary p-0 border-0 bg z-index top-0 start-0 end-0 mx-auto">
              <img src={arrow} alt='' />
      </button>
      <div>
     
      <SubHeader/> 

      
      </div>
      <div className={isSubHeaderVisible ? "" : "sidebar-area-top" }>
      <MainContainer/>
      </div>
      </div> 
    </div>
      }
      </>
  )
}

