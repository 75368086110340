import * as React from 'react';
import ProfileManagementSliderSetp1 from '../../../global/ProfileManagementSliderSetp1';
import '../../profileManagement/profileManagement.scss';
import '../../profileManagement/profileManagementSec.scss';
import BasicInfoTab from '../../profileManagement/tabs/BasicInfoTab';
import PersonalInfoTab from '../../profileManagement/tabs/PersonalInfoTab'
import AccomplishmentTab from '../../profileManagement/tabs/AccomplishmentTab'
import EmployementInfoTab from '../../profileManagement/tabs/EmployementInfoTab'
import ProfileInfoTab from '../../profileManagement/tabs/ProfileInfoTab';
import AddressInfoTab from '../../profileManagement/tabs/AddressInfoTab'
import PasswordTab from '../../profileManagement/tabs/PasswordTab';
import ProfileEditTab from '../../profileManagement/tabs/ProfileEditTab';
import SocialMediaTab from '../../profileManagement/tabs/SocialMediaTab';
import ListFavouritesTab from '../../profileManagement/tabs/ListFavouritesTab';
import EducationTab from '../../profileManagement/tabs/EducationTab';
import ProfileViewTab from '../../profileManagement/tabs/ProfileViewTab';
import VideoPopup from '../../../global/VideoPopup';
import IntegrationAnalytics from '../../profileManagement/tabs/IntegrationAnalytics';


export default function ProfileStep() {

    return (
        <div className="w-100 d-flex flex-column-reverse">
            <div className='w-100'>
                <VideoPopup />
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="profileInfo" role="tabpanel" aria-labelledby="profileInfo">
                        <ProfileInfoTab />
                    </div>
                    <div className="tab-pane fade" id="basicInfo" role="tabpanel" aria-labelledby="basicInfo">
                        {/* <BusinessTab /> */}
                        <BasicInfoTab />
                    </div>
                    <div className="tab-pane text-center detailTab fade" id="detail" role="tabpanel" aria-labelledby="detail">
                        <AddressInfoTab />
                        {/* <DetailTab />  */}
                    </div>
                    <div className="tab-pane text-center detailTab fade" id="personalInfo" role="tabpanel" aria-labelledby="personalInfo">
                        {/* <ShippingTab /> */}
                        <PersonalInfoTab />
                    </div>
                    <div className="tab-pane detailTab fade" id="employementInfo" role="tabpanel" aria-labelledby="employementInfo">
                        <EmployementInfoTab />
                    </div>
                    <div className="tab-pane fade" id="tab5" role="tabpanel" aria-labelledby="tab5">
                        <EducationTab />
                    </div>
                    <div className="tab-pane fade" id="tab6" role="tabpanel" aria-labelledby="tab6">
                        <ListFavouritesTab />
                    </div>
                    <div className="tab-pane fade" id="tab7" role="tabpanel" aria-labelledby="tab7">
                        <AccomplishmentTab />
                    </div>
                    <div className="tab-pane fade" id="tab8" role="tabpanel" aria-labelledby="tab8">
                        <IntegrationAnalytics />
                    </div>
                    <div className="tab-pane fade" id="tab9" role="tabpanel" aria-labelledby="tab9">
                        <SocialMediaTab />
                    </div>
                    <div className="tab-pane fade" id="tab10" role="tabpanel" aria-labelledby="tab10">
                        <PasswordTab />
                    </div>
                    <div className="tab-pane fade" id="tab11" role="tabpanel" aria-labelledby="tab11">
                        <ProfileEditTab />
                    </div>
                    <div className="tab-pane fade" id="tab12" role="tabpanel" aria-labelledby="tab12">
                        <ProfileViewTab />
                    </div>
                </div>
            </div>
            <div className='container privacyTab mb-4 pb-3 mt-5 mt-lg-0'>
                <div id="myTab" className='slidetab' role="tablist">
                    <ProfileManagementSliderSetp1 /> 
                </div>
            </div>
        </div>
    );
  }