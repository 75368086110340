import React from "react";
import './EmailSignatureStep.scss';
import EmailSlider from "../../../global/EmailSlider";
import { ReactComponent as IconBlue1 } from "../../../../images/icons/icon55.svg";
import ButtonBoxNew from "../../../global/ButtonBoxNew";


export default function EmailSignatureForm5() {
  return (
    <div>
      {/* 1st section */}
      <EmailSlider />
      <div className="">
        <h4 className="h6 fw-bold lh-20 mb-3">EMBBED CODE FOR YOUR EMAIL SIGNATURE</h4>
        <textarea className="h-200 shadow-5in"></textarea>
        <div className='w-100 check-button mx-auto mb-2 mt-3 d-flex justify-content-start align-items-center'>
            <input type="checkbox" className="btn-check" name="Remember" id="Remember4" />
            <label className="btn btn-outline-primary" htmlFor="Remember4">
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                </svg>
            </label>
            Remember my details
        </div>
        <div className="w-100 text-center pt-3 mb-4 pb-3">
          {/* <button className="btn bs-primary h6 text-white fw-semibold h-48 w-125"><IconBlue1 /> Copy</button> */}
          <ButtonBoxNew  name=" Copy" boxwidth="w-25 mt-0 mb-4 bg2 br-40 mx-auto" />

        </div>
        <h4 className="h6 fw-bold lh-20 mb-3">OUT OF OFFICE OR VACATION AUTO-RESPONDER EMAIL MESSAGE </h4>
        <div className="textareasec shadow-5in pt-30 pb-4 px-4">
          <p>Your Greeting</p>
          <p>Thank you for your email. I am currently out of the office and will be returning on Date of Return. During this period I have Limited or No access to my email.</p>
          <p>If you need immediate assistance during my absence, please contact Contact Name at Contact Email Address Or Extension Number</p>
          <p>Please note that upon my return, I will reply to all emails in the order in which they were received. Therefore, there may be a delay in my reply to you depending on the volume of email received.</p>
          <p>As of my return date, if your email still requires a response, please re-send it to me on or after Date of Return to draw it to my attention.</p>
          <p className="m-0">Your Name</p>
        </div>
        <div className='w-100 check-button mx-auto mb-2 mt-3 d-flex justify-content-start align-items-center'>
            <input type="checkbox" className="btn-check" name="Remember" id="Remember5" />
            <label className="btn btn-outline-primary" htmlFor="Remember5">
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                </svg>
            </label>
            Remember my details
        </div>
        <div className="w-100 text-center pt-3 pb-5">
          {/* <button className="btn bs-primary h6 text-white fw-semibold h-48 w-125"><IconBlue1 /> Copy</button> */}
          <ButtonBoxNew  name=" Copy" boxwidth="w-25 mt-0 mb-4 bg2 br-40 mx-auto" />

        </div>
      </div>
    </div>
  );
} 
