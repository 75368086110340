import React from "react";
import ButtonBox from "../../../global/ButtonBox";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { NavItem } from 'react-bootstrap';


export default function MissionStatementForm() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });

    const options = [
        {
            value: "I",
            label: "I",
        },
        {
            value: "We",
            label: "We",
        },
    ];

    const options1 = [
        {
            value: "In",
            label: "In",
        },
        {
            value: "Within",
            label: "Within",
        },
    ];

    return (
        <div>
            <ul className="TAB-4-form">
                <div className="row">
                    <div className="col-lg-2">
                        <NavItem as="li" className="mb-4 mt-4 first-field we-field">
                            <div className=" bg-box position-relative">
                                <Select isSearchable={false}
                                    options={options}
                                    className="country mb-4 w-100 mx-auto text-center bgArrow"
                                    placeholder={t('timeline_form_four_component.i_label')}
                                    theme={theme}
                                    styles={{
                                        option: (base) => ({
                                            ...base,
                                            borderRadius: "5px",
                                            width: "60px",
                                            zIndex: "5",
                                            height: "50px",
                                            lineHeight: "32px",
                                            margin: "10px",
                                            boxShadow: "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                            cursor: 'pointer',
                                        }),
                                    }}
                                />
                            </div>
                        </NavItem>
                    </div>
                    <div className="col-lg-2">
                        <div className="mb-4 mt-4">
                            <input placeholder={t('timeline_form_four_component.help_placeholder')} className='shadow-5in br-10  mb-0 me-3' type="text"></input>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_four_component.who_label')}</label>
                            <input placeholder={t('timeline_form_four_component.new_moms')} className='shadow-5in br-10  mb-0 me-3' type="text"></input>

                        </NavItem>
                    </div>
                    <div className="col-lg-4">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_four_component.do_what')}</label>
                            <input placeholder={t('timeline_form_four_component.lose_their_baby_weight')} className='shadow-5in br-10  mb-0 me-3' type="text"></input>

                        </NavItem>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2">
                    <NavItem as="li" className="mb-4 mt-4 first-field we-field">
                            <div className=" bg-box position-relative">
                                <Select isSearchable={false}
                                    options={options1}
                                    className="country mb-4 w-100 mx-auto text-center bgArrow"
                                    placeholder={t('timeline_form_four_component.in_label')}
                                    theme={theme}
                                    styles={{
                                        option: (base) => ({
                                            ...base,
                                            borderRadius: "5px",
                                            width: "60px",
                                            zIndex: "5",
                                            height: "50px",
                                            paddingLeft: "5px",
                                            lineHeight: "32px",
                                            margin: "10px",
                                            boxShadow: "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                            cursor: 'pointer',
                                        }),
                                    }}
                                />
                            </div>
                        </NavItem>
                    </div>

                    <div className="col-lg-6">
                        <NavItem as="li" className="mb-4">
                            <label className="mb-1">  {t('timeline_form_four_component.what_timeframe')}</label>
                            <input placeholder={t('timeline_form_four_component.eight_week')} className='shadow-5in br-10  mb-0 me-3' type="text"></input>

                        </NavItem>
                    </div>
                    <div className="col-lg-4">

                        <NavItem as="li" className="mb-4 mt-4 in-field-design" >
                            {t('timeline_form_four_component.so_that_they_can')}

                        </NavItem>
                    </div>
                </div>
                <NavItem as="li" className="mb-4">
                    <label className="mb-1">  {t('timeline_form_four_component.have_or_do_what')}</label>
                    <input placeholder={t('timeline_form_four_component.have_their_body_back')} className='shadow-5in br-10  mb-0 me-3' type="text"></input>

                </NavItem>
                <NavItem as="li" className="mb-4">
                    <label className="mb-1">  {t('timeline_form_four_component.how_would_that_benefit_them_or_change_their_life')}</label>
                    <input placeholder={t('timeline_form_four_component.enjoy_their_newborns_with_confidence')} className='shadow-5in br-10  mb-0 me-3' type="text"></input>

                </NavItem>

                <NavItem as="li" className="mb-5 border-box gradient-box ">
                    <div>
                        <h5 className="suggest-field">  {t('timeline_form_four_component.generated_mission_statement')}</h5>
                        <p className="suggest-p">  {t('timeline_form_four_component.generated_mission_text')}</p>
                    </div>

                </NavItem>
                <NavItem as="li" className=" text-center time-btn">

                <ButtonBox boxwidth="w-300 lg text-primary mb-4" clicked="Mission Statement Saved" name="Save Generated Mission Statement" />

                </NavItem>
            </ul>
        </div>
    );
}
