import React, { useState } from "react";
import desktop_3_1 from "../../../../images/subscription/Desktop3-1.png";
import tab_3_1 from "../../../../images/subscription/Tab3-1.png";
import mobile_3_1 from "../../../../images/subscription/Mobile3-1.png";
import desktop_3_2 from "../../../../images/subscription/Desktop3-2.png";
import tab_3_2 from "../../../../images/subscription/Tab3-2.png";
import mobile_3_2 from "../../../../images/subscription/Mobile3-2.png";
import desktop_3_3 from "../../../../images/subscription/Desktop3-3.png";
import tab_3_3 from "../../../../images/subscription/Tab3-3.png";
import mobile_3_3 from "../../../../images/subscription/Mobile3-3.png";
import desktop_3_4 from "../../../../images/subscription/Desktop3-4.png";
import tab_3_4 from "../../../../images/subscription/Tab3-4.png";
import mobile_3_4 from "../../../../images/subscription/Mobile3-4.png";
import desktop_3_5 from "../../../../images/subscription/Desktop3-5.png";
import tab_3_5 from "../../../../images/subscription/Tab3-5.png";
import mobile_3_5 from "../../../../images/subscription/Mobile3-5.png";
import desktop_3_6 from "../../../../images/subscription/Desktop3-6.png";
import tab_3_6 from "../../../../images/subscription/Tab3-6.png";
import mobile_3_6 from "../../../../images/subscription/Mobile3-6.png";
import desktop_3_7 from "../../../../images/subscription/Desktop3-7.png";
import tab_3_7 from "../../../../images/subscription/Tab3-7.png";
import mobile_3_7 from "../../../../images/subscription/Mobile3-7.png";
import Modal from "react-bootstrap/Modal";
import SubscriptionSectionRegularThemeDesktopView1 from "./SubscriptionSectionRegularThemeDesktopView1.js";
import SubscriptionSectionRegularThemeDesktopView2 from "./SubscriptionSectionRegularThemeDesktopView2.js";
import SubscriptionSectionRegularThemeDesktopView3 from "./SubscriptionSectionRegularThemeDesktopView3.js";
import SubscriptionSectionRegularThemeDesktopView4 from "./SubscriptionSectionRegularThemeDesktopView4.js";
import SubscriptionSectionRegularThemeDesktopView5 from "./SubscriptionSectionRegularThemeDesktopView5.js";
import SubscriptionSectionRegularThemeDesktopView6 from "./SubscriptionSectionRegularThemeDesktopView6.js";
import SubscriptionSectionRegularThemeTabView1 from "./SubscriptionSectionRegularThemeTabView1.js";
import SubscriptionSectionRegularThemeTabView2 from "./SubscriptionSectionRegularThemeTabView2.js";
import SubscriptionSectionRegularThemeTabView3 from "./SubscriptionSectionRegularThemeTabView3.js";
import SubscriptionSectionRegularThemeTabView4 from "./SubscriptionSectionRegularThemeTabView4.js";
import SubscriptionSectionRegularThemeTabView5 from "./SubscriptionSectionRegularThemeTabView5.js";
import SubscriptionSectionRegularThemeTabView6 from "./SubscriptionSectionRegularThemeTabView6.js";
import SubscriptionSectionRegularThemeMobileView1 from "./SubscriptionSectionRegularThemeMobileView1.js";
import SubscriptionSectionRegularThemeMobileView2 from "./SubscriptionSectionRegularThemeMobileView2.js";
import SubscriptionSectionRegularThemeMobileView3 from "./SubscriptionSectionRegularThemeMobileView3.js";
import SubscriptionSectionRegularThemeMobileView4 from "./SubscriptionSectionRegularThemeMobileView4.js";
import SubscriptionSectionRegularThemeMobileView5 from "./SubscriptionSectionRegularThemeMobileView5.js";
import SubscriptionSectionRegularThemeMobileView6 from "./SubscriptionSectionRegularThemeMobileView6.js";
import "./SubscriptionSectionThemes.scss";
import ButtonBoxNew from "../../../global/ButtonBoxNew";

export default function SubscriptionSectionGlassmorphicTheme() {
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [themedesktopShow, setThemedesktopShow] = useState(false);
  const [themetabShow, setThemetabShow] = useState(false);
  const [thememobileShow, setThememobileShow] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);
  const [desktopgeoShow, setDesktopgeoShow] = useState(false);
  const [TabFourShow, setTabFourShow] = useState(false);
  const [MobileFourShow, setMobileFourShow] = useState(false);
  const [deskFourShow, setdeskFourShow] = useState(false);
  const [TabFiveShow, setTabFiveShow] = useState(false);
  const [MobileFiveShow, setMobileFiveShow] = useState(false);
  const [deskFiveShow, setdeskFiveShow] = useState(false);
  const [TabSixShow, setTabSixShow] = useState(false);
  const [MobileSixShow, setMobileSixShow] = useState(false);
  const [deskSixShow, setdeskSixShow] = useState(false);
  const [TabSevenShow, setTabSevenShow] = useState(false);
  const [MobileSevenShow, setMobileSevenShow] = useState(false);
  const [deskSevenShow, setdeskSevenShow] = useState(false);

  return (
    <div className="">
      <h4 className="text-center">Glassmorphic Design Version 1</h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_1} alt="desktop-3-1"></img>
          </div>
          {/* <button
            className="screen-btn"
            onClick={() => setDesktopgeoShow(true)}
          >
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"             onClick={() => setDesktopgeoShow(true)}
/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktopgeoShow}
            onHide={() => setDesktopgeoShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeDesktopView1 /> */}
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_1} alt="desktop-3-1"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setSmShow(true)}/>
          

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeTabView1 /> */}
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_1} alt="mobile-3-1"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeMobileView1 /> */}
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Glassmorphic Design Version 2</h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_2} alt="desktop-3-2"></img>
          </div>
          {/* <button
            className="screen-btn"
            onClick={() => setThemedesktopShow(true)}
          >
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"             onClick={() => setThemedesktopShow(true)}
/>

          <Modal
            show={themedesktopShow}
            onHide={() => setThemedesktopShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeDesktopView2 /> */}
              <SubscriptionSectionRegularThemeDesktopView1 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_2} alt="tab-3-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetabShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setThemetabShow(true)}/>

          <Modal
            show={themetabShow}
            onHide={() => setThemetabShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
               <SubscriptionSectionRegularThemeTabView1 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_2} alt="mobile-3-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobileShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setThememobileShow(true)}/>

          <Modal
            show={thememobileShow}
            onHide={() => setThememobileShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
               <SubscriptionSectionRegularThemeMobileView1 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Glassmorphic Design Version 3</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_3} alt="desktop-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setDeskthreeShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setDeskthreeShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskthreeShow}
            onHide={() => setDeskthreeShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeDesktopView2 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_3} alt="tab-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabThreeShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabThreeShow(true)}/>

          <Modal
            show={TabThreeShow}
            onHide={() => setTabThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeTabView2 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_3} alt="mobile-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileThreeShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileThreeShow(true)}/>

          <Modal
            show={MobileThreeShow}
            onHide={() => setMobileThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeMobileView2 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Glassmorphic Design Version 4</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_4} alt="desktop-4"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setdeskFourShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setdeskFourShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFourShow}
            onHide={() => setdeskFourShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
               <SubscriptionSectionRegularThemeDesktopView3 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_4} alt="tab-4"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFourShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setTabFourShow(true)}/>

          <Modal
            show={TabFourShow}
            onHide={() => setTabFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeTabView3 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_4} alt="mobile-4"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFourShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileFourShow(true)}/>

          <Modal
            show={MobileFourShow}
            onHide={() => setMobileFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeMobileView3 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Glassmorphic Design Version 5</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_5} alt="desktop-5"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setdeskFiveShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setdeskFiveShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFiveShow}
            onHide={() => setdeskFiveShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeDesktopView4 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_5} alt="tab-5"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFiveShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabFiveShow(true)}/>

          <Modal
            show={TabFiveShow}
            onHide={() => setTabFiveShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeTabView4 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_5} alt="mobile-5"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFiveShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileFiveShow(true)}/>

          <Modal
            show={MobileFiveShow}
            onHide={() => setMobileFiveShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeMobileView4 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Glassmorphic Design Version 6</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_6} alt="desktop-6"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setdeskSixShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setdeskSixShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskSixShow}
            onHide={() => setdeskSixShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeDesktopView5 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_6} alt="tab-6"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabSixShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabSixShow(true)}/>

          <Modal
            show={TabSixShow}
            onHide={() => setTabSixShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeTabView5 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_6} alt="mobile-6"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileSixShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileSixShow(true)}/>

          <Modal
            show={MobileSixShow}
            onHide={() => setMobileSixShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeMobileView5 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Glassmorphic Design Version 7</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_7} alt="desktop-7"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setdeskSevenShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setdeskSevenShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskSevenShow}
            onHide={() => setdeskSevenShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeDesktopView6 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_7} alt="tab-7"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabSevenShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabSevenShow(true)}/>

          <Modal
            show={TabSevenShow}
            onHide={() => setTabSevenShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeTabView6 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_7} alt="mobile-7"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileSevenShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileSevenShow(true)}/>

          <Modal
            show={MobileSevenShow}
            onHide={() => setMobileSevenShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeMobileView6 Glassmorphic />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
