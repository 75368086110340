import React, { useState } from "react";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
// import { Link } from 'react-router-dom';
import ButtonBox from '../../../global/ButtonBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import SocialMediaOne from "./SocialSingle/SocialMediaOne";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';


export default function SocialMediaTab() {
  const { t, i18n } = useTranslation();
  let {isVideoVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };
const [widthFull , setWidthFull] = useState(1)
  return (
    <div>
      <div className={` mb-4 align-items-center mx-auto d-flex flex-column-reverse justify-content-between widthSocial ${widthFull == 1  ? "" : ""  }`}>     
        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto mb-5">
        <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
          <h3 className="mb-3 d-flex justify-content-between w-600 pe-5">{t('profile_section.enter_your_profile_details')} <small>{t('step')} <b>9</b> of 11</small></h3>
          <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="socialOne" role="tabpanel" aria-labelledby="socialOne">
               <SocialMediaOne widthFull={widthFull} setWidthFull={setWidthFull}  />
              </div>
              <div className="tab-pane fade" id="socialOne" role="tabpanel" aria-labelledby="socialOne">
              </div>
              <div className="tab-pane fade" id="sociallOne" role="tabpanel" aria-labelledby="sociallOne">
              </div>
          </div>
        </div>
        <span className="d-flex align-items-center justify-content-between w-100 mb-4">
          <div id="myTab1" className='' role="tablist">
            <button className="buttonArrow me-3 text-body border-0 bg br-10 active" id="socialOne" data-bs-toggle="tab" data-bs-target="#socialOne" type="button" role="tab" aria-controls="socialOne" aria-selected="true">
              <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
            </button>
            <button className="buttonArrow me-3 text-body border-0 bg br-10" id="socialOne" data-bs-toggle="tab" data-bs-target="#socialOne" type="button" role="tab" aria-controls="socialOne" aria-selected="false">
              <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
            </button>
          </div>
          <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="Saved" icon={faFloppyDisk} name="Save" />
        </span>
      </div>
    </div>   
  )
}
