import React, { useState } from "react";
import ButtonBox from "../../../../global/ButtonBox";
import LivePreview from "../../../../../images/liveview.png";
import user from '../../../../../images/img1.png';
import ImageBox from "../../../../global/ImageBox";
import ProfileSlider from "../../../../global/ProfileSlider";
import RangeSlider from "react-bootstrap-range-slider";
import Select from 'react-select';
// import { ReactComponent as IconBlue5q } from "../../../../../images/listicon/icon19.svg";
import { ReactComponent as IconBlue6q } from "../../../../../images/svgIcon/icon23.svg";
import { ReactComponent as IconBlue7q } from "../../../../../images/svgIcon/icon24.svg";

import { ReactComponent as IconBlue1 } from "../../../../../images/listicon/icon1.svg";
import { ReactComponent as IconBlue2 } from "../../../../../images/listicon/icon2.svg";
import { ReactComponent as IconBlue3 } from "../../../../../images/social/whatsa.svg";
import { ReactComponent as IconBlue4 } from "../../../../../images/social/call.svg";
import { ReactComponent as IconBlue5 } from "../../../../../images/listicon/icon5.svg";
import { ReactComponent as IconBlue6 } from "../../../../../images/listicon/icon6.svg";
import { ReactComponent as IconBlue7 } from "../../../../../images/listicon/icon7.svg";
import { ReactComponent as IconBlue8 } from "../../../../../images/listicon/icon8.svg";
import { ReactComponent as IconBlue9 } from "../../../../../images/listicon/icon9.svg";
import { ReactComponent as IconBlue10 } from "../../../../../images/listicon/icon10.svg";
import { ReactComponent as IconBlue11 } from "../../../../../images/listicon/icon11.svg";
import { ReactComponent as IconBlue12 } from "../../../../../images/listicon/icon12.svg";
import { ReactComponent as IconBlue13 } from "../../../../../images/listicon/icon13.svg";
import { ReactComponent as IconBlue14 } from "../../../../../images/listicon/icon14.svg";
import { ReactComponent as IconBlue15 } from "../../../../../images/listicon/icon18.svg";
import { ReactComponent as IconBlue16 } from "../../../../../images/listicon/icon19.svg";
import { ReactComponent as IconBlue17 } from "../../../../../images/listicon/icon20.svg";
import { ReactComponent as IconBlue18 } from "../../../../../images/svgIcon/icon12.svg";

import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import ButtonBoxNew from "../../../../global/ButtonBoxNew";
import DropdownBox from "../../../../global/DropdownBox";
export default function ProfileInfoOne() {
  const { t, i18n } = useTranslation();
  const openSelect = () => { inputRef1.current.click(); }
  const inputRef1 = React.useRef();
  const openSelect2 = () => { inputRef2.current.click(); }
  const inputRef2 = React.useRef();
  const openSelect3 = () => { inputRef3.current.click(); }
  const inputRef3 = React.useRef();
  const openSelect4 = () => { inputRef4.current.click(); }
  const inputRef4 = React.useRef();
  const openSelect5 = () => { inputRef5.current.click(); }
  const inputRef5 = React.useRef();
  const openSelect6 = () => { inputRef6.current.click(); }
  const inputRef6 = React.useRef();
  const openSelect7 = () => { inputRef7.current.click(); }
  const inputRef7 = React.useRef();
  const openSelect8 = () => { inputRef8.current.click(); }
  const inputRef8 = React.useRef();
  const openSelect9 = () => { inputRef9.current.click(); }
  const inputRef9 = React.useRef();
  const openSelect10 = () => { inputRef10.current.click(); }
  const inputRef10 = React.useRef();
  const openSelect11 = () => { inputRef11.current.click(); }
  const inputRef11 = React.useRef();
  const openSelect12 = () => { inputRef12.current.click(); }
  const inputRef12 = React.useRef();
  const openSelect13 = () => { inputRef13.current.click(); }
  const inputRef13 = React.useRef();
  const openSelect14 = () => { inputRef14.current.click(); }
  const inputRef14 = React.useRef();
  const openSelect15 = () => { inputRef15.current.click(); }
  const inputRef15 = React.useRef();
  const openSelect16 = () => { inputRef16.current.click(); }
  const inputRef16 = React.useRef();
  const openSelect17 = () => { inputRef17.current.click(); }
  const inputRef17 = React.useRef();
  const openSelect18 = () => { inputRef18.current.click(); }
  const inputRef18 = React.useRef();
    const openSelect19 = () => { inputRef19.current.click(); }
  const inputRef19 = React.useRef();

  const [previewImage1, setPreviewImage1] = useState('');
  const [previewImage2, setPreviewImage2] = useState('');
  const [previewImage3, setPreviewImage3] = useState('');
  const [previewImage4, setPreviewImage4] = useState('');
  const [previewImage5, setPreviewImage5] = useState('');
  const [previewImage6, setPreviewImage6] = useState('');
  const [previewImage7, setPreviewImage7] = useState('');
  const [previewImage8, setPreviewImage8] = useState('');
  const [previewImage9, setPreviewImage9] = useState('');
  const [previewImage10, setPreviewImage10] = useState('');
  const [previewImage11, setPreviewImage11] = useState('');
  const [previewImage12, setPreviewImage12] = useState('');
  const [previewImage13, setPreviewImage13] = useState('');
  const [previewImage14, setPreviewImage14] = useState('');
  const [previewImage15, setPreviewImage15] = useState('');
  const [previewImage16, setPreviewImage16] = useState('');
  const [previewImage17, setPreviewImage17] = useState('');
  const [previewImage18, setPreviewImage18] = useState('');
  const [previewImage19, setPreviewImage19] = useState('');

  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [image4, setImage4] = useState('');
  const [image5, setImage5] = useState('');
  const [image6, setImage6] = useState('');
  const [image7, setImage7] = useState('');
  const [image8, setImage8] = useState('');
  const [image9, setImage9] = useState('');
  const [image10, setImage10] = useState('');
  const [image11, setImage11] = useState('');
  const [image12, setImage12] = useState('');
  const [image13, setImage13] = useState('');
  const [image14, setImage14] = useState('');
  const [image15, setImage15] = useState('');
  const [image16, setImage16] = useState('');
  const [image17, setImage17] = useState('');
  const [image18, setImage18] = useState('');
  const [image19, setImage19] = useState('');



  const [value, setValue] = useState(5);
  const [value1, setValue1] = useState(7);

  const theme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    }
  });
  // const options = [
  //       { value: 'LIVE', label: <span><IconBlue1 className="me-2"/> {t('profile_section.live')}</span> },
  //       { value: 'IN MEETING NOW', label: <span><IconBlue2 className="me-2"/> {t('profile_section.in_meeting_now')} </span> },
  //       { value: 'COACHING NOW', label: <span><IconBlue3 className="me-2"/> {t('profile_section.coaching_now')}</span> },
  //       { value: 'ON BREAK', label: <span><IconBlue4 className="me-2"/> {t('profile_section.on_break')}</span> },
  //       { value: 'OUT SICK', label: <span><IconBlue5 className="me-2"/> {t('profile_section.out_sick')}</span> },
  //       { value: 'FAMILY TIME', label: <span><IconBlue6 className="me-2"/>{t('profile_section.family_time')}</span> },
  //       { value: 'SELF-CARE', label: <span><IconBlue7 className="me-2"/> {t('profile_section.self_care')}</span>  },
  //       { value: 'MEDITATING', label: <span><IconBlue8 className="me-2"/> {t('profile_section.meditating')}</span>  },
  //       { value: 'WORKING FROM HOME', label: <span><IconBlue9 className="me-2"/> {t('profile_section.working_from_home')}</span> },
  //       { value: 'OUT OF THE OFFICE', label: <span><IconBlue10 className="me-2"/> {t('profile_section.out_of_the_office')}</span> },
  //       { value: 'WORKING OUTSIDE', label: <span><IconBlue11 className="me-2"/> {t('profile_section.working_outside')}</span> },
  //       { value: 'IN THE OFFICE', label: <span><IconBlue12 className="me-2"/> {t('profile_section.in_the_office')}</span> },
  //       { value: 'WORKING IN BED', label: <span><IconBlue13 className="me-2"/>{t('profile_section.working_in_bed')}</span> },
  //       { value: 'DO NOT DISTURB', label: <span><IconBlue14 className="me-2"/>{t('profile_section.do_not_disturb')}</span> },
  //     ]
  const meetOptions = [
    {
      icon: <span className=""><IconBlue1 className="me-2"/></span>,
      name: "Live | Available"
    },
    {
      icon: <span className=""><IconBlue2 className="me-2"/> </span>,
      name: "Busy"
    },
    {
      icon: <span className=""><IconBlue3 className="me-2"/></span>,
      name: "WhatsApp Text Only"
    },
    {
      icon: <span className=""><IconBlue4 className="me-2"/></span>,
      name: "Urgent Calls Only"
    },
    {
      icon: <span className=""><IconBlue5 className="me-2"/> </span>,
      name: "In A Meeting"
    },
    {
      icon: <span className=""><IconBlue6 className="me-2"/> </span>,
      name: "Coaching Now"
    },
    {
      icon: <span className=""><IconBlue7 className="me-2"/> </span>,
      name: "On Break"
    },
    {
      icon: <span className=""><IconBlue8 className="me-2"/> </span>,
      name: "Self-Care"
    },
    {
      icon: <span className=""><IconBlue9 className="me-2"/> </span>,
      name: "Meditation"
    },
    {
      icon: <span className=""><IconBlue10 className="me-2"/> </span>,
      name: "On Vacation"
    },
    {
      icon: <span className=""><IconBlue11 className="me-2"/> </span>,
      name: "Out Sick"
    },
    {
      icon: <span className=""><IconBlue13 className="me-2"/> </span>,
      name: "Working In Bed"
    },
    {
      icon: <span className=""><IconBlue14 className="me-2"/> </span>,
      name: "Do Not Disturb"
    },
  ] 
  return (
    <div>
      <div className="text-center">
        <h3 className="mb-0 pb-40">{t('profile_section.choose_your_profile_picture_banner')}</h3>
      </div>
      <div className="SliderSec pt-0 pb-5">
      <div className="pb-3 d-flex justify-content-end align-items-center">
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" className="me-2" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.0009 20.0001H7.00089C3.87526 20.002 1.27179 17.604 1.01726 14.4887C0.762725 11.3735 2.94242 8.58465 6.02689 8.07911C7.4466 5.562 10.111 4.00363 13.0009 4.00006C14.8029 3.99325 16.5533 4.60141 17.9629 5.72411C19.347 6.82197 20.331 8.34509 20.7629 10.0581C23.3468 10.4551 25.1887 12.7756 24.9889 15.3822C24.7891 17.9888 22.6151 20.0015 20.0009 20.0001ZM13.0009 6.0001C10.8326 6.00267 8.83357 7.17221 7.76889 9.06111L7.30089 9.90011L6.35189 10.0551C4.30217 10.3985 2.8569 12.2544 3.02611 14.3258C3.19531 16.3972 4.92262 17.9939 7.00089 18.0001H20.0009C21.5695 18.0017 22.8745 16.7947 22.9951 15.2308C23.1157 13.6668 22.0112 12.274 20.4609 12.0351L19.1449 11.8351L18.8229 10.5431C18.1582 7.86992 15.7555 5.99507 13.0009 6.0001ZM13.0009 16.0001L9.00089 12.0001H11.5509V9.00011H14.4509V12.0001H17.0009L13.0009 16.0001Z" fill="#1C1C1C"/>
        </svg>
          <span className="h8 ms-1 fw-bold">{t('profile_section.download')}</span>
        </div>
        <ProfileSlider />
      </div>
      <div className="profileUpdate">
        <h4 className="mb-30 text-center">{t('profile_section.lets_desgin_your_profile_banner')} </h4>
        <div className="row mx-0 px-5 accomplishSec">
          <div className="col-lg-4">
                <h5 className="mb-4 pb-2">{t('profile_section.enter_season_or_series')} </h5>
                <RangeSlider
                  value={value}
                  onChange={(changeEvent) =>
                    setValue(changeEvent.target.value)
                  }
                  min={0}
                  max={10}
                />
          </div>
          <div className="col-lg-4">
                <h5 className="mb-4 pb-2">{t('profile_section.enter_episode')}</h5>
                <RangeSlider
                  value={value1}
                  onChange={(changeEvent) =>
                    setValue1(changeEvent.target.value)
                  }
                  min={0}
                  max={10}
                />
          </div>
          <div className="col-lg-4">
            <input type="text" placeholder={t('profile_section.enter_podcast_title')} className="shadow-5in br-10" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3">
          <div className="LivePreview h-286 br-20 mx-auto p-20 w-100 d-flex justify-content-center align-items-center flex-column">
            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.6 15.0992H15.6L11.46 9.69922L8.7 13.2992L6.84 10.8992L3.6 15.0992ZM2.4 19.8992C1.74 19.8992 1.1748 19.664 0.704402 19.1936C0.234002 18.7232 -0.000797963 18.1584 2.03735e-06 17.4992V3.09922C2.03735e-06 2.43922 0.235202 1.87402 0.705602 1.40362C1.176 0.933221 1.7408 0.698421 2.4 0.699221H16.8C17.46 0.699221 18.0252 0.934421 18.4956 1.40482C18.966 1.87522 19.2008 2.44002 19.2 3.09922V8.49922L24 3.69922V16.8992L19.2 12.0992V17.4992C19.2 18.1592 18.9648 18.7244 18.4944 19.1948C18.024 19.6652 17.4592 19.9 16.8 19.8992H2.4Z" fill="#1C1C1C"/>
            </svg>
            <p className="mb-0 mb-3">{t('profile_section.upload_video')}</p>
          <input type="text" placeholder={t('profile_section.enter_video_link_here')} className="shadow-5in h-44 br-10 mb-3" />
          <input type="text" placeholder={t('profile_section.enter_video_title_here')} className="shadow-5in h-44 br-10" />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="LivePreview h-286 br-30 mx-auto p-20 w-100">
            <img src={LivePreview} alt="" className="w-100" />
            <ImageBox boxwidth="profiletab" src={user} />
            <h6 className="br-10 text-center mx-auto">{t('profile_section.live_preview')}</h6>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="LivePreview h-138 mb-2 br-20 mx-auto p-20 w-100 d-flex justify-content-center align-items-center flex-column">
            <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.00031 15.9587C7.94768 15.9587 7.05294 15.5903 6.3161 14.8534C5.57926 14.1166 5.21083 13.2218 5.21083 12.1692V4.59025C5.21083 3.53762 5.57926 2.64289 6.3161 1.90604C7.05294 1.1692 7.94768 0.800781 9.00031 0.800781C10.0529 0.800781 10.9477 1.1692 11.6845 1.90604C12.4214 2.64289 12.7898 3.53762 12.7898 4.59025V12.1692C12.7898 13.2218 12.4214 14.1166 11.6845 14.8534C10.9477 15.5903 10.0529 15.9587 9.00031 15.9587ZM7.73715 24.8008V20.9166C5.54768 20.6218 3.73715 19.6429 2.30557 17.9797C0.873992 16.3166 0.158203 14.3797 0.158203 12.1692H2.68452C2.68452 13.9166 3.30052 15.4063 4.53252 16.6383C5.76452 17.8703 7.25378 18.4858 9.00031 18.485C10.7477 18.485 12.2374 17.869 13.4694 16.637C14.7014 15.405 15.3169 13.9157 15.3161 12.1692H17.8424C17.8424 14.3797 17.1266 16.3166 15.695 17.9797C14.2635 19.6429 12.4529 20.6218 10.2635 20.9166V24.8008H7.73715Z" fill="#1C1C1C"/>
            </svg>
            <p className="mb-0 mb-2 mt-2 h8">{t('profile_section.podcast_audio')}</p>
            <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in h-44 br-10" />
          </div>
          <div className="LivePreview h-138 mt-2 br-20 mx-auto p-20 w-100 d-flex justify-content-center align-items-center flex-column">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0.800781V24.8008H24V0.800781H0ZM2.18182 2.9826H21.8182V22.619H2.18182V2.9826ZM7.63636 5.16442L10.7051 9.11896L7.63636 17.5735L12 21.5281L16.3636 17.5735L13.2949 9.11896L16.3636 5.16442H7.63636Z" fill="#1C1C1C"/>
              </svg>
              <p className="mb-0 mb-2 mt-2 h8">{t('profile_section.hire_me')}</p>
            <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in h-44 br-10" />
          </div>
        </div>
      </div>
      <div className="w-100 border-2 gradient-box p-2 br-10 d-inline-block mt-5">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-end">
            <IconBlue18 />
          </div>
          <div className="col-lg-4">
            <label className="mb-2">{t('profile_section.business_title')}</label>
            <input type="text" placeholder={t('profile_section.enter_podcast_title')} className="shadow-5in br-10" />
          </div>
          <div className="col-lg-4">
            <label className="mb-2">{t('profile_section.preferred_profile_name_business_name')}</label>
            <input type="text" placeholder={t('profile_section.enter_podcast_title')} className="shadow-5in br-10" />
          </div>
          <div className="col-lg-4">
            <div className=" position-relative">
              <label className="mb-2">{t('profile_section.availability_status')}</label>
              {/* <Select isSearchable={false} options={options} className="country customWidth mb-4 w-100 mx-auto" placeholder={t('profile_section.linkedin_email_live_meet')} theme={theme}
                      styles={{
                          option: (base) => ({
                            ...base,
                            borderRadius: "30px",
                            width: "93%",
                            maxheight: "39px",
                            lineHeight: "28px",
                            fontSize:"14px",
                            margin: "10px",
                            paddingLeft:"22px",
                            cursor: 'pointer',
                            color:'#000000',
                            boxShadow:
                              "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                          }),
                        }} /> */} 

              <DropdownBox name="Linkedin Email Live Meet" boxid="livemeet" options={meetOptions} boxwidth="country customWidth mb-4 w-100 mx-auto" />
            </div>
          </div>
        </div>
      </div>
      
      <div className="uploadSec mb-4">
        <ul className="d-flex flex-wrap">
          <NavItem as="li">
            <div className="imgView br-20 bg mb-2">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage1 ? <img src={previewImage1} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="20" height="24" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.158 6.84211C4.158 3.06332 7.22131 0 11.0001 0C14.7789 0 17.8422 3.06332 17.8422 6.84211C17.8422 10.6209 14.7789 13.6842 11.0001 13.6842C7.22131 13.6842 4.158 10.6209 4.158 6.84211ZM11.0001 10.9474C13.2674 10.9474 15.1054 9.10938 15.1054 6.84211C15.1054 4.57483 13.2674 2.73684 11.0001 2.73684C8.73283 2.73684 6.89484 4.57483 6.89484 6.84211C6.89484 9.10938 8.73283 10.9474 11.0001 10.9474Z" fill="#4E4E57" />
                          <path d="M3.25914 18.259C1.20611 20.3121 0.0527344 23.0966 0.0527344 26H2.78958C2.78958 23.8224 3.65461 21.7341 5.19438 20.1943C6.73416 18.6545 8.82254 17.7895 11.0001 17.7895C13.1777 17.7895 15.2661 18.6545 16.8058 20.1943C18.3456 21.7341 19.2106 23.8224 19.2106 26H21.9475C21.9475 23.0966 20.7941 20.3121 18.7411 18.259C16.688 16.206 13.9035 15.0526 11.0001 15.0526C8.09668 15.0526 5.31217 16.206 3.25914 18.259Z" fill="#4E4E57" />
                        </svg>
                        <p className="mb-0 mt-1">{t('profile_section.change_profile_image')}</p>
                        <p className="fw-bold mb-2">{t('profile_section.Pixels_200_x_200_Pixels')}</p>
                        <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in h-44 br-10" />
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage1(url);
                setImage1(e.target.files[0])
              }} ref={inputRef1} />
            </div>
            <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10" />
          </NavItem>
          <NavItem as="li">
            <div className="imgView br-20 bg mb-2">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect2} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage2 ? <img src={previewImage2} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <IconBlue7q />
                        <p className="mb-0 mt-1">{t('profile_section.change_banner_image')}</p>
                        <p className="fw-bold mb-2">{t('profile_section.pixels_1000_X_400_pixels')}</p>
                        <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in h-44 br-10" />
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile" id="profile" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage2(url);
                setImage2(e.target.files[0])
              }} ref={inputRef2} />
            </div>
            <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10" />
          </NavItem>
          <NavItem as="li">
            <div className="imgView br-20 bg mb-2">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect3} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage3 ? <img src={previewImage3} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <IconBlue6q />
                        <p className="mb-0 mt-1">{t('profile_section.change_business_logo')}</p>
                        <p className="fw-bold mb-2">{t('profile_section.pixels_600_X_600_pixels')}</p>
                        <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in h-44 br-10" />
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile" id="profile" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage3(url);
                setImage3(e.target.files[0])
              }} ref={inputRef3} />
            </div>
            <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10" />
          </NavItem>
          <NavItem as="li">
            <div className="imgView br-20 bg mb-2">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect4} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage4 ? <img src={previewImage4} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 24.5C2.32181 24.5 0 22.1782 0 12.5C0 2.82181 2.32181 0.5 12 0.5C21.6782 0.5 24 2.82181 24 12.5C24 22.1782 21.6782 24.5 12 24.5ZM8.60287 6.43887L4.83909 14.9929C4.668 15.4084 4.52138 15.946 4.52138 16.4104C4.52138 18.5367 6.01219 20.0276 8.13844 20.0276C8.83528 20.0276 9.41391 19.8445 10.0022 19.6584L10.0027 19.6581C10.611 19.4656 11.2298 19.2699 12 19.2699C12.7793 19.2699 13.3832 19.4639 13.9798 19.6556L13.98 19.6557C14.5622 19.8427 15.1373 20.0276 15.8615 20.0276C17.9877 20.0276 19.4786 18.5367 19.4786 16.4104C19.4786 15.946 19.3319 15.4084 19.1609 14.9929L15.3972 6.43887C14.7616 4.997 13.5397 4.21484 12 4.21484C10.4603 4.21484 9.23831 4.99691 8.60287 6.43887V6.43887ZM8.28506 16.3371L12 7.92978L15.666 16.3371C14.6151 15.8239 13.2953 15.5795 12 15.5795C10.6558 15.5795 9.38494 15.8239 8.28516 16.3371H8.28506Z" fill="#4E4E57" />
                        </svg>
                        <p className="mb-0 mt-0">{t('profile_section.change_favicon')}</p>
                        <p className="small mb-0 lh-sm">{t('profile_section.url_Icon_logo')}</p>
                        <p className="fw-bold mb-2">{t('profile_section.pixels_16_X_16_pixels')}</p>
                        <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in h-44 br-10" />
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile" id="profile" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage4(url);
                setImage4(e.target.files[0])
              }} ref={inputRef4} />
            </div>
            <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10" />
          </NavItem>
        </ul>
      </div>

      <div className="row mt-5 pt-4">
        <div className="col-lg-3 px-2 text-center mb-4">
          <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
            <IconBlue15 />
            <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10 my-2" />
            <div className="upload-btn-wrapper">
              <button className="btns shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
              <input type="file" name="myfile" />
            </div>
            <p className="h7 fw-semibold mt-2">{t('profile_section.pixels_1024_X_1024_pixels')}</p>
          </div>
          {/* <button className="title-btn br-5 bs-primary border-0">{t('profile_section.title')}</button> */}
          <ButtonBoxNew boxwidth="mx-auto"  name="Title" />
          <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in w-200 h-44 br-10 my-4" />
        </div>
        <div className="col-lg-3 px-2 text-center mb-4">
          <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
            <IconBlue15 />
            <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10 my-2" />
            <div className="upload-btn-wrapper">
              <button className="btns shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
              <input type="file" name="myfile" />
            </div>
            <p className="h7 fw-semibold mt-2">{t('profile_section.pixels_1024_X_1024_pixels')}</p>
          </div>
          {/* <button className="title-btn br-5 bs-primary border-0">{t('profile_section.title')}</button> */}
          <ButtonBoxNew boxwidth="mx-auto"  name="Title" />
          <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in w-200 h-44 br-10 my-4" />
        </div>
        <div className="col-lg-3 px-2 text-center mb-4">
          <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
            <IconBlue15 />
            <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10 my-2" />
            <div className="upload-btn-wrapper">
              <button className="btns shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
              <input type="file" name="myfile" />
            </div>
            <p className="h7 fw-semibold mt-2">{t('profile_section.pixels_1024_X_1024_pixels')}</p>
          </div>
          {/* <button className="title-btn br-5 bs-primary border-0">{t('profile_section.title')}</button> */}
          <ButtonBoxNew boxwidth="mx-auto"  name="Title" />
          <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in w-200 h-44 br-10 my-4" />
        </div>
        <div className="col-lg-3 px-2 text-center mb-4">
          <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
            <IconBlue15 />
            <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10 my-2" />
            <div className="upload-btn-wrapper">
              <button className="btns shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
              <input type="file" name="myfile" />
            </div>
            <p className="h7 fw-semibold mt-2">{t('profile_section.pixels_1024_X_1024_pixels')}</p>
          </div>
          {/* <button className="title-btn br-5 bs-primary border-0">{t('profile_section.title')}</button> */}
          <ButtonBoxNew boxwidth="mx-auto"  name="Title" />
          <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in w-200 h-44 br-10 my-4" />
        </div>

        <div className="col-lg-3 px-2 text-center mb-4">
          <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
            <IconBlue15 />
            <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10 my-2" />
            <div className="upload-btn-wrapper">
              <button className="btns shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
              <input type="file" name="myfile" />
            </div>
            <p className="h7 fw-semibold mt-2">{t('profile_section.pixels_1024_X_1024_pixels')}</p>
          </div>
          {/* <button className="title-btn br-5 bs-primary border-0">{t('profile_section.title')}</button> */}
          <ButtonBoxNew boxwidth="mx-auto"  name="Title" />
          <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in w-200 h-44 br-10 my-4" />
        </div>
        <div className="col-lg-3 px-2 text-center mb-4">
          <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
            <IconBlue15 />
            <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10 my-2" />
            <div className="upload-btn-wrapper">
              <button className="btns shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
              <input type="file" name="myfile" />
            </div>
            <p className="h7 fw-semibold mt-2">{t('profile_section.pixels_1024_X_1024_pixels')}</p>
          </div>
          {/* <button className="title-btn br-5 bs-primary border-0">{t('profile_section.title')}</button> */}
          <ButtonBoxNew boxwidth="mx-auto"  name="Title" />
          <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in w-200 h-44 br-10 my-4" />
        </div>
        <div className="col-lg-3 px-2 text-center mb-4">
          <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
            <IconBlue15 />
            <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10 my-2" />
            <div className="upload-btn-wrapper">
              <button className="btns shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
              <input type="file" name="myfile" />
            </div>
            <p className="h7 fw-semibold mt-2">{t('profile_section.pixels_1024_X_1024_pixels')}</p>
          </div>
          {/* <button className="title-btn br-5 bs-primary border-0">{t('profile_section.title')}</button> */}
          <ButtonBoxNew boxwidth="mx-auto"  name="Title" />
          <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in w-200 h-44 br-10 my-4" />
        </div>
        <div className="col-lg-3 px-2 text-center mb-4">
          <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
            <IconBlue15 />
            <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10 my-2" />
            <div className="upload-btn-wrapper">
              <button className="btns shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
              <input type="file" name="myfile" />
            </div>
            <p className="h7 fw-semibold mt-2">{t('profile_section.pixels_1024_X_1024_pixels')}</p>
          </div>
          {/* <button className="title-btn br-5 bs-primary border-0">{t('profile_section.title')}</button> */}
          <ButtonBoxNew boxwidth="mx-auto"  name="Title" />
          <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in w-200 h-44 br-10 my-4" />
        </div>
      </div>
      <div className="w-650 row mx-auto uploadSo justify-content-center align-items-center my-5">
        <div className="col-lg-6 justify-content-center align-items-center d-flex">
          <label>{t('profile_section.upload_country_flag')}</label>
          <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect18} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage18 ? <img src={previewImage18} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <IconBlue16 />
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage18(url);
                setImage18(e.target.files[0])
              }} ref={inputRef18} />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="justify-content-between align-items-center d-flex">
            <div className="justify-content-center align-items-center d-flex me-3">
              <label>{t('profile_section.upload_rank')}<br /> {t('profile_section.profile_picture_icon')}</label>
              <div className="Icon-Div">
                  <div className='mx-auto mb-4 fileTab h-100'>
                    <label onClick={openSelect19} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage19 ? <img src={previewImage19} alt="Profile Picture" className="w-100 h-100" /> :
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBlue16 />
                          </div>
                        }
                      </div>
                    </label>
                  </div>
                  <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                    let url = URL.createObjectURL(e.target.files[0])
                    setPreviewImage19(url);
                    setImage19(e.target.files[0])
                  }} ref={inputRef19} />
              </div>
            </div>
            <div className="iconSoc shadow-4 br-40 bg">
              <span className="shadow-5in br-40 bg">
                <div className="br-40">
                  <IconBlue17 />
                </div>
              </span>
            </div>
          </div>
          <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in h-44 br-10 my-4" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <label className="mb-2 w-100"> {t('profile_section.hire_me')} ({t('profile_section.booking_page_url_here')})</label>
          <div className="d-flex mt0">
            <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in w-300 me-2 br-10" />
            {/* <button className="h-50s w-110 border-0 bg text-uppercase shadow-4 h7 fw-semibold">{t('profile_section.hire_me')}</button> */}
            <ButtonBoxNew boxwidth="w-145" name="Hire Me" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="mb-2 w-100">{t('profile_section.message_me')}</label>
          <div className="d-flex mt0">
            <input type="text" placeholder={t('profile_section.paste_contact_page_url_here')} className="shadow-5in w-300 me-2 br-10" />
            {/* <button className="h-50s w-145 border-0 bg text-uppercase shadow-4 h7 fw-semibold">{t('profile_section.message_me')}</button> */}
            <ButtonBoxNew boxwidth="w-145" name="Message Me" />
          </div>
        </div>
      </div>

      <div className="row my-5">
        <div className="col-md-5 text-center">
          <label className="mb-2 w-100">{t('profile_section.email_address')}</label>
          <input type="text" placeholder={t('profile_section.type_preferred_email_address_here')} className="shadow-5in mr-2 br-10 mb-2" />
          {/* <button className="h-50s w-105 border-0 mx-auto bg text-uppercase shadow-4 h7 fw-semibold">{t('profile_section.emai_me')}</button> */}
          <ButtonBoxNew boxwidth="w-105 mx-auto" name="email Me" />
        </div>
        <div className="col-md-7 circleSec">
          <ul className="row">
            <NavItem as="li" className="col-lg-4">
              <div className="circleIcon shadow-5in bg">
                <div className="Icon-Div">
                  <div className='mx-auto fileTab h-100'>
                    <label onClick={openSelect15} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage15 ? <img src={previewImage15} alt="Profile Picture" className="w-100 h-100" /> :
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBlue16 />
                            <p className="mb-2 h7">{t('profile_section.add_image')}</p>
                            <p className="h7 mb-0">200 X 200 <br/> Pixels</p>
                          </div>
                        }
                      </div>
                    </label>
                  </div>
                  <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                    let url = URL.createObjectURL(e.target.files[0])
                    setPreviewImage15(url);
                    setImage15(e.target.files[0])
                  }} ref={inputRef15} />
                </div>
              </div>
              <input type="text" placeholder={t('profile_section.enter_podcast_title')} className="shadow-5in br-10" />
            </NavItem>
            <NavItem as="li" className="col-lg-4">
              <div className="circleIcon shadow-5in bg">
                <div className="Icon-Div">
                  <div className='mx-auto fileTab h-100'>
                    <label onClick={openSelect16} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage16 ? <img src={previewImage16} alt="Profile Picture" className="w-100 h-100" /> :
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBlue16 />
                            <p className="mb-2 h7">{t('profile_section.add_image')}</p>
                            <p className="h7 mb-0">200 X 200 <br/> Pixels</p>
                          </div>
                        }
                      </div>
                    </label>
                  </div>
                  <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                    let url = URL.createObjectURL(e.target.files[0])
                    setPreviewImage16(url);
                    setImage16(e.target.files[0])
                  }} ref={inputRef16} />
                </div>
              </div>
              <input type="text" placeholder={t('profile_section.enter_podcast_title')} className="shadow-5in br-10" />
            </NavItem>
            <NavItem as="li" className="col-lg-4">
              <div className="circleIcon shadow-5in bg">
                <div className="Icon-Div">
                  <div className='mx-auto fileTab h-100'>
                    <label onClick={openSelect17} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage17 ? <img src={previewImage17} alt="Profile Picture" className="w-100 h-100" /> :
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBlue16 />
                            <p className="mb-2 h7">{t('profile_section.add_image')}</p>
                            <p className="h7 mb-0">200 X 200 <br/> Pixels</p>
                          </div>
                        }
                      </div>
                    </label>
                  </div>
                  <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                    let url = URL.createObjectURL(e.target.files[0])
                    setPreviewImage17(url);
                    setImage17(e.target.files[0])
                  }} ref={inputRef17} />
                </div>
              </div>
              <input type="text" placeholder={t('profile_section.enter_podcast_title')} className="shadow-5in br-10" />
            </NavItem>
          </ul>
        </div>
      </div>

      <div className="uploadSocial mt-5">
        <h6 className="mb-3">{t('profile_section.upload_custom_social_media_icons')}</h6>
        <ul className="d-flex flex-wrap justify-content-between">
          <NavItem as="li" className="me-3">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect5} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage5 ? <img src={previewImage5} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage5(url);
                setImage5(e.target.files[0])
              }} ref={inputRef5} />
            </div>
            {t('profile_section.facebook')}
          </NavItem>
          <NavItem as="li" className="mx-3">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect6} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage6 ? <img src={previewImage6} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage6(url);
                setImage6(e.target.files[0])
              }} ref={inputRef6} />
            </div>
            {t('profile_section.twitter')}
          </NavItem>
          <NavItem as="li" className="mx-3">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect7} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage7 ? <img src={previewImage7} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage7(url);
                setImage7(e.target.files[0])
              }} ref={inputRef7} />
            </div>
            {t('profile_section.instagram')}
          </NavItem>
          <NavItem as="li" className="mx-3">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect8} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage8 ? <img src={previewImage8} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage8(url);
                setImage8(e.target.files[0])
              }} ref={inputRef8} />
            </div>
            {t('profile_section.linked_in')}
          </NavItem>
          <NavItem as="li" className="me-3">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect9} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage9 ? <img src={previewImage9} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage9(url);
                setImage9(e.target.files[0])
              }} ref={inputRef9} />
            </div>
            {t('profile_section.twitch')} 
          </NavItem>
          <NavItem as="li" className="mx-3">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect10} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage10 ? <img src={previewImage10} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage10(url);
                setImage10(e.target.files[0])
              }} ref={inputRef10} />
            </div>
            {t('profile_section.tiktok')} 
          </NavItem>
          <NavItem as="li" className="mx-3">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect11} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage11 ? <img src={previewImage11} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage11(url);
                setImage11(e.target.files[0])
              }} ref={inputRef11} />
            </div>
            {t('profile_section.youtube')}
          </NavItem>
          <NavItem as="li" className="mx-3">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect12} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage12 ? <img src={previewImage12} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage12(url);
                setImage12(e.target.files[0])
              }} ref={inputRef12} />
            </div>
            {t('profile_section.pinterest')} 
          </NavItem>
          <NavItem as="li" className="mx-3">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect13} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage13 ? <img src={previewImage13} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage13(url);
                setImage13(e.target.files[0])
              }} ref={inputRef13} />
            </div>
            {t('profile_section.snapchat')} 
          </NavItem>
          <NavItem as="li" className="mx-3">
            <div className="Icon-Div">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect14} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage14 ? <img src={previewImage14} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.8571 9.48214C12.8571 9.57054 12.7848 9.64286 12.6964 9.64286H9.64286V12.6964C9.64286 12.7848 9.57054 12.8571 9.48214 12.8571H8.51786C8.42946 12.8571 8.35714 12.7848 8.35714 12.6964V9.64286H5.30357C5.21518 9.64286 5.14286 9.57054 5.14286 9.48214V8.51786C5.14286 8.42946 5.21518 8.35714 5.30357 8.35714H8.35714V5.30357C8.35714 5.21518 8.42946 5.14286 8.51786 5.14286H9.48214C9.57054 5.14286 9.64286 5.21518 9.64286 5.30357V8.35714H12.6964C12.7848 8.35714 12.8571 8.42946 12.8571 8.51786V9.48214Z" fill="#1C1C1C" />
                        </svg>
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage14(url);
                setImage14(e.target.files[0])
              }} ref={inputRef14} />
            </div>
            {t('profile_section.discord')} 
          </NavItem>
        </ul>
      </div>
      <ul>
        <NavItem as="li" className=" text-center mt-5 justify-content-center">
          <ButtonBox
            boxwidth="w-140 mt-0 mb-50 mx-auto"
            clicked="Saved"
            name="Save/Next"
          />
        </NavItem>
      </ul>
    </div>
  );
}
