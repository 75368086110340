import React from "react";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
// import { Link } from 'react-router-dom';
import ButtonBox from '../../../global/ButtonBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import ToggleButton from "../../../global/ToggleButton";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { NavItem } from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';
export default function AccomplishmentTab() {
  const { t, i18n } = useTranslation();
  let {isVideoVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };

const openSelect30 = () =>{inputRef30.current.click();}
const inputRef30 = React.useRef();
const openSelect31 = () =>{inputRef31.current.click();}
const inputRef31 = React.useRef();
const openSelect32 = () =>{inputRef32.current.click();}
const inputRef32 = React.useRef();
const openSelect33 = () =>{inputRef33.current.click();}
const inputRef33 = React.useRef();
const openSelect34 = () =>{inputRef34.current.click();}
const inputRef34 = React.useRef();
const openSelect35 = () =>{inputRef35.current.click();}
const inputRef35 = React.useRef();
const openSelect36 = () =>{inputRef36.current.click();}
const inputRef36 = React.useRef();
const openSelect37 = () =>{inputRef37.current.click();}
const inputRef37 = React.useRef();

const [previewImage30, setPreviewImage30] = useState('');
const [previewImage31, setPreviewImage31] = useState('');
const [previewImage32, setPreviewImage32] = useState('');
const [previewImage33, setPreviewImage33] = useState('');
const [previewImage34, setPreviewImage34] = useState('');
const [previewImage35, setPreviewImage35] = useState('');
const [previewImage36, setPreviewImage36] = useState('');
const [previewImage37, setPreviewImage37] = useState('');

const [setImage30] = useState('');
const [setImage31] = useState('');
const [setImage32] = useState('');
const [setImage33] = useState('');
const [setImage34] = useState('');
const [setImage35] = useState('');
const [setImage36] = useState('');
const [setImage37] = useState('');


  const theme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    }
  });
  const [ yearStarted, setYearStarted ] = useState(2018); 
  const [ yearEnded, setYearEnded ] = useState(1); 

  const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]
  return (
    <div>
      <div className="w-780 mb-4 align-items-center mx-auto d-flex justify-content-between">
        <span className="d-flex align-items-center justify-content-center">
          <button className="buttonArrow text-body me-3 border-0 bg br-10">
            <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
          </button>
          <button className="buttonArrow text-body bg border-0 br-10">
            <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
          </button>
        </span>
        <ButtonBox boxwidth="w-110 mt-0 mb-4" clicked="Saved" name="Save" />
      </div>
      <div className="factorTab w-780 text-start d-block br-20 position-relative mx-auto mb-5 accomplishSec">
      <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
        <h3 className="mb-3 d-flex justify-content-between w-600 pe-5"> {t('profile_section.enter_your_profile_details')} <small> 
          {t('step')} <b>8</b> of 11</small></h3>
        <div className="ProgressBar">
          <ul>
              <NavItem as="li" className="active" id="profile"></NavItem>
              <NavItem as="li" className="active" id="chat"></NavItem>
              <NavItem as="li" className="active" id="location"></NavItem>
              <NavItem as="li" className="active" id="user"></NavItem>
              <NavItem as="li" className="active" id="briefcase"></NavItem>
              <NavItem as="li" className="active" id="graduation-cap"></NavItem>
              <NavItem as="li" className="active" id="favorite"></NavItem>
              <NavItem as="li" className="active" id="award"></NavItem>
              <NavItem as="li" id="connectivity"></NavItem>
              <NavItem as="li" id="lock"></NavItem>
              <NavItem as="li" id="eye"></NavItem>
          </ul>
        </div>
        <form>
          <div className="text-center mb-5">
            <h5> {t('profile_section.your_accomplishments')}</h5>
          </div>
          <div className="row mx-0 align-items-center accomplishSec"> 
              <div className="col-md-11 pe-4">
                <label className="mb-2 w-100">{t('profile_section.awards_contests')}</label>
                <input
                  placeholder={t('profile_section.fashion_stylist_expression_award')}
                  className="shadow-5in br-10 w-100 mb-4"
                  type="text"
                />
              </div>
              <div className="col-md-1 p-0">
                <div className="custom-file-drop-sec">
                  <div className="imgView">
                    <div className="mx-auto mb-0 fileTab h-100">
                      <label onClick={openSelect30} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                          {previewImage30 ? (
                            <img
                              src={previewImage30}
                              alt=""
                              className="w-100 h-100"
                            />
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 0.222656C3.13438 0.222656 0 3.35703 0 7.22266C0 11.0883 3.13438 14.2227 7 14.2227C10.8656 14.2227 14 11.0883 14 7.22266C14 3.35703 10.8656 0.222656 7 0.222656ZM10 7.59766C10 7.66641 9.94375 7.72266 9.875 7.72266H7.5V10.0977C7.5 10.1664 7.44375 10.2227 7.375 10.2227H6.625C6.55625 10.2227 6.5 10.1664 6.5 10.0977V7.72266H4.125C4.05625 7.72266 4 7.66641 4 7.59766V6.84766C4 6.77891 4.05625 6.72266 4.125 6.72266H6.5V4.34766C6.5 4.27891 6.55625 4.22266 6.625 4.22266H7.375C7.44375 4.22266 7.5 4.27891 7.5 4.34766V6.72266H9.875C9.94375 6.72266 10 6.77891 10 6.84766V7.59766Z" fill="black"/>
                              </svg>
                              <p className="mb-0 mt-0 lh-sm">{t('profile_section.add_image')}</p>
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    <input
                      hidden
                      type="file"
                      name="profile"
                      id="profile"
                      accept="image/*"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setPreviewImage30(url);
                        setImage30(e.target.files[0]);
                      }}
                      ref={inputRef30}
                    />
                  </div>
                </div>
              </div>
          </div>
          <div className="row mx-0 align-items-center">
              <div className="col-md-11 pe-4">
              <label className="mb-2">{t('profile_section.points')}</label>
              <input
                placeholder={t('profile_section.total_points')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
              </div>
              <div className="custom-form-group col-md-1 px-0">
                <div className="custom-file-drop-sec">
                  <div className="imgView">
                    <div className="mx-auto mb-0 fileTab h-100">
                      <label onClick={openSelect35} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                          {previewImage35 ? (
                            <img
                              src={previewImage35}
                              alt=""
                              className="w-100 h-100"
                            />
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 0.222656C3.13438 0.222656 0 3.35703 0 7.22266C0 11.0883 3.13438 14.2227 7 14.2227C10.8656 14.2227 14 11.0883 14 7.22266C14 3.35703 10.8656 0.222656 7 0.222656ZM10 7.59766C10 7.66641 9.94375 7.72266 9.875 7.72266H7.5V10.0977C7.5 10.1664 7.44375 10.2227 7.375 10.2227H6.625C6.55625 10.2227 6.5 10.1664 6.5 10.0977V7.72266H4.125C4.05625 7.72266 4 7.66641 4 7.59766V6.84766C4 6.77891 4.05625 6.72266 4.125 6.72266H6.5V4.34766C6.5 4.27891 6.55625 4.22266 6.625 4.22266H7.375C7.44375 4.22266 7.5 4.27891 7.5 4.34766V6.72266H9.875C9.94375 6.72266 10 6.77891 10 6.84766V7.59766Z" fill="black"/>
                              </svg>
                              <p className="mb-0 mt-0 lh-sm">{t('profile_section.add_image')}</p>
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    <input
                      hidden
                      type="file"
                      name="profile"
                      id="profile"
                      accept="image/*"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setPreviewImage35(url);
                        setImage35(e.target.files[0]);
                      }}
                      ref={inputRef35}
                    />
                  </div>
                </div>
              </div>
          </div>
          <div className="row mx-0 align-items-center">
              <div className="col-md-11 pe-4">
              <label className="mb-2">{t('profile_section.badges')}</label>
              <input
                placeholder={t('profile_section.super_brand_badge')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
              </div>
              <div className="custom-form-group col-md-1 px-0">
                 <div className="custom-file-drop-sec">
                  <div className="imgView">
                    <div className="mx-auto mb-0 fileTab h-100">
                      <label onClick={openSelect31} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                          {previewImage31 ? (
                            <img
                              src={previewImage31}
                              alt=""
                              className="w-100 h-100"
                            />
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 0.222656C3.13438 0.222656 0 3.35703 0 7.22266C0 11.0883 3.13438 14.2227 7 14.2227C10.8656 14.2227 14 11.0883 14 7.22266C14 3.35703 10.8656 0.222656 7 0.222656ZM10 7.59766C10 7.66641 9.94375 7.72266 9.875 7.72266H7.5V10.0977C7.5 10.1664 7.44375 10.2227 7.375 10.2227H6.625C6.55625 10.2227 6.5 10.1664 6.5 10.0977V7.72266H4.125C4.05625 7.72266 4 7.66641 4 7.59766V6.84766C4 6.77891 4.05625 6.72266 4.125 6.72266H6.5V4.34766C6.5 4.27891 6.55625 4.22266 6.625 4.22266H7.375C7.44375 4.22266 7.5 4.27891 7.5 4.34766V6.72266H9.875C9.94375 6.72266 10 6.77891 10 6.84766V7.59766Z" fill="black"/>
                              </svg>
                              <p className="mb-0 mt-0 lh-sm">{t('profile_section.add_image')}</p>
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    <input
                      hidden
                      type="file"
                      name="profile"
                      id="profile"
                      accept="image/*"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setPreviewImage31(url);
                        setImage31(e.target.files[0]);
                      }}
                      ref={inputRef31}
                    />
                  </div>
                </div>
              </div>
          </div>
          
          <div className="row mx-0 align-items-center">
              <div className="col-md-11 pe-4">
              <label className="mb-2">{t('profile_section.quests')}</label>
              <input
                placeholder={t('profile_section.quest_1')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
              </div>
              <div className="custom-form-group col-md-1 px-0">
                <div className="custom-file-drop-sec">
                  <div className="imgView">
                    <div className="mx-auto mb-0 fileTab h-100">
                      <label onClick={openSelect32} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                          {previewImage32 ? (
                            <img
                              src={previewImage32}
                              alt=""
                              className="w-100 h-100"
                            />
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 0.222656C3.13438 0.222656 0 3.35703 0 7.22266C0 11.0883 3.13438 14.2227 7 14.2227C10.8656 14.2227 14 11.0883 14 7.22266C14 3.35703 10.8656 0.222656 7 0.222656ZM10 7.59766C10 7.66641 9.94375 7.72266 9.875 7.72266H7.5V10.0977C7.5 10.1664 7.44375 10.2227 7.375 10.2227H6.625C6.55625 10.2227 6.5 10.1664 6.5 10.0977V7.72266H4.125C4.05625 7.72266 4 7.66641 4 7.59766V6.84766C4 6.77891 4.05625 6.72266 4.125 6.72266H6.5V4.34766C6.5 4.27891 6.55625 4.22266 6.625 4.22266H7.375C7.44375 4.22266 7.5 4.27891 7.5 4.34766V6.72266H9.875C9.94375 6.72266 10 6.77891 10 6.84766V7.59766Z" fill="black"/>
                              </svg>
                              <p className="mb-0 mt-0 lh-sm">{t('profile_section.add_image')}</p>
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    <input
                      hidden
                      type="file"
                      name="profile"
                      id="profile"
                      accept="image/*"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setPreviewImage32(url);
                        setImage32(e.target.files[0]);
                      }}
                      ref={inputRef32}
                    />
                  </div>
                </div>
              </div>
          </div>
          <div className="row mx-0 align-items-center"> 
              <div className="col-md-11 pe-4">
              <label className="mb-2">{t('profile_section.influencer_score')}</label>
              <input
                placeholder={t('profile_section.your_influencer_score')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
              </div>
              <div className="custom-form-group col-md-1 px-0">
                <div className="custom-file-drop-sec">
                  <div className="imgView">
                    <div className="mx-auto mb-0 fileTab h-100">
                      <label onClick={openSelect33} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                          {previewImage33 ? (
                            <img
                              src={previewImage33}
                              alt=""
                              className="w-100 h-100"
                            />
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 0.222656C3.13438 0.222656 0 3.35703 0 7.22266C0 11.0883 3.13438 14.2227 7 14.2227C10.8656 14.2227 14 11.0883 14 7.22266C14 3.35703 10.8656 0.222656 7 0.222656ZM10 7.59766C10 7.66641 9.94375 7.72266 9.875 7.72266H7.5V10.0977C7.5 10.1664 7.44375 10.2227 7.375 10.2227H6.625C6.55625 10.2227 6.5 10.1664 6.5 10.0977V7.72266H4.125C4.05625 7.72266 4 7.66641 4 7.59766V6.84766C4 6.77891 4.05625 6.72266 4.125 6.72266H6.5V4.34766C6.5 4.27891 6.55625 4.22266 6.625 4.22266H7.375C7.44375 4.22266 7.5 4.27891 7.5 4.34766V6.72266H9.875C9.94375 6.72266 10 6.77891 10 6.84766V7.59766Z" fill="black"/>
                              </svg>
                              <p className="mb-0 mt-0 lh-sm">{t('profile_section.add_image')}</p>
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    <input
                      hidden
                      type="file"
                      name="profile"
                      id="profile"
                      accept="image/*"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setPreviewImage33(url);
                        setImage33(e.target.files[0]);
                      }}
                      ref={inputRef33}
                    />
                  </div>
                </div>
              </div>
          </div>
          <div className="row mx-0 align-items-center">
              <div className="col-md-11 pe-4">
              <label className="mb-2">{t('profile_section.social_score')}</label>
              <input
                placeholder={t('profile_section.your_social_score')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
              </div>
              <div className="custom-form-group col-md-1 px-0">
                <div className="custom-file-drop-sec">
                  <div className="imgView">
                    <div className="mx-auto mb-0 fileTab h-100">
                      <label onClick={openSelect34} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                          {previewImage34 ? (
                            <img
                              src={previewImage34}
                              alt=""
                              className="w-100 h-100"
                            />
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 0.222656C3.13438 0.222656 0 3.35703 0 7.22266C0 11.0883 3.13438 14.2227 7 14.2227C10.8656 14.2227 14 11.0883 14 7.22266C14 3.35703 10.8656 0.222656 7 0.222656ZM10 7.59766C10 7.66641 9.94375 7.72266 9.875 7.72266H7.5V10.0977C7.5 10.1664 7.44375 10.2227 7.375 10.2227H6.625C6.55625 10.2227 6.5 10.1664 6.5 10.0977V7.72266H4.125C4.05625 7.72266 4 7.66641 4 7.59766V6.84766C4 6.77891 4.05625 6.72266 4.125 6.72266H6.5V4.34766C6.5 4.27891 6.55625 4.22266 6.625 4.22266H7.375C7.44375 4.22266 7.5 4.27891 7.5 4.34766V6.72266H9.875C9.94375 6.72266 10 6.77891 10 6.84766V7.59766Z" fill="black"/>
                              </svg>
                              <p className="mb-0 mt-0 lh-sm">{t('profile_section.add_image')}</p>
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    <input
                      hidden
                      type="file"
                      name="profile"
                      id="profile"
                      accept="image/*"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setPreviewImage34(url);
                        setImage34(e.target.files[0]);
                      }}
                      ref={inputRef34}
                    />
                  </div>
                </div>
              </div>
          </div>
          <div className="row mx-0 align-items-center">
              <div className="col-md-11 pe-4">
              <label className="mb-2">{t('profile_section.csat')}</label>
              <input
                placeholder={t('profile_section._100')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
              </div>
              <div className="custom-form-group col-md-1 px-0">
                <div className="custom-file-drop-sec">
                  <div className="imgView">
                    <div className="mx-auto mb-0 fileTab h-100">
                      <label onClick={openSelect34} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                          {previewImage34 ? (
                            <img
                              src={previewImage34}
                              alt=""
                              className="w-100 h-100"
                            />
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 0.222656C3.13438 0.222656 0 3.35703 0 7.22266C0 11.0883 3.13438 14.2227 7 14.2227C10.8656 14.2227 14 11.0883 14 7.22266C14 3.35703 10.8656 0.222656 7 0.222656ZM10 7.59766C10 7.66641 9.94375 7.72266 9.875 7.72266H7.5V10.0977C7.5 10.1664 7.44375 10.2227 7.375 10.2227H6.625C6.55625 10.2227 6.5 10.1664 6.5 10.0977V7.72266H4.125C4.05625 7.72266 4 7.66641 4 7.59766V6.84766C4 6.77891 4.05625 6.72266 4.125 6.72266H6.5V4.34766C6.5 4.27891 6.55625 4.22266 6.625 4.22266H7.375C7.44375 4.22266 7.5 4.27891 7.5 4.34766V6.72266H9.875C9.94375 6.72266 10 6.77891 10 6.84766V7.59766Z" fill="black"/>
                              </svg>
                              <p className="mb-0 mt-0 lh-sm">{t('profile_section.add_image')}</p>
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    <input
                      hidden
                      type="file"
                      name="profile"
                      id="profile"
                      accept="image/*"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setPreviewImage34(url);
                        setImage34(e.target.files[0]);
                      }}
                      ref={inputRef34}
                    />
                  </div>
                </div>
              </div>
          </div>
          <div className="row mx-0 align-items-center">
              <div className="col-md-11 pe-4">
              <label className="mb-2">{t('profile_section.nps')}</label>
              <input
                placeholder={t('profile_section._100')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
              </div>
              <div className="custom-form-group col-md-1 px-0">
                <div className="custom-file-drop-sec">
                  <div className="imgView">
                    <div className="mx-auto mb-0 fileTab h-100">
                      <label onClick={openSelect34} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                          {previewImage34 ? (
                            <img
                              src={previewImage34}
                              alt=""
                              className="w-100 h-100"
                            />
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 0.222656C3.13438 0.222656 0 3.35703 0 7.22266C0 11.0883 3.13438 14.2227 7 14.2227C10.8656 14.2227 14 11.0883 14 7.22266C14 3.35703 10.8656 0.222656 7 0.222656ZM10 7.59766C10 7.66641 9.94375 7.72266 9.875 7.72266H7.5V10.0977C7.5 10.1664 7.44375 10.2227 7.375 10.2227H6.625C6.55625 10.2227 6.5 10.1664 6.5 10.0977V7.72266H4.125C4.05625 7.72266 4 7.66641 4 7.59766V6.84766C4 6.77891 4.05625 6.72266 4.125 6.72266H6.5V4.34766C6.5 4.27891 6.55625 4.22266 6.625 4.22266H7.375C7.44375 4.22266 7.5 4.27891 7.5 4.34766V6.72266H9.875C9.94375 6.72266 10 6.77891 10 6.84766V7.59766Z" fill="black"/>
                              </svg>
                              <p className="mb-0 mt-0 lh-sm">{t('profile_section.add_image')}</p>
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    <input
                      hidden
                      type="file"
                      name="profile"
                      id="profile"
                      accept="image/*"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setPreviewImage34(url);
                        setImage34(e.target.files[0]);
                      }}
                      ref={inputRef34}
                    />
                  </div>
                </div>
              </div>
          </div>
          
          <div className="row mx-0 align-items-center">
              <div className="col-md-11 pe-4">
              <label className="mb-2">{t('profile_section.rank')}</label>
              <input
                placeholder={t('profile_section.rank')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
              </div>
              <div className="custom-form-group col-md-1 px-0">
                 <div className="custom-file-drop-sec">
                  <div className="imgView">
                    <div className="mx-auto mb-0 fileTab h-100">
                      <label onClick={openSelect37} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                          {previewImage37 ? (
                            <img
                              src={previewImage37}
                              alt=""
                              className="w-100 h-100"
                            />
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 0.222656C3.13438 0.222656 0 3.35703 0 7.22266C0 11.0883 3.13438 14.2227 7 14.2227C10.8656 14.2227 14 11.0883 14 7.22266C14 3.35703 10.8656 0.222656 7 0.222656ZM10 7.59766C10 7.66641 9.94375 7.72266 9.875 7.72266H7.5V10.0977C7.5 10.1664 7.44375 10.2227 7.375 10.2227H6.625C6.55625 10.2227 6.5 10.1664 6.5 10.0977V7.72266H4.125C4.05625 7.72266 4 7.66641 4 7.59766V6.84766C4 6.77891 4.05625 6.72266 4.125 6.72266H6.5V4.34766C6.5 4.27891 6.55625 4.22266 6.625 4.22266H7.375C7.44375 4.22266 7.5 4.27891 7.5 4.34766V6.72266H9.875C9.94375 6.72266 10 6.77891 10 6.84766V7.59766Z" fill="black"/>
                              </svg>
                              <p className="mb-0 mt-0 lh-sm">{t('profile_section.add_image')}</p>
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    <input
                      hidden
                      type="file"
                      name="profile"
                      id="profile"
                      accept="image/*"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setPreviewImage37(url);
                        setImage37(e.target.files[0]);
                      }}
                      ref={inputRef37}
                    />
                  </div>
                </div>
              </div>
          </div>
          
          
          <div className="row mx-0 align-items-center">
              <div className="col-md-11 pe-4">
              <label className="mb-2">{t('profile_section.leaderboard_rank_status')}</label>
              <input
                placeholder={t('profile_section.your_rank_statustotal_points')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
              </div>
              <div className="custom-form-group col-md-1 px-0">
                <div className="custom-file-drop-sec">
                  <div className="imgView">
                    <div className="mx-auto mb-0 fileTab h-100">
                      <label onClick={openSelect36} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                          {previewImage36 ? (
                            <img
                              src={previewImage36}
                              alt=""
                              className="w-100 h-100"
                            />
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 0.222656C3.13438 0.222656 0 3.35703 0 7.22266C0 11.0883 3.13438 14.2227 7 14.2227C10.8656 14.2227 14 11.0883 14 7.22266C14 3.35703 10.8656 0.222656 7 0.222656ZM10 7.59766C10 7.66641 9.94375 7.72266 9.875 7.72266H7.5V10.0977C7.5 10.1664 7.44375 10.2227 7.375 10.2227H6.625C6.55625 10.2227 6.5 10.1664 6.5 10.0977V7.72266H4.125C4.05625 7.72266 4 7.66641 4 7.59766V6.84766C4 6.77891 4.05625 6.72266 4.125 6.72266H6.5V4.34766C6.5 4.27891 6.55625 4.22266 6.625 4.22266H7.375C7.44375 4.22266 7.5 4.27891 7.5 4.34766V6.72266H9.875C9.94375 6.72266 10 6.77891 10 6.84766V7.59766Z" fill="black"/>
                              </svg>
                              <p className="mb-0 mt-0 lh-sm">{t('profile_section.add_image')}</p>
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    <input
                      hidden
                      type="file"
                      name="profile"
                      id="profile"
                      accept="image/*"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setPreviewImage36(url);
                        setImage36(e.target.files[0]);
                      }}
                      ref={inputRef36}
                    />
                  </div>
                </div>
              </div>
          </div>
          <div className="">
            <ul className="row">
              <NavItem as="li" className="col-lg-4 col-md-6 col-12">
                <ToggleButton name="1st Place" id="1stPlace" />
              </NavItem>
              <NavItem as="li" className="col-lg-4 col-md-6 col-12">
                <ToggleButton name="2nd Place" id="2ndPlace" />
              </NavItem>
              <NavItem as="li" className="col-lg-4 col-md-6 col-12">
                <ToggleButton name="3rd Place" id="3rdPlace" />
              </NavItem>
            </ul>
          </div>
          <div className="">
          <div className="my-3">
              <label className="w-100 mb-5">{t('profile_section.month')}</label>
              <RangeSlider value={yearEnded} onChange={changeEvent => setYearEnded(changeEvent.target.value)} min={1} max={12}/> 
              <ul className="d-flex monthsec flex-wrap mb-4 pb-2">
                <NavItem as="li">Jan</NavItem>
                <NavItem as="li">Feb</NavItem>
                <NavItem as="li">Mar</NavItem>
                <NavItem as="li">Apr</NavItem>
                <NavItem as="li">May</NavItem>
                <NavItem as="li">Jun</NavItem>
                <NavItem as="li">Jul</NavItem>
                <NavItem as="li">Aug</NavItem>
                <NavItem as="li">Sep</NavItem>
                <NavItem as="li">Oct</NavItem>
                <NavItem as="li">Nov</NavItem>
                <NavItem as="li">Dec</NavItem>
              </ul>
            </div>
            <div className="mb-3">
            <label  className="w-100 mb-5">{t('profile_section.year_started')}</label>
              <RangeSlider value={yearStarted} onChange={changeEvent => setYearStarted(changeEvent.target.value)} min={2000} max={2099}/>
            </div>
          </div>
          <div className="mt-4 text-center">
            <ButtonBox boxwidth="w-140 mt-0 mb-50 mx-auto" clicked="Saved" name="Save/Next" />
          </div>
        </form>
      </div>
    </div>
  );
}
