import React from 'react'
// import integrationbanner from '../../../../../images/integrationbanner.png'
import IntegTab from './integTab'

const Integration = () => {
  return (
    <div className='pt-4'>
      <div className="topheadGlob px-4 mx-auto">
          <div className="inrhead text-uppercase">
            INTEGRATIONS & CONNECTIONS
          </div>
      </div>
      <div className='glob-track py-4'>
        <div className='pt-5'>
        <IntegTab/>
        </div>
      </div>
    </div>
  )
}

export default Integration
