import React from 'react'
import iconBluew1 from "../../../../../../images/formicon/icon13.png";
import iconBluew2 from "../../../../../../images/formicon/icon14.png";
import iconBluew3 from "../../../../../../images/formicon/icon15.png";
import IntegAddFrom from '../IntegAddFrom';
import icon2 from "../../../../../../images/formicon/user/icon2.png";
import icon3 from "../../../../../../images/formicon/icon40.png";
import icon4 from "../../../../../../images/formicon/icon41.png";
import icon5 from "../../../../../../images/formicon/icon42.png";
import icon6 from "../../../../../../images/formicon/icondot.png";
import ConnectComponent from './ConnectComponent';
import ConnectComponentInput from './ConnectComponentInput'; 
export default function Other() {
  return (
    <div>
      <div className='row'>
        <div className='col-lg-12'>
          <ul className="customDrop6 customDrop1s max-h-auto customDrop2s w-100 shadow-none position-relative">
            <div className="dropheight h-auto">
              <ConnectComponent Name="Shopify" icon={iconBluew1} />
              <ConnectComponent Name="Wordpress" icon={iconBluew2} />
              <ConnectComponent Name="Blogger" icon={iconBluew3} />
              <ConnectComponent Name="Hubspot" icon={icon2} />
              <ConnectComponent Name="Ghost" icon={icon3} />
              <ConnectComponent Name="Email  Marketing List" icon={icon4} />
              <ConnectComponent Name="social media Marketing" icon={icon5} />
              <ConnectComponent Name="Google Calendar" icon={icon6} />
              <ConnectComponent Name="Gmail accounts" icon={icon6}/>
              <ConnectComponent Name="zapier" icon={icon6}/>
              <ConnectComponent Name="Trello" icon={icon6}/>
              <ConnectComponent Name="TRUSTPILOT" icon={icon6}/>
              <ConnectComponent Name="CAPTERRA" icon={icon6}/>
              <ConnectComponent Name="GOOGLE REVIEWS" icon={icon6}/>
              <ConnectComponent Name="REVIEWS FROM SOCIAL MEDIA" icon={icon6}/>
              <ConnectComponentInput Name="Other ?  Type here...." icon={icon6}/>
              <IntegAddFrom />
            </div>
          </ul>
        </div>
      </div>
    </div>
  )
}

