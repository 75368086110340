import React from 'react';
import '../../../global/timelineTab/TimelineTab.scss';
import Video from '../../../../images/iconVid.png';
import VideoPopup from '../../../global/VideoPopup';
import Video1 from '../../../../images/iconVid1.png';
import ButtonBox from "../../../global/ButtonBox";
// import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import MissionStatementForm from './MissionStatementForm';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';

export default function MissionStatmentStep() {
    const { t, i18n } = useTranslation();
    let {isVideoVisible} = useSelector(state => state.videoReducer);
    const dispatch = useDispatch();
    const handleClick6 = () => {
      dispatch(toggleVideo());
    };
    return (
        <div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="two">
                <div className='' >
                    <div className='flex'>
                        <div className='width-fll'>
                            <VideoPopup />
                            <Tab.Content className='class-scroll  ' >
                                <Tab.Pane eventKey="two">
                                    <div className="  align-items-center mx-auto d-flex flex-column-reverse justify-content-between three-bsiness">
                                        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto ">
                                        <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>

                                            <h3 className='h3-border h4-top'>{t('timeline_form_four_component.create_your_mission_statement')}</h3>
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="factorone7" role="tabpanel" aria-labelledby="factorone7">
                                                    <MissionStatementForm />
                                                </div>
                                                <div className="tab-pane fade" id="factortwo7" role="tabpanel" aria-labelledby="factortwo7">
                                                    <div>
                                                        <p className='h3-border1 pb-2 mb-2'>
                                                        Already Have a Mission Statement?
                                                        </p>
                                                        <p className='h7 mt-3 fw-normal lh-normal'>Type your current mission statement here</p>
                                                        <textarea rows="4" className='shadow-5in p-4 h6 lh-normal fw-normal d-block  textarea-for' placeholder="I help new moms to lose their baby weight in 8 weeks so they can have their body back and enjoy their newborn with confidence"></textarea>
                                                    </div>
                                                    <div className="w-100 d-flex pb-5 justify-content-center align-items-center btn_rotate">
                                                        <ButtonBox boxwidth="w-300 mt-5 lg text-primary mb-4" clicked="Mission Statement Saved" name="Save Your Mission Statement" />
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <span className="d-flex position-relative align-items-center justify-content-between w-100 mission-step mb-4">
                                            <div id="myTab1" className='flex' role="tablist">
                                                <button className="buttonArrow me-3 nav-link text-body border-0 bg br-10 active" id="factorone7" data-bs-toggle="tab" data-bs-target="#factorone7" type="button" role="tab" aria-controls="factorone7" aria-selected="true">
                                                    <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
                                                </button>
                                                <button className=" me-3 btn-positions1 shadow-4 h8 topBtn border-0 bg text-body nav-link border-0 bg" id="factortwo7" data-bs-toggle="tab" data-bs-target="#factortwo7" type="button" role="tab" aria-controls="factortwo7" aria-selected="false">
                                                Already Have a Mission Statement?
                                                </button>
                                            </div>
                                            <div className='btn-save-hide'>
                                                <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="" icon={faFloppyDisk} name="Save" />
                                            </div>

                                        </span>
                                    </div>
                                </Tab.Pane>
                           
                            </Tab.Content>


                        </div>
                    </div>
                </div>





            </Tab.Container>
          
        </div>
    )
}