import React from "react";
import ButtonBox from "../../../global/ButtonBox";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownload, faLock, faMicrophone, faPlay } from '@fortawesome/free-solid-svg-icons'
import './EmailSignatureStep.scss';
import { NavItem } from 'react-bootstrap';
import Messenger from '../../../../images/social-icons/messenger.png'
import Blog from '../../../../images/social-icons/blog.png'
import Event from '../../../../images/social-icons/event.png'
import Product from '../../../../images/social-icons/product.png'
import Webinar from '../../../../images/social-icons/webinar.png'
import Course from '../../../../images/social-icons/course.png'
import Surveys from '../../../../images/social-icons/surveys.png'
import Polls from '../../../../images/social-icons/polls.png'
import donwloadimg from '../../../../images/profileview/icon48.png'
import ButtonBoxNew from "../../../global/ButtonBoxNew";
// import { ReactComponent as Download } from "../../../../images/icons/icon29.svg";

export default function EmailSignatureForm2() {
  const { t } = useTranslation();
  return (
    <div>
      <ul className="TAB-8_1-form">
        <div className="text-center mb-5">
          <h2>{t('timeline_form_seven_component.featureed_content')}</h2>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Messenger} alt="" />
              </div>
            </div>
            <label for="">Wall of fame</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                 {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                 <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <FontAwesomeIcon icon={faMicrophone} className=" icon" />
              </div>
            </div>
            <label for="">Podcast Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                 {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                 <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <FontAwesomeIcon icon={faPlay} className=" icon" />
              </div>
            </div>
            <label for="">Video Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Blog} alt="" />
              </div>
            </div>
            <label for="">Blog Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                 {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                 <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Event} alt="" />
              </div>
            </div>
            <label for="">Event Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Product} alt="" />
              </div>
            </div>
            <label for="">Product Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Webinar} alt="" />
              </div>
            </div>
            <label for="">Webinar Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Course} alt="" />
              </div>
            </div>
            <label for="">Course Icon</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                 {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                 <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Surveys} alt="" />
              </div>
            </div>
            <label for="">Surveys</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2 text-center">
            <div className="social_icon mb-2 mx-auto">
              <div className="social_icon_block">
                <img src={Polls} alt="" />
              </div>
            </div>
            <label for="">Polls</label>
          </div>
          <div className="col-md-8 ps-0 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <img src={donwloadimg} alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4 justify-content-end">
          {/* <button class="btn btn-primary px-2 me-3 all_QR_btn" type="button" id="button-addon1" data-mdb-ripple-color="dark">
            Generate all QR code
          </button> */}
           <ButtonBoxNew  name=" Generate all QR code" boxwidth="w-300 mt-0 mb-4 bg2 br-40" />
        </div>

        <div className="row mb-4">
          <label for="">Disclaimer text:</label>
          <div className="disclaimer">
            <p>Thank you for subscribing to us and opting in. You received this email to keep you up-to-date on important news, promos, and changes to your account and services with us.</p>
            <p>| Update Email Preferences| Update your profile | Privacy Policy | Unsubscribe |</p>
            <p>© 2023| 9 to 5 Escapee</p>
            <p>2967 Dundas STREET WEST  SUITE # 1220 Toronto, ONTARIO  M6P 1Z2 , CANADA Powered by 9 to 5 Escapee</p>
          </div>
        </div>
        <div className='col-md-12 p-0 h8 check-button mx-auto mb-2 d-flex justify-content-between align-items-center'>
          <div>
            {/* <input type="checkbox" className="btn-check" name="communication-day" id="signature" />
            <label className="btn btn-outline-primary" htmlFor="signature">
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
              </svg>
            </label>
            {t('timeline_form_seven_component.remember_my_details')} */}
            <div className='check-button w-500 mx-auto mb-2 d-flex justify-content-start align-items-center'>
                <input type="checkbox" className="btn-check" name="remember1s" id="remember1s" />
                <label className="btn rounded-circle btnRadio large me-3 p-0" htmlFor="remember1s"><div className="radioInner"><span></span></div></label>
                {t('timeline_form_seven_component.remember_my_details')}
            </div>
          </div>
        </div>


        {/*            
                <NavItem as="li" className="mb-5 border-box">
                  
                </NavItem> */}
        <NavItem as="li" className=" text-center time-btn mt-4">

          <ButtonBox
            boxwidth="w-300 mt-0 mb-50 mx-auto"
            clicked="Generate Email Signature"
            name="Generate Email Signature"
          />
        </NavItem>
      </ul>
    </div >
  );
} 
