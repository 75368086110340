import * as React from 'react';
import ButtonBox from '../global/ButtonBox';
import User from '../../images/img1.png';
import '../login/login.scss';
import { useState, useEffect } from 'react';
import SocialBox from '../global/SocialBox';
import { toast } from 'react-toastify';
import {useDispatch, useSelector} from "react-redux";
import { SigninAction } from '../../redux/actions/authActions';
import { useTranslation } from "react-i18next";
import { ReactComponent as IconBlue1 } from "../../images/svgIcon/icon3.svg";
import { NavItem } from 'react-bootstrap';


export default function SignIn() {
    const { t, i18n } = useTranslation();

    const validateEmail = (email)  => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const {response} = useSelector(state=> state.authReducer);
    useEffect(() => {
        if(response){
            document.getElementById('termCondition').click();
            document.getElementById('remember').click();
            setClose(true);
        }
    }, [response]); 
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [termCondition, setTermCondition] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [close, setClose] = useState(false);


    const handleRemember = event => {
        if (event.target.checked) {
            setRememberMe(true);
        } else {
            setRememberMe(false);
        }
      };
      const handleTermCondition = event => {
        if (event.target.checked) {
            setTermCondition(true);
        } else {
            setTermCondition(false);
        }
      };
    const handleSubmit = async (e) =>{
        e.preventDefault();
          if (!email){
            toast('Email is required');
          }
          else if (!validateEmail(email)) {
            toast('Please Enter a Valid Email Address')
        }
          else if (!password){
            toast('Password is required');
          }
          else if (!termCondition && termCondition === false){
            toast('Terms & Conditions is required');
          }
          else if (!rememberMe){
            toast('Remember Me  is required');
          }
          else{
            try { 
                 let data = {
                     email : email,
                     password : password,
                     terms_conditions : termCondition,
                     remember_me : rememberMe
                 }
                 dispatch(SigninAction(data, setEmail,setPassword,setTermCondition,setRememberMe));
         
               
               } catch (error) {
                 console.log("err", error);
               }
             }
          }
    return (
        <div className='text-center'>
            <div className='uploadImage mx-auto mb-4'>
                <label htmlFor="profile_pic" className='p-0'>
                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center" >
                        <img src={User} alt="" className='ImageCover' />
                    </div>
                </label>
                <input className="hidden" type="file" name="" id="profile_pic" accept="image/*" />
            </div>
            <h3 className='mb-4'>{t('login')}</h3>
            <ul className='pb-5'>
                <form onSubmit={handleSubmit}>
                <NavItem as="li" className='mb-4 '>
                    <input placeholder={t('signin_component.email_username_placeholder')} className="shadow-5in br-10 w-500" type="text" value={email} onChange={(e)=> {setEmail(e.target.value);}} />
                </NavItem>
                <NavItem as="li" className='mb-4'>
                    <input placeholder={t('signin_component.password_placeholder')} className="shadow-5in br-10 w-500" type="password" value={password} onChange={(e)=> {setPassword(e.target.value);}} />
                </NavItem>
                <NavItem as="li" className='mb-3'>
                    <div className='check-button w-500 mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="termCondition" id="termCondition" value={termCondition}  onChange={handleTermCondition}  />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="termCondition"><div className="radioInner"><span></span></div></label>
                        {t('agree_statement')}
                    </div>
                </NavItem>
                <NavItem as="li" className='mb-5'>
                    <div className='check-button w-500 mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="remember" id="remember" value={rememberMe } onChange={handleRemember}  />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="remember"><div className="radioInner"><span></span></div></label>
                        {t('remember_me')}
                    </div>
                </NavItem>
                <NavItem as="li">
                    <ButtonBox boxwidth="w-110 mt-0 mb-50" clicked="Signed" close={close} name="sign in" />
                </NavItem>
                <NavItem as="li">
                    <h6>{t('signin_component.dont_have_an_account')}</h6>
                    <ButtonBox boxwidth="w-110 mt-3 mb-50"  clicked="Signed" name="Sign Up" />
                </NavItem>
                </form>
                <NavItem as="li" className='w-100 text-center'>
                    <div className='dividerText'>{t('or')}</div>
                </NavItem>
                <NavItem as="li">
                    <SocialBox />
                </NavItem>
            </ul>

        </div>
    );
}

