import * as React from 'react';
import {lazy} from 'react';
import './login.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import LockScreen from '../auth/LockScreen';
import ButtonBox from '../../components/global/ButtonBox';
import SignUp from '../auth/SignUp';
import SignIn from '../auth/SignIn';
import Reset from '../auth/Reset';
import CarouselFade from '../../components/global/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutAction } from '../../redux/actions/authActions';
// const ButtonBox = lazy(()=> import('../../components/global/ButtonBox'));
// const SignUp = lazy(()=> import('../auth/SignUp'));
// const SignIn = lazy(()=> import('../auth/SignIn'));
import { useTranslation } from "react-i18next";
import { library } from "@fortawesome/fontawesome-svg-core";
import { NavItem } from 'react-bootstrap';
import  downArrow  from '../../images/triangle1.png';
import  frame  from  '../../images/Frame 427320362.png';
import frameGray from '../../images/Frame-gray.png';
import Rectangle from '../../images/Rectangle-golden.png';



library.add( faPowerOff, faArrowRotateLeft  );

export default function App() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [close, setClose] = useState(false);
    const {user,isAuthenticated} = useSelector(state => state.authReducer);
    const handleLogout = () =>{
        dispatch(LogoutAction());
        setClose(true);
    }
    return (
        <div className="w-100 d-inline-block">
            {/* <div className="dropdown bg-transparent">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Dropdown button
                <img src={downArrow} className='ml-2 ' />

                </button>
          
                <ul class="dropdown-menu customDrop my-4 relative" aria-labelledby="dropdownMenuButton1">
                    <li><span><a className="dropdown-item" href="#">Action</a></span></li>
                    <li><span><a className="dropdown-item" href="#">Action</a></span></li>
                    <li><span><a className="dropdown-item" href="#">Action</a></span></li>
                </ul>
            </div> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <CarouselFade />
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className='w-100 TabsHeight pe-4'>
                            {isAuthenticated && 
                            <div className= "d-flex justify-content-end px-3" >
                                <ButtonBox boxwidth="w-110 mt-0 mb-50" close={close} btnclick={handleLogout} clicked="Logout" name="Logout" />
                            </div> }
                            <div className='d-flex flex-column-reverse'>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade" id="signin" role="tabpanel" aria-labelledby="signin">
                                        <SignIn />
                                    </div>
                                    <div className="tab-pane fade  show active" id="signup" role="tabpanel" aria-labelledby="signup">
                                        <SignUp />
                                    </div>
                                    <div className="tab-pane fade" id="reset" role="tabpanel" aria-labelledby="reset">
                                        <Reset />
                                    </div>
                                    <div className="tab-pane fade" id="lockscreen" role="tabpanel" aria-labelledby="lockscreen">
                                        <LockScreen />
                                    </div>
                                </div>
                                <div className='w-540 mx-auto'>
                                    <div className='d-none d-lg-block'>
                                        <div className='scroll-bar w-100 shadow-4in br-20 my-30'></div>
                                    </div>
                                    <div className='login-tab  mb-4 pb-3 mt-5 mt-lg-0'>
                                        <ul className="nav" id="myTab" role="tablist">
                                            <NavItem as="li" className='pe-3'>
                                                <div className='btn-layer br-30 bg'>
                                                    <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="signin" data-bs-toggle="tab" data-bs-target="#signin" type="button" role="tab" aria-controls="signin" aria-selected="true">
                                                        <FontAwesomeIcon icon={faPowerOff} className="me-2 h6" />
                                                        {t('login')}
                                                    </button>
                                                </div>
                                            </NavItem>
                                            <NavItem as="li" className="pe-3">
                                                <div className='btn-layer br-30 bg'>
                                                    <button className="br-20 w-140 h-90 border-0 active" id="signup" data-bs-toggle="tab" data-bs-target="#signup" type="button" role="tab" aria-controls="signup" aria-selected="false">
                                                        <FontAwesomeIcon icon={faPowerOff} className="me-2 h6" />
                                                        {t('signup')}
                                                    </button>
                                                </div>
                                            </NavItem>
                                            <NavItem as="li" className="pe-3">
                                                <div className='btn-layer br-30 bg'>
                                                    <button className="br-20 w-140 h-90 border-0" id="reset" data-bs-toggle="tab" data-bs-target="#reset" type="button" role="tab" aria-controls="reset" aria-selected="false">
                                                        <FontAwesomeIcon icon={faArrowRotateLeft} className="me-2 h6 IconRotate" />
                                                        {t('reset')}
                                                    </button>
                                                </div>
                                            </NavItem>
                                            <NavItem as="li" className="pe-0">
                                                <div className='btn-layer br-30 bg'>
                                                    <button className="br-20 w-140 h-90 border-0" id="lockscreen" data-bs-toggle="tab" data-bs-target="#lockscreen" type="button" role="tab" aria-controls="lockscreen" aria-selected="false">
                                                        <svg width="12" className='me-2' height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.2 7.04713H9.26672V5.18047C9.26672 3.36047 7.82005 1.9138 6.00005 1.9138C4.18005 1.9138 2.73338 3.36047 2.73338 5.18047V7.04713H1.80005V5.18047C1.80005 2.84714 3.66672 0.980469 6.00005 0.980469C8.33338 0.980469 10.2 2.84714 10.2 5.18047V7.04713Z" fill="currentColor" />
                                                            <path d="M9.73337 14.9801H2.2667C1.47337 14.9801 0.866699 14.3734 0.866699 13.5801V7.98008C0.866699 7.18674 1.47337 6.58008 2.2667 6.58008H9.73337C10.5267 6.58008 11.1334 7.18674 11.1334 7.98008V13.5801C11.1334 14.3734 10.5267 14.9801 9.73337 14.9801ZM2.2667 7.51341C1.9867 7.51341 1.80003 7.70008 1.80003 7.98008V13.5801C1.80003 13.8601 1.9867 14.0467 2.2667 14.0467H9.73337C10.0134 14.0467 10.2 13.8601 10.2 13.5801V7.98008C10.2 7.70008 10.0134 7.51341 9.73337 7.51341H2.2667Z" fill="currentColor" />
                                                            <path d="M6.00011 10.3139C6.51557 10.3139 6.93344 9.89606 6.93344 9.3806C6.93344 8.86513 6.51557 8.44727 6.00011 8.44727C5.48464 8.44727 5.06677 8.86513 5.06677 9.3806C5.06677 9.89606 5.48464 10.3139 6.00011 10.3139Z" fill="currentColor" />
                                                            <path d="M6.23338 9.38086H5.76672L5.30005 12.1809H6.70005L6.23338 9.38086Z" fill="currentColor" />
                                                        </svg>
                                                        {t('lock_screen')}
                                                    </button>
                                                </div>
                                            </NavItem>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}