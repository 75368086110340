import React from 'react';
import '../../../global/timelineTab/TimelineTab.scss';
import VideoPopup from '../../../global/VideoPopup';
import ButtonBox from "../../../global/ButtonBox";
import Tab from 'react-bootstrap/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import './AffiliatePrograms.scss'
import { useTranslation } from "react-i18next";
import AffiliateProgramsForm from './AffiliateProgramsForm';
import PrintfulMerchAccountForm from './PrintfulMerchAccountForm';

export default function AffiliateProgramsStep() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });


    return (
        <div className='sectionfifth paypal_accrount arrow-select'>
            <Tab.Container id="left-tabs-example" defaultActiveKey="two">
                <div className='' >
                    <div className='flex'>


                        <div className='width-fll'>
                            <VideoPopup />
                            <Tab.Content className='class-scroll'>
                                <Tab.Pane eventKey="two">
                                    <div className="w-100 align-items-center mx-auto d-flex flex-column-reverse justify-content-between three-bsiness">
                                        <div className='d-flex w-100'>

                                            <div className='w-100 align-items-center mx-auto d-flex flex-column-reverse justify-content-between three-bsiness'>
                                                <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto ">
                                                    <div className="tab-content" id="myTabContent">
                                                        <div className="tab-pane fade active show" id="factor6" role="tabpanel" aria-labelledby="factor6">
                                                            <AffiliateProgramsForm/>
                                                        </div>
                                                        <div className="tab-pane fade" id="factor7" role="tabpanel" aria-labelledby="factor7">
                                                            <PrintfulMerchAccountForm/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className="d-flex align-items-center justify-content-between w-100 mb-4">
                                                    <div id="myTab1" className='flex' role="tablist">
                                                        <button className="buttonArrow me-3 nav-link text-body border-0 bg br-10 active" id="factor6" data-bs-toggle="tab" data-bs-target="#factor6" type="button" role="tab" aria-controls="factor6" aria-selected="false">
                                                            <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
                                                        </button>
                                                        <button className="buttonArrow me-3 text-body nav-link border-0 bg br-10" id="factor7" data-bs-toggle="tab" data-bs-target="#factor7" type="button" role="tab" aria-controls="factor7" aria-selected="false">
                                                            <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
                                                        </button>
                                                    </div>
                                                    <div className='btn-save-hide'>
                                                        <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="" icon={faFloppyDisk} name="Save" />
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>

                            </Tab.Content>


                        </div>
                    </div>
                </div>





            </Tab.Container>

        </div>
    )
}