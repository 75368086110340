import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
export default function PrivacySlider() {
  const { t, i18n } = useTranslation();
  const responsive = {
    desktop: {
    breakpoint: { max: 3000, min: 1366 },
    items: 6,
    slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
    breakpoint: { max: 1366, min: 768 },
    items: 5,
    slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
    }
};
  return (
    <Carousel responsive={responsive} autoPlay={false}  autoPlaySpeed={2000} transitionDuration={500}>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 active" id="general" data-bs-toggle="tab" data-bs-target="#general" type="button" role="tab" aria-controls="general" aria-selected="true">{t('privacy_component.general_settings')}</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="factor" data-bs-toggle="tab" data-bs-target="#factor" type="button" role="tab" aria-controls="factor" aria-selected="false">{t('privacy_component.two_factor_authentication_settings')}</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="emailTab" data-bs-toggle="tab" data-bs-target="#emailTab" type="button" role="tab" aria-controls="emailTab" aria-selected="false">{t('privacy_component.email_notifications')}</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="privacyTab" data-bs-toggle="tab" data-bs-target="#privacyTab" type="button" role="tab" aria-controls="privacyTab" aria-selected="false">{t('privacy_component.privacy_settings')}</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="timeLine" data-bs-toggle="tab" data-bs-target="#timeLine" type="button" role="tab" aria-controls="timeLine" aria-selected="true">{t('privacy_component.timeline_tagging_reviews_settings')}</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="restrictions" data-bs-toggle="tab" data-bs-target="#restrictions" type="button" role="tab" aria-controls="restrictions" aria-selected="false">{t('privacy_component.restrictions_blocks_settings')}</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="sessions" data-bs-toggle="tab" data-bs-target="#sessions" type="button" role="tab" aria-controls="sessions" aria-selected="false">{t('privacy_component.sessions_logs_location_settings')}</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="security" data-bs-toggle="tab" data-bs-target="#security" type="button" role="tab" aria-controls="security" aria-selected="false">{t('privacy_component.account_settings')}</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="change" data-bs-toggle="tab" data-bs-target="#change" type="button" role="tab" aria-controls="change" aria-selected="false">{t('privacy_component.change_passwords')}</button>
        </div>
    </Carousel>
  )
}