import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import subscribeUserIcon from "../../../../../../images/subscription/subscribe-user-icon.png";
import subscribeAroundTextImg from "../../../../../../images/subscription/subscribr-text-img.svg";
import subscribeAroundTextBlackImg from "../../../../../../images/subscription/subscribr-text-black-img.png";
import theme3TopRightDotsImg from "../../../../../../images/subscription/theme3-top-right-dots-img.png";
import theme3BottomLeftDotsImg from "../../../../../../images/subscription/theme3-bottom-left-dots-img.png";
import theme3BottomRightDotsImg from "../../../../../../images/subscription/theme3-bottom-right-dots-bg-img.png";
import theme3NeumorphicTopRightDotsImg from "../../../../../../images/subscription/theme3-neumorphic-top-right-dots-img.png";
import theme3NeumorphicBottomRightDotsImg from "../../../../../../images/subscription/theme3-neumorphic-bottom-right-dots-img.png";
import theme3NeumorphicBottomLeftDotsImg from "../../../../../../images/subscription/theme3-neumorphic-bottom-left-dots-img.png";
import "./RegularTheme3Desktop.scss";
import "../regularTheme3Mobile/RegularTheme3Mobile.scss";
import "../regularTheme3Tab/RegularTheme3Tab.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Form from "react-bootstrap/Form";
export default function RegularTheme3Desktop({
  Regular,
  Neumorphic,
  Glassmorphic,
}) {
  const [isSwitchBtn, setSwitchBtn] = useState(false);
  const switchToggleBtn = () => {
    setSwitchBtn(!isSwitchBtn);
  };
  return (
    <>
      <div
        className="subscription-desktop-theme3"
        style={{
          background: Regular ? "#CBD5E0" : Neumorphic ? "#F0F4F8" : Glassmorphic ? "linear-gradient(326deg, #9595B8 0%, #ECEDF5 46.35%, #C8C9D2 70.83%, #97999D 100%)" : "",
        }}
      >
        <div
          className={`theme-3-main-wrapper  ${
            Regular
              ? "regular-style"
              : Neumorphic
              ? "neumorphic-style"
              : Glassmorphic
              ? "glassmorphic-style"
              : ""
          }`}
        >
          <div
            className={`subscription-desktop-theme3-main-wrapper ${
              Regular
                ? "regular-style"
                : Neumorphic
                ? "neumorphic-style"
                : Glassmorphic
                ? "glassmorphic-style"
                : ""
            }`}
          >
            <div
              className={`subscribe-weekly-drops ${
                Regular
                  ? "regular-style"
                  : Neumorphic
                  ? "neumorphic-style"
                  : Glassmorphic
                  ? "glassmorphic-style"
                  : ""
              }`}
            >
              <div className="subscribe-weekly-drops-icon-wrapper">
                <div
                  className={`img-wrapper  ${
                    Regular
                      ? "regular-style"
                      : Neumorphic
                      ? "neumorphic-style"
                      : Glassmorphic
                      ? "glassmorphic-style"
                      : ""
                  }`}
                >
                  <img
                    src={subscribeAroundTextBlackImg}
                    alt="subscribeAroundTextImg"
                    className="subscribeAroundTextBlackImg"
                  />
                  <img
                    src={subscribeAroundTextImg}
                    alt="subscribeAroundTextImg"
                    className="subscribeAroundNeumorphicTextImg"
                  />
                  <div className="icon-wrapper">
                    <img
                        src={subscribeUserIcon}
                        alt="subscribeUserIcon"
                        className="subscribeUserIcon"
                    />
                  </div>
                  <p className="pick-poison-text">WHAT'S YOUR POISON?</p>
                </div>
              </div>
              <div
                className={`on-off-buttons-wrapper ${
                  Regular
                    ? "regular-style"
                    : Neumorphic
                    ? "neumorphic-style"
                    : Glassmorphic
                    ? "glassmorphic-style"
                    : ""
                }`}
              >
                <ul className="on-off-btn-items">
                  <li>
                    <div
                      className={`toggle-switch ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="events"
                        id="events"
                        defaultChecked={isSwitchBtn}
                        onClick={switchToggleBtn}
                      />
                      <label className="label" htmlFor="events">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <strong>EVENTS</strong>
                  </li>
                  <li>
                    <div
                      className={`toggle-switch ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="newsletter"
                        id="newsletter"
                        defaultChecked={true}
                        onClick={switchToggleBtn}
                      />
                      <label className="label" htmlFor="newsletter">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <strong>NEWSLETTER</strong>
                  </li>
                  <li>
                    <div
                      className={`toggle-switch ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="videoChannel"
                        id="videoChannel"
                        defaultChecked={isSwitchBtn}
                        onClick={switchToggleBtn}
                      />
                      <label className="label" htmlFor="videoChannel">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <strong>VIDEO CHANNEL</strong>
                  </li>
                  <li>
                    <div
                      className={`toggle-switch ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="blogPosts"
                        id="blogPosts"
                        defaultChecked={isSwitchBtn}
                        onClick={switchToggleBtn}
                      />
                      <label className="label" htmlFor="blogPosts">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <strong>BLOG POSTS</strong>
                  </li>
                  <li>
                    <div
                      className={`toggle-switch ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="podcast"
                        id="podcast"
                        defaultChecked={isSwitchBtn}
                        onClick={switchToggleBtn}
                      />
                      <label className="label" htmlFor="podcast">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <strong>PODCAST</strong>
                  </li>
                  <li>
                    <div
                      className={`toggle-switch ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                          : Glassmorphic
                          ? "glassmorphic-style"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="subscribe"
                        id="subscribe"
                        defaultChecked={isSwitchBtn}
                        onClick={switchToggleBtn}
                      />
                      <label className="label" htmlFor="subscribe">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <strong>SUBSCRIBE TO ALL</strong>
                  </li>
                </ul>
              </div>
              <div
                className={`signup-form ${
                  Regular
                    ? "regular-style"
                    : Neumorphic
                    ? "neumorphic-style"
                    : Glassmorphic
                    ? "glassmorphic-style"
                    : ""
                }`}
              >
                <Form>
                  <Form.Group>
                    <div
                      className={`form-group ${
                        Regular
                          ? "regular-style"
                          : Neumorphic
                          ? "neumorphic-style"
                            : Glassmorphic
                             ? "glassmorphic-style" :
                            ""
                      }`}
                    >
                      <Form.Control type="text" placeholder="Your@email.com" />
                      <Button variant="primary" type="submit">
                        SIGNUP
                      </Button>
                      <a href="#" className="nothanks-btn">NO THANKS</a>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
            <img
              src={theme3TopRightDotsImg}
              alt="theme3TopRighttDotsImg"
              className="theme3TopRighttDotsImg"
            />
            <img
              src={theme3BottomLeftDotsImg}
              alt="theme3BottomLeftDotsImg"
              className="theme3BottomLeftDotsImg"
            />
            <img
              src={theme3BottomRightDotsImg}
              alt="theme3BottomRightDotsImg"
              className="theme3BottomRightDotsImg"
            />

            <img
                src={theme3NeumorphicTopRightDotsImg}
                alt="theme3NeumorphicTopRightDotsImg"
                className="theme3NeumorphicTopRightDotsImg"
            />
            <img
                src={theme3NeumorphicBottomRightDotsImg}
                alt="theme3NeumorphicBottomRightDotsImg"
                className="theme3NeumorphicBottomRightDotsImg"
            />
            <img
                src={theme3NeumorphicBottomLeftDotsImg}
                alt="theme3NeumorphicBottomLeftDotsImg"
                className="theme3NeumorphicBottomLeftDotsImg"
            />

            <img
                src={theme3NeumorphicTopRightDotsImg}
                alt="theme3NeumorphicTopRightDotsImg"
                className="theme3NeumorphicTopRightDotsImg"
            />
            <img
                src={theme3NeumorphicBottomRightDotsImg}
                alt="theme3NeumorphicBottomRightDotsImg"
                className="theme3NeumorphicBottomRightDotsImg"
            />
            <img
                src={theme3NeumorphicBottomLeftDotsImg}
                alt="theme3NeumorphicBottomLeftDotsImg"
                className="theme3NeumorphicBottomLeftDotsImg"
            />
          </div>
        </div>
      </div>
    </>
  );
}
