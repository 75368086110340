import React, { useState } from "react";
import ButtonBox from "../../../global/ButtonBox";
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import ProgressBar from 'react-bootstrap/ProgressBar';
import './LogoStep.scss';
import { faImage, faArrowLeft, faFont, faBold, faItalic, faUnderline, faAlignCenter, faLink, faLayerGroup, faTrash, faArrowRotateRight, faArrowRotateLeft, faEyeDropper, faHeart, faSquare, faSquareFull, faCircle, faCircleLeft, faCropSimple, faEye, faCopy, faLock, faCheck, faMagnifyingGlass, faCloudArrowDown, faArrowsLeftRight, faUnlockKeyhole, faArrowsUpDown, faGear, faWindowMinimize, faArrowRight, } from '@fortawesome/free-solid-svg-icons';
import imgcolor from '../../../../images/Elements.png'
import image4 from '../../../../images/image4.png'
import image2 from '../../../../images/image2.png'
import image9 from '../../../../images/image9.png'
import text_1 from '../../../../images/text-1.png'
import text_2 from '../../../../images/text-2.png'
import text_img_1 from '../../../../images/text-img-1.png'
import text_img_2 from '../../../../images/text-img-2.png'
import text_img_3 from '../../../../images/text-img-3.png'
import text_img_4 from '../../../../images/text-img-4.png'
import text_img_5 from '../../../../images/text-img-5.png'
import text_img_8 from '../../../../images/text-img-8.png'
import image_3 from '../../../../images/image-3.png'
import image_6 from '../../../../images/image-6.png'
import image_5 from '../../../../images/image-5.png'
import vector from '../../../../images/Vector.png'
import icon_shap from '../../../../images/icon-shap.png'
import Vector_doble from '../../../../images/Vector-doble.png'
import vector_align from '../../../../images/Vector-align.png'
import frame from '../../../../images/frame.png'
import line_vector from '../../../../images/line-vector.png'
import star_vector from '../../../../images/star-vector.png'
import line_height from '../../../../images/line-height.png'
import photo_1 from '../../../../images/photo-1.png'
import photo_2 from '../../../../images/photo-2.png'
import photo_3 from '../../../../images/photo-3.png'
import photo_4 from '../../../../images/photo-4.png'
import photo_5 from '../../../../images/photo-5.png'
import photo_8 from '../../../../images/photo-8.png'
import star_1 from '../../../../images/star-1.png'
import star_2 from '../../../../images/star-2.png'
import star_4 from '../../../../images/star-4.png'
import star_3 from '../../../../images/star-3.png'
import second_3 from "../../../../images/second-3.png";
import { NavItem } from 'react-bootstrap';
import strat_vector from '../../../../images/strat-vector.png'
import DropdownBox from '../../../global/DropdownBox';
export default function LogoStepEditor() {

  const [showDiv1, setShowDiv1] = useState(true);
  const [showDiv2, setShowDiv2] = useState(false);

  const handleDiv1Click = () => {
    setShowDiv1(true);
    setShowDiv2(false);
  };

  const handleDiv2Click = () => {
    setShowDiv1(false);
    setShowDiv2(true);
  };



  const { t, i18n } = useTranslation();
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });
  const options = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "9",
      label: "9",
    },
    {
      value: "8",
      label: "8",
    },
    {
      value: "7",
      label: "7",
    },
    {
      value: "6",
      label: "6",
    },
    {
      value: "5",
      label: "5",
    },
    {
      value: "4",
      label: "4",
    },
    {
      value: "3",
      label: "3",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "1",
      label: "1",
    },


  ];
  const boldoptions = [
    {
      value: "Extra Light",
      label: "Extra Light",
    },
    {
      value: " Light",
      label: " Light",
    },
    {
      value: "Regular",
      label: "Regular",
    },
    {
      value: "Medium",
      label: "Medium",
    },
    {
      value: "Semi Bold",
      label: "Semi Bold",
    },
    {
      value: "Bold",
      label: "Bold",
    },
    {
      value: "Extra bold",
      label: "Extra bold",
    },
    {
      value: "Black",
      label: "Black",
    },



  ];

  const transformoptions = [
    {
      value: "none",
      label: "none",
    },
    {
      value: "UPPER CASE",
      label: "UPPER CASE",
    },
    {
      value: "lower case",
      label: "lower case",
    },
    {
      value: "Title Case",
      label: "Title Case",
    },




  ];


  return (
    <div className="three-tab-stle arrow-select editor-width-set">


      <div className="logo-editor-width gridify tiny">
        <div className="editor">

          <div className="d-flex align-item-center Top-bar">
            <div>
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <div className="border-css">

            </div>
            <div className="  first-field we-field">
              <div className=" bg-box position-relative">





                <Select isSearchable={false}
                  options={options}
                  className="country mb-4  mx-auto text-center bgArrow "
                  placeholder="50"
                  theme={theme}
                  styles={{
                    option: (base) => ({
                      ...base,
                      borderRadius: "35px",
                      width: "60px",
                      zIndex: "5",
                      height: "50px",
                      lineHeight: "32px",
                      margin: "10px",
                      boxShadow: "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                      cursor: 'pointer',
                    }),
                  }}
                />
                {/* <DropdownBox name="Country" boxid="country" boxwidth="w-220 mx-auto"/> */}
              </div>


            </div>
            <div className="icons-css">
              <FontAwesomeIcon icon={faFont} />
            </div>
            <img src={frame} alt='frame' ></img>
            <div className="icons-css">
              <FontAwesomeIcon icon={faBold} />
            </div>
            <div className="icons-css">
              <FontAwesomeIcon icon={faItalic} />
            </div>
            <div className="icons-css">
              <FontAwesomeIcon icon={faUnderline} />
            </div>
            <div className="icons-css font-bold">
              Ab
            </div>
            <div className="border-css">

            </div>
            <div className="icons-css">
              <FontAwesomeIcon icon={faAlignCenter} />
            </div>
            <img src={line_height} alt='height'>

            </img>
            <div className="border-css">

            </div>
            <div className="icons-css">
              <FontAwesomeIcon icon={faLink} />
            </div>
            <div>
              {t('choose_your_logo_component.animate')}
            </div>
            <div className="border-css">

            </div>

            <div>
              {t('choose_your_logo_component.effects')}
            </div>
            <div className="mrgin-left">
              <div className="d-flex  space-center">
                <div>
                  {t('choose_your_logo_component.letter_spacing')}
                </div>
                <div className="">
                  3
                </div>
              </div>
              <ProgressBar now={50} />
            </div>

            <img className="" src={Vector_doble} alt='vetor'>
            </img>


            <div className="icons-css">
              <FontAwesomeIcon icon={faLayerGroup} />
            </div>

            <img className="" src={vector_align} alt='align'>
            </img>



            <img src={vector} alt='vector-logo'>
            </img>


            <img className="" src={icon_shap} alt='sharp-logo' >
            </img>




            <div className="icons-css">
              <FontAwesomeIcon icon={faTrash} />
            </div>
            <div className="dropbtn space-set dropdown-toggle " type="button" data-bs-toggle="dropdown" id="dropdownMenuClickableInside" data-bs-auto-close="outside" aria-expanded="false">
              <div className=" text-center time-btn editor-btn-css">
                <ButtonBox

                  boxwidth="w-150 mt-0 mb-20 mx-auto"
                  clicked="Downloading"
                  name="Download"
                />
              </div>
            </div>
            <ul aria-labelledby="dropdownMenuClickableInside" className="dropdown-menu-top dropdown-menu p-3 laer-dropdown brand-stle-dropdown">
              <h2>
                Export files {t('choose_your_logo_component.export_files')}
              </h2>
              <p>
                File type {t('choose_your_logo_component.file_type')}
              </p>
              <div id="exTab2" className="container">
                <ul className="nav nav-pills text-pills d-flex">
                  <NavItem as="li" className="active">
                    <a href="#1aa" data-toggle="tab">JPEG</a>
                  </NavItem>
                  <NavItem as="li" className="tab-secnd" ><a href="#2aa" data-toggle="tab">PNG</a>
                  </NavItem>
                  <NavItem as="li" className="tab-secnd" ><a href="#3aa" data-toggle="tab">webp</a>
                  </NavItem>

                </ul>
                <div className="line"></div>
                <div className="tab-content  clearfix">
                  <div className="tab-pane active " id="1aa">
                    <div className="mrgin-left">
                      <div className="d-flex  space-center mt-4">
                        <div>
                          {t('choose_your_logo_component.file_quality')}
                        </div>
                        <div className="">
                          80
                        </div>
                      </div>
                      <ProgressBar now={50} />
                    </div>
                    <button className="btn-text">
                      <FontAwesomeIcon className="font-icon" icon={faCloudArrowDown} ></FontAwesomeIcon>
                      {t('choose_your_logo_component.download')}  </button>



                  </div>
                  <div className="tab-pane" id="2aa">
                    <div className="mrgin-left">
                      <div className="d-flex  space-center mt-4 mb-3 pb-2">
                        <div>
                          Transparency:  {t('choose_your_logo_component.transparency')}
                        </div>
                        <div className="radioButton d-flex"><div className="me-3"><input type="radio" className="btn-check" id="comments1" name="comments" autoComplete="off"></input><label className="btn br-30 bg" htmlFor="comments1"> {t('choose_your_logo_component.on')}</label></div><div><input type="radio" className="btn-check" id="comments2" name="comments"></input><label className="btn br-30 bg" htmlFor="comments2"> {t('choose_your_logo_component.off')}</label></div></div>


                      </div>
                      <ProgressBar now={50} />
                    </div>
                    <button className="btn-text">
                      <FontAwesomeIcon className="font-icon" icon={faCloudArrowDown} ></FontAwesomeIcon>
                      {t('choose_your_logo_component.download')}</button>
                  </div>
                  <div className="tab-pane" id="3aa">
                    <div className="mrgin-left">
                      <div className="d-flex  space-center mt-4">
                        <div>
                          {t('choose_your_logo_component.file_quality')}
                        </div>
                        <div className="">
                          80
                        </div>
                      </div>
                      <ProgressBar now={50} />
                    </div>
                    <button className="btn-text">
                      <FontAwesomeIcon className="font-icon" icon={faCloudArrowDown} ></FontAwesomeIcon>
                      {t('choose_your_logo_component.download')}</button>
                  </div>
                </div>

              </div>

            </ul>


          </div>
        </div>
        <div className="Top-bar-inner">

          <div className="d-flex align-item-center">
            <div className="icons-css">
              <FontAwesomeIcon icon={faArrowRotateLeft} />
            </div>
            <div className="icons-css">
              <FontAwesomeIcon icon={faArrowRotateRight} />
            </div>

            <div className="  first-field  font-choose"  >
              <div className="resize dropdown-toggle" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">

                <div className="bgArrow inner-field  bg-box position-relative width-set-inner font-chhose-btn">


                  {t('choose_your_logo_component.inter')}



                </div>
              </div>
              <ul className="dropdown-menu p-3 laer-dropdown stle-drop " aria-labelledby="dropdownMenuClickableInside">
                <h2>
                  {t('choose_your_logo_component.choose_font')}
                </h2>
                <div className='search-area w-100 mt-2 mb-4 ms-auto'>
                  <form autoComplete="off">
                    <div className='search-field d-flex justify-content-between align-items-center pe-4 ms-auto'>
                      <input placeholder="find a font" className="border-0" type="text" />
                      <div>
                        <FontAwesomeIcon className="" icon={faMagnifyingGlass} />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="line">

                </div>
                <div className="font-card">
                  {t('choose_your_logo_component.inter')}
                </div>
                <div className="font-card bebas mt-2" style={{ fontFamily: "'Bebas Neue', cursive" }}>
                  {t('choose_your_logo_component. bebas')}
                </div>
                <div className="font-card mt-2" style={{ fontFamily: "'Italiana', serif" }}>
                  {t('choose_your_logo_component.italian_typewriter')}
                </div>
                <div className="font-card mt-2" style={{ fontFamily: "'Julee', cursive" }}>
                  {t('choose_your_logo_component.julee')}
                </div>
                <div className="font-card font-bold mt-2" style={{ fontFamily: "'Dancing Script', cursive" }}>
                  {t('choose_your_logo_component.script_mt_bold')}
                </div>
                <div className="font-card mt-2" style={{ fontFamily: "'Inder', sans-serif" }}>
                  {t('choose_your_logo_component.inder')}

                </div>
              </ul>

            </div>
            <div className=" inner-field  bg-box position-relative width-set-inner">





              <Select isSearchable={false}
                options={boldoptions}
                className="country mb-4  mx-auto text-center bgArrow"
                placeholder="bold"
                theme={theme}
                styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: "35px",
                    width: "90%",
                    zIndex: "5",
                    height: "50px",
                    lineHeight: "32px",
                    margin: "10px",
                    boxShadow: "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                    cursor: 'pointer',
                  }),
                }}
               
              />
               {/* <DropdownBox name="Country" boxid="country" boxwidth="w-220 mx-auto"/> */}
            </div>
            <div className="inner-btns ">
              <FontAwesomeIcon icon={faEyeDropper} className="gradient" />
              {t('choose_your_logo_component.gradient')}
            </div>
            <div className="color-stle">
              <div className="resize dropdown-toggle" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">

                <div className="inner-btns">
                  <img src={imgcolor} className="color-padding-set" alt='imgcolor'></img>
                  {t('choose_your_logo_component.color')}
                </div>
              </div>
              <ul className="dropdown-menu p-3 laer-dropdown stle-drop " aria-labelledby="dropdownMenuClickableInside">
                <h4 >
                  {t('choose_your_logo_component.color')}
                </h4>
                <p>custom color  {t('choose_your_logo_component.custom_color')} </p>
                <div className='search-area w-100 mt-2 mb-4 ms-auto'>
                  <form autoComplete="off">
                    <div className='search-field d-flex justify-content-between align-items-center pe-4 ms-auto'>
                      <input placeholder="#7F838F" className="border-0" type="text" />
                      <div>
                        <img src={imgcolor} className="color-padding-set" alt='color'></img>
                      </div>
                    </div>
                  </form>
                </div>
                <h4>
                  {t('choose_your_logo_component.seven_figure_bosses')}
                </h4>

                <div className="d-flex color-plate mt-3">
                  <div className="color-plate-pink large">

                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#FF8991' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFA0A7' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#DC666E' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFB5BA' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#BC434B' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFCFD3' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#93393F' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFE7E8' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#682F33' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFF3F4' }}>

                    </div>
                  </div>

                </div>
                <div className="d-flex color-plate mt-4">
                  <div className="color-plate-gold large">

                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#FFAA70' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFC095' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#FF964F' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFD8BE' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#FF8B3C' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFE1CC' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#FF7C22' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFE8D9' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#FF6F0D' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFEEE2' }}>

                    </div>
                  </div>

                </div>
                <div className="d-flex color-plate mt-4 " >
                  <div className="color-plate-black large">

                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#38383A' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#38383A' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#29292C' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#5C5C5C' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#222224' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#69696C' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#1E1E1E' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#97979F' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#050505' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#CECED6' }}>

                    </div>
                  </div>

                </div>
                <div className="d-flex color-plate mt-4">
                  <div className="color-plate-orange large">

                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#FFAF60' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFAF60' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#FFA043' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFBE7E' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#FF9731' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFC68E' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#FF8A16' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFE3C8' }}>

                    </div>
                  </div>
                  <div>
                    <div className="color-plate-lightpink small" style={{ backgroundColor: '#FF7F00' }}>

                    </div>
                    <div className="color-plate-brownpink small mt-2" style={{ backgroundColor: '#FFF2E4' }}>

                    </div>
                  </div>

                </div>

              </ul>
            </div>

            <div className=" inner-field  bg-box position-relative width-set-transform">





              <Select isSearchable={false}
                options={transformoptions}
                className="country mb-4  mx-auto text-center bgArrow"
                placeholder="Transform"
                theme={theme}
                styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: "35px",
                    width: "90%",
                    zIndex: "5",
                    height: "50px",
                    lineHeight: "32px",
                    margin: "10px",
                    boxShadow: "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                    cursor: 'pointer',
                  }),
                }}
              />
               {/* <DropdownBox name="Country" boxid="country" boxwidth="w-500 mx-auto"/> */}
            </div>
            <div className="edit-img">
              {t('choose_your_logo_component.edit_image')}
            </div>
            <div className="icons-css">
              <FontAwesomeIcon icon={faCropSimple} />
            </div>


            <img src={line_vector} alt='line-vector'></img>
            <img src={frame} alt='frame' ></img>

            <div className="icons-css">
              <img src={strat_vector} alt='stratVector'></img>
            </div>

            <div>

              <img src={star_vector} alt='star-ventor'></img>
            </div>
            <div>
              {t('choose_your_logo_component.animate')}
            </div>
            <div className="icons-css">
              <FontAwesomeIcon icon={faHeart} />
            </div>

          </div>
        </div>
        <div className="d-flex">
          <div className="edition-sidebar">
            <ul className="horizontal">
              <div className="horiztal-padding">
                <div className="dropbtn dropdown-toggle" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Gallery</div>
                <ul className="dropdown-menu gallery-dropdown p-3" aria-labelledby="dropdownMenuClickableInside">
                  <h4>
                    {t('choose_your_logo_component.gallery')}
                  </h4>
                  <div className="row">
                    <div className="col-lg-6">
                      <img src={image4} alt='image4'>
                      </img>
                    </div>
                    <div className="col-lg-6">
                      <img src={image2} alt='image5' >
                      </img>
                    </div>

                    <div className="col-lg-6 mt-3" >
                      <img src={image_6} alt='images-gallery' >
                      </img>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <img src={image9} alt='image9'>
                      </img>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <img src={image_3} alt='image3'>
                      </img>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <img src={image_5} alt='image5' >
                      </img>
                    </div>
                  </div>
                </ul>
                <div className="dropbtn dropdown-toggle" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Object</div>
                <ul className="dropdown-menu p-3 obj-dropdown" aria-labelledby="dropdownMenuClickableInside">
                  <h4>
                    {t('choose_your_logo_component.object')}
                  </h4>

                  <div className="d-flex space-between mb-4">
                    <h5>
                      {t('choose_your_logo_component.squares')}
                    </h5>
                    <div className="btn-drops">
                      {t('choose_your_logo_component.show_more')}
                    </div>
                  </div>
                  <div className="d-flex  object-scroll">
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon" icon={faSquareFull} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon" icon={faSquare} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon third-icon" icon={faSquare} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon" icon={faSquareFull} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon" icon={faSquare} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon third-icon" icon={faSquare} />
                    </div>
                  </div>
                  <div className="line">

                  </div>
                  <div className="d-flex space-between mb-4">
                    <h5>
                      {t('choose_your_logo_component.circles')}

                    </h5>
                    <div className="btn-drops">
                      {t('choose_your_logo_component.show_more')}
                    </div>
                  </div>
                  <div className="d-flex object-scroll">
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon" icon={faCircle} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon" icon={faCircleLeft} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon third-icon" icon={faSquare} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon" icon={faSquareFull} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon" icon={faSquare} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon third-icon" icon={faSquare} />
                    </div>
                  </div>
                  <div className="line">

                  </div>
                  <div className="d-flex space-between mb-4">
                    <h5>
                      {t('choose_your_logo_component.polygon')}
                    </h5>
                    <div className="btn-drops">
                      {t('choose_your_logo_component.show_more')}
                    </div>
                  </div>
                  <div className="d-flex object-scroll">
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon" icon={faSquareFull} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon" icon={faSquare} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon third-icon" icon={faSquare} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon" icon={faSquareFull} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon" icon={faSquare} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon third-icon" icon={faSquare} />
                    </div>
                  </div>
                  <div className="line">

                  </div>
                  <div className="d-flex space-between mb-4">
                    <h5>
                      {t('choose_your_logo_component.line')}

                    </h5>
                    <div className="btn-drops">
                      {t('choose_your_logo_component.show_more')}
                    </div>
                  </div>
                  <div className="d-flex object-scroll">
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon line-strat" icon={faWindowMinimize} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="icon" icon={faArrowRight} />
                    </div>
                    <div className="sqr-box">
                      <FontAwesomeIcon className="" />
                      <div className="icon doted-line"> -------</div>
                    </div>

                  </div>
                  <div className="line">

                  </div>
                  <div className="d-flex space-between mb-4">
                    <h5>
                      {t('choose_your_logo_component.star')}
                    </h5>
                    <div className="btn-drops">
                      {t('choose_your_logo_component.show_more')}
                    </div>
                  </div>
                  <div className="d-flex object-scroll">
                    <div className="sqr-box">
                      <img src={star_1} alt='star-1' ></img>
                    </div>
                    <div className="sqr-box">
                      <img src={star_2} alt='star-2' ></img>
                    </div>
                    <div className="sqr-box">
                      <img src={star_3} alt='star-3' ></img>
                    </div>
                    <div className="sqr-box">
                      <img src={star_4} alt='star-4'></img>

                    </div>

                  </div>
                  <div className="line">

                  </div>
                </ul>
                <div className="dropbtn dropdown-toggle" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Text</div>
                <ul className="dropdown-menu p-3 text-dropdown" aria-labelledby="dropdownMenuClickableInside">
                  <div>

                    <h4>
                      {t('choose_your_logo_component.text')}
                    </h4>




                    {showDiv1 && (

                      <div>
                        <button onClick={handleDiv2Click} className="btn-text">
                          {t('choose_your_logo_component.add_text')}
                        </button>

                        <div className="line mt-2">

                        </div>
                        <div className="content-text">
                          <div className="row">
                            <div className="col-lg-6 mt-1">
                              <div className="text-box">
                                <img src={text_img_8} alt='eight-imge'>
                                </img>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="text-box">
                                <img src={text_2} alt='eight-text'>
                                </img>
                              </div>

                            </div>
                            <div className="col-lg-6 mt-1">
                              <div className="text-box">
                                <img src={text_img_5} alt='text-5'>
                                </img>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="text-box">
                                <img src={text_1} alt='text-1' >
                                </img>
                              </div>
                            </div>



                            <div className="col-lg-6 mt-1">
                              <div className="text-box">
                                <img src={text_img_4} alt='text-i-4'  >
                                </img>
                              </div>
                            </div>
                            <div className="col-lg-6 mt-1">
                              <div className="text-box">
                                <img src={text_img_3} alt='text-i-3' >
                                </img>
                              </div>
                            </div>
                            <div className="col-lg-6 mt-1">
                              <div className="text-box">
                                <img src={text_img_2} alt='text-i-2' >
                                </img>
                              </div>
                            </div>
                            <div className="col-lg-6 mt-1">
                              <div className="text-box">
                                <img src={text_img_1} alt='text-e-1' >
                                </img>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    )}

                    {showDiv2 && (
                      <div>
                        <div id="exTab1" className="container">
                          <ul className="nav nav-pills text-pills d-flex">
                            <NavItem as="li" className="active">
                              <a href="#1a" data-toggle="tab"> {t('choose_your_logo_component.styles')} </a>
                            </NavItem>
                            <NavItem as="li" className="tab-secnd" ><a href="#2a" data-toggle="tab"> {t('choose_your_logo_component.my_fonts')} </a>
                            </NavItem>

                          </ul>
                          <div className="line"></div>
                          <div className="tab-content  clearfix">
                            <div className="tab-pane active " id="1a">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="text-cardss" >
                                  </div>
                                </div>
                                <div className="col-lg-6" >
                                  <div className="text-cardss" >
                                  </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                  <div className="text-cardss" >
                                  </div>
                                </div>
                                <div className="col-lg-6 mt-3" >
                                  <div className="text-cardss" >
                                  </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                  <div className="text-cardss" >
                                  </div>
                                </div>
                                <div className="col-lg-6 mt-3" >
                                  <div className="text-cardss" >
                                  </div>
                                </div>
                              </div>



                            </div>
                            <div className="tab-pane" id="2a">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="text-cardss" >
                                  </div>
                                </div>
                                <div className="col-lg-6" >
                                  <div className="text-cardss" >
                                  </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                  <div className="text-cardss" >
                                  </div>
                                </div>
                                <div className="col-lg-6 mt-3" >
                                  <div className="text-cardss" >
                                  </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                  <div className="text-cardss" >
                                  </div>
                                </div>
                                <div className="col-lg-6 mt-3" >
                                  <div className="text-cardss" >
                                  </div>
                                </div>
                              </div>				</div>

                          </div>
                          <div className="bottom-text-fix">
                            <h6 className="bottom-text-stle">
                              {t('choose_your_logo_component.uploaded_fonts_will_appear_here')}
                            </h6>
                            <p className="bottom-p">
                              {t('choose_your_logo_component.files_should_be_in_ttf_or_otf_format')}
                            </p>

                            <button onClick={handleDiv1Click} className="btn-text">
                              {t('choose_your_logo_component.upload_fonts')}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                </ul>
                <div className="dropbtn dropdown-toggle" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Upload</div>
                <ul className="dropdown-menu p-3 laer-dropdown svg-dropdown" aria-labelledby="dropdownMenuClickableInside">
                  <h4>
                    {t('choose_your_logo_component.upload')}
                  </h4>
                  <button className="btn-text">
                    {t('choose_your_logo_component.upload_image')}
                  </button>
                  <div className="line">

                  </div>
                  <div id="exTab1" className="container">
                    <ul className="nav nav-pills text-pills d-flex">
                      <NavItem as="li" className="active">
                        <a href="#1apld" data-toggle="tab"> {t('choose_your_logo_component.upload_image')} </a>
                      </NavItem>
                      <NavItem as="li" className="tab-secnd" ><a href="#2apld" data-toggle="tab"> SVG</a>
                      </NavItem>

                    </ul>
                    <div className="line"></div>
                    <div className="tab-content  clearfix">
                      <div className="tab-pane active " id="1apld">

                        <div className="font-size-img mb-3 mt-4">
                          <FontAwesomeIcon className="font-size-img" icon={faImage} />
                        </div>
                        <div className="text-center mb-4">
                          {t('choose_your_logo_component.no_uploaded_image')}
                        </div>


                      </div>
                      <div className="tab-pane" id="2apld">
                        <div className="font-size-img mb-3 mt-4">
                          <FontAwesomeIcon className="font-size-img" icon={faImage} />
                        </div>
                        <div className="text-center mb-4">
                          {t('choose_your_logo_component.no_uploaded_svgs')}
                        </div>
                      </div>

                    </div>

                  </div>


                </ul>
                <div className="dropbtn dropdown-toggle" type="button" data-bs-toggle="dropdown" id="dropdownMenuClickableInside" data-bs-auto-close="outside" aria-expanded="false">Photos</div>
                <ul className="dropdown-menu p-3 photo-drodown  laer-dropdown" aria-labelledby="dropdownMenuClickableInside" >
                  <li >
                    <h4>
                      {t('choose_your_logo_component.photos')}
                    </h4>
                    <div className='search-area w-100 mt-2 mb-4 ms-auto'>
                      <form autoComplete="off">
                        <div className='search-field d-flex justify-content-between align-items-center pe-4 ms-auto'>
                          <input placeholder={t('search_placeholder')} className="border-0" type="text" />
                          <div>
                            <FontAwesomeIcon className="" icon={faMagnifyingGlass} />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="d-flex img-photo">
                      <div className=" ">
                        <img className="ml-2 mr-2" src={photo_1} alt='photo1'>
                        </img>
                        <img className="ml-2 mr-2" src={photo_3} alt='photo3' >

                        </img>
                        <img className="ml-2 mr-2" src={photo_5} alt='photo-5' >
                        </img>




                      </div>


                      <div className="">
                        <img className="ml-2 mr-2" src={photo_2} alt='photo2' >
                        </img>
                        <img className="ml-2 mr-2" src={photo_4} alt='photo4' >
                        </img>

                        <img className="ml-2 mr-2" src={photo_8} alt='photo8' >
                        </img>
                      </div>



                    </div>
                  </li>

                </ul>
                <div className="dropbtn dropdown-toggle" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Layers</div>
                <ul className="dropdown-menu p-3 laer-dropdown" aria-labelledby="dropdownMenuClickableInside">
                  <h4>
                    {t('choose_your_logo_component.layers')}
                  </h4>
                  <div className="laer-cards mt-4">
                    <div className="d-flex space-between" >
                      <div className="d-flex">
                        <FontAwesomeIcon className="" icon={faEye} />
                        <div className="laer-card-heading">
                          {t('choose_your_logo_component.company_name')}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div>
                          <FontAwesomeIcon className="icons" icon={faCopy} />
                        </div>
                        <div className="padding-left-icon">
                          <FontAwesomeIcon className="icons" icon={faLock} />
                        </div>
                        <div className="padding-left-icon">
                          <FontAwesomeIcon className="icons" icon={faTrash} />
                        </div>


                      </div>


                    </div>
                  </div>
                  <div className="laer-cards mt-2">
                    <div className="d-flex space-between" >
                      <div className="d-flex">
                        <FontAwesomeIcon className="" icon={faEye} />
                        <div className="laer-card-heading">
                          {t('choose_your_logo_component.nine_t0_five_escape')}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div>
                          <FontAwesomeIcon className="icons" icon={faCopy} />
                        </div>
                        <div className="padding-left-icon">
                          <FontAwesomeIcon className="icons" icon={faLock} />
                        </div>
                        <div className="padding-left-icon">
                          <FontAwesomeIcon className="icons" icon={faTrash} />
                        </div>


                      </div>


                    </div>
                  </div>
                  <div className="laer-cards mt-2">
                    <div className="d-flex space-between" >
                      <div className="d-flex">
                        <FontAwesomeIcon className="" icon={faEye} />
                        <div className="laer-card-heading">
                          {t('choose_your_logo_component.layer_one')}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div>
                          <FontAwesomeIcon className="icons" icon={faCopy} />
                        </div>
                        <div className="padding-left-icon">
                          <FontAwesomeIcon className="icons" icon={faLock} />
                        </div>
                        <div className="padding-left-icon">
                          <FontAwesomeIcon className="icons" icon={faTrash} />
                        </div>


                      </div>


                    </div>
                  </div>
                  <div className="laer-cards mt-2">
                    <div className="d-flex space-between" >
                      <div className="d-flex">
                        <FontAwesomeIcon className="" icon={faEye} />
                        <div className="laer-card-heading">
                          {t('choose_your_logo_component.rectangle')}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div>
                          <FontAwesomeIcon className="icons" icon={faCopy} />
                        </div>
                        <div className="padding-left-icon">
                          <FontAwesomeIcon className="icons" icon={faLock} />
                        </div>
                        <div className="padding-left-icon">
                          <FontAwesomeIcon className="icons" icon={faTrash} />
                        </div>


                      </div>


                    </div>
                  </div>
                  <div className="laer-cards mt-2">
                    <div className="d-flex space-between" >
                      <div className="d-flex">
                        <FontAwesomeIcon className="" icon={faEye} />
                        <div className="laer-card-heading">
                          Text
                        </div>
                      </div>
                      <div className="d-flex">
                        <div>
                          <FontAwesomeIcon className="icons" icon={faCopy} />
                        </div>
                        <div className="padding-left-icon">
                          <FontAwesomeIcon className="icons" icon={faLock} />
                        </div>
                        <div className="padding-left-icon">
                          <FontAwesomeIcon className="icons" icon={faTrash} />
                        </div>


                      </div>


                    </div>
                  </div>
                  <div className="laer-cards mt-2">
                    <div className="d-flex space-between" >
                      <div className="d-flex">
                        <FontAwesomeIcon className="" icon={faEye} />
                        <div className="laer-card-heading">
                          {t('choose_your_logo_component.background')}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div>
                          <FontAwesomeIcon className="icons" icon={faCopy} />
                        </div>
                        <div className="padding-left-icon">
                          <FontAwesomeIcon className="icons" icon={faLock} />
                        </div>
                        <div className="padding-left-icon">
                          <FontAwesomeIcon className="icons" icon={faTrash} />
                        </div>


                      </div>


                    </div>
                  </div>
                  <div>
                    <button type="submit" className="btn-laer d-flex">
                      <div>
                        <FontAwesomeIcon className="icons" icon={faCheck} />
                      </div>
                      {t('choose_your_logo_component.apply_changes')}
                    </button>
                  </div>
                </ul>
                <div className="dropbtn space-set dropdown-toggle " id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                  {t('choose_your_logo_component.brand')}
                </div>
                <ul className="dropdown-menu p-3 laer-dropdown stle-drop brand-stle-dropdown" aria-labelledby="dropdownMenuClickableInside">
                  <div className="d-flex space-between-brand">
                    <h4>
                      {t('choose_your_logo_component.brand')}

                    </h4>
                    <div className="brand-color-plate gear-setting " typeof="button" data-bs-toggle="modal" data-bs-target="#exampleModal" >
                      <FontAwesomeIcon icon={faGear} ></FontAwesomeIcon>
                    </div>

                  </div>
                  <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title" id="exampleModalLabel">{t('choose_your_logo_component.type_scale')}</h4>
                          {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="modal-body">
                          <p>{t('choose_your_logo_component.type_scale_text')}</p>

                          <div className=" position-relative border-rounded shadow-fix">
                            <label className="mb-2">{t('choose_your_logo_component.base_size')}</label>

                            <Select isSearchable={false}
                              options={options}
                              className="country mb-4 w-100 mx-auto text-center bgArrow"
                              placeholder="19"
                              theme={theme}
                              styles={{
                                option: (base) => ({
                                  ...base,
                                  borderRadius: "30px",
                                  width: "94%",
                                  maxheight: "50px",
                                  lineHeight: "32px",
                                  margin: "10px",
                                  cursor: 'pointer',

                                  boxShadow:
                                    "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                }),
                              }}
                            />
                             {/* <DropdownBox name="Country" boxid="country" boxwidth="w-500 mx-auto"/> */}
                          </div>
                          <div className=" position-relative shadow-fix">
                            <label className="mb-2">{t('choose_your_logo_component.scale_ratio')}</label>

                            <Select isSearchable={false}
                              options={options}
                              className="country mb-4 w-100 mx-auto text-center bgArrow"
                              placeholder={t('choose_your_logo_component.major_third')}
                              theme={theme}
                              styles={{
                                option: (base) => ({
                                  ...base,
                                  borderRadius: "30px",
                                  width: "94%",
                                  maxheight: "50px",
                                  lineHeight: "32px",
                                  margin: "10px",
                                  cursor: 'pointer',

                                  boxShadow:
                                    "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                }),
                              }}
                            />
                          </div>
                          <div className=" position-relative shadow-fix">
                            <label className="mb-2">{t('choose_your_logo_component.preview_text')}</label>

                            <Select isSearchable={false}
                              options={options}
                              className="country mb-4 w-100 mx-auto text-center bgArrow"
                              placeholder={t('choose_your_logo_component.lorem_ipsum')}
                              theme={theme}
                              styles={{
                                option: (base) => ({
                                  ...base,
                                  borderRadius: "30px",
                                  width: "94%",
                                  maxheight: "50px",
                                  lineHeight: "32px",
                                  margin: "10px",
                                  cursor: 'pointer',

                                  boxShadow:
                                    "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                }),
                              }}
                            />
                             {/* <DropdownBox name="Country" boxid="country" boxwidth="w-500 mx-auto"/> */}
                          </div>
                          <p>
                            h1 3.05em (48.828px)
                          </p>
                          <h2>
                            {t('choose_your_logo_component.Lorem_ipsum_small')}
                          </h2>
                          <p>
                            h2 2.44em (39.063px)
                          </p>
                          <h4>
                            {t('choose_your_logo_component.Lorem_ipsum_dolor')}
                          </h4>
                          <p>
                            h3 1.95em (31.25px)
                          </p>
                          <h6>
                            {t('choose_your_logo_component.lorem_ipsum')}
                          </h6>
                          <br />
                        </div>

                      </div>
                    </div>
                  </div>

                  <p>
                    {t('choose_your_logo_component.applied_brand')}
                  </p>

                  <div className=" position-relative shadow-fix">
                    <Select isSearchable={false}
                      options={options}
                      className="country mb-4 w-100 mx-auto text-center bgArrow"
                      placeholder={t('choose_your_logo_component.seven_figure_bosses')}
                      theme={theme}
                      styles={{
                        option: (base) => ({
                          ...base,
                          borderRadius: "30px",
                          width: "94%",
                          maxheight: "50px",
                          lineHeight: "32px",
                          margin: "10px",
                          cursor: 'pointer',

                          boxShadow:
                            "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                        }),
                      }}
                    />
                     {/* <DropdownBox name="Country" boxid="country" boxwidth="w-500 mx-auto"/> */}
                  </div>
                  <p> {t('choose_your_logo_component.change_which_brand_gets_applied_to_this_design')} </p>
                  <div>
                    <img src={second_3} alt='second-3' className="mt-4 mb-5" ></img>
                  </div>
                  <div className="d-flex space-between-brand">
                    <div >
                      <h5>
                        Color
                      </h5>
                      <p>
                        {t('choose_your_logo_component.my_first_palette')}
                      </p>
                    </div>
                    <div className="brand-color-plate">
                      <img src={imgcolor} alt='imgcolor' className="color-padding-set" ></img>

                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="color-plate-pink">

                    </div>
                    <div className="color-plate-lightpink">

                    </div>
                    <div className="color-plate-brownpink">

                    </div>
                    <div className="color-plate-babpink">

                    </div>
                    <div className="color-plate-orange">

                    </div>
                    <div className="color-plate-gold">

                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <div className="color-plate-black">

                    </div>
                    <div className="color-plate-black">

                    </div>


                  </div>
                  <h5 className="mt-4">
                    {t('choose_your_logo_component.fonts')}
                  </h5>

                  <div className="font-cards">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <div className="accordion-header" id="headingOne">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div>
                              <div className="heading">
                                {t('choose_your_logo_component.heading')}
                              </div>

                              <div className="heading-text">
                                {t('choose_your_logo_component.acme')}
                              </div>
                            </div>

                          </button>
                        </div>
                        <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <h4 className="pt-3">
                              {t('choose_your_logo_component.select_a_heading_font')}
                            </h4>
                            <div id="exTab3" className="">
                              <ul className="nav nav-pills text-pills d-flex">
                                <NavItem as="li" className="active">
                                  <a href="#1atext" data-toggle="tab">  {t('choose_your_logo_component.google_fonts')} </a>
                                </NavItem>
                                <NavItem as="li" className="tab-secnd" ><a href="#2atext" data-toggle="tab">{t('choose_your_logo_component.custom_fonts')}</a>
                                </NavItem>

                              </ul>
                              <div className="line"></div>
                              <div className="tab-content  clearfix">
                                <div className="tab-pane active " id="1atext">
                                  <div className="d-flex" >
                                    <div className=" position-relative shadow-fix select-right-m">
                                      <Select isSearchable={false}
                                        options={options}
                                        className="country mb-4 w-100 mx-auto text-center bgArrow"
                                        placeholder="Open Sans"
                                        theme={theme}
                                        styles={{
                                          option: (base) => ({
                                            ...base,
                                            borderRadius: "30px",
                                            width: "40%",
                                            maxheight: "50px",
                                            lineHeight: "32px",
                                            margin: "10px",
                                            cursor: 'pointer',

                                            boxShadow:
                                              "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                          }),
                                        }}
                                      />
                                       {/* <DropdownBox name="Country" boxid="country" boxwidth="w-500 mx-auto"/> */}
                                    </div>

                                    <div className=" position-relative shadow-fix">
                                      <Select isSearchable={false}
                                        options={options}
                                        className="country mb-4 w-100 mx-auto text-center bgArrow"
                                        placeholder="Regular"
                                        theme={theme}
                                        styles={{
                                          option: (base) => ({
                                            ...base,
                                            borderRadius: "30px",
                                            width: "40%",
                                            maxheight: "50px",
                                            lineHeight: "32px",
                                            margin: "10px",
                                            cursor: 'pointer',

                                            boxShadow:
                                              "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                          }),
                                        }}
                                      />
                                       {/* <DropdownBox name="Country" boxid="country" boxwidth="w-500 mx-auto"/> */}
                                    </div>
                                  </div>
                                  <p>  {t('choose_your_logo_component.select_a_font_from_google_fonts')} </p>


                                </div>
                                <div className="tab-pane" id="2atext">
                                  <div className="loader-img d-flex">
                                    <div>
                                      {t('choose_your_logo_component.upload_font')}
                                    </div>
                                    <div>
                                      <FontAwesomeIcon icon={faCloudArrowDown} ></FontAwesomeIcon>
                                    </div>
                                  </div>
                                  <p className="pt-2"> {t('choose_your_logo_component.accepted_font_file')} </p>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>

                  <div className="font-cards">
                    <div className="accordion" id="accordionExamplee">
                      <div className="accordion-item">
                        <div className="accordion-header" id="headingtwo">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnee" aria-expanded="true" aria-controls="collapseOnee">
                            <div>
                              <div className="heading">
                                {t('choose_your_logo_component.subheading')}
                              </div>
                              <div className="heading-text-normal">
                                {t('choose_your_logo_component.fifty_open_sans')}
                              </div>
                            </div>

                          </button>
                        </div>
                        <div id="collapseOnee" className="accordion-collapse collapse " aria-labelledby="headingtwo" data-bs-parent="#accordionExamplee">
                          <div className="accordion-body">
                            <h4 className="pt-3">
                              {t('choose_your_logo_component.select_a_sub_heading')}
                            </h4>
                            <div id="exTab32" className="">
                              <ul className="nav nav-pills text-pills d-flex">
                                <NavItem as="li" className="active">
                                  <a href="#1atext2" data-toggle="tab">{t('choose_your_logo_component.google_fonts')}  </a>
                                </NavItem>
                                <NavItem as="li" className="tab-secnd" ><a href="#2atext2" data-toggle="tab">{t('choose_your_logo_component.custom_fonts')}</a>
                                </NavItem>

                              </ul>
                              <div className="line"></div>
                              <div className="tab-content  clearfix">
                                <div className="tab-pane active " id="1atext2">
                                  <div className="d-flex" >
                                    <div className=" position-relative shadow-fix select-right-m">
                                      <Select isSearchable={false}
                                        options={options}
                                        className="country mb-4 w-100 mx-auto text-center bgArrow"
                                        placeholder="Open Sans"
                                        theme={theme}
                                        styles={{
                                          option: (base) => ({
                                            ...base,
                                            borderRadius: "30px",
                                            width: "40%",
                                            maxheight: "50px",
                                            lineHeight: "32px",
                                            margin: "10px",
                                            cursor: 'pointer',

                                            boxShadow:
                                              "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                          }),
                                        }}
                                      />
                                       {/* <DropdownBox name="Country" boxid="country" boxwidth="w-500 mx-auto"/> */}
                                    </div>

                                    <div className=" position-relative shadow-fix">
                                      <Select isSearchable={false}
                                        options={options}
                                        className="country mb-4 w-100 mx-auto text-center bgArrow"
                                        placeholder="Regular"
                                        theme={theme}
                                        styles={{
                                          option: (base) => ({
                                            ...base,
                                            borderRadius: "30px",
                                            width: "40%",
                                            maxheight: "50px",
                                            lineHeight: "32px",
                                            margin: "10px",
                                            cursor: 'pointer',

                                            boxShadow:
                                              "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                          }),
                                        }}
                                      />
                                       {/* <DropdownBox name="Country" boxid="country" boxwidth="w-500 mx-auto"/> */}
                                    </div>
                                  </div>
                                  <p>  {t('choose_your_logo_component.select_a_font_from_google_fonts')} </p>


                                </div>
                                <div className="tab-pane" id="2atext2">
                                  <div className="loader-img d-flex">
                                    <div>
                                      Upload font  {t('choose_your_logo_component.')}
                                    </div>
                                    <div>
                                      <FontAwesomeIcon icon={faCloudArrowDown} ></FontAwesomeIcon>
                                    </div>
                                  </div>
                                  <p className="pt-2"> {t('choose_your_logo_component.accepted_font_file')}</p>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="font-cards">
                    <div className="accordion" id="accordionExampleee">
                      <div className="accordion-item">
                        <div className="accordion-header" id="headingthree">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneee" aria-expanded="true" aria-controls="collapseOneee">
                            <div>
                              <div className="heading">
                                {t('choose_your_logo_component.body_text')}
                              </div>
                              <div className="heading-text-body">
                                {t('choose_your_logo_component.twentyfive_open_sans')}
                              </div>
                            </div>

                          </button>
                        </div>
                        <div id="collapseOneee" className="accordion-collapse collapse " aria-labelledby="headingthree" data-bs-parent="#accordionExampleee">
                          <div className="accordion-body">
                            <h4 className="pt-3">
                              {t('choose_your_logo_component. select_a_body_font')}
                            </h4>
                            <div id="exTab324" className="">
                              <ul className="nav nav-pills text-pills d-flex">
                                <NavItem as="li" className="active">
                                  <a href="#1atext233ee" data-toggle="tab">{t('choose_your_logo_component.google_fonts')}  </a>
                                </NavItem>
                                <NavItem as="li" className="tab-secnd" ><a href="#2atext233e" data-toggle="tab">{t('choose_your_logo_component.custom_fonts')}</a>
                                </NavItem>

                              </ul>
                              <div className="line"></div>
                              <div className="tab-content  clearfix">
                                <div className="tab-pane active " id="1atext233ee">
                                  <div className="d-flex" >
                                    <div className=" position-relative shadow-fix select-right-m">
                                      <Select isSearchable={false}
                                        options={options}
                                        className="country mb-4 w-100 mx-auto text-center bgArrow"
                                        placeholder="Open Sans"
                                        theme={theme}
                                        styles={{
                                          option: (base) => ({
                                            ...base,
                                            borderRadius: "30px",
                                            width: "40%",
                                            maxheight: "50px",
                                            lineHeight: "32px",
                                            margin: "10px",
                                            cursor: 'pointer',

                                            boxShadow:
                                              "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                          }),
                                        }}
                                      />
                                       {/* <DropdownBox name="Country" boxid="country" boxwidth="w-500 mx-auto"/> */}
                                    </div>

                                    <div className=" position-relative shadow-fix">
                                      <Select isSearchable={false}
                                        options={options}
                                        className="country mb-4 w-100 mx-auto text-center bgArrow"
                                        placeholder="Regular"
                                        theme={theme}
                                        styles={{
                                          option: (base) => ({
                                            ...base,
                                            borderRadius: "30px",
                                            width: "40%",
                                            maxheight: "50px",
                                            lineHeight: "32px",
                                            margin: "10px",
                                            cursor: 'pointer',

                                            boxShadow:
                                              "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                                          }),
                                        }}
                                      />
                                       {/* <DropdownBox name="Country" boxid="country" boxwidth="w-500 mx-auto"/> */}
                                    </div>
                                  </div>
                                  <p> {t('choose_your_logo_component.select_a_font_from_google_fonts')} </p>


                                </div>
                                <div className="tab-pane" id="2atext233e">
                                  <div className="loader-img d-flex">
                                    <div>
                                      {t('choose_your_logo_component.upload_font')}
                                    </div>
                                    <div>
                                      <FontAwesomeIcon icon={faCloudArrowDown} ></FontAwesomeIcon>
                                    </div>
                                  </div>
                                  <p className="pt-2"> {t('choose_your_logo_component.accepted_font_file')}</p>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </ul>
              </div>
            </ul>
          </div>
          <div className="sidebar-content">
            <div className=" inner-field  bg-box position-relative logo-shadow">





              <Select isSearchable={false}
                options={transformoptions}
                className="country mb-4  mx-auto text-center bgArrow"
                placeholder="Logo: 500 X 500 px"
                theme={theme}
                styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: "35px",
                    width: "90%",
                    zIndex: "5",
                    height: "50px",
                    lineHeight: "32px",
                    margin: "10px",
                    boxShadow: "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                    cursor: 'pointer',
                  }),
                }}
              />
               {/* <DropdownBox name="Country" boxid="country" boxwidth="w-500 mx-auto"/> */}
            </div>
            <div className="resize-head">
              <div className="resize dropdown-toggle" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                {t('choose_your_logo_component.resize')}

              </div>
              <ul className="dropdown-menu p-3 laer-dropdown stle-drop brand-stle-dropdown" aria-labelledby="dropdownMenuClickableInside">
                <h4>
                  {t('choose_your_logo_component.resize')}

                </h4>
                <p>
                  Logo 500 X 500 px
                </p>
                <div className='search-area w-100 mt-2 mb-4 ms-auto'>
                  <form autoComplete="off">
                    <div className='search-field d-flex justify-content-between align-items-center pe-4 ms-auto'>
                      <input placeholder={t('search_placeholder')} className="border-0" type="text" />
                      <div>
                        <FontAwesomeIcon className="" icon={faMagnifyingGlass} />
                      </div>
                    </div>
                  </form>
                </div>
                <b>
                  {t('choose_your_logo_component.custom_size')}
                </b>
                <div className="d-flex justify-space mt-3 mb-3">
                  <div className='check-button   fw-bold d-flex justify-content-start align-items-center'>
                    <input type="checkbox" className="btn-check" name="remember" id="img4" />
                    <label className="btn btn-outline-primary" htmlFor="img4">
                      <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                      </svg>
                    </label>

                  </div>
                  <div className="input-size">
                    <FontAwesomeIcon icon={faArrowsLeftRight} className="size-icon" ></FontAwesomeIcon>500
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faUnlockKeyhole} ></FontAwesomeIcon>
                  </div>
                  <div className="input-size">
                    <FontAwesomeIcon icon={faArrowsUpDown} className="size-icon" ></FontAwesomeIcon>500
                  </div>
                  <div className="  first-field we-field">
                    <div className="  bg-box position-relative">





                      <Select isSearchable={false}
                        options={options}
                        className="country mb-4  mx-auto text-center bgArrow"
                        placeholder="50"
                        theme={theme}
                        styles={{
                          option: (base) => ({
                            ...base,
                            borderRadius: "35px",
                            width: "60px",
                            zIndex: "5",
                            height: "50px",
                            lineHeight: "32px",
                            margin: "10px",
                            boxShadow: "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                            cursor: 'pointer',
                          }),
                        }}
                      />
                       {/* <DropdownBox name="Country" boxid="country" boxwidth="w-500 mx-auto"/> */}
                    </div>


                  </div>
                </div>
                <b className="pt-2 pb-2" >
                  {t('choose_your_logo_component.recently_used')}
                </b>
                <div className="recent-head  mt-3 mb-3">
                  <div className="d-flex justify-space" >
                    <div className='check-button   fw-bold d-flex justify-content-start align-items-center'>
                      <input type="checkbox" className="btn-check" name="remember" id="img4" />
                      <label className="btn btn-outline-primary" htmlFor="img4">
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                        </svg>
                      </label>

                    </div>
                    <div className="">
                      <FontAwesomeIcon icon={faArrowsLeftRight} className="size-icon" ></FontAwesomeIcon>500
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faUnlockKeyhole} ></FontAwesomeIcon>
                    </div>
                    <div className="">
                      <FontAwesomeIcon icon={faArrowsUpDown} className="size-icon" ></FontAwesomeIcon>500
                    </div>
                    <div>PX</div>
                  </div>
                </div>
                <div className="recent-head  mt-3 mb-3">
                  <div className="d-flex justify-space" >
                    <div className='check-button   fw-bold d-flex justify-content-start align-items-center'>
                      <input type="checkbox" className="btn-check" name="remember" id="img4" />
                      <label className="btn btn-outline-primary" htmlFor="img4">
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                        </svg>
                      </label>

                    </div>
                    <div className="">
                      <FontAwesomeIcon icon={faArrowsLeftRight} className="size-icon" ></FontAwesomeIcon>500
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faUnlockKeyhole} ></FontAwesomeIcon>
                    </div>
                    <div className="">
                      <FontAwesomeIcon icon={faArrowsUpDown} className="size-icon" ></FontAwesomeIcon>500
                    </div>
                    <div>PX</div>
                  </div>
                </div>

              </ul>
            </div>

          </div>
        </div>

      </div>

    </div>

  );
}
