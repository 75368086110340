import React, { useState, useEffect } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import img2 from "../../images/img200.png";
function CustomRangeColor({ onBackgroundColorChange,  onTextChange  }) {
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(1);

  const [divColor, setDivColor] = useState(
    "linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) "
  );
  const handleInput = (e) => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);

    const step = e.maxValue - e.minValue;

    // Set the color based on the step value
    if (step === 1) {
      setDivColor(
        "linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) "
      );
      onTextChange("50");
    } else if (step === 2) {
      onTextChange("100");
		setDivColor("linear-gradient(180deg,#CC0000 4.26%,#FF6666 23.45%,#CC3333 46.57%,#FF6666 74.61%,#CC0000 90.85%)");
    } else if (step === 3) {
      onTextChange("150");
      setDivColor("linear-gradient(180deg, #EDB2B1 0%, #FAE2E2 23.75%, #EDB2B1 46.15%, #FAE2E2 70.62%, #EDB2B1 100%)");
    } else if (step === 4) {
      onTextChange("200");
      setDivColor("linear-gradient(180deg, #CEBBBB 4.26%, #FFEBEB 23.45%, #E9CECE 46.57%, #FFEBEB 74.61%, #CEBBBB 90.85%)");
    } else if (step === 5) {
      onTextChange("250");
      setDivColor(" linear-gradient(180deg, #947594 9.75%, #E0BDE0 28.96%, #BC94BC 52.1%, #DFBFDF 80.17%, #947594 96.43%)");
    } else if (step === 6) {
      onTextChange("270");
      setDivColor("linear-gradient(180deg, #A6A6A6 0%, #EDEDED 29.48%, #A6A6A6 53.44%, #EDEDED 76.35%, #A6A6A6 100%)");
    } else if (step === 7) {
      onTextChange("300");
      setDivColor("linear-gradient(182deg, #759490 1.7%, #BFDFD2 25.08%, #94BCB7 49.45%, #BFDFD2 70.89%, #759490 93.79%)");
    } else if (step === 8) {
      onTextChange("330");
      setDivColor(" linear-gradien180deg,#C0C0C0 4.26%, #D2B48C 23.45%,#C0C0C0 46.57%,#D2B48C 74.61%,#C0C0C0 90.85%)");
    } else if (step === 9) {
      onTextChange("376");
      setDivColor("linear-gradient(180deg,#CC0000 4.26%,#FF6666 23.45%,#CC3333 46.57%,#FF6666 74.61%,#CC0000 90.85%)");
    } else if (step === 10) {
      onTextChange("400");
      setDivColor("linear-gradient(180deg,#808080 4.26%,#A9A9A9 23.45%,#808080 46.57%,#A9A9A9 74.61%,#808080 90.85%)");
    } else {
      // Add more conditions for other steps as needed
    }

    onBackgroundColorChange(divColor);
  };

  useEffect(() => {
    onBackgroundColorChange(divColor);
  }, [divColor, onBackgroundColorChange, onTextChange]);
  


 


  return (
    <div className="App colorRange">
      <MultiRangeSlider
        min={0}
        max={10}
        step={1}
        minValue={minValue}
        maxValue={maxValue}
        onInput={(e) => {
          handleInput(e);
        }}
      />
      <div className="text-flex mt-3 d-none">
        <h6>
          <img src={img2} alt="" />
          High School
        </h6>
        <h6>
          <img src={img2} alt="" />
          College
        </h6>
        <h6>
          <img src={img2} alt="" />
          BAs
        </h6>
        <h6>
          <img src={img2} alt="" />
          MAs
        </h6>
        <h6>
          <img src={img2} alt="" />
          PhD
        </h6>
      </div>
  
    </div>
  );
}

export default CustomRangeColor;
