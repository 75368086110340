import * as React from 'react';
import ButtonBox from '../../global/ButtonBox';
import Face from '../../../images/face.png';
import '../../login/login.scss';
import { useTranslation } from "react-i18next";
import { NavItem } from 'react-bootstrap';

import { ReactComponent as IconBlue1 } from "../../../images/svgIcon/icon1.svg";
import { ReactComponent as IconBlue2 } from "../../../images/svgIcon/icon2.svg";
import ButtonBoxNew from '../../global/ButtonBoxNew';

export default function FaceIDVerify() {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <ul className='pb-5'>
                <NavItem as="li">
                    <div className='verificationCard bg br-30 shadow-4 mx-auto my-4'>
                        <div className='card shadow-4 p-0 bg mx-auto border-0'>
                            <img src={Face} alt="" className='w-auto ' />
                        </div>
                        <h6>{t('reset_component.scan_your_face_to_turn_on_face_recognition')}</h6>
                        <div className='success-section mt-4'>
                            {/* <button type="button" className="btn border-0 bg shadow-4 w-125 h-45 rounded-pill h6 text-uppercase textBody fw-bold">Scan Now</button> */}
                            <ButtonBoxNew name="Scan Now" boxwidth="w-100" />
                        </div>
                        <div className='success-section'>
                            <div className='verifyIcon rounded-circle icon-sucess d-flex justify-content-center align-items-center'>
                                <IconBlue2 />
                            </div>
                            <h6>{t('reset_component.face_id_verified')}</h6>
                        </div>
                        <div className='success-section d-flex flex-column justify-content-center align-items-center'>
                            <div className='verifyIcon rounded-circle bg-danger d-flex justify-content-center align-items-center'>
                                <IconBlue1 />
                            </div>
                            <h6 className="w-100 mb-4">{t('reset_component.verfication_failed')}</h6>
                            {/* <button type="button" className="btn border-0 bg shadow-4 w-110 rounded-pill h6 text-uppercase textBody fw-bold">Try again</button> */}
                            <ButtonBoxNew name="Try again" boxwidth="w-100" />
                        </div>

                    </div>
                </NavItem>
                <NavItem as="li" className='mb-4'>
                    <input placeholder={t('enter_new_password')} className="shadow-5in br-10 w-500" type="password" />
                </NavItem>
                <NavItem as="li">
                    <ButtonBox boxwidth="w-185 mt-0 mb-50" clicked="Password changed" name="Change Password" />
                </NavItem>
            </ul>
        </div>
    );
}