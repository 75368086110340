import React from "react";
import { Link } from 'react-router-dom';
import ButtonBox from "../../../../../global/ButtonBox";
import SocialSliderFB from "../../../../../global/socialSlider/SocialSliderFb";
import SocialSliderTW from "../../../../../global/socialSlider/SocialSliderTw";
import SocialSliderYT from "../../../../../global/socialSlider/SocialSliderYt";
import SocialSliderLK from "../../../../../global/socialSlider/SocialSliderLinkd";
import qrcode from '../../../../../../images/qrcode.png';
import { ReactComponent as Download } from "../../../../../../images/icons/icon29.svg";
import { ReactComponent as VideoIcon } from "../../../../../../images/icons/iconvid.svg";

import { ReactComponent as IconBluew1 } from "../../../../../../images/svgIcon/icon13.svg";
import { ReactComponent as IconBluew2 } from "../../../../../../images/svgIcon/icon14.svg";
import { ReactComponent as IconBluew3 } from "../../../../../../images/svgIcon/icon15.svg";
import { ReactComponent as IconBluew4 } from "../../../../../../images/svgIcon/icon16.svg";
import { ReactComponent as IconBluew6 } from "../../../../../../images/svgIcon/icon18.svg";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

import TwitterBanner from "../../../../../global/socialBanner/TwitterBanner";
import YoutubeBanner from "../../../../../global/socialBanner/YoutubeBanner";
import FacebookBanner from "../../../../../global/socialBanner/FacebookBanner";
import LinkedinBanner from "../../../../../global/socialBanner/LinkedinBanner";
import InstagramBanner from "../../../../../global/socialBanner/InstagramBanner";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import './ContactUsSectionThemes.scss';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { faLock } from "@fortawesome/free-solid-svg-icons";
import active_vector from '../../../../../../images/active-vector.png'
export default function Social2() {
  const { t, i18n } = useTranslation();



  return (
    <div>
      <div className="SocialMedia d-none">
        <ul>
          <NavItem as="li" className="shadow-4 br-20 my-4 px-3">
            <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-4">{t('profile_section.choose_your_facebook_business_profile_banner_design')} <b className="ms-2">{t('profile_section._1980_X_1005_pixels')}</b></h6>
              <Link to="" className="text-primary">
                <VideoIcon className="w-100x h-auto" />
              </Link>
            </div>
            <div className="px-5 mb-4 mt-3 position-relative">
              <SocialSliderFB />

            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.facebook_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.facebook_business_id')}</h6>
                <input placeholder={t('profile_section.enter_username')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.facebook_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.facebook_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_facebook_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_facebook_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className="shadow-4 br-20 my-4 px-3">
            <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-5">{t('profile_section.choose_your_twitter_business_profile_banner_design')} <b className="ms-2">{t('profile_section._1500_X_500_pixels')}</b></h6>
              <Link to="" className="text-primary">
                <VideoIcon className="w-100x h-auto" />
              </Link>
            </div>
            <div className="px-5 mb-4 mt-3">
              <SocialSliderTW />
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.twitter_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew3 className="me-2" />
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.twitter_business_account_username')}</h6>
                <input placeholder={t('profile_section.enter_username')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.twitter_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.twitter_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_twitter_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_twitter_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className="shadow-4 br-20 my-4 px-3">
            <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-4">{t('profile_section.choose_your_youtube_business_channel_banner_design')}
                <b className="ms-2">{t('profile_section._2048_X_1152_pIixels')}</b></h6>
              <Link to="" className="text-primary">
                <VideoIcon className="w-100x h-auto" />
              </Link>
            </div>
            <div className="px-5 mb-4 mt-3">
              <SocialSliderYT />
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.youtube_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew6 className="me-2" />
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.youtube_business_account_url')}</h6>
                <input placeholder={t('profile_section.enter_username')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.youtube_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.youtube_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_youtube_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_youtube_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className="bdrnone shadow-4 br-20 my-4 px-3">
            {/* <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-4">Choose Your Instagram Business Channel | Banner Design2048 X 1152 PIXELS
              <b className="ms-2">{t('profile_section._2048_X_1152_pIixels')}</b></h6>
              <Link to="" className="text-primary">
              <VideoIcon className="w-100x h-auto" />
              </Link>
              </div> */}
            {/* <div className="px-5 mb-4 mt-3">
                <SocialSliderInst />
              </div> */}
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.instagram_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew2 className="me-2" />
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.instagram_business_account_username')}</h6>
                <input placeholder={t('profile_section.enter_username')} className="w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.instagram_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.instagram_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_instagram_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_instagram_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className="shadow-4 br-20 my-4 px-3">
            <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-5">{t('profile_section.choose_your_linkedin_business_profile_banner_design')} <b className="ms-2">{t('profile_section._2256_X_382_pixels')}</b></h6>
              <Link to="" className="text-primary">
                <VideoIcon className="w-100x h-auto" />
              </Link>
            </div>
            <div className="px-5 mb-4 mt-3">
              <SocialSliderLK />
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.linkedIn_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew4 className="me-2" />
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.linkedIn_business_username')}</h6>
                <input placeholder={t('profile_section.enter_username')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.linkedIn_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section. pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.linkedIn_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_linkedIn_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_linkedin_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className=" text-center mt-5 justify-content-center bdrnone">
            <ButtonBox
              boxwidth="w-140 mt-0 mb-50 mx-auto"
              clicked="Saved"
              name="Save/Next"
            />
          </NavItem>
        </ul>
      </div>
      <div>
        <h4 className="text-center">
          Switch the Style
        </h4>
        <Tab.Container id="left-tabs-example" defaultActiveKey="Regular">
          <div className="d-flex flex-column-reverse">
            <Tab.Content>
              <Tab.Pane eventKey="Regular">
                <div className="social-reguler">
                  <FacebookBanner />
                  <TwitterBanner />
                  <YoutubeBanner />
                  <InstagramBanner />
                  <LinkedinBanner />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Neumorphic">
                <div className="social-neumorphic">
                  <FacebookBanner />
                  <TwitterBanner />
                  <YoutubeBanner />
                  <InstagramBanner />
                  <LinkedinBanner />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Glassmorphic">
                <div className="social-glassmorphic">
                  <FacebookBanner />
                  <TwitterBanner />
                  <YoutubeBanner />
                  <InstagramBanner />
                  <LinkedinBanner />
                </div>
              </Tab.Pane>
            </Tab.Content>
            <div className="d-flex align-items-center justify-content-between services-work  w-100 mb-0 ">
              <Nav  className="w-100 d-flex  align-items-center justify-content-center">
                <ul className="d-flex justify-content-center my-4 pb-2 items-center">
                  <Nav.Item as="li" className="me-0">
                    <Nav.Link className="m-0 p-0 border-0 design-one design-one-reg" eventKey="Regular">
                      <div className="active-sign">
                        <div className="active-icon-color " >
                          <img src={active_vector} className="active-vector" alt="active-icon" ></img>
                        </div>
                      </div>
                      <span>Regular</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="margin-left-scnd pl-3">
                    <Nav.Link className="m-0 p-0 border-0 design-two ml-3" eventKey="Neumorphic">
                      <div className="active-sign">
                        <div className="active-icon-color-second " >
                          <img src={active_vector} className="active-vector" alt="active-icon" ></img>
                        </div>
                      </div>  <span>Neumorphic <FontAwesomeIcon className="lock" icon={faLock} ></FontAwesomeIcon> </span>
                    </Nav.Link>

                  </Nav.Item>
                  <Nav.Item as="li" className="margin-left-scnd pl-3">
                    <Nav.Link className="m-0 p-0 border-0 design-three ml-3" eventKey="Glassmorphic">
                      <div className="active-sign">
                        <div className="active-icon-color-third " >
                          <img src={active_vector} className="active-vector" alt="active-icon" ></img>
                        </div>
                      </div>      <span>Glassmorphic <FontAwesomeIcon className="lock" icon={faLock} ></FontAwesomeIcon> </span>

                    </Nav.Link>

                  </Nav.Item>
                </ul>
              </Nav>
            </div>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
}
