import React from "react";
import ReactStars from "react-rating-stars-component";
import avatar from "../../../../../images/testimonial-case-study/regular2_avatar.png";

import "./RegularTheme2.scss";

export default function RegularTheme2() {
  const ratingChanged = (newRating) => {
  };
  return (
    <div className="dot-container">
      <div className="profile-div">
        <h1>What Our Clients Say Avout US...</h1>
        <div className="img-div">
          <img src={avatar} alt="Regular Theme 2 Avatar" srcset="" />
        </div>
        <div className="thought-outer-div">
         
          <div className="thought-inner-div">
            <h1>DYNA APPOLON</h1>
            <p>CEO | SOCIAL NETWORK STRATEGIST</p>
            <span>
              “Lorem ipsum dolor sit amet consectetur. Duis elementum varius
              duis eu donec. Tellus ut metus sed id mi consequat odio nunc
              velit.”
            </span>
          </div>
        </div>
        <div className="see-my-wall-div">
          <p>SEE MY</p>
          <a href="#">WALL OF LOVE</a>
        </div>
        <div className="rating-stars-div">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={20}
            activeColor="#FACB30"
          />
        </div>
      </div>
    </div>
  );
}
