import React, { useState } from "react";
import desktop_3_1 from "../../../../images/pressrelease/Desktop3-1.png";
import tab_3_1 from "../../../../images/pressrelease/Tab3-1.png"
import mobile_3_1 from "../../../../images/pressrelease/Mobile3-1.png"
import desktop_3_2 from "../../../../images/pressrelease/Desktop3-2.png"
import tab_3_2 from "../../../../images/pressrelease/Tab3-2.png"
import mobile_3_2 from "../../../../images/pressrelease/Mobile3-2.png"
import desktop_3_3 from "../../../../images/pressrelease/Desktop3-3.png"
import tab_3_3 from "../../../../images/pressrelease/Tab3-3.png"
import mobile_3_3 from "../../../../images/pressrelease/Mobile3-3.png"
// import desktop_3_4 from "../../../../images/featured-on/Desktop3-4.png"
// import tab_3_4 from "../../../../images/featured-on/Tab3-4.png"
// import mobile_3_4 from "../../../../images/featured-on/Mobile3-4.png"
import Modal from 'react-bootstrap/Modal';
// import HowItWorkSectionGlassmorphicThemeDesktopView1 from "./howItWorkGlassmorphicThemes/HowItWorkSectionGlassmorphicThemeDesktopView1";
// import HowItWorkSectionGlassmorphicThemeTabView1 from "./howItWorkGlassmorphicThemes/HowItWorkSectionGlassmorphicThemeTabView1";
// import HowItWorkSectionGlassmorphicThemeMobileView1 from "./howItWorkGlassmorphicThemes/HowItWorkSectionGlassmorphicThemeMobileView1";
// import HowItWorkSectionGlassmorphicThemeDesktopView2 from "./howItWorkGlassmorphicThemes/HowItWorkSectionGlassmorphicThemeDesktopView2";
// import HowItWorkSectionGlassmorphicThemeTabView2 from "./howItWorkGlassmorphicThemes/HowItWorkSectionGlassmorphicThemeTabView2";
// import HowItWorkSectionGlassmorphicThemeMobileView2 from "./howItWorkGlassmorphicThemes/HowItWorkSectionGlassmorphicThemeMobileView2";


import './PressReleaseSectionThemes.scss';
import { useTranslation } from "react-i18next";
import ButtonBoxNew from "../../../global/ButtonBoxNew";
export default function PressReleaseSectionGlassmorphicTheme() {
  const { t, i18n } = useTranslation();

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [themedesktopShow, setThemedesktopShow] = useState(false);
  const [themetabShow, setThemetabShow] = useState(false);
  const [themedesktop3Show, setThemedesktop3Show] = useState(false);
  const [themetab3Show, setThemetab3Show] = useState(false);
  const [thememobileShow, setThememobileShow] = useState(false);
  const [thememobile3Show, setThememobile3Show] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);
  const [desktopgeoShow, setDesktopgeoShow] = useState(false);
  const [TabFourShow, setTabFourShow] = useState(false);
  const [MobileFourShow, setMobileFourShow] = useState(false);
  const [deskFourShow, setdeskFourShow] = useState(false);
  const [TabFiveShow, setTabFiveShow] = useState(false);
  const [MobileFiveShow, setMobileFiveShow] = useState(false);
  const [deskFiveShow, setdeskFiveShow] = useState(false);
  const [TabSixShow, setTabSixShow] = useState(false);
  const [MobileSixShow, setMobileSixShow] = useState(false);
  const [deskSixShow, setdeskSixShow] = useState(false);
  const [TabSevenShow, setTabSevenShow] = useState(false);
  const [MobileSevenShow, setMobileSevenShow] = useState(false);
  const [deskSevenShow, setdeskSevenShow] = useState(false);
  


  

  return (
    <div className="">
   
<h4 className="text-center">
Glassmorphic  Design Version 1
</h4>
<div className="d-flex card-outo">
<div className="">
<div className="screen-bg">
  <img src={desktop_3_1} alt='desktop-3-1' >
  </img>

</div>
{/* <button className="screen-btn" onClick={() => setDesktopgeoShow(true)}>
See Desktop view
</button> */}
<ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

<Modal
    
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
                show={desktopgeoShow}
        onHide={() => setDesktopgeoShow(false)}
        
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        {/* <HowItWorkSectionGlassmorphicThemeDesktopView1 /> */}

        </Modal.Body>
      </Modal>

</div>
<div className="">
<div className="screen-bg">
<img src={tab_3_1} alt='desktop-3-1' ></img>
</div>
{/* <div className="screen-btn"   onClick={() => setSmShow(true)}>
See Tablet view
</div> */}
<ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

<Modal
    
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
                show={smShow}
        onHide={() => setSmShow(false)}
        
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        {/* <HowItWorkSectionGlassmorphicThemeTabView1 /> */}

        </Modal.Body>
      </Modal>
</div>

<div className="">
<div className="screen-bg">
<img src={mobile_3_1} alt='mobile-3-1' ></img>
</div>
{/* <div className="screen-btn" onClick={() => setLgShow(true)}>
See Mobile view
</div> */}
<ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

<Modal
        show={lgShow}
        onHide={() => setLgShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-titlee"
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        {/* <HowItWorkSectionGlassmorphicThemeMobileView1 /> */}

        </Modal.Body>
      </Modal>
</div>
</div>
<h4 className="text-center">
Glassmorphic  Design Version 2
</h4>
<div className="d-flex card-outo">
<div className="">
<div className="screen-bg">
  <img src={desktop_3_2} alt='desktop-3-2' >
  </img>

</div>
{/* <button className="screen-btn"  onClick={() => setThemedesktopShow(true)}>
See Desktop view
</button> */}
<ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

<Modal
        show={themedesktopShow}
        onHide={() => setThemedesktopShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-titlee"
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        {/* <HowItWorkSectionGlassmorphicThemeDesktopView2 /> */}

        </Modal.Body>
      </Modal>
</div>
<div className="">
<div className="screen-bg">
<img src={tab_3_2} alt='tab-3-2' ></img>
</div>
{/* <div className="screen-btn" onClick={() => setThemetabShow(true)}>
See Tablet view
</div> */}
<ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

<Modal
        show={themetabShow}
        onHide={() => setThemetabShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-titlee"
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        {/* <HowItWorkSectionGlassmorphicThemeTabView2 /> */}

        </Modal.Body>
      </Modal>
</div>

<div className="">
<div className="screen-bg">
<img src={mobile_3_2} alt='mobile-3-2' ></img>
</div>
{/* <div className="screen-btn" onClick={() => setThememobileShow(true)}>
See Mobile view
</div> */}
<ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

<Modal
        show={thememobileShow}
        onHide={() => setThememobileShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-titlee"
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        {/* <HowItWorkSectionGlassmorphicThemeMobileView2 /> */}

        </Modal.Body>
      </Modal>
</div>
</div>
<h4 className="text-center">
Glassmorphic  Design Version 3
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_3} alt="desktop-3" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskthreeShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskthreeShow}
            onHide={() => setDeskthreeShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_3} alt="tab-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabThreeShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={TabThreeShow}
            onHide={() => setTabThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_3} alt="mobile-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileThreeShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={MobileThreeShow}
            onHide={() => setMobileThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      {/* <h4 className="text-center">
      Glassmorphic  Design Version 4
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_4} alt="desktop-4" >
            </img>

          </div>
          <div className="screen-btn" onClick={() => setdeskFourShow(true)}>
            See Desktop view
          </div>
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFourShow}
            onHide={() => setdeskFourShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
      

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_4} alt="tab-4" ></img>
          </div>
          <div className="screen-btn" onClick={() => setTabFourShow(true)}>
            See Tablet view
          </div>
          <Modal
            show={TabFourShow}
            onHide={() => setTabFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
    

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_4} alt="mobile-4" ></img>
          </div>
          <div className="screen-btn" onClick={() => setMobileFourShow(true)}>
            See Mobile view
          </div>
          <Modal
            show={MobileFourShow}
            onHide={() => setMobileFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
        

            </Modal.Body>
          </Modal>
        </div>
      </div> */}
    </div>
  );
}
