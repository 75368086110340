import React, { useState } from "react";

import desktop_1 from "../../../../images/servicepackageplan/Desktop1.png"
import tab_1 from "../../../../images/servicepackageplan/Tab1.png"
import mobile_1 from "../../../../images/servicepackageplan/Mobile1.png"
import desktop_2 from "../../../../images/servicepackageplan/Desktop2.png"
import tab_2 from "../../../../images/servicepackageplan/Tab2.png"
import mobile_2 from "../../../../images/servicepackageplan/Mobile2.png"
import desktop_3 from "../../../../images/servicepackageplan/Desktop3.png"
import tab_3 from "../../../../images/servicepackageplan/Tab3.png"
import mobile_3 from "../../../../images/servicepackageplan/Mobile3.png"
import desktop_4 from "../../../../images/servicepackageplan/Desktop4.png"
import tab_4 from "../../../../images/servicepackageplan/Tab4.png"
import mobile_4 from "../../../../images/servicepackageplan/Mobile4.png"
import desktop_5 from "../../../../images/servicepackageplan/Desktop5.png"
import tab_5 from "../../../../images/servicepackageplan/Tab5.png"
import mobile_5 from "../../../../images/servicepackageplan/Mobile5.png"
import desktop_6 from "../../../../images/servicepackageplan/Desktop6.png"
import tab_6 from "../../../../images/servicepackageplan/Tab6.png"
import mobile_6 from "../../../../images/servicepackageplan/Mobile6.png"
import ServicePackagePlanRegularThemeDesktopView1 from './ServicePackagePlanRegularThemeDesktopView1'
import ServicePackagePlanRegularThemeDesktopView2 from './ServicePackagePlanRegularThemeDesktopView2'
import ServicePackagePlanRegularThemeDesktopView3 from './ServicePackagePlanRegularThemeDesktopView3'
import ServicePackagePlanRegularThemeDesktopView4 from './ServicePackagePlanRegularThemeDesktopView4'
import ServicePackagePlanRegularThemeDesktopView5 from './ServicePackagePlanRegularThemeDesktopView5'
import ServicePackagePlanRegularThemeDesktopView6 from './ServicePackagePlanRegularThemeDesktopView6'
import ServicePackagePlanRegularThemeTabView3 from './ServicePackagePlanRegularThemeTabView3'
import ServicePackagePlanRegularThemeTabView4 from './ServicePackagePlanRegularThemeTabView4'
import ServicePackagePlanRegularThemeTabView5 from './ServicePackagePlanRegularThemeTabView5'
import ServicePackagePlanRegularThemeTabView6 from './ServicePackagePlanRegularThemeTabView6'
import ServicePackagePlanRegularThemeMobileView3 from './ServicePackagePlanRegularThemeMobileView3'
import ServicePackagePlanRegularThemeMobileView4 from './ServicePackagePlanRegularThemeMobileView4'
import ServicePackagePlanRegularThemeMobileView5 from './ServicePackagePlanRegularThemeMobileView5'
import ServicePackagePlanRegularThemeMobileView6 from './ServicePackagePlanRegularThemeMobileView6'

import ServicePackagePlanRegularThemeTabView1 from './ServicePackagePlanRegularThemeTabView1'
import ServicePackagePlanRegularThemeMobileView1 from './ServicePackagePlanRegularThemeMobileView1'
import ServicePackagePlanRegularThemeTabView2 from './ServicePackagePlanRegularThemeTabView2'
import ServicePackagePlanRegularThemeMobileView2 from './ServicePackagePlanRegularThemeMobileView2'

import Modal from 'react-bootstrap/Modal';

import { useTranslation } from "react-i18next";

import ButtonBoxNew from "../../../global/ButtonBoxNew";

import './ServicePackagePlanThemes.scss';
export default function ServicePackagePlanRegularTheme() {

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [TabTwoShow, setTabTwoShow] = useState(false);
  const [MobileTwoShow, setMobileTwoShow] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [TabFourShow, setTabFourShow] = useState(false);
  const [TabFiveShow, setTabFiveShow] = useState(false);
  const [TabSixShow, setTabSixShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [MobileFourShow, setMobileFourShow] = useState(false);
  const [MobileFiveShow, setMobileFiveShow] = useState(false);
  const [MobileSixShow, setMobileSixShow] = useState(false);
  const [deskShow, setDeskShow] = useState(false);
  const [desktwoShow, setDesktwoShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);
  const [deskfourShow, setDeskFourShow] = useState(false);
  const [deskfiveShow, setDeskFiveShow] = useState(false);
  const [desksixShow, setDeskSixShow] = useState(false);

  const { t, i18n } = useTranslation();
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });




  return (
    <div className="">

      <h4 className="text-center">
        Regular  Design Version 1
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_1} alt="deshtop-1"  >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setDeskShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeDesktopView1 />

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_1} alt="tab-1"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setSmShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeTabView1 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_1} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeMobileView1 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 2
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2} alt="desktop-2" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDesktwoShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setDesktwoShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktwoShow}
            onHide={() => setDesktwoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeDesktopView2 />

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2} alt="tab-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabTwoShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setTabTwoShow(true)}/>

          <Modal
            show={TabTwoShow}
            onHide={() => setTabTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-tit"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeTabView2 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2} alt="mobile-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileTwoShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileTwoShow(true)}/>

          <Modal
            show={MobileTwoShow}
            onHide={() => setMobileTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-ti"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeMobileView2 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 3
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3} alt="desktop-3" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskthreeShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setDeskthreeShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskthreeShow}
            onHide={() => setDeskthreeShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeDesktopView3 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3} alt="tab-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabThreeShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabThreeShow(true)}/>

          <Modal
            show={TabThreeShow}
            onHide={() => setTabThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeTabView3 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3} alt="mobile-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileThreeShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileThreeShow(true)}/>

          <Modal
            show={MobileThreeShow}
            onHide={() => setMobileThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeMobileView3 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 4
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_4} alt="desktop-4" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskFourShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setDeskFourShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskfourShow}
            onHide={() => setDeskFourShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeDesktopView4 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_4} alt="tab-4" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFourShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabFourShow(true)}/>

          <Modal
            show={TabFourShow}
            onHide={() => setTabFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeTabView4 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_4} alt="mobile-4" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFourShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileFourShow(true)}/>

          <Modal
            show={MobileFourShow}
            onHide={() => setMobileFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeMobileView4 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 5
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_5} alt="desktop-5" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskFiveShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setDeskFiveShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskfiveShow}
            onHide={() => setDeskFiveShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeDesktopView5 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_5} alt="tab-5" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFiveShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabFiveShow(true)}/>

          <Modal
            show={TabFiveShow}
            onHide={() => setTabFiveShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeTabView5 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_5} alt="mobile-5" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFiveShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileFiveShow(true)}/>

          <Modal
            show={MobileFiveShow}
            onHide={() => setMobileFiveShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeMobileView5 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 6
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_6} alt="desktop-6" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskSixShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setDeskSixShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desksixShow}
            onHide={() => setDeskSixShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeDesktopView6 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_6} alt="tab-6" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabSixShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabSixShow(true)}/>

          <Modal
            show={TabSixShow}
            onHide={() => setTabSixShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeTabView6 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_6} alt="mobile-6" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileSixShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setMobileSixShow(true)}/>

          <Modal
            show={MobileSixShow}
            onHide={() => setMobileSixShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanRegularThemeMobileView6 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
