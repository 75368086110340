import React from "react";
import '../EmailSignatureStep.scss';
import Contest3 from '../../../../../images/img29sss.png';
import user from '../../../../../images/img43ss.png';
import label1 from '../../../../../images/img31s.png';
import { Link } from 'react-router-dom';
// import Select from 'react-select';

import { ReactComponent as IconBlue14 } from "../../../../../images/icons/icon41.svg";
import { ReactComponent as IconBlue15 } from "../../../../../images/icons/icon42.svg";
import { ReactComponent as IconBlue16 } from "../../../../../images/icons/icon43.svg";
import { ReactComponent as IconBlue17 } from "../../../../../images/icons/icon44.svg";
import { ReactComponent as IconBlue1 } from "../../../../../images/icons/icon46.svg";
import { ReactComponent as IconBlue2 } from "../../../../../images/icons/icon54.svg";
import { ReactComponent as IconBlue3 } from "../../../../../images/icons/icon53.svg";
import { ReactComponent as IconBlue4 } from "../../../../../images/icons/icon50.svg";
import { ReactComponent as IconBlue5 } from "../../../../../images/icons/icon49.svg";
import { ReactComponent as IconBlue6 } from "../../../../../images/icons/icon47.svg";
import { ReactComponent as IconBlue7 } from "../../../../../images/icons/icon52.svg";
import { ReactComponent as IconBlue8 } from "../../../../../images/icons/icon51.svg";
import { ReactComponent as IconBlue9 } from "../../../../../images/icons/icon48.svg";
import EmailTemp from "../../../../global/EmailTemp";
import EmailTempBm from "../../../../global/EmailTempBm";

export default function EmailDesign5() {
  return (
    <div className="w-98 pt-2">
      {/* 1st section */}
      <div className="shadow-4 mb-4 silverClr bgTopEmail">
        <div className="px-4 w-100">
          <div className="w-100 bgmiddelEmail">
            <div className="w-100 p-4 row mx-0">
              <div className="col-lg-4 p-0">
                <img src={user} alt="" width="100%" height="340" className="mt-n6 object-cover" />
              </div>
              <div className="col-md-7 ps-4 pe-0 d-flex flex-column justify-content-between">
                <div>
                  <h3 className="mb-0 pb-3">Hello I'm<br /> Dyna Appolon</h3>
                  <h4 className=" text-uppercase shadow-4 br-5 ">CEO | Social Network Strategist</h4>
                  <ul className="d-flex flex-wrap text-start emaillist br-10 p-10 shadow-4">
                    <li className="w-50 pe-3 h-50s d-flex py-2 flex-column justify-content-center align-items-start">
                      <h5>Industry:</h5>
                      <p>Social Network</p>
                    </li>
                    <li className="w-50 ps-3 h-50s d-flex py-2 flex-column justify-content-center align-items-start">
                      <h5>Department:</h5>
                      <p>Startup</p>
                    </li>
                    <li className="w-100 text-center mt-3 pt-1"><h6 className="mb-0">The Best Alternative<br /> Lifestyle to the 9 to 5 Grind.</h6></li>
                  </ul>
                </div>
                <div className="w-90s shadow-4 p-1 ms-auto mt-30">
                  <img src={label1} height="60" alt="" />
                </div>
              </div>
              <div className="col-lg-1 px-1">
                <div className="mt-5 pt-4 d-flex flex-column justify-content-center align-items-end">
                  <Link to="#" className="shadow-4 mb-1 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue1 /></Link>
                  <Link to="#" className="shadow-4 mb-1 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue2 /></Link>
                  <Link to="#" className="shadow-4 mb-1 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue3 /></Link>
                  <Link to="#" className="shadow-4 mb-1 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue4 /></Link>
                  <Link to="#" className="shadow-4 mb-1 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue5 /></Link>
                  <Link to="#" className="shadow-4 mb-1 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue6 /></Link>
                  <Link to="#" className="shadow-4 mb-1 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue7 /></Link>
                  <Link to="#" className="shadow-4 mb-1 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue8 /></Link>
                  <Link to="#" className="shadow-4 br-50 w-26 d-flex justify-content-center align-items-center"><IconBlue9 /></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 px-4">
            <div className="shadow-4 p-3 w-350 mt-n7 bg br-10">
              <p className="m-0 h13 lh-20"><b>Mission Statement:</b> I help people quit their 9 to 5 jobs and launch, manage, and scale up their online business.</p>
            </div>
          </div>
        </div>
        <div className="w-100 my-4 text-center">
          <div className="w-560 shadow-4 text-start px-3 py-2 h8 mx-auto">
            <div className="px-3 text-start d-flex justify-content-center flex-wrap">
              <h5 className="py-1 h7 d-flex align-items-center m-0 mb-0 pe-2"><span className="w-28 br-50 shadow-4 me-2 d-flex justify-content-center align-items-center"><IconBlue15 /></span>1-800-000-00-00</h5>
              <h5 className="py-1 h7 d-flex align-items-center m-0 mb-0 pe-2"><span className="w-28 br-50 shadow-4 me-2 d-flex justify-content-center align-items-center"><IconBlue17 /></span>dyna@7figurebosses.com</h5>
              <h5 className="py-1 h7 d-flex align-items-center m-0 mb-0"><span className="w-28 br-50 shadow-4 me-2 d-flex justify-content-center align-items-center"><IconBlue16 /></span>9to5escapee.com </h5>
              <h5 className="py-1 h7 d-flex align-items-center m-0"><span className="w-28 me-2 br-50 shadow-4 d-flex justify-content-center align-items-center"><IconBlue14 /></span><span className="w-100">123 Success Rd, Take Action City, Freedom Country M6Y 10T </span></h5>
            </div>
          </div>
        </div>
        <div className="br-5 p-2 w-100 position-relative h-93 px-5 d-flex justify-content-center align-items-center">
            <img src={Contest3} alt="" width="650" height="100%" className="bottom-0 position-absolute top-0 start-0 end-0 m-auto" />
            <p className="h6 lh-30 mb-0 px-2">“I never dreamed about success. I worked for it.” Estee Lauder</p>
        </div>
        <EmailTemp />
      </div>
      <div className="shadow-4 mb-4 silverClr">
        <EmailTempBm />
      </div>
    </div>
  );
} 
