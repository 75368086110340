import React from "react";
import { useState } from "react";
import "../regularTheme1Mobile/RegularTheme1Mobile.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import timeline from "../../../../../../images/how-it-work/timeline-mobile.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { IoIosArrowForward } from "react-icons/io";
import { FcCheckmark } from "react-icons/fc";
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai";
import "slick-carousel/slick/slick-theme.css";
export default function RegularTheme1Mobile() {
  const [isVisible, setIsVisible] = useState(false);

  const [Competency1, setCompetency1] = useState(10);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <div className="">
        <div>
          <div className="main-content plan_content">
            <div>
              <div className="mobile-help-section text-center relative">
                <div className="d-flex align-items-center justify-content-center mb-5">
                  <div className="toggle_button">
                    <button type="" className="btn active_btn">
                      Monthly
                    </button>
                    <button type="" className="btn1 ">
                      Yearly
                    </button>
                  </div>
                </div>
                <div className="relative">
                  <div className="main_row p-4"></div>
                  <div className="content_wrapper">
                    <div className="flex align-items-center w-100 pt-4">
                      <h2 className="mb-3">HOSTING FEATURES</h2>
                      <ul>
                        <li>Lorem ipsum dolor</li>
                        <li> Sit amet consect</li>
                        <li>Amet consectetur</li>
                        <li>Neque sed suspend</li>
                        <li>Se sed diam mattis</li>
                        <li>Ac sagittis ac</li>
                        <li>Sitt isporta vestibulum</li>
                        <li>eu velit viverra quis vel</li>
                      </ul>
                    </div>
                    <div className="how-it-work-programs-wrapper">
                      {/* <div className="programs-flex d-flex justify-content-center"> */}
                      <Slider {...settings} className="">
                        <div className="px-4 py-5">
                          <div className="plan_outer mx-auto">
                            <div className="pt-3 px-3 plan_div">
                              <h1 className="text_basic">BASIC</h1>
                            </div>
                            <div>
                              <div className="ribbon">
                                <span className="ribbon_1"></span>
                                <div className="ribbon1">
                                  <div className="price">
                                    <h2>$29</h2>
                                    <p>/Month</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-5 p-4">
                              <ul>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Lorem ipsum dolor
                                </li>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Sit amet consect
                                </li>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Amet consectetur
                                </li>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Neque sed suspend
                                </li>
                                <li>
                                  <RxCross2 className="icon text-danger" />
                                  Se sed diam mattis
                                </li>
                                <li>
                                  <RxCross2 className="icon text-danger" />
                                  Ac sagittis ac
                                </li>
                                <li>
                                  <RxCross2 className="icon text-danger" />
                                  Sitt isporta vestibulum
                                </li>
                                <li>
                                  <RxCross2 className="icon text-danger" />
                                  eu velit viverra quis vel
                                </li>
                              </ul>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              <button type="" className="signin_btn">
                                <div className="btn_inner basic_btn_inner">
                                  <div>
                                    <span>Sign up</span>
                                    <IoIosArrowForward className="arrow_icon" />
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-5">
                          <div className="plan_outer mx-auto">
                            <div className="pt-3 px-3 plan_div">
                              <h1 className="text_professional">
                                PROFESSIONAL
                              </h1>
                            </div>
                            <div>
                              <div className="ribbon">
                                <span className="ribbon_2"></span>
                                <div className="ribbon2">
                                  <div className="price">
                                    <h2>$99</h2>
                                    <p>/Month</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-5 p-4">
                              <ul>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Lorem ipsum dolor
                                </li>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Sit amet consect
                                </li>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Amet consectetur
                                </li>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Neque sed suspend
                                </li>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Se sed diam mattis
                                </li>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Ac sagittis ac
                                </li>
                                <li>
                                  <RxCross2 className="icon text-danger" />
                                  Sitt isporta vestibulum
                                </li>
                                <li>
                                  <RxCross2 className="icon text-danger" />
                                  eu velit viverra quis vel
                                </li>
                              </ul>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              <button type="" className="signin_btn">
                                <div className="btn_inner professional_btn_inner">
                                  <div>
                                    <span>Sign up</span>
                                    <IoIosArrowForward className="arrow_icon" />
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="px-4 py-5">
                          <div className="plan_outer mx-auto">
                            <div className="pt-3 px-3 plan_div">
                              <h1 className="text_luxury">LUXURY</h1>
                            </div>
                            <div>
                              <div className="ribbon">
                                <span className="ribbon_3"></span>
                                <div className="ribbon3">
                                  <div className="price">
                                    <h2>$199</h2>
                                    <p>/Month</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-5 p-4">
                              <ul>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Lorem ipsum dolor
                                </li>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Sit amet consect
                                </li>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Amet consectetur
                                </li>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Neque sed suspend
                                </li>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Se sed diam mattis
                                </li>
                                <li>
                                  <FcCheckmark className="icon" />
                                  Ac sagittis ac
                                </li>
                                <li>
                                  <AiFillStar className="icon text_yellow" />
                                  Sitt isporta vestibulum
                                </li>
                                <li>
                                  <AiFillStar className="icon text_yellow" />
                                  eu velit viverra quis vel
                                </li>
                              </ul>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              <button type="" className="signin_btn">
                                <div className="btn_inner luxury_btn_inner">
                                  <div>
                                    <span>Sign up</span>
                                    <IoIosArrowForward className="arrow_icon" />
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </Slider>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
