import React from 'react';

import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';


import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import { useTranslation } from "react-i18next";



export default function ProfileNameComponent() {
  const { t, i18n } = useTranslation();


  return (
    <div>


<div className="name-btn">
                DYNA APPOLON
              </div>
              <p>
                CEO | 9 to 5 Escapee
              </p>




    </div>



  );
}
