import React from 'react';
import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import { useTranslation } from "react-i18next";



export default function AboutMeTittleComponent() {
  const { t, i18n } = useTranslation();


  return (
    <div>






    
         

   
         

                    <h4 className="">
                      {t('profile_hero_section.a_litter_abot_me')}
                      <div className="gold-btn mt-3">
                        {t('timeline_form_seven_component.job_title_placeholder')}
                      </div>
                    </h4>

             
      

           


     
         
            






    </div>



  );
}
