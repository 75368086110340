import * as React from 'react';
// import VideoPopup from '../../../global/VideoPopup';
import './StepZeroStyle.scss';
import StepZeroTab from '../../../global/StepZeroTab';
import NicheClarity from '../../profileManagement/tabs/StepZero/NicheClarity';
export default function StepTabOne() {
    return (
        <div className="w-100 d-flex flex-column-reverse">
            <div className='w-100'>
                {/* <VideoPopup /> */}
                <NicheClarity />
                <div className="tab-content d-none" id="myTabContent">
                    <div className="tab-pane fade show active" id="clarity" role="tabpanel" aria-labelledby="clarity">
                    </div>
                    <div className="tab-pane fade" id="marketr" role="tabpanel" aria-labelledby="marketr">
                        
                    </div>
                    <div className="tab-pane text-center detailTab fade" id="strategy" role="tabpanel" aria-labelledby="strategy">
                        
                    </div>
                    <div className="tab-pane text-center detailTab fade" id="brandingr" role="tabpanel" aria-labelledby="brandingr">
                        
                    </div>
                    <div className="tab-pane detailTab fade" id="canvasplaning" role="tabpanel" aria-labelledby="canvasplaning">
                        
                    </div>
                    <div className="tab-pane fade" id="summarytb" role="tabpanel" aria-labelledby="summarytb">
                        
                    </div>
                    <div className="tab-pane fade" id="decktb" role="tabpanel" aria-labelledby="decktb">
                        
                    </div>
                    <div className="tab-pane fade" id="registertb" role="tabpanel" aria-labelledby="registertb">
                        
                    </div>
                </div>
            </div>
            <div className='container privacyTab mb-4 pb-3 mt-5 mt-lg-0'>
                <div id="myTab" className='slidetab' role="tablist">
                    <StepZeroTab />
                </div>
            </div>
        </div>
    );
  }