import React from "react";
import { useState } from "react";

import EventsSlider3 from "../components/EventsSlider3/EventsSlider3"
import Calendar from 'react-calendar'
import EventsTimeline3 from '../components/EventsTimeline3/EventsTimeline3'
import "../regularTheme3Desktop/RegularTheme3Desktop.scss";
import "../regularTheme3Mobile/RegularTheme3Mobile.scss";
import "./RegularTheme3Tab.scss";


export default function RegularTheme3Desktop({ Regular, Neumorphic }) {
  const [value, onChange] = useState(new Date());

  return (
    <>
      <div
        className="main-content-tab eventsTab RegularTheme3Tab"
        style={{ backgroundColor: Regular ? "#cbd5e0" : "" }}
      >

        <div className="text-center-desk">
          <p className="top_heading">MY EVENTS</p>
        </div>

        <div className='container eventTab mb-4 pb-3 mt-5 mt-lg-0'>
          <div id="myTab3" className='slidetab3' role="tablist">
            <EventsSlider3 Regular={Regular} Neumorphic={Neumorphic} />
          </div>
        </div>

        <div className="">
          <Calendar className="mx-auto" onChange={onChange} value={value} />
        </div>
        <div>
          <EventsTimeline3 />
        </div>

      </div>
    </>
  );
}
