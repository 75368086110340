import React from "react";
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import DropdownBox from "../../../global/DropdownBox";
import ButtonBoxNew from "../../../global/ButtonBoxNew";

export default function ShippingTab() {
  const { t, i18n } = useTranslation();

  const theme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    }
  });
  const countryOptions =
    [{
        "name": "AFGHANISTAN",
        
        
      }, {
        "name": "ALAND ISLANDS",
        
        
      }, {
        "name": "ALBANIA",
        
        
      }, {
        "name": "ALGERIA",
        
      
      }, {
        "name": "AMERICAN SAMOA",
        
        
      }, {
        "name": "ANDORRA",
        
        
      }, {
        "name": "ANGOLA",
        
       
      }, {
        "name": "ANGUILLA",
        
         
      }, {
        "name": "ANTARCTICA",
        
        
      }, {
        "name": "ANTIGUA AND BARBUDA",
        
       
      }, {
        "name": "ARGENTINA",
        
       
      }, {
        "name": "ARMENIA",
        
        
      }, {
        "name": "ARUBA",
        
        
      }, {
        "name": "AUSTRALIA",
        
        
      }, {
        "name": "AUSTRIA",
        
        
      }, {
        "name": "AZERBAIJAN",
        
         
      }, {
        "name": "BAHAMAS",
        
         
      }, {
        "name": "BAHRAIN",
        
         
      }, {
        "name": "BANGLADESH",
        
        
      }, {
        "name": "BARBADOS",
        
         
      }, {
        "name": "BELARUS",
        
         
      }, {
        "name": "BELGIUM",
        
        
      }, {
        "name": "BELIZE",
        
         
      }, {
        "name": "BENIN",
        
         
        
      }, {
        "name": "BERMUDA",
        
         
      }, {
        "name": "BHUTAN",
        
         
        
      }, {
        "name": "BHUTAN",
        
        
      }, {
        "name": "BOLIVIA",
        
        
      }, {
        "name": "BONAIRE, SINT EUSTATIUS AND SABA",
        
         
      }, {
        "name": "BOSNIA and HERZEGOVINA",
        
         
      }, {
        "name": "BOTSWANA",
        
        
      }, {
        "name": "BOUVET ISLAND",
        
        
      }, {
        "name": "BRAZIL",
        
         
      }, {
        "name": "BRAZIL",
        
        
      }, {
        "name": "BRITISH INDIAN OCEAN TERRITORY",
        
         
      }, {
        "name": "BRUNEI DARUSSALAM",
        
         
      }, {
        "name": "BULGARIA",
        
         
      }, {
        "name": "BURKINA FASO",
        
         
        
      }, {
        "name": "BURUNDI",
        
         
      }, {
        "name": "CAMBODIA",
        
        
      }, {
        "name": "CAMEROON",
        
         
      }, {
        "name": "CANADA",
        
         
      }, {
        "name": "CAPE VERDE",
        
        
      }, {
        "name": "CAYMAN ISLANDS",
        
         
      }, {
        "name": "CENTRAL AFRICAN REPUBLIC",
        
         
      }, {
        "name": "CHAD",
        
         
      }, {
        "name": "CHILE",
        
         
      }, {
        "name": "CHINA",
        
         
      }, {
        "name": "CHINA",
        
        
      }, {
        "name": "CHRISTMAS ISLAND",
        
         
      }, {
        "name": "COCOS (KEELING) ISLANDS",
        
         
      }, {
        "name": "COLOMBIA",
        
         
      }, {
        "name": "COMOROS",
        
         
      }, {
        "name": "CONGO",
        
         
      }, {
        "name": "COOK ISLANDS",
        
         
      }, {
        "name": "COSTA RICA",
        
        
      }, {
        "name": "COTE D’IVOIRE (IVORY COAST)",
        
         
        
      }, {
        "name": "CROATIA",
        
       
      }, {
        "name": "CURACAO",
        
        
      }, {
        "name": "CYPRUS",
        
        
      }, {
        "name": "CZECH REPUBLIC",
        
         
      }, {
        "name": "DEMOCRATIC REPUBLIC OF THE CONGO",
        
        
      }, {
        "name": "DEN",
        
        
      }, {
        "name": "DJIBOUTI",
        
         
      }, {
        "name": "DOMINICA",
        
         
      }, {
        "name": "DOMINICAN REPUBLIC",
        
         
      }, {
        "name": "EGYPT",
        
         
      }, {
        "name": "EL SALVADOR",
        
         
      }, {
        "name": "ECUADOR",
        
         
      }, {
        "name": "EQUATORIAL GUINEA",
        
         
      }, {
        "name": "ERITREA",
        
         
      }, {
        "name": "ERITREA",
        
         
      }, {
        "name": "ESTONIA",
        
        
      }, {
        "name": "ETHIOPIA",
        
         
      }, {
        "name": "FALKLAND ISLANDS (MALVINAS)",
        
         
      }, {
        "name": "FEDERATED STATES OF MICRONESIA",
        
        
      }, {
        "name": "FIJI",
        
         
      }, {
        "name": "FINLAND",
        
        
      }, {
        "name": "FRANCE",
        
        
      }, {
        "name": "FRANCE, METROPOLITAN",
        
        
      }, {
        "name": "FRENCH GUIANA",
        
        
      }, {
        "name": "FRENCH POLYNESIA",
        
         
      }, {
        "name": "GABON",
        
         
      }, {
        "name": "GAMBIA",
        
        
      }, {
        "name": "GEORGIA",
        
        
      }, {
        "name": "GERMANY",
        
        
      }, {
        "name": "GHANA",
        
        
      }, {
        "name": "GIBRALTAR",
        
         
      }, {
        "name": "GREECE",
        
        
      }, {
        "name": "GREENLAND",
        
        
      }, {
        "name": "GRENADA",
        
         
      }, {
        "name": "GUADELOUPE",
        
        
      }, {
        "name": "GUAM",
        
         
      }, {
        "name": "GUATEMALA",
        
        
      }, {
        "name": "GUINEA-BISSAU",
        
         
        
      }, {
        "name": "GUINEA",
        
         
      }, {
        "name": "GUYANA",
        
         
      }, {
        "name": "HAITI",
        
        
      }, {
        "name": "HEARD and MCDONALD ISLANDS",
        
         
      }, {
        "name": "HOLY SEE (VATICAN CITY STATE)",
       
      }, {
        "name": "HONDURUS",
        
        
      }, {
        "name": "HONG KONG",
        
         
      }, {
        "name": "HUNGARY",
        
        
      }, {
        "name": "ICELAND",
        
        
      }, {
        "name": "INDIA",
        
        
      }, {
        "name": "INDONESIA",
        
        
      }, {
        "name": "IRAQ",
        
         
      }, {
        "name": "ISRAEL",
        
         
      }, {
        "name": "IRELAND",
        
        
      }, {
        "name": "ISLE OF MAN",
        
         
      }, {
        "name": "ITALY",
        
        
      }, {
        "name": "JAMAICA",
        
         
      }, {
        "name": "JAPAN",
        
        
      }, {
        "name": "JERSEY",
        
         
      }, {
        "name": "JORDAN",
        
         
      }, {
        "name": "KAZAKHSTAN",
        
        
      }, {
        "name": "KENYA",
        
         
      }, {
        "name": "KIRIBATI",
        
         
      }, {
        "name": "KOREA, REPUBLIC OF",
        
        
      }, {
        "name": "KUWAIT",
        
         
      }, {
        "name": "KYRGYZSTAN",
        
        
      }, {
        "name": "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
        
        
      }, {
        "name": "LATVIA",
        
        
      }, {
        "name": "LEBANON",
        
         
      }, {
        "name": "LESOTHO",
        
        
      }, {
        "name": "LESOTHO",
        
        
      }, {
        "name": "LIBERIA",
        
         
      }, {
        "name": "LIBYAN ARAB JAMAHIRIYA",
        
         
      }, {
        "name": "LIECHTENSTEIN",
        
         
      }, {
        "name": "LITHUANIA",
        
        
      }, {
        "name": "LUXEMBOURG",
        
        
      }, {
        "name": "MACAO",
        
        
      }, {
        "name": "MACEDONIA",
        
        
      }, {
        "name": "MADAGASCAR",
        
         
      }, {
        "name": "MALAWI",
        
         
      }, {
        "name": "MALAYSIA",
        
         
      }, {
        "name": "MALDIVES",
        
        
      }, {
        "name": "MALI",
        
         
        
      }, {
        "name": "MALTA",
        
        
      }, {
        "name": "MARSHALL ISLANDS",
        
         
      }, {
        "name": "MARTINIQUE",
        
        
      }, {
        "name": "MAURITANIA",
        
        
      }, {
        "name": "MAURITIUS",
        
        
      }, {
        "name": "MAYOTTE",
        
        
      }, {
        "name": "MEXICO",
        
         
      }, {
        "name": "MOLDOVA, REPUBLIC OF",
        
        
      }, {
        "name": "MONACO",
        
        
      }, {
        "name": "MONGOLIA",
        
        
      }, {
        "name": "MONTENEGRO, REPUBLIC OF",
        
        
      }, {
        "name": "MONTSERRAT",
        
         
      }, {
        "name": "MOROCCO",
        
        
      }, {
        "name": "MOZAMBIQUE",
        
         
      }, {
        "name": "MYANMAR",
        
         
      }, {
        "name": "NAMIBIA",
        
         
      }, {
        "name": "NAURU",
        
         
      }, {
        "name": "NEPAL",
        
        
      }, {
        "name": "NETHERLANDS",
        
        
      }, {
        "name": "NETHERLANDS ANTILLES",
        
         
      }, {
        "name": "NEW CALEDONIA",
        
         
      }, {
        "name": "NEW ZEALAND",
        
         
      }, {
        "name": "NICARAGUA",
        
         
      }, {
        "name": "NIGER",
        
         
        
      }, {
        "name": "NIGERIA",
        
        
      }, {
        "name": "NIUE",
        
         
      }, {
        "name": "NORFOLK ISLAND",
        
         
      }, {
        "name": "NORTHERN MARIANA ISLANDS",
        
         
      }, {
        "name": "NORWAY",
        
        
      }, {
        "name": "OMAN",
        
         
      }, {
        "name": "PAKISTAN",
        
        
      }, {
        "name": "PALAU",
        
         
      }, {
        "name": "PANAMA",
        
        
      }, {
        "name": "PAPUA NEW GUINEA",
        
        
      }, {
        "name": "PARAGUAY",
        
        
      }, {
        "name": "PERU",
        
         
      }, {
        "name": "PHILIPPINES",
        
         
      }, {
        "name": "PITCAIRN",
        
         
      }, {
        "name": "POLAND",
        
        
      }, {
        "name": "PORTUGAL",
        
        
      }, {
        "name": "PUERTO RICO",
        
         
      }, {
        "name": "QATAR",
        
        
      }, {
        "name": "REUNION",
        
        
      }, {
        "name": "ROMANIA",
        
        
      }, {
        "name": "RUSSIAN FEDERATION",
        
         
      }, {
        "name": "RWANDA",
        
         
      }, {
        "name": "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
        
       
      }, {
        "name": "SAMOA",
        
        
      }, {
        "name": "SAN MARINO",
        
        
      }, {
        "name": "SAO TOME AND PRINCIPE",
        
        
      }, {
        "name": "SAUDI ARABIA",
        
        
      }, {
        "name": "SENEGAL",
        
         
        
      }, {
        "name": "SERBIA",
        
         
      }, {
        "name": "SEYCHELLES",
        
        
      }, {
        "name": "SIERRA LEONE",
        
        
      }, {
        "name": "SINGAPORE",
        
         
      }, {
        "name": "SINT MAARTEN (DUTCH PART)",
        
        
      }, {
        "name": "SLOVAKIA",
        
        
      }, {
        "name": "SLOVENIA",
        
        
      }, {
        "name": "SOLOMON ISLANDS",
        
         
      }, {
        "name": "SOMALIA",
        
         
      }, {
        "name": "SOUTH AFRICA",
        
        
      }, {
        "name": "SOUTH SUDAN",
        
         
      }, {
        "name": "SPAIN",
        
        
      }, {
        "name": "SRI LANKA",
        
        
      }, {
        "name": "ST. KITTS-NEVIS",
        
         
      }, {
        "name": "ST. LUCIA",
        
         
      }, {
        "name": "ST. PIERRE AND MIQUELON",
        
        
      }, {
        "name": "ST. VINCENT AND THE GRENADINES",
        
         
      }, {
        "name": "SURINAME",
        
         
      }, {
        "name": "SVALBARD AND JAN MAYEN",
        
        
      }, {
        "name": "SWITZERLAND",
        
         
      }, {
        "name": "SWAZILAND",
        
        
      }, {
        "name": "SWEDEN",
        
         
      }, {
        "name": "TAIWAN",
        
         
      }, {
        "name": "TAJIKISTAN",
        
        
      }, {
        "name": "TANZANIA, UNITED REPUBLIC OF",
        
        
      }, {
        "name": "THAILAND",
        
        
      }, {
        "name": "TIMOR-LESTE",
        
         
      }, {
        "name": "TOGO",
        
         
        
      }, {
        "name": "TOKELAU",
        
         
      }, {
        "name": "TONGA",
        
        
      }, {
        "name": "TRINIDAD AND TOBAGO",
        
      }, {
        "name": "TUNISIA",
        
         
      }, {
        "name": "TURKEY",
        
        
      }, {
        "name": "TURKMENISTAN",
        
        
      }, {
        "name": "TURKS and CAICOS ISLANDS",
        
         
      }, {
        "name": "TUVALU",
        
         
      }, {
        "name": "UGANDA",
        
         
      }, {
        "name": "UKRAINE",
        
         
      }, {
        "name": "UNITED ARAB EMIRATES",
        
        
      }, {
        "name": "UNITED KINGDOM",
        
         
      }, {
        "name": "UNITED STATES",
        
         
      }, {
        "name": "URUGUAY",
        
         
      }, {
        "name": "UZBEKISTAN",
        
         
      }, {
        "name": "VANUATU",
        
        
      }, {
        "name": "VENEZUELA",
        
         
      }, {
        "name": "VIETNAM",
        
        
      }, {
        "name": "BRITISH VIRGIN ISLANDS",
        
         
      }, {
        "name": "U.S. VIRGIN ISLANDS",
        
         
      }, {
        "name": "WALLIS AND FUTUNA ISLANDS",
        
         
      }, {
        "name": "WESTERN SAHARA",
        
        
      }, {
        "name": "YEMEN",
        
        
      }, {
        "name": "ZAMBIA",
        
         
      }, {
        "name": "ZIMBABWE",
        
         
      }, {
        "name": "CUBA",
        
         
      }, {
        "name": "ECUADOR",
        
        
      }, {
        "name": "IRAN",
        
        
      }, {
        "name": "LATVIA",
        
         
      }, {
        "name": "LITHUANIA",
        
         
      }, {
        "name": "GUINEA-BISSAU",
        
         
      }, {
        "name": "SYRIA",
        
         
      }, {
        "name": "TONGA",
        
        
      }]
  const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]
      const cityOptions =
      [{
          "name": "chocolate",
          
          
        }, {
          "name": "strawberry",
          
          
        }, {
          "name": "vanilla",
          
          
        }] 

  return (
    <>
      <div className="shippingTab p-0 shadow-none w-100 text-start d-block br-20 position-relative mx-auto">
        <form>
          <div className="row">
            <div className="col-md-6 pe-4">
              <label className="mb-2">{t('profile_section.suite_appt_unit_number')}</label>
              <input
                placeholder={t('profile_section._4B')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
            </div>
            <div className="col-md-6">
              <div className="billingTab bg br-10">
                <label className="mb-2">{t('profile_section.street_house_number')}</label>
                <input
                  placeholder={t('profile_section._123456')}
                  className="shadow-5in br-10 w-100 mb-3"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="mb-2">{t('profile_section.street_name')}</label>
              <input
                placeholder={t('profile_section.carling_avenue')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
            </div>
            <div className="col-md-6">
            <div className=" position-relative">
                <label className="mb-2">{t('profile_section.city_name')}</label>
                {/* <Select isSearchable={false} options={options} className="country mb-4 w-100 mx-auto" placeholder={t('profile_section.toronto')} theme={theme}
                    styles={{
                        option: (base) => ({
                          ...base,
                          borderRadius: '30px',
                          width:'94%',
                          height:'50px',
                          lineHeight: '32px',
                          margin: '10px',
                          cursor: 'pointer',
                          color:'#000000',
                          boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                          
                        }),
                      }} /> */}
                       {/* <DropdownBox name="City Name" boxid="city" options={cityOptions} boxwidth="country mb-4 w-100 mx-auto" /> */}
                       <input
                          placeholder="City Name"
                          className="shadow-5in br-10 w-100 mb-4"
                          type="text"
                        />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 px-1">
              <div className=" position-relative">
                <label className="mb-2">{t('profile_section.country')}</label>
                {/* <Select isSearchable={false} options={options} className="country mb-4 w-100 mx-auto" placeholder='United States' theme={theme}
                    styles={{
                        option: (base) => ({
                          ...base,
                          borderRadius: '30px',
                          width:'94%',
                          height:'50px',
                          lineHeight: '32px',
                          margin: '10px',
                          cursor: 'pointer',
                          color:'#000000',
                          boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                          
                        }),
                      }} /> */}
                      <DropdownBox name="Country" boxid="country" options={countryOptions} boxwidth="country mb-4 w-200 mx-auto" />
              </div>
            </div>
            <div className="col-md-4 px-1">
              <div className=" position-relative">
                <label className="mb-2">{t('profile_section.states')}</label>
                {/* <Select isSearchable={false} options={options} className="country mb-4 w-100 mx-auto" placeholder='California' theme={theme}
                    styles={{
                        option: (base) => ({
                          ...base,
                          borderRadius: '30px',
                          width:'94%',
                          height:'50px',
                          lineHeight: '32px',
                          margin: '10px',
                          cursor: 'pointer',
                          color:'#000000',
                          boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                          
                        }),
                      }} /> */}
                      <DropdownBox name="States" boxid="states" options={cityOptions} boxwidth="country mb-4 w-200 mx-auto" />
              </div>
            </div>
            <div className="col-md-4">
              <label className="mb-2">{t('profile_section.zip_code')}</label>
              <input
                placeholder='63235'
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
            </div>
            
          </div>
          <div className="row">
            <div className="col-md-4 px-1">
            <div className=" position-relative">
                <label className="mb-2">{t('profile_section.country')}</label>
                {/* <Select isSearchable={false} options={options} className="country mb-4 w-100 mx-auto" placeholder='Canada' theme={theme}
                    styles={{
                        option: (base) => ({
                          ...base,
                          borderRadius: '30px',
                          width:'94%',
                          height:'50px',
                          lineHeight: '32px',
                          margin: '10px',
                          cursor: 'pointer',
                          color:'#000000',
                          boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                          
                        }),.shippingTab
                      }} /> */}
                       <DropdownBox name="Country" boxid="country" options={countryOptions} boxwidth="country mb-4 w-200 mx-auto" />
              </div>
            </div>
            <div className="col-md-4 px-1">
              <div className=" position-relative">
                <label className="mb-2">{t('profile_section.province')}</label>
                {/* <Select isSearchable={false} options={options} className="country mb-4 w-100 mx-auto" placeholder='Ontario' theme={theme}
                    styles={{
                        option: (base) => ({
                          ...base,
                          borderRadius: '30px',
                          width:'94%',
                          height:'50px',
                          lineHeight: '32px',
                          margin: '10px',
                          cursor: 'pointer',
                          color:'#000000',
                          boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                          
                        }),
                      }} /> */}
                      <DropdownBox name="Province" boxid="province" options={cityOptions} boxwidth="country mb-4 w-200 mx-auto" />
              </div>
            </div>
            <div className="col-md-4">
              <label className="mb-2">{t('profile_section.postal_code')}</label>
              <input
                placeholder='K1P 2Y9'
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
            </div>
          </div>
          <div className="mt-4 text-center">
          {/* <button className="h-50 bs-success br-30 w-110 h8 text-center border-0 shadow-4">
          {t('done')}
            </button> */}
            <ButtonBoxNew name="Done" boxwidth="h-50 br-30 w-125 py-0 bg mx-auto text-center border-0 shadow-4" />
          </div>
        </form>
      </div>
    </>
  );
}
