import React from "react";
import '../../../../login/login.scss';
import Email from '../../../../auth/verification/EmailVerify';
import SMS from '../../../../auth/verification/SmsVerify';
import FingerPrint from '../../../../auth/verification/FingerpPrintVerify';
import FaceID from '../../../../auth/verification/FaceIDVerify';
import { NavItem } from 'react-bootstrap';

export default function ChangeTwo() {


  return (
    <div className="text-center">
      <h4 className="mb-4">Select a method to change your Password</h4>
      <h6>Verification Via</h6>
      <div className='d-flex flex-column-reverse'>
              <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="email2" role="tabpanel" aria-labelledby="email2">
                      <Email />
                  </div>
                  <div className="tab-pane fade" id="sms2" role="tabpanel" aria-labelledby="sms2">
                      <SMS />
                  </div>
                  <div className="tab-pane fade" id="fingerprint2" role="tabpanel" aria-labelledby="fingerprint2">
                      <FingerPrint />
                  </div>
                  <div className="tab-pane fade" id="faceid2" role="tabpanel" aria-labelledby="faceid2">
                      <FaceID />
                  </div>
              </div>
              <div className='w-540 mx-auto'>
                  <div className='d-none d-lg-block'>
                      <div className='scroll-bar w-100 shadow-4in br-20 my-30'></div>
                  </div>
                  <div className='login-tab  mb-4 pb-3 mt-5 mt-lg-0'>
                      <ul className="nav" id="myTab" role="tablist">
                          <NavItem as="li" className='pe-3'>
                              <div className='btn-layer br-30 bg'>
                                  <button className="br-20 w-140 active h-90 border-0 d-flex justify-content-center align-items-center" id="email2" data-bs-toggle="tab" data-bs-target="#email2" type="button" role="tab" aria-controls="email2" aria-selected="true">
                                      Email
                                  </button>
                              </div>
                          </NavItem>
                          <NavItem as="li" className="pe-3">
                              <div className='btn-layer br-30 bg'>
                                  <button className="br-20 w-140 h-90 border-0" id="sms" data-bs-toggle="tab" data-bs-target="#sms2" type="button" role="tab" aria-controls="sms2" aria-selected="false">
                                      SMS
                                  </button>
                              </div>
                          </NavItem>
                          <NavItem as="li" className="pe-3">
                              <div className='btn-layer br-30 bg'>
                                  <button className="br-20 w-140 h-90 border-0" id="fingerprint2" data-bs-toggle="tab" data-bs-target="#fingerprint2" type="button" role="tab" aria-controls="fingerprint2" aria-selected="false">
                                      Fingerprint
                                  </button>
                              </div>
                          </NavItem>
                          <NavItem as="li" className="pe-0">
                              <div className='btn-layer br-30 bg'>
                                  <button className="br-20 w-140 h-90 border-0" id="faceid2" data-bs-toggle="tab" data-bs-target="#faceid2" type="button" role="tab" aria-controls="faceid2" aria-selected="false">
                                      Face ID
                                  </button>
                              </div>
                          </NavItem>
                      </ul>
                  </div>
              </div>
      </div>
    </div>
  );
}
