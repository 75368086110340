
const initialState = {
    isVideoVisible: false, // Video initially hidden
    isLeftSidebarVisible: true, // Initially visible
    isRightSidebarVisible: true, 
    isSubHeaderVisible: true, 
  };

const videoReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_VIDEO':
          return {
            ...state,
            isVideoVisible: true,
            isLeftSidebarVisible: false,
            isRightSidebarVisible: false,
            isSubHeaderVisible: false,
          };
        case 'CLOSE_VIDEO':
          return {
            ...state,
            isVideoVisible: false,
            isLeftSidebarVisible: true,
            isRightSidebarVisible: true,
            isSubHeaderVisible: true,
          };
          case 'CLOSE_SUB_HEADER':
            if(state.isSubHeaderVisible){
              return  {
                ...state,
                isSubHeaderVisible: false,
              };
            }else{
              return  {
                ...state,
                isSubHeaderVisible: true,
              };
            }
            case 'CLOSE_LEFT_BAR':
              if(state.isLeftSidebarVisible){
                return  {
                  ...state,
                  isLeftSidebarVisible: false,
                };
              }else{
                return  {
                  ...state,
                  isLeftSidebarVisible: true,
                };
              }
              case 'CLOSE_RIGHT_BAR':
                if(state.isRightSidebarVisible){
                  return  {
                    ...state,
                    isRightSidebarVisible: false,
                  };
                }else{
                  return  {
                    ...state,
                    isRightSidebarVisible: true,
                  };
                }
                case 'SOCIAL_BANNER_VIDEO_OPEN':
                  return {
                    ...state,
                    isLeftSidebarVisible: false,
                    isRightSidebarVisible: false,
                  };
        default:
          return state;
      }
  };
 export default videoReducer;
  