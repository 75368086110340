import React from "react";
import ButtonBox from "../../../global/ButtonBox";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import './WebsiteDesignStyleStep.scss';
import { NavItem } from 'react-bootstrap';
import { BsFillPatchCheckFill } from "react-icons/bs"
import Image2 from '../../../../images/glassmorphic.png';
import Neumorphic from '../../../../images/neumorphic.png';
import Image3 from '../../../../images/regular.png';

export default function WebsiteDesignStyleForm() {

    const [regular,setRegular] = useState('');
    const [neumorphic,setNeumorphic] = useState('');
    const [glassmorphic,setGlassmorphic] = useState('');

    const { t } = useTranslation();
;

    return (
        <div>
            <ul className="TAB-8-form">
                <NavItem as="li" className="my-5 pb-4">
                    <div className="col-md-6 mx-auto pb-5">
                        <h2 className="heading mb-5">{t('timeline_form_website_design_component.your_website_says_a_lot_about_you')}</h2>
                    </div>

<div className="row pt-5" onClick={() => {setNeumorphic('');setRegular('green-chk');setGlassmorphic('');}}>
                        <div className="col-md-12 mb-5 text-center relative">
                            <div className="check_box d-flex align-items-center regular_mod neumorphic">
                                <input type="radio" className="btn_check" name="mode" />
                                <label className="btn bg" htmlFor="light-mode">
                                    <BsFillPatchCheckFill className={`icons-set ${regular}`  } />
                                </label>
                            </div>
                            <img src={Neumorphic} alt="" className="mx-auto w-100" />
                        </div>
                    </div>

                    <div className="row pt-5" onClick={() => {setNeumorphic('green-chk');setRegular('');setGlassmorphic('');}}>
                        <div className="col-md-12 mb-5 text-center relative">
                            <div className="check_box d-flex align-items-center regular_mod glassmorphic">
                                <input type="radio" className="btn_check" name="mode" />
                                <label className="btn bg" htmlFor="light-mode">
                                    <BsFillPatchCheckFill className={`icons-set ${neumorphic}`  } />
                                </label>
                            </div>
                            <img src={Image2} alt="" className="mx-auto w-100" />
                        </div>
                    </div>
                    <div className="row pt-3" onClick={() => {setNeumorphic('');setRegular('');setGlassmorphic('green-chk');}}>
                        <div className="col-md-12 text-center relative">
                            <div className="check_box d-flex align-items-center regular_mod regular-style">
                                <input type="radio" className="btn_check"  name="mode" />
                                <label className="btn bg" htmlFor="light-mode">
                                    <BsFillPatchCheckFill className={`icons-set ${glassmorphic}`} />
                                </label>
                            </div>
                            <img src={Image3} alt="" className="mx-auto w-100" />
                        </div>
                    </div>
                    <div className=" text-center time-btn mt-5">
                        <ButtonBox
                            boxwidth="w-150 mt-0 mb-50 mx-auto text-white"
                            clicked="Save your style"
                            name="Save your style"
                        />
                    </div>
                </NavItem>
            </ul>
        </div >
    );
}
