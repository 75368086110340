import React, { useState, useEffect } from 'react';

import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { useTranslation } from "react-i18next";
export default function HeroSectionTextComponent() {
  const { t, i18n } = useTranslation();
  return (
  <div>
          <div className="hero-part">
                    <h1>
                      {t('profile_hero_section.hi')}! {t('profile_hero_section.i_am')}
                      <br />
                      {t('profile_section.dyna_appolon')}

                    </h1>
                    <p className="mt-4">
                      {t('timeline_form_seven_component.job_title_placeholder')}
                    </p>
                  </div>
  </div>


  );
}
