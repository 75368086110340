import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaBars, FaFacebookF, FaTwitter, FaLinkedinIn, FaDribbble, FaPinterest, FaYoutube } from "react-icons/fa";
import '../../BlogPostSectionThemes.scss'
import '../regularTheme2Desktop/RegularTheme2Desktop.scss';
import '../regularTheme2Tab/RegularTheme2Tab.scss'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import blogpostdesktop_2 from "../../../../../../../src/images/desktopblogpost/desktop2blogpost.png"
import blogpost2desktop_2 from "../../../../../../../src/images/desktopblogpost/desktop2blogpost2.png"
import blogpost2tab_3 from "../../../../../../../src/images/desktopblogpost/blogpost2desktop3.png"
import blogpost2tab_4 from "../../../../../../../src/images/desktopblogpost/blogpost2desktop4.png"
import { IoIosArrowForward } from "react-icons/io";
import { FcCheckmark } from "react-icons/fc"
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai"
import { BsCheckCircleFill } from "react-icons/bs";

import { useTranslation } from "react-i18next";
export default function RegularTheme1Desktop() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="tab-maincontent p-5">
        <div className="row">
          <div className="col-md-8 col-8 px-0">
            <div className="bg-whoweare d-flex justify-content-center flex-column py-5 h-100">
              <div className="whoweare-content-1">
                <h5 className="whoweare">Who are we?</h5>
              </div>
              <div className="whoweare-paragraph-1">
                <p className="paragraph-1 mb-0 mt-2">
                  Lorem ipsum dolor sit amet consectetur. Elit nunc faucibus sit pellentesque aliquet condimentum risus cursus vivamus. Id in quis amet urna enim sed nunc ut. Nunc scelerisque arcu dictum sem rhoncus duis. Lacus vulputate tempus cursus elit quam gravida lectus at. Nec mauris sed ac arcu ullamcorper sem nulla accumsan. Mauris iaculis etiam at molestie lobortis a pulvinar eget. Fames venenatis euismod tincidunt sapien. Senectus sit morbi arcu egestas ac id pellentesque. Et consectetur orci hac volutpat. Volutpat viverra fames imperdiet quis rhoncus nunc aliquam. Consectet.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-4 px-0">
            <div className="blogpostdesktop_2 d-flex h-100">
              <img src={blogpost2tab_3} alt="blogpostdesktop_2" className="img-fluid w-100"></img>
            </div>
          </div>


            <div className="col-md-8 col-8 px-0">
              <div className="blogpost2desktop_2 h-100 d-flex">
                <img src={blogpost2tab_4} alt="blogpost2desktop_2" className="img-fluid w-100"></img>

                {/* <video src={blogpost2desktop_2} alt="blogpost2desktop_2" className="img-fluid w-100"></video> */}
              </div>
            </div>
            <div className="col-md-4 col-4 px-0">
              <div className="bg-whoweare2 d-flex justify-content-center flex-column h-100 py-5">
                <div className="whoweare-content-2">
                  <h5 className="whoweare2">Who are we?</h5>
                </div>
                <div className="whoweare-paragraph-2">
                  <p className="paragraph-2 mb-0 mt-2 pb-3">
                    Lorem ipsum dolor sit amet consectetur. Elit nunc faucibus sit pellentesque aliquet condimentum risus cursus vivamus. Id in quis amet urna enim sed nunc ut. Nunc scelerisque arcu dictum sem rhoncus duis.
                  </p>
                </div>
                <ul className="whoweare-paragraph-list">
                  <li className="list-items mt-3"><span className="me-3"><BsCheckCircleFill className="checkicon" /></span>Lacus vulputate tempus</li>
                  <li className="list-items mt-3"><span className="me-3"><BsCheckCircleFill className="checkicon" /></span>Cursus elit quam</li>
                  <li className="list-items mt-3"><span className="me-3"><BsCheckCircleFill className="checkicon" /></span>Gravida lectus at</li>
                  <li className="list-items mt-3"><span className="me-3"><BsCheckCircleFill className="checkicon" /></span>Nec mauris sed</li>
                  <li className="list-items mt-3"><span className="me-3"><BsCheckCircleFill className="checkicon" /></span>Ac arcu ullamcorper</li>
                </ul>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}
