import React, { useState } from "react";
import desktop_2_1 from "../../../../images/servicepackageplan/Desktop2-1.png"
import tab_2_1 from "../../../../images/servicepackageplan/Tab2-1.png"
import mobile_2_1 from "../../../../images/servicepackageplan/Mobile2-1.png"
import desktop_2_2 from "../../../../images/servicepackageplan/Desktop2-2.png"
import tab_2_2 from "../../../../images/servicepackageplan/Tab2-2.png"
import mobile_2_2 from "../../../../images/servicepackageplan/Mobile2-2.png"
import desktop_2_3 from "../../../../images/servicepackageplan/Desktop2-3.png"
import tab_2_3 from "../../../../images/servicepackageplan/Tab2-3.png"
import mobile_2_3 from "../../../../images/servicepackageplan/Mobile2-3.png"
import desktop_2_4 from "../../../../images/servicepackageplan/Desktop2-4.png"
import tab_2_4 from "../../../../images/servicepackageplan/Tab2-4.png"
import mobile_2_4 from "../../../../images/servicepackageplan/Mobile2-4.png"
import desktop_2_5 from "../../../../images/servicepackageplan/Desktop2-5.png"
import tab_2_5 from "../../../../images/servicepackageplan/Tab2-5.png"
import mobile_2_5 from "../../../../images/servicepackageplan/Mobile2-5.png"
import desktop_2_6 from "../../../../images/servicepackageplan/Desktop2-6.png"
import tab_2_6 from "../../../../images/servicepackageplan/Tab2-6.png"
import mobile_2_6 from "../../../../images/servicepackageplan/Mobile2-6.png"
import ButtonBoxNew from "../../../global/ButtonBoxNew";

import '././ServicePackagePlanThemes.scss';
import Modal from 'react-bootstrap/Modal';
import ServicePackagePlanNeumorphicThemeDesktopView1 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeDesktopView1"
import ServicePackagePlanNeumorphicThemeDesktopView2 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeDesktopView2"
import ServicePackagePlanNeumorphicThemeDesktopView3 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeDesktopView3"
import ServicePackagePlanNeumorphicThemeDesktopView4 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeDesktopView4"
import ServicePackagePlanNeumorphicThemeDesktopView5 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeDesktopView5"
import ServicePackagePlanNeumorphicThemeDesktopView6 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeDesktopView6"
import ServicePackagePlanNeumorphicThemeTabView1 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeTabView1"
import ServicePackagePlanNeumorphicThemeTabView2 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeTabView2"
import ServicePackagePlanNeumorphicThemeTabView3 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeTabView3"
import ServicePackagePlanNeumorphicThemeTabView4 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeTabView4"
import ServicePackagePlanNeumorphicThemeTabView5 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeTabView5"
import ServicePackagePlanNeumorphicThemeTabView6 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeTabView6"
import ServicePackagePlanNeumorphicThemeMobileView1 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeMobileView1"
import ServicePackagePlanNeumorphicThemeMobileView2 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeMobileView2"
import ServicePackagePlanNeumorphicThemeMobileView3 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeMobileView3"
import ServicePackagePlanNeumorphicThemeMobileView4 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeMobileView4"
import ServicePackagePlanNeumorphicThemeMobileView5 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeMobileView5"
import ServicePackagePlanNeumorphicThemeMobileView6 from "./servicePackagePlanNeumorphicThemes/ServicePackagePlanNeumorphicThemeMobileView6"
import { useTranslation } from "react-i18next";

export default function ServicePackagePlanNeumorphicTheme() {
  const { t, i18n } = useTranslation();

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [themedesktopShow, setThemedesktopShow] = useState(false);
  const [themetabShow, setThemetabShow] = useState(false);
  const [themedesktop3Show, setThemedesktop3Show] = useState(false);
  const [themedesktop4Show, setThemedesktop4Show] = useState(false);
  const [themedesktop5Show, setThemedesktop5Show] = useState(false);
  const [themedesktop6Show, setThemedesktop6Show] = useState(false);
  const [themetab3Show, setThemetab3Show] = useState(false);
  const [themetab4Show, setThemetab4Show] = useState(false);
  const [themetab5Show, setThemetab5Show] = useState(false);
  const [themetab6Show, setThemetab6Show] = useState(false);
  const [thememobileShow, setThememobileShow] = useState(false);
  const [thememobile3Show, setThememobile3Show] = useState(false);
  const [thememobile4Show, setThememobile4Show] = useState(false);
  const [thememobile5Show, setThememobile5Show] = useState(false);
  const [thememobile6Show, setThememobile6Show] = useState(false);




  return (
    <div className="Neumorphic-design">

      <h4 className="text-center">
        Neumorphic  Design Version 1
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg newimg1s">
            <img src={desktop_2_1} alt='desktop-2-1' ></img>
          </div>
          {/* <button className="screen-btn" typeof="button" data-bs-toggle="modal" data-bs-target="#staticBackdropNem">
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" typeof="button" data-bs-toggle="modal" data-bs-target="#staticBackdropNem"/>

          <div className="modal fade" id="staticBackdropNem" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropNemLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen" >
              <div className="modal-content">
                <div className="modal-header ">
                  <button className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="">
                    <ServicePackagePlanNeumorphicThemeDesktopView1 />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2_1} alt="tab_2_1"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setSmShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeTabView1 />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={mobile_2_1} alt="mobile_2_1"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeMobileView1 />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Neumorphic  Design Version 2
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2_2} alt='desktop-2-2' >
            </img>
          </div>
          {/* <button className="screen-btn" onClick={() => setThemedesktopShow(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setThemedesktopShow(true)}/>

          <Modal
            show={themedesktopShow}
            onHide={() => setThemedesktopShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeDesktopView2 />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2_2} alt='tab-2-2'></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetabShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setThemetabShow(true)}/>

          <Modal
            show={themetabShow}
            onHide={() => setThemetabShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeTabView2 />

            </Modal.Body>
          </Modal>
        </div>


        <div className="">
          <div className="screen-bg">
            <img src={mobile_2_2} alt='mobile-2-2' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobileShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setThememobileShow(true)}/>

          <Modal
            show={thememobileShow}
            onHide={() => setThememobileShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeMobileView2 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Neumorphic  Design Version 3
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2_3} alt='desktop-2-3'>
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setThemedesktop3Show(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setThemedesktop3Show(true)}/>

          <Modal
            show={themedesktop3Show}
            onHide={() => setThemedesktop3Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeDesktopView3 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2_3} alt='tab-2-3' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetab3Show(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setThemetab3Show(true)}/>

          <Modal
            show={themetab3Show}
            onHide={() => setThemetab3Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeTabView3 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2_3} alt='mobile-2-3' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobile3Show(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThememobile3Show(true)}/>

          <Modal
            show={thememobile3Show}
            onHide={() => setThememobile3Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeMobileView3 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Neumorphic  Design Version 4
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2_4} alt='desktop-2-4'>
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setThemedesktop4Show(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThemedesktop4Show(true)}/>

          <Modal
            show={themedesktop4Show}
            onHide={() => setThemedesktop4Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeDesktopView4 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2_4} alt='tab-2-4' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetab4Show(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThemetab4Show(true)}/>

          <Modal
            show={themetab4Show}
            onHide={() => setThemetab4Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeTabView4 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2_4} alt='mobile-2-4' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobile4Show(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThememobile4Show(true)}/>

          <Modal
            show={thememobile4Show}
            onHide={() => setThememobile4Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeMobileView4 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Neumorphic  Design Version 5
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2_5} alt='desktop-2-5'>
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setThemedesktop5Show(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setThemedesktop5Show(true)}/>

          <Modal
            show={themedesktop5Show}
            onHide={() => setThemedesktop5Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeDesktopView5 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2_5} alt='tab-2-5' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetab5Show(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThemetab5Show(true)}/>

          <Modal
            show={themetab5Show}
            onHide={() => setThemetab5Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeTabView5 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2_5} alt='mobile-2-5' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobile5Show(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThememobile5Show(true)}/>

          <Modal
            show={thememobile5Show}
            onHide={() => setThememobile5Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeMobileView5 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Neumorphic  Design Version 6
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2_6} alt='desktop-2-6'>
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setThemedesktop6Show(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setThemedesktop6Show(true)}/>

          <Modal
            show={themedesktop6Show}
            onHide={() => setThemedesktop6Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeDesktopView6 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2_6} alt='tab-2-6' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetab6Show(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setThemetab6Show(true)}/>

          <Modal
            show={themetab6Show}
            onHide={() => setThemetab6Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeTabView6 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2_6} alt='mobile-2-6' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobile6Show(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setThememobile6Show(true)}/>

          <Modal
            show={thememobile6Show}
            onHide={() => setThememobile6Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanNeumorphicThemeMobileView6 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
