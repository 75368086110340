import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function ProfileManagementSlider() {
  const responsive = {
    desktop: {
    breakpoint: { max: 3000, min: 1366 },
    items: 6,
    slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
    breakpoint: { max: 1366, min: 768 },
    items: 5,
    slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
    }
};
  return (
    <Carousel responsive={responsive} autoPlay={false}  autoPlaySpeed={2000} transitionDuration={500}>
       <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 active" id="profileInfo" data-bs-toggle="tab" data-bs-target="#profileInfo" type="button" role="tab" aria-controls="profileInfo" aria-selected="true">Banner | Photo | Member Verification</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="basicInfo" data-bs-toggle="tab" data-bs-target="#basicInfo" type="button" role="tab" aria-controls="basicInfo" aria-selected="true">Business Info | Ways of Communication</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="detail" data-bs-toggle="tab" data-bs-target="#detail" type="button" role="tab" aria-controls="detail" aria-selected="false">Billing | Shipping Addresses</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="personalInfo" data-bs-toggle="tab" data-bs-target="#personalInfo" type="button" role="tab" aria-controls="shipping" aria-selected="false">Personal Info | Zodiac | Personality | Values</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="employementInfo" data-bs-toggle="tab" data-bs-target="#employementInfo" type="button" role="tab" aria-controls="employementInfo" aria-selected="false">Employment Info Status & Income</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0 react-toastify" id="tab5" data-bs-toggle="tab" data-bs-target="#tab5" type="button" role="tab" aria-controls="tab5" aria-selected="true">Education Level & Status | Skills | Hobbies | Languages</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="tab6" data-bs-toggle="tab" data-bs-target="#tab6" type="button" role="tab" aria-controls="tab6" aria-selected="false">List of Favourites</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="tab7" data-bs-toggle="tab" data-bs-target="#tab7" type="button" role="tab" aria-controls="tab7" aria-selected="false">Accomplishments</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="tab8" data-bs-toggle="tab" data-bs-target="#tab8" type="button" role="tab" aria-controls="tab8" aria-selected="false">Integration | Analytics</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="tab9" data-bs-toggle="tab" data-bs-target="#tab9" type="button" role="tab" aria-controls="tab9" aria-selected="false">Social Media | Banners | Bio | Handles</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="tab10" data-bs-toggle="tab" data-bs-target="#tab10" type="button" role="tab" aria-controls="tab10" aria-selected="false">Change Passwords</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="tab11" data-bs-toggle="tab" data-bs-target="#tab11" type="button" role="tab" aria-controls="tab11" aria-selected="false">Profile Show | Hide</button>
        </div>
        <div className='p-2'>
          <button className="br-40 w-100 h-70 border-0" id="tab12" data-bs-toggle="tab" data-bs-target="#tab12" type="button" role="tab" aria-controls="tab12" aria-selected="false">Preview Profile</button>
        </div>

    </Carousel>
  )
}