import React, { useState } from "react";
import img1 from '../../../../../../images/StepZero/img31.png';
import img2 from '../../../../../../images/StepZero/img32.png';
import img1s from '../../../../../../images/triangle1.png';
import GoogleMapReact from 'google-map-react';
import img2s from '../../../../../../images/close1.png';
import Video from '../../../../../../images/iconVid.png';
import Video1 from '../../../../../../images/iconVid1.png';
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../../../redux/actions/videoAction'; 
export default function NicheThree() {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };
  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const handleClick1 = () => {
    setToggle1(!toggle1);
  };
  const [toggle1, setToggle1] = useState("true");
  const [address, setAddress] = useState("");
  const [selectAddress, setSelectAddress] = useState("");

  let { isVideoVisible } = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };

  return (
    <div className="px-3">
      <div className="row justify-content-center mt-4 mx-0 formleft1 px-3">
        <div className="col-lg-6 announcment newinput pt-5">
          <div className=" d-flex mb-28 nav-item2">
            <label className="label1">SEARCH NEAREST OFFICE ADDRESS</label>
            <div className="w-75 z1">
              <span className="inputspan">
                <input className="announcment shadow-5in w-100" type="text" placeholder="TYPE ZIPCOODE OR POSTAL CODE" value={address} />
              </span>
            </div>
          </div>
          <div className="w-100 pt-3 checkboxevent">
            <div className="mb-4 d-flex lidiv shadow-4">
              <div className="w-66">
                4.1 <br />KM
              </div>
              <div className="w-100 d-flex justify-content-between align-items-center ps-3">
                <div className="text-start">
                  <h5>2482 Yonge St</h5>
                  <h5><b>Suite: 1234</b></h5>
                  <h5>Toronto, Ontario</h5>
                  <h5><b>Canada</b></h5>
                </div>
                <div>
                  <h5>M4P 2H5</h5>
                </div>
              </div>
              <div className="liDivLast">
                <h6>Toronto - Yonge St</h6>
                <div className='check-button w-100 mb-2 d-flex justify-content-center align-items-center'>
                  <input type="radio" className="btn-check" name="checkrems" id="remember5s" />
                  <label className="btn rounded-circle btnRadio large mt-2 me-0" htmlFor="remember5s"><div className="radioInner"><span></span></div></label>
                </div>
              </div>
            </div>
            <div className="mb-4 d-flex lidiv shadow-4">
              <div className="w-66">
                4.7 <br />KM
              </div>
              <div className="w-100 d-flex justify-content-between align-items-center ps-3">
                <div className="text-start">
                  <h5>2482 Yonge St</h5>
                  <h5><b>Suite: 1234</b></h5>
                  <h5>Toronto, Ontario</h5>
                  <h5><b>Canada</b></h5>
                </div>
                <div>
                  <h5>M4P 2H5</h5>
                </div>
              </div>
              <div className="liDivLast">
                <h6>Toronto - Yonge St</h6>
                <div className='check-button w-100 mb-2 d-flex justify-content-center align-items-center'>
                  <input type="radio" className="btn-check" name="checkrems" id="remember6s" />
                  <label className="btn rounded-circle btnRadio large mt-2 me-0" htmlFor="remember6s"><div className="radioInner"><span></span></div></label>
                </div>
              </div>
            </div>
            <div className="mb-4 d-flex lidiv shadow-4">
              <div className="w-66">
                4.8 <br />KM
              </div>
              <div className="w-100 d-flex justify-content-between align-items-center ps-3">
                <div className="text-start">
                  <h5>2482 Yonge St</h5>
                  <h5><b>Suite: 1234</b></h5>
                  <h5>Toronto, Ontario</h5>
                  <h5><b>Canada</b></h5>
                </div>
                <div>
                  <h5>M4P 2H5</h5>
                </div>
              </div>
              <div className="liDivLast">
                <h6>Toronto - Yonge St</h6>
                <div className='check-button w-100 mb-2 d-flex justify-content-center align-items-center'>
                  <input type="radio" className="btn-check" name="checkrems" id="remember7s" />
                  <label className="btn rounded-circle btnRadio large mt-2 me-0" htmlFor="remember7s"><div className="radioInner"><span></span></div></label>
                </div>
              </div>
            </div>
            <div className="mb-0 d-flex lidiv shadow-4">
              <div className="w-66">
                4.9 <br />KM
              </div>
              <div className="w-100 d-flex justify-content-between align-items-center ps-3">
                <div className="text-start">
                  <h5>2482 Yonge St</h5>
                  <h5><b>Suite: 1234</b></h5>
                  <h5>Toronto, Ontario</h5>
                  <h5><b>Canada</b></h5>
                </div>
                <div>
                  <h5>M4P 2H5</h5>
                </div>
              </div>
              <div className="liDivLast">
                <h6>Toronto - Yonge St</h6>
                <div className='check-button w-100 mb-2 d-flex justify-content-center align-items-center'>
                  <input type="radio" className="btn-check" name="checkrems" id="remember8s" />
                  <label className="btn rounded-circle btnRadio large mt-2 me-0" htmlFor="remember8s"><div className="radioInner"><span></span></div></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 pe-0 d-flex flex-column">
          <img src={img1} alt="" width="218px" height="182px" className="ms-auto" />
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img src={img2} alt="" width="62px" height="67px" />
            <div className='check-button checkboxevent w-100 mb-2 d-flex justify-content-center align-items-center textlabel'>
              <input type="checkbox" className="btn-check" name="remember23" id="remember23" />
              <label className="btn rounded-circle btnRadio large me-3" htmlFor="remember23"><div className="radioInner"><span></span></div></label>
              Toronto - Yonge St
            </div>
            <div style={{ height: '331px', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}>
                <AnyReactComponent
                  lat={59.955413}
                  lng={30.337844}
                  text="My Marker"
                />
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
      <span className="cursor-pointer" onClick={handleClick1}><img src={img1s} alt="" width="40px" height="20px" /></span>
      {toggle1 ? (
        <></>
      ) : (
      <div className="w-100 d-flex flex-column-reverse mt-5">
        <div className='w-100'>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="manage2" role="tabpanel" aria-labelledby="manage2">
              <div className="row justify-content-center mt-4 mx-0 formleft1 px-3 factorTab1s position-relative">
                <div className="text-end d-flex">
                  <div className="VideoIcon ms-auto" onClick={handleClick6}>
                    <img src={Video} className="lightImg" width="130px" height="80px" alt="" />
                    <img src={Video1} className="darkImg" width="130px" height="80px" alt="" />
                  </div>
                  <img src={img2s} alt="" width="40px" height="52px" className="mt-87 cursor-pointer me-3 position-absolute end-0 top-0"  onClick={handleClick1}/>
                </div>
                <div className="col-lg-6 announcment newinput pt-5">
                  <div className=" d-flex mb-28 nav-item2">
                    <label className="label1">SEARCH NEAREST OFFICE ADDRESS</label>
                    <div className="w-75 z1">
                      <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder="TYPE ZIPCOODE OR POSTAL CODE" value={address} onChange={(e) => setAddress(e.target.value)} />
                      </span>
                    </div>
                  </div>
                  <div className="w-100 pt-3">
                    <div className="mb-4 d-flex lidiv shadow-4">
                      <div className="w-66">
                        4.1 <br />KM
                      </div>
                      <div className="w-100 d-flex justify-content-between align-items-center ps-3">
                        <div className="text-start">
                          <h5>2482 Yonge St</h5>
                          <h5><b>Suite: 1234</b></h5>
                          <h5>Toronto, Ontario</h5>
                          <h5><b>Canada</b></h5>
                        </div>
                        <div>
                          <h5>M4P 2H5</h5>
                        </div>
                      </div>
                      <div className="liDivLast">
                        <h6>Toronto - Yonge St</h6>
                        <div className='check-button w-100 mb-2 d-flex justify-content-center align-items-center'>
                          <input type="radio" className="btn-check" name="checkrem" id="remember5" onClick={(e) => setSelectAddress(e.target.value)} />
                          <label className="btn rounded-circle btnRadio large mt-2 me-0" htmlFor="remember5"><div className="radioInner"><span></span></div></label>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 d-flex lidiv shadow-4">
                      <div className="w-66">
                        4.7 <br />KM
                      </div>
                      <div className="w-100 d-flex justify-content-between align-items-center ps-3">
                        <div className="text-start">
                          <h5>2482 Yonge St</h5>
                          <h5><b>Suite: 1234</b></h5>
                          <h5>Toronto, Ontario</h5>
                          <h5><b>Canada</b></h5>
                        </div>
                        <div>
                          <h5>M4P 2H5</h5>
                        </div>
                      </div>
                      <div className="liDivLast">
                        <h6>Toronto - Yonge St</h6>
                        <div className='check-button w-100 mb-2 d-flex justify-content-center align-items-center'>
                          <input type="radio" className="btn-check" name="checkrem" id="remember6" onClick={(e) => setSelectAddress(e.target.value)} />
                          <label className="btn rounded-circle btnRadio large mt-2 me-0" htmlFor="remember6"><div className="radioInner"><span></span></div></label>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 d-flex lidiv shadow-4">
                      <div className="w-66">
                        4.8 <br />KM
                      </div>
                      <div className="w-100 d-flex justify-content-between align-items-center ps-3">
                        <div className="text-start">
                          <h5>2482 Yonge St</h5>
                          <h5><b>Suite: 1234</b></h5>
                          <h5>Toronto, Ontario</h5>
                          <h5><b>Canada</b></h5>
                        </div>
                        <div>
                          <h5>M4P 2H5</h5>
                        </div>
                      </div>
                      <div className="liDivLast">
                        <h6>Toronto - Yonge St</h6>
                        <div className='check-button w-100 mb-2 d-flex justify-content-center align-items-center'>
                          <input type="radio" className="btn-check" name="checkrem" id="remember7" onClick={(e) => setSelectAddress(e.target.value)} />
                          <label className="btn rounded-circle btnRadio large mt-2 me-0" htmlFor="remember7"><div className="radioInner"><span></span></div></label>
                        </div>
                      </div>
                    </div>
                    <div className="mb-0 d-flex lidiv shadow-4">
                      <div className="w-66">
                        4.9 <br />KM
                      </div>
                      <div className="w-100 d-flex justify-content-between align-items-center ps-3">
                        <div className="text-start">
                          <h5>2482 Yonge St</h5>
                          <h5><b>Suite: 1234</b></h5>
                          <h5>Toronto, Ontario</h5>
                          <h5><b>Canada</b></h5>
                        </div>
                        <div>
                          <h5>M4P 2H5</h5>
                        </div>
                      </div>
                      <div className="liDivLast">
                        <h6>Toronto - Yonge St</h6>
                        <div className='check-button w-100 mb-2 d-flex justify-content-center align-items-center'>
                          <input type="radio" className="btn-check" name="checkrem" id="remember8" onClick={(e) => setSelectAddress(e.target.value)} />
                          <label className="btn rounded-circle btnRadio large mt-2 me-0" htmlFor="remember8"><div className="radioInner"><span></span></div></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 pe-0 d-flex flex-column position-relative">
                  <div className="w-100 d-flex ps-5">
                    <img src={img1} alt="" width="218px" height="182px" className="ms-5" />
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={img2} alt="" width="62px" height="67px" />
                    <div className='check-button w-100 mb-2 d-flex justify-content-center align-items-center textlabel'>
                      <input type="checkbox" className="btn-check" name="remember" id="remember" />
                      <label className="btn rounded-circle btnRadio large me-3" htmlFor="remember"><div className="radioInner"><span></span></div></label>
                      Toronto - Yonge St
                    </div>
                    <div style={{ height: '331px', width: '100%' }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: "" }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}>
                        <AnyReactComponent
                          lat={59.955413}
                          lng={30.337844}
                          text="My Marker"
                        />
                      </GoogleMapReact>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="box2" role="tabpanel" aria-labelledby="box2">

            </div>
            <div className="tab-pane text-center detailTab fade" id="setting2" role="tabpanel" aria-labelledby="setting2">

            </div>
          </div>
        </div>
        <div className='container privacyTab1 privacyTab3s privacyTab1s mb-0 pb-0 mt-0'>
          <div id="myTab" className='slidetab' role="tablist">
            <div className="d-flex justify-content-center mb-3">
              <div className='px-2 btntb1'>
                <button className="br-40 w-100 h-100 border-0 active" id="manage2" data-bs-toggle="tab" data-bs-target="#manage2" type="button" role="tab" aria-controls="manage2" aria-selected="true">MANAGE OFFICE ADDRESS</button>
              </div>
              <div className='px-2 btntb1'>
                <button className="br-40 w-100 h-100 border-0" id="box2" data-bs-toggle="tab" data-bs-target="#box2" type="button" role="tab" aria-controls="box2" aria-selected="true">DIGITAL MAIL box dashboard</button>
              </div>
              <div className='px-2 btntb1'>
                <button className="br-40 w-100 h-100 border-0" id="setting2" data-bs-toggle="tab" data-bs-target="#setting2" type="button" role="tab" aria-controls="setting2" aria-selected="false">BUSINESS ADDRESS SETTINGS</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  )
}
