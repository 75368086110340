import React from "react";
import { useState } from "react";
import "../regularTheme2Mobile/RegularTheme2Mobile.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import timeline from "../../../../../../images/how-it-work/timeline-mobile.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { IoIosArrowForward } from "react-icons/io";
import { FcCheckmark } from "react-icons/fc"
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai"
import "slick-carousel/slick/slick-theme.css";
export default function RegularTheme2Mobile() {
    const [isVisible, setIsVisible] = useState(false);

    const handleClick = () => {
        setIsVisible(!isVisible);
    };

    const handleClose = () => {
        setIsVisible(false);
    };
    const [hamburger, setHamburger] = useState(false);

    const handleHamburgerClick = () => {
        setHamburger(!hamburger);
    };

    const handleHamburgerClose = () => {
        setHamburger(false);
    };
    const [bookme, setBookme] = useState(false);

    const handleBookmeClick = () => {
        setBookme(!bookme);
    };

    const handleBookmeClose = () => {
        setBookme(false);
    };

    const [Competency1, setCompetency1] = useState(10);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div>
            <div className="">
                <div>
                    <div className="main-content plan_content">

                        <div>
                            <div className="mobile-help-section text-center relative" >
                                <div className="d-flex align-items-center justify-content-center mb-5">
                                    <div className="toggle_button">
                                        <button type="" className="btn1 active_btn">Monthly</button>
                                        <button type="" className="btn1 ">Yearly</button>
                                    </div>
                                </div>
                                <div className="relative">

                                    <div className="content_wrapper">

                                        <div className="how-it-work-programs-wrapper">
                                            {/* <div className="programs-flex d-flex justify-content-center"> */}
                                            <Slider {...settings} className="">
                                                <div className="px-4 pt_80 pb-5">
                                                    <div class="plan_outer mx-auto">
                                                        <div class="shape_outer">
                                                            <div class="shape44_1"></div>
                                                            <div class="shape44"></div>
                                                            <div id="base">
                                                                <div class="price">
                                                                    <h2>$29</h2>
                                                                    <p>/Month</p>
                                                                </div>
                                                                <div class="pt-3 px-3 plan_heading d-flex align-items-center justify-content-center">
                                                                    <h1>REGULAR</h1>
                                                                    <p>Ipsum dolor sit amet consect</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-5 plan_listing">
                                                            <ul>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Lorem ipsum dolor</li>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Sit amet consect</li>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Amet consectetur</li>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Neque sed suspend</li>
                                                                <li>
                                                                    <RxCross2 className="icon text-danger" />
                                                                    Se sed diam mattis</li>
                                                                <li>
                                                                    <RxCross2 className="icon text-danger" />
                                                                    Ac sagittis ac
                                                                </li>
                                                                <li>
                                                                    <RxCross2 className="icon text-danger" />
                                                                    Sitt isporta vestibulum
                                                                </li>
                                                                <li>
                                                                    <RxCross2 className="icon text-danger" />
                                                                    eu velit viverra quis vel
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="d-flex justify-content-center">
                                                            <button class="btn" type="button">Order Now</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-4 pt_80 pb-5">
                                                    <div class="plan_outer mx-auto">
                                                        <div class="shape_outer">
                                                            <div class="shape44_1"></div>
                                                            <div class="shape44"></div>
                                                            <div id="base">
                                                                <div class="price">
                                                                    <h2>$99</h2>
                                                                    <p>/Mo</p>
                                                                </div>
                                                                <div class="pt-3 px-3 plan_heading d-flex align-items-center justify-content-center">
                                                                    <h1>ADVANCED</h1>
                                                                    <p>Ipsum dolor sit amet consect</p>
                                                                    <button type="" className="green_btn1 mt-3">Most Popular</button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-5 plan_listing">
                                                            <ul>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Lorem ipsum dolor</li>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Sit amet consect</li>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Amet consectetur</li>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Neque sed suspend</li>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Se sed diam mattis</li>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Ac sagittis ac
                                                                </li>
                                                                <li>
                                                                    <RxCross2 className="icon text-danger" />
                                                                    Sitt isporta vestibulum
                                                                </li>
                                                                <li>
                                                                    <RxCross2 className="icon text-danger" />
                                                                    eu velit viverra quis vel
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="d-flex justify-content-center">
                                                            <button class="btn" type="button">Order Now</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="px-4 pt_80 pb-5">
                                                    <div class="plan_outer mx-auto">
                                                        <div class="shape_outer">
                                                            <div class="shape44_1"></div>
                                                            <div class="shape44"></div>
                                                            <div id="base">
                                                                <div class="price">
                                                                    <h2>$199</h2>
                                                                    <p>/Mo</p>
                                                                </div>
                                                                <div class="pt-3 px-3 plan_heading d-flex align-items-center justify-content-center">
                                                                    <h1>BUSINESS</h1>
                                                                    <p>Ipsum dolor sit amet consect</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-5 plan_listing">
                                                            <ul>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Lorem ipsum dolor</li>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Sit amet consect</li>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Amet consectetur</li>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Neque sed suspend</li>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Se sed diam mattis</li>
                                                                <li>
                                                                    <FcCheckmark className="icon" />
                                                                    Ac sagittis ac
                                                                </li>
                                                                <li>
                                                                    <AiFillStar className="icon text_yellow" />
                                                                    Sitt isporta vestibulum
                                                                </li>
                                                                <li>
                                                                    <AiFillStar className="icon text_yellow" />
                                                                    eu velit viverra quis vel
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="d-flex justify-content-center">
                                                            <button class="btn" type="button">Order Now</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
