import React from 'react'
import CounterTimer from '../../global/counterTimer/CounterTimer';
// import TimelineTabDesktop from '../../global/timelineTab/TimelineTabDesktop';
import './ThreeHoursBusinessLaunch.scss';
import '../threeHoursBusinessLaunch/customDomain/CustomDomainStep.scss';
import { useTranslation } from "react-i18next";
// import CustomDomainStep from './customDomain/CustomDomainStep';
import TimelineTabMobile from '../../global/timelineTab/TimelineTabMobile';
import NicheTwo from '../profileManagement/tabs/StepZero/Nicheform/NicheTwo';
import img10 from '../../../images/StepZero/left.png';
import img11 from '../../../images/StepZero/right.png';
import { Link } from 'react-router-dom'; 
import VideoViewSec from '../../global/VideoViewSec';
import { useDispatch, useSelector } from 'react-redux';
export default function SecureBusinessPhoneNumber() {
  const { t, i18n } = useTranslation();
  let {isVideoVisible} = useSelector(state => state.videoReducer);
  return (
    <div className='twelvestep-scss'>
      {isVideoVisible ?
          <div className="w-100">
            <VideoViewSec/>
          </div>
          :
          <></>
      }
      <div className='flex header-text' ><h2>{t('business_step_2.three_hours_business_launch_challenge')} </h2>
        <div className='circles-small'>
        <div className='circle-small'>
            <div className='text'><span className="d-block">45%</span></div>
            <svg>
              <circle className='bg' cx="30" cy="30" r="20"></circle>
              <circle className='progress one' cx="30" cy="30" r="20"></circle>
            </svg>
          </div>
        </div>
      </div>
      <div className='three-hour-launch d-flex w-1200s mx-auto'>
        <div className=''>
          <CounterTimer />
        </div>

        <div className='width-tab-set'>
          {/* <TimelineTabDesktop /> */}
          <TimelineTabMobile />
        </div>
        <div className='width-form-set'>
          <div className='flex justify-content-center'>


            <div className='width-fll w-1120 mb-5 text-center'>
              <div className="borderTop1 borderTop1s">
                <div className="borderTop borderTop4">
                <Link className='leftlink'>
                  <img src={img10} alt="" width="38px" />
                </Link>
                <Link className='rightlink'>
                  <img src={img11} alt="" width="38px" />
                </Link>
                <NicheTwo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
