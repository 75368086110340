import React, { useState } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import img2 from "../../images/img200.png";
function CustomRange() {
const [minValue, set_minValue] = useState(0);
const [maxValue, set_maxValue] = useState(4);
const handleInput = (e) => {
	set_minValue(e.minValue);
	set_maxValue(e.maxValue);
};

return (
	<div className="App">
		<MultiRangeSlider
			min={0}
			max={5}
			step={1}
			minValue={minValue}
			maxValue={maxValue}
			onInput={(e) => {
				handleInput(e);
			}}
		/>
		<div className="text-flex mt-3">
			<h6>
				<img src={img2} alt="" />
				High School
			</h6>
			<h6>
				<img src={img2} alt="" />
				College
			</h6>
			<h6>
				<img src={img2} alt="" />
				BAs
			</h6>
			<h6>
				<img src={img2} alt="" />
				MAs
			</h6>
			<h6>
				<img src={img2} alt="" />
				PhD
			</h6>
		</div>
	</div>
	);
}

export default CustomRange;