import React from "react";
import ButtonBox from "../../../../global/ButtonBox";
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {  faCaretDown, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { NavItem } from 'react-bootstrap';
import ButtonBoxNew from "../../../../global/ButtonBoxNew";

export default function SecurityOne() {

  return (
    <div>
      <ul>
        <NavItem as="li" className="py-3 tabBdr">
          <div className="listText w-400">
            <h5>Sign out of all other sessions</h5>
            <p>This will sign you out of sessions in other browsers or on other computers</p>
          </div>
          <div className="d-flex">
            {/* <ButtonBox
              boxwidth="w-240 mt-0 mb-50 mx-auto"
              clicked="Signing out"
              name="Sign out other sessions"
            /> */}
            <ButtonBoxNew  name="Sign out other sessions" boxwidth="w-300 mt-0 mb-50 mx-auto bs-primary"/>
          </div>
        </NavItem>
        <NavItem as="li" className="py-3 tabBdr">
          <div className="listText w-400 my-3">
            <h5>Deactivate account</h5>
            <p>Deactivating your account will remove it from Medium within a few minutes. Deactivation will also immediately cancel any subscription for Medium Membership, and no money will be reimbursed. You can sign back in anytime to reactivate your account and restore its content.</p>
          </div>
          <div className="d-flex">
            {/* <ButtonBox
              boxwidth="w-200 mt-0 mb-50 mx-auto bs-gray"
              clicked="Deactivating"
              name="Deactivate account"
            /> */}
            <ButtonBoxNew  name="Deactivate account" boxwidth="w-240 mt-0 mb-50 mx-auto bs-gray1"/>
          </div>
        </NavItem>
        <NavItem as="li" className="py-3">
          <div className="listText w-400">
            <h5>Delete account</h5>
            <p>Permanently delete your account and all of your content.</p>
          </div>
          <div className="d-flex">
            {/* <ButtonBox
              boxwidth="w-200 mt-4 mb-50 mx-auto bs-danger"
              clicked="Deleting account"
              name="Delete account"
            /> */}
          </div>
          <ButtonBoxNew  name="Delete account" boxwidth="w-240 mt-4 mb-50 mx-auto text-white bg-danger"/>
        </NavItem>

        <NavItem as="li" className=" text-center mt-5 justify-content-center">
          <ButtonBox
            boxwidth="w-140 mt-0 mb-50 mx-auto"
            clicked="Saved"
            name="Save/Next"
          />
        </NavItem>
      </ul>
    </div>
  );
}
