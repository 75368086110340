import React, { useState } from "react";
import desktop_1 from "../../../../images/featured-on/Desktop1.png"
import tab_1 from "../../../../images/featured-on/Tab1.png"
import mobile_1 from "../../../../images/featured-on/Mobile1.png"
import desktop_2 from "../../../../images/featured-on/Desktop2.png"
import tab_2 from "../../../../images/featured-on/Tab2.png"
import mobile_2 from "../../../../images/featured-on/Mobile2.png"
import desktop_3 from "../../../../images/featured-on/Desktop3.png"
import tab_3 from "../../../../images/featured-on/Tab3.png"
import mobile_3 from "../../../../images/featured-on/Mobile3.png"
import desktop_4 from "../../../../images/featured-on/Desktop4.png"
import tab_4 from "../../../../images/featured-on/Tab4.png"
import mobile_4 from "../../../../images/featured-on/Mobile4.png"

import FeaturedOnSectionRegularThemeDesktopView1 from './FeaturedOnSectionRegularThemeDesktopView1'
import FeaturedOnSectionRegularThemeDesktopView2 from './FeaturedOnSectionRegularThemeDesktopView2'
import FeaturedOnSectionRegularThemeDesktopView3 from './FeaturedOnSectionRegularThemeDesktopView3'
import FeaturedOnSectionRegularThemeDesktopView4 from './FeaturedOnSectionRegularThemeDesktopView4'



import FeaturedOnSectionRegularThemeTabView1 from './FeaturedOnSectionRegularThemeTabView1'
import FeaturedOnSectionRegularThemeMobileView1 from './FeaturedOnSectionRegularThemeMobileView1'
import FeaturedOnSectionRegularThemeTabView2 from './FeaturedOnSectionRegularThemeTabView2'
import FeaturedOnSectionRegularThemeTabView3 from './FeaturedOnSectionRegularThemeTabView3'
import FeaturedOnSectionRegularThemeMobileView2 from './FeaturedOnSectionRegularThemeMobileView2'
import FeaturedOnSectionRegularThemeTabView4 from './FeaturedOnSectionRegularThemeTabView4'
import FeaturedOnSectionRegularThemeMobileView4 from './FeaturedOnSectionRegularThemeMobileView4'
import FeaturedOnSectionRegularThemeMobileView3 from './FeaturedOnSectionRegularThemeMobileView3'
// import HowItWorkSectionRegularThemeDesktopView2 from './HowItWorkSectionRegularThemeDesktopView2'
import ButtonBoxNew from "../../../global/ButtonBoxNew";


// import HowItWorkSectionRegularThemeTabView1 from './HowItWorkSectionRegularThemeTabView1'
// import HowItWorkSectionRegularThemeMobileView1 from './HowItWorkSectionRegularThemeMobileView1'
// import HowItWorkSectionRegularThemeTabView2 from './HowItWorkSectionRegularThemeTabView2'
// import HowItWorkSectionRegularThemeMobileView2 from './HowItWorkSectionRegularThemeMobileView2'

import Modal from 'react-bootstrap/Modal';

import { useTranslation } from "react-i18next";


import './FeaturedOnSectionThemes.scss';
export default function FeaturedOnSectionRegularTheme() {

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [TabTwoShow, setTabTwoShow] = useState(false);
  const [MobileTwoShow, setMobileTwoShow] = useState(false);
  const [deskShow, setDeskShow] = useState(false);
  const [desktwoShow, setDesktwoShow] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);
  const [TabFourShow, setTabFourShow] = useState(false);
  const [MobileFourShow, setMobileFourShow] = useState(false);
  const [deskFourShow, setdeskFourShow] = useState(false);
  const [TabFiveShow, setTabFiveShow] = useState(false);
  const [MobileFiveShow, setMobileFiveShow] = useState(false);
  const [deskFiveShow, setdeskFiveShow] = useState(false);
  const [TabSixShow, setTabSixShow] = useState(false);
  const [MobileSixShow, setMobileSixShow] = useState(false);
  const [deskSixShow, setdeskSixShow] = useState(false);

  const { t, i18n } = useTranslation();
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });




  return (
    <div className="">

      <h4 className="text-center">
        Regular  Design Version 1
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg newimg1s">
            <img src={desktop_1} alt="deshtop-1"  >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
         <ButtonBoxNew  name=" See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setDeskShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <FeaturedOnSectionRegularThemeDesktopView1 />

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_1} alt="tab-1"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
         <ButtonBoxNew  name=" See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setSmShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <FeaturedOnSectionRegularThemeTabView1 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_1} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
         <ButtonBoxNew  name=" See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <FeaturedOnSectionRegularThemeMobileView1 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 2
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg newimg1s">
            <img src={desktop_2} alt="desktop-2" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDesktwoShow(true)}>
            See Desktop view
          </div> */}
         <ButtonBoxNew  name=" See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setDesktwoShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktwoShow}
            onHide={() => setDesktwoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <FeaturedOnSectionRegularThemeDesktopView2 />

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2} alt="tab-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabTwoShow(true)}>
            See Tablet view
          </div> */}
         <ButtonBoxNew  name=" See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"onClick={() => setTabTwoShow(true)}/>

          <Modal
            show={TabTwoShow}
            onHide={() => setTabTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-tit"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <FeaturedOnSectionRegularThemeTabView2 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2} alt="mobile-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileTwoShow(true)}>
            See Mobile view
          </div> */}
         <ButtonBoxNew  name=" See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileTwoShow(true)}/>

          <Modal
            show={MobileTwoShow}
            onHide={() => setMobileTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-ti"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <FeaturedOnSectionRegularThemeMobileView2 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 3
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg newimg1s">
            <img src={desktop_3} alt="desktop-3" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskthreeShow(true)}>
            See Desktop view
          </div> */}
         <ButtonBoxNew  name=" See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setDeskthreeShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskthreeShow}
            onHide={() => setDeskthreeShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <FeaturedOnSectionRegularThemeDesktopView3 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3} alt="tab-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabThreeShow(true)}>
            See Tablet view
          </div> */}
         <ButtonBoxNew  name=" See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabThreeShow(true)}/>

          <Modal
            show={TabThreeShow}
            onHide={() => setTabThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <FeaturedOnSectionRegularThemeTabView3/>

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3} alt="mobile-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileThreeShow(true)}>
            See Mobile view
          </div> */}
         <ButtonBoxNew  name=" See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileThreeShow(true)}/>

          <Modal
            show={MobileThreeShow}
            onHide={() => setMobileThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <FeaturedOnSectionRegularThemeMobileView3 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 4
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg newimg1s">
            <img src={desktop_4} alt="desktop-4" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setdeskFourShow(true)}>
            See Desktop view
          </div> */}
         <ButtonBoxNew  name=" See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setdeskFourShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFourShow}
            onHide={() => setdeskFourShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <FeaturedOnSectionRegularThemeDesktopView4 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_4} alt="tab-4" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFourShow(true)}>
            See Tablet view
          </div> */}
         <ButtonBoxNew  name=" See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"onClick={() => setTabFourShow(true)}/>

          <Modal
            show={TabFourShow}
            onHide={() => setTabFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <FeaturedOnSectionRegularThemeTabView4/>

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_4} alt="mobile-4" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFourShow(true)}>
            See Mobile view
          </div> */}
         <ButtonBoxNew  name=" See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileFourShow(true)}/>

          <Modal
            show={MobileFourShow}
            onHide={() => setMobileFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <FeaturedOnSectionRegularThemeMobileView4/>

            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
