import React, {useState} from 'react';
import '../../../global/timelineTab/TimelineTab.scss';
import Video from '../../../../images/iconVid.png';
import VideoPopup from '../../../global/VideoPopup';
import Video1 from '../../../../images/iconVid1.png';
import ButtonBox from "../../../global/ButtonBox";
// import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import './LogoStep.scss';
import LogoStepImages from './LogoStepImages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import LogoForm from './LogoForm';
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';

export default function LogoStep() {
    const [step3Images, setStep3Images] = useState(false);
    const { t, i18n } = useTranslation();
    let {isVideoVisible} = useSelector(state => state.videoReducer);
    const dispatch = useDispatch();
    const handleClick6 = () => {
      dispatch(toggleVideo());
    };
    return (
        <div>
            <Tab.Container  defaultActiveKey="">
                <div className='' >
                    <div className='flex'>
                        {step3Images && 
                    <LogoStepImages />
                }
                        <div className='width-fll step-three'>
                            <VideoPopup />
                            <Tab.Content className=' ' >
                    
                                    <div className="  align-items-center mx-auto d-flex flex-column-reverse justify-content-between three-bsiness">
                                      <div className='d-flex w-100'>
                                      
                                        <div className='w-100'>
                                        <span className="d-flex align-items-center justify-content-between w-100 mb-4">
                                            <div id="myTab1" className='flex' role="tablist">
                                                <button className="buttonArrow me-3 nav-link text-body border-0 bg br-10 active" >
                                                    <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
                                                </button>
                                                {/* <button className="buttonArrow me-3 text-body nav-link border-0 bg br-10" >
                                                    <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
                                                </button> */}
                                            </div>
                                            <div className='btn-save-hide'>
                                                <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="" icon={faFloppyDisk} name="Save" />
                                            </div>

                                        </span>
                                        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto ">
                                        <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>

                                            <h3 className='h3-border'> {t('choose_your_logo_component.create_your_logo')}</h3>
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="factorone" role="tabpanel" aria-labelledby="factorone">
                                                    <LogoForm setStep3Images={setStep3Images} />
                                                </div>
                                                <div className="tab-pane fade" id="factortwo" role="tabpanel" aria-labelledby="factortwo">
                                                    <label className='mb-4 formtwo-label z-per'>{t('timeline_form_component.step_two_heading')}</label>
                                                    <div>
                                                        <ul className="row mx-0 justify-content-center">
                                                            <NavItem as="li" className=" mb-2">
                                                                <div className='check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                                                                    <input type="checkbox" className="btn-check" name="Password-length" id="uppercase" />
                                                                    <label className="btn btn-outline-primary" htmlFor="uppercase">
                                                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                                                                        </svg>
                                                                    </label>
                                                                    <label htmlFor="uppercase" className="ml-3 mt-2">
                                                                        {t('timeline_form_component.tagline_checkbox_one')}
                                                                    </label>
                                                                </div>
                                                            </NavItem>
                                                            <NavItem as="li" className="mb-2">
                                                                <div className=' check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                                                                    <input type="checkbox" className="btn-check" name="Password-length" id="special" />
                                                                    <label className="btn btn-outline-primary" htmlFor="special">
                                                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                                                                        </svg>
                                                                    </label>
                                                                    <label htmlFor="special" className="ml-3 mt-2">

                                                                        {t('timeline_form_component.tagline_checkbox_two')}
                                                                    </label>
                                                                </div>
                                                            </NavItem>
                                                            <NavItem as="li" className=" mb-2">
                                                                <div className=' check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                                                                    <input type="checkbox" className="btn-check" name="Password-length" id="lowercase" />
                                                                    <label className="btn btn-outline-primary" htmlFor="lowercase">
                                                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                                                                        </svg>
                                                                    </label>
                                                                    <label htmlFor="lowercase" className="ml-3 mt-2">
                                                                        {t('timeline_form_component.tagline_checkbox_three')}
                                                                    </label>
                                                                </div>
                                                            </NavItem>
                                                            <NavItem as="li" className="mb-2 ">
                                                                <div className=' check-button mx-auto mb-2 d-flex justify-content-start align-items-center'>
                                                                    <input type="checkbox" className="btn-check" name="Password-length" id="minimum" />
                                                                    <label className="btn btn-outline-primary" htmlFor="minimum">
                                                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                                                                        </svg>
                                                                    </label>
                                                                    <label htmlFor="minimum" className="ml-2 mt-2">
                                                                        {t('timeline_form_component.tagline_checkbox_four')}
                                                                    </label>
                                                                </div>
                                                            </NavItem>
                                                            <NavItem as="li" className=" mb-2">
                                                                <div className=' check-button mx-auto mb-2 d-flex justify-content-start align-items-center h-100'>
                                                                    <input type="checkbox" className="btn-check" name="Password-length" id="numbers7" />
                                                                    <label className="btn btn-outline-primary" htmlFor="numbers7">
                                                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M7.80878 0.943619C7.94965 0.809508 8.13711 0.735366 8.3316 0.736838C8.52609 0.73831 8.71241 0.81528 8.85123 0.951507C8.99005 1.08773 9.07052 1.27256 9.07566 1.46699C9.0808 1.66142 9.01021 1.85025 8.87878 1.99362L4.88878 6.98362C4.82017 7.05752 4.73737 7.11682 4.64531 7.15799C4.55326 7.19915 4.45385 7.22133 4.35303 7.2232C4.25221 7.22506 4.15205 7.20658 4.05854 7.16885C3.96502 7.13112 3.88008 7.07493 3.80878 7.00362L1.16278 4.35762C1.08909 4.28896 1.02999 4.20616 0.989 4.11416C0.948008 4.02216 0.925966 3.92284 0.924189 3.82214C0.922412 3.72144 0.940937 3.62141 0.978658 3.52802C1.01638 3.43463 1.07252 3.3498 1.14374 3.27858C1.21496 3.20736 1.29979 3.15122 1.39318 3.1135C1.48657 3.07578 1.5866 3.05725 1.6873 3.05903C1.78801 3.0608 1.88732 3.08285 1.97932 3.12384C2.07132 3.16483 2.15412 3.22393 2.22278 3.29762L4.31678 5.39062L7.78978 0.96562C7.79603 0.95792 7.80271 0.950575 7.80978 0.943619H7.80878Z" fill="#2B2B2E" />
                                                                        </svg>
                                                                    </label>
                                                                    <label htmlFor="numbers7" className="ml-3 mt-2">

                                                                        {t('timeline_form_component.tagline_checkbox_five')}
                                                                    </label>
                                                                </div>
                                                            </NavItem>

                                                            <div id="separator" className='mt-3'>
                                                                <hr className='hr_s' />
                                                                <p className='ml-3 mr-3'>
                                                                    OR
                                                                </p>
                                                                <hr className='hr_s' />
                                                            </div>
                                                            <div className="mt-4 three-bsiness position-relative">
                                                                <label className="mb-3">{t('timeline_form_component.tagline_custom_slogan')}</label>
                                                                <textarea
                                                                    className="country mb-4 w-100 mx-auto text-center"
                                                                    placeholder={t('timeline_form_component.tagline_boss_up_placholder')}
                                                                >

                                                                </textarea>
                                                            </div>
                                                            <div className='three-bsiness'>


                                                                <NavItem as="li" className=" text-center time-btn">
                                                                    <ButtonBox

                                                                        boxwidth="w-300 mt-0 mb-50 mx-auto"
                                                                        clicked=""
                                                                        name="Save Slogan/Tagline"
                                                                    />
                                                                </NavItem>

                                                            </div>

                                                        </ul>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                  
                                        </div>
                                       
                                        </div>
                                    </div>
                               
                           
                            </Tab.Content>


                        </div>
                    </div>
                </div>





            </Tab.Container>
          
        </div>
    )
}