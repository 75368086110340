import { faCalendar, faLock, faCloudDownload, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import Merch from '../../../../images/merch.png';
import ButtonBox from "../../../global/ButtonBox";
import ButtonBoxNew from "../../../global/ButtonBoxNew";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
// import { Link } from 'react-router-dom';
import "./PrintfulMerchAccount.scss"
import { ReactComponent as Download } from "../../../../images/icons/icon29.svg";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';
export default function PrintfulMerchAccountForm() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });
    let {isVideoVisible} = useSelector(state => state.videoReducer);
    const dispatch = useDispatch();
    const handleClick6 = () => {
      dispatch(toggleVideo());
    };
    return (
        <div className="pb-5 pt-2 printful_merch_account">
            <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
            <div className="row mb-4 px-3">
                <div className="col-md-4 px-0">
                    <label className="mb-1">{t('timeline_form_printful_merch_account_component.printful_registration')}</label>
                    <span className="shadow-4 h-50s br-3  d-flex justify-content-start px-3 align-items-center text_blue text-center h6">
                        <img src={Merch} alt="" className="w-35 me-3" />
                        Registration Link</span>
                </div>
                <div className="col-md-8">
                    <label className="mb-1">{t('timeline_form_printful_merch_account_component.your_printful_merch_account_link')}</label>
                    <input placeholder={t('timeline_form_printful_merch_account_component.your_printful_merch_account_link_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
            </div>
            <div className="row mb-4 px-3">
                <div className="col-md-12 d-flex justify-content-between px-0">
                    <label className="mb-3">{t('timeline_form_printful_merch_account_component.ecommercestore_link')}</label>
                    <div className="flex items-center">
                        <span className="QR_label">Save QR code</span>
                        <div className="download_icon">
                            {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                            <Download />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 px-0">
                    <div className="row px-0">
                        <div className="col-md-1 px-0">
                            <span className="shadow-4 h-50s br-3  d-flex justify-content-start px-3 align-items-center text-secondary text-center h6">
                                <FontAwesomeIcon icon={faLink} className=" icon" />
                            </span>
                        </div>
                        <div className="col-md-11">
                            <div class="d-flex justify-content-center">
                                <div class="input-group">
                                    <input
                                        type="text"
                                        class="form-control shadow-5in br-5 h6 mb-0"
                                        placeholder={t('timeline_form_printful_merch_account_component.ecommercestore_link_placeholder')}
                                        aria-label="Example input"
                                        aria-describedby="button-addon1"
                                    />
                                    {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                                        Generate QR code
                                    </button> */}
                                    <ButtonBoxNew name="Generate QR code" boxwidth="w-240 br-30 bg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-4 px-3 justify-content-between  align-items-center">
                <div className="date_area">
                    Joined Since: <span className="ms-1">Nov. 10, 2022</span>
                    <div className="icon ms-2">
                        <FontAwesomeIcon icon={faCalendar} className="" />
                    </div>
                </div>
                <div className="QR_outer_div ms-auto">
                    <div className="QR_box">
                        <FontAwesomeIcon icon={faLock} className=" icon" />
                    </div>
                </div>
            </div>
            <div className=" text-center time-btn rotate_btn">

                <ButtonBox
                    boxwidth="w-400 text_light mt-4 mb-50 mx-auto"
                    clicked="Confirm Trustpilot Business Review Account"
                    name="Confirm Trustpilot Business Review Account"
                />
            </div>
        </div>
    );
}
