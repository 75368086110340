import React from "react";
import { useState } from "react";
import "../regularTheme1Tab/RegularTheme1Tab.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import timeline from "../../../../../../images/how-it-work/timeline.png";
import timeline_2 from "../../../../../../images/how-it-work/timeline-2.png";
import timeline_3 from "../../../../../../images/how-it-work/timeline-3.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import abc from "../../../../../../images/featured-on/circle/abc.png";
import ben from "../../../../../../images/featured-on/circle/ben.png";
import cbs from "../../../../../../images/featured-on/circle/cbs.png";
import digi from "../../../../../../images/featured-on/circle/digi.png";
import fn from "../../../../../../images/featured-on/circle/fn.png";
import nbc from "../../../../../../images/featured-on/circle/nbc.png";
import usa from "../../../../../../images/featured-on/circle/usa.png";
import above from "../../../../../../images/featured-on/circle/above.png";
import below from "../../../../../../images/featured-on/circle/below.png";
import line from "../../../../../../images/featured-on/circle/line.png";

export default function RegularTheme1Tab() {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [Competency1, setCompetency1] = useState(10);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  return (
    <div>
      <div className="features-on-design-tab-1">
        <div className="grey-box-tab d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-center outer-circle-tab-1">
            <div className="curve-image-tab p-b-20 ">
              <img src={above} alt="" />
            </div>
        
            <div className="line-update-tab"></div>

            <div className="d-flex align-item-center justify-content-center">
              <div className="image-circle-tab">
             <span>   <img src={ben} alt="Image 1" width="80" height="80" /></span>
              </div>
              <div className="image-circle-tab">
             <span>   <img src={digi} alt="Image 2" width="80" height="80" /></span>
              </div>
              <div className="image-circle-tab">
             <span>   <img src={cbs} alt="Image 3" width="80" height="80" /></span>
              </div>
              <div className="image-circle-tab">
             <span>   <img src={nbc} alt="Image 4" width="80" height="80" /></span>
              </div>
              <div className="image-circle-tab image-usa">
             <span>   <img src={usa} alt="Image 5" width="80" height="80" /></span>
              </div>
              <div className="image-circle-tab">
                <div className="image-line"></div>
             <span>   <img src={fn} alt="Image 6" width="80" height="80" /></span>
              </div>
              <div className="image-circle-tab">
             <span>   <img src={abc} alt="Image 7" width="80" height="80" /></span>
              </div>
            </div>
            <div className="curve-image-tab p-b-20 ">
              <img src={below} style={{marginTop:"40px"}} alt="" />
            </div>
          </div>
        </div>
      </div>
         
    </div>
  );
}
