import React, { useState } from "react";
import desktop_2_1 from "../../../../images/hire-me/Desktop2-1.png";
import tab_2_1 from "../../../../images/hire-me/Tab2-1.png";
import mobile_2_1 from "../../../../images/hire-me/Mobile2-1.png";
import desktop_2_2 from "../../../../images/hire-me/Desktop2-2.png";
import tab_2_2 from "../../../../images/hire-me/Tab2-2.png";
import mobile_2_2 from "../../../../images/hire-me/Mobile2-2.png";
import desktop_2_3 from "../../../../images/hire-me/Desktop2-3.png";
import tab_2_3 from "../../../../images/hire-me/Tab2-3.png";
import mobile_2_3 from "../../../../images/hire-me/Mobile2-3.png";
import "./HireMeSectionThemes.scss";
import Modal from "react-bootstrap/Modal";
// import HowItWorkSectionNeumorphicThemeDesktopView1 from "./howItWorkSectionNeumorphicThemes/HowItWorkSectionNeumorphicThemeDesktopView1";
// import HowItWorkSectionNeumorphicThemeTabView1 from "./howItWorkSectionNeumorphicThemes/HowItWorkSectionNeumorphicThemeTabView1";
// import HowItWorkSectionNeumorphicThemeDesktopView2 from "./howItWorkSectionNeumorphicThemes/HowItWorkSectionNeumorphicThemeDesktopView2";
// import HowItWorkSectionNeumorphicThemeTabView2 from "./howItWorkSectionNeumorphicThemes/HowItWorkSectionNeumorphicThemeTabView2";
// import HowItWorkSectionNeumorphicThemeMobileView1 from "./howItWorkSectionNeumorphicThemes/HowItWorkSectionNeumorphicThemeMobileView1";
// import HowItWorkSectionNeumorphicThemeMobileView2 from "./howItWorkSectionNeumorphicThemes/HowItWorkSectionNeumorphicThemeMobileView2";
import { useTranslation } from "react-i18next";
import HireMeSectionRegularThemeDesktopView1 from "./HireMeSectionRegularThemeDesktopView1";
import HireMeSectionRegularThemeTabletView1 from "./HireMeSectionRegularThemeTabletView1";
import HireMeSectionRegularThemeMobileView1 from "./HireMeSectionRegularThemeMobileView1";
import RegularTheme1Tab from "./regularTheme1/regularTheme1Tab/RegularTheme1Tab";
import RegularTheme1Mobile from "./regularTheme1/regularTheme1Mobile/RegularTheme1Mobile";
import ButtonBoxNew from "../../../global/ButtonBoxNew";

export default function HireSectionNeumorphicTheme() {
  const { t, i18n } = useTranslation();

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [TabTwoShow, setTabTwoShow] = useState(false);
  const [MobileTwoShow, setMobileTwoShow] = useState(false);
  const [deskShow, setDeskShow] = useState(false);
  const [desktwoShow, setDesktwoShow] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);

  return (
    <div className="">
      <h4 className="text-center">Neumorphic Design Version 1</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2_1} alt="desktop-2-1"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"       onClick={() => setDeskShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <HireMeSectionRegularThemeDesktopView1 Neumorphic />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2_1} alt="tab_2_1"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"     onClick={() => setSmShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <HireMeSectionRegularThemeTabletView1 Neumorphic />
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2_1} alt="mobile_2_1"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"       onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <HireMeSectionRegularThemeMobileView1 Neumorphic />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Neumorphic Design Version 2</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg newimg1s">
            <img src={desktop_2_2} alt="desktop-2-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setDesktwoShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"      onClick={() => setDesktwoShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktwoShow}
            onHide={() => setDesktwoShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <HireMeSectionRegularThemeMobileView/> */}
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2_2} alt="tab-2-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabTwoShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"     onClick={() => setTabTwoShow(true)}/>

          <Modal
            show={TabTwoShow}
            onHide={() => setTabTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-tit"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeTabView2 /> */}
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2_2} alt="mobile-2-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileTwoShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"      onClick={() => setMobileTwoShow(true)}/>

          <Modal
            show={MobileTwoShow}
            onHide={() => setMobileTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-ti"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView2 /> */}
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Neumorphic Design Version 3</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2_3} alt="desktop-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setDeskthreeShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"       onClick={() => setDeskthreeShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskthreeShow}
            onHide={() => setDeskthreeShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2_3} alt="tab-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabThreeShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"    onClick={() => setTabThreeShow(true)}/>

          <Modal
            show={TabThreeShow}
            onHide={() => setTabThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2_3} alt="mobile-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileThreeShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"       onClick={() => setMobileThreeShow(true)}/>

          <Modal
            show={MobileThreeShow}
            onHide={() => setMobileThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
