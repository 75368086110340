import React from "react";
// import Video from "../../../../../images/iconVid.png";
import { Link } from "react-router-dom";
import RadioNo from "../../../../global/RadioNo";
import ButtonBox from "../../../../global/ButtonBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import RangeSelect from "../../../../global/rangeSelect/RangeSelect";
import { AgeRange } from "../../../../global/rangeSelect/RangeValues";
import { useState } from "react";
import RangeSlider from "react-bootstrap-range-slider";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import img56 from '../../../../../images/man.png';
import { NavItem } from 'react-bootstrap';

import { ReactComponent as IconBlue1q } from "../../../../../images/svgIcon/icon25.svg";
import { ReactComponent as IconBlue2q } from "../../../../../images/svgIcon/icon26.svg";
import { ReactComponent as IconBlue3q } from "../../../../../images/svgIcon/icon27.svg";
import { ReactComponent as IconBlue4q } from "../../../../../images/svgIcon/icon28.svg";
import { ReactComponent as IconBlue5q } from "../../../../../images/listicon/icon19.svg";
import { ReactComponent as IconBlue6q } from "../../../../../images/svgIcon/icon24.svg";
import { useTranslation } from "react-i18next";
import ButtonBoxNew from "../../../../global/ButtonBoxNew";
import DropdownBox from "../../../../global/DropdownBox";


export default function PersonalOne() {

  const { t, i18n } = useTranslation();

  const [selectedRange, setSelectedRange] = useState(
    AgeRange[Math.floor(AgeRange.length / 2)]
  );
  const [gender, setGender] = useState("");
  const inputRef = React.useRef();
  const [previewImage, setPreviewImage] = useState("");

  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState(8);

  const getGenderName = (e) => {
    setGender(e.target.value);
  };
  const open = () => {
    inputRef.current.click();
  };
  // const openSelect = () => {
  //   inputRef.current.click();
  // };


  const openSelect = () => {inputRef10.current.click();};
  const inputRef10 = React.useRef();
  const [previewImage10, setPreviewImage10] = useState("");
  const [image10, setImage10] = useState("");

  const openSelect2 = () => {inputRef11.current.click();};
  const inputRef11 = React.useRef();
  const [previewImage11, setPreviewImage11] = useState("");
  const [image11, setImage11] = useState("");

  const openSelect3 = () => {inputRef12.current.click();};
  const inputRef12 = React.useRef();
  const [previewImage12, setPreviewImage12] = useState("");
  const [image12, setImage12] = useState("");

  const openSelect4 = () => {inputRef13.current.click();};
  const inputRef13 = React.useRef();
  const [previewImage13, setPreviewImage13] = useState("");
  const [image13, setImage13] = useState("");

  const openSelect5 = () => {inputRef14.current.click();};
  const inputRef14 = React.useRef();
  const [previewImage14, setPreviewImage14] = useState("");
  const [image14, setImage14] = useState("");

  const openSelect32 = () => {inputRef32.current.click();};
  const inputRef32 = React.useRef();
  const [previewImage32, setPreviewImage32] = useState("");
  const [image32, setImage32] = useState("");

  const openSelect33 = () => {inputRef33.current.click();};
  const inputRef33 = React.useRef();
  const [previewImage33, setPreviewImage33] = useState("");
  const [image33, setImage33] = useState("");

  // const openSelect34s = () => {inputRef34s.current.click();};
  // const inputRef34s = React.useRef();
  // const [previewImage34s, setPreviewImage34s] = useState("");
  // const [image34s, setImage34s] = useState("");

  const openSelect35s = () => {inputRef35s.current.click();};
  const inputRef35s = React.useRef();
  const [previewImage35s, setPreviewImage35s] = useState("");
  const [image35s, setImage35s] = useState("");

  // const openSelect36s = () => {inputRef36s.current.click();};
  // const inputRef36s = React.useRef();
  // const [previewImage36s, setPreviewImage36s] = useState("");
  // const [image36s, setImage36s] = useState("");

  const openSelect37s = () => {inputRef37s.current.click();};
  const inputRef37s = React.useRef();
  const [previewImage37s, setPreviewImage37s] = useState("");
  const [image37s, setImage37s] = useState("");

  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });
  // const options = [
  //   { value: "Single", label: "Single" },
  //   { value: "Engaged", label: "Engaged" },
  //   { value: "In Relationship", label: "In Relationship" },
  //   { value: "Married", label: "Married" },
  //   { value: "Divorced", label: "Divorced" },
  //   { value: "Widow", label: "Widow" },
  //   { value: "Complicated", label: "Complicated" },
  // ];

  const stateOptions =
  [{
      "name": "Single",      
    }, {
      "name": "Engaged",
    }, {
      "name": "In Relationship",      
    }, {
      "name": "Married",      
    }, {
      "name": "Divorced",      
    }, {
      "name": "Widow",      
    }, {
      "name": "Complicated",      
    }
  ] 

  

  return (
    <div>
        <form>
          <div className="text-center mb-5">
            <h5>{t('profile_section.your_personal_information')}</h5>
            <div>
              <div>
                <p className="text-center neu_range_select__title mt-5 mb-3">
                {t('profile_section.select_your_age_range')}
                </p>
                <RangeSelect
                  data={AgeRange}
                  type="ages"
                  selectedRange={selectedRange}
                  setSelectedRange={setSelectedRange}
                  value={selectedRange}
                />
              </div>
            </div>
            <div className="w-400 mx-auto my-4">
              <div className="shadow-4 h-40 br-30 w-100">
              {t('profile_section.what_is_your_gender')}
              </div>
              <label
                className="mt-4 d-flex justify-content-between"
                onChange={getGenderName}
              >
                <div>
                  <div className="gander-button mb-2 d-flex justify-content-center align-items-center">
                    <input
                      type="radio"
                      className="btn-check"
                      name="gender"
                      id="Male"
                      value="Male"
                    />
                    <label className="btn btn-outline-primary" htmlFor="Male">
                    <IconBlue2q />
                    </label>
                  </div>
                  <small>{t('profile_section.male')}
</small>
                </div>
                <div>
                  <div className="gander-button mb-2 d-flex justify-content-center align-items-center">
                    <input
                      type="radio"
                      className="btn-check"
                      name="gender"
                      id="Female"
                      value="Female"
                    />
                    <label className="btn btn-outline-primary" htmlFor="Female">
                    <IconBlue1q />
                    </label>
                  </div>
                  <small>{t('profile_section.female')}</small>
                </div>
                <div>
                  <div className="gander-button mb-2 d-flex justify-content-center align-items-center">
                    <input
                      type="radio"
                      className="btn-check"
                      name="gender"
                      id="Non-Binary"
                      value="Non-Binary"
                    />
                    <label className="btn btn-outline-primary" htmlFor="Non-Binary">
                    <IconBlue3q />
                    </label>
                  </div>
                  <small>{t('profile_section.non_binary')}
</small>
                </div>
                <div>
                  <div className="gander-button mb-2 d-flex justify-content-center align-items-center">
                    <input
                      type="radio"
                      className="btn-check"
                      name="gender"
                      id="Non-Conforming"
                      value="Non-Conforming"
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="Non-Conforming"
                    >
                      <IconBlue4q />
                    </label>
                  </div>
                  <small>{t('profile_section.non_conforming')}</small>
                </div>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className=" position-relative">
                <label className="mb-2">{t('profile_section.birthday')}</label>
                <input type="text" placeholder="Birthday" className="shadow-5in br-10 w-100" />
                {/* <Select isSearchable={false}
                  options={options}
                  className="country mb-4 w-100 mx-auto"
                  placeholder={t('profile_section.dd_mm')}
                  theme={theme}
                  styles={{
                    option: (base) => ({
                      ...base,
                      borderRadius: "30px",
                      width: "94%",
                      maxheight: "50px",
                      lineHeight: "32px",
                      margin: "10px",
                      cursor: "pointer",
                      color: "#000000",
                      boxShadow:
                        "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                    }),
                  }}
                /> */}
                {/* <DropdownBox name="Birthday" boxid="birthday" options={stateOptions} boxwidth="country mb-4 w-100 mx-auto" /> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className=" position-relative">
                <label className="mb-2">{t('profile_section.date_of_birth')}</label>
                <input type="text" placeholder="Date Of Birth" className="shadow-5in br-10 w-100" />
                {/* <Select isSearchable={false}
                  options={options}
                  className="country mb-4 w-100 mx-auto"
                  placeholder={t('profile_section.dd_mm_yy')}
                  theme={theme}
                  styles={{
                    option: (base) => ({
                      ...base,
                      borderRadius: "30px",
                      width: "94%",
                      maxheight: "50px",
                      lineHeight: "32px",
                      margin: "10px",
                      cursor: "pointer",
                      color: "#000000",
                      boxShadow:
                        "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                    }),
                  }}
                /> */}
                {/* <DropdownBox name="Date Of Birth" boxid="dateofbirth" options={stateOptions} boxwidth="country mb-4 w-100 mx-auto" /> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className=" position-relative">
                <label className="mb-2">{t('profile_section.birth_place')}</label>
                <input type="text" placeholder="Birth Place" className="shadow-5in br-10 w-100" />
                {/* <Select isSearchable={false}
                  options={options}
                  className="country mb-4 w-100 mx-auto"
                  placeholder={t('profile_section.canada')}
                  theme={theme}
                  styles={{
                    option: (base) => ({
                      ...base,
                      borderRadius: "30px",
                      width: "94%",
                      maxheight: "50px",
                      lineHeight: "32px",
                      margin: "10px",
                      cursor: "pointer",
                      color: "#000000",
                      boxShadow:
                        "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                    }),
                  }}
                /> */}
                 {/* <DropdownBox name="Birth Place" boxid="birthplace" options={stateOptions} boxwidth="country mb-4 w-100 mx-auto" /> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className=" position-relative">
                <label className="mb-2">{t('profile_section.marital_status')}</label>
                {/* <Select isSearchable={false}
                  options={options}
                  className="country mb-4 w-100 mx-auto"
                  placeholder={t('profile_section.single')}
                  theme={theme}
                  styles={{
                    option: (base) => ({
                      ...base,
                      borderRadius: "30px",
                      width: "94%",
                      maxheight: "50px",
                      lineHeight: "32px",
                      margin: "10px",
                      cursor: "pointer",
                      color: "#000000",
                      boxShadow:
                        "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                    }),
                  }}
                /> */}
                <DropdownBox name="Marital Status" boxid="maritalstatus" options={stateOptions} boxwidth="country mb-4 w-100 mx-auto" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className=" position-relative">
                <label className="mb-2">{t('profile_section.nationality_1')}</label>
                <input type="text" placeholder="Nationality1" className="shadow-5in br-10 w-100" />
                {/* <Select isSearchable={false}
                  options={options}
                  className="country mb-4 w-100 mx-auto"
                  placeholder={t('profile_section.american')}
                  theme={theme}
                  styles={{
                    option: (base) => ({
                      ...base,
                      borderRadius: "30px",
                      width: "94%",
                      maxheight: "50px",
                      lineHeight: "32px",
                      margin: "10px",
                      cursor: "pointer",
                      color: "#000000",
                      boxShadow:
                        "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                    }),
                  }}
                /> */}
                {/* <DropdownBox name="Nationality1" boxid="nationality" options={stateOptions} boxwidth="country mb-4 w-100 mx-auto" /> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className=" position-relative">
                <label className="mb-2">{t('profile_section.nationality_2')}</label>
                <input type="text" placeholder="Nationality2" className="shadow-5in br-10 w-100" />
                {/* <Select isSearchable={false}
                  options={options}
                  className="country mb-4 w-100 mx-auto"
                  placeholder={t('profile_section.canadian')}
                  theme={theme}
                  styles={{
                    option: (base) => ({
                      ...base,
                      borderRadius: "30px",
                      width: "94%",
                      maxheight: "50px",
                      lineHeight: "32px",
                      margin: "10px",
                      cursor: "pointer",
                      color: "#000000",
                      boxShadow:
                        "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                    }),
                  }}
                /> */}
                {/* <DropdownBox name="Nationality2" boxid="nationalitys" options={stateOptions} boxwidth="country mb-4 w-100 mx-auto" /> */}
              </div>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
                <label className="me-3">{t('profile_section.upload_country_flag')}</label>
                <div className="custom-file-drop-sec w-80 h-50s">
                  <div className="imgView w-100 h-100">
                    <div className="mx-auto mb-0 fileTab h-100">
                      <label onClick={openSelect32} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center h-100">
                          {previewImage32 ? (
                            <img
                              src={previewImage32}
                              alt=""
                              className="w-100 h-100"
                            />
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <IconBlue5q />
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    <input
                      hidden
                      type="file"
                      name="profile"
                      id="profile"
                      accept="image/*"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setPreviewImage32(url);
                        setImage32(e.target.files[0]);
                      }}
                      ref={inputRef32}
                    />
                  </div>
                </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
                <label className="me-3">{t('profile_section.upload_country_flag')}</label>
                <div className="custom-file-drop-sec w-80 h-50s">
                  <div className="imgView w-100 h-100">
                    <div className="mx-auto mb-0 fileTab h-100">
                      <label onClick={openSelect33} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center h-100">
                          {previewImage33 ? (
                            <img
                              src={previewImage33}
                              alt=""
                              className="w-100 h-100"
                            />
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <IconBlue5q />
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    <input
                      hidden
                      type="file"
                      name="profile"
                      id="profile"
                      accept="image/*"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setPreviewImage33(url);
                        setImage33(e.target.files[0]);
                      }}
                      ref={inputRef33}
                    />
                  </div>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div>
                <label>{t('profile_section.do_you_have_any_pets')}</label>
                <div className="radioButton radiobtn my-3 d-flex">
                  <div className="dropdown">
                    <span
                      className="dropArrow"
                      role="button"
                      id="dropdownPets"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                      data-bs-display="static"
                    >
                      {/* <Link
                        to="#"
                        className="d-flex justify-content-center px-4 align-items-center w-80 shadow-4 h-50s h8 br-30" 
                      >
                        Yes
                      </Link> */}
                      <ButtonBoxNew name="Yes" boxwidth="w-110" />
                    </span>
                    <ul
                      className="dropdown-menu w-400 dropdown-menu-start dropLink px-1 bg shadow-4 border-0 br-20"
                      onClick="event.stopPropagation()"
                      aria-labelledby="dropdownPets"
                    >
                      <NavItem as="li" className="">
                        <table>
                          <thead>
                            <tr>
                              <th>{t('profile_section.pets_type')}</th>
                              <th>{t('profile_section.no_of_pets')}</th>
                              <th>{t('profile_section.pets_name')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span>{t('profile_section.dog')}</span>
                              </td>
                              <td>
                                <span className="petNum">1</span>
                              </td>
                              <td className="d-flex">
                                <span>{t('profile_section.lucifer')}</span>
                                <div className="custom-file-drop-sec w-50s h-50s">
                                  <div className="imgView h-100 w-100 m-0">
                                    <div className="mx-auto mb-0 fileTab h-100">
                                      <label className="w-100 h-100">
                                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-1 h-100 m-0">
                                          <img
                                              src={img56}
                                              alt=""
                                              className="w-100 h-100"
                                            />
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input type="text" placeholder={t('profile_section.cat')} />
                              </td>
                              <td>
                                <input
                                  className="petNum"
                                  type="text"
                                  placeholder={t('profile_section._1')}
                                />
                              </td>
                              <td className="d-flex">
                                <input type="text" placeholder={t('profile_section._meow')} />
                                <div className="custom-file-drop-sec w-50s h-50s">
                                  <div className="imgView h-100 w-100 m-0">
                                    <div className="mx-auto mb-0 fileTab h-100">
                                      <label onClick={openSelect35s} className="w-100 h-100">
                                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-1 h-100 m-0">
                                          {previewImage35s ? (
                                            <img
                                              src={previewImage35s}
                                              alt=""
                                              className="w-100 h-100"
                                            />
                                          ) : (
                                            <div className="d-flex flex-column justify-content-center align-items-center h-100 m-0">
                                              <IconBlue5q />
                                              <p className="mb-0 mt-0 lh-sm h10">{t('profile_section.add_image')}</p>
                                            </div>
                                          )}
                                        </div>
                                      </label>
                                    </div>
                                    <input
                                      hidden
                                      type="file"
                                      name="profile"
                                      id="profile"
                                      accept="image/*"
                                      onChange={(e) => {
                                        let url = URL.createObjectURL(e.target.files[0]);
                                        setPreviewImage35s(url);
                                        setImage35s(e.target.files[0]);
                                      }}
                                      ref={inputRef35s}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </NavItem>
                      <NavItem as="li" className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <ButtonBoxNew name="Done" boxwidth="w-110 me-2" />
                        {/* <button className="addMore h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                          <FontAwesomeIcon
                            icon={faCirclePlus}
                            className="h5 my-auto me-1"
                          />
                          {t('profile_section.add_more_pets')}
                        </button> */}
                        <ButtonBoxNew name="Add More Pets" iconbox="d-block" boxwidth="w-200" />
                      </NavItem>
                    </ul>
                  </div>
                  {/* <div className="dropdown me-3">
              <span role="button" className="" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
              <Link to="#">
              <RadioYes id="pet1" name="pets" text="Yes" />
              </Link>
              </span>
              <ul className="dropdown-menu dropdown-menu-end dropLink mt-3 bg shadow-4 border-0 br-20" aria-labelledby="dropdownMenuLink">
                <NavItem as="li" className='h8 mb-3'>Unacceptable Words <small className="d-inline-block">(Seperate using comma)</small></NavItem>
                <NavItem as="li" className='px-2'>
                  <textarea className="shadow-5in br-20 mb-3 me-2 w-300" placeholder="Word1, Word2, Word3..."> </textarea>
                </NavItem>
                <NavItem as="li" className="px-4 d-flex align-items-center justify-content-center">
                  <button className="h-50 bs-success br-30 w-auto px-4 h8 text-center border-0 shadow-4 me-2">
                    Filter | Hide Words
                  </button>
                </NavItem>
              </ul>
            </div> */}
                  {/* <RadioYes id="pet1" name="pets" text="Yes" /> */}
                  {/* <RadioNo id="pet2" name="pets" text="No" /> */}
                  <ButtonBoxNew id="pet2"  name="No" boxwidth="w-110" />
                </div>
                <div>
                  <div className="slidecontainer">
                    <p>{t('profile_section.number_of_pets')}</p>
                    <RangeSlider
                      value={value}
                      onChange={(changeEvent) =>
                        setValue(changeEvent.target.value)
                      }
                      min={0}
                      max={10}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6 px-2">
                  <div className="custom-form-group">
                    <div className="custom-file-drop-area ">
                      <div className="imgView">
                        <div className="mx-auto mb-4 fileTab h-100">
                          <label onClick={openSelect} className="w-100 h-100">
                            <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                              {previewImage10 ? (
                                <img
                                  src={previewImage10}
                                  alt="Profile Picture"
                                  className="w-100 h-100"
                                />
                              ) : (
                                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                  <IconBlue6q />
                                  <p className="mb-0 mt-1">{t('profile_section.change_cover')}</p>
                                </div>
                              )}
                            </div>
                          </label>
                        </div>
                        <input
                          hidden
                          type="file"
                          name="profile"
                          id="profile"
                          accept="image/*"
                          onChange={(e) => {
                            let url = URL.createObjectURL(e.target.files[0]);
                            setPreviewImage10(url);
                            setImage10(e.target.files[0]);
                          }}
                          ref={inputRef10}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2">
                  <div className="custom-form-group">
                    <div className="custom-file-drop-area ">
                      <div className="imgView">
                        <div className="mx-auto mb-4 fileTab h-100">
                          <label onClick={openSelect2} className="w-100 h-100">
                            <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                              {previewImage11 ? (
                                <img
                                  src={previewImage11}
                                  alt="Profile Picture"
                                  className="w-100 h-100"
                                />
                              ) : (
                                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                  <IconBlue6q />
                                  <p className="mb-0 mt-1">{t('profile_section.change_cover')}</p>
                                </div>
                              )}
                            </div>
                          </label>
                        </div>
                        <input
                          hidden
                          type="file"
                          name="profile"
                          id="profile"
                          accept="image/*"
                          onChange={(e) => {
                            let url = URL.createObjectURL(e.target.files[0]);
                            setPreviewImage11(url);
                            setImage11(e.target.files[0]);
                          }}
                          ref={inputRef11}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0 px-0">
            <div className="col-lg-12 optionalYes d-none">
              <div className="slidecontainer my-4">
                <p>{t('profile_section.number_of_people_in_household')}</p>
                <RangeSlider
                  value={value2}
                  onChange={(changeEvent) =>
                    setValue2(changeEvent.target.value)
                  }
                  min={0}
                  max={10}
                />
              </div>
            </div>
            <div className="col-md-6 ps-0">
              <label>{t('profile_section.do_you_have_any_children')}</label>
              <div className="radioButton my-3 d-flex">
                <div className="d-flex justify-content-between w-100 m-0 px-0">
                  <div className="dropdown position-relative">
                    <span
                      className="dropArrow h-auto"
                      role="button"
                      id="dropdownChildren"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                      data-bs-display="static"
                      >
                      {/* <Link
                        to="#"
                        className="d-flex justify-content-center px-4 align-items-center w-80 shadow-4 h-50s h8 br-30" 
                      >
                        Yes
                      </Link> */}
                      <ButtonBoxNew name="Yes" boxwidth="w-110" />
                    </span>
                    <ul
                      className="dropdown-menu dropdown-menu-end d-none position-absolute w-650 px-3 dropLink bg shadow-4 border-0 br-20 z-index-1"
                      onclick="event.stopPropagation()"
                      aria-labelledby="dropdownChildren"
                    >
                      <NavItem as="li" className="">
                        <table>
                          <thead>
                            <tr>
                              <th>{t('profile_section.children')}</th>
                              <th>{t('profile_section.children_name')}</th>
                              <th>{t('profile_section.age')}</th>
                              <th>{t('profile_section.date_of_birth')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span>{t('profile_section.boy')}</span>
                              </td>
                              <td>
                                <span>{t('profile_section.james')}</span>
                              </td>
                              <td>
                                <span className="petNum">14</span>
                              </td>
                              <td className="d-flex">
                                <span className="w-150 me-3 ms-2">08/26/2008</span>
                                <div className="custom-file-drop-sec w-50s h-50s ms-0">
                                  <div className="imgView h-100 w-100 m-0">
                                    <div className="mx-auto mb-0 fileTab h-100">
                                      <label className="w-100 h-100">
                                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-1 h-100 m-0">
                                          <img
                                              src={img56}
                                              alt=""
                                              className="w-100 h-100"
                                            />
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  className="w-100x px-3 py-1 text-center"
                                  type="text"
                                  placeholder={t('profile_section.girl')}
                                />
                              </td>
                              <td>
                                <input
                                  className="w-100x px-3 py-1 text-center"
                                  type="text"
                                  placeholder={t('profile_section.jesica')}
                                />
                              </td>
                              <td className="new">
                                <RangeSlider
                                  value={value3}
                                  onChange={(changeEvent) =>
                                    setValue3(changeEvent.target.value)
                                  }
                                  min={0}
                                  max={50}
                                />
                              </td>
                              <td className="d-flex">
                                <input
                                  className="w-150 ms-2 me-3"
                                  type="text"
                                  placeholder={t('profile_section._08_26_2014')}
                                />
                                <div className="custom-file-drop-sec w-50s h-50s m-0">
                                  <div className="imgView h-100 w-100 m-0">
                                    <div className="mx-auto mb-0 fileTab h-100">
                                      <label onClick={openSelect37s} className="w-100 h-100">
                                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-1 h-100 m-0">
                                          {previewImage37s ? (
                                            <img
                                              src={previewImage37s}
                                              alt=""
                                              className="w-100 h-100"
                                            />
                                          ) : (
                                            <div className="d-flex flex-column justify-content-center align-items-center h-100 m-0">
                                              <IconBlue5q />
                                              <p className="mb-0 mt-0 lh-sm h10">{t('profile_section.add_image')}</p>
                                            </div>
                                          )}
                                        </div>
                                      </label>
                                    </div>
                                    <input
                                      hidden
                                      type="file"
                                      name="profile"
                                      id="profile"
                                      accept="image/*"
                                      onChange={(e) => {
                                        let url = URL.createObjectURL(e.target.files[0]);
                                        setPreviewImage37s(url);
                                        setImage37s(e.target.files[0]);
                                      }}
                                      ref={inputRef37s}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </NavItem>
                      <NavItem as="li" className="py-3 px-4 d-flex align-items-center justify-content-center">
                        {/* <ButtonBox
                            boxwidth="w-85 me-2 br-30s"
                            clicked="Done"
                            name="Done"
                          />
                        <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                          <FontAwesomeIcon
                            icon={faCirclePlus}
                            className="h5 my-auto me-1"
                          />
                          {t('profile_section.add_more')}
                        </button> */}
                        <ButtonBoxNew name="Done" boxwidth="w-110 me-2" />
                        <ButtonBoxNew name="Add More Pets" iconbox="d-block" boxwidth="w-200" />
                      </NavItem>
                    </ul>
                  </div>
                  <span className="dropdown">
                    {/* <Link
                      to="#"
                      id="noOne"
                      className="d-flex justify-content-center px-4 align-items-center w-80 shadow-4 h-50s h8 br-30"
                    >
                     {t('profile_section.no')}
                    </Link> */}
                    <ButtonBoxNew id="pet2"  name="No" boxwidth="w-110" />
                  </span>
                </div>
                {/* <RadioYes id="childYes" name="childer" text="Yes" /> */}
                {/* <RadioNo id="childNo" name="childer" text="No" /> */}
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <div>
                  <div className="slidecontainer">
                    <p> {t('profile_section.number_of_children')}</p>
                    <RangeSlider
                      value={value1}
                      onChange={(changeEvent) =>
                        setValue1(changeEvent.target.value)
                      }
                      min={0}
                      max={10}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="noOne d-block">
            <div className="col-md-7 mx-auto my-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="custom-form-group">
                    <div className="custom-file-drop-area ">
                      <div className="imgView">
                        <div className="mx-auto mb-4 fileTab h-100">
                          <label onClick={openSelect3} className="w-100 h-100">
                            <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                              {previewImage12 ? (
                                <img
                                  src={previewImage12}
                                  alt="Profile Picture"
                                  className="w-100 h-100"
                                />
                              ) : (
                                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                  <IconBlue6q />
                                  <p className="mb-0 mt-1">{t('profile_section.change_cover')}</p>
                                </div>
                              )}
                            </div>
                          </label>
                        </div>
                        <input
                          hidden
                          type="file"
                          name="profile"
                          id="profile"
                          accept="image/*"
                          onChange={(e) => {
                            let url = URL.createObjectURL(e.target.files[0]);
                            setPreviewImage12(url);
                            setImage12(e.target.files[0]);
                          }}
                          ref={inputRef12}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="custom-form-group">
                    <div className="custom-file-drop-area ">
                      <div className="imgView">
                        <div className="mx-auto mb-4 fileTab h-100">
                          <label onClick={openSelect4} className="w-100 h-100">
                            <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                              {previewImage13 ? (
                                <img
                                  src={previewImage13}
                                  alt="Profile Picture"
                                  className="w-100 h-100"
                                />
                              ) : (
                                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                  <IconBlue6q />
                                  <p className="mb-0 mt-1">{t('profile_section.change_cover')}</p>
                                </div>
                              )}
                            </div>
                          </label>
                        </div>
                        <input
                          hidden
                          type="file"
                          name="profile"
                          id="profile"
                          accept="image/*"
                          onChange={(e) => {
                            let url = URL.createObjectURL(e.target.files[0]);
                            setPreviewImage13(url);
                            setImage13(e.target.files[0]);
                          }}
                          ref={inputRef13}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-10 mx-auto">
              <div className="row">
                <div className="col-md-7">
                  <div className="slidecontainer">
                    <p>{t('profile_section.number_of_people_in_household')}</p>
                    <RangeSlider
                      value={value2}
                      onChange={(changeEvent) =>
                        setValue2(changeEvent.target.value)
                      }
                      min={0}
                      max={10}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                <div className="custom-form-group">
                    <div className="custom-file-drop-area ">
                      <div className="imgView">
                        <div className="mx-auto mb-4 fileTab h-100">
                          <label onClick={openSelect5} className="w-100 h-100">
                            <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                              {previewImage14 ? (
                                <img
                                  src={previewImage14}
                                  alt="Profile Picture"
                                  className="w-100 h-100"
                                />
                              ) : (
                                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                  <IconBlue6q />
                                  <p className="mb-0 mt-1">{t('profile_section.change_cover')}</p>
                                </div>
                              )}
                            </div>
                          </label>
                        </div>
                        <input
                          hidden
                          type="file"
                          name="profile"
                          id="profile"
                          accept="image/*"
                          onChange={(e) => {
                            let url = URL.createObjectURL(e.target.files[0]);
                            setPreviewImage14(url);
                            setImage14(e.target.files[0]);
                          }}
                          ref={inputRef14}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 text-center">
            <ButtonBox
              boxwidth="w-140 mt-0 mb-50 mx-auto"
              clicked="Saved"
              name="Save/Next"
            />
          </div>
        </form>
    </div>
  );
}
