import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import './RegularTheme3Tab.scss';
import sond_1 from '../../../../../../images/sond-1.png';
import sond_2 from '../../../../../../images/sond-2.png';
import gold_star from '../../../../../../images/gold-star.png';
import message_gold from '../../../../../../images/msg-gold.png'
import rank_gold from '../../../../../../images/rank-gold.png'
import cap_gold from '../../../../../../images/cap-gold.png'
import three_btn_m from '../../../../../../images/three-btn-m.png'
import geo_btn from '../../../../../../images/geo-btn.png'
import timeline_gold from '../../../../../../images/timeline-gold.png';
import tab_three_m from '../../../../../../images/tab-three-m.png';
import plalist_gold from '../../../../../../images/plalist-gold.png'
import img1 from '../../../../../../images/img1.png'
import { BiMessageDots } from "react-icons/bi";
import Form from 'react-bootstrap/Form';
import printer_est from '../../../../../../images/pinter-est.png';
import linked_in from '../../../../../../images/linked-in.png';
import you_tube from '../../../../../../images/you-tube.png';
import Social_2 from '../../../../../../images/Social-2.png'
import fb from '../../../../../../images/fb.png';
import dribble_dark from '../../../../../../images/dribble-dark.png';
import insta from '../../../../../../images/insta.png';
import message from '../../../../../../images/message.png';
import answer from '../../../../../../images/answers.png';
import rank from '../../../../../../images/rank.png';
import load from '../../../../../../images/load.png';
import vedio from '../../../../../../images/video.png'
import mic_img from '../../../../../../images/mic-img.png'
import RangeSlider from "react-bootstrap-range-slider";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { MdSkipNext, MdSkipPrevious, } from "react-icons/md";
import { BsPatchCheckFill } from "react-icons/bs"
import { RiVipFill ,RiVipCrown2Fill , RiCloseLine,RiDownloadLine, RiPlayCircleFill,RiPlayMiniLine,RiArrowDropRightFill,RiInstagramFill, RiShareForwardLine, RiFullscreenLine ,RiDownloadCloud2Line } from "react-icons/ri";
import sond_right from '../../../../../../images/sond-right.png';
import sond_left from '../../../../../../images/sond-left.png';
import { RxGear } from "react-icons/rx";
import second_3 from '../../../../../../images/second-3.png'
import testimonial from '../../../../../../images/testimonial.png';
import { BsInfoCircle } from "react-icons/bs";
import testimonial_glass from '../../../../../../images/testimonial-glass.png';
import { RxMagnifyingGlass } from "react-icons/rx";
import tab_3_bg from '../../../../../../images/tab-3-bg.png';
import { useTranslation } from "react-i18next";
import  canda_img  from "../../../../../../images/canada.png"
import { FaBars,FaStar,FaFacebookF,FaTwitter,FaLinkedinIn,FaDribbble,FaPinterest,FaYoutube ,FaTiktok ,FaSnapchatGhost ,FaInstagram  } from "react-icons/fa";
export default function RegularTheme3Tab() {
  const { t, i18n } = useTranslation();


  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };
  

  const [bookmenow, setBookmenow] = useState(false);

  const handleBookmenowClick = () => {
    setBookmenow(!bookmenow);
  };

  const handleBookmenowClose = () => {
    setBookmenow(false);
  };

  const [Competency1, setCompetency1] = useState(10);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setIsOpen(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [isOpenn, setIsOpenn] = useState(false);

  useEffect(() => {
    if (isOpenn) {
      const timer = setTimeout(() => {
        setIsOpenn(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isOpenn]);

  const toggleDropdownn = () => {
    setIsOpenn(!isOpenn);
  };
  return (
    <div>

    

 

          <div className="design-screen-tab-3">
          <div>
          <div className="d-flex w-100">
       
          </div>
          <div className="main-content d-flex">
           
            <div className="content-hero">
            <div className="navbar-top p-2 w-100 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center ">
              </div>
              <div className="bottom-tabs" >
                <RiDownloadCloud2Line />
                  <h6>
                     {t('profile_hero_section.download_cv')}
                  </h6>
                </div>
                <div className="logo-intro">
                    <div className="img-top">
                      <img src={second_3} className="img-logo" alt="logo" >
                      </img>
                    </div>
                  
                  </div>
              <div>
              <div className='search-area w-100'>
                  <form autoComplete="off">
                    <div className='search-field d-flex justify-content-between align-items-center pe-4 ms-auto'>
                    <RxMagnifyingGlass className="icon-mgni" />
                      <input placeholder="Search" className="border-0" type="text" />
                      <div>

                      </div>
                    </div>
                  </form>
                 
                </div>
            
                </div>
                <div className="mi-info">
                    <BsInfoCircle />
                  </div>
               
                  </div>
                  <div className="navbar-top p-2 w-100 d-flex align-items-center justify-content-between">

<div>

<ul className="d-flex social-icon-navbar">

  <li>
    <FaFacebookF />
  </li>
  <li>
    <RiInstagramFill />
  </li>
  <li>
    <FaTwitter />
  </li>
  <li>
    <FaLinkedinIn />
  </li>
  <li>
    <FaDribbble />
  </li>
  <li>
    <FaPinterest />
  </li>
  <li>
    <FaYoutube />
  </li>
</ul>
</div>
<div className="d-flex align-items-center">
<div className="mode-set" >
MODE
</div>
<div className="switch-outer">
<input type="checkbox" hidden="hidden" id="tab3" /> 
 <label class="switch" for="tab3"></label>
 {/* <InputGroup.Checkbox aria-label="Checkbox for following text input" />
         <Form.Control class="switch" aria-label="Text input with checkbox" /> */}
</div>
<div className="social-set" >
SOCIAL
</div>
</div>
</div>
                <div>
            

            
            </div>

              <div className="hero-conent">
                <div className="hero-section">
                <button onClick={toggleDropdown} className={`arrow-button ${isOpen ? 'open' : ''}`}>
                    <RiArrowDropRightFill className="icon-arrow" />
                    </button>
                {isOpen && (
                <div className="intro-card">
                  <div className="intro-inner">
              
<h5>
Hi,
</h5>
<h1>
I am
</h1>
<h2>
Andy rubin
</h2>
<div className="border-card">

</div>
<h4>
CEO
</h4>
<p>
Social Network Strategist
  </p>
                    </div>
                   </div>
                )}
                 <div className='plalist-arrow'>
 <button onClick={toggleDropdownn} className={`arrow-buttonn ${isOpenn ? 'open' : ''}`}>
                    <RiArrowDropRightFill className="icon-arrow" />
                    </button>
                       {isOpenn && (
                    <div className="playlist-card">
                
                    <div className="playlist-section">

                    <div className="playlist-section-text">
                    <div className="meu-playlist-section">
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="next-icon">
                          <MdSkipNext />
                        </div>
                        <div className="mic-img  reg-img">
                          <img src={mic_img} alt="mic" ></img>
                        </div>
                        <div className="mic-img meu-img">
                            <img src={plalist_gold} alt="mic" ></img>

                          </div>
                        <div className="next-icon">
                          <MdSkipPrevious />
                        </div>
                      </div>
                      <div className="d-flex align-items-center mt-2 mb-2 justify-content-center " >
                        <div className="slidecontainer mb- text-start">
                          <RangeSlider value={Competency1} onChange={changeEvent => setCompetency1(changeEvent.target.value)} min={0} max={15} />
                        </div>
                        <div className="next-icon-center">
                          <RiDownloadLine className="iconss" />
                        </div>
                        <div className="video-icon reg-icon">
                          <RiPlayCircleFill />
                        </div>
                        <div className=" meu-icon">
                            <div className="video-icon">
                              <RiPlayMiniLine />
                            </div>

                          </div>
                        <div className="next-icon-center">
                          <RiShareForwardLine className="iconss" />
                        </div>
                        <div className="setting-icons ">
                          <RxGear className="icons-set" />
                          <RiFullscreenLine className="icons-set" />
                        </div>
                      </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center ">
                        <div className="reg-sond">
                          <img src={sond_right}  alt="" ></img>
                        </div>
                        <div className="glass-sond">
                          <img src={sond_2} alt="" ></img>
                        </div>
                        <div>
                          <div className="text-gree">
                            {t('profile_section.episode_5_season_1')}

                          </div>
                          <div className="side-text">
                            {t('profile_hero_section.seven_figure_bosses_series')}

                          </div>
                        </div>


                        <div className="reg-sond">
                          <img src={sond_left} alt="" ></img>
                        </div>
                        <div className="glass-sond">
                          <img src={sond_1} alt="" ></img>
                        </div>
                      </div>
                    </div>
</div>
                    </div>
                       )}
                       </div>
                  <img src={tab_3_bg} alt="desktop-3" className="content-img hero-section-reg" >
                  </img>
                  <div className="hero-section-mue">
                  <img src={tab_three_m} alt="backgrnd-2" className="content-img" >
                  </img>
    
                </div>
                </div>
                <div className="himself-section">
                  <div className="tab-section d-flex">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="d-flex tab-section">


                  
                      <div className="align-items-center justify-center  w-100 mb-0">

                        <Nav variant="pills" className="w-100  align-items-center justify-content-center ">
                          <ul className="   my-4 pb-2 align-items-center modal-tabs justify-content-center">

                            <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
                              <Nav.Link className=" m-0 p-0 design-about  border-0" eventKey="first">                  <span className="d-flex align-items-center "> {t('profile_section.who_am_i')}</span>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="margin-left-scnd margin-left-btn" >
                              <Nav.Link className=" m-0 p-0 design-about  border-0" eventKey="second">    <span className="d-flex align-items-center ">
                                {t('profile_section.what_i_do')}</span> </Nav.Link>

                            </Nav.Item >
                            <Nav.Item as="li" className="margin-left-scnd margin-left-btn" >
                              <Nav.Link className=" m-0 p-0 design-about  border-0" eventKey="three">                  <span className="d-flex align-items-center "> {t('profile_section.who_is_my_service_for')}</span></Nav.Link>

                            </Nav.Item>
                            <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
                              <Nav.Link className=" m-0 p-0 design-about  border-0" eventKey="forr">
                                <span className="d-flex align-items-center ">
                                  {t('profile_section.why_work_with_me')}</span>
                              </Nav.Link>

                            </Nav.Item>
                          </ul>
                        </Nav>
                      </div>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className=" tab-himself-text">
                          <div className=" tab-himself-text d-flex">
                          <div className="himself-left">
                          <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                          <div className="himself-right">
                            <div className="playlist-section">

                         
                              <p className="mb-2">
                                And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.
                              </p>
                            </div>
                          </div>

                        </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div className=" tab-himself-text">
                          <div className=" tab-himself-text d-flex">
                          <div className="himself-left">
     <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                          <div className="himself-right">
                            <div className="playlist-section">

                          
                              <p className="mb-2">
                                And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.
                              </p>
                            </div>
                          </div>

                        </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="three">
                          <div className=" tab-himself-text">
                          <div className=" tab-himself-text d-flex">
                          <div className="himself-left">
     <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                          <div className="himself-right">
                            <div className="playlist-section">

                            
                              <p className="mb-2">
                                And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.
                              </p>
                            </div>
                          </div>

                        </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="forr">
                          <div className=" tab-himself-text">
                          <div className=" tab-himself-text d-flex">
                          <div className="himself-left">
     <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                          <div className="himself-right">
                            <div className="playlist-section">

                          
                              <p className="mb-2">
                                And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.
                              </p>
                            </div>
                          </div>

                        </div>
                          </div>
                        </Tab.Pane>
                      
                      </Tab.Content>
                      <div className="video-img">
                                <img src={vedio} alt="vedio" ></img>
                              </div>
                    </div>
                  </Tab.Container>
                   

                  </div>
                  <div className="bookme-oterr">
                  <button className="book-me-btn"  onClick={handleBookmenowClick} >Book me Now</button>
                  <div className="book-me-mue"  onClick={handleBookmenowClick}>
  <img src={three_btn_m} alt="two-btn" ></img>
  </div>
  <div className="book-me-geo"  onClick={handleBookmenowClick}>
  <img src={geo_btn} alt="two-btn" ></img>
  </div>
                  {bookmenow && (
                        <div className="bookme-inner">
                <div className="hamburger-outer">
                  <div className=' mb-5 w-100'>
                    <button className="close-btn" onClick={handleBookmenowClose}>
                    <RiCloseLine />
                    </button>
                    <div className="w-100 reg-timeline mt-5">
                          <ul className="timelinee">
                            <li className="active">
                              <div className="timeline-topp">
                                9
                              </div>
                              <div className="timeline-icon">
                                <img className="message" src={message} alt="message" ></img>
                                <p>
        {t('profile_hero_section.answers')}
                                </p>
                              </div>
                            </li>
                            <li> <div className="timeline-topp">
                              300/
                              1000
                            </div>
                              <div className="timeline-icon">
                                <img src={load} className="load" alt="load" ></img>
                                <p>
                                  Social
                                  score
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="timeline-topp">
                                #1
                              </div>
                              <div className="timeline-icon">
                                <img src={rank} alt="rank" ></img>
                                <p>
                                  Rank
                                </p>
                              </div>
                            </li>
                            <li className="mr-0">
                              <div className="timeline-topp">
                                5
                              </div>
                              <div className="timeline-icon">

                                <img src={answer} className="answer" alt="answer" >
                                </img>

                                <p>
                                {t('profile_hero_section.answers')}</p>
                              </div>
                            </li>

                          </ul>
                          <div className=" forms-inpt">
                                <div>
                                  <Form.Group className="w-100" controlId="reg-Form.Controlname">

                                    <Form.Control type="input" placeholder="Type Your Name here" />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group className="mt-2 w-100" controlId="reg-forn.Controlemail">

                                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                                  </Form.Group>
                                </div>
                                <div className="book-me-btnn mt-2" >
                                  Book me Now
                                </div>
                              </div>
                        </div>
                        <div className="w-100 meu-timeline">
                        <div className=" forms-inpt">
                                <div>
                                  <Form.Group className="w-100" controlId="reg-Form.Controlname">

                                    <Form.Control type="input" placeholder="Type Your Name here" />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group className="mt-2 w-100" controlId="reg-forn.Controlemail">

                                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                                  </Form.Group>
                                </div>
                                <div className="book-me-mue mt-2 m-auto text-center"  >
  <img src={three_btn_m} alt="two-btn" ></img>
  </div>
  <div className="book-me-geo mt-2 m-auto  text-center" >
  <img src={geo_btn} alt="two-btn" ></img>
  </div>
                              </div>

              </div>
                  </div>


                </div>
                
                </div>
              )}

    
        </div>
         
                </div>
          

            
              </div>

            </div>
            <div className="hero-sidebar text-center">
            <div className="mr-3 zoom-section">
                  <div className="icons-circle">
                  <div id="parent-circle">
        <div class="circle printerest"><FaTwitter /></div>
        <div class="circle facebookk"><FaLinkedinIn /></div>
        <div class="circle instaa"><FaTiktok /></div>
        <div class="circle twitter"><FaYoutube /></div>
        <div class="circle innn"><FaSnapchatGhost /></div>
        <div class="circle tiktok"><BiMessageDots /></div>
        <div class="circle videoo"><BsPatchCheckFill /></div>
        <div class="circle videooo"><img src={canda_img} alt="canada" /></div>
        <div class="circle snapchat"><RiVipCrown2Fill /></div>
        <div class="circle logo-f"><RiVipFill /></div>
        <div class="circle logo2"><FaPinterest /></div>
        <div class="circle logo-3"><FaFacebookF /></div>
        <div class="circle logo-4"><FaInstagram /></div>
      
   
      </div>
                  </div>
                  <img src={img1} alt="img1" className="profile-img" ></img>
                </div>
              <div className="name-btn">
                DYNA APPOLON
              </div>
              <p>
                CEO | 9 to 5 Escapee
              </p>
              <div className="bars-hambrger">
              <div className="navbar-bars bars-green mb-3" onClick={handleHamburgerClick}>
              <FaBars />
              </div>
              {hamburger && (
                <div className="hamburger-outer">
                  <div className=' mb-5 w-100'>
                    <button className="close-btn" onClick={handleHamburgerClose}>
                    <RiCloseLine />
                    </button>
                    <div className="d-flex align-items-center">
                              
                    <div className="switch-outer">
                      <input type="checkbox" hidden="hidden" id="mobile-tab" />
                      <label class="switch" for="mobile-tab"></label>
                    </div>
                    <div className="social-set" >
                    {t('profile_hero_section.social_mode')}
                    </div>
                      </div>
              
                  </div>

                  <div>
                  <ul className="mb-3">
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')}1
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')}2
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')} 3
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')} 4
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')} 5
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')}6
                      </li>
                    </ul>
                    <p className="testimonial-p">
                    {t('profile_hero_section.testimonials')}
                    </p>
                    <div>
         
                      <div className="d-flex w-100">

                        <div className="testimonial">
                          <div className="timeline-top d-flex">
                            <div className="d-flex">
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                            </div>
                            <div>
                              <p>
                        {t('profile_hero_section.dummy_text_of_the_printing_printing')}
                        <p><b>   {t('profile_hero_section.client_name_here')}</b>
                          {t('profile_hero_section.designation_here')}</p>
                      </p>


                            </div>

                          </div>
                          <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                        </div>
                        <div className="testimonial">
                          <div className="timeline-top d-flex">
                            <div className="d-flex">
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                            </div>
                            <div>
                              <p>
                        {t('profile_hero_section.dummy_text_of_the_printing_printing')}
                        <p><b>   {t('profile_hero_section.client_name_here')}</b>
                          {t('profile_hero_section.designation_here')}</p>
                      </p>


                            </div>

                          </div>
                          <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                        </div>
                        <div className="testimonial">
                          <div className="timeline-top d-flex">
                            <div className="d-flex">
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                            </div>
                            <div>
                              <p>
                        {t('profile_hero_section.dummy_text_of_the_printing_printing')}
                        <p><b>   {t('profile_hero_section.client_name_here')}</b>
                          {t('profile_hero_section.designation_here')}</p>
                      </p>


                            </div>

                          </div>
                          <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>

                        </div>
                      </div>
                      <ul className="mt-5 top-padding reg-social d-flex">
                        <li className="pb-3">
                          <img src={fb} alt="fb" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={insta} alt="insta" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={Social_2} alt="twitter" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={linked_in} alt="linked-in" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={dribble_dark} alt="dribble" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={printer_est} alt="printerest" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={you_tube} alt="youtube" ></img>
                        </li>
                      </ul>
                      <div className="meu-social">
                      <ul className="mt-5 top-padding">
                    <li >
                      <FaFacebookF />
                    </li>
                    <li >
                      <RiInstagramFill />
                    </li>
                    <li >
                      <FaTwitter />
                    </li>
                    <li >
                      <FaLinkedinIn />
                    </li>
                    <li >
                      <FaDribbble />
                    </li>
                    <li >
                      <FaPinterest />
                    </li>
                    <li >
                      <FaYoutube />
                    </li>
                    </ul>
                    </div>
                      <div className="bottom-tabs" >
                        <RiDownloadCloud2Line />
                        <h6>
                           {t('profile_hero_section.download_cv')}
                        </h6>
                      </div>


                    </div>

                  </div>

                </div>
              )}
            
            </div>
              <div>
              <div className="w-100 timeline-desktop reg-timeline">
                <ul className="timeline">
                  <li className="active">
                    <div className="timeline-top">
                      9
                    </div>
                    <div className="timeline-icon">
                      <img className="message" src={message} alt="message" ></img>
                      <p>
                        Answers
                      </p>
                    </div>
                  </li>
                  <li> <div className="timeline-top">
                    300/
                    1000
                  </div>
                    <div className="timeline-icon">
                      <img src={load} className="load" alt="load" ></img>
                      <p>
                        Social
                        score
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-top">
                      #1
                    </div>
                    <div className="timeline-icon">
                      <img src={rank} alt="rank" ></img>
                      <p>
                        Rank
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-top">
                      5
                    </div>
                    <div className="timeline-icon">

                      <img src={answer} className="answer" alt="answer" >
                      </img>

                      <p>
                        Best
                        Answers</p>
                    </div>
                  </li>

                </ul>

              </div>
              <div className="w-100 meu-timeline">
                <ul className="timeline">
                  <div className="active-oter">
                    <li className="active">
                      <div className="timeline-top">
                        9
                      </div>
                      <div className="timeline-icon">
                        <img className="message" src={message_gold} alt="message" ></img>
                        <p>
{t('profile_hero_section.answers')}
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li> <div className="timeline-top">
                      300/
                      1000
                    </div>
                      <div className="timeline-icon">
                        <img src={rank_gold} className="load" alt="load" ></img>
                        <p>
                          {t('profile_hero_section.social_score')}
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li>
                      <div className="timeline-top">
                        #1
                      </div>
                      <div className="timeline-icon">
                        <img src={gold_star} alt="rank" ></img>
                        <p>
                          Rank
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li>
                      <div className="timeline-top">
                        5
                      </div>
                      <div className="timeline-icon">

                        <img src={cap_gold} className="answer" alt="answer" >
                        </img>

                        <p>
                          {t('profile_hero_section.best_answers')}</p>
                      </div>
                    </li>
                  </div>


                </ul>

              </div>
              <div className="bookme-oter">
              <button className="book-me-btn-sidebar"  onClick={handleBookmeClick}>
                  Book me
                </button>
                <div className="book-me-mue"  onClick={handleBookmeClick}>
  <img src={three_btn_m} alt="two-btn" ></img>
  </div>
  <div className="book-me-geo"  onClick={handleBookmeClick} >
  <img src={geo_btn} alt="two-btn" ></img>
  </div>
                {bookme && (
                        <div className="bookme-inner">
                <div className="hamburger-outer">
                  <div className=' mb-5 w-100'>
                    <button className="close-btn" onClick={handleBookmeClose}>
                    <RiCloseLine />
                    </button>
                    <div className="w-100 reg-timeline mt-5">
                          <ul className="timelinee">
                            <li className="active">
                              <div className="timeline-topp">
                                9
                              </div>
                              <div className="timeline-icon">
                                <img className="message" src={message} alt="message" ></img>
                                <p>
        {t('profile_hero_section.answers')}
                                </p>
                              </div>
                            </li>
                            <li> <div className="timeline-topp">
                              300/
                              1000
                            </div>
                              <div className="timeline-icon">
                                <img src={load} className="load" alt="load" ></img>
                                <p>
                                  Social
                                  score
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="timeline-topp">
                                #1
                              </div>
                              <div className="timeline-icon">
                                <img src={rank} alt="rank" ></img>
                                <p>
                                  Rank
                                </p>
                              </div>
                            </li>
                            <li className="mr-0">
                              <div className="timeline-topp">
                                5
                              </div>
                              <div className="timeline-icon">

                                <img src={answer} className="answer" alt="answer" >
                                </img>

                                <p>
                                  Best
        {t('profile_hero_section.answers')}</p>
                              </div>
                            </li>

                          </ul>
                          <div className=" forms-inpt">
                                <div>
                                  <Form.Group className="w-100" controlId="reg-Form.Controlname">

                                    <Form.Control type="input" placeholder="Type Your Name here" />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group className="mt-2 w-100" controlId="reg-forn.Controlemail">

                                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                                  </Form.Group>
                                </div>
                                <div className="book-me-btnn mt-2" >
                                  Book me Now
                                </div>
                                <div className="book-me-mue"  >
  <img src={three_btn_m} alt="two-btn" ></img>
  </div>
  <div className="book-me-geo"  >
  <img src={geo_btn} alt="two-btn" ></img>
  </div>
                              </div>
                        </div>
                        <div className="w-100 meu-timeline">
                        <div className=" forms-inpt">
                                <div>
                                  <Form.Group className="w-100" controlId="reg-Form.Controlname">

                                    <Form.Control type="input" placeholder="Type Your Name here" />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group className="mt-2 w-100" controlId="reg-forn.Controlemail">

                                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                                  </Form.Group>
                                </div>
                                <div className=" mt-2 text-center" >
                                <img src={three_btn_m} alt="bookbtn" ></img>
                                </div>
                              </div>

              </div>
              
                  </div>


                </div>
                
                </div>
              )}
              
      
    
        </div>
         
            <div className="w-100">
  <div className="testimonial">
  <div className="timeline-top d-flex">
    <div className="d-flex">
<FaStar className="icon-green" />
<FaStar className="icon-green" />
<FaStar className="icon-green" />
<FaStar className="icon-green" />
<FaStar className="icon-green" />
    </div>
    <div>
    <p>
    dummy text of the 
    printing  printing 
    <p><b>Client Name Here</b>
Designation Here</p>
   </p>


    </div>

                        </div>
                        <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
  </div>
  <div className="testimonial">
  <div className="timeline-top d-flex">
    <div className="d-flex">
<FaStar className="icon-green" />
<FaStar className="icon-green" />
<FaStar className="icon-green" />
<FaStar className="icon-green" />
<FaStar className="icon-green" />
    </div>
    <div>
    <p>
    dummy text of the 
    printing  printing 
    <p><b>Client Name Here</b>
Designation Here</p>
   </p>


    </div>

                        </div>
                      <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
  </div>
  <div className="testimonial mb-4">
  <div className="timeline-top ">
    <div className="d-flex">
<FaStar className="icon-green" />
<FaStar className="icon-green" />
<FaStar className="icon-green" />
<FaStar className="icon-green" />
<FaStar className="icon-green" />
    </div>
    <div>
    <p>
    dummy text of the 
    printing  printing 
    <p><b>Client Name Here</b>
Designation Here</p>
   </p>


    </div>

                        </div>
                      <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
  </div>
</div>
                



              
              </div>
            </div>
            <div>

            </div>
          </div>
        </div>
            </div>
        
        

     

    </div>
  );
}
