import * as React from 'react';
import user from '../../../images/img1.png';
import ReactCurvedText from "react-curved-text";
import TabSlider from '../../global/tabSlider';
import { useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import ButtonBox from '../../global/ButtonBox';
import { SubscriptionAction } from '../../../redux/actions/subscriptionActions';

export default function App() {

    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [close, setClose] = useState(false);
    const handleSubmit = async (e) =>{
        e.preventDefault();
       
         if (!email){
            toast('Email is required');
          }

          else{
            try {
                 let data = {
                     email:email,
                 }
                 dispatch(SubscriptionAction(data,setEmail));
                 setClose(true);
         
               } catch (error) {
                 console.log("err", error);
               }
             }
          }


    return (
        <div className="w-100 d-inline-block">
             <form onSubmit={handleSubmit}>
            <div className="container">
                <h3 className="mt-lg-4 text-center pb-40">SUBSCRIPTION CENTRE</h3>
                
                <div className="row">
                    <div className="col-lg-6 col-12 mx-auto text-center pe-lg-5 mb-5 pb-3">
                        <div className="shadow-4 br-40 w-320 p-40 mx-auto text-rounded pt-5 pb-3 bg position-relative z-index-2 overflow-hidden">
                            <ReactCurvedText
                            width={370}
                            height={310}
                            cx={150}
                            cy={200}
                            rx={100}
                            ry={100}
                            reversed={true}
                            startOffset={0}
                            text="Subscription to our weekly newsletter"
                            />
                            <div className="shadow-5in mb-4 position-relative bg z-index br-100 w-180 d-flex align-items-center justify-content-center mx-auto">
                                <img src={user} alt="" className="img-className"/>
                            </div>
                            <h5>WEEKLY GOSSIP DROPS</h5>
                        </div>
                       
                        <div className='email-sec p-30 mt-n6 mx-auto d-flex flex-column justify-content-end align-items-center'>
                            <p className='m-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                            <input type="email" className='br-30 mt-4' placeholder="Enter your email" value={email} onChange={(e)=> {setEmail(e.target.value);}} />
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade" id="tab1" role="tabpanel" aria-labelledby="tab1"></div>
                <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2"></div>
                <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3"></div>
                <div className="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4"></div>
                <div className="tab-pane fade show active" id="tab5" role="tabpanel" aria-labelledby="tab5">
                </div>
                <div className="tab-pane fade" id="tab6" role="tabpanel" aria-labelledby="tab6"></div>
                <div className="tab-pane fade" id="tab7" role="tabpanel" aria-labelledby="tab7"></div>
                <div className="tab-pane fade" id="tab8" role="tabpanel" aria-labelledby="tab8"></div>
                <div className="tab-pane fade" id="tab9" role="tabpanel" aria-labelledby="tab9"></div>
            </div>
            <div className='container d-none d-lg-block'>
                <div className='scroll-bar w-100 shadow-4in br-20 my-30'></div>
            </div>
            <div className='container tab-button mb-4 pb-3 mt-5 mt-lg-0'>
                <div id="myTab" className='slidetab' role="tablist">
                    <TabSlider />
                </div>
            </div>
            <div className='container text-center my-5'>
             <ButtonBox boxwidth="w-185 mt-0 mb-50" close={close} clicked="SUBSCRIBED" name="SUBSCRIBE NOW" />
            </div>
            </form>
        </div>
    );
  }