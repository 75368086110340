import React from "react";
import { useState } from "react";
import '../../ServicePackagePlanThemes.scss'
import { FcCheckmark } from 'react-icons/fc'
import '../regularTheme4Desktop/RegularTheme4Desktop.scss';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { BsCheckLg } from "react-icons/bs"
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai"
import { IoIosArrowForward } from "react-icons/io"
import { useTranslation } from "react-i18next";
export default function RegularTheme4Desktop() {

  return (
    <div>
      <div className="">
        <div>
          <div className="main-content p-5">
            <div className="d-flex align-items-center justify-content-center mb-5">
              <div className="toggle_button">
                <button type="" className="btn active_btn">Monthly</button>
                <button type="" className="btn1 ">Yearly</button>
              </div>
            </div>
            <div className="d-flex align-items-center relative">
              <div className="row px-4 plan_content mx-auto">
                <div className="col-md-4">
                  <div className="plan_outer">
                    <div className="shape_wrapper">
                      <div className="shape_outer bottom">
                        <div className="ribbon_wrapper">
                          <div className="ribbon">
                            <span className="ribbon1"><span>
                              <div className="price">
                                <p>Monthly</p>
                                <h2>$29</h2>
                              </div>
                            </span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="plan_listing">
                      <div className="mt-3">
                        <h3>Lorem Ipsum</h3>
                        <p>Sit amet consectetur</p>
                      </div>
                      <ul>
                        <li>
                          <div className="icon_outer">
                          <FcCheckmark className="icon" />
                          </div>
                          Lorem ipsum dolor</li>
                        <li>
                          <div className="icon_outer">
                          <FcCheckmark className="icon" />
                          </div>
                          Sit amet consect</li>
                        <li>
                          <div className="icon_outer">
                          <RxCross2 className="icon text-danger" />
                          </div>
                          Sitt isporta vestibulum
                        </li>
                        <li>
                          <div className="icon_outer">
                          <RxCross2 className="icon text-danger" />
                          </div>
                          eu velit viverra quis vel
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-center fixed_btn">
                      <button className="btn mt-4" type="button">Order Now
                        <IoIosArrowForward className="ms-2" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="plan_outer">
                    <div className="shape_wrapper">
                      <div className="shape_outer bottom">
                        <div className="ribbon_wrapper">
                          <div className="ribbon">
                            <span className="ribbon1"><span>
                              <div className="price">
                                <p>Monthly</p>
                                <h2>$59</h2>
                              </div>
                            </span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <button type="" className="green_btn1">Most Popular</button>
                    </div>
                    <div className="plan_listing">
                      <div>
                        <h3>Lorem Ipsum</h3>
                        <p>Sit amet consectetur</p>
                      </div>
                      <ul>
                        <li>
                          <div className="icon_outer">
                          <FcCheckmark className="icon" />
                          </div>
                          Lorem ipsum dolor</li>
                        <li>
                          <div className="icon_outer">
                          <FcCheckmark className="icon" />
                          </div>
                          Sit amet consect</li>
                        <li>
                          <div className="icon_outer">
                          <FcCheckmark className="icon" />
                          </div>
                          Sitt isporta vestibulum
                        </li>
                        <li>
                          <div className="icon_outer">
                          <FcCheckmark className="icon" />
                          </div>
                          eu velit viverra quis vel
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-center fixed_btn">
                      <button className="btn mt-4" type="button">Order Now
                        <IoIosArrowForward className="ms-2" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="plan_outer">
                    <div className="shape_wrapper">
                      <div className="shape_outer bottom">
                        <div className="ribbon_wrapper">
                          <div className="ribbon">
                            <span className="ribbon1"><span>
                              <div className="price">
                                <p>Monthly</p>
                                <h2>$99</h2>
                              </div>
                            </span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="plan_listing">
                      <div className="mt-3">
                        <h3>Lorem Ipsum</h3>
                        <p>Sit amet consectetur</p>
                      </div>
                      <ul>
                        <li>
                          <div className="icon_outer">
                          <FcCheckmark className="icon" />
                          </div>
                          Lorem ipsum dolor</li>
                        <li>
                          <div className="icon_outer">
                          <FcCheckmark className="icon" />
                          </div>
                          Sit amet consect</li>
                        <li>
                          <div className="icon_outer">
                          <RxCross2 className="icon text-danger" />
                          </div>
                          Sitt isporta vestibulum
                        </li>
                        <li>
                          <div className="icon_outer">
                          <RxCross2 className="icon text-danger" />
                          </div>
                          eu velit viverra quis vel
                        </li>
                        <li>
                          <div className="icon_outer">
                          <AiFillStar className="icon text_yellow" />
                          </div>
                          Sitt isporta vestibulum
                        </li>
                        <li>
                          <div className="icon_outer">
                          <AiFillStar className="icon text_yellow" />
                          </div>
                          eu velit viverra quis vel
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-center fixed_btn">
                      <button className="btn mt-4" type="button">Order Now
                        <IoIosArrowForward className="ms-2" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>




    </div>



  );
}
