import React from 'react'
import TrackingComponentSec from './TrackingComponentSec'
import IntegAddTrackFrom from '../IntegAddTrackFrom';
import icon from "../../../../../../images/formicon/user/icon.png";
import icon1 from "../../../../../../images/formicon/user/icon1.png";
import icon2 from "../../../../../../images/formicon/user/icon2.png";
import icon3 from "../../../../../../images/formicon/user/icon3.png";
import icon4 from "../../../../../../images/formicon/user/icon4.png";
import icon5 from "../../../../../../images/formicon/user/icon5.png";
import icon6 from "../../../../../../images/formicon/user/icon6.png";
import icon6s from "../../../../../../images/formicon/user/icon6s.png";
import icon7 from "../../../../../../images/formicon/user/icon7.png";
import icon8 from "../../../../../../images/formicon/user/icon8.png";
import icon8s from "../../../../../../images/formicon/user/icon8s.png";
import icon9 from "../../../../../../images/formicon/user/icon9.png";
import icon10 from "../../../../../../images/formicon/user/icon10.png";
import icon11 from "../../../../../../images/formicon/user/icon11.png";
import icon38 from "../../../../../../images/formicon/icon38.png";
import TrackingComponent from './TrackingComponent';
export default function TrackingUserMedia() {


  return (
    <div className='px-4'>
      <ul className="customDrop6 customDrop1s max-h-auto customDrop2s w-100 shadow-none position-relative">
            <div className="dropheight h-auto">
              <TrackingComponentSec Name="PROFESSIONAL WEBSITE" icon={icon} Dropname="PROFESSIONAL WEBSITE"/>
              <TrackingComponentSec Name="1000 SOCIALITE | WEBSITE" icon={icon1} Dropname="1000 SOCIALITE | WEBSITE"/>
              <TrackingComponentSec Name="NICHE BLOG NETWORK | SITE" icon={icon2} Dropname="NICHE BLOG NETWORK | SITE"/>
              <TrackingComponentSec Name="9 TO 5 ESCAPEE | WEBSITE" icon={icon3} Dropname="9 TO 5 ESCAPEE | WEBSITE"/>
              <TrackingComponentSec Name="7 FIGURE BOSSES | WEBSITE" icon={icon38} Dropname="7 FIGURE BOSSES | WEBSITE" />
              <TrackingComponentSec Name="VIDEO CHANNEL | WEBSITE" icon={icon4} Dropname="VIDEO CHANNEL | WEBSITE"/>
              <TrackingComponentSec Name="PODCAST | WEBSITE" icon={icon5} Dropname="PODCAST | WEBSITE"/>
              <TrackingComponentSec Name="EVENTS | WEBSITE" icon={icon6} Dropname="EVENTS | WEBSITE"/>
              <TrackingComponentSec Name="CONTESTS | WEBSITE" icon={icon6s} Dropname="CONTESTS | WEBSITE"/>
              <TrackingComponentSec Name="MARKETPLACE | WEBSITE" icon={icon7} Dropname="MARKETPLACE | WEBSITE"/>
              <TrackingComponentSec Name="E-MAGAZINES | WEBSITE" icon={icon8} Dropname="E-MAGAZINES | WEBSITE"/>
              <TrackingComponentSec Name="PRESS RELEASE | WEBSITE" icon={icon8s} Dropname="PRESS RELEASE | WEBSITE" />
              <TrackingComponentSec Name="GUEST POST | WEBSITE" icon={icon9} Dropname="GUEST POST | WEBSITE"/>
              <TrackingComponentSec Name="LMS COURSE | WEBSITE" icon={icon10} Dropname="LMS COURSE | WEBSITE" />
              <TrackingComponentSec Name="AFFILIATE  | WEBSITE" icon={icon11} Dropname="AFFILIATE  | WEBSITE"/>
              <TrackingComponentSec Name="OTHER  | WEBSITE" icon={icon11} Dropname="OTHER  | WEBSITE"/>
              <TrackingComponentSec Name="OTHER  | WEBSITE" icon={icon11} Dropname="OTHER  | WEBSITE"/>
              <TrackingComponent Dropname="Other ?  Type here...." />
              <IntegAddTrackFrom />
            </div>
          </ul>
    </div>
  )
}
