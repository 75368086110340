import React from "react";
import PersonalityZodic from "../../../../global/PersonalityZodic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import ButtonBoxNew from "../../../../global/ButtonBoxNew";

export default function PersonalThree() {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className="text-center mb-5">
                <h5 className="fw-bold"> {t('profile_section.identify_your_persinality')}</h5> 
            </div>
            <div className="zodiacSection">
                <div className="row">
                    <h5 className="mb-3 p-0 h9"> {t('profile_section.personality')}</h5>
                    <div className="col-lg-12 col-md-12 col-12 text-center radioSec">
                        <h4 className="shadow-5in bg px-2 py-2 h9 rounded-1 fw-bold d-inline-block headingMinus"> {t('profile_section.personality')}</h4>
                        <div className="shadow-4 br-30 p-30 text-center">
                        <PersonalityZodic Heading1="Introvert" Heading2="Extrovert" name="extr1" />
                        <PersonalityZodic Heading1="Submissive" Heading2="Dominant" name="domi1" />
                        <PersonalityZodic Heading1="Realism" Heading2="Idealism" name="ideal1" />
                        <PersonalityZodic Heading1="Analytical" Heading2="Intuitive" name="intu1" />
                        <PersonalityZodic Heading1="Cautious" Heading2="Risk Taker" name="risk1" />
                        <PersonalityZodic Heading1="Reactive" Heading2="Proactive" name="proac1" />
                        <PersonalityZodic Heading1="Avoid Stress" Heading2="Thrives On Stress" name="stress1" />
                        <PersonalityZodic Heading1="Inflexible" Heading2="Adaptable" name="adpt1" />
                        <PersonalityZodic Heading1="Ordinary" Heading2="Extraordinary" name="extra1" />
                        <PersonalityZodic Heading1="Skeptic" Heading2="Promoter" name="prom1" />
                        <PersonalityZodic Heading1="Indecisive" Heading2="Decisive" name="decisi1" />
                        </div>
                        <div className="shadow-4 br-30 p-40 mt-3 text-start">
                            <h3 className="h9 mb-3"> CORE VALUE (S)</h3>
                            <ul>
                                <NavItem as="li">
                                    <label className="mb-2 w-100">{t('profile_section.value_1')}</label>
                                    <input
                                    placeholder={t('profile_section.authenticity')}
                                    className="shadow-5in br-10 w-100 mb-4"
                                    type="text"
                                    />
                                </NavItem>
                                <NavItem as="li">
                                    <label className="mb-2 w-100">{t('profile_section.value_2')}</label>
                                    <input
                                    placeholder={t('profile_section.sustainability')}
                                    className="shadow-5in br-10 w-100 mb-4"
                                    type="text"
                                    />
                                </NavItem>
                                <NavItem as="li">
                                    <label className="mb-2 w-100">{t('profile_section.value_3')}</label>
                                    <input
                                    placeholder={t('profile_section.responsibility')}
                                    className="shadow-5in br-10 w-100 mb-4"
                                    type="text"
                                    />
                                </NavItem>
                                <NavItem as="li">
                                    <label className="mb-2 w-100">{t('profile_section.value_4')}</label>
                                    <input
                                    placeholder={t('profile_section.integrity')}
                                    className="shadow-5in br-10 w-100 mb-4"
                                    type="text"
                                    />
                                </NavItem>
                                <NavItem as="li" className="d-flex align-items-center justify-content-center">
                                    {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                                    {t('profile_section.done')}
                                    </button> */}
                                    <ButtonBoxNew  name="Done" boxwidth=" w-85 me-3" />
                                    {/* <button className="addMore h-50 bg br-30 w-150 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                                        <FontAwesomeIcon icon={faCirclePlus} className="h5 my-auto me-2" />
                                        {t('profile_section.add_more')}

                                    </button> */}
                                    <ButtonBoxNew  name="Add More" boxwidth="w-200" iconbox="d-block" />
                                </NavItem>
                            </ul>
                        </div>
                        <div className="shadow-4 br-30 p-40 mt-3 text-start">
                            <h3 className="h9 mb-3">{t('profile_section.personal_values')}</h3>
                            <ul>
                                <NavItem as="li">
                                    <label className="mb-2 w-100">{t('profile_section.value_1')}</label>
                                    <input
                                    placeholder={t('profile_section.authenticity')}
                                    className="shadow-5in br-10 w-100 mb-4"
                                    type="text"
                                    />
                                </NavItem>
                                <NavItem as="li">
                                    <label className="mb-2 w-100">{t('profile_section.value_2')}</label>
                                    <input
                                    placeholder={t('profile_section.sustainability')}
                                    className="shadow-5in br-10 w-100 mb-4"
                                    type="text"
                                    />
                                </NavItem>
                                <NavItem as="li">
                                    <label className="mb-2 w-100">{t('profile_section.value_3')}</label>
                                    <input
                                    placeholder={t('profile_section.responsibility')}
                                    className="shadow-5in br-10 w-100 mb-4"
                                    type="text"
                                    />
                                </NavItem>
                                <NavItem as="li">
                                    <label className="mb-2 w-100">{t('profile_section.value_4')}</label>
                                    <input
                                    placeholder={t('profile_section.integrity')}
                                    className="shadow-5in br-10 w-100 mb-4"
                                    type="text"
                                    />
                                </NavItem>
                                <NavItem as="li" className="d-flex align-items-center justify-content-center">
                                    {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                                    {t('profile_section.done')}
                                    </button> */}
                                    <ButtonBoxNew  name="Done" boxwidth=" w-85 me-3" />
{/* 
                                    <button className="addMore h-50 bg br-30 w-150 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                                        <FontAwesomeIcon icon={faCirclePlus} className="h5 my-auto me-2" />
                                        {t('profile_section.add_more')}
                                    </button> */}
                                     <ButtonBoxNew  name="Add More" boxwidth="w-200" iconbox="d-block" />
                                </NavItem>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
