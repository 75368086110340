import React from "react";
import { useState } from "react";
import "../regularTheme2Tab/RegularTheme2Tab.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { useTranslation } from "react-i18next";

import "../regularTheme2Tab/components/yesNoThinkingTab/YesNoThinking.scss";
import "../regularTheme2Tab/components/dietConsultationTab/DietConsultation.scss";
import "../regularTheme2Tab/components/dietGuideStepsTab/DietGuideSteps.scss";
import "../regularTheme2Tab/components/transformationTab/Transformation.scss";

import YesNoThinking from "./components/yesNoThinkingTab/YesNoThinking";
import DietConsultation from "./components/dietConsultationTab/DietConsultation";
import DietSteps from "./components/dietGuideStepsTab/DietGuideSteps";
import Transformation from "./components/transformationTab/Transformation";
export default function RegularTheme2Tab() {
  const { t, i18n } = useTranslation();
  const [seeall, setSeeall] = useState(false);

  const handleSeeallClick = () => {
    setSeeall(!seeall);
  };

  const handleSeeallClose = () => {
    setSeeall(false);
  };

  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };
  const [bookmenow, setBookmenow] = useState(false);

  const handleBookmenowClick = () => {
    setBookmenow(!bookmenow);
  };

  const handleBookmenowClose = () => {
    setBookmenow(false);
  };

  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const [Competency1, setCompetency1] = useState(10);

  return (
    <div>
      <div className="diet-programs-main diet-programs-main-tab">
        <div className="diet-programs-container">
          <div className="diet-programs-flex">
            <div className="diet-programs-left-section">
              <YesNoThinking />
              <DietConsultation />
            </div>
            <DietSteps />
          </div>
          <Transformation />
        </div>
      </div>
    </div>
  );
}
