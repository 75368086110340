import * as React from 'react';
import PrivacySlider from '../../global/PrivacySlider';
import './privacy.scss';
import ChangeTab from './tabs/ChangeTab';
import EmailTab from './tabs/EmailTab';
import FactorTab from './tabs/FactorTab';
import GeneralTab from './tabs/GeneralTab';
import PrivacyTab from './tabs/PrivacyTab';
import RestrictionsTab from './tabs/RestrictionsTab';
import SecurityTab from './tabs/SecurityTab';
import SessionsTab from './tabs/SessionsTab';
import TimeLineTab from './tabs/TimeLineTab';
import VideoPopup from '../../global/VideoPopup';
import { useTranslation } from "react-i18next";

export default function Contact() {
    const { t, i18n } = useTranslation();
    return (
        <div className="w-100 d-flex flex-column-reverse">
            <div className='w-100'>
                <VideoPopup />
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane generalTab fade show active" id="general" role="tabpanel" aria-labelledby="general">
                        <GeneralTab />
                    </div>
                    <div className="tab-pane text-center fade" id="factor" role="tabpanel" aria-labelledby="factor">
                        <FactorTab />
                    </div>
                    <div className="tab-pane generalTab RemoveLine text-center fade" id="emailTab" role="tabpanel" aria-labelledby="emailTab">
                        <EmailTab />
                    </div>
                    <div className="tab-pane generalTab privacySec text-center fade" id="privacyTab" role="tabpanel" aria-labelledby="privacyTab">
                        <PrivacyTab />
                    </div>
                    <div className="tab-pane generalTab privacySec timeLinSec text-center fade" id="timeLine" role="tabpanel" aria-labelledby="timeLine">
                        <TimeLineTab />
                    </div>
                    <div className="tab-pane generalTab privacySec text-center fade" id="restrictions" role="tabpanel" aria-labelledby="restrictions">
                        <RestrictionsTab />
                    </div>
                    <div className="tab-pane generalTab privacySec text-center fade" id="sessions" role="tabpanel" aria-labelledby="sessions">
                        <SessionsTab />
                    </div>
                    <div className="tab-pane generalTab privacySec text-center fade" id="security" role="tabpanel" aria-labelledby="security">
                        <SecurityTab />
                    </div>
                    <div className="tab-pane text-center fade" id="change" role="tabpanel" aria-labelledby="change">
                        <ChangeTab />
                    </div>
                </div>
            </div>
            <div className='container privacyTab mb-4 pb-3 mt-5 mt-lg-0'>
                <div id="myTab" className='slidetab' role="tablist">
                    <PrivacySlider />
                </div>
            </div>
        </div>
    );
  }