import React from "react";
import ToggleButton from "../../../../../global/ToggleButton";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function ProfileGroupThree() {
  const { t, i18n } = useTranslation();

  return (
    <div className="shadow-5in w-100 p-40 br-30 mb-4">
      <h3 className="mb-5 text-uppercase">{t('profile_section.preferred_ways_of_communication')}</h3>
      <ul className="row">
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Social Media" id="SocialMedia" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="In Person" id="InPerson" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Live Meet" id="LiveMeet" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Email" id="Email" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Phone" id="Phone" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Text Messaging" id="TextMessaging" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Other (Ex: Linkedin & Email & Live)" id="Others" check="checked" />
        </NavItem>
      </ul>
    </div>
  );
}
