import React, { useState } from "react";
import img1 from '../../../../../../images/StepZero/img42.png';
import img1s from '../../../../../../images/triangle1.png';
import img2 from '../../../../../../images/StepZero/img47.png';
import img3 from '../../../../../../images/StepZero/img43.png';
import img3s from '../../../../../../images/StepZero/img48.png';
import img4 from '../../../../../../images/StepZero/img44.png';
import img4s from '../../../../../../images/StepZero/img45.png';
import img2s from '../../../../../../images/StepZero/img46.png';
import Video from '../../../../../../images/iconVid.png';
import Video1 from '../../../../../../images/iconVid1.png';
import img2e from '../../../../../../images/close1.png';
import DropdownBoxFashion from "../../../../../global/DropdownBoxFashion";
import NicheFiveSlider from "../NicheFiveSlider";
import ButtonBox from "../../../../../global/ButtonBox";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../../../redux/actions/videoAction';
import BusinessEmailAddressForm1 from "../../../../threeHoursBusinessLaunch/businessEmailAddress/BusinessEmailAddressForm1";
export default function NicheFive() {

  let { isVideoVisible } = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };

  const handleClick1 = () => {
    setToggle1(!toggle1);
  };
  const [toggle1, setToggle1] = useState("true");
  const [clientPreference1, setClientPreference1] = useState("dyna");
  const [clientPreference2, setClientPreference2] = useState("contact");
  const [clientPreference3, setClientPreference3] = useState("info");
  const [nicheAssociation, setNicheAssociation] = useState("Fashion");
  const handleNicheAssociation = (newNicheAssociation) => {
    setNicheAssociation(newNicheAssociation);
  };
  const [domain, setDomain] = useState(".9to5escapee");
  const handleDomain = (newDomain) => {
    setDomain(newDomain);
  };
  const associationOptions = [
    {
      "name": "FASHION",
    },
    {
      "name": "BEAUTY",
    },
    {
      "name": "NUTRITION",
    },
    {
      "name": "FITNESS",
    },
    {
      "name": "TRAVEL",
    },
    {
      "name": "DECOR",
    },
    {
      "name": "COACHING",
    },
    {
      "name": "SPIRITUALITY",
    },
    {
      "name": "ENTERTAINMENT",
    },
    {
      "name": "FINANCE",
    },
    {
      "name": "E-LEARNING",
    },
    {
      "name": "ANIMAL",
    },
    {
      "name": "OTHER",
    },
  ]
  const domainOptions = [
    {
      "name": ".9 to 5 escapee.com",
    },
    {
      "name": ".7figurebosses.com",
    },
    {
      "name": ".1000socialites.com",
    },
    {
      "name": "other domain",
    },
  ]
  return (
    <div className="px-3 pt-3">
      <div className="row justify-content-center mt-0 mx-0 formleft1 px-3 pt-5">
        <div className="col-lg-5 text-start p-0 ">
          <img src={img1} alt="" width="266px" height="210px" />
          <div className="tag2 tagbtn">
            <h4>{clientPreference1}@{nicheAssociation}{domain}.com</h4>
          </div>
          <div className="tag5 tagbtn">
            <h4>{clientPreference2}@{nicheAssociation}{domain}.com</h4>
          </div>
          <div className="tag1 tagbtn">
            <h4>{clientPreference3}@{nicheAssociation}{domain}.com</h4>
          </div>
        </div>
        <div className="col-lg-7 pe-0 ps-3 d-flex flex-column tagfive">
          <span className="w-100 py-4 ps-3 mt-4">
            <div className="d-flex justify-content-between align-items-start w-100">
              <div className="w-125 tagleft pt-4 mt-2">
                <h6>Client <br /> Preference</h6>
                <img src={img3} alt="" />
                <div className="tag2 mb-26">
                  <h4>{clientPreference1}</h4>
                </div>
                <div className="tag5 mb-26">
                  <h4>{clientPreference2}</h4>
                </div>
                <div className="tag1">
                  <h4>{clientPreference3}</h4>
                </div>
              </div>
              <div className="w-170 d-flex justify-content-center gap-1 pt-2">
                <div className="w-50s pt-5 tagleft mt-2">
                  <div className="tag5 mb-0 pt-60 mt-2">
                    <h4>@</h4>
                  </div>
                  <img src={img4s} alt="" />
                  <h6>Symbol</h6>
                </div>
                <div className="w-100x tagleft pt-3">
                  <h6>Niche <br /> Association</h6>
                  <img src={img4} alt="" />
                  <div className="tag5">
                    <h4>{nicheAssociation}</h4>
                  </div>
                </div>
              </div>
              <div className="twotag w-65 pt-60 tagleft">
                <div className="tag6 mb-9 pt-5 mt-4">
                  <h4>1</h4>
                </div>
                <img src={img2s} alt="" />
                <h6>Auto-Generate <br /> Client Number</h6>
              </div>
              <div className="threetag w-125 tagleft pt-4">
                <h6>Domain<br /> Name</h6>
                <img src={img2} alt="" />
                <div className="tag7 mb-9 mt-0">
                  <h4>{domain}</h4>
                </div>
              </div>
              <div className="fourtag w-65 tagleft pt-60">
                <div className="tag2 mb-9 pt-60 mt-2">
                  <h4>.com</h4>
                </div>
                <img src={img3s} alt="" />
                <h6>Top Level Domain</h6>
              </div>
            </div>
          </span>
        </div>
      </div>
      <span className="cursor-pointer w-100 text-center d-block" onClick={handleClick1} ><img src={img1s} alt="" width="40px" height="20px" /></span>
      {toggle1 ? (
        <></>
      ) : (
        <div className="w-100 d-flex flex-column-reverse">
          <div className='w-100'>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="manage" role="tabpanel" aria-labelledby="manage">
                <div className="row justify-content-center mt-0 mx-0 formleft1 px-3 factorTab1s position-relative">
                  <div className="text-end d-flex">
                    <div className="VideoIcon ms-auto" onClick={handleClick6}>
                      <img src={Video} className="lightImg" width="130px" height="80px" alt="" />
                      <img src={Video1} className="darkImg" width="130px" height="80px" alt="" />
                    </div>
                    <img src={img2e} alt="" width="40px" height="52px" className="mt-87 cursor-pointer me-3 position-absolute end-0 top-0"  onClick={handleClick1}/>
                  </div>
                  <div className="col-lg-5 text-start p-0 ">
                    <img src={img1} alt="" width="266px" height="210px" />
                    <div className="tag2 tagbtn">
                      <h4>{clientPreference1}@{nicheAssociation}{domain}.com</h4>
                    </div>
                    <div className="tag5 tagbtn">
                      <h4>{clientPreference2}@{nicheAssociation}{domain}.com</h4>
                    </div>
                    <div className="tag1 tagbtn">
                      <h4>{clientPreference3}@{nicheAssociation}{domain}.com</h4>
                    </div>
                  </div>
                  <div className="col-lg-7 pe-0 ps-3 d-flex flex-column tagfive pt-5">
                    <span className="w-100 py-4 ps-3 mt-4">
                      <div className="d-flex justify-content-between align-items-start w-100">
                        <div className="w-125 tagleft pt-4 mt-2">
                          <h6>Client <br /> Preference</h6>
                          <img src={img3} alt="" />
                          <div className="tag2 mb-26">
                            <input type="text" placeholder="Dyna" className="newinput" value={clientPreference1} onChange={(e)=> setClientPreference1(e.target.value)} />
                          </div>
                          <div className="tag5 mb-26">
                            <input type="text" placeholder="contact" className="newinput" value={clientPreference2} onChange={(e)=> setClientPreference2(e.target.value)} />
                          </div>
                          <div className="tag1">
                            <input type="text" placeholder="info" className="newinput"  value={clientPreference3} onChange={(e)=> setClientPreference3(e.target.value)} />
                          </div>
                        </div>
                        <div className="w-170 d-flex justify-content-center gap-1 pt-2">
                          <div className="w-50s pt-5 tagleft mt-2">
                            <div className="tag5 mb-0 pt-60 mt-2">
                              <h4>@</h4>
                            </div>
                            <img src={img4s} alt="" />
                            <h6>Symbol</h6>
                          </div>
                          <div className="w-100x tagleft pt-3">
                            <h6>Niche <br /> Association</h6>
                            <img src={img4} alt="" />
                            <DropdownBoxFashion name={nicheAssociation} namebutton="INDUSTRY" onChange={handleNicheAssociation} boxid="fashion" options={associationOptions} boxwidth="text-start country w-100" />
                          </div>
                        </div>
                        <div className="twotag w-65 pt-60 tagleft">
                          <div className="tag6 mb-9 pt-5 mt-4">
                            <h4>1</h4>
                          </div>
                          <img src={img2s} alt="" />
                          <h6>Auto-Generate <br /> Client Number</h6>
                        </div>
                        <div className="threetag w-125 tagleft pt-4">
                          <h6>Domain<br /> Name</h6>
                          <img src={img2} alt="" />
                          <DropdownBoxFashion name={domain} namebutton="DOMAIN NAMES" onChange={handleDomain} boxid="to5escapee" options={domainOptions} boxwidth="text-start country w-100" />
                        </div>
                        <div className="fourtag w-65 tagleft pt-60">
                          <div className="tag2 mb-9 pt-60 mt-2">
                            <h4>.com</h4>
                          </div>
                          <img src={img3s} alt="" />
                          <h6>Top Level Domain</h6>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div className="col-lg-12">
                  <div className='btnsec d-flex justify-content-center w-100 mt-5'>
                      <ButtonBox
                        boxwidth="w-110 bg-theme mt-0 mb-0 me-5"
                        clicked="Cancel"
                        name="Cancel"
                      />
                      <ButtonBox
                        boxwidth="w-110  mt-0 mb-0"
                        clicked="Saved"
                        name="Save"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="nuans" role="tabpanel" aria-labelledby="nuans">
                <BusinessEmailAddressForm1 />
              </div>
              <div className="tab-pane text-center detailTab fade" id="otherg" role="tabpanel" aria-labelledby="otherg">

              </div>
              <div className="tab-pane text-center detailTab fade" id="otherg" role="tabpanel" aria-labelledby="otherg">

              </div>
              <div className="tab-pane text-center detailTab fade" id="otherg" role="tabpanel" aria-labelledby="otherg">

              </div>
              <div className="tab-pane text-center detailTab fade" id="otherg" role="tabpanel" aria-labelledby="otherg">

              </div>
              <div className="tab-pane text-center detailTab fade" id="otherg" role="tabpanel" aria-labelledby="otherg">

              </div>
            </div>
          </div>
          <div className='container privacyTab1 privacyTab3s mb-3 pb-3 mt-4'>
            <div id="myTab" className='slidetab pb-3' role="tablist">
              <NicheFiveSlider />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
