import React from 'react'
import './MarketResearchGraph.scss'

import GrowthImage from '../../../images/growth-arrow.png';
import PersonChart from '../../../images/person-chart.png';
import closebtn from '../../../images/close1.png';
import PercentageCircle from '../../../images/percentage-circle.png';
import Donut from '../../../images/donut.png';
import GraphChart from '../../../images/graph-chart.png';
import ButtonBox from '../../global/ButtonBox';

export default function MarketResearchGraphTab(props) {
  return (
    <div className="img_wrapper my-4 mx-auto">
      <div class="frame-inner">
        <div class='frame-padding w-100 h-100 shadow-4'>
          <div class="row">
            <div class="col-4 px-2">
              <div class="growth-country growth-content">
                <span>Growth Contributed By Europe</span>
              </div>
            </div>
            <div class="col-4 px-2">
              <div class="growth-year growth-content">
                <span>Growth For <span class="year-color">2021</span></span>
              </div>
            </div>
            <div class="col-4 px-2">
              <div class="growth-amount growth-content">
                <span>Incremental Growth in [$B]</span>
              </div>
            </div>
          </div>
          <div class="row mt-3 align-items-stretch">
            <div class="col-4 px-1 d-flex justify-content-center align-items-center">
              <div class="chart-view">
                <img src={Donut} alt="" className="mx-auto" />
              </div>
            </div>
            <div class="col-4 px-1 d-flex justify-content-center align-items-center">
              <div class="chart-view percentage-circle">
                <div class="inner-content-shadow mx-auto mt-2">
                  <span class="growth-percentage">9%</span>
                </div>
                <img src={PercentageCircle} alt="" className="mx-auto" />
              </div>
            </div>
            <div class="col-4 px-1 d-flex justify-content-center align-items-center">
              <div class="chart-view">
                <img src={GraphChart} alt="" className="mx-auto img-fluid onject_fill" />
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4 px-1">
              <div class="growth-content">
                <div class="positive-btn shadow-5in">
                  <span>positive</span>
                </div>
                <div class="market-impact-text inner-content-shadow">
                  <span>Market Impact</span>
                </div>
                <div class="negative-btn shadow-5in">
                  <span>Negative</span>
                </div>
              </div>
            </div>
            <div class="col-4 px-1">
              <div class="growth-content">
                <div class="inner-content-shadow f_8 mx-auto">
                  <span>Market Growth will <b>ACCELERATE</b> at a <b>CAGR</b> of almost</span>
                </div>
                <div class="inner-content-shadow mx-auto mt-2">
                  <span class="growth-percentage">12%</span>
                </div>
                <img src={GrowthImage} alt="" className="mx-auto growth-img mt-1" />
              </div>
            </div>
            <div class="col-4 px-1">
              <div class="growth-content chart-view">
                <img src={PersonChart} alt="" className="mx-auto" />
                <div class="inner-content-shadow mx-auto f_8 mt-1 p-2">
                  <span>The Market is <b>FRAGMENTED</b> with several players</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="frame-badge framebg">
        <div class={`frame-badge-inner ${props.bgColor}`} onClick={props.handleClick}>
          <img src={closebtn} alt="" className='VideoIcon' />
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center align-items-center frame-button">
        <ButtonBox boxwidth="w-125 lg text-primary mb-4" clicked="Learn More" name="Learn More" />
      </div>
    </div>
  )
}
