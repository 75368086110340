import React, { useState, useRef, useEffect } from "react";
import flag1 from "../../../../images/flag1.png";
import flag from "../../../../images/flag.png";
import ImageBox from "../../../global/ImageBox";
import clipboard from "../../../../images/clipboard.png";
import Aries2 from "../../../../images/zodic/14.png";
import PersonalityZodicCircle from "../../../global/PersonalityZodicCircle";
// import ViewSlider from "../../../global/ViewSlider";
import ProfileViewOne from "./ProfileView/ProfileViewOne";
import ProfileViewTwo from "./ProfileView/ProfileViewTwo";
import ProfileViewThree from "./ProfileView/ProfileViewThree";
import SubHeading from "../../../global/SubHeading";
import ButtonBox from "../../../global/ButtonBox";
import { Link } from "react-router-dom";
import Education from "./ProfileView/ProfileSec/Education";
import img4 from "../../../../images/dropdown/object2.png";
import img5 from "../../../../images/profileview/img5.png";
import img6 from "../../../../images/profileview/img6.png";
import img8 from "../../../../images/profileview/img8.png";
import img8s from "../../../../images/profileview/img8s.png";
import img11 from "../../../../images/profileview/img40.png";
import img12 from "../../../../images/profileview/img41.png";
import img13 from "../../../../images/profileview/img42.png";
import img14 from "../../../../images/profileview/img43.png";
import img15 from "../../../../images/profileview/img44.png";
import img16 from "../../../../images/profileview/img45.png";
import img17 from "../../../../images/profileview/img46.png";
import img19 from "../../../../images/profileview/img47.png";
import imgstatic from "../../../../images/profileview/staticbar.png";

import img68 from "../../../../images/img69.png";
import img67 from "../../../../images/img67.png";
import img70 from "../../../../images/img70.png";
import img71 from "../../../../images/img71.png";
import RangeSlider from "react-bootstrap-range-slider";
import SocialScore from "./ProfileView/ProfileSec/SocialScore";
import { NavItem } from "react-bootstrap";
import { ReactComponent as IconBluew9s } from "../../../../images/svgIcon/icon22.svg";
import SimpleView from "../../../global/SimpleView";
import VideoView from "../../../global/VideoView";
import AudioView from "../../../global/AudioView";
import img5audi from "../../../../images/audiobg.png";
import mute from "../../../../images/audio/mute.png";
import high from "../../../../images/audio/minivol.png";
import { useTranslation } from "react-i18next";
import TabComponent from "../../../global/TabComponent";
import TabComponentStatus from "../../../global/TabComponentStatus";
import testvideo from "../../../../images/audio/testvideo.mp4";
import testvideo2 from "../../../../images/audio/testvideo2.mp4";
import close from "../../../../images/close1.png";
import testvideo3 from "../../../../images/audio/testvideo3.mp4";

import { GiPauseButton } from "react-icons/gi";
import short from "../../../../images/audio/short.png";
import frame1 from "../../../../images/audio/frame1.png";
import full from "../../../../images/audio/full.png";
import play from "../../../../images/audio/play.png";
import forwad from "../../../../images/audio/forwad.png";
import sound from "../../../../images/audio/sound.png";
import auto from "../../../../images/audio/auto.png";
import topbg from "../../../../images/social/img10.png";
import vpause from "../../../../images/audio/vpause.png";
import testaudio2 from "../../../../images/audio/testaudio2.mp3";
import testaudio3 from "../../../../images/audio/testaudio3.mp3";
import testaudio from "../../../../images/audio/testaudio.mp3";
export default function ProfileViewTab() {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(5);
  const [value1, setValue1] = useState(5);
  const [value2, setValue2] = useState(30);
  const [value3, setValue3] = useState(1);
  const [value4, setValue4] = useState(5);
  const [value5, setValue5] = useState(5);
  const [value6, setValue6] = useState(5);
  const [value7, setValue7] = useState(5);
  const [value8, setValue8] = useState(5);
  const videoData = [testvideo, testvideo2, testvideo3];
  const audioData = [testaudio, testaudio2, testaudio3];

  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const [volume, setVolume] = useState(50);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const sliderRef = useRef(null);

  const [showVolumeControls, setShowVolumeControls] = useState(false);
  const [showVideoVolumeControls, setShowVideoVolumeControls] = useState(false);

  const [isMiniScreen, setIsMiniScreen] = useState(false);
  const [autoplay, setAutoplay] = useState(false);
  const [handleView, setHandleView] = useState("");
  const [isMiniScreenOpen, setIsMiniScreenOpen] = useState(false);
  const [isMiniScreenAudioOpen, setIsMiniScreenAudioOpen] = useState(false);
  const [audioSource, setAudioSource] = useState("");
  const [videoSource, setVideoSource] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  

  const onTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };
  const onAudioTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };
  const toggleAudioVolumeControls = () => {
    setShowVolumeControls(!showVolumeControls);
  };
  

  const handleAudioVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value) / 100;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;

  };
  const toggleVideoVolumeControls = () => {
    setShowVideoVolumeControls(!showVideoVolumeControls);
  };
  

  const handleVideoVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value) / 100;
    setVolume(newVolume);
    videoRef.current.volume = newVolume;

  };
  const handleVideoClick = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  const handleAudioClick = () => {
    if (isAudioPlaying) {
      audioRef.current.pause();
      setIsAudioPlaying(false);
    }
  };
  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  const handlePlayPauseAudio = () => {
    if (isAudioPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsAudioPlaying(!isAudioPlaying);
  };
  useEffect(() => {
    if (isPlaying) {
      // If the video is playing in the child component, update the mini-screen video source
      setVideoSource(videoSource);
    }
  }, [isPlaying, videoSource]);

  const toggleMiniScreen = (newVideoSource) => {
    if (isMiniScreenOpen) {
      setIsMiniScreenOpen(false);
    } else {
      setHandleView("");
      setIsMiniScreenOpen(true);
      setVideoSource(newVideoSource);
    }
  };
  useEffect(() => {
    if (isAudioPlaying) {
      // If the video is playing in the child component, update the mini-screen video source
      setAudioSource(audioSource);
    }
  }, [isAudioPlaying, audioSource]);

  const toggleMiniScreenAudio = (newAudioSource) => {
    if (isMiniScreenOpen) {
      setIsMiniScreenOpen(false);
    } else {
      setHandleView("");
      setIsMiniScreenAudioOpen(true);
      setAudioSource(newAudioSource); // Set the new video source
    }
  };
  const closeMiniScreen = () => {
    setIsMiniScreenOpen(false);
  };
  const closeMiniAudioScreen = () => {
    setIsMiniScreenAudioOpen(false);
  };
  const handleFullScreenButtonClick = () => {
    const videoContainer = document.querySelector(".video-container");

    if (videoContainer) {
      if (!document.fullscreenElement) {
        if (videoContainer.requestFullscreen) {
          videoContainer.requestFullscreen();
        } else if (videoContainer.mozRequestFullScreen) {
          videoContainer.mozRequestFullScreen();
        } else if (videoContainer.webkitRequestFullscreen) {
          videoContainer.webkitRequestFullscreen();
        } else if (videoContainer.msRequestFullscreen) {
          videoContainer.msRequestFullscreen();
        }

        videoContainer.classList.add("full-screen1");
      
        setIsFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }

        videoContainer.classList.remove("full-screen1");
      // Set the desired height

        setIsFullScreen(false);
      }
    }
  };
  const handleFullScreenAudioButtonClick = () => {
    const videoContainer = document.querySelector(".audio-container");

    if (videoContainer) {
      if (!document.fullscreenElement) {
        if (videoContainer.requestFullscreen) {
          videoContainer.requestFullscreen();
        } else if (videoContainer.mozRequestFullScreen) {
          videoContainer.mozRequestFullScreen();
        } else if (videoContainer.webkitRequestFullscreen) {
          videoContainer.webkitRequestFullscreen();
        } else if (videoContainer.msRequestFullscreen) {
          videoContainer.msRequestFullscreen();
        }

        videoContainer.classList.add("full-screen1");
      
        setIsFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }

        videoContainer.classList.remove("full-screen1");
      // Set the desired height

        setIsFullScreen(false);
      }
    }
  };

  const miniScreenStyle = {
    position: "fixed",
    bottom: "10px",
    right: "10px",
    display: isMiniScreenOpen ? "block" : "none",
    zIndex:9999,
  };
  const miniScreenAudioStyle = {
    position: "fixed",
    bottom: "10px",
    right: "-45px",
    display: isMiniScreenAudioOpen ? "block" : "none",
    zIndex:9999,
    };
  return (
    <div>
      <div className="w-1440 shadowTextCustom mb-4 align-items-center mx-auto d-flex flex-column-reverse justify-content-between">
        <div className="bg w-100">
          <div className="mb-5">
            {handleView == "" && <SimpleView setHandleView={setHandleView} />}

            {handleView == "video" && (
              <VideoView
                setHandleView={setHandleView}
                isMiniScreenOpen={isMiniScreenOpen}
                toggleMiniScreen={toggleMiniScreen}
                
              />
            )}
            {handleView == "audio" && (
              <AudioView
                setHandleView={setHandleView}
                isMiniScreenAudioOpen={isMiniScreenOpen}
                toggleMiniScreenAudio={toggleMiniScreenAudio}
                
              />
            )}
          </div>
          <div className="bannerSection mx-auto text-center mb-5">
            {isMiniScreenOpen ? (
              
                <div className="mini-screen " style={miniScreenStyle}>
                  <div className="videoplayer1"><button className="topbtn border-0 bg-primary w-100">
              VIDEO CENTER{" "}
            </button></div>
                  <div className={`video-container-mini ${isFullScreen ? "full-screen1" : ""}`}>
                  <div className="topvid41">
                    <div className="vol-btn"> <img src={high} onClick={toggleVideoVolumeControls}/>
                    <div className="sound-control-mini">
                            <div>
                              {showVideoVolumeControls && (
                                <div className=" justify-content-center  ">
                                  
                                  <div className="volume-control-mini">
                                    {/* <div className="slider-container"> */}
                                    <input
                                      className="soundediv win10-thumb"
                                      type="range"
                                      defaultValue={volume}
                                      min={0}
                                      max={100}
                                      step={10}
                                      sliderRef={sliderRef}
                                      onChange={handleVideoVolumeChange}
                                    />
                                   
                                  </div>
                                  
                                </div>
                              )} 
                            </div>
                          </div></div>
                    <div className="close-button " onClick={closeMiniScreen}>
                      <img src={close} alt="" onClick={closeMiniScreen} />
                    </div>

                    <video
                    className="minvid"
                      ref={videoRef}
                      onCanPlayThrough={() => {
                        setDuration(videoRef.current.duration);
                        setIsLoading(false);
                      }}
                      onTimeUpdate={onTimeUpdate}
                      onClick={handleVideoClick}
                     
                    >
                      <source src={videoSource} type="video/mp4" />
                    </video>
                    <div className="position-absolute d-inline-table  top-0 bottom-0 start-0 end-0 m-auto">
                    
                      <div onClick={handlePlayPause}>
                        <div className="miniplay">
                          {isPlaying ? (
                            ""
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="130"
                              height="130"
                              viewBox="0 0 130 130"
                              fill="none"
                            >
                              <circle
                                cx="65"
                                cy="65"
                                r="64"
                                stroke="#F0F4F8"
                                stroke-width="2"
                              />
                              <circle
                                cx="63.375"
                                cy="63.375"
                                r="34.125"
                                fill="url(#paint0_linear_255_76455)"
                              />
                              <path
                                d="M56.875 55.4821C56.875 53.9385 58.5494 52.9768 59.8827 53.7545L73.4135 61.6474C74.7365 62.4192 74.7365 64.3308 73.4135 65.1026L59.8827 72.9955C58.5494 73.7732 56.875 72.8115 56.875 71.2679V55.4821Z"
                                fill="#202020"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_255_76455"
                                  x1="63.375"
                                  y1="16.965"
                                  x2="63.375"
                                  y2="108.42"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#FACB30" />
                                  <stop
                                    offset="0.302269"
                                    stop-color="#FEF9C8"
                                  />
                                  <stop
                                    offset="0.640625"
                                    stop-color="#C49530"
                                  />
                                  <stop offset="1" stop-color="#FFF8C4" />
                                </linearGradient>
                              </defs>
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                    {isFullScreen ? 
                    <div className="position-absolute pb-1 d-inline-table mb-5 w-75 bottom-0 start-0 end-0 mx-auto">
                      <div className="  mx-auto mb-2">
                        <div className=" mx-auto mb-2 progressbar">
                          <div
                            className={`${"progressinner"} ${
                              currentTime !== duration ? "progress-inner" : ""
                            } `}
                            style={{
                              animationPlayState: isPlaying
                                ? "running"
                                : "paused",
                                animationDuration: isLoading ? "0s" : videoRef.current ? `${videoRef.current.duration}s` : "0s"
                                ,
                            }}
                          />
                        </div>{" "}
                        {/* <div className="d-flex playable justify-content-between px-1">
                          <div className="d-flex">
                            <Link to="">
                              <div onClick={handlePlayPause}>
                                {isPlaying ? (
                                  <img src={vpause} alt="" />
                                ) : (
                                  <img src={play} alt="" />
                                )}
                              </div>
                            </Link>
                          </div>
                          <div className="d-flex">
                            <Link to="">
                              <img
                                src={full}
                                alt=""
                                onClick={handleFullScreenButtonClick}
                              />
                            </Link>
                          </div>
                        </div> */}
                      </div>
                    </div> : <div className="position-absolute pb-1 d-inline-table  w-75 bottom-0 start-0 end-0 mx-auto">
                      <div className="  mx-auto mb-1">
                        <div className=" mx-auto mb-1 progressbar">
                          <div
                            className={`${"progressinner"} ${
                              currentTime !== duration ? "progress-inner" : ""
                            } `}
                            style={{
                              animationPlayState: isPlaying
                                ? "running"
                                : "paused",
                                animationDuration: isLoading ? "0s" : videoRef.current ? `${videoRef.current.duration}s` : "0s",
                                
                            }}
                          />
                        </div>{" "}
                        <div className="d-flex playable justify-content-center px-1">
                          <div className="d-flex">
                            <Link to="">
                              <div onClick={handlePlayPause}>
                                {isPlaying ? (
                                  <img src={vpause} alt="" />
                                ) : (
                                  <img src={play} alt="" />
                                )}
                              </div>
                            </Link>
                          </div>
                          {/* <div className="d-flex">
                            <Link to="">
                              <img
                                src={full}
                                alt=""
                                onClick={handleFullScreenButtonClick}
                              />
                            </Link>
                          </div> */}
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            ) : ""}

            {isMiniScreenAudioOpen ? (
              <div className="audio-container">
              
                <div className="mini-screen " style={miniScreenAudioStyle}>
                  
                  <div className=" position-relative">
                
                  <img src={img4} alt="" className="audio-head"/>
                  
           
           
                  <div className="topvid41" >
               
                  <div className="vol-btn2"> <img src={high} onClick={toggleAudioVolumeControls}/>
                  <div className="sound-control-mini2">
                            <div>
                              {showVolumeControls && (
                                <div className=" justify-content-center  ">
                                  
                                  <div className="volume-control-mini">
                                    {/* <div className="slider-container"> */}
                                    <input
                                      className="soundediv win10-thumb"
                                      type="range"
                                      defaultValue={volume}
                                      min={0}
                                      max={100}
                                      step={10}
                                      sliderRef={sliderRef}
                                      onChange={handleAudioVolumeChange}
                                    />
                                    {/* <div
                                      className="thumb-image "
                                      ref={thumbImageRef}
                                    >
                                      <img src={volumeunions} />
                                    </div> */}
                                    {/*</div>  */}
                                  </div>
                                  
                                </div>
                              )}
                            </div>
                          </div></div>

                    <div className="close-button2 ">
                      <img src={close} alt="" onClick={closeMiniAudioScreen} />
                    </div>
                    <audio
                      ref={audioRef}
                      onCanPlayThrough={() => {
                        setDuration(audioRef.current.duration);
                        setIsLoading(false);
                      }}
                      onTimeUpdate={onAudioTimeUpdate}
                      
                      style={{
                        height: "168px",
                        width: "300px",
                        borderRadius: "50px",
                      }}
                    >
                      <source src={audioSource} type="video/mp4" />
                    </audio>
                    <img src={img5audi} alt="" className=" w-100" />
                    <div className="position-absolute d-inline-table  top-0 bottom-0 start-0 end-0 m-auto" onClick={handleAudioClick}>
                    
                      <div onClick={handlePlayPauseAudio}>
                        <div className="miniplay2 ">
                          {isAudioPlaying ? (
                            ""
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="130"
                              height="130"
                              viewBox="0 0 130 130"
                              fill="none"
                            >
                              <circle
                                cx="65"
                                cy="65"
                                r="64"
                                stroke="#F0F4F8"
                                stroke-width="2"
                              />
                              <circle
                                cx="63.375"
                                cy="63.375"
                                r="34.125"
                                fill="url(#paint0_linear_255_76455)"
                              />
                              <path
                                d="M56.875 55.4821C56.875 53.9385 58.5494 52.9768 59.8827 53.7545L73.4135 61.6474C74.7365 62.4192 74.7365 64.3308 73.4135 65.1026L59.8827 72.9955C58.5494 73.7732 56.875 72.8115 56.875 71.2679V55.4821Z"
                                fill="#202020"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_255_76455"
                                  x1="63.375"
                                  y1="16.965"
                                  x2="63.375"
                                  y2="108.42"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#FACB30" />
                                  <stop
                                    offset="0.302269"
                                    stop-color="#FEF9C8"
                                  />
                                  <stop
                                    offset="0.640625"
                                    stop-color="#C49530"
                                  />
                                  <stop offset="1" stop-color="#FFF8C4" />
                                </linearGradient>
                              </defs>
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="position-absolute pb-1 d-inline-table ms-4 ps-4 w-50 bottom-0 start-0 end-0 text-center" >
                      <div className="   mb-1">
                        <div className="  mb-1 progressbar">
                          <div
                            className={`${"progressinner"} ${
                              currentTime !== duration ? "progress-inner" : ""
                            } `}
                            style={{
                              animationPlayState: isAudioPlaying
                                ? "running"
                                : "paused",
                                animationDuration: isLoading ? "0s" : audioRef.current ? `${audioRef.current.duration}s` : "0s"

                            }}
                          />
                        </div>{" "}
                        <div className="d-flex playable justify-content-center px-1">
                          <div className="d-flex">
                            <Link to="">
                              <div onClick={handlePlayPauseAudio}>
                                {isAudioPlaying ? (
                                  <img src={vpause} alt="" />
                                ) : (
                                  <img src={play} alt="" />
                                )}
                              </div>
                            </Link>
                          </div>
                          {/* <div className="d-flex">
                            <Link to="">
                              <img
                                src={full}
                                alt=""
                                onClick={handleFullScreenAudioButtonClick}
                              />
                            </Link>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div></div></div>
                </div>
              
            ) : ""}

            <div className="bnrbtm w-100 d-flex flex-wrap pt-5">
              <div className="d-flex w-270 justify-content-center align-items-start flex-wrap  h-fit">
                <div className="w-50 pe-2 mt-0">
                  <div className="shadow-5in roundedimg br-100 w-125s block">
                    <img src={img11} alt="" className="" />
                  </div>
                  <h4 className="h7 fw-medium text-uppercase my-2">
                    {t("profile_section.tour_memories")}
                  </h4>
                </div>
                <div className="w-50 mt-0">
                  <div className="shadow-5in roundedimg br-100 w-125s block">
                    <img src={img12} alt="" className="" />
                  </div>
                  <h4 className="h7 fw-medium text-uppercase my-2">
                    {t("profile_section._10TH_birthday")}
                  </h4>
                </div>
                <div className="w-50 pe-2">
                  <div className="shadow-5in roundedimg br-100 w-125s block">
                    <img src={img13} alt="" className="" />
                  </div>
                  <h4 className="h7 fw-medium text-uppercase my-2">
                    {t("profile_section.tour_memories")}
                  </h4>
                </div>
                <div className="w-50">
                  <div className="shadow-5in roundedimg br-100 w-125s block">
                    <img src={img12} alt="" className="" />
                  </div>
                  <h4 className="h7 fw-medium text-uppercase my-2">
                    {t("profile_section._10TH_birthday")}
                  </h4>
                </div>
              </div>
              <div className="w-480 p-0 block br-20 mx-3 position-relative">
                <div className="col-md-12 bioSec">
                  <nav className="d-flex justify-content-between align-items-center mb-3">
                    <div
                      className="bioNav d-flex w-100 justify-content-center"
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        className="tabbtn border-0 active"
                        id="nav-who-tabs"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-whos"
                        type="button"
                        role="tab"
                        aria-controls="nav-whos"
                        aria-selected="true"
                      >
                        <span>{t("profile_section.who_am_i")}</span>
                      </button>
                      <button
                        className="tabbtn border-0"
                        id="nav-what-tabs"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-whats"
                        type="button"
                        role="tab"
                        aria-controls="nav-whats"
                        aria-selected="false"
                      >
                        <span>{t("profile_section.what_i_do")}</span>
                      </button>
                      <button
                        className="tabbtn border-0"
                        id="nav-service-tabs"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-services"
                        type="button"
                        role="tab"
                        aria-controls="nav-services"
                        aria-selected="false"
                      >
                        <span>
                          {t("profile_section.who_is_my_service_for")}
                        </span>
                      </button>
                      <button
                        className="tabbtn border-0"
                        id="nav-work-tabs"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-works"
                        type="button"
                        role="tab"
                        aria-controls="nav-works"
                        aria-selected="false"
                      >
                        <span>{t("profile_section.why_work_with_me")}</span>
                      </button>
                    </div>
                  </nav>
                  <div className=" gradient-box z-index-4s">
                    <div className="tab-content bg br-8" id="nav-tabContent">
                      <div
                        className="tab-pane fade show rounded-0 active"
                        id="nav-whos"
                        role="tabpanel"
                        aria-labelledby="nav-who-tabs"
                      >
                        <div className="position-relative shadow-5in mb-0 w-100 p-4 bg text-start">
                          <div className="overflow-s">
                            <h4 class="h8 font-medium">
                              {t("profile_section.hey_i_am_dyna")}
                            </h4>
                            <p className="h7 mb-0">
                              {t("profile_section.dyna_text")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade rounded-0"
                        id="nav-whats"
                        role="tabpanel"
                        aria-labelledby="nav-what-tabs"
                      >
                        <div className="position-relative bg shadow-5in mb-0 w-100 p-3 text-start">
                          <div className="overflow-s">
                            <h4 class="h8 font-medium">
                              {t("profile_section.hey_i_am_dyna")}
                            </h4>
                            <p className="h7 mb-0">
                              {t("profile_section.dyna_text")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade rounded-0"
                        id="nav-services"
                        role="tabpanel"
                        aria-labelledby="nav-service-tabs"
                      >
                        <div className="position-relative bg shadow-5in mb-0 w-100 p-3 text-start">
                          <div className="overflow-s">
                            <h4 class="h8 font-medium">
                              {t("profile_section.hey_i_am_dyna")}
                            </h4>
                            <p className="h7 mb-0">
                              {t("profile_section.dyna_text")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade rounded-0"
                        id="nav-works"
                        role="tabpanel"
                        aria-labelledby="nav-work-tabs"
                      >
                        <div className="position-relative bg shadow-5in mb-0 w-100 p-3 text-start">
                          <div className="overflow-s">
                            <h4 class="h8 font-medium">
                              {t("profile_section.hey_i_am_dyna")}
                            </h4>
                            <p className="h7 mb-0">
                              {t("profile_section.dyna_text")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ButtonBox
                    boxwidth="w-110 mt-0 mb-4 mt-4"
                    clicked="Hired"
                    name="Hire Me"
                  />
                </div>
                <div className="top-0 start-0 topTag1 bg shadow-5in position-absolute br-100 d-flex justify-content-center align-items-center">
                  <img src={img19} alt="" />
                </div>
              </div>
              <div className="d-flex w-270 justify-content-center align-items-start flex-wrap h-fit">
                <div className="w-50 mt-0">
                  <div className="shadow-5in roundedimg br-100 w-125s block">
                    <img src={img14} alt="" className="" />
                  </div>
                  <h4 className="h7 fw-medium text-uppercase my-2">
                    {t("profile_section.lip_liner")}
                  </h4>
                </div>
                <div className="w-50 ps-2 mt-0">
                  <div className="shadow-5in roundedimg br-100 w-125s block">
                    <img src={img15} alt="" className="" />
                  </div>
                  <h4 className="h7 fw-medium text-uppercase my-2">
                    {t("profile_section.fashion_week")}
                  </h4>
                </div>
                <div className="w-50">
                  <div className="shadow-5in roundedimg br-100 w-125s block">
                    <img src={img16} alt="" className="" />
                  </div>
                  <h4 className="h7 fw-medium text-uppercase my-2">
                    {t("profile_section.lip_liner")}
                  </h4>
                </div>
                <div className="w-50 ps-2">
                  <div className="shadow-5in roundedimg br-100 w-125s block">
                    <img src={img17} alt="" className="" />
                  </div>
                  <h4 className="h7 fw-medium text-uppercase my-2">
                    {t("profile_section.fashion_week")}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-2 col-md-4 mb-4">
              <SocialScore />
            </div>
            <div className="col-lg-10 col-md-8 mb-4">
              <div className="jobdiv">
                <div className="innerdiv-job">
                  <h5>You Can Finally Quit Your 9 to 5 Job!</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-4">
              <div className="slideProfile">
                <div className="subHeader m-0 p-0 pb-3 d-none">
                  <div width="100%" direction="left" className="cssmarquee">
                    <ul className="d-flex justify-content-center">
                      <SubHeading
                        text="TODAY’s AGENDA"
                        src={clipboard}
                        alt=""
                      />
                      <SubHeading text="CALENDAR" src={clipboard} alt="" />
                      <SubHeading text="REMINDERS" src={clipboard} alt="" />
                      <SubHeading text="Email" src={clipboard} alt="" />
                      <SubHeading text="APPOIN-TMENTS" src={clipboard} alt="" />
                      <SubHeading text="MEETINGS" src={clipboard} alt="" />
                      <SubHeading text="events" src={clipboard} alt="" />
                      <SubHeading text="WEBINAR" src={clipboard} alt="" />
                      <SubHeading
                        text="CONTACTS | CRM"
                        src={clipboard}
                        alt=""
                      />
                    </ul>
                  </div>
                </div>
                <div className="w-100 px-3 overflow-x">
                  <div className="d-none d-lg-block">
                    <div className="scroll-bar scroll-bar2 w-100 shadow-4in br-20 my-30"></div>
                  </div>
                  {/* <div className='login-tab profiletb'>
                      <ul className="nav" id="myTab" role="tablist">
                        <NavItem as="li" className='pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-100 active h-90 border-0" id="tb1" data-bs-toggle="tab" data-bs-target="#tb1" type="button" role="tab" aria-controls="tb1" aria-selected="true">
                            {t('profile_section.bio')}
                            </button>
                          </div>
                        </NavItem>
                        <NavItem as="li" className='pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-100 h-90 border-0 d-flex" id="tb2" data-bs-toggle="tab" data-bs-target="#tb2" type="button" role="tab" aria-controls="tb2" aria-selected="true">
                            {t('profile_section.press_kit')}
                            </button>
                          </div>
                        </NavItem>
                        <NavItem as="li" className='pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-100 h-90 border-0 d-flex justify-content-center align-items-center" id="tb3" data-bs-toggle="tab" data-bs-target="#tb3" type="button" role="tab" aria-controls="tb3" aria-selected="true">
                            {t('profile_section.stats')} 
                            </button>
                          </div>
                        </NavItem>
                        <NavItem as="li" className='pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb4" data-bs-toggle="tab" data-bs-target="#tb4" type="button" role="tab" aria-controls="tb4" aria-selected="true">
                            {t('profile_section.reviews')} 
                            </button>
                          </div>
                        </NavItem>

                        <NavItem as="li" className='pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb5" data-bs-toggle="tab" data-bs-target="#tb5" type="button" role="tab" aria-controls="tb5" aria-selected="true">
                            {t('profile_section.portfolio')}  
                            </button>
                          </div>
                        </NavItem>
                        <NavItem as="li" className='pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb5" data-bs-toggle="tab" data-bs-target="#tb5" type="button" role="tab" aria-controls="tb5" aria-selected="true">
                            {t('profile_section.portfolio')}  
                            </button>
                          </div>
                        </NavItem>
                        <NavItem as="li" className='pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb5" data-bs-toggle="tab" data-bs-target="#tb5" type="button" role="tab" aria-controls="tb5" aria-selected="true">
                            {t('profile_section.portfolio')}  
                            </button>
                          </div>
                        </NavItem>

                        <NavItem as="li" className='pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb6" data-bs-toggle="tab" data-bs-target="#tb6" type="button" role="tab" aria-controls="tb6" aria-selected="true">
                            {t('profile_section.blogs')} 
                            </button>
                          </div>
                        </NavItem>
                        <NavItem as="li" className='pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb7" data-bs-toggle="tab" data-bs-target="#tb7" type="button" role="tab" aria-controls="tb7" aria-selected="true">
                            {t('profile_section.shop')}
                            </button>
                          </div>
                        </NavItem>
                        <NavItem as="li" className='pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb8" data-bs-toggle="tab" data-bs-target="#tb8" type="button" role="tab" aria-controls="tb8" aria-selected="true">
                            {t('profile_section.website')} 
                            </button>
                          </div>
                        </NavItem>
                        <NavItem as="li" className='pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb9" data-bs-toggle="tab" data-bs-target="#tb9" type="button" role="tab" aria-controls="tb9" aria-selected="true">
                            {t('profile_section.Q_A')}  
                            </button>
                          </div>
                        </NavItem>
                      </ul>
                    </div> */}
                  <TabComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row w-1494">
            <div className="col-lg-4 col-488 col-md-6">
              <div className="row h-645">
                <div className="col-md-12 accomplishSec pt-5">
                  <div className="slidecontainer mb-4">
                    {/* <RangeSlider
                      value={value3}
                      onChange={(changeEvent) =>
                        setValue3(changeEvent.target.value)
                      }
                      min={1}
                      max={5}
                    /> */}
                    <div className="d-none d-lg-block">
                      <div className="scroll-bar1 shadow-4in br-20 my-2"></div>
                    </div>
                    {/* <div className='login-tab1 mb-3'>
                      <div className="nav" id="myTabu" role="tablist">
                        <div className='tabli pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="active border-0" id="tb1s" data-bs-toggle="tab" data-bs-target="#tb1" type="button" role="tab" aria-controls="tb1" aria-selected="true">
                              Single
                            </button>
                          </div>
                        </div>
                        <div className='tabli pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-100 h-90 border-0 d-flex" id="tb2" data-bs-toggle="tab" data-bs-target="#tb2" type="button" role="tab" aria-controls="tb2" aria-selected="true">
                            Married
                            </button>
                          </div>
                        </div>
                        <div className='tabli pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-100 h-90 border-0 d-flex justify-content-center align-items-center" id="tb3" data-bs-toggle="tab" data-bs-target="#tb3" type="button" role="tab" aria-controls="tb3" aria-selected="true">
                            Divorced
                            </button>
                          </div>
                        </div>
                        <div className='tabli pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb4" data-bs-toggle="tab" data-bs-target="#tb4" type="button" role="tab" aria-controls="tb4" aria-selected="true">
                            Widow
                            </button>
                          </div>
                        </div>
                        <div className='tabli pe-1 w-100x'>
                          <div className='btn-layer br-30 bg'>
                            <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb5" data-bs-toggle="tab" data-bs-target="#tb5" type="button" role="tab" aria-controls="tb5" aria-selected="true">
                            Separated 
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <TabComponentStatus />
                  </div>
                  <div className="d-flex flex-wrap justify-content-between">
                    <div className="w-100 pb-5 accomplishSec">
                      <div className="w-100">
                        <ul className="d-flex justify-content-center align-itmes-center">
                          <NavItem as="li" className="agerang">
                            <div className="bs-primary w-100 h-100 flex-column rounded-circle d-flex justify-content-center align-items-center">
                              <IconBluew9s />
                            </div>
                          </NavItem>
                          <NavItem as="li" className="rotateTop">
                            <RangeSlider
                              value={value2}
                              onChange={(changeEvent) =>
                                setValue2(changeEvent.target.value)
                              }
                              min={0}
                              max={60}
                            />
                          </NavItem>
                          <NavItem as="li" className="w-105 symble">
                            <PersonalityZodicCircle
                              img={Aries2}
                              name="Scorpio"
                              date="Nov.2nd"
                            />
                          </NavItem>
                        </ul>
                        <ul className="d-flex justify-content-center text-center align-itmes-center">
                          <NavItem as="li" className="w-110">
                            <h6 className="nametext">Female</h6>
                          </NavItem>
                          <NavItem as="li" className="w-140s">
                            <h6 className="nametext">Age</h6>
                          </NavItem>
                          <NavItem as="li" className="w-110">
                            <h6 className="nametext">Scorpio</h6>
                          </NavItem>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 mt-4 text-center mx-0">
                    <div className="col-md-4 px-1 tooltipHide flex-column">
                      <img
                        src={img4}
                        alt=""
                        width="55"
                        height="50"
                        className="mt-7 rounded-circle"
                      />
                      {/* <RangeSlider
                          value={value}
                          onChange={(changeEvent) =>
                            setValue(changeEvent.target.value)
                          }
                          min={0}
                          max={10}
                        /> */}
                      <img
                        src={imgstatic}
                        alt=""
                        className="w-auto mx-auto d-block"
                      />
                      <h4 className="h7 shadow-5in p-2 br-5 d-inline-block">
                        {" "}
                        {t("profile_section.boy")}{" "}
                      </h4>
                    </div>
                    <div className="col-md-4 px-1 tooltipHide flex-column">
                      <img
                        src={img6}
                        alt=""
                        height="45"
                        width="83"
                        className="mt-7 rounded-circle"
                      />
                      {/* <RangeSlider
                          value={value4}
                          onChange={(changeEvent) =>
                            setValue4(changeEvent.target.value)
                          }
                          min={0}
                          max={10}
                        /> */}
                      <img
                        src={imgstatic}
                        alt=""
                        className="w-auto mx-auto d-block"
                      />
                      <h4 className="h7 shadow-5in p-2 br-5 d-inline-block">
                        {" "}
                        {t("profile_section.family")}{" "}
                      </h4>
                    </div>
                    <div className="col-md-4 px-1 tooltipHide flex-column">
                      <img
                        src={img5}
                        alt=""
                        width="53"
                        height="49"
                        className="mt-7 img-cover rounded-circle"
                      />
                      {/* <RangeSlider
                          value={value5}
                          onChange={(changeEvent) =>
                            setValue5(changeEvent.target.value)
                          }
                          min={0}
                          max={10}
                        /> */}
                      <img
                        src={imgstatic}
                        alt=""
                        className="w-auto mx-auto d-block"
                      />
                      <h4 className="h7 shadow-5in p-2 br-5 d-inline-block">
                        {" "}
                        {t("profile_section.girl")}{" "}
                      </h4>
                    </div>
                    <div className="col-md-6 tooltipHide flex-column">
                      <img
                        src={img8}
                        alt=""
                        width="50"
                        height="48"
                        className=" rounded-circle"
                      />
                      {/* <RangeSlider
                          value={value6}
                          onChange={(changeEvent) =>
                            setValue6(changeEvent.target.value)
                          }
                          min={0}
                          max={10}
                        /> */}
                      <img
                        src={imgstatic}
                        alt=""
                        className="w-auto mx-auto d-block"
                      />
                      <h4 className="h7 shadow-5in p-2 br-5 d-inline-block">
                        {" "}
                        {t("profile_section.dog")}{" "}
                      </h4>
                    </div>
                    <div className="col-md-6 tooltipHide flex-column">
                      <img
                        src={img8s}
                        alt=""
                        width="53"
                        height="52"
                        className=" rounded-circle"
                      />
                      {/* <RangeSlider
                          value={value7}
                          onChange={(changeEvent) =>
                            setValue7(changeEvent.target.value)
                          }
                          min={0}
                          max={10}
                        /> */}
                      <img
                        src={imgstatic}
                        alt=""
                        className="w-auto mx-auto d-block"
                      />
                      <h4 className="h7 shadow-5in p-2 br-5 d-inline-block">
                        {" "}
                        {t("profile_section.cat")}{" "}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <ProfileViewOne />
            </div>
            <div className="col-lg-4 col-446 col-md-6">
              <div className="testiView text-center mb-2 h-645 pt-4 d-flex justify-content-center align-items-center flex-column">
                <div className="position-relative customtesti shadow-4 py-5 px-5 br-20">
                  <img src={img67} alt="" />
                  <h4>Quote of the Day</h4>
                  <div className="inner-cust innerHeight">
                    <div
                      id="carouselExampleIndicators"
                      class="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <p>
                            Lorem ipsum dolor sit amet consectetur. Ullamcorper
                            dictum aliquam pulvinar imperdiet. Massa congue eget
                            enim ultricies nec nibh.
                          </p>
                        </div>
                        <div className="carousel-item">
                          <p>
                            Lorem ipsum dolor sit amet consectetur. Ullamcorper
                            dictum aliquam pulvinar imperdiet. Massa congue eget
                            enim ultricies nec nibh.
                          </p>
                        </div>
                        <div className="carousel-item">
                          <p>
                            Lorem ipsum dolor sit amet consectetur. Ullamcorper
                            dictum aliquam pulvinar imperdiet. Massa congue eget
                            enim ultricies nec nibh.
                          </p>
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex mt-n6 flex-wrap">
                    <div className="w-50">
                      <img src={img68} alt="" />
                    </div>
                    <div className="w-50 d-flex justify-content-end align-items-end">
                      <img src={img70} alt="" />
                    </div>
                    <div className="probtm">
                      <img src={img71} alt="" className="probtm" />
                      <h6>Emma Gilles</h6>
                    </div>
                  </div>
                </div>
                {/* <div className=" testiInner w-100 d-flex justify-content-center align-items-center mb-3">
                  {t('profile_section.just_like_coco_chanel_i_am_my_own_brand')} 
                </div> */}
                {/* <div className="d-flex justify-content-center align-items-center">
                  <span className="me-2"></span>
                  <span className="me-2"></span>
                  <span></span>
                </div> */}
              </div>
              <ProfileViewTwo />
            </div>
            <div className="col-lg-4 col-488 col-md-6">
              <div className="h-645">
                <div className="d-flex flex-wrap justify-content-between py-5">
                  <div className="flagsec w-100s mb-1 text-center tooltipHide flex-column">
                    <div className="flag">
                      <ImageBox boxwidth="w-34 shadow-4" src={flag1} />
                    </div>
                    {/* <RangeSlider
                        value={value8}
                        onChange={(changeEvent) =>
                          setValue8(changeEvent.target.value)
                        }
                        min={0}
                        max={10}
                      /> */}
                    <img src={imgstatic} alt="" className="w-auto" />
                    <h4 className="h7 shadow-5in p-2 br-5 d-inline-block">
                      {t("profile_section.Nationality_1")}
                    </h4>
                  </div>
                  <div className="flagsec w-100s mb-1 text-center tooltipHide flex-column">
                    <div className="flag">
                      <ImageBox boxwidth="w-34 shadow-4" src={flag} />
                    </div>
                    {/* <RangeSlider
                        value={value1}
                        onChange={(changeEvent) =>
                          setValue1(changeEvent.target.value)
                        }
                        min={0}
                        max={10}
                      /> */}
                    <img src={imgstatic} alt="" className="w-auto" />
                    <h4 className="h7 shadow-5in br-5 p-2 d-inline-block">
                      {t("profile_section.Nationality_2")}
                    </h4>
                  </div>
                </div>
                <Education />
              </div>
              <ProfileViewThree />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
