import React from "react";
import { useState } from "react";
import "../regularTheme6Mobile/RegularTheme6Mobile.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";


export default function RegularTheme6Mobile() {
  return (
    <div>
      <div className="">
        <div>
          <div className="">
            <div className="d-flex align-items-center justify-content-center mb-5">
              <div className="toggle_button">
                <button type="" className="btn active_btn">Monthly</button>
                <button type="" className="btn1 ">Yearly</button>
              </div>
            </div>
            <div className="main_wrapper mx-auto">
              <div className="bg_light p-2">
                <div className="plan_outer_wrapper mx-auto">
                  <div className="relative">
                    <div className="content_wrapper1">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Perception of Self</Accordion.Header>
                          <Accordion.Body>
                            <div className="tab_content">
                              <div className="">
                                <h1>$99</h1>
                                <h3>2X 30 Minutes Coaching Sessions</h3>
                                <small>(cost per session)</small>
                                <div className="mt-5">
                                  <h5>30 Minute Free Assessment Consultation (value $50)</h5>
                                  <ul>
                                    <li>SELF-IMAGE EVALUATION</li>
                                    <li>SELF-IMAGE SCORE</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Self-Talk</Accordion.Header>
                          <Accordion.Body>
                            <div className="tab_content">
                              <div className="">
                                <h1>$99</h1>
                                <h3>2X 30 Minutes Coaching Sessions</h3>
                                <small>(cost per session)</small>
                                <div className="mt-5">
                                  <h5>30 Minute Free Assessment Consultation (value $50)</h5>
                                  <ul>
                                    <li>SELF-IMAGE EVALUATION</li>
                                    <li>SELF-IMAGE SCORE</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>World Perception of You</Accordion.Header>
                          <Accordion.Body>
                            <div className="tab_content">
                              <div className="">
                                <h1>$99</h1>
                                <h3>2X 30 Minutes Coaching Sessions</h3>
                                <small>(cost per session)</small>
                                <div className="mt-5">
                                  <h5>30 Minute Free Assessment Consultation (value $50)</h5>
                                  <ul>
                                    <li>SELF-IMAGE EVALUATION</li>
                                    <li>SELF-IMAGE SCORE</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>Ideal-Self</Accordion.Header>
                          <Accordion.Body>
                            <div className="tab_content">
                              <div className="">
                                <h1>$99</h1>
                                <h3>2X 30 Minutes Coaching Sessions</h3>
                                <small>(cost per session)</small>
                                <div className="mt-5">
                                  <h5>30 Minute Free Assessment Consultation (value $50)</h5>
                                  <ul>
                                    <li>SELF-IMAGE EVALUATION</li>
                                    <li>SELF-IMAGE SCORE</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-center align-items-center tab_btn">
                  <button type="" className="btn_left me-2 mt-5 mb-4">Questionnaire</button>
                  <button type="" className="btn_right mt-5 mb-4 ms-2">BOOK NOW</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
