import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ButtonBox(props) {
  return (
    <div className= {!props.close ? "" : "close"  }>
      <div className={"btn-bg bg d-inline-block position-relative " + props.boxwidth}>
        {props.clicked}
        <button id="hide_me1" className="border-0 bg-transparent w-100 h-100 " onClick={props.btnclick} data-bs-toggle={props.modal} data-bs-target={props.modalId} data-bs-dismiss={props.modalClose}>
          <FontAwesomeIcon icon={props.icon} className="h5 m-0 me-2" />
          {props.name}
        </button>
      </div>
    </div>
  );
}
