import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavItem } from "react-bootstrap";
import ButtonBox from "../../../../global/ButtonBox";
import "../ProfileHeroSectionThemes.scss";
import "./ProfileHeroSectionNeumorphicTheme1Desktop.scss";
import tab_icon from "../../../../../images/Tab-icon.png";
import mobile_icon from "../../../../../images/mobile-icon.png";
import { faDesktop, faLock } from "@fortawesome/free-solid-svg-icons";

import active_vector from "../../../../../images/active-vector.png";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

import RegularTheme1Desktop from "../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop";
import RegularTheme1Tab from "../regularTheme1/regularTheme1Tab/RegularTheme1Tab";

import RegularTheme1Mobile from "../regularTheme1/regularTheme1Mobile/RegularTheme1Mobile";
import { useTranslation } from "react-i18next";

export default function ProfileHeroSectionNeumorphicThemeDesktopView1() {
  const { t, i18n } = useTranslation();

  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });

  return (
    <div>
      <h4 className="text-center">
        {t("profile_hero_section.switch_the_style")}
      </h4>

      <div className="d-flex flex-column-reverse">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div className="Neumorphic-design">
                <div className="design-screen-desktop-1">
                  <RegularTheme1Desktop />
                </div>
              </div>

              <div className="w-100 d-flex justify-content-center align-items-center banner-btn">
                <ButtonBox
                  boxwidth="w-150 mt-5 lg text-primary mb-4 btn-left"
                  clicked="Sacing"
                  name="Save/Next"
                />
                <ButtonBox
                  boxwidth="w-200 mt-5 lg text-primary mb-4 ml-3 "
                  clicked="Sacing"
                  name="Close and continue"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div className="Neumorphic-design">
                <div className="design-screen-tab-1">
                  <RegularTheme1Tab />
                </div>
              </div>
              <div className="w-100 d-flex justify-content-center align-items-center banner-btn">
                <ButtonBox
                  boxwidth="w-150 mt-5 lg text-primary mb-4 btn-left"
                  clicked="Sacing"
                  name="Save/Next"
                />
                <ButtonBox
                  boxwidth="w-200 mt-5 lg text-primary mb-4 ml-3 "
                  clicked="Sacing"
                  name="Close and continue"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="three">
              <div className="Neumorphic-design">
                <div className="design-screen-mobile-1">
                  <RegularTheme1Mobile />
                </div>
              </div>
              <div className="w-100 d-flex justify-content-center align-items-center banner-btn">
                <ButtonBox
                  boxwidth="w-150 mt-5 lg text-primary mb-4 btn-left"
                  clicked="Sacing"
                  name="Save/Next"
                />
                <ButtonBox
                  boxwidth="w-200 mt-5 lg text-primary mb-4 ml-3 "
                  clicked="Sacing"
                  name="Close and continue"
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
          <div className="d-flex align-items-center justify-center  w-100 mb-0 ">
            <Nav
              variant="pills"
              className="w-100 d-flex  align-items-center justify-content-center"
            >
              <ul className="d-flex   my-4 pb-2 align-items-center modal-tabs justify-content-center">
                <Nav.Item as="li" className="me-0">
                  <Nav.Link
                    className="m-0 p-0 border-0 design-one-size"
                    eventKey="first"
                  >
                    <span className="d-flex align-items-center ">
                      <FontAwesomeIcon
                        className="icon-design"
                        icon={faDesktop}
                      ></FontAwesomeIcon>{" "}
                      {t("profile_hero_section.desktop")}
                    </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
                  <Nav.Link
                    className="m-0 p-0 border-0 design-one-size"
                    eventKey="second"
                  >
                    <span className="d-flex align-items-center ">
                      {" "}
                      <div className="icon-design">
                        <img src={tab_icon} alt="icon-tab"></img>
                      </div>{" "}
                      {t("profile_hero_section.tab")}
                    </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
                  <Nav.Link
                    className="m-0 p-0 border-0 design-one-size"
                    eventKey="three"
                  >
                    <span className="d-flex align-items-center ">
                      <div className="icon-design">
                        <img src={mobile_icon} alt="icon-mobile"></img>
                      </div>
                      {t("profile_hero_section.mobile")}
                    </span>
                  </Nav.Link>
                </Nav.Item>
              </ul>
            </Nav>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
}
