import React from "react";
import "./TimelineTab.scss";

import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { NavItem } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

library.add(faCaretRight);

export default function TimelineTabMobile() {
  const { t, i18n } = useTranslation();
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });

  return (
    <div>
      <Tab.Container defaultActiveKey="firstss">
        <div className="mobile">
          <div className="ml-4 margin-set width-set-mobile">
            <div className="flex">
              <div className="ProgressBar-timeline">
                <Nav variant="pills" className="flex-column">
                  <ul className="newbarTab">
                    <div>
                      <Link className="card-1 " to="/niche-clarity-discovery">
                        <NavItem as="li" className="card-img-top img-raised">
                          <h6>1</h6>
                        </NavItem>

                        <div className="banner-content">
                          <div className="card-width">
                            <div className="card-tab active-tab card-1-content">
                              <div className="flex justifY-space">
                                <div className="card-text">
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className=" card-icon"
                                  />
                                  <h5>
                                    "Niche Clarity" Discovery
                                  </h5>
                                </div>
                                <div className="card-progress">
                                  <div className="progress-line"></div>
                                  <div className="circles-small">
                                    <div className="circle-small">
                                      <div className="text"><span className="d-block">100%</span></div>
                                      <svg>
                                        <circle
                                          className="bg"
                                          cx="30"
                                          cy="30"
                                          r="20"
                                        ></circle>
                                        <circle
                                          className="progress one"
                                          cx="33"
                                          cy="36"
                                          r="26"
                                        ></circle>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link className="card-1 " to="/market-research-size-validation">
                        <NavItem as="li" className="card-img-top img-raised">
                          <h6>2</h6>
                        </NavItem>

                        <div className="banner-content">
                          <div className="card-width">
                            <div className="card-tab active-tab card-1-content">
                              <div className="flex justifY-space">
                                <div className="card-text">
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className=" card-icon"
                                  />
                                  <h5>
                                    "Market Research" & Size Validation
                                  </h5>
                                </div>
                                <div className="card-progress">
                                  <div className="progress-line"></div>
                                  <div className="circles-small">
                                    <div className="circle-small">
                                      <div className="text"><span className="d-block">100%</span></div>
                                      <svg>
                                        <circle
                                          className="bg"
                                          cx="30"
                                          cy="30"
                                          r="20"
                                        ></circle>
                                        <circle
                                          className="progress one"
                                          cx="33"
                                          cy="36"
                                          r="26"
                                        ></circle>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link className="card-1 " to="">
                        <NavItem as="li" className="card-img-top img-raised">
                          <h6>3</h6>
                        </NavItem>

                        <div className="banner-content">
                          <div className="card-width">
                            <div className="card-tab active-tab card-1-content">
                              <div className="flex justifY-space">
                                <div className="card-text">
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className=" card-icon"
                                  />
                                  <h5>
                                    Buyer Persona & Customer Segmentation
                                  </h5>
                                </div>
                                <div className="card-progress">
                                  <div className="progress-line"></div>
                                  <div className="circles-small">
                                    <div className="circle-small">
                                      <div className="text"><span className="d-block">100%</span></div>
                                      <svg>
                                        <circle
                                          className="bg"
                                          cx="30"
                                          cy="30"
                                          r="20"
                                        ></circle>
                                        <circle
                                          className="progress one"
                                          cx="33"
                                          cy="36"
                                          r="26"
                                        ></circle>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link className="card-1 " to="">
                        <NavItem as="li" className="card-img-top img-raised">
                          <h6>4</h6>
                        </NavItem>

                        <div className="banner-content">
                          <div className="card-width">
                            <div className="card-tab active-tab card-1-content">
                              <div className="flex justifY-space">
                                <div className="card-text">
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className=" card-icon"
                                  />
                                  <h5>
                                    "Buyer's Journey" Mapping
                                  </h5>
                                </div>
                                <div className="card-progress">
                                  <div className="progress-line"></div>
                                  <div className="circles-small">
                                    <div className="circle-small">
                                      <div className="text"><span className="d-block">100%</span></div>
                                      <svg>
                                        <circle
                                          className="bg"
                                          cx="30"
                                          cy="30"
                                          r="20"
                                        ></circle>
                                        <circle
                                          className="progress one"
                                          cx="33"
                                          cy="36"
                                          r="26"
                                        ></circle>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link className="card-1 " to="">
                        <NavItem as="li" className="card-img-top img-raised">
                          <h6>5</h6>
                        </NavItem>

                        <div className="banner-content">
                          <div className="card-width">
                            <div className="card-tab active-tab card-1-content">
                              <div className="flex justifY-space">
                                <div className="card-text">
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className=" card-icon"
                                  />
                                  <h5>
                                    Branding Framework & Design Packages
                                  </h5>
                                </div>
                                <div className="card-progress">
                                  <div className="progress-line"></div>
                                  <div className="circles-small">
                                    <div className="circle-small">
                                      <div className="text"><span className="d-block">100%</span></div>
                                      <svg>
                                        <circle
                                          className="bg"
                                          cx="30"
                                          cy="30"
                                          r="20"
                                        ></circle>
                                        <circle
                                          className="progress one"
                                          cx="33"
                                          cy="36"
                                          r="26"
                                        ></circle>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link className="card-1 " to="">
                        <NavItem as="li" className="card-img-top img-raised">
                          <h6>6</h6>
                        </NavItem>

                        <div className="banner-content">
                          <div className="card-width">
                            <div className="card-tab active-tab card-1-content">
                              <div className="flex justifY-space">
                                <div className="card-text">
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className=" card-icon"
                                  />
                                  <h5>
                                    Business "Strategic Plan"
                                  </h5>
                                </div>
                                <div className="card-progress">
                                  <div className="progress-line"></div>
                                  <div className="circles-small">
                                    <div className="circle-small">
                                      <div className="text"><span className="d-block">100%</span></div>
                                      <svg>
                                        <circle
                                          className="bg"
                                          cx="30"
                                          cy="30"
                                          r="20"
                                        ></circle>
                                        <circle
                                          className="progress one"
                                          cx="33"
                                          cy="36"
                                          r="26"
                                        ></circle>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link className="card-1 " to="/pick-business-address">
                        <NavItem as="li" className="card-img-top img-raised">
                          <h6>7</h6>
                        </NavItem>

                        <div className="banner-content">
                          <div className="card-width">
                            <div className="card-tab active-tab card-1-content">
                              <div className="flex justifY-space">
                                <div className="card-text">
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className=" card-icon"
                                  />
                                  <h5>
                                    Pick Your "Business Address"
                                  </h5>
                                </div>
                                <div className="card-progress">
                                  <div className="progress-line"></div>
                                  <div className="circles-small">
                                    <div className="circle-small">
                                      <div className="text"><span className="d-block">100%</span></div>
                                      <svg>
                                        <circle
                                          className="bg"
                                          cx="30"
                                          cy="30"
                                          r="20"
                                        ></circle>
                                        <circle
                                          className="progress one"
                                          cx="33"
                                          cy="36"
                                          r="26"
                                        ></circle>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link className="card-1 " to="/secure-business-phone-number">
                        <NavItem as="li" className="card-img-top img-raised">
                          <h6>8</h6>
                        </NavItem>

                        <div className="banner-content">
                          <div className="card-width">
                            <div className="card-tab active-tab card-1-content">
                              <div className="flex justifY-space">
                                <div className="card-text">
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className=" card-icon"
                                  />
                                  <h5>
                                    Secure Your "Business Phone Number"
                                  </h5>
                                </div>
                                <div className="card-progress">
                                  <div className="progress-line"></div>
                                  <div className="circles-small">
                                    <div className="circle-small">
                                      <div className="text"><span className="d-block">100%</span></div>
                                      <svg>
                                        <circle
                                          className="bg"
                                          cx="30"
                                          cy="30"
                                          r="20"
                                        ></circle>
                                        <circle
                                          className="progress one"
                                          cx="33"
                                          cy="36"
                                          r="26"
                                        ></circle>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to="/business-email-address"
                      >
                        <NavItem as="li">
                          <h6>9</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.generate_your_business_email_address"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className="card-1 " to="/logo">
                        <NavItem as="li" className="card-img-top img-raised">
                          <h6> 10</h6>
                        </NavItem>

                        <div className="banner-content">
                          <div className="card-width">
                            <div className="card-tab active-tab card-1-content">
                              <div className="flex justifY-space">
                                <div className="card-text">
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className=" card-icon"
                                  />
                                  <h5>
                                    Generate Your “Business Logo” | Favicon Icon
                                  </h5>
                                </div>
                                <div className="card-progress">
                                  <div className="progress-line"></div>
                                  <div className="circles-small">
                                    <div className="circle-small">
                                      <div className="text"><span className="d-block">100%</span></div>
                                      <svg>
                                        <circle
                                          className="bg"
                                          cx="30"
                                          cy="30"
                                          r="20"
                                        ></circle>
                                        <circle
                                          className="progress one"
                                          cx="33"
                                          cy="36"
                                          r="26"
                                        ></circle>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link className="card-1 " to="/profile">
                        <NavItem as="li" className="card-img-top img-raised">
                          <h6>11</h6>
                        </NavItem>

                        <div className="banner-content">
                          <div className="card-width">
                            <div className="card-tab active-tab card-1-content">
                              <div className="flex justifY-space">
                                <div className="card-text">
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className=" card-icon"
                                  />
                                  <h5>
                                    Complete Your "Business Profile"
                                  </h5>
                                </div>
                                <div className="card-progress">
                                  <div className="progress-line"></div>
                                  <div className="circles-small">
                                    <div className="circle-small">
                                      <div className="text"><span className="d-block">100%</span></div>
                                      <svg>
                                        <circle
                                          className="bg"
                                          cx="30"
                                          cy="30"
                                          r="20"
                                        ></circle>
                                        <circle
                                          className="progress one"
                                          cx="33"
                                          cy="36"
                                          r="26"
                                        ></circle>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link className="card-1 " to="">
                        <NavItem as="li" className="card-img-top img-raised">
                          <h6>12</h6>
                        </NavItem>

                        <div className="banner-content">
                          <div className="card-width">
                            <div className="card-tab active-tab card-1-content">
                              <div className="flex justifY-space">
                                <div className="card-text">
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className=" card-icon"
                                  />
                                  <h5>
                                    Secure Business VPN | Virtual Private Network
                                  </h5>
                                </div>
                                <div className="card-progress">
                                  <div className="progress-line"></div>
                                  <div className="circles-small">
                                    <div className="circle-small">
                                      <div className="text"><span className="d-block">100%</span></div>
                                      <svg>
                                        <circle
                                          className="bg"
                                          cx="30"
                                          cy="30"
                                          r="20"
                                        ></circle>
                                        <circle
                                          className="progress one"
                                          cx="33"
                                          cy="36"
                                          r="26"
                                        ></circle>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to="/electronic-signature"
                      >
                        <NavItem as="li">
                          <h6> 13</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.generate_your_electronic_signature"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to="/email-signature"
                      >
                        <NavItem as="li">
                          <h6> 14</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.generate_your_email_signature"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        to="/digital-business-card"
                        className="card-1 card-img-top img-raised"
                      >
                        <NavItem as="li">
                          <h6> 15</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Generate your
                                  “Digital Business Card”
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        to=""
                        className="card-1 card-img-top img-raised"
                      >
                        <NavItem as="li">
                          <h6> 16</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Step-up Your Appointment Booking & Live Chat
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to="/custom-domain"
                      >
                        <NavItem as="li" className="card-img-top img-raised">
                          <h6>17</h6>
                        </NavItem>
                        <div className="banner-content">
                          <div className="card-width">
                            <div className="card-tab active-tab card-1-content">
                              <div className="flex justifY-space">
                                <div className="card-text">
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className=" card-icon"
                                  />
                                  <h5 className="mb-0">
                                    Secure Your Business Domain & Hosting & Site General Settings
                                  </h5>
                                </div>
                                <div className="card-progress">
                                  <div className="progress-line"></div>
                                  <div className="circles-small">
                                    <div className="circle-small">
                                      <div className="text"><span className="d-block">100%</span></div>
                                      <svg>
                                        <circle
                                          className="bg"
                                          cx="30"
                                          cy="30"
                                          r="20"
                                        ></circle>
                                        <circle
                                          className="progress one"
                                          cx="33"
                                          cy="36"
                                          r="26"
                                        ></circle>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="/website-design-style"
                        className="card-1 card-img-top img-raised"
                      >
                        <NavItem as="li">
                          <h6> 18</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Choose Your Website: Design Theme & Layout

                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-1 card-img-top img-raised "
                        to="/profile-hero-banner"
                      >
                        <NavItem as="li" className="card-img-top img-raised">
                          <h6>19</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_website_hero_banner_and_aboutme_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to="/how-it-works"
                      >
                        <NavItem as="li">
                          <h6>20</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_how_it_works_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to="/service-package-plans"
                      >
                        <NavItem as="li">
                          <h6> 21</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_service_package_plans_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to="/hire-me"
                      >
                        <NavItem as="li">
                          <h6>22</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Choose Your “Hire Me”
                                  “Appointment Booking” section
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to="/testimonial-case-study"
                      >
                        <NavItem as="li">
                          <h6> 23</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Choose your “Testimonials |
                                  Case Studies | wall of love” section

                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to="/subscriptions"
                      >
                        <NavItem as="li">
                          <h6>24</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_subscription_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to="/blog-posts"
                      >
                        <NavItem as="li">
                          <h6> 25</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_blog_post_style_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className="card-1 card-img-top img-raised" to="/podcast">
                        <NavItem as="li">
                          <h6>

                            26
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_podcast_series_style_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className="card-1 card-img-top img-raised" to="/video-playlist">
                        <NavItem as="li">
                          <h6>

                            27
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_video_playlist_style_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to="/events"
                      >
                        <NavItem as="li">
                          <h6>28</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_events_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to="/freebies"
                      >
                        <NavItem as="li">
                          <h6>29</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_freebies_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to="/featured-on"
                      >
                        <NavItem as="li">
                          <h6>30</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.Choose_your_as_seen_featured_on_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className="card-1 card-img-top img-raised" to="/e-magazine">
                        <NavItem as="li">
                          <h6>
                            31
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_emagazines_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className="card-1 card-img-top img-raised" to="/press-release">
                        <NavItem as="li">
                          <h6>
                            32
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_press_release_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className="card-1 card-img-top img-raised" to="/contests">
                        <NavItem as="li">
                          <h6>

                            33
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_contests_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className="card-1 card-img-top img-raised" to="/portfolio">
                        <NavItem as="li">
                          <h6>

                            34
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_portfolio_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className='card-1 card-img-top img-raised fw-normal' to="/meet-our-team" >
                        <NavItem as="li"   ><h6>     35
                        </h6></NavItem>
                      </Link>

                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_team_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className='card-1 card-img-top img-raised fw-normal' to="/faqs">
                        <NavItem as="li"   ><h6>    36
                        </h6></NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Generate your Business “FAQ’s” section

                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className='card-1 card-img-top img-raised fw-normal' to="/sponorships">
                        <NavItem as="li"   ><h6>     37
                        </h6></NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Choose your “Sponsorship | collab” section

                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Link className='card-1 card-img-top img-raised fw-normal' to="/online-course">
                        <NavItem as="li"   ><h6>     38
                        </h6></NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Choose your “Online Course” section


                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Link className='card-1 card-img-top img-raised fw-normal' to="/online-store">
                        <NavItem as="li">
                          <h6>

                            39
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  {t(
                                    "timeline_tab_component.choose_your_online_store_section"
                                  )}
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className="card-1 card-img-top img-raised" to="/contact-us">
                        <NavItem as="li">
                          <h6>

                            40
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Choose Your “Contact Me” |
                                  “Live Chat” section

                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className="card-1 card-img-top img-raised fw-normal" to="/resume">
                        <NavItem as="li">
                          <h6>

                            41
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Create Your ”Jobs & Career” |
                                  “Resume | CV” Section
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className="card-1 card-img-top img-raised fw-normal" to="/weekend-mode">
                        <NavItem as="li">
                          <h6>

                            42
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Choose your
                                  “Weekend | Social Mode” section

                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className="card-1 card-img-top img-raised fw-normal" to="">
                        <NavItem as="li">
                          <h6>

                            43
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Choose Your "Website Legal Terms" & "Cookie Banner & Settings"

                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className="card-1 card-img-top img-raised fw-normal" to="">
                        <NavItem as="li">
                          <h6>

                            44
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Generate  "Bio Links”
                                  For All Of Your Business

                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link className="card-1 card-img-top img-raised fw-normal" to="/affiliate-programs">
                        <NavItem as="li">
                          <h6>
                            45
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Set Up Your “affiliate programs”
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Nav.Item className="card-1 card-img-top img-raised">
                        <NavItem as="li">
                          <h6>

                            <Nav.Link> 46</Nav.Link>
                          </h6>
                        </NavItem>
                      </Nav.Item>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  All SEO: Google | yahoo | Bing | Baidu
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Nav.Item className="card-1 card-img-top img-raised">
                        <NavItem as="li">
                          <h6>

                            <Nav.Link> 47</Nav.Link>
                          </h6>
                        </NavItem>
                      </Nav.Item>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Featured Your Site on
                                  “Beta-List StartUps”
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Nav.Item className="card-1 card-img-top img-raised">
                        <NavItem as="li">
                          <h6>

                            <Nav.Link> 48</Nav.Link>
                          </h6>
                        </NavItem>
                      </Nav.Item>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Introduce Your Business to
                                  “100 Pocket-List” Prospects
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Nav.Item className="card-1 card-img-top img-raised">
                        <NavItem as="li">
                          <h6>

                            <Nav.Link> 49</Nav.Link>
                          </h6>
                        </NavItem>
                      </Nav.Item>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Claim Your “Virtual Office Booth” | Generate Your “Avatar”
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        to="/elevator-pitch"
                        className="card-1 card-img-top img-raised"
                      >
                        <NavItem as="li">
                          <h6>

                            50
                          </h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Generate Your 15 Second
                                  “Elevator Pitch | Slide Deck”

                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Nav.Item className="card-1 card-img-top img-raised">
                        <NavItem as="li">
                          <h6>

                            <Nav.Link> 51</Nav.Link>
                          </h6>
                        </NavItem>
                      </Nav.Item>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Generate "qr codes”
                                  for all Of Your business
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to=""
                      >
                        <NavItem as="li">
                          <h6> 52</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Generate An “App” for your Business
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to=""
                      >
                        <NavItem as="li">
                          <h6> 53</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Preview Your Website & Front-End Customization Settings
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to=""
                      >
                        <NavItem as="li">
                          <h6> 54</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  3-hours Biz Launch Challenge
                                  "Certificate Award"
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        className="card-1 card-img-top img-raised"
                        to=""
                      >
                        <NavItem as="li">
                          <h6> 55</h6>
                        </NavItem>
                      </Link>
                      <div className="banner-content">
                        <div className="card-width">
                          <div className="card-tab active-tab card-1-content">
                            <div className="flex justifY-space">
                              <div className="card-text">
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className=" card-icon"
                                />
                                <h5>
                                  Step-Up Your Client Portal
                                </h5>
                              </div>
                              <div className="card-progress">
                                <div className="progress-line"></div>
                                <div className="circles-small">
                                  <div className="circle-small">
                                    <div className="text"><span className="d-block">100%</span></div>
                                    <svg>
                                      <circle
                                        className="bg"
                                        cx="30"
                                        cy="30"
                                        r="20"
                                      ></circle>
                                      <circle
                                        className="progress one"
                                        cx="30"
                                        cy="31"
                                        r="20"
                                      ></circle>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </Nav>
              </div>
            </div>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
}
