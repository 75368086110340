import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';
import { faCloudArrowDown} from "@fortawesome/free-solid-svg-icons";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { useTranslation } from "react-i18next";
export default function HeroBottomTabsComponent() {
  const { t, i18n } = useTranslation();
  return (
  <div>
                  <div className="bottom-tabs" >
                  <FontAwesomeIcon icon={faCloudArrowDown} ></FontAwesomeIcon>
                  <h6>
                    {t('profile_hero_section.download_cv')}
                  </h6>
                </div>
  </div>


  );
}
