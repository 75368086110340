import React, { useState } from "react";
import desktop_2_16 from "../../../../../images/website-mode/Desktop2-16.png";
import tab_2_16 from "../../../../../images/website-mode/Tab2-16.png"
import mobile_2_16 from "../../../../../images/website-mode/Mobile2-16.png"
import desktop_2_17 from "../../../../../images/website-mode/Desktop2-17.png";
import tab_2_17 from "../../../../../images/website-mode/Tab2-17.png"
import mobile_2_17 from "../../../../../images/website-mode/Mobile2-17.png"
import '../WeekendModeSectionThemes.scss';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import ButtonBoxNew from "../../../../global/ButtonBoxNew";

export default function Section402NeumorphicTheme() {
  
  const [desktopShow, setDesktopShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [themedesktopShow, setThemedesktopShow] = useState(false);
  const [themetabShow, setThemetabShow] = useState(false);
  const [themedesktop3Show, setThemedesktop3Show] = useState(false);
  const [themetab3Show, setThemetab3Show] = useState(false);
  const [thememobileShow, setThememobileShow] = useState(false);
  const [thememobile3Show, setThememobile3Show] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);

  return (
    <div className="Neumorphic-design how-it-work-neumorphic">
   
<h4 className="text-center">
Neumorphic  Design Version 1
</h4>
<div className="d-flex card-outo">
<div className="">
<div className="screen-bg">
  <img src={desktop_2_16} alt='desktop-2-1' >
  </img>

</div>

{/* <button className="screen-btn" typeof="button" data-bs-toggle="modal" data-bs-target="#staticBackdropNem">
See Desktop view
</button> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setDesktopShow(true)}/>
          <Modal
    
    dialogClassName="modal-90w"
    aria-labelledby="example-custom-modal-styling-title"
            show={desktopShow}
    onHide={() => setDesktopShow(false)}
    
  >
    <Modal.Header closeButton>
     
    </Modal.Header>
    <Modal.Body>
    {/* <HowItWorkSectionNeumorphicThemeTabView1 /> */}

    </Modal.Body>
  </Modal>
{/* <div className="modal fade" id="staticBackdropNem" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropNemLabel" aria-hidden="true">
  <div className="modal-dialog modal-fullscreen" >
    <div className="modal-content">
      <div className="modal-header ">
    
        <button  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
       <div>
      
     
       </div>
      </div>
      
    </div>
  </div>
</div> */}
</div>
<div className="">
<div className="screen-bg">
<img src={tab_2_16} alt="tab_2_1"  ></img>
</div>
{/* <div className="screen-btn"   onClick={() => setSmShow(true)}>
See Tablet view
</div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setSmShow(true)}/>

<Modal
    
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
                show={smShow}
        onHide={() => setSmShow(false)}
        
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        {/* <HowItWorkSectionNeumorphicThemeTabView1 /> */}

        </Modal.Body>
      </Modal>
</div>

<div className="">
<div className="screen-bg">
<img src={mobile_2_16} alt="mobile_2_1"></img>
</div>
{/* <div className="screen-btn" onClick={() => setLgShow(true)}>
See Mobile view
</div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setLgShow(true)}/>

<Modal
        show={lgShow}
        onHide={() => setLgShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-titlee"
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        {/* <HowItWorkSectionNeumorphicThemeMobileView1 /> */}

        </Modal.Body>
      </Modal>
</div>

</div>

<h4 className="text-center">
Neumorphic  Design Version 2
</h4>
<div className="d-flex card-outo">
<div className="">
<div className="screen-bg">
  <img src={desktop_2_17} alt='desktop-2-1' >
  </img>

</div>

{/* <button className="screen-btn" typeof="button" data-bs-toggle="modal" data-bs-target="#staticBackdropNem">
See Desktop view
</button> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setDesktopShow(true)}/>
          <Modal
    
    dialogClassName="modal-90w"
    aria-labelledby="example-custom-modal-styling-title"
            show={desktopShow}
    onHide={() => setDesktopShow(false)}
    
  >
    <Modal.Header closeButton>
     
    </Modal.Header>
    <Modal.Body>
    {/* <HowItWorkSectionNeumorphicThemeTabView1 /> */}

    </Modal.Body>
  </Modal>
{/* <div className="modal fade" id="staticBackdropNem" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropNemLabel" aria-hidden="true">
  <div className="modal-dialog modal-fullscreen" >
    <div className="modal-content">
      <div className="modal-header ">
    
        <button  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
       <div>
      
     
       </div>
      </div>
      
    </div>
  </div>
</div> */}
</div>
<div className="">
<div className="screen-bg">
<img src={tab_2_17} alt="tab_2_1"  ></img>
</div>
{/* <div className="screen-btn"   onClick={() => setSmShow(true)}>
See Tablet view
</div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setSmShow(true)}/>

<Modal
    
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
                show={smShow}
        onHide={() => setSmShow(false)}
        
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        {/* <HowItWorkSectionNeumorphicThemeTabView1 /> */}

        </Modal.Body>
      </Modal>
</div>

<div className="">
<div className="screen-bg">
<img src={mobile_2_17} alt="mobile_2_1"></img>
</div>
{/* <div className="screen-btn" onClick={() => setLgShow(true)}>
See Mobile view
</div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mt-0 mb-4 mx-auto" onClick={() => setLgShow(true)}/>

<Modal
        show={lgShow}
        onHide={() => setLgShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-titlee"
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        {/* <HowItWorkSectionNeumorphicThemeMobileView1 /> */}

        </Modal.Body>
      </Modal>
</div>

</div>




    </div>
  );
}
