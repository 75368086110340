import React from 'react'
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
export default function HowItWorkCards() {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [Competency1, setCompetency1] = useState(10);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div>
            <div className="how-it-work-programs-wrapper">
                  {/* <div className="programs-flex d-flex justify-content-center"> */}
                  <Slider {...settings} className="">
                    <div className="how-it-work-program">
                      <h5>COACHING</h5>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Ullamcorper
                        dictum aliquam pulvinar imperdiet. Massa congue eget
                        enim ultricies nec nibh.
                      </p>
                      <div className="how-it-work-program-numbering">
                        <span>1</span>
                      </div>
                    </div>
                    <div className="how-it-work-program">
                      <h5>SELF DEVELOPMENT</h5>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Ullamcorper
                        dictum aliquam pulvinar imperdiet. Massa congue eget
                        enim ultricies nec nibh.
                      </p>
                      <div className="how-it-work-program-numbering">
                        <span>2</span>
                      </div>
                    </div>

                    <div className="how-it-work-program">
                      <h5>LIFE PROGRAMS</h5>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Ullamcorper
                        dictum aliquam pulvinar imperdiet. Massa congue eget
                        enim ultricies nec nibh.
                      </p>
                      <div className="how-it-work-program-numbering">
                        <span>3</span>
                      </div>
                    </div>
                    <div className="how-it-work-program">
                      <h5>SUPPORT</h5>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Ullamcorper
                        dictum aliquam pulvinar imperdiet. Massa congue eget
                        enim ultricies nec nibh.
                      </p>
                      <div className="how-it-work-program-numbering">
                        <span>4</span>
                      </div>
                    </div>
                    </Slider>
                  {/* </div> */}
                </div>
    </div>
  )
}
