import React, { useState } from "react";
import img1 from '../../images/profileview/img1.png';
import img2 from '../../images/profileview/imgs2.png';
import img3 from '../../images/profileview/imgs3.png';
import bg from '../../images/profileview/bgimg.png';
// import flag from "../../images/flag.png";
import ImageBox from "../global/ImageBox";
import { Link } from 'react-router-dom';
// import user from '../../images/img1.png';
// import user2 from '../../images/img123.png';
import img9 from '../../images/profileview/img15s.png';
import img10 from '../../images/profileview/img16s.png';
import img18 from '../../images/profileview/img17s.png';
import img18w from '../../images/profileview/img172.png';
import bgimg from '../../images/profileview/bg-img1.png';
import tag from '../../images/profileview/img2.png';
import state2 from '../../images/status2.png';
// import state3 from '../../images/profileview/stateblack.png';
import userimg from '../../images/profileview/userimg.png';
import { NavItem } from 'react-bootstrap';

import crown from "../../images/profileicon/img1.svg";
import fb from "../../images/profileicon/img2.svg";
import ins from "../../images/profileicon/img3.svg";
import twi from "../../images/profileicon/img4.svg";
import linked from "../../images/profileicon/img5.svg";
import pine from "../../images/profileicon/img6.svg";
import tube from "../../images/profileicon/img7.svg";
import tok from "../../images/profileicon/img8.svg";
import check from "../../images/profileicon/img10.svg";
import canda from "../../images/profileicon/img11.svg";
import vip from "../../images/profileicon/img12.svg";
import whts from "../../images/profileicon/img13.svg";

export default function SimpleView({setHandleView}) {
  return (
    <div className="bannerSection mx-auto text-center">
      <ul className="userlist w-950 d-flex justify-content-center align-items-center mx-auto">
        <NavItem as="li" className="mt-auto mb-3">
          <img src={img1} alt="" className="circle" />
        </NavItem>
        <NavItem as="li"  className="mt-auto mb-3">
          <img src={img2} alt="" className="circle" />
        </NavItem>
        <NavItem as="li"  className="ImgArea ImgArea3 mt-auto mb-3 position-relative">
          <img src={img3} alt="" className="circle" />
            <div className="tag top-0 start-0 end-0 mx-auto position-absolute">
              <ImageBox boxwidth="w-16" src={tag} />
            </div>
        </NavItem>
        <NavItem as="li">
          {/* <div className="ImgArea m-0 w-100 h-100">
            <div className="user position-relative mx-auto">
              <ImageBox boxwidth="w-100 userImg" src={user} />
              <div className="tag top-0 position-absolute">
                <ImageBox boxwidth="w-16" src={tag} />
              </div>
              <div className="flag position-absolute">
                <ImageBox boxwidth="w-24" src={flag} />
              </div>
              <div className="status bottom-0 end-0 position-absolute">
                <ImageBox boxwidth="w-19" src={state2} crown />
              </div>
            </div>
          </div> */}
          <div className="imgin5">
            <div className="position-relative mb-3">
              <img src={userimg} alt="" />
            </div>
            <Link to="" className="icon1"><img src={fb} alt=""/></Link>
            <Link to="" className="icon2"><img src={ins} alt=""/></Link>
            <Link to="" className="icon3"><img src={whts} alt=""/></Link>
            <Link to="" className="icon4"><img src={twi} alt=""/></Link>
            <Link to="" className="icon5"><img src={canda} alt=""/></Link>
            <Link to="" className="icon6"><img src={crown} alt=""/></Link>
            <Link to="" className="icon7"><img src={tok} alt=""/></Link>
            <Link to="" className="icon8"><img src={check} alt=""/></Link>
            <Link to="" className="icon9"><img src={tube} alt=""/></Link>
            <Link to="" className="icon10"><img src={linked} alt=""/></Link>
            <Link to="" className="icon11"><img src={vip} alt=""/></Link>
            <Link to="" className="icon12"><img src={pine} alt=""/></Link>
          </div>
          <div className="ng-btn1">
            <button className="tagName border-0 bg-transparent ng-btn">
              DYNA APPOLON
            </button>
          </div>
        </NavItem>
        <NavItem as="li"  className="mt-auto mb-3">
          <div className="bs-primary w-100 h-100 flex-column rounded-circle d-flex justify-content-center align-items-center">
            <h4 className="m-0">547</h4>
            <p className="m-0 h7">POSTS</p>
          </div>
        </NavItem>
        <NavItem as="li" className="position-relative mt-auto mb-3">
          <div className="status bottom-0 start-0 position-absolute">
            <ImageBox boxwidth="w-26" src={state2} />
          </div>
          <div className="topTag bg shadow-4 position-absolute br-100">
            <div class="shadow-5in position-relative bg z-index br-100 d-flex align-items-center justify-content-center p-9 w-100 h-100">
              <img src={img10} alt="" />
            </div>
          </div>
          <div className="bs-primary w-100 h-100 flex-column rounded-circle d-flex justify-content-center align-items-center">
            <h4 className="m-0">1243</h4>
            <p className="m-0 h7">Followers</p>
          </div>
          {/* <div className="status position-absolute">
            <div className="shadow-4 position-relative bg z-index br-100 d-flex align-items-center justify-content-center mx-auto w-24">
              <ImageBox boxwidth="w-19 shadow-0" src={state3} />
            </div>
          </div> */}
        </NavItem>
        <NavItem as="li" className="mt-auto mb-3">
          <div className="bs-primary w-100 h-100 flex-column rounded-circle d-flex justify-content-center align-items-center">
            <h4 className="m-0">689</h4>
            <p className="m-0 h7">Following</p>
          </div>
        </NavItem>
      </ul>
      <div className="w-950 imgMain shadow-5in br-40 p-20 mx-auto position-relative">
        
        <div className="leftbtn left-50 shadow-5in p-2 brs-10 bg position-absolute" onClick={() => setHandleView('video') }>
        {/* <button onClick={() => setHandleView('video') }>video</button> */}
          <Link >
            <img src={img9} alt="" className="br-10" />
          </Link>
        </div>
        <img src={bg} alt="" />
        <div className="leftbtn right-50 shadow-5in p-2 brs-10 bg position-absolute" onClick={() => setHandleView('audio') }>
        {/* <button onClick={() => setHandleView('audio') }>audio</button> */}
          <Link  className="shadow-4 w-100 h-100 d-block br-10">
            <img src={img18} alt="" className="br-10 w-100 h-100 bg-contain" />
          </Link>
        </div>
        <div className="top-0 bottom-0 start-0 end-0 mx-auto position-absolute">
            <img src={img18w} alt="" className="br-10 w-100 h-100 bg-contain centerimg" />
        </div>
        <div className="position-absolute bottom-20 start-0 end-0 mx-auto">
          <div className="ng-btn1">
            <Link to="/" className="tagName border-0 bg-transparent ng-btn">CEO | 9 TO 5 ESCAPEE</Link>
          </div>
        </div>
        <div className="position-absolute bottom-0 start-0 end-0 mx-auto">
          <img src={bgimg} alt="" className="bg-contain w-96" />
        </div>
      </div>
    </div>
  );
}
