import React from 'react';
import '../../../global/timelineTab/TimelineTab.scss';
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
import ButtonBox from "../../../global/ButtonBox";
// import { Link } from 'react-router-dom';
import PaypalPersonalAccountForm from './PaypalPersonalAccountForm';
import PaypalBusinessAccountForm from './PaypalBusinessAccountForm';
import './PaypalPersonalBusinessAccount.scss'
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';
// import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
export default function IntegrationConnectionForm() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });

    let {isVideoVisible} = useSelector(state => state.videoReducer);
    const dispatch = useDispatch();
    const handleClick6 = () => {
      dispatch(toggleVideo());
    };
    return (
        <div>
            <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade active show" id="factor6" role="tabpanel" aria-labelledby="factor6">
                    <div className='h3-border1 d-flex justify-content-between align-items-center w-75'>
                        <h3 className='mb-0 text-body border-0 text-left bg'>
                            {t('timeline_form_paypal_account_component.personal_paypal_account')}
                        </h3>
                        <div className="">
                            <div className="toggle_btn_wrapper bg h-50s">
                                <div className="form-check form-switch px-2 h-100">
                                    <label className="form-check-label me-2 w-75 text-uppercase" htmlFor="">Test Mode</label>
                                    <input className="form-check-input d-block float-end" type="checkbox" role="switch" id="" />
                                    <label className="form-check-label ms-2 w-75 text-right text-uppercase shadow-none bg-transparent" htmlFor="">Live  Mode</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PaypalPersonalAccountForm />
                </div>
            </div>
            {/* </div> */}
            <div className="factorTab1 w-100 text-start d-block br-20 position-relative mx-auto ">
            <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>

                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade  active show" id="factor6" role="tabpanel" aria-labelledby="factor6">
                        <div className='h3-border1 d-flex justify-content-between align-items-center w-75'>
                            <h3 className='mb-0 text-body border-0 text-left bg'>
                                {t('timeline_form_paypal_account_component.business_paypal_account')}
                            </h3>
                            <div className="">
                                <div className="toggle_btn_wrapper bg h-50s">
                                    <div className="form-check form-switch px-2 h-100">
                                        <label className="form-check-label me-2 w-75 text-uppercase" htmlFor="">Test Mode</label>
                                        <input className="form-check-input d-block float-end" type="checkbox" role="switch" id="" />
                                        <label className="form-check-label ms-2 w-75 text-right text-uppercase shadow-none bg-transparent" htmlFor="">Live  Mode</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PaypalBusinessAccountForm />
                    </div>
                </div>
            </div>
            <div className=" text-center time-btn rotate_btn">

                <ButtonBox
                    boxwidth="w-320 mt-0 mb-50 mx-auto"
                    clicked="Save | PayPal Accounts Confirmed"
                    name="Save | PayPal Accounts Confirmed"
                />
            </div>
        </div>
    )
}