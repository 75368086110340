import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight} from '@fortawesome/free-solid-svg-icons';
import GeneralOne from "./GeneralMulti/GeneralOne";
import GeneralTwo from "./GeneralMulti/GeneralTwo";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add( faCaretLeft, faCaretRight );

export default function GeneralTab() {
  return (
    <div>
    <div className="container px-5 mb-4 align-items-center mx-auto d-flex flex-column-reverse justify-content-between">     
      <div className="w-100 text-start d-block br-20 position-relative mx-auto mb-5">
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="GeneralOne" role="tabpanel" aria-labelledby="GeneralOne">
              <GeneralOne />
            </div>
            <div className="tab-pane fade" id="GeneralTwo" role="tabpanel" aria-labelledby="GeneralTwo">
              <GeneralTwo />
            </div>
        </div>
      </div>
      <span className="d-flex align-items-center justify-content-between w-100 mb-4 mt-3">
        <div id="myTab1" className='' role="tablist">
          <button className="buttonArrow me-3 text-body border-0 bg br-10 active" id="GeneralOne" data-bs-toggle="tab" data-bs-target="#GeneralOne" type="button" role="tab" aria-controls="GeneralOne" aria-selected="true">
            <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
          </button>
          <button className="buttonArrow me-3 text-body border-0 bg br-10" id="GeneralTwo" data-bs-toggle="tab" data-bs-target="#GeneralTwo" type="button" role="tab" aria-controls="GeneralTwo" aria-selected="false">
            <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
          </button>
        </div>

      </span>
    </div>
  </div>
  );
}
