import React, { useState } from 'react';
import LeftSideBar from "../global/LeftSideBar";
import RightSideBar from "../global/RightSideBar";
import Pages from "../../routes/Pages";
import arrow from '../../images/arrow2.png';
import arrow1 from '../../images/arrow3.png'
// const Pages = lazy(()=> import('../../routes/Pages'));
import { useDispatch, useSelector } from 'react-redux';
import { leftBarClose,rightBarClose } from '../../redux/actions/videoAction';
export default function MainContainer() {
  let {isLeftSidebarVisible,isRightSidebarVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
 const leftBarToggle = () =>{
  dispatch(leftBarClose());
 }
 const rightBarToggle = () =>{
  dispatch(rightBarClose());
 }

  return (
    <div>
      <div className="container-xl fixed-large">
        <div className="row">
          <div className="col-lg-1 px-0 position-fixed start-0 d-block drop-sec" id="leftArea">
          <button onClick={leftBarToggle}
            className="togglebtn text-primary p-0 border-0 bg z-index top-0 start-0">
              <img src={arrow} alt='' />
          </button>
            {isLeftSidebarVisible ?
              <LeftSideBar />
              :
              <></>
            }
          </div>
          <div className={isLeftSidebarVisible ? "col-lg-10 col-md-12 pt-5 px-4 mx-auto width-px" : "col-lg-12 col-md-12 pt-5 px-4 mx-auto width-px"} >
            <Pages />
          </div>
          <div className="col-lg-1 px-0 position-fixed end-0 d-block drop-sec" id="rightArea">
          <button  onClick={rightBarToggle}
            className="togglebtn text-primary p-0 border-0 bg z-index top-0 end-0">
              <img src={arrow1} alt='' />
          </button>
            {isRightSidebarVisible ?
            <RightSideBar />
            :
              <></>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
