import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slide0 from '../../images/slider1/img1.png';
// import Slide1 from '../../images/slider/01.png';
// import Slide2 from '../../images/slider/02.png';
// import Slide3 from '../../images/slider/03.png';
// import Slide4 from '../../images/slider/04.png';
// import Slide5 from '../../images/slider/05.png';
// import Slide6 from '../../images/slider/06.png';
// import Slide7 from '../../images/slider/07.png';
// import Slide8 from '../../images/slider/08.png';
// import Slide9 from '../../images/slider/09.png';
// import Slide10 from '../../images/slider/10.png';
// import Slide11 from '../../images/slider/11.png';
// import Slide12 from '../../images/slider/12.png';
// import Slide13 from '../../images/slider/13.png';
// import Slide14 from '../../images/slider/14.png';
// import Slide15 from '../../images/slider/15.png';

import Slide0 from '../../images/Banner-main/Style-1.png';
import Slide1 from '../../images/Banner-main/Style-2.png';
import Slide2 from '../../images/Banner-main/Style-3.png';
import Slide3 from '../../images/Banner-main/Style-4.png';
import Slide4 from '../../images/Banner-main/Style-5.png';
import Slide5 from '../../images/Banner-main/Style-6.png';
import Slide6 from '../../images/Banner-main/Style-7.png';
import Slide7 from '../../images/Banner-main/Style-8.png';
import Slide8 from '../../images/Banner-main/Style-9.png';
import Slide9 from '../../images/Banner-main/Style-10.png';
import Slide10 from '../../images/Banner-main/Style-11.png';
import Slide11 from '../../images/Banner-main/Style-12.png';
import Slide12 from '../../images/Banner-main/Style-13.png';
import Slide13 from '../../images/Banner-main/Style-14.png';
import Slide14 from '../../images/Banner-main/Style-15.png';



export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
      <div>
        <Slider className="ProfileSlide"
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
        >
          <div>
          <img src={Slide0} alt="" />
          </div>
          <div>
          <img src={Slide1} alt="" />
          </div>
          <div>
          <img src={Slide2} alt="" />
          </div>
          <div>
          <img src={Slide3} alt="" />
          </div>
          <div>
          <img src={Slide4} alt="" />
          </div>
          <div>
          <img src={Slide5} alt="" />
          </div>
          <div>
          <img src={Slide6} alt="" />
          </div>
          <div>
          <img src={Slide7} alt="" />
          </div>
          <div>
          <img src={Slide8} alt="" />
          </div>
          <div>
          <img src={Slide9} alt="" />
          </div>
          <div>
          <img src={Slide10} alt="" />
          </div>
          <div>
          <img src={Slide11} alt="" />
          </div>
          <div>
          <img src={Slide12} alt="" />
          </div>
          <div>
          <img src={Slide13} alt="" />
          </div>
          <div>
          <img src={Slide14} alt="" />
          </div>
          {/* <div>
          <img src={Slide15} alt="" />
          </div> */}
          
        </Slider>
        <div className="px-4">
          <div className="linebr mt-5"></div>
          <h4 className="my-40">Choose a banner Style</h4>
          <Slider className="ProfileTrack"
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={5}
            swipeToSlide={true}
            focusOnSelect={true}
            arrows={false}
            variableWidth={true}
          >
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide0} alt="" className="" />
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide1} alt="" className="" />
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide2} alt="" className="" />              
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide3} alt="" className="" />
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide4} alt="" className="" />
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide5} alt="" className="" />
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide6} alt="" className="" />
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide7} alt="" className="" />
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide8} alt="" className="" />
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide9} alt="" className="" />
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide10} alt="" className="" />
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide11} alt="" className="" />
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide12} alt="" className="" />
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide13} alt="" className="" />
              </div>
            </div>
            <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide14} alt="" className="" />
              </div>
            </div>
            {/* <div>
              <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                <img src={Slide15} alt="" className="" />
              </div>
            </div> */}
          </Slider>
          <div className="linebr mt-5"></div>
        </div>
      </div>
    );
  }
}