import React from 'react'
import TrackingComponentSec from './TrackingComponentSec'
import IntegAddTrackFrom from '../IntegAddTrackFrom';
import icon from "../../../../../../images/formicon/payment/icon.png";
import icon1 from "../../../../../../images/formicon/payment/icon1.png";
import icon2 from "../../../../../../images/formicon/payment/icon2.png";
import icon3 from "../../../../../../images/formicon/payment/icon3.png";
import icon4 from "../../../../../../images/formicon/payment/icon4.png";
import icon5 from "../../../../../../images/formicon/payment/icon5.png";
import icon6 from "../../../../../../images/formicon/payment/icon6.png";
import icon7 from "../../../../../../images/formicon/payment/icon7.png";
import icon8 from "../../../../../../images/formicon/payment/icon8.png";
import icon9 from "../../../../../../images/formicon/payment/icon9.png";
import icon10 from "../../../../../../images/formicon/payment/icon10.png";
import icon11 from "../../../../../../images/formicon/icondot.png";
import TrackingComponent from './TrackingComponent';
export default function TrackingPayment() {


  return (
    <div className='px-4'>
      <ul className="customDrop6 customDrop1s max-h-auto customDrop2s w-100 shadow-none position-relative">
            <div className="dropheight h-auto">
              <TrackingComponentSec Name="PayPal" icon={icon} Dropname="PayPal"/>
              <TrackingComponentSec Name="Stripe" icon={icon1} Dropname="Stripe" />
              <TrackingComponentSec Name="Apple pay"  Dropname="Apple pay" />
              <TrackingComponentSec Name="g-pay"  Dropname="g-pay" />
              <TrackingComponentSec Name="pay with amazon" Dropname="ay with amazon" />
              <TrackingComponentSec Name="Paybright" icon={icon2} Dropname="Paybright" />
              <TrackingComponentSec Name="Klarna" icon={icon3} Dropname="Klarna" />
              <TrackingComponentSec Name="Bank Check |  WIRE-transfer | E-Transfer" icon={icon4} Dropname="Bank Check |  WIRE-transfer | E-Transfer" />
              <TrackingComponentSec Name="Payoneer" icon={icon5} Dropname="Payoneer" />
              <TrackingComponentSec Name="Wise" icon={icon6} Dropname="Wise" />
              <TrackingComponentSec Name="Crypto: Bitcoin | ethereum | etc" Dropname="Crypto: Bitcoin | ethereum | etc" />
              <TrackingComponentSec Name="Payza" icon={icon7} Dropname="Payza" />
              <TrackingComponentSec Name="Authorize.net" icon={icon8} Dropname="Authorize.net" />
              <TrackingComponentSec Name="2Checkout" icon={icon9} Dropname="2Checkout" />
              <TrackingComponentSec Name="Braintree" icon={icon10} Dropname="Braintree" />
              <TrackingComponent DropName="Other ?  Type here...." icon={icon11} />
              <IntegAddTrackFrom />
            </div>
          </ul>
    </div>
  )
}
