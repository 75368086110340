import React from "react";
import '../pages/threeHoursBusinessLaunch/emailSignature/EmailSignatureStep.scss';
import brcode from '../../images/img30.png';
import Select from 'react-select';


export default function EmailTemp() {
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });

  const options = [
    { value: 'wall-of-fame', label: 'Wall of Fame' },
    { value: 'podcast', label: 'Podcast' },
    { value: 'video', label: 'Video' },
    { value: 'blog', label: 'Blog' },
    { value: 'event', label: 'Event' },
    { value: 'contest', label: 'Contest' },
    { value: 'product', label: 'Product' },
    { value: 'webinar', label: 'Webinar' },
    { value: 'course', label: 'Course' },
    { value: 'survey', label: 'Survey' },
    { value: 'polls', label: 'Polls' }
  ]

  return (
    <div>
      <div className="w-100">
        <div className="text-center my-4 d-inline-block w-100">
          <button className="h6 fw-bold btn br-primary border-0 py-2 px-4 shadow-3 br-10">Check Out My Most Recent:</button>
        </div>
        <div className="d-flex justify-content-between px-3 pb-3">
          <div className="w-100x">
              <Select isSearchable={false} options={options} className="countryList3 w-100 customWidth1 h8 mb-4" placeholder="Contest" theme={theme}
                styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width: '88%',
                    height: 'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color: '#000000',
                    boxShadow: '2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',

                  }),
                  }} />
            <img src={brcode} alt="" className="shadow-3" />
          </div>
          <div className="w-100x">
            <Select isSearchable={false} options={options} className="countryList3 w-100 customWidth1 h8 mb-4" placeholder="Blog" theme={theme}
                styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width: '88%',
                    height: 'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color: '#000000',
                    boxShadow: '2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',

                  }),
                  }} />
            <img src={brcode} alt="" className="shadow-3" />
          </div>
          <div className="w-100x">
            <Select isSearchable={false} options={options} className="countryList3 w-100 customWidth1 h8 mb-4" placeholder="Podcast" theme={theme}
                styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width: '88%',
                    height: 'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color: '#000000',
                    boxShadow: '2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',

                  }),
                  }} />
            <img src={brcode} alt="" className="shadow-3" />
          </div>
          <div className="w-100x">
            <Select isSearchable={false} options={options} className="countryList3 w-100 customWidth1 h8 mb-4" placeholder="Video" theme={theme}
                styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width: '88%',
                    height: 'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color: '#000000',
                    boxShadow: '2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',

                  }),
                  }} />
            <img src={brcode} alt="" className="shadow-3" />
          </div>
          <div className="w-100x">
            <Select isSearchable={false} options={options} className="countryList3 w-100 customWidth1 h8 mb-4" placeholder="Event" theme={theme}
                styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width: '88%',
                    height: 'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color: '#000000',
                    boxShadow: '2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',

                  }),
                  }} />
            <img src={brcode} alt="" className="shadow-3" />
          </div>
          <div className="w-100x">
            <Select isSearchable={false} options={options} className="countryList3 w-100 customWidth1 h8 mb-4" placeholder="Product" theme={theme}
                styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width: '88%',
                    height: 'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color: '#000000',
                    boxShadow: '2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',

                  }),
                  }} />
            <img src={brcode} alt="" className="shadow-3" />
          </div>
        </div>
      </div>
    </div>
  );
} 
