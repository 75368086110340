import * as React from 'react';
import chat from '../../../images/coolicon.png';
import smilye from '../../../images/coolicon1.png';
import user from '../../../images/img1.png';
import letter from '../../../images/img3.png';
import stamp from '../../../images/img6.png';
import label from '../../../images/img5.png';
import smile from '../../../images/emo2.png';
import think from '../../../images/emo1.png';

import { useState } from 'react';
import {useDispatch} from "react-redux";
import { toast } from 'react-toastify';
import { ContactActions } from '../../../redux/actions/contactActions';
import ButtonBox from '../../global/ButtonBox';
import SocialBox from '../../global/SocialBox';
import ImageBox from '../../global/ImageBox';
import TabSlider from '../../global/tabSlider';
import { NavItem } from 'react-bootstrap';


export default function Contact() {

    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [message, setMessage] = useState('');
    const [close, setClose] = useState(false);

    const handleSubmit = async (e) =>{
        e.preventDefault();
       
         if (!name){
            toast('Name is required');
          }
         else if (!email){
            toast('Email is required');
          }

          else if (!contact){
            toast('Contact is required');
          }

          else if (!message){
            toast('Message is required');
          }
          else{
            try {
                 let data = {
                        name:name,
                     email:email,
                     contact:contact,
                     message:message,
                 }
                 dispatch(ContactActions(data, setName ,setEmail, setContact,setMessage));
         setClose(true);
               } catch (error) {
                 console.log("err", error);
               }
             }
          }

    return (
        <div className="w-100 d-inline-block">
            <div className="container">
                <h3 className="mt-lg-4 text-center pb-40 btn-active">CONTACT CENTRE</h3>
                <div className="row">
                    <div className="col-lg-6 col-12 text-center pe-lg-5 mb-5 mb-lg-0">
                        <div className="shadow-4 br-40 w-100 p-40 mt-100">
                            <ImageBox boxwidth="w-180" src={user} />
                            <div className="text-sec bg mt-n9 br-20 ">
                                <h4>Hi, I am DYNA</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                                <div className="social-sec mt-4 d-flex justify-content-between">
                                    <div className="col-lg-6 px-4">
                                        <div className="shadow-4 br-10 d-flex justify-content-center align-items-center mx-auto mb-10 w-34">
                                            <img src={chat} alt=""/>
                                        </div>
                                        <small>libero velitinterdum aliquet odio mattis</small>
                                    </div>
                                    <div className="col-lg-6 px-4">
                                        <div className="shadow-4 br-10 d-flex justify-content-center align-items-center mx-auto mb-10 w-34">
                                            <img src={smilye} alt=""/>
                                        </div>
                                        <small>libero veli tinterdum aliquet odio mattis</small>
                                    </div>
                                </div>
                                <SocialBox/>
                            </div>
                        </div>
                    </div>
                 <div className= {!close ? "col-lg-6 col-12 position-relative ps-lg-5 change-class" : "col-lg-6 col-12 position-relative ps-lg-5 change-class close"  }>
                        <div className="shadow-4 p-30 bg br-20 top-sec position-absolute">
                            <h4 className='submit flex-column'>
                                <img src={think} alt="emo" width="45" height="45"/>
                                Are you thinking what I'm thinking?</h4>
                            <h4 className='submited flex-column'>
                                <img src={smile} alt="emo" width="45" height="45"/>
                            Securely Delivered to the Travel Niche</h4>
                        </div>
                        <div className="contact-area pt-2">
                            <div className="form-sec bg shadow-4 br-40 p-40 h-100 ms-6">
                               <form onSubmit={handleSubmit}>
                                    <ul>
                                        <NavItem as="li">
                                            <input placeholder="Name" className="shadow-5in br-10" type="text" value={name} onChange={(e)=> {setName(e.target.value);}} />
                                        </NavItem>
                                        <NavItem as="li">
                                            <input placeholder="Email" className="shadow-5in br-10" type="email" value={email} onChange={(e)=> {setEmail(e.target.value);}}  />
                                        </NavItem>
                                        <NavItem as="li">
                                            <input placeholder="Contact" className="shadow-5in br-10" type="text" value={contact} onChange={(e)=> {setContact(e.target.value);}}  />
                                        </NavItem>
                                        <NavItem as="li">
                                            <textarea placeholder="What are you thinking?" className="shadow-5in br-10" value={message} onChange={(e)=> {setMessage(e.target.value);}}></textarea>
                                        </NavItem>
                                        <NavItem as="li">
                                            <ButtonBox  boxwidth="w-110" close={close}  clicked="Submit" name="Submited" />
                                        </NavItem>
                                    </ul>
                                    </form>
                            </div>
                            <div className="anvalup position-absolute bottom-0 w-500 shadow-4 br-20">
                                <div className=' position-relative z-index-1'>
                                    <img src={letter} alt="" className="w-100"/>
                                </div>
                                <img src={label} alt="" className="label-img label-1 z-index-1"/>
                                <img src={stamp} alt="" className="label-img label-2 z-index-1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade" id="tab1" role="tabpanel" aria-labelledby="tab1"></div>
                <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2"></div>
                <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3"></div>
                <div className="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4"></div>
                <div className="tab-pane fade show active" id="tab5" role="tabpanel" aria-labelledby="tab5">
                </div>
                <div className="tab-pane fade" id="tab6" role="tabpanel" aria-labelledby="tab6"></div>
                <div className="tab-pane fade" id="tab7" role="tabpanel" aria-labelledby="tab7"></div>
                <div className="tab-pane fade" id="tab8" role="tabpanel" aria-labelledby="tab8"></div>
                <div className="tab-pane fade" id="tab9" role="tabpanel" aria-labelledby="tab9"></div>
            </div>
            <div className='container d-none d-lg-block'>
                <div className='scroll-bar w-100 shadow-4in br-20 my-30'></div>
            </div>
            <div className='container tab-button mb-4 pb-3 mt-5 mt-lg-0'>
                <div id="myTab" className='slidetab' role="tablist">
                    <TabSlider />
                </div>
            </div>
        </div>
    );
  }