import React from "react";
import { useTranslation } from "react-i18next";
import Paypal from '../../../../../images/paypal.png';
export default function IntegrationForm() {
    const { t, i18n } = useTranslation();
    return (
        <div className="pb-0 pt-2">
            <div className='row mx-0 mb-28'>
                <div className="col-md-4 px-0 d-flex">
                    <span className="inputspan shadow-4 align-items-center justify-content-center w-100">
                        <img src={Paypal} alt="" width="35" className="me-3"/>
                        Registration Link
                    </span>
                </div>
                <div className="col-lg-8 d-flex nav-item6">
                    <label>{t('timeline_form_paypal_account_component.paypal_account_user_name')}</label>
                    <div className="w-72 z1">
                        <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder={t('timeline_form_paypal_account_component.paypal_account_user_name_placeholder')} />
                        </span>
                    </div>
                </div>
            </div>
            <div className="row mx-0 mb-28">
                <div className="col-md-6 d-flex nav-item2">
                    <label className="mb-1">{t('timeline_form_paypal_account_component.personal_paypal_email')}</label>
                    <div className="w-custom z1">
                        <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder={t('timeline_form_paypal_account_component.personal_paypal_email_placeholder')}  />
                        </span>
                    </div>
                </div>
                <div className="col-md-6 d-flex nav-item2">
                    <label className="mb-1">{t('timeline_form_paypal_account_component.personal_paypal_page_url')}</label>
                    <div className="w-custom z1">
                        <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder={t('timeline_form_paypal_account_component.personal_paypal_page_url_placeholder')}   />
                        </span>
                    </div>
                </div>
            </div>
            <div className="row mx-0 mb-28">
                <div className="col-md-6 d-flex nav-item2">
                    <label className="mb-1">{t('timeline_form_paypal_account_component.paypal_client_id')}</label>
                    <div className="w-custom z1">
                        <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder={t('timeline_form_paypal_account_component.paypal_client_id_placeholder')}  />
                        </span>
                    </div>
                </div>
                <div className="col-md-6 d-flex nav-item2">
                    <label className="mb-1">{t('timeline_form_paypal_account_component.paypal_secret_code')}</label>
                    <div className="w-custom z1">
                        <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder={t('timeline_form_paypal_account_component.paypal_secret_code_placeholder')}   />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
