import React from 'react'
import TrackingComponentSec from './TrackingComponentSec'
import IntegAddTrackFrom from '../IntegAddTrackFrom';
import iconBluew1 from "../../../../../../images/formicon/icon18.png";
import iconBluew2 from "../../../../../../images/formicon/icon19.png";
import iconBluew3 from "../../../../../../images/formicon/icon20.png";
import TrackingComponent from './TrackingComponent';
export default function TrackingGoogle() {


  return (
    <div className='px-4'>
      <ul className="customDrop6 customDrop1s max-h-auto customDrop2s w-100 shadow-none position-relative">
            <div className="dropheight h-auto">
              <TrackingComponentSec Name="Google Analytics" icon={iconBluew1} Dropname="Google Analytics"/>
              <TrackingComponentSec Name="Google Tag Manager" icon={iconBluew2} Dropname="Google Analytics"/>
              <TrackingComponentSec Name="Google Adwords" icon={iconBluew3} Dropname="Google Analytics"/>
              <TrackingComponent Dropname="Other ?  Type here...." />
              <IntegAddTrackFrom />
            </div>
          </ul>
    </div>
  )
}
