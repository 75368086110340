import React from "react";
import { useState } from "react";
import "../regularTheme1Desktop/RegularTheme1Desktop.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import timeline from "../../../../../../images/how-it-work/timeline.png"
import timeline_2 from "../../../../../../images/how-it-work/timeline-2.png"
import timeline_3 from "../../../../../../images/how-it-work/timeline-3.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import  "./components/howItWorkTabCards/HowItWorkCards.scss"
import "./components/readyToStartTabWork/ReadyToStartWork.scss"
import "./components/stepsTabTimeline/StepsTimeline.scss"

import HowItWorkCards from "./components/howItWorkTabCards/HowItWorkCards";
import ReadyToStartWork from "./components/readyToStartTabWork/ReadyToStartWork";
import StepsTimeline from "./components/stepsTabTimeline/StepsTimeline";
export default function RegularTheme1Tab() {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [Competency1, setCompetency1] = useState(10);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };
  return (
    <div>
      <div className="">
        <div>
          <div className="main-content ">
            <div>
              <div className="tab-help-section tablet-regular-help-section text-center">
                <div className="top-desc">
                  <h2>I can help you with</h2>
                  <p>Most frequent questions & answers</p>
                </div>
                <HowItWorkCards/>
                <div className="timeline-section-content">
                  <ReadyToStartWork/>
                  <StepsTimeline/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}