import React, { useState } from "react";

import desktop_1 from "../../../../images/blog-posts/Desktop1.png"
import tab_1 from "../../../../images/blog-posts/Tab1.png"
import mobile_1 from "../../../../images/blog-posts/Mobile1.png"
import desktop_2 from "../../../../images/blog-posts/Desktop2.png"
import tab_2 from "../../../../images/blog-posts/Tab2.png"
import mobile_2 from "../../../../images/blog-posts/Mobile2.png"
import desktop_3 from "../../../../images/blog-posts/Desktop3.png"
import tab_3 from "../../../../images/blog-posts/Tab3.png"
import mobile_3 from "../../../../images/blog-posts/Mobile3.png"
import desktop_4 from "../../../../images/blog-posts/Desktop4.png"
import tab_4 from "../../../../images/blog-posts/Tab4.png"
import mobile_4 from "../../../../images/blog-posts/Mobile4.png"
import desktop_5 from "../../../../images/blog-posts/Desktop5.png"
import tab_5 from "../../../../images/blog-posts/Tab5.png"
import mobile_5 from "../../../../images/blog-posts/Mobile5.png"
import desktop_6 from "../../../../images/blog-posts/Desktop6.png"
import tab_6 from "../../../../images/blog-posts/Tab6.png"
import mobile_6 from "../../../../images/blog-posts/Mobile6.png"
import desktop_7 from "../../../../images/blog-posts/Desktop7.png"
import tab_7 from "../../../../images/blog-posts/Tab7.png"
import mobile_7 from "../../../../images/blog-posts/Mobile7.png"
import desktop_8 from "../../../../images/blog-posts/Desktop8.png"
import tab_8 from "../../../../images/blog-posts/Tab8.png"
import mobile_8 from "../../../../images/blog-posts/Mobile8.png"
import BlogPostSectionRegularThemeDesktopView1 from './BlogPostSectionRegularThemeDesktopView1'
import BlogPostSectionRegularThemeDesktopView2 from './BlogPostSectionRegularThemeDesktopView2'
import BlogPostSectionRegularThemeDesktopView3 from './BlogPostSectionRegularThemeDesktopView3'
// import BlogPostSectionRegularThemeTabView1 from './BlogPostSectionRegularThemeTabView1'
// import BlogPostSectionRegularThemeMobileView1 from './BlogPostSectionRegularThemeMobileView1'
// import BlogPostSectionRegularThemeTabView2 from './BlogPostSectionRegularThemeTabView2'
// import BlogPostSectionRegularThemeMobileView2 from './BlogPostSectionRegularThemeMobileView2'

import Modal from 'react-bootstrap/Modal';

import { useTranslation } from "react-i18next";
import './BlogPostSectionThemes.scss';
import ButtonBoxNew from "../../../global/ButtonBoxNew";
export default function BlogPostSectionRegularTheme() {

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [TabTwoShow, setTabTwoShow] = useState(false);
  const [MobileTwoShow, setMobileTwoShow] = useState(false);
  const [deskShow, setDeskShow] = useState(false);
  const [desktwoShow, setDesktwoShow] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);
  const [TabFourShow, setTabFourShow] = useState(false);
  const [MobileFourShow, setMobileFourShow] = useState(false);
  const [deskFourShow, setdeskFourShow] = useState(false);
  const [TabFiveShow, setTabFiveShow] = useState(false);
  const [MobileFiveShow, setMobileFiveShow] = useState(false);
  const [deskFiveShow, setdeskFiveShow] = useState(false);
  const [TabSixShow, setTabSixShow] = useState(false);
  const [MobileSixShow, setMobileSixShow] = useState(false);
  const [deskSixShow, setdeskSixShow] = useState(false);
  const [TabSevenShow, setTabSevenShow] = useState(false);
  const [MobileSevenShow, setMobileSevenShow] = useState(false);
  const [deskSevenShow, setdeskSevenShow] = useState(false);
  const [TabEightShow, setTabEightShow] = useState(false);
  const [MobileEightShow, setMobileEightShow] = useState(false);
  const [deskEightShow, setdeskEightShow] = useState(false);
  const { t, i18n } = useTranslation();
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });




  return (
    <div className="">

      <h4 className="text-center">
      Regular  Layout  Version 1
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_1} alt="deshtop-1"  >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
         <ButtonBoxNew  name=" See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setDeskShow(true)}/>
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <BlogPostSectionRegularThemeDesktopView1 />

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_1} alt="tab-1"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setSmShow(true)}/>
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <BlogPostSectionRegularThemeTabView1 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_1} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView1 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
      Regular  Layout  Version 2
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2} alt="desktop-2" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDesktwoShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setDesktwoShow(true)}/>

          
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktwoShow}
            onHide={() => setDesktwoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <BlogPostSectionRegularThemeDesktopView2 />

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2} alt="tab-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabTwoShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setTabTwoShow(true)}/>

          <Modal
            show={TabTwoShow}
            onHide={() => setTabTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-tit"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeTabView2 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2} alt="mobile-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileTwoShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setMobileTwoShow(true)}/>

          <Modal
            show={MobileTwoShow}
            onHide={() => setMobileTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-ti"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView2 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
      Regular  Layout  Version 3
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3} alt="desktop-3" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskthreeShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setDeskthreeShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskthreeShow}
            onHide={() => setDeskthreeShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <BlogPostSectionRegularThemeDesktopView3 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3} alt="tab-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabThreeShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setTabThreeShow(true)}/>

          <Modal
            show={TabThreeShow}
            onHide={() => setTabThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3} alt="mobile-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileThreeShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setMobileThreeShow(true)}/>

          <Modal
            show={MobileThreeShow}
            onHide={() => setMobileThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
      Regular  Blog Post Version 1
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_4} alt="desktop-4" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setdeskFourShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setdeskFourShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFourShow}
            onHide={() => setdeskFourShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_4} alt="tab-4" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFourShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setTabFourShow(true)}/>

          <Modal
            show={TabFourShow}
            onHide={() => setTabFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_4} alt="mobile-4" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFourShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setMobileFourShow(true)}/>

          <Modal
            show={MobileFourShow}
            onHide={() => setMobileFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
      Regular  Blog Post Version 2
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_5} alt="desktop-5" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setdeskFiveShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setdeskFiveShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFiveShow}
            onHide={() => setdeskFiveShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_5} alt="tab-5" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFiveShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setTabFiveShow(true)}/>

          <Modal
            show={TabFiveShow}
            onHide={() => setTabFiveShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_5} alt="mobile-5" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFiveShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setMobileFiveShow(true)}/>

          <Modal
            show={MobileFiveShow}
            onHide={() => setMobileFiveShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
      Regular  Blog Post Version 3
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_6} alt="desktop-6" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setdeskSixShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setdeskSixShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskSixShow}
            onHide={() => setdeskSixShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_6} alt="tab-6" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabSixShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setTabSixShow(true)}/>

          <Modal
            show={TabSixShow}
            onHide={() => setTabSixShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_6} alt="mobile-6" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileSixShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setMobileSixShow(true)}/>

          <Modal
            show={MobileSixShow}
            onHide={() => setMobileSixShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
      Regular  Blog Post Version 4
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_7} alt="desktop-7" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setdeskSevenShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setdeskSevenShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskSevenShow}
            onHide={() => setdeskSevenShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_7} alt="tab-7" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabSevenShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setTabSevenShow(true)}/>

          <Modal
            show={TabSevenShow}
            onHide={() => setTabSevenShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_7} alt="mobile-7" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileSevenShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileSevenShow(true)}/>

          <Modal
            show={MobileSevenShow}
            onHide={() => setMobileSevenShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
      Regular  Blog Post Version 5
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_8} alt="desktop-8" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setdeskEightShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setdeskEightShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskEightShow}
            onHide={() => setdeskEightShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_8} alt="tab-8" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabEightShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabEightShow(true)}/>

          <Modal
            show={TabEightShow}
            onHide={() => setTabEightShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_8} alt="mobile-8" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileEightShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileEightShow(true)}/>

          <Modal
            show={MobileEightShow}
            onHide={() => setMobileEightShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
