import React from "react";
import ButtonBox from "../../../../global/ButtonBox";
import { Link } from 'react-router-dom';
import { ReactComponent as IconBlue15 } from "../../../../../images/listicon/icon15.svg";
import { ReactComponent as IconBlue16 } from "../../../../../images/listicon/icon17.svg";
import { ReactComponent as IconBlue17 } from "../../../../../images/listicon/icon16.svg";
import { ReactComponent as IconBlue2 } from "../../../../../images/icons/icon14.svg";
import { ReactComponent as IconBlue3 } from "../../../../../images/icons/icon15.svg";
import { ReactComponent as IconBlue4 } from "../../../../../images/icons/icon16.svg";

import Select from 'react-select';
import EditorDrop from "../../../../global/EditorDrop";
import PasswordTwo from "../PasswordSingle/PasswordTwo";
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import ButtonBoxNew from "../../../../global/ButtonBoxNew";
import DropdownBox from "../../../../global/DropdownBox";

export default function BasicInfoTab() {

  const { t, i18n } = useTranslation();
  const theme = theme => ({

    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    }
  });

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]
  // const options2 = [
  //   { value: '-None-', label: <span className=""> -None-</span> },
  //   { value: 'She/Her', label: <span className=""><IconBlue15 className="text-blue" /> She/Her</span> },
  //   { value: 'He/Him', label: <span className=""><IconBlue17 className="text-blue" /> He/Him</span> },
  //   { value: 'They/Them', label: <span className=""><IconBlue16 className="text-blue" /> They/Them</span> },
  // ]

  const options2 = [
    {
      icon: <span className=""></span>,
      name: "None"
    },
    {
      icon: <span className=""><IconBlue15 className="text-blue" /> </span>,
      name: "She/Her"
    },
    {
      icon: <span className=""><IconBlue17 className="text-blue" /></span>,
      name: " He/Him"
    },
    {
      icon: <span className=""><IconBlue16 className="text-blue" /> </span>,
      name: "They/Them"
    },
  ]

  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className="ProgressBar">
        <ul>
          <NavItem as="li" className="active" id="profile"></NavItem>
          <NavItem as="li" className="active" id="chat"></NavItem>
          <NavItem as="li" id="location"></NavItem>
          <NavItem as="li" id="user"></NavItem>
          <NavItem as="li" id="briefcase"></NavItem>
          <NavItem as="li" id="graduation-cap"></NavItem>
          <NavItem as="li" id="favorite"></NavItem>
          <NavItem as="li" id="award"></NavItem>
          <NavItem as="li" id="connectivity"></NavItem>
          <NavItem as="li" id="lock"></NavItem>
          <NavItem as="li" id="eye"></NavItem>
        </ul>
      </div>
      <form action="" method="">
        <div className="text-center mb-5">
          <h5>{t('profile_section.your_basic_business_info')}</h5>
        </div>
        <div className="row customCol">
          <div className="col-md-5">
            <label className="mb-2">{t('profile_section.first_name')}</label>
            <input
              placeholder={t('profile_section.your_tagline_here')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
          <div className="col-md-5">
            <label className="mb-2">{t('profile_section.last_name')}</label>
            <input
              placeholder={t('profile_section.your_tagline_here')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
          <div className="col-md-3">
            <label className="mb-2"></label>
            {/* <Select isSearchable={false} options={options2} className="countryList br-30 w-100 customWidth1 mb-4" placeholder="" theme={theme}
              styles={{
                option: (base) => ({
                  ...base,
                  borderRadius: '30px',
                  width: '88%',
                  height: 'auto',
                  lineHeight: '24px',
                  margin: '10px',
                  cursor: 'pointer',
                  color: '#000000',
                  boxShadow: '2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',

                }),
              }}
              defaultValue={{
                value: 'She/Her',
                label: <span className=""><IconBlue15 className="text-blue" />{t('profile_section.she_her')}</span>,
              }}
            /> */}
            <DropdownBox name="Sher/Her" boxid="gender" options={options2} boxwidth="w-100 mx-auto" />

          </div>
          <div className="col-md-6">
            <label className="mb-2">{t('profile_section.business_title')}</label>
            <input
              placeholder={t('profile_section.your_business_title')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
          <div className="col-md-6">
            <label className="mb-2">{t('profile_section.preferred_profile')}</label>
            <input
              placeholder={t('profile_section.dyna')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="mb-2">{t('profile_section.business_industry')}</label>
            <input
              placeholder={t('profile_section.fashion')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
          <div className="col-md-6">
            <label className="mb-2">{t('profile_section.business_niche')}</label>
            <input
              placeholder={t('profile_section.fashion')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="mb-2">{t('profile_section.website')}</label>
            <input
              placeholder={t('profile_section.www_website_com')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
          <div className="col-md-6">
            <label className="mb-2">{t('profile_section.contact_whatsApp_number')}</label>
            <input
              placeholder={t('profile_section._554_980_5678')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="mb-2">{t('profile_section.public_email')}</label>
            <input
              placeholder={t('profile_section.dyna_9t05escapee_com')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
          <div className="col-md-6">
            <label className="mb-2">{t('profile_section.business_email')}</label>
            <input
              placeholder={t('profile_section.dyna_9t05escapee_com')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label className="mb-2">{t('profile_section.business_company_name')}</label>
            <input
              placeholder={t('profile_section.business_name_here')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="mb-2">{t('profile_section.business_headquarter_location')}</label>
            <div className="position-relative">
              <input
                placeholder={t('profile_section.Location')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
              <span className="position-absolute top-10 end-15 w-24 shadow-4 rounded-circle d-flex justify-content-center align-items-center">
                <IconBlue3 />
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <label className="mb-2">{t('profile_section.joined_us_since')}</label>
            <div className="position-relative">
              <input
                placeholder={t('profile_section.Location')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
              <span className="position-absolute top-10 end-15 w-24 rounded-circle shadow-4 d-flex justify-content-center align-items-center">
                <IconBlue2 />
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label className="mb-2">{t('profile_section.business_mission_statement')}</label>
            <input
              placeholder={t('profile_section.mission_statement')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label className="mb-2">{t('profile_section.tagline_slogan')}</label>
            <input
              placeholder={t('profile_section.your_tagline_here')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label className="mb-2">{t('profile_section.brand_position_statement')}</label>
            <input
              placeholder={t('profile_section.position_statement')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 bioSec">
            <nav className="d-flex justify-content-between align-items-center mb-3">
              <div className="bioNav" id="nav-tab" role="tablist">
                <button className="br-top-15 px-3 bg h-50 border-0 active" id="nav-who-tab" data-bs-toggle="tab" data-bs-target="#nav-who" type="button" role="tab" aria-controls="nav-who" aria-selected="true">
                  {t('profile_section.who_am_i')}</button>
                <button className="br-top-15 px-3 bg h-50 border-0" id="nav-what-tab" data-bs-toggle="tab" data-bs-target="#nav-what" type="button" role="tab" aria-controls="nav-what" aria-selected="false">{t('profile_section.what_i_do')}</button>
                <button className="br-top-15 px-3 bg h-50 border-0" id="nav-service-tab" data-bs-toggle="tab" data-bs-target="#nav-service" type="button" role="tab" aria-controls="nav-service" aria-selected="false">{t('profile_section.who_is_my_service_for')}</button>
                <button className="br-top-15 px-3 bg h-50 border-0" id="nav-work-tab" data-bs-toggle="tab" data-bs-target="#nav-work" type="button" role="tab" aria-controls="nav-work" aria-selected="false">{t('profile_section.why_work_with_me')}</button>
              </div>
              <div className="dropFormat">
                <div className="d-flex">
                  <div className="dropdown btnArrow">
                    <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-display="static">
                      <Link to="#" id="notab" className="d-flex justify-content-center fs-5 fw-bold ps-3 pe-5 align-items-center w-100x text-underline shadow-4 h-45 h8 br-30">
                        <u>A</u>
                      </Link>
                    </span>
                    <EditorDrop />
                  </div>
                </div>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-who" role="tabpanel" aria-labelledby="nav-who-tab">
                <div className="position-relative">
                  <textarea
                    placeholder={t('profile_section.your_bio_here')} rows="10"
                    className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                    type="text">
                  </textarea>
                  <span className="position-absolute top-20 start-16 ">
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  </span>
                </div>
              </div>
              <div className="tab-pane fade" id="nav-what" role="tabpanel" aria-labelledby="nav-what-tab">
                <div className="position-relative">
                  <textarea
                    placeholder={t('profile_section.your_bio_here')} rows="10"
                    className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                    type="text">
                  </textarea>
                  <span className="position-absolute top-20 start-16 ">
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  </span>
                </div>
              </div>
              <div className="tab-pane fade" id="nav-service" role="tabpanel" aria-labelledby="nav-service-tab">
                <div className="position-relative">
                  <textarea
                    placeholder={t('profile_section.your_bio_here')} rows="10"
                    className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                    type="text">
                  </textarea>
                  <span className="position-absolute top-20 start-16 ">
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  </span>
                </div>
              </div>
              <div className="tab-pane fade" id="nav-work" role="tabpanel" aria-labelledby="nav-work-tab">
                <div className="position-relative">
                  <textarea
                    placeholder={t('profile_section.your_bio_here')} rows="10"
                    className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                    type="text">
                  </textarea>
                  <span className="position-absolute top-20 start-16 ">
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label className="mb-2">{t('profile_section.user_name')}</label>
            <input
              placeholder={t('profile_section.dynaappolon')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
          </div>
          <div className="col-md-12">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <label className="mb-2">{t('profile_section.enter_your_password')}</label>
              <span className="h9 fw-bold">{t('or')}</span>
              {/* <Button onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open} className="h-50s bs-primary br-5 w-200 p-0 m-0 fw-bold h7 text-uppercase text-center border-0 shadow-4 d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#passgenrate">
              {t('profile_section.genertae_a_new_PAssword')}
              </Button> */}
              <ButtonBoxNew onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open} data-bs-toggle="modal" data-bs-target="#passgenrate" boxwidth="w-300 text-uppercase" name="Genrate a new password" />
            </div>
            <input
              placeholder={t('profile_section._123456')}
              className="shadow-5in br-10 w-100 mb-4"
              type="password"
            />
          </div>
          <div className="modalpass pb-4">
            <Collapse in={open}>
              <div id="example-collapse-text">
                <PasswordTwo />
              </div>
            </Collapse>
          </div>
          <div className="col-md-12">
            <h5 className="mb-4 h9">{t('profile_section.your_password_must_meet_these_requirements')}</h5>
            <ul className="row mx-0 text-center">
              <NavItem as="li" className="col-md-2 h8 p-0">
                <span className="d-block h9 fw-bold mb-2">A</span>
                {t('profile_section.one_uppercase_character')}
              </NavItem>
              <NavItem as="li" className="col-md-2 h8 p-0">
                <span className="d-block h9 fw-bold mb-2">a</span>
                {t('profile_section.one_lowercase_character')}
              </NavItem>
              <NavItem as="li" className="col-md-2 h8 p-0">
                <span className="d-block h9 fw-bold mb-2">7</span>
                {t('profile_section.one_number')}

              </NavItem>
              <NavItem as="li" className="col-md-2 h8 p-0">
                <span className="d-block h9 fw-bold mb-2">!</span>
                {t('profile_section.one_special')}
                <br /> {t('profile_section.character')}
              </NavItem>
              <NavItem as="li" className="col-md-2 h8 p-0">
                <span className="d-block h9 fw-bold mb-2">8</span>
                {t('profile_section._8_characters')}<br /> {t('profile_section.minimum')}
              </NavItem>
            </ul>
          </div>
        </div>
        <div className="mt-5 text-center">
          <ButtonBox boxwidth="w-140 mt-0 mb-50 mx-auto" name="Save/Next" className="next action-button" value="Save/Next" clicked="Saved" />
        </div>
      </form>
    </div>
  );
}
