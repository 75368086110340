import React from "react";
import { Link } from "react-router-dom";
import label from "../../images/ant.png";
import Privacy from "../../images/privacy.png";
import Professional from "../../images/professional.png";
import Business from "../../images/business.png";
import Membership from "../../images/membership.png";
import Addon from "../../images/addon.png";
import MGMT from "../../images/mgmt.png";
import BIZ from "../../images/biz.png";
import Incuba from "../../images/incuba.png";
import Lanuch from "../../images/lanuch.png";
import PLAN from "../../images/plan.png";
import Accel from "../../images/accel.png";
import Grow from "../../images/grow.png";
import Headphones from "../../images/headphones.png";
import GENERATE from "../../images/genrate.png";
import Figure from "../../images/figure.png";
import Register from "../../images/register.png";
import Course from "../../images/course.png";
import Tools from "../../images/tools.png";
import Client from "../../images/client.png";
import user from "../../images/img1.png";
import tag from "../../images/tag.png";
import flag from "../../images/flag.png";
import status from "../../images/status.png";
import EmailMarket from "../../images/emailmarket.png";
import SocialMarket from "../../images/socialmarket.png";
import SaleFinal from "../../images/salefinal.png";
import BlogMarket from "../../images/blogmarket.png";
import Recipe from "../../images/recipe.png";
import Videomgmt from "../../images/videomgmt.png";
import PodCastMgmt from "../../images/podcastmgmt.png";
import SocialEvent from "../../images/socialevent.png";
import Viral from "../../images/viral.png";
import Press from "../../images/press.png";
import Magzin from "../../images/magzin.png";
import Presskit from "../../images/presskit.png";
import Mktg from "../../images/mktg.png";
import MktgStore from "../../images/mktgstore.png";
import MarketPlace from "../../images/marketplace.png";
import Sponser from "../../images/sponser.png";
import Partner from "../../images/partner.png";
import PerrToPeer from "../../images/peertopeer.png";
import Brand from "../../images/brand.png";
import Feedback from "../../images/feedback.png";
import Ads from "../../images/ads.png";
import Legal from "../../images/legal.png";
import SidebarButton from "../layout/SidebarButton";
import ImageBox from "./ImageBox";
import { useTranslation } from "react-i18next";
import crown from "../../images/profileicon/img1.svg";
import fb from "../../images/profileicon/img2.svg";
import ins from "../../images/profileicon/img3.svg";
import twi from "../../images/profileicon/img4.svg";
import linked from "../../images/profileicon/img5.svg";
import pine from "../../images/profileicon/img6.svg";
import tube from "../../images/profileicon/img7.svg";
import tok from "../../images/profileicon/img8.svg";
import check from "../../images/profileicon/img10.svg";
import canda from "../../images/profileicon/img11.svg";
import vip from "../../images/profileicon/img12.svg";
import whts from "../../images/profileicon/img13.svg";
// import img365 from "../../images/StepZero/img70.png";

import { NavItem } from 'react-bootstrap';


export default function LeftSideBar() {
  const { t, i18n } = useTranslation();
  return (
    <div className="leftSide">
      <div className="collapse navbar-collapse" id="leftBar">
        <div className="position-absolute bg start-0 vh-100 z-index-2 sidebar-area border-end w-300">
          <div className="overflow-overlay h-100 position-relative">
            <div className="dividerSidebar bg mx-auto"></div>
            <div className="ImgArea newimg">
              {/* <div className="user position-relative mx-auto">
                <ImageBox boxwidth="w-65 userImg" src={user} />
                <div className="tag top-0 position-absolute">
                  <ImageBox boxwidth="w-16" src={tag} />
                </div>
                <div className="flag position-absolute">
                  <ImageBox boxwidth="w-24" src={flag} />
                </div>
                <div className="status bottom-0 end-0 position-absolute">
                  <ImageBox boxwidth="w-19" src={status} />
                </div>
              </div>
              <h6 className="text-uppercase mx-auto mt-2">Dyna Appolon</h6> */}
              <div className="imgin shadow-4 rounded-circle position-relative wrapimg">
                <ImageBox boxwidth="w-65 userImg" src={user} />
                <Link to="" className="icon1"><img src={fb} alt="" /></Link>
                <Link to="" className="icon2"><img src={ins} alt="" /></Link>
                <Link to="" className="icon3"><img src={twi} alt="" /></Link>
                <Link to="" className="icon4"><img src={linked} alt="" /></Link>
                <Link to="" className="icon5"><img src={pine} alt="" /></Link>
                <Link to="" className="icon6"><img src={tube} alt="" /></Link>
                <Link to="" className="icon7"><img src={tok} alt="" /></Link>
                <Link to="" className="icon8"><img src={check} alt="" /></Link>
                <Link to="" className="icon9"><img src={canda} alt="" /></Link>
                <Link to="" className="icon10"><img src={vip} alt="" /></Link>
                <Link to="" className="icon11"><img src={crown} alt="" /></Link>
                <Link to="" className="icon12"><img src={whts} alt="" /></Link>
              </div>
              <span className="btnone"><h6>Dyna Appolon</h6></span>
              <span className="btntwo"><h6>CEO | Founder | 9 to 5 Escapee</h6></span>
            </div>
            <div className="collapse SideBar d-block bg">
              <SidebarButton src={label} textClass="fw-bold" Text="ONBOARDING GUIDE" boxClass="largeHeading mt-0" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li">{t('left_bar_menu.text_here')}</NavItem>
                </ul>
              </div>
              <SidebarButton src={Incuba} textClass="fw-bold" Stage="Stage I" Text={t('left_bar_menu.business_incubator')} boxClass="largeHeading" />
              <SidebarButton src={PLAN} Text="BUSINESS NICHE CLARITY" />
              {/* <SidebarButton src={PLAN}  Text="MARKET RESEARCH - VALIDATION" /> */}
              <Link className="br-10 btnClose" to="/market-research-validation">
                <div className='w-100 d-flex align-items-center justify-content-start collapsed leftbar' data-bs-toggle="collapse" data-bs-target="#leftBar" aria-expanded="false" aria-label="Toggle navigation">
                  <img src={PLAN} alt="Plan" />
                  <span>
                    {t('left_bar_menu.market_research_validation')}
                  </span>
                </div>
              </Link>
              <SidebarButton src={PLAN} Text="BUSINESS PLAN - OVERVIEW" />
              <Link className="br-10 btnClose" to="/profile">
                <div className='w-100 d-flex align-items-center justify-content-start collapsed leftbar' data-bs-toggle="collapse" data-bs-target="#leftBar" aria-expanded="false" aria-label="Toggle navigation">
                  <img src={Lanuch} alt="Lanuch" />
                  <span>
                    {t('left_bar_menu.three_hour_business_launch')}
                  </span>
                </div>
              </Link>
              <Link className="br-10 btnClose" to="/business-profile-assets">
                <div className='w-100 d-flex align-items-center justify-content-start collapsed leftbar' data-bs-toggle="collapse" data-bs-target="#leftBar" aria-expanded="false" aria-label="Toggle navigation">
                  <img src={PLAN} alt="Lanuch" />
                  <span>
                    Business Profile | Assets
                  </span>
                </div>
              </Link>
              <Link className="br-10 btnClose" to="/day-marketing-planner">
                <div className='w-100 d-flex align-items-center justify-content-start collapsed leftbar' data-bs-toggle="collapse" data-bs-target="#leftBar" aria-expanded="false" aria-label="Toggle navigation">
                  <img src={PLAN} alt="Lanuch" />
                  <span>
                  365-DAY MARKETING PLANNER
                  </span>
                </div>
              </Link>
              {/* <SidebarButton src={PLAN} Text="365-DAY MARKETING PLANNER" />  */}
              {/* <img src={img365} alt="" className="mb-3" /> */}
              <SidebarButton src={PLAN} Text="MEMBERSHIP INCOME MODEL" />
              <SidebarButton src={PLAN} Text="E-COMMERCE STORE" />
              <SidebarButton src={PLAN} Text="GENERATE up to $5K-N-90-DAYS" />
              <SidebarButton src={Incuba} textClass="fw-bold" Text="User Management" boxClass="largeHeading" />
              <Link className="br-10 btnClose" to="/privacy">
                <div className='w-100 d-flex align-items-center justify-content-start collapsed leftbar' data-bs-toggle="collapse" data-bs-target="#leftBar" aria-expanded="false" aria-label="Toggle navigation" >
                  <img src={Privacy} alt="" />
                  <span>
                    USER GENERAL SETTINGS
                  </span>
                </div>
              </Link>
              <SidebarButton src={PLAN} Text="USER PORTAL" />
              <Link className="br-10 btnClose" to="/profileManagement" >
                <div className='w-100 d-flex align-items-center justify-content-start collapsed leftbar' data-bs-toggle="collapse" data-bs-target="#leftBar" aria-expanded="false" aria-label="Toggle navigation" >
                  <img src={Professional} alt="" />
                  <span>
                    SOCIAL PROFILE MGMT
                  </span>
                </div>
              </Link>
              <SidebarButton src={PLAN} Text="REWARDS | ACHIEVEMENT MGMT" />
              <SidebarButton src={Business} textClass="fw-bold" Text={t('left_bar_menu.business_management')} boxClass="largeHeading" />
              <SidebarButton src={Membership} Text="BUSINESS GENERAL SETTINGS" />
              <SidebarButton src={Membership} Text="BUSINESS PORTAL MGMT" />
              <SidebarButton src={Membership} Text="BUSINESS PLAN" />
              <SidebarButton src={Membership} Text="VIRTUAL OFFICE SPACE" />
              <SidebarButton src={Membership} Text="BRAND ASSETS MGMT" />
              <SidebarButton src={Membership} Text="BUSINESS PROFILE MGMT" />
              <SidebarButton src={Membership} Text="REWARDS | ENGAGEMENT MGMT" />
              <SidebarButton src={Membership} Text="ALL WEBSITES MGMT" />
              <SidebarButton src={Membership} Text="TEAM MANAGEMENT" />
              <SidebarButton src={Membership} Text="GROW FAST SYSTEM MGMT" />
              <SidebarButton src={Membership} Text="BETA CLIENTS | LISTS MGMT" />
              <SidebarButton src={Membership} Text="TESTIMONIALS | FEEDBACK MGMT" />
              <SidebarButton src={Membership} Text="BIZ PERFORMANCE METRICS" />
              <SidebarButton src={Membership} Text="MEMBERSHIP PLANS" />
              <SidebarButton src={Membership} Text="MEMBERSHIP ADD-ONS" />
              {/* <SidebarButton src={Addon}  Text={t('left_bar_menu.membership_add_ons')} />
              <SidebarButton src={MGMT}  Text={t('left_bar_menu.business_website_mgmt')} />
              <SidebarButton src={BIZ}  Text={t('left_bar_menu.biz_performance')} /> */}

              <SidebarButton src={Accel} textClass="fw-bold" Stage="Stage II" Text="BUSINESS ACCELERATOR" boxClass="largeHeading" />
              <SidebarButton src={Grow} Text="360 BIZ VISIBILITY STRATEGY BLUEPRINT" />
              <SidebarButton src={Headphones} Text="BRAND EXPOSURE: BETA CLIENTS" />
              <SidebarButton src={GENERATE} Text="GO-TO-MARKET STRATEGY PLAN" />
              <SidebarButton src={GENERATE} Text="GENERATE up to $25K-N-90-DAYS " />
              <SidebarButton src={Figure} textClass="fw-bold" Stage="Stage III" Text="7 FIGURE- SCALE-UP" boxClass="largeHeading" />
              <SidebarButton src={Register} Text="REGISTER & LAUNCH  2 MORE NICHES" />
              <SidebarButton src={Course} Text="UNLOCK “7 FIGURE BOSSES.COM“ DOMAIN" />
              <SidebarButton src={Course} Text="PROFESSIONAL RE-BRANDING" />
              <SidebarButton src={Course} Text="CLIENT AVATAR | PROFILE" />
              <SidebarButton src={Course} Text="LAUNCH YOUR PODCAST" />
              <SidebarButton src={Course} Text="LAUNCH YOUR ONLINE COURSE" />
              <SidebarButton src={Course} Text="LAUNCH YOUR AFFILIATE PROGRAM" />
              <SidebarButton src={Course} Text="GENERATE up to $150K-N-90-DAYS" />
              <SidebarButton src={Tools} textClass="fw-bold" Text="MARKETING TOOLS “ROADMAP BETA” " boxClass="largeHeading" />
              {/* <SidebarButton src={Membership}  Text="ROADMAP BETA" /> */}
              <SidebarButton src={Client} Text="CLIENT  MGMT" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">All in one CRM</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CLIENT ACCOUNTS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CLIENT PROFILE CARD</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CONTRACTS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">PROPOSALS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ESTIMATES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">INVOICES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">PAYMENTS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">INVOICES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREDIT NOTE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">FILE RECORDS VAULT</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={EmailMarket} Text="EMAIL MARKETING MGMT" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">DASHBOARD</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL CONTACTS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL GROUP LISTS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL CAMPAIGNS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">EMAIL CAMPAIGNS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">SMS CAMPAIGNS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">EMAIL TEMPLATES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW EMAIL TEMPLATE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">EMAIL SEQUENCES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">EMAIL SERIES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">AUTO RESPONDER</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL EMAILS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">EMAIL INBOX</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">COMPOSE EMAIL</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">SCHEDULES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">EMAIL BROADCAST</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">TRACKER</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">LOGS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">SOCIAL MEDIA DM</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">VIDEO EMAIL</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">LEAD GENERATION</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">SHORT LINKS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">GRAMMARLY</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={SocialMarket} Text="SOCIAL MEDIA MGMT" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">DASHBOARD</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">POST'S SCHEDULER</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">POST'S DESIGNS | TEMPLATES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">POST'S PLANNER </Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">COMPRESS IMAGES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATIVE DESIGNS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">BIO LINK MICRO SITE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">BIO LINK</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">LIVE STREAM</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">BIO LINK PROFILE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">YOUTUBE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">INSTAGRAM </Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">FACEBOOK</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">PINTEREST</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">LINKEDIN</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">TWITTER</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">TWITCH</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">TIKTOK</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={SaleFinal} Text="SALES FUNNELS MGMT" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">DASHBOARD</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL FUNNEL TEMPLATES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW FUNNEL</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW PRODUCT</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW COURSE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW OFFER</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW WEBINAR</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW CHALLENGE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW CONTEST</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW EVENT</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW POLL</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW COUPON</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL PRODUCTS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL COURSES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL OFFERS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL WEBINARS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL CHALLENGES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL CONTESTS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL EVENTS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL COUPONS</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={BlogMarket} Text="BLOG WRITING MGMT" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">BLOG DASHBOARD</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">BLOG WRITING TEMPLATES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">BLOG | POST AUTHORS | WRITERS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">BLOG | POST CATEGORIES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">BLOG | POST TAGS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL IMAGE & MEDIA WALL</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">GRAMMARLY</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">BLOG PRE-WRITER</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CONTENT AI WRITER (1)</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CONTENT AI WRITER (2)</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">SHORT LINKS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">COMPRESS IMAGES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">BLOG POST AUTHORS | WRITERS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">TAG CATEGORY</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={Recipe} Text="RECIPE WRITING MGMT" />
              <SidebarButton src={Videomgmt} Text="VIDEO CHANNEL MGMT" />
              <SidebarButton src={PodCastMgmt} Text="PODCAST STUDIO MGMT" />
              <SidebarButton src={SocialEvent} Text="SOCIAL EVENTS MGMT" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">DASHBOARD</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW EVENT</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL EVENT TEMPLATES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">LIVE WEBINAR</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW WEBINAR</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL WEBINARS</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={Viral} Text="VIRAL CONTEST MGMT" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">DASHBOARD</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">VIRAL CONTESTS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW CONTEST</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL CONTEST TEMPLATES</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={Press} Text="PRESS RELEASE MGMT" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">PR-WRITING TEMPLATE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">PR | DISTRIBUTER</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={Magzin} Text="E-MAGAZINES MGMT" />
              <SidebarButton src={Presskit} Text="PRESS KIT BRAND MGMT" />
              <SidebarButton src={Mktg} Text="AFFILIATING MKTG MGMT" />
              <SidebarButton src={MktgStore} Text="AFFILIATING MKTG STORE" />
              <SidebarButton src={MarketPlace} Text="E-COMMERCE MARKETPLACE" />
              <SidebarButton src={Sponser} Text="SPONSORSHIPS | MGMT" />
              <SidebarButton src={Partner} Text="PARTNERSHIP MGMT" />
              <SidebarButton src={PerrToPeer} Text="COLLAB : PEER-TO-PEER" />
              <SidebarButton src={Brand} Text="BRAND DEALS MGMT" />
              <SidebarButton src={Feedback} Text="FEEDBACK | REVIEWS MGMT" />
              <SidebarButton src={Ads} Text="ADS MGMT" />
              <SidebarButton src={Legal} Text="LEGAL MGMT" />
            </div>
          </div>
        </div>
      </div>
      <div className="position-absolute bg start-0 vh-100 sidebar-area border-end">
        <div className="overflow-overlay h-100 position-relative">
          <div className="dividerSidebar bg mx-auto"></div>
          <div className="ImgArea newimg">
            {/* <div className="user position-relative mx-auto">
              <ImageBox boxwidth="w-65 userImg" src={user} />
              <div className="tag top-0 position-absolute">
                <ImageBox boxwidth="w-16" src={tag} />
              </div>
              <div className="flag position-absolute">
                <ImageBox boxwidth="w-24" src={flag} />
              </div>
              <div className="status bottom-0 end-0 position-absolute">
                <ImageBox boxwidth="w-19" src={status} />
              </div>
            </div>
            <h6 className="text-uppercase mx-auto mt-2">dyna appolon</h6> */}
            <div className="imgin shadow-4 rounded-circle position-relative ">
              <ImageBox boxwidth="w-65 userImg" src={user} />
              <Link to="" className="icon1"><img src={fb} alt="" /></Link>
              <Link to="" className="icon2"><img src={ins} alt="" /></Link>
              <Link to="" className="icon3"><img src={twi} alt="" /></Link>
              <Link to="" className="icon4"><img src={linked} alt="" /></Link>
              <Link to="" className="icon5"><img src={pine} alt="" /></Link>
              <Link to="" className="icon6"><img src={tube} alt="" /></Link>
              <Link to="" className="icon7"><img src={tok} alt="" /></Link>
              <Link to="" className="icon8"><img src={check} alt="" /></Link>
              <Link to="" className="icon9"><img src={canda} alt="" /></Link>
              <Link to="" className="icon10"><img src={vip} alt="" /></Link>
              <Link to="" className="icon11"><img src={crown} alt="" /></Link>
              <Link to="" className="icon12"><img src={whts} alt="" /></Link>
            </div>
          </div>
          <div className="collapse SideBar d-block bg">
            <SidebarButton src={label} textClass="fw-bold" Text="ONBOARDING GUIDE" boxClass="largeHeading mt-0" id="dropSection" />
            <div className="subBar dropdown px-4 py-3">
              <ul>
                <NavItem as="li">{t('left_bar_menu.text_here')}</NavItem>
              </ul>
            </div>
            <Link className="br-10 btnClose" to="/privacy">
              <div className='w-100 d-flex align-items-center justify-content-start'>
                <img src={Privacy} alt="" />
                <span>
                  {t('left_bar_menu.all_privacy_settings')}
                </span>
              </div>
            </Link>

            <Link className="br-10 btnClose" to="/profileManagement">
              <div className='w-100 d-flex align-items-center justify-content-start'>
                <img src={Professional} alt="" />
                <span>
                  {t('left_bar_menu.professional_social_profile_mgmt')}
                </span>
              </div>
            </Link>

            {/* <SidebarButton src={Professional}  Text="PROFESSIONAL | SOCIAL PROFILE MGMT" boxClass="fs-11" /> */}
            <SidebarButton src={Professional} Text={t('left_bar_menu.rewards_badges_quests_points')} boxClass="fs-11" />
            <SidebarButton src={Business} textClass="fw-bold" Text={t('left_bar_menu.business_management')} boxClass="largeHeading" />
            <SidebarButton src={Membership} Text={t('left_bar_menu.membership_plans')} />
            <SidebarButton src={Addon} Text={t('left_bar_menu.membership_add_ons')} />
            <SidebarButton src={MGMT} Text={t('left_bar_menu.business_website_mgmt')} />
            <SidebarButton src={BIZ} Text={t('left_bar_menu.biz_performance')} />
            <SidebarButton src={Incuba} textClass="fw-bold" Stage="Stage I" Text={t('left_bar_menu.business_incubator')} boxClass="largeHeading" />
            <Link className="br-10 btnClose" to="/threeHoursBusinessLaunch">
              <div className='w-100 d-flex align-items-center justify-content-start'>
                <img src={Lanuch} alt="Lanuch" />
                <span>
                  {t('left_bar_menu.three_hour_business_launch')}
                </span>
              </div>
            </Link>
            {/* <SidebarButton src={Lanuch}  Text="3-HOUR BUSINESS LAUNCH"  /> */}
            <SidebarButton src={PLAN} Text="30-DAY MARKETING PLAN" />
            <SidebarButton src={Accel} textClass="fw-bold" Stage="Stage II" Text="BUSINESS ACCELERATOR" boxClass="largeHeading" />
            <SidebarButton src={Grow} Text="GROW FAST | SOCIAL PROOF & BUILD CREDIBILITY" />
            <SidebarButton src={Headphones} Text="GENERATE $5K- N- 90 DAYS" />
            <SidebarButton src={GENERATE} Text="LAUNCH YOUR PODCAST" />
            <SidebarButton src={Figure} textClass="fw-bold" Stage="Stage III" Text="7 FIGURE- SCALE-UP" boxClass="largeHeading" />
            <SidebarButton src={Register} Text="REGISTER FOR 3 MORE NICHES" />
            <SidebarButton src={Course} Text="LAUNCH YOUR OWN ONLINE COURSE" />
            <SidebarButton src={Tools} textClass="fw-bold" Text="MARKETING TOOLS" boxClass="largeHeading" />
            <SidebarButton src={Membership} Text="ROADMAP BETA" />
            <SidebarButton src={Client} Text="CLIENT  MGMT" id="dropSection" />
            <div className="subBar dropdown px-4 py-3">
              <ul>
                <NavItem as="li"><Link className="br-10" to="#">All in one CRM</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CLIENT ACCOUNTS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CLIENT PROFILE CARD</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CONTRACTS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">PROPOSALS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ESTIMATES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">INVOICES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">PAYMENTS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">INVOICES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREDIT NOTE</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">FILE RECORDS VAULT</Link></NavItem>
              </ul>
            </div>
            <SidebarButton src={EmailMarket} Text="EMAIL MARKETING MGMT" id="dropSection" />
            <div className="subBar dropdown px-4 py-3">
              <ul>
                <NavItem as="li"><Link className="br-10" to="#">DASHBOARD</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL CONTACTS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL GROUP LISTS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL CAMPAIGNS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">EMAIL CAMPAIGNS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">SMS CAMPAIGNS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">EMAIL TEMPLATES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW EMAIL TEMPLATE</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">EMAIL SEQUENCES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">EMAIL SERIES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">AUTO RESPONDER</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL EMAILS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">EMAIL INBOX</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">COMPOSE EMAIL</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">SCHEDULES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">EMAIL BROADCAST</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">TRACKER</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">LOGS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">SOCIAL MEDIA DM</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">VIDEO EMAIL</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">LEAD GENERATION</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">SHORT LINKS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">GRAMMARLY</Link></NavItem>
              </ul>
            </div>
            <SidebarButton src={SocialMarket} Text="SOCIAL MEDIA MGMT" id="dropSection" />
            <div className="subBar dropdown px-4 py-3">
              <ul>
                <NavItem as="li"><Link className="br-10" to="#">DASHBOARD</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">POST'S SCHEDULER</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">POST'S DESIGNS | TEMPLATES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">POST'S PLANNER </Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">COMPRESS IMAGES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATIVE DESIGNS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">BIO LINK MICRO SITE</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">BIO LINK</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">LIVE STREAM</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">BIO LINK PROFILE</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">YOUTUBE</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">INSTAGRAM </Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">FACEBOOK</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">PINTEREST</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">LINKEDIN</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">TWITTER</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">TWITCH</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">TIKTOK</Link></NavItem>
              </ul>
            </div>
            <SidebarButton src={SaleFinal} Text="SALES FUNNELS MGMT" id="dropSection" />
            <div className="subBar dropdown px-4 py-3">
              <ul>
                <NavItem as="li"><Link className="br-10" to="#">DASHBOARD</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL FUNNEL TEMPLATES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW FUNNEL</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW PRODUCT</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW COURSE</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW OFFER</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW WEBINAR</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW CHALLENGE</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW CONTEST</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW EVENT</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW POLL</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW COUPON</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL PRODUCTS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL COURSES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL OFFERS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL WEBINARS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL CHALLENGES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL CONTESTS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL EVENTS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL COUPONS</Link></NavItem>
              </ul>
            </div>
            <SidebarButton src={BlogMarket} Text="BLOG WRITING MGMT" id="dropSection" />
            <div className="subBar dropdown px-4 py-3">
              <ul>
                <NavItem as="li"><Link className="br-10" to="#">BLOG DASHBOARD</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">BLOG WRITING TEMPLATES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">BLOG | POST AUTHORS | WRITERS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">BLOG | POST CATEGORIES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">BLOG | POST TAGS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL IMAGE & MEDIA WALL</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">GRAMMARLY</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">BLOG PRE-WRITER</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CONTENT AI WRITER (1)</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CONTENT AI WRITER (2)</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">SHORT LINKS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">COMPRESS IMAGES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">BLOG POST AUTHORS | WRITERS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">TAG CATEGORY</Link></NavItem>
              </ul>
            </div>
            <SidebarButton src={Recipe} Text="RECIPE WRITING MGMT" />
            <SidebarButton src={Videomgmt} Text="VIDEO CHANNEL MGMT" />
            <SidebarButton src={PodCastMgmt} Text="PODCAST STUDIO MGMT" />
            <SidebarButton src={SocialEvent} Text="SOCIAL EVENTS MGMT" />
            <div className="subBar dropdown px-4 py-3">
              <ul>
                <NavItem as="li"><Link className="br-10" to="#">DASHBOARD</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW EVENT</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL EVENT TEMPLATES</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">LIVE WEBINAR</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW WEBINAR</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL WEBINARS</Link></NavItem>
              </ul>
            </div>
            <SidebarButton src={Viral} Text="VIRAL CONTEST MGMT" id="dropSection" />
            <div className="subBar dropdown px-4 py-3">
              <ul>
                <NavItem as="li"><Link className="br-10" to="#">DASHBOARD</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">VIRAL CONTESTS</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW CONTEST</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">ALL CONTEST TEMPLATES</Link></NavItem>
              </ul>
            </div>
            <SidebarButton src={Press} Text="PRESS RELEASE MGMT" id="dropSection" />
            <div className="subBar dropdown px-4 py-3">
              <ul>
                <NavItem as="li"><Link className="br-10" to="#">PR-WRITING TEMPLATE</Link></NavItem>
                <NavItem as="li"><Link className="br-10" to="#">PR | DISTRIBUTER</Link></NavItem>
              </ul>
            </div>
            <SidebarButton src={Magzin} Text="E-MAGAZINES MGMT" />
            <SidebarButton src={Presskit} Text="PRESS KIT BRAND MGMT" />
            <SidebarButton src={Mktg} Text="AFFILIATING MKTG MGMT" />
            <SidebarButton src={MktgStore} Text="AFFILIATING MKTG STORE" />
            <SidebarButton src={MarketPlace} Text="E-COMMERCE MARKETPLACE" />
            <SidebarButton src={Sponser} Text="SPONSORSHIPS | MGMT" />
            <SidebarButton src={Partner} Text="PARTNERSHIP MGMT" />
            <SidebarButton src={PerrToPeer} Text="COLLAB : PEER-TO-PEER" />
            <SidebarButton src={Brand} Text="BRAND DEALS MGMT" />
            <SidebarButton src={Feedback} Text="FEEDBACK | REVIEWS MGMT" />
            <SidebarButton src={Ads} Text="ADS MGMT" />
            <SidebarButton src={Legal} Text="LEGAL MGMT" />
          </div>
          <div className=" overlayer" type="button" data-bs-toggle="collapse" data-bs-target="#leftBar" aria-controls="leftBar" aria-expanded="false" aria-label="Toggle navigation"></div>
        </div>
      </div>
    </div>
  );
}
