import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import img5 from '../../images/social/img11.png';
import img11s from '../../images/social/img12.png';
import topbg from '../../images/social/img10.png';
// import auto from '../../images/audio/auto.png';
// import setting from '../../images/audio/setting.png';
// import short from '../../images/audio/short.png';
// import frame1 from '../../images/audio/frame1.png';
// import full from '../../images/audio/full.png';
// import play from '../../images/audio/play.png';
// import forwad from '../../images/audio/forwad.png';
// import sound from '../../images/audio/sound.png';
// import playbar from "../../images/audio/img21.png";
// import soundeimg from "../../images/audio/soundeimg.png";

import setting from "../../images/audio/setting.png";
import short from "../../images/audio/short.png";
import frame1 from "../../images/audio/frame1.png";
import full from "../../images/audio/full.png";
import play from "../../images/audio/play.png";
import forwad from "../../images/audio/forwad.png";
import sound from "../../images/audio/sound.png";
import auto from "../../images/audio/auto.png";
import icon2 from "../../images/audio/icon2.png";
import icon3 from "../../images/audio/icon3.png";
import icon4 from "../../images/audio/icon4.png";
import icon5 from "../../images/audio/icon5.png";
import icon6 from "../../images/audio/icon6.png";
import icon9 from "../../images/audio/icon8.png";
import vpause from "../../images/audio/vpause.png";

import testvideo from "../../images/audio/testvideo.mp4";
import testvideo2 from "../../images/audio/testvideo2.mp4";
import close from "../../images/close1.png";
import testvideo3 from "../../images/audio/testvideo3.mp4";
import mute from "../../images/audio/mute.png";
import high from "../../images/audio/high.png";
import { GiPauseButton } from "react-icons/gi";
import { useDispatch } from 'react-redux';
import { closeVideo } from '../../redux/actions/videoAction';

export default function VideoViewSec() {

  const videoData = [testvideo, testvideo2, testvideo3];
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const videoRef = useRef(null);
  const [volume, setVolume] = useState(50);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [showVolumeControls, setShowVolumeControls] = useState(false);
  const [isMiniScreen, setIsMiniScreen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [autoplay, setAutoplay] = useState(false);
  const dispatch = useDispatch();
  const handlePlaylistClick = (index) => {
    setCurrentVideoIndex(index);
    
    setIsPlaying(true);
    const progressBar = document.querySelector('.progress-inner');
    progressBar.style.width = '0%';
    progressBar.style.animation = 'none';
  
    // Load and play the new video
    videoRef.current.load();
    videoRef.current.play();
  
    // Set up a new event listener for the progress bar on the new video
    videoRef.current.addEventListener('timeupdate', updateProgressBar);
  
  };
  const updateProgressBar = () => {
    const video = videoRef.current;
    const progressBar = document.querySelector('.progress-inner');
    const currentTime = video.currentTime;
    const duration = video.duration;
  
    const progress = (currentTime / duration) * 100;
    progressBar.style.width = progress + '%';
  };
  useEffect(() => {
    if (autoplay) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [autoplay]);
  const onVideoEnded = () => {
    if (autoplay) {
      currentVideoIndex.current++;
      if (currentVideoIndex.current < videoData.length) {
        videoRef.current.src = videoData[currentVideoIndex.current];
        videoRef.current.play();
      }
    }
    setIsPlaying(false);
  };
  useEffect(() => {
    // Load the initial video when the component mounts
    loadVideo(currentVideoIndex);
  }, [currentVideoIndex]);

  const loadVideo = (index) => {
    setIsLoading(true);
    setCurrentTime(0);
    setIsPlaying(false);

    videoRef.current.src = videoData[index];
    videoRef.current.load();
  };

  useEffect(() => {
    if (showVolumeControls) {
      setTimeout(() => {
        setShowVolumeControls(false);
      }, 5000);
    }
  }, [showVolumeControls]);

  const toggleVolumeControls = () => {
    setShowVolumeControls(!showVolumeControls);
  };
  const handleForwardClick = () => {
    const nextVideoIndex = (currentVideoIndex + 1) % videoData.length;
    if (nextVideoIndex >= 0) {
      const progressBar = document.querySelector('.progress-inner');
      progressBar.style.width = '0%';
      progressBar.style.animation = 'none';
  
      setTimeout(() => {
        // Re-enable the animation
        progressBar.style.animation = 'animate-bar 1s linear forwards';
  
        // Load and play the new video
        videoRef.current.src = videoData[nextVideoIndex];
        videoRef.current.load();
        videoRef.current.play();
  
        setCurrentVideoIndex(nextVideoIndex);
        setIsPlaying(true);
      }, 10); // Add a slight delay (e.g., 10 milliseconds) before re-enabling the animation
    }
  };
  
  useEffect(() => {
    videoRef.current.src = videoData[currentVideoIndex];
  }, [currentVideoIndex]);

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value) / 100;
    setVolume(newVolume);
    videoRef.current.volume = newVolume;
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  const onTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const handleTheaterButtonClick = () => {
    const videoContainer = document.querySelector(".middleimg");
    if (videoContainer.classList.contains("theater-mode")) {
      videoContainer.classList.remove("theater-mode");
      setIsMiniScreen(false); // Exit theater mode
    } else {
      videoContainer.classList.add("theater-mode");
      setIsMiniScreen(false); // Exit mini-screen mode if currently in it
    }
  };

  const handleMiniPlayerButtonClick = () => {
    const videoContainer = document.querySelector(".middleimg");
    if (videoContainer.classList.contains("theater-mode")) {
      videoContainer.classList.remove("mini-screen");
      setIsMiniScreen(false);
      setIsFullScreen(false); // Exit theater mode
    } else {
      videoContainer.classList.add("mini-screen");
      setIsMiniScreen(true);
      setIsFullScreen(false); // Exit mini-screen mode if currently in it
    }
 
  };

  const handleFullScreenButtonClick = () => {
    const videoContainer = document.querySelector(".middleimg");

    if (videoContainer) {
      if (!document.fullscreenElement) {
        if (videoContainer.requestFullscreen) {
          videoContainer.requestFullscreen();
        } else if (videoContainer.mozRequestFullScreen) {
          videoContainer.mozRequestFullScreen();
        } else if (videoContainer.webkitRequestFullscreen) {
          videoContainer.webkitRequestFullscreen();
        } else if (videoContainer.msRequestFullscreen) {
          videoContainer.msRequestFullscreen();
        }

        videoContainer.classList.add("full-screen");

        setIsFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }

        // Remove the full-screen CSS class
        videoContainer.classList.remove("full-screen");
        setIsFullScreen(false);
      }
    }
  };
 const handleClick6 = () =>{
  dispatch(closeVideo());
};
const [isDragging, setIsDragging] = useState(false);
const [position, setPosition] = useState({ left: 0, top: 0 });
const [mouseOffset, setMouseOffset] = useState({ x: 0, y: 0 });

const handleMouseDown = (e) => {
  setIsDragging(true);
  const { left, top } = position;
  const { clientX, clientY } = e;
  setMouseOffset({
    x: clientX - left,
    y: clientY - top,
  });
};

const handleMouseUp = () => {
  setIsDragging(false);
};

const handleMouseMove = (e) => {
  if (isDragging) {
    const { clientX, clientY } = e;
    const newLeft = clientX - mouseOffset.x;
    const newTop = clientY - mouseOffset.y;
    setPosition({ left: newLeft, top: newTop });
  }
};
const [showVideoVolumeControls, setShowVideoVolumeControls] = useState(false);
const sliderRef = useRef(null);
const miniScreenStyle = {
  position: "fixed",
  bottom: "10px",
  right: "10px",
  display: isMiniScreen ? "block" : "none",
  zIndex:9999,
};

const toggleVideoVolumeControls = () => {
  setShowVideoVolumeControls(!showVideoVolumeControls);
};


const handleVideoVolumeChange = (e) => {
  const newVolume = parseFloat(e.target.value) / 100;
  setVolume(newVolume);
  videoRef.current.volume = newVolume;

};


  return (
    <div>
      {isMiniScreen ?   <div className="mini-screen " style={miniScreenStyle}>
                  <div className="videoplayer1"><button className="topbtn border-0 bg-primary w-100">
              VIDEO CENTER{" "}
            </button></div>
                  <div className={`video-container-mini ${isFullScreen ? "full-screen1" : ""}`}>
                  <div className="topvid41">
                    <div className="vol-btn"> <img src={high} onClick={toggleVideoVolumeControls}/>
                    <div className="sound-control-mini">
                            <div>
                              {showVideoVolumeControls && (
                                <div className=" justify-content-center  ">
                                  
                                  <div className="volume-control-mini">
                                    {/* <div className="slider-container"> */}
                                    <input
                                      className="soundediv win10-thumb"
                                      type="range"
                                      defaultValue={volume}
                                      min={0}
                                      max={100}
                                      step={10}
                                      sliderRef={sliderRef}
                                      onChange={handleVideoVolumeChange}
                                    />
                                   
                                  </div>
                                  
                                </div>
                              )} 
                            </div>
                          </div></div>
                    <div className="close-button " onClick={handleClick6}>
                      <img src={close} alt="" onClick={handleClick6} />
                    </div>

                    <video
                    className="minvid"
                    ref={videoRef}
                    onCanPlayThrough={() => {
                      setDuration(videoRef.current.duration);
                      setIsLoading(false);
                    }}
                    onTimeUpdate={onTimeUpdate}
                    onEnded={onVideoEnded}
                  >
                    <source
                      src={videoData[currentVideoIndex]}
                      type="video/mp4"
                    />
                    </video>
                    <div className="position-absolute d-inline-table  top-0 bottom-0 start-0 end-0 m-auto">
                    
                      <div onClick={handlePlayPause}>
                        <div className="miniplay">
                          {isPlaying ? (
                            ""
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="130"
                              height="130"
                              viewBox="0 0 130 130"
                              fill="none"
                            >
                              <circle
                                cx="65"
                                cy="65"
                                r="64"
                                stroke="#F0F4F8"
                                stroke-width="2"
                              />
                              <circle
                                cx="63.375"
                                cy="63.375"
                                r="34.125"
                                fill="url(#paint0_linear_255_76455)"
                              />
                              <path
                                d="M56.875 55.4821C56.875 53.9385 58.5494 52.9768 59.8827 53.7545L73.4135 61.6474C74.7365 62.4192 74.7365 64.3308 73.4135 65.1026L59.8827 72.9955C58.5494 73.7732 56.875 72.8115 56.875 71.2679V55.4821Z"
                                fill="#202020"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_255_76455"
                                  x1="63.375"
                                  y1="16.965"
                                  x2="63.375"
                                  y2="108.42"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#FACB30" />
                                  <stop
                                    offset="0.302269"
                                    stop-color="#FEF9C8"
                                  />
                                  <stop
                                    offset="0.640625"
                                    stop-color="#C49530"
                                  />
                                  <stop offset="1" stop-color="#FFF8C4" />
                                </linearGradient>
                              </defs>
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                    {isFullScreen ? 
                    <div className="position-absolute pb-1 d-inline-table mb-5 w-75 bottom-0 start-0 end-0 mx-auto">
                      <div className="  mx-auto mb-2">
                        <div className=" mx-auto mb-2 progressbar">
                          <div
                            className={`${"progressinner"} ${
                              currentTime !== duration ? "progress-inner" : ""
                            } `}
                            style={{
                              animationPlayState: isPlaying
                                ? "running"
                                : "paused",
                                animationDuration: isLoading ? "0s" : videoRef.current ? `${videoRef.current.duration}s` : "0s"
                                ,
                            }}
                          />
                        </div>{" "}
                        {/* <div className="d-flex playable justify-content-between px-1">
                          <div className="d-flex">
                            <Link to="">
                              <div onClick={handlePlayPause}>
                                {isPlaying ? (
                                  <img src={vpause} alt="" />
                                ) : (
                                  <img src={play} alt="" />
                                )}
                              </div>
                            </Link>
                          </div>
                          <div className="d-flex">
                            <Link to="">
                              <img
                                src={full}
                                alt=""
                                onClick={handleFullScreenButtonClick}
                              />
                            </Link>
                          </div>
                        </div> */}
                      </div>
                    </div> : <div className="position-absolute pb-1 d-inline-table  w-75 bottom-0 start-0 end-0 mx-auto">
                      <div className="  mx-auto mb-1">
                        <div className=" mx-auto mb-1 progressbar">
                          <div
                            className={`${"progressinner"} ${
                              currentTime !== duration ? "progress-inner" : ""
                            } `}
                            style={{
                              animationPlayState: isPlaying
                                ? "running"
                                : "paused",
                                animationDuration: isLoading ? "0s" : videoRef.current ? `${videoRef.current.duration}s` : "0s",
                                
                            }}
                          />
                        </div>{" "}
                        <div className="d-flex playable justify-content-center px-1">
                          <div className="d-flex">
                            <Link to="">
                              <div onClick={handlePlayPause}>
                                {isPlaying ? (
                                  <img src={vpause} alt="" />
                                ) : (
                                  <img src={play} alt="" />
                                )}
                              </div>
                            </Link>
                          </div>
                          {/* <div className="d-flex">
                            <Link to="">
                              <img
                                src={full}
                                alt=""
                                onClick={handleFullScreenButtonClick}
                              />
                            </Link>
                          </div> */}
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
              </div> :
      <div 
       className="dragme text-center w-560 positionVideo" 
       
      style={{
        position: 'fixed',
        left: `${position.left}px`,
        top: `${position.top}px`,
        cursor: isDragging ? 'grabbing' : 'grab',
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
       
      >
        <div className="audioply">
          <div className="videoplayer2 pt-100">
            {/* <button className="topbtn border-0 bg-primary">SECTIONAL TIPS | TUTORIAL </button> */}
            <div className="videobox d-flex justify-content-between h-100 position-relative">
            {/* <img src={topbg} alt='' className='position-absolute top-0 start-0' /> */}
            <div className="closebtn2" onClick={handleClick6}>
              <img src={close} alt="" />
            </div>
              <div className="playersec">
                <div className="playerinternal position-relative h-100">
                  <div className="vidplay position-relative h-100 w-100">
                    <div className="audiotool position-relative shadow-5in br-50s p-2">
                    <div className="gradient-box-dark br-50s h-310">
                        <div
                          className={`middleimg  position-relative ${
                            isMiniScreen ? "mini-player" : ""
                          } ${isFullScreen ? "full-screen" : ""}`}
                        >
                          {/* <img src={img5} alt="" /> */}
                          {isFullScreen ? (
                            <div>
                              <button className="topbtn border-0 mt-5 bg-primary">
                                VIDEO CENTER{" "}
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="topvid4">
                          <video
                            className="theater-mode br-45 h-304"
                            ref={videoRef}
                            onCanPlayThrough={() => {
                              setDuration(videoRef.current.duration);
                              setIsLoading(false);
                            }}
                            onTimeUpdate={onTimeUpdate}
                            onEnded={onVideoEnded}
                          >
                            <source
                              src={videoData[currentVideoIndex]}
                              type="video/mp4"
                            />
                          </video>
                          <div className="sound-control22">
                            <div>
                              {showVolumeControls && (
                                <div className=" justify-content-center top-25 ">
                                  <div>
                                    <img src={high} />
                                  </div>
                                  <div className="volume-control22">
                                    {/* <div className="slider-container"> */}
                                    <input
                                      className="soundediv22 win10-thumb"
                                      type="range"
                                      defaultValue={volume}
                                      min={0}
                                      max={100}
                                      step={10}
                                      
                                      onChange={handleVolumeChange}
                                    />
                                    {/* <div
                                      className="thumb-image "
                                      ref={thumbImageRef}
                                    >
                                      <img src={volumeunions} />
                                    </div> */}
                                    {/*</div>  */}
                                  </div>
                                  <div>
                                    <img src={mute} />
                                  </div>
                                </div>
                              )} 
                            </div>
                          </div>
                          <div className="position-absolute d-inline-table top-0 bottom-0 start-0 end-0 m-auto">
                            <div onClick={handlePlayPause}>
                              {isMiniScreen ? (
                                ""
                              ) : (
                                <div>
                                  { isPlaying ? (
                                   ""
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="100"
                                      height="100"
                                      viewBox="0 0 130 130"
                                      fill="none"
                                    >
                                      <circle
                                        cx="65"
                                        cy="65"
                                        r="64"
                                        stroke="#F0F4F8"
                                        stroke-width="2"
                                      />
                                      <circle
                                        cx="63.375"
                                        cy="63.375"
                                        r="34.125"
                                        fill="url(#paint0_linear_255_76455)"
                                      />
                                      <path
                                        d="M56.875 55.4821C56.875 53.9385 58.5494 52.9768 59.8827 53.7545L73.4135 61.6474C74.7365 62.4192 74.7365 64.3308 73.4135 65.1026L59.8827 72.9955C58.5494 73.7732 56.875 72.8115 56.875 71.2679V55.4821Z"
                                        fill="#202020"
                                      />
                                      <defs>
                                        <linearGradient
                                          id="paint0_linear_255_76455"
                                          x1="63.375"
                                          y1="16.965"
                                          x2="63.375"
                                          y2="108.42"
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop stop-color="#FACB30" />
                                          <stop
                                            offset="0.302269"
                                            stop-color="#FEF9C8"
                                          />
                                          <stop
                                            offset="0.640625"
                                            stop-color="#C49530"
                                          />
                                          <stop
                                            offset="1"
                                            stop-color="#FFF8C4"
                                          />
                                        </linearGradient>
                                      </defs>
                                    </svg>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          {isFullScreen ? (
                                <div className="position-absolute pb-3 d-inline-table  videoControlls bottom-0 start-0 end-0 mx-auto">
                                  {/* <img src={playbar} alt="" /> */}

                                  <div className="  mx-auto mb-3">
                                    <div className=" mx-auto mb-3 progressbar">
                                      <div
                                        className={`${"progressinner"} ${
                                          currentTime !== duration
                                            ? "progress-inner"
                                            : ""
                                        } `}
                                        style={{
                                          animationPlayState: isPlaying
                                            ? "running"
                                            : "paused",
                                          animationDuration: isLoading
                                            ? "0s"
                                            : `${videoRef.current.duration}s`,
                                        }}
                                      />
                                    </div>{" "}
                                  </div>

                                  {/* <Slider
                                  className="slider1"
                                  type="range"
                                  min="0"
                                  max={duration}
                                  step="1"
                                  value={currentTime}
                                  onChange={handleSliderChange}
                                  style={{
                                    animationPlayState: isPlaying
                                      ? "running"
                                      : "paused",
                                    animationDuration: isLoading
                                      ? "0s"
                                      : `${videoRef.current.duration}s`,
                                  }}
                                /> */}

                                  <div className="d-flex playable justify-content-between px-4">
                                    <div className="d-flex">
                                      <Link to="">
                                        <div onClick={handlePlayPause}>
                                          {isPlaying ? (
                                            <img src={vpause} alt="" />
                                          ) : (
                                            <img src={play} alt="" />
                                          )}
                                        </div>
                                      </Link>
                                      <Link to="">
                                        <img
                                          src={forwad}
                                          alt=""
                                          onClick={handleForwardClick}
                                        />
                                      </Link>
                                      <Link to="">
                                        <img
                                          src={sound}
                                          alt=""
                                          onClick={toggleVolumeControls}
                                        />
                                      </Link>

                                      <div className="btnplaytime">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="me-2"
                                          width="18"
                                          height="19"
                                          viewBox="0 0 18 19"
                                          fill="none"
                                        >
                                          <path
                                            d="M9 18.1699C4.0293 18.1699 0 14.1406 0 9.16992C0 4.19922 4.0293 0.169922 9 0.169922C13.9707 0.169922 18 4.19922 18 9.16992C18 14.1406 13.9707 18.1699 9 18.1699ZM9.9 9.16992V4.66992H8.1V10.9699H13.5V9.16992H9.9Z"
                                            fill="#202020"
                                          />
                                        </svg>
                                        {formatTime(currentTime)} /{" "}
                                        {formatTime(duration)}
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <div
                                        className="dropdown mx-3 dropnew dropnew4"
                                        id="dropbtn8"
                                      >
                                        <button
                                          className="p-0 m-0 bg-transparent border-0"
                                          type="button"
                                          id="dropbtn8"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <img src={auto} alt="" />
                                        </button>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end border-0 bg-transparent"
                                          aria-labelledby="dropbtn8"
                                        >
                                          <div className="gradient-box newdrop6">
                                            <li>
                                              <h6 className="m-0 d-flex align-items-center justify-content-center">
                                                Auto Play On
                                                <div className="toggle_btn_wrapper bg w-auto ms-2">
                                                  <div className="form-check form-switch px-2">
                                                    <input
                                                      className="form-check-input d-block float-end"
                                                      id="auto4"
                                                      type="checkbox"
                                                      role="switch"
                                                      onChange={() =>
                                                        setAutoplay(!autoplay)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </h6>
                                            </li>
                                          </div>
                                        </ul>
                                      </div>

                                      <div
                                        className="dropdown mx-3 dropnew dropnew5"
                                        id="dropbtn8"
                                      >
                                        <button
                                          className="p-0 m-0 bg-transparent border-0"
                                          type="button"
                                          id="dropbtn8"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <img src={setting} alt="" />
                                        </button>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end border-0 bg-transparent"
                                          aria-labelledby="dropbtn8"
                                        >
                                          <div className="gradient-box newdrop6">
                                            <div className="position-relative">
                                              <button className="top">
                                                Settings
                                              </button>
                                              <div className="lidiv mt-4">
                                                <img src={icon2} alt="" />
                                                Ambient Mode
                                                <div className="toggle_btn_wrapper bg w-auto ms-auto">
                                                  <div className="form-check form-switch px-0">
                                                    <input
                                                      className="form-check-input d-block float-end"
                                                      id="auto4"
                                                      type="checkbox"
                                                      role="switch"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lidiv">
                                                <img src={icon3} alt="" />
                                                Annotations
                                                <div className="toggle_btn_wrapper bg w-auto ms-auto">
                                                  <div className="form-check form-switch px-0">
                                                    <input
                                                      className="form-check-input d-block float-end"
                                                      id="auto4"
                                                      type="checkbox"
                                                      role="switch"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lidiv">
                                                <img src={icon4} alt="" />
                                                Playback speed
                                                <h6>
                                                  Normal{" "}
                                                  <img
                                                    src={icon9}
                                                    alt=""
                                                    className="me-0"
                                                  />
                                                </h6>
                                              </div>
                                              <div className="lidiv">
                                                <img src={icon5} alt="" />
                                                Substitles/cc (1)
                                                <div className="toggle_btn_wrapper bg w-auto ms-auto">
                                                  <div className="form-check form-switch px-0">
                                                    <input
                                                      className="form-check-input d-block float-end"
                                                      id="auto4"
                                                      type="checkbox"
                                                      role="switch"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lidiv lastdiv">
                                                <img src={icon6} alt="" />
                                                Quality
                                                <h6>
                                                  Auto{" "}
                                                  <img
                                                    src={icon9}
                                                    alt=""
                                                    className="me-0"
                                                  />
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        </ul>
                                      </div>

                                      <Link to="">
                                        <img
                                          src={short}
                                          alt=""
                                          onClick={handleMiniPlayerButtonClick}
                                        />
                                      </Link>

                                      <div className="d-flex">
                                        <Link to="">
                                          <img
                                            src={frame1}
                                            alt=""
                                            onClick={
                                              handleFullScreenButtonClick
                                            }
                                          />
                                        </Link>
                                        <Link to="">
                                          <img
                                            src={full}
                                            alt=""
                                            onClick={
                                              handleFullScreenButtonClick
                                            }
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) 
                         : <div className="position-absolute pb-4 d-inline-table w-100 px-4  bottom-0 start-0 end-0 mx-auto">
                            {/* <img src={playbar} alt="" /> */}
                            {isMiniScreen ? (
                              ""
                            ) : (
                              <div className="  mx-auto mb-3">
                                <div className=" mx-auto mb-3 progressbar">
                                  <div
                                    className={`${"progressinner"} ${
                                      currentTime !== duration
                                        ? "progress-inner inner2"
                                        : ""
                                    } `}
                                    style={{
                                      animationPlayState: isPlaying
                                        ? "running"
                                        : "paused",
                                      animationDuration: isLoading
                                        ? "0s"
                                        : `${videoRef.current.duration}s`,
                                    }}
                                  />
                                </div>{" "}
                              </div>
                            )}
                            <div className="d-flex playable justify-content-between px-4">
                              <div className="d-flex">
                                <Link to="">
                                  <div onClick={handlePlayPause}>
                                    {isPlaying ? (
                                    <img src={vpause} alt="" />
                                    ) : (
                                      <img src={play} alt="" />
                                    )}
                                  </div>
                                </Link>
                                <Link to="">
                                  <img
                                    src={forwad}
                                    alt=""
                                    onClick={handleForwardClick}
                                  />
                                </Link>
                                <Link to="">
                                  <img
                                    src={sound}
                                    alt=""
                                    onClick={toggleVolumeControls}
                                  />
                                </Link>
                              </div>
                              <div className="d-flex justify-content-between">
                                {isMiniScreen ? (
                                  " "
                                ) : (
                                  <div
                                    className="dropdown mx-3 dropnew dropnew4"
                                    id="dropbtn8"
                                  >
                                    <button
                                      className="p-0 m-0 bg-transparent border-0"
                                      type="button"
                                      id="dropbtn8"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <img src={auto} alt="" />
                                    </button>
                                    <ul
                                      className="dropdown-menu dropdown-menu-end border-0 bg-transparent"
                                      aria-labelledby="dropbtn8"
                                    >
                                      <div className="gradient-box newdrop6">
                                        <li>
                                          <h6 className="m-0 d-flex align-items-center justify-content-center">
                                            Auto Play On
                                            <div className="toggle_btn_wrapper bg w-auto ms-2">
                                              <div className="form-check form-switch px-2">
                                                <input
                                                  className="form-check-input d-block float-end"
                                                  id="auto4"
                                                  type="checkbox"
                                                  role="switch"
                                                  checked={autoplay}
                                                  onChange={() =>
                                                    setAutoplay(!autoplay)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </h6>
                                        </li>
                                      </div>
                                    </ul>
                                  </div>
                                )}

                                <Link to="">
                                  <img
                                    src={short}
                                    alt=""
                                    onClick={handleMiniPlayerButtonClick}
                                  />
                                </Link>
                                {isMiniScreen ? (
                                  <div className="d-flex">
                                    <Link to="">
                                      <img
                                        src={frame1}
                                        alt=""
                                        onClick={handleTheaterButtonClick}
                                      />
                                    </Link>
                                    <Link to="">
                                      <img
                                        src={full}
                                        alt=""
                                        onClick={handleFullScreenButtonClick}
                                      />
                                    </Link>
                                  </div>
                                ) : (
                                  <div>
                                    <Link to="">
                                      <img
                                        src={frame1}
                                        alt=""
                                        onClick={handleFullScreenButtonClick}
                                      />
                                    </Link>
                                    <Link to="">
                                      <img
                                        src={full}
                                        alt=""
                                        onClick={handleFullScreenButtonClick}
                                      />
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>}
    </div>
  );
}
