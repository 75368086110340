import React, { useState } from "react";
import ImageBox from "../../../../../global/ImageBox";
import flag from "../../../../../../images/flag.png";
import medal from "../../../../../../images/1.png";
import user from '../../../../../../images/img1.png';
import tag from '../../../../../../images/tag.png';
import stat from '../../../../../../images/status.png';
import img5 from '../../../../../../images/profileview/img39.png';
import RangeSlider from "react-bootstrap-range-slider";
import { useTranslation } from "react-i18next";
import imgstatic from '../../../../../../images/img124.png';
import imgstatic1 from '../../../../../../images/img126.png';

// import { EducationRange } from "../../../../../global/rangeSelect/RangeValues";
// import RangeSlider from "react-bootstrap-range-slider";


export default function SocialScore() {
  const { t, i18n } = useTranslation();

  const [value9, setValue9] = useState(750);
  const [value10, setValue10] = useState(2022);
  const [value11, setValue11] = useState(0);
  return (
    <div className="text-center mb-4 w-100 pt-5">
      <div className="shadow-4 w-100 br-20 pt-4 pb-2 px-4 d-flex justify-content-center align-items-center flex-column">
        <div className="scoreIcon bg rounded-circle">
          <span className="rounded-circle bg">
            <img src={medal} alt="" className="" />
          </span>
        </div>
        <div className="row mx-0">
          <div className="col-md-3 px-0">
            <RangeSlider
              value={value11}
              onChange={(changeEvent) =>
                setValue11(changeEvent.target.value)
              }
              min={0}
              max={10}
            />
          </div>
          <div className="col-md-6 px-0">
          <h4 className="shadow-5in bg px-2 py-15 w-80 h7 z-10 text-new fw-bold d-inline-block mt-2">{t('profile_section._1st_rank')}</h4>
          </div>
          <div className="col-md-3 px-0">
            <RangeSlider
              value={value10}
              onChange={(changeEvent) =>
                setValue10(changeEvent.target.value)
              }
              min={0}
              max={2022}
            />
          </div>

        </div>
        {/* <img src={img5} alt="" className="w-100 mt-n8" /> */}
          <div className="ImgArea m-0 w-100 h-100 mb-0 mt-0">
            <div className="user position-relative mx-auto profileview">
              <ImageBox boxwidth="w-100 userImg" src={user} />
              <div className="tag top-0 position-absolute">
                <ImageBox boxwidth="w-16" src={tag} />
              </div>
              <div className="flag position-absolute">
                <ImageBox boxwidth="w-24" src={flag} />
              </div>
              <div className="status bottom-0 end-0 position-absolute">
                <ImageBox boxwidth="w-24 shadow-4" src={stat} />
              </div>
            </div>
            <h4 className="shadow-5in bg px-2 py-15 w-125 h7 text-new fw-bold d-inline-block mt-2 mb-3">{t('profile_section.dyna_appolon')}</h4>
          </div>
          {/* <RangeSlider
            value={value9}
            onChange={(changeEvent) =>
              setValue9(changeEvent.target.value)
            }
            min={0}
            max={1000}
          /> */}
          <img src={imgstatic1} alt="" className="w-auto mx-auto d-block" />
          <img src={imgstatic} alt="" className="w-auto mx-auto d-block" />
          <h4 className="shadow-5in bg px-2 py-15 my-0 w-125 h7 text-new fw-bold d-inline-block">{t('profile_section.social_score')}</h4>
      </div>
    </div>
  );
}
