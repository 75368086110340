import React from "react";
// import { Link } from 'react-router-dom';
import Select from 'react-select';

import { ReactComponent as IconBlue1 } from "../../../../../../images/icons/icon1.svg";
import { ReactComponent as IconBlue2 } from "../../../../../../images/icons/icon2.svg";
import { ReactComponent as IconBlue3 } from "../../../../../../images/icons/icon30.svg";
import { ReactComponent as IconBlue4 } from "../../../../../../images/icons/icon12.svg";
import { ReactComponent as IconBlue5 } from "../../../../../../images/icons/icon31.svg";
import { ReactComponent as IconBlue6 } from "../../../../../../images/icons/icon6.svg";
import { ReactComponent as IconBlue7 } from "../../../../../../images/icons/icon7.svg";
import { ReactComponent as IconBlue8 } from "../../../../../../images/icons/icon8.svg";
import { ReactComponent as IconBlue9 } from "../../../../../../images/icons/icon33.svg";
import { ReactComponent as IconBlue10 } from "../../../../../../images/icons/icon10.svg";
import { ReactComponent as IconBlue11 } from "../../../../../../images/icons/icon34.svg";
import { ReactComponent as IconBlue12 } from "../../../../../../images/listicon/icon21.svg";
import { ReactComponent as IconBlue13 } from "../../../../../../images/icons/icon32.svg";
import { ReactComponent as IconBlue14 } from "../../../../../../images/icons/icon13.svg";
import { ReactComponent as IconBlue15 } from "../../../../../../images/icons/icon35.svg";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";


export default function SocialPage3() {
  const { t, i18n } = useTranslation();

  const theme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    }
  });
  const options1 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue1/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue1/></span> },
  ];
  const options2 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue2/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue2/></span> },
  ];
  const options3 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue3/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue3/></span> },
  ];
  const options4 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue4/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue4/></span> },
  ];
  const options5 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue5/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue5/></span> },
  ];
  const options6 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue6/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue6/></span> },
  ];
  const options7 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue7/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue7/></span> },
  ];
  const options8 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue8/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue8/></span> },
  ];
  const options9 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue9/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue9/></span> },
  ];
  const options10 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue10/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue10/></span> },
  ];
  const options11 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue11/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue11/></span> },
  ];
  const options12 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue12/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue12/></span> },
  ];
  const options13 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue13/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue13/></span> },
  ];
  const options14 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue14/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue14/></span> },
  ];
  const options15 = [
    { value: 'Blue', label: <span className="text-darks"><IconBlue15/></span> },
    { value: 'Black', label: <span className="text-blue"><IconBlue15/></span> },
  ];
  

  return (
    <div>
      <div className="mt-4">
        <ul>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
              <Select isSearchable={false} options={options1} isSearchable={false} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                  
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue1/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.name')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options2} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                  
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue2/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.job_title_company')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options3} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                  
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue3/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.brand_mission_tagline')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options4} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue4/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.describe_notable_accomplishments')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options5} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue5/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.target_keyword')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options6} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue6/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.branded_hashtags')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options7} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                  
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue7/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.contact_info')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options8} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                  
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue8/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.website_url')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options9} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                  
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue9/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.email_address')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options10} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                  
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue10/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.link')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options11} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                  
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue11/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.shop_link_or_product_link')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options12} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                  
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue12/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.partnership_sponsorship')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options13} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                  
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue13/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.skills_qualifications')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options14} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                  
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue14/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.special_offer_link')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
          <NavItem as="li" className="d-flex align-items-end mb-20">
            <div className="dropdown m-0 w-90s me-3">
            <Select isSearchable={false} options={options15} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'80%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                  
                }}
                defaultValue={{
                  value: 'blue', 
                  label: <span className="text-darks"><IconBlue15/></span>,
                }}  
              />
            </div>
            <div className="w-100">
              <label className="h8 mb-2">{t('profile_section.location')}</label>
              <input placeholder={t('profile_section.placeholder')} className="shadow-5in br-10 w-100 mb-0" type="text" />
            </div>
          </NavItem>
        </ul>
      </div>
    </div>
  );
}
