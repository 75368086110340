import React from "react";
import Video from '../../../../images/iconVid.png';
import Video1 from '../../../../images/iconVid1.png';
import { Link } from 'react-router-dom';
import ButtonBox from '../../../global/ButtonBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import PasswordThree from "./PasswordSingle/PasswordThree";
import PasswordOne from "./PasswordSingle/PasswordOne";
import PasswordTwo from "./PasswordSingle/PasswordTwo";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';

export default function PasswordTab() {
  const { t, i18n } = useTranslation();
  let {isVideoVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };
  return (
    <div>
      <div className="w-780 mb-4 align-items-center mx-auto d-flex flex-column-reverse justify-content-between">     
        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto mb-5">
          <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
          <h3 className="mb-3 d-flex justify-content-between w-600 pe-5">{t('profile_section.enter_your_profile_details')} <small>
            {t('step')} <b>10</b> of 11</small></h3>
          <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="passOne" role="tabpanel" aria-labelledby="passOne">
                <div className="ProgressBar">
                  <ul>
                      <NavItem as="li" className="active" id="profile"></NavItem>
                      <NavItem as="li" className="active" id="chat"></NavItem>
                      <NavItem as="li" className="active" id="location"></NavItem>
                      <NavItem as="li" className="active" id="user"></NavItem>
                      <NavItem as="li" className="active" id="briefcase"></NavItem>
                      <NavItem as="li" className="active" id="graduation-cap"></NavItem>
                      <NavItem as="li" className="active" id="favorite"></NavItem>
                      <NavItem as="li" className="active" id="award"></NavItem>
                      <NavItem as="li" className="active" id="connectivity"></NavItem>
                      <NavItem as="li" className="active" id="lock"></NavItem>
                      <NavItem as="li" id="eye"></NavItem>
                  </ul>
                </div>
                <h3 className="mb-5 mt-2">{t('profile_section.change_password')}</h3>
                <PasswordOne />
              </div>
              <div className="tab-pane fade" id="passTwo" role="tabpanel" aria-labelledby="passTwo">
                <div className="ProgressBar">
                  <ul>
                      <NavItem as="li" className="active" id="profile"></NavItem>
                      <NavItem as="li" className="active" id="chat"></NavItem>
                      <NavItem as="li" className="active" id="location"></NavItem>
                      <NavItem as="li" className="active" id="user"></NavItem>
                      <NavItem as="li" className="active" id="briefcase"></NavItem>
                      <NavItem as="li" className="active" id="graduation-cap"></NavItem>
                      <NavItem as="li" className="active" id="favorite"></NavItem>
                      <NavItem as="li" className="active" id="award"></NavItem>
                      <NavItem as="li" className="active" id="connectivity"></NavItem>
                      <NavItem as="li" className="active" id="lock"></NavItem>
                      <NavItem as="li" id="eye"></NavItem>
                  </ul>
                </div>
                <h3 className="mb-5 mt-2">{t('profile_section.generate_a_secured_Password')}</h3>
                <PasswordTwo />
              </div>
              <div className="tab-pane fade" id="passThree" role="tabpanel" aria-labelledby="passThree">
                <div className="ProgressBar">
                  <ul>
                      <NavItem as="li" className="active" id="profile"></NavItem>
                      <NavItem as="li" className="active" id="chat"></NavItem>
                      <NavItem as="li" className="active" id="location"></NavItem>
                      <NavItem as="li" className="active" id="user"></NavItem>
                      <NavItem as="li" className="active" id="briefcase"></NavItem>
                      <NavItem as="li" className="active" id="graduation-cap"></NavItem>
                      <NavItem as="li" className="active" id="favorite"></NavItem>
                      <NavItem as="li" className="active" id="award"></NavItem>
                      <NavItem as="li" className="active" id="connectivity"></NavItem>
                      <NavItem as="li" className="active" id="lock"></NavItem>
                      <NavItem as="li" id="eye"></NavItem>
                  </ul>
                </div>
                <h3 className="mb-5 mt-2">{t('profile_section.change_password')}</h3>
                <PasswordThree />
              </div>
          </div>
        </div>
        <span className="d-flex align-items-center justify-content-between w-100 mb-4">
          <div id="myTab1" className='' role="tablist">
            <button className="buttonArrow me-3 text-body border-0 bg br-10 active" id="passOne" data-bs-toggle="tab" data-bs-target="#passOne" type="button" role="tab" aria-controls="passOne" aria-selected="true">
              <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
            </button>
            <button className="buttonArrow me-3 text-body passwordTwo border-0 bg br-10" id="passTwo" data-bs-toggle="tab" data-bs-target="#passTwo" type="button" role="tab" aria-controls="passTwo" aria-selected="false">
              <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
            </button>
            <button className="buttonArrow me-3 text-body passwordTwo border-0 bg br-10" id="passThree" data-bs-toggle="tab" data-bs-target="#passThree" type="button" role="tab" aria-controls="passThree" aria-selected="false">
              <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
            </button>
          </div>
          <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="Saved" icon={faFloppyDisk} name="Save" />
        </span>
      </div>
    </div>   
  )
}
