import React from "react";
import img1 from '../../images/banner/banner5.png';
import img2 from '../../images/banner/banner5s.png';
import img3 from '../../images/banner/banner5ss.png';
import img4 from '../../images/banner/banner5sss.png';
import img5 from '../../images/banner/banner5ssss.png';
import img6 from '../../images/banner/sb.png';
import { useState } from 'react';

export default function BannerFive(props) {

  const openSelect1 = () =>{inputRef1.current.click();}
  const inputRef1 = React.useRef();
  const [previewImage1, setPreviewImage1] = useState('');
  const [setImage1] = useState('');
  
  const openSelect2 = () =>{inputRef2.current.click();}
  const inputRef2 = React.useRef();
  const [previewImage2, setPreviewImage2] = useState('');
  const [setImage2] = useState('');
  
  const openSelect3 = () =>{inputRef3.current.click();}
  const inputRef3 = React.useRef();
  const [previewImage3, setPreviewImage3] = useState('');
  const [setImage3] = useState('');
  
  const openSelect4 = () =>{inputRef4.current.click();}
  const inputRef4 = React.useRef();
  const [previewImage4, setPreviewImage4] = useState('');
  const [setImage4] = useState('');

  return (
    <div className={"banner5s " + props.boxwidth}>
      <ul className="d-flex justify-content-between flex-wrap">
        <li>
          <div className="d-block">
            <label onClick={openSelect1} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage1 ? (
                  <img
                    src={previewImage1}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={img1} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage1(url);
                setImage1(e.target.files[0]);
              }}
              ref={inputRef1}
            />
          </div>
        </li>
        <li>
          <div className="d-block ">
            <label onClick={openSelect2} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage2 ? (
                  <img
                    src={previewImage2}
                    alt=""
                    className="w-100 h-100 m-0"
                  />
                ) : (
                  <img src={img2} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage2(url);
                setImage2(e.target.files[0]);
              }}
              ref={inputRef2}
            />
          </div>
        </li>
        <li>
          <div className="d-block">
            <label onClick={openSelect3} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage3 ? (
                  <img
                    src={previewImage3}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={img4} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage3(url);
                setImage3(e.target.files[0]);
              }}
              ref={inputRef3}
            />
          </div>
        </li>
        <li>
          <div className="d-block">
            <label onClick={openSelect4} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage4 ? (
                  <img
                    src={previewImage4}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={img5} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage4(url);
                setImage4(e.target.files[0]);
              }}
              ref={inputRef4}
            />
          </div>
        </li>
      </ul>
      <div className="banner5sinr d-flex flex-column justify-content-center align-items-center">
        <img src={img6} alt="" width="102px" />
        <h5>DYNA APPOLON</h5>
        <h6>SOCIAL NETWORK STRATEGIST</h6>
      </div>
    </div>
  );
}
