import React, { useState } from "react";
// import img4 from '../../../../../images/profileview/img4.png';
// import img5 from '../../../../../images/profileview/img5.png';
// import img6 from '../../../../../images/profileview/img6.png';
// import img7 from '../../../../../images/profileview/img7.png';
// import img8 from '../../../../../images/profileview/img8.png';
import img14 from '../../../../../images/profileview/img14.png';
import img15 from '../../../../../images/profileview/img15.png';
import img16 from '../../../../../images/profileview/img16.png';
import img17 from '../../../../../images/profileview/img17.png';
import user from '../../../../../images/list.png';
// import RangeSlider from "react-bootstrap-range-slider";
import PersonalityZodic from "../../../../global/PersonalityZodic";
import FavouriteMusic from "./ProfileSec/FavouriteMusic";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function ProfileViewOne() {
  const { t, i18n } = useTranslation();

  const [value, setValue] = useState(5);
  return (
    <div>
      {/* <div className="row">
        <div className="col-md-7">
          <div class="slidecontainer mb-5">
            <RangeSlider
              value={value}
              onChange={(changeEvent) =>
                setValue(changeEvent.target.value)
              }
              min={5}
              max={10}
            />
            <h4 className="h7 shadow-5in p-2 br-5 d-inline-block">SOCIAL STATUS</h4>
          </div>
          <div className="row mb-5 text-center mx-0">
            <div className="col-md-4 p-0">
              <img src={img4} alt="" className=" rounded-circle" />
              <input type="range" class="form-range p-0" id="customRange1"></input>
              <h4 className="h7 shadow-5in p-2 br-5 d-inline-block">boy</h4>
            </div>
            <div className="col-md-4 p-0">
              <img src={img6} alt="" className=" rounded-circle" />
              <input type="range" class="form-range p-0" id="customRange1"></input>
              <h4 className="h7 shadow-5in p-2 br-5 d-inline-block">family</h4>
            </div>
            <div className="col-md-4 p-0">
              <img src={img5} alt="" className="rounded-circle" />
              <input type="range" class="form-range p-0" id="customRange1"></input>
              <h4 className="h7 shadow-5in p-2 br-5 d-inline-block">girl</h4>
            </div>
            <div className="col-md-6 ">
              <img src={img8} alt="" className=" rounded-circle" />
              <input type="range" class="form-range p-0" id="customRange1"></input>
              <h4 className="h7 shadow-5in p-2 br-5 d-inline-block">dog</h4>
            </div>
            <div className="col-md-6 ">
              <img src={img8} alt="" className=" rounded-circle" />
              <input type="range" class="form-range p-0" id="customRange1"></input>
              <h4 className="h7 shadow-5in p-2 br-5 d-inline-block">cat</h4>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <img src={img7} className="w-100" alt="" />
        </div>
      </div> */}
      <div className="text-center languageSec mb-4">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.language_skills')}</h4>
        <div className="shadow-4 br-20 pt-5 px-4 d-flex justify-content-center align-items-center flex-column">
          <div className="d-flex w-100 mb-30">
            <button className="shadow-4 border-0 br-5 bg">{t('profile_section.french')}</button>
            <ul className="franch d-flex justify-content-between align-items-center w-100">
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li">
                <div className="range-slider__wrap">
                  <div className="range-slider__tooltip range-slider__tooltip--auto range-slider__tooltip--bottom">
                    <div className="custom-tooltip-label">10</div>
                    <div className="custom-tooltip-btm "></div>
                  </div>
                </div>
              </NavItem>
            </ul>
          </div>
          <div className="d-flex w-100 mb-30">
            <button className="shadow-4 border-0 br-5 bg">{t('profile_section.english')}</button>
            <ul className="english d-flex justify-content-between align-items-center w-100">
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li">
              <div className="range-slider__wrap">
                  <div className="range-slider__tooltip range-slider__tooltip--auto range-slider__tooltip--bottom">
                    <div className="custom-tooltip-label">9</div>
                    <div className="custom-tooltip-btm "></div>
                  </div>
                </div>
              </NavItem>
              <NavItem as="li"></NavItem>
            </ul>
          </div>
          <div className="d-flex w-100 mb-30">
            <button className="shadow-4 border-0 bg br-5">{t('profile_section.spanish')}</button>
            <ul className="spanish d-flex justify-content-between align-items-center w-100">
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li">
              <div className="range-slider__wrap">
                  <div className="range-slider__tooltip range-slider__tooltip--auto range-slider__tooltip--bottom">
                    <div className="custom-tooltip-label">5</div>
                    <div className="custom-tooltip-btm "></div>
                  </div>
                </div>
              </NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
            </ul>
          </div>
          <div className="d-flex w-100 mb-30">
            <button className="shadow-4 border-0 bg br-5">{t('profile_section.madarin')}</button>
            <ul className="madarin d-flex justify-content-between align-items-center w-100">
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li">
              <div className="range-slider__wrap">
                  <div className="range-slider__tooltip range-slider__tooltip--auto range-slider__tooltip--bottom">
                    <div className="custom-tooltip-label">4</div>
                    <div className="custom-tooltip-btm "></div>
                  </div>
                </div>
              </NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center mb-4">
        <h4 className="shadow-5in bg px-2 py-2 h8 rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.personality')}</h4>
        <div className="shadow-4 br-30 p-30 text-center">
          <PersonalityZodic Heading1="Introvert" Heading2="Extrovert" name="extr" checked7="checked" />
          <PersonalityZodic Heading1="Submissive" Heading2="Dominant" name="domi" checked9="checked" />
          <PersonalityZodic Heading1="Realism" Heading2="Idealism" name="ideal" checked9="checked" />
          <PersonalityZodic Heading1="Analytical" Heading2="Intuitive" name="intu" checked6="checked" />
          <PersonalityZodic Heading1="Cautious" Heading2="Risk Taker" name="risk" checked9="checked" />
          <PersonalityZodic Heading1="Reactive" Heading2="Proactive" name="proac" checked10="checked" />
          <PersonalityZodic Heading1="Avoid Stress" Heading2="Thrives On Stress" name="stress" checked6="checked" />
          <PersonalityZodic Heading1="Inflexible" Heading2="Adaptable" name="adpt" checked9="checked" />
          <PersonalityZodic Heading1="Ordinary" Heading2="Extraordinary" name="extra" checked10="checked" />
          <PersonalityZodic Heading1="Skeptic" Heading2="Promoter" name="prom" checked5="checked" />
          <PersonalityZodic Heading1="Indecisive" Heading2="Decisive" name="decisi" checked10="checked" />
        </div>
      </div>
      <div className="text-center mb-4">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.hobby_ies')}</h4>
        <div className="shadow-4 br-20 py-4 px-4 d-flex justify-content-center align-items-center flex-column">
          <ul className="row d-flex flex-wrap mt-4 w-100 py-0 px-2 m-0 justify-content-between align-items-center">
            <NavItem as="li" className="col-md-6">
              <div className="shadow-4 mb-3 h-40 br-5 px-2 d-flex justify-content-between align-items-center">
              {t('profile_section.fashion_venture')} <span className="circleColor ms-2 colorOne"></span>
              </div>
            </NavItem>
            <NavItem as="li" className="col-md-6">
              <div className="shadow-4 mb-3 h-40 br-5 px-2 d-flex justify-content-between align-items-center">
              {t('profile_section.stock_trading')} <span className="circleColor ms-2 colorTwo"></span>
              </div>
            </NavItem>
            <NavItem as="li" className="col-md-6">
              <div className="shadow-4 mb-3 h-40 br-5 px-2 d-flex justify-content-between align-items-center">
              {t('profile_section.boat_ride')} <span className="circleColor ms-2 colorThree"></span>
              </div>
            </NavItem>
            <NavItem as="li" className="col-md-6">
              <div className="shadow-4 mb-3 h-40 br-5 px-2 d-flex justify-content-between align-items-center">
              {t('profile_section.motorcycle_ride')} <span className="circleColor ms-2 colorFour"></span>
              </div>
            </NavItem>
            <NavItem as="li" className="col-md-6">
              <div className="shadow-4 mb-3 h-40 br-5 px-2 d-flex justify-content-between align-items-center">
              {t('profile_section.equestrian')} <span className="circleColor ms-2 colorFive"></span>
              </div>
            </NavItem>
            <NavItem as="li" className="col-md-6">
              <div className="shadow-4 mb-3 h-40 br-5 px-2 d-flex justify-content-between align-items-center">
              {t('profile_section.helipcopter_ride')} <span className="circleColor ms-2 colorSix"></span>
              </div>
            </NavItem>
            <NavItem as="li" className="col-md-6">
              <div className="shadow-4 mb-3 h-40 br-5 px-2 d-flex justify-content-between align-items-center">
              {t('profile_section.guitar_violin')} <span className="circleColor ms-2 colorSeven"></span>
              </div>
            </NavItem>
            <NavItem as="li" className="col-md-6">
              <div className="shadow-4 mb-3 h-40 br-5 px-2 d-flex justify-content-between align-items-center">
              {t('profile_section.latin_dance')} <span className="circleColor ms-2 colorEight"></span>
              </div>
            </NavItem>
            <NavItem as="li" className="col-md-6">
              <div className="shadow-4 mb-3 h-40 br-5 px-2 d-flex justify-content-between align-items-center">
              {t('profile_section.chess')} <span className="circleColor ms-2 colorNine"></span>
              </div>
            </NavItem>
            <NavItem as="li" className="col-md-6">
              <div className="shadow-4 mb-3 h-40 br-5 px-2 d-flex justify-content-between align-items-center">
              {t('profile_section.home_decor')} <span className="circleColor ms-2 colorTen"></span>
              </div>
            </NavItem>
          </ul>
          <img src={img14} alt="" height="280" />
        </div>
      </div>
      <FavouriteMusic />
      <div className="text-center movieSec mb-4">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 d-inline-block headingMinus"> 
        {t('profile_section.favourite_movie')}</h4>
        <div className="shadow-4 br-20 p-6 flex-wrap d-flex justify-content-center align-items-center flex-column">
          <img src={img15} alt="" width="185" height="140" />
          <p> {t('profile_section.my_cousin_vinny')}</p>
          <p>{t('profile_section.two_can_play_that_game')}</p> 
          <p>{t('profile_section.the_bodyguard')}</p>
          <ul className="w-100 row mt-3 pt-3">
            <NavItem as="li" className="w-100 mb-2">
              <h4 className="shadow-5in bg px-2 py-2 h7 fw-bold text-uppercase rounded-1 d-inline-block">
                {t('profile_section.favourite_actor')}</h4>
            </NavItem>
            <NavItem as="li" className="w-50 p-0 d-flex justify-content-center align-items-center flex-column">
              <img src={img17} alt="" width="85" height="85" className="rounded-circle" />
              <h4 className="shadow-5in bg px-2 py-2 h8 rounded-1 d-inline-block">{t('profile_section.gabrielle_union')}</h4>
            </NavItem>
            <NavItem as="li" className="w-50 p-0 d-flex justify-content-center align-items-center flex-column">
              <img src={img16} alt="" width="85" height="85" className="rounded-circle" />
              <h4 className="shadow-5in bg px-2 py-2 h8 rounded-1 d-inline-block">{t('profile_section.kevin_costner')}</h4>
            </NavItem>
          </ul>
        </div>
      </div>
      <div className="text-center graphSec profiegrap mb-4">
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 d-inline-block headingMinus">
          {t('profile_section.favourite_color')} </h4>
        <div className="shadow-4 h-235 br-20 py-4 px-4 flex-wrap d-flex justify-content-center align-items-center flex-column">
          <div className="row w-100 py-3">
            <div className="col-md-7 text-center">
              <img src={user} alt="" width="200" height="200" />
            </div>
            <div className="col-md-5">
              <ul className="pt-3 m-0 w-100">
                <NavItem as="li" className=""><span></span> {t('profile_section.baby_pink')}
                </NavItem>
                <NavItem as="li" className=""><span></span> {t('profile_section.silver')}
                </NavItem>
                <NavItem as="li" className=""><span></span> {t('profile_section.gold')}
                </NavItem>
                <NavItem as="li" className=""><span></span> {t('profile_section.black')}
                </NavItem>
                <NavItem as="li" className=""><span></span> {t('profile_section.nude')}
                </NavItem>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
