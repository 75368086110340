import React, { useState } from "react";
import img5 from '../../../../../../images/StepZero/img13s.png';
import img6 from '../../../../../../images/StepZero/img17.png';
import img7 from '../../../../../../images/StepZero/img18.png';
import img1s from '../../../../../../images/triangle1.png';
import img2s from '../../../../../../images/close1.png';
import img3s from '../../../../../../images/StepZero/img28.png';
import img5s from '../../../../../../images/StepZero/img29.png';
import img6s from '../../../../../../images/StepZero/img30.png';
import ButtonBox from "../../../../../global/ButtonBox";
import TabComponentMonth from "../../../../../global/TabComponentMonth";
import RangeSlider from "react-bootstrap-range-slider";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../../../redux/actions/videoAction';
import { ReactComponent as IconBlue19 } from "../../../../../../images/audio/coolicon.svg";
import Video from '../../../../../../images/iconVid.png';
import Video1 from '../../../../../../images/iconVid1.png';
export default function NicheOneSec() {
  const { t } = useTranslation();
  const handleClick2 = () => {
    setToggle2(!toggle2);
  };
  const [toggle2, setToggle2] = useState("true");

  let {isVideoVisible} = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };

  const [value, setValue] = useState(20);
  const [value1, setValue1] = useState("2023");
  const [firstDigit, setFirstDigit] = useState("2");
  const [secondDigit, setSecondDigit] = useState("3");
 
   


  const openSelect = () => { inputRef1.current.click(); }
  const inputRef1 = React.useRef();
  const [previewImage1, setPreviewImage1] = useState('');
  const [image1, setImage1] = useState('');
  return (
    <div className="col-lg-12 px-0 mx-auto">
      <div className="formleft d-flex h-300 textgold">
        <div className="d-flex justify-content-center px-3">
          <h4 className="text-start">2</h4>
          {previewImage1 ? 
            <img src={previewImage1} alt="Profile Picture" width="170px" height="170px" className="coverimg rounded-circle mt-5 mx-3" />
             :
            <img src={img6} alt="Profile Picture" width="170px" height="170px" className="coverimg rounded-circle mt-5 mx-3" />
          }
          <h4 className="text-start mt-auto mb-3">{firstDigit}</h4>
        </div>
        <div className="d-flex justify-content-between flex-column w-135">
          <img src={img5} alt="" height="140px" />
          {/* <img src={img7} alt="" height="70px" width="50px" className="mt-auto" /> */}
          <h4 className="text-start mb-0 mt-auto ps-3 line-80">{secondDigit}</h4>
        </div>
      </div>
      <span onClick={handleClick2} className="cursor-pointer"><img src={img1s} alt="" width="40px" height="20px" /></span>
      {toggle2 ? (
        <></>
      ) : (
      <div className="formleft1 flex-column factorTab1s position-relative mt-4">
        <div className="text-end d-flex">
            <div className="VideoIcon ms-auto" onClick={handleClick6}>
              <img src={Video} className="lightImg" width="130px" height="80px" alt="" />
              <img src={Video1} className="darkImg" width="130px" height="80px" alt="" />
            </div>
            <img src={img2s} alt="" width="40px" height="52px" className="mt-87 cursor-pointer me-3 position-absolute end-0 top-0"  onClick={handleClick2}/>
          </div>
        <div className="text-end d-flex justify-content-between">
          <img src={img3s} alt="" className=""/>
        </div>
        <div className="w-100 d-flex flex-column mt-3">
          <div className="d-none d-lg-block  px-2">
            <div className="scroll-bar3 shadow-4in br-20 my-2"></div>
          </div>
          <TabComponentMonth />
          <div className="mt-4 d-flex align-items-center pe-2">
            <RangeSlider
              value={value}
              onChange={(changeEvent) =>
                setValue(changeEvent.target.value)
              }
              min={1}
              max={31}
            />
            <img src={img5s} alt="" className=""/>
          </div>
          <div className="mt-1 d-flex align-items-center pe-2">
            <RangeSlider
              value={value1}
              onChange={(changeEvent) =>{

                setValue1(changeEvent.target.value)
               setFirstDigit(changeEvent.target.value?.charAt(2));
              setSecondDigit(changeEvent.target.value?.charAt(3));
              }

              }
              min={2000}
              max={2099}
            />
            <img src={img6s} alt="" className=""/>
          </div>
          <div className="announcment mt-4">
            <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
              <IconBlue19 />
              <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="newcircle shadow-5in py-1 px-2 w-auto br-10 my-2" />
              <div className="upload-btn-wrapper" onClick={openSelect}>
                <button className="btns1 bg shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
                <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage1(url);
                setImage1(e.target.files[0])
              }} ref={inputRef1} />
              </div>
              <p className="h7 fw-semibold mt-2">200 X 200 Pixels</p>
            </div>
          </div>
          <div className='btnsec d-flex justify-content-center w-100 my-5'>
            <ButtonBox
              boxwidth="w-110 bg-theme mt-0 mb-0 me-5"
              clicked="Cancel"
              name="Cancel"
            />
            <ButtonBox
              boxwidth="w-110  mt-0 mb-0"
              clicked="Saved"
              name="Save"
            />
          </div>
        </div>
      </div>
      )}
    </div>
  )
}
