import React from "react";
import { useState } from "react";

import EventsSlider2 from "../components/EventsSlider2/EventsSlider2"
import Calendar from 'react-calendar'
import EventsTimeline2 from '../components/EventsTimeline2/EventsTimeline2'
import "../regularTheme2Desktop/RegularTheme2Desktop.scss";
import "../regularTheme2Mobile/RegularTheme2Mobile.scss";
import "./RegularTheme2Tab.scss";


export default function RegularTheme2Desktop({ Regular, Neumorphic }) {
  const [value, onChange] = useState(new Date());

  return (
    <>
      <div
        className="main-content-tab eventsTab EventRegularTheme2Tab"
        style={{ backgroundColor: Regular ? "#cbd5e0" : "" }}
      >

        <div className="text-center-desk">
          <p className="top_heading">MY EVENTS</p>
        </div>

        <div className='container  mb-4 pb-3 mt-5 mt-lg-0'>
          <div className="row">
            <div className="col-7 mx-auto">
              <div className="">
                <Calendar className="mx-auto" onChange={onChange} value={value} />
              </div>
            </div>
          </div>
          <div className=" mt-4">
            <div className="row">
              <div className="col-4 flex justify-content-center">
                <div className="eventTab2 mt-5">
                  <div id="myTab2" className='slidetab2' role="tablist">
                    <EventsSlider2 Regular={Regular} Neumorphic={Neumorphic} />
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div>
                  <EventsTimeline2 />
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </>
  );
}
