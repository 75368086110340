import React from 'react';
import '../../../global/timelineTab/TimelineTab.scss';
import gold_star from '../../../../images/gold-star.png';
import message_gold from '../../../../images/msg-gold.png'
import rank_gold from '../../../../images/rank-gold.png'
import cap_gold from '../../../../images/cap-gold.png'
import ButtonBox from "../../../global/ButtonBox";
import { Link } from 'react-router-dom';
import { ReactComponent as IconBlue15 } from "../../../../images/listicon/icon18.svg";
import { ReactComponent as IconBlue7q } from "../../../../images/svgIcon/icon24.svg";

import { ReactComponent as IconBlue6 } from "../../../../images/icons/icon6.svg";

import { ReactComponent as IconBlue16 } from "../../../../images/listicon/icon19.svg";
import { ReactComponent as IconBlue6q } from "../../../../images/svgIcon/icon23.svg";
import Select from 'react-select';
import { ReactComponent as IconBlue4 } from "../../../../images/listicon/icon4.svg";

import { ReactComponent as IconBlue17 } from "../../../../images/listicon/icon20.svg";

import RangeSlider from "react-bootstrap-range-slider";
import { useState } from 'react';
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";


export default function UserProfileForm() {

  const options6 = [
    { value: 'Blue', label: <span className="text-blue"><IconBlue6/></span> },
    { value: 'Black', label: <span className="text-darks"><IconBlue6/></span> },
  ];



  const openSelects1 = () => { inputRefs1.current.click(); }
  const inputRefs1 = React.useRef();
  const [previewImages1, setPreviewImages1] = useState('');
  const [images1, setImages1] = useState('');
  const openSelects2 = () => { inputRefs2.current.click(); }
  const inputRefs2 = React.useRef();
  const [previewImages2, setPreviewImages2] = useState('');
  const [images2, setImages2] = useState('');
  const openSelects3 = () => { inputRefs3.current.click(); }
  const inputRefs3 = React.useRef();
  const [previewImages3, setPreviewImages3] = useState('');
  const [images3, setImages3] = useState('');

  const openSelects4 = () => { inputRefs4.current.click(); }
  const inputRefs4 = React.useRef();
  const [previewImages4, setPreviewImages4] = useState('');
  const [images4, setImages4] = useState('');

  const openSelects5 = () => { inputRefs5.current.click(); }
  const inputRefs5 = React.useRef();
  const [previewImages5, setPreviewImages5] = useState('');
  const [images5, setImages5] = useState('');

  const openSelects6 = () => { inputRefs6.current.click(); }
  const inputRefs6 = React.useRef();
  const [previewImages6, setPreviewImages6] = useState('');
  const [images6, setImages6] = useState('');

  const openSelects7 = () => { inputRefs7.current.click(); }
  const inputRefs7 = React.useRef();
  const [previewImages7, setPreviewImages7] = useState('');
  const [images7, setImages7] = useState('');

  const openSelects8 = () => { inputRefs8.current.click(); }
  const inputRefs8 = React.useRef();
  const [previewImages8, setPreviewImages8] = useState('');
  const [images8, setImages8] = useState('');

  const openSelects9 = () => { inputRefs9.current.click(); }
  const inputRefs9 = React.useRef();
  const [previewImages9, setPreviewImages9] = useState('');
  const [images9, setImages9] = useState('');

  const openSelects10 = () => { inputRefs10.current.click(); }
  const inputRefs10 = React.useRef();
  const [previewImages10, setPreviewImages10] = useState('');
  const [images10, setImages10] = useState('');

  const openSelects11 = () => { inputRefs11.current.click(); }
  const inputRefs11 = React.useRef();
  const [previewImages11, setPreviewImages11] = useState('');
  const [images11, setImages11] = useState('');

  const openSelects12 = () => { inputRefs12.current.click(); }
  const inputRefs12 = React.useRef();
  const [previewImages12, setPreviewImages12] = useState('');
  const [images12, setImages12] = useState('');


  

  const openSelect = () => { inputRef1.current.click(); }
  const inputRef1 = React.useRef();
  const openSelect2 = () => { inputRef2.current.click(); }
  const inputRef2 = React.useRef();
  const openSelect3 = () => { inputRef3.current.click(); }
  const inputRef3 = React.useRef();
  const openSelect4 = () => { inputRef4.current.click(); }
  const inputRef4 = React.useRef();
  const openSelect5 = () => { inputRef5.current.click(); }
  const inputRef5 = React.useRef();
  const openSelect6 = () => { inputRef6.current.click(); }
  const inputRef6 = React.useRef();
  const openSelect7 = () => { inputRef7.current.click(); }
  const inputRef7 = React.useRef();
  const openSelect8 = () => { inputRef8.current.click(); }
  const inputRef8 = React.useRef();
  const openSelect9 = () => { inputRef9.current.click(); }
  const inputRef9 = React.useRef();
  const openSelect10 = () => { inputRef10.current.click(); }
  const inputRef10 = React.useRef();
  const openSelect11 = () => { inputRef11.current.click(); }
  const inputRef11 = React.useRef();
  const openSelect12 = () => { inputRef12.current.click(); }
  const inputRef12 = React.useRef();
  const openSelect13 = () => { inputRef13.current.click(); }
  const inputRef13 = React.useRef();
  const openSelect14 = () => { inputRef14.current.click(); }
  const inputRef14 = React.useRef();
  const openSelect15 = () => { inputRef15.current.click(); }
  const inputRef15 = React.useRef();
  const openSelect16 = () => { inputRef16.current.click(); }
  const inputRef16 = React.useRef();
  const openSelect17 = () => { inputRef17.current.click(); }
  const inputRef17 = React.useRef();
  const openSelect18 = () => { inputRef18.current.click(); }
  const inputRef18 = React.useRef();
  const openSelect19 = () => { inputRef19.current.click(); }
  const inputRef19 = React.useRef();

  const [previewImage1, setPreviewImage1] = useState('');
  const [previewImage2, setPreviewImage2] = useState('');
  const [previewImage3, setPreviewImage3] = useState('');
  const [previewImage4, setPreviewImage4] = useState('');
  const [previewImage5, setPreviewImage5] = useState('');
  const [previewImage6, setPreviewImage6] = useState('');
  const [previewImage7, setPreviewImage7] = useState('');
  const [previewImage8, setPreviewImage8] = useState('');
  const [previewImage9, setPreviewImage9] = useState('');
  const [previewImage10, setPreviewImage10] = useState('');
  const [previewImage11, setPreviewImage11] = useState('');
  const [previewImage12, setPreviewImage12] = useState('');
  const [previewImage13, setPreviewImage13] = useState('');
  const [previewImage14, setPreviewImage14] = useState('');
  const [previewImage15, setPreviewImage15] = useState('');
  const [previewImage16, setPreviewImage16] = useState('');
  const [previewImage17, setPreviewImage17] = useState('');
  const [previewImage18, setPreviewImage18] = useState('');
  const [previewImage19, setPreviewImage19] = useState('');

  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [image4, setImage4] = useState('');
  const [image5, setImage5] = useState('');
  const [image6, setImage6] = useState('');
  const [image7, setImage7] = useState('');
  const [image8, setImage8] = useState('');
  const [image9, setImage9] = useState('');
  const [image10, setImage10] = useState('');
  const [image11, setImage11] = useState('');
  const [image12, setImage12] = useState('');
  const [image13, setImage13] = useState('');
  const [image14, setImage14] = useState('');
  const [image15, setImage15] = useState('');
  const [image16, setImage16] = useState('');
  const [image17, setImage17] = useState('');
  const [image18, setImage18] = useState('');
  const [image19, setImage19] = useState('');



  const [value, setValue] = useState(5);
  const [value1, setValue1] = useState(7);
  const { t, i18n } = useTranslation();
  const theme = theme => ({

    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    }
  });

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]
  const options2 = [
    { value: '-None-', label: <span className=""> -None-</span> },
    { value: 'She/Her', label: <span className=""><IconBlue15 className="text-blue" /> She/Her</span> },
    { value: 'He/Him', label: <span className=""><IconBlue16 className="text-blue" /> He/Him</span> },
    { value: 'They/Them', label: <span className=""><IconBlue17 className="text-blue" /> They/Them</span> },
  ]

  const [open, setOpen] = useState(false);

  return (
    <div className='user-form-oter'>
      <div className='text-border-pr'>
      </div>
      <div className="text-center mb-5 form-text-btn">
        <h5>HERO SECTION FORM</h5>
      </div>


      {/* <div className="text-center mb-5">
        <h5>{t('profile_section.your_basic_business_info')}</h5>
      </div> */}
      <div className='form-oter'>
        <div className='form-line'>

        </div>
        <div className='form-inner '>
          <div className="row customCol">
            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className="col-lg-3 d-flex">

              <label className=" mb-2">{t('profile_section.first_name')}</label>
              <div className='inpt-boxx'>
                <input
                  placeholder={t('profile_section.first_name')}
                  className=" shadow-5in br-10 w-100 mb-4"
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>


            </div>
            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className="col-lg-3 d-flex">

              <label className=" mb-2">{t('profile_section.last_name')}</label>
              <div className='inpt-boxx'>
                <input
                  placeholder={t('profile_section.last_name')}
                  className="shadow-5in br-10 w-100 mb-4"
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>

            </div>
            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className="col-lg-3 d-flex">

              <label className=" mb-2">Preferred Name</label>
              <div className='inpt-boxx'>
                <input
                  placeholder={t('profile_section.first_name')}
                  className="shadow-5in br-10 w-100 mb-4 "
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>

            </div>

            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className=" col-lg-5 d-flex">
              <label className="mb-2">Business Name</label>
              <div className='inpt-boxx width-box'>
                <input
                  placeholder={t('profile_section.business_name_here')}
                  className="shadow-5in br-10 w-100 mb-4"
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>
            </div>
            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className=" col-lg-5 d-flex">
              <label className="mb-2">Company Name</label>
              <div className='inpt-boxx width-box'>
                <input
                  placeholder={t('profile_section.business_name_here')}
                  className="shadow-5in br-10 w-100 mb-4"
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>
            </div>


            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className=" col-lg-11 p-0 d-flex">
              <label className="mb-2">{t('profile_section.tagline_slogan')}</label>
              <div className='inpt-boxx width-boxx'>
                <input
                  placeholder={t('profile_section.your_tagline_here')}
                  className="shadow-5in br-10 w-100 mb-4"
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>
            </div>





          </div>
          <div className='row socail-lins'>
            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className='col-lg-11'>
              <div className="text-center mb-5 form-text-btn">
                <h5>SOCIAL MEDIA</h5>
              </div>
            </div>


          </div>
          <div className="row social-links">
            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className="col-lg-3 d-flex">

              <label className=" mb-2">Facebook</label>
              <div className='inpt-boxx'>
                <input
                  placeholder="Enter Link"
                  className=" shadow-5in br-10 w-100 mb-4"
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>


            </div>
            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className="col-lg-3 d-flex">

              <label className=" mb-2">Instagram</label>
              <div className='inpt-boxx'>
                <input
                  placeholder="Enter Link"
                  className="shadow-5in br-10 w-100 mb-4"
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>

            </div>
            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className="col-lg-3 d-flex">

              <label className=" mb-2">Twitter</label>
              <div className='inpt-boxx'>
                <input
                  placeholder="Enter Link"
                  className="shadow-5in br-10 w-100 mb-4 "
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>

            </div>

            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className="col-lg-3 d-flex">

              <label className=" mb-2">Pinterest</label>
              <div className='inpt-boxx'>
                <input
                  placeholder="Enter Link"
                  className=" shadow-5in br-10 w-100 mb-4"
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>


            </div>
            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className="col-lg-3 d-flex">

              <label className=" mb-2">Youtube</label>
              <div className='inpt-boxx'>
                <input
                  placeholder="Enter Link"
                  className="shadow-5in br-10 w-100 mb-4"
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>

            </div>
            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className="col-lg-3 d-flex">

              <label className=" mb-2">Linkedin</label>
              <div className='inpt-boxx'>
                <input
                  placeholder="Enter Link"
                  className="shadow-5in br-10 w-100 mb-4 "
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>

            </div>
            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className="col-lg-3 d-flex">

              <label className=" mb-2">Tik Tok</label>
              <div className='inpt-boxx'>
                <input
                  placeholder="Enter Link"
                  className=" shadow-5in br-10 w-100 mb-4"
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>


            </div>
            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className="col-lg-3 d-flex">

              <label className=" mb-2">WhatsApp</label>
              <div className='inpt-boxx'>
                <input
                  placeholder="Enter Link"
                  className="shadow-5in br-10 w-100 mb-4"
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>

            </div>
            <div className='col-lg-1'>
              <div className='circle-line'>
                <div className='circle'>

                </div>
                <div className='linee'>

                </div>
              </div>

            </div>
            <div className="col-lg-3 d-flex">

              <label className=" mb-2">1000 Socialite</label>
              <div className='inpt-boxx'>
                <input
                  placeholder="Enter Link"
                  className="shadow-5in br-10 w-100 mb-4 "
                  type="text"
                />
                <div className='boxx'>

                </div>
              </div>

            </div>




          </div>
          <div className='box-white mt-4 mb-4'>
            <div className='boxxx'>

            </div>
            <div className='row socail-lins'>
              <div className='col-lg-1'>
                <div className='circle-line'>
                  <div className='circle'>

                  </div>
                  <div className='linee'>

                  </div>
                </div>

              </div>
              <div className='col-lg-11'>
                <div className="text-center mb-5 form-text-btn">
                  <h5>UPLOAD IMAGES</h5>
                </div>
              </div>


            </div>
            <div className="uploadSec mb-4 ">

              <ul className="d-flex flex-wrap">
                <NavItem as="li">
                  <div className="col-lg-3 px-2 mt-5 text-center mb-4">
                    <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
                      <IconBlue15 />
                      <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10 my-2" />
                      <div className="upload-btn-wrapper">
                        <button className="btns shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
                        <input type="file" name="myfile" />
                      </div>
                      <p className="h7 fw-semibold mt-2">{t('profile_section.pixels_1024_X_1024_pixels')}</p>
                    </div>

                  </div>
                  {/* <div className="imgView br-20 bg mb-2">
              <div className='mx-auto mb-4 fileTab h-100'>
                <label onClick={openSelect} className="w-100 h-100">
                  <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                    {previewImage1 ? <img src={previewImage1} alt="Profile Picture" className="w-100 h-100" /> :
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <svg width="20" height="24" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.158 6.84211C4.158 3.06332 7.22131 0 11.0001 0C14.7789 0 17.8422 3.06332 17.8422 6.84211C17.8422 10.6209 14.7789 13.6842 11.0001 13.6842C7.22131 13.6842 4.158 10.6209 4.158 6.84211ZM11.0001 10.9474C13.2674 10.9474 15.1054 9.10938 15.1054 6.84211C15.1054 4.57483 13.2674 2.73684 11.0001 2.73684C8.73283 2.73684 6.89484 4.57483 6.89484 6.84211C6.89484 9.10938 8.73283 10.9474 11.0001 10.9474Z" fill="#4E4E57" />
                          <path d="M3.25914 18.259C1.20611 20.3121 0.0527344 23.0966 0.0527344 26H2.78958C2.78958 23.8224 3.65461 21.7341 5.19438 20.1943C6.73416 18.6545 8.82254 17.7895 11.0001 17.7895C13.1777 17.7895 15.2661 18.6545 16.8058 20.1943C18.3456 21.7341 19.2106 23.8224 19.2106 26H21.9475C21.9475 23.0966 20.7941 20.3121 18.7411 18.259C16.688 16.206 13.9035 15.0526 11.0001 15.0526C8.09668 15.0526 5.31217 16.206 3.25914 18.259Z" fill="#4E4E57" />
                        </svg>
                        <p className="mb-0 mt-1">{t('profile_section.change_profile_image')}</p>
                        <p className="fw-bold mb-2">{t('profile_section.Pixels_200_x_200_Pixels')}</p>
                        <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in h-44 br-10" />
                      </div>
                    }
                  </div>
                </label>
              </div>
              <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0])
                setPreviewImage1(url);
                setImage1(e.target.files[0])
              }} ref={inputRef1} />
            </div> */}
                  <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in mt-5 h-44 br-30" />
                </NavItem>
                <NavItem as="li" >
                  <div className="imgView br-20 bg  mb-2">
                    <div className='mx-auto mb-4 fileTab h-100'>
                      <label onClick={openSelect2} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                          {previewImage2 ? <img src={previewImage2} alt="Profile Picture" className="w-100 h-100" /> :
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <IconBlue7q />
                              <p className="mb-0 mt-1">{t('profile_section.change_banner_image')}</p>
                              <p className="fw-bold mb-2">{t('profile_section.pixels_1000_X_400_pixels')}</p>
                            </div>
                          }
                        </div>
                      </label>
                    </div>
                    <input className='mt-5' hidden type="file" name="profile" id="profile" accept="image/*" onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0])
                      setPreviewImage2(url);
                      setImage2(e.target.files[0])
                    }} ref={inputRef2} />
                  </div>
                  <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10" />
                </NavItem>
                <NavItem as="li">
                  <div className="imgView br-20 bg mb-2">
                    <div className='mx-auto mb-4 fileTab h-100'>
                      <label onClick={openSelect3} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                          {previewImage3 ? <img src={previewImage3} alt="Profile Picture" className="w-100 h-100" /> :
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <IconBlue6q />
                              <p className="mb-0 mt-1">{t('profile_section.change_business_logo')}</p>
                              <p className="fw-bold mb-2">{t('profile_section.pixels_600_X_600_pixels')}</p>
                              \                      </div>
                          }
                        </div>
                      </label>
                    </div>
                    <input hidden type="file" name="profile" id="profile" accept="image/*" onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0])
                      setPreviewImage3(url);
                      setImage3(e.target.files[0])
                    }} ref={inputRef3} />
                  </div>
                  <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10" />
                </NavItem>
                <NavItem as="li">
                  <div className="imgView br-20 bg mb-2">
                    <div className='mx-auto mb-4 fileTab h-100'>
                      <label onClick={openSelect4} className="w-100 h-100">
                        <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                          {previewImage4 ? <img src={previewImage4} alt="Profile Picture" className="w-100 h-100" /> :
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 24.5C2.32181 24.5 0 22.1782 0 12.5C0 2.82181 2.32181 0.5 12 0.5C21.6782 0.5 24 2.82181 24 12.5C24 22.1782 21.6782 24.5 12 24.5ZM8.60287 6.43887L4.83909 14.9929C4.668 15.4084 4.52138 15.946 4.52138 16.4104C4.52138 18.5367 6.01219 20.0276 8.13844 20.0276C8.83528 20.0276 9.41391 19.8445 10.0022 19.6584L10.0027 19.6581C10.611 19.4656 11.2298 19.2699 12 19.2699C12.7793 19.2699 13.3832 19.4639 13.9798 19.6556L13.98 19.6557C14.5622 19.8427 15.1373 20.0276 15.8615 20.0276C17.9877 20.0276 19.4786 18.5367 19.4786 16.4104C19.4786 15.946 19.3319 15.4084 19.1609 14.9929L15.3972 6.43887C14.7616 4.997 13.5397 4.21484 12 4.21484C10.4603 4.21484 9.23831 4.99691 8.60287 6.43887V6.43887ZM8.28506 16.3371L12 7.92978L15.666 16.3371C14.6151 15.8239 13.2953 15.5795 12 15.5795C10.6558 15.5795 9.38494 15.8239 8.28516 16.3371H8.28506Z" fill="#4E4E57" />
                              </svg>
                              <p className="mb-0 mt-0">{t('profile_section.change_favicon')}</p>
                              <p className="small mb-0 lh-sm">{t('profile_section.url_Icon_logo')}</p>
                              <p className="fw-bold mb-2">{t('profile_section.pixels_16_X_16_pixels')}</p>
                              \                      </div>
                          }
                        </div>
                      </label>
                    </div>
                    <input hidden type="file" name="profile" id="profile" accept="image/*" onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0])
                      setPreviewImage4(url);
                      setImage4(e.target.files[0])
                    }} ref={inputRef4} />
                  </div>
                  <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10" />
                </NavItem>
              </ul>
            </div>
          </div>
          <div className='box-white mt-5 mb-5'>
            <div className='boxxx'>

            </div>
            <div className='row socail-lins'>
              <div className='col-lg-1'>
                <div className='circle-line'>
                  <div className='circle'>

                  </div>
                  <div className='linee'>

                  </div>
                </div>

              </div>
              <div className='col-lg-11'>
                <div className="text-center mb-5 form-text-btn">
                  <h5>ABOUT ME</h5>
                </div>
              </div>


            </div>
            <div className="row ">
              <div className="col-md-12 bioSec">
                <nav className="d-flex justify-content-between align-items-center mb-2">
                  <div className="bioNav" id="nav-tab" role="tablist">
                    <button className="br-top-15 px-3 bg h-50 border-0 active" id="nav-who-tab" data-bs-toggle="tab" data-bs-target="#nav-who" type="button" role="tab" aria-controls="nav-who" aria-selected="true">
                      {t('profile_section.who_am_i')}</button>
                    <button className="br-top-15 px-3 bg h-50 border-0" id="nav-what-tab" data-bs-toggle="tab" data-bs-target="#nav-what" type="button" role="tab" aria-controls="nav-what" aria-selected="false">{t('profile_section.what_i_do')}</button>
                    <button className="br-top-15 px-3 bg h-50 border-0" id="nav-service-tab" data-bs-toggle="tab" data-bs-target="#nav-service" type="button" role="tab" aria-controls="nav-service" aria-selected="false">{t('profile_section.who_is_my_service_for')}</button>
                    <button className="br-top-15 px-3 bg h-50 border-0" id="nav-work-tab" data-bs-toggle="tab" data-bs-target="#nav-work" type="button" role="tab" aria-controls="nav-work" aria-selected="false">{t('profile_section.why_work_with_me')}</button>
                  </div>
                  <div className="dropFormat">
                    <div className="d-flex">
                      <div className="dropdown btnArrow">
                        <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-display="static">
                          <Link to="#" id="notab" className="d-flex justify-content-center fs-5 fw-bold ps-3 pe-5 align-items-center w-100x text-underline shadow-4 h-45 h8 br-30">
                            <u>A</u>
                          </Link>
                        </span>

                      </div>
                    </div>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-who" role="tabpanel" aria-labelledby="nav-who-tab">
                    <div className="position-relative">
                      <textarea
                        placeholder={t('profile_section.your_bio_here')} rows="10"
                        className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                        type="text">
                      </textarea>
                      <span className="position-absolute top-20 start-16 ">
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                      </span>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="nav-what" role="tabpanel" aria-labelledby="nav-what-tab">
                    <div className="position-relative">
                      <textarea
                        placeholder={t('profile_section.your_bio_here')} rows="10"
                        className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                        type="text">
                      </textarea>
                      <span className="position-absolute top-20 start-16 ">
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                      </span>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="nav-service" role="tabpanel" aria-labelledby="nav-service-tab">
                    <div className="position-relative">
                      <textarea
                        placeholder={t('profile_section.your_bio_here')} rows="10"
                        className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                        type="text">
                      </textarea>
                      <span className="position-absolute top-20 start-16 ">
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                      </span>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="nav-work" role="tabpanel" aria-labelledby="nav-work-tab">
                    <div className="position-relative">
                      <textarea
                        placeholder={t('profile_section.your_bio_here')} rows="10"
                        className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                        type="text">
                      </textarea>
                      <span className="position-absolute top-20 start-16 ">
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                        <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row bio-sce-oter'>
            <div className='col-lg-4'>
              <div className='box-white mt-5 mb-5'>
                <div className='boxxx'>

                </div>
                <div className='row socail-lins'>
                  <div className='col-lg-1'>
                    <div className='circle-line'>
                      <div className='circle'>

                      </div>
                      <div className='linee'>

                      </div>
                    </div>

                  </div>
                  <div className='col-lg-11'>
                    <div className="text-center mb-5 form-text-btn">
                      <h5>TESTIMONIAL  | I |</h5>
                    </div>
                  </div>


                </div>
                <div className="row ">
                  <div className="col-md-12 bioSec bio-sce">
                    <NavItem as="li">
                      <div className="col-lg-12 px-2 mt-0 text-center mb-4">
                        <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
                          <IconBlue15 />
                          <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10 my-2" />
                          <div className="upload-btn-wrapper">
                            <button className="btns shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
                            <input type="file" name="myfile" />
                          </div>
                          <p className="h7 fw-semibold mt-2">{t('profile_section.pixels_1024_X_1024_pixels')}</p>
                        </div>

                      </div>
                      <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44  " />

                      <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 mt-0" />
                    </NavItem>
                    <nav className="d-flex justify-content-between align-items-center mb-2">


                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      <div className="tab-pane fade show active" id="nav-who" role="tabpanel" aria-labelledby="nav-who-tab">
                        <div className="position-relative">
                          <textarea
                            placeholder={t('profile_section.your_bio_here')} rows="10"
                            className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                            type="text">
                          </textarea>
                          <span className="position-absolute top-20 start-16 ">
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                          </span>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-what" role="tabpanel" aria-labelledby="nav-what-tab">
                        <div className="position-relative">
                          <textarea
                            placeholder={t('profile_section.your_bio_here')} rows="10"
                            className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                            type="text">
                          </textarea>
                          <span className="position-absolute top-20 start-16 ">
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                          </span>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-service" role="tabpanel" aria-labelledby="nav-service-tab">
                        <div className="position-relative">
                          <textarea
                            placeholder={t('profile_section.your_bio_here')} rows="10"
                            className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                            type="text">
                          </textarea>
                          <span className="position-absolute top-20 start-16 ">
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                          </span>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-work" role="tabpanel" aria-labelledby="nav-work-tab">
                        <div className="position-relative">
                          <textarea
                            placeholder={t('profile_section.your_bio_here')} rows="10"
                            className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                            type="text">
                          </textarea>
                          <span className="position-absolute top-20 start-16 ">
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='box-white mt-5 mb-5'>
                <div className='boxxx'>

                </div>
                <div className='row socail-lins'>
                  <div className='col-lg-1'>
                    <div className='circle-line'>
                      <div className='circle'>

                      </div>
                      <div className='linee'>

                      </div>
                    </div>

                  </div>
                  <div className='col-lg-11'>
                    <div className="text-center mb-5 form-text-btn">
                      <h5>TESTIMONIAL  | I |</h5>
                    </div>
                  </div>


                </div>
                <div className="row ">
                  <div className="col-md-12 bioSec bio-sce">
                    <NavItem as="li">
                      <div className="col-lg-12 px-2 mt-0 text-center mb-4">
                        <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
                          <IconBlue15 />
                          <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10 my-2" />
                          <div className="upload-btn-wrapper">
                            <button className="btns shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
                            <input type="file" name="myfile" />
                          </div>
                          <p className="h7 fw-semibold mt-2">{t('profile_section.pixels_1024_X_1024_pixels')}</p>
                        </div>

                      </div>
                      <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44  " />

                      <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 mt-0" />
                    </NavItem>
                    <nav className="d-flex justify-content-between align-items-center mb-2">


                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      <div className="tab-pane fade show active" id="nav-who" role="tabpanel" aria-labelledby="nav-who-tab">
                        <div className="position-relative">
                          <textarea
                            placeholder={t('profile_section.your_bio_here')} rows="10"
                            className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                            type="text">
                          </textarea>
                          <span className="position-absolute top-20 start-16 ">
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                          </span>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-what" role="tabpanel" aria-labelledby="nav-what-tab">
                        <div className="position-relative">
                          <textarea
                            placeholder={t('profile_section.your_bio_here')} rows="10"
                            className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                            type="text">
                          </textarea>
                          <span className="position-absolute top-20 start-16 ">
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                          </span>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-service" role="tabpanel" aria-labelledby="nav-service-tab">
                        <div className="position-relative">
                          <textarea
                            placeholder={t('profile_section.your_bio_here')} rows="10"
                            className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                            type="text">
                          </textarea>
                          <span className="position-absolute top-20 start-16 ">
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                          </span>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-work" role="tabpanel" aria-labelledby="nav-work-tab">
                        <div className="position-relative">
                          <textarea
                            placeholder={t('profile_section.your_bio_here')} rows="10"
                            className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                            type="text">
                          </textarea>
                          <span className="position-absolute top-20 start-16 ">
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='box-white mt-5 mb-5'>
                <div className='boxxx'>

                </div>
                <div className='row socail-lins'>
                  <div className='col-lg-1'>
                    <div className='circle-line'>
                      <div className='circle'>

                      </div>
                      <div className='linee'>

                      </div>
                    </div>

                  </div>
                  <div className='col-lg-11'>
                    <div className="text-center mb-5 form-text-btn">
                      <h5>TESTIMONIAL  | I |</h5>
                    </div>
                  </div>


                </div>
                <div className="row ">
                  <div className="col-md-12 bioSec bio-sce">
                    <NavItem as="li">
                      <div className="col-lg-12 px-2 mt-0 text-center mb-4">
                        <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
                          <IconBlue15 />
                          <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 br-10 my-2" />
                          <div className="upload-btn-wrapper">
                            <button className="btns shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
                            <input type="file" name="myfile" />
                          </div>
                          <p className="h7 fw-semibold mt-2">{t('profile_section.pixels_1024_X_1024_pixels')}</p>
                        </div>

                      </div>
                      <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44  " />

                      <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="shadow-5in h-44 mt-0" />
                    </NavItem>
                    <nav className="d-flex justify-content-between align-items-center mb-2">


                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      <div className="tab-pane fade show active" id="nav-who" role="tabpanel" aria-labelledby="nav-who-tab">
                        <div className="position-relative">
                          <textarea
                            placeholder={t('profile_section.your_bio_here')} rows="10"
                            className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                            type="text">
                          </textarea>
                          <span className="position-absolute top-20 start-16 ">
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                          </span>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-what" role="tabpanel" aria-labelledby="nav-what-tab">
                        <div className="position-relative">
                          <textarea
                            placeholder={t('profile_section.your_bio_here')} rows="10"
                            className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                            type="text">
                          </textarea>
                          <span className="position-absolute top-20 start-16 ">
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                          </span>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-service" role="tabpanel" aria-labelledby="nav-service-tab">
                        <div className="position-relative">
                          <textarea
                            placeholder={t('profile_section.your_bio_here')} rows="10"
                            className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                            type="text">
                          </textarea>
                          <span className="position-absolute top-20 start-16 ">
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                          </span>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-work" role="tabpanel" aria-labelledby="nav-work-tab">
                        <div className="position-relative">
                          <textarea
                            placeholder={t('profile_section.your_bio_here')} rows="10"
                            className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                            type="text">
                          </textarea>
                          <span className="position-absolute top-20 start-16 ">
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                            <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row bio-sce-oter'>
            <div className='col-lg-4'>
              <div className='box-white mt-5 mb-5'>
                <div className='boxxx'>

                </div>
                <div className='row socail-lins'>
                  <div className='col-lg-1'>
                    <div className='circle-line'>
                      <div className='circle'>

                      </div>
                      <div className='linee'>

                      </div>
                    </div>

                  </div>
                  <div className='col-lg-11'>
                    <div className="text-center mb-5 form-text-btn">
                      <h5>UPLOAD VIDEO</h5>
                    </div>
                  </div>


                </div>
                <div className="row ">
                  <div className="col-md-12 bioSec bio-sce">

                    <div className="col-lg-12 pb-5">
                      <input type="text" placeholder={t('profile_section.enter_video_title_here')} className="shadow-5in h-44 br-10" />

                      <div className="LivePreview h-286 br-20 mx-auto p-20 w-100 d-flex justify-content-center align-items-center flex-column">
                        <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.6 15.0992H15.6L11.46 9.69922L8.7 13.2992L6.84 10.8992L3.6 15.0992ZM2.4 19.8992C1.74 19.8992 1.1748 19.664 0.704402 19.1936C0.234002 18.7232 -0.000797963 18.1584 2.03735e-06 17.4992V3.09922C2.03735e-06 2.43922 0.235202 1.87402 0.705602 1.40362C1.176 0.933221 1.7408 0.698421 2.4 0.699221H16.8C17.46 0.699221 18.0252 0.934421 18.4956 1.40482C18.966 1.87522 19.2008 2.44002 19.2 3.09922V8.49922L24 3.69922V16.8992L19.2 12.0992V17.4992C19.2 18.1592 18.9648 18.7244 18.4944 19.1948C18.024 19.6652 17.4592 19.9 16.8 19.8992H2.4Z" fill="#1C1C1C" />
                        </svg>
                        <p className="mb-0 mb-3">{t('profile_section.upload_video')}</p>
                        <input type="text" placeholder={t('profile_section.enter_video_link_here')} className="shadow-5in h-44 br-10 mb-3" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='box-white mt-5 mb-5'>
                <div className='boxxx'>

                </div>
                <div className='row socail-lins'>
                  <div className='col-lg-1'>
                    <div className='circle-line'>
                      <div className='circle'>

                      </div>
                      <div className='linee'>

                      </div>
                    </div>

                  </div>
                  <div className='col-lg-11'>
                    <div className="text-center mb-5 form-text-btn">
                      <h5>UPLOAD PODCAST</h5>
                    </div>
                  </div>


                </div>
                <div className="row ">
                  <div className="col-md-12 bioSec bio-sce">
                    <div className="col-lg-12">
                      <input type="text" placeholder={t('profile_section.enter_video_title_here')} className="shadow-5in h-44 br-10" />

                      <div className="LivePreview h-138 mb-2 br-20 mx-auto p-20 w-100 d-flex justify-content-center align-items-center flex-column">
                        <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.00031 15.9587C7.94768 15.9587 7.05294 15.5903 6.3161 14.8534C5.57926 14.1166 5.21083 13.2218 5.21083 12.1692V4.59025C5.21083 3.53762 5.57926 2.64289 6.3161 1.90604C7.05294 1.1692 7.94768 0.800781 9.00031 0.800781C10.0529 0.800781 10.9477 1.1692 11.6845 1.90604C12.4214 2.64289 12.7898 3.53762 12.7898 4.59025V12.1692C12.7898 13.2218 12.4214 14.1166 11.6845 14.8534C10.9477 15.5903 10.0529 15.9587 9.00031 15.9587ZM7.73715 24.8008V20.9166C5.54768 20.6218 3.73715 19.6429 2.30557 17.9797C0.873992 16.3166 0.158203 14.3797 0.158203 12.1692H2.68452C2.68452 13.9166 3.30052 15.4063 4.53252 16.6383C5.76452 17.8703 7.25378 18.4858 9.00031 18.485C10.7477 18.485 12.2374 17.869 13.4694 16.637C14.7014 15.405 15.3169 13.9157 15.3161 12.1692H17.8424C17.8424 14.3797 17.1266 16.3166 15.695 17.9797C14.2635 19.6429 12.4529 20.6218 10.2635 20.9166V24.8008H7.73715Z" fill="#1C1C1C" />
                        </svg>
                        <p className="mb-0 mb-2 mt-2 h8">Upload Podcast | Audio</p>
                        <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in h-44 br-10" />
                      </div>
                      <div className=" ss accomplishSec">
                        <div className="">
                          <p className="mb-4 pb-2">Enter Episode </p>
                          <RangeSlider
                            value={value}
                            onChange={(changeEvent) =>
                              setValue(changeEvent.target.value)
                            }
                            min={0}
                            max={10}
                          />
                        </div>
                        <div className="">
                          <p className="mb-4 pb-2">Enter Season or Series</p>
                          <RangeSlider
                            value={value1}
                            onChange={(changeEvent) =>
                              setValue1(changeEvent.target.value)
                            }
                            min={0}
                            max={10}
                          />
                        </div>

                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='box-white mt-5 mb-5'>
                <div className='boxxx'>

                </div>
                <div className='row socail-lins'>
                  <div className='col-lg-1'>
                    <div className='circle-line'>
                      <div className='circle'>

                      </div>
                      <div className='linee'>

                      </div>
                    </div>

                  </div>
                  <div className='col-lg-11'>
                    <div className="text-center mb-5 form-text-btn">
                      <h5>HIRE ME | BOOK ME</h5>
                    </div>
                  </div>


                </div>
                <div className="row ">
                  <div className="col-md-12 bioSec bio-sce customCol   cstom">
                    <div className="pt-4 pb-4 svg-oter">
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0.800781V24.8008H24V0.800781H0ZM2.18182 2.9826H21.8182V22.619H2.18182V2.9826ZM7.63636 5.16442L10.7051 9.11896L7.63636 17.5735L12 21.5281L16.3636 17.5735L13.2949 9.11896L16.3636 5.16442H7.63636Z" fill="#1C1C1C" />
                      </svg>
                    </div>
                    <div className='row'>
                      <div className='col-lg-2 col-ctom'>
                        <div className='circle-line'>
                          <div className='circle'>

                          </div>
                          <div className='linee'>

                          </div>
                        </div>

                      </div>
                      <div className="col-lg-10 d-flex">

                        <label className=" mb-2">{t('profile_section.last_name')}</label>
                        <div className='inpt-boxx'>
                          <input
                            placeholder={t('profile_section.last_name')}
                            className="shadow-5in br-10 w-100 mb-4"
                            type="text"
                          />
                          <div className='boxx'>

                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="pt-4 pb-4 svg-oter">
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0.800781V24.8008H24V0.800781H0ZM2.18182 2.9826H21.8182V22.619H2.18182V2.9826ZM7.63636 5.16442L10.7051 9.11896L7.63636 17.5735L12 21.5281L16.3636 17.5735L13.2949 9.11896L16.3636 5.16442H7.63636Z" fill="#1C1C1C" />
                      </svg>
                    </div>
                    <div className='row pb-5'>
                      <div className='col-lg-2 col-ctom'>
                        <div className='circle-line'>
                          <div className='circle'>

                          </div>
                          <div className='linee'>

                          </div>
                        </div>

                      </div>
                      <div className="col-lg-10 d-flex">

                        <label className=" mb-2">{t('profile_section.last_name')}</label>
                        <div className='inpt-boxx'>
                          <input
                            placeholder={t('profile_section.last_name')}
                            className="shadow-5in br-10 w-100 mb-4"
                            type="text"
                          />
                          <div className='boxx'>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className='box-white  mb-5'>
            <div className='boxxx'>

            </div>
            <div className='row socail-lins'>
              <div className='col-lg-1'>
                <div className='circle-line'>
                  <div className='circle'>

                  </div>
                  <div className='linee'>

                  </div>
                </div>

              </div>
              <div className='col-lg-11'>
                <div className="text-center mb-5 form-text-btn">
                  <h5>CV | SOCIAL MODE | TIK TOK FEED | PROFESSIONAL SCORE</h5>
                </div>
              </div>


            </div>
            <div className="row ">
              <div className="col-md-12 bioSec">
                <div className="col-md-12  customCol cstom">
                  <div className='row'>
                    <div className='col-lg-4'>
                      <div className='row'>
                        <div className='col-lg-2 col-ctom'>
                          <div className='circle-line'>
                            <div className='circle'>

                            </div>
                            <div className='linee'>

                            </div>
                          </div>

                        </div>
                        <div className="col-lg-10 d-flex">

                          <label className=" mb-2">CV</label>
                          <div className='inpt-boxx'>
                            <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 mb-4"
                              type="text"
                            />
                            <div className='boxx'>

                            </div>
                          </div>

                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-lg-2 col-ctom'>
                          <div className='circle-line'>
                            <div className='circle'>

                            </div>
                            <div className='linee'>

                            </div>
                          </div>

                        </div>
                        <div className="col-lg-10 d-flex">

                          <label className=" mb-2">Social Mode</label>
                          <div className='inpt-boxx'>
                            <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 mb-4"
                              type="text"
                            />
                            <div className='boxx'>

                            </div>
                          </div>

                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-2 col-ctom'>
                          <div className='circle-line'>
                            <div className='circle'>

                            </div>
                            <div className='linee'>

                            </div>
                          </div>

                        </div>
                        <div className="col-lg-10 d-flex">

                          <label className=" mb-2">Tik Tok Feed</label>
                          <div className='inpt-boxx'>
                            <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 mb-4"
                              type="text"
                            />
                            <div className='boxx'>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className='col-lg-8'>

                      <div className=" my-5">

                        <div className=" circleSec">
                          <ul className="row">
                            <NavItem as="li" className="col-lg-3">
                              <div class="timeline-top">9</div>
                              <div className="circleIcon shadow-5in bg">

                                <div className="Icon-Div">

                                  <div className='mx-auto fileTab h-100'>

                                    <div className="timeline-icon pt-4">
                                      <img className="message" src={message_gold} alt="message" ></img>
                                      <p>
                                        Influencer
                                        Score
                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <input type="text" placeholder="Paste link here" className="shadow-5in br-10" />
                            </NavItem>
                            <NavItem as="li" className="col-lg-3">
                              <div class="timeline-top">9</div>
                              <div className="circleIcon shadow-5in bg">

                                <div className="Icon-Div">

                                  <div className='mx-auto fileTab h-100'>

                                    <div className="timeline-icon pt-4">
                                      <img src={rank_gold} className="load" alt="load" ></img>
                                      <p>
                                        Social Score
                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <input type="text" placeholder="Paste link here" className="shadow-5in br-10" />
                            </NavItem>
                            <NavItem as="li" className="col-lg-3">
                              <div class="timeline-top">9</div>
                              <div className="circleIcon shadow-5in bg">

                                <div className="Icon-Div">

                                  <div className='mx-auto fileTab h-100'>

                                    <div className="timeline-icon">
                                      <img src={gold_star} alt="rank" ></img>
                                      <p>
                                        Customer Satisfaction Score
                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <input type="text" placeholder="Paste link here" className="shadow-5in br-10" />
                            </NavItem>
                            <NavItem as="li" className="col-lg-3">
                              <div class="timeline-top">9</div>
                              <div className="circleIcon shadow-5in bg">

                                <div className="Icon-Div">

                                  <div className='mx-auto fileTab h-100'>

                                    <div className="timeline-icon">

                                      <img src={cap_gold} className="answer" alt="answer" ></img>
                                      <p>
                                        Net Promoter Score
                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <input type="text" placeholder="Paste link here" className="shadow-5in br-10" />
                            </NavItem>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className='box-white  mb-5'>
            <div className='boxxx'>

            </div>
            <div className='row socail-lins'>
              <div className='col-lg-1'>
                <div className='circle-line'>
                  <div className='circle'>

                  </div>
                  <div className='linee'>

                  </div>
                </div>

              </div>
              <div className='col-lg-11'>
                <div className="text-center mb-5 form-text-btn">
                  <h5>MENU ITEM NUMBER 1,2,3,4,5,6</h5>
                </div>
              </div>


            </div>
            <div className="row ">
              <div className="col-md-12 bioSec last-sec">
                <div className="col-md-12  customCol cstom">
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='row'>
                        <div className='col-lg-2 col-ctom'>
                          <div className='circle-line'>
                            <div className='circle'>

                            </div>
                            <div className='linee'>

                            </div>
                          </div>

                        </div>
                        <div className="col-lg-10 d-flex">

                          <label className=" mb-2">Menu Item Number 1</label>
                          <div className='inpt-boxx '>
                            <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 mb-1"
                              type="text"
                            />
                             <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 "
                              type="text"
                            />
                            <div className='boxx'>

                            </div>
                          </div>
                
      

                        </div>
                        <div className="row bannerthrd bannerthrd1s">
      
            <div className="col-lg-12 ps-4">
              <div className="mb-1 mt-4 m-a d-flex  justify-content-center">
                <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select isSearchable={false} options={options6} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue6/></span>,
                    }}  
                  />
                </div>
                <div className="uploadSo d-flex">
                <div>
                  <div className="Icon-Div w-90s">
                      <div className='mx-auto mb-0 fileTab h-100'>
                        <label onClick={openSelects6} className="w-100 h-100">
                          <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                            {previewImages6 ? <img src={previewImages6} alt="Profile Picture" className="w-100 h-100" /> :
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <IconBlue16 />
                              </div>
                            }
                          </div>
                        </label>
                      </div>
                      <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0])
                        setPreviewImages6(url);
                        setImages6(e.target.files[0])
                      }} ref={inputRefs6} />
                  </div>

                </div>
              </div>
              </div>
            
            </div>
          </div>
                      </div>
                  
                            <br />
                      <div className='row'>
                        <div className='col-lg-2 col-ctom'>
                          <div className='circle-line'>
                            <div className='circle'>

                            </div>
                            <div className='linee'>

                            </div>
                          </div>

                        </div>
                        <div className="col-lg-10 d-flex">

                          <label className=" mb-2">Menu Item Number 2</label>
                          <div className='inpt-boxx'>
                            <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 mb-1"
                              type="text"
                            />
                             <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 "
                              type="text"
                            />
                            <div className='boxx'>

                            </div>
                          </div>

                        </div>
                        <div className="row bannerthrd bannerthrd1s">
      
      <div className="col-lg-12 ps-4">
        <div className="mb-1 mt-4 m-a d-flex  justify-content-center">
          <div className="dropdown m-0 w-90s me-3 ms-2">
            <Select isSearchable={false} options={options6} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                option: (base) => ({
                  ...base,
                  borderRadius: '30px',
                  width:'80%',
                  height:'auto',
                  lineHeight: '24px',
                  margin: '10px',
                  cursor: 'pointer',
                  color:'#000000',
                  boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                }),
                
              }}
              defaultValue={{
                value: 'blue', 
                label: <span className="text-blue"><IconBlue6/></span>,
              }}  
            />
          </div>
          <div className="uploadSo d-flex">
          <div>
            <div className="Icon-Div w-90s">
                <div className='mx-auto mb-0 fileTab h-100'>
                  <label onClick={openSelects6} className="w-100 h-100">
                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                      {previewImages6 ? <img src={previewImages6} alt="Profile Picture" className="w-100 h-100" /> :
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                          <IconBlue16 />
                        </div>
                      }
                    </div>
                  </label>
                </div>
                <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                  let url = URL.createObjectURL(e.target.files[0])
                  setPreviewImages6(url);
                  setImages6(e.target.files[0])
                }} ref={inputRefs6} />
            </div>

          </div>
        </div>
        </div>
      
      </div>
    </div>
                      </div>
                      <br />
                      <div className='row'>
                        <div className='col-lg-2 col-ctom'>
                          <div className='circle-line'>
                            <div className='circle'>

                            </div>
                            <div className='linee'>

                            </div>
                          </div>

                        </div>
                        <div className="col-lg-10 d-flex">

                          <label className=" mb-2">Menu Item Number 3</label>
                          <div className='inpt-boxx '>
                            <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 mb-1"
                              type="text"
                            />
                             <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 "
                              type="text"
                            />
                            <div className='boxx'>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='row'>
                        <div className='col-lg-2 col-ctom'>
                          <div className='circle-line'>
                            <div className='circle'>

                            </div>
                            <div className='linee'>

                            </div>
                          </div>

                        </div>
                        <div className="col-lg-10 d-flex">

                          <label className=" mb-2">Menu Item Number 1</label>
                          <div className='inpt-boxx '>
                            <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 mb-1"
                              type="text"
                            />
                             <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 "
                              type="text"
                            />
                            <div className='boxx'>

                            </div>
                          </div>
                
      

                        </div>
                        <div className="row bannerthrd bannerthrd1s">
      
      <div className="col-lg-12 ps-4">
        <div className="mb-1 mt-4 m-a d-flex  justify-content-center">
          <div className="dropdown m-0 w-90s me-3 ms-2">
            <Select isSearchable={false} options={options6} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                option: (base) => ({
                  ...base,
                  borderRadius: '30px',
                  width:'80%',
                  height:'auto',
                  lineHeight: '24px',
                  margin: '10px',
                  cursor: 'pointer',
                  color:'#000000',
                  boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                }),
                
              }}
              defaultValue={{
                value: 'blue', 
                label: <span className="text-blue"><IconBlue6/></span>,
              }}  
            />
          </div>
          <div className="uploadSo d-flex">
          <div>
            <div className="Icon-Div w-90s">
                <div className='mx-auto mb-0 fileTab h-100'>
                  <label onClick={openSelects6} className="w-100 h-100">
                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                      {previewImages6 ? <img src={previewImages6} alt="Profile Picture" className="w-100 h-100" /> :
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                          <IconBlue16 />
                        </div>
                      }
                    </div>
                  </label>
                </div>
                <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                  let url = URL.createObjectURL(e.target.files[0])
                  setPreviewImages6(url);
                  setImages6(e.target.files[0])
                }} ref={inputRefs6} />
            </div>

          </div>
        </div>
        </div>
      
      </div>
    </div>

                      </div>
                      {/* <div className="uploadSo ">
          <div className='d-flex'>
                          <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select options={options4} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue4/></span>,
                    }}  
                  />

                </div>

                <span className="h7 text-center d-block w-100 mt-1">{t('profile_section.upload')}<br/>{t('profile_section.menu_icon')}</span>
                            </div>
                            </div> */}
                            <br />
                      <div className='row'>
                        <div className='col-lg-2 col-ctom'>
                          <div className='circle-line'>
                            <div className='circle'>

                            </div>
                            <div className='linee'>

                            </div>
                          </div>

                        </div>
                        <div className="col-lg-10 d-flex">

                          <label className=" mb-2">Menu Item Number 2</label>
                          <div className='inpt-boxx'>
                            <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 mb-1"
                              type="text"
                            />
                             <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 "
                              type="text"
                            />
                            <div className='boxx'>

                            </div>
                          </div>

                        </div>
                        <div className="row bannerthrd bannerthrd1s">
      
      <div className="col-lg-12 ps-4">
        <div className="mb-1 mt-4 m-a d-flex  justify-content-center">
          <div className="dropdown m-0 w-90s me-3 ms-2">
            <Select isSearchable={false} options={options6} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                option: (base) => ({
                  ...base,
                  borderRadius: '30px',
                  width:'80%',
                  height:'auto',
                  lineHeight: '24px',
                  margin: '10px',
                  cursor: 'pointer',
                  color:'#000000',
                  boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                }),
                
              }}
              defaultValue={{
                value: 'blue', 
                label: <span className="text-blue"><IconBlue6/></span>,
              }}  
            />
          </div>
          <div className="uploadSo d-flex">
          <div>
            <div className="Icon-Div w-90s">
                <div className='mx-auto mb-0 fileTab h-100'>
                  <label onClick={openSelects6} className="w-100 h-100">
                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                      {previewImages6 ? <img src={previewImages6} alt="Profile Picture" className="w-100 h-100" /> :
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                          <IconBlue16 />
                        </div>
                      }
                    </div>
                  </label>
                </div>
                <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                  let url = URL.createObjectURL(e.target.files[0])
                  setPreviewImages6(url);
                  setImages6(e.target.files[0])
                }} ref={inputRefs6} />
            </div>

          </div>
        </div>
        </div>
      
      </div>
    </div>
                      </div>
                      <br />
                      <div className='row'>
                        <div className='col-lg-2 col-ctom'>
                          <div className='circle-line'>
                            <div className='circle'>

                            </div>
                            <div className='linee'>

                            </div>
                          </div>

                        </div>
                        <div className="col-lg-10 d-flex">

                          <label className=" mb-2">Menu Item Number 3</label>
                          <div className='inpt-boxx '>
                            <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 mb-1"
                              type="text"
                            />
                             <input
                              placeholder="Paste Link Here..."
                              className="shadow-5in br-10 w-100 "
                              type="text"
                            />
                            <div className='boxx'>

                            </div>
                          </div>

                        </div>
                        <div className="row bannerthrd bannerthrd1s">
      
      <div className="col-lg-12 ps-4">
        <div className="mb-1 mt-4 m-a d-flex  justify-content-center">
          <div className="dropdown m-0 w-90s me-3 ms-2">
            <Select isSearchable={false} options={options6} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
              styles={{
                option: (base) => ({
                  ...base,
                  borderRadius: '30px',
                  width:'80%',
                  height:'auto',
                  lineHeight: '24px',
                  margin: '10px',
                  cursor: 'pointer',
                  color:'#000000',
                  boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                }),
                
              }}
              defaultValue={{
                value: 'blue', 
                label: <span className="text-blue"><IconBlue6/></span>,
              }}  
            />
          </div>
          <div className="uploadSo d-flex">
          <div>
            <div className="Icon-Div w-90s">
                <div className='mx-auto mb-0 fileTab h-100'>
                  <label onClick={openSelects6} className="w-100 h-100">
                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                      {previewImages6 ? <img src={previewImages6} alt="Profile Picture" className="w-100 h-100" /> :
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                          <IconBlue16 />
                        </div>
                      }
                    </div>
                  </label>
                </div>
                <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                  let url = URL.createObjectURL(e.target.files[0])
                  setPreviewImages6(url);
                  setImages6(e.target.files[0])
                }} ref={inputRefs6} />
            </div>

          </div>
        </div>
        </div>
      
      </div>
    </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* <div className="row customCol">
        <div className="col-md-4">
          <label className="mb-2">{t('profile_section.first_name')}</label>
          <input
            placeholder={t('profile_section.first_name')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <label className="mb-2">{t('profile_section.last_name')}</label>
          <input
            placeholder={t('profile_section.last_name')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>

        <div className="col-md-4">
          <label className="mb-2">{t('profile_section.preferred_profile')}</label>
          <input
            placeholder={t('profile_section.dyna')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
     
        <div className="col-md-4">
          <label className="mb-2">Business Title</label>
          <input
            placeholder="Business Title"
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <label className="mb-2">{t('profile_section.business_company_name')}</label>
          <input
            placeholder={t('profile_section.business_name_here')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
        
        <div className="col-md-4">
          <label className="mb-2">{t('profile_section.tagline_slogan')}</label>
          <input
            placeholder={t('profile_section.your_tagline_here')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
      </div> */}

      {/* <div className="row">
        <div className="col-md-3">
          <label className="mb-2">Facebook Link</label>
          <input
            placeholder="Enter social media link"
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
        <div className="col-md-3">
          <label className="mb-2">Twitter Link</label>
          <input
            placeholder="Enter social media link"
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
        <div className="col-md-3">
          <label className="mb-2">Instagram Link</label>
          <input
            placeholder="Enter social media link"
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
        <div className="col-md-3">
          <label className="mb-2">Linkedin Link</label>
          <input
            placeholder="Enter social media link"
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
        <div className="col-md-3">
          <label className="mb-2">Pinterest Link</label>
          <input
            placeholder="Enter social media link"
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
        <div className="col-md-3">
          <label className="mb-2">Youtube Link</label>
          <input
            placeholder="Enter social media link"
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>

        <div className="col-md-3">
          <label className="mb-2">TikTok Link</label>
          <input
            placeholder="Enter social media link"
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
        <div className="col-md-3">
          <label className="mb-2">Snapchat Link</label>
          <input
            placeholder="Enter social media link"
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
        </div>
      </div> */}

      {/* <div className="row">
        <div className="col-md-12 bioSec">
          <nav className="d-flex justify-content-between align-items-center mb-2">
            <div className="bioNav" id="nav-tab" role="tablist">
              <button className="br-top-15 px-3 bg h-50 border-0 active" id="nav-who-tab" data-bs-toggle="tab" data-bs-target="#nav-who" type="button" role="tab" aria-controls="nav-who" aria-selected="true">
                {t('profile_section.who_am_i')}</button>
              <button className="br-top-15 px-3 bg h-50 border-0" id="nav-what-tab" data-bs-toggle="tab" data-bs-target="#nav-what" type="button" role="tab" aria-controls="nav-what" aria-selected="false">{t('profile_section.what_i_do')}</button>
              <button className="br-top-15 px-3 bg h-50 border-0" id="nav-service-tab" data-bs-toggle="tab" data-bs-target="#nav-service" type="button" role="tab" aria-controls="nav-service" aria-selected="false">{t('profile_section.who_is_my_service_for')}</button>
              <button className="br-top-15 px-3 bg h-50 border-0" id="nav-work-tab" data-bs-toggle="tab" data-bs-target="#nav-work" type="button" role="tab" aria-controls="nav-work" aria-selected="false">{t('profile_section.why_work_with_me')}</button>
            </div>
            <div className="dropFormat">
              <div className="d-flex">
                <div className="dropdown btnArrow">
                  <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-display="static">
                    <Link to="#" id="notab" className="d-flex justify-content-center fs-5 fw-bold ps-3 pe-5 align-items-center w-100x text-underline shadow-4 h-45 h8 br-30">
                      <u>A</u>
                    </Link>
                  </span>

                </div>
              </div>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-who" role="tabpanel" aria-labelledby="nav-who-tab">
              <div className="position-relative">
                <textarea
                  placeholder={t('profile_section.your_bio_here')} rows="10"
                  className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                  type="text">
                </textarea>
                <span className="position-absolute top-20 start-16 ">
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                </span>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-what" role="tabpanel" aria-labelledby="nav-what-tab">
              <div className="position-relative">
                <textarea
                  placeholder={t('profile_section.your_bio_here')} rows="10"
                  className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                  type="text">
                </textarea>
                <span className="position-absolute top-20 start-16 ">
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                </span>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-service" role="tabpanel" aria-labelledby="nav-service-tab">
              <div className="position-relative">
                <textarea
                  placeholder={t('profile_section.your_bio_here')} rows="10"
                  className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                  type="text">
                </textarea>
                <span className="position-absolute top-20 start-16 ">
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                </span>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-work" role="tabpanel" aria-labelledby="nav-work-tab">
              <div className="position-relative">
                <textarea
                  placeholder={t('profile_section.your_bio_here')} rows="10"
                  className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                  type="text">
                </textarea>
                <span className="position-absolute top-20 start-16 ">
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className='row'>
        <div className="col-md-12 circleSec">
          <h5 className='mt-3'>Testimonials</h5>
          <ul className="row">
            <NavItem as="li" className="col-lg-4">
              <div className="circleIcon shadow-5in bg">
                <div className="Icon-Div">
                  <div className='mx-auto fileTab h-100'>
                    <label onClick={openSelect15} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage15 ? <img src={previewImage15} alt="Profile Picture" className="w-100 h-100" /> :
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBlue16 />
                            <p className="mb-2 h7">{t('profile_section.add_image')}</p>
                            <p className="h7 mb-0">200 X 200 <br /> Pixels</p>
                          </div>
                        }
                      </div>
                    </label>
                  </div>
                  <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                    let url = URL.createObjectURL(e.target.files[0])
                    setPreviewImage15(url);
                    setImage15(e.target.files[0])
                  }} ref={inputRef15} />
                </div>
              </div>
              <div class="">
                <div class="starrating risingstar d-flex justify-content-center flex-row-reverse">
                  <input type="radio" id="star5" name="rating" value="5" /><label htmlFor="star5" title="5 star"></label>
                  <input type="radio" id="star4" name="rating" value="4" /><label htmlFor="star4" title="4 star"></label>
                  <input type="radio" id="star3" name="rating" value="3" /><label htmlFor="star3" title="3 star"></label>
                  <input type="radio" id="star2" name="rating" value="2" /><label htmlFor="star2" title="2 star"></label>
                  <input type="radio" id="star1" name="rating" value="1" /><label htmlFor="star1" title="1 star"></label>
                </div>
              </div>
              <input type="text" placeholder="Client Name" className="shadow-5in br-10" />
              <div className="position-relative mt-2">
                <textarea
                  placeholder="Enter your message" rows="10"
                  className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                  type="text">
                </textarea>
                <span className="position-absolute top-20 start-16 ">
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                </span>
              </div>
            </NavItem>
            <NavItem as="li" className="col-lg-4">
              <div className="circleIcon shadow-5in bg">
                <div className="Icon-Div">
                  <div className='mx-auto fileTab h-100'>
                    <label onClick={openSelect16} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage16 ? <img src={previewImage16} alt="Profile Picture" className="w-100 h-100" /> :
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBlue16 />
                            <p className="mb-2 h7">{t('profile_section.add_image')}</p>
                            <p className="h7 mb-0">200 X 200 <br /> Pixels</p>
                          </div>
                        }
                      </div>
                    </label>
                  </div>
                  <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                    let url = URL.createObjectURL(e.target.files[0])
                    setPreviewImage16(url);
                    setImage16(e.target.files[0])
                  }} ref={inputRef16} />
                </div>
              </div>
              <div class="starrating risingstar d-flex justify-content-center flex-row-reverse">
                <input type="radio" id="star52" name="ratingg" value="5" /><label htmlFor="star52" title="5 star"></label>
                <input type="radio" id="star42" name="ratingg" value="4" /><label htmlFor="star42" title="4 star"></label>
                <input type="radio" id="star32" name="ratingg" value="3" /><label htmlFor="star32" title="3 star"></label>
                <input type="radio" id="star22" name="ratingg" value="2" /><label htmlFor="star22" title="2 star"></label>
                <input type="radio" id="star12" name="ratingg" value="1" /><label htmlFor="star12" title="1 star"></label>
              </div>
              <input type="text" placeholder="Client Name" className="shadow-5in br-10" />
              <div className="position-relative mt-2">
                <textarea
                  placeholder="Enter your message" rows="10"
                  className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                  type="text">
                </textarea>
                <span className="position-absolute top-20 start-16 ">
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                </span>
              </div>
            </NavItem>
            <NavItem as="li" className="col-lg-4">
              <div className="circleIcon shadow-5in bg">
                <div className="Icon-Div">
                  <div className='mx-auto fileTab h-100'>
                    <label onClick={openSelect17} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage17 ? <img src={previewImage17} alt="Profile Picture" className="w-100 h-100" /> :
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBlue16 />
                            <p className="mb-2 h7">{t('profile_section.add_image')}</p>
                            <p className="h7 mb-0">200 X 200 <br /> Pixels</p>
                          </div>
                        }
                      </div>
                    </label>
                  </div>
                  <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                    let url = URL.createObjectURL(e.target.files[0])
                    setPreviewImage17(url);
                    setImage17(e.target.files[0])
                  }} ref={inputRef17} />
                </div>
              </div>
              <div class="starrating risingstar d-flex justify-content-center flex-row-reverse">
                <input type="radio" id="star53" name="rate" value="5" /><label htmlFor="star53" title="5 star"></label>
                <input type="radio" id="star43" name="rate" value="4" /><label htmlFor="star43" title="4 star"></label>
                <input type="radio" id="star33" name="rate" value="3" /><label htmlFor="star33" title="3 star"></label>
                <input type="radio" id="star23" name="rate" value="2" /><label htmlFor="star23" title="2 star"></label>
                <input type="radio" id="star13" name="rate" value="1" /><label htmlFor="star13" title="1 star"></label>
              </div>
              <input type="text" placeholder="Client Name" className="shadow-5in br-10" />
              <div className="position-relative mt-2">
                <textarea
                  placeholder="Enter your message" rows="10"
                  className="shadow-5in br-bottom-15 mb-4 w-100 h-250 ps-5 pt-4"
                  type="text">
                </textarea>
                <span className="position-absolute top-20 start-16 ">
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                </span>
              </div>
            </NavItem>
          </ul>
        </div>
      </div> */}
      {/* <div className="row mt-4">
        <div className="col-lg-3">
          <div className="LivePreview h-286 br-20 mx-auto p-20 w-100 d-flex justify-content-center align-items-center flex-column">
            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.6 15.0992H15.6L11.46 9.69922L8.7 13.2992L6.84 10.8992L3.6 15.0992ZM2.4 19.8992C1.74 19.8992 1.1748 19.664 0.704402 19.1936C0.234002 18.7232 -0.000797963 18.1584 2.03735e-06 17.4992V3.09922C2.03735e-06 2.43922 0.235202 1.87402 0.705602 1.40362C1.176 0.933221 1.7408 0.698421 2.4 0.699221H16.8C17.46 0.699221 18.0252 0.934421 18.4956 1.40482C18.966 1.87522 19.2008 2.44002 19.2 3.09922V8.49922L24 3.69922V16.8992L19.2 12.0992V17.4992C19.2 18.1592 18.9648 18.7244 18.4944 19.1948C18.024 19.6652 17.4592 19.9 16.8 19.8992H2.4Z" fill="#1C1C1C" />
            </svg>
            <p className="mb-0 mb-3">{t('profile_section.upload_video')}</p>
            <input type="text" placeholder="Video Link Here" className="shadow-5in h-44 br-10 mb-3" />
            <input type="text" placeholder={t('profile_section.enter_video_title_here')} className="shadow-5in h-44 br-10" />
          </div>
        </div>

        <div className="col-lg-3">
          <div className="LivePreview h-138 mb-2 br-20 mx-auto p-20 w-100 d-flex justify-content-center align-items-center flex-column">
          
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0.800781V24.8008H24V0.800781H0ZM2.18182 2.9826H21.8182V22.619H2.18182V2.9826ZM7.63636 5.16442L10.7051 9.11896L7.63636 17.5735L12 21.5281L16.3636 17.5735L13.2949 9.11896L16.3636 5.16442H7.63636Z" fill="#1C1C1C" />
            </svg>
            <p className="mb-0 mb-2 mt-2 h8">Book me Now </p>
            <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in h-44 br-10" />
          </div>
          <div className="LivePreview h-138 mt-2 br-20 mx-auto p-20 w-100 d-flex justify-content-center align-items-center flex-column">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0.800781V24.8008H24V0.800781H0ZM2.18182 2.9826H21.8182V22.619H2.18182V2.9826ZM7.63636 5.16442L10.7051 9.11896L7.63636 17.5735L12 21.5281L16.3636 17.5735L13.2949 9.11896L16.3636 5.16442H7.63636Z" fill="#1C1C1C" />
            </svg>
            <p className="mb-0 mb-2 mt-2 h8">{t('profile_section.hire_me')}</p>
            <input type="text" placeholder={t('profile_section.paste_link_here')} className="shadow-5in h-44 br-10" />
          </div>
        </div>
        <div className='col-lg-6'>
          <div className="profileUpdate">
            <div className="row mx-0 px-5 accomplishSec ">
              <div className="col-lg-12">
                <h5 className="mb-4 pb-4">{t('profile_section.enter_season_or_series')} </h5>
                <RangeSlider
                  value={value}
                  onChange={(changeEvent) =>
                    setValue(changeEvent.target.value)
                  }
                  min={0}
                  max={10}
                />
              </div>
              <div className="col-lg-12">
                <h5 className="mb-4  pb-0">{t('profile_section.enter_episode')}</h5>
                <RangeSlider
                  value={value1}
                  onChange={(changeEvent) =>
                    setValue1(changeEvent.target.value)
                  }
                  min={0}
                  max={10}
                />
              </div>
              <div className="col-lg-12 mt-4">
                <input type="text" placeholder={t('profile_section.enter_podcast_title')} className="shadow-5in br-10" />
              </div>
            </div>
          </div>
        </div>
      </div>  */}
      <div className="mt-5 text-center">
        <ButtonBox boxwidth="w-140 mt-0 mb-50 mx-auto" name="Save" className="next action-button" value="Save" clicked="Saved" />
      </div>
    </div >
  )
}