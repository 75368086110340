import React from 'react';
import '../../../global/timelineTab/TimelineTab.scss';
import Video from '../../../../images/iconVid.png';
import VideoPopup from '../../../global/VideoPopup';
import Video1 from '../../../../images/iconVid1.png';
import ButtonBox from "../../../global/ButtonBox";
// import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import EmailSignatureForm from './EmailSignatureForm';
import EmailSignatureForm1 from './EmailSignatureForm1';
import EmailSignatureForm2 from './EmailSignatureForm2';
import EmailSignatureForm3 from './EmailSignatureForm3';
import { useTranslation } from "react-i18next";
import EmailSignatureForm4 from './EmailSignatureForm4';
import EmailSignatureForm5 from './EmailSignatureForm5';
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';
export default function EmailSignatureStep() {
    const { t } = useTranslation();
    let {isVideoVisible} = useSelector(state => state.videoReducer);
    const dispatch = useDispatch();
    const handleClick6 = () => {
      dispatch(toggleVideo());
    };
    return (
        <div>
            <Tab.Container  defaultActiveKey="">
                <div className='' >
                    <div className='flex'>

                       
                        <div className='width-fll'>
                            <VideoPopup />
                            <Tab.Content className='class-scroll  ' >
                    
                           
                                    <div className="w-780  align-items-center mx-auto d-flex flex-column-reverse justify-content-between three-bsiness">
                                        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto ">
                                        <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
                                            <div className="tab-content" id="myTabContentsss">
                                                <div className='tab-pane active' id="custom_email">
                                                    <div className='h3-border mt-3'>
                                                        <div className='flex flex-diction  none' >
                                                            <h5>{t('timeline_form_six_component.your_custom_email_address')}</h5>
                                                        </div>
                                                    </div>
                                                    <EmailSignatureForm />
                                                </div>
                                                <div className='tab-pane' id="generate_social_links">
                                                    <EmailSignatureForm1 />
                                                </div>
                                                <div className='tab-pane' id="generate_social_links1">
                                                    <div className='h3-border mt-3'>
                                                        <div className='flex flex-diction  none' >
                                                            <h5>{t('timeline_form_seven_component.create_your_email_signature')}</h5>
                                                        </div>
                                                    </div>
                                                    <EmailSignatureForm2 />
                                                </div>
                                                <div class="tab-pane px-3" id="email_signature1" role="tabpanel" aria-labelledby="#email_signature1">
                                                    <div className='h3-border mt-3'>
                                                        <div className='flex flex-diction' >
                                                            <h5>Create Your “Email Signature</h5>
                                                        </div>
                                                    </div>
                                                    <EmailSignatureForm3 />
                                                </div>
                                                <div class="tab-pane px-3" id="email_signature2" role="tabpanel" aria-labelledby="#email_signature2">
                                                    <div className='h3-border mt-3'>
                                                        <div className='flex flex-diction' >
                                                            <h5>Create Your “Email Signature</h5>
                                                        </div>
                                                    </div>
                                                    <EmailSignatureForm4 />
                                                </div>
                                                <div class="tab-pane px-3" id="email_signature3" role="tabpanel" aria-labelledby="#email_signature3">
                                                    <div className='h3-border mt-3'>
                                                        <div className='flex flex-diction' >
                                                            <h5>Create Your “Email Signature</h5>
                                                        </div>
                                                    </div>
                                                    <EmailSignatureForm5 />
                                                </div>
                                            </div>
                                        </div>
                                        <span className="d-flex align-items-center justify-content-between w-100 mb-4">
                                            <div id="myTab1" className='flex' role="tablist">
                                                <button className="buttonArrow me-3 nav-link text-body border-0 bg br-10 active" id="custom_email" data-bs-toggle="tab" data-bs-target="#custom_email" type="button" role="tab" aria-controls="custom_email" aria-selected="true">
                                                    {/* <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" /> */}1
                                                </button>
                                                <button className="buttonArrow me-3 text-body nav-link border-0 bg br-10" id="generate_social_links" data-bs-toggle="tab" data-bs-target="#generate_social_links" type="button" role="tab" aria-controls="generate_social_links" aria-selected="false">
                                                    {/* <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" /> */}2
                                                </button>
                                                <button className="buttonArrow me-3 text-body nav-link border-0 bg br-10" id="generate_social_links1" data-bs-toggle="tab" data-bs-target="#generate_social_links1" type="button" role="tab" aria-controls="generate_social_links1" aria-selected="false">
                                                    {/* <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" /> */}3
                                                </button>
                                                <button className="buttonArrow me-3 text-body nav-link border-0 bg br-10" id="email_signature1" data-bs-toggle="tab" data-bs-target="#email_signature1" type="button" role="tab" aria-controls="email_signature1" aria-selected="false">
                                                    {/* <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" /> */}4
                                                </button>
                                                <button className="buttonArrow me-3 text-body nav-link border-0 bg br-10" id="email_signature2" data-bs-toggle="tab" data-bs-target="#email_signature2" type="button" role="tab" aria-controls="email_signature2" aria-selected="false">
                                                    {/* <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" /> */}5
                                                </button>
                                                <button className="buttonArrow me-3 text-body nav-link border-0 bg br-10" id="email_signature3" data-bs-toggle="tab" data-bs-target="#email_signature3" type="button" role="tab" aria-controls="email_signature3" aria-selected="false">
                                                    {/* <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" /> */}6
                                                </button>
                                            </div>
                                            <div className='btn-save-hide'>
                                                <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="" icon={faFloppyDisk} name="Save" />
                                            </div>

                                        </span>
                                    </div>
                            </Tab.Content>


                        </div>
                    </div>
                </div>





            </Tab.Container>
          
        </div>
    )
}