import React from "react";
import { Link } from "react-router-dom";

import user from "../../images/img1.png";
import tag from "../../images/tag.png";
import flag from "../../images/flag.png";
import status from "../../images/status.png";

import KNOWLEDGE from "../../images/rightbar/img1.png";
import COMPANY from "../../images/rightbar/img2.png";
import VENTURE from "../../images/rightbar/img3.png";
import BUSINESS from "../../images/rightbar/img4.png";
import SUBSCRIPTION from "../../images/rightbar/img5.png";
import CHALLENGE from "../../images/rightbar/img6.png";
import MARKETING from "../../images/rightbar/img7.png";
import COMMERCE from "../../images/rightbar/img8.png";
import MAKE from "../../images/rightbar/img9.png";
import ALL from "../../images/rightbar/img10.png";
import PROFILE from "../../images/rightbar/img11.png";
import NICHE from "../../images/rightbar/img12.png";
import WEBSITES from "../../images/rightbar/img13.png";
import PROMOS from "../../images/rightbar/img14.png";
import ROADMAP from "../../images/rightbar/img15.png";
import ASSETS from "../../images/rightbar/img16.png";
import FEEDBACK from "../../images/rightbar/img17.png";
import ADS from "../../images/rightbar/img18.png";
import SEO from "../../images/rightbar/img19.png";
import TERMS from "../../images/rightbar/img20.png";
import CAREER from "../../images/rightbar/img21.png";
import SUPPORT from "../../images/rightbar/img22.png";
import CONTACT from "../../images/rightbar/img23.png";

import crown from "../../images/profileicon/img1.svg";
import fb from "../../images/profileicon/img2.svg";
import ins from "../../images/profileicon/img3.svg";
import twi from "../../images/profileicon/img4.svg";
import linked from "../../images/profileicon/img5.svg";
import pine from "../../images/profileicon/img6.svg";
import tube from "../../images/profileicon/img7.svg";
import tok from "../../images/profileicon/img8.svg";
import check from "../../images/profileicon/img10.svg";
import canda from "../../images/profileicon/img11.svg";
import vip from "../../images/profileicon/img12.svg";
import whts from "../../images/profileicon/img13.svg";
import { NavItem } from 'react-bootstrap';



import SidebarButton from "../layout/SidebarButton";
import ImageBox from "./ImageBox";

export default function RightSideBar() {
  return (
    <div>
      <div className="collapse navbar-collapse" id="rightBar">
        <div className="position-absolute bg end-0 vh-100 sidebar-area border-start w-300">
          <div className="overflow-overlay h-100 position-relative">
            <div className="dividerSidebar bg mx-auto"></div>
            <div className="ImgArea newimg">
              {/* <div className="user position-relative mx-auto">
                <ImageBox boxwidth="w-65 userImg" src={user} />
                <div className="tag top-0 position-absolute">
                  <ImageBox boxwidth="w-16" src={tag} />
                </div>
                <div className="flag position-absolute">
                  <ImageBox boxwidth="w-24" src={flag} />
                </div>
                <div className="status bottom-0 end-0 position-absolute">
                  <ImageBox boxwidth="w-19" src={status} />
                </div>
              </div>
              <h6 className="text-uppercase mx-auto mt-2">dyna appolon</h6> */}
              <div className="imgin shadow-4 rounded-circle position-relative wrapimg">
                <ImageBox boxwidth="w-65 userImg" src={user} />
                <Link to="" className="icon1"><img src={fb} alt=""/></Link>
                <Link to="" className="icon2"><img src={ins} alt=""/></Link>
                <Link to="" className="icon3"><img src={twi} alt=""/></Link>
                <Link to="" className="icon4"><img src={linked} alt=""/></Link>
                <Link to="" className="icon5"><img src={pine} alt=""/></Link>
                <Link to="" className="icon6"><img src={tube} alt=""/></Link>
                <Link to="" className="icon7"><img src={tok} alt=""/></Link>
                <Link to="" className="icon8"><img src={check} alt=""/></Link>
                <Link to="" className="icon9"><img src={canda} alt=""/></Link>
                <Link to="" className="icon10"><img src={vip} alt=""/></Link>
                <Link to="" className="icon11"><img src={crown} alt=""/></Link>
                <Link to="" className="icon12"><img src={whts} alt=""/></Link>
              </div>
              <span className="btnone"><h6>Dyna Appolon</h6></span>
              <span className="btntwo"><h6>CEO | Founder | 9 to 5 Escapee</h6></span>
            </div>
            <div className="collapse SideBar d-block bg">
              <SidebarButton src={KNOWLEDGE} Text="KNOWLEDGE BASE MGMT" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">FAQS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">TIPS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">TRICKS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">TUTORIAL VIDEOS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">GUIDES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">FREQUENTLY ASK QUESTIONS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">SPECIFIC BLOGS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ILLUSTRATIONS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">INFOGRAPHICS</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={COMPANY} Text="COMPANY MGMT" />
              <SidebarButton src={VENTURE} Text="6-7 FIGURE BUSINESS VENTURE" />
              <SidebarButton src={BUSINESS} Text="BUSINESS | MEMBERSHIP PLAN" />
              <SidebarButton src={SUBSCRIPTION} Text="E-MAGAZINES SUBSCRIPTION PLAN" />
              <SidebarButton src={CHALLENGE} Text="3-HOUR BUSINESS LAUNCH CHALLENGE" />
              <SidebarButton src={MARKETING} Text="30-DAY MARKETING CHALLENGE" />

              <SidebarButton src={COMMERCE} Text="E-COMMERCE MARKETPLACE" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">DASHBOARD</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW STORE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL STORES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW PRODUCT</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW OFFER</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL PRODUCTS | ARCHIVES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">PRODUCT DETAILS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW COUPON</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">SHOPPING CART</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL WISHLISTS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CHECKOUT</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL ORDERS</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={MAKE} Text="MAKE Up To $5K- N - 90 DAYS" />
              <SidebarButton src={ALL} Text="3ALL COMPANY  STATS" />
              <SidebarButton src={PROFILE} Text="PROFILE NICHE SALES & STATS" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">FASHION  | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">BEAUTY | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">HOME DECOR | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">COACHING | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">NUTRITION | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">fitness | nich</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">TRAVEL | NICHE </Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">event planning | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">SPIRITUALITY | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">FINANCE | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">parenting | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ANIMAL LOVER | NICHE</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={NICHE} Text="12 NICHE BLOG SITES MGMT" />
              <SidebarButton src={WEBSITES} Text="ALL MASTER WEBSITES MGMT" />
              <SidebarButton src={PROMOS} Text="EVENTS | PROMOS | MGMT" />
              <SidebarButton src={ROADMAP} Text="SEO - ROADMAP STRATEGY" />
              <SidebarButton src={ASSETS} Text="COMPANY BRAND ASSETS MGMT" />
              <SidebarButton src={FEEDBACK} Text="COMPANY FEEDBACK | MGMT" />
              <SidebarButton src={ADS} Text="COMPANY ADS MGMT" />
              <SidebarButton src={SEO} Text="COMPANY SEO MGMT" />
              <SidebarButton src={TERMS} Text="LEGAL TERMS & CONDITIONS MGMT" />
              <SidebarButton src={CAREER} Text="CAREER | JOB POSTINGS MGMT" />
              <SidebarButton src={SUPPORT} Text="SUPPORT MGMT:  OPEN A TICKET " />
              <SidebarButton src={CONTACT} Text="CONTACT US" />
            </div>
          </div>
        </div>
      </div>
      <div className="position-absolute bg end-0 vh-100 sidebar-area border-start">
          <div className="overflow-overlay h-100 position-relative">
            <div className="dividerSidebar bg mx-auto"></div>
            <div className="ImgArea newimg">
              {/* <div className="user position-relative mx-auto">
                <ImageBox boxwidth="w-65 userImg" src={user} />
                <div className="tag top-0 position-absolute">
                  <ImageBox boxwidth="w-16" src={tag} />
                </div>
                <div className="flag position-absolute">
                  <ImageBox boxwidth="w-24" src={flag} />
                </div>
                <div className="status bottom-0 end-0 position-absolute">
                  <ImageBox boxwidth="w-19" src={status} />
                </div>
              </div>
              <h6 className="text-uppercase mx-auto mt-2">dyna appolon</h6> */}
              <div className="imgin shadow-4 rounded-circle position-relative">
                <ImageBox boxwidth="w-65 userImg" src={user} />
                <Link to="" className="icon1"><img src={fb} alt=""/></Link>
                <Link to="" className="icon2"><img src={ins} alt=""/></Link>
                <Link to="" className="icon3"><img src={twi} alt=""/></Link>
                <Link to="" className="icon4"><img src={linked} alt=""/></Link>
                <Link to="" className="icon5"><img src={pine} alt=""/></Link>
                <Link to="" className="icon6"><img src={tube} alt=""/></Link>
                <Link to="" className="icon7"><img src={tok} alt=""/></Link>
                <Link to="" className="icon8"><img src={check} alt=""/></Link>
                <Link to="" className="icon9"><img src={canda} alt=""/></Link>
                <Link to="" className="icon10"><img src={vip} alt=""/></Link>
                <Link to="" className="icon11"><img src={crown} alt=""/></Link>
                <Link to="" className="icon12"><img src={whts} alt=""/></Link>
              </div>
            </div>
            <div className="collapse SideBar d-block bg">
              <SidebarButton src={KNOWLEDGE} Text="KNOWLEDGE BASE MGMT" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">FAQS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">TIPS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">TRICKS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">TUTORIAL VIDEOS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">GUIDES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">FREQUENTLY ASK QUESTIONS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">SPECIFIC BLOGS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ILLUSTRATIONS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">INFOGRAPHICS</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={COMPANY} Text="COMPANY MGMT" />
              <SidebarButton src={VENTURE} Text="6-7 FIGURE BUSINESS VENTURE" />
              <SidebarButton src={BUSINESS} Text="BUSINESS | MEMBERSHIP PLAN" />
              <SidebarButton src={SUBSCRIPTION} Text="E-MAGAZINES SUBSCRIPTION PLAN" />
              <SidebarButton src={CHALLENGE} Text="3-HOUR BUSINESS LAUNCH CHALLENGE" />
              <SidebarButton src={MARKETING} Text="30-DAY MARKETING CHALLENGE" />

              <SidebarButton src={COMMERCE} Text="E-COMMERCE MARKETPLACE" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">DASHBOARD</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW STORE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL STORES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW PRODUCT</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW OFFER</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL PRODUCTS | ARCHIVES</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">PRODUCT DETAILS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CREATE A NEW COUPON</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">SHOPPING CART</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL WISHLISTS</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">CHECKOUT</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ALL ORDERS</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={MAKE} Text="MAKE Up To $5K- N - 90 DAYS" />
              <SidebarButton src={ALL} Text="3ALL COMPANY  STATS" />
              <SidebarButton src={PROFILE} Text="PROFILE NICHE SALES & STATS" id="dropSection" />
              <div className="subBar dropdown px-4 py-3">
                <ul>
                  <NavItem as="li"><Link className="br-10" to="#">FASHION  | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">BEAUTY | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">HOME DECOR | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">COACHING | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">NUTRITION | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">fitness | nich</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">TRAVEL | NICHE </Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">event planning | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">SPIRITUALITY | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">FINANCE | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">parenting | NICHE</Link></NavItem>
                  <NavItem as="li"><Link className="br-10" to="#">ANIMAL LOVER | NICHE</Link></NavItem>
                </ul>
              </div>
              <SidebarButton src={NICHE} Text="12 NICHE BLOG SITES MGMT" />
              <SidebarButton src={WEBSITES} Text="ALL MASTER WEBSITES MGMT" />
              <SidebarButton src={PROMOS} Text="EVENTS | PROMOS | MGMT" />
              <SidebarButton src={ROADMAP} Text="SEO - ROADMAP STRATEGY" />
              <SidebarButton src={ASSETS} Text="COMPANY BRAND ASSETS MGMT" />
              <SidebarButton src={FEEDBACK} Text="COMPANY FEEDBACK | MGMT" />
              <SidebarButton src={ADS} Text="COMPANY ADS MGMT" />
              <SidebarButton src={SEO} Text="COMPANY SEO MGMT" />
              <SidebarButton src={TERMS} Text="LEGAL TERMS & CONDITIONS MGMT" />
              <SidebarButton src={CAREER} Text="CAREER | JOB POSTINGS MGMT" />
              <SidebarButton src={SUPPORT} Text="SUPPORT MGMT:  OPEN A TICKET " />
              <SidebarButton src={CONTACT} Text="CONTACT US" />
            </div>
            <div className="overlayer1" type="button" data-bs-toggle="collapse" data-bs-target="#rightBar" aria-controls="rightBar" aria-expanded="false" aria-label="Toggle navigation"></div>
          </div>
      </div>
    </div>
  );
}
