import React, { useState } from "react";
import img1 from '../../../../../../images/StepZero/img13.png';
import img2 from '../../../../../../images/StepZero/img14.png';
import img3 from '../../../../../../images/StepZero/img15.png';
import img4 from '../../../../../../images/StepZero/img16.png';
import { useTranslation } from "react-i18next";
export default function NicheOne() {
  const { t } = useTranslation();
  const [distinctive, setDistinctive] = useState("dyna");
  const [descriptive, setDescriptive] = useState("fashion");
  const handleDescriptiveChange = (newDescriptive) => {
    setDescriptive(newDescriptive);
  };
  const [legal, setLegal] = useState("INC");
  const handleLegalChange = (newLegal) => {
    setLegal(newLegal);
  };
  return (
    <div className="formleft h-300">
      <img src={img1} alt="" height="140px" className="mw-129" />
      <span className="w-100">
        <h3>9 to 5 ESCAPEE, INC.</h3>
        <div className="d-flex justify-content-between align-items-start w-100">
          <span className="h4">
            {/* <h4>9 TO 5</h4> */}
            <h4>{distinctive}</h4>
            <img src={img2} alt="" />
            <p>Distinctive</p>
          </span>
          <span className="h5">
            <p>Descriptive</p>
            <img src={img3} alt="" />
            {/* <h5>ESCAPEE</h5> */}
            <h5>{descriptive}</h5>
          </span>
          <span className="h6">
            {/* <h6>INC.</h6> */}
            <h6>{legal}</h6>
            <img src={img4} alt="" />
            <p>Legal</p>
          </span>
        </div>
      </span>
    </div>
  )
}
