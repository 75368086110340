import React from "react";
import { useState } from "react";
import 'react-calendar/dist/Calendar.css'
import EventsSlider3 from "../components/EventsSlider3/EventsSlider3"
import Calendar from 'react-calendar'
import EventsTimeline3 from '../components/EventsTimeline3/EventsTimeline3'
import "./RegularTheme3Desktop.scss";
import "../regularTheme3Mobile/RegularTheme3Mobile.scss";
import "../regularTheme3Tab/RegularTheme3Tab.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

export default function RegularTheme3Desktop({ Regular, Neumorphic }) {
  const [isVisible, setIsVisible] = useState(false);
  const [value, onChange] = useState(new Date());


  return (
    <>
      <div
        className="main-content RegularTheme3Desktop h_1550"
        style={{
          background: Regular ? "#CBD5E0" : Neumorphic ? "#EFF4F8" : "",
        }}>
        <div className="text-center-desk">
          <p className="top_heading">MY EVENTS</p>
        </div>

        <div className='container mb-4 pb-3 mt-5 mt-lg-0'>
          <div className="row">
            <div className="col-2 eventTab3 px-0">
              <div id="myTab3" className='slidetab3' role="tablist">
                <EventsSlider3 Regular={Regular} Neumorphic={Neumorphic} />
              </div>
            </div>
            <div className="col-10">
              <div className="">
                <Calendar className="mx-auto" onChange={onChange} value={value} />
              </div>
              <div>
                <EventsTimeline3 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
