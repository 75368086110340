import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonBox from "../../../global/ButtonBox";
import "./TestimonialCaseStudySectionThemes.scss";
import tab_icon from "../../../../images/Tab-icon.png";
import mobile_icon from "../../../../images/mobile-icon.png";
import { faDesktop, faLock } from "@fortawesome/free-solid-svg-icons";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

import RegularTheme1 from "./regularTheme1/RegularTheme1.js";
import "./regularTheme1/RegularTheme1.scss";

import RegularTheme2 from "./regularTheme2/RegularTheme2.js";
import "./regularTheme2/RegularTheme2.scss";

import RegularTheme3 from "./regularTheme3/RegularTheme3.js";
import "./regularTheme3/RegularTheme3.scss";

import RegularTheme4 from "./regularTheme4/RegularTheme4.js";
import "./regularTheme4/RegularTheme4.scss";

import RegularTheme5 from "./regularTheme5/RegularTheme5.js";
import "./regularTheme5/RegularTheme5.scss";

export default function TestimonialCaseStudySectionRegularThemeDesktopView1(
  props
) {
  let renderedComponentDesktop;
  let renderedComponentTab;
  let renderedComponentMobile;

  if (props.themeType === "Regular") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="desktop-main-container">
          <RegularTheme1 screenMode="Desktop" theme="Regular" />
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="tablet-main-container">
          <RegularTheme1 screenMode="Tab" theme="Regular" />
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="mobile-main-container">
          <RegularTheme1 screenMode="Mobile" theme="Regular" />
        </div>
      </div>
    );
  } else if (props.themeType === "Neumorphic") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic-desktop-theme">
          <RegularTheme1 screenMode="Desktop" theme="Neumorphic" />
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic-tablet-main-container">
          <RegularTheme1 screenMode="Tab" theme="Neumorphic" />
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic-mobile-main-container">
          <RegularTheme1 screenMode="Mobile" theme="Neumorphic" />
        </div>
      </div>
    );
  } else if (props.themeType === "Glassmorphic") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="glassmorphic-desktop-theme">
          <RegularTheme1 screenMode="Desktop" theme="Glassmorphic" />
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="glassmorphic-tablet-main-container">
          <RegularTheme1 screenMode="Tab" theme="Glassmorphic" />
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="glassmorphic-mobile-main-container">
          <RegularTheme1 screenMode="Mobile" theme="Glassmorphic" />
        </div>
      </div>
    );
  } else if (props.themeType === "Regular2") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="regular2-desktop-theme">
          <RegularTheme2 />
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="regular2-tab-theme">
          <RegularTheme2 />
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="regular2-mobile-theme">
          <RegularTheme2 />
        </div>
      </div>
    );
  } else if (props.themeType === "Neumorphic2") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic2-desktop-theme">
          <RegularTheme2 />
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic2-tab-theme">
          <RegularTheme2 />
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic2-mobile-theme">
          <RegularTheme2 />
        </div>
      </div>
    );
  } else if (props.themeType === "Glassmorphic2") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="glassmorphic2-desktop-theme">
          <RegularTheme2 />
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="glassmorphic2-tab-theme">
          <RegularTheme2 />
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="glassmorphic2-mobile-theme">
          <RegularTheme2 />
        </div>
      </div>
    );
  } else if (props.themeType === "Regular3") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="regular3-desktop-theme">
          <RegularTheme3 themeType="Regular" />
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="regular3-tab-theme">
          <RegularTheme3 themeType="Regular" />
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="regular3-mobile-theme">
          <RegularTheme3 themeType="Regular" />
        </div>
      </div>
    );
  } else if (props.themeType === "Neumorphic3") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic3-desktop-theme">
          <RegularTheme3 themeType="Neumorphic" />
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic3-tab-theme">
          <RegularTheme3 themeType="Neumorphic" />
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic3-mobile-theme">
          <RegularTheme3 themeType="Neumorphic" />
        </div>
      </div>
    );
  } else if (props.themeType === "Glassmorphic3") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="glassmorphic3-desktop-theme">
          <RegularTheme3 themeType="Glassmorphic" />
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="glassmorphic3-tab-theme">
          <RegularTheme3 themeType="Glassmorphic" />
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="glassmorphic3-mobile-theme">
          <RegularTheme3 themeType="Glassmorphic" />
        </div>
      </div>
    );
  } else if (props.themeType === "Regular4") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="regular4-desktop-theme">
          <RegularTheme4 themeType="Regular" />
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="regular4-tab-theme">
          <RegularTheme4 themeType="Regular" />
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="regular4-mobile-theme">
          <RegularTheme4 themeType="Regular" />
        </div>
      </div>
    );
  }else if (props.themeType === "Neumorphic4") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic4-desktop-theme">
        <RegularTheme4 themeType="Neumorphic"  screenMode='first'/>
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic4-tab-theme">
        <RegularTheme4 themeType="Neumorphic"  screenMode='second'/>
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic4-mobile-theme">
          <RegularTheme4 themeType="Neumorphic"  screenMode='three'/>
        </div>
      </div>
    );
  }else if (props.themeType === "Glassmorphic4") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="glassmorphic4-desktop-theme">
        <RegularTheme4 themeType="Glassmorphic"  screenMode='first'/>
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="glassmorphic4-tab-theme">
        <RegularTheme4 themeType="Glassmorphic"  screenMode='second'/>
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="glassmorphic4-mobile-theme">
          <RegularTheme4 themeType="Glassmorphic"  screenMode='three'/>
        </div>
      </div>
    );
  }else if (props.themeType === "Regular5") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="regular5-desktop-theme">
          <RegularTheme5 themeType="Regular" />
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="regular5-tab-theme">
          <RegularTheme5 themeType="Regular" />
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="regular5-mobile-theme">
          <RegularTheme5 themeType="Regular" />
        </div>
      </div>
    );
  }else if (props.themeType === "Neumorphic5") {
    renderedComponentDesktop = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic5-desktop-theme">
          <RegularTheme5 themeType="Neumorphic" />
        </div>
      </div>
    );
    renderedComponentTab = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic5-tab-theme">
          <RegularTheme5 themeType="Neumorphic" />
        </div>
      </div>
    );
    renderedComponentMobile = (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="neumorphic5-mobile-theme">
          <RegularTheme5 themeType="Neumorphic" />
        </div>
      </div>
    );
  }


  ///////////////////////////////
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });

  return (
    <div>
      <h4 className="text-center">Switch the Style</h4>

      <Tab.Container id="" defaultActiveKey="Regular">
        <div className="d-flex flex-column-reverse">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={props.screenMode}
          >
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {renderedComponentDesktop}
                <div className="w-100 d-flex justify-content-center align-items-center banner-btn">
                  <ButtonBox
                    boxwidth="w-150 mt-5 lg text-primary mb-4 btn-left"
                    clicked="Sacing"
                    name="Save/Next"
                  />
                  <ButtonBox
                    boxwidth="w-200 mt-5 lg text-primary mb-4 ml-3 "
                    clicked="Sacing"
                    name="Close and continue"
                  />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                {renderedComponentTab}
                <div className="w-100 d-flex justify-content-center align-items-center banner-btn">
                  <ButtonBox
                    boxwidth="w-150 mt-5 lg text-primary mb-4 btn-left"
                    clicked="Sacing"
                    name="Save/Next"
                  />
                  <ButtonBox
                    boxwidth="w-200 mt-5 lg text-primary mb-4 ml-3 "
                    clicked="Sacing"
                    name="Close and continue"
                  />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="three">
                {renderedComponentMobile}
                <div className="w-100 d-flex justify-content-center align-items-center banner-btn">
                  <ButtonBox
                    boxwidth="w-150 mt-5 lg text-primary mb-4 btn-left"
                    clicked="Sacing"
                    name="Save/Next"
                  />
                  <ButtonBox
                    boxwidth="w-200 mt-5 lg text-primary mb-4 ml-3 "
                    clicked="Sacing"
                    name="Close and continue"
                  />
                </div>
              </Tab.Pane>
            </Tab.Content>
            <div className="d-flex align-items-center justify-center  w-100 mb-0 ">
              <Nav
                variant="pills"
                className="w-100 d-flex  align-items-center justify-content-center"
              >
                <ul className="d-flex   my-4 pb-2 align-items-center modal-tabs justify-content-center">
                  <Nav.Item as="li" className="me-0">
                    <Nav.Link
                      className="m-0 p-0 border-0 design-one-size"
                      eventKey="first"
                    >
                      <span className="d-flex align-items-center ">
                        <FontAwesomeIcon
                          className="icon-design"
                          icon={faDesktop}
                        ></FontAwesomeIcon>{" "}
                        Desktop
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    as="li"
                    className="margin-left-scnd margin-left-btn"
                  >
                    <Nav.Link
                      className="m-0 p-0 border-0 design-one-size"
                      eventKey="second"
                    >
                      <span className="d-flex align-items-center ">
                        {" "}
                        <div className="icon-design">
                          <img src={tab_icon} alt="icon-tab"></img>
                        </div>{" "}
                        Tab
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    as="li"
                    className="margin-left-scnd margin-left-btn"
                  >
                    <Nav.Link
                      className="m-0 p-0 border-0 design-one-size"
                      eventKey="three"
                    >
                      <span className="d-flex align-items-center ">
                        <div className="icon-design">
                          <img src={mobile_icon} alt="icon-mobile"></img>
                        </div>
                        Mobile
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                </ul>
              </Nav>
            </div>
          </Tab.Container>
        </div>
      </Tab.Container>
    </div>
  );
}
