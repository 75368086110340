import React from "react";
import "./HireMeMainMob.scss";
import box from "../../../../../../../images/hire-me/desktopview/boximage.png";

const HireMeMainMob = ({ Regular, Neumorphic }) => {
  return (
    <>
      <div
        className={`main-container-mob-comp d-flex justify-content-around pt-4 ${
          Regular ? "Regular-style " : Neumorphic ? "Neumorphic-style" : ""
        }`}
      >
        <div className="picture-box">
          <img src={box} className="box-image" alt="boximage" />
        </div>
        <div className="after-picturebox">
          <p className="first-p">MODELING AGENCY</p>
          <p className="sec-p">
            Lobortis feugiat vivamus at augue lobortis in.
          </p>
          <p className="third-p">
            Tempor orci dapibus ultrices in iaculis nunc. Volutpat odio
            facilisis mauris sit amet massa vitae. Gravida in fermentum et
            sollicitudin. Turpis in eu mi bibendum neque egestas congue. Gravida
            in fermentum et sollicitudin ac orci. Ipsum a arcu cursus
          </p>
        </div>
      </div>
    </>
  );
};

export default HireMeMainMob;
