import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import dark_book_btn from '../../../../../images/dark-book-btn.png';
import book_btn from '../../../../../images/met-book.png';
import glass_btn from '../../../../../images/glass-btn.png';
import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';
import {faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import {  RiCloseLine,  } from "react-icons/ri";

import {  RxMagnifyingGlass } from "react-icons/rx";

import { useTranslation } from "react-i18next";



export default function SearchbarComponent() {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  
  



  return (
    <div>



      <div className="">
  
 
                  <div className="search-position">
                    <li onClick={handleClick}>
                      <FontAwesomeIcon className="fa-magnify" icon={faMagnifyingGlass}></FontAwesomeIcon>

                    </li>
                    {isVisible && (
                      <div className="searchbox">
                        <div className='search-area w-100'>
                          <button className="close-btn" onClick={handleClose}>
                            <RiCloseLine />
                          </button>
                          <form autoComplete="off">
                            <div className='search-field d-flex justify-content-between align-items-center pe-4 ms-auto'>
                              <input placeholder="Search" className="border-0" type="text" />
                              <RxMagnifyingGlass className="icon-mgni" />

                              <div>

                              </div>
                            </div>
                          </form>
                          <button class="search-me-btn w-50">Search</button>
                          <div className=" text-center  meu-show mt-2" >
                            <img src={book_btn} alt="bookbtn" ></img>

                          </div>
                          <div className=" text-center dark-book-btn  dark-meu-show mt-2" >
                            <img src={dark_book_btn} alt="bookbtn" ></img>

                          </div>
                          <div className=" glass-show" >
                            <img src={glass_btn} alt="bookbtn" ></img>

                          </div>

                        </div>

                      </div>
                    )}
                  </div>




               
      </div>




    </div>



  );
}
