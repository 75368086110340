import React from "react";
import ButtonBox from "../../../../global/ButtonBox";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { NavItem } from 'react-bootstrap';
import ButtonBoxNew from "../../../../global/ButtonBoxNew";
import DropdownBox from "../../../../global/DropdownBox";
export default function FactorOne() {
  const { t, i18n } = useTranslation();
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });
  // const options = [
  //   {
  //     value: "In which city were you born?",
  //     label: "In which city were you born?",
  //   },
  //   {
  //     value: "What is the name of your favorite pet?",
  //     label: "What is the name of your favorite pet?",
  //   },
  //   {
  //     value: "What is your mothers maiden name?",
  //     label: "What is your mothers maiden name?",
  //   },
  //   {
  //     value: "What is the last name of your first boyfriend?",
  //     label: "What is the last name of your first boyfriend?",
  //   },
  //   {
  //     value: "What is the name of your first pet?",
  //     label: "What is the name of your first pet?",
  //   },
  //   {
  //     value: "What high school did you attend?",
  //     label: "What high school did you attend?",
  //   },
  //   {
  //     value: "What was the name of your favourite teacher?",
  //     label: "What was the name of your favourite teacher?",
  //   },
  //   {
  //     value: "What was the make and model of your first car?",
  //     label: "What was the make and model of your first car?",
  //   },
  //   {
  //     value: "What was the name of your favourite teacher?",
  //     label: "What was the name of your favourite teacher?",
  //   },
  //   {
  //     value: "What was your favorite food as a child?",
  //     label: "What was your favorite food as a child?",
  //   },
  //   {
  //     value: "Where did you meet your spouse?",
  //     label: "Where did you meet your spouse?",
  //   },
  //   {
  //     value: "What year was your father (or mother) born?",
  //     label: "What year was your father (or mother) born?",
  //   },
  // ];
  const questionOptions =
  [{
      "name": "In which city were you born?",      
    }, {
      "name": "What is the name of your favorite pet?",
    }, {
      "name": "What is your mothers maiden name?",      
    }, {
      "name": "What is the last name of your first boyfriend?",      
    },{
      "name": "What is the name of your first pet?",      
    },{
      "name": "What high school did you attend?",      
    },{
      "name": "What was the name of your favourite teacher?",      
    },{
      "name": "What was the make and model of your first car?",      
    },{
      "name": "What was the name of your favourite teacher?",      
    },{
      "name": "What was your favorite food as a child?",      
    },{
      "name": "Where did you meet your spouse?",      
    },{
      "name": "What year was your father (or mother) born?",      
    },
  ];

  return (
    <div>
      <ul>
        <NavItem as="li">
          <label className="mb-2">{t('privacy_component.factor_authentication_component.confirm_current_password')}</label>
          <input
            placeholder={t('enter_current_password')}
            className="shadow-5in br-10 w-100 mb-4"
            type="password"
          />
          <p className="h6 mb-4">
          {t('privacy_component.factor_authentication_component.privacy_security')}
          </p>
        </NavItem>
        <NavItem as="li" className="mb-4 mt0">
          <div className=" position-relative">
            <label className="mb-2">{t('privacy_component.factor_authentication_component.security_question_one')}</label>
            {/* <Select isSearchable={false}
              options={options}
              className="country mb-4 w-100 mx-auto text-center"
              placeholder={t('privacy_component.factor_authentication_component.country')}
              theme={theme}
              styles={{
                option: (base) => ({
                  ...base,
                  borderRadius: "30px",
                  width: "94%",
                  maxheight: "50px",
                  lineHeight: "32px",
                  margin: "10px",
                  boxShadow: "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                  cursor: 'pointer',
                }),
              }}
            /> */}
            <DropdownBox name="Security Question" boxid="securityquestion" options={questionOptions} boxwidth="country mb-4 w-100 mx-auto" />
          </div>
          <div className="w-100 d-flex">
            <input
              placeholder={t('privacy_component.factor_authentication_component.toronto')}
              className="shadow-5in br-10 w-100 mb-4 me-2"
              type="text"
            />
            {/* <button className="h-50 bs-success br-30 w-110 h8 text-center border-0 shadow-4">
            {t('done')}
            </button> */}
            <ButtonBoxNew name="Done" boxwidth="w-110" />
          </div>
        </NavItem>
        <NavItem as="li" className="mb-4 mt0">
          <div className=" position-relative">
            <label className="mb-2">{t('privacy_component.factor_authentication_component.security_question_two')}</label>
            {/* <Select isSearchable={false}
              options={options}
              className="country mb-4 w-100 mx-auto text-center"
              placeholder={t('privacy_component.factor_authentication_component.country')}
              theme={theme}
              styles={{
                option: (base) => ({
                  ...base,
                  borderRadius: "30px",
                  width: "94%",
                  maxheight: "50px",
                  lineHeight: "32px",
                  margin: "10px",
                  cursor: 'pointer',
                  boxShadow:
                    "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                }),
              }}
            /> */}
            <DropdownBox name="Security Question" boxid="securityquestion" options={questionOptions} boxwidth="country mb-4 w-100 mx-auto" />
          </div>
          <div className="w-100 d-flex">
            <input
              placeholder= {t('privacy_component.factor_authentication_component.toronto')}
              className="shadow-5in br-10 w-100 mb-4 me-2"
              type="text"
            />
            {/* <button className="h-50 bs-success br-30 w-110 h8 text-center border-0 shadow-4">
            {t('done')}
            </button> */}
            <ButtonBoxNew name="Done" boxwidth="w-110" />
          </div>
        </NavItem>
        <NavItem as="li" className="mb-5 mt0">
          <div className=" position-relative">
            <label className="mb-2">{t('privacy_component.factor_authentication_component.security_question_three')}</label>
            {/* <Select isSearchable={false}
              options={options}
              className="country mb-4 w-100 mx-auto text-center"
              placeholder={t('privacy_component.factor_authentication_component.country')}
              theme={theme}
              styles={{
                option: (base) => ({
                  ...base,
                  borderRadius: "30px",
                  width: "94%",
                  maxheight: "50px",
                  lineHeight: "32px",
                  margin: "10px",
                  cursor: 'pointer',
                  boxShadow:
                    "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
                }),
              }}
            /> */}
            <DropdownBox name="Security Question" boxid="securityquestion" options={questionOptions} boxwidth="country mb-4 w-100 mx-auto" />
          </div>
          <div className="w-100 d-flex">
            <input
              placeholder={t('privacy_component.factor_authentication_component.toronto')}
              className="shadow-5in br-10 w-100 mb-4 me-2"
              type="text"
            />
            {/* <button className="h-50 bs-success br-30 w-110 h8 text-center border-0 shadow-4">
            {t('done')}
            </button> */}
            <ButtonBoxNew name="Done" boxwidth="w-110" />
          </div>
        </NavItem>
        <NavItem as="li" className=" text-center">
          <ButtonBox
            boxwidth="w-140 mt-0 mb-50 mx-auto"
            clicked="Saved"
            name="Save/Next"
          />
        </NavItem>
      </ul>
    </div>
  );
}
