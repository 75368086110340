import React from "react";
// import { Link } from 'react-router-dom';
import { ReactComponent as IconBlueq2 } from "../../../../../../images/icons/icon1.svg";
import { ReactComponent as IconBlueq3 } from "../../../../../../images/icons/icon30.svg";
import { ReactComponent as IconBlueq5 } from "../../../../../../images/icons/icon31.svg";
import { ReactComponent as IconBlueq4 } from "../../../../../../images/icons/icon2.svg";
import { ReactComponent as IconBlueq1 } from "../../../../../../images/listicon/icon21.svg";
import { ReactComponent as IconBlueq7 } from "../../../../../../images/icons/icon12.svg";
import { ReactComponent as IconBlueq8 } from "../../../../../../images/icons/icon6.svg";
import { ReactComponent as IconBlueq9 } from "../../../../../../images/icons/icon7.svg";
import { ReactComponent as IconBlueq10 } from "../../../../../../images/icons/icon8.svg";
import { ReactComponent as IconBlueq11 } from "../../../../../../images/icons/icon10.svg";
import ButtonBox from '../../../../../global/ButtonBox';
import { ReactComponent as IconBlueq12 } from "../../../../../../images/icons/icon33.svg";
import { ReactComponent as IconBlueq13 } from "../../../../../../images/icons/icon34.svg";
import { useTranslation } from "react-i18next";
import ButtonBoxNew from "../../../../../global/ButtonBoxNew";

export default function SocialPage4() {
  const { t, i18n } = useTranslation();


  return (
    <div className="">
      <h3 className="h8 mb-2 mt-4">{t('profile_section.preview_of_the_generated_bio')} </h3>
      <div className="shadow-4 br-20 p-20 gradient-box h-1250">
        <div className="iconspace pt-4">
          <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq2 /></span> <p className="m-0 lh-24 w-85s">{t('profile_section.dyna_appolon')}</p></div>
          <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq4 /></span> <p className="m-0 lh-24 w-85s">{t('profile_section.ceo_social_network_strategist')}</p></div>
          <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq3 /></span> <p className="m-0 lh-24 w-85s">{t('profile_section.help_people_quit_their')}</p></div>
          <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq7 /></span> <p className="m-0 lh-24 w-85s">{t('profile_section.helped_30000_quit_their_jobs_last_year')}</p></div>
          <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq5 /></span> <p className="m-0 lh-24 w-85s">{t('profile_section.quit_The_rat_race_how_to_quit_your_9_to_5_job_escape_the_9_5')}</p></div>
          <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq8 /></span> <p className="m-0 lh-24 w-85s">{t('profile_section._9to5job_quitthe9to5job_quittheratrace_escapethe9_5')}</p></div>
          <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq9 /></span> <p className="m-0 lh-24 w-85s">{t('profile_section.whatsApp_number')}</p></div>
          <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq10 /></span> <p className="m-0 lh-24 w-85s">{t('profile_section.www_9to5escapee_com')}</p></div>
          <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq12 /></span> <p className="m-0 lh-24 w-85s">{t('profile_section.help_9to5escapee_com')}</p></div>
          <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq11 /></span> <p className="m-0 lh-24 w-85s">{t('profile_section._30_minutes_free_consultation_to_learn_more')}</p></div>
          <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq13 /></span> <p className="m-0 lh-24 w-85s">{t('profile_section.check_Out_my_service_packages')}</p></div>
          <div className="mb-0 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq1 /></span> <p className="m-0 lh-24 w-85s">{t('profile_section.lets_partner_up')}</p></div>
        </div>
        <div className="text-center mt-40 mb-5 pb-5">
        {/* <ButtonBox boxwidth="w-140 mt-0 mb-50 mx-auto" clicked="Copied" name={t('profile_section.copy_bio')} /> */}
        <ButtonBoxNew boxwidth="w-140 mx-auto" name={t('profile_section.copy_bio')} />
          {/* <button className="h-50s bs-primary br-5 mx-auto w-200 fw-bold h7 text-uppercase text-center border-0 shadow-4 d-flex align-items-center justify-content-center mb-5">{t('profile_section.copy_bio')}</button>  */}
        </div>
      </div>
    </div>
  );
}
