import React, { useState, useEffect } from 'react';

import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';

import mic_img from '../../../../../images/mic-img.png'

import RangeSlider from "react-bootstrap-range-slider";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { MdSkipNext, MdSkipPrevious, } from "react-icons/md";

import { RiDownloadLine, RiPlayCircleFill, RiPlayMiniLine, RiShareForwardLine, RiFullscreenLine  } from "react-icons/ri";
import sond_right from '../../../../../images/sond-right.png';
import sond_left from '../../../../../images/sond-left.png';
import sond_1 from '../../../../../images/sond-1.png';
import sond_2 from '../../../../../images/sond-2.png';

import { RxGear,  } from "react-icons/rx";


import plalist_gold from '../../../../../images/plalist-gold.png'
import { useTranslation } from "react-i18next";



export default function PlaylistComponent() {
  const { t, i18n } = useTranslation();









  const [isOpen, setIsOpen] = useState(false);
  
    useEffect(() => {
      if (isOpen) {
        const timer = setTimeout(() => {
          setIsOpen(false);
        }, 5000);
  
        return () => clearTimeout(timer);
      }
    }, [isOpen]);
  

  const [Competency1, setCompetency1] = useState(10);

  return (
  



 
  
 

                 

        
                

                    <div className="playlist-section-text">
                      <div className="meu-playlist-section">
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="next-icon">
                            <MdSkipNext />
                          </div>
                          <div className="mic-img reg-img">
                            <img src={mic_img} alt="mic" ></img>

                          </div>
                          <div className="mic-img meu-img">
                            <img src={plalist_gold} alt="mic" ></img>

                          </div>
                          <div className="next-icon">
                            <MdSkipPrevious />
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center " >
                          <div className="slidecontainer mb- text-start">
                            <RangeSlider value={Competency1} onChange={changeEvent => setCompetency1(changeEvent.target.value)} min={0} max={15} />
                          </div>
                          <div className="next-icon-center">
                            <RiDownloadLine className="iconss" />
                          </div>
                          <div className="video-icon reg-icon">
                            <RiPlayCircleFill />
                          </div>
                          <div className=" meu-icon">
                            <div className="video-icon">
                              <RiPlayMiniLine />
                            </div>

                          </div>
                          <div className="next-icon-center">
                            <RiShareForwardLine className="iconss" />
                          </div>
                          <div className="setting-icons ">
                            <RxGear className="icons-set" />
                            <RiFullscreenLine className="icons-set" />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center justify-content-center ">
                        <div className="reg-sond">
                          <img src={sond_right} alt="" ></img>
                        </div>
                        <div className="glass-sond">
                          <img src={sond_2} alt="" ></img>
                        </div>
                        <div>
                          <div className="text-gree">
                            {t('profile_section.episode_5_season_1')}

                          </div>
                          <div className="side-text">
                            {t('profile_hero_section.seven_figure_bosses_series')}

                          </div>
                        </div>


                        <div className="reg-sond">
                          <img src={sond_left} alt="" ></img>
                        </div>
                        <div className="glass-sond">
                          <img src={sond_1} alt="" ></img>
                        </div>
                      </div>
                    </div>
              
       


        


         




  


     




 



  );
}
