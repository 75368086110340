import React from "react";

export default function ReadyToStartWork() {
  return (
    <div className="timeline-working-mob-content">
      <div className="timeline-workin-top-content">
        <div class="how-it-works-btn">How It Works?</div>
        <h2>Simple Steps to Success</h2>
        <p>It all starts with a single first step.</p>

        <div className="timeline-workin-middle-content">
          <div className="timeline-working-desc">
            Learn about how them you went down prying the wedding ring off his
            cold, dead finger. don't know what you did, Fry, but once again, you
            screwed up! Now all the planets are gonna start cracking wise about
            our mamas.
          </div>
        </div>
      </div>
      <div className="timeline-section-bottom-content mt-3">
        <div className="timeline-section-bottom-content-inner">
          <h3>Ready to start?</h3>
          <p>
            The plans you refer to will soon be back in our hands. Alderaan? I'm
            not going to Alderaan. I've got to go home.
          </p>
          <div className="btn-text-wrapper">
            <div class="timeline-lets-talk">LET'S TALK</div>
            <div className="timeline-working-hours">
              <span>All queries are replied within 24hrs.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
