const initState = {
  user: {},
  isAuthenticated: false,
  response: false,
  email_reset: true,
  otp_reset: false,
  otp_reset_success: false,
  email_success: false,
  otp_reset_fail: false,
  update_password: false,
};
const authReducer = (state = initState, action) => {
  switch (action.type) {
 
    case 'Signup_Error':
      return {
        ...state,
       
        isAuthenticated: false,
        user: {},
      };
    case 'Signup_Success':
      return {
        ...state,
       
        user: action.payload,
      
      };

      case 'Signin_Error':
        return {
          ...state,
          isAuthenticated: false,
          user: {},
          response: action.response,
        };
      case 'Signin_Success':
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload,
          response: action.response,
        
        };

        case 'Reset_Error':
        return {
            ...state,
            email_reset: true,
            otp_reset: false,
            otp_reset_success: false,
            otp_reset_fail: false,
            update_password: false,
            response : action.response
          };
        case 'Reset_Success':
        return {
            ...state,
            response: action.response,
            user: action.payload,
            email_reset: false,
            otp_reset: true,
            email_success: true,
          };

          case 'ResetOTP_Error':
        return {
            ...state,
            email_reset: false,
            otp_reset: true,
            otp_reset_success: false,
            otp_reset_fail: true,
            update_password: false,
            response : action.response
          };
        case 'ResetOTP_Success':
        return {
            ...state,
            response: action.response,
            user: action.payload,
            email_reset: false,
            otp_reset: false,
            otp_reset_success: true,
            email_success: false,
            update_password: true,
          };

          case 'ResetPassword_Error':
        return {
            ...state,
            email_reset: false,
            otp_reset: false,
            otp_reset_success: false,
            otp_reset_fail: false,
            update_password: false,
            response : action.response
          };
        case 'ResetPassword_Success':
        return {
            ...state,
            response: action.response,
            user: action.payload,
            email_reset: false,
            otp_reset: false,
            otp_reset_success: false,
            update_password: true,
          };

        case 'Clear_Reducer':
          return {
            ...state,
            isAuthenticated: false,
            user: {},
            response: false,
          
          };
   
    default:
      return state;
  }
};
export default authReducer;
