import React, { useState } from "react";
import img1 from '../../../../../../images/StepZero/img49.png';
import img2 from '../../../../../../images/StepZero/img50.png';
import icon1 from '../../../../../../images/StepZero/beforeimg/icon1.png';
import icon2 from '../../../../../../images/StepZero/beforeimg/icon2.png';
import icon3 from '../../../../../../images/StepZero/beforeimg/icon3.png';
import icon4 from '../../../../../../images/StepZero/beforeimg/icon4.png';
import icon5 from '../../../../../../images/StepZero/beforeimg/icon5.png';
import icon6 from '../../../../../../images/StepZero/beforeimg/icon6.png';
import icon7 from '../../../../../../images/StepZero/beforeimg/icon7.png';
import icon8 from '../../../../../../images/StepZero/beforeimg/icon8.png';
import icon9 from '../../../../../../images/StepZero/beforeimg/icon9.png';
import icon10 from '../../../../../../images/StepZero/beforeimg/icon10.png';
import icon11 from '../../../../../../images/StepZero/beforeimg/icon11.png';
import icon12 from '../../../../../../images/StepZero/beforeimg/icon12.png';
import MarketResearchGraphTab from "../../../../../global/marketResearchGraph/MarketResearchGraphTab";
export default function NicheSix({ onValueChange }) {

  const [activeColor, setActiveColor] = useState("");

  const [marketResearch, setMarketResearch] = useState(false);

  const MarketResearchOpen = (color) => {
    onValueChange(color);
    setActiveColor(color);
    setMarketResearch(true);
  };

  const MarketResearchClose = () => {
    setMarketResearch(false);
    setActiveColor("");
    onValueChange("");
  };

  return (
    <div className="row justify-content-center sixone mt-0 mx-0 formleft1 px-3">
        <div className="col-lg-5 text-start p-0 ">
          <img src={img1} alt="" width="205px" height="205px" />
        </div>
        <div className="col-lg-7 d-flex flex-column">
          <img src={img2} alt="" width="315px" height="100px" className="mt-7" />
        </div>
        <div className="col-lg-12 d-flex mt-3">
          <div className={` ${activeColor == 'FASHION' ? 'active' : ''} circlediv shadow-4`}>
            <div className="innercircle">
              <div className="innercircle1" onClick={() => MarketResearchOpen('FASHION')}>
                <div className="iconcirlce shadow-5in bg">
                  <span className="shadow-4 bg">
                    <img src={icon1} alt="" />
                  </span>
                </div>
                <h4>FASHION</h4>
              </div>
            </div>
          </div>
          <div className={` ${activeColor == 'BEAUTY' ? 'active' : ''} circlediv shadow-4`}>
            <div className="innercircle">
              <div className="innercircle1" onClick={() => MarketResearchOpen('BEAUTY')}>
                <div className="iconcirlce shadow-5in bg" >
                  <span className="shadow-4 bg">
                    <img src={icon2} alt="" />
                  </span>
                </div>
                <h4>BEAUTY</h4>
              </div>
            </div>
          </div>
          <div className={` ${activeColor == 'COACHING' ? 'active' : ''} circlediv shadow-4`}>
            <div className="innercircle">
              <div className="innercircle1" onClick={() => MarketResearchOpen('COACHING')}>
                <div className="iconcirlce shadow-5in bg">
                  <span className="shadow-4 bg">
                    <img src={icon3} alt="" />
                  </span>
                </div>
                <h4>COACHING</h4>
              </div>
            </div>
          </div>
          <div className={` ${activeColor == 'TRAVEL' ? 'active' : ''} circlediv shadow-4`}>
            <div className="innercircle">
              <div className="innercircle1" onClick={() => MarketResearchOpen('TRAVEL')}>
                <div className="iconcirlce shadow-5in bg">
                  <span className="shadow-4 bg">
                    <img src={icon4} alt="" />
                  </span>
                </div>
                <h4>TRAVEL</h4>
              </div>
            </div>
          </div>
          <div className={` ${activeColor == 'FITNESS' ? 'active' : ''} circlediv shadow-4`}>
            <div className="innercircle">
              <div className="innercircle1" onClick={() => MarketResearchOpen('FITNESS')}>
                <div className="iconcirlce shadow-5in bg">
                  <span className="shadow-4 bg">
                    <img src={icon5} alt="" />
                  </span>
                </div>
                <h4>FITNESS</h4>
              </div>
            </div>
          </div>
          <div className={` ${activeColor == 'NUTRITION' ? 'active' : ''} circlediv shadow-4`}>
            <div className="innercircle">
              <div className="innercircle1" onClick={() => MarketResearchOpen('NUTRITION')}>
                <div className="iconcirlce shadow-5in bg">
                  <span className="shadow-4 bg">
                    <img src={icon6} alt="" />
                  </span>
                </div>
                <h4>NUTRITION</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="sixtab">
          {marketResearch &&
          <div>
            <MarketResearchGraphTab handleClick={MarketResearchClose} />
          </div>
          }
        </div>
        <div className="col-lg-12 d-flex mt-3">
          <div className={` ${activeColor == 'FINANCE' ? 'active' : ''} circlediv shadow-4`}>
            <div className="innercircle">
              <div className="innercircle1" onClick={() => MarketResearchOpen('FINANCE')}>
                <div className="iconcirlce shadow-5in bg">
                  <span className="shadow-4 bg">
                    <img src={icon7} alt="" />
                  </span>
                </div>
                <h4>FINANCE</h4>
              </div>
            </div>
          </div>
          <div className={` ${activeColor == 'INTERIOR' ? 'active' : ''} circlediv shadow-4`}>
            <div className="innercircle">
              <div className="innercircle1" onClick={() => MarketResearchOpen('INTERIOR')}>
                <div className="iconcirlce shadow-5in bg">
                  <span className="shadow-4 bg">
                    <img src={icon8} alt="" />
                  </span>
                </div>
                <h4>INTERIOR <br />DESIGNS</h4>
              </div>
            </div>
          </div>
          <div className={` ${activeColor == 'LEARNING' ? 'active' : ''} circlediv shadow-4`}>
            <div className="innercircle">
              <div className="innercircle1" onClick={() => MarketResearchOpen('LEARNING')}>
                <div className="iconcirlce shadow-5in bg">
                  <span className="shadow-4 bg">
                    <img src={icon9} alt="" />
                  </span>
                </div>
                <h4>E-LEARNING</h4>
              </div>
            </div>
          </div>
          <div className={` ${activeColor == 'SPIRITUALITY' ? 'active' : ''} circlediv shadow-4`}>
            <div className="innercircle">
              <div className="innercircle1" onClick={() => MarketResearchOpen('SPIRITUALITY')}>
                <div className="iconcirlce shadow-5in bg">
                  <span className="shadow-4 bg">
                    <img src={icon10} alt="" />
                  </span>
                </div>
                <h4>SPIRITUALITY</h4>
              </div>
            </div>
          </div>
          <div className={` ${activeColor == 'ENTERTAINMENT' ? 'active' : ''} circlediv shadow-4`}>
            <div className="innercircle">
              <div className="innercircle1" onClick={() => MarketResearchOpen('ENTERTAINMENT')}>
                <div className="iconcirlce shadow-5in bg">
                  <span className="shadow-4 bg">
                    <img src={icon11} alt="" />
                  </span>
                </div>
                <h4>ENTERTAINMENT</h4>
              </div>
            </div>
          </div>
          <div className={` ${activeColor == 'ANIMAL' ? 'active' : ''} circlediv shadow-4`}>
            <div className="innercircle">
              <div className="innercircle1" onClick={() => MarketResearchOpen('ANIMAL')}>
                <div className="iconcirlce shadow-5in bg">
                  <span className="shadow-4 bg">
                    <img src={icon12} alt="" />
                  </span>
                </div>
                <h4>ANIMAL</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
