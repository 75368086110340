import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonBox from "../../../global/ButtonBox";
import './EventSectionThemes.scss';
// import './regularTheme3/regularTheme3Tab/RegularTheme3Tab.scss'
import tab_icon from '../../../../images/Tab-icon.png'
import mobile_icon from '../../../../images/mobile-icon.png'
import { faDesktop, faLock } from "@fortawesome/free-solid-svg-icons";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RegularTheme3Desktop from './regularTheme3/regularTheme3Desktop/RegularTheme3Desktop'
import RegularTheme3Tab from './regularTheme3/regularTheme3Tab/RegularTheme3Tab'
import RegularTheme3Mobile from './regularTheme3/regularTheme3Mobile/RegularTheme3Mobile'
export default function EventSectionRegularThemeDesktopView3() {



  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });


  return (
    <div>

      <h4 className="text-center">
        Switch the Style
      </h4>

      <Tab.Container id="" defaultActiveKey="Regular">
     
        <div className="d-flex flex-column-reverse">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="event-screen-desktop-3">
                  <RegularTheme3Desktop />
                </div>
                <div className="w-100 d-flex justify-content-center align-items-center banner-btn">
                  <ButtonBox boxwidth="w-150 mt-5 lg text-primary mb-4 btn-left" clicked="Sacing" name="Save/Next" />
                  <ButtonBox boxwidth="w-200 mt-5 lg text-primary mb-4 ml-3 " clicked="Sacing" name="Close and continue" />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="event-screen-tab-3">
                  <RegularTheme3Tab />
                </div>
                <div className="w-100 d-flex justify-content-center align-items-center banner-btn">
                  <ButtonBox boxwidth="w-150 mt-5 lg text-primary mb-4 btn-left" clicked="Sacing" name="Save/Next" />
                  <ButtonBox boxwidth="w-200 mt-5 lg text-primary mb-4 ml-3 " clicked="Sacing" name="Close and continue" />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="three">
                <div className="event-screen-mobile-3">
                  <RegularTheme3Mobile />
                </div>
                <div className="w-100 d-flex justify-content-center align-items-center banner-btn">
                  <ButtonBox boxwidth="w-150 mt-5 lg text-primary mb-4 btn-left" clicked="Sacing" name="Save/Next" />
                  <ButtonBox boxwidth="w-200 mt-5 lg text-primary mb-4 ml-3 " clicked="Sacing" name="Close and continue" />
                </div>
              </Tab.Pane>
            </Tab.Content>
            <div className="d-flex align-items-center justify-center  w-100 mb-0 ">
              <Nav variant="pills" className="w-100 d-flex  align-items-center justify-content-center">
                <ul className="d-flex   my-4 pb-2 align-items-center modal-tabs justify-content-center">

                  <Nav.Item as="li" className="me-0">
                    <Nav.Link className="m-0 p-0 border-0 design-one-size" eventKey="first">
                      <span className="d-flex align-items-center "><FontAwesomeIcon className="icon-design" icon={faDesktop} ></FontAwesomeIcon> Desktop</span>

                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
                    <Nav.Link className="m-0 p-0 border-0 design-one-size" eventKey="second">
                      <span className="d-flex align-items-center ">  <div className="icon-design">
                        <img src={tab_icon} alt="icon-tab" ></img>
                      </div>    Tab</span>
                    </Nav.Link>

                  </Nav.Item>
                  <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
                    <Nav.Link className="m-0 p-0 border-0 design-one-size" eventKey="three">
                      <span className="d-flex align-items-center ">
                        <div className="icon-design" >
                          <img src={mobile_icon} alt="icon-mobile" ></img>
                        </div>
                        Mobile</span>
                    </Nav.Link>

                  </Nav.Item>
                </ul>
              </Nav>
            </div>
          </Tab.Container>
        </div>
      </Tab.Container>


    </div >
  );
}
