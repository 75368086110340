import React from "react";
import { ReactComponent as IconBluew1 } from "../../../../../../images/svgIcon/icon13.svg";
import { ReactComponent as IconBluew2 } from "../../../../../../images/svgIcon/icon14.svg";
import { ReactComponent as IconBluew3 } from "../../../../../../images/svgIcon/icon15.svg";
import { ReactComponent as IconBluew4 } from "../../../../../../images/svgIcon/icon16.svg";
import { ReactComponent as IconBluew5 } from "../../../../../../images/svgIcon/icon17.svg";
import { ReactComponent as IconBluew6 } from "../../../../../../images/svgIcon/icon18.svg";
import ButtonBox from '../../../../../global/ButtonBox';
import { ReactComponent as IconBlueq2 } from "../../../../../../images/icons/icon1.svg";
import { ReactComponent as IconBlueq3 } from "../../../../../../images/icons/icon30.svg";
import { ReactComponent as IconBlueq5 } from "../../../../../../images/icons/icon31.svg";
import { ReactComponent as IconBlueq4 } from "../../../../../../images/icons/icon2.svg";
import { ReactComponent as IconBlueq6 } from "../../../../../../images/icons/icon32.svg";
import { ReactComponent as IconBlueq1 } from "../../../../../../images/listicon/icon21.svg";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import ButtonBoxNew from "../../../../../global/ButtonBoxNew";

export default function SocialPage2() {
  const { t, i18n } = useTranslation();


  return (
    <div >
      <h3 className="h8 mb-2">{t('profile_section.preview_of_the_generated_bio')}</h3>
      <div className="shadow-4 br-20 p-20 gradient-box h-845">
        <ul className="socialList d-flex justify-content-between mb-40">
          <NavItem as="li">
            <button className="shadow-4 w-100 h7 h-57 br-10 border-0 bg text-body">
              <IconBluew1 />
              {t('profile_section.facebook')}
            </button>
          </NavItem>
          <NavItem as="li">
            <button className="shadow-4 w-100 h7 h-57 br-10 border-0 bg text-body">
              <IconBluew2 />
              {t('profile_section.instagram')}
            </button>
          </NavItem>
          <NavItem as="li">
            <button className="shadow-4 w-100 h7 h-57 br-10 border-0 bg text-body">
            <IconBluew3 />
            {t('profile_section.twitter')}
            </button>
          </NavItem>
          <NavItem as="li">
            <button className="shadow-4 w-100 h7 h-57 br-10 border-0 bg text-body">
            <IconBluew4 />
            {t('profile_section.linked_in')}
            </button>
          </NavItem>
          <NavItem as="li">
            <button className="shadow-4 w-100 h7 h-57 br-10 border-0 bg text-body">
            <IconBluew5 />
            {t('profile_section.pinterest')}
              
            </button>
          </NavItem>
          <NavItem as="li">
            <button className="shadow-4 w-100 h7 h-57 br-10 border-0 bg text-body">
            <IconBluew6 />
            {t('profile_section.youtube')}
              
            </button>
          </NavItem>
        </ul>
        <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq1 /></span> <p className="m-0 lh-24 w-85s">Hi There,</p></div>
        <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq2 /></span> <p className="m-0 lh-24 w-85s">I’m Dyna Appolon and I Help People Quit Their 9 to 5 Job and Launch Their Brand New Proven Online Business Within 3 Hours.</p></div>
        <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq3 /></span> <p className="m-0 lh-24 w-85s">In my 5 Years working in the Startup Industry, I’ve become an expert in Business Niche Clarity, Market Research, Executing Strategic Business Plans.</p></div>
        <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq4 /></span> <p className="m-0 lh-24 w-85s">My Proudest Accomplishments are helping 3,000 people quit their Jobs Last Year. I build a Proven Business Niche Clarity System, a 3-Hours Business Launch Challenge in 50+ Steps, and a 360 Business Visibility Strategy Blueprint.</p></div>
        <div className="mb-4 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq6 /></span> <p className="m-0 lh-24 w-85s">Thanks to my MBA, my Top Skills Now Range from being a Social Network Strategist to a Venture StartUp Guru with the Add-On Bonus of a 7-Figure Self-Made Coach.</p></div>
        <div className="mb-0 d-flex w-100"><span className="iconNew text-darks me-3"><IconBlueq5 /></span> <p className="m-0 lh-24 w-85s">Don’t Take My Words For Any of it. Please Check Out How My Clients Say It Like it This.</p></div>
        <div className="text-center mt-40 mb-5 pb-5">
        {/* <ButtonBox boxwidth="w-140 mt-0 mb-50 mx-auto" clicked="Copied" name={t('profile_section.copy_bio')} /> */}
        <ButtonBoxNew boxwidth="w-140 mx-auto" name={t('profile_section.copy_bio')} />
          {/* <button className="h-50s bs-primary br-5 mx-auto w-200 fw-bold h7 text-uppercase text-center border-0 shadow-4 d-flex align-items-center justify-content-center mb-5">{t('profile_section.copy_bio')}</button> */}
        </div>
      </div>
    </div>
  );
}
