import React from "react";
import Slide6s from '../../images/desktopblogpost/blogpostdesktop3-1.png';
import { useState } from 'react';

export default function BannerFirst(props) {

const openSelect1 = () =>{inputRef1.current.click();}
const inputRef1 = React.useRef();
const [previewImage1, setPreviewImage1] = useState('');
const [setImage1] = useState('');

const openSelect2 = () =>{inputRef2.current.click();}
const inputRef2 = React.useRef();
const [previewImage2, setPreviewImage2] = useState('');
const [setImage2] = useState('');

const openSelect3 = () =>{inputRef3.current.click();}
const inputRef3 = React.useRef();
const [previewImage3, setPreviewImage3] = useState('');
const [setImage3] = useState('');

const openSelect4 = () =>{inputRef4.current.click();}
const inputRef4 = React.useRef();
const [previewImage4, setPreviewImage4] = useState('');
const [setImage4] = useState('');

const openSelect5 = () =>{inputRef5.current.click();}
const inputRef5 = React.useRef();
const [previewImage5, setPreviewImage5] = useState('');
const [setImage5] = useState('');

const openSelect6 = () =>{inputRef6.current.click();}
const inputRef6 = React.useRef();
const [previewImage6, setPreviewImage6] = useState('');
const [setImage6] = useState('');

const openSelect7 = () =>{inputRef7.current.click();}
const inputRef7 = React.useRef();
const [previewImage7, setPreviewImage7] = useState('');
const [setImage7] = useState('');

const openSelect8 = () =>{inputRef8.current.click();}
const inputRef8 = React.useRef();
const [previewImage8, setPreviewImage8] = useState('');
const [setImage8] = useState('');

const openSelect9 = () =>{inputRef9.current.click();}
const inputRef9 = React.useRef();
const [previewImage9, setPreviewImage9] = useState('');
const [setImage9] = useState('');

const openSelect10 = () =>{inputRef10.current.click();}
const inputRef10 = React.useRef();
const [previewImage10, setPreviewImage10] = useState('');
const [setImage10] = useState('');

const openSelect11 = () =>{inputRef11.current.click();}
const inputRef11 = React.useRef();
const [previewImage11, setPreviewImage11] = useState('');
const [setImage11] = useState('');

const openSelect12 = () =>{inputRef12.current.click();}
const inputRef12 = React.useRef();
const [previewImage12, setPreviewImage12] = useState('');
const [setImage12] = useState('');


  return (
    <div className={"banner-one " + props.boxwidth}>
      <ul className={"zoom-banner " + props.boxul}>
        <li>
          {/* <img src={Slide6s} alt="" /> */}
          <div className="d-block imgsec">
            <label onClick={openSelect1} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage1 ? (
                  <img
                    src={previewImage1}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={Slide6s} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage1(url);
                setImage1(e.target.files[0]);
              }}
              ref={inputRef1}
            />
          </div>
          <div  className="d-block imgsec">
            <label onClick={openSelect2} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage2 ? (
                  <img
                    src={previewImage2}
                    alt=""
                    className="w-100 h-100 m-0"
                  />
                ) : (
                  <img src={Slide6s} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage2(url);
                setImage2(e.target.files[0]);
              }}
              ref={inputRef2}
            />
          </div>
        </li>
        <li>
          <div className="d-block imgsec">
            <label onClick={openSelect3} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage3 ? (
                  <img
                    src={previewImage3}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={Slide6s} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage3(url);
                setImage3(e.target.files[0]);
              }}
              ref={inputRef3}
            />
          </div>
          <div className="d-block imgsec">
            <label onClick={openSelect4} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage4 ? (
                  <img
                    src={previewImage4}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={Slide6s} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage4(url);
                setImage4(e.target.files[0]);
              }}
              ref={inputRef4}
            />
          </div>
        </li>
        <li>
          <div className="d-block imgsec">
            <label onClick={openSelect5} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage5 ? (
                  <img
                    src={previewImage5}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={Slide6s} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage5(url);
                setImage5(e.target.files[0]);
              }}
              ref={inputRef5}
            />
          </div>
        </li>
        <li>
          <div className="d-block imgsec">
            <label onClick={openSelect6} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage6 ? (
                  <img
                    src={previewImage6}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={Slide6s} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage6(url);
                setImage6(e.target.files[0]);
              }}
              ref={inputRef6}
            />
          </div>
          <div className="d-block imgsec">
            <label onClick={openSelect7} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage7 ? (
                  <img
                    src={previewImage7}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={Slide6s} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage7(url);
                setImage7(e.target.files[0]);
              }}
              ref={inputRef7}
            />
          </div>
        </li>
        <li>
          <div className="d-block imgsec">
            <label onClick={openSelect8} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage8 ? (
                  <img
                    src={previewImage8}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={Slide6s} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage8(url);
                setImage8(e.target.files[0]);
              }}
              ref={inputRef8}
            />
          </div>
        </li>
        <li>
          <div className="d-block imgsec">
            <label onClick={openSelect9} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage9 ? (
                  <img
                    src={previewImage9}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={Slide6s} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage9(url);
                setImage9(e.target.files[0]);
              }}
              ref={inputRef9}
            />
          </div>
          <div className="d-block imgsec">
            <label onClick={openSelect10} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage10 ? (
                  <img
                    src={previewImage10}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={Slide6s} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage10(url);
                setImage10(e.target.files[0]);
              }}
              ref={inputRef10}
            />
          </div>
        </li>
        <li>
          <div className="d-block imgsec">
            <label onClick={openSelect11} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage11 ? (
                  <img
                    src={previewImage11}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={Slide6s} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage11(url);
                setImage11(e.target.files[0]);
              }}
              ref={inputRef11}
            />
          </div>
          <div className="d-block imgsec">
            <label onClick={openSelect12} className="w-100 h-100">
              <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-0 h-100">
                {previewImage12 ? (
                  <img
                    src={previewImage12}
                    alt=""
                    className="w-100 h-100"
                  />
                ) : (
                  <img src={Slide6s} alt="" />
                )}
              </div>
            </label>
            <input hidden type="file" name="profile" id="profile" accept="image/*"
              onChange={(e) => {
                let url = URL.createObjectURL(e.target.files[0]);
                setPreviewImage12(url);
                setImage12(e.target.files[0]);
              }}
              ref={inputRef12}
            />
          </div>
        </li>
      </ul>
      <div className="bnrtext">
        <h4 className="heading-4">Dyna Appolon</h4>
        <h4>Social NetWork Strategist</h4>
      </div>
    </div>
  );
}
