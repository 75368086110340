import React from "react";
import { useState } from "react";
import '../../ServicePackagePlanThemes.scss'
import { FcCheckmark } from 'react-icons/fc'
import '../regularTheme5Desktop/RegularTheme5Desktop.scss';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { BsCheckLg } from "react-icons/bs"
import { RxCross2 } from "react-icons/rx";
import { Link } from 'react-router-dom';
export default function RegularTheme5Desktop() {

  return (
    <div>
      <div className="">
        <div>
          <div className="main-content p-5">

            <div className="main_wrapper mx-auto">
              <div className="plan_outer_wrapper mx-auto">
                <div className="content_wrapper">
                  <div className="d-flex align-items-center justify-content-center mb-5">
                    <div className="toggle_button">
                      <button type="" className="btn active_btn">Monthly</button>
                      <button type="" className="btn1 ">Yearly</button>
                    </div>
                  </div>
                  <div className="d-flex align-items-center relative ">
                    <div class="plan_wrapper mx-auto">
                      <div class="row ">
                        <div class="col-4 pt-5">
                          <div class="plan_outer">
                            <div class="plan_content">
                              <div class="d-flex justify-content-center">
                                <div class="badge_wrapper bg_green mx-auto">
                                  <div>
                                    <h2>BASIC</h2>
                                    <p>Lorem Ipsum</p>
                                  </div>
                                </div>
                              </div>
                              <div class="price">
                                <h1 class="text_green">$10.99</h1>
                                <p>Per User/Month</p>
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    <div className="icon_outer">
                                    <BsCheckLg className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit
                                    </li>
                                    <span className="list_divider"></span>
                                  <li>
                                    <div className="icon_outer">
                                    <BsCheckLg className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                    <span className="list_divider"></span>
                                  <li>
                                    <div className="icon_outer">
                                    <BsCheckLg className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                    <span className="list_divider"></span>
                                  <li>
                                    <div className="icon_outer">
                                    <RxCross2 className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                    <span className="list_divider"></span>
                                  <li>
                                    <div className="icon_outer">
                                    <RxCross2 className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                </ul>
                              </div>
                              <div className="d-flex justify-content-center mt-3">
                                <Link to="#" className="read_more_btn p-2 fs_10">READ MORE</Link>
                              </div>
                            </div>
                            <div class="footer_wrapper">
                              <div class="btn_wrapper">
                                <button class="btn1 basic_sign_up_btn">SIGN UP</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-4 pt-5">
                          <div class="plan_outer">
                            <div class="plan_content">
                              <div class="d-flex justify-content-center">
                                <div class="badge_wrapper bg_amber mx-auto">
                                  <div>
                                    <h2>STANDARD</h2>
                                    <p>Lorem Ipsum</p>
                                  </div>
                                </div>
                              </div>
                              <div class="price">
                                <h1 class="text_amber">$10.99</h1>
                                <p>Per User/Month</p>
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    <div className="icon_outer">
                                    <BsCheckLg className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                    <span className="list_divider"></span>
                                  <li>
                                    <div className="icon_outer">
                                    <BsCheckLg className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                    <span className="list_divider"></span>
                                  <li>
                                    <div className="icon_outer">
                                    <BsCheckLg className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                    <span className="list_divider"></span>
                                  <li>
                                    <div className="icon_outer">
                                    <BsCheckLg className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                    <span className="list_divider"></span>
                                  <li>
                                    <div className="icon_outer">
                                    <RxCross2 className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                </ul>
                              </div>
                              <div className="d-flex justify-content-center mt-3">
                                <Link to="#" className="read_more_btn p-2 fs_10">READ MORE</Link>
                              </div>
                            </div>
                            <div class="footer_wrapper">
                              <div class="btn_wrapper">
                                <button class="btn1 standard_sign_up_btn">SIGN UP</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-4 pt-5">
                          <div class="plan_outer">
                            <div class="plan_content">
                              <div class="d-flex justify-content-center">
                                <div class="badge_wrapper bg_gray mx-auto">
                                  <div>
                                    <h2>PREMIUM</h2>
                                    <p>Lorem Ipsum</p>
                                  </div>
                                </div>
                              </div>
                              <div class="price">
                                <h1 class="text_gray">$10.99</h1>
                                <p>Per User/Month</p>
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    <div className="icon_outer">
                                    <BsCheckLg className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                    <span className="list_divider"></span>
                                  <li>
                                    <div className="icon_outer">
                                    <BsCheckLg className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                    <span className="list_divider"></span>
                                  <li>
                                    <div className="icon_outer">
                                    <BsCheckLg className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                    <span className="list_divider"></span>
                                  <li>
                                    <div className="icon_outer">
                                    <BsCheckLg className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                    <span className="list_divider"></span>
                                  <li>
                                    <div className="icon_outer">
                                    <RxCross2 className="icon" />
                                    </div>
                                    Lorem Ipsum Dolor Sit</li>
                                </ul>
                              </div>
                              <div className="d-flex justify-content-center mt-3">
                                <Link to="#" className="read_more_btn p-2 fs_10">READ MORE</Link>
                              </div>
                            </div>
                            <div class="footer_wrapper">
                              <div class="btn_wrapper">
                                <button class="btn1 premium_sign_up_btn">SIGN UP</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>




    </div>



  );
}
