import React from "react";
import SocialPage1 from "../PageSection/SocialPage1";
import SocialPage2 from "../PageSection/SocialPage2";
import SocialPage3 from "../PageSection/SocialPage3";
import SocialPage4 from "../PageSection/SocialPage4";
import SocialPage5 from "../PageSection/SocialPage5";
import EditorDrop from "../../../../../global/EditorDrop";
import ButtonBox from '../../../../../global/ButtonBox';

import { ReactComponent as IconBluew1 } from "../../../../../../images/svgIcon/icon13.svg";
import { ReactComponent as IconBluew2 } from "../../../../../../images/svgIcon/icon14.svg";
import { ReactComponent as IconBluew3 } from "../../../../../../images/svgIcon/icon15.svg";
import { ReactComponent as IconBluew4 } from "../../../../../../images/svgIcon/icon16.svg";
import { ReactComponent as IconBluew5 } from "../../../../../../images/svgIcon/icon17.svg";
import { ReactComponent as IconBluew6 } from "../../../../../../images/svgIcon/icon18.svg";
import { NavItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as IconBlue4 } from "../../../../../../images/icons/icon16.svg";
import { useTranslation } from "react-i18next";

export default function Social1() {
  const { t, i18n } = useTranslation();


  return (
    <div className="responsiveD">
      <form>
        <div className="row sociallabel">
          <div className="col-md-12">
            <div className="col-md-7 pe-2">
              <label className="mb-2 mt-4 pt-2">{t('profile_section.create_your_unique_social_media_username_handle')} </label>
              <input
                placeholder={t('profile_section.appolon')}
                className="shadow-5in br-10 w-100 mb-4"
                type="text"
              />
              <ul className="row justify-content-between">
                <NavItem as="li" className="col-md-2">
                  <button className="shadow-4 w-100 h7 h-50s br-10 border-0 bg text-body">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.facebook')}   
                  </button>
                </NavItem>
                <NavItem as="li" className="col-md-2">
                  <button className="shadow-4 w-100 h7 h-50s br-10 border-0 bg text-body">
                  <IconBluew2 className="me-2" />
                  {t('profile_section.instagram')}   
                    
                  </button>
                </NavItem>
                <NavItem as="li" className="col-md-2">
                  <button className="shadow-4 w-100 h7 h-50s br-10 border-0 bg text-body">
                  <IconBluew3 className="me-2" />
                  {t('profile_section.twitter')}   
                    </button>
                </NavItem>
                <NavItem as="li" className="col-md-2">
                  <button className="shadow-4 w-100 h7 h-50s br-10 border-0 bg text-body">
                  <IconBluew4 className="me-2" />
                  {t('profile_section.linked_in')}   
                    
                  </button>
                </NavItem>
                <NavItem as="li" className="col-md-2">
                  <button className="shadow-4 w-100 h7 h-50s br-10 border-0 bg text-body">
                  <IconBluew5 className="me-2" />
                  {t('profile_section.pinterest')}   
                    
                  </button>
                </NavItem>
                <NavItem as="li" className="col-md-2">
                  <button className="shadow-4 w-100 h7 h-50s br-10 border-0 bg text-body">
                  <IconBluew6 className="me-2" />
                  {t('profile_section.youtube')}   
                    </button>
                </NavItem>
              </ul>
            </div>
          </div>
          <div className="col-md-7">
            <SocialPage1 />
            <SocialPage3 />
            <div className="dividerText2 text-center mb-3 pt-2 ms-5">{t('or')}</div>
            <div className="col-md-12">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <label className="">{t('profile_section.describe_your_typical_day_at_work')}</label>
                  <label className="ms-auto me-3">{t('profile_section.text_formating')}</label>
                  <div className="dropFormat">
                    <div className="d-flex">
                      <div className="dropdown btnArrow">
                        <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside"   data-bs-display="static">
                          <Link to="#" id="notab" className="d-flex justify-content-center fs-5 fw-bold ps-3 pe-5 align-items-center w-100x text-underline shadow-4 h-45 h8 br-30">
                            <u>A</u>
                          </Link>
                        </span>
                        <EditorDrop />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="position-relative">
                  <textarea
                  placeholder={t('profile_section.copy_bio')} rows="9"
                  className="shadow-5in br-20 h-525 w-100 mb-4 ps-5 pt-4"
                  type="text">
                  </textarea>
                  <span className="position-absolute top-20 start-16 ">
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                    <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4 /></span>
                  </span>
                </div>
            </div>
          </div>
          <div className="col-md-5">
            <SocialPage2 />
            <SocialPage4 />
            <SocialPage5 />
          </div>
          <div className="mt-4 text-center">
            <ButtonBox boxwidth="w-140 mt-0 mb-50 mx-auto" clicked="Saved" name="Save/Next" />
          </div>
        </div>
      </form>
    </div>
  );
}
