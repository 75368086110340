import React from "react";
import ButtonBox from "../../../../global/ButtonBox";
import user from '../../../../../images/list.png';
import { useState } from "react";
import ColorPick from "../../../../global/ColorPick";
import { NavItem } from 'react-bootstrap';
import { ReactComponent as IconBluew2s } from "../../../../../images/svgIcon/icon24.svg";
import { useTranslation } from "react-i18next";


export default function ListFavouritesOne() {

const { t, i18n } = useTranslation(); 
const openSelect1 = () =>{inputRef1.current.click();}
const inputRef1 = React.useRef();
const openSelect2 = () =>{inputRef2.current.click();}
const inputRef2 = React.useRef();
const openSelect3 = () =>{inputRef3.current.click();}
const inputRef3 = React.useRef();
const openSelect4 = () =>{inputRef4.current.click();}
const inputRef4 = React.useRef();
const openSelect5 = () =>{inputRef5.current.click();}
const inputRef5 = React.useRef();
const openSelect6 = () =>{inputRef6.current.click();}
const inputRef6 = React.useRef();
const openSelect7 = () =>{inputRef7.current.click();}
const inputRef7 = React.useRef();
const openSelect8 = () =>{inputRef8.current.click();}
const inputRef8 = React.useRef();
const openSelect9 = () =>{inputRef9.current.click();}
const inputRef9 = React.useRef();
const openSelect10 = () =>{inputRef10.current.click();}
const inputRef10 = React.useRef();
const openSelect11 = () =>{inputRef11.current.click();}
const inputRef11 = React.useRef();


const [previewImage1, setPreviewImage1] = useState('');
const [previewImage2, setPreviewImage2] = useState('');
const [previewImage3, setPreviewImage3] = useState('');
const [previewImage4, setPreviewImage4] = useState('');
const [previewImage5, setPreviewImage5] = useState('');
const [previewImage6, setPreviewImage6] = useState('');
const [previewImage7, setPreviewImage7] = useState('');
const [previewImage8, setPreviewImage8] = useState('');
const [previewImage9, setPreviewImage9] = useState('');
const [previewImage10, setPreviewImage10] = useState('');
const [previewImage11, setPreviewImage11] = useState('');

const [setImage1] = useState('');
const [setImage2] = useState('');
const [setImage3] = useState('');
const [setImage4] = useState('');
const [setImage5] = useState('');
const [image6, setImage6] = useState('');
const [image7, setImage7] = useState('');
const [image8, setImage8] = useState('');
const [image9, setImage9] = useState('');
const [image10, setImage10] = useState('');
const [image11, setImage11] = useState('');

  return (
    <div>
      <div className="text-center">
        <h3 className="mb-0 pb-0 fw-bold">{t('profile_section.lets_play_favourites')}</h3>
      </div>
      <div>
        <label className="mb-2 mt-4 pt-2 h7">{t('profile_section.favorite_color')}</label>
          <input
            placeholder={t('profile_section.baby_pink_silver_gold_black_nude')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
      </div>
      <div className="graphSec mt-2 mb-30">
        <div className="row">
          <div className="col-md-6 pe-5">
            <ColorPick />
          </div>
          <div className="col-md-6 text-center">
            <img src={user} alt="" width="180" height="180" />
            <ul className="pt-3 m-0 w-100">
              <NavItem as="li" className="shadow-4 px-3 py-2 br-5 mb-2"><span></span> {t('profile_section.baby_pink')} 
                <div className="text-body ms-auto">
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.93979 3.99943L7.8041 1.14179C7.92953 1.01635 8 0.846231 8 0.668843C8 0.491455 7.92953 0.321332 7.8041 0.1959C7.67867 0.0704672 7.50854 0 7.33116 0C7.15377 0 6.98365 0.0704672 6.85821 0.1959L4.00057 3.06021L1.14292 0.1959C1.01749 0.0704672 0.847368 -1.32164e-09 0.66998 0C0.492592 1.32165e-09 0.322469 0.0704672 0.197036 0.1959C0.0716041 0.321332 0.0011368 0.491455 0.0011368 0.668843C0.0011368 0.846231 0.0716041 1.01635 0.197036 1.14179L3.06134 3.99943L0.197036 6.85708C0.134602 6.919 0.0850471 6.99267 0.0512292 7.07385C0.0174113 7.15502 0 7.24208 0 7.33002C0 7.41795 0.0174113 7.50502 0.0512292 7.58619C0.0850471 7.66737 0.134602 7.74104 0.197036 7.80296C0.258961 7.8654 0.332634 7.91495 0.413807 7.94877C0.494979 7.98259 0.582045 8 0.66998 8C0.757915 8 0.844981 7.98259 0.926153 7.94877C1.00733 7.91495 1.081 7.8654 1.14292 7.80296L4.00057 4.93866L6.85821 7.80296C6.92014 7.8654 6.99381 7.91495 7.07498 7.94877C7.15616 7.98259 7.24322 8 7.33116 8C7.41909 8 7.50616 7.98259 7.58733 7.94877C7.6685 7.91495 7.74218 7.8654 7.8041 7.80296C7.86653 7.74104 7.91609 7.66737 7.94991 7.58619C7.98373 7.50502 8.00114 7.41795 8.00114 7.33002C8.00114 7.24208 7.98373 7.15502 7.94991 7.07385C7.91609 6.99267 7.86653 6.919 7.8041 6.85708L4.93979 3.99943Z" fill="currentColor"/>
                  </svg>
                </div>
              </NavItem>
              <NavItem as="li" className="shadow-4 px-3 py-2 br-5 mb-2"><span></span> {t('profile_section.silver')}
                <div className="text-body ms-auto">
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.93979 3.99943L7.8041 1.14179C7.92953 1.01635 8 0.846231 8 0.668843C8 0.491455 7.92953 0.321332 7.8041 0.1959C7.67867 0.0704672 7.50854 0 7.33116 0C7.15377 0 6.98365 0.0704672 6.85821 0.1959L4.00057 3.06021L1.14292 0.1959C1.01749 0.0704672 0.847368 -1.32164e-09 0.66998 0C0.492592 1.32165e-09 0.322469 0.0704672 0.197036 0.1959C0.0716041 0.321332 0.0011368 0.491455 0.0011368 0.668843C0.0011368 0.846231 0.0716041 1.01635 0.197036 1.14179L3.06134 3.99943L0.197036 6.85708C0.134602 6.919 0.0850471 6.99267 0.0512292 7.07385C0.0174113 7.15502 0 7.24208 0 7.33002C0 7.41795 0.0174113 7.50502 0.0512292 7.58619C0.0850471 7.66737 0.134602 7.74104 0.197036 7.80296C0.258961 7.8654 0.332634 7.91495 0.413807 7.94877C0.494979 7.98259 0.582045 8 0.66998 8C0.757915 8 0.844981 7.98259 0.926153 7.94877C1.00733 7.91495 1.081 7.8654 1.14292 7.80296L4.00057 4.93866L6.85821 7.80296C6.92014 7.8654 6.99381 7.91495 7.07498 7.94877C7.15616 7.98259 7.24322 8 7.33116 8C7.41909 8 7.50616 7.98259 7.58733 7.94877C7.6685 7.91495 7.74218 7.8654 7.8041 7.80296C7.86653 7.74104 7.91609 7.66737 7.94991 7.58619C7.98373 7.50502 8.00114 7.41795 8.00114 7.33002C8.00114 7.24208 7.98373 7.15502 7.94991 7.07385C7.91609 6.99267 7.86653 6.919 7.8041 6.85708L4.93979 3.99943Z" fill="currentColor"/>
                  </svg>
                </div>
                </NavItem>
              <NavItem as="li" className="shadow-4 px-3 py-2 br-5 mb-2"><span></span> {t('profile_section.gold')}
                <div className="text-body ms-auto">
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.93979 3.99943L7.8041 1.14179C7.92953 1.01635 8 0.846231 8 0.668843C8 0.491455 7.92953 0.321332 7.8041 0.1959C7.67867 0.0704672 7.50854 0 7.33116 0C7.15377 0 6.98365 0.0704672 6.85821 0.1959L4.00057 3.06021L1.14292 0.1959C1.01749 0.0704672 0.847368 -1.32164e-09 0.66998 0C0.492592 1.32165e-09 0.322469 0.0704672 0.197036 0.1959C0.0716041 0.321332 0.0011368 0.491455 0.0011368 0.668843C0.0011368 0.846231 0.0716041 1.01635 0.197036 1.14179L3.06134 3.99943L0.197036 6.85708C0.134602 6.919 0.0850471 6.99267 0.0512292 7.07385C0.0174113 7.15502 0 7.24208 0 7.33002C0 7.41795 0.0174113 7.50502 0.0512292 7.58619C0.0850471 7.66737 0.134602 7.74104 0.197036 7.80296C0.258961 7.8654 0.332634 7.91495 0.413807 7.94877C0.494979 7.98259 0.582045 8 0.66998 8C0.757915 8 0.844981 7.98259 0.926153 7.94877C1.00733 7.91495 1.081 7.8654 1.14292 7.80296L4.00057 4.93866L6.85821 7.80296C6.92014 7.8654 6.99381 7.91495 7.07498 7.94877C7.15616 7.98259 7.24322 8 7.33116 8C7.41909 8 7.50616 7.98259 7.58733 7.94877C7.6685 7.91495 7.74218 7.8654 7.8041 7.80296C7.86653 7.74104 7.91609 7.66737 7.94991 7.58619C7.98373 7.50502 8.00114 7.41795 8.00114 7.33002C8.00114 7.24208 7.98373 7.15502 7.94991 7.07385C7.91609 6.99267 7.86653 6.919 7.8041 6.85708L4.93979 3.99943Z" fill="currentColor"/>
                  </svg>
                </div>
              </NavItem>
              <NavItem as="li" className="shadow-4 px-3 py-2 br-5 mb-2"><span></span> {t('profile_section.black')}
                <div className="text-body ms-auto">
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.93979 3.99943L7.8041 1.14179C7.92953 1.01635 8 0.846231 8 0.668843C8 0.491455 7.92953 0.321332 7.8041 0.1959C7.67867 0.0704672 7.50854 0 7.33116 0C7.15377 0 6.98365 0.0704672 6.85821 0.1959L4.00057 3.06021L1.14292 0.1959C1.01749 0.0704672 0.847368 -1.32164e-09 0.66998 0C0.492592 1.32165e-09 0.322469 0.0704672 0.197036 0.1959C0.0716041 0.321332 0.0011368 0.491455 0.0011368 0.668843C0.0011368 0.846231 0.0716041 1.01635 0.197036 1.14179L3.06134 3.99943L0.197036 6.85708C0.134602 6.919 0.0850471 6.99267 0.0512292 7.07385C0.0174113 7.15502 0 7.24208 0 7.33002C0 7.41795 0.0174113 7.50502 0.0512292 7.58619C0.0850471 7.66737 0.134602 7.74104 0.197036 7.80296C0.258961 7.8654 0.332634 7.91495 0.413807 7.94877C0.494979 7.98259 0.582045 8 0.66998 8C0.757915 8 0.844981 7.98259 0.926153 7.94877C1.00733 7.91495 1.081 7.8654 1.14292 7.80296L4.00057 4.93866L6.85821 7.80296C6.92014 7.8654 6.99381 7.91495 7.07498 7.94877C7.15616 7.98259 7.24322 8 7.33116 8C7.41909 8 7.50616 7.98259 7.58733 7.94877C7.6685 7.91495 7.74218 7.8654 7.8041 7.80296C7.86653 7.74104 7.91609 7.66737 7.94991 7.58619C7.98373 7.50502 8.00114 7.41795 8.00114 7.33002C8.00114 7.24208 7.98373 7.15502 7.94991 7.07385C7.91609 6.99267 7.86653 6.919 7.8041 6.85708L4.93979 3.99943Z" fill="currentColor"/>
                  </svg>
                </div>
              </NavItem>
              <NavItem as="li" className="shadow-4 px-3 py-2 br-5 mb-2"><span></span> {t('profile_section.nude')}
                <div className="text-body ms-auto">
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.93979 3.99943L7.8041 1.14179C7.92953 1.01635 8 0.846231 8 0.668843C8 0.491455 7.92953 0.321332 7.8041 0.1959C7.67867 0.0704672 7.50854 0 7.33116 0C7.15377 0 6.98365 0.0704672 6.85821 0.1959L4.00057 3.06021L1.14292 0.1959C1.01749 0.0704672 0.847368 -1.32164e-09 0.66998 0C0.492592 1.32165e-09 0.322469 0.0704672 0.197036 0.1959C0.0716041 0.321332 0.0011368 0.491455 0.0011368 0.668843C0.0011368 0.846231 0.0716041 1.01635 0.197036 1.14179L3.06134 3.99943L0.197036 6.85708C0.134602 6.919 0.0850471 6.99267 0.0512292 7.07385C0.0174113 7.15502 0 7.24208 0 7.33002C0 7.41795 0.0174113 7.50502 0.0512292 7.58619C0.0850471 7.66737 0.134602 7.74104 0.197036 7.80296C0.258961 7.8654 0.332634 7.91495 0.413807 7.94877C0.494979 7.98259 0.582045 8 0.66998 8C0.757915 8 0.844981 7.98259 0.926153 7.94877C1.00733 7.91495 1.081 7.8654 1.14292 7.80296L4.00057 4.93866L6.85821 7.80296C6.92014 7.8654 6.99381 7.91495 7.07498 7.94877C7.15616 7.98259 7.24322 8 7.33116 8C7.41909 8 7.50616 7.98259 7.58733 7.94877C7.6685 7.91495 7.74218 7.8654 7.8041 7.80296C7.86653 7.74104 7.91609 7.66737 7.94991 7.58619C7.98373 7.50502 8.00114 7.41795 8.00114 7.33002C8.00114 7.24208 7.98373 7.15502 7.94991 7.07385C7.91609 6.99267 7.86653 6.919 7.8041 6.85708L4.93979 3.99943Z" fill="currentColor"/>
                  </svg>
                </div>
              </NavItem>
            </ul>
          </div>
        </div>
      </div>
      <div className="row pt-3">
          <div className="col-md-8">
            <label className="mb-2 mt-4 pt-2 h8 w-100">{t('profile_section.favourite_song')} <span className="small d-inline-block">{t('profile_section.seperate_using_comma')}</span></label>
            <input
            placeholder={t('profile_section.seduces_me_celine_dion')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
          <input
            placeholder={t('profile_section.enter_music_link_here')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
          </div>
          <div className="custom-form-group col-lg-4">
            <div className="custom-file-drop-area ">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect1} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage1 ? (
                          <img
                            src={previewImage1}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew2s />
                            <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage1(url);
                      setImage1(e.target.files[0]);
                    }}
                    ref={inputRef1}
                  />
                </div>
              </div>
          </div>
      </div>
      <div className="pe-4 me-2 w-100">
          <label className="mb-2 mt-4 pt-2 h8">{t('profile_section.music_music_genre')} <span className="small d-inline-block">{t('profile_section.seperate_using_comma')}</span></label>
          <input
          placeholder={t('profile_section.jazz_blues_soft_rock_blue_eyed_soul')}
          className="shadow-5in br-10 w-100 mb-4"
          type="text"
        />
      </div>
      <div className="row">
        <label className="mb-3">{t('profile_section.favourite_singer')}</label>
        <div className="custom-form-group col-md-4">
          <input
              placeholder={t('profile_section.taylor_swift')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
            <div className="custom-file-drop-area ">
              <div className="imgView">
                <div className="mx-auto mb-4 fileTab h-100">
                  <label onClick={openSelect2} className="w-100 h-100">
                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                      {previewImage2 ? (
                        <img
                          src={previewImage2}
                          alt=""
                          className="w-100 h-100"
                        />
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                          <IconBluew2s />
                          <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                        </div>
                      )}
                    </div>
                  </label>
                </div>
                <input
                  hidden
                  type="file"
                  name="profile"
                  id="profile"
                  accept="image/*"
                  onChange={(e) => {
                    let url = URL.createObjectURL(e.target.files[0]);
                    setPreviewImage2(url);
                    setImage2(e.target.files[0]);
                  }}
                  ref={inputRef2}
                />
              </div>
            </div>
        </div>
        <div className="custom-form-group col-md-4">
          <input
              placeholder={t('profile_section.taylor_swift')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
            <div className="custom-file-drop-area ">
              <div className="imgView">
                <div className="mx-auto mb-4 fileTab h-100">
                  <label onClick={openSelect3} className="w-100 h-100">
                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                      {previewImage3 ? (
                        <img
                          src={previewImage3}
                          alt=""
                          className="w-100 h-100"
                        />
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                          <IconBluew2s />
                          <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                        </div>
                      )}
                    </div>
                  </label>
                </div>
                <input
                  hidden
                  type="file"
                  name="profile"
                  id="profile"
                  accept="image/*"
                  onChange={(e) => {
                    let url = URL.createObjectURL(e.target.files[0]);
                    setPreviewImage3(url);
                    setImage3(e.target.files[0]);
                  }}
                  ref={inputRef3}
                />
              </div>
            </div>
        </div>
        <div className="custom-form-group col-md-4">
          <input
              placeholder={t('profile_section.taylor_swift')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
            <div className="custom-file-drop-area ">
              <div className="imgView">
                <div className="mx-auto mb-4 fileTab h-100">
                  <label onClick={openSelect4} className="w-100 h-100">
                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                      {previewImage4 ? (
                        <img
                          src={previewImage4}
                          alt=""
                          className="w-100 h-100"
                        />
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                          <IconBluew2s />
                          <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                        </div>
                      )}
                    </div>
                  </label>
                </div>
                <input
                  hidden
                  type="file"
                  name="profile"
                  id="profile"
                  accept="image/*"
                  onChange={(e) => {
                    let url = URL.createObjectURL(e.target.files[0]);
                    setPreviewImage4(url);
                    setImage4(e.target.files[0]);
                  }}
                  ref={inputRef4}
                />
              </div>
            </div>
        </div>
      </div>
      <div className="pe-4 me-2 w-100">
          <div className="col-md-6">
            <label className="mb-2 mt-4 pt-2 h8">{t('profile_section.favourite_music_ban')} <span className="small d-inline-block">{t('profile_section.seperate_using_comma')}</span></label>
            <input
            placeholder={t('profile_section.one_direction')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
          </div>
          <div className="col-md-12">
            <label className="mb-2 mt-0 pt-2 h8">{t('profile_section.favorite_tv_show')} <span className="small d-inline-block">{t('profile_section.seperate_using_comma')}</span></label>
            <input
            placeholder={t('profile_section.my_cousin_vinny_two_can_play_that_games')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
          </div>
      </div>
      <div className="row pt-3">
          <div className="col-md-8 d-flex flex-column justify-content-center align-items-center">
            <label className="mb-2 mt-0 pt-0 h8 w-100">{t('profile_section.favorite_movie')} <span className="small d-inline-block">{t('profile_section.seperate_using_comma')}</span></label>
            <input
            placeholder={t('profile_section.my_cousin_vinny_two_can_play_that_games')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
          </div>
          <div className="custom-form-group col-lg-4">
            <div className="custom-file-drop-area ">
              <div className="imgView">
                <div className="mx-auto mb-4 fileTab h-100">
                  <label onClick={openSelect5} className="w-100 h-100">
                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                      {previewImage5 ? (
                        <img
                          src={previewImage5}
                          alt=""
                          className="w-100 h-100"
                        />
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                          <IconBluew2s />
                          <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                        </div>
                      )}
                    </div>
                  </label>
                </div>
                <input
                  hidden
                  type="file"
                  name="profile"
                  id="profile"
                  accept="image/*"
                  onChange={(e) => {
                    let url = URL.createObjectURL(e.target.files[0]);
                    setPreviewImage5(url);
                    setImage5(e.target.files[0]);
                  }}
                  ref={inputRef5}
                />
              </div>
            </div>
          </div>
      </div>
      <div className="row pt-3">
          <label className="mb-2 mt-0 pt-0 h8 w-100">{t('profile_section.favourite_actor')} <span className="small d-inline-block">{t('profile_section.seperate_using_comma')}</span></label>
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <input
            placeholder={t('profile_section.leonardo_diCaprio')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
            />
            <div className="custom-form-group col-md-8 ms-auto">
              <div className="custom-file-drop-area ">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect6} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage6 ? (
                          <img
                            src={previewImage6}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew2s />
                            <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage6(url);
                      setImage6(e.target.files[0]);
                    }}
                    ref={inputRef6}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <input
            placeholder={t('profile_section.leonardo_diCaprio')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
            />
            <div className="custom-form-group col-md-8 me-auto">
            <div className="custom-file-drop-area ">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect8} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage8 ? (
                          <img
                            src={previewImage8}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew2s />
                            <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage8(url);
                      setImage8(e.target.files[0]);
                    }}
                    ref={inputRef8}
                  />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className="row pt-3">
          <div className="col-md-8 d-flex flex-column justify-content-center align-items-center">
            <label className="mb-2 mt-0 pt-0 h8 w-100">{t('profile_section.favorite_game')} <span className="small d-inline-block">{t('profile_section.seperate_using_comma')}</span></label>
            <input
            placeholder={t('profile_section.call_of_duty')}
            className="shadow-5in br-10 w-100 mb-4"
            type="text"
          />
          </div>
          <div className="custom-form-group col-md-4">
            <div className="custom-file-drop-area ">
              <div className="imgView">
                <div className="mx-auto mb-4 fileTab h-100">
                  <label onClick={openSelect7} className="w-100 h-100">
                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                      {previewImage7 ? (
                        <img
                          src={previewImage7}
                          alt=""
                          className="w-100 h-100"
                        />
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                          <IconBluew2s />
                          <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                        </div>
                      )}
                    </div>
                  </label>
                </div>
                <input
                  hidden
                  type="file"
                  name="profile"
                  id="profile"
                  accept="image/*"
                  onChange={(e) => {
                    let url = URL.createObjectURL(e.target.files[0]);
                    setPreviewImage7(url);
                    setImage7(e.target.files[0]);
                  }}
                  ref={inputRef7}
                />
              </div>
            </div>
          </div>
      </div>
      <div className="row">
        <label className="mb-3">{t('profile_section.favourite_anime_character')}</label>
        <div className="custom-form-group col-md-4">
          <input
              placeholder={t('profile_section.light_yagami')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
            <div className="custom-file-drop-area ">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect9} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage9 ? (
                          <img
                            src={previewImage9}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew2s />
                            <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage9(url);
                      setImage9(e.target.files[0]);
                    }}
                    ref={inputRef9}
                  />
                </div>
              </div>
        </div>
        <div className="custom-form-group col-md-4">
          <input
              placeholder={t('profile_section.light_yagami')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
            <div className="custom-file-drop-area ">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect10} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage10 ? (
                          <img
                            src={previewImage10}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew2s />
                            <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage10(url);
                      setImage10(e.target.files[0]);
                    }}
                    ref={inputRef10}
                  />
                </div>
              </div>
        </div>
        <div className="custom-form-group col-md-4">
          <input
              placeholder={t('profile_section.light_yagami')}
              className="shadow-5in br-10 w-100 mb-4"
              type="text"
            />
            <div className="custom-file-drop-area ">
                <div className="imgView">
                  <div className="mx-auto mb-4 fileTab h-100">
                    <label onClick={openSelect11} className="w-100 h-100">
                      <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                        {previewImage11 ? (
                          <img
                            src={previewImage11}
                            alt=""
                            className="w-100 h-100"
                          />
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <IconBluew2s />
                            <p className="mb-0 mt-1">{t('profile_section.upload_image_here')}</p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <input
                    hidden
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={(e) => {
                      let url = URL.createObjectURL(e.target.files[0]);
                      setPreviewImage11(url);
                      setImage11(e.target.files[0]);
                    }}
                    ref={inputRef11}
                  />
                </div>
              </div>
        </div>
      </div>
      <ul>
      <NavItem as="li" className=" text-center mt-5 justify-content-center">
        <ButtonBox
          boxwidth="w-140 mt-0 mb-50 mx-auto"
          clicked="Saved"
          name="Save/Next"
        />
      </NavItem>
      </ul>
    </div>
  );
}
