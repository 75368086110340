import React, { useState } from "react";
import desktop_1 from "../../../../images/subscription/Desktop1.png";
import tab_1 from "../../../../images/subscription/Tab1.png";
import mobile_1 from "../../../../images/subscription/Mobile1.png";
import desktop_2 from "../../../../images/subscription/Desktop2.png";
import tab_2 from "../../../../images/subscription/Tab2.png";
import mobile_2 from "../../../../images/subscription/Mobile2.png";
import desktop_3 from "../../../../images/subscription/Desktop3.png";
import tab_3 from "../../../../images/subscription/Tab3.png";
import mobile_3 from "../../../../images/subscription/Mobile3.png";
import desktop_4 from "../../../../images/subscription/Desktop4.png";
import tab_4 from "../../../../images/subscription/Tab4.png";
import mobile_4 from "../../../../images/subscription/Mobile4.png";
import desktop_5 from "../../../../images/subscription/Desktop5.png";
import tab_5 from "../../../../images/subscription/Tab5.png";
import mobile_5 from "../../../../images/subscription/Mobile5.png";
import desktop_6 from "../../../../images/subscription/Desktop6.png";
import tab_6 from "../../../../images/subscription/Tab6.png";
import mobile_6 from "../../../../images/subscription/Mobile6.png";
import SubscriptionSectionRegularThemeDesktopView1 from "./SubscriptionSectionRegularThemeDesktopView1.js";
import SubscriptionSectionRegularThemeDesktopView2 from "./SubscriptionSectionRegularThemeDesktopView2.js";
import SubscriptionSectionRegularThemeDesktopView3 from "./SubscriptionSectionRegularThemeDesktopView3.js";
import SubscriptionSectionRegularThemeDesktopView4 from "./SubscriptionSectionRegularThemeDesktopView4.js";
import SubscriptionSectionRegularThemeDesktopView5 from "./SubscriptionSectionRegularThemeDesktopView5.js";
import SubscriptionSectionRegularThemeDesktopView6 from "./SubscriptionSectionRegularThemeDesktopView6.js";
import SubscriptionSectionRegularThemeTabView1 from "./SubscriptionSectionRegularThemeTabView1.js";
import SubscriptionSectionRegularThemeTabView2 from "./SubscriptionSectionRegularThemeTabView2.js";
import SubscriptionSectionRegularThemeTabView3 from "./SubscriptionSectionRegularThemeTabView3.js";
import SubscriptionSectionRegularThemeTabView4 from "./SubscriptionSectionRegularThemeTabView4.js";
import SubscriptionSectionRegularThemeTabView5 from "./SubscriptionSectionRegularThemeTabView5.js";
import SubscriptionSectionRegularThemeTabView6 from "./SubscriptionSectionRegularThemeTabView6.js";
import SubscriptionSectionRegularThemeMobileView1 from "./SubscriptionSectionRegularThemeMobileView1.js";
import SubscriptionSectionRegularThemeMobileView2 from "./SubscriptionSectionRegularThemeMobileView2.js";
import SubscriptionSectionRegularThemeMobileView3 from "./SubscriptionSectionRegularThemeMobileView3.js";
import SubscriptionSectionRegularThemeMobileView4 from "./SubscriptionSectionRegularThemeMobileView4.js";
import SubscriptionSectionRegularThemeMobileView5 from "./SubscriptionSectionRegularThemeMobileView5.js";
import SubscriptionSectionRegularThemeMobileView6 from "./SubscriptionSectionRegularThemeMobileView6.js";
import Modal from "react-bootstrap/Modal";
import "./SubscriptionSectionThemes.scss";
import ButtonBoxNew from "../../../global/ButtonBoxNew";

export default function SubscriptionSectionRegularTheme() {
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [TabTwoShow, setTabTwoShow] = useState(false);
  const [MobileTwoShow, setMobileTwoShow] = useState(false);
  const [deskShow, setDeskShow] = useState(false);
  const [desktwoShow, setDesktwoShow] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);
  const [TabFourShow, setTabFourShow] = useState(false);
  const [MobileFourShow, setMobileFourShow] = useState(false);
  const [deskFourShow, setdeskFourShow] = useState(false);
  const [TabFiveShow, setTabFiveShow] = useState(false);
  const [MobileFiveShow, setMobileFiveShow] = useState(false);
  const [deskFiveShow, setdeskFiveShow] = useState(false);
  const [TabSixShow, setTabSixShow] = useState(false);
  const [MobileSixShow, setMobileSixShow] = useState(false);
  const [deskSixShow, setdeskSixShow] = useState(false);
  return (
    <div className="">
      <h4 className="text-center">Regular Design Version 1</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_1} alt="deshtop-1"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setDeskShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeDesktopView1 Regular />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_1} alt="tab-1"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setSmShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeTabView1 Regular />
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_1} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
             <SubscriptionSectionRegularThemeMobileView1 Regular/>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Regular Design Version 2</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2} alt="desktop-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setDesktwoShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setDesktwoShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktwoShow}
            onHide={() => setDesktwoShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeDesktopView2 Regular />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2} alt="tab-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabTwoShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabTwoShow(true)}/>

          <Modal
            show={TabTwoShow}
            onHide={() => setTabTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-tit"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeTabView2 Regular />
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileTwoShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileTwoShow(true)}/>

          <Modal
            show={MobileTwoShow}
            onHide={() => setMobileTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-ti"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeMobileView2 Regular/>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Regular Design Version 3</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3} alt="desktop-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setDeskthreeShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setDeskthreeShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskthreeShow}
            onHide={() => setDeskthreeShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeDesktopView3 Regular />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3} alt="tab-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabThreeShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabThreeShow(true)}/>

          <Modal
            show={TabThreeShow}
            onHide={() => setTabThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeTabView3 Regular />
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3} alt="mobile-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileThreeShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileThreeShow(true)}/>

          <Modal
            show={MobileThreeShow}
            onHide={() => setMobileThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeMobileView3 Regular/>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Regular Design Version 4</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_4} alt="desktop-4"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setdeskFourShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setdeskFourShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFourShow}
            onHide={() => setdeskFourShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeDesktopView4 Regular />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_4} alt="tab-4"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFourShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabFourShow(true)}/>

          <Modal
            show={TabFourShow}
            onHide={() => setTabFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeTabView4 Regular />
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_4} alt="mobile-4"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFourShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileFourShow(true)}/>

          <Modal
            show={MobileFourShow}
            onHide={() => setMobileFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeMobileView4 Regular/>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Regular Design Version 5</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_5} alt="desktop-5"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setdeskFiveShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Dektop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setdeskFiveShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFiveShow}
            onHide={() => setdeskFiveShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {<SubscriptionSectionRegularThemeDesktopView5 Regular />}
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_5} alt="tab-5"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFiveShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabFiveShow(true)}/>

          <Modal
            show={TabFiveShow}
            onHide={() => setTabFiveShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeTabView5 Regular />
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_5} alt="mobile-5"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFiveShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileFiveShow(true)}/>

          <Modal
            show={MobileFiveShow}
            onHide={() => setMobileFiveShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeMobileView5 Regular/>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Regular Design Version 6</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_6} alt="desktop-6"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setdeskSixShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setdeskSixShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskSixShow}
            onHide={() => setdeskSixShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeDesktopView6 Regular />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_6} alt="tab-6"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabSixShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setTabSixShow(true)}/>

          <Modal
            show={TabSixShow}
            onHide={() => setTabSixShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {<SubscriptionSectionRegularThemeTabView6 Regular />}
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_6} alt="mobile-6"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileSixShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileSixShow(true)}/>

          <Modal
            show={MobileSixShow}
            onHide={() => setMobileSixShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SubscriptionSectionRegularThemeMobileView6 Regular/>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
