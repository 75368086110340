import React from "react";
import RadioOff from "../../../../global/RadioOff";
import RadioOn from "../../../../global/RadioOn";
import ButtonBox from "../../../../global/ButtonBox";
import { useTranslation } from "react-i18next";
import { NavItem } from 'react-bootstrap';
export default function EmailTwo() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <ul className="">
        <NavItem as="li">
          <h3 className="mb-0"> {t('privacy_component.email_notifications_component.email_me_when')}</h3>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <p>
          {t('privacy_component.email_notifications_component.email_me_text')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="asks1" name="asks" />
            <RadioOff id="asks2" name="asks" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <p>
              {t('privacy_component.email_notifications_component.someone_mention_me')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="mentions1" name="mentions" />
            <RadioOff id="mentions2" name="mentions" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <p>
            {t('privacy_component.email_notifications_component.i_receive_invitations_to_invite_new_friends')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="receive1" name="receive" />
            <RadioOff id="receive2" name="receive" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <p>
            {t('privacy_component.email_notifications_component.my_invitations_are_about_to_expire')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="expire1" name="expire" />
            <RadioOff id="expire2" name="expire" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <p>
            {t('privacy_component.email_notifications_component.someone_accepts_my_invitation')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="accepts1" name="accepts" />
            <RadioOff id="accepts2" name="accepts" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <p>
            {t('privacy_component.email_notifications_component.anyone_follows_me')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="follows1" name="follows" />
            <RadioOff id="followss2" name="follows" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <p>
            {t('privacy_component.email_notifications_component.a_player_follows_me')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="player1" name="player" />
            <RadioOff id="player2" name="player" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <h3 className="mb-0"> {t('privacy_component.email_notifications_component.email_me_when')}</h3>
        </NavItem>
        <NavItem as="li">
          <h6>{t('privacy_component.email_notifications_component.subscribe_us_to')}</h6>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.education')}</h5>
            <p>
           {t('privacy_component.email_notifications_component.monday')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="education1" name="education" />
            <RadioOff id="education2" name="education" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.opportunities')}</h5>
            <p>
  {t('privacy_component.email_notifications_component.tuesday')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="opportunities1" name="opportunities" />
            <RadioOff id="opportunities2" name="opportunities" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.figure_bosses_insider')}</h5>
            <p>
            {t('privacy_component.email_notifications_component.wednesday')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="bosses1" name="bosses" />
            <RadioOff id="bosses2" name="bosses" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr">
          <div className="listText">
            <h5>{t('privacy_component.email_notifications_component.inspiration_for_you')}</h5>
            <p className="mr-3">
            {t('privacy_component.email_notifications_component.friday')}
            </p>
          </div>
          <div className="radioButton d-flex">
            <RadioOn id="inspiration1" name="inspiration" />
            <RadioOff id="inspiration2" name="inspiration" text="Off" />
          </div>
        </NavItem>
        <NavItem as="li" className=" text-center mt-5 justify-content-center">
          <ButtonBox
            boxwidth="w-140 mt-0 mb-50 mx-auto"
            clicked="Saved"
            name="Save/Next"
          />
        </NavItem>
      </ul>
    </div>
  );
}
