import * as React from 'react';
import { Link } from "react-router-dom";
import '../login/login.scss'

export default function SidebarButton(props) {
    return (
        <div className="btnSection" >
        {/* <Link  className={"br-10 " + props.boxClass} id={props.id} to={props.url}> */}
        <Link  className={"br-10 " + props.boxClass} id={props.id} to="#">
            <h3 className='h8 w-100 text-center'>{props.Stage}</h3>
            <div className='w-100 d-flex align-items-center justify-content-start'>
                <img src={props.src} alt="" />
                <span className={props.TextClass}>
                    {props.Text}</span>
            </div>
        </Link>
        </div>
    );
}