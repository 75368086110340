import React from "react";
import ButtonBox from "../../../global/ButtonBox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownload, faLock } from '@fortawesome/free-solid-svg-icons'
import './EmailSignatureStep.scss';
import { NavItem } from 'react-bootstrap';
import Facebook from '../../../../images/social-icons/facebook.png'
import Twitter from '../../../../images/social-icons/twitter.png'
import Instagram from '../../../../images/social-icons/instagram.png'
import Youtube from '../../../../images/social-icons/youtube.png'
import Pinterst from '../../../../images/social-icons/pinterst.png'
import LinkedIn from '../../../../images/social-icons/linkedIn.png'
import TikTok from '../../../../images/social-icons/tikTok.png'
import Twitch from '../../../../images/social-icons/twitch.png'
import Reddit from '../../../../images/social-icons/reddit.png'
import Behance from '../../../../images/social-icons/behance.png'
import Discord from '../../../../images/social-icons/discord.png'
import Dribbble from '../../../../images/social-icons/dribbble.png'
import Telegram from '../../../../images/social-icons/telegram.png'
import Medium from '../../../../images/social-icons/medium.png'
import Patreon from '../../../../images/social-icons/patreon.png'
import ButtonBoxNew from "../../../global/ButtonBoxNew";
import { ReactComponent as Download } from "../../../../images/icons/icon29.svg";

export default function EmailSignatureForm1() {
  // const { t, i18n } = useTranslation();
  // const theme = (theme) => ({
  //   ...theme,
  //   colors: {
  //     ...theme.colors,
  //     primary25: "#EFF4F8",
  //     primary: "#EFF4F8",
  //   },
  // });


  return (
    <div>
      <ul className="TAB-8_1-form mt_100">
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={Facebook} alt="" />
            </div>
            <label for="">Facebook</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={Twitter} alt="" />
            </div>
            <label for="">Twitter</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={Instagram} alt="" />
            </div>
            <label for="">Instagram</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={Youtube} alt="" />
            </div>
            <label for="">Youtube</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={Pinterst} alt="" />
            </div>
            <label for="">Pinterst</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={LinkedIn} alt="" />
            </div>
            <label for="">LinkedIn</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={TikTok} alt="" />
            </div>
            <label for="">TikTok</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={Twitch} alt="" />
            </div>
            <label for="">Twitch</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={Reddit} alt="" />
            </div>
            <label for="">Reddit</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={Behance} alt="" />
            </div>
            <label for="">Behance</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={Discord} alt="" />
            </div>
            <label for="">Discord</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={Dribbble} alt="" />
            </div>
            <label for="">Dribbble</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={Telegram} alt="" />
            </div>
            <label for="">Telegram</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={Medium} alt="" />
            </div>
            <label for="">Medium</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-1">
            <div className="social_icon mb-2">
              <img src={Patreon} alt="" />
            </div>
            <label for="">Patreon</label>
          </div>
          <div className="col-md-9 ps-4 pe-0">
            <div className="flex justify-content-between items-center mb-1 h_30">
              <h6>Social Link</h6>
              <div className="flex items-center">
                <span className="QR_label">Save QR code</span>
                <div className="download_icon">
                  {/* <FontAwesomeIcon icon={faCloudDownload} className=" icon" /> */}
                  <Download />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Https://"
                  aria-label="Example input"
                  aria-describedby="button-addon1"
                />
                {/* <button class="btn btn-primary px-2" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                  Generate QR code
                </button> */}
                 <ButtonBoxNew  name=" Generate QR code" boxwidth="w-220 mt-0 mb-0 bg2 br-40" />

              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="QR_outer_div mx-auto">
              <div className="QR_box">
                <FontAwesomeIcon icon={faLock} className=" icon" />
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-end">
          {/* <button class="btn btn-primary px-2 me-3 all_QR_btn" type="button" id="button-addon1" data-mdb-ripple-color="dark">
            Generate all QR code
          </button> */}
                 <ButtonBoxNew  name=" Generate all QR code" boxwidth="w-300 mt-0 mb-4 bg2 br-40" />

        </div>


        {/*            
                <NavItem as="li" className="mb-5 border-box">
                  
                </NavItem> */}
        <NavItem as="li" className=" text-center time-btn mt-4">

          <ButtonBox
            boxwidth="w-300 mt-0 mb-50 mx-auto"
            clicked="Save/Next"
            name="Save/Next"
          />
        </NavItem>
      </ul>
    </div >
  );
}
