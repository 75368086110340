import React, { useState, useEffect } from 'react';

import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';


import { useTranslation } from "react-i18next";



export default function ToggleComponent() {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);











  const [isOpen, setIsOpen] = useState(false);
  
    useEffect(() => {
      if (isOpen) {
        const timer = setTimeout(() => {
          setIsOpen(false);
        }, 5000);
  
        return () => clearTimeout(timer);
      }
    }, [isOpen]);
  


  return (
    <div>



      <div className="">
     
 


 
         
       

                <div className="mode-set" >
                  {t('profile_hero_section.mode')}
                </div>
                <div className="switch-border">
                  <input type="checkbox" />
                </div>

                <div className="social-set" >
                  {t('profile_hero_section.social')}
                </div>




          
       
     


    
     


      </div>




    </div>



  );
}
