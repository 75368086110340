import React from 'react'
import CounterTimer from '../../global/counterTimer/CounterTimer';
import './ThreeHoursBusinessLaunch.scss';
import '../threeHoursBusinessLaunch/customDomain/CustomDomainStep.scss';
import { useTranslation } from "react-i18next";
import TimelineTabMobile from '../../global/timelineTab/TimelineTabMobile';
import img1 from '../../../images/StepZero/img69.png';
import img10 from '../../../images/StepZero/left.png';
import img11 from '../../../images/StepZero/right.png';
import { Link } from 'react-router-dom';
import ButtonBox from "../../global/ButtonBox";
export default function NicheClarityDiscovery() {
  const { t, i18n } = useTranslation();
  return (
    <div className='twelvestep-scss'>
      <div className='flex header-text' ><h2>{t('business_step_2.three_hours_business_launch_challenge')} </h2>
        <div className='circles-small'>
        <div className='circle-small'>
            <div className='text'><span className="d-block">45%</span></div>
            <svg>
              <circle className='bg' cx="30" cy="30" r="20"></circle>
              <circle className='progress one' cx="30" cy="30" r="20"></circle>
            </svg>
          </div>
        </div>
      </div>
      <div className='three-hour-launch d-flex'>
        <div className=''>
          <CounterTimer />
        </div>

        <div className='width-tab-set'>
          {/* <TimelineTabDesktop /> */}
          <TimelineTabMobile />
        </div>
        <div className='width-form-set'>
          <div className='flex'>
            <div className='width-fll w-1120 mb-5 text-center'>
            <div className="borderTop1 borderTop1s">
                <div className="borderTop borderTop4">
                  <Link className='leftlink'>
                    <img src={img10} alt="" width="38px" />
                  </Link>
                  <Link className='rightlink'>
                    <img src={img11} alt="" width="38px" />
                  </Link>
                  <div className='shadow-5in setponeimg'>
                    <img src={img1} alt='' />
                    <div className='btnsec d-flex w-100 py-5 justify-content-center'>
                      <ButtonBox
                        boxwidth="w-110 bg-theme mt-0 mb-0 me-5"
                        clicked="Cancel"
                        name="Cancel"
                      />
                      <ButtonBox
                        boxwidth="w-110  mt-0 mb-0"
                        clicked="Saved"
                        name="Save"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
