import React from "react";
import "./HireMeSideBox.scss";
// import Percent from '../../../../../../../../images/hire-me/desktopview/100%.png'

const HireMeSideBox = ({ Regular, Neumorphic }) => {
  return (
    <>
      <div
        className={`side-position-box ${
          Regular ? "Regular-style" : Neumorphic ? "Neumorphic-style" : ""
        }`}
      >
        <p className="s-p-pfirst">TALK WITH</p>
        <h2 className="s-p-hfirst">CEO-Founder 7 FIGURE BOSSES</h2>
        <p className="s-p-psecond">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius
          tempor incididunt ut labore et dolore magna aliqua. Ut enim adiqua
          minim veniam quis nostrud exercitation ullamco incididunt magna aliqua
          veniam quis nostrud.
        </p>

        <div className="per-card">
          <div className="pr-card-child d-flex justify-content-between">
            <p className="ch">BUSINESS INCUBATOR</p>
            <p className="ch-one o-element1">100%</p>
          </div>
          <div className="line-parent">
            <div className="line-child">.</div>
            <div className="line-icon">
              <i class="fa-sharp fa-solid fa-circle"></i>
            </div>
          </div>
        </div>
        <div className="per-card">
          <div className="pr-card-child d-flex justify-content-between">
            <p className="ch">BUSINESS ACCELERATOR</p>
            <p className="ch-one c-element2">100%</p>
          </div>
          <div className="line-parent">
            <div className="line-child">.</div>
            <div className="line-icon">
              <i class="fa-sharp fa-solid fa-circle"></i>
            </div>
          </div>
        </div>
        <div className="per-card">
          <div className="pr-card-child d-flex justify-content-between">
            <p className="ch">7-FIGURE SCALE-UP</p>
            {/* <img src={Percent} alt="" /> */}
            <p className="ch-one c-element3">100%</p>
          </div>
          <div className="line-parent">
            <div className="line-child">.</div>
            <div className="line-icon">
              <i class="fa-sharp fa-solid fa-circle"></i>
            </div>
          </div>
        </div>
        <div className="per-card">
          <div className="pr-card-child d-flex justify-content-between">
            <p className="ch">SOCIAL NETWORK STRATEGIST</p>
            <p className="ch-one c-element4">100%</p>
          </div>
          <div className="line-parent">
            <div className="line-child">.</div>
            <div className="line-icon">
              <i class="fa-sharp fa-solid fa-circle"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HireMeSideBox;
