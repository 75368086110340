// src/components/Tabs.js
import React, { useState } from 'react';
import frame from "../../images/audio/img14.png";
import frame1 from "../../images/audio/img15.png";
import frame2 from "../../images/audio/img18.png";
import tab1 from "../../images/audio/tab1.png";
import tab2 from "../../images/audio/tab2.png";
import tab3 from "../../images/audio/tab3.png";
import card1 from "../../images/audio/card1.png";
import card2 from "../../images/audio/card2.png";
import visa from "../../images/audio/visa.png";
import visa1 from "../../images/audio/visa1.png";
import { Link } from 'react-router-dom';
import { NavItem } from 'react-bootstrap';

const Tabvid = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className='tabpayment'>
      <div className="tab-buttons">
        <button
          className={activeTab === 1 ? "active" : ""}
          onClick={() => handleTabClick(1)}
        >
          <img src={tab1} alt='' />
        </button>
        <button
          className={activeTab === 2 ? "active" : ""}
          onClick={() => handleTabClick(2)}
        >
          <img src={tab2} alt='' />
        </button>
        <button
          className={activeTab === 3 ? "active" : ""}
          onClick={() => handleTabClick(3)}
        >
          <img src={tab3} alt='' />
        </button>
      </div>
      <div className="tab-content mt-3 mb-4 mx-auto">
        {activeTab === 1 && <div className='greentb'>
          <img src={frame} alt='' />
          <div className='row mx-0 mt-3'>
            <div className='col-lg-7s text-start d-flex justify-content-between flex-wrap'>
              <label className='col-lg-12'>Card Number</label>
              <div className='col-lg-4 pe-2'><input type='number' placeholder='4000' /></div>
              <div className='col-lg-4 pe-2'><input type='number' placeholder='1234600' /></div>
              <div className='col-lg-4'><input type='number' placeholder='90105' /></div>
            </div>
            <div className='col-lg-5s ps-0 text-start'>
              <label className='d-flex'>CVV- 4-digits
                <div className="dropdown dropend dropn ms-4">
                  <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                    <Link to="#" className="d-flex dropBtn justify-content-center align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <g clip-path="url(#clip0_114_115517)">
                          <path d="M8.5 15.1924C6.64348 15.1924 4.86301 14.4549 3.55025 13.1421C2.2375 11.8294 1.5 10.0489 1.5 8.19238C1.5 6.33587 2.2375 4.55539 3.55025 3.24264C4.86301 1.92988 6.64348 1.19238 8.5 1.19238C10.3565 1.19238 12.137 1.92988 13.4497 3.24264C14.7625 4.55539 15.5 6.33587 15.5 8.19238C15.5 10.0489 14.7625 11.8294 13.4497 13.1421C12.137 14.4549 10.3565 15.1924 8.5 15.1924ZM8.5 16.1924C10.6217 16.1924 12.6566 15.3495 14.1569 13.8492C15.6571 12.3489 16.5 10.3141 16.5 8.19238C16.5 6.07065 15.6571 4.03582 14.1569 2.53553C12.6566 1.03524 10.6217 0.192383 8.5 0.192383C6.37827 0.192383 4.34344 1.03524 2.84315 2.53553C1.34285 4.03582 0.5 6.07065 0.5 8.19238C0.5 10.3141 1.34285 12.3489 2.84315 13.8492C4.34344 15.3495 6.37827 16.1924 8.5 16.1924Z" fill="white" />
                          <path d="M9.42995 6.78038L7.13995 7.06738L7.05795 7.44738L7.50795 7.53038C7.80195 7.60038 7.85995 7.70638 7.79595 7.99938L7.05795 11.4674C6.86395 12.3644 7.16295 12.7864 7.86595 12.7864C8.41095 12.7864 9.04395 12.5344 9.33095 12.1884L9.41895 11.7724C9.21895 11.9484 8.92695 12.0184 8.73295 12.0184C8.45795 12.0184 8.35795 11.8254 8.42895 11.4854L9.42995 6.78038ZM9.49995 4.69238C9.49995 4.9576 9.3946 5.21195 9.20706 5.39949C9.01952 5.58703 8.76517 5.69238 8.49995 5.69238C8.23474 5.69238 7.98038 5.58703 7.79285 5.39949C7.60531 5.21195 7.49995 4.9576 7.49995 4.69238C7.49995 4.42717 7.60531 4.17281 7.79285 3.98528C7.98038 3.79774 8.23474 3.69238 8.49995 3.69238C8.76517 3.69238 9.01952 3.79774 9.20706 3.98528C9.3946 4.17281 9.49995 4.42717 9.49995 4.69238Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_114_115517">
                            <rect width="16" height="16" fill="white" transform="translate(0.5 0.192383)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end dropLink newdrop bg shadow-4 w-400 border-0 ms-5" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                    <NavItem as="li" className=" mb-4">
                      <h4><b>American Express</b> (4 digits)</h4>
                    </NavItem>
                    <NavItem as="li" className='text-center'>
                      <img src={card1} alt='' className='w-100' />
                    </NavItem>
                  </ul>
                </div>
              </label>
              <input type='number' placeholder='0320' />
            </div>
            <div className='col-lg-8 mt-4 pe-0 text-start d-flex justify-content-between flex-wrap'>
              <label className='col-lg-12'>Expiry Date</label>
              <div className='col-lg-7s pe-2'><input type='text' placeholder='4000' /></div>
              <div className='col-lg-5 pe-2'><input type='text' placeholder='1234600' /></div>
            </div>
            <div className='col-lg-4 mt-4 text-start'>
              <label>Member Since</label>
              <input type='number' placeholder='09' />
            </div>
            <div className='col-lg-12 mt-4 text-start'>
              <label>Cardholder’s Name</label>
              <input type='text' placeholder='Dyna appolon' />
            </div>
          </div>
        </div>}
        {activeTab === 2 && <div className='graytb'>
          <img src={frame1} alt='' />
          <div className='row mx-0'>
            <div className='col-lg-8 text-start d-flex justify-content-between flex-wrap'>
              <label className='col-lg-12'>Card Number</label>
              <div className='col-lg-3s pe-2'><input type='number' placeholder='4000' /></div>
              <div className='col-lg-3s pe-2'><input type='number' placeholder='4000' /></div>
              <div className='col-lg-3s pe-2'><input type='number' placeholder='1234600' /></div>
              <div className='col-lg-3s'><input type='number' placeholder='90105' /></div>
            </div>
            <div className='col-lg-4 ps-0 text-start'>
              <label className='d-flex'>CVV- 3-digits
                <div className="dropdown dropend dropn ms-4">
                  <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                    <Link to="#" className="d-flex dropBtn justify-content-center align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <g clip-path="url(#clip0_114_115517)">
                          <path d="M8.5 15.1924C6.64348 15.1924 4.86301 14.4549 3.55025 13.1421C2.2375 11.8294 1.5 10.0489 1.5 8.19238C1.5 6.33587 2.2375 4.55539 3.55025 3.24264C4.86301 1.92988 6.64348 1.19238 8.5 1.19238C10.3565 1.19238 12.137 1.92988 13.4497 3.24264C14.7625 4.55539 15.5 6.33587 15.5 8.19238C15.5 10.0489 14.7625 11.8294 13.4497 13.1421C12.137 14.4549 10.3565 15.1924 8.5 15.1924ZM8.5 16.1924C10.6217 16.1924 12.6566 15.3495 14.1569 13.8492C15.6571 12.3489 16.5 10.3141 16.5 8.19238C16.5 6.07065 15.6571 4.03582 14.1569 2.53553C12.6566 1.03524 10.6217 0.192383 8.5 0.192383C6.37827 0.192383 4.34344 1.03524 2.84315 2.53553C1.34285 4.03582 0.5 6.07065 0.5 8.19238C0.5 10.3141 1.34285 12.3489 2.84315 13.8492C4.34344 15.3495 6.37827 16.1924 8.5 16.1924Z" fill="white" />
                          <path d="M9.42995 6.78038L7.13995 7.06738L7.05795 7.44738L7.50795 7.53038C7.80195 7.60038 7.85995 7.70638 7.79595 7.99938L7.05795 11.4674C6.86395 12.3644 7.16295 12.7864 7.86595 12.7864C8.41095 12.7864 9.04395 12.5344 9.33095 12.1884L9.41895 11.7724C9.21895 11.9484 8.92695 12.0184 8.73295 12.0184C8.45795 12.0184 8.35795 11.8254 8.42895 11.4854L9.42995 6.78038ZM9.49995 4.69238C9.49995 4.9576 9.3946 5.21195 9.20706 5.39949C9.01952 5.58703 8.76517 5.69238 8.49995 5.69238C8.23474 5.69238 7.98038 5.58703 7.79285 5.39949C7.60531 5.21195 7.49995 4.9576 7.49995 4.69238C7.49995 4.42717 7.60531 4.17281 7.79285 3.98528C7.98038 3.79774 8.23474 3.69238 8.49995 3.69238C8.76517 3.69238 9.01952 3.79774 9.20706 3.98528C9.3946 4.17281 9.49995 4.42717 9.49995 4.69238Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_114_115517">
                            <rect width="16" height="16" fill="white" transform="translate(0.5 0.192383)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end dropLink newdrop bg shadow-4 w-400 border-0 ms-5" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                    <NavItem as="li" className=" mb-4">
                      <h4><b>Visa/MasterCard </b> (3 digits)</h4>
                    </NavItem>
                    <NavItem as="li" className='text-center'>
                      <img src={card2} alt='' className='w-100' />
                    </NavItem>
                  </ul>
                </div>
              </label>
              <input type='number' placeholder='0320' />
            </div>
            <div className='col-lg-6 mt-4 pe-0 text-start d-flex justify-content-between flex-wrap'>
              <label className='col-lg-12'>Expiry Date</label>
              <input type='text' placeholder='1234600' />
            </div>
            <div className='col-lg-6 mt-4 pt-1 text-start'>
              <label></label>
              <input type='number' placeholder='09' />
            </div>
            <div className='col-lg-9s mt-4 text-start'>
              <label>Cardholder’s Name</label>
              <input type='text' placeholder='Dyna appolon' />
            </div>
            <div className='col-lg-3s pt-5 mt-4 text-end'>
              <img src={visa} alt='' />
            </div>
          </div>
        </div>}
        {activeTab === 3 && <div className='graytb1'>
          <div className='row mx-0 w-100 pt-4 px-4'>
            <div className='col-lg-5s ps-0 text-start'>
              <label>Expiry Date</label>
              <input type='number' placeholder='0320' />
            </div>
            <div className='col-lg-7'>
            </div>
          </div>
          <img src={frame2} alt='' />
          <div className='row mx-0'>
            <div className='col-lg-8 text-start d-flex justify-content-between flex-wrap'>
              <label className='col-lg-12'>Card Number</label>
              <div className='col-lg-3s pe-2'><input type='number' placeholder='4000' /></div>
              <div className='col-lg-3s pe-2'><input type='number' placeholder='4000' /></div>
              <div className='col-lg-3s pe-2'><input type='number' placeholder='1234600' /></div>
              <div className='col-lg-3s'><input type='number' placeholder='90105' /></div>
            </div>
            <div className='col-lg-4 ps-0 text-start'>
              <label className='d-flex'>CVV- 4-digits
                <div className="dropdown dropend dropn ms-4">
                  <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                    <Link to="#" className="d-flex dropBtn justify-content-center align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <g clip-path="url(#clip0_114_115517)">
                          <path d="M8.5 15.1924C6.64348 15.1924 4.86301 14.4549 3.55025 13.1421C2.2375 11.8294 1.5 10.0489 1.5 8.19238C1.5 6.33587 2.2375 4.55539 3.55025 3.24264C4.86301 1.92988 6.64348 1.19238 8.5 1.19238C10.3565 1.19238 12.137 1.92988 13.4497 3.24264C14.7625 4.55539 15.5 6.33587 15.5 8.19238C15.5 10.0489 14.7625 11.8294 13.4497 13.1421C12.137 14.4549 10.3565 15.1924 8.5 15.1924ZM8.5 16.1924C10.6217 16.1924 12.6566 15.3495 14.1569 13.8492C15.6571 12.3489 16.5 10.3141 16.5 8.19238C16.5 6.07065 15.6571 4.03582 14.1569 2.53553C12.6566 1.03524 10.6217 0.192383 8.5 0.192383C6.37827 0.192383 4.34344 1.03524 2.84315 2.53553C1.34285 4.03582 0.5 6.07065 0.5 8.19238C0.5 10.3141 1.34285 12.3489 2.84315 13.8492C4.34344 15.3495 6.37827 16.1924 8.5 16.1924Z" fill="white" />
                          <path d="M9.42995 6.78038L7.13995 7.06738L7.05795 7.44738L7.50795 7.53038C7.80195 7.60038 7.85995 7.70638 7.79595 7.99938L7.05795 11.4674C6.86395 12.3644 7.16295 12.7864 7.86595 12.7864C8.41095 12.7864 9.04395 12.5344 9.33095 12.1884L9.41895 11.7724C9.21895 11.9484 8.92695 12.0184 8.73295 12.0184C8.45795 12.0184 8.35795 11.8254 8.42895 11.4854L9.42995 6.78038ZM9.49995 4.69238C9.49995 4.9576 9.3946 5.21195 9.20706 5.39949C9.01952 5.58703 8.76517 5.69238 8.49995 5.69238C8.23474 5.69238 7.98038 5.58703 7.79285 5.39949C7.60531 5.21195 7.49995 4.9576 7.49995 4.69238C7.49995 4.42717 7.60531 4.17281 7.79285 3.98528C7.98038 3.79774 8.23474 3.69238 8.49995 3.69238C8.76517 3.69238 9.01952 3.79774 9.20706 3.98528C9.3946 4.17281 9.49995 4.42717 9.49995 4.69238Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_114_115517">
                            <rect width="16" height="16" fill="white" transform="translate(0.5 0.192383)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end dropLink newdrop bg shadow-4 w-400 border-0 ms-5" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                    <NavItem as="li" className=" mb-4">
                      <h4><b>Visa/MasterCard </b> (3 digits)</h4>
                    </NavItem>
                    <NavItem as="li" className='text-center'>
                      <img src={card2} alt='' className='w-100' />
                    </NavItem>
                  </ul>
                </div>
              </label>
              <input type='number' placeholder='0320' />
            </div>
            <div className='col-lg-6 mt-4 pe-0 text-start d-flex justify-content-between flex-wrap'>
              <label className='col-lg-12'>Expiry Date</label>
              <input type='text' placeholder='1234600' />
            </div>
            <div className='col-lg-6 mt-4 pt-1 text-start'>
              <label></label>
              <input type='number' placeholder='09' />
            </div>
            <div className='col-lg-9s mt-4 text-start'>
              <label>Cardholder’s Name</label>
              <input type='text' placeholder='Dyna appolon' />
            </div>
            <div className='col-lg-3s pt-5 mt-4 text-end'>
              <img src={visa1} alt='' />
            </div>
          </div>
          </div>
        }
    </div>
    </div >
  );
};

export default Tabvid;
