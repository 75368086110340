import React, { useState } from "react";
import NicheOne from "./Nicheform/NicheOne";
import NicheTwo from "./Nicheform/NicheTwo";
import NicheThree from "./Nicheform/NicheThree";
import NicheFour from "./Nicheform/NicheFour";
import NicheFive from "./Nicheform/NicheFive";
import NicheSix from "./Nicheform/NicheSix";
import NicheOneSec from "./Nicheform/NicheOneSec";
import NicheSeven from "./Nicheform/NicheSeven";
import ButtonBox from "../../../../global/ButtonBox";
import NicheOneEdit from "./Nicheform/NicheOneEdit";
import img1s from '../../../../../images/triangle1.png';
import NicheSixSec from "./Nicheform/NicheSixSec";
export default function NicheFormOne() {
  const handleClick1 = () => {
    setToggle1(!toggle1);
  };
  const [toggle1, setToggle1] = useState("true");
  const [valueFromComponentA, setValueFromComponentA] = useState('');

  const handleValueChange = (newValue) => {
    // Receive the value from Component A and set it in the parent component
    setValueFromComponentA(newValue);
  };
  return (
    <div className="w-100">
      <div className="row mb-5">
        <div className="col-lg-6 mx-auto">
          <NicheOne />
          <span  onClick={handleClick1} className="cursor-pointer"><img src={img1s} alt="" width="40px" height="20px" /></span>
        </div>
        <div className="col-lg-6 mx-auto">
        <NicheOneSec  />
        </div>
        {toggle1 ? (
          <></>
          ) : (
          <div className="col-lg-12 mx-auto">
            <NicheOneEdit handleClick1={handleClick1} />
          </div>
        )}
      </div>
      <NicheTwo />
      <NicheThree />
      <NicheFour />
      <NicheFive />
      <div className="px-3 pt-3">
        <NicheSix onValueChange={handleValueChange} />
        <span className="cursor-pointer"><img src={img1s} alt="" width="40px" height="20px" /></span>
        <NicheSixSec activeColor={valueFromComponentA} />
      </div>
      <NicheSeven />
      <div className='btnsec d-flex w-100 my-5 pt-5 justify-content-center'>
        <ButtonBox
          boxwidth="w-110 bg-theme mt-0 mb-0 me-5"
          clicked="Cancel"
          name="Cancel"
        />
        <ButtonBox
          boxwidth="w-110  mt-0 mb-0"
          clicked="Saved"
          name="Save"
        />
      </div>
    </div>
  )
}
