import React, { useState } from "react";
import img1 from '../../../../../../images/StepZero/img37.png';
import img2s from '../../../../../../images/StepZero/img38.png';
import img3s from '../../../../../../images/StepZero/img39.png';
import img4s from '../../../../../../images/StepZero/img40.png';
import img5s from '../../../../../../images/StepZero/img41.png';
import img1s from '../../../../../../images/triangle1.png';
import img2 from '../../../../../../images/StepZero/img14.png';
import img3 from '../../../../../../images/StepZero/img15.png';
import img4 from '../../../../../../images/StepZero/img16.png';
import img5 from '../../../../../../images/close1.png';
import DropdownBoxFashion from "../../../../../global/DropdownBoxFashion";
import ButtonBox from "../../../../../global/ButtonBox";
import NicheFourSlider from "../NicheFourSlider";
import Video from '../../../../../../images/iconVid.png';
import Video1 from '../../../../../../images/iconVid1.png';
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../../../redux/actions/videoAction';
export default function NicheFour() {

  let { isVideoVisible } = useSelector(state => state.videoReducer);
  const dispatch = useDispatch();
  const handleClick6 = () => {
    dispatch(toggleVideo());
  };

  const handleClick1 = () => {
    setToggle1(!toggle1);
  };
  const [toggle1, setToggle1] = useState("true");
  const [businessName, setBusinessName] = useState("dyna");
  const [nicheAssociation, setNicheAssociation] = useState("Fashion");
  const handleNicheAssociation = (newNicheAssociation) => {
    setNicheAssociation(newNicheAssociation);
  };
  const [selectDomain, setSelectDomain] = useState(".9to5escapee");
  const handleSelectDomain = (newSelectDomain) => {
    setSelectDomain(newSelectDomain);
  };
  const associationOptions = [
    {
      "name": "FASHION",
    },
    {
      "name": "BEAUTY",
    },
    {
      "name": "NUTRITION",
    },
    {
      "name": "FITNESS",
    },
    {
      "name": "TRAVEL",
    },
    {
      "name": "DECOR",
    },
    {
      "name": "COACHING",
    },
    {
      "name": "SPIRITUALITY",
    },
    {
      "name": "ENTERTAINMENT",
    },
    {
      "name": "FINANCE",
    },
    {
      "name": "E-LEARNING",
    },
    {
      "name": "ANIMAL",
    },
    {
      "name": "OTHER",
    },
  ]
  const domainOptions = [
    {
      "name": ".9 to 5 escapee.com",
    },
    {
      "name": ".7figurebosses.com",
    },
    {
      "name": ".1000socialites.com",
    },
    {
      "name": "other domain",
    },
  ]
  return (
    <div className="px-3 pt-3">
      <div className="row justify-content-center mt-0 mx-0 formleft1 px-3 pt-5">
        <div className="col-lg-2 text-start p-0">
          <img src={img1} alt="" />
        </div>
        <div className="col-lg-10 pe-0 ps-4 d-flex flex-column">
          <div className="browserTb">
            <div className="browsertop d-flex justify-content-between align-items-center">
              <img src={img2s} alt="" width="80px" height="25px" />
              <div className="position-relative">
                <img src={img3s} alt="" width="600px" height="60px" />
                <h4 className="textinput position-absolute top-0 start-0 end-0 mx-auto my-0">
                  https://www.{businessName}.{nicheAssociation}{selectDomain}.com
               
                </h4>
              </div>
              <img src={img4s} alt="" width="30px" height="30px" />
              <img src={img5s} alt="" width="17px" height="38px" />
            </div>
            <div className="d-flex justify-content-between align-items-start w-100 pt-30 px-2">
              <div className="tag1 w-80 tagleft">
                <h6>Protocol</h6>
                <img src={img3} alt="" />
                <h4>https://</h4>
              </div>
              <div className="tag4 w-80 tagleft pt-60">
                <h4>www.</h4>
                <img src={img2} alt="" />
                <h6>Subdomain</h6>
              </div>
              <div className="tag3 w-90e tagleft">
                <h6>Business Name</h6>
                <img src={img3} alt="" />
                <h4>{businessName}</h4>
              </div>
              <div className="tag1 w-80 tagleft pt-60">
                <h4>{nicheAssociation}</h4>
                <img src={img4} alt="" />
                <h6>Niche Association</h6>
              </div>
              <div className="tag2 w-110 tagleft">
                <h6>Domain</h6>
                <img src={img3} alt="" />
                <h4>{selectDomain}</h4>
              </div>
              <div className="tag5 w-90e tagleft pt-60">
                <h4>.com</h4>
                <img src={img4} alt="" />
                <h6>Top Level Domain</h6>
              </div>
              <div className="tag6 w-90e tagleft">
                <h6>Sub Folder</h6>
                <img src={img3} alt="" />
                <h4>./file</h4>
              </div>
              <div className="tag7 tagleft pt-60">
                <h4>/Biz-Launch?</h4>
                <img src={img4} alt="" />
                <h6>Slug</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="cursor-pointer w-100 text-center d-block"  onClick={handleClick1} ><img src={img1s} alt="" width="40px" height="20px" /></span>
      {toggle1 ? (
          <></>
          ) : (
          <div className="w-100 d-flex flex-column-reverse">
            <div className='w-100'>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="manage7" role="tabpanel" aria-labelledby="manage7">
                  <div className="row justify-content-center pt-0 factorTab1s position-relative mt-0 mx-0 formleft1 px-3 position-relative">
                    <div className="text-end d-flex">
                      <div className="VideoIcon ms-auto" onClick={handleClick6}>
                        <img src={Video} className="lightImg" width="130px" height="80px" alt="" />
                        <img src={Video1} className="darkImg" width="130px" height="80px" alt="" />
                      </div>
                      <img src={img5} alt="" width="40px" height="52px" className="mt-87 cursor-pointer me-3 position-absolute end-0 top-0"  onClick={handleClick1}/>
                    </div>
                    {/* <img src={img5} alt="" width="70px" height="52px" className="w-70 mt-7 d-flex position-absolute end-0 top-0"  onClick={handleClick1}/> */}
                    <div className="col-lg-2 text-start p-0">
                      <img src={img1} alt="" />
                    </div>
                    <div className="col-lg-10 pe-0 ps-4 d-flex flex-column pt-100 pt-5">
                      <div className="browserTb">
                        <div className="browsertop d-flex justify-content-between align-items-center">
                          <img src={img2s} alt="" width="80px" height="25px" />
                          <div className="position-relative">
                            <img src={img3s} alt="" width="600px" height="60px" />
                            {/* <input type="text" placeholder="" className="textinput px-0 top-0 position-absolute start-0 end-0 mx-auto" /> */}
                            <h4 className="textinput position-absolute top-0 start-0 end-0 mx-auto my-0">
                              https://www.{businessName}.{nicheAssociation}{selectDomain}.com
                          
                            </h4>
                          </div>
                          <img src={img4s} alt="" width="30px" height="30px" />
                          <img src={img5s} alt="" width="17px" height="38px" />
                        </div>
                        <div className="d-flex justify-content-between align-items-start w-100 pt-30 px-2">
                          <div className="tag1 w-80 tagleft">
                            <h6>Protocol</h6>
                            <img src={img3} alt="" />
                            <h4>https://</h4>
                          </div>
                          <div className="tag4 w-80 tagleft pt-60">
                            <h4>www.</h4>
                            <img src={img2} alt="" />
                            <h6>Subdomain</h6>
                          </div>
                          <div className="tag3 w-80 mt-7 tagleft">
                            <h6 className="mb-0">Business Name</h6>
                            <img src={img3} alt="" />
                            <input type="text" placeholder="Dyna" className="newinput" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                          </div>
                          <div className="tag1 w-100s tagleft pt-60">
                            <DropdownBoxFashion name={nicheAssociation } namebutton="INDUSTRY" boxid="fashion" onChange={handleNicheAssociation} options={associationOptions} boxwidth="text-start country w-100" />
                            <img src={img4} alt="" />
                            <h6>Niche Association</h6>
                          </div>
                          <div className="tag2 w-125 tagleft">
                            <h6>Domain</h6>
                            <img src={img3} alt="" />
                            <DropdownBoxFashion name={selectDomain } namebutton="DOMAIN NAMES" boxid="to5escapee" onChange={handleSelectDomain} options={domainOptions} boxwidth="text-start country w-100" />
                          </div>
                          <div className="tag5 w-90e tagleft pt-60">
                            <h4>.com</h4>
                            <img src={img4} alt="" />
                            <h6>Top Level Domain</h6>
                          </div>
                          <div className="tag6 w-80 tagleft">
                            <h6>Sub Folder</h6>
                            <img src={img3} alt="" />
                            <h4>./file</h4>
                          </div>
                          <div className="tag7 tagleft pt-60">
                            <h4>/Biz-Launch?</h4>
                            <img src={img4} alt="" />
                            <h6>Slug</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className='btnsec d-flex w-100 mt-4 mb-2 justify-content-center'>
                        <ButtonBox
                          boxwidth="w-110 bg-theme mt-0 mb-0 me-5"
                          clicked="Cancel"
                          name="Cancel"
                        />
                        <ButtonBox
                          boxwidth="w-110  mt-0 mb-0"
                          clicked="Saved"
                          name="Save"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="favicon" role="tabpanel" aria-labelledby="favicon">

                </div>
                <div className="tab-pane text-center detailTab fade" id="custom7" role="tabpanel" aria-labelledby="custom7">

                </div>
                <div className="tab-pane text-center detailTab fade" id="buy7" role="tabpanel" aria-labelledby="buy7">

                </div>
                <div className="tab-pane text-center detailTab fade" id="setting7s" role="tabpanel" aria-labelledby="setting7s">

                </div>
                <div className="tab-pane text-center detailTab fade" id="servise7" role="tabpanel" aria-labelledby="servise7">

                </div>
                <div className="tab-pane text-center detailTab fade" id="identity7" role="tabpanel" aria-labelledby="identity7">

                </div>
                <div className="tab-pane text-center detailTab fade" id="default7" role="tabpanel" aria-labelledby="default7">

                </div>
              </div>
            </div>
            <div className='container privacyTab1 privacyTab3s mb-3 pb-3 mt-4'>
              <div id="myTab" className='slidetab pb-3' role="tablist">
                <NicheFourSlider/>
              </div>
            </div>
          </div>
        )}
    </div>
  )
}
