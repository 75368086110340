import axios from 'axios';
import { baseUrl } from '../../utils/basePath';
import { toast } from 'react-toastify';

// Subscription
export const ContactActions = (data,setName ,setEmail, setContact,setMessage) => {
  console.log("data is contact",data);
  return async dispatch => {
  try {
    await axios.post(baseUrl + '/contacts/contact', data).then(response => {
        dispatch({
          type: 'Contact_Success',
          payload: response.data,
        });
        toast(response.data.message);
        setEmail(''); setName(''); setContact(''); setMessage('');
    });
  } catch (err) {
    console.log('err', err);
    dispatch({type: 'Contact_Error', msg: 'Server Error'});
  }
}
};
