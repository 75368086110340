import React, { useState, useEffect } from 'react';

import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';
import dark_book_btn from '../../../../../images/dark-book-btn.png';
import book_btn from '../../../../../images/met-book.png';
import glass_btn from '../../../../../images/glass-btn.png';

import message from '../../../../../images/message.png';
import answer from '../../../../../images/answers.png';
import rank from '../../../../../images/rank.png';

import load from '../../../../../images/load.png';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import {  RiCloseLine } from "react-icons/ri";

import Form from 'react-bootstrap/Form';

import { useTranslation } from "react-i18next";



export default function BookMeComponent() {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  
    useEffect(() => {
      if (isOpen) {
        const timer = setTimeout(() => {
          setIsOpen(false);
        }, 5000);
  
        return () => clearTimeout(timer);
      }
    }, [isOpen]);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  const [Competency1, setCompetency1] = useState(10);

  return (
    <div>



      <div className="">
  

     
 
  
     
         

      

           

                  <div className=" meu-show" onClick={handleBookmeClick}>
                    <img src={book_btn} alt="bookbtn" ></img>

                  </div>
                  <div className="  dark-book-btn  dark-meu-show " onClick={handleBookmeClick}>
                            <img src={dark_book_btn} alt="bookbtn" ></img>

                          </div>
                  <div className=" glass-show" onClick={handleBookmeClick}>
                    <img src={glass_btn} alt="bookbtn" ></img>

                  </div>

                  <div className="bookme-oter">
                    <div className="btn-video reg-show" onClick={handleBookmeClick}>
                      {t('profile_hero_section.book_me')}
                    </div>

                    {bookme && (
                      <div className="bookme-inner">
                        <div className="hamburger-outer">
                          <div className=' mb-5 w-100'>
                            <button className="close-btn" onClick={handleBookmeClose}>
                              <RiCloseLine />
                            </button>
                            <div className="w-100 reg-timeline mt-5">
                              <ul className="timeline">
                                <li className="active">
                                  <div className="timeline-top">
                                    9
                                  </div>
                                  <div className="timeline-icon">
                                    <img className="message" src={message} alt="message" ></img>
                                    <p>
                                      {t('profile_hero_section.answers')}
                                    </p>
                                  </div>
                                </li>
                                <li> <div className="timeline-top">
                                  300/
                                  1000
                                </div>
                                  <div className="timeline-icon">
                                    <img src={load} className="load" alt="load" ></img>
                                    <p>
                                      {t('profile_hero_section.social_score')}
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-top">
                                    #1
                                  </div>
                                  <div className="timeline-icon">
                                    <img src={rank} alt="rank" ></img>
                                    <p>
                                      {t('profile_hero_section.rank')}
                                    </p>
                                  </div>
                                </li>
                                <li className="mr-0">
                                  <div className="timeline-top">
                                    5
                                  </div>
                                  <div className="timeline-icon">

                                    <img src={answer} className="answer" alt="answer" >
                                    </img>

                                    <p>

                                      {t('profile_hero_section.answers')}</p>
                                  </div>
                                </li>

                              </ul>
                              <div className=" forms-inpt">
                                <div>
                                  <Form.Group className="w-100" controlId="reg-Form.Controlname">

                                    <Form.Control type="input" placeholder="Type Your Name here" />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group className="mt-2 w-100" controlId="reg-forn.Controlemail">

                                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                                  </Form.Group>
                                </div>
                                <div className="book-me-btn mt-2" >
                                  {t('profile_hero_section.book_me_now')}
                                </div>

                              </div>
                            </div>
                            <div className="w-100 meu-timeline">
                              <div className=" forms-inpt">
                                <div>
                                  <Form.Group className="w-100" controlId="reg-Form.Controlname">

                                    <Form.Control type="input" placeholder="Type Your Name here" />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group className="mt-2 w-100" controlId="reg-forn.Controlemail">

                                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                                  </Form.Group>
                                </div>
                                <div className=" mt-2 m-auto meu-show" >
                                  <img src={book_btn} alt="bookbtn" ></img>
                             
                                </div>
                                <div className=" mt-2 m-auto  dark-book-btn  dark-meu-show " >
                            <img src={dark_book_btn} alt="bookbtn" ></img>

                          </div>
                                <div className=" glass-show"  >
                                    <img src={glass_btn} alt="bookbtn" ></img>

                                  </div>
                              </div>

                            </div>
                          </div>


                        </div>

                      </div>
                    )}

                  </div>






    




      </div>




    </div>



  );
}
