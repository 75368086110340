import AppRoute from './routes/AppRoutes';
import ButtonBox from './components/global/ButtonBox';
import Lock from './images/lockscreen.png';
import LockDark from './images/lockscreen1.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';


function App() {

const dispatch = useDispatch();
  useEffect(() => {
    const init = async () => {
let user = await localStorage.getItem('isAuth');
if(user){
  dispatch({
    type: 'Signin_Success',
    response: true,
    payload: JSON.parse(user),
  });
}
    }
    init();
  }, []); 
    
  return (
    <div>
      <AppRoute />
      <ToastContainer theme="dark" className="newclass" autoClose={5000} />
      <div className="modal" id="lockpopup" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog my-0 h-100 d-flex justify-content-center align-items-center">
              <div className="modal-content bg br-30 shadow-4 border-0">
                  <div className="modal-header border-0 px-4 pt-4 pb-2">
                      {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body border-0 pb-5">
                      <h6 className='mb-30'>Are you sure you want to log out?</h6>
                      <div className='d-flex justify-content-center'>
                      <button type="button" className="btn g-primary shadow-4 w-85 rounded-pill h-45 h7 text-uppercase fw-bold me-3" data-bs-toggle="modal" data-bs-target="#locked">Yes</button>
                      <button type="button" className="btn border-0 bg shadow-4 w-110 rounded-pill h-45 h7 text-uppercase textBody fw-bold" data-bs-dismiss="modal">Go Back</button>
                      </div>
                  </div>
                  <div className="modal-footer border-0 p-1">
                      
                  </div>
              </div>
          </div>
      </div>
      <div className="modal fade p-0" id="locked" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog locked my-0 h-100 d-flex justify-content-center align-items-center">
                <div className="modal-content w-100 h-100 rounded-0 bg shadow-4 border-0">
                    <div className="modal-header border-0 p-0">
                        
                    </div>
                    <div className="modal-body border-0 pb-5 h-100 d-flex justify-content-center align-items-center">
                        <div className='d-flex justify-content-center h-700 align-items-center position-relative'>
                            <img src={Lock} alt="" className='h-700 lightImg'/>
                            <img src={LockDark} alt="" className='h-700 darkImg'/>
                            <div className='position-absolute btn-locked text-center w-500 px-5'>
                                <ButtonBox boxwidth="w-110 mt-0 mb-50" clicked="UNLOCKED" name="UNLOCK" modalClose="modal" />
                                <div className='mt-5 px-5'>
                                  <input placeholder="Enter your password" className="shadow-5in br-10 w-100 mt-5" type="password" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-0 p-0">
                    </div>
                </div>
            </div>
      </div>
    </div>
  );
}

export default App;