import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './ServicePackagePlanThemes.scss';
import tab_icon from '../../../../images/Tab-icon.png'
import mobile_icon from '../../../../images/mobile-icon.png'
import { faDesktop, faLock } from "@fortawesome/free-solid-svg-icons";
import ButtonBox from "../../../global/ButtonBox";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RegularTheme6Desktop from './regularTheme6/regularThem6Desktop/RegularTheme6Desktop'
import RegularTheme6Tab from './regularTheme6/regularTheme6Tab/RegularTheme6Tab'

import RegularTheme6Mobile from './regularTheme6/regularTheme6Mobile/RegularTheme6Mobile'
import { useTranslation } from "react-i18next";
export default function ServicePackagePlanRegularThemeMobileView6() {

  const { t, i18n } = useTranslation();




  return (
    <div>

      <h4 className="text-center">
        {t('profile_hero_section.switch_the_style')}
      </h4>

      <Tab.Container id="" defaultActiveKey="Regular">

        <div className="d-flex flex-column-reverse">
          <Tab.Container id="left-tabs-example" defaultActiveKey="mobile">
            <Tab.Content>
              <Tab.Pane eventKey="desktop" className="bg-clr">
                <div className="design-screen-desktop-6">
                  <RegularTheme6Desktop />
                </div>
                <div className="w-100 d-flex justify-content-center align-items-center banner-btn desktop-3">
                  <ButtonBox boxwidth="w-150 mt-5 lg text-primary mb-4 btn-left" clicked="Sacing" name="Save/Next" />
                  <ButtonBox boxwidth="w-200 mt-5 lg text-primary mb-4 ml-3 " clicked="Sacing" name="Close and continue" />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="tab">
                <div className="design-screen-tab-6">
                  <RegularTheme6Tab />
                </div>
                <div className="w-100 d-flex justify-content-center align-items-center banner-btn">
                  <ButtonBox boxwidth="w-150 mt-5 lg text-primary mb-4 btn-left" clicked="Sacing" name="Save/Next" />
                  <ButtonBox boxwidth="w-200 mt-5 lg text-primary mb-4 ml-3 " clicked="Sacing" name="Close and continue" />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="mobile">
                <div className="design-screen-mobile-6">
                  <RegularTheme6Mobile />
                </div>
                <div className="w-100 d-flex justify-content-center align-items-center banner-btn">
                  <ButtonBox boxwidth="w-150 mt-5 lg text-primary mb-4 btn-left" clicked="Sacing" name="Save/Next" />
                  <ButtonBox boxwidth="w-200 mt-5 lg text-primary mb-4 ml-3 " clicked="Sacing" name="Close and continue" />
                </div>
              </Tab.Pane>
            </Tab.Content>
            <div className="d-flex align-items-center justify-center  w-100 mb-0 ">
              <Nav variant="pills" className="w-100 d-flex  align-items-center justify-content-center">
                <ul className="d-flex   my-4 pb-2 align-items-center modal-tabs justify-content-center">
                  <Nav.Item as="li" className="me-0">
                    <Nav.Link className="m-0 p-0 border-0 design-one-size" eventKey="desktop">
                      <span className="d-flex align-items-center "><FontAwesomeIcon className="icon-design" icon={faDesktop} ></FontAwesomeIcon> Desktop</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
                    <Nav.Link className="m-0 p-0 border-0 design-one-size" eventKey="tab">
                      <span className="d-flex align-items-center ">  <div className="icon-design">
                        <img src={tab_icon} alt="icon-tab" ></img>
                      </div>  {t('profile_hero_section.tab')}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="margin-left-scnd margin-left-btn">
                    <Nav.Link className="m-0 p-0 border-0 design-one-size" eventKey="mobile">
                      <span className="d-flex align-items-center ">
                        <div className="icon-design" >
                          <img src={mobile_icon} alt="icon-mobile" ></img>
                        </div>
                        {t('profile_hero_section.mobile')}</span>
                    </Nav.Link>
                  </Nav.Item>
                </ul>
              </Nav>
            </div>
          </Tab.Container>
        </div>
      </Tab.Container>
    </div >
  );
}
