import React from 'react'
import TrackingComponentSec from './TrackingComponentSec'
import IntegAddTrackFrom from '../IntegAddTrackFrom';
import iconBluew1 from "../../../../../../images/formicon/icon21.png";
import iconBluew2 from "../../../../../../images/formicon/icon22.png";
import iconBluew3 from "../../../../../../images/formicon/icon23.png";
import iconBluew4 from "../../../../../../images/formicon/icon24.png";
import iconBluew5 from "../../../../../../images/formicon/icon25.png";
import iconBluew6 from "../../../../../../images/formicon/icon26.png";
import iconBluew7 from "../../../../../../images/formicon/icon27.png";
import iconBluew8 from "../../../../../../images/formicon/icon28.png";
import iconBluew9 from "../../../../../../images/formicon/icon29.png";
import iconBluew10 from "../../../../../../images/formicon/icon30.png";
import iconBluew11 from "../../../../../../images/formicon/icon31.png";
import iconBluew12 from "../../../../../../images/formicon/icon32.png";
import iconBluew13 from "../../../../../../images/formicon/icon33.png";
import iconBluew14 from "../../../../../../images/formicon/icon34.png";
import iconBluew15 from "../../../../../../images/formicon/icon35.png";
import iconBluew16 from "../../../../../../images/formicon/icon36.png";
import iconBluew17 from "../../../../../../images/formicon/icon37.png";
import TrackingComponent from './TrackingComponent';
export default function TrackingSocial() {


  return (
    <div className='px-4'>
      <ul className="customDrop6 customDrop1s max-h-auto customDrop2s w-100 shadow-none position-relative">
            <div className="dropheight h-auto">
            <TrackingComponentSec Name="Facebook Pixel" icon={iconBluew1} Dropname="Facebook Pixel"/>
              <TrackingComponentSec Name="Facebook Group Pixel" icon={iconBluew1} Dropname="Facebook Group Pixel"/>
              <TrackingComponentSec Name="Twitter Pixel " icon={iconBluew2} Dropname="Twitter Pixel "/>
              <TrackingComponentSec Name="Instagram Pixel" icon={iconBluew3} Dropname="Instagram Pixel"/>
              <TrackingComponentSec Name="YouTube Pixel" icon={iconBluew5} Dropname="YouTube Pixel" />
              <TrackingComponentSec Name="Pinterest Pixel" icon={iconBluew4} Dropname="Pinterest Pixel"/>
              <TrackingComponentSec Name="LinkedIn Pixel" icon={iconBluew6} Dropname="LinkedIn Pixel"/>
              <TrackingComponentSec Name="LinkedIn Org Pixel" icon={iconBluew6} Dropname="LinkedIn Org Pixel"/>
              <TrackingComponentSec Name="Tik --tok Pixel" icon={iconBluew7} Dropname="Tik --tok Pixel"/>
              <TrackingComponentSec Name="Twitch Pixel" icon={iconBluew8} Dropname="Twitch Pixel"/>
              <TrackingComponentSec Name="Snapchat Pixel" icon={iconBluew9} Dropname="Snapchat Pixel"/>
              <TrackingComponentSec Name="reddit Pixel" icon={iconBluew10} Dropname="reddit Pixel" />
              <TrackingComponentSec Name="behance Pixel" icon={iconBluew11} Dropname="behance Pixel"/>
              <TrackingComponentSec Name="discord Pixel" icon={iconBluew12} Dropname="discord Pixel" />
              <TrackingComponentSec Name="telegram Pixel" icon={iconBluew13} Dropname="telegram Pixel"/>
              <TrackingComponentSec Name="Medium Pixel" icon={iconBluew14} Dropname="Medium Pixel"/>
              <TrackingComponentSec Name="Patreon Pixel" icon={iconBluew15} Dropname="Patreon Pixel" />
              <TrackingComponentSec Name="Quora Pixel" icon={iconBluew16} Dropname="Quora Pixel" />
              <TrackingComponentSec Name="Dribble Pixel" icon={iconBluew17} Dropname="Dribble Pixel" />
              <TrackingComponent />
              <IntegAddTrackFrom />
            </div>
          </ul>
    </div>
  )
}
