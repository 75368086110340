import React, { useState } from "react";
import ButtonBox from "../../../../global/ButtonBox";
// import SubHeading from '../../../../global/SubHeading';
// import clipboard from '../../../../../images/clipboard.png';
// import Select from 'react-select';
import { Link } from 'react-router-dom';
import { ReactComponent as IconBlue1 } from "../../../../../images/icons/icon1.svg";
import { ReactComponent as IconBlue2 } from "../../../../../images/icons/icon2.svg";
import { ReactComponent as IconBlue3 } from "../../../../../images/icons/icon17.svg";
import { ReactComponent as IconBlue4 } from "../../../../../images/icons/icon18.svg";
import { ReactComponent as IconBlue5 } from "../../../../../images/icons/icon19.svg";
import { ReactComponent as IconBlue6 } from "../../../../../images/icons/icon6.svg";
import { ReactComponent as IconBlue7 } from "../../../../../images/icons/icon7.svg";
import { ReactComponent as IconBlue8 } from "../../../../../images/icons/icon8.svg";
import { ReactComponent as IconBlue9 } from "../../../../../images/icons/icon21.svg";
import { ReactComponent as IconBlue10 } from "../../../../../images/icons/icon10.svg";
import { ReactComponent as IconBlue11 } from "../../../../../images/icons/icon22.svg";
import { ReactComponent as IconBlue12} from "../../../../../images/icons/icon23.svg";
import { ReactComponent as IconBlue4s } from "../../../../../images/icons/icon16.svg";
import { ReactComponent as IconBlue19 } from "../../../../../images/audio/coolicon.svg";
import ReactStars from "react-rating-stars-component";
// import { ReactComponent as IconBlue16 } from "../../../../../images/listicon/icon19.svg";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import EditorDrop from "../../../../global/EditorDrop";

import img3 from "../../../../../images/download3.png";
import Announcement from "../../../../global/Announcement";
import Announcement2 from "../../../../global/Announcement2";
import TabComponent from "../../../../global/TabComponent";
import RangeSlider from "react-bootstrap-range-slider";
export default function ProfileInfoThree() {

  const ratingChanged = (newRating) => {
  };

  const { t, i18n } = useTranslation();

  const theme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    }
  });
  const options1 = [
    { value: 'Blue', label: <span className="text-blue"><IconBlue1/></span> },
    { value: 'Black', label: <span className="text-darks"><IconBlue1/></span> },
  ];
  const options2 = [
    { value: 'Blue', label: <span className="text-blue"><IconBlue2/></span> },
    { value: 'Black', label: <span className="text-darks"><IconBlue2/></span> },
  ];
  const options3 = [
    { value: 'Blue', label: <span className="text-blue"><IconBlue3/></span> },
    { value: 'Black', label: <span className="text-darks"><IconBlue3/></span> },
  ];
  const options4 = [
    { value: 'Blue', label: <span className="text-blue"><IconBlue4/></span> },
    { value: 'Black', label: <span className="text-darks"><IconBlue4/></span> },
  ];
  const options5 = [
    { value: 'Blue', label: <span className="text-blue"><IconBlue5/></span> },
    { value: 'Black', label: <span className="text-darks"><IconBlue5/></span> },
  ];
  const options6 = [
    { value: 'Blue', label: <span className="text-blue"><IconBlue6/></span> },
    { value: 'Black', label: <span className="text-darks"><IconBlue6/></span> },
  ];
  const options7 = [
    { value: 'Blue', label: <span className="text-blue"><IconBlue7/></span> },
    { value: 'Black', label: <span className="text-darks"><IconBlue7/></span> },
  ];
  const options8 = [
    { value: 'Blue', label: <span className="text-blue"><IconBlue8/></span> },
    { value: 'Black', label: <span className="text-darks"><IconBlue8/></span> },
  ];
  const options9 = [
    { value: 'Blue', label: <span className="text-blue"><IconBlue9/></span> },
    { value: 'Black', label: <span className="text-darks"><IconBlue9/></span> },
  ];
  const options10 = [
    { value: 'Blue', label: <span className="text-blue"><IconBlue10/></span> },
    { value: 'Black', label: <span className="text-darks"><IconBlue10/></span> },
  ];
  const options11 = [
    { value: 'Blue', label: <span className="text-blue"><IconBlue11/></span> },
    { value: 'Black', label: <span className="text-darks"><IconBlue11/></span> },
  ];
  const options12 = [
    { value: 'Blue', label: <span className="text-blue"><IconBlue12/></span> },
    { value: 'Black', label: <span className="text-darks"><IconBlue12/></span> },
  ];

  const [ yearStarted, setYearStarted ] = useState(2018); 
  const [ yearEnded, setYearEnded ] = useState(1); 


  const openSelects1 = () => { inputRefs1.current.click(); }
  const inputRefs1 = React.useRef();
  const [previewImages1, setPreviewImages1] = useState('');
  const [images1, setImages1] = useState('');
  const openSelects2 = () => { inputRefs2.current.click(); }
  const inputRefs2 = React.useRef();
  const [previewImages2, setPreviewImages2] = useState('');
  const [images2, setImages2] = useState('');
  const openSelects3 = () => { inputRefs3.current.click(); }
  const inputRefs3 = React.useRef();
  const [previewImages3, setPreviewImages3] = useState('');
  const [images3, setImages3] = useState('');

  const openSelects4 = () => { inputRefs4.current.click(); }
  const inputRefs4 = React.useRef();
  const [previewImages4, setPreviewImages4] = useState('');
  const [images4, setImages4] = useState('');

  const openSelects5 = () => { inputRefs5.current.click(); }
  const inputRefs5 = React.useRef();
  const [previewImages5, setPreviewImages5] = useState('');
  const [images5, setImages5] = useState('');

  const openSelects6 = () => { inputRefs6.current.click(); }
  const inputRefs6 = React.useRef();
  const [previewImages6, setPreviewImages6] = useState('');
  const [images6, setImages6] = useState('');

  const openSelects7 = () => { inputRefs7.current.click(); }
  const inputRefs7 = React.useRef();
  const [previewImages7, setPreviewImages7] = useState('');
  const [images7, setImages7] = useState('');

  const openSelects8 = () => { inputRefs8.current.click(); }
  const inputRefs8 = React.useRef();
  const [previewImages8, setPreviewImages8] = useState('');
  const [images8, setImages8] = useState('');

  const openSelects9 = () => { inputRefs9.current.click(); }
  const inputRefs9 = React.useRef();
  const [previewImages9, setPreviewImages9] = useState('');
  const [images9, setImages9] = useState('');

  const openSelects10 = () => { inputRefs10.current.click(); }
  const inputRefs10 = React.useRef();
  const [previewImages10, setPreviewImages10] = useState('');
  const [images10, setImages10] = useState('');

  const openSelects11 = () => { inputRefs11.current.click(); }
  const inputRefs11 = React.useRef();
  const [previewImages11, setPreviewImages11] = useState('');
  const [images11, setImages11] = useState('');

  const openSelects12 = () => { inputRefs12.current.click(); }
  const inputRefs12 = React.useRef();
  const [previewImages12, setPreviewImages12] = useState('');
  const [images12, setImages12] = useState('');

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="jobdiv">
              <div className="innerdiv-job">
                <h5>You Can Finally Quit Your 9 to 5 Job!</h5>
              </div>
            </div>
          {/* <div className="slideProfile">
            <div className="subHeader m-0 p-0 pb-3">
              <div width="100%" direction="left" className="cssmarquee">
                <ul className='d-flex justify-content-center'>
                    <SubHeading text="TODAY’s AGENDA" src={clipboard} alt="" />
                    <SubHeading text="CALENDAR" src={clipboard} alt=""/>
                    <SubHeading text="REMINDERS" src={clipboard} alt=""/>
                    <SubHeading text="Email" src={clipboard} alt=""/>
                    <SubHeading text="APPOIN-TMENTS" src={clipboard} alt=""/>
                    <SubHeading text="MEETINGS" src={clipboard} alt=""/>
                    <SubHeading text="events" src={clipboard} alt=""/>
                    <SubHeading text="WEBINAR" src={clipboard} alt=""/>
                    <SubHeading text="CONTACTS | CRM" src={clipboard} alt=""/>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="w-100 px-5">
          <button className="bannerbtn border-0 br-5 mb-5"> {t('profile_section.profile_slide_show_upper_menu')} </button>
          <div className="row bannerthrd bannerthrd1s">
            <div className="col-lg-6 col-left">
              <div className="mb-3 d-flex">
                <span className="brd-bg">
                  <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                </span>
                <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select isSearchable={false} options={options1} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue1/></span>,
                    }}  
                  />
                </div>
              </div>
              <div className="uploadSo d-flex">
                <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                <div>
                  <div className="Icon-Div w-90s">
                      <div className='mx-auto mb-0 fileTab h-100'>
                        <label onClick={openSelects1} className="w-100 h-100">
                          <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                            {previewImages1 ? <img src={previewImages1} alt="Profile Picture" className="w-100 h-100" /> :
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <IconBlue16 />
                              </div>
                            }
                          </div>
                        </label>
                      </div>
                      <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0])
                        setPreviewImages1(url);
                        setImages1(e.target.files[0])
                      }} ref={inputRefs1} />
                  </div>
                  <span className="h7 text-center d-block w-100 mt-1">{t('profile_section.upload')}<br/>{t('profile_section.menu_icon')}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ps-4">
              <div className="mb-3 d-flex">
                <span className="brd-bg">
                  <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                </span>
                <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select isSearchable={false} options={options2} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue2/></span>,
                    }}  
                  />
                </div>
              </div>
              <div className="uploadSo d-flex">
                <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                <div>
                  <div className="Icon-Div w-90s">
                      <div className='mx-auto mb-0 fileTab h-100'>
                        <label onClick={openSelects2} className="w-100 h-100">
                          <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                            {previewImages2 ? <img src={previewImages2} alt="Profile Picture" className="w-100 h-100" /> :
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <IconBlue16 />
                              </div>
                            }
                          </div>
                        </label>
                      </div>
                      <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0])
                        setPreviewImages2(url);
                        setImages2(e.target.files[0])
                      }} ref={inputRefs2} />
                  </div>
                  <span className="h7 text-center d-block w-100 mt-1">{t('profile_section.upload')}<br/>{t('profile_section.menu_icon')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row bannerthrd bannerthrd1s">
            <div className="col-lg-6 col-left">
              <div className="mb-3 d-flex">
              <span className="brd-bg">
                <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                </span>
                <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select isSearchable={false} options={options3} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue3/></span>,
                    }}  
                  />
                </div>
              </div>
              <div className="uploadSo d-flex">
                <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                <div>
                  <div className="Icon-Div w-90s">
                      <div className='mx-auto mb-0 fileTab h-100'>
                        <label onClick={openSelects3} className="w-100 h-100">
                          <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                            {previewImages3 ? <img src={previewImages3} alt="Profile Picture" className="w-100 h-100" /> :
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <IconBlue16 />
                              </div>
                            }
                          </div>
                        </label>
                      </div>
                      <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0])
                        setPreviewImages3(url);
                        setImages3(e.target.files[0])
                      }} ref={inputRefs3} />
                  </div>
                  <span className="h7 text-center d-block w-100 mt-1">{t('profile_section.upload')}<br/>{t('profile_section.menu_icon')}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ps-4">
              <div className="mb-3 d-flex">
                <span className="brd-bg">
                  <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                </span>
                <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select isSearchable={false} options={options4} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue4/></span>,
                    }}  
                  />
                </div>
              </div>
              <div className="uploadSo d-flex">
                <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                <div>
                  <div className="Icon-Div w-90s">
                      <div className='mx-auto mb-0 fileTab h-100'>
                        <label onClick={openSelects4} className="w-100 h-100">
                          <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                            {previewImages4 ? <img src={previewImages4} alt="Profile Picture" className="w-100 h-100" /> :
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <IconBlue16 />
                              </div>
                            }
                          </div>
                        </label>
                      </div>
                      <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0])
                        setPreviewImages4(url);
                        setImages4(e.target.files[0])
                      }} ref={inputRefs4} />
                  </div>
                  <span className="h7 text-center d-block w-100 mt-1">{t('profile_section.upload')}<br/>{t('profile_section.menu_icon')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row bannerthrd bannerthrd1s">
            <div className="col-lg-6 col-left">
              <div className="mb-3 d-flex">
                <span className="brd-bg">
                  <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                </span>
                <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select isSearchable={false} options={options5} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue5/></span>,
                    }}  
                  />
                </div>
              </div>
              <div className="uploadSo d-flex">
                <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                <div>
                  <div className="Icon-Div w-90s">
                      <div className='mx-auto mb-0 fileTab h-100'>
                        <label onClick={openSelects5} className="w-100 h-100">
                          <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                            {previewImages5 ? <img src={previewImages5} alt="Profile Picture" className="w-100 h-100" /> :
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <IconBlue16 />
                              </div>
                            }
                          </div>
                        </label>
                      </div>
                      <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0])
                        setPreviewImages5(url);
                        setImages5(e.target.files[0])
                      }} ref={inputRefs5} />
                  </div>
                  <span className="h7 text-center d-block w-100 mt-1">{t('profile_section.upload')}<br/>{t('profile_section.menu_icon')}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ps-4">
              <div className="mb-3 d-flex">
                <span className="brd-bg">
                  <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                </span>
                <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select isSearchable={false} options={options6} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue6/></span>,
                    }}  
                  />
                </div>
              </div>
              <div className="uploadSo d-flex">
                <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                <div>
                  <div className="Icon-Div w-90s">
                      <div className='mx-auto mb-0 fileTab h-100'>
                        <label onClick={openSelects6} className="w-100 h-100">
                          <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                            {previewImages6 ? <img src={previewImages6} alt="Profile Picture" className="w-100 h-100" /> :
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <IconBlue16 />
                              </div>
                            }
                          </div>
                        </label>
                      </div>
                      <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0])
                        setPreviewImages6(url);
                        setImages6(e.target.files[0])
                      }} ref={inputRefs6} />
                  </div>
                  <span className="h7 text-center d-block w-100 mt-1">{t('profile_section.upload')}<br/>{t('profile_section.menu_icon')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row bannerthrd bannerthrd1s">
            <div className="col-lg-6 col-left">
              <div className="mb-3 d-flex">
                <span className="brd-bg">
                  <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                </span>
                <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select isSearchable={false} options={options7} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue7/></span>,
                    }}  
                  />
                </div>
              </div>
              <div className="uploadSo d-flex">
                <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                <div>
                  <div className="Icon-Div w-90s">
                      <div className='mx-auto mb-0 fileTab h-100'>
                        <label onClick={openSelects7} className="w-100 h-100">
                          <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                            {previewImages7 ? <img src={previewImages7} alt="Profile Picture" className="w-100 h-100" /> :
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <IconBlue16 />
                              </div>
                            }
                          </div>
                        </label>
                      </div>
                      <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0])
                        setPreviewImages7(url);
                        setImages7(e.target.files[0])
                      }} ref={inputRefs7} />
                  </div>
                  <span className="h7 text-center d-block w-100 mt-1">{t('profile_section.upload')}<br/> {t('profile_section.menu_icon')}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ps-4">
              <div className="mb-3 d-flex">
                <span className="brd-bg">
                <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                </span>
                <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select isSearchable={false} options={options8} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue8/></span>,
                    }}  
                  />
                </div>
              </div>
              <div className="uploadSo d-flex">
                <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                <div>
                  <div className="Icon-Div w-90s">
                      <div className='mx-auto mb-0 fileTab h-100'>
                        <label onClick={openSelects8} className="w-100 h-100">
                          <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                            {previewImages8 ? <img src={previewImages8} alt="Profile Picture" className="w-100 h-100" /> :
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <IconBlue16 />
                              </div>
                            }
                          </div>
                        </label>
                      </div>
                      <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0])
                        setPreviewImages8(url);
                        setImages8(e.target.files[0])
                      }} ref={inputRefs8} />
                  </div>
                  <span className="h7 text-center d-block w-100 mt-1">{t('profile_section.upload')}<br/>{t('profile_section.menu_icon')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row bannerthrd bannerthrd1s">
            <div className="col-lg-6 col-left">
              <div className="mb-3 d-flex">
                <span className="brd-bg">
                  <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                </span>
                <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select isSearchable={false} options={options9} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue9/></span>,
                    }}  
                  />
                </div>
              </div>
              <div className="uploadSo d-flex">
                <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                <div>
                  <div className="Icon-Div w-90s">
                      <div className='mx-auto mb-0 fileTab h-100'>
                        <label onClick={openSelects9} className="w-100 h-100">
                          <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                            {previewImages9 ? <img src={previewImages9} alt="Profile Picture" className="w-100 h-100" /> :
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <IconBlue16 />
                              </div>
                            }
                          </div>
                        </label>
                      </div>
                      <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0])
                        setPreviewImages9(url);
                        setImages9(e.target.files[0])
                      }} ref={inputRefs9} />
                  </div>
                  <span className="h7 text-center d-block w-100 mt-1">{t('profile_section.upload')}<br/>{t('profile_section.menu_icon')}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ps-4">
              <div className="mb-3 d-flex">
                <span className="brd-bg">
                  <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                </span>
                <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select isSearchable={false} options={options10} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue10/></span>,
                    }}  
                  />
                </div>
              </div>
              <div className="uploadSo d-flex">
                <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                <div>
                  <div className="Icon-Div w-90s">
                      <div className='mx-auto mb-0 fileTab h-100'>
                        <label onClick={openSelects10} className="w-100 h-100">
                          <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                            {previewImages10 ? <img src={previewImages10} alt="Profile Picture" className="w-100 h-100" /> :
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <IconBlue16 />
                              </div>
                            }
                          </div>
                        </label>
                      </div>
                      <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0])
                        setPreviewImages10(url);
                        setImages10(e.target.files[0])
                      }} ref={inputRefs10} />
                  </div>
                  <span className="h7 text-center d-block w-100 mt-1">{t('profile_section.upload')}<br/>{t('profile_section.menu_icon')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row bannerthrd">
            <div className="col-lg-6 col-left">
              <div className="mb-3 d-flex">
                <span className="brd-bg">
                  <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                </span>
                <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select isSearchable={false} options={options11} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue11/></span>,
                    }}  
                  />
                </div>
              </div>
              <div className="uploadSo d-flex">
                <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                <div>
                  <div className="Icon-Div w-90s">
                      <div className='mx-auto mb-0 fileTab h-100'>
                        <label onClick={openSelects11} className="w-100 h-100">
                          <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                            {previewImages11 ? <img src={previewImages11} alt="Profile Picture" className="w-100 h-100" /> :
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <IconBlue16 />
                              </div>
                            }
                          </div>
                        </label>
                      </div>
                      <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0])
                        setPreviewImages11(url);
                        setImages11(e.target.files[0])
                      }} ref={inputRefs11} />
                  </div>
                  <span className="h7 text-center d-block w-100 mt-1">{t('profile_section.upload')}<br/>{t('profile_section.menu_icon')}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ps-4">
              <div className="mb-3 d-flex">
                <span className="brd-bg">
                <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                </span>
                <div className="dropdown m-0 w-90s me-3 ms-2">
                  <Select isSearchable={false} options={options12} className="countryList br-10 w-90s customWidth2" placeholder="" theme={theme}
                    styles={{
                      option: (base) => ({
                        ...base,
                        borderRadius: '30px',
                        width:'80%',
                        height:'auto',
                        lineHeight: '24px',
                        margin: '10px',
                        cursor: 'pointer',
                        color:'#000000',
                        boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                      }),
                      
                    }}
                    defaultValue={{
                      value: 'blue', 
                      label: <span className="text-blue"><IconBlue12/></span>,
                    }}  
                  />
                </div>
              </div>
              <div className="uploadSo d-flex">
                <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                <div>
                  <div className="Icon-Div w-90s">
                      <div className='mx-auto mb-0 fileTab h-100'>
                        <label onClick={openSelects12} className="w-100 h-100">
                          <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center p-2 h-100">
                            {previewImages12 ? <img src={previewImages12} alt="Profile Picture" className="w-100 h-100" /> :
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <IconBlue16 />
                              </div>
                            }
                          </div>
                        </label>
                      </div>
                      <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0])
                        setPreviewImages12(url);
                        setImages12(e.target.files[0])
                      }} ref={inputRefs12} />
                  </div>
                  <span className="h7 text-center d-block w-100 mt-1">{t('profile_section.upload')}<br/>{t('profile_section.menu_icon')}</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="announcment pt-5 text-center">
            <button className="btn4 border-0 br-5 mb-5 mx-auto">CREATE AN ANNOUNCEMENT</button>
            <div className="position-relative">
              <div className="newform">
                <div className="row w-100 mb-4">
                  <div className="col-lg-6 d-flex mb-28 nav-item2">
                    <label>TITLE</label>
                    <div className="w-75 z1">
                      <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder="BREAKING NEWS" />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex mb-28 nav-item2">
                    <label>BUTTON TEXT</label>
                    <div className="w-75 z1">
                      <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder="FIND OUT HOW" />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-9 d-flex nav-item4 justify-content-start align-items-center">
                    <label>REDIRECT URL</label>
                    <div className=" w-75 z1">
                      <span className="inputspan">
                        <input className="announcment shadow-5in w-100" type="text" placeholder="FIND OUT HOW" />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="newannous d-flex justify-content-end align-itmes-center">
                      <h6 className="ml-auto">Redirect- Visitors to Your Announcement Page to Take Actions</h6>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3 nav-item3">
                    <label className="w-360">ANNOUNCEMENT  MESSAGE</label>
                    <div className="z1 w-100">
                      <span className="inputspan1 position-relative">
                        <div className="dropdown btnArrow btnArrow2 position-absolute spacedrop z-index-2">
                          <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-display="static">
                            <Link to="#" id="notab" className="d-flex justify-content-center fs-5 fw-bold ps-3 pe-5 align-items-center w-100x text-underline shadow-5in h-40s h8 br-30">
                              <u className="h5">A</u>
                            </Link>
                          </span>
                          <EditorDrop />
                        </div>
                        <textarea className="announcment shadow-5in w-100 ps-5 pt-4" placeholder="Type your Announcement here...."></textarea>
                        <span className="position-absolute top-20 start-16 ">
                          <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4s /></span>
                          <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4s /></span>
                          <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4s /></span>

                        </span>
                        <div className="dropdownload">
                          <Link to="#">
                            <img src={img3} alt="" />
                          </Link>
                          <label for="test">
                            <input type="file" id="test" />
                            <span>Choose a local File</span>
                          </label>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Announcement /> 
            </div>
          </div>
          <div className="col-lg-12 col-md-12 mt-5">
            <div className="slideProfile">
              <div className='w-100 px-3 overflow-x tabComponent1'>
                  <div className='d-none d-lg-block'>
                      <div className='scroll-bar w-100 shadow-4in br-20 mb-30'></div>
                  </div>
                  {/* <div className='login-tab profiletb'>
                    <ul className="nav" id="myTab" role="tablist">
                      <NavItem as="li" className='pe-1 w-100x'>
                        <div className='btn-layer br-30 bg'>
                          <button className="br-20 w-100 active h-90 border-0" id="tb1" data-bs-toggle="tab" data-bs-target="#tb1" type="button" role="tab" aria-controls="tb1" aria-selected="true">
                          {t('profile_section.bio')}
                          </button>
                        </div>
                      </NavItem>
                      <NavItem as="li" className='pe-1 w-100x'>
                        <div className='btn-layer br-30 bg'>
                          <button className="br-20 w-100 h-90 border-0 d-flex" id="tb2" data-bs-toggle="tab" data-bs-target="#tb2" type="button" role="tab" aria-controls="tb2" aria-selected="true">
                          {t('profile_section.press_kit')}
                          </button>
                        </div>
                      </NavItem>
                      <NavItem as="li" className='pe-1 w-100x'>
                        <div className='btn-layer br-30 bg'>
                          <button className="br-20 w-100 h-90 border-0 d-flex justify-content-center align-items-center" id="tb3" data-bs-toggle="tab" data-bs-target="#tb3" type="button" role="tab" aria-controls="tb3" aria-selected="true">
                          {t('profile_section.stats')} 
                          </button>
                        </div>
                      </NavItem>
                      <NavItem as="li" className='pe-1 w-100x'>
                        <div className='btn-layer br-30 bg'>
                          <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb4" data-bs-toggle="tab" data-bs-target="#tb4" type="button" role="tab" aria-controls="tb4" aria-selected="true">
                          {t('profile_section.reviews')}  
                          </button>
                        </div>
                      </NavItem>

                      <NavItem as="li" className='pe-1 w-100x'>
                        <div className='btn-layer br-30 bg'>
                          <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb5" data-bs-toggle="tab" data-bs-target="#tb5" type="button" role="tab" aria-controls="tb5" aria-selected="true">
                          {t('profile_section.portfolio')}  
                            
                          </button>
                        </div>
                      </NavItem>

                      <NavItem as="li" className='pe-1 w-100x'>
                        <div className='btn-layer br-30 bg'>
                          <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb6" data-bs-toggle="tab" data-bs-target="#tb6" type="button" role="tab" aria-controls="tb6" aria-selected="true">
                          {t('profile_section.blogs')}  
                            
                          </button>
                        </div>
                      </NavItem>
                      <NavItem as="li" className='pe-1 w-100x'>
                        <div className='btn-layer br-30 bg'>
                          <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb7" data-bs-toggle="tab" data-bs-target="#tb7" type="button" role="tab" aria-controls="tb7" aria-selected="true">
                          {t('profile_section.shop')}  
                            
                          </button>
                        </div>
                      </NavItem>
                      <NavItem as="li" className='pe-1 w-100x'>
                        <div className='btn-layer br-30 bg'>
                          <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb8" data-bs-toggle="tab" data-bs-target="#tb8" type="button" role="tab" aria-controls="tb8" aria-selected="true">
                          {t('profile_section.website')}  
                            
                          </button>
                        </div>
                      </NavItem>
                      <NavItem as="li" className='pe-1 w-100x'>
                        <div className='btn-layer br-30 bg'>
                          <button className="br-20 w-140 h-90 border-0 d-flex justify-content-center align-items-center" id="tb9" data-bs-toggle="tab" data-bs-target="#tb9" type="button" role="tab" aria-controls="tb9" aria-selected="true">
                          {t('profile_section.Q_A')}  
                            
                          </button>
                        </div>
                      </NavItem>
                    </ul>
                  </div> */}
                  <TabComponent />
              </div>
            </div>
          </div>
          <div className="w-100 px-5">
            <div className="mt-5 text-center">
              {/* <button className="bannerbtn sm border-0 br-5 mb-5">{t('profile_section.profile_static_lower_menu')}</button> */}
              <button className="btn4 border-0 br-5 mb-5 mx-auto">{t('profile_section.profile_static_lower_menu')}</button>
              <div className="row bannerthrd bannerthrd1s">
                <div className="col-lg-4 col-left">
                  <div className="mb-3 d-flex">
                    <span className="brd-bg">
                      <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                    </span>
                  </div>
                  <div className="uploadSo d-flex">
                    <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                  </div>
                </div>
                <div className="col-lg-4 col-left">
                  <div className="mb-3 d-flex">
                  <span className="brd-bg">
                    <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                  </span>
                  </div>
                  <div className="uploadSo d-flex">
                    <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3 d-flex">
                    <span className="brd-bg">
                    <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                    </span>
                  </div>
                  <div className="uploadSo d-flex">
                    <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                  </div>
                </div>
              </div>
              <div className="row bannerthrd bannerthrd1s">
                <div className="col-lg-4 col-left">
                  <div className="mb-3 d-flex">
                  <span className="brd-bg">
                    <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                  </span>
                  </div>
                  <div className="uploadSo d-flex">
                    <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                  </div>
                </div>
                <div className="col-lg-4 col-left">
                  <div className="mb-3 d-flex">
                  <span className="brd-bg">
                    <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                    </span>
                  </div>
                  <div className="uploadSo d-flex">
                    <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3 d-flex">
                  <span className="brd-bg">
                    <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                    </span>
                  </div>
                  <div className="uploadSo d-flex">
                    <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                  </div>
                </div>
              </div>
              <div className="row bannerthrd">
                <div className="col-lg-4 col-left">
                  <div className="mb-3 d-flex">
                  <span className="brd-bg">
                    <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                    </span>
                  </div>
                  <div className="uploadSo d-flex">
                    <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                  </div>
                </div>
                <div className="col-lg-4 col-left">
                  <div className="mb-3 d-flex">
                    <span className="brd-bg">
                    <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                    </span>
                  </div>
                  <div className="uploadSo d-flex">
                    <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3 d-flex">
                  <span className="brd-bg">
                    <input type="text" placeholder={t('profile_section.change_menu_title_here')} className="br-10 bg shadow-5in border-0" />
                    </span>
                  </div>
                  <div className="uploadSo d-flex">
                    <input type="text" placeholder={t('profile_section.paste_page_url_here')} className="br-10 bg shadow-5in" />
                  </div>
                </div>
              </div>
            </div>

            <div className="announcment pt-5 text-center">
              <div className="position-relative">
                <div className="w-100 mb-4">
                  <div className="col-lg-3 px-2 text-center mb-4 mx-auto">
                    <div className="shadow-4 mx-auto bg rounded-circle w-200 h-200 p-2 d-flex justify-content-center align-items-center flex-column">
                      <IconBlue19 />
                      <input type="text" placeholder={t('profile_section.enter_a_url_or_upload')} className="newcircle shadow-5in py-1 px-2 w-auto br-10 my-2" />
                      <div className="upload-btn-wrapper">
                        <button className="btns1 bg shadow-4 text-uppercase">{t('profile_section.upload_photos')}</button>
                        <input type="file" name="myfile" />
                      </div>
                      <p className="h7 fw-semibold mt-2">200 X 200 Pixels</p>
                    </div>
                    <input type="text" placeholder="Paste Your Photo Url Here..." className="shadow-5in inputnew3 my-4" />
                  </div>
                  <div className="position-relative">
                    <div className="newform">
                      <div className="row w-100 mb-4">
                        <div className="col-lg-6 d-flex mb-28 nav-item2">
                          <label>FULL NAME</label>
                          <div className="w-75 z1">
                            <span className="inputspan">
                              <input className="announcment shadow-5in w-100" type="text" placeholder="Maya Taylor" />
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-6 d-flex mb-28 nav-item2">
                          <label>DESIGNATION</label>
                          <div className="w-75 z1">
                            <span className="inputspan">
                              <input className="announcment shadow-5in w-100" type="text" placeholder="9 TO 5 ESCAPEE" />
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <h3>100%</h3>
                          <h4>I Would recommend to a friend</h4>
                          <div className="w-250 mx-auto pt-5">
                            <RangeSlider value={yearEnded} onChange={changeEvent => setYearEnded(changeEvent.target.value)} min={0} max={100}/> 
                          </div>
                        </div>
                        <div className="col-lg-6 starrateing">
                          <h5>Your Rating</h5>
                          <ReactStars
                            count={5.0}
                            onChange={ratingChanged}
                            size={43}
                            isHalf={true}
                            activeColor="#c49530"
                            color="#eff4f8"
                          />
                    
                          <h5>Your  Overall Experience</h5>
                        </div>
                        <div className="col-lg-12 mt-3 mb-28 nav-item3">
                          <label className="w-360">TESTIMONIAL MESSAGE</label>
                          <div className=" w-100">
                            <span className="inputspan1 position-relative">
                              <div className="dropdown btnArrow btnArrow2 position-absolute spacedrop z-index-2">
                                <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-display="static">
                                  <Link to="#" id="notab" className="d-flex justify-content-center fs-5 fw-bold ps-3 pe-5 align-items-center w-100x text-underline shadow-5in h-40s h8 br-30">
                                    <u className="h5">A</u>
                                  </Link>
                                </span>
                                <EditorDrop />
                              </div>
                              <textarea className="announcment shadow-5in w-100 ps-5 pt-4" placeholder="Type your  Testimonial  here...."></textarea>
                              <span className="position-absolute top-20 start-16 ">
                                <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4s /></span>
                                <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4s /></span>
                                <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4s /></span>

                              </span>
                              <div className="dropdownload">
                                <Link to="#">
                                  <img src={img3} alt="" />
                                </Link>
                                <label for="test">
                                  <input type="file" id="test" />
                                  <span>Choose a local File</span>
                                </label>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-9 d-flex nav-item4 justify-content-start align-items-center">
                          <label>TESTIMONIAL RECIPIENT</label>
                          <div className=" w-75 z1">
                            <span className="inputspan">
                              <input className="announcment shadow-5in w-100" type="text" placeholder="Dyna Appolon" />
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="newannous d-flex justify-content-end align-itmes-center">
                            <h6 className="ml-auto">Type or Select The Person Whom You are Writing The Testimonial for</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Announcement2 /> 
              </div>
            </div>
          </div>

      </div>

      <ul>
        <NavItem as="li" className=" text-center mt-5 justify-content-center">
          <ButtonBox
            boxwidth="w-140 mt-0 mb-50 mx-auto"
            clicked="Saved"
            name="Save/Next"
          />
        </NavItem>
      </ul>
    </div>
  );
}
