import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide0 from '../../images/slider/16.png';
import Slide1 from '../../images/slider/17.png';
import Slide2 from '../../images/slider/18.png';
import Slide3 from '../../images/slider/19.png';
import Slide4 from '../../images/slider/20.png';
import Slide5 from '../../images/slider/21.png';
import Slide6 from '../../images/slider/22.png';
import EmailDesign1 from "../pages/threeHoursBusinessLaunch/emailSignature/EmailComponent/EmailDesign1";
import EmailDesign2 from "../pages/threeHoursBusinessLaunch/emailSignature/EmailComponent/EmailDesign2";
import EmailDesign3 from "../pages/threeHoursBusinessLaunch/emailSignature/EmailComponent/EmailDesign3";
import EmailDesign4 from "../pages/threeHoursBusinessLaunch/emailSignature/EmailComponent/EmailDesign4";
import EmailDesign5 from "../pages/threeHoursBusinessLaunch/emailSignature/EmailComponent/EmailDesign5";
import EmailDesign6 from "../pages/threeHoursBusinessLaunch/emailSignature/EmailComponent/EmailDesign6";
import EmailDesign7 from "../pages/threeHoursBusinessLaunch/emailSignature/EmailComponent/EmailDesign7";


export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
      <div className="slideremail">
        <div className="px-0 mb-30 emailslider">
          <h4 className="my-30 h9">Choose your Email signature layout preference</h4>
          <div className="py-3 px-2">
            <Slider className="ProfileTrack"
              asNavFor={this.state.nav1}
              ref={slider => (this.slider2 = slider)}
              slidesToShow={3}
              swipeToSlide={true}
              focusOnSelect={true}
              arrows={false}
              // variableWidth={true}
            >
              <div>
                <div className="shadow-4 p-10 h-160 br-10 mx-1 slidimg">
                  <img src={Slide0} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-10 h-160 br-10 mx-1 slidimg">
                  <img src={Slide1} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-10 h-160 br-10 mx-1 slidimg">
                  <img src={Slide2} alt="" className="" />              
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 slidimg">
                  <img src={Slide3} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 slidimg">
                  <img src={Slide4} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                  <img src={Slide5} alt="" className="" />
                </div>
              </div>
              <div>
                <div className="shadow-4 p-3 h-160 br-20 mx-1 d-flex justify-content-center align-items-center">
                  <img src={Slide6} alt="" className="" />
                </div>
              </div>
            </Slider>
            <h5 className="w-100 text-center mt-3">More templates</h5>
          </div>
        </div>
        <Slider className=""
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
        >
          <div>
            <EmailDesign1 />
          </div>
          <div>
            <EmailDesign2 />
          </div>
          <div>
            <EmailDesign3 />
          </div>
          <div>
            <EmailDesign4 />
          </div>
          <div>
            <EmailDesign5 />
          </div>
          <div>
            <EmailDesign6 />
          </div>
          <div>
            <EmailDesign7 />
          </div>
         
        </Slider>
        
      </div>
    );
  }
}