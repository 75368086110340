import React from "react";
import ToggleButton from "../../../../../global/ToggleButton";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function ProfileGroupFive() {
  const { t, i18n } = useTranslation();

  return (
    <div className="shadow-5in w-100 p-40 br-30 mb-4">
      <h3 className="mb-5 text-uppercase">
        <button className="w-150 btn btnSm btnPrimary mt-3 mb-3">{t('profile_section.billing')}</button>
        <button className="w-160 btn btnSm btnPrimary mt-3 mb-3 mx-2">{t('profile_section.shipping')}</button>
        <button className="w-200 btn btnSm btnPrimary mt-3 mb-3">{t('profile_section.business_office')} <br /> {t('profile_section.headquater')} </button>
        <button className="w-150 btn btnSm btnPrimary mt-3 mb-3 mx-2">{t('profile_section.home')}</button>
        <span className="ms-4">{t('profile_section.address')}</span></h3>
      <ul className="row">
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="APT | SUITE | UNIT | NUMBER" id="NUMBER" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="STREET NUMBER" id="STREET" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="STREET NAME" id="STREETNAME" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="PROVINCE" id="PROVINCE" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="STATES" id="STATES" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="COUNTRY" id="COUNTRY" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="POSTAL CODE" id="POSTALCODE" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="ZIP CODE" id="ZIPCODE" check="checked" />
        </NavItem>
      </ul>
    </div>
  );
}
