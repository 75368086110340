import React from "react";
import "./HireMeNav.scss";

import tile2 from "../../../../../../../images/hire-me/desktopview/tile2.png";
import tile3 from "../../../../../../../images/hire-me/desktopview/tile3.png";
import tile4 from "../../../../../../../images/hire-me/desktopview/tile3.png";
import tile1 from "../../../../../../../images/hire-me/desktopview/tile1.png";

const HireMeNav = ({ Regular, Neumorphic }) => {
  return (
    <>
      <div
        className={`position-nav d-flex justify-content-around ${
          Regular ? "Regular-style" : Neumorphic ? "Neumorphic-style" : ""
        }`}
      >
        <div className="p-n ">
          <img src={tile1} alt="" />
        </div>
        <div className="p-n">
          <img src={tile2} alt="" />
        </div>
        <div className="p-n">
          <img src={tile3} alt="" />
        </div>
        <div className="p-n">
          <img src={tile4} alt="" />
        </div>
      </div>
    </>
  );
};

export default HireMeNav;
