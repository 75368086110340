import React, { useState } from 'react';

function TabComponentStatus() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className='login-tab1'>
      <div className="tabsul nav">
        <div
          className={`tabli ${activeTab === 0 ? 'active' : ''}`}
          onClick={() => handleTabClick(0)}
        >
          <div className='btn-layer br-30 bg'><span className='br-20 w-100 h-90 border-0'>Single</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 1 ? 'active' : ''}`}
          onClick={() => handleTabClick(1)}
        >
          <div className='btn-layer br-30 bg'><span className='br-20 w-100 h-90 border-0'>Married</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 2 ? 'active' : ''}`}
          onClick={() => handleTabClick(2)}
        >
          <div className='btn-layer br-30 bg'><span className='br-20 w-100 h-90 border-0'>Divorced</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 4 ? 'active' : ''}`}
          onClick={() => handleTabClick(4)}
        >
          <div className='btn-layer br-30 bg'><span className='br-20 w-100 h-90 border-0'>Widow</span></div>
        </div>
        <div
          className={`tabli ${activeTab === 5 ? 'active' : ''}`}
          onClick={() => handleTabClick(5)}
        >
          <div className='btn-layer br-30 bg'><span className='br-20 w-100 h-90 border-0'>Separated</span></div>
        </div>
      </div>

      {/* Content for each tab */}
      {/* <div className="tab-content">
        {activeTab === 0 && <p>Content for Tab 1</p>}
        {activeTab === 1 && <p>Content for Tab 2</p>}
        {activeTab === 2 && <p>Content for Tab 3</p>}
      </div> */}
    </div>
  );
}

export default TabComponentStatus;
