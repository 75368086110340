import React from "react";
import './EmailSignatureStep.scss';
import Contest from '../../../../images/img29sss1.png';
import Contest1 from '../../../../images/img32s.png';
import Contest3 from '../../../../images/img29sss.png';
import user from '../../../../images/img43ss.png';
import label from '../../../../images/img294s.png';
import label1 from '../../../../images/img31s.png';
import user4 from '../../../../images/img43s.png';
import { Link } from 'react-router-dom';
import ButtonBox from "../../../global/ButtonBox";
// import Select from 'react-select';

import { ReactComponent as IconBlue14 } from "../../../../images/icons/icon41.svg";
import { ReactComponent as IconBlue15 } from "../../../../images/icons/icon42.svg";
import { ReactComponent as IconBlue16 } from "../../../../images/icons/icon43.svg";
import { ReactComponent as IconBlue17 } from "../../../../images/icons/icon44.svg";
import { ReactComponent as IconBlue1 } from "../../../../images/icons/icon46.svg";
import { ReactComponent as IconBlue2 } from "../../../../images/icons/icon54.svg";
import { ReactComponent as IconBlue3 } from "../../../../images/icons/icon53.svg";
import { ReactComponent as IconBlue4 } from "../../../../images/icons/icon50.svg";
import { ReactComponent as IconBlue5 } from "../../../../images/icons/icon49.svg";
import { ReactComponent as IconBlue6 } from "../../../../images/icons/icon47.svg";
import { ReactComponent as IconBlue7 } from "../../../../images/icons/icon52.svg";
import { ReactComponent as IconBlue8 } from "../../../../images/icons/icon51.svg";
import { ReactComponent as IconBlue9 } from "../../../../images/icons/icon48.svg";
import EmailTemp from "../../../global/EmailTemp";
import EmailTempBm from "../../../global/EmailTempBm";
import EmailDesign5 from "./EmailComponent/EmailDesign5";
import EmailDesign7 from "./EmailComponent/EmailDesign7";
import EmailDesign6 from "./EmailComponent/EmailDesign6";

export default function EmailSignatureForm4() {
  return (
    <div>
      {/* 1st section */}
      <EmailDesign5 />
      {/* 2nd section */}
      <EmailDesign6 />
      {/* 3rd section */}
      <EmailDesign7 />
      <div className="d-flex justify-content-center align-items-center mt-5">
          <ButtonBox
            boxwidth="w-125 mt-0 mb-50 me-5 border-0 shadow-none"
            clicked="PREVIOUS"
            name="PREVIOUS"
          />
          <ButtonBox
            boxwidth="w-300 mt-0 mb-50 largetp"
            clicked="SELECTED YOUR FAVORITE STYLE"
            name="NEXT TO SELECT YOUR FAVORITE STYLE"
          />
      </div>
    </div>
  );
} 
