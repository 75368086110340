import * as React from 'react';
import User from '../../images/img1.png';
import '../login/login.scss';
import Email from './verification/EmailVerify';
import SMS from './verification/SmsVerify';
import FingerPrint from './verification/FingerpPrintVerify';
import FaceID from './verification/FaceIDVerify';
import { useTranslation } from "react-i18next";
import { NavItem } from 'react-bootstrap';

export default function Reset() {
    const { t, i18n } = useTranslation();
    return (
        <div className='text-center'>
            <div className='uploadImage mx-auto mb-4'>
                <label htmlFor="profile_pic" className='p-0'>
                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center" >
                        <img src={User} alt="" className='ImageCover' />
                    </div>
                </label>
                <input className="hidden" type="file" name="" id="profile_pic" accept="image/*" />
            </div>
            <h3 className='mb-4 pb-3'>{t('reset_component.reset_password')}</h3>
            <h4>{t('reset_component.verification_via')}</h4>
            <div className='d-flex flex-column-reverse'>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="email" role="tabpanel" aria-labelledby="email">
                        <Email />
                    </div>
                    <div className="tab-pane fade" id="sms" role="tabpanel" aria-labelledby="sms">
                        <SMS />
                    </div>
                    <div className="tab-pane fade" id="fingerprint" role="tabpanel" aria-labelledby="fingerprint">
                        <FingerPrint />
                    </div>
                    <div className="tab-pane fade" id="faceid" role="tabpanel" aria-labelledby="faceid">
                        <FaceID />
                    </div>
                </div>
                <div className='w-540 mx-auto'>
                    <div className='d-none d-lg-block'>
                        <div className='scroll-bar w-100 shadow-4in br-20 my-30'></div>
                    </div>
                    <div className='login-tab  mb-4 pb-3 mt-5 mt-lg-0'>
                        <ul className="nav" id="myTab" role="tablist">
                            <NavItem as="li" className='pe-3'>
                                <div className='btn-layer br-30 bg'>
                                    <button className="br-20 w-140 active h-90 border-0 d-flex justify-content-center align-items-center" id="email" data-bs-toggle="tab" data-bs-target="#email" type="button" role="tab" aria-controls="email" aria-selected="true">
                                    {t('reset_component.email')}
                                    </button>
                                </div>
                            </NavItem>
                            <NavItem as="li" className="pe-3">
                                <div className='btn-layer br-30 bg'>
                                    <button className="br-20 w-140 h-90 border-0" id="sms" data-bs-toggle="tab" data-bs-target="#sms" type="button" role="tab" aria-controls="sms" aria-selected="false">
                                    {t('reset_component.sms')}
                                    </button>
                                </div>
                            </NavItem>
                            <NavItem as="li" className="pe-3">
                                <div className='btn-layer br-30 bg'>
                                    <button className="br-20 w-140 h-90 border-0" id="fingerprint" data-bs-toggle="tab" data-bs-target="#fingerprint" type="button" role="tab" aria-controls="fingerprint" aria-selected="false">
                                    {t('reset_component.fingerprint')}
                                    </button>
                                </div>
                            </NavItem>
                            <NavItem as="li" className="pe-0">
                                <div className='btn-layer br-30 bg'>
                                    <button className="br-20 w-140 h-90 border-0" id="faceid" data-bs-toggle="tab" data-bs-target="#faceid" type="button" role="tab" aria-controls="faceid" aria-selected="false">
                                    {t('reset_component.face_id')}
                                    </button>
                                </div>
                            </NavItem>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

