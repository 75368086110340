import React from "react";
import ButtonBox from "../../../../global/ButtonBox";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import faCaretDown from '../../../../../images/icons/faCaretDown.svg';
import { NavItem } from 'react-bootstrap';
import ButtonBoxNew from "../../../../global/ButtonBoxNew";

export default function PrivacyOne() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <ul>
      <NavItem as="li" className="tabBdr">
        <div className="listText">
          <h5> {t('privacy_component.privacy_tab_component.who_can_see_my_profile')} </h5>
        </div>
        <div className="d-flex">
        <div className="dropdown">
            <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
                {t('choose_your_audience')}
                {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
                <img src={faCaretDown} alt="" />
            </Link>
            </span>
            <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
              <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
            </ul> 
          </div>
        </div>
      </NavItem>
      <NavItem as="li" className="tabBdr">
        <div className="listText">
          <h5>  {t('privacy_component.privacy_tab_component.who_can_send_me_a_friend_request')}</h5>
        </div>
        <div className="d-flex">
        <div className="dropdown">
            <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
            {t('everyone_Public')} 
            {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
            <img src={faCaretDown} alt="" />
            </Link>
            </span>
            <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                            <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
              <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
            </ul> 
          </div>
        </div>
      </NavItem>
      <NavItem as="li" className="tabBdr">
        <div className="listText">
          <h5> {t('privacy_component.privacy_tab_component.who_can_see_my_chat_activity')}</h5>
        </div>
        <div className="d-flex">
        <div className="dropdown">
            <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
                Only me
                {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
                <img src={faCaretDown} alt="" />
            </Link>
            </span>
            <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                            <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
              <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
            </ul> 
          </div>
        </div>
      </NavItem>
      <NavItem as="li" className="tabBdr">
        <div className="listText">
          <h5> {t('privacy_component.privacy_tab_component.who_can_see_my_friends_list')}</h5>
        </div>
        <div className="d-flex">
        <div className="dropdown">
            <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
                {t('choose_your_audience')}
                {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
                <img src={faCaretDown} alt="" />
            </Link>
            </span>
            <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                            <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
              <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
            </ul> 
          </div>
        </div>
      </NavItem>
      <NavItem as="li" className="tabBdr">
        <div className="listText">
          <h5>{t('privacy_component.privacy_tab_component.who_can_see_my_photos')}</h5>
        </div>
        <div className="d-flex">
        <div className="dropdown">
            <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
                {t('choose_your_audience')}
                {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
                <img src={faCaretDown} alt="" />
            </Link>
            </span>
            <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                            <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
              <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
            </ul> 
          </div>
        </div>
      </NavItem>
      <NavItem as="li" className="tabBdr">
        <div className="listText">
          <h5> {t('privacy_component.privacy_tab_component.who_can_see_my_videos')}</h5>
        </div>
        <div className="d-flex">
        <div className="dropdown">
            <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
                {t('choose_your_audience')}
                {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
                <img src={faCaretDown} alt="" />
            </Link>
            </span>
            <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                            <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
              <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
            </ul> 
          </div>
        </div>
      </NavItem>
      <NavItem as="li" className="tabBdr">
        <div className="listText">
          <h5> {t('privacy_component.privacy_tab_component.who_can_see_my_future_posts')}</h5>
        </div>
        <div className="d-flex">
        <div className="dropdown">
            <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
                {t('choose_your_audience')}
                {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
                <img src={faCaretDown} alt="" />
            </Link>
            </span>
            <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                            <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
              <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
            </ul> 
          </div>
        </div>
      </NavItem>
      <NavItem as="li" className="tabBdr">
        <div className="listText">
          <h5>{t('privacy_component.privacy_tab_component.who_can_look_me_up_using_my_email')}</h5>
        </div>
        <div className="d-flex">
        <div className="dropdown">
            <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
                {t('choose_your_audience')}
                {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
                <img src={faCaretDown} alt="" />
            </Link>
            </span>
            <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                            <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
              <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
            </ul> 
          </div>
        </div>
      </NavItem>
      <NavItem as="li" className="tabBdr">
        <div className="listText">
          <h5> {t('privacy_component.privacy_tab_component.who_can_look_me_up_using_my_phone_number')}</h5>
        </div>
        <div className="d-flex">
        <div className="dropdown">
            <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
                {t('choose_your_audience')}
                {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
                <img src={faCaretDown} alt="" />
            </Link>
            </span>
            <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                            <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
              <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
            </ul> 
          </div>
        </div>
      </NavItem>
      <NavItem as="li">
        <h3 className="my-3">{t('privacy_component.privacy_tab_component.suggest_more_privacy_options_to_us')}</h3>
      </NavItem>
      <NavItem as="li" className="d-flex justify-content-between py-2">
        <div className="listText d-flex w-100 justify-content-start align-items-center">
          <h5>{t('privacy_component.privacy_tab_component.who_can_see_my')}</h5>
          <input placeholder={t('privacy_component.privacy_tab_component.type_here')}  className="shadow-5in br-10 w-240 mx-3" type="text"/> ?
        </div>
        <div className="d-flex">
          <div className="dropdown">
            <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <Link to="#" className="d-flex justify-content-between px-4 align-items-center w-240 shadow-4 h-50s h8 br-30">
                {t('choose_your_audience')}
                {/* <FontAwesomeIcon icon={faCaretDown} className="h4 m-0 ms-3" /> */}
                <img src={faCaretDown} alt="" />
            </Link>
            </span>
            <ul className="dropdown-menu dropdown-menu-end dropLink bg shadow-4 border-0 br-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
                            <NavItem as="li" className=''><Link className="h8" to="#">{t('family_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('member_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('everyone_public')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_only')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_of_friends')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('only_me')}</Link></NavItem>
                <NavItem as="li" className=''><Link className="h8" to="#">{t('friends_except_this_user')}</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-3 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className="mb-3 px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
              <NavItem as="li" className=''><Link className="h8" to="#">{t('only_this_person')}:</Link></NavItem>
              <NavItem as="li" className='px-2'>
                <input className="shadow-5in br-10 mb-4 me-2 w-300" type="text" placeholder={t('privacy_component.privacy_tab_component.placeholder_email')} />
              </NavItem>
              <NavItem as="li" className=" px-4 d-flex align-items-center justify-content-center">
                {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
              </NavItem>
            </ul> 
          </div>
        </div>
      </NavItem>
      <NavItem as="li" className="mb-3 mt-3 d-flex align-items-center justify-content-start">
        {/* <button className="h-50 bs-success br-30 w-85 h8 text-center border-0 shadow-4 me-2">
                  {t('done')}
                </button> */}
                <ButtonBoxNew name="Done" boxwidth="w-110 me-3" />
                {/* <button className="h-50 bg br-30 w-180 h8 text-center border-0 shadow-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="h5 m-0 me-1" />
                  {t('add_more_emails')}
                </button> */}
                <ButtonBoxNew name="Add More Emails" iconbox="d-block" boxwidth="w-240" />
      </NavItem>
      <NavItem as="li" className=" text-center mt-5 justify-content-center">
        <ButtonBox
          boxwidth="w-140 mt-0 mb-50 mx-auto"
          clicked="Saved"
          name="Save/Next"
        />
      </NavItem>
      </ul>
    </div>
  );
}
