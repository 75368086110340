import axios from 'axios';
import { baseUrl } from '../../utils/basePath';
import { headers } from '../header';
import { toast } from 'react-toastify';
// signup
export const SignupAction = (data, setImage,setPreviewImage,setFirstName,setLastName,setEmail,setUserName,setPassword,setCountry,setGender,setTermCondition,setRememberMe) => {
  return async dispatch => {
  try {
    await axios.post(baseUrl + '/user/signup', data , {headers}).then(response => {
        dispatch({
          type: 'Signup_Success',
          payload: response.data,
        });
        toast(response.data.message);
        setImage('');
        setPreviewImage('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setUserName('');
        setPassword('');
        setCountry('');
        setGender('');
        setTermCondition('');
        setRememberMe('');
     
    });
  } catch (err) {
  
    console.log('err', err);
    dispatch({type: 'Signup_Error', msg: 'Server Error'});
  }
}
};

// signin
export const SigninAction = (data, setEmail,setPassword,setTermCondition,setRememberMe) => {
  return async dispatch => {
  try {
    await axios.post(baseUrl + '/user/login', data ).then(response => {
        dispatch({
          type: 'Signin_Success',
          response: true,
          payload: response.data.user,
        });
        localStorage.setItem('isAuth', JSON.stringify(response.data.user));
        toast(response.data.message);
        setEmail('');
        setPassword('');
        setTermCondition(false);
        setRememberMe(false);
     
    });
  } catch (err) {
  
    console.log('err', err);
    dispatch({type: 'Signin_Error',response: false, msg: 'Server Error'});
  }
}
};

// logout

export const LogoutAction = () => {
  return async dispatch => {
    
  try {
    localStorage.removeItem('isAuth');
    dispatch({
      type: 'Clear_Reducer',
    });
    toast('Logout Successfully!');

  } catch (err) {
  
  }
}
};

// reset password for sending a email 
export const ResetAction = (data,setEmail) => {
  return async dispatch => {
  try {
    await axios.post(baseUrl + '/user/reset', data ).then(response => {
      if(response.data.success){
        dispatch({
          type: 'Reset_Success',
          response: response.data.success,
          payload: response.data.user,
        });
        toast(response.data.message);
        setEmail('');
      }else{
        toast(response.data.message);
        dispatch({type: 'Reset_Error',response: response.data.success});
      }
   
  });

  } catch (err) {
    toast(err.response.data.message);
  }
}
};

// reset password for checking a otp 
export const ResetOTPAction = (data) => {
  return async dispatch => {
  try {
    await axios.post(baseUrl + '/user/resetOTP', data ).then(response => {
      if(response.data.success){
        dispatch({
          type: 'ResetOTP_Success',
          response: response.data.success,
          payload: response.data.user,
        });
        toast(response.data.message);
      }else{
        toast(response.data.message);
        dispatch({type: 'ResetOTP_Error',response: response.data.success});
      }
   
  });

  } catch (err) {
    toast(err.response.data.message);
  }
}
};

// update a password
export const ResetPasswordAction = (data) => {
  return async dispatch => {
  try {
    await axios.post(baseUrl + '/user/resetPassword', data ).then(response => {
      if(response.data.success){
        dispatch({
          type: 'ResetPassword_Success',
          response: response.data.success,
          payload: response.data.user,
        });
        toast(response.data.message);
      }else{
        toast(response.data.message);
        dispatch({type: 'ResetPassword_Error',response: response.data.success});
      }
   
  });

  } catch (err) {
    toast(err.response.data.message);
  }
}
};