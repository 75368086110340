import React from "react";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';


import FormatIcon from "../global/FormatIcon";
import Icon1 from "../../images/formaticon/01.png";
import Icon2 from "../../images/formaticon/02.png";
import Icon3 from "../../images/formaticon/03.png";
import Icon4 from "../../images/formaticon/04.png";
import Icon5 from "../../images/formaticon/05.png";
import Icon6 from "../../images/formaticon/06.png";
import Icon7 from "../../images/formaticon/07.png";
import Icon8 from "../../images/formaticon/08.png";
import Icon9 from "../../images/formaticon/09.png";
import Icon10 from "../../images/formaticon/10.png";
import Icon11 from "../../images/formaticon/11.png";
import Icon12 from "../../images/formaticon/12.png";
import Icon13 from "../../images/formaticon/13.png";
import Icon14 from "../../images/formaticon/14.png";
import Icon15 from "../../images/formaticon/15.png";
import Icon16 from "../../images/formaticon/16.png";
import Icon17 from "../../images/formaticon/17.png";
import Icon18 from "../../images/formaticon/18.png";
import Icon19 from "../../images/formaticon/19.png";
import Icon20 from "../../images/formaticon/20.png";
import Icon21 from "../../images/formaticon/21.png";
import Icon22 from "../../images/formaticon/22.png";
import Icon23 from "../../images/formaticon/23.png";
import Icon24 from "../../images/formaticon/24.png";
import Icon25 from "../../images/formaticon/25.png";
import Icon26 from "../../images/formaticon/26.png";
import Icon27 from "../../images/formaticon/27.png";
import Icon28 from "../../images/formaticon/28.png";
import Icon29 from "../../images/formaticon/29.png";
import Icon30 from "../../images/formaticon/30.png";
import Icon31 from "../../images/formaticon/31.png";
import Icon32 from "../../images/formaticon/32.png";
import Icon33 from "../../images/formaticon/33.png";
import Icon34 from "../../images/formaticon/34.png";
import Icon35 from "../../images/formaticon/35.png";
import Icon36 from "../../images/formaticon/36.png";
import Icon37 from "../../images/formaticon/37.png";
import Icon38 from "../../images/formaticon/38.png";
import Icon39 from "../../images/formaticon/39.png";
import Icon40 from "../../images/formaticon/40.png";

import { NavItem } from 'react-bootstrap';
import DropdownBox from "./DropdownBox";

export default function EditorDrop() {


  
  const theme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    }
    
  });
  const options = [
        { value: 'USD', label: 'USD' },
        { value: 'CAD', label: 'CAD' },
        { value: 'EURO', label: 'EURO' },
        { value: 'POUNDS', label: 'POUNDS' },
        { value: 'OTHER', label: 'OTHER' },
        
     ]

  const stylesOptions =
  [{
      "name": "USD",      
    }, {
      "name": "CAD",
    }, {
      "name": "EURO",
    }, {
      "name": "POUNDS",
    }, {
      "name": "OTHER",
    }
  ] 

  return (
    <div className="dropdown-menu z-index w-690 dropdown-menu-lg-end bg shadow-4 border-0 br-20 p-20" onClick="event.stopPropagation()" aria-labelledby="dropdownMenuLink">
      <ul className="d-flex align-itmes-center justify-content-center mb-20 flex-wrap">
        <NavItem as="li" className="me-3">
          <Link to="#" className="large">
            <span className="pe-2">Source</span>
            <img src={Icon1} alt="" width="16" height="20" />
          </Link>
        </NavItem>
        <FormatIcon src={Icon2} url="#" />
        <FormatIcon src={Icon3} url="#" />
        <FormatIcon src={Icon4} url="#" />
        <FormatIcon src={Icon5} url="#" />
        <FormatIcon src={Icon6} url="#" />
        <FormatIcon src={Icon7} url="#" />
        <FormatIcon src={Icon8} url="#" />
        <FormatIcon src={Icon9} url="#" />
        <FormatIcon src={Icon10} url="#" />
        <FormatIcon src={Icon11} url="#" />
        <FormatIcon src={Icon12} url="#" />
        <FormatIcon src={Icon13} url="#" />
        <FormatIcon src={Icon14} url="#" />
        <FormatIcon src={Icon15} url="#" />
        <FormatIcon src={Icon16} url="#" />
        <FormatIcon src={Icon17} url="#" />
        <FormatIcon src={Icon18} url="#" />
        <FormatIcon src={Icon19} url="#" />
        <FormatIcon src={Icon20} url="#" />
        <FormatIcon src={Icon21} url="#" />
        <FormatIcon src={Icon22} url="#" />
        <FormatIcon src={Icon23} url="#" />
        <FormatIcon src={Icon24} url="#" />
        <FormatIcon src={Icon25} url="#" />
        <FormatIcon src={Icon26} url="#" />
        <FormatIcon src={Icon27} url="#" />
        <FormatIcon src={Icon28} url="#" />
        <FormatIcon src={Icon29} url="#" />
        <FormatIcon src={Icon30} url="#" />
        <FormatIcon src={Icon31} url="#" />
        <FormatIcon src={Icon32} url="#" />
        <FormatIcon src={Icon33} url="#" />
        <FormatIcon src={Icon34} url="#" />
        <FormatIcon src={Icon35} url="#" />
        <FormatIcon src={Icon36} url="#" />
        <FormatIcon src={Icon37} url="#" />
        <FormatIcon src={Icon38} url="#" />
        <FormatIcon src={Icon39} url="#" />
        <FormatIcon src={Icon40} url="#" />
      </ul>
      <div className="row justify-content-between">
        <div className="w-160 mb-4">
          {/* <Select isSearchable={false} options={options} className="countryList br-30 w-100 customWidth mb-4 css-b62m3t-container" placeholder="Styles" theme={theme}
            styles={{
              option: (base) => ({
                ...base,
                borderRadius: "30px",
                width: "90%",
                Height: "auto",
                lineHeight: "32px",
                fontSize: "14px",
                margin: "10px",
                cursor: 'pointer',
                color: '#000000',
                boxShadow:
                  "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
              }),
            }}
          /> */}
          <DropdownBox name="Styles" boxid="styles" options={stylesOptions} boxwidth="w-100"/>
        </div>
        <div className="w-160 mb-4">
          {/* <Select isSearchable={false} options={options} className="countryList br-30 w-100 customWidth mb-4 css-b62m3t-container" placeholder="Format" theme={theme}
            styles={{
              option: (base) => ({
                ...base,
                borderRadius: "30px",
                width: "90%",
                height: "auto",
                lineHeight: "32px",
                fontSize: "14px",
                margin: "10px",
                cursor: 'pointer',
                color: '#000000',
                boxShadow:
                  "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
              }),
            }}
          /> */}
          <DropdownBox name="Format" boxid="format" options={stylesOptions} boxwidth="w-100"/>
        </div>
        <div className="w-160 mb-4">
          {/* <Select isSearchable={false} options={options} className="countryList br-30 w-100 customWidth mb-4 css-b62m3t-container" placeholder="Font" theme={theme}
            styles={{
              option: (base) => ({
                ...base,
                borderRadius: "30px",
                width: "90%",
                height: "auto",
                lineHeight: "32px",
                fontSize: "14px",
                margin: "10px",
                cursor: 'pointer',
                color: '#000000',
                boxShadow:
                  "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
              }),
            }}
          /> */}
          <DropdownBox name="Font" boxid="font" options={stylesOptions} boxwidth="w-100"/>
        </div>
        <div className="w-160 mb-4">
          {/* <Select isSearchable={false} options={options} className="countryList br-30 w-100 customWidth mb-4 css-b62m3t-container" placeholder="Size" theme={theme}
            styles={{
              option: (base) => ({
                ...base,
                borderRadius: "30px",
                width: "90%",
                height: "auto",
                lineHeight: "32px",
                fontSize: "14px",
                margin: "10px",
                cursor: 'pointer',
                color: '#000000',
                boxShadow:
                  "2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);",
              }),
            }}
          /> */}
          <DropdownBox name="Size" boxid="size" options={stylesOptions} boxwidth="w-100"/>
        </div>
      </div>
      <div className="d-flex justify-content-between px-3 brakectSec">
        <div>...</div>
        <div>Customer Name</div>
        <div>Account Number</div>
        <div>Product Name</div>
        <div>Pin Code</div>
      </div>
    </div>
  );
}
