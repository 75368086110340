import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import circle_img from "../../../../../../images/circle-shape.png";
import "./RegularTheme1Tab.scss";

import testimonial_glass from "../../../../../../images/testimonial-glass.png";
import Vector_shape from "../../../../../../images/Vector-shape.png";

import mue_shape from "../../../../../../images/mue-shape.png";
import meo_shape_right from "../../../../../../images/meo-shape-right.png";
import { faInfo, faStar } from "@fortawesome/free-solid-svg-icons";

import tab_m from "../../../../../../images/tab-m.png";

import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

import { RiCloseLine } from "react-icons/ri";

import down_icon from "../../../../../../images/down-icon.png";
import down_gold from "../../../../../../images/down-gold.png";

import { FaStar } from "react-icons/fa";

import testimonial from "../../../../../../images/testimonial.png";
import timeline_gold from "../../../../../../images/timeline-gold.png";
import dots_tab from "../../../../../../images/Dots-tab.png";
import tab_hero_img from "../../../../../../images/tab-hero-img.png";

import { useTranslation } from "react-i18next";
import BookMeComponent from "../../profileHeroSectionComponents/BookMeComponent";
import LogoImageComponent from "../../profileHeroSectionComponents/LogoImageComponent";
import VideoCardComponent from "../../profileHeroSectionComponents/VideoCardComponent";

import TabsComponent from "../../profileHeroSectionComponents/TabsComponent";

import SearchbarComponent from "../../profileHeroSectionComponents/SearchbarComponent";
import TimelineComponent from "../../profileHeroSectionComponents/TimelineComponent";

import HeroSectionTextComponent from "../../profileHeroSectionComponents/HeroSectionTextComponent";

import HeroBottomTabsComponent from "../../profileHeroSectionComponents/HeroBottomTabsComponent";

import AboutMeTittleComponent from "../../profileHeroSectionComponents/AboutMeTittleComponent";
import PlaylistComponent from "../../profileHeroSectionComponents/PlaylistComponent";
import TabMenuComponent from "../../profileHeroSectionComponents/TabMenuComponent";
import  '../../profileHeroSectionComponents/profileComponent/ProfileComponent.scss';
import ProfileComponent from "../../profileHeroSectionComponents/profileComponent/ProfileComponent";
import  SocialMediaIconsComponent from "../../profileHeroSectionComponents/SocialMediaIconsComponent";
import  ToggleComponent from "../../profileHeroSectionComponents/ToggleComponent";
// import RegularTheme1Desktop from './regularTheme1\RegularTheme1Desktop/regularTheme1Desktop'

export default function RegularTheme1Tab() {
  const { t, i18n } = useTranslation();

  const [seeall, setSeeall] = useState(false);

  const handleSeeallClick = () => {
    setSeeall(!seeall);
  };

  const handleSeeallClose = () => {
    setSeeall(false);
  };

  return (
    <div>
      <div className="">
        <div>
          <div className="d-flex w-100 navbr-icon">
            <div className="navbar-top p-2 w-100 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center w-50">
          <div className="theme-one-tab">

              <ProfileComponent />
              </div>
                <div>
                  <LogoImageComponent />
                </div>
              </div>
              <div className="d-flex align-items-center w-50">
                <div className="testimonial">
                  <div className="timeline-top d-flex">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        className="icon-green"
                        icon={faStar}
                      ></FontAwesomeIcon>
                      <FontAwesomeIcon
                        className="icon-green"
                        icon={faStar}
                      ></FontAwesomeIcon>
                      <FontAwesomeIcon
                        className="icon-green"
                        icon={faStar}
                      ></FontAwesomeIcon>
                      <FontAwesomeIcon
                        className="icon-green"
                        icon={faStar}
                      ></FontAwesomeIcon>
                      <FontAwesomeIcon
                        className="icon-green"
                        icon={faStar}
                      ></FontAwesomeIcon>
                    </div>
                    <div>
                      <p>
                        dummy text of the printing printing
                        <p>
                          <b>Client Name Here</b>
                          Designation Here
                        </p>
                      </p>
                    </div>
                  </div>
                  <img
                    src={testimonial}
                    className="test-reg"
                    alt="testimonial"
                  ></img>
                  <img
                    src={timeline_gold}
                    className="timeline-gold"
                    alt="testimonial-gold"
                  ></img>
                  <img
                    src={testimonial_glass}
                    className="timeline-glass"
                    alt="testimonial"
                  ></img>
                </div>

                <div className="bars-seeall">
                  <div
                    className="testimonial-seeall"
                    onClick={handleSeeallClick}
                  >
                    <h5>See all </h5>{" "}
                    <img
                      src={down_icon}
                      className="down-icon"
                      alt="down_icon"
                    ></img>
                    <img
                      src={down_gold}
                      alt="down_gold"
                      className="down-gold"
                    ></img>
                  </div>

                  {seeall && (
                    <div className="hamburger-outer">
                      <div className=" mb-5 w-100">
                        <button
                          className="close-btn"
                          onClick={handleSeeallClose}
                        >
                          <RiCloseLine />
                        </button>
                      </div>

                      <div>
                        <p className="testimonial-p m-auto">Testimonials:</p>
                        <div>
                          <div className=" geo-left m-atuo w-100">
                            <div className="testimonial">
                              <div className="timeline-top d-flex">
                                <div className="d-flex">
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                </div>
                                <div>
                                  <p>
                                    {t(
                                      "profile_hero_section.dummy_text_of_the_printing_printing"
                                    )}
                                    <p>
                                      <b>
                                        {" "}
                                        {t(
                                          "profile_hero_section.client_name_here"
                                        )}
                                      </b>
                                      {t(
                                        "profile_hero_section.designation_here"
                                      )}
                                    </p>
                                  </p>
                                </div>
                              </div>
                              <img
                                src={testimonial}
                                className="test-reg"
                                alt="testimonial"
                              ></img>
                              <img
                                src={timeline_gold}
                                className="timeline-gold"
                                alt="testimonial-gold"
                              ></img>
                              <img
                                src={testimonial_glass}
                                className="timeline-glass"
                                alt="testimonial"
                              ></img>
                            </div>
                            <div className="testimonial">
                              <div className="timeline-top d-flex">
                                <div className="d-flex">
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                </div>
                                <div>
                                  <p>
                                    {t(
                                      "profile_hero_section.dummy_text_of_the_printing_printing"
                                    )}
                                    <p>
                                      <b>
                                        {" "}
                                        {t(
                                          "profile_hero_section.client_name_here"
                                        )}
                                      </b>
                                      {t(
                                        "profile_hero_section.designation_here"
                                      )}
                                    </p>
                                  </p>
                                </div>
                              </div>
                              <img
                                src={testimonial}
                                className="test-reg"
                                alt="testimonial"
                              ></img>
                              <img
                                src={timeline_gold}
                                className="timeline-gold"
                                alt="testimonial-gold"
                              ></img>
                              <img
                                src={testimonial_glass}
                                className="timeline-glass"
                                alt="testimonial"
                              ></img>
                            </div>
                            <div className="testimonial">
                              <div className="timeline-top d-flex">
                                <div className="d-flex">
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                  <FaStar className="icon-green" />
                                </div>
                                <div>
                                  <p>
                                    {t(
                                      "profile_hero_section.dummy_text_of_the_printing_printing"
                                    )}
                                    <p>
                                      <b>
                                        {" "}
                                        {t(
                                          "profile_hero_section.client_name_here"
                                        )}
                                      </b>
                                      {t(
                                        "profile_hero_section.designation_here"
                                      )}
                                    </p>
                                  </p>
                                </div>
                              </div>
                              <img
                                src={testimonial}
                                className="test-reg"
                                alt="testimonial"
                              ></img>
                              <img
                                src={timeline_gold}
                                className="timeline-gold"
                                alt="testimonial-gold"
                              ></img>
                              <img
                                src={testimonial_glass}
                                className="timeline-glass"
                                alt="testimonial"
                              ></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <TimelineComponent />
            </div>
            <img
              src={meo_shape_right}
              className="mue-shape-right"
              alt="mue-shape"
            ></img>
            <TabMenuComponent />
          </div>
          <div className="main-content d-flex">
            <div className="hero-sidebar text-center">
              <div className="name-btn">DYNA APPOLON</div>
              <p>CEO | 9 to 5 Escapee</p>
              <div>
                <ul className="pt-5 pb-5">
                  <SearchbarComponent />
                  <li>
                    <div className="info-oter">
                      <FontAwesomeIcon
                        className="info-icon"
                        icon={faInfo}
                      ></FontAwesomeIcon>
                    </div>
                  </li>
                </ul>
                <ToggleComponent />
                <div className="text-hide">dfdf</div>
                <div className="text-hide">dfgv</div>
                <div className="text-hide">dfger</div>
                <div className="text-hide">dfdf</div>
                <div className="text-hide">dfgv</div>
                <div className="text-hide">dfger</div>

                <SocialMediaIconsComponent />
                <HeroBottomTabsComponent />
              </div>
            </div>
            <div className="content-hero m-section">
              <img src={circle_img} alt="  " className="circle-shape"></img>

              <div className="hero-conent">
                <img src={Vector_shape} alt="  " className="vector-shape"></img>
                <div className="hero-section">
                  <img
                    src={tab_hero_img}
                    alt="desktop"
                    className="content-img hero-section-reg"
                  ></img>
                  <div className="hero-section hero-section-mue">
                    <img
                      src={tab_m}
                      alt="backgrnd-2"
                      className="content-img"
                    ></img>
                  </div>
                  <HeroSectionTextComponent />
                </div>

                <div className="content-text d-flex">
                  <div className="img-section">
                    <img src={dots_tab} className="dots" alt="dots"></img>
                    <img
                      src={mue_shape}
                      className="mue-shape"
                      alt="mue-shape"
                    ></img>
                  </div>

                  <div className="playlist-section">
                    <AboutMeTittleComponent />
                    <PlaylistComponent />
                  </div>

                  <TabsComponent />

                  <div className="tab-right-section"></div>
                  <BookMeComponent />
                  <VideoCardComponent />
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
