import * as React from "react";
import ButtonBox from "../../global/ButtonBox";
import Email from "../../../images/emailicon.png";
import "../../login/login.scss";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResetAction, ResetOTPAction , ResetPasswordAction } from "../../../redux/actions/authActions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { NavItem } from 'react-bootstrap';

import { ReactComponent as IconBlue1 } from "../../../images/svgIcon/icon1.svg";
import { ReactComponent as IconBlue2 } from "../../../images/svgIcon/icon2.svg";

export default function EmailVerify() {
  const { t, i18n } = useTranslation();
  const { response, user } = useSelector((state) => state.authReducer);
  useEffect(() => {
    if (response) {
      setClose(true);
    }
  }, [response]);
  const [close, setClose] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  let {
    email_reset,
    otp_reset,
    otp_reset_success,
    email_success,
    otp_reset_fail,
    update_password,
  } = useSelector((state) => state.authReducer);
  const validateEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const handleSubmitEmail = (e) => {
    e.preventDefault();
    if (!email) {
      toast("Email is required");
    } else if (!validateEmail(email)) {
      toast("Please Enter a Valid Email Address");
    } else {
      try {
        let data = {
          email: email,
        };
        dispatch(ResetAction(data, setEmail));
      } catch (error) {
        console.log("err", error);
      }
    }
  };

  const handleSubmitOTP = (e) => {
    e.preventDefault();
    if (!otp) {
      toast("OTP is required");
    } else {
      try {
        let data = {
          user_id: user._id,
          otp: otp,
        };
        dispatch(ResetOTPAction(data));
      } catch (error) {
        console.log("err", error);
      }
    }
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
    if (!password) {
      toast("Passowrd is required");
    } else {
      try {
        let data = {
          user_id: user._id,
          password: password,
        };
        dispatch(ResetPasswordAction(data));
      } catch (error) {
        console.log("err", error);
      }
    }
  };
  return (
    <div>
      <h6 className="w-100 text-center mb-3">
      {t('reset_component.a_code_will_be_emailed_to_you_to_reset_your_password')}
      </h6>
      <ul className="pb-5">
        {email_reset && (
          <form onSubmit={handleSubmitEmail}>
            <NavItem as="li" className="mb-4">
              <input
                placeholder={t('enter_your_email')} 
                className="shadow-5in br-10 w-500"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </NavItem>

            <NavItem as="li">
              <ButtonBox
                boxwidth="w-185 mt-0 mb-50"
                close={close}
                clicked="Requested"
                name="Request Code"
              />
            </NavItem>

            <NavItem as="li" className="mb-3">
              <div className="w-500 mx-auto d-flex justify-content-between">
                <h6 className="h7">{t('reset_component.a_code_has_been_sent_to_your_email')}</h6>
                <h6 className="h7 fw-bold text-uppercase">{t('reset_component.resend_code')}</h6>
              </div>
            </NavItem>
          </form>
        )}
        {otp_reset && (
          <NavItem as="li">
            <div className="verificationCard bg br-30 shadow-4 mx-auto my-4">    
            {otp_reset && (
              <div className="card bg shadow-4 mx-auto border-0">
                <img src={Email} alt="" />
              </div>
            )}
             {otp_reset && (
              <h6>{t('reset_component.enter_6_digit_security_code')}</h6>
             )}
              {otp_reset && (
              <form onSubmit={handleSubmitOTP}>
                <input
                  placeholder={t('pass_code')} 
                  classbox="w-100 text-center"
                  type="text"
                  value={otp}
                  onChange={(e) => {
                    setOTP(e.target.value);
                  }}
                />
                <ButtonBox
                  boxwidth="w-185 mt-0 mb-50"
                  clicked="Verify OTP"
                  name="Verify"
                />
              </form>
              )}
              {email_success && (
                <div className="success-section">
                  <div className="verifyIcon rounded-circle icon-success d-flex justify-content-center align-items-center">
                    <IconBlue2 />
                  </div>
                  <h6>{t('reset_component.email_verified')}</h6>
                </div>
              )}
              <div className="success-section d-flex flex-column justify-content-center align-items-center">
                {otp_reset_success && (
                  <div className="verifyIcon rounded-circle bg-danger d-flex justify-content-center align-items-center">
                    <IconBlue1 />
                  </div>
                )}
                {otp_reset_fail && (
                  <div>
                    <h6 className="w-100 mb-3">{t('reset_component.invalid_code')}</h6>
                    <button
                      type="button"
                      className="btn border-0 bg shadow-4 w-110 rounded-pill h7 text-uppercase textBody fw-bold"
                    >
                      {t('reset_component.try_again')}
                    </button>
                    <h6 className="w-100 my-3">{t('or')}</h6>
                    <button
                      type="button"
                      className="btn border-0 bg shadow-4 w-125 rounded-pill h7 text-uppercase textBody fw-bold"
                    >
                      {t('reset_component.resend_code')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </NavItem>
        )}
        {update_password && (
          <form onSubmit={handleSubmitPassword}>
          <NavItem as="li" className="mb-4">
            <input
              placeholder={t('enter_new_password')} 
              className="shadow-5in br-10 w-500"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </NavItem>
          <NavItem as="li">
            <ButtonBox
              boxwidth="w-185 mt-0 mb-50"
              clicked="Password changed"
              name="Change Password"
            />
          </NavItem>
          </form>
        )}
      </ul>
    </div>
  );
}
