import React from "react";
// import { Slider, Sketch, Material, Colorful, Compact, Circle, Wheel, Block, Github, Chrome } from '@uiw/react-color';
// import { Alpha, Hue, ShadeSlider, Saturation, Interactive, hsvaToHslaString } from '@uiw/react-color';
import { Alpha, Sketch, Circle, Wheel} from '@uiw/react-color';
// import { EditableInput, EditableInputRGBA, EditableInputHSLA } from '@uiw/react-color';
import { useState } from "react";
import { NavItem } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import ButtonBox from "../global/ButtonBox";
import ButtonBoxNew from "./ButtonBoxNew";

export default function ColorPick() {
  const [hex, setHex] = useState("#FACB30");
  const [hsva, setHsva] = useState({ h: 46, s: 80, v: 98, a: 1 });
  
  return (
    <div className="w-100 shadow-4 br-10 p-cutom">
      <div className='clock-timer clock-timer1 mb-4'>
              <div id="watch" className="watch1">
                <ul className='minute-marks minute-marks1'>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                </ul>
                <div className='seconds-hand1'></div>
                <div className='frame-face'>
                </div>
                <div className='digital-wrap'>
                <div className="wheel-gear">
                  <Wheel style={{ marginLeft: 20 }}
                  color={hex}
                  onChange={(color) => {
                    setHex(color.hex);
                  }} />
                </div>
                </div>
              </div>
            </div>
      <Alpha
        hsva={hsva}
        onChange={(newAlpha) => {
          setHsva({ ...hsva, ...newAlpha });
        }}
      />
      <div className="row justify-content-between mt-3 mx-0">
          <div className="select-box p-0">
            <div className="select-box__current" tabIndex="1">
              <div className="select-box__value">
                <input className="select-box__input" type="radio" id="0" value="0" name="Ben" checked="checked" />
                <p className="select-box__input-text">RGB:</p>
              </div>
              <div className="select-box__value">
                <input className="select-box__input" type="radio" id="1" value="1" name="Ben" checked="checked"  />
                <p className="select-box__input-text">Hex:</p>
              </div>
              <img className="select-box__icon" src="http://cdn.onlinewebfonts.com/svg/img_295694.svg" alt="Arrow Icon"
                aria-hidden="true" />
            </div>
            <ul className="select-box__list bg w-150 shadow-4 border-0 px-2 py-0 br-10 ">
              <NavItem as="li">
                <label className="select-box__option shadow-4 br-10 boxHexBtn w-100" htmlFor="0" aria-hidden="aria-hidden">Hex</label>
              </NavItem>
              <NavItem as="li">
                <label className="select-box__option shadow-4 br-10 boxClrBtn w-100" htmlFor="1" aria-hidden="aria-hidden">RGB</label>
              </NavItem>
            </ul>
          </div>
        <div className="hexBox boxHex br-5 h7 p-0 d-flex justify-content-center align-items-center">
          {hex}
        </div>
        <div className="boxClrCircle w-auto p-0  d-none">
          <Sketch
            style={{ width: "auto", background: 'none', boxShadow:'0' }}
            color={hex}
            onChange={(color) => {
              setHex(color.hex);
            }}
          />
        </div>
        <div className="hexBox br-5 h6 p-0 d-flex justify-content-center align-items-center">
          <span>{hsva.a}</span>
        </div>
      </div>
    <div className="d-flex justify-content-between align-items-center pt-2 custom-clr">
      <Circle
        colors={[ hex ]}
      />
      {/* <ButtonBox
        boxwidth="w-145 me-2 h-50s"
        clicked="color Added"
        name="Add color"
      /> */}
      <ButtonBoxNew name="Add color" boxwidth="w-145 me-2" />
    </div> 
    </div>
  );
}