import React from "react";
import '../EmailSignatureStep.scss';
import Contest1 from '../../../../../images/img29s.png';
import brcode from '../../../../../images/img30.png';
import label1 from '../../../../../images/img31s.png';
import user2 from '../../../../../images/img43.png';

// import Select from 'react-select';
import { ReactComponent as IconBlue14 } from "../../../../../images/icons/icon41.svg";
import { ReactComponent as IconBlue15 } from "../../../../../images/icons/icon42.svg";
import { ReactComponent as IconBlue16 } from "../../../../../images/icons/icon43.svg";
import { ReactComponent as IconBlue17 } from "../../../../../images/icons/icon44.svg";
import { ReactComponent as IconBlue18 } from "../../../../../images/icons/icon54.svg";
import { ReactComponent as IconBlue19 } from "../../../../../images/icons/icon46.svg";
import { ReactComponent as IconBlue20 } from "../../../../../images/icons/icon47.svg";
import { ReactComponent as IconBlue21 } from "../../../../../images/icons/icon48.svg";
import { ReactComponent as IconBlue22 } from "../../../../../images/icons/icon49.svg";
import { ReactComponent as IconBlue23 } from "../../../../../images/icons/icon50.svg";
import { ReactComponent as IconBlue24 } from "../../../../../images/icons/icon51.svg";
import { ReactComponent as IconBlue25 } from "../../../../../images/icons/icon52.svg";
import { ReactComponent as IconBlue26 } from "../../../../../images/icons/icon53.svg";
import EmailTemp from "../../../../global/EmailTemp";
import EmailTempBm from "../../../../global/EmailTempBm";

export default function EmailDesign2() {

  return (
    <div className="w-98 pt-2">
      {/* 2nd section */}
      <div className="w-100 shadow-4 d-flex flex-wrap mb-4 px-3 py-2">
        <div className="ms-auto bg br-5 shadow-4 p-1">
          <img src={label1} alt="" />
        </div>
        <div className="shadow-4 br-10 bg w-100 p-4 pb-30 mt-2 d-flex align-items-center">
          <div className="col-lg-4 h-100">
            <div class="socialText text-start w-100 mt-0 mb-0 h-100 d-flex align-items-center justify-content-center flex-column">
              <h4 class="py-2 d-block m-0">The Best Alternative Lifestyle to the 9 to 5 Grind.</h4>
              <div class="linesap bg w-100"></div>
              <h4 class="py-2 d-block m-0 w-100"><b className="d-block fw-bold">Industry:</b>Social Network</h4>
              <div class="linesap bg w-100"></div>
              <h4 class="py-2 d-block m-0 w-100"><b className="d-block fw-bold">Department:</b>Startup</h4>
            </div>
          </div>
          <div className="col-lg-6 px-3 text-center">
            <img src={user2} alt="" width="220" height="220" className="mt-87" />
            <div className="linesep8 text-center">
              <h3 className="h-45 br-30 w-200 shadow-4 bg d-flex justify-content-center align-items-center">Dyna Appolon</h3>
              <button className="border-0 mx-auto br-20">CEO | Social Network Strategist</button>
            </div>
          </div>
          <div className="col-lg-2">
            <img src={brcode} alt="" className="br-10 shadow-4" width="110px" height="110px" />
          </div>
        </div>
        <div className="row w-100">
          <div className="col-lg-5 py-3">
            <div className="px-3 pt-3 text-start">
              <h5 className="py-1 h7 d-flex align-items-center m-0 mb-2"><span className="w-28 br-50 shadow-4 me-2 d-flex justify-content-center align-items-center"><IconBlue15 /></span>1-800-000-00-00</h5>
              <h5 className="py-1 h7 d-flex align-items-center m-0 mb-2"><span className="w-28 br-50 shadow-4 me-2 d-flex justify-content-center align-items-center"><IconBlue17 /></span>dyna@7figurebosses.com</h5>
              <h5 className="py-1 h7 d-flex align-items-center m-0 mb-2"><span className="w-28 br-50 shadow-4 me-2 d-flex justify-content-center align-items-center"><IconBlue16 /></span>9to5escapee.com </h5>
              <h5 className="py-1 h7 d-flex align-items-center m-0"><span className="w-28 me-2 br-50 shadow-4 d-flex justify-content-center align-items-center"><IconBlue14 /></span><span className="w-75">123 Success Rd, Take Action City, Freedom Country M6Y 10T </span></h5>
            </div>
          </div>
          <div className="col-lg-7 d-flex flex-column align-items-center">
            <div className="socialblue mb-3">
              <a href="" className="w-24 br-50 d-flex justify-content-center align-items-center"><IconBlue19 /></a>
              <a href="" className="w-24 br-50 d-flex justify-content-center align-items-center"><IconBlue18 /></a>
              <a href="" className="w-24 br-50 d-flex justify-content-center align-items-center"><IconBlue26 /></a>
              <a href="" className="w-24 br-50 d-flex justify-content-center align-items-center"><IconBlue23 /></a>
              <a href="" className="w-24 br-50 d-flex justify-content-center align-items-center"><IconBlue22 /></a>
              <a href="" className="w-24 br-50 d-flex justify-content-center align-items-center"><IconBlue20 /></a>
              <a href="" className="w-24 br-50 d-flex justify-content-center align-items-center"><IconBlue25 /></a>
              <a href="" className="w-24 br-50 d-flex justify-content-center align-items-center"><IconBlue24 /></a>
              <a href="" className="w-24 br-50 d-flex justify-content-center align-items-center"><IconBlue21 /></a>
            </div>
            <div className="border-7">
              <h6 className="shadow-4 py-2 px-4 h15">Mission Statement: I help people quit their 9 to 5 jobs and launch, manage, and scale up their online business.</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-centent-end align-items-center bg shadow-5in p-4">
          <div className="br-5 p-4 w-100 position-relative bg shadow-4">
            <img src={Contest1} alt="" className="w-96 position-absolute top-15 start-15" />
            <p className="h9 text-center lh-85 mb-0">“I never dreamed about success. I worked for it.” <b>Estee Lauder</b></p>
          </div>
      </div>
      <div className="shadow-4 mb-4 silverClr">
        <EmailTemp />
      </div>
      <div className="shadow-4 mb-4 silverClr">
        <EmailTempBm />
      </div>
    </div>
  );
} 
