import React, { useState, useEffect } from 'react';

import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';

import message from '../../../../../images/message.png';
import answer from '../../../../../images/answers.png';
import rank from '../../../../../images/rank.png';
import gold_star from '../../../../../images/gold-star.png';
import load from '../../../../../images/load.png';


import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import message_gold from '../../../../../images/msg-gold.png'
import rank_gold from '../../../../../images/rank-gold.png'

import cap_gold from '../../../../../images/cap-gold.png'

import { useTranslation } from "react-i18next";



export default function TimelineComponent() {
  const { t, i18n } = useTranslation();


  return (
    <div  className='w-100'>




 

      

              <div className="w-100 reg-timeline">
                <ul className="timeline">
                  <div className="active-oter">
                    <li className="active">
                      <div className="timeline-top">
                        9
                      </div>
                      <div className="timeline-icon">
                        <img className="message" src={message} alt="message" ></img>
                        <p>
                          {t('profile_hero_section.answers')}
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li> <div className="timeline-top">
                      300/
                      1000
                    </div>
                      <div className="timeline-icon">
                        <img src={load} className="load" alt="load" ></img>
                        <p>
                          {t('profile_hero_section.social_score')}
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li>
                      <div className="timeline-top">
                        #1
                      </div>
                      <div className="timeline-icon">
                        <img src={rank} alt="rank" ></img>
                        <p>
                          {t('profile_hero_section.rank')}
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li>
                      <div className="timeline-top">
                        5
                      </div>
                      <div className="timeline-icon">

                        <img src={answer} className="answer" alt="answer" >
                        </img>

                        <p>
                          {t('profile_hero_section.best_answers')}</p>
                      </div>
                    </li>
                  </div>


                </ul>

              </div>
              <div className="w-100 meu-timeline">
                <ul className="timeline">
                  <div className="active-oter">
                    <li className="active">
                      <div className="timeline-top">
                        9
                      </div>
                      <div className="timeline-icon">
                        <img className="message" src={message_gold} alt="message" ></img>
                        <p>
                          {t('profile_hero_section.answers')}
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li> <div className="timeline-top">
                      300/
                      1000
                    </div>
                      <div className="timeline-icon">
                        <img src={rank_gold} className="load" alt="load" ></img>
                        <p>
                          {t('profile_hero_section.social_score')}
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li>
                      <div className="timeline-top">
                        #1
                      </div>
                      <div className="timeline-icon">
                        <img src={gold_star} alt="rank" ></img>
                        <p>
                          {t('profile_hero_section.rank')}
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li>
                      <div className="timeline-top">
                        5
                      </div>
                      <div className="timeline-icon">

                        <img src={cap_gold} className="answer" alt="answer" >
                        </img>

                        <p>
                          {t('profile_hero_section.best_answers')}</p>
                      </div>
                    </li>
                  </div>


                </ul>

              </div>
         

         

    




    </div>



  );
}
