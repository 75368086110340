import React, { useState } from 'react';
import './CounterTimer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faPause, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import { library } from "@fortawesome/fontawesome-svg-core";
import IconBlue1 from "../../../images/clock.png";
import { NavItem } from 'react-bootstrap';
import { Link } from "react-router-dom";

library.add(faBell, faPause, faRotateLeft);

export default function CounterTimer() {
  const { t, i18n } = useTranslation();

  const handleClick = () => {
    setToggle(!toggle);
  };
  const [toggle, setToggle] = useState(false);

  return (
    <div className='btnTop'>
      <button
        onClick={handleClick}
        className="togglebtn p-0 border-0 bg left-side newbarTab arrow5">
        {/* <img src={IconBlue1} alt="" width="150" /> */}
        <div className='clock-timer clock-timer4'>
          <div id="watch">

            <ul className='minute-marks'>
              <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
              <NavItem as="li"></NavItem><NavItem as="li"></NavItem>
            </ul>
            <div className='seconds-hand'></div>
            <div className='frame-face'>

            </div>

            <div className='digital-wrap'>

              <ul className='digit-minutes'>
                <NavItem as="li">10</NavItem><NavItem as="li">11</NavItem>
                <NavItem as="li">12</NavItem><NavItem as="li">13</NavItem><NavItem as="li">14</NavItem><NavItem as="li">15</NavItem><NavItem as="li">16</NavItem><NavItem as="li">17</NavItem>
                <NavItem as="li">18</NavItem><NavItem as="li">19</NavItem><NavItem as="li">20</NavItem><NavItem as="li">21</NavItem><NavItem as="li">22</NavItem><NavItem as="li">23</NavItem>
                <NavItem as="li">24</NavItem><NavItem as="li">25</NavItem><NavItem as="li">26</NavItem><NavItem as="li">27</NavItem><NavItem as="li">28</NavItem><NavItem as="li">29</NavItem>
                <NavItem as="li">30</NavItem><NavItem as="li">31</NavItem><NavItem as="li">32</NavItem><NavItem as="li">33</NavItem><NavItem as="li">34</NavItem><NavItem as="li">35</NavItem>
                <NavItem as="li">36</NavItem><NavItem as="li">37</NavItem><NavItem as="li">38</NavItem><NavItem as="li">39</NavItem><NavItem as="li">40</NavItem><NavItem as="li">41</NavItem>
                <NavItem as="li">42</NavItem><NavItem as="li">43</NavItem><NavItem as="li">44</NavItem><NavItem as="li">45</NavItem><NavItem as="li">46</NavItem><NavItem as="li">47</NavItem>
                <NavItem as="li">48</NavItem><NavItem as="li">49</NavItem><NavItem as="li">50</NavItem><NavItem as="li">51</NavItem><NavItem as="li">52</NavItem><NavItem as="li">53</NavItem>
                <NavItem as="li">54</NavItem><NavItem as="li">55</NavItem><NavItem as="li">56</NavItem><NavItem as="li">57</NavItem><NavItem as="li">58</NavItem><NavItem as="li">59</NavItem>
                <NavItem as="li">00</NavItem><NavItem as="li">01</NavItem><NavItem as="li">02</NavItem><NavItem as="li">03</NavItem><NavItem as="li">04</NavItem><NavItem as="li">05</NavItem>
                <NavItem as="li">06</NavItem><NavItem as="li">07</NavItem><NavItem as="li">08</NavItem><NavItem as="li">09</NavItem>
              </ul>
              <div> : </div>
              <ul className='digit-seconds'>
                <NavItem as="li">20</NavItem><NavItem as="li">21</NavItem><NavItem as="li">22</NavItem><NavItem as="li">23</NavItem>
                <NavItem as="li">24</NavItem><NavItem as="li">25</NavItem><NavItem as="li">26</NavItem><NavItem as="li">27</NavItem><NavItem as="li">28</NavItem><NavItem as="li">29</NavItem>
                <NavItem as="li">30</NavItem><NavItem as="li">31</NavItem><NavItem as="li">32</NavItem><NavItem as="li">33</NavItem><NavItem as="li">34</NavItem><NavItem as="li">35</NavItem>
                <NavItem as="li">36</NavItem><NavItem as="li">37</NavItem><NavItem as="li">38</NavItem><NavItem as="li">39</NavItem><NavItem as="li">40</NavItem><NavItem as="li">41</NavItem>
                <NavItem as="li">42</NavItem><NavItem as="li">43</NavItem><NavItem as="li">44</NavItem><NavItem as="li">45</NavItem><NavItem as="li">46</NavItem><NavItem as="li">47</NavItem>
                <NavItem as="li">48</NavItem><NavItem as="li">49</NavItem><NavItem as="li">50</NavItem><NavItem as="li">51</NavItem><NavItem as="li">52</NavItem><NavItem as="li">53</NavItem>
                <NavItem as="li">54</NavItem><NavItem as="li">55</NavItem><NavItem as="li">56</NavItem><NavItem as="li">57</NavItem><NavItem as="li">58</NavItem><NavItem as="li">59</NavItem>
                <NavItem as="li">00</NavItem><NavItem as="li">01</NavItem><NavItem as="li">02</NavItem><NavItem as="li">03</NavItem><NavItem as="li">04</NavItem><NavItem as="li">05</NavItem>
                <NavItem as="li">06</NavItem><NavItem as="li">07</NavItem><NavItem as="li">08</NavItem><NavItem as="li">09</NavItem><NavItem as="li">10</NavItem><NavItem as="li">11</NavItem>
                <NavItem as="li">12</NavItem><NavItem as="li">13</NavItem><NavItem as="li">14</NavItem><NavItem as="li">15</NavItem><NavItem as="li">16</NavItem><NavItem as="li">17</NavItem>
                <NavItem as="li">18</NavItem><NavItem as="li">19</NavItem>
              </ul>
            </div>


          </div>
        </div>
        <Link to="/profile" className='w-100 pt-4'>
          <div className='card-tab active-tab'>
              <div className='flex justifY-space'>
                  <div className='card-text'>
                      {/* <FontAwesomeIcon icon={faCaretRight} className=" card-icon" /> */}
                      <h5>
                        Complete Your “Profile”
                      </h5>
                  </div>
                  <div className='card-progress'>
                      <div className='progress-line'></div>
                      <div className='circles-small'>
                          <div className='circle-small'>
                              <div className='text'>
                                <span className='d-block'>100%</span>
                              </div>
                              <svg><circle className="bg" cx="36" cy="36" r="26"></circle><circle className="progress one" cx="33" cy="36" r="26"></circle></svg>
                          </div>

                      </div>
                  </div>

              </div>

          </div>
      </Link>
      </button>
      {toggle ?
        <div className='timer-card p-4 mb-5 width-cont-set'>
          <h4 className='top-text'>{t('counter_timer_component.step_1')}</h4>
          <p className='timer-p'>{t('counter_timer_component.complete_your_profile')} </p>
          <div>
            <div className='frame'>
              <div>
                <div className='circle-big'>
                  <div className='text'>
                    <span className="d-block">53</span>%
                  </div>
                  <svg><circle className="bg" cx="53" cy="53" r="43"></circle><circle className="progress" cx="51" cy="54" r="40"></circle></svg>
                </div>
                <div className='progress-text'>
                  {t('completed')}
                </div>
              </div>
            </div>
            <div className='border-shadow'></div>
            <div className='clock-timer mt-4'>
              <div id="watch">

                <ul className='minute-marks'>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                  <NavItem as="li"></NavItem><NavItem as="li"></NavItem>
                </ul>
                <div className='seconds-hand'></div>
                <div className='frame-face'>

                </div>

                <div className='digital-wrap'>

                  <ul className='digit-minutes'>
                    <NavItem as="li">10</NavItem><NavItem as="li">11</NavItem>
                    <NavItem as="li">12</NavItem><NavItem as="li">13</NavItem><NavItem as="li">14</NavItem><NavItem as="li">15</NavItem><NavItem as="li">16</NavItem><NavItem as="li">17</NavItem>
                    <NavItem as="li">18</NavItem><NavItem as="li">19</NavItem><NavItem as="li">20</NavItem><NavItem as="li">21</NavItem><NavItem as="li">22</NavItem><NavItem as="li">23</NavItem>
                    <NavItem as="li">24</NavItem><NavItem as="li">25</NavItem><NavItem as="li">26</NavItem><NavItem as="li">27</NavItem><NavItem as="li">28</NavItem><NavItem as="li">29</NavItem>
                    <NavItem as="li">30</NavItem><NavItem as="li">31</NavItem><NavItem as="li">32</NavItem><NavItem as="li">33</NavItem><NavItem as="li">34</NavItem><NavItem as="li">35</NavItem>
                    <NavItem as="li">36</NavItem><NavItem as="li">37</NavItem><NavItem as="li">38</NavItem><NavItem as="li">39</NavItem><NavItem as="li">40</NavItem><NavItem as="li">41</NavItem>
                    <NavItem as="li">42</NavItem><NavItem as="li">43</NavItem><NavItem as="li">44</NavItem><NavItem as="li">45</NavItem><NavItem as="li">46</NavItem><NavItem as="li">47</NavItem>
                    <NavItem as="li">48</NavItem><NavItem as="li">49</NavItem><NavItem as="li">50</NavItem><NavItem as="li">51</NavItem><NavItem as="li">52</NavItem><NavItem as="li">53</NavItem>
                    <NavItem as="li">54</NavItem><NavItem as="li">55</NavItem><NavItem as="li">56</NavItem><NavItem as="li">57</NavItem><NavItem as="li">58</NavItem><NavItem as="li">59</NavItem>
                    <NavItem as="li">00</NavItem><NavItem as="li">01</NavItem><NavItem as="li">02</NavItem><NavItem as="li">03</NavItem><NavItem as="li">04</NavItem><NavItem as="li">05</NavItem>
                    <NavItem as="li">06</NavItem><NavItem as="li">07</NavItem><NavItem as="li">08</NavItem><NavItem as="li">09</NavItem>
                  </ul>
                  <div> : </div>
                  <ul className='digit-seconds'>
                    <NavItem as="li">20</NavItem><NavItem as="li">21</NavItem><NavItem as="li">22</NavItem><NavItem as="li">23</NavItem>
                    <NavItem as="li">24</NavItem><NavItem as="li">25</NavItem><NavItem as="li">26</NavItem><NavItem as="li">27</NavItem><NavItem as="li">28</NavItem><NavItem as="li">29</NavItem>
                    <NavItem as="li">30</NavItem><NavItem as="li">31</NavItem><NavItem as="li">32</NavItem><NavItem as="li">33</NavItem><NavItem as="li">34</NavItem><NavItem as="li">35</NavItem>
                    <NavItem as="li">36</NavItem><NavItem as="li">37</NavItem><NavItem as="li">38</NavItem><NavItem as="li">39</NavItem><NavItem as="li">40</NavItem><NavItem as="li">41</NavItem>
                    <NavItem as="li">42</NavItem><NavItem as="li">43</NavItem><NavItem as="li">44</NavItem><NavItem as="li">45</NavItem><NavItem as="li">46</NavItem><NavItem as="li">47</NavItem>
                    <NavItem as="li">48</NavItem><NavItem as="li">49</NavItem><NavItem as="li">50</NavItem><NavItem as="li">51</NavItem><NavItem as="li">52</NavItem><NavItem as="li">53</NavItem>
                    <NavItem as="li">54</NavItem><NavItem as="li">55</NavItem><NavItem as="li">56</NavItem><NavItem as="li">57</NavItem><NavItem as="li">58</NavItem><NavItem as="li">59</NavItem>
                    <NavItem as="li">00</NavItem><NavItem as="li">01</NavItem><NavItem as="li">02</NavItem><NavItem as="li">03</NavItem><NavItem as="li">04</NavItem><NavItem as="li">05</NavItem>
                    <NavItem as="li">06</NavItem><NavItem as="li">07</NavItem><NavItem as="li">08</NavItem><NavItem as="li">09</NavItem><NavItem as="li">10</NavItem><NavItem as="li">11</NavItem>
                    <NavItem as="li">12</NavItem><NavItem as="li">13</NavItem><NavItem as="li">14</NavItem><NavItem as="li">15</NavItem><NavItem as="li">16</NavItem><NavItem as="li">17</NavItem>
                    <NavItem as="li">18</NavItem><NavItem as="li">19</NavItem>
                  </ul>
                </div>


              </div>
            </div>

            <div className='clock mt-5'>
              <div className='hours'>
                <div className='first'>
                  <div className='number'>0</div>
                </div>
                <div className='second'>
                  <div className='number'>0</div>
                </div>
              </div>
              <div className='tick'>:</div>
              <div className='hours'>
                <div className='first'>
                  <div className='number'>0</div>
                </div>
                <div className='second'>
                  <div className='number'>0</div>
                </div>
              </div>
              <div className='tick'>:</div>
              <div className='minutes'>
                <div className='first'>
                  <div className='number'>0</div>
                </div>
                <div className='second'>
                  <div className='number'>0</div>
                </div>
              </div>
              <div className='tick'>:</div>
              <div className='seconds'>
                <div className='first'>
                  <div className='number'>0</div>
                </div>
                <div className='second infinite'>
                  <div className='number'>0</div>
                </div>
              </div>

            </div>
            <div className='clock-text'>
              <div>day</div>
              <div>hour</div>
              <div>
                min
              </div>
              <div>sec</div>
            </div>
            <div className='footer-icons mt-4'>
              <div className='icon-bg'>
                <FontAwesomeIcon icon={faRotateLeft} className=" footer-icon" />
              </div>

              <div className='icon-bg'>
                <FontAwesomeIcon icon={faPause} className=" footer-icon-pink " />
              </div>
              <div className='icon-bg'>

                <FontAwesomeIcon icon={faBell} className="footer-icon " />
              </div>
            </div>
          </div>
        </div>
        :
        <></>
      }
    </div>


  )
}
