import React, { useState } from "react";

import desktop_1 from "../../../../images/profile/Desktop1.png"
import tab_1 from "../../../../images/profile/Tab1.png"
import mobile_1 from "../../../../images/profile/Mobile1.png"
import desktop_2 from "../../../../images/profile/Desktop2.png"
import tab_2 from "../../../../images/profile/Tab2.png"
import mobile_2 from "../../../../images/profile/Mobile2.png"
import desktop_3 from "../../../../images/profile/Desktop3.png"
import tab_3 from "../../../../images/profile/Tab3.png"
import mobile_3 from "../../../../images/profile/Mobile3.png"
import ProfileHeroSectionRegularThemeDesktopView1 from './ProfileHeroSectionRegularThemeDesktopView1'
import ProfileHeroSectionRegularThemeDesktopView2 from './ProfileHeroSectionRegularThemeDesktopView2'
import ProfileHeroSectionRegularThemeDesktopView3 from './ProfileHeroSectionRegularThemeDesktopView3'
import ProfileHeroSectionRegularThemeTabView3 from './ProfileHeroSectionRegularThemeTabView3'
import ProfileHeroSectionRegularThemeMobileView3 from './ProfileHeroSectionRegularThemeMobileView3'

import ProfileHeroSectionRegularThemeTabView1 from './ProfileHeroSectionRegularThemeTabView1'
import ProfileHeroSectionRegularThemeMobileView1 from './ProfileHeroSectionRegularThemeMobileView1'
import ProfileHeroSectionRegularThemeTabView2 from './ProfileHeroSectionRegularThemeTabView2'
import ProfileHeroSectionRegularThemeMobileView2 from './ProfileHeroSectionRegularThemeMobileView2'

import Modal from 'react-bootstrap/Modal';
import ButtonBoxNew from "../../../global/ButtonBoxNew"

import { useTranslation } from "react-i18next";
import './ProfileHeroSectionThemes.scss';
export default function ProfileHeroSectionRegularTheme() {
 
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [TabTwoShow, setTabTwoShow] = useState(false);
  const [MobileTwoShow, setMobileTwoShow] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskShow, setDeskShow] = useState(false);
  const [desktwoShow, setDesktwoShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);

  return (
    <div className="">

      <h4 className="text-center">
        Regular  Design Version 1
      </h4>
      <div className="d-flex card-outo justify-center">
  
        <div className="">
          <div className="screen-bg">
            <img src={desktop_1} alt="deshtop-1" width={100} >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setDeskShow(true)} />
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionRegularThemeDesktopView1 />

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_1} alt="tab-1"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name=" See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setSmShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionRegularThemeTabView1 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_1} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name=" See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionRegularThemeMobileView1 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 2
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2} alt="desktop-2" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDesktwoShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name=" See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setDesktwoShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktwoShow}
            onHide={() => setDesktwoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionRegularThemeDesktopView2 />

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2} alt="tab-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabTwoShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto  " onClick={() => setTabTwoShow(true)}/>

          <Modal
            show={TabTwoShow}
            onHide={() => setTabTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-tit"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionRegularThemeTabView2 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2} alt="mobile-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileTwoShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name=" See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileTwoShow(true)}/>

          <Modal
            show={MobileTwoShow}
            onHide={() => setMobileTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-ti"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionRegularThemeMobileView2 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 3
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3} alt="desktop-3" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskthreeShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name=" See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setDeskthreeShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskthreeShow}
            onHide={() => setDeskthreeShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionRegularThemeDesktopView3 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3} alt="tab-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabThreeShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="  See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto"  onClick={() => setTabThreeShow(true)}/>

          <Modal
            show={TabThreeShow}
            onHide={() => setTabThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionRegularThemeTabView3 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3} alt="mobile-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileThreeShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="  See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileThreeShow(true)}/>

          <Modal
            show={MobileThreeShow}
            onHide={() => setMobileThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ProfileHeroSectionRegularThemeMobileView3 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
