import React from "react";
import ButtonBox from "../../../../global/ButtonBox";
import ProfileGroupOne from "./ProfileSections/ProfileGroupOne";
import ProfileGroupTwo from "./ProfileSections/ProfileGroupTwo";
import ProfileGroupThree from "./ProfileSections/ProfileGroupThree";
import ProfileGroupFour from "./ProfileSections/ProfileGroupFour";
import ProfileGroupFive from "./ProfileSections/ProfileGroupFive";
import ProfileGroupSix from "./ProfileSections/ProfileGroupSix";
import ProfileGroupSeven from "./ProfileSections/ProfileGroupSeven";
import ProfileGroupEight from "./ProfileSections/ProfileGroupEight";
import ProfileGroupNine from "./ProfileSections/ProfileGroupNine";
import ProfileGroupTen from "./ProfileSections/ProfileGroupTen";
import ProfileGroupEleven from "./ProfileSections/ProfileGroupEleven";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function ProfileEditOne() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="ProgressBar">
        <ul>
            <NavItem as="li" className="active" id="profile"></NavItem>
            <NavItem as="li" className="active" id="chat"></NavItem>
            <NavItem as="li" className="active" id="location"></NavItem>
            <NavItem as="li" className="active" id="user"></NavItem>
            <NavItem as="li" className="active" id="briefcase"></NavItem>
            <NavItem as="li" className="active" id="graduation-cap"></NavItem>
            <NavItem as="li" className="active" id="favorite"></NavItem>
            <NavItem as="li" className="active" id="award"></NavItem>
            <NavItem as="li" className="active" id="connectivity"></NavItem>
            <NavItem as="li" className="active" id="lock"></NavItem>
            <NavItem as="li" className="active" id="eye"></NavItem>
        </ul>
      </div>
      <h4 className=" mt-2">{t('profile_section.edit_profile_intro')}</h4>
      <p className="lh-normal">{t('profile_section.here_you_have_100_Control')}<br/>{t('profile_section.profile')}</p>
      <div className="h-100 mt-5">
        <ProfileGroupOne />
        <ProfileGroupTwo />
        <ProfileGroupThree />
        <ProfileGroupFour />
        <ProfileGroupFive />
        <ProfileGroupSix />
        <ProfileGroupSeven />
        <ProfileGroupEight />
        <ProfileGroupNine />
        <ProfileGroupTen />
        <ProfileGroupEleven />
        
      </div>
      <ul>
      <NavItem as="li" className=" text-center mt-5 justify-content-center">
        <ButtonBox
          boxwidth="w-140 mt-0 mb-50 mx-auto"
          clicked="Saved"
          name="Save/Next"
        />
      </NavItem>
      </ul>
    </div>
  );
}
