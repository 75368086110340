import React, { useState } from "react";
import RangeSelect from "../../../../../global/rangeSelect/RangeSelect";
import { YearExperiance, Salaries } from "../../../../../global/rangeSelect/RangeValues";
// import RangeSlider from "react-bootstrap-range-slider";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function Experiance() {
  const { t, i18n } = useTranslation();

  const [value, setValue] = useState(2018);
  const [selectedRange, setSelectedRange] = useState(
    YearExperiance[Math.floor(YearExperiance.length / 2)] 
  );
  const [selectedRange1, setSelectedRange1] = useState(
    Salaries[Math.floor(Salaries.length / 2)] 
  );
  return (
    <div className="text-center mb-4">
      <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block headingMinus">{t('profile_section.experience')}</h4>
      <div className="shadow-4 br-20 pt-4 pb-5 px-4 d-flex justify-content-center align-items-center flex-column">
        <h4 className="h8">{t('profile_section.ceo_social_network_strategist')}</h4>
        <h4 className="h7">{t('profile_section._7_figure_bosses')}</h4>
        <h4 className="h10">{t('profile_section.full_time')}</h4>
        <div className="dropLink large">
          <h4 className="h7">{t('profile_section.years_of_experience')}</h4>
          <RangeSelect
            data={YearExperiance}
            type="ages"
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
            value={selectedRange}
          />
          <h4 className="h7 mt-3">{t('profile_section.years_of_experience')}</h4>
          <RangeSelect
            data={Salaries}
            type="ages"
            selectedRange={selectedRange1}
            setSelectedRange={setSelectedRange1}
            value={selectedRange1}
          />
        </div>
        <h4 className="shadow-5in bg px-2 py-2 h8 fw-bold text-uppercase rounded-1 fw-bold d-inline-block mb-4">{t('profile_section.areas_of_expertise_passion')}</h4>
        <ul className="experianceColorSec w-100 m-0 py-0 px-3 text-start">
          <NavItem as="li" className="mb-4">
            <span className="h8 mb-2">{t('profile_section.social_network_strategist')}</span>
            <div className="colorVis"></div>
          </NavItem>
          <NavItem as="li" className="mb-4">
            <span className="h8 mb-2">{t('profile_section.fashion_stylist_coach')}</span>
            <div className="colorVis"></div>
          </NavItem>
          <NavItem as="li" className="mb-4">
            <span className="h8 mb-2">{t('profile_section.venture_start_up_guru')}</span>
            <div className="colorVis"></div>
          </NavItem>
          <NavItem as="li" className="mb-4">
            <span className="h8 mb-2">{t('profile_section._7_figure_lifestyle_brand_consultant')}</span>
            <div className="colorVis"></div>
          </NavItem>
          <NavItem as="li">
            <span className="h8 mb-2">{t('profile_section._7_figure_self_made_coach')}</span>
            <div className="colorVis"></div>
          </NavItem>
        </ul>
      </div>
    </div>
  );
}
