import React, { useState, useEffect } from 'react';

import two_btn from '../../../../../images/two-btn.png'

import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';

import glass_btn_3 from '../../../../../images/glass-btn-3.png';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import {  RiCloseLine,  } from "react-icons/ri";

import {  RxMagnifyingGlass } from "react-icons/rx";

import { useTranslation } from "react-i18next";



export default function SearchbarThemeSecondComponent() {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  
  



  return (
    <div>



  
 
      <div className="search-position">
                    <li onClick={handleClick}>
                    <RxMagnifyingGlass className="fa-magnify" />

                    </li>
                    {isVisible && (
                      <div className="searchbox">
                        <div className='search-area w-100'>
                          <button className="close-btn" onClick={handleClose}>
                            <RiCloseLine />
                          </button>
                          <form autoComplete="off">
                            <div className='search-field d-flex justify-content-between align-items-center pe-4 ms-auto'>
                              <input placeholder="Search" className="border-0" type="text" />
                              <RxMagnifyingGlass className="icon-mgni" />

                              <div>

                              </div>
                            </div>
                          </form>
                          <button className="search-me-btn book-me-reg  w-50">
                            Search
                          </button>
                          <div className="book-me-mue text-center     m-auto"  >
  <img src={two_btn} alt="two-btn" ></img>
  </div>
  <div className=" glass-show"  >
                    <img src={glass_btn_3} alt="bookbtn" ></img>

                  </div>
                        </div>

                      </div>
                    )}
                  </div>




               
      




    </div>



  );
}
