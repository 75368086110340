import React from 'react'
import TrackingComponentSec from './TrackingComponentSec'
import IntegAddTrackFrom from '../IntegAddTrackFrom';
import iconBluew1 from "../../../../../../images/formicon/icon13.png";
import iconBluew2 from "../../../../../../images/formicon/icon14.png";
import iconBluew3 from "../../../../../../images/formicon/icon15.png";
import icon2 from "../../../../../../images/formicon/user/icon2.png";
import icon3 from "../../../../../../images/formicon/icon40.png";
import icon4 from "../../../../../../images/formicon/icon41.png";
import icon5 from "../../../../../../images/formicon/icon42.png";
import icon6 from "../../../../../../images/formicon/icondot.png";
import TrackingComponent from './TrackingComponent';
export default function TrackingOther() {


  return (
    <div className='px-4'>
      <ul className="customDrop6 customDrop1s max-h-auto customDrop2s w-100 shadow-none position-relative">
            <div className="dropheight h-auto">
              <TrackingComponentSec Name="Shopify" icon={iconBluew1} Dropname="Shopify"/>
              <TrackingComponentSec Name="Wordpress" icon={iconBluew2} Dropname="Wordpress"/>
              <TrackingComponentSec Name="Blogger" icon={iconBluew3} Dropname="Blogger"/>
              <TrackingComponentSec Name="Hubspot" icon={icon2} Dropname="Hubspot"/>
              <TrackingComponentSec Name="Ghost" icon={icon3} Dropname="Ghost"/>
              <TrackingComponentSec Name="Email  Marketing List" icon={icon4} Dropname="Email  Marketing List"/>
              <TrackingComponentSec Name="social media Marketing" icon={icon5} Dropname="social media Marketing"/>
              <TrackingComponentSec Name="Google Calendar" icon={icon6} Dropname="Google Calendar"/>
              <TrackingComponentSec Name="Gmail accounts" icon={icon6} Dropname="Gmail accounts"/>
              <TrackingComponentSec Name="zapier" icon={icon6} Dropname="zapier"/>
              <TrackingComponentSec Name="Trello" icon={icon6} Dropname="Trello"/>
              <TrackingComponentSec Name="TRUSTPILOT" icon={icon6} Dropname="TRUSTPILOT"/>
              <TrackingComponentSec Name="CAPTERRA" icon={icon6} Dropname="CAPTERRA"/>
              <TrackingComponentSec Name="GOOGLE REVIEWS" icon={icon6} Dropname="GOOGLE REVIEWS"/>
              <TrackingComponentSec Name="REVIEWS FROM SOCIAL MEDIA" icon={icon6} Dropname="REVIEWS FROM SOCIAL MEDIA"/>
              <TrackingComponent DropName="Other ?  Type here...." icon={icon6} />
              <IntegAddTrackFrom />
            </div>
          </ul>
    </div>
  )
}
