import React from 'react'
import IntegAddFrom from '../IntegAddFrom';
import icon from "../../../../../../images/formicon/payment/icon.png";
import icon1 from "../../../../../../images/formicon/payment/icon1.png";
import icon2 from "../../../../../../images/formicon/payment/icon2.png";
import icon3 from "../../../../../../images/formicon/payment/icon3.png";
import icon4 from "../../../../../../images/formicon/payment/icon4.png";
import icon5 from "../../../../../../images/formicon/payment/icon5.png";
import icon6 from "../../../../../../images/formicon/payment/icon6.png";
import icon7 from "../../../../../../images/formicon/payment/icon7.png";
import icon8 from "../../../../../../images/formicon/payment/icon8.png";
import icon9 from "../../../../../../images/formicon/payment/icon9.png";
import icon10 from "../../../../../../images/formicon/payment/icon10.png";
import ConnectComponent from './ConnectComponent';
import ConnectComponentInput from './ConnectComponentInput';

export default function Payment() { 
  return (
    <div>
      <div className='row'>
        <div className='col-lg-12'>
          <ul className="customDrop6 customDrop1s max-h-auto customDrop2s w-100 shadow-none position-relative">
            <div className="dropheight h-auto">
              <ConnectComponent Name="PayPal" icon={icon} />
              <ConnectComponent Name="Stripe" icon={icon1} />
              <ConnectComponent Name="Apple pay" />
              <ConnectComponent Name="g-pay" />
              <ConnectComponent Name="pay with amazon" />
              <ConnectComponent Name="Paybright" icon={icon2} />
              <ConnectComponent Name="Klarna" icon={icon3} />
              <ConnectComponent Name="Bank Check |  WIRE-transfer | E-Transfer" icon={icon4} />
              <ConnectComponent Name="Payoneer" icon={icon5} />
              <ConnectComponent Name="Wise" icon={icon6} />
              <ConnectComponent Name="Crypto: Bitcoin | ethereum | etc" />
              <ConnectComponent Name="Payza" icon={icon7} />
              <ConnectComponent Name="Authorize.net" icon={icon8} />
              <ConnectComponent Name="2Checkout" icon={icon9} />
              <ConnectComponent Name="Braintree" icon={icon10} />
              <ConnectComponentInput Name="Other ?  Type here...."/>
              <IntegAddFrom />
            </div>
          </ul>
        </div>
      </div>
    </div>
  )
}
