import React from "react";

export default function ToggleButton(props) {
  return (
    <div className="shadow-4 br-30 bg h-50s mb-4">
      <div className="form-check form-switch px-4 h-100">
        <label className="form-check-label ms-2 w-75 text-uppercase" htmlFor={props.id}>{props.name}</label>
        <input className="form-check-input d-block float-end" type="checkbox" role="switch" id={props.id}  />
      </div>
      {/* checked={props.check} */}
      
    </div>
  );
}
