import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../ProfileHeroSectionDarkTheme.scss'
import dark_book_btn from '../../../../../../images/dark-book-btn.png';
import book_btn from '../../../../../../images/met-book.png';
import glass_btn from '../../../../../../images/glass-btn.png';
import './RegularTheme1Desktop.scss';
import { faCloudArrowDown, faInfo, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import img1 from '../../../../../../images/img1.png'
import version_video from "../../../../../../images/version-video.png"
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { RiCloseLine } from "react-icons/ri";
import { RiArrowDropRightFill } from "react-icons/ri";
import logos_tiktok_icon from "../../../../../../images/logos_tiktok-icon.png"
import desktop_m from '../../../../../../images/desktop-m.png';
import { RxMagnifyingGlass } from "react-icons/rx";
import navbar_shape from '../../../../../../images/navbar-shape.png'
import Vector_shape from '../../../../../../images/Vector-shape.png'
import circle_img from '../../../../../../images/circle-shape.png'
import dots from '../../../../../../images/Dots.png'
import mue_shape from '../../../../../../images/mue-shape.png'
import { useTranslation } from "react-i18next";
import  BookMeComponent from "../../profileHeroSectionComponents/BookMeComponent";
import LogoImageComponent from "../../profileHeroSectionComponents/LogoImageComponent";
import VideoCardComponent from "../../profileHeroSectionComponents/VideoCardComponent";

import TabsComponent from "../../profileHeroSectionComponents/TabsComponent";

import SearchbarComponent from "../../profileHeroSectionComponents/SearchbarComponent";
import TimelineComponent from "../../profileHeroSectionComponents/TimelineComponent";
import HeroSectionComponent from "../../profileHeroSectionComponents/HeroSectionComponent";

import HeroSectionTextComponent from "../../profileHeroSectionComponents/HeroSectionTextComponent";
import  '../../profileHeroSectionComponents/profileComponent/ProfileComponent.scss';
import ProfileComponent from "../../profileHeroSectionComponents/profileComponent/ProfileComponent";
import HeroBottomTabsComponent from "../../profileHeroSectionComponents/HeroBottomTabsComponent";

import AboutMeTittleComponent from "../../profileHeroSectionComponents/AboutMeTittleComponent";
import PlaylistComponent from "../../profileHeroSectionComponents/PlaylistComponent";

import TabMenuComponent from "../../profileHeroSectionComponents/TabMenuComponent";
import RegularTestimonialComponent from "../../profileHeroSectionComponents/RegularTestimonialComponent";
import  SocialMediaIconsComponent from "../../profileHeroSectionComponents/SocialMediaIconsComponent";
import  ToggleComponent from "../../profileHeroSectionComponents/ToggleComponent";
export default function RegularTheme1Desktop() {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

    const handleClick = () => {
      setIsVisible(!isVisible);
    };
  
    const handleClose = () => {
      setIsVisible(false);
    };
   
  
    const [isOpen, setIsOpen] = useState(false);
    
      useEffect(() => {
        if (isOpen) {
          const timer = setTimeout(() => {
            setIsOpen(false);
          }, 5000);
    
          return () => clearTimeout(timer);
        }
      }, [isOpen]);
    
      const toggleDropdown = () => {
        setIsOpen(!isOpen);
      };



  return (
    <div>



      <div className="">
        <div>
 

          <div className="d-flex w-100">
            <img src={navbar_shape} alt="  " className="navbar-shape"></img>

            <div className="navbar-top p-2 w-100 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center w-50">
         <div className='theme-one-desktop'>

           <ProfileComponent />
           </div>
            <LogoImageComponent />

              </div>
        <RegularTestimonialComponent />

      <TimelineComponent />
              <div className='search-navbar-btn'>
     <SearchbarComponent />


<div className='border-bt'>

</div>

                  <li>
                    <div className="info-oter">
                      <FontAwesomeIcon className="info-icon" icon={faInfo} >

                      </FontAwesomeIcon>
                    </div>
                  </li>
                </div>

            </div>
          <TabMenuComponent />

          </div>
          <div className="main-content d-flex">
            <div>
              <img src={circle_img} alt="  " className="circle-shape"></img>
            </div>
            <div className="hero-sidebar text-center">
              <div className="name-btn">
                {t('profile_section.dyna_appolon')}
              </div>
              <p>
                {t('profile_hero_section.ceo_escapee')}
              </p>
              <div>
                <ul className="pt-5 pb-5">
                  <div className='sidebar-video'>
                  <div class="gold-btn-version">LET'S be 
social</div>
<div className='version-video-2'>
<div className='d-flex p-2 pb-0'>
<div>
      <img src={img1} alt="img1" className="" ></img>
  </div>
  <div>
    <h5>
    @9to5Escapee.com
    </h5>
    <p>
    1 min ago <img src={logos_tiktok_icon} className='logos_tiktok-icon' alt='tiktok'></img>
    </p>
  </div>

</div>
<h1 className='p-2 '>
  Escape The Rat Race.
Here is How.  See more
    </h1>
  <img className='version-vido'  src={version_video}></img>
</div>
                  </div>
                  <div className="search-position">
                    <li onClick={handleClick}>
                      <FontAwesomeIcon className="fa-magnify" icon={faMagnifyingGlass}></FontAwesomeIcon>

                    </li>
                    {isVisible && (
                      <div className="searchbox">
                        <div className='search-area w-100'>
                          <button className="close-btn" onClick={handleClose}>
                            <RiCloseLine />
                          </button>
                          <form autoComplete="off">
                            <div className='search-field d-flex justify-content-between align-items-center pe-4 ms-auto'>
                              <input placeholder="Search" className="border-0" type="text" />
                              <RxMagnifyingGlass className="icon-mgni" />

                              <div>

                              </div>
                            </div>
                          </form>
                          <button class="search-me-btn w-50">Search</button>
                          <div className=" text-center  meu-show mt-2" >
                            <img src={book_btn} alt="bookbtn" ></img>

                          </div>
                          <div className=" text-center dark-book-btn  dark-meu-show mt-2" >
                            <img src={dark_book_btn} alt="bookbtn" ></img>

                          </div>
                          <div className=" glass-show" >
                            <img src={glass_btn} alt="bookbtn" ></img>

                          </div>

                        </div>

                      </div>
                    )}
                  </div>




                  <li>
                    <div className="info-oter">
                      <FontAwesomeIcon className="info-icon" icon={faInfo} >

                      </FontAwesomeIcon>
                    </div>
                  </li>

                </ul>
             <ToggleComponent />
            <SocialMediaIconsComponent />
      <HeroBottomTabsComponent />
              </div>
            </div>
            <img src={Vector_shape} alt="  " className="vector-shape"></img>
            <div className="content-hero m-section">
              <div className="hero-conent">
                <div className="hero-section ">
                  <div className='version-2'>
                  <button onClick={toggleDropdown} className={`arrow-button ${isOpen ? 'open' : ''}`}>
                    <RiArrowDropRightFill className="icon-arrow" />
                    </button>
                {isOpen && (
                  <div className={`dropdown-content ${isOpen ? 'fadeInUp' : 'fadeInDown'}`}>
                  <div className="intro-card ">
             
        <div>
                    <div className="intro-inner">
                 
                    <h5>
                      Hi,
                    </h5>
                    <h1>
                      I am
                    </h1>
                    <h2>
                      Andy rubin
                    </h2>
                    <div className="border-card">
                    </div>
                    <h4>
                      CEO
                    </h4>
                    <p>
                      Social Network Strategist
                    </p>
                    </div>
                    </div>             
                  </div>
                  </div>
 )}
                  </div>
               <HeroSectionComponent />
                  <div className="hero-section hero-section-mue">
                    <img src={desktop_m} alt="backgrnd-2" className="content-img" >
                    </img>

                  </div>
             <HeroSectionTextComponent />
                </div>

                <div className="content-text d-flex">
                  <div className="img-section">
                    <img src={dots} className="dots" alt="dots"></img>
                    <img src={mue_shape} className="mue-shape" alt="mue-shape"></img>
                  </div>
                  <div className="playlist-section">
<AboutMeTittleComponent />
         <PlaylistComponent />
</div>
          <TabsComponent />
                  <div className="tab-right-section">

                  </div>
                 <BookMeComponent />
                <VideoCardComponent />

                </div>
              </div>

            </div>

            <div>

            </div>
          </div>
        </div>


      </div>




    </div>



  );
}
