import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function NicheFourSlider() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1366 },
      items: 6,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1366, min: 768 },
      items: 5,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <Carousel responsive={responsive} autoPlay={false} autoPlaySpeed={2000} transitionDuration={500}>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0 active" id="manage7" data-bs-toggle="tab" data-bs-target="#manage7" type="button" role="tab" aria-controls="manage7" aria-selected="true">MANAGE
SUB-DOMAIN & SSL</button>
      </div>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0" id="favicon" data-bs-toggle="tab" data-bs-target="#favicon" type="button" role="tab" aria-controls="favicon" aria-selected="true">favicon generator</button>
      </div>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0" id="custom7" data-bs-toggle="tab" data-bs-target="#custom7" type="button" role="tab" aria-controls="custom7" aria-selected="false">CONNECT YOUR OWN custom DOMAIN</button>
      </div>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0" id="buy7" data-bs-toggle="tab" data-bs-target="#buy7" type="button" role="tab" aria-controls="buy7" aria-selected="false">BUY NEW DOMAIN</button>
      </div>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0" id="setting7s" data-bs-toggle="tab" data-bs-target="#setting7s" type="button" role="tab" aria-controls="setting7s" aria-selected="false">DOMAIN SETTINGS</button>
      </div>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0" id="servise7" data-bs-toggle="tab" data-bs-target="#servise7" type="button" role="tab" aria-controls="servise7" aria-selected="false">WEB HOSTING SERVICE &  SETTINGS</button>
      </div>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0" id="identity7" data-bs-toggle="tab" data-bs-target="#identity7" type="button" role="tab" aria-controls="identity7" aria-selected="false">site identity & general settings</button>
      </div>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0" id="default7" data-bs-toggle="tab" data-bs-target="#default7" type="button" role="tab" aria-controls="default7" aria-selected="false">website default pages home | blog| shop|401 | 404</button>
      </div>
    </Carousel>
  )
}