import React, { useState } from "react";
import desktop_3_1 from "../../../../images/servicepackageplan/Desktop3-1.png"
import tab_3_1 from "../../../../images/servicepackageplan/Tab3-1.png"
import mobile_3_1 from "../../../../images/servicepackageplan/Mobile3-1.png"
import desktop_3_2 from "../../../../images/servicepackageplan/Desktop3-2.png"
import tab_3_2 from "../../../../images/servicepackageplan/Tab3-2.png"
import mobile_3_2 from "../../../../images/servicepackageplan/Mobile3-2.png"
import desktop_3_3 from "../../../../images/servicepackageplan/Desktop3-3.png"
import tab_3_3 from "../../../../images/servicepackageplan/Tab3-3.png"
import mobile_3_3 from "../../../../images/servicepackageplan/Mobile3-3.png"
import desktop_3_4 from "../../../../images/servicepackageplan/Desktop3-4.png"
import tab_3_4 from "../../../../images/servicepackageplan/Tab3-4.png"
import mobile_3_4 from "../../../../images/servicepackageplan/Mobile3-4.png"
import desktop_3_5 from "../../../../images/servicepackageplan/Desktop3-5.png"
import tab_3_5 from "../../../../images/servicepackageplan/Tab3-5.png"
import mobile_3_5 from "../../../../images/servicepackageplan/Mobile3-5.png"
import desktop_3_6 from "../../../../images/servicepackageplan/Desktop3-6.png"
import tab_3_6 from "../../../../images/servicepackageplan/Tab3-6.png"
import mobile_3_6 from "../../../../images/servicepackageplan/Mobile3-6.png"
import Modal from 'react-bootstrap/Modal';
import ServicePackagePlanGlassmorphicThemeDesktopView1 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeDesktopView1";
import ServicePackagePlanGlassmorphicThemeTabView1 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeTabView1";
import ServicePackagePlanGlassmorphicThemeDesktopView2 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeDesktopView2";
import ServicePackagePlanGlassmorphicThemeTabView2 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeTabView2";
import ServicePackagePlanGlassmorphicThemeMobileView1 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeMobileView1";
import ServicePackagePlanGlassmorphicThemeMobileView2 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeMobileView2";
import ServicePackagePlanGlassmorphicThemeMobileView3 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeMobileView3";
import ServicePackagePlanGlassmorphicThemeMobileView4 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeMobileView4";
import ServicePackagePlanGlassmorphicThemeMobileView5 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeMobileView5";
import ServicePackagePlanGlassmorphicThemeMobileView6 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeMobileView6";
import ServicePackagePlanGlassmorphicThemeTabView3 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeTabView3";
import ServicePackagePlanGlassmorphicThemeTabView4 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeTabView4";
import ServicePackagePlanGlassmorphicThemeTabView5 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeTabView5";
import ServicePackagePlanGlassmorphicThemeTabView6 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeTabView6";
import ServicePackagePlanGlassmorphicThemeDesktopView3 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeDesktopView3";
import ServicePackagePlanGlassmorphicThemeDesktopView4 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeDesktopView4";
import ServicePackagePlanGlassmorphicThemeDesktopView5 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeDesktopView5";
import ServicePackagePlanGlassmorphicThemeDesktopView6 from "./servicePackagePlanGlassmorphicThemes/ServicePackagePlanGlassmorphicThemeDesktopView6";
import ButtonBoxNew from "../../../global/ButtonBoxNew";

import './ServicePackagePlanThemes.scss';
import { useTranslation } from "react-i18next";
export default function ServicePackagePlanGlassmorphicTheme() {
  const { t, i18n } = useTranslation();

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [themedesktopShow, setThemedesktopShow] = useState(false);
  const [themetabShow, setThemetabShow] = useState(false);
  const [themedesktop3Show, setThemedesktop3Show] = useState(false);
  const [themedesktop4Show, setThemedesktop4Show] = useState(false);
  const [themedesktop5Show, setThemedesktop5Show] = useState(false);
  const [themedesktop6Show, setThemedesktop6Show] = useState(false);
  const [themetab3Show, setThemetab3Show] = useState(false);
  const [themetab4Show, setThemetab4Show] = useState(false);
  const [themetab5Show, setThemetab5Show] = useState(false);
  const [themetab6Show, setThemetab6Show] = useState(false);
  const [thememobileShow, setThememobileShow] = useState(false);
  const [thememobile3Show, setThememobile3Show] = useState(false);
  const [thememobile4Show, setThememobile4Show] = useState(false);
  const [thememobile5Show, setThememobile5Show] = useState(false);
  const [thememobile6Show, setThememobile6Show] = useState(false);
  const [desktopgeoShow, setDesktopgeoShow] = useState(false);





  return (
    <div className="">

      <h4 className="text-center">
        Glassmorphic  Design Version 1
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_1} alt='desktop-3_1' >
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setDesktopgeoShow(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setDesktopgeoShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktopgeoShow}
            onHide={() => setDesktopgeoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeDesktopView1 />

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_1} alt='desktop-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setSmShow(true)}/>

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeTabView1 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_1} alt='mobile-3-1' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeMobileView1 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 2
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_2} alt='desktop-3-2' >
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setThemedesktopShow(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThemedesktopShow(true)}/>

          <Modal
            show={themedesktopShow}
            onHide={() => setThemedesktopShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeDesktopView2 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_2} alt='tab-3-2' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetabShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setThemetabShow(true)}/>

          <Modal
            show={themetabShow}
            onHide={() => setThemetabShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeTabView2 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_2} alt='mobile-3-2' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobileShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThememobileShow(true)}/>

          <Modal
            show={thememobileShow}
            onHide={() => setThememobileShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeMobileView2 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Glassmorphic  Design Version 3
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_3} alt='desktop-3-3' >
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setThemedesktop3Show(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setThemedesktop3Show(true)}/>

          <Modal
            show={themedesktop3Show}
            onHide={() => setThemedesktop3Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeDesktopView3 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_3} alt='tab-3-3' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetab3Show(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThemetab3Show(true)}/>

          <Modal
            show={themetab3Show}
            onHide={() => setThemetab3Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeTabView3 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_3} alt='mobile-3-3' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobile3Show(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobiel view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThememobile3Show(true)}/>

          <Modal
            show={thememobile3Show}
            onHide={() => setThememobile3Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeMobileView3 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Glassmorphic  Design Version 4
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_4} alt='desktop-3_4' >
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setThemedesktop4Show(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThemedesktop4Show(true)}/>

          <Modal
            show={themedesktop4Show}
            onHide={() => setThemedesktop4Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeDesktopView4 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_4} alt='tab-3-4' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetab4Show(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setThemetab4Show(true)}/>

          <Modal
            show={themetab4Show}
            onHide={() => setThemetab4Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeTabView4 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_4} alt='mobile-3_4' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobile4Show(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setThememobile4Show(true)}/>

          <Modal
            show={thememobile4Show}
            onHide={() => setThememobile4Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeMobileView4 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Glassmorphic  Design Version 5
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_5} alt='desktop-3_5' >
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setThemedesktop5Show(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThemedesktop5Show(true)}/>

          <Modal
            show={themedesktop5Show}
            onHide={() => setThemedesktop5Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeDesktopView5 />

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_5} alt='tab-3-5' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetab5Show(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThemetab5Show(true)}/>

          <Modal
            show={themetab5Show}
            onHide={() => setThemetab5Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeTabView5 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_5} alt='mobile-3_5' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobile5Show(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThememobile5Show(true)}/>

          <Modal
            show={thememobile5Show}
            onHide={() => setThememobile5Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeMobileView5 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Glassmorphic  Design Version 6
      </h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_6} alt='desktop-3_6' >
            </img>

          </div>
          {/* <button className="screen-btn" onClick={() => setThemedesktop6Show(true)}>
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setThemedesktop6Show(true)} />

          <Modal
            show={themedesktop6Show}
            onHide={() => setThemedesktop6Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeDesktopView6 />
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_6} alt='tab-3-6' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetab6Show(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThemetab6Show(true)}/>

          <Modal
            show={themetab6Show}
            onHide={() => setThemetab6Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeTabView6 />

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_6} alt='mobile-3_6' ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobile6Show(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThememobile6Show(true)}/>

          <Modal
            show={thememobile6Show}
            onHide={() => setThememobile6Show(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <ServicePackagePlanGlassmorphicThemeMobileView6 />

            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
