import React from 'react';
import '../../../global/timelineTab/TimelineTab.scss';
import Video from '../../../../images/iconVid.png';
import VideoPopup from '../../../global/VideoPopup';
import Video1 from '../../../../images/iconVid1.png';
import ButtonBox from "../../../global/ButtonBox";
// import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import BrandPositionStatementForm from './BrandPositionStatementForm';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';
export default function BrandPositionStatementStep() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });
    let {isVideoVisible} = useSelector(state => state.videoReducer);
    const dispatch = useDispatch();
    const handleClick6 = () => {
      dispatch(toggleVideo());
    };

    return (
        <div className='sectionfifth arrow-select'>
            <Tab.Container id="left-tabs-example" defaultActiveKey="two">
                <div className='' >
                    <div className='flex'>


                        <div className='width-fll'>
                            <VideoPopup />
                            <Tab.Content className='class-scroll'>
                                <Tab.Pane eventKey="two">
                                    <div className="w-100 align-items-center mx-auto d-flex flex-column-reverse justify-content-between three-bsiness">
                                        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto ">
                                        <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
                                            
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade  active show" id="custone" role="tabpanel" aria-labelledby="custone">
                                                    <div className='h3-border1 d-flex'>
                                                        <h3 className='h8 mb-0 text-body border-0 text-left w-100 bg'>Create Your Brand Position Statement</h3>
                                                        {/* <button className='shadow-4 h8 topBtn border-0 bg'>Already Have a Brand Position Statement?</button> */}
                                                    </div>
                                                    <BrandPositionStatementForm />
                                                </div>
                                                <div className="tab-pane fade" id="custtwo" role="tabpanel" aria-labelledby="custtwo">
                                                    <div className='h3-border1 d-flex'>
                                                        <h3 className='h8 mb-0 text-body border-0 text-left w-100 bg'>Already Have a Brand Position Statement?</h3>
                                                    </div>
                                                    <div className="w-100 mb-30 mt-5 p-4">
                                                        {/* <h4 className="h6 fw-medium pt-3 mt-5 custombdr"></h4> */}
                                                        <p className="h7 mt-3 fw-normal lh-normal">Type your current Brand Position  Statement here</p>
                                                        <textarea rows="4" className='shadow-5in p-4 h6 h-220 lh-normal fw-normal d-block' placeholder='For people who are tired of working 9 to 5 and seriously ready for real Financial Freedom, 9 to 5 ESCAPEE is the leader of the Turnkey  Business In-A-Box Automated System that delivers 55 Proven Steps to launch any online businesses within 3 hours, so people can finally break-free from the rat race and experience real financial freedom, because 9 to 5 ESCAPEE takes innovative and efficient approach to business best practices fully-loaded with business niche clarity, market research, business plan in order to launch a proven to be Successful, Profitable, Scalable, Sustainable, Passive, and Evergreen online business from the start.'></textarea>
                                                    </div>
                                                    <div className="w-100 d-flex pb-5 justify-content-center align-items-center">
                                                        <ButtonBox boxwidth="w-400 mt-5 lg text-primary mb-4" clicked="Mission Statement Saved" name="Save Generated Mission Statement" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="d-flex position-relative align-items-center justify-content-between w-100 mb-4">
                                            <div id="myTab1" className='flex' role="tablist">
                                                <button className="buttonArrow me-3 nav-link text-body border-0 bg br-10 active" id="custone" data-bs-toggle="tab" data-bs-target="#custone" type="button" role="tab" aria-controls="custone" aria-selected="true">
                                                    <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
                                                </button>
                                                <button className=" me-3 btn-positions1 shadow-4 h8 topBtn border-0 bg text-body nav-link border-0 bg" id="custtwo" data-bs-toggle="tab" data-bs-target="#custtwo" type="button" role="tab" aria-controls="custtwo" aria-selected="false">
                                                    Already Have a Brand Position Statement?
                                                </button>
                                            </div>
                                            <div className='btn-save-hide'>
                                                <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="" icon={faFloppyDisk} name="Save" />
                                            </div>

                                        </span>
                                    </div>
                                </Tab.Pane>

                            </Tab.Content>


                        </div>
                    </div>
                </div>





            </Tab.Container>

        </div>
    )
}