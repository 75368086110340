import React from "react";
import btnIcon from '../../images/btn-icon.png';

export default function ButtonBoxNew(props) {
  return (
    <div className="button_wrapper_new" onClick={props.onClick ? props.onClick : ""}> 
      <button className={"border-0 button_7 " + props.boxwidth} >
        <img src={btnIcon} alt="" className="btn_border ml_2 w-auto h-auto" />
        <span className="d-flex justify-content-center aligh-items-center">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-plus" className={"svg-inline--fa fa-circle-plus h5 my-auto me-2 d-none " + props.iconbox} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"></path></svg>
        {props.name}</span>
        <div className="inner_btn">
          <div className="btn_1"></div>
        </div>
        <img src={btnIcon} alt="" className="btn_border_reverse ml_2 w-auto h-auto" />
      </button>
    </div>
  );
}
