import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';
import {  faStar } from "@fortawesome/free-solid-svg-icons";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import testimonial from '../../../../../images/testimonial.png';
import testimonial_glass from '../../../../../images/testimonial-glass.png';
import timeline_gold from '../../../../../images/timeline-gold.png';
import { useTranslation } from "react-i18next";
export default function RegularTestimonialComponent() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
      if (isOpen) {
        const timer = setTimeout(() => {
          setIsOpen(false);
        }, 5000);
  
        return () => clearTimeout(timer);
      }
    }, [isOpen]);
  return (
    <div className='w-100'>  
              <div className="d-flex w-100">
                <div className="testimonial">
                  <div className="timeline-top d-flex">
                    <div className="d-flex">
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                    </div>
                    <div>
                      <p>
                        {t('profile_hero_section.dummy_text_of_the_printing_printing')}
                        <p><b>   {t('profile_hero_section.client_name_here')}</b>
                          {t('profile_hero_section.designation_here')}</p>
                      </p>


                    </div>

                  </div>
                  <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                </div>
                <div className="testimonial">
                  <div className="timeline-top d-flex">
                    <div className="d-flex">
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                    </div>
                    <div>
                      <p>
                        {t('profile_hero_section.dummy_text_of_the_printing_printing')}
                        <p><b> {t('profile_hero_section.client_name_here')}</b>
                          {t('profile_hero_section.designation_here')}</p>
                      </p>


                    </div>

                  </div>
                  <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                </div>
                <div className="testimonial">
                  <div className="timeline-top d-flex">
                    <div className="d-flex">
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                      <FontAwesomeIcon className="icon-green" icon={faStar} ></FontAwesomeIcon>
                    </div>
                    <div>
                      <p>
                        {t('profile_hero_section.dummy_text_of_the_printing_printing')}
                        <p><b> {t('profile_hero_section.client_name_here')}</b>
                          {t('profile_hero_section.designation_here')}</p>
                      </p>


                    </div>

                  </div>
                  <img src={testimonial} className="test-reg" alt="testimonial"></img>
                  <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                  <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                </div>
              </div>

    </div>



  );
}
