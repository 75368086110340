import React from "react";
import { useState } from "react";
// import ButtonBox from "../../../../../global/ButtonBox";
import "./RegularTheme1Mobile.scss";

import dark_book_btn from "../../../../../../images/dark-book-btn.png";

import book_btn from "../../../../../../images/met-book.png";

import second_3 from "../../../../../../images/second-3.png";

import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

import { RiCloseLine } from "react-icons/ri";

import Vector_shape from "../../../../../../images/Vector-shape.png";
import { ImInfo } from "react-icons/im";
import { RxMagnifyingGlass } from "react-icons/rx";

import glass_btn from "../../../../../../images/glass-btn.png";
import mobile_m from "../../../../../../images/mobile-m.png";
import mobile_hero_img from "../../../../../../images/mobile-img.png";

import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

import vedio from "../../../../../../images/video.png";

import mue_shape from "../../../../../../images/mue-shape.png";
import BookMeComponent from "../../profileHeroSectionComponents/BookMeComponent";

import VideoCardComponent from "../../profileHeroSectionComponents/VideoCardComponent";

import TabsComponent from "../../profileHeroSectionComponents/TabsComponent";

import TimelineSectionComponent from "../../profileHeroSectionComponents/TimelineSectionComponent";

import HeroSectionTextComponent from "../../profileHeroSectionComponents/HeroSectionTextComponent";

import AboutMeTittleComponent from "../../profileHeroSectionComponents/AboutMeTittleComponent";
import PlaylistRegMobileComponent from "../../profileHeroSectionComponents/PlaylistRegMobileComponent";
import TabMenuComponent from "../../profileHeroSectionComponents/TabMenuComponent";
import "../../profileHeroSectionComponents/profileComponent/ProfileComponent.scss";
import ProfileComponent from "../../profileHeroSectionComponents/profileComponent/ProfileComponent";

import { useTranslation } from "react-i18next";

export default function RegularTheme1Mobile() {
  const { t, i18n } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [Competency1, setCompetency1] = useState(10);
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  return (
    <div>
      <div className="mobile-modal">
        <div>
          <div className="d-flex w-100 border-b">
            <div className="navbar-top p-2 w-100 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center w-50">
                <div className="theme-one-mobile">
                  <ProfileComponent />
                </div>
                <div className="navbar-btn">
                  <div className="name-btn">DYNA APPOLON</div>
                  <p>CEO | 9 to 5 Escapee</p>
                </div>
                <div className="mi-info">
                  <ImInfo />
                </div>
              </div>
            </div>
            <div className="navbar-right">
              <TabMenuComponent />

              <div className="search-position">
                <div className="navbar-magni" onClick={handleClick}>
                  <RxMagnifyingGlass className="fa-magnify" />
                </div>
                {isVisible && (
                  <div className="searchbox">
                    <div className="search-area w-100">
                      <button className="close-btn" onClick={handleClose}>
                        <RiCloseLine />
                      </button>
                      <form autoComplete="off">
                        <div className="search-field d-flex justify-content-between align-items-center pe-4 ms-auto">
                          <input
                            placeholder="Search"
                            className="border-0"
                            type="text"
                          />
                          <RxMagnifyingGlass className="icon-mgni" />

                          <div></div>
                        </div>
                      </form>
                      <button class="search-me-btn w-50">Search</button>
                      <div className=" text-center  meu-show mt-2">
                        <img src={book_btn} alt="bookbtn"></img>
                      </div>
                      <div className="  mt-2  dark-book-btn  dark-meu-show ">
                        <img src={dark_book_btn} alt="bookbtn"></img>
                      </div>
                      <div className=" text-center glass-btn   mt-2">
                        <img src={glass_btn} alt="bookbtn"></img>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mue-border"></div>
          <div className="w-100 d-flex navbar-top p-2 w-100 d-flex align-items-center justify-content-between">
            <div className="">
              <img
                src={second_3}
                className="img-logo img-logo-oter"
                alt="logo"
              ></img>
            </div>
            <TimelineSectionComponent />
          </div>
          <div className="main-content">
            <div className="hero-conent m-section">
              <div className="hero-conent">
                <div className="hero-section">
                  <div className="switch-mobile">
                    <div className="mode-set">
                      {t("profile_hero_section.mode")}
                    </div>
                    <div className="switch-border">
                      <input type="checkbox" />
                    </div>

                    <div className="social-set">
                      {t("profile_hero_section.social")}
                    </div>
                  </div>
                  <img
                    src={mobile_hero_img}
                    alt="mobile"
                    className="content-img hero-section-reg"
                  ></img>
                  <div className="hero-section hero-section-mue">
                    <img
                      src={mobile_m}
                      alt="backgrnd-2"
                      className="content-img"
                    ></img>
                  </div>
                  <HeroSectionTextComponent />
                </div>
                <div className="d-flex mobile-btns">
                  <AboutMeTittleComponent />

                  <div className="mue-shape">
                    <img src={mue_shape} alt="shape"></img>
                  </div>
                  <BookMeComponent />
                </div>

                <div className="content-text ">
                  <img
                    src={Vector_shape}
                    alt="  "
                    className="vector-shape"
                  ></img>
                  <div className="video-sectionn mue-video-section">
                    <div className="video-name">
                      <div className="video-img">
                        <img src={vedio}></img>
                      </div>
                    </div>
                  </div>
                  <div className="gold-btn">
                    {t("profile_section.ceo_social_network_strategist")}
                  </div>
                  <TabsComponent />

                  <PlaylistRegMobileComponent />

                  <VideoCardComponent />
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
