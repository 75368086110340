import React from "react";

export default function RadioOn(props) {
  return (
    
    <div className="me-3">
      <input type="radio" className="btn-check" id={props.id} checked={props.check}  name={props.name} autoComplete="off" />
      <label className="btn br-30 bg" htmlFor={props.id}>On</label>
    </div>
  );
}
