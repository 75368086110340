import React from "react";
import { Link } from 'react-router-dom';
import ButtonBox from "../../../../../global/ButtonBox";
// import SocialSlider from "../../../../../global/SocialSlider";
import SocialSliderMed from "../../../../../global/socialSlider/SocialSliderMed";
import SocialSliderPat from "../../../../../global/socialSlider/SocialSliderPat";
import SocialSliderDis from "../../../../../global/socialSlider/SocialSliderDis";
import SocialSliderBeh from "../../../../../global/socialSlider/SocialSliderBeh";
import qrcode from '../../../../../../images/qrcode.png';
import { ReactComponent as Download } from "../../../../../../images/icons/icon29.svg";
import { ReactComponent as IconBluew1 } from "../../../../../../images/svgIcon/icon13.svg";

import { ReactComponent as IconBlue2r } from "../../../../../../images/icons/icon25.svg";
import { ReactComponent as IconBlue1r } from "../../../../../../images/icons/icon24.svg";
import { ReactComponent as IconBlue3r } from "../../../../../../images/icons/icon26.svg";
import { ReactComponent as IconBlue4r } from "../../../../../../images/icons/icon27.svg";
import { ReactComponent as IconBlue5r } from "../../../../../../images/icons/icon28.svg";
import { ReactComponent as VideoIcon } from "../../../../../../images/icons/iconvid.svg";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import MediumBanner from "../../../../../global/socialBanner/MediumBanner";
import DribbleBanner from "../../../../../global/socialBanner/DribbleBanner";
import PatreonBanner from "../../../../../global/socialBanner/PatreonBanner";
import DiscordBanner from "../../../../../global/socialBanner/DiscordBanner";
import BehanceBanner from "../../../../../global/socialBanner/BehanceBanner";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import './ContactUsSectionThemes.scss';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { faLock } from "@fortawesome/free-solid-svg-icons";
import active_vector from '../../../../../../images/active-vector.png'
export default function Social4() {

  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="SocialMedia d-none">
        <ul>
          <NavItem as="li" className="shadow-4 br-20 my-4 px-3">
            <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-4">{t('profile_section. choose_your_medium_business_profile_banner_design')}   <b className="ms-2">{t('profile_section._1500_X_750_pixels')}</b></h6>
              <Link to="" className="text-primary">
                <VideoIcon className="w-100x h-auto" />
              </Link>
            </div>
            <div className="px-5 mb-4 mt-3">
              <SocialSliderMed />
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.medium_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBlue2r />
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.medium_business_username')}</h6>
                <input placeholder={t('profile_section.enter_username')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.medium_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.medium_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_medium_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_medium_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className="bdrnone shadow-4 br-20 my-4 px-3">
            {/* <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-4">Choose Your Dribble Business Profile | Banner Design 3200 X 410 PIXELS   <b className="ms-2">{t('profile_section._1500_X_750_pixels')}</b></h6>
              <Link to="" className="text-primary">
              <VideoIcon className="w-100x h-auto" />
              </Link>
              </div>
              <div className="px-5 mb-4 mt-3">
                <SocialSliderMed />
              </div> */}
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.dribble_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBlue1r />
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.dribble_business_username')}</h6>
                <input placeholder={t('profile_section.enter_username')} className="w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.dribble_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.dribble_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_dribble_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_dribble_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className="shadow-4 br-20 my-4 px-3">
            <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-4">{t('profile_section.choose_your_patreon_business_profile_banner_design')} <b className="ms-2">{t('profile_section._1600_X_400_pixels')}</b></h6>
              <Link to="" className="text-primary">
                <VideoIcon className="w-100x h-auto" />
              </Link>
            </div>
            <div className="px-5 mb-4 mt-3">
              <SocialSliderPat />
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.patreon_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBlue3r />
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.patreon_business_account_username')}</h6>
                <input placeholder={t('profile_section.enter_username')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.patreon_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.patreon_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_patreon_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_patreon_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className="shadow-4 br-20 my-4 px-3">
            <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-4">{t('profile_section.choose_your_discord_business_profile_banner_design')}  <b className="ms-2">{t('profile_section._960_X_540_pixels')}</b></h6>
              <Link to="" className="text-primary">
                <VideoIcon className="w-100x h-auto" />
              </Link>
            </div>
            <div className="px-5 mb-4 mt-3">
              <SocialSliderDis />
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.discord_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBlue4r />
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.discord_business_account_username')}</h6>
                <input placeholder={t('profile_section.enter_username')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.discord_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.discord_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_discord_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_discord_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className="shadow-4 br-20 my-4 px-3">
            <div className="d-flex justify-content-start align-items-center">
              <h6 className="h8 mb-0 me-4">{t('profile_section.choose_your_behance_business_profile_banner_design')}    <b className="ms-2">{t('profile_section._3200_X_410_pixels')}</b></h6>
              <Link to="" className="text-primary">
                <VideoIcon className="w-100x h-auto" />
              </Link>
            </div>
            <div className="px-5 mb-4 mt-3">
              <SocialSliderBeh />
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.behance_business_account')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBlue5r />
                  {t('profile_section.registration_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.behance_business_account_username')}</h6>
                <input placeholder={t('profile_section.enter_username')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.behance_pixel_id')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  <IconBluew1 className="me-2" />
                  {t('profile_section.pixel_access_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.behance_pixel_id')}</h6>
                <input placeholder={t('profile_section.enter_behance_pixel_id_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex mb-4">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.bio_link')}
                </button>
              </span>
              <span className="w-100">
                <h6 className="h8 text-muted mb-2">{t('profile_section.bio_link')}</h6>
                <input placeholder={t('profile_section.enter_bio_link_you_want_to_add_on_your_behance_profile_here')} className=" w-100" type="text" />
              </span>
            </div>
            <div className="d-flex">
              <span className="w-220 me-4">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <button className="btn h-50s w-100 lh-normal border-0">
                  {t('profile_section.generate_qr_code_now')}
                </button>
              </span>
              <span className="w-100 d-flex flex-column">
                <h6 className="h8 text-muted mb-2">{t('profile_section.qr_code')}</h6>
                <input placeholder={t('profile_section.enter_qr_code_title_label_here')} className="w-100" type="text" />
              </span>
              <span className="w-50 d-flex justify-content-end align-items-center">
                <img src={qrcode} alt="" width="120" height="120" />
                <Link to="" className="text-primary downloadLink shadow-4 ms-4 d-flex bg justify-content-center align-items-center">
                  <Download />
                </Link>
              </span>
            </div>
          </NavItem>
          <NavItem as="li" className=" text-center mt-5 justify-content-center bdrnone">
            <ButtonBox
              boxwidth="w-140 mt-0 mb-50 mx-auto"
              clicked="Saved"
              name="Save/Next"
            />
          </NavItem>
        </ul>
      </div>
      <div>
        <h4 className="text-center">
          Switch the Style
        </h4>
        <Tab.Container id="left-tabs-example3" defaultActiveKey="Regular">
          <div className="d-flex flex-column-reverse">
            <Tab.Content>
              <Tab.Pane eventKey="Regular">
                <div className="social-reguler">
                  <MediumBanner />
                  <DribbleBanner />
                  <PatreonBanner />
                  <DiscordBanner />
                  <BehanceBanner />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Neumorphic">
                <div className="social-neumorphic">
                  <MediumBanner />
                  <DribbleBanner />
                  <PatreonBanner />
                  <DiscordBanner />
                  <BehanceBanner />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Glassmorphic">
                <div className="social-glassmorphic">
                  <MediumBanner />
                  <DribbleBanner />
                  <PatreonBanner />
                  <DiscordBanner />
                  <BehanceBanner />
                </div>
              </Tab.Pane>
            </Tab.Content>
            <div className="d-flex align-items-center justify-content-between services-work  w-100 mb-0 ">
              <Nav className="w-100 d-flex  align-items-center justify-content-center">
                <ul className="d-flex justify-content-center my-4 pb-2 items-center">
                  <Nav.Item as="li" className="me-0">
                    <Nav.Link className="m-0 p-0 border-0 design-one design-one-reg" eventKey="Regular">
                      <div className="active-sign">
                        <div className="active-icon-color " >
                          <img src={active_vector} className="active-vector" alt="active-icon" ></img>
                        </div>
                      </div>
                      <span>Regular</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="margin-left-scnd pl-3">
                    <Nav.Link className="m-0 p-0 border-0 design-two ml-3" eventKey="Neumorphic">
                      <div className="active-sign">
                        <div className="active-icon-color-second " >
                          <img src={active_vector} className="active-vector" alt="active-icon" ></img>
                        </div>
                      </div>  <span>Neumorphic <FontAwesomeIcon className="lock" icon={faLock} ></FontAwesomeIcon> </span>
                    </Nav.Link>

                  </Nav.Item>
                  <Nav.Item as="li" className="margin-left-scnd pl-3">
                    <Nav.Link className="m-0 p-0 border-0 design-three ml-3" eventKey="Glassmorphic">
                      <div className="active-sign">
                        <div className="active-icon-color-third " >
                          <img src={active_vector} className="active-vector" alt="active-icon" ></img>
                        </div>
                      </div>      <span>Glassmorphic <FontAwesomeIcon className="lock" icon={faLock} ></FontAwesomeIcon> </span>

                    </Nav.Link>

                  </Nav.Item>
                </ul>
              </Nav>
            </div>
          </div>
        </Tab.Container>
      </div>
      <MediumBanner />
      <DribbleBanner />
      <PatreonBanner />
      <DiscordBanner />
      <BehanceBanner />
    </div>
  );
}
