import React, { useState } from 'react';
import EditorDrop from "../global/EditorDrop";
import img3 from "../../images/download3.png";
import { Link } from 'react-router-dom';
import { ReactComponent as IconBlue4s } from "../../images/icons/icon16.svg";
// import { ReactComponent as Plus } from "../../images/icons/plus.svg";
import { ReactComponent as Delete } from "../../images/icons/delete.svg";

function Announcment() {
  const [divs, setDivs] = useState([]);

  const addDiv = () => {
    const newDivs = [...divs, { id: divs.length, content: 'New Div' }];
    setDivs(newDivs);
  };

  const deleteDiv = (idToDelete) => {
    const newDivs = divs.filter((div) => div.id !== idToDelete); 
    setDivs(newDivs);
  };

  return (
    <div>
        {divs.map((div, index) => (
          <div className='newform mt-4 position-relative'>
            <div className="row w-100 mb-4">
              <div className="col-lg-6 d-flex mb-28 nav-item2">
                <label>TITLE</label>
                <div className="w-75 z1">
                  <span className="inputspan">
                    <input className="announcment shadow-5in w-100" type="text" placeholder="BREAKING NEWS" />
                  </span>
                </div>
              </div>
              <div className="col-lg-6 d-flex mb-28 nav-item2">
                <label>BUTTON TEXT</label>
                <div className="w-75 z1">
                  <span className="inputspan">
                    <input className="announcment shadow-5in w-100" type="text" placeholder="FIND OUT HOW" />
                  </span>
                </div>
              </div>
              <div className="col-lg-9 d-flex nav-item4 justify-content-start align-items-center">
                <label>REDIRECT URL</label>
                <div className=" w-75 z1">
                  <span className="inputspan">
                    <input className="announcment shadow-5in w-100" type="text" placeholder="FIND OUT HOW" />
                  </span>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="newannous d-flex justify-content-end align-itmes-center">
                  <h6 className="ml-auto">Redirect- Visitors to Your Announcement Page to Take Actions</h6>
                </div>
              </div>
              <div className="col-lg-12 mt-3 nav-item3">
                <label className="w-360">ANNOUNCEMENT  MESSAGE</label>
                <div className="z1 w-100">
                  <span className="inputspan1 position-relative">
                    <div className="dropdown btnArrow btnArrow2 position-absolute spacedrop z-index-2">
                      <span className="dropArrow" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-display="static">
                        <Link to="#" id="notab" className="d-flex justify-content-center fs-5 fw-bold ps-3 pe-5 align-items-center w-100x text-underline shadow-5in h-40s h8 br-30">
                          <u className="h5">A</u>
                        </Link>
                      </span>
                      <EditorDrop />
                    </div>
                    <textarea className="announcment shadow-5in w-100 ps-5 pt-4" placeholder="Type your Announcement here...."></textarea>
                    <span className="position-absolute top-20 start-16 ">
                      <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4s /></span>
                      <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4s /></span>
                      <span className="rounded-circle shadow-4 d-flex justify-content-center align-items-center w-24 mb-2"><IconBlue4s /></span>

                    </span>
                    <div className="dropdownload">
                      <Link to="#">
                        <img src={img3} alt="" />
                      </Link>
                      <label for="test">
                        <input type="file" id="test" />
                        <span>Choose a local File</span>
                      </label>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <button className='position-absolute bottom-0 end-0 custom-end2 border-0 btn bs-primary' onClick={() => deleteDiv(div.id)}><Delete /></button>
          </div>
        ))}
        <div className='w-100 mt-5 custmToggle'>
              <div className='btmBtn9 d-flex w-100 justify-content-center '>
                <button className='shadow-4 border-0'>Add MORE</button>
                <span>
                  <div className="switch">
                    <div className="slider1 round" onClick={addDiv}></div>
                  </div>
                </span>
              </div>
            </div>
    </div>
  );
}

export default Announcment;
