import React from "react";
import { useState } from "react";
import '../../BlogPostSectionThemes.scss'
import '../regularTheme1Desktop/RegularTheme1Desktop.scss';
import '../regularTheme1Tab/RegularTheme1Tab.scss';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { IoIosArrowForward } from "react-icons/io";
import { FcCheckmark } from "react-icons/fc"
import { RxCross2 } from "react-icons/rx";
import { AiFillStar } from "react-icons/ai"
import { useTranslation } from "react-i18next";
import blogposttab_1 from "../../../../../../../src/images/desktopblogpost/blogtabpost1.png"
import blogpost_2 from "../../../../../../../src/images/desktopblogpost/blogpost-2.png"
import blogposttab_2 from "../../../../../../../src/images/desktopblogpost/tabblogpost2.png"
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { BsHeartFill, BsHeart } from "react-icons/bs";
import { BsChatLeft } from "react-icons/bs";
export default function RegularTheme1Desktop() {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);



  const [Competency1, setCompetency1] = useState(10);

  return (
    <div>

      <div className="mainblogpostcontent p-4">
        <div className="row">
          <div className="position-relative d-flex justify-content-center">
            <div className="blogpost-circle position-absolute">
              <span>
                Dec 11 <br></br> 2022
              </span>
            </div>
          </div>

          <div className="col-md-6 col-6 ps-0 pe-0">

            <div className="blogpost-image position-relative">
              <img src={blogposttab_1} alt=" blogpost_1 " className="img-fluid w-100"></img>
              <div className="blogpost-content">
                <div className="icons d-flex justify-content-end mb-2">
                  <span className="text-white me-2">  <span className="me-1">5</span>   <BsHeart /></span>
                  <span className="text-white">  <span className="me-1">8</span>   <BsChatLeft /></span>
                </div>
                <p className="mb-0 text-end text-white">
                  Lorem ipsum dolor sit amet consecteture
                </p>
              </div>


            </div>

          </div>
          <div className="col-md-6 col-6 bg-blogpost">
            <div className="branding-photoshooting d-flex justify-content-center align-items-center h-100">
              <div>
                <p className="brand-heading">- -Branding / photoshooting</p>
                <p className="mb-0 brandingparagraph">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue mauris augue neque gravida in fermentum et.
                </p>
              </div>
              <div>
                <img src={blogpost_2} className="img-fluid"></img>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-6 bg-blogpost-2 ps-0 pe-0">
            <div className="branding-photoshooting d-flex justify-content-center align-items-center h-100">
              <div>
                <img src={blogpost_2} className="img-fluid blog-postimage2"></img>
              </div>
              <div>
                <p className="brand-heading2 text-end">- -Branding / photoshooting</p>
                <p className="mb-0 brandingparagraph2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue mauris augue neque gravida in fermentum et.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-6 ps-0 pe-0">
            <div className="blogpost-image2 position-relative">
              <img src={blogposttab_2} alt=" blogpost_3" className="img-fluid w-100"></img>
              <div className="blogpost-content2">
                <p className="mb-0 text-start text-white">
                  <div className="icons d-flex justify-content-start mb-2">
                    <span className="text-white me-2">  <span className="me-1">5</span>   <BsHeart /></span>
                    <span className="text-white">  <span className="me-1">8</span>   <BsChatLeft /></span>
                  </div>

                  Lorem ipsum dolor sit amet consecteture
                </p>
              </div>


            </div>
          </div>
          <div className="position-relative d-flex justify-content-center">
            <div className="blogpost-circle2 position-absolute">
              <span>
                Dec 11 <br></br> 2022
              </span>
            </div>
          </div>

        </div>
      </div>





    </div>



  );
}
