import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./EventsTimeline3.scss";

export default function EventsTimeline3() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1366 },
      items: 6,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1366, min: 768 },
      items: 5,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div className="container">
      <div className='event-timeline'>
        <div className="row">
          <div class="col-lg-12">
            <div class="timeline-container">
              <div class="timeline-continue">
                {/* <div class="row"> */}
                <div class="main-timeline w-100 row">
                  <div class="col-6 timeline-left line_left">
                    <div class="timeline">
                      <div class="timeline-content">
                        <div class="d-flex event_detail">
                          <div class="event_day">
                            Monday
                          </div>
                          <div class="event_date">
                            <div>
                              <p><span>01</span>-17 <br /> Dec</p>
                            </div>
                          </div>
                          <div class="event_description">
                            <h2>NUNC SED IACULIS LEO</h2>
                            <h6>OCT (Oct 04, 2021) 10:30 AM- (Oct 17, 2021) 7:30 PM</h6>
                            <p>761 Camden Street. Reno, NV</p>
                            <div class="d-flex align-items-center justify-content-between">
                              <p class="event_theme">Dress code: <strong>Express Yourself</strong></p>
                              <div>
                                <button type="button" class="btn btn-success">Success</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 timeline-right line_right">
                    <div class="timeline">
                      <div class="timeline-content">
                        <div class="d-flex event_detail">
                          <div class="event_day">
                            Tuesday
                          </div>
                          <div class="event_date">
                            <div>
                              <p><span>02</span>-17 <br /> Dec</p>
                            </div>
                          </div>
                          <div class="event_description">
                            <h2>NUNC SED IACULIS LEO</h2>
                            <h6>OCT (Oct 04, 2021) 10:30 AM- (Oct 17, 2021) 7:30 PM</h6>
                            <p>761 Camden Street. Reno, NV</p>
                            <div class="d-flex align-items-center justify-content-between">
                              <p class="event_theme">Dress code: <strong>Express Yourself</strong></p>
                              <div>
                                <button type="button" class="btn btn-success">Success</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="main-timeline w-100 row -mt_100">
                  <div class="col-6 timeline-left line_left">
                    <div class="timeline">
                      <div class="timeline-content">
                        <div class="d-flex event_detail">
                          <div class="event_day">
                            Wednesday
                          </div>
                          <div class="event_date">
                            <div>
                              <p><span>03</span>-17 <br /> Dec</p>
                            </div>
                          </div>
                          <div class="event_description">
                            <h2>NUNC SED IACULIS LEO</h2>
                            <h6>OCT (Oct 04, 2021) 10:30 AM- (Oct 17, 2021) 7:30 PM</h6>
                            <p>761 Camden Street. Reno, NV</p>
                            <div class="d-flex align-items-center justify-content-between">
                              <p class="event_theme">Dress code: <strong>Express Yourself</strong></p>
                              <div>
                                <button type="button" class="btn btn-success">Success</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 timeline-right line_right">
                    <div class="timeline">
                      <div class="timeline-content">
                        <div class="d-flex event_detail">
                          <div class="event_day">
                            Thursday
                          </div>
                          <div class="event_date">
                            <div>
                              <p><span>04</span>-17 <br /> Dec</p>
                            </div>
                          </div>
                          <div class="event_description">
                            <h2>NUNC SED IACULIS LEO</h2>
                            <h6>OCT (Oct 04, 2021) 10:30 AM- (Oct 17, 2021) 7:30 PM</h6>
                            <p>761 Camden Street. Reno, NV</p>
                            <div class="d-flex align-items-center justify-content-between">
                              <p class="event_theme">Dress code: <strong>Express Yourself</strong></p>
                              <div>
                                <button type="button" class="btn btn-success">Success</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="main-timeline w-100 row -mt_100">
                  <div class="col-6 timeline-left line_left">
                    <div class="timeline">
                      <div class="timeline-content">
                        <div class="d-flex event_detail">
                          <div class="event_day">
                            Friday
                          </div>
                          <div class="event_date">
                            <div>
                              <p><span>05</span>-17 <br /> Dec</p>
                            </div>
                          </div>
                          <div class="event_description">
                            <h2>NUNC SED IACULIS LEO</h2>
                            <h6>OCT (Oct 04, 2021) 10:30 AM- (Oct 17, 2021) 7:30 PM</h6>
                            <p>761 Camden Street. Reno, NV</p>
                            <div class="d-flex align-items-center justify-content-between">
                              <p class="event_theme">Dress code: <strong>Express Yourself</strong></p>
                              <div>
                                <button type="button" class="btn btn-success">Success</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 timeline-right line_right last_timeline">
                    <div class="timeline">
                      <div class="timeline-content">
                        <div class="d-flex event_detail">
                          <div class="event_day">
                            Saturday
                          </div>
                          <div class="event_date">
                            <div>
                              <p><span>06</span>-17 <br /> Dec</p>
                            </div>
                          </div>
                          <div class="event_description">
                            <h2>NUNC SED IACULIS LEO</h2>
                            <h6>OCT (Oct 04, 2021) 10:30 AM- (Oct 17, 2021) 7:30 PM</h6>
                            <p>761 Camden Street. Reno, NV</p>
                            <div class="d-flex align-items-center justify-content-between">
                              <p class="event_theme">Dress code: <strong>Express Yourself</strong></p>
                              <div>
                                <button type="button" class="btn btn-success">Success</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}