import React, {useEffect} from 'react';
import ButtonBox from '../global/ButtonBox';
import Upload from '../../images/upload.png';
import '../login/login.scss';
import { useState, useMemo  } from 'react';
import SocialBox from '../global/SocialBox';
import {AgeRange, Salaries} from '../global/rangeSelect/RangeValues';
import RangeSelect from '../global/rangeSelect/RangeSelect';
import {useDispatch, useSelector} from "react-redux";
import { SignupAction } from '../../redux/actions/authActions';
import { toast } from 'react-toastify';
import Select from 'react-select';
import countryList from 'react-select-country-list'
import { useTranslation } from "react-i18next";

import { NavItem } from 'react-bootstrap';

import { ReactComponent as IconBlue1 } from "../../images/svgIcon/icon3.svg";
import { ReactComponent as IconBlue2 } from "../../images/svgIcon/icon4.svg";
import { ReactComponent as IconBlue3 } from "../../images/svgIcon/icon5.svg";
import { ReactComponent as IconBlue4 } from "../../images/svgIcon/icon6.svg";
import { ReactComponent as IconBlue5 } from "../../images/svgIcon/icon7.svg";
import DropdownBox from '../global/DropdownBox';

export default function SignUp() {
    const { t, i18n } = useTranslation();
 const options = useMemo(() => countryList().getData(), [])
let {user} = useSelector(state => state.authReducer);
useEffect(() => {
}, [user]); 
 
    const dispatch = useDispatch();
    const inputRef = React.useRef();

    // states start
    const [image, setImage] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [country, setCountry] = useState('');
    const [gender, setGender] = useState('');
    const [termCondition, setTermCondition] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [close, setClose] = useState(false);
    const [selectedRange, setSelectedRange] = useState(AgeRange[Math.floor(AgeRange.length/2)])
    const [selectedSalary, setSelectedSalary] = useState(Salaries[Math.floor(Salaries.length/2)])
// states ends

// function starts

function validateFields(str) {
    return /^[A-Za-z\s]*$/.test(str);
  }

  const validateEmail = (email)  => {
         var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         return re.test(String(email).toLowerCase());
     }

 const changeHandler = value => {
    setCountry(value)
  }
    const openSelect = () =>{
        inputRef.current.click();
    }
    const getGenderName = (e) =>{
        setGender(e.target.value);
     }
    const handleSubmit = async (e) =>{
        e.preventDefault();
        if(!image){
            toast('Image is required');
          }
          if (!firstName){
            toast('First Name is required');
          }
            else if (!validateFields(firstName)){

            toast('Do not use special characters and numbers in first name field');
         
          }
         else if(firstName.length > 50){
            toast('Please use max 50 characters in first name field');
        }
       
           else if (!lastName){
            toast('Last Name is required');
          }
          else if (!validateFields(lastName)){

            toast('Do not use special characters and numbers in last name field');
         
          }
         else if(lastName.length > 50){
            toast('Please use max 50 characters in last name field');
        }
          else if (!email){
            toast('Email is required');
          }
          else if (!validateEmail(email)) {
            toast('Please Enter a Valid Email Address')
        }
          else if (!userName){
            toast('User Name is required');
          }
          else if (!validateFields(userName)){

            toast('Do not use special characters and numbers in first name field');
         
          }
         else if(userName.length > 50){
            toast('Please use max 50 characters in user name field');
        }
       
          else if (!password){
            toast('Password is required');
          }
          else if (!country){
            toast('Country is required');
          }
          else if (!gender){
            toast('Gender is required');
          }
          else if (!termCondition && termCondition === false){
            toast('Terms & Conditions is required');
          }
          else if (!rememberMe){
            toast('Remember Me  is required');
         }
          else{
            try { 
                let ageRange = selectedRange.start + " to " + selectedRange.end;
                let incomeRange = selectedSalary.start + " to " + selectedSalary.end;
               
         
                 let data = new FormData()
                 data.append("image", image)
                 data.append("first_name", firstName)
                 data.append("last_name", lastName)
                 data.append("email", email)
                 data.append("user_name", userName)
                 data.append("password", password)
                 data.append("age_range", ageRange)
                 data.append("country", country.label)
                 data.append("gender", gender)
                 data.append("income_range", incomeRange)
                 data.append("term_condition", termCondition)
                 data.append("remember_me", rememberMe)
                 dispatch(SignupAction(data, setImage,setPreviewImage,setFirstName,setLastName,setEmail,setUserName,setPassword,setCountry,setGender,setTermCondition,setRememberMe));
                setClose(true);
               } catch (error) {
                 console.log("err", error);
               }
             }
          }
          const theme = theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#EFF4F8",
              primary: "#EFF4F8",
            }
          });
   
    // function ends


    const countryOptions =
    [{
        "name": "AFGHANISTAN",
        
        
      }, {
        "name": "ALAND ISLANDS",
        
        
      }, {
        "name": "ALBANIA",
        
        
      }, {
        "name": "ALGERIA",
        
      
      }, {
        "name": "AMERICAN SAMOA",
        
        
      }, {
        "name": "ANDORRA",
        
        
      }, {
        "name": "ANGOLA",
        
       
      }, {
        "name": "ANGUILLA",
        
         
      }, {
        "name": "ANTARCTICA",
        
        
      }, {
        "name": "ANTIGUA AND BARBUDA",
        
       
      }, {
        "name": "ARGENTINA",
        
       
      }, {
        "name": "ARMENIA",
        
        
      }, {
        "name": "ARUBA",
        
        
      }, {
        "name": "AUSTRALIA",
        
        
      }, {
        "name": "AUSTRIA",
        
        
      }, {
        "name": "AZERBAIJAN",
        
         
      }, {
        "name": "BAHAMAS",
        
         
      }, {
        "name": "BAHRAIN",
        
         
      }, {
        "name": "BANGLADESH",
        
        
      }, {
        "name": "BARBADOS",
        
         
      }, {
        "name": "BELARUS",
        
         
      }, {
        "name": "BELGIUM",
        
        
      }, {
        "name": "BELIZE",
        
         
      }, {
        "name": "BENIN",
        
         
        
      }, {
        "name": "BERMUDA",
        
         
      }, {
        "name": "BHUTAN",
        
         
        
      }, {
        "name": "BHUTAN",
        
        
      }, {
        "name": "BOLIVIA",
        
        
      }, {
        "name": "BONAIRE, SINT EUSTATIUS AND SABA",
        
         
      }, {
        "name": "BOSNIA and HERZEGOVINA",
        
         
      }, {
        "name": "BOTSWANA",
        
        
      }, {
        "name": "BOUVET ISLAND",
        
        
      }, {
        "name": "BRAZIL",
        
         
      }, {
        "name": "BRAZIL",
        
        
      }, {
        "name": "BRITISH INDIAN OCEAN TERRITORY",
        
         
      }, {
        "name": "BRUNEI DARUSSALAM",
        
         
      }, {
        "name": "BULGARIA",
        
         
      }, {
        "name": "BURKINA FASO",
        
         
        
      }, {
        "name": "BURUNDI",
        
         
      }, {
        "name": "CAMBODIA",
        
        
      }, {
        "name": "CAMEROON",
        
         
      }, {
        "name": "CANADA",
        
         
      }, {
        "name": "CAPE VERDE",
        
        
      }, {
        "name": "CAYMAN ISLANDS",
        
         
      }, {
        "name": "CENTRAL AFRICAN REPUBLIC",
        
         
      }, {
        "name": "CHAD",
        
         
      }, {
        "name": "CHILE",
        
         
      }, {
        "name": "CHINA",
        
         
      }, {
        "name": "CHINA",
        
        
      }, {
        "name": "CHRISTMAS ISLAND",
        
         
      }, {
        "name": "COCOS (KEELING) ISLANDS",
        
         
      }, {
        "name": "COLOMBIA",
        
         
      }, {
        "name": "COMOROS",
        
         
      }, {
        "name": "CONGO",
        
         
      }, {
        "name": "COOK ISLANDS",
        
         
      }, {
        "name": "COSTA RICA",
        
        
      }, {
        "name": "COTE D’IVOIRE (IVORY COAST)",
        
         
        
      }, {
        "name": "CROATIA",
        
       
      }, {
        "name": "CURACAO",
        
        
      }, {
        "name": "CYPRUS",
        
        
      }, {
        "name": "CZECH REPUBLIC",
        
         
      }, {
        "name": "DEMOCRATIC REPUBLIC OF THE CONGO",
        
        
      }, {
        "name": "DEN",
        
        
      }, {
        "name": "DJIBOUTI",
        
         
      }, {
        "name": "DOMINICA",
        
         
      }, {
        "name": "DOMINICAN REPUBLIC",
        
         
      }, {
        "name": "EGYPT",
        
         
      }, {
        "name": "EL SALVADOR",
        
         
      }, {
        "name": "ECUADOR",
        
         
      }, {
        "name": "EQUATORIAL GUINEA",
        
         
      }, {
        "name": "ERITREA",
        
         
      }, {
        "name": "ERITREA",
        
         
      }, {
        "name": "ESTONIA",
        
        
      }, {
        "name": "ETHIOPIA",
        
         
      }, {
        "name": "FALKLAND ISLANDS (MALVINAS)",
        
         
      }, {
        "name": "FEDERATED STATES OF MICRONESIA",
        
        
      }, {
        "name": "FIJI",
        
         
      }, {
        "name": "FINLAND",
        
        
      }, {
        "name": "FRANCE",
        
        
      }, {
        "name": "FRANCE, METROPOLITAN",
        
        
      }, {
        "name": "FRENCH GUIANA",
        
        
      }, {
        "name": "FRENCH POLYNESIA",
        
         
      }, {
        "name": "GABON",
        
         
      }, {
        "name": "GAMBIA",
        
        
      }, {
        "name": "GEORGIA",
        
        
      }, {
        "name": "GERMANY",
        
        
      }, {
        "name": "GHANA",
        
        
      }, {
        "name": "GIBRALTAR",
        
         
      }, {
        "name": "GREECE",
        
        
      }, {
        "name": "GREENLAND",
        
        
      }, {
        "name": "GRENADA",
        
         
      }, {
        "name": "GUADELOUPE",
        
        
      }, {
        "name": "GUAM",
        
         
      }, {
        "name": "GUATEMALA",
        
        
      }, {
        "name": "GUINEA-BISSAU",
        
         
        
      }, {
        "name": "GUINEA",
        
         
      }, {
        "name": "GUYANA",
        
         
      }, {
        "name": "HAITI",
        
        
      }, {
        "name": "HEARD and MCDONALD ISLANDS",
        
         
      }, {
        "name": "HOLY SEE (VATICAN CITY STATE)",
       
      }, {
        "name": "HONDURUS",
        
        
      }, {
        "name": "HONG KONG",
        
         
      }, {
        "name": "HUNGARY",
        
        
      }, {
        "name": "ICELAND",
        
        
      }, {
        "name": "INDIA",
        
        
      }, {
        "name": "INDONESIA",
        
        
      }, {
        "name": "IRAQ",
        
         
      }, {
        "name": "ISRAEL",
        
         
      }, {
        "name": "IRELAND",
        
        
      }, {
        "name": "ISLE OF MAN",
        
         
      }, {
        "name": "ITALY",
        
        
      }, {
        "name": "JAMAICA",
        
         
      }, {
        "name": "JAPAN",
        
        
      }, {
        "name": "JERSEY",
        
         
      }, {
        "name": "JORDAN",
        
         
      }, {
        "name": "KAZAKHSTAN",
        
        
      }, {
        "name": "KENYA",
        
         
      }, {
        "name": "KIRIBATI",
        
         
      }, {
        "name": "KOREA, REPUBLIC OF",
        
        
      }, {
        "name": "KUWAIT",
        
         
      }, {
        "name": "KYRGYZSTAN",
        
        
      }, {
        "name": "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
        
        
      }, {
        "name": "LATVIA",
        
        
      }, {
        "name": "LEBANON",
        
         
      }, {
        "name": "LESOTHO",
        
        
      }, {
        "name": "LESOTHO",
        
        
      }, {
        "name": "LIBERIA",
        
         
      }, {
        "name": "LIBYAN ARAB JAMAHIRIYA",
        
         
      }, {
        "name": "LIECHTENSTEIN",
        
         
      }, {
        "name": "LITHUANIA",
        
        
      }, {
        "name": "LUXEMBOURG",
        
        
      }, {
        "name": "MACAO",
        
        
      }, {
        "name": "MACEDONIA",
        
        
      }, {
        "name": "MADAGASCAR",
        
         
      }, {
        "name": "MALAWI",
        
         
      }, {
        "name": "MALAYSIA",
        
         
      }, {
        "name": "MALDIVES",
        
        
      }, {
        "name": "MALI",
        
         
        
      }, {
        "name": "MALTA",
        
        
      }, {
        "name": "MARSHALL ISLANDS",
        
         
      }, {
        "name": "MARTINIQUE",
        
        
      }, {
        "name": "MAURITANIA",
        
        
      }, {
        "name": "MAURITIUS",
        
        
      }, {
        "name": "MAYOTTE",
        
        
      }, {
        "name": "MEXICO",
        
         
      }, {
        "name": "MOLDOVA, REPUBLIC OF",
        
        
      }, {
        "name": "MONACO",
        
        
      }, {
        "name": "MONGOLIA",
        
        
      }, {
        "name": "MONTENEGRO, REPUBLIC OF",
        
        
      }, {
        "name": "MONTSERRAT",
        
         
      }, {
        "name": "MOROCCO",
        
        
      }, {
        "name": "MOZAMBIQUE",
        
         
      }, {
        "name": "MYANMAR",
        
         
      }, {
        "name": "NAMIBIA",
        
         
      }, {
        "name": "NAURU",
        
         
      }, {
        "name": "NEPAL",
        
        
      }, {
        "name": "NETHERLANDS",
        
        
      }, {
        "name": "NETHERLANDS ANTILLES",
        
         
      }, {
        "name": "NEW CALEDONIA",
        
         
      }, {
        "name": "NEW ZEALAND",
        
         
      }, {
        "name": "NICARAGUA",
        
         
      }, {
        "name": "NIGER",
        
         
        
      }, {
        "name": "NIGERIA",
        
        
      }, {
        "name": "NIUE",
        
         
      }, {
        "name": "NORFOLK ISLAND",
        
         
      }, {
        "name": "NORTHERN MARIANA ISLANDS",
        
         
      }, {
        "name": "NORWAY",
        
        
      }, {
        "name": "OMAN",
        
         
      }, {
        "name": "PAKISTAN",
        
        
      }, {
        "name": "PALAU",
        
         
      }, {
        "name": "PANAMA",
        
        
      }, {
        "name": "PAPUA NEW GUINEA",
        
        
      }, {
        "name": "PARAGUAY",
        
        
      }, {
        "name": "PERU",
        
         
      }, {
        "name": "PHILIPPINES",
        
         
      }, {
        "name": "PITCAIRN",
        
         
      }, {
        "name": "POLAND",
        
        
      }, {
        "name": "PORTUGAL",
        
        
      }, {
        "name": "PUERTO RICO",
        
         
      }, {
        "name": "QATAR",
        
        
      }, {
        "name": "REUNION",
        
        
      }, {
        "name": "ROMANIA",
        
        
      }, {
        "name": "RUSSIAN FEDERATION",
        
         
      }, {
        "name": "RWANDA",
        
         
      }, {
        "name": "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
        
       
      }, {
        "name": "SAMOA",
        
        
      }, {
        "name": "SAN MARINO",
        
        
      }, {
        "name": "SAO TOME AND PRINCIPE",
        
        
      }, {
        "name": "SAUDI ARABIA",
        
        
      }, {
        "name": "SENEGAL",
        
         
        
      }, {
        "name": "SERBIA",
        
         
      }, {
        "name": "SEYCHELLES",
        
        
      }, {
        "name": "SIERRA LEONE",
        
        
      }, {
        "name": "SINGAPORE",
        
         
      }, {
        "name": "SINT MAARTEN (DUTCH PART)",
        
        
      }, {
        "name": "SLOVAKIA",
        
        
      }, {
        "name": "SLOVENIA",
        
        
      }, {
        "name": "SOLOMON ISLANDS",
        
         
      }, {
        "name": "SOMALIA",
        
         
      }, {
        "name": "SOUTH AFRICA",
        
        
      }, {
        "name": "SOUTH SUDAN",
        
         
      }, {
        "name": "SPAIN",
        
        
      }, {
        "name": "SRI LANKA",
        
        
      }, {
        "name": "ST. KITTS-NEVIS",
        
         
      }, {
        "name": "ST. LUCIA",
        
         
      }, {
        "name": "ST. PIERRE AND MIQUELON",
        
        
      }, {
        "name": "ST. VINCENT AND THE GRENADINES",
        
         
      }, {
        "name": "SURINAME",
        
         
      }, {
        "name": "SVALBARD AND JAN MAYEN",
        
        
      }, {
        "name": "SWITZERLAND",
        
         
      }, {
        "name": "SWAZILAND",
        
        
      }, {
        "name": "SWEDEN",
        
         
      }, {
        "name": "TAIWAN",
        
         
      }, {
        "name": "TAJIKISTAN",
        
        
      }, {
        "name": "TANZANIA, UNITED REPUBLIC OF",
        
        
      }, {
        "name": "THAILAND",
        
        
      }, {
        "name": "TIMOR-LESTE",
        
         
      }, {
        "name": "TOGO",
        
         
        
      }, {
        "name": "TOKELAU",
        
         
      }, {
        "name": "TONGA",
        
        
      }, {
        "name": "TRINIDAD AND TOBAGO",
        
      }, {
        "name": "TUNISIA",
        
         
      }, {
        "name": "TURKEY",
        
        
      }, {
        "name": "TURKMENISTAN",
        
        
      }, {
        "name": "TURKS and CAICOS ISLANDS",
        
         
      }, {
        "name": "TUVALU",
        
         
      }, {
        "name": "UGANDA",
        
         
      }, {
        "name": "UKRAINE",
        
         
      }, {
        "name": "UNITED ARAB EMIRATES",
        
        
      }, {
        "name": "UNITED KINGDOM",
        
         
      }, {
        "name": "UNITED STATES",
        
         
      }, {
        "name": "URUGUAY",
        
         
      }, {
        "name": "UZBEKISTAN",
        
         
      }, {
        "name": "VANUATU",
        
        
      }, {
        "name": "VENEZUELA",
        
         
      }, {
        "name": "VIETNAM",
        
        
      }, {
        "name": "BRITISH VIRGIN ISLANDS",
        
         
      }, {
        "name": "U.S. VIRGIN ISLANDS",
        
         
      }, {
        "name": "WALLIS AND FUTUNA ISLANDS",
        
         
      }, {
        "name": "WESTERN SAHARA",
        
        
      }, {
        "name": "YEMEN",
        
        
      }, {
        "name": "ZAMBIA",
        
         
      }, {
        "name": "ZIMBABWE",
        
         
      }, {
        "name": "CUBA",
        
         
      }, {
        "name": "ECUADOR",
        
        
      }, {
        "name": "IRAN",
        
        
      }, {
        "name": "LATVIA",
        
         
      }, {
        "name": "LITHUANIA",
        
         
      }, {
        "name": "GUINEA-BISSAU",
        
         
      }, {
        "name": "SYRIA",
        
         
      }, {
        "name": "TONGA",
        
        
      }] 
return (
        <div className='text-center'>
            <form onSubmit={handleSubmit} >
            <div className='uploadImage mx-auto mb-4'>
                <label onClick={openSelect}>
                    <div className="cursor-pointer w-full h-full bg-no-repeat bg-cover bg-center">
                   {previewImage ? <img src={previewImage} alt="Profile Picture"  /> : <img src={Upload} alt="Profile Picture"  /> }
                    </div>
                </label>
            </div>
                <input hidden type="file" name="profile_pic" id="profile_pic" accept="image/*" onChange={(e) => { 
                    let url=URL.createObjectURL(e.target.files[0])
                    setPreviewImage(url);
                     setImage(e.target.files[0])}} ref={inputRef} />
            <h3 className='mb-4'>{t('signup')}</h3>
            <ul className='pb-5'>
                <NavItem as="li" className='mb-4'>
                    <input placeholder={t('first_name')}   value={firstName} className="shadow-5in br-10 w-500"  type="text" onChange={(e)=> {setFirstName(e.target.value);}} />
                </NavItem>
                <NavItem as="li" className='mb-4'>
                    <input placeholder={t('last_name')}  className="shadow-5in br-10 w-500" type="text"  value={lastName} onChange={(e)=> {setLastName(e.target.value);}} />
                </NavItem>
                <NavItem as="li" className='mb-4'>
                    <input placeholder={t('username')}  className="shadow-5in br-10 w-500" type="text"  value={userName} onChange={(e)=> {setUserName(e.target.value);}} />
                </NavItem>
                <NavItem as="li" className='mb-4'>
                    <input placeholder={t('email')}  className="shadow-5in br-10 w-500" type="text"  value={email} onChange={(e)=> {setEmail(e.target.value);}} />
                </NavItem>
                <NavItem as="li" className='mb-4'>
                    <input placeholder={t('password')}  className="shadow-5in br-10 w-500" type="password"   value={password} onChange={(e)=> {setPassword(e.target.value);}} />
                </NavItem>
                <NavItem as="li" className='mb-4'>
                    <div>
                        <p className="text-center neu_range_select__title mt-5 mb-3">{t('signup_component.select_your_age_range')}</p>
                        <RangeSelect data={AgeRange} type='ages' selectedRange={selectedRange} setSelectedRange={setSelectedRange} value={selectedRange} />
                    </div>
                </NavItem>
                <NavItem as="li" className='mb-4 bgArrow1 position-relative'>
                    {/* <input placeholder="Country"  className="shadow-5in br-10 w-500" type="text" value={country} onChange={(e)=> {setCountry(e.target.value);}} /> */}
                    {/* <Select isSearchable={false} options={options} className="country w-500 mx-auto" placeholder={t('country')} value={country} onChange={changeHandler} theme={theme}
                    styles={{
                        option: (base) => ({
                          ...base,
                          borderRadius: '30px',
                          width:'94%',
                          height:'50px',
                          lineHeight: '32px',
                          margin: '10px',
                          cursor: 'pointer',
                          color:'#000000',
                          boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                        }),
                      }} /> */}
                      <DropdownBox name="Country" boxid="country" options={countryOptions} boxwidth="w-500 mx-auto"/>
                </NavItem>
                <NavItem as="li" className='mb-4 px-5'>
                    <div className='w-400 mx-auto'>
                        <div className='shadow-4 h-40 br-30 w-100'>
                            {t('signup_component.what_is_your_gender')}
                        </div>
                        <label className='mt-4 d-flex justify-content-between' onChange={getGenderName}>
                            
                            <div>
                                <div className='gander-button mb-2 d-flex justify-content-center align-items-center'>
                                    <input type="radio" className="btn-check" name="gender" id="Male"  value="Male" />
                                    <label className="btn btn-outline-primary" htmlFor="Male">
                                        <IconBlue2 />
                                    </label>
                                </div>
                                <small>{t('signup_component.male')}</small>
                            </div>
                            <div>
                                <div className='gander-button mb-2 d-flex justify-content-center align-items-center'>
                                    <input type="radio" className="btn-check" name="gender" id="Female"  value="Female" />
                                    <label className="btn btn-outline-primary" htmlFor="Female">
                                        <IconBlue3 />
                                    </label>
                                </div>
                                <small>{t('signup_component.female')}</small>
                            </div>
                            <div>
                                <div className='gander-button mb-2 d-flex justify-content-center align-items-center'>
                                    <input type="radio" className="btn-check" name="gender" id="Non-Binary"   value="Non-Binary" />
                                    <label className="btn btn-outline-primary" htmlFor="Non-Binary">
                                        <IconBlue4 />
                                    </label>
                                </div>
                                <small>{t('signup_component.non_binary')}</small>
                            </div>
                            <div>
                                <div className='gander-button mb-2 d-flex justify-content-center align-items-center'>
                                    <input type="radio" className="btn-check" name="gender" id="Non-Conforming"  value="Non-Conforming" />
                                    <label className="btn btn-outline-primary" htmlFor="Non-Conforming">
                                        <IconBlue5 />
                                    </label>
                                </div>
                                <small>{t('signup_component.non_conforming')}</small>
                            </div>
                         
                        </label>
                    </div>
                </NavItem>
                <NavItem as="li" className='mb-4'>
                    <div className="">
                        <p className="text-center neu_range_select__title mt-5 mb-3">{t('signup_component.yearly_income_range_in_US_currency')}</p>
                        <RangeSelect data={Salaries} type='salaries' selectedRange={selectedSalary} setSelectedRange={setSelectedSalary} value={selectedSalary} />
                    </div>
                </NavItem>
                <NavItem as="li" className='mb-3'>
                    <div className='check-button w-500 mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="termCondition1" id="termCondition1"  value={termCondition}  onChange={(e)=> {setTermCondition(!termCondition);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="termCondition1"><div className="radioInner"><span></span></div></label>
                        {t('agree_statement')}
                    </div>
                </NavItem>
                <NavItem as="li" className='mb-5'>
                    <div className='check-button w-500 mx-auto mb-2 d-flex justify-content-start align-items-center'>
                        <input type="checkbox" className="btn-check" name="remember1" id="remember1"  value={rememberMe} onChange={(e)=> {setRememberMe(!rememberMe);}} />
                        <label className="btn rounded-circle btnRadio large me-3" htmlFor="remember1"><div className="radioInner"><span></span></div></label>
                        {t('remember_me')}
                    </div> 
                </NavItem>
                <NavItem as="li">
                    <ButtonBox boxwidth="w-185 mt-0 mb-50" clicked="account created" name="create account" close={close} />
                </NavItem>
                <NavItem as="li">
                    <h6>{t('signup_component.already_have_an_account')}</h6>
                    <div  className="nav justify-content-center" id="myTab" role="tablist">
                    <div className="btn-bg bg d-inline-block position-relative w-110 mt-3 mb-50">
                        {t('signed')}
                        <button className="border-0 bg-transparent w-100 h-100" id="signin" data-bs-toggle="tab" data-bs-target="#signin" type="button" role="tab" aria-controls="signin" aria-selected="true">
                        {t('sign_in')}
                        </button>
                    </div>
                    </div>
                </NavItem>
                <NavItem as="li" className='w-100 text-center'>
                    <div className='dividerText'>{t('or')}</div>
                </NavItem>
                <NavItem as="li">
                    <SocialBox />
                </NavItem>
            </ul>
            </form>
        </div>
    );
}