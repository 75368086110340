import React from 'react'
import { useState } from 'react';
import arrowicon from "../../../../../../images/triangle1.png";
import dot from "../../../../../../images/dropdown/img13.png";

export default function TrackingComponentSec(props) {

  const handleClick1 = () => {
    setToggle1(!toggle1);
  };
  const [toggle1, setToggle1] = useState("true");

  return (
    <div className='dropNew21'>
    <li onClick={handleClick1}>
      <div className="spansec">
        <div className="dropdiv d-flex align-items-center" >
          <h6 className='m-0'>{props.Name}</h6>
        </div>
      </div>
      <div className='position-relative z-index-1 w-125 d-flex justify-content-center'>
        <span className="dropicn">
          <span>
            <img src={props.icon} alt='' className='w-auto' height="14px" />
          </span>
        </span>
      </div>
      <div className='bgLine7 w-100'>
        <h6>{props.Dropname} <img src={arrowicon} alt="" className='ms-auto' /></h6>
      </div>
    </li>
      {toggle1 ? (
        <></>
      ) : (
        <div className='d-flex justify-content-between align-items-center w-100 bgLine8 ps-1 bglinearrow1'>
          <img src={dot} className='dot' alt="" />
          <input type="text" placeholder='G-XXXXXXXXXX or UA-XXXXXXXXXXX-X' />
        </div>
      )}
    </div>
  )
}
