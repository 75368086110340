import React from "react";
import ToggleButton from "../../../../../global/ToggleButton";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function ProfileGroupTwo() {
  const { t, i18n } = useTranslation();

  return (
    <div className="shadow-5in w-100 p-40 br-30 mb-4">
      <h3 className="mb-5 text-uppercase">{t('profile_section.preferred_days_of_communication')}</h3>
      <ul className="row">
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Mondays" id="Mondays" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Tuesdays" id="Tuesdays" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Wednesdays" id="Wednesdays" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Thursdays" id="Thursdays" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Fridays" id="Fridays" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Saturdays" id="Saturdays" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Sundays" id="Sundays" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Other (Ex: Mondays & Thursdays)" id="Other (Ex: Mondays & Thursdays)" check="checked" />
        </NavItem>
        <NavItem as="li" className="col-lg-4 col-md-6 col-12">
          <ToggleButton name="Profile Availability Status" id="Profile Availability Status" check="checked" />
        </NavItem>
      </ul>
    </div>
  );
}
