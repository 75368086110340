import React from "react";
// import { useState } from "react";
// import { useTranslation } from "react-i18next";
import './DigitalBusinessCardStep.scss';
import { NavItem } from 'react-bootstrap';
import DigitalBusinessPrimary from './DigitalBusinessPrimary';
import DigitalBusinessSocial1 from './DigitalBusinessSocial1';
import DigitalBusinessSocial2 from './DigitalBusinessSocial2';
import DigitalBusinessSettings from './DigitalBusinessSettings';
import DigitalBusinessGenerate from "./DigitalBusinessGenerate";

export default function DigitalBusinessCardForm() {

    return (
        <div>
            <ul className="TAB-10-form">
                <NavItem as="li" className="my-3 pb-4">
                    <div className="d-flex flex-column-reverse">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane listOne fade show active" id="primarybusiness" role="tabpanel" aria-labelledby="primarybusiness">
                                <DigitalBusinessPrimary />
                            </div>
                            <div className="tab-pane listTwo fade" id="socialbusiness" role="tabpanel" aria-labelledby="socialbusiness">
                            <DigitalBusinessSocial1 />
                            </div>
                            <div className="tab-pane listTwo fade" id="featurebusiness" role="tabpanel" aria-labelledby="featurebusiness">
                            <DigitalBusinessSocial2 />
                            </div>
                            <div className="tab-pane listTwo fade" id="settingbusiness" role="tabpanel" aria-labelledby="settingbusiness">
                                <DigitalBusinessSettings />
                            </div>
                            <div className="tab-pane listTwo fade" id="genratebusiness" role="tabpanel" aria-labelledby="genratebusiness">
                                <DigitalBusinessGenerate />
                            </div>
                        </div>
                        <span className="d-flex align-items-center justify-content-between w-100 mb-0 TabBtns">
                            <div id="myTab1" className='w-100' role="tablist">
                                <ul className="d-flex justify-content-center my-2 pb-2">
                                    <NavItem as="li" className="me-0">
                                        <button className="listOne h7 m-0 firsttab border-0 active" id="primarybusiness" data-bs-toggle="tab" data-bs-target="#primarybusiness" type="button" role="tab" aria-controls="primarybusiness" aria-selected="true">
                                            Business | PRIMARY <br/> CONTACT
                                        </button>
                                    </NavItem>
                                    <NavItem as="li" className="ms-4">
                                        <button className="listTwo h7 m-0 border-0 sectab " id="socialbusiness" data-bs-toggle="tab" data-bs-target="#socialbusiness" type="button" role="tab" aria-controls="socialbusiness" aria-selected="false">
                                            SOCIAL MEDIA
                                        </button>
                                    </NavItem>
                                    <NavItem as="li" className="ms-4">
                                        <button className="listTwo h7 m-0 border-0 thrdtab" id="featurebusiness" data-bs-toggle="tab" data-bs-target="#featurebusiness" type="button" role="tab" aria-controls="featurebusiness" aria-selected="false">
                                            FEATURED CONTENT
                                        </button>
                                    </NavItem>
                                    <NavItem as="li" className="ms-4">
                                        <button className="listTwo h7 m-0 border-0 thrdtab" id="settingbusiness" data-bs-toggle="tab" data-bs-target="#settingbusiness" type="button" role="tab" aria-controls="settingbusiness" aria-selected="false">
                                            SETTINGS
                                        </button>
                                    </NavItem>
                                    <NavItem as="li" className="ms-4">
                                        <button className="listTwo h7 m-0 border-0 thrdtab" id="genratebusiness" data-bs-toggle="tab" data-bs-target="#genratebusiness" type="button" role="tab" aria-controls="genratebusiness" aria-selected="false">
                                            GENERATE | PREVIEW
                                        </button>
                                    </NavItem>
                                </ul>
                            </div>
                        </span>
                    </div>
                </NavItem>
            </ul>
        </div >
    );
}
