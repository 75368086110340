import * as React from 'react';
import WeekendModeSectionSlider from './WeekendModeSectionSlider';
import SocialModeSectionThemes from './SocialModeSectionThemes';
import VacationModeSectionThemes from './VacationModeSectionThemes';
import UnderConstructionSectionThemes from './UnderConstructionSectionThemes';
import UnderMaintenanceSectionThemes from './UnderMaintenanceSectionThemes';
import ComingSoonSectionThemes from './ComingSoonSectionThemes';
import Section400Themes from './Section400Themes';
import Section401Themes from './Section401Themes';
import Section402Themes from './Section402Themes';
import Section403Themes from './Section403Themes';
import Section404Themes from './Section404Themes';
import Section500Themes from './Section500Themes';
import Section501Themes from './Section501Themes';
import Section503Themes from './Section503Themes';
import Section504Themes from './Section504Themes';



export default function WeekendModeSectionTab() {

    return (
        <div className="w-100 d-flex flex-column-reverse">
            <div className='w-100'>
                
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="WeekendMode1" role="tabpanel" aria-labelledby="WeekendMode1">
                        <SocialModeSectionThemes />
                    </div>
                    <div className="tab-pane fade" id="WeekendMode2" role="tabpanel" aria-labelledby="WeekendMode2">
                    <VacationModeSectionThemes />
                    </div>
                    <div className="tab-pane detailTab fade" id="WeekendMode3" role="tabpanel" aria-labelledby="WeekendMode3">
                   
                    </div>
                    <div className="tab-pane detailTab fade" id="WeekendMode4" role="tabpanel" aria-labelledby="WeekendMode4">
                        
                    </div>
                    <div className="tab-pane detailTab fade" id="WeekendMode5" role="tabpanel" aria-labelledby="WeekendMode5">
                    <UnderConstructionSectionThemes />
                    </div>
                    <div className="tab-pane fade" id="WeekendMode6" role="tabpanel" aria-labelledby="WeekendMode6">
                    <UnderMaintenanceSectionThemes />
                    </div>   
                    <div className="tab-pane fade" id="WeekendMode7" role="tabpanel" aria-labelledby="WeekendMode7">
                    <ComingSoonSectionThemes />
                    </div>             
                    <div className="tab-pane fade" id="WeekendMode8" role="tabpanel" aria-labelledby="WeekendMode8">
                    <Section400Themes />
                    </div>   
                    <div className="tab-pane fade" id="WeekendMode9" role="tabpanel" aria-labelledby="WeekendMode9">
                    <Section401Themes />
                    </div>   
                    <div className="tab-pane fade" id="WeekendMode10" role="tabpanel" aria-labelledby="WeekendMode10">
                    <Section402Themes />
                    </div>  
                    <div className="tab-pane fade" id="WeekendMode11" role="tabpanel" aria-labelledby="WeekendMode11">
                    <Section403Themes />
                    </div>  
                    <div className="tab-pane fade" id="WeekendMode12" role="tabpanel" aria-labelledby="WeekendMode12">
                    <Section404Themes />
                    </div>  
                    <div className="tab-pane fade" id="WeekendMode13" role="tabpanel" aria-labelledby="WeekendMode13">
                    <Section500Themes />
                    </div>  
                    <div className="tab-pane fade" id="WeekendMode14" role="tabpanel" aria-labelledby="WeekendMode14">
                    <Section501Themes />
                    </div>  
                    <div className="tab-pane fade" id="WeekendMode15" role="tabpanel" aria-labelledby="WeekendMode15">
                    <Section503Themes />
                    </div> 
                    <div className="tab-pane fade" id="WeekendMode16" role="tabpanel" aria-labelledby="WeekendMode16">
                    <Section504Themes />
                    </div>   
                        
                </div>
            </div>
            <div className='container privacyTab mb-0 pb-2 mt-5 mt-lg-0'>
                <div id="myTab78" className='slidetab' role="tablist">
                    <WeekendModeSectionSlider />
                </div>
            </div>
        </div>
    );
  }