import React from 'react';
import '../../../global/timelineTab/TimelineTab.scss';
import Video from '../../../../images/iconVid.png';
import VideoPopup from '../../../global/VideoPopup';
import Video1 from '../../../../images/iconVid1.png';
import ButtonBox from "../../../global/ButtonBox";
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import DigitalBusinessCardForm from './DigitalBusinessCardForm';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideo } from '../../../../redux/actions/videoAction';
export default function DigitalBusinessCardStep() {
    const { t } = useTranslation();
    let {isVideoVisible} = useSelector(state => state.videoReducer);
    const dispatch = useDispatch();
    const handleClick6 = () => {
      dispatch(toggleVideo());
    };
    return (
        <div>
            <Tab.Container  defaultActiveKey="">
                <div className='' >
                    <div className='flex'>
                        <div className='width-fll'>
                            <VideoPopup />
                            <Tab.Content className='class-scroll  ' >                          
                                    <div className="  align-items-center mx-auto d-flex flex-column-reverse justify-content-between three-bsiness">
                                        <div className="factorTab w-100 text-start d-block br-20 position-relative mx-auto ">
                                        <div className="VideoIcon" onClick={handleClick6}>
            <img src={Video} className="lightImg" alt="" />
            <img src={Video1} className="darkImg" alt="" />
          </div>
                                            <div className="tab-content" id="myTabContentsss">
                                                <div className='tab-pane active' id="digitalcard">
                                                    <div className='h3-border mt-3'>
                                                        <div className='flex flex-diction  none' >
                                                            <h5>Create Your “Digital Business Card”</h5>
                                                        </div>
                                                    </div>
                                                    <DigitalBusinessCardForm />
                                                </div>
                                                {/* <div className='tab-pane' id="digitalbusiness">
                                                </div> */}
                                            </div>
                                        </div>
                                        <span className="d-flex align-items-center justify-content-between w-100 mb-4">
                                            <div id="myTab1" className='flex' role="tablist">
                                                <button className="buttonArrow me-3 nav-link text-body border-0 bg br-10 active" id="digitalcard" data-bs-toggle="tab" data-bs-target="#digitalcard" type="button" role="tab" aria-controls="digitalcard" aria-selected="true">
                                                    <FontAwesomeIcon icon={faCaretLeft} className="h4 m-0" />
                                                </button>
                                                <button className="buttonArrow me-3 text-body nav-link border-0 bg br-10" id="digitalbusiness" data-bs-toggle="tab" data-bs-target="#digitalbusiness" type="button" role="tab" aria-controls="digitalbusiness" aria-selected="false">
                                                    <FontAwesomeIcon icon={faCaretRight} className="h4 m-0" />
                                                </button>
                                            </div>
                                            <div className='btn-save-hide'>
                                                <ButtonBox boxwidth="w-125 mt-0 mb-4" clicked="" icon={faFloppyDisk} name="Save" />
                                            </div>

                                        </span>
                                    </div>
                             
                           
                               
                           
                            </Tab.Content>


                        </div>
                    </div>
                </div>





            </Tab.Container>
          
        </div>
    )
}