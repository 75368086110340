import React, { useState, useEffect } from 'react';

import { FaBars, FaFacebookF, FaTwitter, FaLinkedinIn, FaDribbble, FaPinterest, FaYoutube ,FaTiktok ,FaSnapchatGhost ,FaInstagram } from "react-icons/fa";


import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';

import dribble_dark from '../../../../../images/dribble-dark.png';

import fb from '../../../../../images/fb.png';

import insta from '../../../../../images/insta.png';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import {  RiCloseLine } from "react-icons/ri";

import { FaStar } from "react-icons/fa";
import { RiDownloadCloud2Line, RiInstagramFill } from "react-icons/ri";

import printer_est from '../../../../../images/pinter-est.png';
import linked_in from '../../../../../images/linked-in.png';
import you_tube from '../../../../../images/you-tube.png';
import Social_2 from '../../../../../images/Social-2.png'
import testimonial from '../../../../../images/testimonial.png';
import testimonial_glass from '../../../../../images/testimonial-glass.png';
import timeline_gold from '../../../../../images/timeline-gold.png';

import meo_shape_right from '../../../../../images/meo-shape-right.png'

import { useTranslation } from "react-i18next";



export default function MenuGreenBarComponent() {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  
    useEffect(() => {
      if (isOpen) {
        const timer = setTimeout(() => {
          setIsOpen(false);
        }, 5000);
  
        return () => clearTimeout(timer);
      }
    }, [isOpen]);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  const [Competency1, setCompetency1] = useState(10);

  return (
    <div>



    



      
            <div className="bars-hambrger">

              <div className="navbar-bars" onClick={handleHamburgerClick}>
                <FaBars />
              </div>
              {hamburger && (
                <div className="hamburger-outer">
                  <div className=' mb-5 w-100'>
                    <button className="close-btn" onClick={handleHamburgerClose}>
                      <RiCloseLine />
                    </button>
                    <div className="d-flex align-items-center">

                      <div className="switch-outer">
                        <input type="checkbox" hidden="hidden" id="mobile-1-1" />
                        <label class="switch" for="mobile-1-1"></label>
                      </div>
                      <div className="social-set" >
                        {t('profile_hero_section.social_mode')}
                      </div>
                    </div>

                  </div>

                  <div>
                    <ul className="mb-3">
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')}1
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')}2
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')} 3
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')} 4
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')} 5
                      </li>
                      <li className="sidebar-li">
                        {t('profile_hero_section.menu_item_number')}6
                      </li>
                    </ul>
                    <p className="testimonial-p">
                      {t('profile_hero_section.testimonials')}
                    </p>
                    <div>

                      <div className="d-flex w-100">

                        <div className="testimonial">
                          <div className="timeline-top d-flex">
                            <div className="d-flex">
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                            </div>
                            <div>
                              <p>
                                {t('profile_hero_section.dummy_text_of_the_printing_printing')}
                                <p><b> {t('profile_hero_section.client_name_here')}</b>
                                  {t('profile_hero_section.designation_here')}</p>
                              </p>


                            </div>

                          </div>
                          <img src={testimonial} className="test-reg" alt="testimonial"></img>
                          <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                          <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                        </div>
                        <div className="testimonial">
                          <div className="timeline-top d-flex">
                            <div className="d-flex">
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                            </div>
                            <div>
                              <p>
                                {t('profile_hero_section.dummy_text_of_the_printing_printing')}
                                <p><b> {t('profile_hero_section.client_name_here')}</b>
                                  {t('profile_hero_section.designation_here')}</p>
                              </p>


                            </div>

                          </div>
                          <img src={testimonial} className="test-reg" alt="testimonial"></img>
                          <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                          <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                        </div>
                        <div className="testimonial">
                          <div className="timeline-top d-flex">
                            <div className="d-flex">
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                              <FaStar className="icon-green" />
                            </div>
                            <div>
                              <p>
                                {t('profile_hero_section.dummy_text_of_the_printing_printing')}
                                <p><b> {t('profile_hero_section.client_name_here')}</b>
                                  {t('profile_hero_section.designation_here')}</p>
                              </p>


                            </div>

                          </div>
                          <img src={testimonial} className="test-reg" alt="testimonial"></img>
                          <img src={timeline_gold} className="timeline-gold" alt="testimonial-gold"></img>
                          <img src={testimonial_glass} className="timeline-glass" alt="testimonial"></img>
                        </div>
                      </div>
                      <ul className="mt-5 top-padding reg-social d-flex">
                        <li className="pb-3">
                          <img src={fb} alt="fb" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={insta} alt="insta" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={Social_2} alt="twitter" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={linked_in} alt="linked-in" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={dribble_dark} alt="dribble" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={printer_est} alt="printerest" ></img>

                        </li>
                        <li className="pb-3">
                          <img src={you_tube} alt="youtube" ></img>
                        </li>
                      </ul>
                      <div className="meu-social">
                        <ul className="mt-5 top-padding">
                          <li >
                            <FaFacebookF />
                          </li>
                          <li >
                            <RiInstagramFill />
                          </li>
                          <li >
                            <FaTwitter />
                          </li>
                          <li >
                            <FaLinkedinIn />
                          </li>
                          <li >
                            <FaDribbble />
                          </li>
                          <li >
                            <FaPinterest />
                          </li>
                          <li >
                            <FaYoutube />
                          </li>
                        </ul>
                      </div>
                      <div className="bottom-tabs" >
                        <RiDownloadCloud2Line />
                        <h6>
                          {t('profile_hero_section.download_cv')}
                        </h6>
                      </div>


                    </div>

                  </div>

                </div>
              )}

            </div>

   
     
       







    </div>



  );
}
