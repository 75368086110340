import React, { useState } from "react";
import img1 from '../../../../../../images/StepZero/img56.png';
import img2 from '../../../../../../images/StepZero/img57.png';
import img3 from '../../../../../../images/StepZero/img61.png';
import img4 from '../../../../../../images/StepZero/linesection/img1.png';
import img5 from '../../../../../../images/StepZero/linesection/img2.png';
import img6 from '../../../../../../images/StepZero/linesection/img3.png';
export default function NicheSeven() {
  return (
    <div className="px-3 pt-3">
      <div className="row justify-content-center mt-0 mx-0 formleft1 px-3">
        <div className="col-lg-6 text-start p-0 ">
          <img src={img1} alt="" width="200px" height="auto" className="z1" />
        </div>
        <div className="col-lg-6 d-flex justify-content-end p-0 position-relative">
          <img src={img2} alt="" width="350px" height="auto" className="ms-auto" />
          <div className="headingtb">
            <h3>(SOM)</h3>
            <h4>(SAM)</h4>
            <h5>(EVG)</h5>
            <h6>(TAM)</h6>
          </div>
        </div>
        <div className="col-lg-12 px-0 pt-3">
          <div className="d-flex">
            <div className="circletab1">
              <div className="circleiner mt-5">
                <h4>(TAM)<br />Total  Available Market </h4>
                <div className="pincircle">
                  $10.9B
                </div>
              </div>
              <img src={img3} alt="" width="200px" height="auto" />
              <div className="newannous">
                <h6>How Big Is The Total Possible Market?</h6>
              </div>
            </div>
            <div className="circletab2">
              <div className="circleiner">
                <h4><span>(SAM)</span>Serviceable Available Market</h4>
                <div className="circle2">
                  <h4><span>(SOM)</span>Serviceable Otainable Market </h4>
                  <div className="circle3">
                    <h4><span>(EVG)</span>Earlyvangelists </h4>
                  </div>
                </div>
              </div>
              <div className="circle1inner">
                <div className="iner1">
                  <h5>$5.9B</h5>
                </div>
                <img src={img6} alt="" />
                <div className="newannous">
                  <h6>This is The Size Of  The Market I Can Reach Now</h6>
                </div>
              </div>
              <div className="circle2inner">
                <div className="iner1">
                  <h5>$3.9B</h5>
                </div>
                <img src={img5} alt="" />
                <div className="newannous">
                  <h6>The Market I Can Reach  With My Current Service Capabilities</h6>
                </div>
              </div>
              <div className="circle3inner">
                <div className="iner1">
                  <h5>$1.9B</h5>
                </div>
                <img src={img4} alt="" />
                <div className="newannous">
                  <h6>My Most  Potential Customers</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
