import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function NicheFiveSlider() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1366 },
      items: 6,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1366, min: 768 },
      items: 5,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <Carousel responsive={responsive} autoPlay={false} autoPlaySpeed={2000} transitionDuration={500}>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0 active" id="manage" data-bs-toggle="tab" data-bs-target="#manage" type="button" role="tab" aria-controls="manage" aria-selected="true">EMAIL ADDRESSES</button>
      </div>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0" id="nuans" data-bs-toggle="tab" data-bs-target="#nuans" type="button" role="tab" aria-controls="nuans" aria-selected="true">PASSWORD GENERATOR</button>
      </div>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0" id="otherg" data-bs-toggle="tab" data-bs-target="#otherg" type="button" role="tab" aria-controls="otherg" aria-selected="false">EMAIL SETTINGS</button>
      </div>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0" id="otherg" data-bs-toggle="tab" data-bs-target="#otherg" type="button" role="tab" aria-controls="otherg" aria-selected="false">CONNECT TO EMAIL SIGNATURE</button>
      </div>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0" id="otherg" data-bs-toggle="tab" data-bs-target="#otherg" type="button" role="tab" aria-controls="otherg" aria-selected="false">CONNECT TO ELECTRONIC SIGNATURE</button>
      </div>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0" id="otherg" data-bs-toggle="tab" data-bs-target="#otherg" type="button" role="tab" aria-controls="otherg" aria-selected="false">EMAIL MARKETING | default emails | chat gpt|</button>
      </div>
      <div className='px-2 btntb1'>
        <button className="br-40 w-100 h-100 border-0" id="otherg" data-bs-toggle="tab" data-bs-target="#otherg" type="button" role="tab" aria-controls="otherg" aria-selected="false">Email Subscription mgmt |pepeda law| compliance</button>
      </div>
    </Carousel>
  )
}