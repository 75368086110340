import React from "react";
import Aries from "../../../../../images/zodic/01.png";
import Aries2 from "../../../../../images/zodic/04.png";
import Taurus from "../../../../../images/zodic/02.png";
import Taurus2 from "../../../../../images/zodic/05.png";
import Gemini from "../../../../../images/zodic/03.png";
import Gemini2 from "../../../../../images/zodic/06.png";
import Cancer from "../../../../../images/zodic/07.png";
import Leo from "../../../../../images/zodic/08.png";
import Virgo from "../../../../../images/zodic/09.png";
import Cancer2 from "../../../../../images/zodic/10.png";
import Leo2 from "../../../../../images/zodic/11.png";
import Virgo2 from "../../../../../images/zodic/12.png";
import Libra from "../../../../../images/zodic/13.png";
import Scorpio from "../../../../../images/zodic/14.png";
import Sagittarius from "../../../../../images/zodic/15.png";
import Libra2 from "../../../../../images/zodic/16.png";
import Scorpio2 from "../../../../../images/zodic/17.png";
import Sagittarius2 from "../../../../../images/zodic/18.png";
import Capricon from "../../../../../images/zodic/19.png";
import Aquarius from "../../../../../images/zodic/20.png";
import Pisces from "../../../../../images/zodic/21.png";
import Capricon2 from "../../../../../images/zodic/22.png";
import Aquarius2 from "../../../../../images/zodic/23.png";
import Pisces2 from "../../../../../images/zodic/24.png";
import PersonalityZodicCircle from "../../../../global/PersonalityZodicCircle";
import { NavItem } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function PersonalTwo() {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className="text-center mb-5">
                <h5 className="fw-bold"> {t('profile_section.identify_your_zodiac_sign')}</h5> 
            </div>
            <div className="zodiacSection">
                <div className="row">
                    <h5 className="mb-3 p-0 h9"> {t('profile_section.What_your_zodiac_sign')}</h5>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="zodiaarea br-30 shadow-4 p-30">
                            <ul className="row flex-wrap justify-content-between">
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Aries} name="Aries" date="March 21-April 19" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Taurus} name="Taurus" date="April 20-May 20" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Gemini} name="Gemini" date="May 21-June 20" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Aries2} name="Aries" date="March 21-April 19" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Taurus2} name="Taurus" date="April 20-May 20" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Gemini2} name="Gemini" date="May 21-June 20" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Cancer} name="Cancer" date="June 21-July 22" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Leo} name="Leo" date="July 23-August 22" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Virgo} name="Virgo" date="August 23-Sep 22" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Cancer2} name="Cancer" date="June 21-July 22" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Leo2} name="Leo" date="July 23-August 22" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Virgo2} name="Virgo" date="August 23-Sep 22" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Libra} name="Libra" date="Sep 23-October 22" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Scorpio} name="Scorpio" date="Oct 23-Nov 21" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Sagittarius} name="Sagittarius" date="Nov 22-Dec 21" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Libra2} name="Libra" date="Sep 23-October 22" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Scorpio2} name="Scorpio" date="Oct 23-Nov 21" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Sagittarius2} name="Sagittarius" date="Nov 22-Dec 21" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Capricon} name="Capricon" date="Dec 22-January 19" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Aquarius} name="Aquarius" date="Jan 20-Feb 18" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Pisces} name="Aries" date="Feb 19-March 20" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Capricon2} name="Capricon" date="Dec 22-January 19" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Aquarius2} name="Aquarius" date="Jan 20-Feb 18" />
                                </NavItem>
                                <NavItem as="li" className="w-150 mb-3">
                                    <PersonalityZodicCircle img={Pisces2} name="Aries" date="Feb 19-March 20" />
                                </NavItem>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
