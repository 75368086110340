import React, { useState, useEffect } from 'react';

import '../ProfileHeroSectionDarkTheme.scss'
import '../regularTheme1/regularTheme1Desktop/RegularTheme1Desktop.scss';

import vedio from '../../../../../images/video.png'

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import { useTranslation } from "react-i18next";


export default function VideoSectionComponent() {
  const { t, i18n } = useTranslation();


  
 


  return (
    <div>





<div className="video-section">
                          <div className="video-name">
                          <div className="video-img video-img-set">
                      <img src={vedio} alt="vedio"></img>
                    </div>
                      </div>
                  </div> 

                


    </div>



  );
}
