import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerFirst from "../BannerFirst1"
import BannerTwo from "../BannerTwo1";
import BannerThree from "../BannerThree1";
import BannerFour from "../BannerFour1";
import BannerFive from "../BannerFive1";
import BannerSix from "../BannerSix1";
import Slide7 from '../../../images/social/icon45.svg';
// import { ReactComponent as Download } from "../../../images/icons/icon29.svg";
import { Link } from 'react-router-dom';


export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div className="px-4 position-relative">
        <Slider {...settings} className="socialSlider">
        <div>
            <BannerFirst boxwidth="banner-small" />
          </div>
          <div>
            <BannerTwo boxul="zoom-banner zoomul" />
          </div>
          <div>
            <BannerThree />
          </div>
          <div>
            <BannerFour boxwidth="banner-10"/>
          </div>
          <div>
            <BannerFive boxwidth="zoomebanner7" />
          </div>
          <div>
            <BannerSix boxwidth="zoomebanner6" />
          </div>
        </Slider>
        <div className="position-absolute bottom-0 end-0 py-3 px-5">
            <Link to="" className="text-primary downloadLink shadow-4 d-flex bg justify-content-center align-items-center">
              <img src={Slide7} alt="" />
            </Link>
          </div>
      </div>
    );
  }
}