import React from "react";

export default function PersonalityZodic(props) {
  return (

    <div className='d-flex flex-wrap justify-content-between mb-3'>
      <div className="d-flex justify-content-between w-100">
        <h5 className="h7 mb-3 text-capitalize">{props.Heading1}</h5>
        <h5 className="h7 mb-3 text-capitalize">{props.Heading2}</h5>
      </div>
      <span className='me-0 d-flex'>
        <input type="radio" className="btn-check" name={"s" + props.name} id={"1" + props.name} autoComplete="off" checked={props.checked1} />
        <label className="btn btnRadio large me-3" htmlFor={"1" + props.name}>
          <div className='radioInner'>
            <span></span>
          </div>
        </label>
      </span>
      <span className='me-0 d-flex'>
        <input type="radio" className="btn-check" name={"s" + props.name} id={"2" + props.name} autoComplete="off" checked={props.checked2} />
        <label className="btn btnRadio large me-3" htmlFor={"2" + props.name}>
          <div className='radioInner'>
            <span></span>
          </div> 
        </label>
      </span>
      <span className='me-0 d-flex'>
        <input type="radio" className="btn-check" name={"s" + props.name} id={"3" + props.name} autoComplete="off" checked={props.checked3} />
        <label className="btn btnRadio large me-3" htmlFor={"3" + props.name}>
          <div className='radioInner'>
            <span></span>
          </div>
        </label>
      </span>
      <span className='me-0 d-flex'>
        <input type="radio" className="btn-check" name={"s" + props.name} id={"4" + props.name} autoComplete="off" checked={props.checked4} />
        <label className="btn btnRadio large me-3" htmlFor={"4" + props.name}>
          <div className='radioInner'>
            <span></span>
          </div>
        </label>
      </span>
      <span className='me-0 d-flex'>
        <input type="radio" className="btn-check" name={"s" + props.name} id={"5" + props.name} autoComplete="off" checked={props.checked5} />
        <label className="btn btnRadio large me-3" htmlFor={"5" + props.name}>
          <div className='radioInner'>
            <span></span>
          </div>
        </label>
      </span>
      <span className='me-0 d-flex'>
        <input type="radio" className="btn-check" name={"s" + props.name} id={"6" + props.name} autoComplete="off" checked={props.checked6} />
        <label className="btn btnRadio large me-3" htmlFor={"6" + props.name}>
          <div className='radioInner'>
            <span></span>
          </div>
        </label>
      </span>
      <span className='me-0 d-flex'>
        <input type="radio" className="btn-check" name={"s" + props.name} id={"7" + props.name} autoComplete="off" checked={props.checked7} />
        <label className="btn btnRadio large me-3" htmlFor={"7" + props.name}>
          <div className='radioInner'>
            <span></span>
          </div>
        </label>
      </span>
      <span className='me-0 d-flex'>
        <input type="radio" className="btn-check" name={"s" + props.name} id={"8" + props.name} autoComplete="off" checked={props.checked8} />
        <label className="btn btnRadio large me-3" htmlFor={"8" + props.name}>
          <div className='radioInner'>
            <span></span>
          </div>
        </label>
      </span>
      <span className='me-0 d-flex'>
        <input type="radio" className="btn-check" name={"s" + props.name} id={"9" + props.name} autoComplete="off" checked={props.checked9} />
        <label className="btn btnRadio large me-3" htmlFor={"9" + props.name}>
          <div className='radioInner'>
            <span></span>
          </div>
        </label>
      </span>
      <span className='me-0 d-flex'>
        <input type="radio" className="btn-check" name={"s" + props.name} id={"10" + props.name} autoComplete="off" checked={props.checked10} />
        <label className="btn btnRadio m-0 large" htmlFor={"10" + props.name}>
          <div className='radioInner'>
            <span></span>
          </div>
        </label>
      </span>
    </div>
  );
}
