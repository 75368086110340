import React, { useState } from "react";
import desktop_12 from "../../../../../images/website-mode/Desktop12.png"
import tab_12 from "../../../../../images/website-mode/Tab12.png"
import mobile_12 from "../../../../../images/website-mode/Mobile12.png"

import Modal from 'react-bootstrap/Modal';

import { useTranslation } from "react-i18next";


import '../WeekendModeSectionThemes.scss';
import ButtonBoxNew from "../../../../global/ButtonBoxNew";
export default function Section400RegularTheme() {

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [TabTwoShow, setTabTwoShow] = useState(false);
  const [MobileTwoShow, setMobileTwoShow] = useState(false);
  const [deskShow, setDeskShow] = useState(false);
  const [desktwoShow, setDesktwoShow] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);
  const [TabFourShow, setTabFourShow] = useState(false);
  const [MobileFourShow, setMobileFourShow] = useState(false);
  const [deskFourShow, setdeskFourShow] = useState(false);
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    },
  });




  return (
    <div className="">

      <h4 className="text-center">
        Regular  Design Version 1
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_12} alt="deshtop-1"  >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HireMeSectionRegularThemeDesktopView1 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_12} alt="tab-1"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeTabView1 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_12} alt="mobile-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView1 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
    
    </div>
  );
}
