import React from "react";
import { useState } from "react";
import gold_star from '../../../../../../images/gold-star.png';
import message_gold from '../../../../../../images/msg-gold.png'
import rank_gold from '../../../../../../images/rank-gold.png'
import cap_gold from '../../../../../../images/cap-gold.png'
import glass_btn_3 from '../../../../../../images/glass-btn-3.png';
import three_btn_m from '../../../../../../images/three-btn-m.png'

import shape_end from '../../../../../../images/shape-end.png'
import mue_shape from '../../../../../../images/mue-shape.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavItem } from 'react-bootstrap';
import './RegularTheme2Desktop.scss';
import {  faCloudArrowDown,  faInfo,  } from "@fortawesome/free-solid-svg-icons";

import second_3 from '../../../../../../images/second-3.png'
import dribble_dark from '../../../../../../images/dribble-dark.png';
import message from '../../../../../../images/message.png';
import answer from '../../../../../../images/answers.png';
import rank from '../../../../../../images/rank.png';
import load from '../../../../../../images/load.png';

import fb from '../../../../../../images/fb.png';

import insta from '../../../../../../images/insta.png';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import { RiInstagramFill, RiCloseLine} from "react-icons/ri";

import Form from 'react-bootstrap/Form';

import printer_est from '../../../../../../images/pinter-est.png';
import linked_in from '../../../../../../images/linked-in.png';
import you_tube from '../../../../../../images/you-tube.png';
import Social_2 from '../../../../../../images/Social-2.png'
import two_btn from '../../../../../../images/two-btn.png'

import reg_bg_2 from '../../../../../../images/reg-bg-2.png';
import desktop_2_m from '../../../../../../images/desktop-2-m.png';

import { FaFacebookF, FaTwitter, FaLinkedinIn, FaDribbble, FaPinterest, FaYoutube ,FaTiktok ,FaSnapchatGhost ,FaInstagram } from "react-icons/fa";
import shape_m from '../../../../../../images/shape-m.png'

import { useTranslation } from "react-i18next";

import TimelineComponent from "../../profileHeroSectionComponents/TimelineComponent";

import InfoIconComponent from "../../profileHeroSectionComponents/InfoIconComponent";


import PlaylistComponent from "../../profileHeroSectionComponents/PlaylistComponent";

import SearchbarThemeSecondComponent from "../../profileHeroSectionComponents/SearchbarThemeSecondComponent";

import ProfileNameComponent from "../../profileHeroSectionComponents/ProfileNameComponent";
import UserIntroductionComponent from "../../profileHeroSectionComponents/UserIntroductionComponent";

import ToggleCheckboxComponent from "../../profileHeroSectionComponents/ToggleCheckboxComponent";
import MenuGreenBarComponent from "../../profileHeroSectionComponents/MenuGreenBarComponent";

import VideoSectionComponent from "../../profileHeroSectionComponents/VideoSectionComponent";

import TimelineSectionComponent from "../../profileHeroSectionComponents/TimelineSectionComponent";
import ProfileComponent from "../../profileHeroSectionComponents/profileComponent/ProfileComponent";

export default function RegularTheme2Desktop() {
  const { t, i18n } = useTranslation();
  const [seeall, setSeeall] = useState(false);



  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };
  const [bookmenow, setBookmenow] = useState(false);

  const handleBookmenowClick = () => {
    setBookmenow(!bookmenow);
  };

  const handleBookmenowClose = () => {
    setBookmenow(false);
  };



  return (
    <div>
      <div className="design-screen-desktop">
        <div>
          <div className="d-flex w-100">
       
          </div>
          <div className="main-content d-flex">
            <div className="hero-sidebar text-center">
            <ProfileComponent />
                
             <ProfileNameComponent />
              <div>
                <ul className="pt-2 pb-2">
                <SearchbarThemeSecondComponent />
              
                <InfoIconComponent />
                </ul>
            <ToggleCheckboxComponent />
          
       <MenuGreenBarComponent />

                <div className="intro-left-section">
                  <div className="logo-intro">
                    <div className="img-top">
                      <img src={second_3} className="img-logo" alt="logo" >
                      </img>
                    </div>
                    <div className="bookme-oter">
                <button className="book-me-btn book-me-reg"   onClick={handleBookmeClick}>
                  Book me
                </button>
            
<div className="book-me-mue"  onClick={handleBookmeClick}>
  <img src={two_btn} alt="two-btn" ></img>
  </div>
  <div className=" glass-show"  onClick={handleBookmeClick}>
                    <img src={glass_btn_3} alt="bookbtn" ></img>

                  </div>
                  {bookme && (
                        <div className="bookme-inner">
                <div className="hamburger-outer">
                  <div className=' mb-5 w-100'>
                    <button className="close-btn" onClick={handleBookmeClose}>
                    <RiCloseLine />
                    </button>
         <TimelineComponent />
              
                  </div>


                </div>
                
                </div>
              )}
    
        </div>
                            

                  </div>

<UserIntroductionComponent />
                </div>




                <ul className="top-padding reg-social">
                  <li className="pb-3">
                    <img src={fb} alt="fb" ></img>

                  </li>
                  <li className="pb-3">
                    <img src={insta} alt="insta" ></img>

                  </li>
                  <li className="pb-3">
                    <img src={Social_2} alt="twitter" ></img>

                  </li>
                  <li className="pb-3">
                    <img src={linked_in} alt="linked-in" ></img>

                  </li>
                  <li className="pb-3">
                    <img src={dribble_dark} alt="dribble" ></img>

                  </li>
                  <li className="pb-3">
                    <img src={printer_est} alt="printerest" ></img>

                  </li>
                  <li className="pb-3">
                    <img src={you_tube} alt="youtube" ></img>
                  </li>
                </ul>
                <div className="meu-social">
                      <ul className="mb-5 top-padding">
                    <li >
                      <FaFacebookF />
                    </li>
                    <li >
                      <RiInstagramFill />
                    </li>
                    <li >
                      <FaTwitter />
                    </li>
                    <li >
                      <FaLinkedinIn />
                    </li>
                    <li >
                      <FaDribbble />
                    </li>
                    <li >
                      <FaPinterest />
                    </li>
                    <li >
                      <FaYoutube />
                    </li>
                    </ul>
                    </div>
                <div className="bottom-tabs" >
                  <FontAwesomeIcon icon={faCloudArrowDown} ></FontAwesomeIcon>
                  <h6>
                    {t('profile_hero_section.download_cv')}
                  </h6>
                </div>
              </div>
            </div>
            <div className="content-hero">
              <img className="meo-shape" src={shape_m}></img>
            <div className="navbar-top p-2 w-100 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center ">
              </div>
              <div className="d-flex forms-inpt">
                <div>
                  <Form.Group className="" controlId="reg-Form.Controlname">

                    <Form.Control type="input" placeholder="Type Your Name here" />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group className="" controlId="reg-forn.Controlemail">

                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                  </Form.Group>
                </div>
              </div>
              <div>
              <div className="bookme-oterr">
                  <button className="book-me-btn book-me-reg"  onClick={handleBookmenowClick} >Book me Now</button>
                  <div className="book-me-mue"  onClick={handleBookmenowClick}>
  <img src={two_btn} alt="two-btn" ></img>
  </div>
  <div className=" glass-show"  onClick={handleBookmenowClick}>
                    <img src={glass_btn_3} alt="bookbtn" ></img>

                  </div>
                  {bookmenow && (
                        <div className="bookme-inner">
                <div className="hamburger-outer">
                  <div className=' mb-5 w-100'>
                    <button className="close-btn" onClick={handleBookmenowClose}>
                    <RiCloseLine />
                    </button>
                    <div className="w-100 reg-timeline mt-5">
                          <ul className="timelinee">
                            <li className="active">
                              <div className="timeline-topp">
                                9
                              </div>
                              <div className="timeline-icon">
                                <img className="message" src={message} alt="message" ></img>
                                <p>
        {t('profile_hero_section.answers')}
                                </p>
                              </div>
                            </li>
                            <li> <div className="timeline-topp">
                              300/
                              1000
                            </div>
                              <div className="timeline-icon">
                                <img src={load} className="load" alt="load" ></img>
                                <p>
                                  Social
                                  score
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="timeline-topp">
                                #1
                              </div>
                              <div className="timeline-icon">
                                <img src={rank} alt="rank" ></img>
                                <p>
                                  Rank
                                </p>
                              </div>
                            </li>
                            <li className="mr-0">
                              <div className="timeline-topp">
                                5
                              </div>
                              <div className="timeline-icon">

                                <img src={answer} className="answer" alt="answer" >
                                </img>

                                <p>
                                  Best
        {t('profile_hero_section.answers')}</p>
                              </div>
                            </li>

                          </ul>
                          <div className=" forms-inpt">
                                <div>
                                  <Form.Group className="w-100" controlId="reg-Form.Controlname">

                                    <Form.Control type="input" placeholder="Type Your Name here" />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group className="mt-2 w-100" controlId="reg-forn.Controlemail">

                                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                                  </Form.Group>
                                </div>
                                <div className="book-me-btnn book-me-reg mt-2" >
                                  Book me Now

                                </div>
<div className="book-me-mue">
  <img src={two_btn} alt="two-btn" ></img>
  </div>
  <div className=" glass-show"  >
                    <img src={glass_btn_3} alt="bookbtn" ></img>

                  </div>
                              </div>
                        </div>
                        <div className="w-100 meu-timeline">
                        <div className="w-100 meu-timeline">
                        <div className=" forms-inpt">
                                <div>
                                  <Form.Group className="w-100" controlId="reg-Form.Controlname">

                                    <Form.Control type="input" placeholder="Type Your Name here" />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group className="mt-2 w-100" controlId="reg-forn.Controlemail">

                                    <Form.Control type="email" placeholder="Type Your Main Email Here" />
                                  </Form.Group>
                                </div>
                                <div className=" mt-2 text-center reg-book"  >
                                <img src={three_btn_m} alt="bookbtn" ></img>
                                </div>
                                <div className="book-me-mue mt-2 m-auto"  >
  <img src={two_btn} alt="two-btn" ></img>
  </div>
                                <div className=" glass-show m-auto mt-2" >
                    <img src={glass_btn_3} alt="bookbtn" ></img>

                  </div>
                              </div>

              </div>

              </div>
              
                  </div>


                </div>
                
                </div>
              )}

    
        </div>
              </div>
              <div className="w-100  reg-timeline timeline-desktop">
                <ul className="timeline">
                  <li className="active">
                    <div className="timeline-top">
                      9
                    </div>
                    <div className="timeline-icon">
                      <img className="message" src={message} alt="message" ></img>
                      <p>
                        Answers
                      </p>
                    </div>
                  </li>
                  <li> <div className="timeline-top">
                    300/
                    1000
                  </div>
                    <div className="timeline-icon">
                      <img src={load} className="load" alt="load" ></img>
                      <p>
                        Social
                        score
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-top">
                      #1
                    </div>
                    <div className="timeline-icon">
                      <img src={rank} alt="rank" ></img>
                      <p>
                        Rank
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-top">
                      5
                    </div>
                    <div className="timeline-icon">

                      <img src={answer} className="answer" alt="answer" >
                      </img>

                      <p>
                        Best
                        Answers</p>
                    </div>
                  </li>

                </ul>

              </div>
              
              <div className="w-100 meu-timeline">
                <ul className="timeline">
                  <div className="active-oter">
                    <li className="active">
                      <div className="timeline-top">
                        9
                      </div>
                      <div className="timeline-icon">
                        <img className="message" src={message_gold} alt="message" ></img>
                        <p>
{t('profile_hero_section.answers')}
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li> <div className="timeline-top">
                      300/
                      1000
                    </div>
                      <div className="timeline-icon">
                        <img src={rank_gold} className="load" alt="load" ></img>
                        <p>
                          {t('profile_hero_section.social_score')}
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li>
                      <div className="timeline-top">
                        #1
                      </div>
                      <div className="timeline-icon">
                        <img src={gold_star} alt="rank" ></img>
                        <p>
                          Rank
                        </p>
                      </div>
                    </li>
                  </div>
                  <div className="active-oter">
                    <li>
                      <div className="timeline-top">
                        5
                      </div>
                      <div className="timeline-icon">

                        <img src={cap_gold} className="answer" alt="answer" >
                        </img>

                        <p>
                          {t('profile_hero_section.best_answers')}</p>
                      </div>
                    </li>
                  </div>


                </ul>

              </div>
            </div>

              <div className="hero-conent">
              <div className="hero-section hero-section-reg">
                  <img src={reg_bg_2} alt="backgrnd-2" className="content-img" >
                  </img>

                </div>
                <div className="hero-section hero-section-mue">
                  <img src={desktop_2_m} alt="backgrnd-2" className="content-img" >
                  </img>

                </div>
                <div className="himself-section">
                <div className="tab-section">
                  <div className="tab-oter-content ">
                  <div className="tab-content " id="myTabContent">
                      <div className="tab-pane fade show active" id="how-i-am-desktop" role="tabpanel" aria-labelledby="how-i-am-desktop">
                        <div className=" tab-himself-text d-flex">
                          <div className="himself-left">
                          <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                          <div className="himself-right">
                          <div className="playlist-section">
                  
                
                    <p>
                    And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.
                    </p>
                  </div>
                          </div>
                          <div className="video-btn-oter">
                          <div className="video-section">
                          <div className="video-name">
               
                      </div>
                  </div>
                            </div>
                   
                        </div>
                      </div>
                      <div className="tab-pane  fade" id="what-i-do-desktop" role="tabpanel" aria-labelledby="what-i-do-desktop">
                      <div className=" tab-himself-text d-flex">
                          <div className="himself-left">
       <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                          <div className="himself-right">
                          <div className="playlist-section">
                  
             
                    <p>
                    And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.
                    </p>
                  </div>
                          </div>
                          <div className="video-btn-oter">
                          <div className="video-section">
                          <div className="video-name">
                
                      </div>
                  </div>
                            </div>
                        </div>
                      </div>
                      <div className="tab-pane  fade" id="me-service-desktop" role="tabpanel" aria-labelledby="me-service-desktop">
                      <div className=" tab-himself-text d-flex">
                          <div className="himself-left">
       <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                          <div className="himself-right">
                          <div className="playlist-section">
                  
         
                    <p>
                    And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.
                    </p>
                  </div>
                          </div>
                          <div className="video-btn-oter">
                          <div className="video-section">
                          <div className="video-name">
           
                      </div>
                  </div>
                            </div>
                        </div>
                      </div>
                      <div className="tab-pane  fade" id="work-with-me-desktop" role="tabpanel" aria-labelledby="work-with-me-desktop">
                      <div className=" tab-himself-text d-flex">
                          <div className="himself-left">
       <h5>
                              {t('profile_section.hey_i_am_dyna')}
                            </h5>
                            <p>
                              {t('profile_section.dyna_text')}                             </p>
                          </div>
                          <div className="himself-right">
                          <div className="playlist-section">
                  
             
                    <p>
                    And I translate these positive strengths into terrific social expressions through-n-through for my brand. Soon you can enjoy the freedom of you Intentionally BOSS-UP Your LEADERSHIP STYLE and DRESS-UP your personality with signature styles and flair without apology.
                    </p>
                  </div>
                          </div>
                    
                        </div>
                      </div>
               <PlaylistComponent />
                <div className="video-btn-oter">
                  <VideoSectionComponent />
        
                            </div>
                    </div>
             
                  </div>
                  
                    <span className="d-flex align-items-center justify-center  w-100 mb-0 ">
                      <div id="myTab1" className='w-100' role="tablist">
                        <ul className="d-flex   my-4 pb-2 align-items-center modal-tabs justify-content-center">
                          <NavItem as="li" className="me-0">
                            <button className=" m-0 p-0 design-about  border-0 active " id="how-i-am-desktop" data-bs-toggle="tab" data-bs-target="#how-i-am-desktop" type="button" role="tab" aria-controls="how-i-am-desktop" aria-selected="true">
                              <span className="d-flex align-items-center "> {t('profile_section.who_am_i')}</span>
                            </button>
                          </NavItem>
                          <NavItem as="li" className="margin-left-scnd margin-left-btn">
                            <button className=" m-0 p-0 border-0 design-about  " id="what-i-do-desktop" data-bs-toggle="tab" data-bs-target="#what-i-do-desktop" type="button" role="tab" aria-controls="what-i-do-desktop" aria-selected="false">
                              <span className="d-flex align-items-center ">
                                {t('profile_section.what_i_do')}</span>
                            </button>
                          </NavItem>
                          <NavItem as="li" className="margin-left-scnd margin-left-btn">
                            <button className=" m-0 p-0 design-about  border-0  " id="me-service-desktop" data-bs-toggle="tab" data-bs-target="#me-service-desktop" type="button" role="tab" aria-controls="me-service-desktop" aria-selected="true">
                              <span className="d-flex align-items-center "> {t('profile_section.who_is_my_service_for')}</span>
                            </button>
                          </NavItem>
                          <NavItem as="li" className="margin-left-scnd margin-left-btn">
                            <button className=" m-0 p-0 border-0 design-about  " id="work-with-me-desktop" data-bs-toggle="tab" data-bs-target="#work-with-me-desktop" type="button" role="tab" aria-controls="work-with-me-desktop" aria-selected="false">
                              <span className="d-flex align-items-center ">
                                {t('profile_section.why_work_with_me')}</span>
                            </button>
                          </NavItem>
                        </ul>

                      </div>

                    </span>
                  </div>
                </div>
            <TimelineSectionComponent />
                <img src={mue_shape} className="mue-shape" alt="mue-shape"></img>
                <img src={shape_end} className="mue-shape-end" alt="mue-shape"></img>
              </div>

            </div>

            <div>

            </div>
          </div>
        </div>
      </div>




    </div>



  );
}
