import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Select from "react-select";
import countryList from 'react-select-country-list';
import { useState, useMemo  } from 'react';
import ButtonBox from "../../../../global/ButtonBox";
import RadioOff from "../../../../global/RadioOff";
import RadioOn from "../../../../global/RadioOn";
import { useTranslation } from "react-i18next";
import { NavItem } from 'react-bootstrap';
import ButtonBoxNew from '../../../../global/ButtonBoxNew';
import DropdownBox from '../../../../global/DropdownBox';

export default function GeneralOne() {
  const { t, i18n } = useTranslation();
  const theme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#EFF4F8",
      primary: "#EFF4F8",
    }
  });
  const options = useMemo(() => countryList().getData(), [])

  const changeHandler = value => {
    setCountry(value)
  }
  const [country, setCountry] = useState('United States');

  // const options1 = [
  //       { value: 'english', label: 'English' },
  //       { value: 'garman', label: 'Garman' }
  //     ]
  // const options2 = [
  //   { value: 'USD $', label: 'USD $' },
  //   { value: 'Euro €', label: 'Euro €' },
  //   { value: 'Pound £', label: 'Pound £' },
  //   { value: 'Yen ¥', label: 'Yen ¥' },
  // ]

  const languageOptions =
  [{
      "name": "english",      
    }, {
      "name": "garman",
    }
  ] 

  const currancyOptions =
  [{
      "name": "USD $",      
    }, {
      "name": "Euro €",
    }, {
      "name": "Pound £",      
    }, {
      "name": "Yen ¥",      
    }
  ]

  const countryOptions =
    [{
        "name": "AFGHANISTAN",
        
        
      }, {
        "name": "ALAND ISLANDS",
        
        
      }, {
        "name": "ALBANIA",
        
        
      }, {
        "name": "ALGERIA",
        
      
      }, {
        "name": "AMERICAN SAMOA",
        
        
      }, {
        "name": "ANDORRA",
        
        
      }, {
        "name": "ANGOLA",
        
       
      }, {
        "name": "ANGUILLA",
        
         
      }, {
        "name": "ANTARCTICA",
        
        
      }, {
        "name": "ANTIGUA AND BARBUDA",
        
       
      }, {
        "name": "ARGENTINA",
        
       
      }, {
        "name": "ARMENIA",
        
        
      }, {
        "name": "ARUBA",
        
        
      }, {
        "name": "AUSTRALIA",
        
        
      }, {
        "name": "AUSTRIA",
        
        
      }, {
        "name": "AZERBAIJAN",
        
         
      }, {
        "name": "BAHAMAS",
        
         
      }, {
        "name": "BAHRAIN",
        
         
      }, {
        "name": "BANGLADESH",
        
        
      }, {
        "name": "BARBADOS",
        
         
      }, {
        "name": "BELARUS",
        
         
      }, {
        "name": "BELGIUM",
        
        
      }, {
        "name": "BELIZE",
        
         
      }, {
        "name": "BENIN",
        
         
        
      }, {
        "name": "BERMUDA",
        
         
      }, {
        "name": "BHUTAN",
        
         
        
      }, {
        "name": "BHUTAN",
        
        
      }, {
        "name": "BOLIVIA",
        
        
      }, {
        "name": "BONAIRE, SINT EUSTATIUS AND SABA",
        
         
      }, {
        "name": "BOSNIA and HERZEGOVINA",
        
         
      }, {
        "name": "BOTSWANA",
        
        
      }, {
        "name": "BOUVET ISLAND",
        
        
      }, {
        "name": "BRAZIL",
        
         
      }, {
        "name": "BRAZIL",
        
        
      }, {
        "name": "BRITISH INDIAN OCEAN TERRITORY",
        
         
      }, {
        "name": "BRUNEI DARUSSALAM",
        
         
      }, {
        "name": "BULGARIA",
        
         
      }, {
        "name": "BURKINA FASO",
        
         
        
      }, {
        "name": "BURUNDI",
        
         
      }, {
        "name": "CAMBODIA",
        
        
      }, {
        "name": "CAMEROON",
        
         
      }, {
        "name": "CANADA",
        
         
      }, {
        "name": "CAPE VERDE",
        
        
      }, {
        "name": "CAYMAN ISLANDS",
        
         
      }, {
        "name": "CENTRAL AFRICAN REPUBLIC",
        
         
      }, {
        "name": "CHAD",
        
         
      }, {
        "name": "CHILE",
        
         
      }, {
        "name": "CHINA",
        
         
      }, {
        "name": "CHINA",
        
        
      }, {
        "name": "CHRISTMAS ISLAND",
        
         
      }, {
        "name": "COCOS (KEELING) ISLANDS",
        
         
      }, {
        "name": "COLOMBIA",
        
         
      }, {
        "name": "COMOROS",
        
         
      }, {
        "name": "CONGO",
        
         
      }, {
        "name": "COOK ISLANDS",
        
         
      }, {
        "name": "COSTA RICA",
        
        
      }, {
        "name": "COTE D’IVOIRE (IVORY COAST)",
        
         
        
      }, {
        "name": "CROATIA",
        
       
      }, {
        "name": "CURACAO",
        
        
      }, {
        "name": "CYPRUS",
        
        
      }, {
        "name": "CZECH REPUBLIC",
        
         
      }, {
        "name": "DEMOCRATIC REPUBLIC OF THE CONGO",
        
        
      }, {
        "name": "DEN",
        
        
      }, {
        "name": "DJIBOUTI",
        
         
      }, {
        "name": "DOMINICA",
        
         
      }, {
        "name": "DOMINICAN REPUBLIC",
        
         
      }, {
        "name": "EGYPT",
        
         
      }, {
        "name": "EL SALVADOR",
        
         
      }, {
        "name": "ECUADOR",
        
         
      }, {
        "name": "EQUATORIAL GUINEA",
        
         
      }, {
        "name": "ERITREA",
        
         
      }, {
        "name": "ERITREA",
        
         
      }, {
        "name": "ESTONIA",
        
        
      }, {
        "name": "ETHIOPIA",
        
         
      }, {
        "name": "FALKLAND ISLANDS (MALVINAS)",
        
         
      }, {
        "name": "FEDERATED STATES OF MICRONESIA",
        
        
      }, {
        "name": "FIJI",
        
         
      }, {
        "name": "FINLAND",
        
        
      }, {
        "name": "FRANCE",
        
        
      }, {
        "name": "FRANCE, METROPOLITAN",
        
        
      }, {
        "name": "FRENCH GUIANA",
        
        
      }, {
        "name": "FRENCH POLYNESIA",
        
         
      }, {
        "name": "GABON",
        
         
      }, {
        "name": "GAMBIA",
        
        
      }, {
        "name": "GEORGIA",
        
        
      }, {
        "name": "GERMANY",
        
        
      }, {
        "name": "GHANA",
        
        
      }, {
        "name": "GIBRALTAR",
        
         
      }, {
        "name": "GREECE",
        
        
      }, {
        "name": "GREENLAND",
        
        
      }, {
        "name": "GRENADA",
        
         
      }, {
        "name": "GUADELOUPE",
        
        
      }, {
        "name": "GUAM",
        
         
      }, {
        "name": "GUATEMALA",
        
        
      }, {
        "name": "GUINEA-BISSAU",
        
         
        
      }, {
        "name": "GUINEA",
        
         
      }, {
        "name": "GUYANA",
        
         
      }, {
        "name": "HAITI",
        
        
      }, {
        "name": "HEARD and MCDONALD ISLANDS",
        
         
      }, {
        "name": "HOLY SEE (VATICAN CITY STATE)",
       
      }, {
        "name": "HONDURUS",
        
        
      }, {
        "name": "HONG KONG",
        
         
      }, {
        "name": "HUNGARY",
        
        
      }, {
        "name": "ICELAND",
        
        
      }, {
        "name": "INDIA",
        
        
      }, {
        "name": "INDONESIA",
        
        
      }, {
        "name": "IRAQ",
        
         
      }, {
        "name": "ISRAEL",
        
         
      }, {
        "name": "IRELAND",
        
        
      }, {
        "name": "ISLE OF MAN",
        
         
      }, {
        "name": "ITALY",
        
        
      }, {
        "name": "JAMAICA",
        
         
      }, {
        "name": "JAPAN",
        
        
      }, {
        "name": "JERSEY",
        
         
      }, {
        "name": "JORDAN",
        
         
      }, {
        "name": "KAZAKHSTAN",
        
        
      }, {
        "name": "KENYA",
        
         
      }, {
        "name": "KIRIBATI",
        
         
      }, {
        "name": "KOREA, REPUBLIC OF",
        
        
      }, {
        "name": "KUWAIT",
        
         
      }, {
        "name": "KYRGYZSTAN",
        
        
      }, {
        "name": "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
        
        
      }, {
        "name": "LATVIA",
        
        
      }, {
        "name": "LEBANON",
        
         
      }, {
        "name": "LESOTHO",
        
        
      }, {
        "name": "LESOTHO",
        
        
      }, {
        "name": "LIBERIA",
        
         
      }, {
        "name": "LIBYAN ARAB JAMAHIRIYA",
        
         
      }, {
        "name": "LIECHTENSTEIN",
        
         
      }, {
        "name": "LITHUANIA",
        
        
      }, {
        "name": "LUXEMBOURG",
        
        
      }, {
        "name": "MACAO",
        
        
      }, {
        "name": "MACEDONIA",
        
        
      }, {
        "name": "MADAGASCAR",
        
         
      }, {
        "name": "MALAWI",
        
         
      }, {
        "name": "MALAYSIA",
        
         
      }, {
        "name": "MALDIVES",
        
        
      }, {
        "name": "MALI",
        
         
        
      }, {
        "name": "MALTA",
        
        
      }, {
        "name": "MARSHALL ISLANDS",
        
         
      }, {
        "name": "MARTINIQUE",
        
        
      }, {
        "name": "MAURITANIA",
        
        
      }, {
        "name": "MAURITIUS",
        
        
      }, {
        "name": "MAYOTTE",
        
        
      }, {
        "name": "MEXICO",
        
         
      }, {
        "name": "MOLDOVA, REPUBLIC OF",
        
        
      }, {
        "name": "MONACO",
        
        
      }, {
        "name": "MONGOLIA",
        
        
      }, {
        "name": "MONTENEGRO, REPUBLIC OF",
        
        
      }, {
        "name": "MONTSERRAT",
        
         
      }, {
        "name": "MOROCCO",
        
        
      }, {
        "name": "MOZAMBIQUE",
        
         
      }, {
        "name": "MYANMAR",
        
         
      }, {
        "name": "NAMIBIA",
        
         
      }, {
        "name": "NAURU",
        
         
      }, {
        "name": "NEPAL",
        
        
      }, {
        "name": "NETHERLANDS",
        
        
      }, {
        "name": "NETHERLANDS ANTILLES",
        
         
      }, {
        "name": "NEW CALEDONIA",
        
         
      }, {
        "name": "NEW ZEALAND",
        
         
      }, {
        "name": "NICARAGUA",
        
         
      }, {
        "name": "NIGER",
        
         
        
      }, {
        "name": "NIGERIA",
        
        
      }, {
        "name": "NIUE",
        
         
      }, {
        "name": "NORFOLK ISLAND",
        
         
      }, {
        "name": "NORTHERN MARIANA ISLANDS",
        
         
      }, {
        "name": "NORWAY",
        
        
      }, {
        "name": "OMAN",
        
         
      }, {
        "name": "PAKISTAN",
        
        
      }, {
        "name": "PALAU",
        
         
      }, {
        "name": "PANAMA",
        
        
      }, {
        "name": "PAPUA NEW GUINEA",
        
        
      }, {
        "name": "PARAGUAY",
        
        
      }, {
        "name": "PERU",
        
         
      }, {
        "name": "PHILIPPINES",
        
         
      }, {
        "name": "PITCAIRN",
        
         
      }, {
        "name": "POLAND",
        
        
      }, {
        "name": "PORTUGAL",
        
        
      }, {
        "name": "PUERTO RICO",
        
         
      }, {
        "name": "QATAR",
        
        
      }, {
        "name": "REUNION",
        
        
      }, {
        "name": "ROMANIA",
        
        
      }, {
        "name": "RUSSIAN FEDERATION",
        
         
      }, {
        "name": "RWANDA",
        
         
      }, {
        "name": "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
        
       
      }, {
        "name": "SAMOA",
        
        
      }, {
        "name": "SAN MARINO",
        
        
      }, {
        "name": "SAO TOME AND PRINCIPE",
        
        
      }, {
        "name": "SAUDI ARABIA",
        
        
      }, {
        "name": "SENEGAL",
        
         
        
      }, {
        "name": "SERBIA",
        
         
      }, {
        "name": "SEYCHELLES",
        
        
      }, {
        "name": "SIERRA LEONE",
        
        
      }, {
        "name": "SINGAPORE",
        
         
      }, {
        "name": "SINT MAARTEN (DUTCH PART)",
        
        
      }, {
        "name": "SLOVAKIA",
        
        
      }, {
        "name": "SLOVENIA",
        
        
      }, {
        "name": "SOLOMON ISLANDS",
        
         
      }, {
        "name": "SOMALIA",
        
         
      }, {
        "name": "SOUTH AFRICA",
        
        
      }, {
        "name": "SOUTH SUDAN",
        
         
      }, {
        "name": "SPAIN",
        
        
      }, {
        "name": "SRI LANKA",
        
        
      }, {
        "name": "ST. KITTS-NEVIS",
        
         
      }, {
        "name": "ST. LUCIA",
        
         
      }, {
        "name": "ST. PIERRE AND MIQUELON",
        
        
      }, {
        "name": "ST. VINCENT AND THE GRENADINES",
        
         
      }, {
        "name": "SURINAME",
        
         
      }, {
        "name": "SVALBARD AND JAN MAYEN",
        
        
      }, {
        "name": "SWITZERLAND",
        
         
      }, {
        "name": "SWAZILAND",
        
        
      }, {
        "name": "SWEDEN",
        
         
      }, {
        "name": "TAIWAN",
        
         
      }, {
        "name": "TAJIKISTAN",
        
        
      }, {
        "name": "TANZANIA, UNITED REPUBLIC OF",
        
        
      }, {
        "name": "THAILAND",
        
        
      }, {
        "name": "TIMOR-LESTE",
        
         
      }, {
        "name": "TOGO",
        
         
        
      }, {
        "name": "TOKELAU",
        
         
      }, {
        "name": "TONGA",
        
        
      }, {
        "name": "TRINIDAD AND TOBAGO",
        
      }, {
        "name": "TUNISIA",
        
         
      }, {
        "name": "TURKEY",
        
        
      }, {
        "name": "TURKMENISTAN",
        
        
      }, {
        "name": "TURKS and CAICOS ISLANDS",
        
         
      }, {
        "name": "TUVALU",
        
         
      }, {
        "name": "UGANDA",
        
         
      }, {
        "name": "UKRAINE",
        
         
      }, {
        "name": "UNITED ARAB EMIRATES",
        
        
      }, {
        "name": "UNITED KINGDOM",
        
         
      }, {
        "name": "UNITED STATES",
        
         
      }, {
        "name": "URUGUAY",
        
         
      }, {
        "name": "UZBEKISTAN",
        
         
      }, {
        "name": "VANUATU",
        
        
      }, {
        "name": "VENEZUELA",
        
         
      }, {
        "name": "VIETNAM",
        
        
      }, {
        "name": "BRITISH VIRGIN ISLANDS",
        
         
      }, {
        "name": "U.S. VIRGIN ISLANDS",
        
         
      }, {
        "name": "WALLIS AND FUTUNA ISLANDS",
        
         
      }, {
        "name": "WESTERN SAHARA",
        
        
      }, {
        "name": "YEMEN",
        
        
      }, {
        "name": "ZAMBIA",
        
         
      }, {
        "name": "ZIMBABWE",
        
         
      }, {
        "name": "CUBA",
        
         
      }, {
        "name": "ECUADOR",
        
        
      }, {
        "name": "IRAN",
        
        
      }, {
        "name": "LATVIA",
        
         
      }, {
        "name": "LITHUANIA",
        
         
      }, {
        "name": "GUINEA-BISSAU",
        
         
      }, {
        "name": "SYRIA",
        
         
      }, {
        "name": "TONGA",
        
        
      }]

  return (
    <div>
      <ul className="mb-5">
        <NavItem as="li" className="tabBdr flex-column justify-content-start text-start align-items-start">
          {/* <button className="w-270 btn btnDefult">{t('privacy_component.privacy_general_component.account_web_address')}</button> */}
          <ButtonBoxNew name="Account URL (Web Address)" boxwidth="w-300" />
          <div className="d-flex justify-content-start align-items-center py-4">
            <input placeholder={t('enter_current_password')} className="shadow-5in br-10 w-340 mb-0 me-3" type="text" />
            <p className="m-0">{t('privacy_component.privacy_general_component.privacy_password_mail')}</p>
          </div>
          <p><FontAwesomeIcon icon={faCircleExclamation} className="h6 m-0" /> {t('privacy_component.privacy_general_component.privacy_change_account')}</p>
        </NavItem>
        <NavItem as="li" className="tabBdr flex-column justify-content-start text-start align-items-start">
          {/* <button className="w-240 btn btnDefult mt-2 mb-4">{t('privacy_component.privacy_general_component.first_day')}</button> */}
          <ButtonBoxNew name="First Day Of The Week" boxwidth="w-240 mb-4" />
          <p>{t('privacy_component.privacy_general_component.change_the_first_day')}</p>
          <div className='d-flex mb-4 mt-3 pb-3'>
            <span className='me-4 d-flex'>
              <input type="radio" className="btn-check" name="options-outlined" id="Sunday" autoComplete="off" />
              <label className="btn btnRadio me-2" htmlFor="Sunday">
                <div className='radioInner'>
                  <span></span>
                </div>
              </label>
              <label htmlFor="Sunday">{t('privacy_component.privacy_general_component.sunday')}</label>
            </span>
            <span className='me-4 d-flex'>
              <input type="radio" className="btn-check" name="options-outlined" id="Monday" autoComplete="off" />
              <label className="btn btnRadio me-2" htmlFor="Monday">
                <div className='radioInner'>
                  <span></span>
                </div>
              </label>
              <label htmlFor="Monday">{t('privacy_component.privacy_general_component.monday')}</label>
            </span>
            <span className='me-4 d-flex'>
              <input type="radio" className="btn-check" name="options-outlined" id="Tuesday" autoComplete="off" />
              <label className="btn btnRadio me-2" htmlFor="Tuesday">
                <div className='radioInner'>
                  <span></span>
                </div>
              </label>
              <label htmlFor="Tuesday">{t('privacy_component.privacy_general_component.tuesday')}</label>
            </span>
            <span className='me-4 d-flex'>
              <input type="radio" className="btn-check" name="options-outlined" id="Wednesday" autoComplete="off" />
              <label className="btn btnRadio me-2" htmlFor="Wednesday">
                <div className='radioInner'>
                  <span></span>
                </div>
              </label>
              <label htmlFor="Wednesday">{t('privacy_component.privacy_general_component.wednesday')}</label>
            </span>
            <span className='me-4 d-flex'>
              <input type="radio" className="btn-check" name="options-outlined" id="Thursday" autoComplete="off" />
              <label className="btn btnRadio me-2" htmlFor="Thursday">
                <div className='radioInner'>
                  <span></span>
                </div>
              </label>
              <label htmlFor="Thursday">{t('privacy_component.privacy_general_component.thursday')}</label>
            </span>
            <span className='me-4 d-flex'>
              <input type="radio" className="btn-check" name="options-outlined" id="Friday" autoComplete="off" />
              <label className="btn btnRadio me-2" htmlFor="Friday">
                <div className='radioInner'>
                  <span></span>
                </div>
              </label>
              <label htmlFor="Friday">Friday {t('privacy_component.privacy_general_component.friday')} </label>
            </span>
            <span className='me-4 d-flex'>
              <input type="radio" className="btn-check" name="options-outlined" id="Saturday" autoComplete="off" />
              <label className="btn btnRadio" htmlFor="Saturday">
                <div className='radioInner'>
                  <span></span>
                </div>
              </label>
              <label htmlFor="Saturday">{t('privacy_component.privacy_general_component.saturday')}</label>
            </span>
          </div>
          <div className="d-flex justify-content-between w-100 align-items-center">
            {/* <button className="w-200 btn btnDefult mt-3 mb-3"> {t('privacy_component.privacy_general_component.language')}</button> */}
            <ButtonBoxNew name="Language" boxwidth="w-200" />
            <h6 className="mb-0">{t('privacy_component.privacy_general_component.language_eng')}</h6>
            {/* <Select isSearchable={false} options={options1} className="countryList br-30 w-220 customWidth mb-4" placeholder={t('privacy_component.privacy_general_component.change_language')} theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'90%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                  }),
                }} /> */}
              <DropdownBox boxwidth="w-220" name="Change Language" options={languageOptions} />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr flex-column justify-content-start text-start align-items-start">
          <div className="d-flex justify-content-between w-100 align-items-center">
          <ButtonBoxNew name="Date Format" boxwidth="w-200" />
            {/* <button className="w-200 btn btnDefult mt-3 mb-3">{t('privacy_component.privacy_general_component.date_format')}</button> */}
            <h6 className="mb-0">Md, YYYY</h6>
            {/* <Select isSearchable={false} options={options1} className="countryList br-30 w-150 customWidth mb-4" placeholder={t('change')} theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'90%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                    
                  }),
                }} /> */}
                <DropdownBox boxwidth="w-220" name="Change" options={languageOptions} />
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr flex-column justify-content-start text-start align-items-start">
          <div className="tabBdr pt-0 d-flex justify-content-between w-100 align-items-center mb-4">
            {/* <button className="w-200 btn btnDefult mt-3 mb-3">{t('privacy_component.privacy_general_component.timezone')}</button> */}
            <ButtonBoxNew name="Timezone" boxwidth="w-200" />
            <h6 className="mb-0">{t('privacy_component.privacy_general_component.bangladesh')}</h6>
            {/* <Select isSearchable={false} options={options1} className="countryList br-30 w-150 customWidth mb-4" placeholder={t('change')} theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'90%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                    
                  }),
                }} /> */}
                <DropdownBox boxwidth="w-220" name="Change" options={languageOptions} />
          </div>
          <div className="d-flex justify-content-between w-100 align-items-center mb-5">
            {/* <button className="w-200 btn btnDefult mt-3 mb-3">{t('privacy_component.privacy_general_component.change_currency')}</button> */}
            <ButtonBoxNew name="Change Currency" boxwidth="w-200" />
            <h6 className="mb-0">USD $</h6>
            {/* <Select isSearchable={false} options={options2} className="countryList br-30 w-150 customWidth mb-4" placeholder={t('change')} theme={theme}
              styles={{
                  option: (base) => ({
                    ...base,
                    borderRadius: '30px',
                    width:'90%',
                    height:'auto',
                    lineHeight: '24px',
                    margin: '10px',
                    cursor: 'pointer',
                    color:'#000000',
                    boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                    
                  }),
                }} /> */}
                <DropdownBox boxwidth="w-220" name="Change" options={currancyOptions} />
          </div>
          {/* <button className="w-200 btn btnDefult mt-1 mb-4">{t('privacy_component.privacy_general_component.timeline_weekends')}</button> */}
          <ButtonBoxNew name="Timeline Weekends" boxwidth="w-240 mb-4" />
          <p>{t('privacy_component.privacy_general_component.weekend_text')}</p>
          <div className='d-flex mb-3 mt-3'>
            <span className='me-4 d-flex'>
              <input type="radio" className="btn-check" name="timeline" id="show" autoComplete="off" />
              <label className="btn btnShadow me-2 w-200 d-flex" htmlFor="show">
                <div className='btnRadio'>
                  <div className='radioInner'>
                    <span></span>
                  </div>
                </div>
                 {t('privacy_component.privacy_general_component.show_weekends')}
              </label>
            </span>
            <span className='me-4 d-flex'>
              <input type="radio" className="btn-check" name="timeline" id="hide" autoComplete="off" />
              <label className="btn btnShadow me-2 w-200 d-flex" htmlFor="hide">
                <div className='btnRadio'>
                  <div className='radioInner'>
                    <span></span>
                  </div>
                </div>
                 {t('privacy_component.privacy_general_component.hide_weekends')}
              </label>
            </span>
          </div>
        </NavItem>
        <NavItem as="li" className="tabBdr flex-column justify-content-start text-start align-items-start" autoComplete="off">
          {/* <button className="w-180 btn btnDefult mt-2 mb-4"> {t('privacy_component.privacy_general_component.data_residency')}</button> */}
          <ButtonBoxNew name="Data Residency" boxwidth="w-200 mb-4" />
          {/* <Select isSearchable={false} options={options} className="countryList w-240 bg br-30" autoComplete="off" placeholder= {t('privacy_component.privacy_general_component.country')} value={country} onChange={changeHandler} theme={theme}
                    styles={{
                        option: (base) => ({
                          ...base,
                          borderRadius: '30px',
                          width:'90%',
                          height:'auto',
                          lineHeight: '24px',
                          margin: '10px',
                          cursor: 'pointer',
                          color:'#000000',
                          boxShadow:'2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shodow-drop);',
                        }),
                      }} /> */}
            <DropdownBox boxwidth="w-240 mt-4" name="country" options={countryOptions} />
        </NavItem>
        <NavItem as="li" className="tabBdr flex-column justify-content-start text-start align-items-start">
          <div className='d-flex justify-content-start  align-items-center mb-4'> 
            {/* <button className="w-320 btn btnDefult me-4"> {t('privacy_component.privacy_general_component.enable_two_factor_authentication')}</button> */}
            <ButtonBoxNew name="Enable Two-Factor Authentication" boxwidth="w-320 me-4" />
            <div className="radioButton d-flex mt-2">
              <RadioOn id="factor1" name="factor" />
              <RadioOff id="factor2" name="factor" text="Off" />
            </div>
          </div>
          <h5> {t('privacy_component.privacy_general_component.two_factor_authentication')}</h5>
          <p> {t('privacy_component.privacy_general_component.two_factor_authentication_text')}<br/>{t('privacy_component.privacy_general_component.two_factor_authentication_text_two')}</p>
        </NavItem>
        <NavItem as="li" className="py-3 flex-column justify-content-start text-start align-items-start">
          <h5>{t('privacy_component.privacy_general_component.support_access')}</h5>
          <p>{t('privacy_component.privacy_general_component.authorize_account_access')}</p>
          <div className='d-flex justify-content-start  align-items-center  mt-3'> 
            {/* <button className="w-240 btn btnDefult me-4">{t('privacy_component.privacy_general_component.grant_support_access')}</button> */}
            <ButtonBoxNew name="Grant Support Access" boxwidth="w-240 me-4" />
            <div className="radioButton d-flex mt-2">
              <RadioOn id="support1" name="support" />
              <RadioOff id="support2" name="support" text="Off" />
            </div>
          </div>
        </NavItem>
        <NavItem as="li" className=" text-center justify-content-center">
            <ButtonBox
              boxwidth="w-140 mt-5 mb-50 mx-auto"
              clicked="Saved"
              name="Save/Next"
            />
          </NavItem>

      </ul>
    </div>
  );
}
