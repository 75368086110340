import React from "react";
import "./HireMePositionBox.scss";
const HireMePositionBox = ({ Regular, Neumorphic }) => {
  return (
    <>
      <div
        className={`position-box-one ${
          Regular ? "Regular-style" : Neumorphic ? "Neumorphic-style" : ""
        }`}
      >
        <p className="pos-one">
          Sollicitudin ac orci phasellus egestas tellus rutrum tellus. Aliquam
          nulla facilisi cras fermentum odio eu feugiat, ornare quam viverra
          sagittis eu magna.
        </p>
        <p className="pos-two">Dyn Appolon</p>
      </div>
    </>
  );
};

export default HireMePositionBox;
