export const toggleVideo = () => {
  return async dispatch => {

    dispatch({
      type: 'TOGGLE_VIDEO',
    });


  }
  };
  export const closeVideo = () => {
    return async dispatch => {
  
      dispatch({
        type: 'CLOSE_VIDEO',
      });
  
  
    }
    };

    export const closeSubHeaderContainer = () => {
      return async dispatch => {
    
        dispatch({
          type: 'CLOSE_SUB_HEADER',
        });
    
    
      }
      };

      export const leftBarClose = () => {
        return async dispatch => {
      
          dispatch({
            type: 'CLOSE_LEFT_BAR',
          });
      
      
        }
        };
        export const rightBarClose = () => {
          return async dispatch => {
        
            dispatch({
              type: 'CLOSE_RIGHT_BAR',
            });
        
        
          }
          };

          export const socialBannerVideoOpen = () => {
            return async dispatch => {
          
              dispatch({
                type: 'SOCIAL_BANNER_VIDEO_OPEN',
              });
          
          
            }
            };