import React, { useState } from "react";
import desktop_1 from "../../../../images/emagazine/Desktop1.png"
import tab_1 from "../../../../images/emagazine/Tab1.png"
import mobile_1 from "../../../../images/emagazine/Mobile1.png"
import desktop_2 from "../../../../images/emagazine/Desktop1.png"
import tab_2 from "../../../../images/emagazine/Tab2.png"
import mobile_2 from "../../../../images/emagazine/Mobile2.png"
import desktop_3 from "../../../../images/emagazine/Desktop3.png"
import tab_3 from "../../../../images/emagazine/Tab3.png"
import mobile_3 from "../../../../images/emagazine/Mobile3.png"
import desktop_4 from "../../../../images/emagazine/Desktop4.png"
import tab_4 from "../../../../images/emagazine/Tab4.png"
import mobile_4 from "../../../../images/emagazine/Mobile4.png"
import desktop_5 from "../../../../images/emagazine/Desktop5.png"
import tab_5 from "../../../../images/emagazine/Tab5.png"
import mobile_5 from "../../../../images/emagazine/Mobile5.png"
import desktop_6 from "../../../../images/emagazine/Desktop6.png"
import tab_6 from "../../../../images/emagazine/Tab6.png"
import mobile_6 from "../../../../images/emagazine/Mobile6.png"
import desktop_7 from "../../../../images/emagazine/Desktop7.png"
import tab_7 from "../../../../images/emagazine/Tab7.png"
import mobile_7 from "../../../../images/emagazine/Mobile7.png"

// import HowItWorkSectionRegularThemeDesktopView1 from './HowItWorkSectionRegularThemeDesktopView1'
// import HowItWorkSectionRegularThemeDesktopView2 from './HowItWorkSectionRegularThemeDesktopView2'


// import HowItWorkSectionRegularThemeTabView1 from './HowItWorkSectionRegularThemeTabView1'
// import HowItWorkSectionRegularThemeMobileView1 from './HowItWorkSectionRegularThemeMobileView1'
// import HowItWorkSectionRegularThemeTabView2 from './HowItWorkSectionRegularThemeTabView2'
// import HowItWorkSectionRegularThemeMobileView2 from './HowItWorkSectionRegularThemeMobileView2'

import Modal from 'react-bootstrap/Modal';

import { useTranslation } from "react-i18next";


import './EMagazineSectionThemes.scss';
import ButtonBoxNew from "../../../global/ButtonBoxNew";
export default function EMagazineSectionRegularTheme() {

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [TabTwoShow, setTabTwoShow] = useState(false);
  const [MobileTwoShow, setMobileTwoShow] = useState(false);
  const [deskShow, setDeskShow] = useState(false);
  const [desktwoShow, setDesktwoShow] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);
  const [TabFourShow, setTabFourShow] = useState(false);
  const [MobileFourShow, setMobileFourShow] = useState(false);
  const [deskFourShow, setdeskFourShow] = useState(false);


  return (
    <div>

      <h4 className="text-center">
        Regular  Design Version 1
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_1} alt="deshtop-1"  >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setDeskShow(true)} />
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskShow}
            onHide={() => setDeskShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HireMeSectionRegularThemeDesktopView1 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_1} alt="tab-1"  ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setSmShow(true)} />
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeTabView1 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_1} alt="mobile-2"></img>
          </div>
          <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div>
          <ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setLgShow(true)} />
          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView1 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 2
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_2} alt="desktop-2" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDesktwoShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setDesktwoShow(true)} />
          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktwoShow}
            onHide={() => setDesktwoShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeDesktopView2 /> */}

            </Modal.Body>
          </Modal>

        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_2} alt="tab-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabTwoShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setTabTwoShow(true)} />
          
          <Modal
            show={TabTwoShow}
            onHide={() => setTabTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-tit"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeTabView2 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_2} alt="mobile-2" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileTwoShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileTwoShow(true)} />

          <Modal
            show={MobileTwoShow}
            onHide={() => setMobileTwoShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-ti"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionRegularThemeMobileView2 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 3
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3} alt="desktop-3" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setDeskthreeShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setDeskthreeShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskthreeShow}
            onHide={() => setDeskthreeShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3} alt="tab-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabThreeShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setTabThreeShow(true)} />

          <Modal
            show={TabThreeShow}
            onHide={() => setTabThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3} alt="mobile-3" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileThreeShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileThreeShow(true)} />

          <Modal
            show={MobileThreeShow}
            onHide={() => setMobileThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">
        Regular  Design Version 4
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_4} alt="desktop-4" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setdeskFourShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setdeskFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFourShow}
            onHide={() => setdeskFourShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_4} alt="tab-4" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFourShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setTabFourShow(true)} />

          <Modal
            show={TabFourShow}
            onHide={() => setTabFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_4} alt="mobile-4" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFourShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={MobileFourShow}
            onHide={() => setMobileFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>


      <h4 className="text-center">
        Regular  Design Version 5
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_5} alt="desktop-5" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setdeskFourShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setdeskFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFourShow}
            onHide={() => setdeskFourShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_5} alt="tab-5" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFourShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setTabFourShow(true)} />

          <Modal
            show={TabFourShow}
            onHide={() => setTabFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_5} alt="mobile-5" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFourShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={MobileFourShow}
            onHide={() => setMobileFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>


      <h4 className="text-center">
        Regular  Design Version 6
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_6} alt="desktop-6" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setdeskFourShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFourShow}
            onHide={() => setdeskFourShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_6} alt="tab-6" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFourShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={TabFourShow}
            onHide={() => setTabFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_6} alt="mobile-6" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFourShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={MobileFourShow}
            onHide={() => setMobileFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>


      <h4 className="text-center">
        Regular  Design Version 7
      </h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_7} alt="desktop-7" >
            </img>

          </div>
          {/* <div className="screen-btn" onClick={() => setdeskFourShow(true)}>
            See Desktop view
          </div> */}
          <ButtonBoxNew  name="See Desktop View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal

            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskFourShow}
            onHide={() => setdeskFourShow(false)}

          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_7} alt="tab-7" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabFourShow(true)}>
            See Tablet view
          </div> */}
          <ButtonBoxNew  name="See Tablet View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={TabFourShow}
            onHide={() => setTabFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}

            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_7} alt="mobile-7" ></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileFourShow(true)}>
            See Mobile view
          </div> */}
          <ButtonBoxNew  name="See Mobile View" boxwidth="w-220 mt-4 mb-5 mx-auto " onClick={() => setMobileFourShow(true)} />

          <Modal
            show={MobileFourShow}
            onHide={() => setMobileFourShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}

            </Modal.Body>
          </Modal>
        </div>
      </div>


    </div>
  );
}
