import React from 'react'
import TrackingComponentSec from './TrackingComponentSec'
import IntegAddTrackFrom from '../IntegAddTrackFrom';
import iconBluew2 from "../../../../../../images/formicon/icon16.png";
import iconBluew3 from "../../../../../../images/formicon/icon44.png";
import iconBluew4 from "../../../../../../images/formicon/user/icon1.png";
import iconBluew5 from "../../../../../../images/formicon/user/icon9.png";
import iconBluew6 from "../../../../../../images/formicon/user/icon10.png";
import iconBluew7 from "../../../../../../images/formicon/user/icon8.png";
import iconBluew8 from "../../../../../../images/formicon/user/icon8s.png";
import iconBluew9 from "../../../../../../images/formicon/user/icon7.png";
import iconBluew10 from "../../../../../../images/formicon/user/icon4.png";
import iconBluew11 from "../../../../../../images/formicon/user/icon5.png";
import iconBluew12 from "../../../../../../images/formicon/user/icon11.png";
import icon from "../../../../../../images/formicon/user/icon.png";
import icons from "../../../../../../images/formicon/icon43.png";
import icon1 from "../../../../../../images/formicon/master/icon.png";
import icon2 from "../../../../../../images/formicon/master/icon1.png";
import icon3 from "../../../../../../images/formicon/master/icon2.png";
import icon4 from "../../../../../../images/formicon/master/icon3.png";
import icon5 from "../../../../../../images/formicon/master/icon4.png";
import icon7 from "../../../../../../images/formicon/master/icon5.png";
import icon6 from "../../../../../../images/formicon/icondot.png";
import icon8 from "../../../../../../images/formicon/user/icon6s.png";
import icon9 from "../../../../../../images/formicon/user/icon6.png";
import icon10 from "../../../../../../images/formicon/master/icon6.png";
import icon11 from "../../../../../../images/formicon/master/icon7.png";
import icon12 from "../../../../../../images/formicon/master/icon8.png";
import icon13 from "../../../../../../images/formicon/master/icon9.png";
import TrackingComponent from './TrackingComponent';
export default function TrackingMaster() {


  return (
    <div className='px-4'>
      <ul className="customDrop6 customDrop1s max-h-auto customDrop2s w-100 shadow-none position-relative">
            <div className="dropheight h-auto">
              <TrackingComponentSec Name="Personal Website" icon={icon} Dropname="Personal Website" />
              <TrackingComponentSec Name="Profile Widgets" icon={icons} Dropname="Profile Widgets" />
              <TrackingComponentSec Name="1000 Socialite  Site" icon={iconBluew4} Dropname="1000 Socialite  Site" />
              <TrackingComponentSec Name="Coming Soon Beta-List" icon={iconBluew3} Dropname="Coming Soon Beta-List" />
              <TrackingComponentSec Name="Landing Page | Queue Launch" icon={iconBluew5} Dropname="Landing Page | Queue Launch" />
              <TrackingComponentSec Name="Webinar sales page" icon={iconBluew2} Dropname="Webinar sales page" />
              <TrackingComponentSec Name="LMS online Academy" icon={iconBluew6} Dropname="LMS online Academy" />
              <TrackingComponentSec Name="E-magazine Website" icon={iconBluew7} Dropname="E-magazine Website" />
              <TrackingComponentSec Name="Press Release" icon={iconBluew8} Dropname="Press Release" />
              <TrackingComponentSec Name="E-commerce store" icon={iconBluew9} Dropname="E-commerce store" />
              <TrackingComponentSec Name="Video Channel" icon={iconBluew10} Dropname="Video Channel" />
              <TrackingComponentSec Name="Podcast website" icon={iconBluew11} Dropname="Podcast website" />
              <TrackingComponentSec Name="Digital content site" icon={iconBluew12} Dropname="Digital content site" />
              <TrackingComponentSec Name="Fashion niche Landing Page website" icon={icon1} Dropname="Fashion niche Landing Page website" />
              <TrackingComponentSec Name="beauty niche Landing Page website" icon={icon2} Dropname="beauty niche Landing Page website" />
              <TrackingComponentSec Name="Fitness niche lead generation site" icon={icon3} Dropname="Fitness niche lead generation site" />
              <TrackingComponentSec Name="nutrition niche Landing Page website" icon={icon4} Dropname="nutrition niche Landing Page website" />
              <TrackingComponentSec Name="travel niche Landing Page website" icon={icon5} Dropname="travel niche Landing Page website" />
              <TrackingComponentSec Name="decor niche Landing Page website" icon={icon7} Dropname="decor niche Landing Page website" />
              <TrackingComponentSec Name="coaching niche Landing Page website" icon={icon8} Dropname="coaching niche Landing Page website" />
              <TrackingComponentSec Name="entertainment niche Landing Page website" icon={icon9} Dropname="entertainment niche Landing Page website" />
              <TrackingComponentSec Name="Finance niche Landing Page website" icon={icon10} Dropname="Finance niche Landing Page website" />
              <TrackingComponentSec Name="spirituality niche Landing Page website" icon={icon11} Dropname="spirituality niche Landing Page website" />
              <TrackingComponentSec Name="relationships niche Landing Page website" icon={icon12} Dropname="relationships niche Landing Page website" />
              <TrackingComponentSec Name="animal niche Landing Page website" icon={icon13} Dropname="animal niche Landing Page website" />
              <TrackingComponentSec Name="Fashion niche Landing Page website" icon={icon1} Dropname="Fashion niche Landing Page website" />
              <TrackingComponentSec Name="beauty niche Landing Page website" icon={icon2} Dropname="beauty niche Landing Page website" />
              <TrackingComponentSec Name="Fitness niche lead generation site" icon={icon3} Dropname="Fitness niche lead generation site" />
              <TrackingComponentSec Name="nutrition niche Landing Page website" icon={icon4} Dropname="nutrition niche Landing Page website" />
              <TrackingComponentSec Name="travel niche Landing Page website" icon={icon5} Dropname="travel niche Landing Page website" />
              <TrackingComponentSec Name="decor niche Landing Page website" icon={icon7} Dropname="decor niche Landing Page website" />
              <TrackingComponentSec Name="coaching niche Landing Page website" icon={icon8} Dropname="coaching niche Landing Page website" />
              <TrackingComponentSec Name="entertainment niche Landing Page website" icon={icon9} Dropname="entertainment niche Landing Page website" />
              <TrackingComponentSec Name="Finance niche Landing Page website" icon={icon10} Dropname="Finance niche Landing Page website" />
              <TrackingComponentSec Name="spirituality niche Landing Page website" icon={icon11} Dropname="spirituality niche Landing Page website" />
              <TrackingComponentSec Name="relationships niche Landing Page website" icon={icon12} Dropname="relationships niche Landing Page website" />
              <TrackingComponentSec Name="animal niche Landing Page website" icon={icon13} Dropname="animal niche Landing Page website" />
              <TrackingComponentSec Name="Other" icon={icon6} Dropname="Other" />
              <TrackingComponentSec Name="Other" icon={icon6} Dropname="Other" />
              <TrackingComponent  icon={icon6} Dropname="Other ?  Type here...." />
              <IntegAddTrackFrom />
            </div>
          </ul>
    </div>
  )
}
