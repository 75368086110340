import React, { useState } from "react";
import desktop_3_1 from "../../../../images/hire-me/Desktop3-1.png";
import tab_3_1 from "../../../../images/hire-me/Tab3-1.png";
import mobile_3_1 from "../../../../images/hire-me/Mobile3-1.png";
import desktop_3_2 from "../../../../images/hire-me/Desktop3-2.png";
import tab_3_2 from "../../../../images/hire-me/Tab3-2.png";
import mobile_3_2 from "../../../../images/hire-me/Mobile3-2.png";
import desktop_3_3 from "../../../../images/hire-me/Desktop3-3.png";
import tab_3_3 from "../../../../images/hire-me/Tab3-3.png";
import mobile_3_3 from "../../../../images/hire-me/Mobile3-3.png";
import Modal from "react-bootstrap/Modal";
// import HowItWorkSectionGlassmorphicThemeDesktopView1 from "./howItWorkGlassmorphicThemes/HowItWorkSectionGlassmorphicThemeDesktopView1";
// import HowItWorkSectionGlassmorphicThemeTabView1 from "./howItWorkGlassmorphicThemes/HowItWorkSectionGlassmorphicThemeTabView1";
// import HowItWorkSectionGlassmorphicThemeMobileView1 from "./howItWorkGlassmorphicThemes/HowItWorkSectionGlassmorphicThemeMobileView1";
// import HowItWorkSectionGlassmorphicThemeDesktopView2 from "./howItWorkGlassmorphicThemes/HowItWorkSectionGlassmorphicThemeDesktopView2";
// import HowItWorkSectionGlassmorphicThemeTabView2 from "./howItWorkGlassmorphicThemes/HowItWorkSectionGlassmorphicThemeTabView2";
// import HowItWorkSectionGlassmorphicThemeMobileView2 from "./howItWorkGlassmorphicThemes/HowItWorkSectionGlassmorphicThemeMobileView2";
import ButtonBoxNew from "../../../global/ButtonBoxNew";

import "./HireMeSectionThemes.scss";
import { useTranslation } from "react-i18next";
export default function HireMeSectionGlassmorphicTheme() {
  const { t, i18n } = useTranslation();

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [themedesktopShow, setThemedesktopShow] = useState(false);
  const [themetabShow, setThemetabShow] = useState(false);
  const [themedesktop3Show, setThemedesktop3Show] = useState(false);
  const [themetab3Show, setThemetab3Show] = useState(false);
  const [thememobileShow, setThememobileShow] = useState(false);
  const [thememobile3Show, setThememobile3Show] = useState(false);
  const [TabThreeShow, setTabThreeShow] = useState(false);
  const [MobileThreeShow, setMobileThreeShow] = useState(false);
  const [deskthreeShow, setDeskthreeShow] = useState(false);
  const [desktopgeoShow, setDesktopgeoShow] = useState(false);

  return (
    <div className="">
      <h4 className="text-center">Glassmorphic Design Version 1</h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_1} alt="desktop-3-1"></img>
          </div>
          {/* <button
            className="screen-btn"
            onClick={() => setDesktopgeoShow(true)}
          >
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"       onClick={() => setDesktopgeoShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={desktopgeoShow}
            onHide={() => setDesktopgeoShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeDesktopView1 /> */}
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_1} alt="desktop-3-1"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setSmShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setSmShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={smShow}
            onHide={() => setSmShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeTabView1 /> */}
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_1} alt="mobile-3-1"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setLgShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setLgShow(true)}/>

          <Modal
            show={lgShow}
            onHide={() => setLgShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeMobileView1 /> */}
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Regular Design Version 2</h4>
      <div className="d-flex card-outo">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_2} alt="desktop-3-2"></img>
          </div>
          {/* <button
            className="screen-btn"
            onClick={() => setThemedesktopShow(true)}
          >
            See Desktop view
          </button> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"    onClick={() => setThemedesktopShow(true)}/>

          <Modal
            show={themedesktopShow}
            onHide={() => setThemedesktopShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeDesktopView2 /> */}
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_2} alt="tab-3-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThemetabShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setThemetabShow(true)}/>

          <Modal
            show={themetabShow}
            onHide={() => setThemetabShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeTabView2 /> */}
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_2} alt="mobile-3-2"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setThememobileShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setThememobileShow(true)}/>

          <Modal
            show={thememobileShow}
            onHide={() => setThememobileShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-titlee"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <HowItWorkSectionGlassmorphicThemeMobileView2 /> */}
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <h4 className="text-center">Regular Design Version 3</h4>
      <div className="d-flex card-outo justify-center">
        <div className="">
          <div className="screen-bg">
            <img src={desktop_3_3} alt="desktop-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setDeskthreeShow(true)}>
            See Desktop view
          </div> */}
<ButtonBoxNew  name="See Desktop view" boxwidth="w-220 mx-auto mt-4 mb-4"  onClick={() => setDeskthreeShow(true)}/>

          <Modal
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={deskthreeShow}
            onHide={() => setDeskthreeShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeDesktopView3 /> */}
            </Modal.Body>
          </Modal>
        </div>
        <div className="">
          <div className="screen-bg">
            <img src={tab_3_3} alt="tab-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setTabThreeShow(true)}>
            See Tablet view
          </div> */}
<ButtonBoxNew  name="See Tablet view" boxwidth="w-220 mx-auto mt-4 mb-4"   onClick={() => setTabThreeShow(true)}/>

          <Modal
            show={TabThreeShow}
            onHide={() => setTabThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-st"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeTabView3 /> */}
            </Modal.Body>
          </Modal>
        </div>

        <div className="">
          <div className="screen-bg">
            <img src={mobile_3_3} alt="mobile-3"></img>
          </div>
          {/* <div className="screen-btn" onClick={() => setMobileThreeShow(true)}>
            See Mobile view
          </div> */}
<ButtonBoxNew  name="See Mobile view" boxwidth="w-220 mx-auto mt-4 mb-4" onClick={() => setMobileThreeShow(true)}/>

          <Modal
            show={MobileThreeShow}
            onHide={() => setMobileThreeShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* <ProfileHeroSectionRegularThemeMobileView3 /> */}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
