import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import img12s from "../../images/audio/img7.png";
import auto from "../../images/audio/auto.png";
import setting from "../../images/audio/setting.png";
import short from "../../images/audio/short.png";
import frame1 from "../../images/audio/frame1.png";
import full from "../../images/audio/full.png";
import play from "../../images/audio/play.png";
import forwad from "../../images/audio/forwad.png";
import sound from "../../images/audio/sound2.png";
import vpause from "../../images/audio/vpause.png";
import closebtn from "../../images/closebtn.png";
import testvideo from "../../images/audio/testvideo.mp4";
import testvideo2 from "../../images/audio/testvideo2.mp4";

import testvideo3 from "../../images/audio/testvideo3.mp4";
import mute from "../../images/audio/mute.png";
import high from "../../images/audio/high.png";

import mainimg7 from '../../images/profileview/img1.png';
import mainimg8 from '../../images/profileview/imgs2.png';
import mainimg9 from '../../images/profileview/imgs3.png';
import tag from '../../images/dropdown/tag.png';
import ImageBox from "../global/ImageBox";

export default function VideoView1({ setHandleView }) {
  const videoData = [testvideo, testvideo2, testvideo3];
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const videoRef = useRef(null);
  const [volume, setVolume] = useState(50);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [showVolumeControls, setShowVolumeControls] = useState(false);
  const [isMiniScreen, setIsMiniScreen] = useState(false);
  const [autoplay, setAutoplay] = useState(false);
  const thumbImageRef = useRef(null);
  const sliderRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handlePlaylistClick = (index) => {
    setCurrentVideoIndex(index);
    setAutoplay(true);
    setIsPlaying(true);
    const progressBar = document.querySelector(".progress-inner");
    progressBar.style.width = "0%";
    progressBar.style.animation = "none";

    // Load and play the new video
    videoRef.current.load();
    videoRef.current.play();

    // Set up a new event listener for the progress bar on the new video
    videoRef.current.addEventListener("timeupdate", updateProgressBar);
  };
  const updateProgressBar = () => {
    const video = videoRef.current;
    const progressBar = document.querySelector(".progress-inner");
    const currentTime = video.currentTime;
    const duration = video.duration;

    const progress = (currentTime / duration) * 100;
    progressBar.style.width = progress + "%";
  };

  useEffect(() => {
    if (autoplay) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [autoplay]);
  const onVideoEnded = () => {
    if (autoplay) {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoData.length);

      if (currentVideoIndex < videoData.length) {
        videoRef.current.src = videoData[currentVideoIndex];
        videoRef.current.play();
      }
    }
  };

  useEffect(() => {
    if (showVolumeControls) {
      setTimeout(() => {
        setShowVolumeControls(false);
      }, 5000);
    }
  }, [showVolumeControls]);

  const toggleVolumeControls = () => {
    setShowVolumeControls(!showVolumeControls);
  };
  const handleForwardClick = () => {
    const nextVideoIndex = (currentVideoIndex + 1) % videoData.length;
    if (nextVideoIndex >= 0) {
      const progressBar = document.querySelector(".progress-inner");
      progressBar.style.width = "0%";
      progressBar.style.animation = "none";
      setAutoplay(true);
      videoRef.current.load();
      videoRef.current.play();
      setIsPlaying(true);
      setCurrentVideoIndex(nextVideoIndex);
      videoRef.current.addEventListener("timeupdate", updateProgressBar);
    }
  };

  useEffect(() => {
    videoRef.current.src = videoData[currentVideoIndex];
  }, [currentVideoIndex]);

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value) / 100;
    setVolume(newVolume);
    videoRef.current.volume = newVolume;

    // Calculate the thumb image position
    updateThumbPosition(newVolume);
  };

  const updateThumbPosition = (newVolume) => {
    const slider = sliderRef.current;
    const thumbImage = thumbImageRef.current;
    const min = parseFloat(slider.min);
    const max = parseFloat(slider.max);
    const percent = ((newVolume - min) / (max - min)) * 100;

    // Calculate the position of the thumb image vertically
    const thumbPosition =
      ((slider.clientHeight - thumbImage.clientHeight) * percent) / 100;

    // Set the position of the thumb image
    thumbImage.style.top = `${thumbPosition}px`;
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  const onTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const handleTheaterButtonClick = () => {
    const videoContainer = document.querySelector(".middleimg");
    if (videoContainer.classList.contains("theater-mode1")) {
      videoContainer.classList.remove("theater-mode1");
      setIsMiniScreen(false);
      setIsFullScreen(false); // Exit theater mode
    } else {
      videoContainer.classList.add("theater-mode1");
      setIsMiniScreen(false);
      setIsFullScreen(false); // Exit mini-screen mode if currently in it
    }
  };

  const handleMiniPlayerButtonClick = () => {
    const videoContainer = document.querySelector(".videop");

    if (videoContainer.classList.contains("mini-player")) {
      videoContainer.classList.remove("mini-player");
      setIsMiniScreen(false);
      setIsFullScreen(false); // Exit mini-screen mode if currently in it
    } else {
      videoContainer.classList.add("mini-player");
      setIsMiniScreen(true);
      setIsFullScreen(false); // Enter mini-screen mode
    }
  };

  const handleFullScreenButtonClick = () => {
    const videoContainer = document.querySelector(".middleimg");

    if (videoContainer) {
      if (!document.fullscreenElement) {
        if (videoContainer.requestFullscreen) {
          videoContainer.requestFullscreen();
        } else if (videoContainer.mozRequestFullScreen) {
          videoContainer.mozRequestFullScreen();
        } else if (videoContainer.webkitRequestFullscreen) {
          videoContainer.webkitRequestFullscreen();
        } else if (videoContainer.msRequestFullscreen) {
          videoContainer.msRequestFullscreen();
        }

        // Add the full-screen CSS class
        videoContainer.classList.add("full-screen");
        setIsFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }

        // Remove the full-screen CSS class
        videoContainer.classList.remove("full-screen");
        setIsFullScreen(false);
      }
    }
  };

  useEffect(() => {
    const videoContainer = document.querySelector(".middleimg");

    if (videoContainer) {
      videoContainer.addEventListener("fullscreenchange", () => {
        setIsFullScreen(!!document.fullscreenElement);
      });
    }
  }, []);
  const handleSliderChange = (e) => {
    videoRef.current.currentTime = e.target.value;
    setCurrentTime(e.target.value);
  };

  const handleClick1 = () => {
    setToggle1(!toggle1);
  };
  const [toggle1, setToggle1] = useState("true");

  const handleClick2 = () => {
    setToggle2(!toggle2);
  };
  const [toggle2, setToggle2] = useState("true");
  const handleVideoClick = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="bg-vid mb-4">
      <div className="text-center w-100">
        <div className="audioply bottomlayer1">
          <div className="w-100">
            <div className="">
              <div className='buttontop position-relative bottom-10s'>
                <button>DYNA APPOLON</button>
              </div>
              <div className='buttontop position-relative bottom-10s'>
                <button>CEO | 9 TO 5 ESCAPEE</button>
              </div>
            </div>
          </div>
          <div className="videoplayer1">
            <button className="topbtn border-0 bg-primary">
              VIDEO CENTER{" "}
            </button>
            <div className="videobox px-2 h-auto py-4 d-flex justify-content-between">
              <div className="playersec w-100">
                <div className="playerinternal h-auto position-relative">
                  <div className="vidplay position-relative w-100">
                    <div className="audiotool position-relative">
                      <div className="videop">
                        <div
                          className={`middleimg  position-relative  ${
                            isMiniScreen ? "mini-player" : ""
                          } ${isFullScreen ? 'full-screen' : ''}`}
                        >
                          {/* <img src={img5} alt="" /> */}
                          <div className="topvid4">
                          <video
                            className="theater-mode1 "
                            ref={videoRef}
                            onCanPlayThrough={() => {
                              setDuration(videoRef.current.duration);
                              setIsLoading(false);
                            }}
                            onTimeUpdate={onTimeUpdate}
                            onEnded={onVideoEnded}
                            autoPlay={autoplay}
                            onClick={handleVideoClick}
                          >
                            <source
                              src={videoData[currentVideoIndex]}
                              type="video/mp4"
                            />
                          </video>
                          </div>
                          <div className="sound-control4">
                            <div>
                              {showVolumeControls && (
                                <div className=" justify-content-center top-25 ">
                                  <div className="volume-control volume-control4">
                                    <input
                                      className="soundediv win10-thumb"
                                      type="range"
                                      defaultValue={volume}
                                      min={0}
                                      max={100}
                                      step={10}
                                      sliderRef={sliderRef}
                                      onChange={handleVolumeChange}
                                    />
                                    </div>
                                    <div>
                                      <img src={mute} width="16px" height="16px" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            {isMiniScreen ? (
                              ""
                            ) : (
                              <div className="position-absolute d-inline-table top-0 start-0 end-0 pt-65 mx-auto">
                                <div onClick={handlePlayPause}>
                                  <div>
                                    {isPlaying ? (
                                      ""
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="40"
                                        height="40"
                                        viewBox="0 0 130 130"
                                        fill="none"
                                      >
                                        <circle
                                          cx="65"
                                          cy="65"
                                          r="64"
                                          stroke="#F0F4F8"
                                          stroke-width="2"
                                        />
                                        <circle
                                          cx="63.375"
                                          cy="63.375"
                                          r="34.125"
                                          fill="url(#paint0_linear_255_76455)"
                                        />
                                        <path
                                          d="M56.875 55.4821C56.875 53.9385 58.5494 52.9768 59.8827 53.7545L73.4135 61.6474C74.7365 62.4192 74.7365 64.3308 73.4135 65.1026L59.8827 72.9955C58.5494 73.7732 56.875 72.8115 56.875 71.2679V55.4821Z"
                                          fill="#202020"
                                        />
                                        <defs>
                                          <linearGradient
                                            id="paint0_linear_255_76455"
                                            x1="63.375"
                                            y1="16.965"
                                            x2="63.375"
                                            y2="108.42"
                                            gradientUnits="userSpaceOnUse"
                                          >
                                            <stop stop-color="#FACB30" />
                                            <stop
                                              offset="0.302269"
                                              stop-color="#FEF9C8"
                                            />
                                            <stop
                                              offset="0.640625"
                                              stop-color="#C49530"
                                            />
                                            <stop
                                              offset="1"
                                              stop-color="#FFF8C4"
                                            />
                                          </linearGradient>
                                        </defs>
                                      </svg>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                            {isMiniScreen ? (
                              ""
                            ) : (
                              <div className="darkbg">
                                {isFullScreen ? (
                                  <div className="position-relative pb-0 d-inline-block videoControlls bottom-0 start-0 end-0 mx-auto">
                                    {/* <img src={playbar} alt="" /> */}

                                    <div className="mx-auto mb-3">
                                      <div className=" mx-auto mb-3 progressbar">
                                        <div
                                          className={`${"progressinner"} ${
                                            currentTime !== duration
                                              ? "progress-inner inner2"
                                              : ""
                                          } `}
                                          style={{
                                            animationPlayState: isPlaying
                                              ? "running"
                                              : "paused",
                                            animationDuration: isLoading
                                              ? "0s"
                                              : `${videoRef.current.duration}s`,
                                          }}
                                        />
                                      </div>{" "}
                                    </div>

                                    <div className="d-flex playable justify-content-between px-4">
                                      <div className="d-flex">
                                        <Link to="">
                                          <div onClick={handlePlayPause}>
                                            {isPlaying ? (
                                              <img src={vpause} alt="" />
                                            ) : (
                                              <img src={play} alt="" />
                                            )}
                                          </div>
                                        </Link>
                                        <Link to="">
                                          <img
                                            src={forwad}
                                            alt=""
                                            onClick={handleForwardClick}
                                          />
                                        </Link>
                                        <div className="btnplaytime">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="me-2"
                                            width="18"
                                            height="19"
                                            viewBox="0 0 18 19"
                                            fill="none"
                                          >
                                            <path
                                              d="M9 18.1699C4.0293 18.1699 0 14.1406 0 9.16992C0 4.19922 4.0293 0.169922 9 0.169922C13.9707 0.169922 18 4.19922 18 9.16992C18 14.1406 13.9707 18.1699 9 18.1699ZM9.9 9.16992V4.66992H8.1V10.9699H13.5V9.16992H9.9Z"
                                              fill="#202020"
                                            />
                                          </svg>
                                          {formatTime(currentTime)} /{" "}
                                          {formatTime(duration)}
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                        <div
                                          className="dropdown mx-3 dropnew dropnew4"
                                          id="dropbtn8"
                                        >
                                          <button
                                            className="p-0 m-0 bg-transparent border-0"
                                            type="button"
                                            id="dropbtn8"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <img src={auto} alt="" />
                                          </button>
                                          
                                        </div>

                                        <div
                                          className="dropdown mx-3 dropnew dropnew5"
                                          id="dropbtn8"
                                        >
                                          <button
                                            className="p-0 m-0 bg-transparent border-0"
                                            type="button"
                                            id="dropbtn8"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <img src={setting} alt="" />
                                          </button>
                                        </div>

                                        <Link to="">
                                          <img
                                            src={short}
                                            alt=""
                                            onClick={
                                              handleMiniPlayerButtonClick
                                            }
                                          />
                                        </Link>

                                        <div className="d-flex">
                                          <Link to="">
                                            <img
                                              src={frame1}
                                              alt=""
                                              onClick={
                                                handleFullScreenButtonClick
                                              }
                                            />
                                          </Link>
                                          <Link to="">
                                            <img
                                              src={full}
                                              alt=""
                                              onClick={
                                                handleFullScreenButtonClick
                                              }
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="position-relative pb-0 d-inline-table w-100 bottom-0 start-0 end-0 mx-auto">
                                    {/* <img src={playbar} alt="" /> */}

                                    <div className="  mx-auto mb-3">
                                      <div className=" mx-auto mb-3 progressbar">
                                        <div
                                          className={`${"progressinner"} ${
                                            currentTime !== duration
                                              ? "progress-inner inner2"
                                              : ""
                                          } `}
                                          style={{
                                            animationPlayState: isPlaying
                                              ? "running"
                                              : "paused",
                                            animationDuration: isLoading
                                              ? "0s"
                                              : `${videoRef.current.duration}s`,
                                          }}
                                        />
                                      </div>{" "}
                                    </div>

                                    <div className="d-flex playable flex-column px-4">
                                      <div className="">
                                        <div className="btnplaytime3">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="me-2"
                                            width="18"
                                            height="19"
                                            viewBox="0 0 18 19"
                                            fill="none"
                                          >
                                            <path
                                              d="M9 18.1699C4.0293 18.1699 0 14.1406 0 9.16992C0 4.19922 4.0293 0.169922 9 0.169922C13.9707 0.169922 18 4.19922 18 9.16992C18 14.1406 13.9707 18.1699 9 18.1699ZM9.9 9.16992V4.66992H8.1V10.9699H13.5V9.16992H9.9Z"
                                              fill="#202020"
                                            />
                                          </svg>
                                          {formatTime(currentTime)} /{" "}
                                          {formatTime(duration)}
                                        </div>
                                      </div>
                                      <div className="d-flex">
                                        <Link to="">
                                          <div onClick={handlePlayPause}>
                                            {isPlaying ? (
                                              <img src={vpause} alt="" />
                                            ) : (
                                              <img src={play} alt="" />
                                            )}
                                          </div>
                                        </Link>
                                        <Link to="">
                                          <img
                                            src={forwad}
                                            alt=""
                                            onClick={handleForwardClick}
                                          />
                                        </Link>
                                        {/* <Link to="">
                                          <img
                                            src={sound}
                                            alt=""
                                            onClick={toggleVolumeControls}
                                          />
                                        </Link> */}
                                        <Link to="">
                                          <img src={auto} alt="" />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          {isMiniScreen ? (
                            " "
                          ) : (
                            <div className="position-absolute z-1w end-0 top-0 d-flex flex-column justify-content-center">
                              <div className="mb-auto">
                                {isFullScreen ? (
                                  <Link
                                    to=""
                                    onClick={handleTheaterButtonClick}
                                  >
                                    <img src={closebtn} width="20" alt="" />
                                  </Link>
                                ) : (
                                  <Link to="" onClick={() => setHandleView("")}>
                                    <img src={closebtn} width="20" alt="" />
                                  </Link>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="position-absolute z-1w start-0 top-0">
                          <Link to="">
                              <img
                                src={sound}
                                alt=""
                                onClick={toggleVolumeControls}
                              />
                            </Link>
                          </div>
                        </div>
                      
                    </div>
                    <div className="audioBtm1 bg d-flex align-items-start flex-column">
                      <div className="listenLive2 d-flex justify-content-center align-items-center pe-3">
                        <div className="icontb shadow-5in bg">
                          <img src={img12s} alt="" />
                        </div>
                        <span>
                          <h3>LISTEN LIVE</h3>
                          <h4>ALL TIPS & TRICKS</h4>
                        </span>
                      </div>
                      <div className="liststyle1 text-start">
                        HOW TO FINALLY QUIT YOUR 9 TO 5 JOB IN 4-HOURS
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul class="d-flex justify-content-center mb-4 px-4 mt-5">
            <li className='me-2'>
              <div className='outerdiv'>
                <img src={mainimg7} alt="" className="circle" />
              </div>
            </li>
            <li className='me-2'>
              <div className='outerdiv'>
                <img src={mainimg8} alt="" className="circle" />
              </div>
            </li>
            <li className=''>
              <div className='outerdiv position-relative'>
                <img src={mainimg9} alt="" className="circle" />
                <div className="tag position-absolute">
                  <ImageBox boxwidth="w-16" src={tag} />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
