import React from "react";
import { useTranslation } from "react-i18next";
import Paypal from '../../../../images/paypal.png';
import "./PaypalPersonalBusinessAccount.scss"
export default function PaypalBusinessAccountForm() {
    const { t, i18n } = useTranslation();
    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: "#EFF4F8",
            primary: "#EFF4F8",
        },
    });

    return (
        <div className="pb-5 pt-2">
            <div className="row mb-4 px-3">
                <div className="col-md-4 px-0">
                    <label className="mb-1">{t('timeline_form_paypal_account_component.business_paypal_registration')}</label>
                    <span className="shadow-4 h-50s br-3  d-flex justify-content-center align-items-center text-center text_blue h6">
                    <img src={Paypal} alt="" className="w-35 me-3"/>
                        Registration Link</span>
                </div>
                <div className="col-md-8">
                    <label className="mb-1">{t('timeline_form_paypal_account_component.paypal_business_account_username')}</label>
                    <input placeholder={t('timeline_form_paypal_account_component.paypal_business_account_username_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
            </div>
            <div className="row mb-4 px-3">
                <div className="col-md-6">
                    <label className="mb-1">{t('timeline_form_paypal_account_component.business_paypal_email')}</label>
                    <input placeholder={t('timeline_form_paypal_account_component.business_paypal_email_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
                <div className="col-md-6">
                    <label className="mb-1">{t('timeline_form_paypal_account_component.business_paypal_page_url')}</label>
                    <input placeholder={t('timeline_form_paypal_account_component.business_paypal_page_url_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
            </div>
            <div className="row mb-4 px-3">
                <div className="col-md-6">
                    <label className="mb-1">{t('timeline_form_paypal_account_component.paypal_client_id')}</label>
                    <input placeholder={t('timeline_form_paypal_account_component.paypal_client_id_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
                <div className="col-md-6">
                    <label className="mb-1">{t('timeline_form_paypal_account_component.paypal_secret_code')}</label>
                    <input placeholder={t('timeline_form_paypal_account_component.paypal_secret_code_placeholder')} className='shadow-5in br-5 h6 mb-0' type="text" />
                </div>
            </div>
        </div>
    );
}
