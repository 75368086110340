import React from "react";
import { useState } from "react";
import HireMeNav from "../components/HireMeNav/HireMeNav";
import HireMeMainContent from "../components/HireMeMainContent/HireMeMainContent";
import HireMePositionBox from "../components/HireMePositionBox/HireMePositionBox";
import HireMeForm from "../components/HireMeForm/HireMeForm";
import HireMeSideBox from "../components/HireMeSideBox/HireMeSideBox";
import Pattern from "../../../../../../images/hire-me/desktopview/Dots.png";
import "../regularTheme1Desktop/RegularTheme1Desktop.scss";
import "../regularTheme1Mobile/RegularTheme1Mobile.scss";
import "./RegularTheme1Tab.scss";

import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import timeline from "../../../../../../images/how-it-work/timeline.png";
import timeline_2 from "../../../../../../images/how-it-work/timeline-2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function RegularTheme1Desktop({ Regular, Neumorphic }) {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const handleHamburgerClose = () => {
    setHamburger(false);
  };
  const [bookme, setBookme] = useState(false);

  const handleBookmeClick = () => {
    setBookme(!bookme);
  };

  const handleBookmeClose = () => {
    setBookme(false);
  };

  const [Competency1, setCompetency1] = useState(10);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  return (
    <>
      <div
        className="main-content-tab RegularTheme1Tab"
        style={{ backgroundColor: Regular ? "#cbd5e0" : "" }}
      >
        <div className="pattern-tab">
          <img src={Pattern} alt="" />
        </div>
        <div className="tab-main-section" style={{ position: "relative" }}>
          <p className="text-center-tab pt-5">AWARDS</p>
          <div className="position-nav-tab d-flex justify-content-around">
            <HireMeNav Regular={Regular} Neumorphic={Neumorphic} />
          </div>
          <div className="main-container-tab">
            <HireMeMainContent Regular={Regular} Neumorphic={Neumorphic} />
          </div>
        </div>
        <div className="position-box-one-tab">
          <HireMePositionBox Regular={Regular} Neumorphic={Neumorphic} />
        </div>
      </div>
      <div className="form-and-profile-tab d-flex ">
        <div className="side-position-box-tab">
          <HireMeSideBox Regular={Regular} Neumorphic={Neumorphic} />
        </div>
        <div className="form-position-tab">
          <HireMeForm Regular={Regular} Neumorphic={Neumorphic} />
        </div>
      </div>
         
    </>
  );
}
